import { Injectable } from '@angular/core';
import { Hit, isDataSourceDataset, Source } from '@shared/models/query-result-models';
import { UIDataCatalog } from 'generated-sources';
import { DataCatalogRightPanelContextService } from '@features/data-catalog/data-catalog-right-panel-context.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class DataSourcesRightPanelContextService extends DataCatalogRightPanelContextService<Hit<Source>> {
    protected fetchDetails(selectedItem: Hit<Source>): Observable<UIDataCatalog.AbstractDataCatalogItemDetails | undefined> {
        if (isDataSourceDataset(selectedItem)) {
            return this.dataikuApiService.catalog.getDataSourceDatasetDetails(selectedItem._source.projectKey, selectedItem._source.id);
        }
        return of(undefined);
    }
}
