import { Injectable } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Chunk } from "@shared/components/infinite-scroll/infinite-scroll.component";
import { ImagesDataFetcherService } from "@shared/services/item-feed/image-feed/images-data-fetcher.service";
import { AnyLoc } from "generated-sources";
import { Observable } from "rxjs";
import { LabelingReviewService } from "../labeling-task-review/services/labeling-review.service";
import { getImageURL } from "../utils";
import { ImageLabelingTaskInfo, LabelingService } from "./labeling.service";


@UntilDestroy()
@Injectable()
export class LabelingReviewDataFetcherService extends ImagesDataFetcherService {
    labelingTaskId: string;
    CHUNK_SIZE = 10;
    managedFolderLoc: AnyLoc;

    constructor(private labelingService: LabelingService, private labelingReviewService: LabelingReviewService) {
        super();

        this.labelingService.labelingTaskInfo$.pipe(untilDestroyed(this)).subscribe((info) => {
            this.refetchData();
            this.labelingTaskId = info.labelingTaskId;
            this.projectKey = info.projectKey;
            this.managedFolderLoc = (info as ImageLabelingTaskInfo).getManagedFolderLoc();
        })

        this.labelingReviewService.recordIds$.pipe(untilDestroyed(this)).subscribe(() => {
            this.refetchData();
        });
    }

    getImagePath(itemPath: string): string {
        return getImageURL(this.projectKey, this.labelingTaskId, this.managedFolderLoc, itemPath);
    }

    updateItem(index: number, item: any): void {
        super.updateItem(index, item);
        this.labelingReviewService.updateCurrentReviewRecord(item);
    }

    getChunk(offset: number): Observable<Chunk> {
        return this.labelingReviewService.getChunk(offset, this.CHUNK_SIZE);
    }
}
