import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LabelingService, TextLabelingTaskInfo } from '@features/labeling/services/labeling.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNumeric } from '@utils/schema';
import { observeInput } from 'dku-frontend-core';
import { Observable, Subject, combineLatest, map, withLatestFrom } from 'rxjs';
import { LabelingTask, SchemaColumn } from 'src/generated-sources';

@UntilDestroy()
@Component({
    selector: 'text-labeling-settings-data',
    templateUrl: './text-labeling-settings-data.component.html',
    styleUrls: ['../labeling-settings-data.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextLabelingSettingsDataComponent implements OnInit {

    @Input() metadataColumns: SchemaColumn[] | null;
    metadataColumns$ = observeInput(this, 'metadataColumns');

    hasAnswers$: Observable<boolean>;
    idColumnChanged$ = new Subject<boolean>();

    @Output() partialTaskChange = new EventEmitter<Partial<LabelingTask>>();

    form = this.fb.group({
        idColumn: this.fb.control(null, [Validators.required]),
        dataColumn: this.fb.control(null, [Validators.required]),
        extraColumns: this.fb.control(null, [Validators.required])
    });

    specifyIdColumn: boolean;

    constructor(private fb: FormBuilder, private labelingService: LabelingService) { }

    ngOnInit(): void {
        combineLatest([
            this.labelingService.labelingTaskInfo$,
            this.metadataColumns$
        ]).pipe(
            untilDestroyed(this),
        ).subscribe(([info, columns]) => {
            this.form.patchValue({
                idColumn: info.idColumn,
                dataColumn: (info as TextLabelingTaskInfo).dataColumn,
                extraColumns: columns?.map(column => column.name).filter(column => info.extraColumns?.includes(column))
            }, { emitEvent: false });

            this.specifyIdColumn = info.idColumn != null; 
        });

        this.hasAnswers$ = this.labelingService.countAnswers().pipe(
            map((count) => count > 0),
        );

        this.form.valueChanges.pipe(
            withLatestFrom(this.labelingService.labelingTaskInfo$),
            untilDestroyed(this)
        ).subscribe(([formValue, task]) => {
            this.idColumnChanged$.next(formValue.idColumn !== task.idColumn);
            this.partialTaskChange.emit(formValue);
        });
    }


    canBeIDColumn(column: SchemaColumn) {
       return isNumeric(column.type) || column.type === "string";
    }

    canBeTextColumn(column: SchemaColumn) {
        return column.type === "string";
    }

    toggleSpecifyIdColumn(value: boolean) {
        this.specifyIdColumn = value;

        if (!value) {
            this.form.patchValue({
                idColumn: null,
            });
        }
    }

}
