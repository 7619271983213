import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';
import { BehaviorSubject } from 'rxjs';
import { debounceTime} from 'rxjs/operators';
import { RegressionTypes } from '../../../constants';
import { RegressionLineOptionsService } from './regression-line-options.service';

@Component({
    selector: 'regression-line-form',
    templateUrl: './regression-line-form.component.html',
    styleUrls: ['../forms.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegressionLineFormComponent implements OnInit {

    public opened$ = new BehaviorSubject<boolean>(false);
    public REGRESSION_TYPES = ChartDef.RegressionType;
    public regressionTypes;
    public labelPositionOptions;
    public fontSizeOptions: number[];
    public colors;
    public cutValuesWarning = false;
    public logWarning = false;
    public expWarning = false;

    public regressionLineForm: FormGroup = new FormGroup({
        show: new FormControl(null),
        displayFormula: new FormControl(null),
        type: new FormControl(null),
        lineColor: new FormControl('#000'),
        lineSize: new FormControl(1),
        labelPosition: new FormControl(ChartDef.RegressionLinePosition.INSIDE_END),
        textFormatting: new FormControl(null)
    });

    @Input()
    set displayLogWarning(value: boolean) {
        this.logWarning = value;
        this.updateWarning();
    }

    @Input()
    set displayExpWarning(value: boolean) {
        this.expWarning = value;
        this.updateWarning();
    }

    @Input()
    set regressionLine(value: ChartDef.Regression) {
        if (!this.equals(value, this.regressionLineForm.getRawValue())) {
            this.regressionLineForm.patchValue(value);
        }
    }

    @Output() regressionLineChange = new EventEmitter<Partial<ChartDef.Regression>>();

    constructor(regressionLineOptions: RegressionLineOptionsService) {
        this.regressionTypes = regressionLineOptions.regressionTypes;
        this.labelPositionOptions = regressionLineOptions.labelPositionOptions;
        this.colors = regressionLineOptions.colors;
    }

    toggle() {
        this.opened$.next(!this.opened$.getValue());
    }

    hasRegressionLine() {
        return this.regressionLineForm.value.show;
    }

    addRegression() {
        this.regressionLineForm.patchValue({ show: true });
    }

    deleteRegression() {
        this.regressionLineForm.patchValue({ show: false });
    }

    updateWarning() {
        this.cutValuesWarning = this.logWarning && this.regressionLineForm.value.type === RegressionTypes.LOGARITHMIC || this.expWarning && this.regressionLineForm.value.type === RegressionTypes.EXPONENTIAL;
    }

    ngOnInit(): void {
        this.regressionLineForm.valueChanges
            .pipe(debounceTime(10))
            .subscribe((nextValue: Partial<ChartDef.Regression>) => {
                this.updateWarning();
                this.regressionLineChange.emit({ ...nextValue });
            });
    }

    private equals(regressionLine: ChartDef.Regression, regressionLineForm: Partial<ChartDef.Regression>) {
        return regressionLine && regressionLine.lineColor === regressionLineForm.lineColor &&
            regressionLine.lineSize === regressionLineForm.lineSize &&
            regressionLine.displayFormula === regressionLineForm.displayFormula &&
            regressionLine.show === regressionLineForm.show &&
            regressionLine.type === regressionLineForm.type &&
            regressionLine.labelPosition === regressionLineForm.labelPosition &&
            regressionLine.textFormatting === regressionLineForm.textFormatting;
    }
}
