import { Component, ElementRef } from '@angular/core';
import { NamedEntityGroup } from '@features/labeling/models/annotation-group';
import { AnnotationFactory } from '@features/labeling/services/annotation.factory';
import { LabelingService } from '@features/labeling/services/labeling.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LabelingTask } from 'src/generated-sources';
import { LabelingReviewAnnotationGroupService } from '../../services/labeling-review-annotation-group.service';

@UntilDestroy()
@Component({
    selector: 'annotation-group-list',
    templateUrl: './annotation-group-list.component.html',
    styleUrls: [
        '../../../shared-styles/annotation.less',
        './annotation-group-list.component.less'
    ]
})
export class AnnotationGroupListComponent {
    hasSelectedAnnotationGroup$: Observable<boolean>;
    readonly LabelingTaskType = LabelingTask.LabelingTaskType;
    readonly NamedEntityGroup = NamedEntityGroup;
    readonly ANNOTATION_GRP_ID_PREFIX = "annotationGrp-";
    private elementToScrollTo: any;

    constructor(
        private annotationFactory: AnnotationFactory,
        public labelingReviewAnnotationGroupService: LabelingReviewAnnotationGroupService,
        public labelingService: LabelingService,
        private host: ElementRef,
    ) {
        this.hasSelectedAnnotationGroup$ = labelingReviewAnnotationGroupService.annotationGroupList$.pipe(
            map(annotationGroupList => annotationGroupList.some(r => r.selected))
        )


        labelingReviewAnnotationGroupService.annotationGroupList$.pipe(
            untilDestroyed(this)
        ).subscribe((annotationGroups) => {
            const openGrpIdx = annotationGroups.findIndex(g => g.selected || g.annotations.some(a => a.selected)); 
            if (openGrpIdx >= 0) {
                this.elementToScrollTo = this.host.nativeElement.querySelector(`#${this.ANNOTATION_GRP_ID_PREFIX+openGrpIdx}`);
            }
        });
    }

    ngAfterViewChecked() {
        if (this.elementToScrollTo) {
            this.elementToScrollTo.scrollIntoView();
            this.elementToScrollTo = null;
        }
    }

    selectAnnotationGroup(index: number) {
        this.labelingReviewAnnotationGroupService.selectAnnotationGroup(index);
    }

    deleteAnnotationGroup(type: LabelingTask.LabelingTaskType, index: number) {
        switch (type) {
            case LabelingTask.LabelingTaskType.IMAGE_CLASSIFICATION:
                // clear out all objects
                this.labelingReviewAnnotationGroupService.updateAnnotationGroup([this.annotationFactory.createEmptyAnnotation()], true);
                break;
            default:
                this.labelingReviewAnnotationGroupService.deleteAnnotationGroup(index);
        }
    }

    getDeleteTooltip(type: LabelingTask.LabelingTaskType) {
        switch (type) {
            case LabelingTask.LabelingTaskType.IMAGE_CLASSIFICATION:
                return 'Clear all annotations for this image'
            default:
                return 'Delete region'
        }
    }
}


