import type {BinningMode} from './../../models/binning-mode';
import type {BoxPlotFragment} from './../fragments/box-plot-fragment';
import type {CardResult, _CardResult} from './../card-result';
import type {Card} from './../card';
import type {Filter} from './../../../compute/filtering/filter';
import type {Variable} from './../../models/variable';
import type {_BivariateCard} from './../bivariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateBoxPlotCard
 */
export interface BivariateBoxPlotCard extends _BivariateCard {
    binningMode: BinningMode;
    customBinningBoundaries: number[];
    groupOthers: boolean;
    maxValues: number;

    // PolyJson type
    type: 'bivariate_box_plot';
}

export namespace BivariateBoxPlotCard {
    export const type = 'bivariate_box_plot';
}

/** @deprecated Use Card.isBivariateBoxPlotCard() */ export function isBivariateBoxPlotCard(toTest: Card): toTest is BivariateBoxPlotCard { return BivariateBoxPlotCard.type === toTest['type']; }

export namespace BivariateBoxPlotCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateBoxPlotCard$BivariateBoxPlotCardResult
     */
    export interface BivariateBoxPlotCardResult extends _CardResult {
        aggregatedVariable: Variable;
        axisVals: Filter[];
        axisVariable: Variable;
        boxPlots: BoxPlotFragment[];

        // PolyJson type
        type: 'bivariate_box_plot';
    }

    export namespace BivariateBoxPlotCardResult {
        export const type = 'bivariate_box_plot';
    }

    /** @deprecated Use CardResult.isBivariateBoxPlotCardResult() */ export function isBivariateBoxPlotCardResult(toTest: CardResult): toTest is BivariateBoxPlotCardResult { return BivariateBoxPlotCardResult.type === toTest['type']; }
}