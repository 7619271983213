import { OverlayConfig, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Injectable, Inject, ComponentRef } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { WaitingOverlayComponent } from './waiting-overlay/waiting-overlay.component';
import { fairAny } from 'dku-frontend-core';
import { Observable, defer, Subject, OperatorFunction, MonoTypeOperatorFunction } from 'rxjs';
import { takeUntil, finalize, tap } from 'rxjs/operators';
import type { IScope } from 'angular';
import { TypedFutureResponse, FutureWatcherService } from 'dku-frontend-core';

interface FutureState {
    progress: any;
    aborter: () => void;
}

const ABORT_ERROR = {
    message: 'Interrupted',
    errorType: 'FutureAbort',
    httpCode: 444
};

@Injectable({
    providedIn: 'root'
})
export class WaitingService {
    constructor(
        private overlay: Overlay,
        private futureWatcher: FutureWatcherService,
        @Inject('SpinnerService') SpinnerService: fairAny,
        @Inject('$rootScope') private $rootScope: IScope
    ) {
        this.spinnerServiceInstance = SpinnerService();
    }

    private spinnerServiceInstance: any;

    // List of running futures (only the last one is displayed)
    private futures: FutureState[] = [];
    // Active overlay reference
    private overlayRef?: OverlayRef;
    private componentRef?: ComponentRef<WaitingOverlayComponent>;

    // Nb. of observables requesting the spinner to be shown (spinner is displayed when count > 0)
    private spinnerRequestCount = 0;
    // Active spinner flag
    private isSpinnerDisplayed = false;

    bindSpinner<T>() {
        const spinnerHolder$ = new Observable<T>(_ => {
            this.spinnerRequestCount++;
            this.update();
            return () => {
                this.spinnerRequestCount--;
                this.update();
            };
        });
        return (longOperation: Observable<T>) => longOperation.pipe(takeUntil(spinnerHolder$));
    }

    bindStaticOverlay<T>(message = 'Please wait...'): MonoTypeOperatorFunction<T> {
        const attachedObservable = new Observable(observer => {
            const futureState = {
                progress: { states: [{ name: message }] },
                aborter: () => { observer.error(ABORT_ERROR); }
            };
            this.futures.push(futureState);
            this.update();
            return () => {
                this.futures = this.futures.filter(f => f !== futureState);
                this.update();
            };
        });

        return future => future.pipe(takeUntil(attachedObservable));
    }

    bindOverlayAndWaitForResult<T>(): OperatorFunction<TypedFutureResponse<T>, T> {
        return future => defer(() => { // defer() ensures we get a different context at every subscription
            const aborted$ = new Subject<void>();
            let futureState: FutureState | undefined;
            return future.pipe(
                tap(resp => {
                    if (!futureState) {
                        futureState = {
                            aborter: () => aborted$.error(ABORT_ERROR),
                            progress: resp.progress
                        };
                        this.futures.push(futureState);
                    }
                    futureState.progress = resp.progress;
                    this.update();
                }),
                finalize(() => {
                    this.futures = this.futures.filter(f => f !== futureState);
                    this.update();
                }),
                this.futureWatcher.waitForResult(),
                takeUntil(aborted$)
            );
        });
    }

    private update() {
        // Update the future overlay (managed by Angular)
        const shouldDisplayOverlay = this.futures.length > 0;
        const isOverlayDisplayed = this.overlayRef && this.componentRef;

        if (shouldDisplayOverlay && !isOverlayDisplayed) {
            const config = new OverlayConfig();
            config.positionStrategy = this.overlay.position()
                .global().centerHorizontally().centerVertically();
            this.overlayRef = this.overlay.create(config);
            const portal = new ComponentPortal(WaitingOverlayComponent);
            this.componentRef = this.overlayRef.attach(portal);
            this.componentRef.instance.setAbortFunction(() => {
                // Abort them all
                for (const future of this.futures) {
                    future.aborter();
                }
                this.futures = [];
            });
        }

        if (!shouldDisplayOverlay && isOverlayDisplayed) {
            this.overlayRef!.dispose();
            delete this.overlayRef;
            delete this.componentRef;
        }

        if (shouldDisplayOverlay) {
            const lastFuture = this.futures[this.futures.length - 1];
            this.componentRef!.instance.update(lastFuture.progress);
        }

        // Update the spinner (managed by AngularJS)
        const shouldDisplaySpinner = this.spinnerRequestCount > 0
            && !shouldDisplayOverlay; // Hide spinner if overlay is shown

        if (this.isSpinnerDisplayed && !shouldDisplaySpinner) {
            this.$rootScope.$applyAsync(() => this.spinnerServiceInstance.release());
            this.isSpinnerDisplayed = false;
        }

        if (!this.isSpinnerDisplayed && shouldDisplaySpinner) {
            this.$rootScope.$applyAsync(() => this.spinnerServiceInstance.acquire());
            this.isSpinnerDisplayed = true;
        }
    }
}
