import { UIDataCollection } from "src/generated-sources";

export enum SortByOptions {
    NAME = 'Name',
    DATASET_COUNT = 'Dataset count',
    LAST_MODIFIED = 'Last modified'
}

export enum SortOrder {
    ASC ='ASC',
    DESC = 'DESC'
}

type DataCollectionSortFunction = (a: UIDataCollection.ListItemWithDetails, b: UIDataCollection.ListItemWithDetails) => number;

const sortFunctions: Record<SortByOptions, DataCollectionSortFunction> = {
    'Name': (a, b) => a.displayName.localeCompare(b.displayName),
    'Dataset count': (a, b) => a.itemCount - b.itemCount,
    'Last modified': (a, b) => b.lastModifiedOn - a.lastModifiedOn, // most recent first
};

export function sortDataCollections(sortBy: SortByOptions, sortOrder: SortOrder) : DataCollectionSortFunction {
    const order = sortOrder == SortOrder.ASC ? 1 : -1;
    return (a, b) => order * sortFunctions[sortBy](a, b);
}
