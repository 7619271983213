import { Pipe, PipeTransform, Inject } from '@angular/core';
import { fairAny } from 'dku-frontend-core';

@Pipe({
    name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

    constructor(
      @Inject('UpgradedFilters') private upgradedFilters: fairAny
    ) {}

    transform<T>(input: T[], orders: string|string[]): T[] {
        return this.upgradedFilters.orderBy(input, orders);
    }

}
