import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'top-drawer',
  templateUrl: './top-drawer.component.html',
  styleUrls: ['./top-drawer.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopDrawerComponent {
    contextDrawerOpen: boolean = false;

    toggleContextDrawer() {
        this.contextDrawerOpen = !this.contextDrawerOpen;
    }
}
