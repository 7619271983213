import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { LabelingShortcutService } from '@features/labeling/services/labeling-shortcut.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep } from 'lodash';
import { debounceTime, Subject } from 'rxjs';
import { Token } from 'src/generated-sources';
import { UINamedEntity } from '../../models/annotation';
import { NamedEntityExtractionUILabel } from '../../models/label';
import { LabelingAnnotateTextService, TokenAnnotationMap } from '../services/labeling-annotate-text.service';
import { TextLabelingRecord } from '@model-main/labeling/text/text-labeling-record';
import { namedEntityAnnotationsSortingFn } from '@features/labeling/utils';

@UntilDestroy()
@Component({
    selector: 'labeling-task-text-annotate',
    templateUrl: './labeling-task-text-annotate.component.html',
    styleUrls: ['./labeling-task-text-annotate.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelingTaskTextAnnotateComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() selectionModeAvailable?: boolean = false;
    @Input() record: TextLabelingRecord;
    @Input() label: NamedEntityExtractionUILabel;
    @Input() readOnly?: boolean;
    @Output() labelChange = new EventEmitter<NamedEntityExtractionUILabel>();

    @ViewChild('textContainer', { static: false }) textContainer: ElementRef;
    @ViewChildren('tokens') tokens: QueryList<ElementRef>;

    tokenAnnotationsMap: TokenAnnotationMap = {};
    
    readonly ANNOTATION_HEIGHT = 22;
    readonly ANNOTATION_POSITION = 12;
    readonly TOKEN_HEIGHT = 34;
    readonly TOKEN_PREFIX = this.labelingAnnotateTextService.TOKEN_PREFIX;
    readonly DELIMITER_PREFIX = this.labelingAnnotateTextService.DELIMITER_PREFIX;

    private displayedLabel: NamedEntityExtractionUILabel;
    private resizeObserver: ResizeObserver;
    private resize$ = new Subject<void>();
    private shouldScroll = true; // do not scroll if action occurs within this component

    constructor(
        private host: ElementRef,
        private labelingAnnotateTextService: LabelingAnnotateTextService,
        private labelingShortcutService: LabelingShortcutService,
        private changeDetectionRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.resize$.pipe(
            debounceTime(100),
            untilDestroyed(this)
        ).subscribe(_ => {
            this.setLabelWidths();
            this.changeDetectionRef.detectChanges();
        });

        this.resizeObserver = new ResizeObserver(_ => {
            this.resize$.next();
        })
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.record) {
            // clear all existing labels in UI
            this.tokenAnnotationsMap = {};
            this.labelingAnnotateTextService.setTokens(this.record?.tokens);

            // ensure we are scrolled to top when record is changed
            if (this.textContainer?.nativeElement) {
                this.textContainer.nativeElement.scrollTop = 0;
            }
        }

        if (changes.label && this.label && this.record) {
            this.displayedLabel = cloneDeep(this.label);

            this.clearSelectionIfNeeded();
            this.resetTokenAnnotations();

            const selectedAnnotations = this.displayedLabel.annotations.filter(annotation => annotation.selectableInSidePanel() && annotation.selected);

            if (this.shouldScroll && selectedAnnotations.length) {
                // find the annotation that was just selected
                const selectedAnnotation = selectedAnnotations.filter(annotation => !changes.label.previousValue.annotations.includes(annotation))[0];

                if (selectedAnnotation) {
                    const beginningIndex = selectedAnnotation.beginningIndex;
                    const id = '#' + this.TOKEN_PREFIX + this.labelingAnnotateTextService.getTokenIdFromStart(beginningIndex);

                    this.host.nativeElement.querySelector(id)?.scrollIntoView({
                        block: 'center'
                    });
                }
            }
            
            this.shouldScroll = true;
        }
    }

    numberOfLineReturns(token: Token): any {
        return (token.delimiter.match(/\n/g) || []).length;
    }

    formatDelimiter(delimiter: string) {
        return delimiter.replace(/\n/g,'');
    }

    ngAfterViewInit(): void {
        this.resizeObserver.observe(this.host.nativeElement);
    }

    handleSelection() {
        if (this.readOnly) {
            return;
        }
        // deselect existing annotations
        const selection = document.getSelection();
        let annotationWasSelected = false;

        // deselect and de-hover everything before continuining
        this.displayedLabel.annotations.forEach(annotation => {
            annotationWasSelected = annotationWasSelected || annotation.selected ||annotation.hovered;
            annotation.selected = false;
            annotation.hovered = false;
        });

        if (selection && this.isSelectionValid(selection)) {
            const annotation = this.labelingAnnotateTextService.createAnnotation(selection);

            if (annotation) {
                this.addAnnotation(annotation);
            }
        } else {
            // if no selection, emit deselection change if something was selected
            if (annotationWasSelected) {
                this.labelChange.emit(this.displayedLabel);
            }
        }

        // remove selection everything after handling selection
        selection?.removeAllRanges();
    }

    // use mouseup event here to prevent mouseup on parent selection
    toggleSelectedAnnotation(event: MouseEvent, selectedAnnotation: UINamedEntity) {
        if (document.getSelection()?.type === 'Range') {
            // if user is selecting but they mouse up on an existing annotation line, handle the annotation creation instead
            this.handleSelection();
        } else {
            if (!this.selectionModeAvailable || !event.shiftKey) {
                this.displayedLabel.annotations.forEach(annotation => {
                    annotation.selected = false;
                });
            }
            selectedAnnotation.selected = !selectedAnnotation.selected;
            this.shouldScroll = false;
            this.labelChange.emit(this.displayedLabel);
        }

        event.stopPropagation();
    }

    setHoveredAnnotation(event: Event, hoveredAnnotation: UINamedEntity, hover: boolean) {
        hoveredAnnotation.hovered = hover;
    }

    deleteAnnotation(event: MouseEvent, annotationToDelete: UINamedEntity) {
        if (this.readOnly) {
            return;
        }
        this.displayedLabel.annotations = this.displayedLabel.annotations.filter(annotation => !annotation.equals(annotationToDelete));
        this.labelChange.emit(this.displayedLabel);

        event.stopPropagation();
    }

    private addAnnotation(newAnnotation: UINamedEntity) {
        // check if annotation already exists
        const annotationExists = this.displayedLabel.annotations.some(annotation => {
            return annotation.equals(newAnnotation, true);
        });

        if (!annotationExists) {
            this.displayedLabel.addAnnotations([newAnnotation]);
            this.shouldScroll = false;
            this.labelChange.emit(this.displayedLabel);
        }
    }

    private deleteSelectedAnnotations() {
        const selection = this.displayedLabel.annotations.filter(annotation => annotation.selected);

        if (selection == null) {
            return;
        }
        
        this.displayedLabel.annotations = this.displayedLabel.annotations.filter(annotation => !selection.includes(annotation));
        this.labelChange.emit(this.displayedLabel);
    }

    private resetTokenAnnotations() {
        this.tokenAnnotationsMap = this.labelingAnnotateTextService.createTokenAnnotationMap(this.displayedLabel.annotations);
        
        // ensure labels are visible before setting label width
        this.changeDetectionRef.detectChanges();

        this.setLabelWidths();

        this.changeDetectionRef.detectChanges();

    }

    private isSelectionValid(selection: Selection): boolean {
        return !(selection.isCollapsed || 
            selection.toString() === '');
    }

    /*
        When adding/deleting an annotation or resizing the window,
        calculate the max width for each annotation label, which is either
        - the distance between startToken's left position and the left position of closest token on the same line
        - the distance between startToken's left position and the right edge of the textbox
    */
    private setLabelWidths() {
        if (!this.displayedLabel?.annotations) {
            return;   
        }

        // sort annotations by start index
        const sortedAnnotations =[...this.displayedLabel.annotations].sort(namedEntityAnnotationsSortingFn)
        sortedAnnotations.forEach((annotation, index) => {
            const tokenPosition = this.getTokenPositionFromBeginningIndex(annotation.beginningIndex);
            // find next annotation that doesn't overlap with current one
            const nextAnnotation = sortedAnnotations.find(nextAnnotation => annotation.endIndex < nextAnnotation.beginningIndex);
            const nextTokenPosition = nextAnnotation ? this.getTokenPositionFromBeginningIndex(nextAnnotation.beginningIndex) : null;

            // if both tokens are on the same line, find the max width of this annotation
            if (tokenPosition && nextTokenPosition && tokenPosition.top >= nextTokenPosition.top) {
                annotation.labelWidth = nextTokenPosition.left - tokenPosition.left;
            } else {
                // set the max-width of the annotation to be from the start token to the end of the textbox
                annotation.labelWidth = this.getElementRightBound(this.textContainer?.nativeElement) - tokenPosition.left;
            }
        });
    }

    private getTokenPositionFromBeginningIndex(index: number) {
        const tokenId = this.labelingAnnotateTextService.getTokenIdFromData('beginningIndex', index);
        const tokenEl = this.tokens.find(token => token.nativeElement.id === 'token_' + tokenId);
        return tokenEl?.nativeElement?.getBoundingClientRect();
    }

    private getElementRightBound(element: HTMLElement) {
        // the right bound should include inner padding
        return element.getBoundingClientRect().right - parseInt(window.getComputedStyle(element).paddingLeft);
    }

    private clearSelectionIfNeeded() {
        // clear selection only if existing selection is within the annotation area (and not in an input field for example)
        const selection = document.getSelection();

        if (selection && this.textContainer?.nativeElement?.contains(selection.anchorNode)) {
            selection.removeAllRanges();
        }
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyDownEvent(event: KeyboardEvent) {
        if (this.labelingShortcutService.isShortcut(event, "DELETE")) {
            this.deleteSelectedAnnotations();
        }
    }
}
