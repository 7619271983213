import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberSelectComponent } from './number-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        NumberSelectComponent
    ],
    imports: [
        CommonModule,
        NgSelectModule,
        FormsModule
    ],
    exports: [
        NumberSelectComponent
    ]
})
export class NumberSelectModule { }
