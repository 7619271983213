import _ from 'lodash';
import type { EChartsOption, SeriesOption } from 'echarts';
import { Component, Input, OnChanges, EventEmitter, Output, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { filterName } from '@features/eda/pipes/filter-name.pipe';
import { ColorsService } from '@shared/graphics/colors.service';
import { CardAction } from '../../events';
import { ParallelCoordinatesPlotCard, Variable } from 'src/generated-sources';
import { encodeHTML } from 'entities';
import { smarterNumber } from '@shared/pipes/number-pipes/smarter-number.pipe';

// Adapt the width depending on the number of lines to display.
// This may help when the dataset to display gets very large.
function getLineWidth(nLines: number): number {
    if (nLines <= 1000) {
        return 0.5;
    }

    if (nLines <= 10_000) {
        return 0.3;
    }

    return 0.1;
}

// Adapt the opacity depending on the number of lines to display.
function getLineOpacity(nLines: number, highlight: boolean): number {
    if (nLines <= 500) {
        return highlight ? 1 : 0.6;
    }

    if (nLines <= 1000) {
        return highlight ? 0.6 : 0.3;
    }

    return highlight ? 0.3 : 0.1;
}

function getLegendOpacity(highlight: boolean): number {
    return highlight ? 1 : 0.6;
}

function formatTooltipHtml(columns: Variable[], dataPoint: number[]): string {
    const lines = [];
    for (let i = 0; i < columns.length; i++) {
        const columnName = encodeHTML(columns[i].name);
        const value = encodeHTML(smarterNumber(dataPoint[i]));
        lines.push(`${columnName}: <b>${value}</b>`);
    }

    return lines.join("<br>");
}

@Component({
    selector: 'parallel-coordinates-plot-card-body',
    templateUrl: './parallel-coordinates-plot-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/chart.less',
        './parallel-coordinates-plot-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParallelCoordinatesPlotCardBodyComponent implements OnChanges {
    @Input() results: ParallelCoordinatesPlotCard.ParallelCoordinatesPlotCardResult;
    @Input() params: ParallelCoordinatesPlotCard;
    @Input() hasFixedHeight: boolean;
    @Output() action = new EventEmitter<CardAction>();

    chartOptions: EChartsOption;

    private readonly DEFAULT_COLOR = '#1E7EFA';
    private chart: any;

    constructor(private colorsService: ColorsService) {}

    setChartHandle(chart: any) {
        this.chart = chart;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results) {
            this.chartOptions = this.buildChartOptions();;

            /*
             * When the dataset is large the chart does not refresh correctly,
             * especially when the max number of points is set from a high
             * number to a low number - in such cases, a lot of lines still
             * persist on the chart post rendering.
             *
             * To cope with this, we grab a handle on the chart instance and
             * explicitely clear it before rendering.
             */
            if (this.chart) {
                this.chart.clear();
            }
        }
    };

    private buildChartOptions(): EChartsOption {
        const groupNames = this.results.groups.map(it => filterName(it));
        const isHighlightEnabled = this.results.parallelCoordinates.some(pc => pc.isHighlighted);
        const hasMultipleSubgroups = this.results.groups.length > 1;
        const showLegend = hasMultipleSubgroups || isHighlightEnabled;

        const groupColors: string[] = [];
        if (hasMultipleSubgroups) {
            groupNames.forEach(it => {
                const color = this.colorsService.getColorForVariable(it);
                groupColors.push(color);
            });
        } else {
            groupColors.push(this.DEFAULT_COLOR);
        }

        // get total number of lines to display
        const nLines = _.sumBy(this.results.parallelCoordinates, it => it.columnSeries[0].data.length);

        const legendData = this.results.parallelCoordinates.map(pc => {
            let name = groupNames[pc.groupIndex];
            if (pc.isHighlighted) {
                name += " (selection)";
            } else if (isHighlightEnabled) {
                name += " (other)";
            }

            const highlight = pc.isHighlighted || !isHighlightEnabled;

            return {
                name,
                itemStyle: {
                    opacity: getLegendOpacity(highlight),
                },
            };
        });

        const series: SeriesOption[] = [];
        this.results.parallelCoordinates.forEach((pc, i) => {
            if (pc.columnSeries.length !== this.params.columns.length) {
                throw new Error("Series length is different from the number of columns")
            }

            const flattenedSeries = pc.columnSeries.map(it => it.data);
            const zippedSeries = _.zip(...flattenedSeries) as number[][];
            const highlight = pc.isHighlighted || !isHighlightEnabled;

            series.push({
                name: legendData[i].name,
                type: 'parallel',
                data: zippedSeries,
                progressiveChunkMode: "mod",
                lineStyle: {
                    width: getLineWidth(nLines),
                    opacity: getLineOpacity(nLines, highlight),
                    color: groupColors[pc.groupIndex]
                },
            });
        });

        const maxValues: number[] = [];
        const minValues: number[] = [];
        for (let i = 0, nColumns = this.params.columns.length; i < nColumns; i++) {
            const columnSeries = _.flatMap(this.results.parallelCoordinates, it => it.columnSeries[i].data);
            const minValue = _.min(columnSeries) ?? 0;
            const maxValue = _.max(columnSeries) ?? 0;
            minValues.push(minValue);
            maxValues.push(maxValue);
        }

        const parallelAxis = this.params.columns.map((column, i) => {
            return {
                dim: i,
                name: column.name,
                max: maxValues[i],
                min: minValues[i],
                axisLabel: {
                    showMinLabel: false,
                    showMaxLabel: false,
                },
            };
        });

        const tooltipFormatter = (payload: any): string => {
            const dataPoint: number[] = payload.value;
            return formatTooltipHtml(this.params.columns, dataPoint);
        }

        return {
            animation: false,
            series,
            parallelAxis,
            legend: {
                show: showLegend,
                data: legendData,
                type: 'scroll',
                itemGap: 20,
                bottom: 0,
            },
            tooltip: {
                padding: 10,
                borderWidth: 1,
                formatter: tooltipFormatter,
            },
            parallel: {
                right: '10%',
                parallelAxisDefault: {
                    type: 'value',
                    nameGap: 20,
                    nameLocation: 'end',
                    realtime: false,
                    nameTextStyle: {
                        fontSize: 12
                    },
                }
            }
        };
    };
};
