import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { BivariateFrequencyTableCard } from 'src/generated-sources';
import { CardBodyRenderingMode } from '@features/eda/worksheet/cards/body/rendering-mode';
import { CardAction } from '../../events';

@Component({
    selector: 'bivariate-frequency-table-card-body',
    templateUrl: './bivariate-frequency-table-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/stats-table.less',
        '../../../../shared-styles/fit-table.less',
        './bivariate-frequency-table-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BivariateFrequencyTableCardBodyComponent {
    readonly CardBodyRenderingMode = CardBodyRenderingMode;

    @Input() params: BivariateFrequencyTableCard;
    @Input() results: BivariateFrequencyTableCard.BivariateFrequencyTableCardResult;
    @Input() renderingMode: CardBodyRenderingMode;
    @Output() action = new EventEmitter<CardAction>();

    trackByIndex(index: number) {
        return index;
    }
}
