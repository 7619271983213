import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { Insight } from '@model-main/dashboards/model/insight';
import { APIError } from '@core/dataiku-api/api-error';

@Directive({
  selector: '[articleInsightView]'
})
export class ArticleInsightViewDirective extends UpgradeComponent {
  @Input()
  insight?: Partial<Insight> | null;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onError = new EventEmitter<APIError>();

  constructor(elementRef: ElementRef, injector: Injector) {
    super('ng1ArticleInsightView', elementRef, injector);
  }
}
