import { Component, ChangeDetectionStrategy, TemplateRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'dss-reactive-input',
    templateUrl: './reactive-input.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi:true,
            useExisting: ReactiveInputComponent
        }
    ]
})
export class ReactiveInputComponent<T> implements ControlValueAccessor {
  @Input() inputTemplate: TemplateRef<any>;

  value: T;

  onValueChange = (value: T) => {
      this.markAsTouched();
      if (!this.disabled) {
          this.value = value;
          this.onChange(this.value);
      }
  };

  onChange = (value: T) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  writeValue(value: T) {
      this.value = value;
  }

  registerOnChange(onChange: any) {
      this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
      this.onTouched = onTouched;
  }

  markAsTouched() {
      if (!this.touched) {
          this.onTouched();
          this.touched = true;
      }
  }

  setDisabledState(disabled: boolean) {
      this.disabled = disabled;
  }
}
