import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges } from '@angular/core';

export type Color = 'default' | 'danger';

@Component({
    selector: 'right-panel-action',
    templateUrl: './right-panel-action.component.html',
    styleUrls: ['./right-panel-action.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightPanelActionComponent implements OnChanges {
    @Input() icon!: string;
    @Input() href?: string;
    @Input() disabled: boolean = false;
    @Input() tooltip?: string;
    @Input() disabledTooltip?: string;
    @Input() color: Color = 'default';
    @Output() clicked = new EventEmitter<void>();

    realTooltip: string = '';

    constructor() { }

    ngOnChanges(): void {
        this.realTooltip = (this.disabled ? this.disabledTooltip : this.tooltip) || '';
    }
}
