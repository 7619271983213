<drag-scroll [dragEnabled]="params.type === 'univariate_header' || params.type === 'bivariate_header'">
    <div [id]="params.id" class="card"
        [qa]="{'top-level-card': {id: params.id, type: params.type}}"
        *ngIf="cardCollapseState$ | async as cardCollapseState">
        <div class="card__inner vertical-flex" *ngIf="helpCollapseState$ | async as helpCollapseState">
            <div class="card__header horizontal-flex">
                <div class="card__header-content" cdkDragHandle>
                    <h4 class="card__header-text">
                        <span (click)="toggleCard(!cardCollapseState.collapsed)">
                        <i *ngIf="showCollapsingControls"
                            
                            class="icon-chevron-right chevron"
                            [class.chevron--collapsed]="cardCollapseState.collapsed"
                        ></i>
                        &nbsp;
                        {{ params | cardTitle: 'full' }}
                        </span>
                    </h4>
    
                    <div class="card__header-sub">
                        <span *ngIf="params.filter" class="filter__title" matTooltip="Filtered on {{ params.filter | filterName }}">
                            {{ params.filter | filterName }}
                        </span>

                        <split-by-settings
                            *ngIf="canUpdateSplitBySettings"
                            [readOnly]="readOnly || cardCollapseState.collapsed"
                            [splitBy]="splitBySettings"
                            [acceptsColoring]="acceptsColoring"
                            [allowGroupAll]="allowGroupWithAll"
                            (splitByChange)="updateSplitBySettings($event)"
                        ></split-by-settings>
                    </div>

                    <card-menu class="pull-right"
                        [params]="params" [readOnly]="readOnly" [deletable]="true"
                        [extendedActions]="extendedActions" (action)="handleAction($event)"
                    ></card-menu>

                    <button *ngIf="showCollapsingControls && !cardCollapseState.collapsed && (helpIsAvailable$ | async)"
                        class="btn btn--icon btn--secondary help-button pull-right"
                        [qa]="'eda-card-help-toggle'"
                        [class.help-button--closed]="helpCollapseState.collapsed"
                        (click)="toggleHelp(!helpCollapseState.collapsed)"
                        [matTooltip]="helpCollapseState.collapsed ? 'Show help': 'Hide help'"
                    >
                        <i class="icon-dku-help"></i>
                    </button>
    
                    <div class="card__header-subtitle" *ngIf="subtitle">
                        {{ subtitle }}
                    </div>
                </div>
            </div>
            <div [@fadeInOut]="cardCollapseState.collapsed ? 'closed' : 'opened'">
                <ng-container *ngIf="!cardCollapseState.collapsed | delayWhenFalse">
                    <ng-container *ngIf="helpIsAvailable$ | async">
                        <div [@fadeInOut]="helpCollapseState.collapsed ? 'closed' : 'opened'">
                            <div class="card__help">
                                <card-help
                                    [params]="params"
                                    *ngIf="!helpCollapseState.collapsed | delayWhenFalse"
                                    [readOnly]="readOnly"
                                    (action)="handleAction($event)"
                                    [extendedActions]="extendedActions">
                                </card-help>
                            </div>
                        </div>
                    </ng-container>
    
                    <div class="card__body"
                        [ngClass]="{'card__body--header': params.type === 'univariate_header' || params.type === 'bivariate_header'}">
                        <card-body
                            [params]="params"
                            [results]="results"
                            [renderingMode]="CardBodyRenderingMode.TOP_LEVEL_CARD"
                            [readOnly]="readOnly"
                            [extendedActions]="extendedActions"
                            [hasFixedHeight]="hasFixedHeight"
                            (action)="handleAction($event)">
                        </card-body>
                    </div>
                </ng-container>
            </div>
    
        </div>
    </div>                
</drag-scroll>
