<form [formGroup]="configForm" id="config-form">
    <div cdkDropListGroup class="card-header-editor horizontal-flex">
        <div class="card-header-editor__column">
            <span class="card-header-editor__description">1. Select variables</span>
            <div class="card-header-editor__header">
                <h4 class="card-header-editor__title">
                    {{ count }} available variables
                </h4>
            </div>
            <list-box [list]="(currentVariables$ | async) ?? []" [countTitle]="'available variables'"
                (select)="selectedVariables = $event" class="vertical-flex card-header-editor__list"
                (dropped)="onDropVariables($event)"
                [dragDropable]="true"
                [sortDraggable]="true"
                [dragDropable]="true"
            ></list-box>
            <div class="card-header-editor__footnote">
                <i class="icon-lightbulb"></i> Use <strong>Shift + Click</strong> to select a range of variables.
            </div>
        </div>
        <div class="card-header-editor__column">
            <span class="card-header-editor__description">2. Select their use</span>
            <div class="card-header-editor__header">
                <h4 class="card-header-editor__title"
                    [matTooltip]="tooManySelectedVariablesWarning">
                    {{ configForm.value.xColumns.length }} selected factor(s) <i *ngIf="tooManySelectedVariablesWarning" class="icon-warning-sign text-error"></i>
                </h4>
                <div>
                    <button class="btn btn--text btn--primary btn--icon" (click)="add('xColumns')" [disabled]="!canAddX"
                        [qa]="{'eda-add-variables-button': {as: 'factor'}}"
                        [matTooltip]="disabledReasonX">
                        <i class="icon-plus"></i>
                    </button>
                    <button class="btn btn--text btn--primary btn--icon" (click)="del('xColumns')"
                        [disabled]="selectedFactors.length < 1"
                        [qa]="{'eda-del-factors-button': {as: 'factor'}}">
                        <i class="icon-trash"></i>
                    </button>
                </div>
            </div>
            <list-box-form-control formControlName="xColumns"
                class="vertical-flex card-header-editor__list card-header-editor__list--multi"
                [dragDropable]="true"
                [sortDraggable]="true"
                [multiselectable]="true"
                placeHolderText="(Drag factor(s) here)"
                (dropped)="onDropFactors($event)"
                (select)="selectedFactors = $event"
            ></list-box-form-control>
            <div class="card-header-editor__header">
                <h4 class="card-header-editor__title">
                    Response
                </h4>
                <div [matTooltip]="disabledReasonY">
                    <button class="btn btn--text btn--primary btn--icon" (click)="add('yColumn')" [disabled]="!canAddY"
                        [qa]="{'eda-add-variables-button': {as: 'response'}}">
                        <i class="icon-plus"></i>
                    </button>
                </div>
            </div>
            <list-box-form-control formControlName="yColumn"
                class="vertical-flex card-header-editor__list card-header-editor__list--single"
                [dragDropable]="true"
                [hideItemsOnDrop]="true"
                placeHolderText="(Drag response here)"
                (dropped)="onDropResponse($event)"
            ></list-box-form-control>
        </div>
        <div class="card-header-editor__column">
            <span class="card-header-editor__description">3. Select options</span>
            <div class="card-header-editor__header">
                <h4 class="card-header-editor__title">Options</h4>
            </div>
            <div class="card-header-editor__options">
                <div class="control-group" *ngFor="let option of options" [ngClass]="{'card-option--disabled': !option.enabled}">
                    <label class="control-label control-label--flex">
                        <!-- show fake checkbox when disabled so that it's immediately checked when activated for the first time -->
                        <input type="checkbox" class="control" *ngIf="!option.enabled" disabled>
                        <input type="checkbox" class="control" formControlName="{{ option.id }}" *ngIf="option.enabled">
                        {{ option.name }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</form>
