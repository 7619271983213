import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Observable, Subscriber, interval, from } from 'rxjs';
import { publish, refCount, switchMapTo, filter, distinctUntilChanged } from 'rxjs/operators';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';


/**
 * Reading/writing the size of a lot of elements can trigger a lot of forced browser reflows
 *
 * In order to alleviate this, we carefully control the order of operations with a master clock
 * - TICK: read height of all elements
 * - TOCK: write new height of all elements if it has changed
 */
const TICK = 1;
const TOCK = 2;
const MASTER_CLOCK_INTERVAL = 300;

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class HeightService implements OnDestroy {
    items: Map<string, Map<Subscriber<number | undefined>, number | undefined>> = new Map();
    readClock$: Observable<number>;

    constructor(private ngZone: NgZone) {
        this.ngZone.runOutsideAngular(() => {
            const masterClock$ = interval(MASTER_CLOCK_INTERVAL)
                .pipe(switchMapTo(from([TICK, TOCK])), publish(), refCount());

            this.readClock$ = masterClock$.pipe(filter(type => type === TICK), publish(), refCount());
            masterClock$.pipe(filter(type => type === TOCK)).pipe(untilDestroyed(this)).subscribe(() => {
                this.items.forEach(map => {
                    // Compute max height
                    let maxHeight: number | undefined;
                    map.forEach(height => {
                        if (maxHeight !== undefined && height !== undefined) {
                            maxHeight = Math.max(maxHeight, height);
                        } else {
                            maxHeight = height;
                        }
                    });
                    // Broadcast max height
                    if (maxHeight !== undefined) {
                        map.forEach((_, observer) => observer.next(maxHeight));
                    }
                });
            });
        });
    }

    registerHeight(key: string, heightReader: () => number | undefined): Observable<number> {
        return this.ngZone.runOutsideAngular(() => {
            return new Observable<number>(observer => {
                const subscription = this.readClock$.subscribe(() => {
                    if (!this.items.has(key)) {
                        this.items.set(key, new Map());
                    }
                    this.items.get(key)!.set(observer, heightReader());
                });

                return () => {
                    subscription.unsubscribe();

                    if (this.items.has(key)) {
                        this.items.get(key)!.delete(observer);
                        if (!this.items.get(key)!.size) {
                            this.items.delete(key);
                        }
                    }
                };
            }).pipe(distinctUntilChanged());
        });
    }

    ngOnDestroy() { }
}
