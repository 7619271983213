import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'backendTypeToIcon'
})
export class BackendTypeToIconPipe implements PipeTransform {

    transform(backendType: string, _args?: string): any {
        if (!backendType) {
            return;
        }

        return 'icon-ml icon-ml-' + backendType.toLowerCase();
    }

}
