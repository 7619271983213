import { Component, ChangeDetectionStrategy, OnChanges, Input, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Workspace } from '@model-main/workspaces/workspace';
import { ITaggingService } from '@model-main/server/services/itagging-service';
import { WorkspaceObjectType, WorkspaceDisplayService, WorkspaceNonTaggableType, WorkspacesService } from '../../../../shared';
import { BehaviorSubject, filter, map, Observable, of, startWith, switchMap } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

enum View {
    Favicon = 'favicon',
    FaviconDescription = 'favicon-description',
    Image = 'image',
    ImageDescription = 'image-description',
    IconDescription = 'icon-description',
    DatasetIcon = 'dataset-icon',
    Icon = 'icon',
    Url = 'url'
}

function isNonNull<T>(value: T): value is NonNullable<T> {
    return value != null;
}

@UntilDestroy()
@Component({
    selector: 'workspace-object-thumbnail',
    templateUrl: './workspace-object-thumbnail.component.html',
    styleUrls: ['./workspace-object-thumbnail.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceObjectThumbnailComponent implements OnChanges {
    @Input() object: Workspace.WorkspaceObject | null;
    @Input() workspaceKey: string;

    readonly WorkspaceNonTaggableType = WorkspaceNonTaggableType;
    readonly View = View;
    readonly object$ = new BehaviorSubject<Workspace.WorkspaceObject | null>(null);
    readonly objectDetails$: Observable<{ view: View, type: WorkspaceObjectType, safeImage?: SafeUrl}> = this.object$.pipe(
        filter(isNonNull),
        switchMap(object => {
            const type = this.workspaceDisplayService.getObjectType(object);
            const view = this.getViewType(object, type);
            if (view === View.Image || view === View.ImageDescription) {
                const url = object.appId ? this.workspaceDisplayService.getAppImageURL(object.appId) : object.thumbnailData;
                return of({ type, view, safeImage: this.sanitizer.bypassSecurityTrustUrl(url)});
            } else if (view === View.Url) {
                return this.workspaceService.fetchThumbnail(this.workspaceKey, object.id).pipe(
                    map(url => ({ view: url ? View.Image : View.IconDescription, safeImage: this.sanitizer.bypassSecurityTrustUrl(url), type })),
                    startWith({ view: View.IconDescription, type })
                );
            }
            return of({type, view});
        })
    );

    constructor(
        private sanitizer: DomSanitizer,
        private workspaceDisplayService: WorkspaceDisplayService,
        private workspaceService: WorkspacesService
    ) { }

    ngOnChanges({ object }: SimpleChanges): void {
        if (object) {
            this.object$.next(this.object);
        }
    }

    getViewType(object: Workspace.WorkspaceObject, type: WorkspaceObjectType): View {
        if (type === WorkspaceNonTaggableType.STORY) {
            return View.Url;
        }
        if (type === WorkspaceNonTaggableType.LINK) {
            if (object.shortDesc) {
                return View.FaviconDescription;
            } else {
                return View.Favicon;
            }
        } else if (object.thumbnailData || object.appId) {
            if (object.appId && (object.shortDesc || object.description)) {
                return View.ImageDescription;
            }
            return View.Image;
        } else if (object.shortDesc || object.description) {
            return View.IconDescription;
        } else if (type === ITaggingService.TaggableType.DATASET) {
            return View.DatasetIcon;
        }
        return View.Icon;
    }
}
