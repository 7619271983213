import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output, forwardRef, ChangeDetectorRef } from '@angular/core';
import { realAny } from 'dku-frontend-core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'dku-bs-select-form-control',
  templateUrl: './dku-bs-select-form-control.component.html',
  styleUrls: ['./dku-bs-select-form-control.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DkuBsSelectFormControlComponent),
        multi: true
        }
    ]
})
export class DkuBsSelectFormControlComponent implements OnInit, ControlValueAccessor {
    @Input() params?: object;
    @Input() list: realAny[];
    @Input() optionsDescriptions?: realAny[];
    @Input() ngOptions: string;
    @Input() model: realAny;
    @Input() required?: boolean;
    @Input() layout?: string;
    @Input() dataActionsBox?: boolean;
    @Input() dataLiveSearch?: boolean;
    @Input() dkuMultiple?: boolean;
    // Allow to have 2 way data-binding in templates using the `[(model)]` syntax
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(private changeDetectionRef: ChangeDetectorRef) {

    }

    propagateChange = (_: any) => {};

    ngOnInit() {
    }

    select(selected: any) {
        this.propagateChange(selected);
    }

    writeValue(value: any) {
        this.model = value;
        this.changeDetectionRef.markForCheck();
    }

    registerOnChange(fn: (_: any) => {}) {
        this.propagateChange = fn;
    }

    registerOnTouched() {}
}
