<lazy-render
    [trackData]="options"
    [visibleTemplate]="visible"
    [passThrough]="passThrough"
    [invisibleTemplate]="invisible"
>
    <ng-template #visible>
        <div
            echarts
            (chartClick)="chartClick.emit($event)"
            (chartDataZoom)="chartDataZoom.emit($event)"
            (chartInit)="chartInit.emit($event)"
            *ngIf="options"
            [options]="options"
        ></div>
    </ng-template>
    <ng-template #invisible>
        <ng-container *ngIf="!noLoading">
            <div *ngIf="!compactLoading">
                Loading chart...
            </div>
            <div *ngIf="compactLoading" class="loading-icon-wrapper">
                <i class="icon-spinner"></i>
            </div>
        </ng-container>
    </ng-template>
</lazy-render>
