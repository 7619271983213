import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { realAny } from 'dku-frontend-core';

@Component({
  selector: 'ack-modal',
  templateUrl: './ack-modal.component.html',
  styleUrls: ['./ack-modal.component.less']
})
export class AckModalComponent {

    constructor(
        public dialogRef: MatDialogRef<realAny, boolean>,
        @Inject(MAT_DIALOG_DATA) public data: {
            title: string,
            message: string,
            icon?: string
        }
    ) { }

    ack() {
        this.dialogRef.close(); //WARNING: You are not allowed to modify this class and use a value for resolution
    }
}
