import type {Aggregation} from './../../backend/model/aggregation';
import type {ChartDef} from './chart-def';
import type {DisplayAxis} from './display-axis';
import type {DisplayType} from './display-type';
import type {LabelPosition} from './label-position';
import type {Multiplier} from './multiplier';

/**
 * Generated from com.dataiku.dip.pivot.frontend.model.MeasureDef
 */
export interface MeasureDef {
    colorRules: MeasureDef.ColorRule[];
    column: string;
    computeMode: Aggregation.ComputeMode;
    computeModeDim: number;
    decimalPlaces?: number | null;
    displayAxis: DisplayAxis;
    displayLabel?: string | null;
    displayType: DisplayType;
    displayed: boolean;
    function: Aggregation.Function;
    isA: string;
    labelPosition?: LabelPosition | null;
    multiplier: Multiplier;
    prefix: string;
    showDisplayLabel?: boolean | null;
    suffix: string;
    type: string;
}

export namespace MeasureDef {
    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.MeasureDef$ColorRule
     */
    export interface ColorRule {
        colorOptions: ChartDef.ColorOptions;
        mode: MeasureDef.ColorRule.Mode;
        styleClass: string;
        value: number;
    }
}

export namespace MeasureDef.ColorRule {
    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.MeasureDef$ColorRule$Mode
     */
    export enum Mode {
        LOWER_THAN = 'LOWER_THAN',
        LOWER_OR_EQUAL_THAN = 'LOWER_OR_EQUAL_THAN',
        GREATER_THAN = 'GREATER_THAN',
        GREATER_OR_EQUAL_THAN = 'GREATER_OR_EQUAL_THAN'
    }
}