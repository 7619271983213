import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'right-panel-accordion-section',
  templateUrl: './right-panel-accordion-section.component.html',
  styleUrls: ['./right-panel-accordion-section.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightPanelAccordionSectionComponent implements OnChanges {
  internalOpenedState = false;
  @Input() opened = false;
  @Output() openedChanged = new EventEmitter<boolean>();
  @Input() showTopBorder = true;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes.opened) {
        this.internalOpenedState = changes.opened.currentValue;
      }
  }

  toggleOpened() {
    const newValue = !this.internalOpenedState;
    this.internalOpenedState = newValue;
    this.openedChanged.emit(newValue);
  }
}
