export enum UnitSymbol {
    K = 'k',
    M = 'M',
    B = 'B',
    G = 'G',
    T = 'T',
    P = 'P',
    E = 'E',
    Z = 'Z',
    Y = 'Y'
}
