<ng-container *ngIf="labelingService.labelingTaskInfo$ | async as task">
    <ng-container *ngIf="labelingReviewUpdateService.reviewStatus$ | async as reviewStatus">
        <ng-container *ngIf="annotations">
            <review-no-annotations-placeholder *ngIf="annotations.length === 0"  [taskType]="task.type" [rejected]="reviewStatus === ReviewStatus.REJECTED"></review-no-annotations-placeholder>
            <ng-container *ngFor="let annotation of annotations; let index = index">
                <div class="labeling-annotation" [id]="ANNOTATION_ID_PREFIX+index" [ngClass]="{
                    'selected': annotation.selected,
                    'labeling-annotation--is-selectable': annotation.isSelectable,
                    'labeling-annotation__info--big': task.type === LabelingTaskType.NAMED_ENTITY_EXTRACTION &&  reviewMode === ReviewMode.UPDATE }"
                    (click)="annotation.isSelectable && selectAnnotation(index)">
                    <div *ngIf="task.type === LabelingTaskType.OBJECT_DETECTION && (annotation | instanceOf: UIBoundingBox)" class="thumb-container">
                        <bbox-thumb [bboxHeight]="annotation.height" [bboxWidth]="annotation.width" [color]="color(annotation)"></bbox-thumb>
                    </div>
                    <div
                        *ngIf="task.type === LabelingTaskType.IMAGE_CLASSIFICATION && (annotation | instanceOf: UIClassificationAnnotation)"
                        [ngStyle]="{
                            'backgroundColor': labelingColorService.categoryToColorString(annotation.category, 0.5),
                            'borderColor': labelingColorService.categoryToColorString(annotation.category, 0.5)
                        }"
                        class="color-box">
                    </div>

                    <div class="labeling-annotation__info mright8 minw0">
                        <ng-container *ngIf="reviewMode === ReviewMode.INITIAL">
                            <div *ngIf="task.type === LabelingTaskType.NAMED_ENTITY_EXTRACTION" class="horizontal-flex">
                                <ng-container *ngIf="labelingService.allUsersByLogin$ | async as users">
                                    <user-picture class="labeling-annotation__annotator-picture" *ngIf="annotation.annotator" [user]="annotation.annotator" [size]="16" [borderColor]="labelingColorService.getAnnotatorColorString(annotation.annotator)" [matTooltip]="annotation.annotator | formattedAnnotatorName: users"></user-picture>
                                </ng-container>
                                <div *ngIf="annotation.annotator" class="vertical-flex minw0">
                                    <ng-container *ngIf="annotation | instanceOf: UINamedEntity">
                                        <named-entity-thumb [text]="annotation.text" [maxWordLength]="12"></named-entity-thumb>
                                    </ng-container>
                                    <div class="mx-textellipsis" [matTooltip]="annotation.category || ''" isEllipsisActive>{{annotation.category}}</div>
                                </div>
                            </div>
                            <ng-container *ngIf="task.type === LabelingTaskType.OBJECT_DETECTION || task.type === LabelingTaskType.IMAGE_CLASSIFICATION">
                                <ng-container *ngIf="labelingService.allUsersByLogin$ | async as users">
                                    <div *ngIf="annotation.annotator" class="mx-textellipsis" title="{{annotation.category}}">{{annotation.category}}</div>
                                    <div class="text-weak mx-textellipsis" *ngIf="annotation.annotator">{{ annotation.annotator | formattedAnnotatorName: users }}</div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!annotation.annotator || reviewMode === ReviewMode.UPDATE">
                            <ng-container *ngIf="task.type === LabelingTaskType.NAMED_ENTITY_EXTRACTION">
                                <ng-container *ngIf="annotation | instanceOf: UINamedEntity">
                                    <named-entity-thumb *ngIf="reviewMode === ReviewMode.UPDATE" [text]="annotation.text" [maxWordLength]="12" [color]="labelingColorService.getColorForText(annotation)" class="mbot8"></named-entity-thumb>
                                    <named-entity-thumb *ngIf="annotation.annotator" [text]="annotation.text" [maxWordLength]="12"></named-entity-thumb>
                                </ng-container>
                            </ng-container>
                            <mat-form-field (click)="$event.stopPropagation()" class="category-select">
                                <mat-select matInput type="text" name="category" [(ngModel)]="annotation.category" [placeholder]="'Assign a ' + (task.type | categoryLabel)" (selectionChange)="emitNewAnnotations()">
                                    <mat-option *ngFor="let class of classes" [value]="class"> {{class}} </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                    </div>
                    <div *ngIf="canResolve(annotations.length)" class="accept-answer-wrapper icon-wrapper" [matTooltip]="'Accept this annotation' + (multiAccept ? '' : ' and delete other annotations')" [matTooltipPosition]="'above'">
                        <i [class]="'dku-icon-' + (this.multiAccept ? 'checkmark' : 'trophy') + '-16'" class="pad4" (click)="resolveAnnotation(annotation, task); $event.stopPropagation()"></i>
                    </div>
                    <div *ngIf="canDelete(annotation, task.type)" class="delete-box-wrapper icon-wrapper mleft16">
                        <i class="dku-icon-trash-16 pad4" (click)="deleteAnnotation(index, task.type)"></i>
                    </div>
                </div>
            </ng-container>
        </ng-container>

    </ng-container>

</ng-container>
