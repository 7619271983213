import type {Distribution, _Distribution} from './distribution';

/**
 * Generated from com.dataiku.dip.eda.compute.distributions.Beta
 */
export interface Beta extends _Distribution {
    // PolyJson type
    type: 'beta';
}

export namespace Beta {
    export const type = 'beta';
}

/** @deprecated Use Distribution.isBeta() */ export function isBeta(toTest: Distribution): toTest is Beta { return Beta.type === toTest['type']; }

export namespace Beta {
    /**
     * Generated from com.dataiku.dip.eda.compute.distributions.Beta$FittedBeta
     */
    export interface FittedBeta extends Distribution._FittedDistribution {
        alpha: number;
        beta: number;
        lower: number;
        upper: number;

        // PolyJson type
        type: 'beta';
    }

    export namespace FittedBeta {
        export const type = 'beta';
    }

    /** @deprecated Use Distribution.FittedDistribution.isFittedBeta() */ export function isFittedBeta(toTest: Distribution.FittedDistribution): toTest is FittedBeta { return FittedBeta.type === toTest['type']; }
}