<div class="w100 h100">
    <div class="w100">
        <api-error-alert
            [apiError]="error$ | async"
            (resetError)="resetError()"
            [closable]="true"
        ></api-error-alert>
    </div>
    <div class="deephub-data-augmentation-page" *ngIf="augmentationParams$ | async as augmentationParams">
        <div [formGroup]="form" class="deephub-data-augmentation-page__params">
            <div formGroupName="colorJitter" class="deephub-data-augmentation-page__section">
                <div class="deephub-data-augmentation-page__section-params">
                    <h2 class="mtop0 settings-section-title">
                        Color
                    </h2>
                    <div class="control-group">
                        <label class="dku-toggle mright4 mleft0">
                            <input type="checkbox" formControlName="enabled">
                            <span></span>
                        </label>
                        Color
                    </div>
                </div>
                <deephub-design-data-augmentation-augmented-image
                    class="deephub-data-augmentation-page__section-image"
                    *ngIf="imagePath$ | async as imagePath"
                    [augmentationTypeInfo]="getAugmentationTypeInfo(AugmentationType.COLOR)"
                    [applyMaxTransform]="true"
                    [legend]= "'Example of maximum color shift'"
                    [numAugmentedVersions]="1"
                    [augmentationParams]="augmentationParams"
                    [imagePath]="imagePath"
                    [imageHeight]="150"
                    [managedFolderSmartId]="managedFolderSmartId">
                </deephub-design-data-augmentation-augmented-image>
            </div>
            <div formGroupName="affine" class="deephub-data-augmentation-page__section">
                <div class="deephub-data-augmentation-page__section-params">
                    <h2 class="mtop0 settings-section-title">
                        Affine
                    </h2>
                    <div class="control-group" formGroupName="horizontalFlip">
                        <label class="dku-toggle mright4 mleft0">
                            <input type="checkbox" formControlName="enabled">
                            <span></span>
                        </label>
                        Horizontal flip
                    </div>
                    <div class="control-group" formGroupName="verticalFlip">
                        <label class="dku-toggle mright4 mleft0">
                            <input type="checkbox" formControlName="enabled">
                            <span></span>
                        </label>
                        Vertical flip
                    </div>
                    <div formGroupName="rotate">
                        <div class="control-group">
                            <label class="dku-toggle mright4 mleft0">
                                <input type="checkbox" formControlName="enabled">
                                <span></span>
                            </label>
                            Rotate
                        </div>
                        <div class="control-group control-group--with-text-input">
                            <label class="control-label">Max. rotation</label>
                            <input type="number" min="0." max="360" step="1"  formControlName="maxRotation"/>
                        </div>
                    </div>
                </div>
                <deephub-design-data-augmentation-augmented-image
                    class="deephub-data-augmentation-page__section-image"
                    *ngIf="imagePath$ | async as imagePath"
                    [augmentationTypeInfo]="getAugmentationTypeInfo(AugmentationType.AFFINE)"
                    [applyMaxTransform]="true"
                    [legend]="'Example of maximum distortion'"
                    [numAugmentedVersions]="1"
                    [augmentationParams]="augmentationParams"
                    [imagePath]="imagePath"
                    [imageHeight]="150"
                    [managedFolderSmartId]="managedFolderSmartId">
                </deephub-design-data-augmentation-augmented-image>
            </div>
            <div formGroupName="crop" class="deephub-data-augmentation-page__section">
                <div class="deephub-data-augmentation-page__section-params">
                    <h2 class="mtop0 settings-section-title">
                        Crop
                    </h2>
                    <div class="control-group">
                        <label class="dku-toggle mright4 mleft0">
                            <input type="checkbox" formControlName="enabled">
                            <span></span>
                        </label>
                        Crop
                    </div>
                    <div class="control-group control-group--with-text-input">
                        <label class="control-label">Min. kept ratio</label>
                        <input type="number" min="0.01" max="1" step="0.01" formControlName="minKeptRatio"/>
                    </div>
                </div>
                <deephub-design-data-augmentation-augmented-image
                    class="deephub-data-augmentation-page__section-image"
                    *ngIf="imagePath$ | async as imagePath"
                    [augmentationTypeInfo]="getAugmentationTypeInfo(AugmentationType.CROP)"
                    [applyMaxTransform]="true"
                    [legend]="'Example of maximum crop'"
                    [numAugmentedVersions]="1"
                    [augmentationParams]="augmentationParams"
                    [imagePath]="imagePath"
                    [imageHeight]="150"
                    [managedFolderSmartId]="managedFolderSmartId">
                </deephub-design-data-augmentation-augmented-image>
            </div>
        </div>
        <div class="deephub-data-augmentation-page__images" *ngIf="imagePath$ | async as imagePath">
            <div>
                <div class="mtop0 settings-section-title">
                    <span class="dibvam mright16">
                        Original image
                    </span>
                    <button class="btn btn--text btn--primary" (click)="getNewImagePath()">
                        Change image
                    </button>
                </div>
                <img class="original-image" [src]="getImagePath(imagePath)">
            </div>
            <div>
                <div class="settings-section-title">
                    <span class="dibvam">
                        Augmentation examples
                    </span>
                </div>

                <div class="placeholder-block" *ngIf="augmentationParamsDisabled$ | async">
                    All augmentation params disabled.
                </div>

                <deephub-design-data-augmentation-augmented-image
                    *ngIf="! (augmentationParamsDisabled$ | async)"
                    [numAugmentedVersions]="6"
                    [augmentationParams]="augmentationParams"
                    [imagePath]="imagePath"
                    [imageHeight]="150"
                    [managedFolderSmartId]="managedFolderSmartId">
                </deephub-design-data-augmentation-augmented-image>
            </div>
        </div>
    </div>
</div>