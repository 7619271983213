import { AnyLoc } from 'src/generated-sources';


export function resolveSmartName(contextProjectKey: string, datasetSmartName: string): AnyLoc {
    const chunks = datasetSmartName.split('.');
    if (chunks.length === 1) {
        return { projectKey: contextProjectKey, id: chunks[0] };
    } else if (chunks.length === 2) {
        return { projectKey: chunks[0], id: chunks[1] };
    }

    throw new Error('Invalid smart name: ' + datasetSmartName);
}

// A.B (A) -> B
// A.B (C) -> A.B
// B (A) -> A.B
export function normalizeSmartName(contextProjectKey: string, datasetSmartName: string): string {
    const loc = resolveSmartName(contextProjectKey, datasetSmartName);
    return toSmartName(contextProjectKey, loc);
}

function toSmartName(contextProjectKey: string, datasetLoc: AnyLoc): string {
    if (contextProjectKey === datasetLoc.projectKey) {
        return datasetLoc.id;
    } else {
        return `${datasetLoc.projectKey}.${datasetLoc.id}`;
    }
}
