<data-catalog-nav></data-catalog-nav>
<div class="dss-page data-catalog-home-page__content" *ngIf="dataCollections$ | async as dataCollections">

    <div *ngIf="dataCollections.length === 0"
        class="data-collections__empty-container"
    >
        <img src="/static/dataiku/images/data-catalog/data-collection-icon.svg">
        <h2 class="dku-large-title">No Data Collections yet</h2>
        <p *ngIf="canCreateDataCollections">Create data collections to organize key datasets for easier data discovery (by team, use case, etc.)</p>
        <p *ngIf="!canCreateDataCollections">Users with permission can create data collections to organize key datasets (by team, use case, etc.)</p>
        <button
            *ngIf="canCreateDataCollections"
            class="btn btn--primary btn--dku-icon mtop16 mbot16"
            (click)="createDataCollection()"
        >
            <i class="dku-icon-plus-16"></i>
            New collection
        </button>
    </div>

    <ng-container *ngIf="dataCollections.length > 0">
        <data-collections-top-bar
            class="data-collections__top-bar"
            [availableTags]="(tagsWithCount$ | async) || []"
            [canCreateDataCollections]="canCreateDataCollections"
            (newCollection)="createDataCollection()"
            [(filters)]="filter"
        ></data-collections-top-bar>

        <ng-container *ngIf="filteredDataCollections$ | async as filteredDataCollections">
            <div class="data-collections__no-result"
                *ngIf="filteredDataCollections.length === 0"
            >
                <div>No Data Collections match your search</div>
                <button class="btn btn--primary" (click)="resetFilters()">
                    Reset filters
                </button>
            </div>

            <card-virtual-scroll
                *ngIf="filteredDataCollections.length > 0"
                class="data-collections__cards-container"
                [items]="filteredDataCollections"
                [itemHeight]="240" [itemsPerRow]="itemsPerRow" (widthChange)="recomputeItemsPerRow($event)">
                <ng-template let-collection="item">
                    <data-collection-card
                        class="tile-set"
                        [dataCollection]="collection"
                        (click)="openCollectionPage(collection.id)"
                    >
                    </data-collection-card>
                </ng-template>
            </card-virtual-scroll>
        </ng-container>

    </ng-container>

</div>
