import type {CausalPredictionPreprocessingParams} from './causal-prediction-preprocessing-params';
import type {ClassicalPredictionPreprocessingParams} from './classical-prediction-preprocessing-params';
import type {PredictionPreprocessingParams} from './prediction-preprocessing-params';
import type {TimeseriesForecastingPreprocessingParams} from './timeseries-forecasting-preprocessing-params';

/**
 * Generated from com.dataiku.dip.analysis.model.preprocessing.TabularPredictionPreprocessingParams
 */
export interface _TabularPredictionPreprocessingParams extends PredictionPreprocessingParams {
    feature_selection_params: TabularPredictionPreprocessingParams.FeatureSelectionParams;
    preprocessingFitSampleRatio: number;
    preprocessingFitSampleSeed: number;
}

export type TabularPredictionPreprocessingParams = CausalPredictionPreprocessingParams | ClassicalPredictionPreprocessingParams | TimeseriesForecastingPreprocessingParams;

export namespace TabularPredictionPreprocessingParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.TabularPredictionPreprocessingParams$FeatureSelectionMethod
     */
    export enum FeatureSelectionMethod {
        NONE = 'NONE',
        RANDOM_FOREST = 'RANDOM_FOREST',
        LASSO = 'LASSO',
        CORRELATION = 'CORRELATION',
        PCA = 'PCA',
        CUSTOM = 'CUSTOM'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.TabularPredictionPreprocessingParams$FeatureSelectionParams
     */
    export interface FeatureSelectionParams {
        correlation_params: TabularPredictionPreprocessingParams.FeatureSelectionParams.CorrelationParams;
        custom_params: TabularPredictionPreprocessingParams.FeatureSelectionParams.CustomParams;
        lasso_params: TabularPredictionPreprocessingParams.FeatureSelectionParams.LassoParams;
        method: TabularPredictionPreprocessingParams.FeatureSelectionMethod;
        pca_params: TabularPredictionPreprocessingParams.FeatureSelectionParams.PCAParams;
        random_forest_params: TabularPredictionPreprocessingParams.FeatureSelectionParams.RandomForestParams;
    }
}

export namespace TabularPredictionPreprocessingParams.FeatureSelectionParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.TabularPredictionPreprocessingParams$FeatureSelectionParams$CustomParams
     */
    export interface CustomParams {
        code: string;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.TabularPredictionPreprocessingParams$FeatureSelectionParams$CorrelationParams
     */
    export interface CorrelationParams {
        max_abs_correlation: number;
        min_abs_correlation: number;
        n_features: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.TabularPredictionPreprocessingParams$FeatureSelectionParams$RandomForestParams
     */
    export interface RandomForestParams {
        depth: number;
        n_features: number;
        n_trees: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.TabularPredictionPreprocessingParams$FeatureSelectionParams$LassoParams
     */
    export interface LassoParams {
        alpha: number[];
        cross_validate: boolean;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.TabularPredictionPreprocessingParams$FeatureSelectionParams$PCAParams
     */
    export interface PCAParams {
        n_features: number;
        variance_proportion: number;
    }
}