import { Injectable } from '@angular/core';
import { Experiment } from '@model-main/experimenttracking/experiment';
import { DkuHttpService } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { Run } from 'src/generated-sources';

@Injectable({ providedIn: 'root' })
export class PrivateManagedFoldersAPI {
    constructor(
        private dkuHttp: DkuHttpService
    ) { }

    get(contextProjectKey: string, projectKey: string, id: string): Observable<any> {
        return this.dkuHttp.request('GET', '/managedfolder/get', { contextProjectKey, projectKey, id });
    }

    getFullInfo(contextProjectKey: string, projectKey: string, id: string): Observable<any> {
        return this.dkuHttp.request('GET', '/managedfolder/get-full-info', { contextProjectKey, projectKey, id });
    }

    list(projectKey: string): Observable<any[]> {
        return this.dkuHttp.request('GET', '/managedfolder/list', { projectKey });
    }
}
