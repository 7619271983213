import { Injectable } from '@angular/core';
import { hsl } from 'd3-color';
import { Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { category20 } from '../../utils/color-constants';

export interface QaSelectorProperties {
    [property: string]: string | number | boolean | null | undefined;
}

export interface QaSelectors {
    [key: string]: QaSelectorProperties;
}

export function getAttributeName(key: string, property?: string) {
    return 'data-qa-' + key + (property ? '--' + property : '');
}

export function getCssSelector(key: string, property?: string, value?: string | null) {
    let escapedValue = '';
    if (value) {
        escapedValue = '=\'' + (value || '').replace('\'', '\\') + '\'';
    }
    return '[' + getAttributeName(key, property) + escapedValue + ']';
}

@Injectable({
    providedIn: 'root'
})
export class QaSelectorService {
    private enabled$ = new BehaviorSubject(false);
    private colorMap: { [key: string]: string } = {};
    private colorGenerator = function* colorGenerator() {
        while (true) {
            for (const color of category20.range()) {
                yield hsl(color).darker().toString();
            }
        }
    }();

    constructor() {
        // Make toggleQaSelectors() globally available
        (window as any).toggleQaSelectors = (flag?: boolean) => this.enabled$.next(flag ?? !this.enabled$.value);
    }

    public getColorForSelector(key: string) {
        if (!(key in this.colorMap)) {
            this.colorMap[key] = this.colorGenerator.next().value as string;
        }
        return this.colorMap[key];
    }

    public get isOverlayEnabled(): Observable<boolean> {
        return this.enabled$.pipe(distinctUntilChanged());
    }
}
