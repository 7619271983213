import type {ChartDef} from './../../pivot/frontend/model/chart-def';
import type {ClusteringMLTask} from './clustering/clustering-mltask';
import type {CodeEnvSelection} from './../../code/code-env-selection';
import type {ContainerExecSelection} from './../../containers/exec/container-exec-selection';
import type {MLSparkParams} from './../ml/mlspark-params';
import type {PredictionMLTask} from './prediction/prediction-mltask';
import type {SerializedShakerScript} from './../../shaker/model/serialized-shaker-script';
import type {SimpleKeyValue} from './../../../../../../../../dku-core/src/main/java/com/dataiku/dip/coremodel/simple-key-value';
import type {SparkConstants} from './../ml/spark-constants';

/**
 * Generated from com.dataiku.dip.analysis.model.MLTask
 */
export interface _MLTask {
    backendType: MLTask.BackendType;
    containerSelection: ContainerExecSelection;
    diagnosticsSettings: MLTask.DiagnosticsSettings;
    envSelection: CodeEnvSelection;
    id: string;
    initiator: string;
    labels: SimpleKeyValue[];
    maxConcurrentModelTraining: number;
    name: string;
    predictionDisplayCharts: MLTask.PredictedDataChart[];
    predictionDisplayScript: SerializedShakerScript;
    sparkCheckpoint: SparkConstants.Checkpoint;
    sparkCheckpointDir: string;
    sparkParams: MLSparkParams;
    taskType: MLTask.MLTaskType;
}

export type MLTask = ClusteringMLTask | PredictionMLTask.CausalPredictionMLTask | PredictionMLTask.ClassicalPredictionMLTask | PredictionMLTask.DeepHubPredictionMLTask | PredictionMLTask.TimeseriesForecastingMLTask;

export namespace MLTask {
    /**
     * Generated from com.dataiku.dip.analysis.model.MLTask$DiagnosticsSettings
     */
    export interface DiagnosticsSettings {
        enabled: boolean;
        settings: MLTask.DiagnosticSetting[];
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.MLTask$DiagnosticSetting
     */
    export interface DiagnosticSetting {
        enabled: boolean;
        type: any /* TODO: Missing translation of com.dataiku.dip.warnings.WarningsContext$WarningType */;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.MLTask$PredictedDataChart
     */
    export interface PredictedDataChart {
        def: ChartDef;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.MLTask$MLTaskType
     */
    export enum MLTaskType {
        PREDICTION = 'PREDICTION',
        CLUSTERING = 'CLUSTERING',
        LLM_GENERIC_RAW = 'LLM_GENERIC_RAW',
        LLM_GENERIC_PROMPTABLE_COMPLETION = 'LLM_GENERIC_PROMPTABLE_COMPLETION',
        LLM_CLASSIFICATION = 'LLM_CLASSIFICATION'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.MLTask$BackendType
     */
    export enum BackendType {
        PY_MEMORY = 'PY_MEMORY',
        MLLIB = 'MLLIB',
        VERTICA = 'VERTICA',
        H2O = 'H2O',
        KERAS = 'KERAS',
        DEEP_HUB = 'DEEP_HUB'
    }
}