<form class="dkuform-modal-vertical" id="config-form" >
    <div class="control-group">
        <label class="control-label">Stats</label>
        <div
            class="controls"
            *ngFor="let stat of availableStatItems"
        >
            <label>
                <input
                    [ngModel]="$any(localParams.compute)[stat.property]"
                    (ngModelChange)="toggleStat(stat.property, $event)"
                    type="checkbox"
                    [name]="stat.property"
                    class="form__checkbox"
                >
                {{ stat.label }}
            </label>
        </div>
    </div>
</form>
