import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.Entropy
 */
export interface Entropy extends Computation._UnivariateComputation {
    // PolyJson type
    type: 'entropy';
}

export namespace Entropy {
    export const type = 'entropy';
}

/** @deprecated Use Computation.isEntropy() */ export function isEntropy(toTest: Computation): toTest is Entropy { return Entropy.type === toTest['type']; }

export namespace Entropy {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.Entropy$EntropyResult
     */
    export interface EntropyResult extends _AvailableResult {
        value: number;

        // PolyJson type
        type: 'entropy';
    }

    export namespace EntropyResult {
        export const type = 'entropy';
    }

    /** @deprecated Use ComputationResult.isEntropyResult() */ export function isEntropyResult(toTest: ComputationResult): toTest is EntropyResult { return EntropyResult.type === toTest['type']; }
}