import type {CardResult} from './card-result';
import type {Card} from './card';
import type {STLDecompositionParams} from './../../compute/computations/stldecomposition-params';
import type {STLDecomposition} from './../../compute/computations/timeseries/stldecomposition';
import type {TimeSeriesCard, _TimeSeriesCard} from './time-series-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.STLDecompositionCard
 */
export interface STLDecompositionCard extends _TimeSeriesCard {
    dataZoomEnd: number;
    dataZoomStart: number;
    params: STLDecompositionParams;
    selectedSeries: STLDecompositionCard.SeriesKind[];
    showCompactChart: boolean;

    // PolyJson type
    type: 'stl_decomposition';
}

export namespace STLDecompositionCard {
    export const type = 'stl_decomposition';
}

/** @deprecated Use Card.isSTLDecompositionCard() */ export function isSTLDecompositionCard(toTest: Card): toTest is STLDecompositionCard { return STLDecompositionCard.type === toTest['type']; }

export namespace STLDecompositionCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.STLDecompositionCard$STLDecompositionCardResult
     */
    export interface STLDecompositionCardResult extends TimeSeriesCard._TimeSeriesCardResult {
        guessedParams: STLDecomposition.STLDecompositionResult.STLGuessedParams;
        observed: number[];
        resid: number[];
        seasonal: number[];
        time: string[];
        trend: number[];

        // PolyJson type
        type: 'stl_decomposition';
    }

    export namespace STLDecompositionCardResult {
        export const type = 'stl_decomposition';
    }

    /** @deprecated Use CardResult.isSTLDecompositionCardResult() */ export function isSTLDecompositionCardResult(toTest: CardResult): toTest is STLDecompositionCardResult { return STLDecompositionCardResult.type === toTest['type']; }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.STLDecompositionCard$SeriesKind
     */
    export enum SeriesKind {
        OBSERVED = 'OBSERVED',
        TREND = 'TREND',
        SEASONALITY = 'SEASONALITY',
        RESIDUALS = 'RESIDUALS'
    }
}