import { Component, ChangeDetectionStrategy, Input, ElementRef, ViewChild, ChangeDetectorRef, SimpleChanges, OnInit, Output, EventEmitter, OnChanges, AfterViewInit } from '@angular/core';
import { PainterService } from "@shared/services/item-feed/image-feed/painter.service";
import { debounceTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ImagePositionInformation, PainterImage, DivPainterImageArtifact, MaskPainterImageArtifact } from '@shared/models/painter';
import { ImagesDataFetcherService } from '@shared/services/item-feed/image-feed/images-data-fetcher.service';
import { Subject } from 'rxjs';
import { CellData } from '@shared/services/item-feed/items-data-fetcher.service';

@UntilDestroy()
@Component({
    selector: 'image-with-artifacts',
    templateUrl: './image-with-artifacts.component.html',
    styleUrls: ['./image-with-artifacts.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageWithArtifactsComponent implements OnInit, OnChanges {
    @ViewChild('imageEl') imageEl: ElementRef;
    @ViewChild('containerEl') containerEl: ElementRef;
    @Input() cellData: CellData;
    @Input() placeholderSize: number;
    @Input() set painterImage(image: PainterImage) {
        this.activePainterImage = image;
        this.cd.detectChanges();
    }
    @Output() painterImageChange = new EventEmitter<PainterImage>();

    public hasLoaded = false;
    public hasError = false;
    public imgUrl: string;
    public showArtifacts = true;
    private resizeObserver: ResizeObserver;
    private resize$ = new Subject<void>();
    public activePainterImage: PainterImage = new PainterImage();
    // sometimes onImageError gets triggered before we try to load the image, so make sure we are actually
    // trying to load it
    private loading = false;
    private image: HTMLImageElement;

    constructor(
        private dataFetcher: ImagesDataFetcherService,
        private painter: PainterService,
        private cd: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.resize$.pipe(
            untilDestroyed(this)
        ).subscribe(_ => {
            if (this.showArtifacts) {
                this.showArtifacts = false;
                this.cd.detectChanges();
            }
        });

        this.resize$.pipe(
            debounceTime(100),
            untilDestroyed(this)
        ).subscribe(_ => {
            this.showArtifacts = true;
            this.drawImageArtifacts();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.cellData) {
            // load image before redrawing if image is different
            if (changes.cellData?.currentValue?.itemId !== changes.cellData?.previousValue?.itemId) {
                this.hasLoaded = false;
                this.hasError = false;
                this.loading = true;
                this.imgUrl = this.dataFetcher.getImagePath(this.cellData.itemId);
            } else { // if we're just resizing, don't reload image, draw immediately
                if (changes.painterImage?.currentValue === changes.painterImage?.previousValue) {
                    this.drawImageArtifacts();
                }
            }

            this.cd.markForCheck();
        }
    }

    onImageLoad() {
        this.image = this.imageEl.nativeElement;

        this.resizeObserver = new ResizeObserver(_ => {
            this.resize$.next();
        })
        this.resizeObserver.observe(this.containerEl.nativeElement);
        this.hasLoaded = true;
        this.hasError = !this.image?.width;
        this.loading = false;

        if (!this.hasError) {
            this.drawImageArtifacts();
        }
    }

    onImageError() {
        if (this.loading) {
            this.hasLoaded = true;
            this.hasError = true;
            this.loading = false;
        }
    }

    public get LabelPainterImageArtifact() {
        return DivPainterImageArtifact;
    }
    
    public get MaskPainterImageArtifact() {
        return MaskPainterImageArtifact;
    }

    drawImageArtifacts() {
        if (!this.image) {
            return;
        }
        const width = this.image.width;
        const originalWidth = this.image.naturalWidth;

        const imgPosition: ImagePositionInformation = {
            scale: width / originalWidth,
            left: this.image.offsetLeft,
            top: this.image.offsetTop
        };

        this.activePainterImage.removeArtifacts();

        this.painter.paintForFeed(this.cellData, this.activePainterImage, imgPosition);
        this.showArtifacts = true;
        this.painterImageChange.emit(this.activePainterImage);
        this.cd.detectChanges();
    }

    ngOnDestroy() {
        if (this.image) {
            /*
                Stop trying to load the image if no longer in view by replacing src attribute

                this.image.src = '' is also possible but causes problems in Android browsers
                (which aren't really supported anyway)

                See https://stackoverflow.com/a/5278475/11907039 &
                https://stackoverflow.com/a/14115340/11907039
            */
            this.image.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
        }
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }
}
