import type {DatasetMetricsStatusService} from './../server/datasets/dataset-metrics-status-service';
import type {ExposedObjectsService} from './../server/services/exposed-objects-service';
import type {ITaggingService} from './../server/services/itagging-service';
import type {InterestsInternalDB} from './../dao/interests-internal-db';
import type {PublicUser} from './../security/model/public-user';
import type {Schema} from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/coremodel/schema';
import type {TaggableObjectsService} from './../server/services/taggable-objects-service';
import type {Usages} from './../featurestore/usages';

export namespace UIDataCatalog {
    /**
     * Generated from com.dataiku.dip.datacatalog.UIDataCatalog$DatasetDetails
     */
    export interface DatasetDetails extends UIDataCatalog._AbstractDatasetDetails {
        canExportData: boolean;
        createdBy: PublicUser;
        creationDate: number;
        interest: InterestsInternalDB.Interest;
        isFeatureGroup: boolean;
        lastBuiltOn: number;
        lastModificationDate: number;
        lastModifiedBy: PublicUser;
        partitioningDimensions: UIDataCatalog.DetailsDimension[];
        schema: Schema;
        splitInputsByType: {[key in ITaggingService.TaggableType]: TaggableObjectsService.TaggableObjectRefWithName[]};
        splitOutputsByType: {[key in ITaggingService.TaggableType]: TaggableObjectsService.TaggableObjectRefWithName[]};
        status: DatasetMetricsStatusService.DatasetSummaryStatus;
        usages: Usages;

        // PolyJson type
        type: 'DATASET';
    }

    export namespace DatasetDetails {
        export const type = 'DATASET';
    }

    /** @deprecated Use UIDataCatalog.AbstractDataCatalogItemDetails.isDatasetDetails() */ export function isDatasetDetails(toTest: UIDataCatalog.AbstractDataCatalogItemDetails): toTest is DatasetDetails { return DatasetDetails.type === toTest['type']; }

    /**
     * Generated from com.dataiku.dip.datacatalog.UIDataCatalog$DiscoverablePopularDataset
     */
    export interface DiscoverablePopularDataset extends UIDataCatalog._AbstractPopularDataset {
        // PolyJson type
        type: 'DISCOVERABLE-DATASET';
    }

    export namespace DiscoverablePopularDataset {
        export const type = 'DISCOVERABLE-DATASET';
    }

    /** @deprecated Use UIDataCatalog.AbstractPopularDataset.isDiscoverablePopularDataset() */ export function isDiscoverablePopularDataset(toTest: UIDataCatalog.AbstractPopularDataset): toTest is DiscoverablePopularDataset { return DiscoverablePopularDataset.type === toTest['type']; }

    /**
     * Generated from com.dataiku.dip.datacatalog.UIDataCatalog$DetailsDimension
     */
    export interface DetailsDimension {
        name: string;
        type: string;
    }

    /**
     * Generated from com.dataiku.dip.datacatalog.UIDataCatalog$DiscoverableDatasetDetails
     */
    export interface DiscoverableDatasetDetails extends UIDataCatalog._AbstractDatasetDetails {
        // PolyJson type
        type: 'DISCOVERABLE-DATASET';
    }

    export namespace DiscoverableDatasetDetails {
        export const type = 'DISCOVERABLE-DATASET';
    }

    /** @deprecated Use UIDataCatalog.AbstractDataCatalogItemDetails.isDiscoverableDatasetDetails() */ export function isDiscoverableDatasetDetails(toTest: UIDataCatalog.AbstractDataCatalogItemDetails): toTest is DiscoverableDatasetDetails { return DiscoverableDatasetDetails.type === toTest['type']; }

    /**
     * Generated from com.dataiku.dip.datacatalog.UIDataCatalog$AbstractDataCatalogItemDetails
     */
    export interface _AbstractDataCatalogItemDetails {}

    export type AbstractDataCatalogItemDetails = UIDataCatalog.DatasetDetails | UIDataCatalog.DiscoverableDatasetDetails;

    export namespace AbstractDataCatalogItemDetails {
        export const type = ['DATASET', 'DISCOVERABLE-DATASET'] as const;
        export function isAbstractDatasetDetails(toTest: UIDataCatalog.AbstractDataCatalogItemDetails): toTest is UIDataCatalog.AbstractDatasetDetails { return ['DATASET', 'DISCOVERABLE-DATASET'].includes(toTest['type']); }
        export function isDatasetDetails(toTest: UIDataCatalog.AbstractDataCatalogItemDetails): toTest is UIDataCatalog.DatasetDetails { return toTest['type'] === 'DATASET'; }
        export function isDiscoverableDatasetDetails(toTest: UIDataCatalog.AbstractDataCatalogItemDetails): toTest is UIDataCatalog.DiscoverableDatasetDetails { return toTest['type'] === 'DISCOVERABLE-DATASET'; }
    }

    /**
     * Generated from com.dataiku.dip.datacatalog.UIDataCatalog$AbstractDatasetDetails
     */
    export interface _AbstractDatasetDetails extends UIDataCatalog._AbstractDataCatalogItemDetails {
        dataSteward?: PublicUser | null;
        datasetType: string;
        description: string;
        name: string;
        objectAuthorizations: ExposedObjectsService.ObjectAuthorizations;
        shortDesc: string;
        sourceProjectKey: string;
        sourceProjectName: string;
        tags: string[];
    }

    export type AbstractDatasetDetails = UIDataCatalog.DatasetDetails | UIDataCatalog.DiscoverableDatasetDetails;

    export namespace AbstractDatasetDetails {
        export const type = ['DATASET', 'DISCOVERABLE-DATASET'] as const;
    }

    /** @deprecated Use UIDataCatalog.AbstractDataCatalogItemDetails.isAbstractDatasetDetails() */ export function isAbstractDatasetDetails(toTest: UIDataCatalog.AbstractDataCatalogItemDetails): toTest is AbstractDatasetDetails { return (AbstractDatasetDetails.type as readonly unknown[]).includes(toTest['type']); }

    /**
     * Generated from com.dataiku.dip.datacatalog.UIDataCatalog$PopularDataset
     */
    export interface PopularDataset extends UIDataCatalog._AbstractPopularDataset {
        isFeatureGroup: boolean;
        lastBuiltOn: number;
        numberOfColumns: number;
        numberOfDataCollections: number;
        numberOfProjects: number;

        // PolyJson type
        type: 'DATASET';
    }

    export namespace PopularDataset {
        export const type = 'DATASET';
    }

    /** @deprecated Use UIDataCatalog.AbstractPopularDataset.isPopularDataset() */ export function isPopularDataset(toTest: UIDataCatalog.AbstractPopularDataset): toTest is PopularDataset { return PopularDataset.type === toTest['type']; }

    /**
     * Generated from com.dataiku.dip.datacatalog.UIDataCatalog$AbstractPopularDataset
     */
    export interface _AbstractPopularDataset {
        datasetType: string;
        name: string;
        objectAuthorizations: ExposedObjectsService.ObjectAuthorizations;
        projectKey: string;
        projectName: string;
        score: number;
        shortDesc: string;
        tags: string[];
    }

    export type AbstractPopularDataset = UIDataCatalog.DiscoverablePopularDataset | UIDataCatalog.PopularDataset;

    export namespace AbstractPopularDataset {
        export const type = ['DISCOVERABLE-DATASET', 'DATASET'] as const;
        export function isDiscoverablePopularDataset(toTest: UIDataCatalog.AbstractPopularDataset): toTest is UIDataCatalog.DiscoverablePopularDataset { return toTest['type'] === 'DISCOVERABLE-DATASET'; }
        export function isPopularDataset(toTest: UIDataCatalog.AbstractPopularDataset): toTest is UIDataCatalog.PopularDataset { return toTest['type'] === 'DATASET'; }
    }
}