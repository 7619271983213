import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { UIRouterModule } from '@migration/modules';
import { ComponentsModule } from '@shared/components';
import { DataCatalogNavComponent } from './data-catalog-nav.component';

@NgModule({
    declarations: [
        DataCatalogNavComponent,
    ],
    imports: [
        CommonModule,
        ComponentsModule,
        UIRouterModule
    ],
    exports: [
        DataCatalogNavComponent,
    ]
})

export class DataCatalogNavModule {}
