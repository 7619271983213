import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.Min
 */
export interface Min extends Computation._UnivariateComputation {
    // PolyJson type
    type: 'min';
}

export namespace Min {
    export const type = 'min';
}

/** @deprecated Use Computation.isMin() */ export function isMin(toTest: Computation): toTest is Min { return Min.type === toTest['type']; }

export namespace Min {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.Min$MinResult
     */
    export interface MinResult extends _AvailableResult {
        value: number;

        // PolyJson type
        type: 'min';
    }

    export namespace MinResult {
        export const type = 'min';
    }

    /** @deprecated Use ComputationResult.isMinResult() */ export function isMinResult(toTest: ComputationResult): toTest is MinResult { return MinResult.type === toTest['type']; }
}