import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import type { APIError, ErrorContext } from "@core/dataiku-api/api-error";
import type { SerializedRecipe } from "@model-main/coremodel/serialized-recipe";
import type { StatsRecipePayloadParams } from "@model-main/recipes/eda/stats-recipe-payload-params";
import { UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: 'stats-recipe-settings',
    templateUrl: './stats-recipe-settings.component.html',
    styleUrls: ['./stats-recipe-settings.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatsRecipeSettingsComponent implements OnInit, ErrorContext {

    /**
     * The recipe project key.
     */
    @Input() projectKey: string;

    /**
     * The recipe input.
     */
    @Input() recipeInput: SerializedRecipe.RecipeInput;

    /**
     * The recipe payload parameters.
     */
    @Input() payload: StatsRecipePayloadParams;

    /**
     * Event emitter for forwarding the payload changes.
     */
    @Output() payloadChange = new EventEmitter<StatsRecipePayloadParams>();

    /**
     * Event emitter for forwarding any api error.
     */
    @Output() onApiError = new EventEmitter<APIError>();

    ngOnInit(): void {
    }

    pushError(error: APIError): void {
        this.onApiError.emit(error);
    }
}
