import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { ResolvedDeepHubPredictionCoreParams } from 'src/generated-sources';

export interface DeephubReport {
    coreParams: ResolvedDeepHubPredictionCoreParams,
    fullModelId: string,
    categories: string[],
}

@Injectable({
  providedIn: 'root'
})
export abstract class DeephubReportService<R extends DeephubReport> {
    private report$ = new ReplaySubject<R>(1);

    constructor() { }

    updateReport(report: R) {
        this.report$.next(report);
    }

    getReport(): Observable<R> {
        return this.report$;
    }
}
