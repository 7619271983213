import { Injectable } from '@angular/core';
import { JoinType, _JoinRecipePayloadParams } from 'generated-sources';

@Injectable({
    providedIn: 'root'
})
export class JoinDisplayNamesService {

    getJoinTypeName(joinType: JoinType): string {
        switch (joinType) {
            case JoinType.LEFT: return "Left join";
            case JoinType.INNER: return "Inner join";
            case JoinType.FULL: return "Outer join";
            case JoinType.RIGHT: return "Right join";
            case JoinType.CROSS: return "Cross join";
            case JoinType.ADVANCED: return "Advanced join";
            case JoinType.LEFT_ANTI: return "Left anti join";
            case JoinType.RIGHT_ANTI: return "Right anti join";
        }
    }

    getMatchingTypeName(matchingType: _JoinRecipePayloadParams.MatchingType): string {
        // There is an issue with J2TS enums with variables (the first variable is used as enum key), it should be fixed in https://app.shortcut.com/dataiku/story/120922/fix-j2ts-enum-mapping
        // The next line is a hack to get the display name of the matching type
        const matchingTypeName: string = (_JoinRecipePayloadParams.MatchingType as unknown as Record<_JoinRecipePayloadParams.MatchingType, string>)[matchingType];
        if (matchingTypeName.length > 2) {
            return "~";
        }
        return matchingTypeName;
    }
}
