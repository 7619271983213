import { Pipe, PipeTransform } from '@angular/core';
import { Card } from 'src/generated-sources';
import { getFullCardTitle, getBasicCardTitle, getConfigureCardTitle } from '../card-utils';

@Pipe({
    name: 'cardTitle'
})
export class CardTitlePipe implements PipeTransform {
    transform(params: Card, layout: 'basic' | 'full' | 'config'): string {
        switch (layout) {
            case 'basic':
                return getBasicCardTitle(params);
            case 'full':
                return getFullCardTitle(params);
            case 'config':
                return getConfigureCardTitle(params);
        }
    }
}
