<div class="deephub-results-image-legend" *ngIf="{ filter: dataFetcher.filter$ | async } as data">
    <div class="deephub-results-image-legend__item">
        <div class="deephub-results-image-legend__header">Ground Truth</div>
        <div class="horizontal-flex aic">
            <span *ngIf="!data?.filter?.groundTruth">Any class</span>
            <span *ngIf="data?.filter?.groundTruth as groundTruth" class="deephub-results-image-legend__text"
                  [style.background-color]="colorMapService.getColor(groundTruth)"
                  [style.color]="colorMapService.getColor(groundTruth) | colorContrast">
                {{ groundTruth }}
            </span>
        </div>
    </div>
    <div class="deephub-results-image-legend__item">
        <div class="deephub-results-image-legend__header">Predicted</div>
        <div class="horizontal-flex aic">
            <span *ngIf="!data?.filter?.detection">Any class</span>
            <span *ngIf="data?.filter?.detection as detection" class="deephub-results-image-legend__text"
                  [style.background-color]="colorMapService.getColor(detection)"
                  [style.color]="colorMapService.getColor(detection) | colorContrast">
                {{ detection }}
            </span>
        </div>
    </div>
</div>
