import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { DataikuAPIModule } from '@core/dataiku-api/dataiku-api.module';
import { DataCollectionsModule } from '@features/data-collections/data-collections.module';
import { ComponentsModule } from '@shared/components';
import { DataCatalogHomeComponent } from './data-catalog-home/data-catalog-home.component';
import { DataCatalogHomeLinkComponent } from './data-catalog-home/data-catalog-home-link/data-catalog-home-link.component';
import { UIRouterModule } from '@migration/modules';
import { DataSourcesComponent } from './data-sources/data-sources.component';
import { DataSourcesTopBarComponent } from './data-sources/data-sources-top-bar/data-sources-top-bar.component';
import { DataSourcesContentTableComponent } from './data-sources/data-sources-content-table/data-sources-content-table.component';
import { MatTableModule } from '@angular/material/table';
import { PipesModule } from '@shared/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataSourcesRightPanelComponent } from './data-sources/data-sources-right-panel/data-sources-right-panel.component';
import { DataCatalogPageRightPanelExternalTableComponent } from './data-sources/data-sources-right-panel/data-catalog-page-right-panel-external-table/data-catalog-page-right-panel-external-table.component';
import { MatSelectModule } from '@angular/material/select';
import { DataSourcesFilterComponent } from './data-sources/shared/components/data-sources-filter/data-sources-filter.component';
import { MigrationModule } from '@migration/migration.module';
import { DkuMatTooltipModule } from '@shared/components/dku-mat-tooltip/dku-mat-tooltip.module';
import { DataCatalogNavModule } from './data-catalog-tabs/data-catalog-nav.module';
import { PopularDatasetsModule } from '../popular-datasets/popular-datasets.module';

@NgModule({
    declarations: [
        DataCatalogHomeComponent,
        DataCatalogHomeLinkComponent,
        DataSourcesComponent,
        DataSourcesTopBarComponent,
        DataSourcesContentTableComponent,
        DataSourcesRightPanelComponent,
        DataCatalogPageRightPanelExternalTableComponent,
        DataSourcesFilterComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        PipesModule,
        DataCollectionsModule,
        DataikuAPIModule,
        MigrationModule,
        UIRouterModule,
        MatTableModule,
        MatSelectModule,
        DkuMatTooltipModule,
        DataCatalogNavModule,
        PopularDatasetsModule,
    ]
})

export class DataCatalogModule {}
