import { Injectable } from '@angular/core';
import { DeephubReport, DeephubReportService } from '@features/deephub/computer-vision/report/services/abstract-deephub-report.service';
import { DeephubImagesDataService, DeepHubPredictionModelPerf } from 'src/generated-sources';

export interface ObjectDetectionReport extends DeephubReport {
    perf: DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf,
    confidenceThreshold: number,
    iou: number
}

@Injectable({
  providedIn: 'root'
})
export class DeephubObjectDetectionReportService extends DeephubReportService<ObjectDetectionReport> {
    // Converts a value on a scale to its position (index)
    private stepValueToIndex(value: number, startValue: number, step: number) {
        return Math.round((value - startValue) / step);
    }

    iouToIndex(iou: number): number {
        return this.stepValueToIndex(iou, 0.5, 0.05);
    }

    confidenceThresholdToIndex(confidenceThreshold: number): number {
        return this.stepValueToIndex(confidenceThreshold, 0, 0.05);
    }

    /*
        Category clicked: filter type = ONE for row/column clicked, ANY for other axis
        Cell clicked: filter type = ONE if in category row/column, NONE if no detection/object row/column
    */
    getFilterCategory(index: number, categories: string[]): DeephubImagesDataService.Category {
        const categoryCount = categories.length;
        let value = '';
        let type = DeephubImagesDataService.CategoryType.ONE;

        if (index < 0) { // no category was clicked on, so don't filter any out
            type = DeephubImagesDataService.CategoryType.ANY;
        } else if (index >= categoryCount) { // no detection/object cell clicked
            type = DeephubImagesDataService.CategoryType.NONE;
        } else { // specific category or cell with category was clicked on
            value = categories[index];
        }

        return {
            type,
            value
        }
    }
}
