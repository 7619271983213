import { DatasetSelection, BinningMode } from 'src/generated-sources';

export const DEFAULT_TAGGABLE_OBJECT_FIELDS = {
    checklists: { checklists: [] },
    customFields: {},
    tags: []
};

export const DEFAULT_DATASET_SELECTION_FIELDS = {
    filter: { enabled: false, distinct: false },
    ordering: { enabled: false, rules: [] },
    maxRecords: -1,
    targetRatio: 0.02,
    latestPartitionsN: 1,
    partitionSelectionMethod: DatasetSelection.PartitionSelectionMethod.ALL,
    maxReadUncompressedBytes: -1,
    withinFirstN: -1
};


export const DEFAULT_SPLIT_BY_SPEC = {
    groupWithAll: true,
    groupWithOthers: true,
    maxValues: 5,
    binningMode: BinningMode.AUTO,
    customBinningBoundaries: []
};
