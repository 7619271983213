import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluck'
})
export class PluckPipe implements PipeTransform {
    transform(list: any[], propertyName: string): any[] {
        if (!list || !propertyName) {
            return [];
        }
        return list.map(item => item[propertyName]);
    }
}
