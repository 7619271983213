import { EventEmitter, ViewContainerRef } from "@angular/core";
import { EditCardModalComponent } from "@features/eda/worksheet/card-wizard/edit-card-modal/edit-card-modal.component";
import { CardAction, CardActionType } from "@features/eda/worksheet/cards/events";
import { createACFPlotCard, createDurbinWatsonCard } from "@features/eda/worksheet/cards/help/card-creation-templates";
import { ModalShape, ModalsService } from "@shared/modals/modals.service";
import { Card, TimeSeriesCard } from "src/generated-sources";

// Characterize a time series
export type TimeSeriesParams = Pick<TimeSeriesCard, 'seriesColumn' | 'timeColumn' | 'seriesIdentifiers' | 'resamplerSettings'>

export class CardCreationHelper {
    constructor(
        private modalsService: ModalsService,
        private viewContainerRef: ViewContainerRef,
        private action: EventEmitter<CardAction>,
    ) { }

    createDurbinWatsonCard(params: TimeSeriesParams) {
        this.openModal(createDurbinWatsonCard(
            params.seriesColumn,
            params.timeColumn,
            params.seriesIdentifiers,
            params.resamplerSettings
        ))
    }

    createACFPlotCard(params: TimeSeriesParams) {
        this.openModal(createACFPlotCard(
            params.seriesColumn,
            params.timeColumn,
            params.seriesIdentifiers,
            params.resamplerSettings
        ))
    }

    openModal(params: Card) {
        this.modalsService.open(
            EditCardModalComponent,
            { params },
            ModalShape.NONE,
            this.viewContainerRef
        ).then(
            ({ card }) => {
                this.action.emit({
                    type: CardActionType.ADD,
                    card,
                })
            },
            () => { /* swallow the error */ }
        );
    }
}
