import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PerAnnotatorStats } from '../labeling-task-overview.component';

@Component({
    selector: 'classical-per-labeler-stats',
    templateUrl: './classical-per-labeler-stats.component.html',
    styleUrls: ['../shared-overview.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObjectDetectionPerLabelerStatsComponent {

    @Input() perAnnotatorStats: PerAnnotatorStats;
}
