import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { getImageURLFromTask } from '@features/labeling/utils';
import { LabelingRecord, LabelingTask } from 'src/generated-sources';
import { ObjectDetectionUILabel, UILabel } from '../../models/label';
import { ImageLabelingTaskInfo } from '../../services/labeling.service';

@Component({
    selector: 'labeling-task-image-annotate',
    templateUrl: './labeling-task-image-annotate.component.html',
    styleUrls: ['./labeling-task-image-annotate.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelingTaskImageAnnotateComponent implements OnChanges {
    @Input() record: LabelingRecord;
    @Input() label: UILabel;
    @Input() task: ImageLabelingTaskInfo;
    @Output() labelChange = new EventEmitter<UILabel>();

    imageUrl: string;
    readonly LabelingTaskType = LabelingTask.LabelingTaskType;
    ObjectDetectionUILabel = ObjectDetectionUILabel;

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.record && changes.record.previousValue !== changes.record.currentValue) {
            this.imageUrl = this.record?.id ? getImageURLFromTask(this.task, this.record?.id) : '';
        }
    }
}
