import { Component, Inject, Input, OnChanges } from '@angular/core';
import { JoinDesc, JoinRecipePayloadParams } from '@shared/models/join-recipe-payload.model';
import { JoinDisplayNamesService } from '@shared/services/join-display-names.service';
import { fairAny } from 'dku-frontend-core';
import { ConditionsMode, FilterDesc, JoinInputDescBase, JoinType, SerializedRecipe, _JoinRecipePayloadParams } from 'src/generated-sources';

@Component({
    selector: 'right-panel-details-join-recipe',
    templateUrl: './right-panel-details-join-recipe.component.html',
    styleUrls: ['./right-panel-details-join-recipe.component.less'],
})
export class RightPanelDetailsJoinRecipeComponent implements OnChanges {

    @Input() params: SerializedRecipe;
    @Input() payload: JoinRecipePayloadParams;
    public preFilters: JoinInputDescBase[] = []; // TODO : change to protected once in Angular 14+ (more secure as outside classes should not have access to this + can be bound to the template)

    constructor(public readonly joinDisplayNames: JoinDisplayNamesService,
        @Inject("StateUtils") private StateUtils: fairAny
    ) {}

    ngOnChanges() {
        this.preFilters = this.payload ? this.filterActiveFilters(this.payload.virtualInputs) : [];
    }

    getDatasetName(index: number): string {
        return this.params.inputs.main.items[this.payload.virtualInputs[index].index].ref;
    }

    getJoinConditions(join: JoinDesc): _JoinRecipePayloadParams.MatchingCondition[] {
        if (this.joinCanHaveConditions(join)) {
            return join.on;
        }
        return [];
    }

    joinCanHaveConditions(join: JoinDesc): boolean {
        return this.getCustomJoinConditionMessage(join) == undefined;
    }

    getCustomJoinConditionMessage(join: JoinDesc): string | undefined {
        if (join.type == JoinType.CROSS) {
            return "Complete cartesian product";
        } else if (join.conditionsMode == ConditionsMode.CUSTOM) {
            return "Custom SQL condition";
        } else if (join.conditionsMode == ConditionsMode.NATURAL) {
            return "Natural join: all columns present in both datasets at execution time will be used";
        } else if (join.on.length == 0) {
            return "No join condition set";
        }
        return;
    }

    getJoinIcon(joinType: JoinType): string | undefined {
        switch (joinType) {
            case JoinType.FULL:
                return "dku-icon-join-outer-24";
            case JoinType.INNER:
                return "dku-icon-join-inner-24";
            case JoinType.LEFT:
                return "dku-icon-join-left-24";
            case JoinType.RIGHT:
                return "dku-icon-join-right-24";
            case JoinType.ADVANCED:
                return "dku-icon-join-advanced-24";
            case JoinType.CROSS:
                return "dku-icon-join-cross-24";
            case JoinType.LEFT_ANTI:
                return "dku-icon-join-anti-join-left-24";
            case JoinType.RIGHT_ANTI:
                return "dku-icon-join-anti-join-right-24";
        }
        return;
    }

    getJoinConditionModeMessage(conditionsMode: ConditionsMode): string {
        switch (conditionsMode) {
            case ConditionsMode.AND:
                return "Match when all conditions are satisfied";
            case ConditionsMode.OR:
                return "Match when one condition is satisfied";
        }
        return "";
    }

    // Unmatched outputs

    hasUnmatchedOutput(outputRole: string): boolean {
        return this.params.outputs[outputRole] && this.params.outputs[outputRole].items.length > 0;
    }

    getUnmatchedOutput(outputRole: string): string {
        return this.params.outputs[outputRole].items[0].ref;
    }

    getUnmatchedOutputLink(outputRole: "unmatchedLeft" | "unmatchedRight"): string {
        return this.StateUtils.href.dataset(this.getUnmatchedOutput(outputRole));
    }

    // Pre and post filters

    filterActiveFilters(virtualInputs: JoinInputDescBase[]): JoinInputDescBase[] {
        return virtualInputs.filter(input => input && this.isFilterActive(input.preFilter));
    }

    isFilterActive(filter: FilterDesc) {
        return filter && (filter.enabled || filter.distinct);
    }

    getPreconditionsPreText(filterDesc: FilterDesc): string {
        const preConditions: FilterDesc.FilterUiCondition[] = filterDesc.uiData?.conditions || [];
        const plural = preConditions.length > 1 ? "s" : "";
        return preConditions.length == 0 ? "" : "Filter rows satisfying the following condition" + plural + ":<br />";
    }

    getPreconditions(filterDesc: FilterDesc): FilterDesc.FilterUiCondition[] {
        return filterDesc.uiData?.conditions || [];
    }

}
