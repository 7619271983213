import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'left-pane',
    templateUrl: './left-pane.component.html',
    styleUrls: ['./left-pane.component.less']
})
export class LeftPaneComponent {

    @Output()
    isOpenChange = new EventEmitter<boolean>();

    @Input() isOpen: boolean;
    @Input() hasHandle: boolean;

    toggleMenu = () => {
        this.isOpen = !this.isOpen;
        this.isOpenChange.next(this.isOpen);
    };
}
