import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { LabelingTaskPrivileges } from '@features/labeling/labeling.component';
import { LabelingReviewDataFetcherService } from '@features/labeling/services/labeling-review-images-data-fetcher.service';
import { ImagesDataFetcherService } from '@shared/services/item-feed/image-feed/images-data-fetcher.service';
import { ItemsDataFetcherService } from '@shared/services/item-feed/items-data-fetcher.service';
import { LabelingReviewService } from '../services/labeling-review.service';

@Component({
    selector: 'image-labeling-task-review',
    templateUrl: './image-labeling-task-review.component.html',
    styleUrls: ['./image-labeling-task-review.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LabelingReviewService,
        LabelingReviewDataFetcherService,
        { provide: ItemsDataFetcherService, useExisting: LabelingReviewDataFetcherService },
        { provide: ImagesDataFetcherService, useExisting: ItemsDataFetcherService }
    ]
})
export class ImageLabelingTaskReviewComponent {
    @Input() privilegesOnTask: LabelingTaskPrivileges;
}
