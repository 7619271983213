import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { HeatmapParams } from 'src/generated-sources';
import { DeephubReport, DeephubReportService } from '../services/abstract-deephub-report.service';

export interface MatrixData {
    data: number[][];
    xCategories: string[];
    yCategories: string[];
    params: HeatmapParams
}
@Injectable()
export abstract class DeephubAbstractReportConfusionMatrixComponent<R extends DeephubReport, S extends DeephubReportService<R>, > implements OnInit {
    matrix$: Observable<MatrixData>;
    filterMatrix$: Observable<MatrixData>;
    categories: string[] = [];

    readonly NO_DETECTION_LABEL = 'Not Detected';
    readonly NO_GROUND_TRUTH_LABEL = 'Not an object';

    constructor(
        protected reportService: S
    ) { }

    ngOnInit(): void {
        this.matrix$ = this.reportService.getReport()
            .pipe(
                debounceTime(400),
                map(report => this.createMatrix(report)
            )
        );
    }

    abstract matrixClick(clickedCell: { xIndex: number, yIndex: number} | null): void;

    abstract createMatrix(report: R): MatrixData;
}
