import type {Filter} from './../../../compute/filtering/filter';

export namespace SummaryCardBuilder {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.common.SummaryCardBuilder$ComputedStatType
     */
    export enum ComputedStatType {
        FILTERS = 'FILTERS',
        FLOAT = 'FLOAT',
        INT = 'INT',
        FAILURE = 'FAILURE',
        NOT_COMPUTED = 'NOT_COMPUTED'
    }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.common.SummaryCardBuilder$ComputedStat
     */
    export interface ComputedStat {
        failureMessage?: string | null;
        filterValues?: Filter[] | null;
        flagName: string;
        floatValue?: number | null;
        fullLabel: string;
        intValue?: number | null;
        lower?: number | null;
        pvalue?: number | null;
        shortLabel: string;
        type: SummaryCardBuilder.ComputedStatType;
        upper?: number | null;
        warning?: string | null;
    }
}