

import { Inject, Injectable } from '@angular/core';
import { noop } from 'rxjs';
import { fairAny } from 'dku-frontend-core';
import { DatasetPreviewModalComponent } from '@shared/services/dataset-and-table-preview/dataset-preview-modal/dataset-preview-modal.component';
import { ModalShape, ModalsService } from '@shared/modals/modals.service';

export type ExternalTableDescription = {
    catalog: string | null | undefined;
    schema: string;
    table: string;
    type: string | string[];
    remarks: string | null | undefined;
    connectionName: string;
};

@Injectable({
    providedIn: 'root'
})
export class DatasetAndTablePreview {
    constructor(
        private modalsService: ModalsService,
        @Inject('ConnectionExplorationService') public ConnectionExplorationService: fairAny,
        @Inject('$rootScope') private $rootScope: fairAny,
    ) { }

    openDatasetPreviewModal(projectKey: string, datasetName: string) {
        return this.modalsService.open(DatasetPreviewModalComponent, {
            projectKey,
            id: datasetName
        }, ModalShape.FULL).then(noop, noop);
    }

    /** Data sources External Tables actions  */
    openExternalTablePreviewModal(connectionType: string, tableDesc: ExternalTableDescription) {
        this.ConnectionExplorationService.previewInModal(this.$rootScope, connectionType, tableDesc);
    }
}


