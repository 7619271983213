import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { CurrentRouteService } from "@core/nav/current-route.service";
import { Chunk } from "@shared/components/infinite-scroll/infinite-scroll.component";
import { ImagesDataFetcherService } from "@shared/services/item-feed/image-feed/images-data-fetcher.service";
import { PainterService } from "@shared/services/item-feed/image-feed/painter.service";
import { CellData } from "@shared/services/item-feed/items-data-fetcher.service";
import { ManagedFolder } from "@shared/utils/managed-folder";
import { resolveSmartName } from "@utils/loc";
import { Observable } from "rxjs";
import { AnyLoc, ImageViewSettings } from "src/generated-sources";


//TODO: the cleanest approach would be to extract the `getImagePath` from the data fetcher service
class FakeImageDataFetcherService extends ImagesDataFetcherService {
    managedFolderLoc: AnyLoc;

    getChunk(offset: number): Observable<Chunk> {
        throw new Error("Should not be called.");
    }

    setInfo(managedFolderLoc: AnyLoc, projectKey: string) {
        this.managedFolderLoc = managedFolderLoc;
        this.projectKey = projectKey;
    }

    getImagePath(itemPath: string): string {
        return new ManagedFolder(this.managedFolderLoc, this.projectKey).getImagePath(itemPath);
    }
}

@Component({
    selector: 'dataset-view-image-preview',
    templateUrl: './dataset-view-image-preview.component.html',
    styleUrls: ['./dataset-view-image-preview.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        PainterService,
        FakeImageDataFetcherService,
        {provide: ImagesDataFetcherService, useExisting: FakeImageDataFetcherService}
    ]
})
export class DatasetViewImagePreview implements OnInit {

    @Input() imageViewSettings: ImageViewSettings;
    @Input() imagePath: string;

    fakeCellData: CellData;

    constructor(private currentRoute: CurrentRouteService, private fakeDataFetcher: FakeImageDataFetcherService) {}

    ngOnInit(): void {
        this.fakeCellData = {itemId: this.imagePath, itemIndex: 0};
        const contextProjectKey = this.currentRoute.projectKey;
        this.fakeDataFetcher.setInfo(resolveSmartName(contextProjectKey, this.imageViewSettings.managedFolderSmartId), contextProjectKey);
    }
}