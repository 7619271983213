/**
 * Generated from com.dataiku.dip.labeling.ImageViewSettings
 */
export interface ImageViewSettings {
    annotationParams: ImageViewSettings.AnnotationParams;
    defaultViewOnExplore: boolean;
    enabled: boolean;
    managedFolderSmartId: string;
    pathColumn: string;
}

export namespace ImageViewSettings {
    /**
     * Generated from com.dataiku.dip.labeling.ImageViewSettings$AnnotationType
     */
    export enum AnnotationType {
        OBJECT_DETECTION = 'OBJECT_DETECTION',
        IMAGE_CLASSIFICATION = 'IMAGE_CLASSIFICATION'
    }

    /**
     * Generated from com.dataiku.dip.labeling.ImageViewSettings$AnnotationParams
     */
    export interface AnnotationParams {
        annotationCategories: string[];
        annotationColumn: string;
        annotationType: ImageViewSettings.AnnotationType;
        enabled: boolean;
    }
}