import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Card, DebugCard } from 'src/generated-sources';
import { copyToClipboard } from '@utils/clipboard';
import { randomId } from '@utils/random';

export type DebugCardModalComponentInput = DebugCard.DebugCardResult & { addTopLevelCard: (card: Card) => void };
@Component({
    selector: 'debug-card-modal',
    templateUrl: './debug-card-modal.component.html',
    styleUrls: ['./debug-card-modal.component.less']
})
export class DebugCardModalComponent {
    computationJson: string;
    resultsJson: string;
    paramsJson: string;
    computationResultsJson: string;
    selectedTabIndex: number;
    copyString: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: DebugCardModalComponentInput,
        public dialogRef: MatDialogRef<DebugCardModalComponent>
    ) {
        this.computationJson = JSON.stringify(data.computation, null, 4);
        this.paramsJson = JSON.stringify(data.card, null, 4);
        this.resultsJson = JSON.stringify(data.cardResult, null, 4);
        this.computationResultsJson = JSON.stringify(data.computationResult, null, 4);

        this.copyString = this.computationJson;
    }

    debugComputation() {
        this.data.addTopLevelCard({
            id: randomId(),
            type: 'playground',
            rawComputation: this.computationJson
        });
        this.dialogRef.close();
    }

    copyToClipboard() {
        let text: string;
        switch (this.selectedTabIndex) {
            case 0:
                text = this.paramsJson;
                break;
            case 1:
                text = this.resultsJson;
                break;
            case 2:
                text = this.computationJson;
                break;
            case 3:
                text = this.computationResultsJson;
                break;
            default:
                throw new Error('Unhandled tab index');
        }
        copyToClipboard(text);
    }
}
