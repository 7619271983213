import { ElementRef, Injector, Directive, Input, Output, EventEmitter, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { IScope } from 'angular';

@Directive({
    selector: 'star-interest'
})
export class StarInterestDirective extends UpgradeComponent implements OnChanges {
    @Input() status: boolean;
    @Input() tooltipPosition?: string;
    @Output() toggle = new EventEmitter<boolean>();

    constructor(@Inject('$rootScope') private $rootScope: IScope, elementRef: ElementRef, injector: Injector) {
        super('ng1StarInterest', elementRef, injector);
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Run AngularJS change detetion
        this.$rootScope.$applyAsync();
        super.ngOnChanges(changes);
    }
}
