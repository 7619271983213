import { Component, Inject, Input, ChangeDetectionStrategy, EventEmitter, Output, OnChanges } from '@angular/core';
import { APIError } from '@core/dataiku-api/api-error';
import { fairAny } from 'dku-frontend-core';
@Component({
    selector: 'api-error-alert',
    templateUrl: './api-error-alert.component.html',
    styleUrls: ['./api-error-alert.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class APIErrorAlertComponent implements OnChanges {
    @Input() canBeUnexpected: boolean = true;
    @Input() closable: boolean = true;
    @Input() errorFoldable: boolean = false;
    @Input() apiError: APIError | null | undefined;
    @Output() resetError = new EventEmitter<any>();

    open: boolean = true;
    detailedMessageOrMessage?: string | null = null; // we use a snapshot to avoid a lot of async subscriptions

    constructor(@Inject('$rootScope') public $root: fairAny) {
    }

    ngOnChanges() {
        if (!this.apiError) {
            this.detailedMessageOrMessage = null;
            return;
        }
        if ('detailedMessage' in this.apiError) {
            this.detailedMessageOrMessage = this.apiError.detailedMessage;
        } else {
            this.detailedMessageOrMessage = this.apiError.message;
        }
    }

    reset(): void {
        this.resetError.emit();
    }
}
