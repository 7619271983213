<div
    class="card-suggestion"
    [class.selected]="isSelected"
    (dblclick)="showDetails.emit($event)"
    (click)="select.emit($event)"
>    
    <div class="card-suggestion__title">
        <mat-checkbox
            [checked]="isSelected"
            [class.mat-checkbox-disabled]="false"
            disabled
        >
            <span class="dku-text-sb">
                {{ proposedVisualization.name }}
            </span>
        </mat-checkbox>
    </div>

    <div class="card-suggestion__preview-container"
        [style.height.px]="thumbnailHeight"
        [style.width.px]="thumbnailWidth">
        <card-body
            [params]="proposedVisualization.miniatureCard"
            [results]="proposedVisualization.miniatureResult"
            [readOnly]="true"
            [extendedActions]="false"
            [style.width.px]="desiredCardWidth"
            [style.height.px]="desiredCardHeight"
            [style.transform]="'scale('+ displayRatio +')'"
            [hasFixedHeight]="false"
            class="card-suggestion__preview"
        ></card-body> 
    </div>

    <div class="card-suggestion__overlay" [title]="proposedVisualization.name"></div>

    <i
        class="preview-icon icon-zoom-in"
        (click.stop)="showDetails.emit($event)"
        matTooltip="Open preview"
        [qa]="{
            'eda-assistant-preview-card': {
                'card-id': proposedVisualization.card.id
            }
        }"
    ></i>

    <i
        *ngIf="isAlreadyIncludedInWorksheet"
        class="already-created-icon icon-dku-statistics"
        matTooltip="Already present in the worksheet"
    ></i>
</div>
