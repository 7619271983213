import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { smarterNumber } from "@shared/pipes/number-pipes/smarter-number.pipe";
import { EChartsOption, SeriesOption } from "echarts";
import { encodeHTML } from "entities";
import { BoxPlotFragment } from "src/generated-sources";

@Component({
    selector: 'embedded-box-plot',
    templateUrl: './embedded-box-plot.component.html',
    styleUrls: [
        '../../../../shared-styles/chart.less',
        './embedded-box-plot.component.less',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbeddedBoxPlotComponent implements OnChanges {
    @Input() variableName: string;
    @Input() boxPlot: BoxPlotFragment;

    // By default, the min/max values are read from the boxPlot object.
    // However, we want in some cases to override the min/max values so that
    // multiple embedded box plots share the same scales.
    @Input() minValue: number | null = null;
    @Input() maxValue: number | null = null;

    boxPlotOptions: EChartsOption;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.boxPlot) {
            this.boxPlotOptions = this.createBoxPlot();
        }
    }

    private createBoxPlot(): EChartsOption {
        const { pc01, pc25, pc75, pc99, median, min, max } = this.boxPlot;

        const boxplotSeries: SeriesOption = {
            name: 'boxplot',
            type: 'boxplot',
            tooltip: {
                formatter: ((param: { data: number[] }) => {
                    const bullets = [
                        '&bull; 1st percentile: ' + encodeHTML(smarterNumber(param.data[1])),
                        '&bull; 1st quartile: ' + encodeHTML(smarterNumber(param.data[2])),
                        '&bull; Median: ' + encodeHTML(smarterNumber(param.data[3])),
                        '&bull; 3rd quartile: ' + encodeHTML(smarterNumber(param.data[4])),
                        '&bull; 99th percentile: ' + encodeHTML(smarterNumber(param.data[5]))
                    ];

                    return encodeHTML(this.variableName)
                        + ':<br>'
                        + bullets.join('<br>');
                }) as any,
            },
            data: [[
                pc01 ?? 0,
                pc25 ?? 0,
                median ?? 0,
                pc75 ?? 0,
                pc99 ?? 0,
            ]],
            itemStyle: {
                borderColor: '#888888',
                borderWidth: 1,
            },
        };

        return {
            animation: false,
            grid: {
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
            },
            yAxis: {
                type: 'category',
                show: false,
            },
            tooltip: {
                confine: true,
                trigger: 'item',
                axisPointer: {
                    type: 'shadow'
                }
            },
            xAxis: {
                min: this.minValue ?? min ?? 0,
                max: this.maxValue ?? max ?? 0,
                type: 'value',
                show: false,
            },
            series: [
                boxplotSeries,
            ],
        };
    }
}
