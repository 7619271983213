<div class="computer-vision-report h100">
    <div class="computer-vision-report__results">
        <div class="paned-page-section paned-page-section--no-border padleft0 padbot0 h100">
            <deephub-image-classification-report-confusion-matrix class="h100 vertical-flex"></deephub-image-classification-report-confusion-matrix>
        </div>
    </div>
    <div class="computer-vision-report__feed">
        <deephub-image-classification-report-image-feed></deephub-image-classification-report-image-feed>
    </div>
</div>
