import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'percentage'
})
export class PercentagePipe implements PipeTransform {
    transform(numerator?: number, denominator?: number): string|undefined {
        if (numerator == undefined || denominator == undefined) {
            return undefined;
        }
        if (!denominator) {
            if (numerator) {
                return undefined;
            } else {
                return '0%';
            }
        }
        return Math.round(numerator / denominator * 100) + '%';
    }
}
