import type {IsotonicCurve} from './isotonic-curve';
import type {PolynomialCurve} from './polynomial-curve';

/**
 * Generated from com.dataiku.dip.eda.compute.curves.Curve
 */
export interface _Curve {}

export type Curve = IsotonicCurve | PolynomialCurve;

export namespace Curve {
    export const type = ['isotonic', 'polynomial'] as const;
    export function isIsotonicCurve(toTest: Curve): toTest is IsotonicCurve { return toTest['type'] === 'isotonic'; }
    export function isPolynomialCurve(toTest: Curve): toTest is PolynomialCurve { return toTest['type'] === 'polynomial'; }
}

export namespace Curve {
    /**
     * Generated from com.dataiku.dip.eda.compute.curves.Curve$ParametrizedCurve
     */
    export interface _ParametrizedCurve {}

    export type ParametrizedCurve = IsotonicCurve.ParametrizedIsotonicCurve | PolynomialCurve.ParametrizedPolynomialCurve;

    export namespace ParametrizedCurve {
        export const type = ['isotonic', 'polynomial'] as const;
        export function isParametrizedIsotonicCurve(toTest: Curve.ParametrizedCurve): toTest is IsotonicCurve.ParametrizedIsotonicCurve { return toTest['type'] === 'isotonic'; }
        export function isParametrizedPolynomialCurve(toTest: Curve.ParametrizedCurve): toTest is PolynomialCurve.ParametrizedPolynomialCurve { return toTest['type'] === 'polynomial'; }
    }
}