import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'right-panel-status-metric',
    templateUrl: './right-panel-status-metric.component.html',
    styleUrls: ['./right-panel-status-metric.component.less'],
})
export class RightPanelStatusMetricComponent {
    @Input() metricTitle: string;
    @Input() metricLabel: string | null;
    @Input() metricValue: number;
    @Input() metricDate: number;
    @Input() metricTooltip?: string;
    @Input() canUpdate?: boolean = false;
    @Input() canBeForced?: boolean = false;
    @Output() refreshMetric = new EventEmitter<boolean>();

    constructor() { }
}
