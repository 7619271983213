import { Injectable, Inject } from '@angular/core';
import { FutureResponse, fairAny } from 'dku-frontend-core';
import { SerializedError } from 'dku-frontend-core';
import { LegacyDialogs } from '@migration/upgraded-providers';
import { EnrichedPromise } from '@core/dataiku-api/utils/enriched-promise';
import { BackendResponse } from '@core/requests/dku-legacy-http.service';

@Injectable({
    providedIn: 'root'
})
export class LegacyDialogsService {
    constructor(
        @Inject('$rootScope') private $rootScope: fairAny,
        @Inject('legacyDialogs') private legacyDialogs: LegacyDialogs
    ) { }

    displaySerializedError(e: SerializedError): void {
        this.legacyDialogs.displaySerializedError(this.$rootScope, e);
    }

    checkChangesBeforeLeaving($scope: any, dirty: (data: TransitionData) => boolean, message?: string, allowedTransitions?: string[] | TransitionData): void {
        this.legacyDialogs.checkChangesBeforeLeaving($scope, dirty, message, allowedTransitions);
    }

    infoMessagesDisplayOnly($scope: fairAny, title: string, data:fairAny): EnrichedPromise<BackendResponse<FutureResponse>> {
        return this.legacyDialogs.infoMessagesDisplayOnly($scope, title, data);
    }
}

export interface TransitionData {
    toState: any;
    toParams: any;
    fromState: any;
    fromParams: any;
}
