import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: 'deephub-report-interactive-scoring-drop-zone',
    templateUrl: './deephub-report-interactive-scoring-drop-zone.component.html',
    styleUrls: ['./deephub-report-interactive-scoring-drop-zone.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeephubReportInteractiveScoringDropZoneComponent {
    @Input() hideDefault: boolean | null;
    @Input() draggedOver: boolean | null;
    @Input() loading: boolean | null;
    @Output() uploadByClick = new EventEmitter<FileList>();

    handleFileInput(files?: FileList): void {
        if (files?.length && files?.length > 0) {
            this.uploadByClick.next(files);
        }
    }
}
