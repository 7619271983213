import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Inject, OnInit } from '@angular/core';
import { catchAPIError } from '@core/dataiku-api/api-error';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { Aggregation, Hit, Source, isDataSourceExternalTable, isDataSourceDataset } from '@shared/models/query-result-models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ErrorContextService } from '@shared/services/error-context.service';
import { BehaviorSubject, combineLatest, distinctUntilChanged, switchMap } from 'rxjs';
import { DataSourcesTab } from './shared/models';
import { deepDistinctUntilChanged, fairAny } from 'dku-frontend-core';
import { CatalogFacetMap } from '@shared/models';
import { DataCatalogService } from '../data-catalog.service';
import { INFO_TAB, SCHEMA_TAB, TabDefinition } from '@shared/components/collapsible-right-panel-layout/collapsible-right-panel-layout.component';
import { LocalStorageSortingService } from '@features/data-collections/shared/services/data-collections-sorting.service';


function getFacetsForTab(currentFacets: CatalogFacetMap, tab: DataSourcesTab): CatalogFacetMap {
    const datasetsFacets = {
        "tag.raw": currentFacets['tag.raw'] ?? [],
        "projectKey.raw": currentFacets["projectKey.raw"] ?? [],
        "type_raw": currentFacets["type_raw"] ?? [],
        "partitioned": currentFacets['partitioned'] ?? []
    };
    const tablesFacets = {
        "tag.raw": currentFacets['tag.raw'] ?? [],
        "connection.raw": currentFacets['connection.raw'] ?? [],
        "catalog.raw": currentFacets['catalog.raw'] ?? [],
        "schema.raw": currentFacets['schema.raw'] ?? []
    };

    switch(tab) {
        case DataSourcesTab.ALL: return {
            "scope": ['all'],
            "_type": ['dataset', 'table'],
            ...datasetsFacets,
            ...tablesFacets
        };
        case DataSourcesTab.DATASETS: return {
            "scope": ['dss'],
            "_type": ['dataset'],
            ...datasetsFacets,
        };
        case DataSourcesTab.EXTERNAL_TABLES: return {
            "scope": ['external'],
            "_type": ['table'],
            ...tablesFacets
        };
    }
}

@UntilDestroy()
@Component({
    selector: 'data-sources',
    templateUrl: './data-sources.component.html',
    styleUrls: ['./data-sources.component.less'],
    providers: [ErrorContextService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataSourcesComponent implements OnInit {
    isRightPanelOpened = true;
    rightPanelSelectedTab: string = 'info';
    get rightPanelTabs(): TabDefinition<string>[] {
        const tabs: TabDefinition<string>[] = [INFO_TAB];
        if ((isDataSourceDataset(this.selectedItem) || isDataSourceExternalTable(this.selectedItem)) && this.selectedItem._source.columns.length > 0) {
            tabs.push(SCHEMA_TAB);
        }
        return tabs;
    }

    DataSourcesTab = DataSourcesTab;
    readonly query$: BehaviorSubject<string>;
    readonly selectedTab$: BehaviorSubject<DataSourcesTab>;
    readonly facets$: BehaviorSubject<CatalogFacetMap>;

    items: Hit<Source>[] = [];
    itemCount: number = 0;
    aggregations: Record<string, Aggregation> = {};

    selectedItem?: Hit<Source>;
    multiSelectedItems: Hit<Source>[] = [];
    showSelected = false;

    showLoadingPanel = true;

    get itemsToShow() {
        return this.showSelected ? this.multiSelectedItems : this.items;
    }

    // we use construction-time constants to avoid messing with change detection
    private readonly massImportOption = {icon: 'dku-icon-arrow-circle-download-16', label: 'Import', action: () => this.multiActionImportTables()};
    private readonly massActionOptionsWhenShowSelected = [
        this.massImportOption,
        {icon: 'dku-icon-eye-16', label: 'Show all tables', action: () => this.toggleShowSelected()}
    ];
    private readonly massActionOptionsWhenShowAll = [
        this.massImportOption,
        {icon: 'dku-icon-eye-16', label: 'Show selected tables', action: () => this.toggleShowSelected()}
    ];
    get massActionOptions() {
        return this.showSelected ? this.massActionOptionsWhenShowSelected : this.massActionOptionsWhenShowAll;
    }

    constructor(
        private dataikuAPI: DataikuAPIService,
        private errorContextService: ErrorContextService,
        private cd: ChangeDetectorRef,
        @Inject('$rootScope') public $rootScope: fairAny,
        @Inject('$state') public $state: fairAny,
        private dataCatalogService: DataCatalogService,
        private localStorageSortingService: LocalStorageSortingService,
    ) {
        const selectedTab = Object.values(DataSourcesTab).find(tab => tab.toLowerCase() === this.$state.params.selectedTab) || DataSourcesTab.ALL;
        this.selectedTab$ = new BehaviorSubject<DataSourcesTab>(selectedTab);
        this.facets$ = new BehaviorSubject<CatalogFacetMap>(getFacetsForTab(
            this.localStorageSortingService.getDataSourcesFacets(),
            selectedTab
        ));

        this.facets$.pipe(
            untilDestroyed(this)
        ).subscribe((facets) => {
            this.localStorageSortingService.saveDataSourcesFacets(facets);
        });

        this.query$ = new BehaviorSubject<string>(this.localStorageSortingService.getDataSourcesQuery());
        this.query$.pipe(
            untilDestroyed(this)
        ).subscribe((query) => {
            this.localStorageSortingService.saveDataSourcesQuery(query);
        });
    }

    ngOnInit(): void {
        combineLatest([
            this.query$,
            this.facets$
        ]).pipe(
            deepDistinctUntilChanged(),
            switchMap(([query, facets]) => {
                return this.dataikuAPI.catalog.search(query, facets).pipe(
                    catchAPIError(this.errorContextService)
                );
            }),
            untilDestroyed(this),
        ).subscribe((data) => {
            this.showLoadingPanel = false;
            this.itemCount = data.hits.total;
            this.items = data.hits.hits;
            this.aggregations = data.aggregations;
            this.selectedItem = this.items.find(respItem => respItem._id === this.selectedItem?._id);
            this.multiSelectedItems = this.refreshSelectedItems(this.multiSelectedItems, this.items);
            this.cd.detectChanges();
        });

        // The first observable value must not trigger any route change.
        let firstTimeRedirect = true;
        this.selectedTab$.pipe(
            distinctUntilChanged(),
            untilDestroyed(this)
        ).subscribe((selectedTab) => {
            this.facets$.next(getFacetsForTab(this.facets$.value, selectedTab));
            this.showLoadingPanel = true;
            this.showSelected = selectedTab === DataSourcesTab.EXTERNAL_TABLES && this.showSelected;
            if(!firstTimeRedirect) {
                this.$state.go('.', {selectedTab: selectedTab.toLowerCase()}, {notify: false});
            }
            firstTimeRedirect = false;
        });
    }

    resetFilters() {
        this.facets$.next(getFacetsForTab({}, this.selectedTab$.value));
        this.query$.next("");
    }

    onFacetChange(facet : CatalogFacetMap) {
        this.facets$.next({...this.facets$.value, ...facet});
    }

    // Multi-select on indexed tables
    isMultiSelectEnabled() {
        return this.selectedTab$.value == DataSourcesTab.EXTERNAL_TABLES;
    }

    setMultiSelectedItems(selectedItems: Hit<Source>[]) {
        this.multiSelectedItems = selectedItems;
        if (selectedItems.length === 0) {
            this.showSelected = false;
        }
    }

    multiActionImportTables() {
        this.dataCatalogService.gotoMassImportPage(
            this.multiSelectedItems
                .filter(isDataSourceExternalTable) // filter should be identity when UI allows this code-path
                .map(i => i._source)
        );
    }

    toggleShowSelected() {
        this.showSelected = !this.showSelected;
    }

    /**
     * After a new search query, find out which selected items are in the response and replace the hit instance from selectedItem by the new one
     * Keep selected items that are not in the new response, as their old instance.
     */
    private refreshSelectedItems(massSelectedItems: Hit<Source>[], newResponseItems: Hit<Source>[]) {
        if (!this.isMultiSelectEnabled()) { // don't bother filtering if multi-select is not available
            return massSelectedItems;
        }
        return massSelectedItems.map(selectedItem => {
            const itemInNewResponse = newResponseItems.find(respItem => respItem._id === selectedItem._id);
            return itemInNewResponse ?? selectedItem;
        });
    }

    goToConnectionsExplorer() {
        if (this.$state.current.name.startsWith('projects.project')) {
            this.$state.go('projects.project.datacatalog.connectionexplorer');
        } else {
            this.$state.go('datacatalog.connectionexplorer');
        }
    }
}
