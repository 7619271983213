import { Component, Input, EventEmitter, Output, SimpleChanges, OnChanges, ChangeDetectionStrategy, ViewChild, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DataSpec } from 'src/generated-sources';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { DkuPopoverComponent } from '@app/widgets/dropdowns/dku-popover/dku-popover.component';
import { fairAny } from 'dku-frontend-core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import type { IScope } from 'angular';
import _ from 'lodash';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { resolveSmartName } from "@utils/loc";

/*
    This component is equivalent to the "sampling-tab", minus the DatasetSelectionToMemTable.maxSizeMb,
    as we do not support this type of dataset selection iun EDA.
*/
@UntilDestroy()
@Component({
    selector: 'sample-settings',
    templateUrl: './sample-settings.component.html',
    styleUrls: [
        '../../shared-styles/forms.less',
        '../../shared-styles/header-button.less',
        './sample-settings.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SampleSettingsComponent implements OnChanges, OnDestroy {
    @Input() dataSpec: DataSpec;
    @Input() readOnly: boolean;
    @Input() projectKey: string;
    @Output() dataSpecChange = new EventEmitter<DataSpec>();
    @ViewChild('popover', { static: true }) popover: DkuPopoverComponent;

    partitioned: boolean = false;

    samplingMethods: string[];
    samplingMethodDescriptions: string[];

    datasetFullInfo: fairAny;

    form = this.formBuilder.group({
        samplingMethod: new FormControl(undefined, [Validators.required]),
        maxRecords: new FormControl(undefined, [Validators.required, Validators.min(1)]),
        autoRefreshSample: new FormControl(undefined, [Validators.required]),
        enableFiltering: new FormControl(undefined, [Validators.required]),
        targetRatio: new FormControl(1, [Validators.required, Validators.min(0), Validators.max(1)]),
        filter: new FormControl(undefined, [Validators.required]),
        partitionSelectionMethod: new FormControl(undefined, [Validators.required]),
        selectedPartitions: new FormControl(),
        latestPartitionsN: new FormControl(),
        datasetSelectionForOrdering: new FormControl(undefined, [Validators.required])
    });

    constructor(
        private formBuilder: FormBuilder,
        private DataikuAPI: DataikuAPIService,
        @Inject('CreateModalFromTemplate') private createModal: fairAny,
        @Inject('SamplingData') public SamplingData: fairAny,
        @Inject('$rootScope') private $rootScope: IScope,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        const methodCount = 3;

        this.samplingMethods = this.SamplingData.streamSamplingMethods.slice(0, methodCount);
        this.samplingMethodDescriptions = this.SamplingData.streamSamplingMethodsDesc.slice(0, methodCount);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dataSpec) {
            if (!changes.dataSpec.currentValue) {
                this.partitioned = false;
            } else {
                const datasetLoc = resolveSmartName(this.projectKey, this.dataSpec.inputDatasetSmartName);
                this.DataikuAPI.datasets
                    .getFullInfo(this.projectKey, datasetLoc.projectKey, datasetLoc.id)
                    .subscribe(
                        response => {
                            this.datasetFullInfo = response;
                            this.partitioned = response.partitioned;
                            this.changeDetectorRef.markForCheck();
                        }
                    );
            }
            if (!_.isEqual(changes.dataSpec.currentValue, changes.dataSpec.previousValue)) {
                this.reset();
            }
        }
    }

    reset() {
        this.form.patchValue({
            samplingMethod: this.dataSpec.datasetSelection.samplingMethod,
            maxRecords: this.dataSpec.datasetSelection.maxRecords,
            autoRefreshSample: this.dataSpec.autoRefreshSample,
            enableFiltering: this.dataSpec.datasetSelection.filter!.enabled,
            targetRatio: this.dataSpec.datasetSelection.targetRatio,
            filter: this.dataSpec.datasetSelection.filter,
            partitionSelectionMethod: this.dataSpec.datasetSelection.partitionSelectionMethod,
            selectedPartitions: this.dataSpec.datasetSelection.selectedPartitions,
            latestPartitionsN: this.dataSpec.datasetSelection.latestPartitionsN,
            datasetSelectionForOrdering: _.cloneDeep(this.dataSpec.datasetSelection)
        });
    }

    save() {
        let selection;
        switch (this.form.value.samplingMethod) {
            case 'RANDOM_FIXED_RATIO':
                selection = { targetRatio: this.form.value.targetRatio };
                break;
            case 'HEAD_SEQUENTIAL':
                selection = { maxRecords: this.form.value.maxRecords };
                break;
            default:
                selection = {};
        }

        this.dataSpecChange.emit({
            ...this.dataSpec,
            refreshTrigger: this.dataSpec.refreshTrigger + 1,
            autoRefreshSample: this.form.value.autoRefreshSample,
            datasetSelection: {
                ...this.dataSpec.datasetSelection,
                ...selection,
                samplingMethod: this.form.value.samplingMethod,
                filter: {
                    ...this.form.value.filter,
                    enabled: this.form.value.enableFiltering
                },
                targetRatio: this.form.value.targetRatio,
                partitionSelectionMethod: this.form.value.partitionSelectionMethod,
                ...(this.form.value.selectedPartitions && { selectedPartitions: this.form.value.selectedPartitions }),
                latestPartitionsN: this.form.value.latestPartitionsN ? this.form.value.latestPartitionsN : 1,
                ordering: {
                    ...this.form.value.datasetSelectionForOrdering.ordering
                }
            },
        });

        this.popover.hide();
    }

    onFilteringChange() {
        if (this.form.value.enableFiltering) {
            const datasetLoc = resolveSmartName(this.projectKey, this.dataSpec.inputDatasetSmartName);
            this.DataikuAPI.datasets.get(
                datasetLoc.projectKey, datasetLoc.id, this.projectKey
            ).pipe(untilDestroyed(this))
                .subscribe(
                    dataset => {
                        const newScope: fairAny = this.$rootScope.$new();
                        newScope.dataset = dataset;
                        newScope.schema = dataset.schema,
                            newScope.filter = _.cloneDeep(this.form.value.filter);
                        newScope.filter.enabled = true;
                        newScope.ok = () => {
                            this.form.patchValue({ filter: newScope.filter });
                            this.changeDetectorRef.markForCheck();
                        };

                        this.popover.hide();
                        this.createModal('/static/dataiku/nested-filters/input-filter-block/filter-modal.component.html', newScope);
                    },
                );
        }
    }

    ngOnDestroy() {
    }
}
