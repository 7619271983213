import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, ChangeDetectorRef, Output, EventEmitter, OnInit, Inject } from '@angular/core';
import { DataCatalogRightPanelContextService } from '@features/data-catalog/data-catalog-right-panel-context.service';
import { DataCollectionFullItemRef, getDataCollectionFullItemRef } from '@features/data-collections/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ErrorContextService } from '@shared/services/error-context.service';
import { WT1Service, fairAny } from 'dku-frontend-core';
import { BasePermissions, UIDataCatalog, UIDataCollection } from 'src/generated-sources';
import { DataCollectionPageRightPanelContextService } from './data-collection-page-right-panel-context.service';

@UntilDestroy()
@Component({
    selector: 'data-collection-page-right-panel',
    templateUrl: './data-collection-page-right-panel.component.html',
    styleUrls: ['./data-collection-page-right-panel.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ErrorContextService, {
        provide: DataCatalogRightPanelContextService,
        useClass: DataCollectionPageRightPanelContextService
    }],
})
export class DataCollectionPageRightPanelComponent implements OnInit, OnChanges {
    @Input() readonly dataCollectionId!: string;
    @Input() readonly selectedItem?: UIDataCollection.AbstractDataCollectionItemInfo;
    @Input() readonly currentUserPermissions?: BasePermissions.Permissions;
    @Input() selectedTab: string;
    @Output() removeSelectedItem = new EventEmitter<UIDataCollection.AbstractDataCollectionItemInfo>();

    selectedItemDetails?: Readonly<UIDataCatalog.AbstractDataCatalogItemDetails>;
    isItemDetailsLoading$ = this.rightPanelContext.getLoadingStatus();

    constructor(
        public rightPanelContext: DataCatalogRightPanelContextService<DataCollectionFullItemRef>,
        private WT1Service: WT1Service,
        private cd: ChangeDetectorRef,
        @Inject('StateUtils') private StateUtils: fairAny,
    ) { }

    ngOnInit(): void {
        this.rightPanelContext.getCurrentDetails().pipe(
            untilDestroyed(this),
        ).subscribe(details => {
            this.selectedItemDetails = details;
            this.cd.detectChanges();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.dataCollectionId || changes.selectedItem) {
            this.rightPanelContext.setSelectedItem(this.selectedItem
                 ? getDataCollectionFullItemRef(this.dataCollectionId, this.selectedItem)
                 : undefined
            );
        }
    }

    isSelectedItemReadableDataset(): this is {selectedItemDetails: UIDataCatalog.DatasetDetails} {
        return this.selectedItemDetails !== undefined
            && UIDataCatalog.AbstractDataCatalogItemDetails.isDatasetDetails(this.selectedItemDetails);
    }

    isSelectedItemDiscoverableDataset(): this is {selectedItemDetails: UIDataCatalog.DiscoverableDatasetDetails} {
        return this.selectedItemDetails !== undefined
            && UIDataCatalog.AbstractDataCatalogItemDetails.isDiscoverableDatasetDetails(this.selectedItemDetails);
    }

    share(selectedItemDetails: UIDataCatalog.DatasetDetails) {
        this.rightPanelContext.share(selectedItemDetails, {
            from: 'data-collection',
            dataCollectionh: this.WT1Service.md5(this.dataCollectionId)
        });
    }

    editDataSteward(selectedItemDetails: UIDataCatalog.DatasetDetails) {
        this.rightPanelContext.editDataSteward(selectedItemDetails, {
            from: 'data-collection',
            dataCollectionh: this.WT1Service.md5(this.dataCollectionId)
        });
    }
}
