import { FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

/*
    Validate that an object is not empty
*/
export function isIntegerValidator(): ValidatorFn {
    return function validate(control: AbstractControl) {
        const value = control.value;

        return Number.isInteger(value) ? null : {
            isNotInteger: true
        };
    };
}
