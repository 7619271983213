import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.CountDistinct
 */
export interface CountDistinct extends Computation._UnivariateComputation {
    // PolyJson type
    type: 'count_distinct';
}

export namespace CountDistinct {
    export const type = 'count_distinct';
}

/** @deprecated Use Computation.isCountDistinct() */ export function isCountDistinct(toTest: Computation): toTest is CountDistinct { return CountDistinct.type === toTest['type']; }

export namespace CountDistinct {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.CountDistinct$CountDistinctResult
     */
    export interface CountDistinctResult extends _AvailableResult {
        count: number;

        // PolyJson type
        type: 'count_distinct';
    }

    export namespace CountDistinctResult {
        export const type = 'count_distinct';
    }

    /** @deprecated Use ComputationResult.isCountDistinctResult() */ export function isCountDistinctResult(toTest: ComputationResult): toTest is CountDistinctResult { return CountDistinctResult.type === toTest['type']; }
}