/*
 * From https://github.com/ecomfe/echarts-stat/blob/1.2.0/src/regression.js
 * By Deqing Li, under BSD license https://github.com/ecomfe/echarts-stat/blob/1.2.0/package.json#L15
 */

import { Injectable } from '@angular/core';
import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';
import { NumberFormatterService } from '../formatting';

export interface ChartRegression {
    expression: string;
    parameter: any;
    points: any[][];
}

@Injectable({
    providedIn: 'root'
})
export class ChartRegressionService {

    private regressions: Record<ChartDef.RegressionType, (predata: [][], opt: { dimensions: [number, number], xCustomMin?: number | undefined, yCustomMin?: number | undefined }) => ChartRegression>;
    private filter: (d: any, formattingOptions: any) => number;

    constructor(private numberFormatterService: NumberFormatterService) {
        this.regressions = {
            [ChartDef.RegressionType.LINEAR]: this.linear,
            [ChartDef.RegressionType.POLYNOMIAL]: this.polynomial,
            [ChartDef.RegressionType.EXPONENTIAL]: this.exponential,
            [ChartDef.RegressionType.LOGARITHMIC]: this.logarithmic
        };
        this.filter = this.numberFormatterService.smartNumberFilter();
    }

    public regression(scatterRegression: ChartDef.Regression, predata: [][], opt: { dimensions: [number, number], xCustomMin?: number | undefined, yCustomMin?: number | undefined }) {
        const result = this.regressions[scatterRegression.type](predata, opt);
        const xDimIdx = opt.dimensions[0];
        result.points.sort((itemA: any, itemB: any) => itemA[xDimIdx] - itemB[xDimIdx]);

        return result;
    }

    private linear = (predata: [][], opt: { dimensions: [number, number] }): ChartRegression => {
        const xDimIdx = opt.dimensions[0];
        const yDimIdx = opt.dimensions[1];
        let sumX = 0;
        let sumY = 0;
        let sumXY = 0;
        let sumXX = 0;
        const len = predata.length;

        for (let i = 0; i < len; i++) {
            const rawItem = predata[i];
            sumX += rawItem[xDimIdx];
            sumY += rawItem[yDimIdx];
            sumXY += rawItem[xDimIdx] * rawItem[yDimIdx];
            sumXX += rawItem[xDimIdx] * rawItem[xDimIdx];
        }

        const gradient = ((len * sumXY) - (sumX * sumY)) / ((len * sumXX) - (sumX * sumX));
        const intercept = (sumY / len) - ((gradient * sumX) / len);

        const result = [];
        for (let j = 0; j < predata.length; j++) {
            const rawItem = predata[j];
            const resultItem: any[] = rawItem.slice();
            resultItem[xDimIdx] = rawItem[xDimIdx];
            resultItem[yDimIdx] = gradient * rawItem[xDimIdx] + intercept;
            result.push(resultItem);
        }

        const xCoefficient = this.formatValue(gradient, 100, 100);
        const constant = this.formatValue(intercept, 100, 100);

        const sign = constant < 0 ? '-' : '+';

        const expression = `y = ${xCoefficient}x ${sign} ${Math.abs(constant)}`;

        return {
            points: result,
            parameter: {
                gradient: gradient,
                intercept: intercept
            },
            expression: expression
        };
    };

    private polynomial = (predata: [][], opt: { dimensions: [number, number], order?: number }): ChartRegression => {
        const xDimIdx = opt.dimensions[0];
        const yDimIdx = opt.dimensions[1];
        let order = opt.order;

        if (order == null) {
            order = 2;
        }
        //coefficient matrix
        const coeMatrix = [];
        const lhs = [];
        const k = order + 1;

        for (let i = 0; i < k; i++) {
            let sumA = 0;
            for (let n = 0; n < predata.length; n++) {
                const rawItem = predata[n];
                sumA += rawItem[yDimIdx] * Math.pow(rawItem[xDimIdx], i);
            }

            lhs.push(sumA);
            const temp = [];
            for (let j = 0; j < k; j++) {
                let sumB = 0;
                for (let m = 0; m < predata.length; m++) {
                    sumB += Math.pow(predata[m][xDimIdx], i + j);
                }
                temp.push(sumB);
            }
            coeMatrix.push(temp);
        }
        coeMatrix.push(lhs);

        const coeArray = this.gaussianElimination(coeMatrix, k);

        const result = [];

        for (let i = 0; i < predata.length; i++) {
            let value = 0;
            const rawItem = predata[i];
            for (let n = 0; n < coeArray.length; n++) {
                value += coeArray[n] * Math.pow(rawItem[xDimIdx], n);
            }
            const resultItem: any[] = rawItem.slice();
            resultItem[xDimIdx] = rawItem[xDimIdx];
            resultItem[yDimIdx] = value;
            result.push(resultItem);
        }

        let expression = 'y =';
        for (let i = coeArray.length - 1; i >= 0; i--) {
            const isFirstElement = i === coeArray.length - 1;
            if (i > 1) {
                const coefficient = this.formatValue(coeArray[i], Math.pow(10, i + 1), Math.pow(10, i + 1));
                const sign = isFirstElement ? ' ' : coefficient < 0 ? '- ' : '+ ';
                expression += `${sign}${isFirstElement ? coefficient : Math.abs(coefficient)}x^${i} `;
            } else if (i === 1) {
                const coefficient = this.formatValue(coeArray[i], 100, 100);
                const sign = isFirstElement ? ' ' : coefficient < 0 ? '- ' : '+ ';
                expression += `${sign}${isFirstElement ? coefficient : Math.abs(coefficient)}x `;
            } else {
                const constant = this.formatValue(coeArray[i], 100, 100);
                const sign = isFirstElement ? ' ' : constant < 0 ? '- ' : '+ ';
                expression += `${sign}${isFirstElement ? constant : Math.abs(constant)} `;
            }
        }

        expression = expression.trim();

        return {
            points: result,
            parameter: coeArray,
            expression: expression
        };
    };

    private exponential = (predata: [][], opt: { dimensions: [number, number], yCustomMin?: number }): ChartRegression => {
        const xDimIdx = opt.dimensions[0];
        const yDimIdx = opt.dimensions[1];
        let sumX = 0;
        let sumY = 0;
        let sumXXY = 0;
        let sumYlny = 0;
        let sumXYlny = 0;
        let sumXY = 0;

        let data = predata;
        const min = opt.yCustomMin;

        if (min !== undefined && min !== null) {
            data = predata.filter(point => point[yDimIdx] >= min);
        }

        for (let i = 0; i < data.length; i++) {
            const rawItem = data[i];
            if (rawItem[yDimIdx] <= 0) {
                continue;
            }
            sumX += rawItem[xDimIdx];
            sumY += rawItem[yDimIdx];
            sumXY += rawItem[xDimIdx] * rawItem[yDimIdx];
            sumXXY += rawItem[xDimIdx] * rawItem[xDimIdx] * rawItem[yDimIdx];
            sumYlny += rawItem[yDimIdx] * Math.log(rawItem[yDimIdx]);
            sumXYlny += rawItem[xDimIdx] * rawItem[yDimIdx] * Math.log(rawItem[yDimIdx]);
        }

        const denominator = (sumY * sumXXY) - (sumXY * sumXY);
        const coefficient = Math.pow(Math.E, (sumXXY * sumYlny - sumXY * sumXYlny) / denominator);
        const index = (sumY * sumXYlny - sumXY * sumYlny) / denominator;
        const result = [];

        for (let j = 0; j < data.length; j++) {
            const rawItem = data[j];
            if (rawItem[yDimIdx] <= 0) {
                continue;
            }
            const resultItem: any[] = rawItem.slice();
            resultItem[xDimIdx] = rawItem[xDimIdx];
            resultItem[yDimIdx] = coefficient * Math.pow(Math.E, index * rawItem[xDimIdx]);
            result.push(resultItem);
        }

        const eCoefficient = this.formatValue(coefficient, 100, 100);
        const xCoefficient = this.formatValue(index, 100, 100);
        const expression = `y = ${eCoefficient}e^(${xCoefficient}x)`;

        return {
            points: result,
            parameter: {
                coefficient: coefficient,
                index: index
            },
            expression: expression
        };
    };

    private logarithmic = (predata: [][], opt: { dimensions: [number, number], xCustomMin?: number | undefined }): ChartRegression => {
        const xDimIdx = opt.dimensions[0];
        const yDimIdx = opt.dimensions[1];

        let data = predata;
        const min = opt.xCustomMin;

        if (min !== undefined && min !== null) {
            data = predata.filter(point => point[xDimIdx] >= min);
        }

        let sumlnx = 0;
        let sumYlnx = 0;
        let sumY = 0;
        let sumlnxlnx = 0;

        let i = 0;
        for (i = 0; i < data.length; i++) {
            const rawItem = data[i];
            if (rawItem[xDimIdx] <= 0) {
                continue;
            }
            sumlnx += Math.log(rawItem[xDimIdx]);
            sumYlnx += rawItem[yDimIdx] * Math.log(rawItem[xDimIdx]);
            sumY += rawItem[yDimIdx];
            sumlnxlnx += Math.pow(Math.log(rawItem[xDimIdx]), 2);
        }

        const gradient = (i * sumYlnx - sumY * sumlnx) / (i * sumlnxlnx - sumlnx * sumlnx);
        const intercept = (sumY - gradient * sumlnx) / i;
        const result = [];

        for (let j = 0; j < data.length; j++) {
            const rawItem = data[j];
            if (rawItem[xDimIdx] <= 0) {
                continue;
            }
            const resultItem: any[] = rawItem.slice();
            resultItem[xDimIdx] = rawItem[xDimIdx];
            resultItem[yDimIdx] = gradient * Math.log(rawItem[xDimIdx]) + intercept;
            result.push(resultItem);
        }

        const constant = this.formatValue(intercept, 100, 100);
        const coefficient = this.formatValue(gradient, 100, 100);

        const sign = coefficient < 0 ? '-' : '+';

        const expression = `y = ${constant} ${sign} ${Math.abs(coefficient)}ln(x)`;

        return {
            points: result,
            parameter: {
                gradient: gradient,
                intercept: intercept
            },
            expression: expression
        };
    };

    /**
     * Gaussian elimination
     * @param  {Array.<Array.<number>>} matrix two-dimensional number array
     * @param  {number} number
     * @return {Array}
     */
    private gaussianElimination(matrix: Array<Array<number>>, number: number) {

        for (let i = 0; i < matrix.length - 1; i++) {
            let maxColumn = i;
            for (let j = i + 1; j < matrix.length - 1; j++) {
                if (Math.abs(matrix[i][j]) > Math.abs(matrix[i][maxColumn])) {
                    maxColumn = j;
                }
            }
            /*
             * the matrix here is the transpose of the common Augmented matrix.
             *  so the can perform the primary column transform, in fact, equivalent
             *  to the primary line changes
             */
            for (let k = i; k < matrix.length; k++) {
                const temp = matrix[k][i];
                matrix[k][i] = matrix[k][maxColumn];
                matrix[k][maxColumn] = temp;
            }
            for (let n = i + 1; n < matrix.length - 1; n++) {
                for (let m = matrix.length - 1; m >= i; m--) {
                    matrix[m][n] -= matrix[m][i] / matrix[i][i] * matrix[i][n];
                }
            }
        }

        const data = new Array(number);
        const len = matrix.length - 1;
        for (let j = matrix.length - 2; j >= 0; j--) {
            let temp = 0;
            for (let i = j + 1; i < matrix.length - 1; i++) {
                temp += matrix[i][j] * data[i];
            }
            data[j] = (matrix[len][j] - temp) / matrix[j][j];

        }

        return data;
    }

    /**
     * Formatting numbers between -1 and 1 to avoid coefficients = 0
     * @return {number} formatter number
     */
    private formatValue(value: number, multiplier: number, divisor: number): number {
        let result;
        if (value > -1 && value < 1) {
            result = this.filter(value * multiplier, {});
            result = this.filter(result / divisor, {});
        } else {
            result = Math.round(value * multiplier) / divisor;
        }
        return result;
    }
}
