export namespace Privileges {
    /**
     * Generated from com.dataiku.dip.security.Privileges$WorkspaceLevelPrivilegeType
     */
    export enum WorkspaceLevelPrivilegeType {
        READ = 'READ',
        WRITE = 'WRITE',
        ADMIN = 'ADMIN'
    }

    /**
     * Generated from com.dataiku.dip.security.Privileges$DataCollectionLevelPrivilegeType
     */
    export enum DataCollectionLevelPrivilegeType {
        READ = 'READ',
        WRITE = 'WRITE',
        ADMIN = 'ADMIN'
    }
}