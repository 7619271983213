import type {AbstractNSampTestCard} from './common/abstract-nsamp-test-card';
import type {CardResult} from './card-result';
import type {Card} from './card';
import type {_AbstractPairwiseTestCard} from './common/abstract-pairwise-test-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.PairwiseTTestCard
 */
export interface PairwiseTTestCard extends _AbstractPairwiseTestCard {
    // PolyJson type
    type: 'pairwise_ttest';
}

export namespace PairwiseTTestCard {
    export const type = 'pairwise_ttest';
}

/** @deprecated Use Card.isPairwiseTTestCard() */ export function isPairwiseTTestCard(toTest: Card): toTest is PairwiseTTestCard { return PairwiseTTestCard.type === toTest['type']; }

export namespace PairwiseTTestCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.PairwiseTTestCard$PairwiseTTestCardResult
     */
    export interface PairwiseTTestCardResult extends AbstractNSampTestCard._AbstractNSampleTestCardResult {
        pvalues: number[];
        statistics: number[];

        // PolyJson type
        type: 'pairwise_ttest';
    }

    export namespace PairwiseTTestCardResult {
        export const type = 'pairwise_ttest';
    }

    /** @deprecated Use CardResult.isPairwiseTTestCardResult() */ export function isPairwiseTTestCardResult(toTest: CardResult): toTest is PairwiseTTestCardResult { return PairwiseTTestCardResult.type === toTest['type']; }
}