import { Pipe, PipeTransform } from '@angular/core';
import { friendlyDurationShort } from '@shared/pipes/date-pipes/date-pipes-common';
import { fairAny } from 'dku-frontend-core';

@Pipe({
    name: 'friendlyDurationShort'
})
export class FriendlyDurationShortPipe implements PipeTransform {

    transform(input: string, ref?: fairAny, noSeconds?: boolean): string {
        return friendlyDurationShort(parseInt(input, 10) / 1000, ref, noSeconds);
    }
}
