import { Component, ChangeDetectionStrategy, Input, OnChanges, ChangeDetectorRef, Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { normalizeTextForSearch } from '@utils/string-utils';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { fairAny } from 'dku-frontend-core';
import _ from 'lodash';
import { AccessibleObjectsService } from '../../../../generated-sources';

export interface AccessibleObjectOption extends Partial<Omit<AccessibleObjectsService.AccessibleObject, 'type'>> {
    id: AccessibleObjectsService.AccessibleObject['id'];
    label: AccessibleObjectsService.AccessibleObject['label'];
    type?: AccessibleObjectsService.AccessibleObject['type'] | 'APP';
    localProject?: AccessibleObjectsService.AccessibleObject['localProject'];
    usable?: boolean;
    usableReason?: string;
}

// Lightweight wrapper around AccessibleObjectOption to please <ng-select>
interface SelectableItem {
    object: AccessibleObjectOption;
    normalizedLabel: string;
    label: string; // Recognized by <ng-select>
    disabled: boolean; // Recognized by <ng-select>
}

function makeSelectableItem(object: AccessibleObjectOption) {
    return {
        object: object,
        label: object.label,
        disabled: object.usable === false,
        normalizedLabel: normalizeTextForSearch(object.label)
    };
}

@Component({
    selector: 'dss-accessible-objects-selector',
    templateUrl: './accessible-objects-selector.component.html',
    styleUrls: ['./accessible-objects-selector.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: AccessibleObjectsSelectorComponent,
        multi: true
    }]
})
export class AccessibleObjectsSelectorComponent implements ControlValueAccessor, OnChanges {
    @Input() id: string;
    @Input() type: string;
    @Input() multi: boolean;
    @Input() objects: AccessibleObjectOption[] | null;
    @Input() placeholder: string = "Nothing selected";
    @Input() hideForeign: boolean = true;
    @Input() showObjectLink: boolean = false;
    @Input() selectClass: string;

    selectableItems: SelectableItem[] = [];
    touched = false;
    isDisabled = false;
    currentSelection: SelectableItem | SelectableItem[] | undefined;

    constructor(private cd: ChangeDetectorRef,
        @Inject("StateUtils") public stateUtils: fairAny,
        public currentRouteService: CurrentRouteService) { }

    private onChange?: (objects: AccessibleObjectOption[] | AccessibleObjectOption | undefined) => void;
    private onTouched?: () => void;

    searchFn(term: string, selectableItem: SelectableItem): boolean {
        if (!term) {
            return true;
        }
        const normalizedFilter = normalizeTextForSearch(term);
        const labelFilter = selectableItem.normalizedLabel.includes(normalizedFilter);
        const typeFilter = normalizeTextForSearch(selectableItem.object.type).includes(normalizedFilter);
        const subtypeFilter = normalizeTextForSearch(selectableItem.object.subtype).includes(normalizedFilter);

        return labelFilter || typeFilter || subtypeFilter;
    }

    ngOnChanges() {
        let selectableItems = (this.objects || []);
        if (this.hideForeign) {
            selectableItems = selectableItems.filter((item) => item.localProject);
        }

        this.selectableItems = _.sortBy(selectableItems.map(makeSelectableItem), item => normalizeTextForSearch(item.label));
        this.fixupCurrentSelection();
    }

    // Update 'currentSelection' to make sure it keeps references to objects living in 'selectableItems'
    // Note: selected item which are not in 'selectableItems' are preserved
    fixupCurrentSelection() {
        if (this.currentSelection) {
            if (Array.isArray(this.currentSelection)) {
                this.currentSelection = this.currentSelection
                    .map(item => this.selectableItems.find(selectableItem => selectableItem.object === item.object) || item);
            } else {
                const selectedObject = this.currentSelection.object;
                this.currentSelection = this.selectableItems.find(selectableItem => selectableItem.object === selectedObject) || this.currentSelection;
            }
        }
    }

    writeValue(objects: AccessibleObjectOption[] | AccessibleObjectOption | undefined): void {
        if (objects) {
            if (Array.isArray(objects)) {
                this.currentSelection = objects.map(makeSelectableItem);
            } else {
                this.currentSelection = makeSelectableItem(objects);
            }
        } else {
            this.currentSelection = undefined;
        }
        this.fixupCurrentSelection();
        this.cd.markForCheck();
    }

    registerOnChange(onChange: (objects: AccessibleObjectOption[] | AccessibleObjectOption | undefined) => void): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    private markAsTouched(): void {
        if (!this.touched) {
            this.onTouched?.();
            this.touched = true;
        }
    }

    changeCurrentSelection(items: SelectableItem[] | SelectableItem | undefined): void {
        this.currentSelection = items;
        if (Array.isArray(items)) {
            this.onChange?.(items.map(item => item.object));
        } else {
            this.onChange?.(items?.object);
        }
        this.markAsTouched();
    }

    groupByFn(item: SelectableItem): string {
        // Work around https://github.com/ng-select/ng-select/issues/1991 by prefixing group name with a space
        // (the [space] key always opens the ng-select menu and so doesn't trigger https://github.com/ng-select/ng-select/blob/45b61c706bd6d6773574a2fc58eed4340563642f/src/ng-select/lib/ng-select.component.ts#L761)
        return ' ' + item.normalizedLabel.charAt(0).toUpperCase();
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
