/**
 * Generated from com.dataiku.dip.eda.worksheets.models.Variable
 */
export interface Variable {
    name: string;
    type: Variable.Type;
}

export namespace Variable {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.models.Variable$Type
     */
    export enum Type {
        CATEGORICAL = 'CATEGORICAL',
        CONTINUOUS = 'CONTINUOUS'
    }
}