import { Workspace } from '@model-main/workspaces/workspace';

export const EMPTY_WORKSPACE: Workspace = {
    color: '',
    workspaceKey: '',
    displayName: '',
    description: '',
    permissions: [],
    currentUserPermissions: {
        admin: true,
        write: true,
        read: true,
    },
    workspaceObjects: [],
    checklists: { checklists: [] },
    tags: [],
    customFields: {},
};
