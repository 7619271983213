import type {Abstract2SampTestCard, _Abstract2SampTestCard} from './common/abstract2-samp-test-card';
import type {CardResult} from './card-result';
import type {Card} from './card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.MoodTest2SampCard
 */
export interface MoodTest2SampCard extends _Abstract2SampTestCard {
    // PolyJson type
    type: 'mood_test_2samp';
}

export namespace MoodTest2SampCard {
    export const type = 'mood_test_2samp';
}

/** @deprecated Use Card.isMoodTest2SampCard() */ export function isMoodTest2SampCard(toTest: Card): toTest is MoodTest2SampCard { return MoodTest2SampCard.type === toTest['type']; }

export namespace MoodTest2SampCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.MoodTest2SampCard$MoodTest2SampCardResult
     */
    export interface MoodTest2SampCardResult extends Abstract2SampTestCard._Abstract2SampTestCardResult {
        pvalue: number;
        statistic: number;

        // PolyJson type
        type: 'mood_test_2samp';
    }

    export namespace MoodTest2SampCardResult {
        export const type = 'mood_test_2samp';
    }

    /** @deprecated Use CardResult.isMoodTest2SampCardResult() */ export function isMoodTest2SampCardResult(toTest: CardResult): toTest is MoodTest2SampCardResult { return MoodTest2SampCardResult.type === toTest['type']; }
}