import type {CardResult} from './card-result';
import type {Card} from './card';
import type {UnitRootTestCard, _UnitRootTestCard} from './unit-root-test-card';
import type {UnitRootTestKPSS} from './../../compute/computations/timeseries/unit-root-test-kpss';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.UnitRootTestKPSSCard
 */
export interface UnitRootTestKPSSCard extends _UnitRootTestCard {
    regressionMode: UnitRootTestKPSS.RegressionMode;

    // PolyJson type
    type: 'unit_root_test_kpss';
}

export namespace UnitRootTestKPSSCard {
    export const type = 'unit_root_test_kpss';
}

/** @deprecated Use Card.isUnitRootTestKPSSCard() */ export function isUnitRootTestKPSSCard(toTest: Card): toTest is UnitRootTestKPSSCard { return UnitRootTestKPSSCard.type === toTest['type']; }

export namespace UnitRootTestKPSSCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.UnitRootTestKPSSCard$UnitRootTestKPSSCardResult
     */
    export interface UnitRootTestKPSSCardResult extends UnitRootTestCard._UnitRootTestCardResult {
        // PolyJson type
        type: 'unit_root_test_kpss';
    }

    export namespace UnitRootTestKPSSCardResult {
        export const type = 'unit_root_test_kpss';
    }

    /** @deprecated Use CardResult.isUnitRootTestKPSSCardResult() */ export function isUnitRootTestKPSSCardResult(toTest: CardResult): toTest is UnitRootTestKPSSCardResult { return UnitRootTestKPSSCardResult.type === toTest['type']; }
}