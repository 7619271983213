export namespace DKUApp {
    /**
     * Generated from com.dataiku.dip.DKUApp$DSSVersion
     */
    export interface DSSVersion {
        conf_version: string;
        product_commitid: string;
        product_version: string;
    }

    /**
     * Generated from com.dataiku.dip.DKUApp$LicensingMode
     */
    export enum LicensingMode {
        NONE = 'NONE',
        SAAS = 'SAAS',
        ON_PREMISE_CONNECTED = 'ON_PREMISE_CONNECTED',
        ON_PREMISE_DISCONNECTED = 'ON_PREMISE_DISCONNECTED'
    }
}