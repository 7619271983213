import type {FilterDesc} from './../../../../../../../../../dss-core/src/main/java/com/dataiku/dip/dataflow/exec/filter/filter-desc';
import type {_JoinDescBase} from './../joinlike/join-desc-base';
import type {_JoinLikeRecipePayloadParams} from './../joinlike/join-like-recipe-payload-params';
import type {_MatchingConditionBase} from './../joinlike/matching-condition-base';

/**
 * Generated from com.dataiku.dip.dataflow.exec.geojoin.GeoJoinRecipePayloadParams
 */
export interface GeoJoinRecipePayloadParams extends _JoinLikeRecipePayloadParams {
    postFilter: FilterDesc;
}

export namespace GeoJoinRecipePayloadParams {
    /**
     * Generated from com.dataiku.dip.dataflow.exec.geojoin.GeoJoinRecipePayloadParams$DistanceUnit
     */
    export enum DistanceUnit {
        METER = 'm',
        KILOMETER = 'km',
        MILE = 'mi',
        YARD = 'yds',
        FOOT = 'ft',
        NAUTICAL_MILE = 'nmi'
    }

    /**
     * Generated from com.dataiku.dip.dataflow.exec.geojoin.GeoJoinRecipePayloadParams$MatchingCondition
     */
    export interface MatchingCondition extends _MatchingConditionBase {
        threshold: number;
        type: GeoJoinRecipePayloadParams.MatchingType;
        unit: GeoJoinRecipePayloadParams.DistanceUnit;
    }

    /**
     * Generated from com.dataiku.dip.dataflow.exec.geojoin.GeoJoinRecipePayloadParams$MatchingType
     */
    export enum MatchingType {
        EQ = 'Is strictly equal to',
        DWITHIN = 'Is within %.0f %s of',
        WITHIN = 'Is contained within',
        BEYOND = 'Is beyond %.0f %s of',
        TOUCHES = 'Touches',
        CONTAINS = 'Contains',
        DISJOINT = 'Is disjoint to',
        INTERSECTS = 'Intersects'
    }

    /**
     * Generated from com.dataiku.dip.dataflow.exec.geojoin.GeoJoinRecipePayloadParams$JoinDesc
     */
    export interface JoinDesc extends _JoinDescBase {}
}