import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { InterestsService } from '@shared/services';

@Component({
    selector: 'right-panel-action-star-btn',
    templateUrl: './right-panel-action-star-btn.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightPanelActionStarBtnComponent {
    @Input() starred!: boolean;
    @Input() disabled = false;
    @Input() disabledTooltip = '';
    @Output() starredChange = new EventEmitter<boolean>(); // emits the new value


    //just to make is visible from template;
    INTEREST_WORDING = this.interestService.WORDING;

    constructor(
        private interestService: InterestsService
    ) { }
}
