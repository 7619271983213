<div>
    <modal-header [title]="data.title" [icon]="data.icon"></modal-header>
    <div class="modal-body flex dkuform-horizontal">
        <div class="control-group">
            <label for="value" class="control-label">{{data.message}}</label>
            <div class="controls">
                <input id="value" type="text" [(ngModel)]="value" name="value"/>
            </div>
        </div>
    </div>
    <div class="modal-footer modal-footer-std-buttons">
        <div class="pull-right" *ngIf="!data.danger">
            <button type="button" class="btn btn-default" (click)="cancel();">Cancel</button>
            <button type="button" class="btn btn-primary" (click)="confirm();"  [disabled]="value === ''" autofocus>Ok</button>
        </div>
        <div class="pull-right" *ngIf="data.danger">
            <!-- Dangerous modals don't validate on enter-->
            <button type="button" class="btn btn-default" (click)="cancel();" tabindex="-1">Cancel</button>
            <button type="button" class="btn btn-danger" (click)="confirm();" tabindex="-1" [disabled]="value === ''">Ok</button>
        </div>
    </div>
</div>
