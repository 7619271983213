import type {CodeEnvModel} from './../../../code/code-env-model';
import type {ProjectRemappingSettings} from './project-remapping-settings';

/**
 * Generated from com.dataiku.dip.projects.importexport.model.BundleContainerSettings
 */
export interface BundleContainerSettings {
    codeEnvsBehavior: BundleContainerSettings.PythonEnvsBehavior;
    remapping: ProjectRemappingSettings;
}

export namespace BundleContainerSettings {
    /**
     * Generated from com.dataiku.dip.projects.importexport.model.BundleContainerSettings$PythonEnvsBehavior
     */
    export interface PythonEnvsBehavior {
        envImportSpecificationMode: CodeEnvModel.EnvImportSpecificationMode;
        importTimeMode: BundleContainerSettings.PythonEnvImportTimeMode;
    }

    /**
     * Generated from com.dataiku.dip.projects.importexport.model.BundleContainerSettings$PythonEnvImportTimeMode
     */
    export enum PythonEnvImportTimeMode {
        INSTALL_IF_MISS = 'INSTALL_IF_MISS',
        FAIL_IF_MISS = 'FAIL_IF_MISS',
        DO_NOTHING = 'DO_NOTHING'
    }
}