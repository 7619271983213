import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIViewDirective } from './ui-view.directive';
import { UISrefDirective } from './ui-sref.directive';

@NgModule({
  declarations: [
    UIViewDirective,
    UISrefDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    UIViewDirective,
    UISrefDirective
  ]
})
export class UIRouterModule { }
