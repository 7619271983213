import { Component, Input, EventEmitter, Output, SimpleChanges, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { FitCurveCard } from 'src/generated-sources';
import type { EChartsOption, SeriesOption } from 'echarts';
import * as _ from 'lodash';
import { CardAction } from '@features/eda/worksheet/cards/events';
import { ColorsService } from '@shared/graphics/colors.service';
import { smarterNumber } from '@shared/pipes/number-pipes/smarter-number.pipe';
import { zipSameSize } from '@features/eda/echarts-utils';

@Component({
    selector: 'fit-curve-card-body',
    templateUrl: './fit-curve-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/fit-table.less',
        '../../../../shared-styles/stats-table.less',
        '../../../../shared-styles/chart.less',
        './fit-curve-card-body.component.less',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FitCurveCardBodyComponent implements OnChanges {
    @Input() params: FitCurveCard;
    @Input() results: FitCurveCard.FitCurveCardResult;
    @Output() action = new EventEmitter<CardAction>();

    chartOptions: EChartsOption;

    constructor(public colorsService: ColorsService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results) {
            this.chartOptions = this.results && this.buildChartOptions(this.results);
        }
    }

    buildChartOptions(results: FitCurveCard.FitCurveCardResult): EChartsOption {
        const curvesData = results.parametrizedCurves;

        const minMaxReducer = (p: { min: number, max: number }, value: number) => {
            return {
                min: Math.min(p.min, value),
                max: Math.max(p.max, value),
            };
        }
        const minMaxMinMaxReducer = (p: { min: number, max: number }, value: { min: number, max: number }) => {
            return {
                min: Math.min(p.min, value.min),
                max: Math.max(p.max, value.max),
            };
        }
        const initMinMax = {
            min: Number.MAX_VALUE,
            max: -Number.MAX_VALUE,
        };

        const { min: minX, max: maxX } = curvesData[0].plot.x.reduce(minMaxReducer, results.xSeries.reduce(minMaxReducer, initMinMax));

        const { min: minY, max: maxY } = curvesData.map(cd => cd.plot.y.reduce(minMaxReducer, initMinMax))
            .reduce(minMaxMinMaxReducer, results.ySeries.reduce(minMaxReducer, initMinMax));

        const curveSeries: SeriesOption[] = curvesData.map((cd, i) => {
            return {
                type: 'line',
                data: zipSameSize(cd.plot.x, cd.plot.y),
                symbol: 'none',
                itemStyle: {
                    color: this.colorsService.getColorFromIndex(i)
                },
            };
        });

        const scatterSeries: SeriesOption = {
            type: 'scatter',
            z: 0,
            itemStyle: {
                color: '#1e7efa',
                opacity: 0.5
            },
            symbolSize: 4,
            data: zipSameSize(results.xSeries, results.ySeries),
            large: true
        };

        return {
            color: ['#3398DB'],
            animation: false,
            tooltip: {
                trigger: 'none',
                axisPointer: { type: 'cross' }
            },
            grid: {
                left: 0,
                top: 10,
                right: 10,
                bottom: 0,
                containLabel: true,
            },
            xAxis: [{
                type: 'value',
                min: minX,
                max: maxX,
                axisTick: { show: true },
                axisLine: { show: true },
                axisLabel: {
                    color: '#999999',
                    formatter: (value: number) => value === minX || value === maxX ? '' : value + ''
                },
                axisPointer: {
                    label: {
                        formatter: ({ value }) => {
                            return this.params.xColumn.name + ': ' + smarterNumber(Number(value));
                        }
                    }
                }
            }],
            yAxis: {
                type: 'value',
                min: minY,
                max: maxY,
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: {
                    color: '#999999',
                    formatter: (value: number) => value === maxY || value === minY ? '' : value + ''
                },
                axisPointer: {
                    label: {
                        formatter: ({ value }) => {
                            return this.params.yColumn.name + ': ' + smarterNumber(Number(value));
                        }
                    }
                }
            },
            series: [scatterSeries, ...curveSeries]
        };
    }
}
