import { isNumber } from 'lodash';
import { EChartsOption, GridComponentOption, PieSeriesOption, TitleComponentOption } from 'echarts';
import { ChartBase, ChartCoordinates } from '../../../interfaces';
import { ChartFormattingService } from '../../../services';
import { EChartDrawContext, FacetsMetadata, NonCartesian2DSeries } from '../interfaces';
import { EChartDef } from './echart-def.model';
import { PrepareData } from './prepare-data.model';

export abstract class NonCartesian2DEChartDef extends EChartDef {
    constructor(
        protected stringUtils: any,
        protected chartStoreFactory: any,
        protected chartFormattingOptions: any,
        protected chartFormattingService: ChartFormattingService,
        protected prepareDataService: PrepareData
    ) {
        super(stringUtils, chartFormattingOptions, prepareDataService);
    }

    private getFacetSeriesOption(
        series: NonCartesian2DSeries,
        top: number,
        bottom: number,
        left: number,
        right: number,
        height: number
    ) {
        const updatedSeries: NonCartesian2DSeries = {
            ...series,
            top,
            bottom,
            left,
            right,
            height
        };

        return updatedSeries;
    }

    private facetSeriesHasData(facetSerie: NonCartesian2DSeries): boolean {
        // check if all values are null or undefined
        return facetSerie.data && facetSerie.data.some((d: any) => d.value != null) || false;
    }

    /**
     * Generate a TitleComponentOption title displaying 'No data'
     * horizontaly centered to the facet
     */
    private getFacetNoDataTitleOption(facetTitle: TitleComponentOption, left: number): TitleComponentOption {
        return {
            text: 'No data',
            left: left,
            top: facetTitle.top,
            textAlign: 'center',
            textStyle: {
                ...this.AXIS_LABELS_DEFAULT_FORMATTING_OPTIONS
            }
        };
    }

    private getOptionsByFacets(context: {
        options: EChartsOption,
        facetsMetadata: FacetsMetadata,
        chartHeight: number,
        chartWidth: number
    }): EChartsOption {
        const series = context.options.series as Array<NonCartesian2DSeries>;
        const grid = context.options.grid as GridComponentOption;

        let updatedOptions: EChartsOption = {
            series: [],
            title: [],
            grid
        };

        const top = isNumber(grid.top) ? grid.top : parseInt(grid.top || '0');
        const bottom = isNumber(grid.bottom) ? grid.bottom : parseInt(grid.bottom || '0');
        const left = isNumber(grid.left) ? grid.left : parseInt(grid.left || '0');
        const right = isNumber(grid.right) ? grid.right : parseInt(grid.right || '0');
        const height = context.chartHeight - top - bottom;

        updatedOptions = context.facetsMetadata.facets.reduce((acc, facet, facetIndex, facets) => {
            const seriesItem = series.find(seriesItem => {
                const coordIds = (seriesItem.id as string).split('-');
                const facetId = coordIds[1];
                const seriesItemFacetIndex = parseInt(facetId.split('facet')[1]);
                return seriesItemFacetIndex === facetIndex;
            });

            let facetSerieHasData = true;
            if (seriesItem) {
                let updatedTop = top;
                let updatedBottom = top;

                if (facetIndex > 0 && acc.series && 'length' in acc.series) {
                    const previousSeries = acc.series[facetIndex - 1];
                    if ('bottom' in previousSeries) {
                        updatedTop = (previousSeries.bottom as number) + top + context.chartHeight * facetIndex;
                    }
                }

                if (facetIndex < facets.length - 1) {
                    updatedBottom = 0;
                }

                const facetSeries = this.getFacetSeriesOption(
                    seriesItem,
                    updatedTop,
                    updatedBottom,
                    context.facetsMetadata.maxContainerWidth + left,
                    right,
                    height
                );

                facetSerieHasData = this.facetSeriesHasData(facetSeries);
                (acc.series as Array<NonCartesian2DSeries>).push(facetSeries);
            } else {
                throw new Error(`Expected series for facet ${facetIndex} to be found.`);
            }

            const title = context.options.title as TitleComponentOption;
            const facetTitle = this.getFacetTitleOption(
                facet,
                facetIndex,
                top,
                context.chartHeight,
                context.facetsMetadata.maxContainerWidth,
                false,
                title?.subtext,
                title?.subtextStyle
            );

            (acc.title as Array<TitleComponentOption>).push(facetTitle);

            if (!facetSerieHasData) {
                const left = context.chartWidth / 2 + context.facetsMetadata.maxContainerWidth;
                (acc.title as Array<TitleComponentOption>).push(this.getFacetNoDataTitleOption(facetTitle, left));
            }

            return acc;
        }, updatedOptions);

        return updatedOptions;
    }

    drawFacets(
        facets: Array<{ label: string }>,
        { chartId, chartDef, chartData, chartBase, legends, frameIndex }: EChartDrawContext
    ): { options: EChartsOption, allCoords: Array<Array<ChartCoordinates>> } {
        const facetsMetadata = this.getFacetsMetadata(facets);

        const chartBaseForFacet: ChartBase = {
            ...chartBase,
            height: chartDef.chartHeight,
            width: chartBase.width - facetsMetadata.maxContainerWidth
        };

        const facetsResult = this.draw({
            chartId,
            chartDef,
            chartData,
            chartBase: chartBaseForFacet,
            legends,
            frameIndex
        });

        const updatedOptions = this.getOptionsByFacets({
            options: facetsResult.options,
            facetsMetadata,
            chartHeight: chartDef.chartHeight,
            chartWidth: chartBaseForFacet.width
        });

        return { options: updatedOptions, allCoords: facetsResult.allCoords };
    }

    getThumbnailOptions(options: EChartsOption) {

        const updatedSeries: any[] = [];
        (options.series as any[]).forEach((serie: any, index: number) => {

            const updatedData: any[] = [];

            serie.data?.forEach((point: any) => {
                const updatedPoint = {
                    value: point.value,
                    itemStyle: point.itemStyle,
                    label: {
                        show: false
                    },
                    labelLine: {
                        show: false
                    },
                    children: point.children
                }
                updatedData.push(updatedPoint);
            })

            updatedSeries.push({
                type: serie.type,
                animation: false,
                labelLine: {
                    show: false
                },
                emphasis: {
                    disabled: true
                },
                label: {
                    show: false
                },
                levels: serie.levels,
                breadcrumb: {
                    show: false
                },
                data: updatedData
            });

            if (serie.radius) {
                (updatedSeries as PieSeriesOption[])[index].radius = serie.radius;
            }
        });

        return {
            series: updatedSeries
        }
    }
}
