<div class="top-level-tabs">
    <div class="horizontal-flex row-fluid object-nav">
        <div class="flex oh object-breadcrumb">
            <a class="item" ui-sref [route]="route">
                <i class="icon {{icon}}"></i>
                <span class="title ellipsis">{{label}}</span>
            </a>
        </div>
        <div class="noflex">
            <ng-content select="[object-nav-tabs]"></ng-content>
        </div>
    </div>
</div>