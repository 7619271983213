import { Component, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'academy-link',
  templateUrl: './academy-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AcademyLinkComponent {
  public href$ = new BehaviorSubject<string | undefined>(this.$rootScope.academyRootUrl);

  @Input() set page(value: string) {
    const path = value || '';
    this.href$.next(`${this.$rootScope.academyRootUrl}${path}`);
  }

  constructor(@Inject('$rootScope') private $rootScope: { academyRootUrl: string }) { }
}
