import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewContainerRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LabelingService } from '@features/labeling/services/labeling.service';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ImageFeedModalComponent } from '@shared/components/image-feed-modal/image-feed-modal.component';
import { ModalShape, ModalsService } from "@shared/modals/modals.service";
import { ImagesDataFetcherService } from '@shared/services/item-feed/image-feed/images-data-fetcher.service';
import { PainterService } from '@shared/services/item-feed/image-feed/painter.service';
import { SampleDataFetcherService } from "@shared/services/item-feed/image-feed/sample-data-fetcher.service";
import { SampleDataFormatterService, SimpleDataFormatterService } from '@shared/services/item-feed/image-feed/sample-data-formatter.service';
import { ItemsDataFetcherService } from '@shared/services/item-feed/items-data-fetcher.service';
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LabelingTask, SchemaColumn, UsabilityComputer } from "src/generated-sources";
import { ImageLabelingSettingsDataFetcherService } from "../../image-labeling-settings-data-fetcher.service";
import { observeInput } from 'dku-frontend-core';

@UntilDestroy()
@Component({
    selector: 'image-labeling-settings-data',
    templateUrl: './image-labeling-settings-data.component.html',
    styleUrls: ['../labeling-settings-data.component.less', './image-labeling-settings-data.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ImageLabelingSettingsDataFetcherService,
        { provide: ItemsDataFetcherService, useExisting: ImageLabelingSettingsDataFetcherService },
        { provide: ImagesDataFetcherService, useExisting: ImageLabelingSettingsDataFetcherService },
        { provide: SampleDataFetcherService, useExisting: ImageLabelingSettingsDataFetcherService },
        ImageLabelingSettingsDataFetcherService,
        SimpleDataFormatterService,
        { provide: SampleDataFormatterService, useExisting: SimpleDataFormatterService},
        PainterService,
    ]
})
export class ImageLabelingSettingsDataComponent implements OnInit {

    @Input() metadataColumns: SchemaColumn[] | null;
    metadataColumns$ = observeInput(this, 'metadataColumns');

    @Output() partialTaskChange = new EventEmitter<Partial<LabelingTask>>();
    
    form = this.fb.group({
        idColumn: this.fb.control(null, [Validators.required]),
        extraColumns:  this.fb.control(null, [Validators.required])
    });

    managedFolder$: Observable<UsabilityComputer.UsableComputable>;

    constructor(private modalsService: ModalsService,
        private viewContainerRef: ViewContainerRef,
        public labelingService: LabelingService,
        private labelingSettingsDataFetcherService: ImageLabelingSettingsDataFetcherService,
        private fb: FormBuilder,
        @Inject('$state') private $state: any) {
    }

    ngOnInit() {
        combineLatest([
            this.labelingService.labelingTaskInfo$,
            this.metadataColumns$
        ]).pipe(
            untilDestroyed(this),
        ).subscribe(([info, columns]) => {
            this.form.patchValue({
                idColumn: info.idColumn,
                extraColumns: columns?.map(column => column.name).filter(column => info.extraColumns?.includes(column))
            }, { emitEvent: false });
        });

        this.form.valueChanges.pipe(
            untilDestroyed(this)
        ).subscribe((formValue) => {
            this.partialTaskChange.emit(formValue);
            this.labelingSettingsDataFetcherService.setIdColumn(formValue.idColumn)
        })

        this.managedFolder$ = combineLatest([
            this.labelingService.getManagedFolderLoc(),
            this.labelingService.listComputables()
        ]).pipe(
            map(([mfLoc, computables]) => computables.filter((computable) => computable.id === `${mfLoc.projectKey}.${mfLoc.id}`)[0])
        )
    }

    goToManagedFolder(managedFolder: UsabilityComputer.UsableComputable) {
        this.$state.go('projects.project.managedfolders.managedfolder.view', {
            projectKey: managedFolder.projectKey,
            odbId: managedFolder.name,
        });
    }

    openModal({imageId}: { imageId?: number }) {
        this.modalsService
            .open(ImageFeedModalComponent, {
                imageId
            }, ModalShape.FULL, this.viewContainerRef)
            .then(() => {}, () => {});
    }

}
