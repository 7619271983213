import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {ResamplerSettings} from './../../resampling/resampler-settings';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.GuessTimeStep
 */
export interface GuessTimeStep extends Computation._UnivariateComputation {
    seriesIdentifiers: string[];

    // PolyJson type
    type: 'guess_time_step';
}

export namespace GuessTimeStep {
    export const type = 'guess_time_step';
}

/** @deprecated Use Computation.isGuessTimeStep() */ export function isGuessTimeStep(toTest: Computation): toTest is GuessTimeStep { return GuessTimeStep.type === toTest['type']; }

export namespace GuessTimeStep {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.GuessTimeStep$GuessTimeStepResult
     */
    export interface GuessTimeStepResult extends _AvailableResult {
        nTimeUnits: number;
        timeUnit: ResamplerSettings.TimeUnit;

        // PolyJson type
        type: 'guess_time_step';
    }

    export namespace GuessTimeStepResult {
        export const type = 'guess_time_step';
    }

    /** @deprecated Use ComputationResult.isGuessTimeStepResult() */ export function isGuessTimeStepResult(toTest: ComputationResult): toTest is GuessTimeStepResult { return GuessTimeStepResult.type === toTest['type']; }
}