/**
 * Generated from com.dataiku.dip.datacollections.AbstractDataCollectionItemRef
 */
export interface _AbstractDataCollectionItemRef {}

export type AbstractDataCollectionItemRef = AbstractDataCollectionItemRef.DatasetRef;

export namespace AbstractDataCollectionItemRef {
    export const type = ['DATASET'] as const;
    export function isDatasetRef(toTest: AbstractDataCollectionItemRef): toTest is AbstractDataCollectionItemRef.DatasetRef { return toTest['type'] === 'DATASET'; }
}

export namespace AbstractDataCollectionItemRef {
    /**
     * Generated from com.dataiku.dip.datacollections.AbstractDataCollectionItemRef$DatasetRef
     */
    export interface DatasetRef extends _AbstractDataCollectionItemRef {
        id: string;
        projectKey: string;

        // PolyJson type
        type: 'DATASET';
    }

    export namespace DatasetRef {
        export const type = 'DATASET';
    }


}