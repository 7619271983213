import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import type { CompareWithFn } from '@ng-select/ng-select/lib/ng-select.component';
import { normalizeTextForSearch } from '@utils/string-utils';
import _ from 'lodash';


/**
 * Lightweight wrapper around <ng-select> designed for simple text-based select menus
 * - Large list are supported: virtual scroll is always enabled
 * - Dropdown is always appended to <body>
 * - Compatible with AngularJS (via ngUpgrade)
 */
@Component({
    selector: 'basic-select',
    templateUrl: './basic-select.component.html',
    styleUrls: ['./basic-select.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: BasicSelectComponent,
        multi: true
    }]
})
export class BasicSelectComponent<ItemType extends {}> implements ControlValueAccessor, OnChanges {
    // List of items
    @Input() items?: ItemType[];

    // Property of each item containing the label
    @Input() bindLabel?: PropertyAccessor<ItemType>;

    // Whether a search box should be displayed
    @Input() searchable?: boolean;

    // Text to display when nothing is selected
    @Input() placeholder = '';

    // Single vs multiple selection
    @Input() multiple = false;

    @Input() titlePrefix = '';

    // Property of each item containing the value
    @Input() bindValue?: PropertyAccessor<ItemType>;

    // Property of each item containing the group name, in order to group items in categories
    @Input() groupBy?: PropertyAccessor<ItemType>;

    // Derive a key that uniquely represent each value (similar to "track by" in AngularJS "ng-options")
    // This can be used when the value are JS objects that cannot be compared by identity.
    // Note: the key/function is derived from the *value* of items (and NOT from the items themselves)
    @Input() trackBy?: PropertyAccessor<any>; // Not type-safe: we don't know type of values

    // Visible for testing purpose
    @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;

    selectedValue: unknown;
    ungroupedItems: UIRegularItem<ItemType>[] = [];
    groupedUiItems?: UIGroup<ItemType>[];
    trackByFn: (value: unknown) => unknown;
    searchFn?: (term: string, item: UIItem<ItemType>) => boolean;
    compareWith: CompareWithFn;
    onChange: (value: unknown) => void = () => { };

    constructor(
        private cd: ChangeDetectorRef,
        private ngZone: NgZone
    ) { }

    // for testing purpose only
    get nVisibilityComputed(): number {
        if (this.ungroupedItems.length === 0) {
            return 0;
        }

        return this.ungroupedItems[0].cache.nOps;
    }

    // Re-sync our internal UI items from the 'items' inputs
    updateUIItems() {
        const sharedCache = new VisibilityCache<ItemType>();

        this.ungroupedItems = (this.items ?? [])
            .map(item => makeRegularItem(item, this.bindLabel, this.bindValue, this.trackByFn, sharedCache));

        if (this.groupBy) {
            this.groupedUiItems = [];
            const groupBy = this.groupBy;

            const groupedItems = _.groupBy(this.ungroupedItems, (item) => String(readProperty(item.originalItem, groupBy)));
            const groupKeys = _.sortBy(Object.keys(groupedItems), group => normalizeTextForSearch(group));

            this.groupedUiItems = groupKeys.map(groupKey => makeGroup(groupKey, groupedItems[groupKey], sharedCache));
            // remove the separator from the last group (unused)
            if (this.groupedUiItems.length > 0) {
                this.groupedUiItems[this.groupedUiItems.length - 1].items.pop();
            }
        } else {
            this.groupedUiItems = undefined;
        }

        sharedCache.setState(this.ungroupedItems, this.groupedUiItems);
    }

    modelChanged(newValue: unknown) {
        this.selectedValue = newValue;
        this.onChange?.(newValue);
    }

    ngOnChanges() {
        const trackBy = this.trackBy;
        this.trackByFn = (value: any) => readProperty(value, trackBy);

        this.searchFn = (term: string, item: UIItem<ItemType>): boolean => {
            return item.cache.isVisible(term, item);
        };

        this.compareWith = (a: UIItem<ItemType>, b: unknown): boolean => {
            // Note: while the signature of this function looks surprisingly non-symmetric, it matches what ng-select passes to it
            return a.itemType == 'regular' && readProperty<unknown>(a.value, this.trackByFn) == readProperty(b, this.trackByFn);
        };

        this.updateUIItems();
    }

    writeValue(value: unknown): void {
        // writeValue() is not called from within Angular zone when a change of ngModel occurred in AngularJS (sc-102154)
        this.ngZone.run(() => {
            this.selectedValue = value;
            this.updateUIItems();
            this.cd.markForCheck();
        });
    }

    registerOnChange(fn: typeof this.onChange) {
        this.onChange = fn;
    }

    registerOnTouched() { }
}

interface UIItemBase<ItemType> {
    /** Properties below are consumed by <ng-select> */

    // <ng-select> interprets this to determine wether an item is selectable
    disabled: boolean;

    // <ng-select> interprets this as being the displayed label
    label: string;

    /** Properties below are used by us only */

    // the cache itself
    cache: VisibilityCache<ItemType>;
}

// A real item object passed to <ng-select>
interface UIRegularItem<ItemType> extends UIItemBase<ItemType> {
    /** Properties below are consumed by <ng-select>  */

    // <ng-select> interprets this as the value of this item
    value: unknown;

    /** Properties below are used by us only */

    // This is a real item and not a separator
    itemType: 'regular';

    // The original item provided by the consumer of this component
    // (UIRegularItem is used to wrap items provided by the user of <basic-select [items]="...">)
    originalItem: ItemType;

    // Result of applying the trackByFn on this item
    trackBy: unknown;

    // Normalized label of this item (pre-computed to speed up the search)
    normalizedLabel: string;
}

// Special fake-item which visually acts as visual separator
interface UISeparatorItem<ItemType> extends UIItemBase<ItemType> {
    /** Properties below are consumed by <ng-select> */

    // <ng-select> should not let the user select this (fake) item
    disabled: true;

    /** Properties below are used by us only */

    // This is not really a menu item. We trick <ng-select> so that we can display an horizontal separator at the end of each group (in grouped mode only)
    itemType: 'separator';
}

// Item in the list (fake or real)
type UIItem<ItemType> = UIRegularItem<ItemType> | UISeparatorItem<ItemType>;

// Group of items (Grouped items
type UIGroup<ItemType> = {
    /** Properties below are consumed by <ng-select> */

    items: UIItem<ItemType>[];
    label: string;
};

export type PropertyAccessor<ItemType> = (keyof ItemType & string) | ((value: ItemType) => unknown) | undefined;

function readProperty<T>(item: T, accessor: PropertyAccessor<T>) {
    if (typeof accessor == 'string' || typeof accessor == 'number') {
        return item[accessor];
    }
    if (typeof accessor == 'function') {
        return accessor(item);
    }
    return item;
}

function makeRegularItem<T>(
    item: T,
    bindLabel: PropertyAccessor<T>,
    bindValue: PropertyAccessor<T>,
    trackByFn: PropertyAccessor<unknown>,
    cache: VisibilityCache<T>,
): UIRegularItem<T> {
    const label = String(readProperty(item, bindLabel));
    const value = readProperty(item, bindValue);
    const normalizedLabel = normalizeTextForSearch(label);
    const trackBy = readProperty(value, trackByFn);

    return {
        disabled: false,
        itemType: 'regular',
        label,
        normalizedLabel,
        value,
        originalItem: item,
        trackBy,
        cache,
    };
}

function makeGroup<T>(
    name: string,
    group_items: UIRegularItem<T>[],
    cache: VisibilityCache<T>
): UIGroup<T> {
    // Work around https://github.com/ng-select/ng-select/issues/1991 by prefixing group names with a space
    const label = ` ${name}`;
    const items: UIItem<T>[] = [
        ...group_items,
        makeSeparator(cache),
    ];

    return {
        label,
        items,
    };
}

function makeSeparator<T>(
    cache: VisibilityCache<T>
): UISeparatorItem<T> {
    return {
        itemType: 'separator',
        label: ' -----', // for testing only (never displayed)
        disabled: true,
        cache,
    };
}

/*
* Since we are introducing fake items as separators in this select, we need to
* decide by ourselves whether a separator must be displayed when the items are
* filtered using the search box.
*
* Note: ng-select use an item-wise filter function which is impractical to
* decide whether the separator should be displayed. So we do a first pass to
* pre-compute the visibility. This implementation leverages caching for better
* efficiency.
*/
class VisibilityCache<T> {
    private searchTerm = '';
    private items: UIRegularItem<T>[] = [];
    private groups?: UIGroup<T>[];

    // holds the visibility of the element for the given search term
    private visibility: Map<UIItem<T>, boolean> = new Map();

    // for testing only (assert linear complexity)
    public nOps = 0;

    setState(items: UIRegularItem<T>[], groups?: UIGroup<T>[]) {
        this.items = items;
        this.groups = groups;
    }

    isVisible(searchTerm: string, item: UIItem<T>): boolean {
        // fast path to avoid unnecessary computation
        if (searchTerm === '') {
            return true;
        }

        if (this.searchTerm !== searchTerm) {
            this.searchTerm = searchTerm;
            const normalizedSearchTerm = normalizeTextForSearch(searchTerm);
            this.refreshVisibility(normalizedSearchTerm);
        }

        return this.visibility.get(item) ?? false;
    }

    private refreshVisibility(normalizedSearchTerm: string) {
        this.nOps = 0;
        this.visibility = new Map<UIItem<T>, boolean>();

        if (this.groups == null) {
            // no grouping: a regular item is visible iif the normalized label matches
            for (let i = 0; i < this.items.length; i++) {
                const item = this.items[i];
                const labelMatch = item.normalizedLabel.indexOf(normalizedSearchTerm) != -1;
                this.visibility.set(item, labelMatch);
                this.nOps++;
            }

            return;
        }

        // grouping: a group is visible iif it contains at least one visible regular item
        let hasPreviousVisibleGroup = false;

        // run backwards to know whether we have to display separators
        for (let i = this.groups.length - 1; i >= 0; i--) {
            const group = this.groups[i];
            let atLeastOneLabelMatch = false;

            for (let j = 0; j < group.items.length; j++) {
                const item = group.items[j];

                if (item.itemType == "regular") {
                    // a regular item is visible iif the normalized label matches
                    const labelMatch = item.normalizedLabel.indexOf(normalizedSearchTerm) != -1;
                    this.visibility.set(item, labelMatch);
                    atLeastOneLabelMatch ||= labelMatch;

                } else {
                    // a separator is visible iif the group is visible and there is another visible group after
                    // note: a separator is always the last item in the group
                    this.visibility.set(item, atLeastOneLabelMatch && hasPreviousVisibleGroup);
                }
                this.nOps++;
            }
            hasPreviousVisibleGroup ||= atLeastOneLabelMatch;
        }
    }
}
