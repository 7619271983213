<p>
    The Durbin-Watson statistic tests the hypothesis that there is no
    first order autocorrelation in <b>{{ params.seriesColumn.name }}</b>,
    with the alternate hypothesis that there is a first order autocorrelation
    (either positive or negative).
</p>

<p>
    First order autocorrelation means that the series is correlated with a lagged version of itself,
    using a lag value of 1.
</p>

<p>
    This test assumes that the series is stationary, normally distributed and centered around 0.
    Such conditions can be met when the series contains the residuals between a model and real data
    are computed using ordinary least squares, for instance.
</p>

<p>
    The statistic value <em>stat</em> always statisfies <em>0 &le; stat &le; 4</em>.
    There are three possible outcomes depending on the statistic:
</p>

<ul>
    <li>
        <b>the closer to 0 the statistic</b>,
        the more evidence for positive serial correlation.
    </li>
    <li>
        <b>the closer to 4 the statistic</b>,
        the more evidence for negative serial correlation.
    </li>
    <li>
        <b>the closer to 2 the statistic</b>,
        the more evidence for no serial correlation.
    </li>
</ul>

<p>
    A rule of thumb is that Durbin-Watson statistic values in the range of 1.5
    to 2.5 do not show enough evidence for serial correlation.
</p>
