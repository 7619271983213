export enum NavUniverses {
    LOGIN = "LOGIN",
    DSS_HOME = "DSS_HOME",
    HOME = "HOME",
    FLOW = "FLOW",
    ANALYSES = "ANALYSES",
    NOTEBOOKS = "NOTEBOOKS",
    JOBS = "JOBS",
    DASHBOARD = "DASHBOARD",
    WIKI = "WIKI",
    API_DEPLOYER = "API_DEPLOYER",
    MORE = "MORE"
}
