import { Fit2DDistribution } from 'src/generated-sources';
import { decompactArray } from '@utils/compacted-array';
import { scaleLinear } from 'd3-scale';
import { range } from 'd3-array';
import { rgb } from 'd3-color';
import { fairAny } from 'dku-frontend-core';

export class DensityMap {
    constructor(
        public density: Float64Array,
        public xMin: number,
        public xMax: number,
        public yMin: number,
        public yMax: number,
        public dMin: number,
        public dMax: number,
        public xResolution: number,
        public yResolution: number
    ) { }

    static async createFromResult(result: Fit2DDistribution.Density) {
        const density: Float64Array = await decompactArray(result.data);
        const { xMin, xMax, yMin, yMax } = result;
        const [xResolution, yResolution] = result.data.shape;
        return new DensityMap(density, xMin, xMax, yMin, yMax, result.data.vmin, result.data.vmax, xResolution, yResolution);
    }

    // Convert a point in data domain -> pixel in density map
    // Note: "density map pixels" are not the same thing as "screen pixels"
    //       (density map -> screen mapping is handled by echarts)
    dataPointToPixel([x, y]: [number, number]): [number, number] {
        return [
            Math.floor(this.xResolution * (x - this.xMin) / (this.xMax - this.xMin)),
            Math.floor(this.yResolution * (y - this.yMin) / (this.yMax - this.yMin))
        ];
    }

    valueAtPixel([x, y]: [number, number]): number {
        x = Math.max(0, Math.min(x, this.xResolution - 1));
        y = Math.max(0, Math.min(y, this.yResolution - 1));
        return this.density[x * this.yResolution + y];
    }

    valueAtDataPoint([x, y]: [number, number]): number {
        return this.valueAtPixel(this.dataPointToPixel([x, y]));
    }

    createDensityImage() {
        const canvas = document.createElement('canvas');
        canvas.width = this.xResolution;
        canvas.height = this.yResolution;
        const ctx = canvas.getContext('2d')!;

        // Define a color mapping
        const colors = ['#ffffff', '#1cc8fb', '#e5ff3a', '#ffc42f', '#fe5924', '#d11d1d', '#841211'];
        const delta = this.dMax / (colors.length - 1);
        const colorMap = scaleLinear<fairAny>()
            .domain(range(colors.length).map(i => delta * i))
            .range(colors);

        // Map values to colors
        const imageData = ctx.createImageData(this.xResolution, this.yResolution);
        const rawData = imageData.data;
        for (let ix = 0; ix < this.xResolution; ix++) {
            for (let iy = 0; iy < this.yResolution; iy++) {
                const srcIdx = ix * this.yResolution + iy;

                const dstIdx = (this.yResolution - iy - 1) * this.xResolution + ix;
                const value = this.density[srcIdx];
                const color = rgb(colorMap(value));

                rawData[dstIdx * 4] = color.r;
                rawData[dstIdx * 4 + 1] = color.g;
                rawData[dstIdx * 4 + 2] = color.b;
                rawData[dstIdx * 4 + 3] = 255;

            }
        }
        ctx.putImageData(imageData, 0, 0);

        return canvas;
    }
}

