import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DatasetMetricsStatusService } from 'src/generated-sources';

export enum DATASET_METRIC {
  PARTITIONING = "PARTITIONING",
  SIZE = "SIZE",
  RECORDS = "RECORDS"
}

export interface RefreshStatusEvent {
  metricToRefresh: DATASET_METRIC, forceRefresh: boolean
}

@Component({
  selector: 'right-panel-dataset-status',
  templateUrl: './right-panel-dataset-status.component.html',
  styleUrls: ['./right-panel-dataset-status.component.less']
})
export class RightPanelDatasetStatusComponent {
  DATASET_METRIC = DATASET_METRIC;
  @Input() status: DatasetMetricsStatusService.DatasetSummaryStatus;
  @Input() showPartitionning: boolean = false;
  @Input() showSize: boolean = false;
  @Input() canUpdate: boolean = false;
  @Output() refreshStatus = new EventEmitter<RefreshStatusEvent>();

  constructor() { }
}
