import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { UIDataCollection } from 'src/generated-sources';

@Component({
    selector: 'data-collection-card',
    templateUrl: './data-collection-card.component.html',
    styleUrls: ['./data-collection-card.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataCollectionCardComponent {
    @Input() dataCollection!: UIDataCollection.ListItemWithDetails;

    constructor(
    ) { }
}
