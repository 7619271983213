import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {Grouping} from './../../grouping/grouping';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.KsTest2Samp
 */
export interface KsTest2Samp extends Computation._UnivariateComputation {
    grouping: Grouping;

    // PolyJson type
    type: 'ks_test_2samp';
}

export namespace KsTest2Samp {
    export const type = 'ks_test_2samp';
}

/** @deprecated Use Computation.isKsTest2Samp() */ export function isKsTest2Samp(toTest: Computation): toTest is KsTest2Samp { return KsTest2Samp.type === toTest['type']; }

export namespace KsTest2Samp {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.KsTest2Samp$KsTest2SampResult
     */
    export interface KsTest2SampResult extends _AvailableResult {
        pvalue: number;
        statistic: number;

        // PolyJson type
        type: 'ks_test_2samp';
    }

    export namespace KsTest2SampResult {
        export const type = 'ks_test_2samp';
    }

    /** @deprecated Use ComputationResult.isKsTest2SampResult() */ export function isKsTest2SampResult(toTest: ComputationResult): toTest is KsTest2SampResult { return KsTest2SampResult.type === toTest['type']; }
}