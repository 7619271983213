import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {Grouping} from './../../grouping/grouping';
import type {PValueAdjustmentMethod} from './../../stats/pvalue-adjustment-method';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.PairwiseTTest
 */
export interface PairwiseTTest extends Computation._UnivariateComputation {
    adjustmentMethod: PValueAdjustmentMethod;
    grouping: Grouping;

    // PolyJson type
    type: 'pairwise_ttest';
}

export namespace PairwiseTTest {
    export const type = 'pairwise_ttest';
}

/** @deprecated Use Computation.isPairwiseTTest() */ export function isPairwiseTTest(toTest: Computation): toTest is PairwiseTTest { return PairwiseTTest.type === toTest['type']; }

export namespace PairwiseTTest {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.PairwiseTTest$PairwiseTTestResult
     */
    export interface PairwiseTTestResult extends _AvailableResult {
        pvalues: number[];
        statistics: number[];

        // PolyJson type
        type: 'pairwise_ttest';
    }

    export namespace PairwiseTTestResult {
        export const type = 'pairwise_ttest';
    }

    /** @deprecated Use ComputationResult.isPairwiseTTestResult() */ export function isPairwiseTTestResult(toTest: ComputationResult): toTest is PairwiseTTestResult { return PairwiseTTestResult.type === toTest['type']; }
}