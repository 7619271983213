<api-error-alert-from-context></api-error-alert-from-context>
<div *ngIf="selectedItem === undefined"
    class="data-collection-right-panel__no-selection"
>
    <i class="dku-icon-cursor-click-48"></i>
    <p>Click an item to view details</p>
</div>
<div *ngIf="selectedItem && isItemDetailsLoading$.value"
    class="data-collection-right-panel__no-selection"
>
    <p>Loading...</p>
</div>
<ng-container *ngIf="!isItemDetailsLoading$.value">
    <data-collection-page-right-panel-dataset *ngIf="isSelectedItemReadableDataset() && currentUserPermissions"
        [selectedTab]="selectedTab"
        [details]="selectedItemDetails" [currentUserPermissions]="currentUserPermissions"
        (toggleStarred)="rightPanelContext.toggleStarred(selectedItemDetails, $event)"
        (toggleWatched)="rightPanelContext.toggleWatched(selectedItemDetails, $event)"
        (removeSelectedItem)="removeSelectedItem.emit(selectedItem)"
        (preview)="rightPanelContext.preview(selectedItemDetails)" (share)="share(selectedItemDetails)"
        (requestShare)="rightPanelContext.requestShare(selectedItemDetails, 'data-collection')"
        (export)="rightPanelContext.exportDataset(selectedItemDetails)"
        (shareToDashboard)="rightPanelContext.shareToDashboard(selectedItemDetails)"
        (shareToWorkspace)="rightPanelContext.shareToWorkspace(selectedItemDetails)"
        (addToFeatureStore)="rightPanelContext.addToFeatureStore(selectedItemDetails)"
        (addToDataCollection)="rightPanelContext.addToDataCollection(selectedItemDetails)"
        (editDataSteward)="editDataSteward(selectedItemDetails)"
        (refreshStatus)="rightPanelContext.refreshStatus(selectedItemDetails, $event)">
    </data-collection-page-right-panel-dataset>

    <data-collection-page-right-panel-discoverable-dataset
        *ngIf="isSelectedItemDiscoverableDataset() && currentUserPermissions" [details]="selectedItemDetails"
        [currentUserPermissions]="currentUserPermissions" (removeSelectedItem)="removeSelectedItem.emit(selectedItem)"
        (requestShare)="rightPanelContext.requestShare(selectedItemDetails, 'data-collection')"
        (addToDataCollection)="rightPanelContext.addToDataCollection(selectedItemDetails)">
    </data-collection-page-right-panel-discoverable-dataset>

</ng-container>
