<div [style.--workspace-color]="editedWorkspace.color">
    <div class="control-group">
        <label for="displayName" class="control-label">Name</label>
        <div class="controls">
            <input type="text" placeholder="Workspace name" name="displayName"
                [(ngModel)]="editedWorkspace.displayName"
                (ngModelChange)="onDisplayNameChange()"
                required>
        </div>
    </div>

    <div class="control-group">
        <div class="controls workspace-general-edition__color-options">
            <initials-icon
                class="workspace-general-edition__badge-preview"
                [name]="editedWorkspace.displayName || 'Workspace'"
                [color]="editedWorkspace.color"
            ></initials-icon>
            <stock-color-picker
                [color]="editedWorkspace.color"
                (colorChange)="onColorChange($event)"
            ></stock-color-picker>
        </div>
    </div>

    <div class="control-group" *ngIf="!editionMode">
        <label for="workspaceKey" class="control-label">Workspace Key</label>
        <div class="controls">
            <input type="text"
                name="workspaceKey"
                [(ngModel)]="editedWorkspace.workspaceKey"
                (ngModelChange)="onWorkspaceChange()"
                pattern="/^\w+$/"
                placeholder="Workspace key"
                required>
        </div>
    </div>

    <div class="control-group workspace-general-edition__description">
        <label for="description" class="control-label">Description</label>
        <div class="controls">
            <autocompletable-textarea
                [(model)]="editedWorkspace.description"
                (modelChange)="onWorkspaceChange()"
                [textarea]="{ name: 'description', placeholder: 'Workspace description' }"
                options="{ autofocus: false }">
            </autocompletable-textarea>
        </div>
    </div>
</div>
