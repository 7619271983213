<div class="h100 paned-page-section paned-page-section--no-border vertical-flex pad0" *ngIf="dataFetcher.filter$ | async as filter">
    <div class="image-feed__header">
        <h2 class="paned-page-section__subheading">
            {{ dataFetcher.totalItems! }} {{ 'Image' | plurify: dataFetcher.totalItems!}}
        </h2>

        <div *ngIf="filter?.sorting as sorting">
            Sort by <a class="image-feed__sorting-by-link" [class.image-feed__sorting-by-link--selected]="sorting.sortBy === SortBy.CONFIDENCE" (click)="sorting.sortBy !== SortBy.CONFIDENCE && setSortingByConfidence(filter)">Confidence</a> |
            <a class="image-feed__sorting-by-link" [class.image-feed__sorting-by-link--selected]="sorting.sortBy === SortBy.IOU" (click)="sorting.sortBy !== SortBy.IOU && setSortingByIOU(filter)">IOU</a>
            &nbsp; <i *ngIf="sorting.ascending" (click)='setSortingDescending(filter)' class="icon-sort-by-attributes image-feed__sorting-order"></i>
                    <i *ngIf="!sorting.ascending" (click)='setSortingAscending(filter)' class="icon-sort-by-attributes-alt image-feed__sorting-order"></i>
        </div>

    </div>
    <div class="padleftright16 mbot8">
        <deephub-object-detection-report-image-legend></deephub-object-detection-report-image-legend>
    </div>
    <div class="image-feed__feed">
        <div *ngIf="dataFetcher.totalItems! === 0" class="empty-list-cta">
            <p>No images found</p>
        </div>
        <image-feed class="h100" [imageHeight]="200" [itemsPerRow]="2" (imageClick)="openModal($event)"></image-feed>
    </div>
</div>
