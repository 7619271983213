import { Injectable } from '@angular/core';
import { Label, LabelingAnswer, LabelingTask } from 'src/generated-sources';
import { AnnotationGroup } from "@features/labeling/models/annotation-group";
import { AnnotationFactory } from './annotation.factory';
import { UIAnnotation } from '../models/annotation';

@Injectable()
export class LabelingAnswerService {

    constructor(
        private annotationFactory: AnnotationFactory
    ) { }

    createAnswerFromLabel(projectKey: string, labelingTaskId: string, label: Label, recordId: string): Partial<LabelingAnswer> {
        return {
            projectKey,
            labelingTaskId,
            recordId,
            label
        };
    }

    answerToSaveFromAnnotationGroupList(projectKey: string, labelingTaskId: string, type: LabelingTask.LabelingTaskType, annotationGroupList: AnnotationGroup[], path: string): Partial<LabelingAnswer> {
        const annotations: UIAnnotation[] = [];
        annotationGroupList.forEach(annotationGroup => {
            annotations.push(annotationGroup.computeAverageObject());
        });
    
        const label = this.annotationFactory
            .fromAnnotations(annotations)
            .toPreparedLabel();
    
        return this.createAnswerFromLabel(projectKey, labelingTaskId, label, path);
    }
}
