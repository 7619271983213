import { Component, ChangeDetectionStrategy, Input, Inject, OnChanges } from '@angular/core';
import { APIError, EnhancedSerializedError } from '@core/dataiku-api/api-error';
import { InfoMessage } from 'dku-frontend-core';
import { NavigatorService } from 'src/generated-sources';
import type { IScope } from 'angular';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

enum DatasetStatus {
    NONE = 'NONE',
    BEING_BUILT = 'BEING_BUILT',
    ABOUT_TO_BE_BUILT = 'ABOUT_TO_BE_BUILT',
    EMPTY_BUILD = 'EMPTY_BUILD',
    FAILED_BUILD = 'FAILED_BUILD'
}

@UntilDestroy()
@Component({
    selector: 'dataset-error-cta',
    templateUrl: './dataset-error-cta.component.html',
    styleUrls: ['./dataset-error-cta.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatasetErrorCtaComponent implements OnChanges {
    @Input() apiError: APIError | null | undefined;
    @Input() datasetFullInfo: NavigatorService.DatasetFullInfo;

    DatasetStatus = DatasetStatus;
    datasetStatus: DatasetStatus;

    constructor(
        private DataikuAPI: DataikuAPIService,
        @Inject('CreateModalFromTemplate') private createOldModal: any,
        @Inject('$rootScope') private $rootScope: IScope,
        @Inject('$state') private $state: any
    ) { }

    ngOnChanges() {
        /*
            This is essentially a port of the AngularJS datasetErrorCta service
            (datasets/directives.js).

            AngularJS Template: templates/datasets/fragments/dataset-error-cta.html
        */
        const info = this.datasetFullInfo;
        const apiError = this.apiError;
        const managed = !!info?.dataset?.managed;
        const beingBuilt = !!info?.currentBuildState?.beingBuilt?.length;
        const aboutToBeBuilt = !!info?.currentBuildState?.aboutToBeBuilt?.length;
        const neverBuiltBuildable = !!info?.buildable && info?.lastBuild === undefined;
        const buildable = !!info?.buildable;
        const isNotUnauthorized = apiError?.errorType !== 'com.dataiku.dip.exceptions.UnauthorizedException';
        const requiresDatasetBuild = [
            InfoMessage.FixabilityCategory.USER_CONFIG_DATASET,
            InfoMessage.FixabilityCategory.USER_CONFIG_OR_BUILD
        ].includes((apiError as EnhancedSerializedError)?.fixability);
        let status = DatasetStatus.NONE;

        if (apiError && !this.ignoreError(apiError)) {
            if (beingBuilt && isNotUnauthorized) {
                status = DatasetStatus.BEING_BUILT;
            } else if (aboutToBeBuilt && isNotUnauthorized) {
                status = DatasetStatus.ABOUT_TO_BE_BUILT;
            } else if (!beingBuilt && !aboutToBeBuilt && managed) {
                if (neverBuiltBuildable) {
                    status = DatasetStatus.EMPTY_BUILD;
                } else if (buildable && requiresDatasetBuild) {
                    status = DatasetStatus.FAILED_BUILD;
                }
            }
        }
        this.datasetStatus = status;
    }

    buildOpenDataset(projectKey: string, datasetName: string) {
        this.DataikuAPI.datasets.get(projectKey, datasetName, projectKey)
            .pipe(untilDestroyed(this))
            .subscribe((dataset) => {
                this.createOldModal('/templates/datasets/build-dataset-modal.html',
                    this.$rootScope, 'BuildDatasetController', (newScope: any) => {
                        newScope.jobStartRedirects = true;
                        newScope.dataset = dataset;
                    }, 'build-dataset-modal').catch(() => { });
            });
    }

    goToJob(jobRef: {jobId: string, projectKey: string}) {
        this.$state.go('projects.project.jobs.job', jobRef, { location: 'replace' });
    }

    ignoreError(apiError: APIError | null) {
        const ignorableErrorTypes = ['FutureAbort'];
        return apiError && ignorableErrorTypes.indexOf(apiError.errorType) >= 0;
    }
}
