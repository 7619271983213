import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';


@UntilDestroy()
@Component({
    selector: 'text-formatting-form',
    templateUrl: './text-formatting-form.component.html'
})

export class TextFormattingFormComponent {

    public colors: Array<string>;
    public showBackgroundSettings$ = new BehaviorSubject(true);
    public showFontColorSettings$ = new BehaviorSubject(true);

    @Input()
    set textFormatting(value: ChartDef.TextFormattingOptions) {
        if (!this.equals(value, this.textFormattingForm.getRawValue())) {
            this.textFormattingForm.patchValue(value);
        }
    }

    @Input()
    set showBackgroundSettings(value: boolean) {
        this.showBackgroundSettings$.next(value);
        if (value === false) {
            this.textFormattingForm.removeControl('hasBackground');
            this.textFormattingForm.removeControl('backgroundColor');
        }
    }

    @Input()
    set showFontColorSettings(value: boolean) {
        this.showFontColorSettings$.next(value);
        if (value === false) {
            this.textFormattingForm.removeControl('fontColor');
        }
    }

    @Output() textFormattingChange = new EventEmitter<ChartDef.TextFormattingOptions>();

    public textFormattingForm = new FormGroup({
        fontSize: new FormControl(11),
        fontColor: new FormControl('#333'),
        hasBackground: new FormControl(false),
        backgroundColor: new FormControl('#D9D9D9BF')
    });

    constructor() {
        this.colors = (window as any).dkuColorPalettes.discrete[1].colors;

        this.textFormattingForm.valueChanges
            .pipe(
                untilDestroyed(this),
                debounceTime(10),
                distinctUntilChanged(this.equals),
            )
            .subscribe((nextValue: ChartDef.TextFormattingOptions) => {
                this.textFormattingChange.emit({ ...nextValue });
            });
    }

    equals(textFormatting: ChartDef.TextFormattingOptions, textFormattingForm: ChartDef.TextFormattingOptions): boolean {
        return textFormatting && textFormatting.fontSize === textFormattingForm.fontSize && textFormatting.fontColor === textFormattingForm.fontColor &&
        ((isNil(textFormattingForm.hasBackground) && isNil(textFormattingForm.backgroundColor)) || (textFormatting.hasBackground === textFormattingForm.hasBackground && textFormatting.backgroundColor === textFormattingForm.backgroundColor));
    }
}
