import { Injectable } from '@angular/core';

const PATTERN_CANVAS_SIDE_LENGTH = 10;
const PATTERN_WIDTH = PATTERN_CANVAS_SIDE_LENGTH;
const PATTERN_HEIGHT = PATTERN_CANVAS_SIDE_LENGTH;
const PATTERN_DIVISIONS = 6;

/**
 * ECharts accepts a pattern wherever a color is expected
 *
 * Undocumented in ECharts, but possibly documented in 'zrender'.
 * I don't speak chinese so I can't check.
 */
interface EChartPattern {
    type: 'pattern';
    image: HTMLCanvasElement;
    repeat: 'repeat' | 'repeat-x' | 'repeat-y';
}

/**
 * Generate color patterns (such as stripes)
 * A pattern a small image which can be repeated to create the desired effect
 */
@Injectable({ providedIn: 'root' })
export class PatternsService {
    patternCache: Map<string, EChartPattern> = new Map();

    // Re-use a cache pattern or generate a new one
    // Beware: it can create memory leaks if a lot of different colors are requested (cache never expires)
    public getStripePattern(stripeColor: string, backgroundColor?: string): EChartPattern {
        const cacheKey = stripeColor + '-' + backgroundColor;
        let pattern = this.patternCache.get(cacheKey);
        if (!pattern) {
            pattern = this.generateStripePattern(stripeColor, backgroundColor);
            this.patternCache.set(cacheKey, pattern);
        }
        return pattern;
    }

    // Derived from https://stackoverflow.com/a/47288427
    public generateStripePattern(stripeColor: string, backgroundColor?: string): EChartPattern {
        const patternCanvas = document.createElement('canvas');
        patternCanvas.width = PATTERN_WIDTH;
        patternCanvas.height = PATTERN_HEIGHT;

        const pctx = patternCanvas.getContext('2d')!;
        pctx.imageSmoothingEnabled = true;

        // Fill background if a color is provided (otherwise, it is transparent)
        if (backgroundColor) {
            pctx.fillStyle = backgroundColor;
            pctx.fillRect(0, 0, patternCanvas.width, patternCanvas.height);
        }

        // Draw the stripes
        pctx.fillStyle = stripeColor;

        // Top line
        pctx.beginPath();
        pctx.moveTo(0, PATTERN_HEIGHT * (1 / PATTERN_DIVISIONS));
        pctx.lineTo(PATTERN_WIDTH * (1 / PATTERN_DIVISIONS), 0);
        pctx.lineTo(0, 0);
        pctx.lineTo(0, PATTERN_HEIGHT * (1 / PATTERN_DIVISIONS));
        pctx.fill();

        // Middle line
        pctx.beginPath();
        pctx.moveTo(PATTERN_WIDTH, PATTERN_HEIGHT * (1 / PATTERN_DIVISIONS));
        pctx.lineTo(PATTERN_WIDTH * (1 / PATTERN_DIVISIONS), PATTERN_HEIGHT);
        pctx.lineTo(0, PATTERN_HEIGHT);
        pctx.lineTo(0, PATTERN_HEIGHT * ((PATTERN_DIVISIONS - 1) / PATTERN_DIVISIONS));
        pctx.lineTo(PATTERN_WIDTH * ((PATTERN_DIVISIONS - 1) / PATTERN_DIVISIONS), 0);
        pctx.lineTo(PATTERN_WIDTH, 0);
        pctx.lineTo(PATTERN_WIDTH, PATTERN_HEIGHT * (1 / PATTERN_DIVISIONS));
        pctx.fill();

        // Bottom line
        pctx.beginPath();
        pctx.moveTo(PATTERN_WIDTH, PATTERN_HEIGHT * ((PATTERN_DIVISIONS - 1) / PATTERN_DIVISIONS));
        pctx.lineTo(PATTERN_WIDTH * ((PATTERN_DIVISIONS - 1) / PATTERN_DIVISIONS), PATTERN_HEIGHT);
        pctx.lineTo(PATTERN_WIDTH, PATTERN_HEIGHT);
        pctx.lineTo(PATTERN_WIDTH, PATTERN_HEIGHT * ((PATTERN_DIVISIONS - 1) / PATTERN_DIVISIONS));
        pctx.fill();

        return { type: 'pattern', image: patternCanvas, repeat: 'repeat' };
    }
}
