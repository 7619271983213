import { Injectable } from '@angular/core';
import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';
import { ReferenceLineAxisOptionsType } from '../reference-line-axis-options-type.enum';

@Injectable({
    providedIn: 'root'
})
export class ReferenceLineOptionsService {

    public labelPositionOptions = [{
        id: ChartDef.ReferenceLinePosition.INSIDE_START_TOP,
        name: 'Top Left'
    }, {
        id: ChartDef.ReferenceLinePosition.INSIDE_END_TOP,
        name: 'Top Right'
    }, {
        id: ChartDef.ReferenceLinePosition.INSIDE_START_BOTTOM,
        name: 'Bottom Left'
    }, {
        id: ChartDef.ReferenceLinePosition.INSIDE_END_BOTTOM,
        name: 'Bottom Right'
    }];

    public colors = (window as any).dkuColorPalettes.discrete[1].colors;

    public styleOptions = [{
        id: ChartDef.ChartLineType.DASHED,
        name: 'Dashed'
    }, {
        id: ChartDef.ChartLineType.FILLED,
        name: 'Filled'
    }];


    public multiplierOptions = [{
        id: ChartDef.ReferenceLineMultiplier.Inherit,
        name: 'Inherit from axis'
    },{
        id: ChartDef.ReferenceLineMultiplier.Auto,
        name: 'Auto'
    }, {
        id: ChartDef.ReferenceLineMultiplier.None,
        name: 'None'
    }, {
        id: ChartDef.ReferenceLineMultiplier.Thousands,
        name: 'Thousands'
    }, {
        id: ChartDef.ReferenceLineMultiplier.Millions,
        name: 'Millions'
    }, {
        id: ChartDef.ReferenceLineMultiplier.Billions,
        name: 'Billions'
    }];

    public getAxisOptions(type: ReferenceLineAxisOptionsType) {
        const leftYAxisOption = {
            id: ChartDef.ReferenceLineAxis.LEFT_Y_AXIS,
            name: 'Left Y axis'
        };

        const rightYAxisOptions = {
            id: ChartDef.ReferenceLineAxis.RIGHT_Y_AXIS,
            name: 'Right Y axis'
        };

        const xAxisOptions = {
            id: ChartDef.ReferenceLineAxis.X_AXIS,
            name: 'X axis'
        };

        switch(type) {
            case ReferenceLineAxisOptionsType.ONLY_X:
                return [xAxisOptions];
            case ReferenceLineAxisOptionsType.X_AND_LEFT_Y:
                leftYAxisOption.name = 'Y axis';
                return [xAxisOptions, leftYAxisOption];
            case ReferenceLineAxisOptionsType.ALL_Y:
                return [leftYAxisOption, rightYAxisOptions];
            case ReferenceLineAxisOptionsType.ALL:
            default:
                return [leftYAxisOption, rightYAxisOptions, xAxisOptions];
        }
    }
}
