import type {Distribution, _Distribution} from './distribution';

/**
 * Generated from com.dataiku.dip.eda.compute.distributions.Normal
 */
export interface Normal extends _Distribution {
    // PolyJson type
    type: 'normal';
}

export namespace Normal {
    export const type = 'normal';
}

/** @deprecated Use Distribution.isNormal() */ export function isNormal(toTest: Distribution): toTest is Normal { return Normal.type === toTest['type']; }

export namespace Normal {
    /**
     * Generated from com.dataiku.dip.eda.compute.distributions.Normal$FittedNormal
     */
    export interface FittedNormal extends Distribution._FittedDistribution {
        mean: number;
        std: number;

        // PolyJson type
        type: 'normal';
    }

    export namespace FittedNormal {
        export const type = 'normal';
    }

    /** @deprecated Use Distribution.FittedDistribution.isFittedNormal() */ export function isFittedNormal(toTest: Distribution.FittedDistribution): toTest is FittedNormal { return FittedNormal.type === toTest['type']; }
}