import { Directive, ElementRef, Injector, Input } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";

@Directive({
    selector: 'angularjs-managed-folder-contents-view'
})
export class AngularJSManagedFolderContentsView extends UpgradeComponent {
    @Input() odb: object;
    @Input() subFolderStartingPoint?: string;
    @Input() initialSubFolder?: string;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('ng1ManagedFolderContentsView', elementRef, injector);
    }
}