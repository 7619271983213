import { FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

/*
    Validate that an object is not empty
*/
export function objectNotEmptyValidator(): ValidatorFn {
    return function validate(control: AbstractControl) {
        const value = control.value || {};

        return Object.keys(value).length > 0 && value.constructor === Object ? null : {
            objectEmpty: true
        };
    };
}
