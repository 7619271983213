export namespace GeneralSettingsDAO.InheritableEnabledSetting {
    /**
     * Generated from com.dataiku.dip.dao.GeneralSettingsDAO$InheritableEnabledSetting$LocalValue
     */
    export enum LocalValue {
        ENABLED = 'ENABLED',
        DISABLED = 'DISABLED',
        INHERIT = 'INHERIT'
    }
}

export namespace GeneralSettingsDAO {
    /**
     * Generated from com.dataiku.dip.dao.GeneralSettingsDAO$SoftHardLimit
     */
    export interface SoftHardLimit {
        hard: number;
        soft: number;
    }

    /**
     * Generated from com.dataiku.dip.dao.GeneralSettingsDAO$ProjectDefaultDatasetCreationSettings
     */
    export interface ProjectDefaultDatasetCreationSettings extends GeneralSettingsDAO.AbstractDefaultDatasetCreationSettings {
        useGlobal: boolean;
    }

    /**
     * Generated from com.dataiku.dip.dao.GeneralSettingsDAO$AbstractDefaultDatasetCreationSettings
     */
    export interface AbstractDefaultDatasetCreationSettings {
        allowUploadsWithoutConnection: boolean;
        forcedPreferedConnection: string;
        preferedConnection: string;
        preferedStorageFormats: string;
        preferedUploadConnection: string;
        virtualizable: boolean;
    }
}