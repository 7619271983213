import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QaSelectorDirective } from './qa-selector/qa-selector.directive';
import { QaSelectorService } from './qa-selector/qa-selector.service';
import { QaSelectorComponent } from './qa-selector/qa-selector.component';
import { QaModeService } from './qa-mode/qa-mode.service';

@NgModule({
    declarations: [
        QaSelectorDirective,
        QaSelectorComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        QaSelectorDirective,
    ],
    providers: [
        QaSelectorService,
        QaModeService,
    ]
})
export class QaModule { }
