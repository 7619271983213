import type {CodeEnvSelection} from './../code/code-env-selection';
import type {ConditionalOutput} from './../coremodel/conditional-output';
import type {MLTask} from './../analysis/model/mltask';
import type {PartitioningScheme} from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/partitioning/partitioning-scheme';
import type {PredictionMLTask} from './../analysis/model/prediction/prediction-mltask';
import type {SerializedDataset} from './../coremodel/serialized-dataset';
import type {TaggableObjectsService} from './../server/services/taggable-objects-service';
import type {VersionTag} from './../coremodel/version-tag';

/**
 * Generated from com.dataiku.dip.dao.SavedModel
 */
export interface SavedModel extends TaggableObjectsService._TaggableObject {
    activeVersion: string;
    conditionalOutputs: ConditionalOutput[];
    contentType: string;
    finedTunedLLMType: any /* TODO: Missing translation of com.dataiku.dip.llm.promptstudio.PromptStudio$LLMType */;
    flowOptions: SerializedDataset.FlowOptions;
    id: string;
    inlineVersions: SavedModel.SavedModelInlineVersion[];
    lastExportedFrom: string;
    lastTrainIndex: number;
    lastTrainJobId: string;
    llmTaskType: MLTask.MLTaskType;
    metrics: any /* TODO: Missing translation of com.dataiku.dip.metrics.ProbesSet */;
    metricsChecks: any /* TODO: Missing translation of com.dataiku.dip.metrics.ChecksSet */;
    miniTask: MLTask;
    name: string;
    partitioning: PartitioningScheme;
    projectKey: string;
    proxyModelConnection: string;
    proxyModelProtocol: string;
    proxyModelRegion: string;
    publishPolicy: SavedModel.ModelPublishPolicy;
    rebuildBehavior: SerializedDataset.RebuildBehavior;
    savedModelType: SavedModel.SavedModelType;
}

export namespace SavedModel {
    /**
     * Generated from com.dataiku.dip.dao.SavedModel$ModelPublishPolicy
     */
    export enum ModelPublishPolicy {
        MANUAL = 'MANUAL',
        UNCONDITIONAL = 'UNCONDITIONAL'
    }

    /**
     * Generated from com.dataiku.dip.dao.SavedModel$SavedModelType
     */
    export enum SavedModelType {
        DSS_MANAGED = 'DSS_MANAGED',
        MLFLOW_PYFUNC = 'MLFLOW_PYFUNC',
        PROXY_MODEL = 'PROXY_MODEL',
        LANGCHAIN_INLINE = 'LANGCHAIN_INLINE',
        LLM_GENERIC = 'LLM_GENERIC'
    }

    /**
     * Generated from com.dataiku.dip.dao.SavedModel$SavedModelInlineVersion
     */
    export interface SavedModelInlineVersion {
        code: string;
        codeEnvSelection: CodeEnvSelection;
        creationTag?: VersionTag | null;
        description: string;
        versionId: string;
        versionTag?: VersionTag | null;
    }

    /**
     * Generated from com.dataiku.dip.dao.SavedModel$SavedModelListItem
     */
    export interface SavedModelListItem extends TaggableObjectsService._TaggableListItem {
        backendType: MLTask.BackendType;
        predictionType?: PredictionMLTask.PredictionType | null;
        proxyModelConnection?: string | null;
        proxyModelProtocol?: string | null;
        proxyModelRegion?: string | null;
        savedModelType: SavedModel.SavedModelType;
        type: MLTask.MLTaskType;
        versionsCount: number;
    }
}