import { TaggableObjectsService } from '@model-main/server/services/taggable-objects-service';
import { Worksheet, ITaggingService, Insight, Card, isAbstractCardContainer } from 'src/generated-sources';

export function getWorksheetObjectRef(ws: Worksheet): TaggableObjectsService.TaggableObjectRef {
    return { id: ws.id!, projectKey: ws.projectKey, type: ITaggingService.TaggableType.STATISTICS_WORKSHEET, workspaceKey: '' };
}

export function getInsightObjectRef(insight: Insight): TaggableObjectsService.TaggableObjectRef {
    return { id: insight.id!, projectKey: insight.projectKey, type: ITaggingService.TaggableType.INSIGHT, workspaceKey: '' };
}

export function hasConfidenceLevel(params: Card): params is Card & {
    showConfidenceInterval: boolean,
    confidenceLevel: number
} {
    return ('confidenceLevel' in params) && ('showConfidenceInterval' in params);
}

export function clearHighlightFilter(params: Card): Card {
    if (isAbstractCardContainer(params)) {
        params = {
            ...params,
            cards: params.cards.map(subParams => clearHighlightFilter(subParams))
        };
    }
    if ('highlightFilter' in params) {
        params = { ...params, highlightFilter: null };
    }
    return params;
}


