<div>
    <modal-header [title]="data.title" [icon]="data.icon"></modal-header>
    <div class="modal-body flex" [innerHTML]="data.message">
    </div>
    <div class="modal-footer modal-footer-std-buttons">
        <div class="pull-right" *ngIf="!data.danger">
            <button type="button" class="btn btn--text btn--secondary" (click)="cancel()">{{ data.cancel || 'Cancel' }}</button>
            <button type="button" class="btn btn--text btn--primary" (click)="confirm()" autofocus>{{ data.confirm || 'Confirm' }}</button>
        </div>
        <div class="pull-right" *ngIf="data.danger">
            <!-- Dangerous modals don't validate on enter-->
            <button type="button" class="btn btn--text btn--secondary" (click)="cancel()" tabindex="-1">{{ data.cancel || 'Cancel' }}</button>
            <button type="button" class="btn btn--text btn--danger" (click)="confirm()" tabindex="-1">{{ data.confirm || 'Confirm' }}</button>
        </div>
    </div>
</div>
