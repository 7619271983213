<ng-container *ngIf="record">
    <div class="text-annotation" #textContainer>
        <div class="text-annotation__tokens" (mouseup)="handleSelection()">
            <ng-container *ngFor="let token of record.tokens; let i = index">
                <ng-container *ngIf="record.tokens[i-1]" >
                    <!-- Add line breaks from previous delimiter-->
                    <br *ngFor="let _ of [].constructor(numberOfLineReturns(record.tokens[i-1]))">
                </ng-container>
                <span
                    #tokens
                    *ngIf = "(tokenAnnotationsMap[i] || {}) as tokenAnnotations"
                    [id]="TOKEN_PREFIX + i"
                    class="text-annotation__token"
                    [style.minHeight]="(TOKEN_HEIGHT + (tokenAnnotations?.maxLevel || 0) * ANNOTATION_HEIGHT) + 'px'"
                >
                    <span>
                        <span 
                            class="text-annotation__text"
                            [class.text-annotation__text--warning]="tokenAnnotations.hasConflict && !tokenAnnotations.selected"
                            [style.top]="((tokenAnnotations?.maxLevel || 0) * ANNOTATION_HEIGHT) + 'px'"
                        >{{ token.text }}</span>
                        <ng-container *ngFor="let tokenAnnotation of tokenAnnotations.annotations">
                            <span
                                class="text-annotation__underline-container"
                                (mouseup)="toggleSelectedAnnotation($event, tokenAnnotation.annotation)"
                                (mouseenter)="setHoveredAnnotation($event, tokenAnnotation.annotation, true)"
                                (mouseleave)="setHoveredAnnotation($event, tokenAnnotation.annotation, false)"
                                [style.bottom]="ANNOTATION_POSITION + (tokenAnnotation.level * ANNOTATION_HEIGHT) + 'px'" 
                            >
                                <span class="text-annotation__underline" [style.backgroundColor]="tokenAnnotation.color"></span>
                            </span>
                            <span 
                                *ngIf="tokenAnnotation.isStart" 
                                class="text-annotation__label"
                                [style.bottom]="ANNOTATION_POSITION + (tokenAnnotation.level * ANNOTATION_HEIGHT) + 'px'"
                                [style.color]="tokenAnnotation.color"
                                (mouseenter)="setHoveredAnnotation($event, tokenAnnotation.annotation, true)"
                                (mouseleave)="setHoveredAnnotation($event, tokenAnnotation.annotation, false)"
                            >
                                <span *ngIf="tokenAnnotation.annotation.hovered" class="text-annotation__label-controls">
                                    <i class="dku-icon-trash-12" (mouseup)="deleteAnnotation($event, tokenAnnotation.annotation)"></i>
                                </span>
                                <span 
                                    class="text-annotation__label-text"
                                    (mouseup)="toggleSelectedAnnotation($event, tokenAnnotation.annotation)"
                                    [style.maxWidth]="tokenAnnotation.annotation.labelWidth + 'px'"
                                >{{ tokenAnnotation.annotation.category || '???' }}</span>
                            </span>
                            <span *ngIf="tokenAnnotation.annotation.selected || tokenAnnotation.annotation.hovered" class="text-annotation__token-highlight" [ngClass]="{
                                'text-annotation__token-highlight--selected': tokenAnnotation.annotation.selected,
                                'text-annotation__token-highlight--hovered': tokenAnnotation.annotation.hovered
                            }" [style.backgroundColor]="tokenAnnotation.color"></span>
                        </ng-container>
                    </span>
                </span>

                <span
                    *ngIf = "(tokenAnnotationsMap[i] || {}) as tokenAnnotations"
                    [id]="DELIMITER_PREFIX + i"
                    class="text-annotation__token"
                    [style.minHeight]="(TOKEN_HEIGHT + (tokenAnnotations?.maxLevel || 0) * ANNOTATION_HEIGHT) + 'px'"
                >
                    <span>
                        <span 
                            class="text-annotation__text"
                            [class.text-annotation__text--warning]="tokenAnnotations.hasConflict && !tokenAnnotations.selected && !tokenAnnotations.isEnd"
                            [style.top]="((tokenAnnotations?.maxLevel || 0) * ANNOTATION_HEIGHT) + 'px'"
                        >{{formatDelimiter(token.delimiter)}}</span>
                        <ng-container *ngFor="let tokenAnnotation of tokenAnnotations.annotations">
                            <ng-container *ngIf="!tokenAnnotation.isEnd">
                                <span
                                    class="text-annotation__underline-container" 
                                    (mouseup)="toggleSelectedAnnotation($event, tokenAnnotation.annotation)"
                                    (mouseenter)="setHoveredAnnotation($event, tokenAnnotation.annotation, true)"
                                    (mouseleave)="setHoveredAnnotation($event, tokenAnnotation.annotation, false)"
                                    [style.bottom]="ANNOTATION_POSITION + (tokenAnnotation.level * ANNOTATION_HEIGHT) + 'px'" 
                                >
                                    <span class="text-annotation__underline" [style.backgroundColor]="tokenAnnotation.color"></span>
                                </span>
                                <span *ngIf="tokenAnnotation.annotation.selected || tokenAnnotation.annotation.hovered" class="text-annotation__token-highlight" [ngClass]="{
                                    'text-annotation__token-highlight--selected': tokenAnnotation.annotation.selected,
                                    'text-annotation__token-highlight--hovered': tokenAnnotation.annotation.hovered
                                }" [style.backgroundColor]="tokenAnnotation.color"></span>
                            </ng-container>
                        </ng-container>
                    </span>
                </span>
            </ng-container>
        </div>
    </div>
</ng-container>
