/**
 * Generated from com.dataiku.dip.code.CodeEnvSelection
 */
export interface CodeEnvSelection {
    envMode: CodeEnvSelection.EnvMode;
    envName: string;
}

export namespace CodeEnvSelection {
    /**
     * Generated from com.dataiku.dip.code.CodeEnvSelection$EnvMode
     */
    export enum EnvMode {
        USE_BUILTIN_MODE = 'USE_BUILTIN_MODE',
        INHERIT = 'INHERIT',
        EXPLICIT_ENV = 'EXPLICIT_ENV'
    }
}