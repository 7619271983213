<right-panel-header class="data-collection-right-panel__header" icon="dku-icon-data-table-24">
    <a [href]="StateUtils.href.table(details.connection, details.catalog, details.schema, details.name)">
        {{details.name}}
    </a>
</right-panel-header>
<div class="data-collection-right-panel__content" *ngIf="selectedTab === 'info'">
    <right-panel-accordion-section [opened]="true" [showTopBorder]="false">
        <ng-container title>Actions</ng-container>
        <div content class="data-collection-right-panel__actions-container">
            <div class="data-collection-right-panel__big-actions-buttons">
                <button class="btn btn--secondary"
                        [class.disabled]="isHiveTable()"
                        [matTooltip]="isHiveTable() ? 'Preview is not supported for Hive tables' : ''"
                        matTooltipPosition="below"
                        (click)="!isHiveTable() && preview.emit()">
                    <i class="dku-icon-search-16"></i>
                    <span>Preview</span>
                </button>
                <button class="btn btn--primary" (click)="import.emit()">
                    <i class="dku-icon-arrow-circle-input-16"></i>
                    <span>Import</span>
                </button>
            </div>
        </div>
    </right-panel-accordion-section>


    <right-panel-accordion-section [opened]="true">
        <ng-container title>Details</ng-container>
        <div content class="data-collection-right-panel__details-container">
            <right-panel-details-description [longDescription]="metadata.description" [highlights]="highlights">
            </right-panel-details-description>
            <right-panel-details-tags [tags]="metadata.tags" objectType="DATASET"></right-panel-details-tags>
        </div>
    </right-panel-accordion-section>

    <right-panel-accordion-section [opened]="true" *ngIf="relatedProjects.length > 0">
        <ng-container title>Used in DSS Projects ({{relatedProjects.length}})</ng-container>
        <ng-container content>
            <div class="link-container children-horizontal-spacing" content *ngFor="let project of relatedProjects">
                <a class="project-link" [href]="StateUtils.href.project(project.key)"
                    [matTooltip]="project.name" matTooltipPosition="left" isEllipsisActive
                >
                    {{project.name}}
                </a>
                <i class="dku-icon-compass-16"></i>
            </div>
        </ng-container>
    </right-panel-accordion-section>

    <right-panel-accordion-section [opened]="true" *ngIf="relatedDatasets.length > 0">
        <ng-container title>Datasets ({{relatedDatasets.length}})</ng-container>
        <ng-container content>
            <div class="link-container children-horizontal-spacing" *ngFor="let dataset of relatedDatasets" >
                <a class="dataset-link" [href]="StateUtils.href.dssObject('DATASET', dataset.id, dataset.projectKey)"
                    [matTooltip]="dataset.projectKey + '.' + dataset.id" matTooltipPosition="left" isEllipsisActive
                >
                    {{dataset.projectKey}}.{{dataset.id}}
                </a>
            </div>
        </ng-container>
    </right-panel-accordion-section>
</div>
<div class="data-collection-right-panel__content" *ngIf="selectedTab === 'schema'">
    <right-panel-schema content class="data-collection-right-panel-schema__full-height"
        wordingOption="column"
        [columns]="columns"
        [showMeaning]="false"
        [columnHighlight]="columnsHighlighting">
    </right-panel-schema>
</div>
