import type {Beta} from './beta';
import type {Binomial} from './binomial';
import type {Exponential} from './exponential';
import type {Laplace} from './laplace';
import type {LogNormal} from './log-normal';
import type {NormalMixture} from './normal-mixture';
import type {Normal} from './normal';
import type {Pareto} from './pareto';
import type {Poisson} from './poisson';
import type {Triangular} from './triangular';
import type {Weibull} from './weibull';

/**
 * Generated from com.dataiku.dip.eda.compute.distributions.Distribution
 */
export interface _Distribution {}

export type Distribution = Beta | Binomial | Exponential | Laplace | LogNormal | Normal | NormalMixture | Pareto | Poisson | Triangular | Weibull;

export namespace Distribution {
    export const type = ['beta', 'binomial', 'exponential', 'laplace', 'lognormal', 'normal', 'normal_mixture', 'pareto', 'poisson', 'triangular', 'weibull'] as const;
    export function isBeta(toTest: Distribution): toTest is Beta { return toTest['type'] === 'beta'; }
    export function isBinomial(toTest: Distribution): toTest is Binomial { return toTest['type'] === 'binomial'; }
    export function isExponential(toTest: Distribution): toTest is Exponential { return toTest['type'] === 'exponential'; }
    export function isLaplace(toTest: Distribution): toTest is Laplace { return toTest['type'] === 'laplace'; }
    export function isLogNormal(toTest: Distribution): toTest is LogNormal { return toTest['type'] === 'lognormal'; }
    export function isNormal(toTest: Distribution): toTest is Normal { return toTest['type'] === 'normal'; }
    export function isNormalMixture(toTest: Distribution): toTest is NormalMixture { return toTest['type'] === 'normal_mixture'; }
    export function isPareto(toTest: Distribution): toTest is Pareto { return toTest['type'] === 'pareto'; }
    export function isPoisson(toTest: Distribution): toTest is Poisson { return toTest['type'] === 'poisson'; }
    export function isTriangular(toTest: Distribution): toTest is Triangular { return toTest['type'] === 'triangular'; }
    export function isWeibull(toTest: Distribution): toTest is Weibull { return toTest['type'] === 'weibull'; }
}

export namespace Distribution {
    /**
     * Generated from com.dataiku.dip.eda.compute.distributions.Distribution$FittedDistribution
     */
    export interface _FittedDistribution {}

    export type FittedDistribution = Beta.FittedBeta | Binomial.FittedBinomial | Exponential.FittedExponential | Laplace.FittedLaplace | LogNormal.FittedLogNormal | Normal.FittedNormal | NormalMixture.FittedNormalMixture | Pareto.FittedPareto | Poisson.FittedPoisson | Triangular.FittedTriangular | Weibull.FittedWeibull;

    export namespace FittedDistribution {
        export const type = ['beta', 'binomial', 'exponential', 'laplace', 'lognormal', 'normal', 'normal_mixture', 'pareto', 'poisson', 'triangular', 'weibull'] as const;
        export function isFittedBeta(toTest: Distribution.FittedDistribution): toTest is Beta.FittedBeta { return toTest['type'] === 'beta'; }
        export function isFittedBinomial(toTest: Distribution.FittedDistribution): toTest is Binomial.FittedBinomial { return toTest['type'] === 'binomial'; }
        export function isFittedExponential(toTest: Distribution.FittedDistribution): toTest is Exponential.FittedExponential { return toTest['type'] === 'exponential'; }
        export function isFittedLaplace(toTest: Distribution.FittedDistribution): toTest is Laplace.FittedLaplace { return toTest['type'] === 'laplace'; }
        export function isFittedLogNormal(toTest: Distribution.FittedDistribution): toTest is LogNormal.FittedLogNormal { return toTest['type'] === 'lognormal'; }
        export function isFittedNormal(toTest: Distribution.FittedDistribution): toTest is Normal.FittedNormal { return toTest['type'] === 'normal'; }
        export function isFittedNormalMixture(toTest: Distribution.FittedDistribution): toTest is NormalMixture.FittedNormalMixture { return toTest['type'] === 'normal_mixture'; }
        export function isFittedPareto(toTest: Distribution.FittedDistribution): toTest is Pareto.FittedPareto { return toTest['type'] === 'pareto'; }
        export function isFittedPoisson(toTest: Distribution.FittedDistribution): toTest is Poisson.FittedPoisson { return toTest['type'] === 'poisson'; }
        export function isFittedTriangular(toTest: Distribution.FittedDistribution): toTest is Triangular.FittedTriangular { return toTest['type'] === 'triangular'; }
        export function isFittedWeibull(toTest: Distribution.FittedDistribution): toTest is Weibull.FittedWeibull { return toTest['type'] === 'weibull'; }
    }
}