import { ColorContrastPipe } from "@shared/pipes/text-pipes/color-contrast.pipe";

export interface ImagePositionInformation {
    scale: number;
    top: number;
    left: number;
}

export interface BasePainterOptions {
}

export class PainterImage {
    artifacts: AbstractPainterImageArtifact[] = [];

    addArtifact(artifact: AbstractPainterImageArtifact) {
        this.artifacts.push(artifact); 
    }

    removeArtifacts() {
        this.artifacts = [];
    }
    getArtifactByName(name: string): AbstractPainterImageArtifact | undefined {
        return this.artifacts.find(mask => mask.name === name);
    }
}

export abstract class AbstractPainterImageArtifact {
    style: any;
    name?: string;
    constructor(style?: any, name?:string) {
        this.style = style;
        this.name = name;
    }
}

export class DivPainterImageArtifact extends AbstractPainterImageArtifact{
    text?: string;
    icon?: string;
}

export class MaskPainterImageArtifact extends AbstractPainterImageArtifact {
    maskUrl?: string;
}

export function createBottomLeftLabelArtifact(color?: string) {
    return new DivPainterImageArtifact({
        backgroundColor: color,
        color: ColorContrastPipe.getColorContrast(color),
        left: "4px",
        top: "calc(100% - 24px)",
        padding: "0px 4px",
        borderRadius: "15px",
    });
}

export function createCustomArtifact(style: any) {
    return new DivPainterImageArtifact(style);
}