import { Role } from '@shared/models';

enum ROLES {
    ADMIN = 'admin',
    CONTRIBUTOR = 'write',
    READER ='read',
}

export const ROLE_LIST: Role[] = [
    {
        role: ROLES.ADMIN,
        label: 'Admin',
        description: 'All contributors privileges, plus the ability to manage the data collection and its users.',
        permissions: { admin: true, write: true, read: true }
    },
    {
        role: ROLES.CONTRIBUTOR,
        label: 'Contributor',
        description: 'All readers privileges, plus the ability to add or remove content from the data collection.',
        permissions: { admin: false, write: true, read: true }
    },
    {
        role: ROLES.READER,
        label: 'Reader',
        description: 'Readers can, based on underlying project permissions, view data collection content, and preview or use datasets.',
        permissions: { admin: false, write: false, read: true }
    },
];
