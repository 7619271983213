import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.bivariate.MutualInformation
 */
export interface MutualInformation extends Computation._BivariateComputation {
    // PolyJson type
    type: 'mutual_information';
}

export namespace MutualInformation {
    export const type = 'mutual_information';
}

/** @deprecated Use Computation.isMutualInformation() */ export function isMutualInformation(toTest: Computation): toTest is MutualInformation { return MutualInformation.type === toTest['type']; }

export namespace MutualInformation {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.bivariate.MutualInformation$MutualInformationResult
     */
    export interface MutualInformationResult extends _AvailableResult {
        value: number;

        // PolyJson type
        type: 'mutual_information';
    }

    export namespace MutualInformationResult {
        export const type = 'mutual_information';
    }

    /** @deprecated Use ComputationResult.isMutualInformationResult() */ export function isMutualInformationResult(toTest: ComputationResult): toTest is MutualInformationResult { return MutualInformationResult.type === toTest['type']; }
}