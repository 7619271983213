import { Injectable } from "@angular/core";
import { UINamedEntity } from "@features/labeling/models/annotation";
import { LabelingAnnotationService } from "@features/labeling/services/labeling-annotation.service";
import { LabelingColorService } from "@features/labeling/services/labeling-color.service";
import { namedEntityAnnotationsSortingFn } from "@features/labeling/utils";
import { rgbToString } from "@utils/rgb-to-string";
import { Token } from "src/generated-sources";

export interface TokenAnnotation {
    isStart: boolean,
    isEnd: boolean,
    level: number,
    annotation: UINamedEntity,
    color: string
}

export interface TokenAnnotationMap {
    [id: number]: {
        maxLevel: number,
        isEnd: boolean, // true if isEnd is true for every annotation of this token 
        selected: boolean,
        hasConflict: boolean,
        annotations: TokenAnnotation[]
    }
}

type NodeType = "token" | "delimiter";

class NodeInfo {
    constructor(public nodeId: number, public nodeType: NodeType) {}

    getNextNodeInfo(): NodeInfo {
        if (this.nodeType === "delimiter") {
            return new NodeInfo(this.nodeId + 1, "token");
        } else {
            return new NodeInfo(this.nodeId, "delimiter");
        }
    }

    getPreviousNodeInfo(): NodeInfo {
        if (this.nodeType === "delimiter") {
            return new NodeInfo(this.nodeId, "token");
        } else {
            return new NodeInfo(this.nodeId - 1, "delimiter");
        }
    }
}

@Injectable()
export class LabelingAnnotateTextService {
    readonly TOKEN = 'token';
    readonly TOKEN_PREFIX = this.TOKEN + '_';
    readonly DELIMITER = "delimiter";
    readonly DELIMITER_PREFIX = this.DELIMITER + '_';
    private tokens: Token[];

    constructor(
        private labelingAnnotationService: LabelingAnnotationService,
        private labelingColorService: LabelingColorService
    ) {}

    setTokens(tokens: Token[]) {
        this.tokens = tokens;
    }

    createAnnotation(selection: Selection): UINamedEntity | undefined {
        let [startTokenId, endTokenId] = this.getBoundaryTokenIds(selection);
        let annotation;

        if (startTokenId != null && endTokenId != null) {
            annotation = this.createAnnotationFromTokenIds(startTokenId, endTokenId, this.labelingAnnotationService.getSelectedCategory());
            annotation.selected = true;
        }
        return annotation;
    }

    private createAnnotationFromTokenIds(startId: number, endId: number, category: string) : UINamedEntity {
        const beginningIndex = this.tokens[startId].beginningIndex;
        const endIndex = this.tokens[endId].endIndex;
        const seletectedTokens = this.tokens.slice(startId, endId + 1);
        const text = seletectedTokens.map((token, index) => {
            if (index < seletectedTokens.length - 1) {
                return token.text + token.delimiter;
            } else {
                return token.text;
            }
        }).join('');
        return new UINamedEntity({
            category,
            beginningIndex,
            endIndex,
            text
        });
    }

    /*
        Contains a map of annotations associated with each token
    */
    createTokenAnnotationMap(annotations: UINamedEntity[]) {
        const tokenAnnotationMap: TokenAnnotationMap = {};

        [...annotations]
            .sort(namedEntityAnnotationsSortingFn)
            .filter(a => !a.isHidden())
            .forEach(annotation => {
                const [startTokenId, endTokenId] = [this.getTokenIdFromStart(annotation.beginningIndex), this.getTokenIdFromEnd(annotation.endIndex)];

                /*
                    A level represents the position of the annotation below the text in the UI
                    Find the next available level for the current annotation in this token
                */
                let level = 1;
                if (tokenAnnotationMap[startTokenId]) {
                    const levels = tokenAnnotationMap[startTokenId].annotations.map(annotation => annotation.level);
                    while (levels.indexOf(level) !== -1) {
                        level++;
                    }
                }

                // add annotation data to each token
                for (let i = startTokenId; i <= endTokenId; i++) {
                    const color = this.labelingColorService.getColorForText(annotation);
                    tokenAnnotationMap[i] = {
                        selected: tokenAnnotationMap[i]?.selected || !!annotation.selected,
                        hasConflict: tokenAnnotationMap[i]?.hasConflict || !!annotation.hasConflict(),
                        isEnd: i === endTokenId && (!tokenAnnotationMap[i] || tokenAnnotationMap[i]?.isEnd),
                        maxLevel: Math.max(level, (tokenAnnotationMap[i]?.maxLevel || 1)),
                        annotations: (tokenAnnotationMap[i]?.annotations || []).concat([{
                            level,
                            annotation,
                            color: rgbToString(color, annotation.isDisabled() ? 0.35 : 1),
                            isStart: i === startTokenId,
                            isEnd: i == endTokenId
                        }])
                    };
                }
            });

        return tokenAnnotationMap;
    }

    public getTokenIdFromData(position: 'beginningIndex' | 'endIndex', index: number) {
        return this.tokens.findIndex(token => {
            return token[position] === index;
        });
    }

    public getTextFromBoundaries(text: string, start: number, end: number) {
        return [...text].slice(start, end).join('');
    }

    public getTokenIdFromStart(beginningIndex: number) {
        return this.getTokenIdFromData('beginningIndex', beginningIndex);
    }

    public getTokenIdFromEnd(endIndex: number) {
        return this.getTokenIdFromData('endIndex', endIndex);
    }

    public getAllMatchingAnnotations(annotation: UINamedEntity) : UINamedEntity[] {
        const tokensFromAnnotation = this.tokens.slice(this.getTokenIdFromStart(annotation.beginningIndex), this.getTokenIdFromEnd(annotation.endIndex) + 1);
        const matchingAnnotations = [];

        for (let i = 0; i < this.tokens.length; i++) {
            let differentTokenFound = false;
            for (let j = 0; j < tokensFromAnnotation.length; j++) {
                let currentToken = this.tokens[i + j];
                let currentTokenInAnnotation = tokensFromAnnotation[j];
                // checking equality of tokens
                if (!(currentToken.text === currentTokenInAnnotation.text && (j === tokensFromAnnotation.length - 1 || currentToken.delimiter === currentTokenInAnnotation.delimiter))) {
                    differentTokenFound = true;
                    break;
                }
            }
            if (!differentTokenFound) { // We have a candidate
                matchingAnnotations.push(this.createAnnotationFromTokenIds(i, i + tokensFromAnnotation.length - 1, annotation.category));
            }
        }

        return matchingAnnotations;
    }

    private getNodeInfo(node: HTMLElement): NodeInfo|null {
        let nodeId: string | undefined = node.id;

        if (!nodeId || !(nodeId.includes(this.TOKEN_PREFIX) || nodeId.includes(this.DELIMITER_PREFIX))) {
            nodeId = node.parentElement!.closest(`[id^="${this.TOKEN_PREFIX}"],[id^="${this.DELIMITER_PREFIX}"]`)?.id;
        }

        if (!nodeId) {
            return null;
        }
        const nodeIdSplit = nodeId.split("_");
        return new NodeInfo(
            parseInt(nodeIdSplit[1]),
            nodeIdSplit[0] as NodeType
        )
    }

    private getBoundaryTokenIds(selection: Selection): [number|null, number|null] {
        /*
            In Firefox, rangeCount can be greater than 1 if an element with "user-select: none" 
            (in this case, category labels) falls within a selection (e.g., rangeCount would be 3
            if the selection is "[text] [nonSelectableText] [text] [nonSelectableText] [text]"
            since nonSelectableText acts as a separator.)
            Therefore, we need to ensure we use the start container from the first range, and the
            end container from the last range.
        */
        const startRange = selection.getRangeAt(0);
        const endRange = selection.getRangeAt(selection.rangeCount - 1);

        let startNode: HTMLElement | null = startRange.startContainer as HTMLElement;
        let endNode: HTMLElement | null = endRange.endContainer as HTMLElement;

        let startNodeInfo = startNode ? this.getNodeInfo(startNode) : null;
        let endNodeInfo = endNode ? this.getNodeInfo(endNode) : null;

        // Another Firefox annoying specificity. When selecting multiple spans, and selecting completely one at the 
        // beginning/end of the selection, it will actually consider the startContainer/endContainer to be the 
        // previous/next, unselected span. So we need to take that into account.
        const startNodeNotInSelection = startNode?.textContent?.length == startRange.startOffset;
        const endNodeNotInSelection = endRange.endOffset === 0;

        let startNodeId = null;
        if (startNodeInfo) {
            if (startNodeNotInSelection) {
                startNodeInfo = startNodeInfo.getNextNodeInfo();
            }
            // For the start node, we only want to actually select it if it is a token.
            // If it is a delimiter, we consider that the corresponding node has not been
            // selected and we will start at the next token.
            if(startNodeInfo.nodeType === this.TOKEN) {
                startNodeId = startNodeInfo.nodeId;
            } else {
                startNodeId = startNodeInfo.nodeId + 1;
            }
        }

        let endNodeId = null;
        if (endNodeInfo) {
            if (endNodeNotInSelection) {
                endNodeInfo = endNodeInfo.getPreviousNodeInfo();
            }
            // We always want to select the last node, whether is is a token or a delimiter
            endNodeId = endNodeInfo.nodeId;
        }

        // Make sure that startNodeId <= endNodeId (possible to get the opposite if only selecting a delimiter)
        if (startNodeId != null && endNodeId != null && startNodeId > endNodeId) {
            return [null, null];
        }

        return [startNodeId, endNodeId];
    }
}