import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Observable, Subject, of, concat } from 'rxjs';
import { Card, Filter, Variable, SplitBySpec, AbstractHeaderCard } from 'src/generated-sources';
import { FilterNamePipe } from '@features/eda/pipes/filter-name.pipe';

// Collapsing key is an identifier used to synchronize collapsible elements together
// Typically, two elements are collapsed together if their collapsing key is the same
abstract class CollapsibleBase {
    // Collapsible is always per-card
    cardId: string;

    // Type of collapsible
    type: string;

    // Used to
    // - Persist collapsing states
    // - Compare collapsible key
    persistenceKey(): string {
        return `${this.type}.${this.cardId}`;
    }

    constructor(cardId: string) {
        this.cardId = cardId;
    }

    collapsedByDefault(): boolean {
        return false;
    }
}

// Collapsing key referencing a specific group in any grouped top level card
export class CollapsibleGroup extends CollapsibleBase {
    type = 'group';
    filter: Filter;

    persistenceKey() {
        return `${super.persistenceKey()}.${new FilterNamePipe().transform(this.filter)}`;
    }

    constructor(cardId: string, filterArg: Filter) {
        super(cardId);
        this.filter = filterArg;
    }
}


// Collapsing key referencing a top level card
export class CollapsibleTopLevelCard extends CollapsibleBase {
    type = 'top_level';
}

// Collapsing key referencing the help section of a top level card
export class CollapsibleHelp extends CollapsibleBase {
    type = 'help';

    collapsedByDefault(): boolean {
        return true;
    }
}


// Collapsing key of a stat card
// Note: since grouped header cards are rotated, collapsing works per-column
export class CollapsibleStatCard extends CollapsibleBase {
    type = 'stat_card';
    column: Variable;
    statType: Card['type'];
    headerSplitBy?: SplitBySpec | null;

    persistenceKey() {
        if (this.headerSplitBy) {
            return `${super.persistenceKey()}.grouped.${this.statType}.${this.column.type}.${this.column.name}`;
        } else {
            return `${super.persistenceKey()}.notGrouped.${this.statType}.${this.column.type}`;
        }
    }

    constructor(headerCard: AbstractHeaderCard, column: Variable, statType: Card['type']) {
        super(headerCard.id);
        this.column = column;
        this.statType = statType;
        this.headerSplitBy = headerCard.splitBy;
    }
}

// Collapsing key of a column any header card
export class CollapsibleColumnCard extends CollapsibleBase {
    type = 'column_card';
    column: Variable;

    persistenceKey() {
        return `${super.persistenceKey()}.${this.column.name}`;
    }

    constructor(cardId: string, column: Variable) {
        super(cardId);
        this.column = column;
    }
}

export type Collapsible = CollapsibleGroup
    | CollapsibleStatCard
    | CollapsibleColumnCard
    | CollapsibleTopLevelCard
    | CollapsibleHelp;

export abstract class CollapsingService {
    abstract watchIsCollapsed(collapsible: Collapsible): Observable<boolean>;
    abstract setIsCollapsed(collapsible: Collapsible, isCollapsed: boolean): void;
}

export abstract class UpdatableCollapsingService extends CollapsingService {

}

@Injectable()
export class LocalStorageCollapsingService extends UpdatableCollapsingService {
    private changes = new Subject<{ storageKey: string, isCollapsed: boolean }>();

    watchIsCollapsed(collapsible: Collapsible): Observable<boolean> {
        const storageKey = collapsible.persistenceKey();
        const initialValue = this.getItem(storageKey, collapsible.collapsedByDefault());
        const updatedValues = this.changes.pipe(
            filter(change => change.storageKey === storageKey),
            map(change => change.isCollapsed)
        );
        return concat(of(initialValue), updatedValues);
    }

    constructor() {
        super();
    }

    setIsCollapsed(collapsible: Collapsible, newIsCollapsed: boolean): void {
        const storageKey = collapsible.persistenceKey();
        const newIsDefault = newIsCollapsed === collapsible.collapsedByDefault();

        if (newIsDefault) {
            this.removeItem(storageKey);
        } else {
            this.setItem(storageKey, newIsCollapsed);
        }
        this.changes.next({ storageKey, isCollapsed: newIsCollapsed });
    }

    // Private

    private setItem(key: string, collapsed: true | false) {
        localStorage.setItem(key, collapsed ? 'true' : 'false');
    }

    private removeItem(key: string) {
        localStorage.removeItem(key);
    }

    private getItem(key: string, collapsedByDefault: boolean): boolean {
        switch (localStorage.getItem(key)) {
            case 'true':
                return true;
            case 'false':
                return false;
        }
        return collapsedByDefault;
    }
}

@Injectable()
export class NoopCollapsingService extends CollapsingService {
    watchIsCollapsed(collapsible: Collapsible): Observable<boolean> {
        return of(collapsible.collapsedByDefault());
    }

    setIsCollapsed(collapsible: Collapsible, isCollapsed: boolean): void {
        return;
    }
}
