import { Pipe, PipeTransform } from '@angular/core';
import { friendlyDuration } from '@shared/pipes/date-pipes/date-pipes-common';

@Pipe({
    name: 'friendlyDuration'
})
export class FriendlyDurationPipe implements PipeTransform {

    transform(input: string|number): string {
        if (typeof input == 'string') {
            input = parseInt(input, 10);
        }
        return friendlyDuration(input / 1000);
    }

}
