import { Directive, ElementRef, Inject, Injector, Input, OnChanges, SimpleChanges } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";
import { fairAny } from 'dku-frontend-core';

@Directive({
    selector: 'ui-global-tag',
})
export class UiGlobalTag extends UpgradeComponent implements OnChanges {
    @Input() tag: string;
    @Input() objectType?: string;
    
    constructor(
        @Inject('$rootScope') public $rootScope: fairAny,
        elementRef: ElementRef, 
        injector: Injector
    ) {
        super('ng1UiGlobalTag', elementRef, injector);
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Run AngularJS change detetion
        this.$rootScope.$applyAsync();
        super.ngOnChanges(changes);
    }
}
