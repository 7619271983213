import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { UIState } from '@shared/models';

/*
    Limitations:
    - The active vs route test is only done on input changes
      If we want it to dynamically change on state change, we'd need to use $rootScope.$on('$stateChangeSuccess', ...
      But it's probably not required in most situations (most angular components are not reused when state changes)
    - The same is true for the inherited routeParams (href is not updated unless an input changes)
    - state activation does not check params
*/

@Component({
    selector: 'object-nav-tab',
    templateUrl: './object-nav-tab.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObjectNavTabComponent implements OnChanges {
    @Input() label!: string;
    @Input() route!: string;
    @Input() routeParams?: Record<string, string | undefined>;
    @Input() active?: string | boolean;

    resolvedActive: boolean = false;
    resolvedRoute: string = '';

    constructor(
        @Inject('$state') private $state: UIState
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.active || changes.route) {
            const active = this.active ?? this.route;
            this.resolvedActive = typeof active === 'string' ? this.$state.includes(active) : active;
        }
        if(changes.route || changes.routeParams) {
            this.resolvedRoute = this.$state.href(this.route, this.routeParams);
        }
    }
}
