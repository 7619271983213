<section class="base-permissions-edition__header">
    <clearable-searchbar *ngIf="permissions.length"
            class="base-permissions-edition__list-search"
            (searchTermChange)="onMemberSearchChange($event)"
            [hasPrefixIcon]="true"
            inputPlaceholder="Find users"
            [isClearable]="true">
        </clearable-searchbar>

    <hr *ngIf="permissions.length">

    <div class="base-permissions-edition__add-block"
        *ngIf="canEdit && (groups?.length || availableUsers?.length)">

        <select-button *ngIf="groups" [options]="groups"
            [selection]="permissions | pluck:'group'"
            (selectionChange)="onGroupPermissionsChange($event)"
            searchPlaceholder="Find groups"
            searchNoEntry="No matching group">
            <i class="dku-icon-plus-16"></i>Add group
            <ng-template #optionTemplate let-option>
                <i class="dku-icon-user-group-16 dibvam"></i>
                <span class="base-general-edition__add-option-label" [ngClass]="{'font-weight-semi-bold': option === '$$ALL_USERS$$'}">
                    {{ option | map: mapGroup }}
                </span>
            </ng-template>
        </select-button>

        <select-button [options]="availableUsers ?? []"
            [selection]="permissions | pluck:'user'"
            (selectionChange)="onUserPermissionChange($event)"
            searchPlaceholder="Find users"
            searchNoEntry="No matching user"
            valueKey="login"
            labelKey="displayName">
            <i class="dku-icon-plus-16"></i>Add user
            <ng-template #optionTemplate let-option>
                <user-picture [user]="option.login" [size]="24"></user-picture>
                <span class="base-general-edition__add-option-label">
                    {{ option.displayName }}
                </span>
            </ng-template>
        </select-button>

    </div>
</section>

<section class="base-permissions-edition__users-list" *ngIf="availableUsers && groups">

    <div class="base-permissions-edition__user mbot8" *ngIf="!editionMode">
        <div class="base-permissions-edition__avatar">
            <user-picture [user]="currentUser.login" [size]="24"></user-picture>
        </div>
        <div class="base-permissions-edition__user-label">
            <span class="font-weight-semi-bold">{{ currentUser.displayName }}</span>
            (You)
        </div>
        <div class="base-permissions-edition__user-rights mleft8">
            Admin
        </div>
    </div>

    <ng-container *ngIf="permissions | map:mapPermission:this | filterDefined | filter:memberFilter:'displayName' as filteredPermissions">
        <div *ngFor="let permission of filteredPermissions; trackBy:trackPermission" class="base-permissions-edition__user">
            <div class="base-permissions-edition__avatar">
                <span *ngIf="permission.group"><i class="dku-icon-user-group-16"></i></span>
                <user-picture *ngIf="permission.user" [user]="permission.user" [size]="24"></user-picture>
            </div>
            <div class="base-permissions-edition__user-label" [class.font-weight-semi-bold]="permission.group || permission.exists">
                <span [class.text-disabled]="!permission.exists">
                    {{ permission.displayName }}
                </span>
            </div>
            <div class="base-permissions-edition__user-rights" *ngIf="canEdit">
                <mat-select [value]="permission.role.role" (valueChange)="updatePermissionRole(permission, $event)" class="base-permissions-edition__role-select">
                    <mat-select-trigger>{{ permission.role.label }}</mat-select-trigger>
                    <mat-option *ngFor="let role of roleList" [value]="role.role" class="base-permissions-edition__role">
                        <div class="base-permissions-edition__role-label">
                            {{ role.label }}
                        </div>
                        <div class="base-permissions-edition__role-description">
                            {{ role.description }}
                        </div>
                    </mat-option>
                </mat-select>
            </div>
            <button *ngIf="canEdit" class="btn btn--text btn--danger btn--icon btn--dku-icon"
                [class.btn--disabled]="permission.exists"
                (click)="deletePermission(permission)">
                <i class="dku-icon-trash-12"></i>
            </button>
            <div *ngIf="!canEdit" class="base-permissions-edition__user-rights">
                {{ permission.role.label }}
            </div>
        </div>

        <div [hidden]="!permissions.length || !memberFilter || filteredPermissions.length">
            <div class="dku-text">No match found for <b>{{ memberFilter }}</b></div>
        </div>
    </ng-container>

    <p class="dku-text text-weak" *ngIf="!permissions.length && editionMode">
        No group or user is granted access to this {{objectType | niceConst | lowercase }}.
    </p>

    <p class="dku-text text-weak mtop16" *ngIf="!permissions.length && !editionMode">
        You can skip this step and add groups or users later.
    </p>
</section>
