<h4>{{metricTitle}}</h4>
<span
    class="metric-value"
    *ngIf="metricValue >= 0 && metricDate > 0"
>{{metricLabel}}</span>
<span
    class="metric-value"
    *ngIf="metricDate === 0"
>Not computed</span>
<div class="button-group">
        <span
            class="smallgrey"
            *ngIf="metricDate > 0"
        >{{metricDate | date}}</span>
        <button
            type="button"
            *ngIf="canBeForced"
            [class.disabled]="!canUpdate"
            (click)="canUpdate && refreshMetric.emit(true)"
            class="btn btn--secondary btn--icon btn--dku-icon"
            [matTooltip]="canUpdate ? 'Force refresh of all partitions, even if DSS thinks it\'s not needed' : 'You don\'t have permission to compute status on this dataset'"
            matTooltipPosition="left"
        >
            <i class="dku-icon-text-exclamation-16"></i>
        </button>
        <button
            type="button"
            (click)="canUpdate && refreshMetric.emit(false)"
            class="btn btn--secondary btn--icon btn--dku-icon"
            [class.disabled]="!canUpdate"
            [matTooltip]="canUpdate ? 'Refresh ' + metricTitle: 'You don\'t have permission to compute status on this dataset'"
            matTooltipPosition="left"
        >
            <i class="dku-icon-arrow-sync-16"></i>
        </button>
</div>
