import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fileSizeOrNA'
})
export class FileSizeOrNAPipe implements PipeTransform {

    transform(size: number): string {
        if (size < 0) {
            return "N/A";
        } else if (size >= 1024 * 1024 * 1024) {
            return String(Math.round(size / 1024 / 1024 / 1024 * 100) / 100) + ' GB';
        } else if (size >= 1024 * 1024) {
            return String(Math.round(size / 1024 / 1024 * 100) / 100) + ' MB';
        } else {
            return String(Math.round(size / 1024 * 100) / 100) + ' KB';
        }
    }
}
