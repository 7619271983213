import { Injectable } from "@angular/core";
import { rgbToString } from "@utils/rgb-to-string";
import { ReviewRegionUINamedEntity, ReviewUINamedEntity } from "../labeling-task-review/named-entity-region-selector/named-entity-region-selector.component";
import { UINamedEntity } from "../models/annotation";

export enum ColorMeaning {
    CLASS,
    ANNOTATOR,
    ITEM_STATE
}

export type RBGColor = [number, number, number];

@Injectable()
export class LabelingColorService {
    private colorMeaning: ColorMeaning = ColorMeaning.CLASS;
    private choices: { [key in ColorMeaning]?: string[]; } = {};

    readonly COLORS: RBGColor[] = [[230, 25, 75], [60, 180, 75], [240, 180, 63], [0, 130, 200], [245, 130, 48],
    [145, 30, 180], [87, 168, 238], [240, 50, 230], [194, 200, 81], [232, 94, 89], [0, 128, 128],
    [116, 86, 245], [128, 128, 128], [170, 110, 40], [136, 111, 101], [128, 0, 0], [105, 179, 172], [128, 128, 0],
    [238, 162, 133], [0, 0, 128]];

    readonly UNDEFINED_COLOR: RBGColor = [102, 102, 102];
    readonly VALIDATION_COLOR: RBGColor = [52, 125, 57];
    readonly WARNING_COLOR: RBGColor = [242, 140, 55];

    getColor(item: string | undefined, colorMeaning?: ColorMeaning): RBGColor {
        colorMeaning = colorMeaning || this.colorMeaning; // can pass custom color meaning
        const idx = this.choices[colorMeaning]?.findIndex((c) => c == item);
        if (idx === undefined || idx === -1 || this.COLORS[idx] === undefined) {
            return colorMeaning == ColorMeaning.ANNOTATOR ? this.VALIDATION_COLOR : this.UNDEFINED_COLOR;
        }
        switch(colorMeaning) {
            case ColorMeaning.ANNOTATOR:
                return this.COLORS[this.COLORS.length - idx - 1];
            case ColorMeaning.CLASS:
                return this.COLORS[idx];
            case ColorMeaning.ITEM_STATE:
                return [this.WARNING_COLOR, this.VALIDATION_COLOR, this.UNDEFINED_COLOR][idx]
        }
    }

    getAnnotatorColor(annotator: string) {
        return this.getColor(annotator, ColorMeaning.ANNOTATOR);
    }

    getAnnotatorColorString(annotator: string) {
        return rgbToString(this.getAnnotatorColor(annotator));
    }

    getColorForBBox(bbox: {annotator?: string, category?: string, state?: string}): RBGColor {
        if (this.colorMeaning === ColorMeaning.ANNOTATOR) {
            return this.getColor(bbox.annotator);
        } else if (this.colorMeaning === ColorMeaning.CLASS) {
            return this.getColor(bbox.category);
        } else if (this.colorMeaning === ColorMeaning.ITEM_STATE) {
            return this.getColor(bbox.state);
        } else {
            throw new Error("Unsupported color meaning");
        }
    }

    getColorForText(textEntity: UINamedEntity): RBGColor {
        if (textEntity instanceof ReviewUINamedEntity) {
            if (textEntity instanceof ReviewRegionUINamedEntity) {
                return this.getColor(textEntity.state, ColorMeaning.ITEM_STATE);
            } else {
                return this.getColor(textEntity.annotator, ColorMeaning.ANNOTATOR);
            }
        } else if (textEntity.annotator && this.colorMeaning === ColorMeaning.ANNOTATOR) {
            return this.getColor(textEntity.annotator);
        }
        return this.getColor(textEntity.category, ColorMeaning.CLASS);
    }

    categoryToColorString(category: string, opacity?: number) {
        return rgbToString(this.getColor(category), opacity);
    }

    setColorOptions(colorMeaning: ColorMeaning, options: string[]) {
        this.choices[colorMeaning] = options;
    }

    setCurrentColorMeaning(colorMeaning: ColorMeaning) {
        this.colorMeaning = colorMeaning;
    }
}
