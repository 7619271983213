<editable-list
    [itemsFormArray]="itemsFormArray"
    [addLabel]="addLabel"
    [sortable]="sortable"
    [focusOnEnter]="focusOnEnter"
    [fullWidthList]="fullWidthList"
    [disableAdd]="disableAdd"
    (onAdd)="onAdd.emit($event)"
    (onDelete)="onDelete.emit($event)"
    (onReorder)="onReorder.emit($event)"
    (onValidityChange)="onValidityChange.emit($event)"
    (onMouseOverAddButton)="onMouseOverAddButton.emit()"
    (onMouseLeaveAddButton)="onMouseLeaveAddButton.emit()"
    >
    <ng-template let-item="item" let-goToNext="goToNext" let-index="index">
        <editable-list-template (onInputEnter)="goToNext($event)">
            <editable-list-input
                [inputControl]="item?.get(inputKey)"
                (inputChange)="updateSuggestions($event)"
                [suggestions]="filteredSuggestions"
                [autocomplete]="autocomplete"
                [placeholder]="valuePlaceholder | enrichValuePlaceholder:richValuePlaceholder:index"
                [required]="required"
                (onFocus)="handleFocus($event)"
                (onBlur)="onBlur.emit($event)">
            </editable-list-input>
        </editable-list-template>
    </ng-template>
</editable-list>
