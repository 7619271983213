import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { CardAction, CardActionType } from '../cards/events';
import { WorksheetRootCard } from 'src/generated-sources';


@Component({
    selector: 'highlight-settings',
    templateUrl: './highlight-settings.component.html',
    styleUrls: [
        '../../shared-styles/header-button.less',
        './highlight-settings.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HighlightSettingsComponent {
    @Input() params: WorksheetRootCard;
    @Input() readOnly: boolean;
    @Output() action = new EventEmitter<CardAction>();

    dropHighlight() {
        if (this.params.highlightFilter) {
            this.action.emit({
                type: CardActionType.UPDATE,
                newParams: { ...this.params, highlightFilter: null }
            });
        }
    }
}
