import { Inject, Injectable } from '@angular/core';
import { EChartsOption } from 'echarts';
import { ChartBase, ChartCoordinates, FrontendChartDef, Legend } from '../../interfaces';
import { ChartTensorDataWrapper } from '../../models';
import { EChartDef } from './models';

@Injectable({
    providedIn: 'root'
})
export class EChartsInstanceManagerService {
    constructor(
        @Inject('Logger') private logger: any
    ) { }

    public draw(
        chartId: string,
        chartDef: FrontendChartDef,
        chartData: ChartTensorDataWrapper,
        chartBase: ChartBase,
        echartDef: EChartDef,
        noXAxis: boolean,
        noYAxis: boolean,
        legends: Array<Legend> | null,
        frameIndex: number,
        facets?: Array<{ label: string }>
    ): { meta?: {height?: string | number, options: EChartsOption}, options: EChartsOption, allCoords: Array<Array<ChartCoordinates>> } {
        if (facets && facets.length && echartDef.drawFacets) {
            return echartDef.drawFacets(facets, { chartId, chartDef, chartData, chartBase, noXAxis, noYAxis, legends, frameIndex, facetIndex: 0 });
        } else {
            return echartDef.draw({ chartId, chartDef, chartData, chartBase, noXAxis, noYAxis, legends, frameIndex, facetIndex: 0 });
        }
    }

    public clearEvents(echartInstance: any) {
        echartInstance.off('mousemove');
        echartInstance.off('mouseout');
        echartInstance.off('finished');

        try {
            echartInstance.getZr().off('click');
        } catch (e) {
            this.logger({ serviceName: 'EChartsManager', objectName: 'Service' }).error('on click', e);
        }

        echartInstance.off('globalout');
    }
}
