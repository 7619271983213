import type {GroupProfile} from './../auth/group-profile';
import type {UserRemappingRule} from './../auth/user-remapping-rule';

/**
 * Generated from com.dataiku.dip.security.azure.AzureADSettings
 */
export interface AzureADSettings {
    allowOnDemandUsersProvisioning: boolean;
    allowOnDemandUsersSync: boolean;
    authorizedGroups: string;
    autoProvisionUsersAtLoginTime: boolean;
    autoSyncUsersAtLoginTime: boolean;
    credentialMethod: AzureADSettings.CredentialMethod;
    credentialsCertificatePassword: string;
    credentialsCertificatePath: string;
    credentialsClientId: string;
    credentialsClientSecret: string;
    credentialsTenantId: string;
    defaultUserProfile: string;
    enabled: boolean;
    groupProfiles: GroupProfile[];
    loginAttributeRef: AzureADSettings.LoginAttribute;
    order?: number | null;
    remappingRules: UserRemappingRule[];
    trustAllCerts: boolean;
    useGlobalProxy: boolean;
    userQueryFilter: string;
}

export namespace AzureADSettings {
    /**
     * Generated from com.dataiku.dip.security.azure.AzureADSettings$CredentialMethod
     */
    export enum CredentialMethod {
        OAUTH2_SECRET = 'OAUTH2_SECRET',
        OAUTH2_CERT = 'OAUTH2_CERT'
    }

    /**
     * Generated from com.dataiku.dip.security.azure.AzureADSettings$LoginAttribute
     */
    export enum LoginAttribute {
        EMAIL = 'EMAIL',
        USER_PRINCIPAL_NAME = 'USER_PRINCIPAL_NAME',
        DISPLAY_NAME = 'DISPLAY_NAME'
    }
}