import { Injectable } from '@angular/core';
import { DkuHttpService, FutureWatcherService, TypedFutureResponse } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { MLflowExperimentRunOrigin, MLflowModelDeployResult, SavedModel, SamplingParam } from 'src/generated-sources';
import { PrivateSavedModelsClusteringAPI } from './private-saved-models-clustering-api';
import { PrivateSavedModelsPredictionAPI } from './private-saved-models-prediction-api';

@Injectable({ providedIn: 'root' })
export class PrivateSavedModelsAPI {
    constructor(
        private futureWatcher: FutureWatcherService,
        private dkuHttp: DkuHttpService,
        public clustering: PrivateSavedModelsClusteringAPI,
        public prediction: PrivateSavedModelsPredictionAPI
    ) { }

    deployMLflowModel(projectKey: string, predictionType: string, smId: string, pythonCodeEnvName: string,
        folderRef: string, path: string, versionId: string, activate: boolean, dataset: string,
        targetColumn: string, classes: string[], origin: MLflowExperimentRunOrigin, binaryClassificationThreshold: number,
        useOptimalThreshold: number, skipExpensiveReports: boolean, samplingParam: SamplingParam): Observable<TypedFutureResponse<MLflowModelDeployResult>> {
        const classLabels = classes?.map((classLabel) => ({label: classLabel}));
        const modelVersionInfo = {
            gatherFeaturesFromDataset: dataset,
            targetColumnName: targetColumn,
            ...(classLabels && {classLabels}),
            pythonCodeEnvName,
            predictionType
        };
        return this.futureWatcher.requestFuture('POST', '/savedmodels/deploy-mlflow-model', {
            projectKey, smId, folderRef, path, versionId, modelVersionInfo, origin, samplingParam, activate,
            binaryClassificationThreshold, useOptimalThreshold, skipExpensiveReports
         });
    }

    createExternalSavedModel(projectKey: string, savedModelType: SavedModel.SavedModelType, predictionType: string, name: string): Observable<SavedModel> {
        return this.dkuHttp.request('POST', '/savedmodels/prediction/create-external', {
            projectKey, name, savedModelType, ...(predictionType && predictionType !== 'OTHER' && {predictionType})
        });
    }
}
