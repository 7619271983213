import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mlTaskTypeToIcon'
})
export class MlTaskTypeToIconPipe implements PipeTransform {

    transform(taskType: string, predictionType?: string): any {
        if (!taskType || !predictionType) {
            return;
        }
        if (taskType.toLowerCase() == 'clustering') {
            return 'icon-clustering';
        }
        return 'icon-prediction-' + predictionType.toLowerCase();
    }

}
