import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PairwiseTTestCard, PairwiseMoodTestCard, BoxPlotFragment } from 'src/generated-sources';
import { ChangeDetectionStrategy } from '@angular/core';
import { CardAction } from '@features/eda/worksheet/cards/events';
import _ from 'lodash';

type SupportedCard =
    PairwiseTTestCard
    | PairwiseMoodTestCard;

type SupportedCardResult =
    PairwiseTTestCard.PairwiseTTestCardResult
    | PairwiseMoodTestCard.PairwiseMoodTestCardResult;

@Component({
    selector: 'generic-pairwise-test-card-body',
    templateUrl: './generic-pairwise-test-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/test-conclusion.less',
        '../../../../shared-styles/stats-table.less',
        '../../../../shared-styles/card-layout.less',
        './generic-pairwise-test-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class GenericPairwiseTestCardBodyComponent {
    @Input() results: SupportedCardResult;
    @Input() params: SupportedCard;
    @Output() action = new EventEmitter<CardAction>();

    get locationParameterName() {
        switch (this.params.type) {
            case 'pairwise_ttest':
                return 'mean';
            case 'pairwise_mood':
                return 'median';
        }
    }

    private get boxPlots(): BoxPlotFragment[] {
        return this.results.groups
            .filter(group => group.boxPlot)
            .map(group => group.boxPlot);
    }

    get boxPlotsMin(): number {
        const minList = this.boxPlots.map(it => it.min);
        return _.min(minList) ?? 0;
    }

    get boxPlotsMax(): number {
        const maxList = this.boxPlots.map(it => it.max);
        return _.max(maxList) ?? 0;
    }

    getFlattenedIndex(colIdx: number, rowIdx: number) {
        const nbGroups = this.results.groups.length;
        return (nbGroups * (nbGroups - 1) / 2)
            - (nbGroups - colIdx) * ((nbGroups - colIdx) - 1) / 2
            + rowIdx - colIdx - 1;
    }
}
