import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ErrorMessage } from '../../models';

@Component({
    selector: 'workspace-error-message',
    templateUrl: './workspace-error-message.component.html',
    styleUrls: ['./workspace-error-message.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceErrorMessageComponent {
    title$ = new BehaviorSubject<string>('');
    message$ = new BehaviorSubject<string>('');
    instructions$ = new BehaviorSubject<string>('');
    redirectLink$ = new BehaviorSubject<string>('/');
    redirectLabel$ = new BehaviorSubject<string>('Go back to home');

    @Input()
    set message(value: ErrorMessage | null) {
        this.title$.next(value?.title || '');
        this.message$.next(value?.message || '');
        this.instructions$.next(value?.instructions || '');

        if (value?.redirect) {
            this.redirectLink$.next(value.redirect.href);
            this.redirectLabel$.next(value.redirect.label || 'Go back to home');
        } else {
            this.redirectLink$.next('/');
            this.redirectLabel$.next('Go back to home');
        }
    }
}
