<modal-header [title]="'Create a new data collection'" [hasBorder]="true" icon="dku-icon-stacked-24"></modal-header>
<api-error-alert [apiError]="error$ | async" (resetError)="resetError()" [closable]="true"></api-error-alert>
<form class="dkuform-modal-horizontal">
    <div class="modal-body" *ngIf="currentTabIndex === 0">
        <div class="control-group">
            <div [style.--data-collection-color]="creationInfo.color">
                <div class="control-group">
                    <label for="displayName" class="control-label">Name</label>
                    <div class="controls">
                        <input type="text" placeholder="Data collection name" id="displayName" name="displayName"
                            [(ngModel)]="creationInfo.displayName" (ngModelChange)="onDataCollectionChange()" required>
                    </div>
                </div>

                <div class="control-group">
                    <div class="controls data-collection-modal__color-options">
                        <data-collection-icon [name]="creationInfo.displayName || 'Data Collection'"
                            [color]="creationInfo.color"></data-collection-icon>
                        <stock-color-picker [color]="creationInfo.color" (colorChange)="onColorChange($event)">
                        </stock-color-picker>
                    </div>
                </div>

                <div class="control-group data-collection-modal__description">
                    <label for="description" class="control-label">Description</label>
                    <div class="controls">
                        <autocompletable-textarea [(model)]="creationInfo.description"
                            (modelChange)="onDataCollectionChange()"
                            [textarea]="{ name: 'description', placeholder: 'Data collection description' }"
                            options="{ autofocus: false }">
                        </autocompletable-textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-body" *ngIf="currentTabIndex === 1">
        <div class="step-header">
            <a (click)="previousTab()" class="dku-back-link"><i class="dku-icon-arrow-left-16"></i></a>
            <span class="modal__step-header-title">Main Setup</span>
        </div>
        <base-permissions-edition
            [objectType]="objectType"
            [permissions]="creationInfo.permissions"
            [roleList]="roleList"
            [users]="users$ | async"
            [groups]="groups$ | async"
            [editionMode]="false"
            [canEdit]="true"
            (permissionsChange)="onPermissionsChange({ permissions: $event })">
        </base-permissions-edition>
    </div>
    <div class="modal-footer modal-footer-std-buttons">
        <div class="pull-right">
            <button type="button" class="btn btn-default btn--secondary btn--text" (click)="cancel()">Cancel</button>
            <button *ngIf="currentTabIndex === 0" type="submit" class="btn btn-primary" (click)="nextTab()" autofocus [disabled]="!canNext">
                Next
            </button>
            <button *ngIf="currentTabIndex === 1" type="submit" class="btn btn-primary" (click)="confirm()" autofocus>
                Create
            </button>
        </div>
    </div>
</form>
