import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.Quantiles
 */
export interface Quantiles extends Computation._UnivariateComputation {
    confidence?: number | null;
    freqs: number[];

    // PolyJson type
    type: 'quantiles';
}

export namespace Quantiles {
    export const type = 'quantiles';
}

/** @deprecated Use Computation.isQuantiles() */ export function isQuantiles(toTest: Computation): toTest is Quantiles { return Quantiles.type === toTest['type']; }

export namespace Quantiles {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.Quantiles$QuantileDesc
     */
    export interface QuantileDesc {
        freq: number;
        lower?: number | null;
        quantile: number;
        upper?: number | null;
    }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.Quantiles$QuantilesResult
     */
    export interface QuantilesResult extends _AvailableResult {
        quantiles: Quantiles.QuantileDesc[];

        // PolyJson type
        type: 'quantiles';
    }

    export namespace QuantilesResult {
        export const type = 'quantiles';
    }

    /** @deprecated Use ComputationResult.isQuantilesResult() */ export function isQuantilesResult(toTest: ComputationResult): toTest is QuantilesResult { return QuantilesResult.type === toTest['type']; }
}