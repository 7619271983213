import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { APIError } from '@core/dataiku-api/api-error';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { Insight } from '@model-main/dashboards/model/insight';
import { InsightSummary } from '@shared/models';
import { of, forkJoin, throwError, Observable } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { InsightViewer } from '../object-viewer-host';

@Component({
    selector: 'web-app-viewer',
    templateUrl: './web-app-viewer.component.html',
    styleUrls: ['./web-app-viewer.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebAppViewerComponent extends InsightViewer implements OnInit {
    constructor(protected dataikuApiService: DataikuAPIService) {
        super();
    }

    private getSummary(projectKey: string, id: string): Observable<InsightSummary> {
        return this.dataikuApiService.webapps.getSummary(projectKey, id)
            .pipe(
                catchError((err: APIError) => {
                    this.handleError(err);
                    return throwError(() => err);
                })
            );
    }

    ngOnInit(): void {
        this.insight$ = this.options$.pipe(
            switchMap(options => {
                if (options) {
                    return forkJoin([
                        of(options),
                        this.getSummary(options.projectKey, options.id)
                    ]);
                }

                return throwError(() => 'Missing options');
            }),
            map(([options, data]) => {
                const insight: Partial<Insight> = {
                    ...options,
                    params: {
                        webAppSmartId: data.object.id,
                        webAppType: data.object.type,
                        versionTag: data.object.versionTag
                    }
                };

                return insight;
            })
        );
    }
}
