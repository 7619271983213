import { Component, ViewChild, OnDestroy, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'dku-popover',
    templateUrl: './dku-popover.component.html',
    styleUrls: ['./dku-popover.component.less']
})
export class DkuPopoverComponent implements OnDestroy {
    @ViewChild('popupTemplate', {static: true})
    public popupTemplate: TemplateRef<any>;
    public hidden = false;

    private notifyTriggerSource = new BehaviorSubject<boolean>(false);
    public notifyTrigger$ = this.notifyTriggerSource.asObservable();

    public className = 'dku-popover';

    public show() {
        this.hidden = false;
    }

    hide(closedWithinPopover = true) {
        this.hidden = true;

        if (closedWithinPopover) {
            this.notifyTriggerSource.next(true);
        }
    }

    ngOnDestroy() {
        this.hidden = false;
    }
}
