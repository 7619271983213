import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.ZTest1Samp
 */
export interface ZTest1Samp extends Computation._UnivariateComputation {
    hypothesizedMean: number;
    knownStdDev: number;

    // PolyJson type
    type: 'ztest_1samp';
}

export namespace ZTest1Samp {
    export const type = 'ztest_1samp';
}

/** @deprecated Use Computation.isZTest1Samp() */ export function isZTest1Samp(toTest: Computation): toTest is ZTest1Samp { return ZTest1Samp.type === toTest['type']; }

export namespace ZTest1Samp {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.ZTest1Samp$ZTest1SampResult
     */
    export interface ZTest1SampResult extends _AvailableResult {
        pvalue: number;
        pvalueAltGt: number;
        pvalueAltLt: number;
        statistic: number;

        // PolyJson type
        type: 'ztest_1samp';
    }

    export namespace ZTest1SampResult {
        export const type = 'ztest_1samp';
    }

    /** @deprecated Use ComputationResult.isZTest1SampResult() */ export function isZTest1SampResult(toTest: ComputationResult): toTest is ZTest1SampResult { return ZTest1SampResult.type === toTest['type']; }
}