import { Directive, ElementRef, Injector, Input } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";

@Directive({
    selector: 'angularjs-autocompletable-textarea',
})
export class AngularJSAutocompletableTextareaDirective extends UpgradeComponent {
    @Input() model: any;
    @Input() modelChange: any;
    @Input() textarea?: object;
    @Input() options?: string;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('ng1AutocompletableTextarea', elementRef, injector);
    }
}