import { ValidatorFn } from '@angular/forms';

export class DkuValidators {
    static float(): ValidatorFn {
        return control => {
            const value = control.value;
            if (value == null || value.length === 0) {
                // don't validate empty values to allow optional controls
                return null;
            }
            return isNaN(value) ? { isFloat: false } : null;
        };
    }
}
