<div class="h100 vertical-flex">
    <div class="experiment-tracking-run-details">
        <div class="run-details__title">
            <h2 class="dku-grand-title mtop0 mbot0" [qa]="'experiments-run-details-title'">
                Run {{ run.runNameId }}
                <button *ngIf="run.info.status == 'RUNNING'"
                        class="refresh-button link-std mleft8 btn btn--text btn--secondary"
                        matTooltip="Refresh to get the latest values while the run is in progress"
                        (click)="requestRunRefresh()"
                        [wt1Click]="'experiment-tracking-refresh-run'"
                        [qa]="'experiments-run-details-refresh'">
                    <i class="icon-refresh"></i>
                </button>
            </h2>
            <div class="subtitle" [qa]="'experiments-run-details-subtitle'">
                <span *ngIf="run.dssUser">
                    Run created by
                    <a [matTooltip]="run.dssUser || ''" [href]="'/profile/' + run.dssUser + '/'">
                        {{ run.dssUser }}
                    </a>
                </span>
                <span class="text-prompt">
                    {{ run.info.startTime | friendlyDateTime }}
                </span>
            </div>
        </div>
        <div class="vertical-flex run-details-section" [qa]="'experiments-run-details-section-summary'">
            <div class="run-details-section__title">
                <h3 class="dku-medium-title-sb mtop0 mbot0">Summary</h3>
            </div>
            <div class="horizontal-flex oxa">
                <div class="run-details-section__table-container span4">
                    <table class="table run-details-section__table">
                        <tbody>
                            <tr [qa]="{'experiments-run-details-section-summary-property': { key: 'experimentName' }}">
                                <th>Experiment</th>
                                <td>{{ run.experimentName?run.experimentName:run.info.experimentId }}</td>
                            </tr>
                            <tr [qa]="{'experiments-run-details-section-summary-property': { key: 'startTime' }}">
                                <th>Start time</th>
                                <td [qa]="'experiments-run-details-summary-title'">{{ run.info.startTime | YYYYMMDDHHmmssDateTime }}</td>
                            </tr>
                            <tr [qa]="{'experiments-run-details-section-summary-property': { key: 'duration' }}">
                                <th>Duration</th>
                                <td
                                    [matTooltip]="run.info.endTime == 0 ? 'RUNNING' : 'End time: ' + (run.info.endTime | YYYYMMDDHHmmssDateTime)">
                                    {{ run.info.endTime == 0 ? '-' : run.info.startTime |
                                    friendlyTimeDeltaHHMMSS:run.info.endTime }}</td>
                            </tr>
                            <tr [qa]="{'experiments-run-details-section-summary-property': { key: 'status' }}">
                                <th>Status</th>
                                <td>
                                    <experiment-tracking-status [runStatus]="run.info.status">
                                    </experiment-tracking-status>
                                    &nbsp;{{ run.info.status }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="run-details-section__table-container span5">
                    <table class="table run-details-section__table">
                        <tbody>
                            <tr [qa]="{'experiments-run-details-section-summary-property': { key: 'lifecycleStage' }}">
                                <th>Lifecycle stage</th>
                                <td>{{ run.info.lifecycleStage }}</td>
                            </tr>
                            <tr [qa]="{'experiments-run-details-section-summary-property': { key: 'dssUser' }}">
                                <th>User</th>
                                <td class="user-avatar-cell">
                                    <ng-container *ngIf="run.dssUser">
                                        <a [matTooltip]="run.dssUser || ''" [href]="'/profile/' + run.dssUser + '/'">
                                            <user-picture [user]="run.dssUser" [size]="24"></user-picture>
                                        </a>
                                    </ng-container>
                                </td>
                            </tr>
                            <ng-container [ngSwitch]="run.origin">
                                <ng-container *ngSwitchCase="'analysis'">
                                    <tr [qa]="{'experiments-run-details-section-summary-property': { key: 'origin' }}">
                                        <th>Original model</th>
                                        <td>
                                            <span *ngIf="run.analysisId"><i class="icon-dku-nav_analysis universe-color analysis"></i>
                                                <a [wt1Click]="'experiment-tracking-run-go-original-analysis-model-from-uri'" [href]="run.analysisModelHref">
                                                    {{ run.fullModelId }}
                                                </a>
                                            </span>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <tr [qa]="{'experiments-run-details-section-summary-property': { key: 'artifactUri' }}">
                                        <th>Artifact URI</th>
                                        <td>
                                            <div class="managed-folder-container">
                                                <div class="mx-textellipsis">
                                                    <span *ngIf="run.artifactInfo.managedFolderName" matTooltip="{{ run.artifactInfo.managedFolderName + '/' +
                                                        run.artifactInfo.subfolder }}">
                                                        <a [wt1Click]="'experiment-tracking-run-go-artifacts-from-uri'"
                                                           [href]="run.artifactHref">
                                                            {{ run.artifactInfo.managedFolderName + '/' +
                                                            run.artifactInfo.subfolder }}
                                                        </a>
                                                    </span>
                                                    <span *ngIf="!run.artifactInfo.managedFolderName"
                                                          [matTooltip]="run.info.artifactUri">
                                                        {{ run.info.artifactUri }}
                                                    </span>
                                                </div>
                                                <button class="btn btn--text btn--primary copy-button"
                                                        (click)="copyToClipboard(run.info.artifactUri)"
                                                        matTooltip="Copy artifact URI">
                                                    <i class="icon-copy"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="vertical-flex run-details-section" [qa]="'experiments-run-details-section-metrics'">
            <div class="run-details-section__title">
                <h3 class="dku-medium-title-sb mtop0 mbot0">Metrics ({{ metricsInfos.length }})</h3>
                <label *ngIf="areMetricsWithMultipleSteps" class="toggle-all-charts-selector"
                       [qa]="'experiments-run-details-section-metrics-toogle-all-charts'">
                    <input type="checkbox" (click)="$event.stopPropagation()" (change)="toggleAllCharts()"
                           [checked]="allChartsShown" [indeterminate]="allChartsShown == undefined"
                           matTooltip="Show/Hide all charts"
                           [wt1Click]="'experiment-tracking-run-toggle-all-charts'">&nbsp;Display all charts
                </label>
            </div>
            <div class="horizontal-flex oxa">
                <div class="run-details-section__table-container span3"
                     *ngFor="let metricInfosChunk of metricsInfosInCols">
                    <table class="table run-details-section__table">
                        <tbody>
                            <tr>
                                <th class="little-cell-header"></th>
                                <th class="run-details-section__table-header">Name</th>
                                <th class="run-details-section__table-header">Last Value</th>
                            </tr>
                            <tr *ngFor="let metricInfos of metricInfosChunk"
                                [qa]="{'experiments-run-details-section-metrics-property': { key: metricInfos.key }}">
                                <td class="{{ metricInfos.isMultiStep ? 'little-cell' : 'empty-cell' }}">
                                    <button *ngIf="metricInfos.isMultiStep" class="btn btn--icon btn--secondary"
                                            matTooltip="Show steps as chart" (click)="toggleStepsDisplay(metricInfos)"
                                            [wt1Click]="'experiment-tracking-run-toggle-steps'">
                                        <i
                                           class="{{ metricInfos.displaySteps ? 'icon-dku-nav_dashboard' : 'icon-dku-nav_dashboard text-disabled' }}"></i>
                                    </button>
                                </td>
                                <td>{{ metricInfos.key }}</td>
                                <td [class.experiment-tracking-invalid-values]="metricInfos.lastInvalidValue">
                                    {{ metricInfos.lastInvalidValue || metricInfos.lastValue?.toFixed(4) }}
                                    <experiment-tracking-invalid-metric-value [metricInfo]="metricInfos"></experiment-tracking-invalid-metric-value>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="!metricsInfos.length" class="span9 placeholder-block">
                    <span>No metrics</span>
                </div>
            </div>
            <div class="oxa" *ngIf="chartOptions">
                <div class="horizontal-flex chart-options">
                    <div>
                        Display:
                        <ul class="chart-options-selector btn-group">
                            <button class="btn btn--secondary" (click)="toggleStepsDisplayMode(false)"
                                    [ngClass]="{'btn--active' : !displayStepsAsTSDiff}"
                                    [wt1Click]="'experiment-tracking-run-toggle-steps-display-mode'"
                                    [qa]="'experiments-run-details-section-metrics-toogle-charts-steps'"
                                    matTooltip="Display metrics by steps">
                                STEP
                            </button>
                            <button class="btn btn--secondary" (click)="toggleStepsDisplayMode(true)"
                                    [ngClass]="{'btn--active' : displayStepsAsTSDiff}"
                                    [wt1Click]="'experiment-tracking-run-toggle-steps-display-mode'"
                                    [qa]="'experiments-run-details-section-metrics-toogle-charts-relative-time'"
                                    matTooltip="Display metrics as relative time">
                                RELATIVE TIME
                            </button>
                        </ul>
                    </div>
                    <label class="big-tiles-selector">
                        <input type="checkbox" (click)="$event.stopPropagation()" (change)="toggleBigTiles()"
                               [checked]="bigTiles" matTooltip="Show big tiles"
                               [wt1Click]="'experiment-tracking-run-toggle-big-title'"
                               [qa]="'experiments-run-details-section-metrics-toogle-big-tiles'">&nbsp;Big tiles
                    </label>
                </div>
                <div [style.width]="chartOptions.chartWidth" [style.height]="chartOptions.chartHeight"
                     [qa]="'experiments-run-details-section-metrics-charts'">
                    <lazy-echart [options]="chartOptions"></lazy-echart>
                </div>
            </div>
        </div>
        <div class="vertical-flex run-details-section" [qa]="'experiments-run-details-section-params'">
            <div class="run-details-section__title">
                <h3 class="dku-medium-title-sb mtop0 mbot0">Params ({{ run.data?.runParams?.length || 0 }})
                </h3>
            </div>
            <div class="horizontal-flex oxa">
                <div class="run-details-section__table-container span3" *ngFor="let runParams of runParamsInCols">
                    <table class="table run-details-section__table">
                        <tbody>
                            <tr *ngFor="let param of runParams" [qa]="{'experiments-run-details-section-params-property': { key: param.key }}">
                                <th>{{ param.key }}</th>
                                <td>{{ param.value }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="!run.data?.runParams?.length" class="span9 placeholder-block">
                    <span>No params</span>
                </div>
            </div>
        </div>
        <div class="vertical-flex run-details-section" [qa]="'experiments-run-details-section-models'">
            <div class="run-details-section__title">
                <h3 class="dku-medium-title-sb mtop0 mbot0">Models ({{ getNbModels() }})</h3>
                <span matTooltip="No model logged in the current run." [matTooltipDisabled]="!hasArtifact() || getNbModels() != 0" style="padding-left:8px;">
                <button class="btn btn--secondary right-header-deploy"
                        (click)="requestOpenDeployModal()"
                        [disabled]="!canDeploy()"
                        [ngClass]="{disabled: hasArtifact() && getNbModels() == 0}"
                        [qa]="'experiments-run-details-deploy-model'">
                   <i class="icon-play"></i>
                   {{ getNbModels() < 2 ? 'Deploy the model' : 'Deploy a model (' + getNbModels() + ')'}}
                </button>
            </span>
            </div>
            <div class="horizontal-flex oxa">
                <div class="run-details-section__table-container span3" *ngFor="let models of modelsInCols">
                    <table class="table run-details-section__table">
                        <tbody>
                            <tr *ngFor="let model of models" [qa]="{'experiments-run-details-section-models-property': { key: model.artifactPath }}">
                                <th>
                                    <span *ngIf="run.artifactInfo.managedFolderName" matTooltip="{{ run.artifactInfo.managedFolderName + '/' +
                                                run.artifactInfo.subfolder + '/' + model.artifactPath }}">
                                        <a [wt1Click]="'experiment-tracking-run-go-artifacts-from-uri'"
                                           [href]="model.artifactHref">
                                            {{ model.artifactPath }}
                                        </a>
                                    </span>
                                    <span *ngIf="!run.artifactInfo.managedFolderName">
                                        {{ model.artifactPath }}
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="run-details-section__table-container span3" *ngIf="run.origin == 'analysis'">
                    <table class="table run-details-section__table">
                        <tbody>
                            <tr [qa]="{'experiments-run-details-section-models-property': { key: 'run.fullModelId' }}">
                                <td>
                                    <span *ngIf="run.analysisId"><i class="icon-dku-nav_analysis universe-color analysis"></i>
                                        <a [wt1Click]="'experiment-tracking-run-go-original-analysis-model-from-uri'" [href]="run.analysisModelHref">
                                            {{ run.fullModelId }}
                                        </a>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="getNbModels() == 0" class="span9 placeholder-block">
                    <span>No Models</span>
                </div>
                <experiment-tracking-run-inference *ngIf="getNbModels()"
                                                   class="models-run-inference-info"
                                                   [modelType]="run.predictionType || run.taskType"
                                                   [codeEnv]="run.codeEnvName"
                                                   [target]="run.target"
                                                   [modelClasses]="run.classes">
                </experiment-tracking-run-inference>
            </div>
        </div>
        <div class="vertical-flex run-details-section" [qa]="'experiments-run-details-section-tags'">
            <div class="run-details-section__title">
                <h3 class="dku-medium-title-sb mtop0 mbot0">Tags ({{ tags.length || 0 }})</h3>
            </div>
            <div class="horizontal-flex oxa">
                <div class="run-details-section__table-container span3" *ngFor="let tagsChunk of tagsInCols">
                    <table class="table run-details-section__table">
                        <tbody>
                            <tr *ngFor="let tag of tagsChunk" [qa]="{'experiments-run-details-section-tags-property': { key: tag.key }}">
                                <th>{{ tag.key }}</th>
                                <td>{{ tag.value }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="!tags.length" class="span9 placeholder-block">
                    <span>No tags</span>
                </div>
            </div>
        </div>
        <div class="vertical-flex run-details-section" [qa]="'experiments-run-details-section-systemTags'">
            <div class="run-details-section__title">
                <h3 class="dku-medium-title-sb mtop0 mbot0">MLflow System Tags ({{ systemTags.length || 0 }})</h3>
            </div>
            <div class="horizontal-flex oxa">
                <div class="run-details-section__table-container span6" *ngFor="let tagsChunk of systemTagsInCols">
                    <table class="table run-details-section__table">
                        <tbody>
                            <tr *ngFor="let tag of tagsChunk" [qa]="{'experiments-run-details-section-systemTags-property': { key: tag.key }}">
                                <th>{{ tag.key }}</th>
                                <td>{{ tag.value }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="!systemTags.length" class="span9 placeholder-block">
                    <span>No MLflow system tags</span>
                </div>
            </div>
        </div>
        <div class="vertical-flex run-details-section" [qa]="'experiments-run-details-section-dssGitInformation'">
            <div class="run-details-section__title">
                <h3 class="dku-medium-title-sb mtop0 mbot0">DSS Git information</h3>
            </div>
            <div class="horizontal-flex oxa">
                <div class="run-details-section__table-container span12">
                    <table class="table run-details-section__table">
                        <tbody>
                            <tr [qa]="{'experiments-run-details-section-dssGitInformation-property': { key: 'dssGitCommit' }}">
                                <th>Commit ID</th>
                                <td><a [href]="run.dssGitCommitHref">{{ run.dssGitCommit }}</a></td>
                            </tr>
                            <tr [qa]="{'experiments-run-details-section-dssGitInformation-property': { key: 'dssGitMessage' }}">
                                <th>Commit message</th>
                                <td>{{ run.dssGitMessage }}</td>
                            </tr>
                            <tr [qa]="{'experiments-run-details-section-dssGitInformation-property': { key: 'dssGitBranch' }}">
                                <th>Branch</th>
                                <td>{{ run.dssGitBranch }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
