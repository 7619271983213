<api-error-alert-from-context></api-error-alert-from-context>
<div *ngIf="selectedItem === undefined" class="right-panel__no-selection">
    <i class="dku-icon-cursor-click-48"></i>
    <p>Click an item to view details</p>
</div>
<div *ngIf="selectedItem && isItemDetailsLoading$.value" class="right-panel__no-selection">
    <p>Loading...</p>
</div>
<ng-container *ngIf="!isItemDetailsLoading$.value">
    <data-collection-page-right-panel-dataset *ngIf="isSelectedItemReadableDataset()" [selectedTab]="selectedTab"
        [details]="selectedItemDetails" [highlights]="selectedItem.highlight"
        (toggleStarred)="rightPanelContext.toggleStarred(selectedItemDetails, $event)"
        (toggleWatched)="rightPanelContext.toggleWatched(selectedItemDetails, $event)"
        (preview)="rightPanelContext.preview(selectedItemDetails)" (share)="share(selectedItemDetails)"
        (requestShare)="rightPanelContext.requestShare(selectedItemDetails, 'data-catalog')"
        (export)="rightPanelContext.exportDataset(selectedItemDetails)"
        (shareToDashboard)="rightPanelContext.shareToDashboard(selectedItemDetails)"
        (shareToWorkspace)="rightPanelContext.shareToWorkspace(selectedItemDetails)"
        (addToFeatureStore)="rightPanelContext.addToFeatureStore(selectedItemDetails)"
        (addToDataCollection)="rightPanelContext.addToDataCollection(selectedItemDetails)"
        (editDataSteward)="editDataSteward(selectedItemDetails)"
        (refreshStatus)="rightPanelContext.refreshStatus(selectedItemDetails, $event)">
    </data-collection-page-right-panel-dataset>
    <data-catalog-page-right-panel-external-table *ngIf="isSelectedItemExternalTable()" [selectedTab]="selectedTab"
        [details]="selectedItem._source" [highlights]="selectedItem.highlight"
        (preview)="rightPanelContext.previewExternalTable(selectedItem._source)"
        (import)="rightPanelContext.import(selectedItem._source)">
    </data-catalog-page-right-panel-external-table>
</ng-container>
