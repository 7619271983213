import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { zipSameSize } from '@features/eda/echarts-utils';
import { smarterNumber } from '@shared/pipes/number-pipes/smarter-number.pipe';
import { EChartsOption, SeriesOption } from 'echarts';
import { encodeHTML } from 'entities';
import _ from 'lodash';
import { ACFPlotCard, PACF } from 'src/generated-sources';

const DEFAULT_COLOR = '#1E7EFA';

@Component({
    selector: 'acf-plot-card-body',
    templateUrl: './acf-plot-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/chart.less',
        '../../../../shared-styles/stats-table.less',
        '../../../../shared-styles/card-layout.less',
        './acf-plot-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ACFPlotCardBodyComponent implements OnChanges {
    @Input() results: ACFPlotCard.ACFPlotCardResult;
    @Input() params: ACFPlotCard;
    @Input() hasFixedHeight: boolean;

    acfPlotOptions: EChartsOption;

    get methodLabel(): string | null {
        const card = this.params;

        if (card.isPartial) {
            switch (card.pacfMethod) {
                case PACF.Method.YULE_WALKER:
                    return 'Yule-Walker';
                case PACF.Method.OLS:
                    return 'Regression on lags and on constant';
                case PACF.Method.OLS_UNBIASED:
                    return 'Regression on lags using bias adjustment';
                case PACF.Method.LEVINSON_DURBIN:
                    return 'Levinson-Durbin recursion';
            }
        }

        return null;
    }

    get nLagsLabel(): string {
        if (this.params.nLags == null) {
            return `${this.results.coefficients.length - 1} (automatically computed)`;
        }

        return `${this.params.nLags} (manually set)`;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results) {
            this.acfPlotOptions = this.results && this.buildChartOptions(this.results);
        }
    }

    buildChartOptions(results: ACFPlotCard.ACFPlotCardResult): EChartsOption {
        const { coefficients, confidenceIntervals } = results;
        const size = coefficients.length;
        const lags = Array.from(Array(size).keys());
        const mainSeriesData = zipSameSize(lags, coefficients);

        const confIntLowerValues = confidenceIntervals.map((value, i) => value[0] - coefficients[i]);
        const confIntUpperValues = confidenceIntervals.map((value, i) => value[1] - coefficients[i]);
        const confIntLags = [...lags];

        // For display we want to skip lag == 0
        confIntLowerValues.shift();
        confIntUpperValues.shift();
        confIntLags.shift();

        // statsmodels does this on its acf plot - I do not know the rationale behind this.
        // https://github.com/statsmodels/statsmodels/blob/152e27dcc667a423f798810a1b0fee2f6340f73d/statsmodels/graphics/tsaplots.py#L74
        confIntLags[0] -= 0.5;
        confIntLags[confIntLags.length - 1] += 0.5;

        const mainSeries: SeriesOption = {
            type: 'scatter',
            data: mainSeriesData,
            itemStyle: {
                color: DEFAULT_COLOR,
                opacity: 1,
            },
            tooltip: {
                formatter: (params: any) => {
                    const lag: number = params.value[0];
                    const coeff = smarterNumber(coefficients[lag]);
                    return [
                        `Lag: <b>${encodeHTML('' + lag)}</b>`,
                        `Correlation: <b>${encodeHTML(coeff)}</b>`,
                    ].join('<br>');
                },
            },
        };

        const vLineSeries: SeriesOption = {
            type: 'line',
            symbol: 'none',
            silent: true,
            lineStyle: {
                color: DEFAULT_COLOR,
                width: 2,
                opacity: 1,
            },
            data: _.flatMap(mainSeriesData, (point) => {
            const [x, y] = point;
                return [[x, 0], [x, y], '-'];
            }),
        };

        const confIntLowerSeries: SeriesOption = {
            type: 'line',
            smooth: true,
            symbol: 'none',
            silent: true,
            data: zipSameSize(confIntLags, confIntLowerValues),
            lineStyle: {
                width: 0,
            },
            areaStyle: {
                color: DEFAULT_COLOR,
                opacity: 0.25,
            }
        };

        const confIntUpperSeries: SeriesOption = {
            type: 'line',
            smooth: true,
            symbol: 'none',
            silent: true,
            data: zipSameSize(confIntLags, confIntUpperValues),
            lineStyle: {
                width: 0,
            },
            areaStyle: {
                color: DEFAULT_COLOR,
                opacity: 0.25,
            },
        };

        return {
            animation: false,
            series: [
                confIntUpperSeries,
                confIntLowerSeries,
                vLineSeries,
                mainSeries,
            ],
            tooltip: {
                show: true,
            },
            grid: {
                top: 40,
                left: 60,
                bottom: 40,
                right: 60,
            },
            xAxis: {
                type: 'value',
                name: 'Lag',
            },
            yAxis: {
                type: 'value',
                name: 'Correlation',
            },
        };
    }
}
