import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'colorContrast'
})
export class ColorContrastPipe implements PipeTransform {

    static getColorContrast(backgroundColor: string | undefined): string {
        const DARK = '#000000';
        const LIGHT = '#FFFFFF';
        let textColor = DARK;

        if (backgroundColor) {
            textColor = getContrastYIQ(backgroundColor);
        }

        return textColor;

        // 24 WAYS - http://24ways.org/2010/calculating-color-contrast/
        function getContrastYIQ(color: string){
            try {
                const [r, g, b] = extractColorValues(color);
                const yiq = ((r*299)+(g*587)+(b*114))/1000;

                return isNaN(yiq) ? DARK : (yiq >= 128) ? DARK : LIGHT;  
            } catch (err) {
                return DARK;
            }
        }

        function extractColorValues(color: string): number[] {
            if (color.includes('rgb')) {
                // remove all characters that aren't numbers, commas, or decimal points; don't include alpha if it exists
                return color.replace(/[^\d,.]/g, '').split(',').slice(0, 3).map(value => parseInt(value));
            } else {
                color = (color.charAt(0) === '#') ? color.substring(1, color.length) : color;
                return [
                    parseInt(color.substring(0, 2), 16),
                    parseInt(color.substring(2, 4), 16),
                    parseInt(color.substring(4, 6), 16)
                ];
            }
        }
    }

    transform(backgroundColor: string | undefined): string {
        return ColorContrastPipe.getColorContrast(backgroundColor);
    }
}