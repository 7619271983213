import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { DataikuAPIModule } from '@core/dataiku-api/dataiku-api.module';
import { AutocompletableTextareaModule, UIRouterModule } from '@migration/modules';
import { StockColorPickerModule } from '@shared/modules/stock-color-picker';
import { ModalsModule } from '@shared/modals/modals.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { DataCollectionsHomeComponent } from './data-collections-home/data-collections-home.component';
import { CreateDataCollectionModalComponent } from './shared/components/create-data-collection-modal/create-data-collection-modal.component';
import { DataCollectionPageComponent } from './data-collection-page/data-collection-page.component';
import { DataCollectionPathComponent } from './data-collection-page/data-collection-path/data-collection-path.component';
import { DataCollectionSummaryComponent } from './data-collection-page/data-collection-summary/data-collection-summary.component';
import { DataCollectionContentTableComponent } from './data-collection-page/data-collection-content-table/data-collection-content-table.component';
import { MatTableModule } from '@angular/material/table';
import { ComponentsModule } from '@shared/components';
import { MigrationModule } from '@migration/migration.module';
import { MatMenuModule } from '@angular/material/menu';
import { DataCollectionHeaderComponent } from './data-collection-page/data-collection-header/data-collection-header.component';
import { DataCollectionsTopBarComponent } from './data-collections-home/data-collections-top-bar/data-collections-top-bar.component';
import { DataCollectionCardComponent } from './shared/components/data-collection-card/data-collection-card.component';
import { AddObjectToCollectionModalComponent } from './shared/components/add-object-to-collection-modal/add-object-to-collection-modal.component';
import { EmptyDataCollectionPlaceholderComponent } from './data-collection-page/empty-data-collection-placeholder/empty-data-collection-placeholder.component';
import { DataCollectionIconComponent } from './shared/components/data-collection-icon/data-collection-icon.component';
import { DataCollectionPageRightPanelDatasetComponent } from './data-collection-page/data-collection-page-right-panel/data-collection-page-right-panel-dataset/data-collection-page-right-panel-dataset.component';
import { DataCollectionPageRightPanelDiscoverableDatasetComponent } from './data-collection-page/data-collection-page-right-panel/data-collection-page-right-panel-discoverable-dataset/data-collection-page-right-panel-discoverable-dataset.component';
import { EditSecuritySettingsModalComponent } from './shared/components/edit-security-settings-modal/edit-security-settings-modal.component';
import { DatasetPreviewModalComponent } from '../../shared/services/dataset-and-table-preview/dataset-preview-modal/dataset-preview-modal.component';
import { MatTabsModule } from '@angular/material/tabs';
import { DataCollectionPageRightPanelComponent } from './data-collection-page/data-collection-page-right-panel/data-collection-page-right-panel.component';
import { SetDataCollectionColorModalComponent } from './shared/components/set-data-collection-color-modal/set-data-collection-color-modal.component';
import { DataCatalogNavModule } from '@features/data-catalog/data-catalog-tabs/data-catalog-nav.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { DkuMatTooltipModule } from '@shared/components/dku-mat-tooltip/dku-mat-tooltip.module';

@NgModule({
    declarations: [
        DataCollectionsHomeComponent,
        CreateDataCollectionModalComponent,
        DataCollectionPageComponent,
        DataCollectionPathComponent,
        DataCollectionSummaryComponent,
        DataCollectionContentTableComponent,
        AddObjectToCollectionModalComponent,
        DataCollectionHeaderComponent,
        DataCollectionsTopBarComponent,
        DataCollectionCardComponent,
        DataCollectionPageRightPanelComponent,
        EmptyDataCollectionPlaceholderComponent,
        DataCollectionIconComponent,
        DataCollectionPageRightPanelDatasetComponent,
        DataCollectionPageRightPanelDiscoverableDatasetComponent,
        EditSecuritySettingsModalComponent,
        DatasetPreviewModalComponent,
        SetDataCollectionColorModalComponent,
    ],
    imports: [
        CommonModule,
        PipesModule,
        DataikuAPIModule,
        AutocompletableTextareaModule,
        FormsModule,
        MatDialogModule,
        MatTabsModule,
        ModalsModule,
        ReactiveFormsModule,
        StockColorPickerModule,
        MatTableModule,
        DkuMatTooltipModule,
        MatMenuModule,
        ComponentsModule,
        MigrationModule,
        UIRouterModule,
        DataCatalogNavModule,
        WidgetsModule
    ],
    exports: [
        DataCollectionCardComponent,
        DataCollectionPageRightPanelDatasetComponent
    ]
})

export class DataCollectionsModule {}
