import { Injectable } from "@angular/core";
import { APIError, ErrorContext } from "@core/dataiku-api/api-error";
import { DataikuAPIService } from "@core/dataiku-api/dataiku-api.service";
import { CurrentRouteService } from "@core/nav/current-route.service";
import { WaitingService } from "@core/overlays/waiting.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CellData } from "@shared/services/item-feed/items-data-fetcher.service";
import { BehaviorSubject, EMPTY, from, Observable, of, Subject, zip } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { InteractiveModelParams } from "src/generated-sources";


export abstract class DeephubInteractiveScoringCellData implements CellData {
    private readonly SRC_REGEXP = /^data\:image\/(?<format>\w+);base64,(?<base64Repr>[A-Za-z0-9+/=]+)$/; 
    
    itemId: string;  // holding the base 64 url representation (e.g. "data:image/png;base64,XXXXX") of the image to fit the DataFetcher API

    fileName: string;
    format: string;
    base64Repr: string;
    score: any;
    selected: boolean;
    itemIndex: number;

    constructor(imageSrcAsBase64: string, fileName: string) {
        this.itemId = imageSrcAsBase64;
        this.fileName = fileName;

        const match = this.SRC_REGEXP.exec(imageSrcAsBase64);

        if (!match) {
            throw new Error("Passed string is not a base64 url " + imageSrcAsBase64);
        }

        this.format = match.groups?.format!;
        this.base64Repr = match.groups?.base64Repr!;
    }

    public abstract setScore(modelResponse: any): void;
    public abstract setExplanation(explanations: any): void;

    public setIndex(index: number) {
        this.itemIndex = index;
    }
}

@UntilDestroy()
@Injectable()
export abstract class InteractiveScoringService implements ErrorContext {
    loadedCellDataItems$ = new BehaviorSubject<DeephubInteractiveScoringCellData[]>([]);
    loading$ = new BehaviorSubject<boolean>(false);
    imagesUploaded$ = new Subject<DeephubInteractiveScoringCellData[]>();
    private error$ = new BehaviorSubject<APIError | undefined>(undefined);
    private loadStoredImages$ = new Subject<void>();
    private clearImages$ = new Subject<void>();
    private currentFullModelId$ = new Subject<string>();
    private sessionStorageKey$: Observable<string>;

    constructor(
        private DataikuAPI: DataikuAPIService,
        private currentRouteService: CurrentRouteService,
        private waitingService: WaitingService
    ) {

        this.sessionStorageKey$ = this.currentFullModelId$.pipe(
            map(fmi => `dku.whatif.${fmi}`),
            untilDestroyed(this));

        this.imagesUploaded$.pipe(
            withLatestFrom(this.currentFullModelId$),
            switchMap(([uploadedCellDataItems, currentFmi]) => {
                return zip(
                    of(uploadedCellDataItems),
                    this.DataikuAPI.analysis.computeWithInteractiveModel(
                        currentFmi,
                        this.getComputationParams(),
                        uploadedCellDataItems.map(uploadedCellData => ({input: uploadedCellData.base64Repr}))
                    ).pipe(this.waitingService.bindOverlayAndWaitForResult(),
                            catchError((error: APIError) => {
                                this.loading$.next(false);
                                this.pushError(error);
                                return EMPTY;
                            })
                    )
                );
            }),
            map(([uploadedCellData, results]) => {
                uploadedCellData.forEach((uploadedCellData, index) => {
                    const result = results[index];
                    uploadedCellData.setScore(result.score);
                    if (result.explanation){
                        uploadedCellData.setExplanation(result.explanation);
                    }
                }); 
                return uploadedCellData;
            }),
            withLatestFrom(this.loadedCellDataItems$, this.sessionStorageKey$),
            untilDestroyed(this)
        ).subscribe(([uploadedCellDataItems, loadedCellDataItems, sessionStorageKey]) => {
            const newLoadedCellDataItems = [...uploadedCellDataItems, ...loadedCellDataItems];
            newLoadedCellDataItems.forEach((cellData, index) => cellData.setIndex(index))

            if (this.supportsSessionStorageCaching()) {
                try {
                    sessionStorage.setItem(
                        sessionStorageKey,
                        // store partial to not overload session storage
                        JSON.stringify(newLoadedCellDataItems.map((item: Partial<DeephubInteractiveScoringCellData>) => ({
                            itemId: item.itemId,
                            fileName: item.fileName
                        })))
                    );
                } catch (e) {
                    console.warn(e);
                }
            }

            this.loadedCellDataItems$.next(newLoadedCellDataItems);
            this.loading$.next(false);
        });

        this.loadStoredImages$.pipe(
            withLatestFrom(this.sessionStorageKey$),
            untilDestroyed(this)
        ).subscribe(([_, sessionStorageKey]) => {
            if (this.supportsSessionStorageCaching()) {
                // ensure that items have an item path and a file name
                let images: Partial<DeephubInteractiveScoringCellData>[] = (JSON.parse(sessionStorage.getItem(sessionStorageKey)!) || []).filter((image: Partial<DeephubInteractiveScoringCellData>) => image.itemId && image.fileName);
                if (images.length) {
                    this.loading$.next(true);
                    this.imagesUploaded$.next(images.map(image => this.createCellData(image.itemId!, image.fileName!)));
                }
            }
        });

        this.clearImages$.pipe(
            withLatestFrom(this.sessionStorageKey$),
            untilDestroyed(this)
        ).subscribe(([_, sessionStorageKey]) => {
            this.loadedCellDataItems$.next([]);
            if (this.supportsSessionStorageCaching()) {
                sessionStorage.removeItem(sessionStorageKey);
            }
        });
    }

    abstract createCellData(image: string, file: string): DeephubInteractiveScoringCellData;

    setFullModelid(fmi: string) {
        this.currentFullModelId$.next(fmi);
    }

    getLoading(): Observable<boolean> {
        return this.loading$;
    }

    getLoadedCellDataItems(): BehaviorSubject<DeephubInteractiveScoringCellData[]> {
        return this.loadedCellDataItems$;
    }

    loadStoredImages() {
        this.loadStoredImages$.next();
    }

    private supportsSessionStorageCaching(): boolean {
        // When we are NOT in a dashboard
        return !this.currentRouteService.dashboardId && !this.currentRouteService.insightId;
    }

    clearImages() {
        this.clearImages$.next();
    }

    private uploadImageAsBase64(file: File): Observable<DeephubInteractiveScoringCellData> {
        return from(new Promise<DeephubInteractiveScoringCellData>((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = (event: ProgressEvent<FileReader>) => {
                if (reader.result) {
                    resolve(this.createCellData(reader.result as string, file.name));
                }
            }
            reader.readAsDataURL(file)
        }));
    }

    uploadFiles(files: FileList) {
        this.loading$.next(true);
        
        const uploadedFiles$ = [] as Observable<DeephubInteractiveScoringCellData>[];
        for (var i = 0; i < files.length; i++) { 
            uploadedFiles$.push(this.uploadImageAsBase64(files[i]));
        }

        zip(...uploadedFiles$).subscribe((uploadedFiles) => {
            this.imagesUploaded$.next(uploadedFiles);
        })
    }

    protected abstract getNbExplanations(): number;

    private getComputationParams(): InteractiveModelParams.ComputationParams {
        const nExplanations = this.getNbExplanations();
        if (nExplanations > 0) {
            return {
                type: InteractiveModelParams.ExplanationsParams.type,
                nExplanations: nExplanations,
                applyPreparationScript: false
            }
        } else {
            return {
                type: InteractiveModelParams.ScoringParams.type,
                applyPreparationScript: false
            }
        }
    }

    pushError(error?: APIError): void {
        this.error$.next(error);
    }

    getError() {
        return this.error$;
    }
}
