import { ChangeDetectionStrategy, Component, SimpleChanges, Input, Output, EventEmitter, OnChanges } from "@angular/core";
import { ExperimentTrackingService, MetricInfo, ModelArtifactInfo, RunViewModel } from "@features/experiment-tracking/experiment-tracking.service";
import { EChartsOption } from "echarts";
import _ from "lodash";
import { RunParam, RunTag } from "src/generated-sources";
import { copyToClipboard } from '@utils/clipboard';
import { SmartNumberPipe } from '@shared/pipes/number-pipes/smart-number.pipe';
import { formatTime } from "@features/experiment-tracking/utils";
import { fairAny } from "dku-frontend-core";
import { YYYYMMDDHHmmssDateTimePipe } from "@shared/pipes/date-pipes/yyyymmddhhmmss-date-time.pipe";

@Component({
    selector: '[experiment-tracking-run-details]',
    templateUrl: './experiment-tracking-run-details.component.html',
    styleUrls: ['./experiment-tracking-run-details.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperimentTrackingRunDetailsComponent implements OnChanges {
    readonly PARAMS_MAX_COLUMNS = 4;
    readonly METRICS_MAX_COLUMNS = 4;
    readonly TAGS_MAX_COLUMNS = 4;
    readonly SYSTEM_TAGS_MAX_COLUMNS = 2;
    readonly MODELS_MAX_COLUMNS = 3;
    readonly CHARTS_COLOR = "#1f77b4";

    @Input() run: RunViewModel;
    @Output() refreshRun = new EventEmitter<void>();
    @Output() openDeployModal = new EventEmitter<void>();

    chartOptions: EChartsOption;

    metricsInfos: MetricInfo[] = [];
    tags: RunTag[] = [];
    systemTags: RunTag[] = [];

    metricsInfosInCols: MetricInfo[][] = [];
    runParamsInCols: RunParam[][] = [];
    modelsInCols: ModelArtifactInfo[][] = [];
    tagsInCols: RunTag[][] = [];
    systemTagsInCols: RunTag[][] = [];

    bigTiles: boolean = true;
    displayStepsAsTSDiff = true;
    allChartsShown: (boolean | undefined) = false;
    areMetricsWithMultipleSteps: boolean = false;

    constructor(
        private experimentTrackingService: ExperimentTrackingService,
        private YYYYMMDDHHmmssDateTimePipe: YYYYMMDDHHmmssDateTimePipe,
        private smartNumberPipe: SmartNumberPipe,
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.run) {
            const oldMetricsInfos = this.metricsInfos;

            this.metricsInfos = Array.from(this.run.metrics.values());
            this.areMetricsWithMultipleSteps = this.metricsInfos.some(m => m.isMultiStep);
            this.metricsInfosInCols = this.splitInCols(this.metricsInfos, this.METRICS_MAX_COLUMNS);
            this.runParamsInCols = this.splitInCols(this.run.data?.runParams, this.PARAMS_MAX_COLUMNS);
            this.modelsInCols = this.splitInCols([...this.run.models?.values()], this.MODELS_MAX_COLUMNS);
            this.tags = this.experimentTrackingService.getTags(this.run);
            this.tagsInCols = this.splitInCols(this.tags, this.TAGS_MAX_COLUMNS);
            this.systemTags = this.experimentTrackingService.getSystemTags(this.run);
            this.systemTagsInCols = this.splitInCols(this.systemTags, this.SYSTEM_TAGS_MAX_COLUMNS);

            if (oldMetricsInfos && oldMetricsInfos.length == this.metricsInfos.length) {
                oldMetricsInfos.forEach(omi => {
                    const found = this.metricsInfos.find((mi => mi.key == omi.key));
                    if (found) {
                        found.displaySteps = omi.displaySteps;
                    }
                })
            }
            this.chartOptions = this.buildChartOptions();
        }
    }

    requestRunRefresh() {
        this.refreshRun.emit();
    }

    canDeploy() {
        return !this.hasArtifact() || this.getNbModels() > 0;
    }

    requestOpenDeployModal() {
        this.openDeployModal.emit();
    }

    hasArtifact() {
        return this.run.origin != "analysis";
    }

    getNbModels() {
        if(this.run.origin == "analysis") {
            return 1;
        }
        return this.run.data?.models?.length || 0;
    }

    buildChartOptions() {
        const HEIGHT = this.bigTiles ? 200 : 120;
        const HEIGHT_MARGIN = 30;
        const WIDTH = this.bigTiles ? 280 : 170;
        const WIDTH_MARGIN = 30;
        const MAX_COLS = this.bigTiles ? 3 : 6;

        const multiStepMetrics = this.metricsInfos.filter(mi => mi.isMultiStep && mi.displaySteps);
        if (multiStepMetrics.length == 0) {
            return undefined;
        }

        let maxDuration = 0;
        const series = multiStepMetrics.map((mi, idx) => {
            let data;
            const TS0 = mi.serie[0].timestamp;
            if (!this.displayStepsAsTSDiff) {
                data = mi.serie.map(
                    metricValue => {
                    if (this.run.keptEpoch === metricValue.step) {
                        return {
                            symbol: "pin",
                            symbolSize: 10,
                            value: [ metricValue.step, metricValue.value ]
                        };

                    } else {
                        return { value: [ metricValue.step, metricValue.value ] };
                    }
                });
            } else {
                data = mi.serie.map(metricValue => {
                    if (this.run.keptEpoch === metricValue.step) {
                        return {
                            symbol: "pin",
                            symbolSize: 10,
                            value: [ metricValue.timestamp - TS0, metricValue.value ]
                        };

                    } else {
                        return { value: [ metricValue.timestamp - TS0, metricValue.value ] };
                    }
                });
                const duration = mi.serie[mi.serie.length - 1].timestamp - TS0;
                maxDuration = Math.max(maxDuration, duration);
            }

            const color = this.CHARTS_COLOR;
            return {
                animation: false,
                type: 'line',
                symbol: 'circle',
                symbolSize: 1,
                smooth: true,
                name: mi.key,
                xAxisIndex: idx,
                yAxisIndex: idx,
                lineStyle: {
                    width: 1,
                    color
                },
                emphasis: {
                    itemStyle: {
                        borderWidth: 4,
                        borderColor: color
                    },
                    lineStyle: {
                        width: 1
                    }
                },
                dataZoom: [{
                    type: 'slider',
                    show: true,
                    xAxisIndex: [0, 1],
                    start: 1,
                    end: 70
                },
                ],
                data
            };
        });
        const xAxis = multiStepMetrics.map((mi, idx) => {
            return {
                type: 'value',
                scale: true,
                gridIndex: idx,
                nameLocation: 'middle',
                nameGap: 30,
                min: 0,
                name: this.displayStepsAsTSDiff ? 't' : 'Step',
                axisPointer: {
                    label: {
                        precision: 0
                    }
                },
                axisLabel: {
                    hideOverlap: true,
                    formatter: (value: number): string => {
                        return this.displayStepsAsTSDiff ? formatTime(value, maxDuration) : this.smartNumberPipe.transform(value);
                    }
                },
                splitNumber: this.bigTiles ? 6 : 3
            };
        });
        const yAxis = multiStepMetrics.map((mi, idx) => {
            return {
                type: 'value',
                axisLabel: {
                    formatter: (value: number): string => {
                        return this.smartNumberPipe.transform(value);
                    }
                },
                min: 0,
                max: _.maxBy(mi.serie, metricValue => metricValue.value)?.value,
                gridIndex: idx,
                splitNumber: 3
            };
        });

        const grid = multiStepMetrics.map((mi, idx) => {
            const row = Math.floor(idx / MAX_COLS);
            const col = idx % MAX_COLS;
            return {
                left: 30 + (WIDTH + WIDTH_MARGIN) * col + 'px',
                top: row * (HEIGHT + HEIGHT_MARGIN + 30) + 30 + 'px',
                height: HEIGHT + 'px',
                width: WIDTH + 'px',
                containLabel: true
            };
        });

        const title = multiStepMetrics.map((mi, idx) => {
            const row = Math.floor(idx / MAX_COLS);
            const col = idx % MAX_COLS;
            return {
                left: 15 + (WIDTH + WIDTH_MARGIN) * col + 'px',
                top: row * (HEIGHT + HEIGHT_MARGIN + 30) + 'px',
                text: mi.key,
                textStyle: {
                    fontWeight: 700,
                    color: '#000000',
                    fontSize: 13,
                    fontFamily: 'SourceSansPro',
                    width: WIDTH,
                    overflow: 'truncate'
                }
            };
        });

        const colors = multiStepMetrics.map(() => this.CHARTS_COLOR);

        return <any>{
            chartHeight: 30 + Math.floor((multiStepMetrics.length - 1) / MAX_COLS + 1) * (HEIGHT + HEIGHT_MARGIN + 30) + 'px',
            chartWidth: 30 + Math.min(multiStepMetrics.length, MAX_COLS) * (WIDTH + WIDTH_MARGIN) + 'px',
            color: colors,
            title,
            grid,
            axisPointer: {
                show: true,
                link: [
                    {
                        xAxisIndex: 'all'
                    },
                    {
                        yAxisIndex: 'none'
                    }
                ],
                snap: true,
                lineStyle: {
                    type: "dashed"
                },
                triggerTooltip: false,
                label: {
                    formatter: (params: fairAny) => {
                        let value = params.value;
                        if(params.axisDimension === 'x' && this.displayStepsAsTSDiff) {
                            value = formatTime(value, maxDuration);
                        } else {
                            value = this.smartNumberPipe.transform(value);
                        }
                        return value;
                    }
                }
            },
            xAxis,
            yAxis,
            series,
            tooltip: {
                trigger: 'item',
                showContent: true,
                formatter: (params: any) => {
                    if (!this.displayStepsAsTSDiff) {
                        let ret = "<table><thead><th></th><th>Name</th><th>Value</th><th>Step</th><th>Timestamp</th></thead><tbody>";
                        for (const msm of multiStepMetrics) {
                            const entry = msm.serie.filter((e : any) => e.step == params.value[0])[0]
                            const value = entry == undefined ? undefined : entry.value;
                            const step = entry == undefined ? undefined : entry.step;
                            const ts = entry == undefined ? undefined : this.YYYYMMDDHHmmssDateTimePipe.transform(entry.timestamp);
                            ret += `<tr><td style="padding-left: 10px">${params.marker.replace(/#[a-f0-9]{6}/i, this.CHARTS_COLOR)}</td>
                            <td style="padding-right: 10px">${msm.key}</td><td style="padding: 0 10px">${value ? this.smartNumberPipe.transform(value) : '-'}</td><td style="padding: 0 10px">${step}</td><td style="padding: 0 10px">${ts}</td></tr>`;
                        }
                        ret += "</tbody></table>"
                        return ret;
                    } else {
                        return `${params.marker.replace(/#[a-f0-9]{6}/i, this.CHARTS_COLOR)} ${formatTime(params.value[0], maxDuration)}: ${this.smartNumberPipe.transform(params.value[1])}`;
                    }
                },
                rich: {
                    bold: {
                        fontWeight: 'bold'
                    }
                },
                confine: true
            }
        };
    }

    checkAllStepsDisplay() {
        let chartsShown = this.metricsInfos.reduce((previousValue, currentValue) => previousValue + (currentValue.displaySteps ? 1 : 0), 0);
        if (chartsShown == this.metricsInfos.length) {
            this.allChartsShown = true;
        } else if (chartsShown == 0) {
            this.allChartsShown = false;
        } else {
            this.allChartsShown = undefined;
        }
    }

    toggleStepsDisplay(toToggle: MetricInfo) {
        toToggle.displaySteps = !toToggle.displaySteps;
        this.checkAllStepsDisplay();
        this.chartOptions = this.buildChartOptions();
    }

    toggleBigTiles() {
        this.bigTiles = !this.bigTiles;
        this.chartOptions = this.buildChartOptions();
    }

    splitInCols<T>(items: T[], maxColumns: number) {
        const MIN_ROWS = 3;
        const rowsPerColumn = (items.length < maxColumns * MIN_ROWS) ? MIN_ROWS : Math.ceil(items.length / maxColumns);
        const results = [];
        var itemsCopy = [...items];

        while (itemsCopy.length) {
            results.push(itemsCopy.splice(0, rowsPerColumn));
        }

        return results;
    }

    toggleStepsDisplayMode(value: boolean) {
        this.displayStepsAsTSDiff = value;
        this.chartOptions = this.buildChartOptions();
    }

    toggleAllCharts() {
        this.allChartsShown = !this.allChartsShown;
        this.metricsInfos.forEach((metricInfo: MetricInfo) => metricInfo.displaySteps = Boolean(this.allChartsShown));
        this.chartOptions = this.buildChartOptions();
    }

    copyToClipboard(text: string) {
        copyToClipboard(text);
    }
}
