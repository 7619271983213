import { Component, ChangeDetectionStrategy, Input, SimpleChange, OnChanges, SimpleChanges } from '@angular/core';
import { statusIconAndColor } from '@features/labeling/utils';
import { ReviewStatus } from '../../services/labeling-review.service';

@Component({
    selector: 'review-status-icon',
    templateUrl: './review-status-icon.component.html',
    styleUrls: ['./review-status-icon.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewStatusIconComponent implements OnChanges {

    @Input() status: ReviewStatus;

    iconDetails: {color: string, icon: string};

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.status && changes.status.currentValue) {
            this.iconDetails = statusIconAndColor(changes.status.currentValue)
        }
    }
}
