<div class="recipe-settings-section1 w800">
    <h1 class="recipe-settings-section1-title">
        Principal Component Analysis settings
    </h1>

    <div class="recipe-settings-section2">
        <form class="dkuform-horizontal"
            [formGroup]="configForm"
        >
            <div *ngIf="selectedColumns.length < 1" class="alert alert-error">
                <i class="icon-dku-error icon-error-color mright4"></i>
                Principal component analysis requires at least one selected column.
                Running this recipe will fail.
            </div>

            <div class="control-group">
                <div class="control-label">
                    Input columns
                </div>
                <basic-select *ngIf="columns$ | async as inputColumns"
                    formControlName="columns"
                    [items]="inputColumns"
                    bindLabel="name"
                    bindValue="name"
                    [multiple]="true"
                    [searchable]="true"
                ></basic-select>
            </div>
        </form>
    </div>
</div>

<div class="recipe-settings-section1 w800">
    <h1 class="recipe-settings-section1-title">
        Output
    </h1>

    <div class="recipe-settings-section2">
        <form class="dkuform-horizontal"
            [formGroup]="configForm"
        >
            <div *ngIf="forwardedColumns.length > 0 && (projectionsOutput$ | async) === undefined" class="alert alert-info">
                <i class="icon-info-sign icon-info-color mright4"></i>
                You must set a projections output dataset in order to forward input columns.
            </div>

            <div class="control-group">
                <div class="control-label">
                    Input columns to forward
                </div>
                <basic-select *ngIf="columns$ | async as inputColumns"
                    formControlName="forwardedColumns"
                    [items]="inputColumns"
                    bindLabel="name"
                    bindValue="name"
                    [multiple]="true"
                    [searchable]="true"
                ></basic-select>
            </div>

            <div class="control-group">
                <div class="control-label">
                    Add computation timestamp
                </div>
                <input type="checkbox" class="control" formControlName="addComputationTimestamp"/>
            </div>
        </form>
    </div>
</div>
