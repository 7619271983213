import { Injectable } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Chunk } from "@shared/components/infinite-scroll/infinite-scroll.component";
import { ImagesDataFetcherService } from "@shared/services/item-feed/image-feed/images-data-fetcher.service";
import { deepDistinctUntilChanged } from "dku-frontend-core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { InteractiveScoringService } from "./interactive-scoring-data.service";

@UntilDestroy()
@Injectable()
export class DeephubInteractiveScoringDataFetcherService extends ImagesDataFetcherService {

    constructor(private interactiveScoringService: InteractiveScoringService) {
        super();

        this.interactiveScoringService.getLoadedCellDataItems().subscribe(() => {
            this.refetchData();
        });
    }

    getChunk(offset: number): Observable<Chunk> {
       return this.interactiveScoringService.getLoadedCellDataItems().pipe(
        deepDistinctUntilChanged(),
        map((loadedImagesInfo) => {
            return {
                totalItems: loadedImagesInfo.length,
                chunkItems: loadedImagesInfo.slice(offset, offset + this.CHUNK_SIZE)
            }
        })
       )
    }

    getImagePath(itemPath: string): string {
        // Need override because image not coming from a Managed Folder
        return itemPath;
   }

}
