import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { SignTest1SampCard, Variable } from 'src/generated-sources';
import { FormBuilder, Validators } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { CardWizardService } from '../../../card-wizard/card-wizard.service';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'sign-test1-samp-card-config',
  templateUrl: './sign-test1-samp-card-config.component.html',
  styleUrls: ['./sign-test1-samp-card-config.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignTest1SampCardConfigComponent implements OnInit, OnChanges, OnDestroy {
    @Input() params: SignTest1SampCard;
    @Output() paramsChange = new EventEmitter<SignTest1SampCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    allVariables$: Observable<Variable[]>;

    configForm = this.fb.group({
        hypothesizedMedian: this.fb.control(null, [Validators.required]),
        column: this.fb.control(null, [Validators.required])
    });

    constructor(
        private fb: FormBuilder,
        private cardWizardService: CardWizardService
    ) {
        this.configForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((formValue) => this.paramsChange.emit({
                ...this.params,
                column: formValue.column,
                hypothesizedMedian: formValue.hypothesizedMedian
            }));

        this.configForm.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => this.validityChange.emit(this.configForm.valid));
    }

    ngOnInit() {
        this.allVariables$ = this.cardWizardService.availableVariables(this.params.type);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.configForm.patchValue({
                hypothesizedMedian: this.params.hypothesizedMedian,
                column: this.params.column
            });
        }
    }

    ngOnDestroy() {

    }
}
