import { Inject, Pipe, PipeTransform } from '@angular/core';
import { fairAny } from 'dku-frontend-core';

@Pipe({
    name: 'typeToIcon'
})
export class TypeToIconPipe implements PipeTransform {
    constructor(@Inject('TypeMappingService') private readonly typeMappingService: fairAny) { }

    transform(type?: string, size?: number): string {
        return this.typeMappingService.mapTypeToIcon(type, size);
    }
}
