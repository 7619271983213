export namespace PythonCodeEnvPackagesUtils {
    /**
     * Generated from com.dataiku.dip.code.PythonCodeEnvPackagesUtils$ExperimentTrackingCompatibilityInfo
     */
    export interface ExperimentTrackingCompatibilityInfo {
        compatibilityStatus: PythonCodeEnvPackagesUtils.ExperimentTrackingCompatibilityInfo.CompatibilityStatus;
        reasons: string[];
    }

    /**
     * Generated from com.dataiku.dip.code.PythonCodeEnvPackagesUtils$CodeEnvExperimentTrackingCompat
     */
    export interface CodeEnvExperimentTrackingCompat {
        compatibilityInfo: PythonCodeEnvPackagesUtils.ExperimentTrackingCompatibilityInfo;
        envName: string;
    }
}

export namespace PythonCodeEnvPackagesUtils.ExperimentTrackingCompatibilityInfo {
    /**
     * Generated from com.dataiku.dip.code.PythonCodeEnvPackagesUtils$ExperimentTrackingCompatibilityInfo$CompatibilityStatus
     */
    export enum CompatibilityStatus {
        INCOMPATIBLE = 'INCOMPATIBLE',
        MAYBE_COMPATIBLE = 'MAYBE_COMPATIBLE',
        TESTED_COMPATIBLE = 'TESTED_COMPATIBLE'
    }
}