import { Pipe, PipeTransform } from '@angular/core';

/**
 * Filter out null and undefined elements from input array
 */
@Pipe({ name: 'filterDefined' })
export class FilterDefinedPipe implements PipeTransform {
    transform<T>(v: (T | null | undefined)[] | null | undefined) {
        return (v || []).filter((x: T | null | undefined): x is T => x != null);
    }
}
