import type {UserAttributes} from './user-attributes';

/**
 * Generated from com.dataiku.dip.security.auth.ExternalUser
 */
export interface ExternalUser {
    profile: string;
    status: ExternalUser.Status;
    userAttributes: UserAttributes;
}

export namespace ExternalUser {
    /**
     * Generated from com.dataiku.dip.security.auth.ExternalUser$Status
     */
    export enum Status {
        NOT_PROVISIONED = 'NOT_PROVISIONED',
        UNSYNCED = 'UNSYNCED',
        SYNCED = 'SYNCED',
        SOURCE_CONFLICT = 'SOURCE_CONFLICT'
    }
}