import type {Card} from './../card';
import type {PValueAdjustmentMethod} from './../../../compute/stats/pvalue-adjustment-method';
import type {PairwiseMoodTestCard} from './../pairwise-mood-test-card';
import type {PairwiseTTestCard} from './../pairwise-ttest-card';
import type {_AbstractNSampTestCard} from './abstract-nsamp-test-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.common.AbstractPairwiseTestCard
 */
export interface _AbstractPairwiseTestCard extends _AbstractNSampTestCard {
    adjustmentMethod: PValueAdjustmentMethod;
}

export type AbstractPairwiseTestCard = PairwiseMoodTestCard | PairwiseTTestCard;

export namespace AbstractPairwiseTestCard {
    export const type = ['pairwise_mood', 'pairwise_ttest'] as const;
}

/** @deprecated Use Card.isAbstractPairwiseTestCard() */ export function isAbstractPairwiseTestCard(toTest: Card): toTest is AbstractPairwiseTestCard { return (AbstractPairwiseTestCard.type as readonly unknown[]).includes(toTest['type']); }