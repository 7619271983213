import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'labeling-metadata-content',
  templateUrl: './labeling-metadata-content.component.html',
  styleUrls: ['./labeling-metadata-content.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelingMetadataContentComponent {
    @Input() content: {[key: string]: any} | undefined;
}
