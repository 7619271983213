<div>
    <div class="alert alert-error mbot8" *ngIf="!image.valid">
        <i class="icon-dku-error mright4"></i> Path not found in managed folder
    </div>
    <div class="image-metadata__columns" *ngFor="let column of displayedColums">
        <div class="image-metadata__columns-name mx-textellipsis" [title]="column.name">
            {{column.name}}
        </div>
        <div class="image-metadata__columns-content mx-textellipsis" [title]="column.content">
            {{column.content}}
        </div>
    </div>
</div>
