import type {AxisDef} from './axis-def';

/**
 * Generated from com.dataiku.dip.pivot.backend.model.Unaggregated
 */
export interface Unaggregated {
    column: string;
    dateMode: Unaggregated.DateMode;
    id: string;
    type: AxisDef.Type;
}

export namespace Unaggregated {
    /**
     * Generated from com.dataiku.dip.pivot.backend.model.Unaggregated$DateMode
     */
    export enum DateMode {
        RANGE = 'RANGE',
        HOUR_OF_DAY = 'HOUR_OF_DAY',
        DAY_OF_WEEK = 'DAY_OF_WEEK',
        DAY_OF_MONTH = 'DAY_OF_MONTH',
        MONTH_OF_YEAR = 'MONTH_OF_YEAR',
        WEEK_OF_YEAR = 'WEEK_OF_YEAR',
        QUARTER_OF_YEAR = 'QUARTER_OF_YEAR',
        YEAR = 'YEAR'
    }
}