import { Pipe, PipeTransform } from '@angular/core';
import { Dict } from 'dku-frontend-core';

@Pipe({
    name: 'infoMessageAlertClass'
})
export class InfoMessageAlertClassPipe implements PipeTransform {

    transform(infoMessage: InfoMessage) {
        return dict[infoMessage.severity];
    }

}

const dict: Dict<string> = {
    'ERROR': 'alert-danger',
    'WARNING': 'alert-warning',
    'INFO': 'alert-info'
};

interface InfoMessage { //TODO move
    severity: string;
}
