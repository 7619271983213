
<div class="modal modal--analysis" [qa]="'eda-wizard-modal'">
    <modal-header [title]="title" [icon]="state === WizardState.ANALYSIS ? '' : 'icon-arrow-left'" (iconAction)="goToPreviousState(state)" [hasBorder]="false"></modal-header>
    <div class="modal-body flex modal-body--analysis no-padding" [ngClass]="{'modal-body--test': state === WizardState.TEST }">
        <ng-container [ngSwitch]="state">
            <ng-container *ngSwitchCase="WizardState.VARIABLES">
                <card-editor
                    [params]="params"
                    (paramsChange)="changeParams($event)"
                    (validityChange)="changeValidity($event)"
                    (validityTooltipChange)="changeValidityTooltip($event)"
                ></card-editor>
            </ng-container>

            <ng-container *ngSwitchCase="WizardState.ANALYSIS">
                <div class="new-card card-wizard__body--wide">
                    <h4 class="analysis-header">
                        Automated selection
                    </h4>

                    <a class="new-card-type type-discover" (click)="goToState(WizardState.AUTOMAGIC)"
                        [qa]="{'eda-wizard-navigate': {'category': 'assistant'}}">
                        <div class="new-card-type__body">
                            <img class="new-card-type__icon" src="/static/dataiku/images/eda/eda-suggest.png">
                            <h5 class="new-card-type__title">Automatically suggest analyses</h5>
                            <p class="new-card-type__description">
                                Explore the dataset and perform analyses on variables of interest.
                            </p>
                        </div>
                    </a>

                    <h4 class="analysis-header">
                        Manual selection
                    </h4>

                    <div>
                        <a class="new-card-type" (click)="setCardType({ type: 'univariate_header' })"
                            [qa]="{'eda-wizard-navigate': {'category': 'univariate'}}">
                            <div class="new-card-type__body">
                                <img class="new-card-type__icon" src="/static/dataiku/images/eda/eda-univariate-analysis.png">
                                <h5 class="new-card-type__title">Univariate analysis</h5>
                                <p class="new-card-type__description">
                                    Discover your variables and see their distributions side-by-side.
                                </p>
                            </div>
                        </a>
                        <a class="new-card-type" (click)="setCardType({ type: 'bivariate_header' })"
                            [qa]="{'eda-wizard-navigate': {'category': 'bivariate'}}">
                            <div class="new-card-type__body">
                                <img class="new-card-type__icon" src="/static/dataiku/images/eda/eda-bivariate-analysis.png">
                                <h5 class="new-card-type__title">Bivariate analysis</h5>
                                <p class="new-card-type__description">
                                    Look into the relationship between pairs of variables simultaneously.
                                </p>
                            </div>
                        </a>
                        <a class="new-card-type" (click)="goToState(WizardState.TEST)"
                            [qa]="{'eda-wizard-navigate': {'category': 'test'}}">
                            <div class="new-card-type__body">
                                <img class="new-card-type__icon" src="/static/dataiku/images/eda/eda-statistical-test.png">
                                <h5 class="new-card-type__title">Statistical tests</h5>
                                <p class="new-card-type__description">
                                    Make quantitative decisions by testing hypotheses.
                                </p>
                            </div>
                        </a>
                        <a class="new-card-type" (click)="goToState(WizardState.FIT)"
                            [qa]="{'eda-wizard-navigate': {'category': 'fit'}}">
                            <div class="new-card-type__body">
                                <img class="new-card-type__icon" src="/static/dataiku/images/eda/eda-fit-curve-distribution.png">
                                <h5 class="new-card-type__title">Fit curves & distributions</h5>
                                <p class="new-card-type__description">
                                    Model variable distributions or relationships.
                                </p>
                            </div>
                        </a>
                        <a class="new-card-type" (click)="goToState(WizardState.MULTIVARIATE)"
                            [qa]="{'eda-wizard-navigate': {'category': 'multivariate'}}">
                            <div class="new-card-type__body">
                                <img class="new-card-type__icon" src="/static/dataiku/images/eda/eda-multivariate-analysis.png">
                                <h5 class="new-card-type__title">Multivariate analysis</h5>
                                <p class="new-card-type__description">
                                    Look into the relationship of multiple variables simultaneously.
                                </p>
                            </div>
                        </a>
                        <a class="new-card-type" (click)="goToState(WizardState.TIME_SERIES)"
                            [qa]="{'eda-wizard-navigate': {'category': 'time-series'}}"
                        >
                            <div class="new-card-type__body">
                                <img class="new-card-type__icon" src="/static/dataiku/images/eda/eda-time-series-analysis.png">
                                <h5 class="new-card-type__title">Time series analysis</h5>
                                <p class="new-card-type__description">
                                    Check the statistical properties of a series.
                                </p>
                            </div>
                        </a>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="WizardState.FIT">
                <div class="card-wizard__body--wide">
                    <a class="new-card-type" (click)="setCardType({ type: 'fit_distribution' })"
                        [qa]="{'eda-wizard-navigate': {'category': 'fit-distribution'}}">
                        <div class="new-card-type__body">
                            <h5 class="new-card-type__title">Fit Distribution</h5>
                            <p class="new-card-type__description">
                                Estimate the parameters of the most common distributions (normal, exponential, ...)
                            </p>
                        </div>
                    </a>
                    <a class="new-card-type" (click)="setCardType({ type: 'fit_2d_distribution' })"
                        [qa]="{'eda-wizard-navigate': {'category': 'fit-2d-distribution'}}">
                        <div class="new-card-type__body">
                            <h5 class="new-card-type__title">2D Fit Distribution</h5>
                            <p class="new-card-type__description">
                                Visualize the density of bivariate distributions (KDE and bivariate gaussian).
                            </p>
                        </div>
                    </a>
                    <a class="new-card-type" (click)="setCardType({ type: 'fit_curve' })"
                        [qa]="{'eda-wizard-navigate': {'category': 'fit-curve'}}">
                        <div class="new-card-type__body">
                            <h5 class="new-card-type__title">Fit Curve</h5>
                            <p class="new-card-type__description">
                                Model the relationship between two variables.
                            </p>
                        </div>
                    </a>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="WizardState.TEST">
                <div class="new-card new-card--test horizontal-flex card-wizard__body--wide statistical-tests-menu">
                    <div class="new-card-menu">
                        <ul class="new-card__list">
                            <li (click)="setTestType(TestState.ONE_SAMPLE)" class="new-card-menu__item"
                                [ngClass]="{ 'new-card-menu__item--selected': selectedTest === TestState.ONE_SAMPLE }"
                                [qa]="{'eda-wizard-navigate': {'category': 'one-sample-test'}}">
                                <h5 class="new-card-menu__header">One-sample test</h5>
                                <span class="new-card-menu__subtext">1 <i class="icon-number"></i> variable</span>
                            </li>
                            <li (click)="setTestType(TestState.TWO_SAMPLE)" class="new-card-menu__item"
                                [ngClass]="{ 'new-card-menu__item--selected': selectedTest === TestState.TWO_SAMPLE }"
                                [qa]="{'eda-wizard-navigate': {'category': 'two-sample-test'}}">
                                <h5 class="new-card-menu__header">Two-sample test</h5>
                                <span class="new-card-menu__subtext">1 <i class="icon-number"></i> variable</span>
                                <span class="new-card-menu__subtext">1 <i class="icon-font"></i> variable with 2 modalities</span>
                            </li>
                            <li (click)="setTestType(TestState.N_SAMPLE)" class="new-card-menu__item"
                                [ngClass]="{ 'new-card-menu__item--selected': selectedTest === TestState.N_SAMPLE }"
                                [qa]="{'eda-wizard-navigate': {'category': 'n-sample-test'}}">
                                <h5 class="new-card-menu__header">N-sample test</h5>
                                <span class="new-card-menu__subtext">1 <i class="icon-number"></i> variable</span>
                                <span class="new-card-menu__subtext">1 <i class="icon-font"></i> variable with N modalities</span>
                            </li>
                            <li (click)="setTestType(TestState.PAIRED)" class="new-card-menu__item"
                                [ngClass]="{ 'new-card-menu__item--selected': selectedTest === TestState.PAIRED }"
                                [qa]="{'eda-wizard-navigate': {'category': 'categorical-test'}}">
                                <h5 class="new-card-menu__header">Categorical test</h5>
                                <span class="new-card-menu__subtext">2 <i class="icon-font"></i> variables</span>
                            </li>
                        </ul>
                    </div>
                    <div class="new-card-content">
                        <ng-container *ngIf="selectedTest === TestState.ONE_SAMPLE">
                            <h4 class="new-card-content__header">Location</h4>
                            <p>Compare the location parameters of a population to a hypothesized constant.</p>
                            <ul class="new-card__list">
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType({ type: 'ttest_ztest_1samp' })"
                                        [qa]="{'eda-wizard-navigate': {'category': '1samp-ttest'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Student 𝘵-test</h5>
                                            <p class="new-card-type__description">
                                                Test whether the mean of a population is a specified value.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType({ type: 'sign_test_1samp' })"
                                        [qa]="{'eda-wizard-navigate': {'category': 'sign-test'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Sign Test</h5>
                                            <p class="new-card-type__description">
                                                Test whether the median of a population is a specified value.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                                <h4  class="new-card-content__header">Distribution</h4>
                                <p>Compare the distribution of a population to a hypothesized one.</p>
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType({ type: 'shapiro' })"
                                        [qa]="{'eda-wizard-navigate': {'category': 'shapiro'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Shapiro-Wilk Test</h5>
                                            <p class="new-card-type__description">
                                                Test whether a population is normally distributed.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngIf="selectedTest === TestState.TWO_SAMPLE">
                            <h4  class="new-card-content__header">Location</h4>
                            <p>Compare the location parameters of two populations.</p>
                            <ul class="new-card__list">
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType({ type: 'ttest_2samp' })"
                                        [qa]="{'eda-wizard-navigate': {'category': '2samp-ttest'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Student 𝘵-test</h5>
                                            <p class="new-card-type__description">
                                                Test whether the mean of two populations is equal.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType({ type: 'mood_test_2samp' })"
                                        [qa]="{'eda-wizard-navigate': {'category': '2samp-mood-test'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Median Mood Test</h5>
                                            <p class="new-card-type__description">
                                                Test whether the median of two populations is equal.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                                <h4  class="new-card-content__header">Distribution</h4>
                                <p>Compare the distributions of two populations.</p>
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType({ type: 'ks_test_2samp' })"
                                        [qa]="{'eda-wizard-navigate': {'category': 'ks-test'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Kolmogorov-Smirnov</h5>
                                            <p class="new-card-type__description">
                                                Test whether the two populations are similarly distributed.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngIf="selectedTest === TestState.N_SAMPLE">
                            <h4  class="new-card-content__header">Location</h4>
                            <p>Compare the location parameters of multiple populations.</p>
                            <ul class="new-card__list">
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType({ type: 'oneway_anova' })"
                                        [qa]="{'eda-wizard-navigate': {'category': 'anova'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Oneway ANOVA</h5>
                                            <p class="new-card-type__description">
                                                Test whether the mean of all populations is equal.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType({ type: 'mood_nsamp' })"
                                        [qa]="{'eda-wizard-navigate': {'category': 'nsamp-mood-test'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Median Mood Test</h5>
                                            <p class="new-card-type__description">
                                                Test whether the median of all populations is equal.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType({ type: 'pairwise_ttest' })"
                                        [qa]="{'eda-wizard-navigate': {'category': 'pairwise-ttest'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Pairwise Student 𝘵-test</h5>
                                            <p class="new-card-type__description">
                                                Test whether every pair of population has the same mean.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType({ type: 'pairwise_mood' })"
                                        [qa]="{'eda-wizard-navigate': {'category': 'pairwise-mood-test'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Pairwise Median Mood Test</h5>
                                            <p class="new-card-type__description">
                                                Test whether every pair of population has the same median.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngIf="selectedTest === TestState.PAIRED">
                            <h4  class="new-card-content__header">Independence</h4>
                            <p>Test independence between categorical variables.</p>
                            <ul class="new-card__list">
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType({ type: 'chi2_independence_test' })"
                                        [qa]="{'eda-wizard-navigate': {'category': 'chi2-test'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Chi-square Independence Test</h5>
                                            <p class="new-card-type__description">
                                                Test whether two categorical variables are independent.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="WizardState.MULTIVARIATE">
                <div class="card-wizard__body--wide">
                    <a class="new-card-type" (click)="setCardType({ type: 'pca' })"
                        [qa]="{'eda-wizard-navigate': {'category': 'pca'}}">
                        <div class="new-card-type__body">
                            <h5 class="new-card-type__title">Principal Component Analysis</h5>
                            <p class="new-card-type__description">
                                Reduce a large set of variables into a smaller set of components.
                            </p>
                        </div>
                    </a>
                    <a class="new-card-type" (click)="setCardType({ type: 'correlation_matrix' })"
                        [qa]="{'eda-wizard-navigate': {'category': 'correlation'}}">
                        <div class="new-card-type__body">
                            <h5 class="new-card-type__title">Correlation matrix</h5>
                            <p class="new-card-type__description">
                                Examine the relationship of multiple variables at the same time.
                            </p>
                        </div>
                    </a>
                    <a class="new-card-type" (click)="setCardType({ type: 'scatter_plot_3d' })"
                        [qa]="{'eda-wizard-navigate': {'category': 'scatter-3d'}}">
                        <div class="new-card-type__body">
                            <h5 class="new-card-type__title">Scatter plot 3D</h5>
                            <p class="new-card-type__description">
                                Visualize the relationships between 3 numerical variables.
                            </p>
                        </div>
                    </a>
                    <a class="new-card-type" (click)="setCardType({ type: 'parallel_coordinates_plot' })"
                        [qa]="{'eda-wizard-navigate': {'category': 'parallel-coordinates'}}">
                        <div class="new-card-type__body">
                            <h5 class="new-card-type__title">Parallel coordinates plot</h5>
                            <p class="new-card-type__description">
                                Visualize a high number of variables at the same time.
                            </p>
                        </div>
                    </a>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="WizardState.TIME_SERIES">
                <div class="new-card new-card--test horizontal-flex card-wizard__body--wide time-series-menu">
                    <div class="new-card-menu">
                        <ul class="new-card__list">
                            <li (click)="setTimeSeriesAnalysis(TimeSeriesAnalysisState.STATIONARITY)" class="new-card-menu__item"
                                [ngClass]="{'new-card-menu__item--selected': selectedTimeSeriesAnalysis === TimeSeriesAnalysisState.STATIONARITY}"
                                [qa]="{'eda-wizard-navigate': {'category': 'stationarity'}}"
                            >
                                <h5 class="new-card-menu__header">Stationarity</h5>
                                <span class="new-card-menu__subtext">Assess the stationarity of a series</span>
                            </li>
                            <li (click)="setTimeSeriesAnalysis(TimeSeriesAnalysisState.TREND)" class="new-card-menu__item"
                                [ngClass]="{'new-card-menu__item--selected': selectedTimeSeriesAnalysis === TimeSeriesAnalysisState.TREND}"
                                [qa]="{'eda-wizard-navigate': {'category': 'trend'}}"
                            >
                                <h5 class="new-card-menu__header">Trend & Seasonality</h5>
                                <span class="new-card-menu__subtext">Analyze the components of a series</span>
                            </li>
                            <li (click)="setTimeSeriesAnalysis(TimeSeriesAnalysisState.AUTOCORRELATION)" class="new-card-menu__item"
                                [ngClass]="{'new-card-menu__item--selected': selectedTimeSeriesAnalysis === TimeSeriesAnalysisState.AUTOCORRELATION}"
                                [qa]="{'eda-wizard-navigate': {'category': 'autocorrelation'}}"
                            >
                                <h5 class="new-card-menu__header">Autocorrelation</h5>
                                <span class="new-card-menu__subtext">Check for serial correlation</span>
                            </li>
                        </ul>
                    </div>
                    <div class="new-card-content">
                        <ng-container *ngIf="selectedTimeSeriesAnalysis === TimeSeriesAnalysisState.STATIONARITY">
                            <h4 class="new-card-content__header">Stationarity</h4>
                            <p>The statistical properties of a stationary series do not change over time.</p>
                            <ul class="new-card__list">
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType({ type: 'unit_root_test_kpss' })"
                                        [qa]="{'eda-wizard-navigate': {'category': 'unit-root-kpss'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Kwiatkowski-Phillips-Schmidt-Shin test</h5>
                                            <p class="new-card-type__description">
                                                Test for stationarity.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                            <h4 class="new-card-content__header">Unit root</h4>
                            <p>The absence of a unit root may indicate that the series is stationary.</p>
                            <ul class="new-card__list">
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType({ type: 'unit_root_test_adf' })"
                                        [qa]="{'eda-wizard-navigate': {'category': 'unit-root-adf'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Augmented Dickey-Fuller test</h5>
                                            <p class="new-card-type__description">
                                                Test for a unit root in the presence of serial correlation.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType({ type: 'unit_root_test_za' })"
                                        [qa]="{'eda-wizard-navigate': {'category': 'unit-root-za'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Zivot-Andrews test</h5>
                                            <p class="new-card-type__description">
                                                Test for a unit root in the presence of serial correlation and one structural break.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngIf="selectedTimeSeriesAnalysis === TimeSeriesAnalysisState.TREND">
                            <h4 class="new-card-content__header">Decomposition</h4>
                            <p>Decompose the trend and seasonality of a series.</p>
                            <ul class="new-card__list">
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType({ type: 'stl_decomposition' })"
                                        [qa]="{'eda-wizard-navigate': {'category': 'timeseries-decomposition'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">STL decomposition plot</h5>
                                            <p class="new-card-type__description">
                                                Visualize the trend and seasonality of a series.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                            <h4 class="new-card-content__header">Trend</h4>
                            <p>Analyze the trend of a series.</p>
                            <ul class="new-card__list">
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType({ type: 'mann_kendall_test' })"
                                        [qa]="{'eda-wizard-navigate': {'category': 'mann-kendall'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Mann-Kendall trend test</h5>
                                            <p class="new-card-type__description">
                                                Analyze time series for consistently increasing or decreasing trends (monotonic trends).
                                            </p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngIf="selectedTimeSeriesAnalysis === TimeSeriesAnalysisState.AUTOCORRELATION">
                            <h4 class="new-card-content__header">Autocorrelation</h4>
                            <p>Assess whether a series is correlated to lagged versions of itself.</p>
                            <ul class="new-card__list">
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType({ type: 'acf_plot', isPartial: false })"
                                        [qa]="{'eda-wizard-navigate': {'category': 'acf-plot'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Autocorrelation function plot</h5>
                                            <p class="new-card-type__description">
                                                Visualize the autocorrelation function of a series.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType({ type: 'acf_plot', isPartial: true })"
                                        [qa]="{'eda-wizard-navigate': {'category': 'pacf-plot'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Partial autocorrelation function plot</h5>
                                            <p class="new-card-type__description">
                                                Visualize the partial autocorrelation function of a series.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType({ type: 'durbin_watson' })"
                                        [qa]="{'eda-wizard-navigate': {'category': 'durbin-watson'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Durbin-Watson statistic</h5>
                                            <p class="new-card-type__description">
                                                Characterize the autocorrelation of a series.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="WizardState.AUTOMAGIC">
                <automagic-sniffer></automagic-sniffer>
            </ng-container>
        </ng-container>
    </div>
    <ng-container *ngIf="state === WizardState.VARIABLES">
        <div class="modal-footer modal-footer-std-buttons">
            <div class="pull-right" [matTooltip]="validationTooltip">
                <button
                    type="button"
                    class="btn btn--text btn--secondary"
                    (click)="dismiss()"
                    tabindex="-1"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    form="config-form"
                    class="btn btn--text btn--primary"
                    [disabled]="!canSave"
                    (click)="save()"
                    tabindex="-1"
                    [wt1Click]="'worksheets_create-card'"
                    [qa]="'eda-create-card-button'"
                >
                    Create Card
                </button>
            </div>
        </div>
    </ng-container>
</div>

