import type {EdaErrorCodes} from './../../eda-error-codes';
import type {InteractiveQuery, _InteractiveQuery} from './interactive-query';

/**
 * Generated from com.dataiku.dip.eda.worksheets.queries.CheckTimeStepRegularity
 */
export interface CheckTimeStepRegularity extends _InteractiveQuery {
    column: string;

    // PolyJson type
    type: 'check_time_step_regularity';
}

export namespace CheckTimeStepRegularity {
    export const type = 'check_time_step_regularity';
}

/** @deprecated Use InteractiveQuery.isCheckTimeStepRegularity() */ export function isCheckTimeStepRegularity(toTest: InteractiveQuery): toTest is CheckTimeStepRegularity { return CheckTimeStepRegularity.type === toTest['type']; }

export namespace CheckTimeStepRegularity {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.queries.CheckTimeStepRegularity$CheckTimeStepRegularityResult
     */
    export interface CheckTimeStepRegularityResult extends InteractiveQuery._InteractiveQueryResult {
        error_code?: EdaErrorCodes | null;
        error_message?: string | null;
        warnings: string[];

        // PolyJson type
        type: 'check_time_step_regularity';
    }

    export namespace CheckTimeStepRegularityResult {
        export const type = 'check_time_step_regularity';
    }

    /** @deprecated Use InteractiveQuery.InteractiveQueryResult.isCheckTimeStepRegularityResult() */ export function isCheckTimeStepRegularityResult(toTest: InteractiveQuery.InteractiveQueryResult): toTest is CheckTimeStepRegularityResult { return CheckTimeStepRegularityResult.type === toTest['type']; }
}