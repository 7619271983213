import { Pipe, PipeTransform } from '@angular/core';
import { realAny } from 'dku-frontend-core';

@Pipe({
    name: 'entries'
})
export class EntriesPipe implements PipeTransform {
    transform(object?: object): {key: string, value: realAny}[] {
        if (!object) {
            return [];
        }
        if (Array.isArray(object)) {
            throw new Error("Cannot compute the entries of an array"); // We could but it's specific the following loop iterates over keys as string
        }
        let kv = [];
        for (let key in object) {
            if (object.hasOwnProperty(key)) {
                let value = (<any>object)[key];
                kv.push({key, value});
            }
        }
        return kv;
    }
}
