import type {CheckTimeStepRegularity} from './check-time-step-regularity';
import type {DebugCard} from './debug-card';
import type {ListMostFrequentValues} from './list-most-frequent-values';
import type {SuggestCards} from './suggest-cards';

/**
 * Generated from com.dataiku.dip.eda.worksheets.queries.InteractiveQuery
 */
export interface _InteractiveQuery {}

export type InteractiveQuery = CheckTimeStepRegularity | DebugCard | ListMostFrequentValues | SuggestCards;

export namespace InteractiveQuery {
    export const type = ['check_time_step_regularity', 'debug_card', 'list_most_frequent_values', 'suggest_cards'] as const;
    export function isCheckTimeStepRegularity(toTest: InteractiveQuery): toTest is CheckTimeStepRegularity { return toTest['type'] === 'check_time_step_regularity'; }
    export function isDebugCard(toTest: InteractiveQuery): toTest is DebugCard { return toTest['type'] === 'debug_card'; }
    export function isListMostFrequentValues(toTest: InteractiveQuery): toTest is ListMostFrequentValues { return toTest['type'] === 'list_most_frequent_values'; }
    export function isSuggestCards(toTest: InteractiveQuery): toTest is SuggestCards { return toTest['type'] === 'suggest_cards'; }
}

export namespace InteractiveQuery {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.queries.InteractiveQuery$InteractiveQueryResult
     */
    export interface _InteractiveQueryResult {}

    export type InteractiveQueryResult = CheckTimeStepRegularity.CheckTimeStepRegularityResult | DebugCard.DebugCardResult | ListMostFrequentValues.ListMostFrequentValuesResult | SuggestCards.SuggestCardsResult;

    export namespace InteractiveQueryResult {
        export const type = ['check_time_step_regularity', 'debug_card', 'list_most_frequent_values', 'suggest_cards'] as const;
        export function isCheckTimeStepRegularityResult(toTest: InteractiveQuery.InteractiveQueryResult): toTest is CheckTimeStepRegularity.CheckTimeStepRegularityResult { return toTest['type'] === 'check_time_step_regularity'; }
        export function isDebugCardResult(toTest: InteractiveQuery.InteractiveQueryResult): toTest is DebugCard.DebugCardResult { return toTest['type'] === 'debug_card'; }
        export function isListMostFrequentValuesResult(toTest: InteractiveQuery.InteractiveQueryResult): toTest is ListMostFrequentValues.ListMostFrequentValuesResult { return toTest['type'] === 'list_most_frequent_values'; }
        export function isSuggestCardsResult(toTest: InteractiveQuery.InteractiveQueryResult): toTest is SuggestCards.SuggestCardsResult { return toTest['type'] === 'suggest_cards'; }
    }
}