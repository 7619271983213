import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {UnitRootTestADF} from './unit-root-test-adf';
import type {UnitRootTestKPSS} from './unit-root-test-kpss';
import type {UnitRootTestZA} from './unit-root-test-za';
import type {_AvailableResult} from './../available-result';
import type {_TimeSeriesComputation} from './time-series-computation';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTest
 */
export interface _UnitRootTest extends _TimeSeriesComputation {}

export type UnitRootTest = UnitRootTestADF | UnitRootTestKPSS | UnitRootTestZA;

export namespace UnitRootTest {
    export const type = ['unit_root_adf', 'unit_root_kpss', 'unit_root_za'] as const;
}

/** @deprecated Use Computation.isUnitRootTest() */ export function isUnitRootTest(toTest: Computation): toTest is UnitRootTest { return (UnitRootTest.type as readonly unknown[]).includes(toTest['type']); }

export namespace UnitRootTest {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTest$UnitRootTestResult
     */
    export interface _UnitRootTestResult extends _AvailableResult {
        criticalValues: {[key: string]: number};
        nObservations: number;
        pValue: number;
        statistic: number;
        usedLag: number;
    }

    export type UnitRootTestResult = UnitRootTestADF.UnitRootTestADFResult | UnitRootTestKPSS.UnitRootTestKPSSResult | UnitRootTestZA.UnitRootTestZAResult;

    export namespace UnitRootTestResult {
        export const type = ['unit_root_adf', 'unit_root_kpss', 'unit_root_za'] as const;
    }

    /** @deprecated Use ComputationResult.isUnitRootTestResult() */ export function isUnitRootTestResult(toTest: ComputationResult): toTest is UnitRootTestResult { return (UnitRootTestResult.type as readonly unknown[]).includes(toTest['type']); }
}