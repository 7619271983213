/**
 * Linked list node.
 * @template T
 * @class LinkedListNode
 */
export class LinkedListNode<T> {
    /**
     * Creates an instance of LinkedListNode.
     * @param {T} element
     * @memberof LinkedListNode
     */
    next?: LinkedListNode<T>;
    prev?: LinkedListNode<T>;

    constructor(public element: T) {
    }
}

/**
 * Linked list data structure to be used to as a queue or a stack.
 * @template T
 * @class LinkedList
 */
export class LinkedList<T> {
    /**
     * Creates an instance of LinkedList.
     * @param {T [] | undefined} elements
     * @memberof LinkedList
     */
    head?: LinkedListNode<T>;
    tail?: LinkedListNode<T>;
    length: number = 0;

    constructor(elements?: Array<T>) {
        if (elements !== undefined) {
            elements.forEach(element => this.push(element));
        }
    }

    /**
     * Removes the last element from the linked list and returns it, with O(1) complexity. 
     * If the linked list is empty, undefined is returned and the linked list is not modified.
     * @returns {T} the last element from the linked list
     * @memberof LinkedList<T>
     */
    pop() {
        if (this.tail) {
            const node = this.tail;
            if (this.length === 1) {
                this.head = undefined;
                this.tail = undefined;
            } else if (this.tail.prev) {
                this.tail = this.tail.prev;
                this.tail.next = undefined;
            }
            this.length--;
            return node.element;
        }
        return undefined;
    }

    /**
     * Appends a new element to the end of the linked list, and returns the new length of the linked list, with O(1) complexity.
     * @param {T} element to append at the end of the linked list.
     * @returns {number} the new length of the linked list
     * @memberof LinkedList
     */
    push(element: T) {
        const newTail = new LinkedListNode(element);
        if (this.tail === undefined) {
            this.tail = newTail;
            if (this.head === undefined) {
                this.head = newTail;
            }
        } else {
            this.connect(this.tail, newTail);
            this.tail = newTail;
        }
        this.length++;
        return this.length;
    }

    /**
     * Removes the first element from the linked list and returns it, with O(1) complexity. 
     * If the linked list is empty, undefined is returned and the linked list is not modified.. 
     * @returns {T} the last element from the linked list
     * @memberof LinkedList
     */
    shift() {
        if (this.head) {
            const node = this.head;
            if (this.length === 1) {
                this.head = undefined;
                this.tail = undefined;
            } else if (this.head.next) {
                this.head = this.head.next;
                this.head.prev = undefined;
            }
            this.length--;
            return node.element;
        }
        return undefined;
    }

    /**
     * Inserts new elements at the start of the linked list, and returns the new length of the linked list, with O(1) complexity.
     * @param {T} element to append at the start of the linked list.
     * @returns {number} the new length of the linked list
     * @memberof LinkedList
     */
    unshift(element: T) {
        const newHead = new LinkedListNode(element);
        if (this.head === undefined) {
            this.head = newHead;
            if (this.tail === undefined) {
                this.tail = newHead;
            }
        } else {
            this.connect(newHead, this.head);
            this.head = newHead;
        }
        this.length++;
        return this.length;
    }

    /**
     * Connect two linked list nodes
     * @param {LinkedListNode} prevEl
     * @param {LinkedListNode} nextEl
     */
    connect(prevEl: LinkedListNode<T>, nextEl: LinkedListNode<T>) {
        prevEl.next = nextEl;
        nextEl.prev = prevEl;
    }
}
