<div class="h100 oa row-fluid">
    <div class="h100">
        <form class="fh oa dkuform-horizontal" [formGroup]="settingsForm">
            <sidebar-nav>
                <sidebar-nav-item label="Data" id="data">
                    <labeling-settings-data (partialTaskChange)="partialTaskChange.emit($event)"></labeling-settings-data>
                </sidebar-nav-item>
                <sidebar-nav-item [label]="labelingTask.type | categoryLabel: true" id="classes">
                    <div class="h100 vertical-flex">
                        <labeling-error></labeling-error>
                        <h1 class="labeling-settings-form__header">{{ labelingTask.type | categoryLabel: true }}</h1>
                        <div class="section flex">
                            <div class="alert alert-info">
                                You can add optional instructions to help labelers select the correct {{ labelingTask.type | categoryLabel: true }}, which will be displayed when hovering over <i class="category-info icon-info-sign"></i> on the {{ labelingTask.type | categoryLabel }} button.
                            </div>
                            <key-values-list formControlName="classes" [required]="false" [addLabel]="'Add ' + (labelingTask.type | categoryLabel)" [keyPlaceholder]="labelingTask.type | categoryLabel | capitalize" [valuePlaceholder]="'Instructions'" [disableRawEdit]="false" [fullHeightList]="true" [useVirtualScroll]="true" [virtualScrollItemSize]="33"></key-values-list>
                        </div>
                    </div>
                </sidebar-nav-item>
                <sidebar-nav-item label="Task Setup" id="task-setup">
                    <labeling-error></labeling-error>
                    <h1>Task Setup</h1>
                    <div class="section">
                        <h2>Output</h2>
                        <div class="dkuform-horizontal">
                            <div class="control-group">
                                <label for="" class="control-label">Label column name</label>
                                <div class="controls">
                                    <input type="text" formControlName="labelColumnName" />
                                    <div class="help-inline">Name of the column that contains annotations in the output dataset(s)</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="section">
                        <h2>Labeling guidelines</h2>
                        <div class="dkuform-horizontal">
                            <div class="control-group">
                                <div class="controls mleft0">
                                    <textarea formControlName="instructions" class="labeling-settings-form__instructions" placeholder="Enter guidelines to help labelers annotate"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="section" *ngIf="isTextTask(labelingTask.type)" formGroupName="tokenizationParam">
                        <h2>Tokenization</h2>
                        <div class="control-group">
                            <label class="control-label">
                                Split
                            </label>
                            <div class="controls">
                                <dku-bs-select-form-control
                                    formControlName="splitMechanism"
                                    ngOptions="option.value as option.text for option in list"
                                    [list]="tokenizationSplitOptions"
                                    [layout]="'list'">
                                </dku-bs-select-form-control>
                                <span class="help-inline">Choose how the text should be split into tokens.</span>
                            </div>
                        </div>
                    </div>

                    <div class="section">
                        <h2>Annotation process</h2>
                        <div class="dkuform-horizontal">
                            <div class="control-group">
                                <label for="" class="control-label"># of labelers per {{ itemName(labelingTask.type) }}</label>
                                <div class="controls">
                                    <input type="number" formControlName="minNbAnnotatorsPerRecord" class="labeling-settings-form__one-digit-input"/>
                                    <div class="help-inline">Each {{ itemName(labelingTask.type) }} should be annotated by this many labelers</div>
                                </div>
                            </div>
                        </div>
                        <div class="dkuform-horizontal">
                            <div class="control-group" *ngIf="labelingTask.type === LabelingTaskType.OBJECT_DETECTION">
                                <label for="" class="control-label">IoU threshold</label>
                                <div class="controls">
                                    <input type="number" step="0.1" min="0" max="1" formControlName="objectDetectionIOUConflictThreshold" class="labeling-settings-form__one-digit-input"/>
                                    <div class="help-inline">If two annotators label an object and their bounding box have a lower IoU (Intersection over Union) than this threshold, they will be considered as conflicting</div>
                                </div>
                            </div>
                        </div>
                        <div class="control-group">
                            <label for="autoValidateAnswers" class="control-label">Auto validation</label>
                            <div class="controls">
                                <input id="autoValidateAnswers" type="checkbox" formControlName="autoValidateAnswers"/>
                                <div class="help-inline">Automatically validate answers done by reviewers</div>
                            </div>
                        </div>
                    </div>
                </sidebar-nav-item>
                <sidebar-nav-item label="Permissions" id="permissions">
                    <labeling-error></labeling-error>
                    <labeling-permissions [labelingTask]="labelingTask" (partialTaskChange)="partialTaskChange.emit($event)"></labeling-permissions>
                </sidebar-nav-item>
            </sidebar-nav>
        </form>
    </div>
</div>
