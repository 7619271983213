<table *ngIf="editedItems && editedItems.length; else empty">
    <tbody>
        <tr *ngFor="let item of editedItems; let i = index" class="boundary__row"><td class="boundary__value">{{item}}</td><td class="boundary__separator"></td><td (click)="remove(i)" class="boundary__remove"><i class="icon-trash"></i></td></tr>
    </tbody>
</table>
<ng-template #empty>
    <p>(no value)</p>
</ng-template>
<div [formGroup]="addForm">
    <input type="number" formControlName="value" placeholder="Bin boundary to add" (keydown.enter)="add($event)">
    <button type="button" class="btn btn--primary" (click)="add($event)" [disabled]="addForm.invalid">Add</button>
</div>
