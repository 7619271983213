import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Abstract2SampTestCard, Variable, Fit2DDistributionCard } from 'src/generated-sources';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { CardWizardService } from '../../../card-wizard/card-wizard.service';
import { CardWizardVariable } from '@features/eda/card-models';
import { Observable } from 'rxjs';
import { toggleFormControl } from '@utils/toggle-form-control';

@UntilDestroy()
@Component({
    selector: 'fit-2d-distribution-card-config',
    templateUrl: './fit-2d-distribution-card-config.component.html',
    styleUrls: [
        '../../../../shared-styles/forms.less',
        '../../../../shared-styles/card-wizard.less',
        './fit-2d-distribution-card-config.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class Fit2DDistributionCardConfigComponent implements OnInit, OnChanges, OnDestroy {
    @Input() params: Fit2DDistributionCard;
    @Input() variables: Variable[];
    @Output() paramsChange = new EventEmitter<Fit2DDistributionCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    allVariables$: Observable<CardWizardVariable[]>;

    configForm: FormGroup;

    distributionGroup: FormGroup;

    constructor(
        private fb: FormBuilder,
        private cardWizardService: CardWizardService
    ) {
        const type = this.fb.control('kde_2d', [Validators.required]);
        this.distributionGroup = this.fb.group({
            type,
            x_relative_bandwidth: toggleFormControl(this.fb.control({ value: 15, disabled: true }, [Validators.required, Validators.min(1), Validators.max(100)]), type, 'kde_2d'),
            y_relative_bandwidth: toggleFormControl(this.fb.control({ value: 15, disabled: true }, [Validators.required, Validators.min(1), Validators.max(100)]), type, 'kde_2d')
        });

        this.configForm = this.fb.group({
            xColumn: this.fb.control(null, [Validators.required]),
            yColumn: this.fb.control(null, [Validators.required]),
            distribution: this.distributionGroup
        });

        this.configForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((formValue) => this.paramsChange.emit({
                ...this.params,
                xColumn: formValue.xColumn,
                yColumn: formValue.yColumn,
                distribution: formValue.distribution
            }));



        this.configForm.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => this.validityChange.emit(this.configForm.valid));
    }

    ngOnInit() {
        this.allVariables$ = this.cardWizardService.availableVariables(this.params.type);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.configForm.patchValue({
                xColumn: this.params.xColumn,
                yColumn: this.params.yColumn,
                distribution: this.params.distribution
            });
        }
    }

    ngOnDestroy() {

    }
}
