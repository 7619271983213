import { Injectable } from '@angular/core';
import { Feature, FeatureGroup } from '@features/feature-store/feature-store-models';
import { Result } from '@shared/models/query-result-models';
import { DkuHttpService } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { FeatureGroupDetails } from 'src/generated-sources';

@Injectable({ providedIn: 'root' })
export class PrivateFeatureStoresAPI {
    constructor(
        private dkuHttp: DkuHttpService,
    ) { }

    searchFeatureGroups(queryString: string, facets: {[key: string]: string[]}): Observable<Result<FeatureGroup>> {
        return this.dkuHttp.request('POST', '/feature-store/feature-groups/search',
            {
                query: queryString,
                facets: JSON.stringify(facets)
            });
    }

    searchFeatures(queryString: string, facets: {[key: string]: string[]}): Observable<Result<Feature>> {
        return this.dkuHttp.request('POST', '/feature-store/features/search',
            {
                query: queryString,
                facets: JSON.stringify(facets)
            });
    }

    getDetails(projectKey: string, datasetName: string): Observable<FeatureGroupDetails> {
        return this.dkuHttp.request('GET', '/feature-store/feature-groups/details',
            {
                projectKey: projectKey,
                datasetName: datasetName,
            });
    }

    setFeatureGroup(projectKey: string, datasetName: string, isFeatureGroup: boolean): Observable<any> {
        return this.dkuHttp.request("POST", "/feature-store/feature-groups/set",
            {
                projectKey: projectKey,
                name: datasetName,
                isFeatureGroup: isFeatureGroup
            });
    }
}
