import { Component, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';
import { EdaService } from './eda.service';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';

@Component({
    selector: 'stats',
    templateUrl: './eda.component.html',
    styleUrls: ['./eda.component.less'],
    providers: [EdaService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EdaComponent implements OnChanges {
    worksheetsIfAvailable$ = this.edaService.listWorksheets();
    error$ = this.edaService.getError();

    @Input() datasetName: string;
    @Input() projectKey: string;
    @Input() worksheetId: string | undefined;

    constructor(
        private edaService: EdaService,
        public apiService: DataikuAPIService
    ) { }

    ngOnChanges() {
        this.edaService.setWorksheetLoc(this.projectKey, this.datasetName, this.worksheetId);
    }

    newWorksheet() {
        this.edaService.newWorksheet();
    }

    resetError() {
        this.edaService.pushError();
    }
}
