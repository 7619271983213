import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Workspace } from '@model-main/workspaces/workspace';
import { WorkspaceUtilsService, WorkspaceListType, WorkspaceSecurityService } from '../../../../shared';
import { WorkspaceObjectStarEvent } from '../workspace-object-list-element';

@Component({
    selector: 'workspace-object-list',
    templateUrl: './workspace-object-list.component.html',
    styleUrls: ['./workspace-object-list.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceObjectListComponent implements OnChanges {
    objectsToDisplay: Workspace.WorkspaceObject[];

    @Input() workspaceKey: string;
    @Input() objects: Workspace.WorkspaceObject[];
    @Input() displayType = WorkspaceListType.TILE;
    @Input() editable = false;
    @Output() duplicateObject = new EventEmitter<Workspace.WorkspaceObject>();
    @Output() removeObject = new EventEmitter<Workspace.WorkspaceObject>();
    @Output() toggleStar = new EventEmitter<WorkspaceObjectStarEvent>();
    readonly WorkspaceListType = WorkspaceListType;

    readonly trackWorkspaceObject: (index: number, object: Workspace.WorkspaceObject) => string | undefined = (_, object) => this.workspaceUtilsService.getWorkspaceObjectId(object);

    constructor(
        private workspaceUtilsService: WorkspaceUtilsService,
        private workspaceSecurityService: WorkspaceSecurityService,
    ) { }

    ngOnChanges({ objects }: SimpleChanges): void {
        if (objects) {
            this.objectsToDisplay = this.objects.filter(obj => this.shouldKeepObject(obj));
        }
    }

    onToggleStar(event: WorkspaceObjectStarEvent): void {
        this.toggleStar.emit(event);
    }

    onDuplicateObject(object: Workspace.WorkspaceObject): void {
        this.duplicateObject.emit(object);
    }

    onRemoveObject(object: Workspace.WorkspaceObject): void {
        this.removeObject.emit(object);
    }

    private shouldKeepObject(workspaceObject: Workspace.WorkspaceObject): boolean {
        return workspaceObject.story != null && this.workspaceSecurityService.isDatastoryEnabled() || workspaceObject.story == null;
    }

}
