<div class="card-layout__section card-layout__section--horizontal" [qa]="'eda-ts-series-summary'">

    <div class="card-layout__section">
        <div class="card-layout__title">
            <span *ngIf="hasResampling">Resampled series summary</span>
            <span *ngIf="!hasResampling">Series summary</span>
        </div>
        <div class="card-layout__content">
            <table class="stats-table">
                <tbody class="stats-table__body">
                    <tr class="stats-table__row">
                        <th class="stats-table__head">Count</th>
                        <td class="stats-table__cell">{{ results.boxPlot.countComplete }}</td>
                    </tr>
                    <tr class="stats-table__row" *ngIf="hasResampling">
                        <th class="stats-table__head">Count before resampling</th>
                        <td class="stats-table__cell">{{ results.beforeResamplingCount }}</td>
                    </tr>
                    <tr class="stats-table__row">
                        <th class="stats-table__head">Mean</th>
                        <td class="stats-table__cell">{{ results.boxPlot.mean | smarterNumber }}</td>
                    </tr>
                    <tr class="stats-table__row">
                        <th class="stats-table__head">Standard deviation</th>
                        <td class="stats-table__cell">{{ results.boxPlot.stdDev | smarterNumber }}</td>
                    </tr>
                    <tr class="stats-table__row">
                        <th class="stats-table__head">Box plot</th>
                        <td class="stats-table__cell boxplot-cell">
                            <embedded-box-plot
                                [variableName]="seriesColumnName"
                                [boxPlot]="results.boxPlot"
                            ></embedded-box-plot>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>


    <div class="card-layout__section">
        <div class="card-layout__title">Time summary</div>
        <div class="card-layout__content">
            <table class="stats-table">
                <tbody class="stats-table__body">
                    <tr class="stats-table__row">
                        <th class="stats-table__head">Time variable</th>
                        <td class="stats-table__cell">{{ timeColumnName }}</td>
                    </tr>
                    <tr class="stats-table__row" *ngIf="hasResampling">
                        <th class="stats-table__head">Resampled time step</th>
                        <td class="stats-table__cell">{{ resamplingTimeStep }}</td>
                    </tr>
                    <tr class="stats-table__row" *ngIf="!hasResampling">
                        <th class="stats-table__head">
                            Detected time {{ nTimeSteps === 1 ? 'step' : 'steps' }}
                        </th>
                        <td class="stats-table__cell" [matTooltip]="totalTimeStepsTooltip">
                            {{ timeStepsLabel }}
                            <span *ngIf="showTimeStepsEllipsis">…</span>
                        </td>
                    </tr>
                    <tr class="stats-table__row">
                        <th class="stats-table__head">Min timestamp</th>
                        <td class="stats-table__cell">{{ results.timeInfo.min }}</td>
                    </tr>
                    <tr class="stats-table__row">
                        <th class="stats-table__head">Max timestamp</th>
                        <td class="stats-table__cell">{{ results.timeInfo.max }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>
