export function getLoadingUrl(url: string): string {
    return `<!DOCTYPE html>
        <html lang="en-us">
            <head>
                <title>Dataiku</title>
                <link rel="shortcut icon" href="${url}/favicon.ico"/>
                <style>
                    @font-face {
                        font-family: SourceSansPro;
                        src: url(${url}/static/dataiku/fonts/SourceSansPro-Regular.woff);
                        font-weight: 400;
                    }
            
                    body {
                        font-family: SourceSansPro, Verdana, sans-serif;
                        overflow: hidden;
                        height: 100%;
                    }
            
                    .page {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: 0;
                    }
            
                    .container {
                        display: flex;
                        flex-wrap: wrap;
                        height: 100%;
                        margin: auto;
                    }
            
                    .right-container {
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: wrap;
                        flex-grow: 1;
                        z-index: 1;
                        padding: 16px;
                        background: #ffffff;
                    }
            
                    .right-column {
                        width: 600px;
                        display: flex;
                        flex-direction: column;
                    }
            
                    .right-column h1 {
                        font-size: 26px;
                        font-weight: 400;
                        color: #444;
                        margin-bottom: 1.2em;
                    }
                </style>
            </head>
            <body style="height: 100%; width: 100%">
                <div style="display: flex;height: 100%;width: 100%; align-items: center;justify-content: center;" class="page">
                    <section class="container">
                        <section class="right-container">
                            <section class="right-column">
                                <h1>Please wait, your story is getting ready</h1>
                                <div>
                                    <svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="160px" height="20px" viewBox="0 0 128 16" xml:space="preserve">
                                        <path fill="#b1b1b1" d="M6.4,4.8A3.2,3.2,0,1,1,3.2,8,3.2,3.2,0,0,1,6.4,4.8Zm12.8,0A3.2,3.2,0,1,1,16,8,3.2,3.2,0,0,1,19.2,4.8ZM32,4.8A3.2,3.2,0,1,1,28.8,8,3.2,3.2,0,0,1,32,4.8Zm12.8,0A3.2,3.2,0,1,1,41.6,8,3.2,3.2,0,0,1,44.8,4.8Zm12.8,0A3.2,3.2,0,1,1,54.4,8,3.2,3.2,0,0,1,57.6,4.8Zm12.8,0A3.2,3.2,0,1,1,67.2,8,3.2,3.2,0,0,1,70.4,4.8Zm12.8,0A3.2,3.2,0,1,1,80,8,3.2,3.2,0,0,1,83.2,4.8ZM96,4.8A3.2,3.2,0,1,1,92.8,8,3.2,3.2,0,0,1,96,4.8Zm12.8,0A3.2,3.2,0,1,1,105.6,8,3.2,3.2,0,0,1,108.8,4.8Zm12.8,0A3.2,3.2,0,1,1,118.4,8,3.2,3.2,0,0,1,121.6,4.8Z"/>
                                        <g>
                                            <path fill="#444444" d="M-42.7,3.84A4.16,4.16,0,0,1-38.54,8a4.16,4.16,0,0,1-4.16,4.16A4.16,4.16,0,0,1-46.86,8,4.16,4.16,0,0,1-42.7,3.84Zm12.8-.64A4.8,4.8,0,0,1-25.1,8a4.8,4.8,0,0,1-4.8,4.8A4.8,4.8,0,0,1-34.7,8,4.8,4.8,0,0,1-29.9,3.2Zm12.8-.64A5.44,5.44,0,0,1-11.66,8a5.44,5.44,0,0,1-5.44,5.44A5.44,5.44,0,0,1-22.54,8,5.44,5.44,0,0,1-17.1,2.56Z"/>
                                            <animateTransform attributeName="transform" type="translate" values="23 0;36 0;49 0;62 0;74.5 0;87.5 0;100 0;113 0;125.5 0;138.5 0;151.5 0;164.5 0;178 0" calcMode="discrete" dur="1300ms" repeatCount="indefinite"/>
                                        </g>
                                    </svg>
                                </div>
                            </section>
                        </section>
                    </section>
                </div>
            </body>
        </html>`;
};
