import { ImagePositionInformation } from "@shared/models/painter";
import { DeepHubColumnFormat } from "src/generated-sources";

export class BoxHelper {
    static createBox<T, P>(Box: (new(...args : any[]) => T), item: DeepHubColumnFormat.ObjectDetectionTargetItem, imagePosition: ImagePositionInformation, params: P, suffix: string | null = null) {
        try {
            const bbox = item.bbox;
            return new Box(Object.assign(this.getBoxParams(bbox, imagePosition, suffix), params));
        } catch(error) {
            console.warn("couldn't parse bbox for: ",item);
            return null;
        };
    }

    static getBoxParams(bbox: number[], imagePosition: ImagePositionInformation, suffix: string | null = null) {
         return {
            left: imagePosition.left + bbox[0] * imagePosition.scale + suffix!,
            top: imagePosition.top + bbox[1] * imagePosition.scale + suffix!,
            width: bbox[2] * imagePosition.scale + suffix!,
            height: bbox[3] * imagePosition.scale + suffix!
        };
    }
}
