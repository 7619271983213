import type {_Dimension} from './dimension';

/**
 * Generated from com.dataiku.dip.partitioning.TimeDimension
 */
export interface TimeDimension extends _Dimension {
    mappedPeriod: TimeDimension.Period;
}

export namespace TimeDimension {
    /**
     * Generated from com.dataiku.dip.partitioning.TimeDimension$Period
     */
    export enum Period {
        YEAR = 'YEAR',
        MONTH = 'MONTH',
        DAY = 'DAY',
        HOUR = 'HOUR'
    }
}