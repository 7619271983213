import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { FeatureGroupDetails } from "src/generated-sources";
import { FeatureStoreService } from "../feature-store.service";
import { formatSourceFeatureGroupName } from "../highlight-utils";
import { Highlight } from "@shared/models/query-result-models";

@Component({
  selector: "feature-group-header",
  templateUrl: "./feature-group-header.component.html",
  styleUrls: ["./feature-group-header.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureGroupHeaderComponent implements OnChanges {
  @Input() projectKey?: string;
  @Input() details!: FeatureGroupDetails;
  @Input() highlight!: Highlight;
  @Input() datasetsInCurrentProject!: string[];
  @Output() useFeatureGroupInProject: EventEmitter<void> = new EventEmitter();
  @Output() removeFeatureGroupFromFeatureStore: EventEmitter<void> = new EventEmitter();

  useInProjectTitle: string = "Use in project";

  constructor(private featureStoreService: FeatureStoreService) {}

  ngOnChanges() {
    if (this.featureGroupUsedInCurrentProject()) {
      this.useInProjectTitle = "This Feature Group is already used in current project.";
    } else {
      const useInProjectLabel = "Use in project" + (this.projectKey ? " " + this.projectKey : "");
      this.useInProjectTitle = this.disableUseInProject()
        ? "This Feature Group is not quickly shareable and access requests are not enabled for the source project"
        : useInProjectLabel;
    }
  }

  formatSourceFeatureGroupName() {
    return formatSourceFeatureGroupName(this.highlight, this.details.datasetFullInfo.dataset.name || "");
  }

  datasetLink() {
    return this.featureStoreService.datasetLink(this.details);
  }

  removeFromFeatureStore() {
    this.removeFeatureGroupFromFeatureStore.emit();
  }

  removeFromFeatureStoreLabel() {
    return this.featureStoreService.removeFromFeatureStoreLabel();
  }

  mayManageFeatureStore() {
    return this.featureStoreService.mayManageFeatureStore();
  }

  displayRemoveButton() {
    return !this.projectKey;
  }

  useInProject() {
    if (!this.disableUseInProject()) {
      this.useFeatureGroupInProject.emit();
    }
  }

  featureGroupUsedInCurrentProject() {
    return this.featureStoreService.featureGroupUsedInCurrentProject(
      this.datasetsInCurrentProject,
      this.details,
      this.projectKey
    );
  }

  disableUseInProject() {
    return this.featureStoreService.disableUseInProject(this.datasetsInCurrentProject, this.details, this.projectKey);
  }
}
