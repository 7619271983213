/**
 * Generated from com.dataiku.dip.pivot.frontend.model.ChartType
 */
export enum ChartType {
    multi_columns_lines = 'multi_columns_lines',
    grouped_columns = 'grouped_columns',
    stacked_columns = 'stacked_columns',
    stacked_bars = 'stacked_bars',
    lines = 'lines',
    stacked_area = 'stacked_area',
    pivot_table = 'pivot_table',
    pie = 'pie',
    scatter = 'scatter',
    grouped_xy = 'grouped_xy',
    binned_xy = 'binned_xy',
    heatmap = 'heatmap',
    density_2d = 'density_2d',
    timeseries = 'timeseries',
    grid_map = 'grid_map',
    scatter_map = 'scatter_map',
    density_heat_map = 'density_heat_map',
    geom_map = 'geom_map',
    grouped_scatter_map = 'grouped_scatter_map',
    admin_map = 'admin_map',
    heatmap_map = 'heatmap_map',
    treemap = 'treemap',
    lift = 'lift',
    boxplots = 'boxplots',
    distribution = 'distribution',
    kpi = 'kpi',
    numerical_heatmap = 'numerical_heatmap',
    radar = 'radar',
    webapp = 'webapp'
}