import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { QuantilesTableCard } from 'src/generated-sources';
import { CardAction } from '../../events';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'quantiles-table-card-body',
    templateUrl: './quantiles-table-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/stats-table.less',
        './quantiles-table-card-body.component.less'
    ]
})
export class QuantilesTableCardBodyComponent {
    @Input() params: QuantilesTableCard;
    @Input() results: QuantilesTableCard.QuantilesTableCardResult;
    @Output() action = new EventEmitter<CardAction>();

    trackByIndex(index: number) {
        return index;
    }
}
