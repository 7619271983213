import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { WT1Event } from './wt1-event';
import { WT1Service } from './wt1.service';

@Directive({
    selector: '[wt1Click]'
})
export class Wt1ClickDirective {
    @Input() wt1Click: string | WT1Event | null | undefined;

    constructor(private wt1Service: WT1Service, private el: ElementRef) { }

    @HostListener('click') onClick(): void {
        if (this.wt1Click && !this.el.nativeElement.classList?.contains("disabled")) {
            if(typeof this.wt1Click === 'string'){
                this.wt1Service.event('clicked-item', { 'item-id': this.wt1Click });
            } else {
                this.wt1Service.event(this.wt1Click);
            }

        }
    }
}
