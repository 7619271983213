import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { ExperimentTrackingService } from "../experiment-tracking.service";

export interface ETBreadcrumbItem {
    displayName: string,
    href: string
}

@Component({
    selector: 'experiment-tracking-header',
    templateUrl: './experiment-tracking-header.component.html',
    styleUrls: ['./experiment-tracking-header.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperimentTrackingHeaderComponent implements OnInit {

    @Input() intermediateLinks: Observable<ETBreadcrumbItem>[];
    experimentTrackingHref: string;
    @Input() rightHeader: string = "";

    constructor(
        private experimentTrackingService: ExperimentTrackingService
    ) {
    }

    ngOnInit(): void {
        this.experimentTrackingHref = this.experimentTrackingService.getExperimentTrackingHref(this.experimentTrackingService.viewAllExperiments);
    }
}
