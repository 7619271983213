import type {InteractiveQuery, _InteractiveQuery} from './interactive-query';

/**
 * Generated from com.dataiku.dip.eda.worksheets.queries.ListMostFrequentValues
 */
export interface ListMostFrequentValues extends _InteractiveQuery {
    column: string;
    maxValues: number;

    // PolyJson type
    type: 'list_most_frequent_values';
}

export namespace ListMostFrequentValues {
    export const type = 'list_most_frequent_values';
}

/** @deprecated Use InteractiveQuery.isListMostFrequentValues() */ export function isListMostFrequentValues(toTest: InteractiveQuery): toTest is ListMostFrequentValues { return ListMostFrequentValues.type === toTest['type']; }

export namespace ListMostFrequentValues {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.queries.ListMostFrequentValues$ListMostFrequentValuesResult
     */
    export interface ListMostFrequentValuesResult extends InteractiveQuery._InteractiveQueryResult {
        values: string[];

        // PolyJson type
        type: 'list_most_frequent_values';
    }

    export namespace ListMostFrequentValuesResult {
        export const type = 'list_most_frequent_values';
    }

    /** @deprecated Use InteractiveQuery.InteractiveQueryResult.isListMostFrequentValuesResult() */ export function isListMostFrequentValuesResult(toTest: InteractiveQuery.InteractiveQueryResult): toTest is ListMostFrequentValuesResult { return ListMostFrequentValuesResult.type === toTest['type']; }
}