import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FeatureGroupDetails } from "src/generated-sources";
import { FeatureStoreService } from "../feature-store.service";

@Component({
  selector: "feature-group-general-info",
  templateUrl: "./feature-group-general-info.html",
  styleUrls: ["./feature-group-general-info.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureGroupGeneralInfoComponent {
  @Input() details!: FeatureGroupDetails;
  @Input() users!: Record<string, string>;

  constructor(
    private featureStoreService: FeatureStoreService
  ) {}

  lastBuildInfo() {
    return this.featureStoreService.lastBuildInfo(this.details);
  }
}
