<ng-container *ngIf="displayedResults && displayedParams">
    <div
        class="h100"
        [qa]="{'eda-card-body': {'id': params.id,'type': params.type}}"
        *ngIf="!displayOverlay && displayedResults.type === 'unavailable'; else availableBody"
    >
        <unavailable-card-body [results]="displayedResults" [params]="displayedParams"></unavailable-card-body>
    </div>
    <ng-template #availableBody>
        <ng-container *ngIf="displayedParams.type === displayedResults.type">
            <div [qa]="{'eda-card-body': {id: params.id, type: params.type}}" class="h100">
                <categorical-histogram-card-body *ngIf="displayedResults.type === 'categorical_histogram' && displayedParams.type === 'categorical_histogram'" class="header-stat-card"
                    [params]="displayedParams" [results]="displayedResults" [hasFixedHeight]="hasFixedHeight"
                    (action)="propagateAction($event)" [renderingMode]="renderingMode">
                </categorical-histogram-card-body>

                <ks-test-2samp-card-body *ngIf="displayedResults.type === 'ks_test_2samp' && displayedParams.type === 'ks_test_2samp'" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)">
                </ks-test-2samp-card-body>

                <tztest1-samp-card-body *ngIf="displayedResults.type === 'ttest_ztest_1samp' && displayedParams.type === 'ttest_ztest_1samp'" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)">
                </tztest1-samp-card-body>

                <sign-test1-samp-card-body *ngIf="displayedResults.type === 'sign_test_1samp' && displayedParams.type === 'sign_test_1samp'" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)">
                </sign-test1-samp-card-body>

                <generic-nsamp-test-card-body *ngIf="(
                            displayedResults.type === 'ttest_2samp' ||
                            displayedResults.type === 'mood_test_2samp' ||
                            displayedResults.type === 'mood_nsamp' ||
                            displayedResults.type === 'oneway_anova'
                        ) && (
                            displayedParams.type === 'ttest_2samp' ||
                            displayedParams.type === 'mood_test_2samp' ||
                            displayedParams.type === 'mood_nsamp' ||
                            displayedParams.type === 'oneway_anova'
                        )"
                    [params]="displayedParams"
                    [results]="displayedResults"
                    (action)="propagateAction($event)">
                </generic-nsamp-test-card-body>

                <generic-pairwise-test-card-body
                    *ngIf="(
                            displayedResults.type === 'pairwise_ttest' ||
                            displayedResults.type === 'pairwise_mood'
                        ) && (
                            displayedParams.type === 'pairwise_ttest' ||
                            displayedParams.type === 'pairwise_mood'
                        )"
                    [params]="displayedParams"
                    [results]="displayedResults"
                    (action)="propagateAction($event)">
                </generic-pairwise-test-card-body>

                <numerical-histogram-card-body *ngIf="displayedResults.type === 'numerical_histogram' && displayedParams.type === 'numerical_histogram'" class="header-stat-card"
                    [params]="displayedParams" [results]="displayedResults" [hasFixedHeight]="hasFixedHeight"
                    (action)="propagateAction($event)" [renderingMode]="renderingMode">
                </numerical-histogram-card-body>

                <generic-summary-stats-card-body
                    *ngIf="(
                            displayedResults.type === 'univariate_summary' ||
                            displayedResults.type === 'bivariate_summary'
                        ) && (
                            displayedParams.type === 'univariate_summary' ||
                            displayedParams.type === 'bivariate_summary'
                        )"
                    class="header-stat-card"
                    [params]="displayedParams"
                    [results]="displayedResults"
                    (action)="propagateAction($event)"
                    [readOnly]="readOnly"
                    [extendedActions]="extendedActions">
                </generic-summary-stats-card-body>

                <shapiro-normality-test-card-body *ngIf="displayedResults.type === 'shapiro' && displayedParams.type === 'shapiro'" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)">
                </shapiro-normality-test-card-body>

                <quantiles-table-card-body *ngIf="displayedResults.type === 'quantile_table' && displayedParams.type === 'quantile_table'" class="header-stat-card"
                    [params]="displayedParams" [results]="displayedResults" (action)="propagateAction($event)">
                </quantiles-table-card-body>

                <univariate-frequency-table-card-body *ngIf="displayedResults.type === 'univariate_frequency_table' && displayedParams.type === 'univariate_frequency_table'" class="header-stat-card"
                    [params]="displayedParams" [results]="displayedResults" (action)="propagateAction($event)">
                </univariate-frequency-table-card-body>

                <bivariate-histogram-card-body *ngIf="displayedResults.type === 'bivariate_histogram' && displayedParams.type === 'bivariate_histogram'" class="header-stat-card"
                    [params]="displayedParams" [results]="displayedResults" [hasFixedHeight]="hasFixedHeight"
                    (action)="propagateAction($event)">
                </bivariate-histogram-card-body>

                <bivariate-box-plot-card-body *ngIf="displayedResults.type === 'bivariate_box_plot' && displayedParams.type === 'bivariate_box_plot'" class="header-stat-card"
                    [params]="displayedParams" [results]="displayedResults" [hasFixedHeight]="hasFixedHeight"
                    (action)="propagateAction($event)">
                </bivariate-box-plot-card-body>

                <scatter-plot-card-body *ngIf="displayedResults.type === 'scatter_plot' && displayedParams.type === 'scatter_plot'" class="header-stat-card" [params]="displayedParams"
                    [results]="displayedResults" [hasFixedHeight]="hasFixedHeight" (action)="propagateAction($event)">
                </scatter-plot-card-body>

                <mosaic-plot-card-body *ngIf="displayedResults.type === 'mosaic_plot' && displayedParams.type === 'mosaic_plot'" class="header-stat-card" [params]="displayedParams"
                    [results]="displayedResults" [hasFixedHeight]="hasFixedHeight" (action)="propagateAction($event)">
                </mosaic-plot-card-body>

                <bivariate-frequency-table-card-body *ngIf="displayedResults.type === 'bivariate_frequency_table' && displayedParams.type === 'bivariate_frequency_table'" class="header-stat-card"
                    [params]="displayedParams" [results]="displayedResults" [renderingMode]="renderingMode"
                    (action)="propagateAction($event)">
                </bivariate-frequency-table-card-body>

                <fit-2d-distribution-card-body *ngIf="displayedResults.type === 'fit_2d_distribution' && displayedParams.type === 'fit_2d_distribution'" [params]="displayedParams"
                    [results]="displayedResults" [hasFixedHeight]="hasFixedHeight" (action)="propagateAction($event)">
                </fit-2d-distribution-card-body>

                <header-card-body *ngIf="(
                            displayedResults.type === 'bivariate_header' ||
                            displayedResults.type === 'univariate_header'
                        ) && (
                            displayedParams.type === 'bivariate_header' ||
                            displayedParams.type === 'univariate_header'
                        )"
                    [params]="displayedParams"
                    [results]="displayedResults"
                    [readOnly]="readOnly"
                    [extendedActions]="extendedActions"
                    (action)="propagateAction($event)">
                </header-card-body>

                <fit-distribution-card-body *ngIf="displayedResults.type === 'fit_distribution' && displayedParams.type === 'fit_distribution'" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)">
                </fit-distribution-card-body>

                <chi2-ind-test-card-body *ngIf="displayedResults.type === 'chi2_independence_test' && displayedParams.type === 'chi2_independence_test'" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)">
                </chi2-ind-test-card-body>

                <fit-curve-card-body *ngIf="displayedResults.type === 'fit_curve' && displayedParams.type === 'fit_curve'" [params]="displayedParams" [results]="displayedResults"
                    (action)="propagateAction($event)">
                </fit-curve-card-body>

                <correlation-matrix-card-body *ngIf="displayedResults.type === 'correlation_matrix' && displayedParams.type === 'correlation_matrix'" [params]="displayedParams"
                    [results]="displayedResults" [readOnly]="readOnly" (action)="propagateAction($event)">
                </correlation-matrix-card-body>

                <scatter-plot-3d-card-body *ngIf="displayedResults.type === 'scatter_plot_3d' && displayedParams.type === 'scatter_plot_3d'" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)">
                </scatter-plot-3d-card-body>

                <cdf-plot-card-body *ngIf="displayedResults.type === 'cdf_plot' && displayedParams.type === 'cdf_plot'" class="header-stat-card"
                    [hasFixedHeight]="hasFixedHeight" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)"
                    [renderingMode]="renderingMode">
                </cdf-plot-card-body>

                <parallel-coordinates-plot-card-body *ngIf="displayedResults.type === 'parallel_coordinates_plot' && displayedParams.type === 'parallel_coordinates_plot'"
                    [params]="displayedParams"
                    [results]="displayedResults"
                    (action)="propagateAction($event)">
                </parallel-coordinates-plot-card-body>

                <pca-card-body *ngIf="displayedResults.type === 'pca' && displayedParams.type === 'pca'" [params]="displayedParams" [results]="displayedResults"
                    [hasFixedHeight]="hasFixedHeight" [readOnly]="readOnly" (action)="propagateAction($event)">
                </pca-card-body>

                <unit-root-test-card-body
                    *ngIf="(
                            displayedResults.type === 'unit_root_test_adf' ||
                            displayedResults.type === 'unit_root_test_za' ||
                            displayedResults.type === 'unit_root_test_kpss'
                        ) && (
                            displayedParams.type === 'unit_root_test_adf' ||
                            displayedParams.type === 'unit_root_test_za' ||
                            displayedParams.type === 'unit_root_test_kpss'
                        )"
                    [params]="displayedParams"
                    [results]="displayedResults"
                ></unit-root-test-card-body>

                <durbin-watson-card-body
                    *ngIf="displayedResults.type === 'durbin_watson' && displayedParams.type === 'durbin_watson'"
                    [params]="displayedParams"
                    [results]="displayedResults"
                >
                </durbin-watson-card-body>

                <mann-kendall-test-card-body
                    *ngIf="displayedResults.type === 'mann_kendall_test' && displayedParams.type === 'mann_kendall_test'"
                    [params]="displayedParams"
                    [results]="displayedResults"
                ></mann-kendall-test-card-body>

                <acf-plot-card-body
                    *ngIf="displayedResults.type === 'acf_plot' && displayedParams.type === 'acf_plot'"
                    [params]="displayedParams"
                    [results]="displayedResults"
                    [hasFixedHeight]="hasFixedHeight"
                ></acf-plot-card-body>

                <timeseries-decomposition-card-body
                    *ngIf="displayedResults.type === 'stl_decomposition' && displayedParams.type === 'stl_decomposition'"
                    [params]="displayedParams"
                    [results]="displayedResults"
                    [hasFixedHeight]="hasFixedHeight"
                    [readOnly]="readOnly"
                    (action)="propagateAction($event)"
                ></timeseries-decomposition-card-body>

                <playground-card-body
                    *ngIf="displayedResults.type === 'playground' && displayedParams.type === 'playground'"
                    [params]="displayedParams"
                    [results]="displayedResults"
                    [readOnly]="readOnly"
                    (action)="propagateAction($event)"
                ></playground-card-body>
            </div>
        </ng-container>

        <ng-container *ngIf="displayedResults.type === 'groups'">
            <ng-container>
                <!-- Card has a custom/special grouped layout -->
                <ng-container *ngIf="displayedParams.type === 'univariate_header'">
                    <grouped-header-card-body [params]="displayedParams" [results]="displayedResults" [readOnly]="readOnly" [extendedActions]="extendedActions" (action)="propagateAction($event)">
                    </grouped-header-card-body>
                </ng-container>

                <ng-container *ngIf="displayedParams.type === 'bivariate_header'">
                    <grouped-header-card-body [params]="displayedParams" [results]="displayedResults" [readOnly]="readOnly" [extendedActions]="extendedActions" (action)="propagateAction($event)">
                    </grouped-header-card-body>
                </ng-container>

                <!-- Most cards are using the generic grouped layout -->
                <grouped-card-body *ngIf="displayedParams.type !== 'univariate_header' && displayedParams.type !== 'bivariate_header'" [params]="displayedParams" [results]="displayedResults"
                    [renderingMode]="CardBodyRenderingMode.TOP_LEVEL_CARD" [readOnly]="readOnly" [extendedActions]="extendedActions" [hasFixedHeight]="hasFixedHeight" (action)="propagateAction($event)"></grouped-card-body>
            </ng-container>
        </ng-container>

        <div *ngFor="let warning of distinctWarnings; trackBy: trackByIndex" class="alert alert-warning">
            <i class="icon-warning-sign"></i>
            {{ warning }}
        </div>
    </ng-template>
    <div class="overlay" *ngIf="displayOverlay">
        <div>
            <span class="dku-loader icon-spin card-spinner"></span>
        </div>
    </div>
</ng-container>
