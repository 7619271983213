<div class="review-image-container h100" *ngIf="(labelingReviewService.finishedReview$ | async) === false && (labelingReviewService.isEmpty$ | async) === false">
    <div class="review-image-container__body">
        <ng-container *ngIf="((labelingReviewService.currentReviewRecord$ | async)?.record?.extraColumns) as extraColumns">
            <top-drawer class="review-image-container__context" *ngIf="(extraColumns | keyvalue)?.length">
                <labeling-metadata-content [content]="extraColumns"></labeling-metadata-content>
            </top-drawer>
        </ng-container>
        <div class="image-container">
            <labeling-error></labeling-error>
            <ng-container *ngIf="{
                currentReview: labelingReviewUpdateService.currentReview$ | async,
                updatingReview: labelingReviewUpdateService.updatingReview$ | async,
                itemId: itemId$ | async,
                canEditAnnotations: labelingReviewUpdateService.canEditAnnotations$ | async
            } as ctx">
                <ng-container
                    *ngIf="labelingService.labelingTaskInfo$ | async as task"
                    [ngSwitch]="task.type"
                >
                    <ng-container *ngSwitchCase="LabelingTaskType.OBJECT_DETECTION">
                        <object-detection-image-canvas
                            *ngIf="ctx.updatingReview && (ctx.currentReview | instanceOf: ObjectDetectionUILabel) && ctx.itemId"
                            class="w100 h100"
                            [readOnly]="!ctx.canEditAnnotations"
                            [imagePath]="ctx.itemId"
                            [label]="ctx.currentReview"
                            (labelChange)="labelingReviewUpdateService.currentReview$.next($event)">
                        </object-detection-image-canvas>
                        <object-detection-region-selector
                            *ngIf="!ctx.updatingReview && ctx.itemId"
                            [readOnly]="!ctx.canEditAnnotations"
                            class="w100 h100"
                            [imagePath]="ctx.itemId">
                        </object-detection-region-selector>
                    </ng-container>
                    <ng-container *ngSwitchCase="LabelingTaskType.IMAGE_CLASSIFICATION">
                        <image-container class="image-container__classification" *ngIf="ctx.itemId" [src]="ctx.itemId" [scale]="3" [scaleOrigin]="'center'" [placeholderSize]="'large'"></image-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="LabelingTaskType.NAMED_ENTITY_EXTRACTION">
                        <labeling-task-text-annotate
                            *ngIf="ctx.updatingReview && (ctx.currentReview | instanceOf: NamedEntityExtractionUILabel)"
                            [record]="(((labelingReviewService.currentReviewRecord$ | async) | as: ReviewRecordInfo).record | as: TextLabelingRecord)"
                            [label]="ctx.currentReview"
                            [readOnly]="!ctx.canEditAnnotations"
                            (labelChange)="labelingReviewUpdateService.currentReview$.next($event)"
                            class="h100"
                        ></labeling-task-text-annotate>
                        <named-entity-region-selector
                            *ngIf="!ctx.updatingReview"
                            class="h100"
                        ></named-entity-region-selector>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div class="annotation-group-list__container h100">
        <div class="annotation-group-list__scroll">
            <annotation-group-list *ngIf="(labelingReviewUpdateService.updatingReview$ | async) === false"></annotation-group-list>
            <annotation-list *ngIf="(labelingReviewUpdateService.updatingReview$ | async)" [updating]="true"></annotation-list>
        </div>
    </div>
</div>
<div *ngIf="labelingReviewService.finishedReview$ | async" class="empty-list-cta">
    <div>
        <h1>
            All good!
        </h1>
        <p class="small">
                The review session is finished
        </p>
        <p class="small" *ngIf="privilegesOnTask.canReadConf && (labelingService.labelingTaskInfo$ | async)?.labelsDataset as datasetName">
            Browse the
            <a href="{{$state.href('projects.project.datasets.dataset.explore', {datasetName: datasetName})}}">
            output labels dataset
            </a>
        </p>
    </div>
</div>
<div *ngIf="(labelingReviewService.isFiltered$ | async) === false && (labelingReviewService.isEmpty$ | async) === true" class="empty-list-cta">
    <h1>
        <review-item-empty></review-item-empty>
    </h1>
    <p class="small" *ngIf="privilegesOnTask.canReadConf">
        Go to <a  href="{{$state.href('projects.project.labelingtasks.labelingtask.overview')}}"> overview</a>
    </p>
</div>
