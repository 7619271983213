import type {JointNormal} from './joint-normal';
import type {KDE2D} from './kde2-d';

/**
 * Generated from com.dataiku.dip.eda.compute.distributions.Distribution2D
 */
export interface _Distribution2D {}

export type Distribution2D = JointNormal | KDE2D;

export namespace Distribution2D {
    export const type = ['joint_normal', 'kde_2d'] as const;
    export function isJointNormal(toTest: Distribution2D): toTest is JointNormal { return toTest['type'] === 'joint_normal'; }
    export function isKDE2D(toTest: Distribution2D): toTest is KDE2D { return toTest['type'] === 'kde_2d'; }
}

export namespace Distribution2D {
    /**
     * Generated from com.dataiku.dip.eda.compute.distributions.Distribution2D$FittedDistribution2D
     */
    export interface _FittedDistribution2D {}

    export type FittedDistribution2D = JointNormal.FittedJointNormal | KDE2D.FittedKDE2D;

    export namespace FittedDistribution2D {
        export const type = ['joint_normal', 'kde_2d'] as const;
        export function isFittedJointNormal(toTest: Distribution2D.FittedDistribution2D): toTest is JointNormal.FittedJointNormal { return toTest['type'] === 'joint_normal'; }
        export function isFittedKDE2D(toTest: Distribution2D.FittedDistribution2D): toTest is KDE2D.FittedKDE2D { return toTest['type'] === 'kde_2d'; }
    }
}