import { Component, Input, Output, HostListener, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { UntilDestroy } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';
import { MatOptionSelectionChange } from '@angular/material/core';

/**
 * Input meant to be used within an editable list.
 */
@UntilDestroy()
@Component({
    selector: 'editable-list-input',
    templateUrl: './editable-list-input.component.html',
    styleUrls: ['./editable-list-input.component.less']
})
export class EditableListInputComponent implements OnInit {
    @Input() type: string = 'text';
    @Input() inputControl: FormControl;
    @Input() name: string;
    @Input() placeholder: string;
    // native browser autocomplete
    @Input() autocomplete: string;
    @Input() required: boolean = false;
    @Input() warnIfTrimmable: boolean = false;
    @Output() onFocus: EventEmitter<any> = new EventEmitter();
    @Output() onBlur: EventEmitter<any> = new EventEmitter();
    @Output() onEnter: EventEmitter<any> = new EventEmitter();
    @Output() inputChange: EventEmitter<string> = new EventEmitter();
    // suggestions for mat-autocomplete
    @Input() suggestions?: string[];
    @ViewChild(MatAutocomplete) matAutocompleteEl: MatAutocomplete;
    @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger;

    // whether or not user selected item in autocomplete suggestion list
    selectedAutocompleteItemViaEnter: boolean = false;

    ngOnInit() {
        this.inputControl.valueChanges
            .pipe(
                distinctUntilChanged()
            )
            .subscribe((entry: string) => this.inputChange.emit(entry));
    }

    handleFocus($event: FocusEvent) {
        this.onFocus.emit($event);
    }

    handleBlur() {
        this.onBlur.emit();
    }

    /*
        Whenever an autocomplete item is selected, set
        flag to true. If item was selected via click,
        it will be reset (see onSelectionClick).

        This flag is used to prevent the editable list from
        creating a new entry after selecting a suggestion.
    */
    onSelectionChange(event: MatOptionSelectionChange) {
        this.selectedAutocompleteItemViaEnter = true;
    }

    /*
        Fires after onSelectionChange.
        If selected autocomplete item was not done via enter key,
        reset flag.
    */
    onSelectionClick(event: any) {
        this.selectedAutocompleteItemViaEnter = false;
    }

    @HostListener('keydown.enter', ['$event'])
    handleEnter(event: KeyboardEvent) {
        if (!this.selectedAutocompleteItemViaEnter) {
            this.onEnter.emit(event);
        }

        this.matAutocompleteTrigger.closePanel();
        this.selectedAutocompleteItemViaEnter = false;
    }

    warnValueIsTrimmable() {
        return this.warnIfTrimmable && (this.inputControl.value || "").trim() !== (this.inputControl.value || "");
    }
}
