<h4>Data Steward</h4>
<span class="data-steward">
    <button
        type="button"
        *ngIf="isEditable"
        (click)="editDataSteward.emit()"
        class="btn btn--icon btn--dku-icon edit-data-steward-button"
        matTooltip="Edit Data Steward"
        matTooltipPosition="left"
    >
        <i class="dku-icon-edit-16"></i>
    </button>
    <span class="data-steward__login"
        [matTooltip]="isEditable ? '' : 'You must have write permissions on dataset project to edit Data Steward'"
        matTooltipPosition="left"
    >
        {{ dataSteward?.displayName || dataSteward?.login || 'None' }}
    </span>
    <a *ngIf="dataSteward && dataSteward.login"
        class="mleft4" href="/profile/{{dataSteward.login}}/"
        [matTooltip]="dataSteward.displayName || dataSteward.login"
        matTooltipPosition="left"
    >
        <user-picture [user]="dataSteward.login" [size]="24"></user-picture>
    </a>
</span>
