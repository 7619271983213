import { Injectable } from "@angular/core";
import { CatalogFacetMap } from '@shared/models';
import { SortByOptions, SortOrder } from "../models/data-collections-filter.model";

const SORT_BY_KEY = 'dataCollectionsSortBy';
const SORT_ORDER_KEY = 'dataCollectionsSortOrder';
const DATA_COLLECTION_TAG_SELECTION = 'dataCollectionSelectedTags';
const DATA_COLLECTION_QUERY_KEY = 'dataCollectionQuery';

const DATA_SOURCE_FACETS_KEY = "dataSourcesFacets";
const DATA_SOURCE_QUERY_KEY = "dataSourcesQuery";

export class SortingParams {
    sortBy: SortByOptions;
    sortOrder: SortOrder;

    constructor(sortByOption: SortByOptions = SortByOptions.NAME, sortOrder: SortOrder = SortOrder.ASC) {
        this.sortBy = sortByOption;
        this.sortOrder = sortOrder;
    }
}
@Injectable({
    providedIn: 'root'
})
export class LocalStorageSortingService {

    getSortingParams(): SortingParams {
        return new SortingParams(this.getSortByOption(), this.getSortOrder());
    }

    getSortByOption(): SortByOptions {
        return (localStorage.getItem(SORT_BY_KEY) || 'Name') as SortByOptions;
    }

    setSortByOption(sortByOption: SortByOptions): void {
        localStorage.setItem(SORT_BY_KEY, sortByOption);
    }

    getSortOrder(): SortOrder {
        return (localStorage.getItem(SORT_ORDER_KEY) || SortOrder.ASC) as SortOrder;
    }

    setSortOrder(sortOrder: SortOrder): void {
        localStorage.setItem(SORT_ORDER_KEY, sortOrder);
    }

    getDataCollectionTagSelection(): string[] {
        const tags = localStorage.getItem(DATA_COLLECTION_TAG_SELECTION);
        if (!tags) {
            return [];
        }
        return JSON.parse(tags) as string[];
    }

    setDataCollectionTagSelection(tags: string[]): void {
        localStorage.setItem(DATA_COLLECTION_TAG_SELECTION, JSON.stringify(tags));
    }

    getDataCollectionQuery(): string {
        return localStorage.getItem(DATA_COLLECTION_QUERY_KEY) ?? "";
    }

    setDataCollectionQuery(value: string) {
        localStorage.setItem(DATA_COLLECTION_QUERY_KEY, value);
    }

    getDataSourcesFacets(): CatalogFacetMap {
        return JSON.parse(localStorage.getItem(DATA_SOURCE_FACETS_KEY) ?? '{}') as CatalogFacetMap;
    }

    saveDataSourcesFacets(value: CatalogFacetMap) {
        localStorage.setItem(DATA_SOURCE_FACETS_KEY, JSON.stringify(value));
    }

    getDataSourcesQuery(): string {
        return localStorage.getItem(DATA_SOURCE_QUERY_KEY) ?? "";
    }

    saveDataSourcesQuery(value: string) {
        localStorage.setItem(DATA_SOURCE_QUERY_KEY, value);
    }
}
