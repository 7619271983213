
<div class="modal-header no-totem has-border">
    <h4 class="remove-title">{{ removalText }} "{{ data.title }}"</h4>
</div>
<div class="modal-body">
    <p>Are you sure you want to {{ removalText }} "{{ data.title }}"?
    </p>
</div>
<div class="modal-footer modal-footer-std-buttons">
    <div class="pull-right">
        <button type="button" class="btn btn--text btn--secondary" (click)="dialogRef.close(false)">Cancel</button>
        <button type="button" class="btn btn--text btn--danger" (click)="dialogRef.close(true)" >
            {{ removalText }}
        </button>
    </div>
</div>