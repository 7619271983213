<div
    class="drag-scroll"
    cdkScrollable
    [style.cursor]="dragging ? 'grabbing': 'auto'"
    [ngStyle]="{'height': height}"
    (mouseleave)="onMouseLeave($event)"
    (mouseup)="onMouseUp($event)"
    (mousedown)="onMouseDown($event)"
    #scroller
>
    <ng-content></ng-content>
</div>
