<right-panel-status-metric
    *ngIf="showSize"
    [metricValue]="status.size.totalValue"
    [metricDate]="status.size.lastComputed"
    metricTitle="Total Size"
    [metricLabel]="status.size.totalValue | fileSizeOrNA"
    [canBeForced]="showPartitionning"
    [canUpdate]="canUpdate"
    (refreshMetric)="refreshStatus.emit({metricToRefresh: DATASET_METRIC.SIZE, forceRefresh: $event})"
></right-panel-status-metric>
<right-panel-status-metric
    [metricValue]="status.records.totalValue"
    [metricDate]="status.records.lastComputed"
    metricTitle="Records"
    [metricLabel]="status.records.totalValue | number"
    [canBeForced]="showPartitionning"
    [canUpdate]="canUpdate"
    (refreshMetric)="refreshStatus.emit({metricToRefresh: DATASET_METRIC.RECORDS, forceRefresh: $event})"
></right-panel-status-metric>
<right-panel-status-metric
    *ngIf="showPartitionning"
    [metricValue]="status.partitions"
    [metricDate]="status.partitionsLastCompute"
    metricTitle="Partitions"
    [metricLabel]="status.partitions | number"
    [canUpdate]="canUpdate"
    (refreshMetric)="refreshStatus.emit({metricToRefresh: DATASET_METRIC.PARTITIONING, forceRefresh: false})"
></right-panel-status-metric>