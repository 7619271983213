import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {CorrelationFunction, _CorrelationFunction} from './correlation-function';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.timeseries.PACF
 */
export interface PACF extends _CorrelationFunction {
    alpha: number;
    method: PACF.Method;
    nLags?: number | null;

    // PolyJson type
    type: 'pacf';
}

export namespace PACF {
    export const type = 'pacf';
}

/** @deprecated Use Computation.isPACF() */ export function isPACF(toTest: Computation): toTest is PACF { return PACF.type === toTest['type']; }

export namespace PACF {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.PACF$Method
     */
    export enum Method {
        YULE_WALKER = 'YULE_WALKER',
        OLS = 'OLS',
        OLS_UNBIASED = 'OLS_UNBIASED',
        LEVINSON_DURBIN = 'LEVINSON_DURBIN'
    }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.PACF$PACFResult
     */
    export interface PACFResult extends CorrelationFunction._CorrelationFunctionResult {
        // PolyJson type
        type: 'pacf';
    }

    export namespace PACFResult {
        export const type = 'pacf';
    }

    /** @deprecated Use ComputationResult.isPACFResult() */ export function isPACFResult(toTest: ComputationResult): toTest is PACFResult { return PACFResult.type === toTest['type']; }
}