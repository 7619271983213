<ng-select
    [items]="groupedUiItems || ungroupedItems"
    [bindLabel]="'label'"
    [bindValue]="'value'"
    [searchable]="false"
    [placeholder]="placeholder || 'Nothing selected'"
    [virtualScroll]="true"
    class="
        ng-select--without-ng-option-padding
        ng-select--without-ng-option-disabled-opacity
        ng-select--no-wrap-selected-items
        ng-select--without-highlighting-of-selected-items-if-mutiple
    "
    [clearable]="false"
    [searchFn]="searchFn"
    [trackByFn]="trackByFn"
    [groupBy]="groupedUiItems ? 'items' : ''"
    [compareWith]="compareWith"
    [multiple]="multiple"
    [closeOnSelect]="!multiple"
    [style.--ng-select-title-prefix-text]="(titlePrefix ? titlePrefix + ':&nbsp;' : '') | escapeCssString"
    [bufferAmount]="30"
    appendTo="body"
    [ngModel]="selectedValue"
    (ngModelChange)="modelChanged($event)">
    <ng-template ng-header-tmp>
        <ng-select-search-input *ngIf="searchable"></ng-select-search-input>
    </ng-template>
    <ng-template ng-optgroup-tmp let-item="item">
        <!-- Weird: <ng-select> puts the group name in a property named after [bindLabel] -->
        <div class="group">{{ item.label }}</div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
        <ng-container *ngIf="item.itemType === 'regular'">
            <div class="checkmark" *ngIf="multiple">
                <ng-select-pseudo-checkbox></ng-select-pseudo-checkbox>
            </div>
            <div class="item"
                [class.item--grouped]="!!groupedUiItems"
                [class.item--with-checkmark]="multiple"
            >
                {{ item.label }}
            </div>
        </ng-container>
        <div class="separator" *ngIf="item.itemType === 'separator'"><hr></div>
    </ng-template>
    <ng-template ng-notfound-tmp>
        <div class="item">No items found</div>
    </ng-template>
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <!-- Template for the menu trigger in multiple mode -->
        <span class="title-prefix" *ngIf="titlePrefix"><!-- Content set by CSS --></span>
        <ng-container *ngFor="let item of items; let first = first">{{ (!first ? ', ' : '') + item.label }}</ng-container>
    </ng-template>
    <ng-template ng-label-tmp let-item="item">
        <span class="title-prefix" *ngIf="titlePrefix"><!-- Content set by CSS --></span>
        {{ item.label }}
    </ng-template>
</ng-select>
<!--
    [bufferAmount] was set to a value higher than default (=4) in order to work around a known bug of <ng-select>'s virtual scroll
    See https://github.com/ng-select/ng-select/issues/1994
-->
