import { directive } from '@migration/downgraded-components';
import { service, constant } from '@migration/downgraded-providers';

import { RegressionTypes, ValuesInChartOverlappingStrategy } from './constants';
import { ChartTensorDataWrapper } from './models';
import { EChartsManagerService, EChartsInstanceManagerService, TreemapEChartDefService, PieEChartDefService, ChartCustomColorsService, ReferenceLinesService, ReferenceLinesFormComponent, ChartColorPickerComponent, ChartCustomColorsComponent, RegressionLineFormComponent, ValuesDisplayFormComponent } from './modules';
import { ChartLabelsService, ChartDataWrapperFactoryService, ChartDimensionService, ChartFormattingService, ChartStaticDataService, ChartUADimensionService, NumberFormatterService, ChartDefinitionChangeHandlerService, ChartFiltersService, ChartFilterUtilsService, ChartColumnTypeUtilsService, ChartRegressionService, ColorUtilsService } from './services';

export function downgradeSimpleReport() {
    //  Services
    service('EChartsManager', EChartsManagerService),
    service('EChartsInstanceManager', EChartsInstanceManagerService),
    service('TreemapEChartDef', TreemapEChartDefService),
    service('PieEChartDef', PieEChartDefService),
    service('ChartDimension', ChartDimensionService),
    service('ChartUADimension', ChartUADimensionService),
    service('ChartsStaticData', ChartStaticDataService),
    service('NumberFormatter', NumberFormatterService),
    service('ChartFormatting', ChartFormattingService),
    service('ChartLabels', ChartLabelsService),
    service('ChartDataWrapperFactory', ChartDataWrapperFactoryService),
    service('ChartCustomColors', ChartCustomColorsService),
    service('ChartDefinitionChangeHandler', ChartDefinitionChangeHandlerService),
    service('ChartRegression', ChartRegressionService),
    service('ReferenceLines', ReferenceLinesService),
    service('ChartFilters', ChartFiltersService),
    service('ChartFilterUtils', ChartFilterUtilsService),
    service('ChartColumnTypeUtils', ChartColumnTypeUtilsService),
    service('ColorUtils', ColorUtilsService),

    //  Constants
    constant('ChartTensorDataWrapper', ChartTensorDataWrapper);
    constant('ValuesInChartOverlappingStrategy', ValuesInChartOverlappingStrategy);
    constant('RegressionTypes', RegressionTypes);

    //  Components
    directive('ng2RegressionLineForm', RegressionLineFormComponent);
    directive('ng2ReferenceLinesForm', ReferenceLinesFormComponent);
    directive('ng2ChartColorPicker', ChartColorPickerComponent);
    directive('ng2ChartCustomColors', ChartCustomColorsComponent);
    directive('ng2ValuesDisplayForm', ValuesDisplayFormComponent);
}
