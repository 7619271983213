import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';
import { TopNavService } from '@core/nav/top-nav.service';
import { NavUniverses } from '@core/nav/nav-universes.enum';
import { NavItems } from '@core/nav/nav-items.enum';
import { APIError } from '@core/dataiku-api/api-error';
import { WorkspacesService, WorkspacesNavigationService, WorkspaceStates, WorkspaceLocalStorageService } from './shared';

const LEFT_MENU_STATES: string[] = [
    WorkspaceStates.HOME,
    WorkspaceStates.WORKSPACE
];

@UntilDestroy()
@Component({
    selector: 'workspaces',
    templateUrl: './workspaces.component.html',
    styleUrls: ['./workspaces.component.less']
})
export class WorkspacesComponent implements OnInit {
    readonly workspaces$ = this.workspacesService.getWorkspaces();
    readonly currentWorkspace$ = this.workspacesService.getCurrentWorkspace();
    readonly currentObject$ = this.workspacesService.getCurrentObject();
    readonly loading$ = new BehaviorSubject<boolean>(true);
    readonly error$ = new BehaviorSubject<APIError | undefined>(undefined);

    isLeftMenuVisible = true;

    constructor(
        private workspacesNavigation: WorkspacesNavigationService,
        private topNav: TopNavService,
        private workspacesService: WorkspacesService,
        private workspaceLocalStorageService: WorkspaceLocalStorageService,
    ) { }

    ngOnInit(): void {
        this.topNav.setLocation(NavUniverses.DSS_HOME, NavItems.WORKSPACES);

        combineLatest([
            this.workspaces$,
            this.workspacesNavigation.currentState$
        ]).pipe(
            tap(([workspaces, state]) => {
                const workspaceKey = this.workspacesNavigation.getParam('workspaceKey');
                const isInWorkspace: boolean = !!(workspaceKey && workspaceKey?.length > 0);

                if (workspaces.length > 0 && (state === WorkspaceStates.HOME || !isInWorkspace)) {
                    const lastOpenedWorkspace = this.workspaceLocalStorageService.getLastOpenedWorkspace();
                    if (lastOpenedWorkspace && workspaces.find(workspace => workspace.workspaceKey === lastOpenedWorkspace)) {
                        this.goToWorkspace(lastOpenedWorkspace, true);
                    } else {
                        this.goToWorkspace(workspaces[0].workspaceKey, true);
                    }
                }

                this.isLeftMenuVisible = workspaces.length > 0 && LEFT_MENU_STATES.includes(state);
            }),
            untilDestroyed(this)
        ).subscribe();

        this.workspacesService.fetchWorkspaces().pipe(
            catchError((error: APIError) => {
                this.error$.next(error);
                return of([]);
            }),
            finalize(() => this.loading$.next(false))
        ).subscribe();

        this.currentWorkspace$.pipe(
            tap(workspace => this.workspacesNavigation.updateTopNavTitle(workspace)),
            untilDestroyed(this)
        ).subscribe();
    }

    goToWorkspace(workspaceKey: string, replace?: boolean): void {
        this.workspacesNavigation.goToWorkspace(workspaceKey, replace);
    }

    closeError(): void {
        this.error$.next(undefined);
    }
}
