import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChild, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { LabelingService } from '@features/labeling/services/labeling.service';
import { ReplaySubject } from 'rxjs';
import { PerAnnotatorStats, Stats } from '../labeling-task-overview.component';


@Component({
    selector: 'per-labeler-stats',
    templateUrl: './per-labeler-stats.component.html',
    styleUrls: ['./per-labeler-stats.component.less', '../shared-overview.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PerLabelerStatsComponent implements OnChanges {

    numRecordsLabeledByBiggestAnnotator$ = new ReplaySubject<number>(1);

    @Input() perAnnotatorStats: PerAnnotatorStats;
    @Input() numMetrics: number;

    @ContentChild("headerTemplate") headerTemplateRef: TemplateRef<any>;
    @ContentChild("dataTemplate") dataTemplateRef: TemplateRef<any>;

    objectKeysFun = Object.keys;

    constructor(public labelingService: LabelingService) { }
    
    
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.perAnnotatorStats.currentValue) {
            this.numRecordsLabeledByBiggestAnnotator$.next(this.computeNumLabeledRecordsByBiggestLabelers(changes.perAnnotatorStats.currentValue));
        }
    }
    
    sortByBiggerLabeler = (a: KeyValue<string, Stats>, b: KeyValue<string, Stats>): number => {
        return b.value.numTotal - a.value.numTotal;
    }
    
    private computeNumLabeledRecordsByBiggestLabelers(perAnnotatorStats: PerAnnotatorStats) {
        let numRecordsLabeledByBiggestAnnotator = 0;
        for (const annotator in perAnnotatorStats) {
            if (perAnnotatorStats[annotator].numTotal > numRecordsLabeledByBiggestAnnotator) {
                numRecordsLabeledByBiggestAnnotator = perAnnotatorStats[annotator].numTotal;
            }
        }
        return numRecordsLabeledByBiggestAnnotator;
    }
}
