import { Injectable } from '@angular/core';
import { ModalsService } from '@shared/modals/modals.service';
import { ConfirmModalComponent } from '@shared/modals/confirm-modal/confirm-modal.component';
import { AckModalComponent } from '@shared/modals/ack-modal/ack-modal.component';
import { PromptModalComponent } from '@shared/modals/prompt-modal/prompt-modal.component';
import { SelectModalComponent } from '@shared/modals/select-modal/select-modal.component';

@Injectable({
    providedIn: 'root'
})
export class PromptService {

    constructor(
        private modalsService: ModalsService,
    ) {
    }

    confirm(title: string, message: string, danger = true): Promise<any> {
        return this.modalsService.open(ConfirmModalComponent, {title, message, danger});
    }

    ack(title: string, message: string): Promise<undefined> {
        return this.modalsService.open(AckModalComponent, {title, message});
    }

    prompt(title: string, message: string, defaultVal: string): Promise<string> {
        return this.modalsService.open(PromptModalComponent, {title, message, defaultVal});
    }

    select(title: string, message: string, items: any[], defaultItem?: any): Promise<string> {
        return this.modalsService.open(SelectModalComponent, {title, message, items, defaultItem});
    }
}
