import type {Distribution, _Distribution} from './distribution';

/**
 * Generated from com.dataiku.dip.eda.compute.distributions.Laplace
 */
export interface Laplace extends _Distribution {
    // PolyJson type
    type: 'laplace';
}

export namespace Laplace {
    export const type = 'laplace';
}

/** @deprecated Use Distribution.isLaplace() */ export function isLaplace(toTest: Distribution): toTest is Laplace { return Laplace.type === toTest['type']; }

export namespace Laplace {
    /**
     * Generated from com.dataiku.dip.eda.compute.distributions.Laplace$FittedLaplace
     */
    export interface FittedLaplace extends Distribution._FittedDistribution {
        b: number;
        mu: number;

        // PolyJson type
        type: 'laplace';
    }

    export namespace FittedLaplace {
        export const type = 'laplace';
    }

    /** @deprecated Use Distribution.FittedDistribution.isFittedLaplace() */ export function isFittedLaplace(toTest: Distribution.FittedDistribution): toTest is FittedLaplace { return FittedLaplace.type === toTest['type']; }
}