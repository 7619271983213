import { Component, ChangeDetectionStrategy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
    selector: "spreadsheet-cell-editor",

    templateUrl: "./spreadsheet-cell-editor.component.html",
    styleUrls: ["./spreadsheet-cell-editor.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpreadsheetCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
    // Fixed height of the status bar defined at src/static/css/ag-grid.less > .ag-status-bar
    private readonly STATUSBAR_HEIGHT = 45;
    private params!: ICellEditorParams;
    public value: string | null;
    @ViewChild('cellInput')
    public input: ElementRef;

    constructor() {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            // focus on the text area input when entering edit mode
            this.setInputElementMaxHeight();
            this.updateInputElementHeight();
            this.input.nativeElement.focus();
        });
    }

    getValue() {
        return this.value;
    }

    onKeyDown(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            if (event.shiftKey) {
                // Ignore propagation of Shift+Enter, otherwise this will cause focus to move to another cell (default by AG Grid)
                event.stopImmediatePropagation();
                // Expand the input area when there is a new line inserted
                this.updateInputElementHeight();
            }
        }
    }

    onInput(event: Event) {
        this.value = (event.target as HTMLInputElement).value;
    }

    private updateInputElementHeight() {
        this.input.nativeElement.style.height = `${this.input.nativeElement.scrollHeight as number}px`;
    }

    private setInputElementMaxHeight() {
        // Adding scroll position in case the view port is scrollable
        const topPos = this.input.nativeElement.getBoundingClientRect().top as number + window.scrollY;
        // In theory it should never be negative, but better safe than sorry
        // The max height of the input is the leftover space from the top of the input area to the bottom of the screen. The statusbar height is subtracted from the available height to avoid overlapping the input behind the status bar content
        // 4px (concluded from visual experiments and logically half of the @spacing value in CSS) is the space between the status bar and the text input box
        this.input.nativeElement.style.maxHeight = `${Math.max(window.innerHeight - this.STATUSBAR_HEIGHT - topPos - 4, 0)}px`;
    }

    agInit(params: ICellEditorParams): void {
        this.params = params;
        // Replace cell content with pressed character if it is not a modifier key. Otherwise enter edit mode, in which case the value should be set to the value of the cell
        this.value = params.charPress != null ? params.charPress : this.params.value;
    }
}
