import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'experiment-tracking-origin',
    templateUrl: './experiment-tracking-origin.component.html',
    styleUrls: ['./experiment-tracking-origin.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperimentTrackingOriginComponent {
    @Input() runOrigin: string;

    constructor(
    ) {

    }
}
 