import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompletableTextareaComponent } from './autocompletable-textarea.component';
import { AngularJSAutocompletableTextareaDirective } from './angularjs-autocompletable-textarea.directive';

@NgModule({
  declarations: [
    AngularJSAutocompletableTextareaDirective,
    AutocompletableTextareaComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AutocompletableTextareaComponent
  ]
})
export class AutocompletableTextareaModule { }
