import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toModernIcon'
})
export class ToModernIcon implements PipeTransform {
  constructor(@Inject('TypeMappingService') private readonly typeMappingService: any) { }

  transform(type: string | null, size = 16): string {
    return this.typeMappingService.mapOldIconTypeToModernIcon(type, size);
  }
}
