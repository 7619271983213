<form [formGroup]="configForm" id="config-form" class="dkuform-modal-horizontal">
    <div class="control-group">
        <label class="control-label">
            Variable
        </label>
        <div class="controls">
            <variable-selector
                [variables]="allVariables$ | async"
                formControlName="column"
            ></variable-selector>
        </div>
    </div>  
</form>
