import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WidgetsModule } from "@app/widgets/widgets.module";
import { NewInVersionPopoverComponent } from "./new-in-version-popover/new-in-version-popover.component";

@NgModule({
  declarations: [NewInVersionPopoverComponent],
  imports: [CommonModule, WidgetsModule],
  exports: [NewInVersionPopoverComponent],
})
export class NewInVersionModule {}
