export namespace DataAugmentationService {
    /**
     * Generated from com.dataiku.dip.analysis.coreservices.DataAugmentationService$AugmentationType
     */
    export enum AugmentationType {
        AFFINE = 'AFFINE',
        COLOR = 'COLOR',
        CROP = 'CROP'
    }

    /**
     * Generated from com.dataiku.dip.analysis.coreservices.DataAugmentationService$AugmentedImage
     */
    export interface AugmentedImage {
        failed: boolean;
        imgDataAsBase64: string;
    }
}