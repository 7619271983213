import type {Card, _Card} from './card';
import type {CardResult, _CardResult} from './card-result';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.PlaygroundCard
 */
export interface PlaygroundCard extends _Card {
    rawComputation: string;

    // PolyJson type
    type: 'playground';
}

export namespace PlaygroundCard {
    export const type = 'playground';
}

/** @deprecated Use Card.isPlaygroundCard() */ export function isPlaygroundCard(toTest: Card): toTest is PlaygroundCard { return PlaygroundCard.type === toTest['type']; }

export namespace PlaygroundCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.PlaygroundCard$PlaygroundCardResult
     */
    export interface PlaygroundCardResult extends _CardResult {
        errorMessage?: string | null;
        rawResult?: string | null;

        // PolyJson type
        type: 'playground';
    }

    export namespace PlaygroundCardResult {
        export const type = 'playground';
    }

    /** @deprecated Use CardResult.isPlaygroundCardResult() */ export function isPlaygroundCardResult(toTest: CardResult): toTest is PlaygroundCardResult { return PlaygroundCardResult.type === toTest['type']; }
}