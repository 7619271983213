<div class="labeling__annotate-main" *ngIf="labelingService.labelingTaskInfo$ | async as task" [ngClass]="{
    'labeling__annotate-main--named-entity': task.type === LabelingTaskType.NAMED_ENTITY_EXTRACTION
}">
    <div class="labeling__annotate-container">
        <ng-container *ngIf="((currentRecord$ | async)?.extraColumns) as extraColumns">
            <top-drawer class="labeling__annotate-context" *ngIf="(extraColumns | keyvalue)?.length">
                <labeling-metadata-content [content]="extraColumns"></labeling-metadata-content>
            </top-drawer>
        </ng-container>
        <div class="labeling__record-container">
            <labeling-error></labeling-error>
            <labeling-unusable-warning *ngIf="unusableTaskWarning$ | async as warning" [warning]="warning" [privileges]="privilegesOnTask"></labeling-unusable-warning>
            <ng-container *ngIf="(unusableTaskWarning$ | async) === null">
                <ng-container *ngIf="{
                    currentLabel: currentLabel$ | async,
                    currentRecord: currentRecord$ | async
                } as ctx">
                    <ng-container *ngIf="isImageTask(task.type)">
                        <labeling-task-image-annotate
                            class="w100 h100"
                            (labelChange)="labelTrigger$.next($event)"
                            [record]="ctx.currentRecord!"
                            [label]="ctx.currentLabel!"
                            [task]="(task | as: ImageLabelingTaskInfo)"
                        ></labeling-task-image-annotate>
                    </ng-container>
                    <ng-container *ngIf="task.type === LabelingTaskType.NAMED_ENTITY_EXTRACTION">
                        <labeling-task-text-annotate
                            [selectionModeAvailable]="true"
                            [record]="(ctx.currentRecord! | as: TextLabelingRecord)"
                            [label]="(ctx.currentLabel! | as: NamedEntityExtractionUILabel)"
                            (labelChange)="labelTrigger$.next($event)"
                            class="h100">
                        </labeling-task-text-annotate>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div class="labeling__right-panel">
        <div class="labeling__right-panel-section labeling__right-panel-top">
            <div class="labeling__control-buttons">
                <button class="btn btn--secondary labeling__right-panel-button" [disabled]="(noRecords$ | async) ||(indexInHistory$ | async) === 0" (click)="first()"><span>first</span><code class="keybind"><i class="icon-arrow-up"></i></code></button>
                <button class="btn btn--secondary labeling__right-panel-button" [disabled]="(noRecords$ | async) || (indexInHistory$ | async) === 0" (click)="previous()"><span>back</span><code class="keybind"><i class="icon-arrow-left"></i></code></button>
                <button class="btn btn--secondary labeling__right-panel-button" [disabled]="(hasNoNextRecord$ | async)" (click)="skip()"><span>skip</span><code class="keybind">X</code></button>
                <button class="btn btn--secondary labeling__right-panel-button" *ngIf="(isDirty$ | async) " [disabled]="(canSave$ | async) === false" (click)="saveAndNext()"><span>Save & Next</span><code class="keybind"><i class="icon-arrow-right"></i></code></button>
                <button class="btn btn--secondary labeling__right-panel-button" *ngIf="(isDirty$ | async) === false" [disabled]="(hasNoNextRecord$ | async) || (isLast$ | async)" (click)="next()"><span>Next</span><code class="keybind"><i class="icon-arrow-right"></i></code></button>
                <button class="btn btn--secondary labeling__right-panel-button" [disabled]="(hasNoNextRecord$ | async) || (isLast$ | async)" (click)="last()"><span>last</span><code class="keybind"><i class="icon-arrow-down"></i></code></button>
            </div>
            <ng-container *ngIf="!(noRecords$ | async) && (labelingService.classes$ | async)?.length">
                <div class="labeling__stat" *ngIf="!(hasNoNextRecord$ | async)">
                    <ng-container *ngIf="labelingService.instructions$ | async as instructions">
                        <div class="mrightauto">
                            <a [dkuPopoverTrigger]="instructionsPopover">
                                How to label?
                            </a>
    
                            <dku-popover #instructionsPopover>
                                <div class="labeling__instruction-popover">
                                    <div class="labeling__instruction-popover-title">
                                        Instructions for labeling
                                    </div>
                                    {{ instructions }}
                                </div>
                            </dku-popover>
                        </div>
                    </ng-container>
                    <span>Sample {{(indexInHistory$ | async)! + 1}} / <span class="labeling__sample-counter">{{(recordHistory$ | async)?.length}}</span></span>
                    <button class="btn btn--secondary btn--icon btn--dku-icon" (click)="copyPermalink()" [matTooltip]="'Copy '+ getItemName(task.type) + ' link to clipboard'" [matTooltipPosition]="'above'">
                        <i class="cursor-pointer mleft8" [class]="labelingService.copyClass$ | async"></i>
                    </button>
                    <div class="btn-group" *ngIf="task.type !== LabelingTaskType.IMAGE_CLASSIFICATION">
                        <button class="btn btn--secondary btn--dku-icon labeling__undo" [disabled]="!(canUndo$ | async)" (click)="undo()">
                            <i class="dku-icon-arrow-undo-16"></i> Undo
                        </button>
                        <button class="btn btn--secondary btn--dku-icon" [disabled]="!(canRedo$ | async)" (click)="redo()">
                            <i class="dku-icon-arrow-redo-16"></i> Redo
                        </button>
                    </div>
                </div>
                <div *ngIf="(hasNoNextRecord$ | async)" class="mtop16 mbot0 alert alert-info mx-center-children">
                    <i class="dku-icon-info-circle-fill-16 mright4"></i> No more images left to annotate.
                </div>
            </ng-container>
        </div>
        <div class="image-object-wrapper">
            <div class="labeling__right-panel-section oa mbot0" [class.labeling__right-panel-section--half]="task.type !== LabelingTaskType.IMAGE_CLASSIFICATION">
                <category-selector class="category-selector" [label]="currentLabel$ | async" (labelChange)="labelTrigger$.next($event)" (categoryChange)="selectedCategory$.next($event)"></category-selector>
            </div>
            <annotate-annotation-list
                *ngIf="task.type === LabelingTaskType.OBJECT_DETECTION"
                class="labeling__annotation-list"
                [skipped]="(hasNoNextRecord$ | async) === false && (hasBeenSkipped$ | async) ?? false"
                [label]="currentLabel$ | async"
                (labelChange)="labelTrigger$.next($event)">
            </annotate-annotation-list>
            <named-entity-annotate-annotation-list
                *ngIf="task.type === LabelingTaskType.NAMED_ENTITY_EXTRACTION"
                class="labeling__annotation-list"
                [skipped]="(hasNoNextRecord$ | async) === false && (hasBeenSkipped$ | async) ?? false"
                [label]="(currentLabel$ | async) | as: NamedEntityExtractionUILabel"
                (labelChange)="labelTrigger$.next($event)">
            </named-entity-annotate-annotation-list>
        </div>
    </div>
</div>
