import type {_FeaturePreprocessingParams} from './feature-preprocessing-params';

/**
 * Generated from com.dataiku.dip.analysis.model.preprocessing.NumFeaturePreprocessingParams
 */
export interface NumFeaturePreprocessingParams extends _FeaturePreprocessingParams {
    binarize_constant_threshold: number;
    binarize_threshold_mode: NumFeaturePreprocessingParams.BinarizeThresholdMode;
    datetime_cyclical_periods: any /* TODO: Missing translation of com.dataiku.scoring.pipelines.DatetimeCyclicalEncoder$Period */[];
    generate_derivative: boolean;
    impute_constant_value: number;
    keep_regular: boolean;
    missing_handling: NumFeaturePreprocessingParams.MissingHandlingMethod;
    missing_impute_with: NumFeaturePreprocessingParams.NumericalImputationMethod;
    numerical_handling: NumFeaturePreprocessingParams.NumericalHandlingMethod;
    quantile_bin_nb_bins: number;
    rescaling: NumFeaturePreprocessingParams.RescalingMethod;
}

export namespace NumFeaturePreprocessingParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.NumFeaturePreprocessingParams$BinarizeThresholdMode
     */
    export enum BinarizeThresholdMode {
        MEAN = 'MEAN',
        MEDIAN = 'MEDIAN',
        CONSTANT = 'CONSTANT'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.NumFeaturePreprocessingParams$NumericalHandlingMethod
     */
    export enum NumericalHandlingMethod {
        REGULAR = 'REGULAR',
        FLAG_PRESENCE = 'FLAG_PRESENCE',
        BINARIZE = 'BINARIZE',
        QUANTILE_BIN = 'QUANTILE_BIN',
        DATETIME_CYCLICAL = 'DATETIME_CYCLICAL',
        CUSTOM = 'CUSTOM'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.NumFeaturePreprocessingParams$RescalingMethod
     */
    export enum RescalingMethod {
        NONE = 'NONE',
        AVGSTD = 'AVGSTD',
        MINMAX = 'MINMAX'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.NumFeaturePreprocessingParams$NumericalImputationMethod
     */
    export enum NumericalImputationMethod {
        MEAN = 'MEAN',
        MEDIAN = 'MEDIAN',
        CONSTANT = 'CONSTANT'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.NumFeaturePreprocessingParams$MissingHandlingMethod
     */
    export enum MissingHandlingMethod {
        IMPUTE = 'IMPUTE',
        DROP_ROW = 'DROP_ROW'
    }
}