import type {CustomEvaluationMetric} from './../core/custom-evaluation-metric';

/**
 * Generated from com.dataiku.dip.analysis.model.prediction.MetricParams
 */
export interface MetricParams {
    costMatrixWeights: MetricParams.CostMatrixWeights;
    customEvaluationMetricCode: string;
    customEvaluationMetricGIB: boolean;
    customEvaluationMetricNeedsProba: boolean;
    customMetrics: CustomEvaluationMetric[];
    evaluationMetric: MetricParams.EvaluationMetric;
    liftPoint: number;
    netUpliftPoint: number;
    thresholdOptimizationMetric: MetricParams.ThresholdOptimizationMetric;
}

export namespace MetricParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.MetricParams$CostMatrixWeights
     */
    export interface CostMatrixWeights {
        fnGain: number;
        fpGain: number;
        tnGain: number;
        tpGain: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.MetricParams$ThresholdOptimizationMetric
     */
    export enum ThresholdOptimizationMetric {
        ACCURACY = 'ACCURACY',
        F1 = 'F1',
        COST_MATRIX = 'COST_MATRIX'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.MetricParams$EvaluationMetric
     */
    export enum EvaluationMetric {
        ACCURACY = 'ACCURACY',
        RECALL = 'RECALL',
        PRECISION = 'PRECISION',
        F1 = 'F1',
        COST_MATRIX = 'COST_MATRIX',
        CUMULATIVE_LIFT = 'CUMULATIVE_LIFT',
        LOG_LOSS = 'LOG_LOSS',
        ROC_AUC = 'ROC_AUC',
        CALIBRATION_LOSS = 'CALIBRATION_LOSS',
        AVERAGE_PRECISION = 'AVERAGE_PRECISION',
        EVS = 'EVS',
        MAPE = 'MAPE',
        MAE = 'MAE',
        MSE = 'MSE',
        RMSE = 'RMSE',
        RMSLE = 'RMSLE',
        R2 = 'R2',
        MASE = 'MASE',
        MEAN_ABSOLUTE_QUANTILE_LOSS = 'MEAN_ABSOLUTE_QUANTILE_LOSS',
        MEAN_WEIGHTED_QUANTILE_LOSS = 'MEAN_WEIGHTED_QUANTILE_LOSS',
        MSIS = 'MSIS',
        ND = 'ND',
        SMAPE = 'SMAPE',
        AUUC = 'AUUC',
        QINI = 'QINI',
        NET_UPLIFT = 'NET_UPLIFT',
        CUSTOM = 'CUSTOM'
    }
}