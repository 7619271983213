<div class="vertical-flex">
    <div class="top-level-tabs">
        <div class="horizontal-flex row-fluid object-nav">
            <div class="flex oh object-breadcrumb">
                <a class="item" ui-sref route=".items">
                    <i class="icon dku-icon-feature-store-label-24 universe-background feature-store"></i>
                    <span class="title ellipsis">Feature Store</span>
                </a>
            </div>
            <div class="otherLinks" *ngIf="projectKey">
                <a class="btn btn--secondary btn--icon" [href]="currentProjectLink()" alt="Back to project" matTooltip="Back to project" [attr.data-qa-smid]="'feature-store-back-to-project'">
                    Back to project
                </a>
            </div>
        </div>
    </div>
    <div class="list-page-4 flex dss-page catalog-page feature-store">
        <api-error-alert
            *ngIf="$rootScope.fatalAPIError || (featureStoreService.error$ | async) as error"
            [apiError]="error"
            [closable]="true"
            (resetError)="featureStoreService.resetError()"
        ></api-error-alert>

        <div class="h100 vertical-flex">
            <search-bar
                [searchQuery]=searchQuery
                (newQueryEvent)=newQuery($event)
                class="summary-row noflex"></search-bar>

            <div class="flex">
                <div class="fh">
                    <div class="row-fluid horizontal-flex">
                        <div class="span9 h100 no-flex">
                            <div block-api-error></div>
                            <div class="main-list-zone h100">
                                <div class="h100" style="position: relative">
                                    <facet-list
                                        [aggregationResults]=aggregationResults
                                        [users]=users
                                        [projectNames]=projectNames
                                        (newFacetSelection)=newFacetSelection($event)
                                        class="left-sidebar span3 scroll-zone offset0 fh"></facet-list>
                                    <ng-template [ngIf]="viewByFeatureGroup()" [ngIfElse]="viewByFeature">
                                        <feature-group-search-results
                                            [totalItems]=totalItems
                                            [items]=featureGroups
                                            [selectedItem]=selectedFeatureGroup
                                            [searchQuery]=searchQuery
                                            [facetMap]=activeFacets
                                            [projectKey]=projectKey
                                            [datasetsInCurrentProject]=datasetsInCurrentProject
                                            (resetFiltersEvent)=resetFiltersRequest()
                                            (featureGroupSelectedEvent)=newFeatureGroupSelected($event)
                                            class="scroll-zone span9 offset0 fh"
                                            style="margin-left: 25%"></feature-group-search-results>
                                    </ng-template>
                                    <ng-template #viewByFeature>
                                        <feature-search-results
                                            [totalItems]=totalItems
                                            [items]=features
                                            [selectedItem]=selectedFeature
                                            [searchQuery]=searchQuery
                                            [facetMap]=activeFacets
                                            [projectKey]=projectKey
                                            [datasetsInCurrentProject]=datasetsInCurrentProject
                                            (resetFiltersEvent)=resetFiltersRequest()
                                            (featureSelectedEvent)=newFeatureSelected($event)
                                            class="scroll-zone span9 offset0 fh"
                                            style="margin-left: 25%"></feature-search-results>
                                    </ng-template>
                                </div>
                            </div>
                        </div>

                        <div class="span3 right-sidebar object-right-column-summary h100 flex" style="margin-left: 0">
                            <div class="catalog-right-column h100">
                                <div class="col-md-3 h100" [attr.data-qa-smid]="'feature-store-details'">
                                    <div class="h100 vertical-flex" *ngIf="!details" [attr.data-qa-smid]="'feature-store-no-item-selected'">
                                        <h4 class="empty-right-column small-lr-padding" >Click an item to view details</h4>
                                    </div>
                                    <div class="h100 vertical-flex" *ngIf="details">
                                        <div class="column-header noflex">
                                            <ng-template [ngIf]="selectedFeature" [ngIfElse]="selectedFeatureGroupHeader">
                                                <feature-header
                                                    class="item-header horizontal-flex"
                                                    [selectedFeature]=selectedFeature
                                                    [featureHighlight]=featureHighlight
                                                ></feature-header>
                                            </ng-template>
                                            <ng-template #selectedFeatureGroupHeader>
                                                <feature-group-header
                                                    class="item-header horizontal-flex"
                                                    [projectKey]=projectKey
                                                    [details]=details
                                                    [highlight]=featureGroupHighlight
                                                    [datasetsInCurrentProject]=datasetsInCurrentProject
                                                    (removeFeatureGroupFromFeatureStore)=removeFromFeatureStore()
                                                    (useFeatureGroupInProject)=useInProject()
                                                ></feature-group-header>
                                            </ng-template>
                                        </div>
                                        <div class="h100 flex" style="overflow: hidden;">
                                            <div class="object-details h100" style="padding-top: 15px; overflow-y: auto;">
                                                <ng-template [ngIf]="selectedFeature" [ngIfElse]="selectedFeatureGroupDetails">
                                                    <feature-details
                                                        [projectKey]=projectKey
                                                        [details]=details
                                                        [selectedFeature]=selectedFeature
                                                        [highlight]=featureHighlight
                                                        [users]=users
                                                        [datasetsInCurrentProject]=datasetsInCurrentProject
                                                        (useFeatureGroupInProject)=useInProject()
                                                    ></feature-details>
                                                </ng-template>
                                                <ng-template #selectedFeatureGroupDetails>
                                                    <feature-group-details
                                                        [projectKey]=projectKey
                                                        [details]=details
                                                        [selectedFeature]=selectedFeature
                                                        [highlight]=featureGroupHighlight
                                                        [featureHighlight]=featureHighlight
                                                        [users]=users
                                                        [datasetsInCurrentProject]=datasetsInCurrentProject
                                                        ></feature-group-details>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
