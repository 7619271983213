import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ViewContainerRef } from '@angular/core';
import { UnivariateSummaryStatsCard, BivariateSummaryStatsCard, Card, SummaryCardBuilder, isBivariateSummaryStatsCard, Variable } from 'src/generated-sources';
import { StatKey } from '@features/eda/worksheet/cards/config/generic-summary-stats-card-config/generic-summary-stats-card-config.component';
import { CardAction, CardActionType } from '@features/eda/worksheet/cards/events';
import { createChi2IndTestCard, createKSTest2SampCard, createMoodTestNSampCard, createOneWayAnovaCard } from '@features/eda/worksheet/cards/help/card-creation-templates';
import { ModalShape, ModalsService } from '@shared/modals/modals.service';
import { EditCardModalComponent } from '@features/eda/worksheet/card-wizard/edit-card-modal/edit-card-modal.component';

type SupportedParams = UnivariateSummaryStatsCard | BivariateSummaryStatsCard;
type SupportedResults = UnivariateSummaryStatsCard.UnivariateSummaryStatsCardResult
    | BivariateSummaryStatsCard.BivariateSummaryStatsCardResult;

function getCardTemplateFromStats(
    stat: SummaryCardBuilder.ComputedStat,
    sourceCard: SupportedParams
): Card | null {
    if (isBivariateSummaryStatsCard(sourceCard)) {
        const { xColumn, yColumn, confidenceLevel } = sourceCard;
        const catColumn = xColumn.type === Variable.Type.CATEGORICAL ? xColumn : yColumn;
        const numColumn = xColumn.type === Variable.Type.CONTINUOUS ? xColumn: yColumn;

        switch(stat.flagName as StatKey) {
            case 'chi2':
                return createChi2IndTestCard(xColumn, yColumn, confidenceLevel);
            case 'oneWayAnova':
                return createOneWayAnovaCard(numColumn, catColumn, confidenceLevel);
            case 'nSampleMood':
                return createMoodTestNSampCard(numColumn, catColumn, confidenceLevel);
            case 'kolmogorovSmirnov':
                return createKSTest2SampCard(numColumn, catColumn, confidenceLevel);
            default:
                return null;
        }
    }

    return null;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'generic-summary-stats-card-body',
    templateUrl: './generic-summary-stats-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/card-spinner.less',
        '../../../../shared-styles/stats-table.less',
        '../../../../shared-styles/test-conclusion.less',
        './generic-summary-stats-card-body.component.less'
    ]
})
export class GenericSummaryStatsCardBodyComponent {
    @Input() results: SupportedResults;
    @Input() params: SupportedParams;
    @Input() readOnly: boolean;
    @Input() extendedActions: boolean;
    @Output() action = new EventEmitter<CardAction>();

    constructor(
        private modalsService: ModalsService,
        private viewContainerRef: ViewContainerRef
    ) {
    }

    trackByIndex(index: number) {
        return index;
    }

    canCreateCardFromTemplate(stat: SummaryCardBuilder.ComputedStat): boolean {
        if (this.readOnly || !this.extendedActions) {
            return false;
        }

        // The user can create a card as long as the template exists.
        return getCardTemplateFromStats(stat, this.params) != null;
    }

    createCardFromTemplate(stat: SummaryCardBuilder.ComputedStat) {
        if (!this.canCreateCardFromTemplate(stat)) {
            return;
        }

        const cardTemplate = getCardTemplateFromStats(stat, this.params);

        this.modalsService.open(
            EditCardModalComponent,
            { params: cardTemplate! },
            ModalShape.NONE,
            this.viewContainerRef
        ).then(({ card }) => {
            this.action.emit({
                type: CardActionType.ADD,
                card,
            });
        }).catch(() => {});
    }
}
