
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'image-feed',
    templateUrl: './image-feed.component.html',
    styleUrls: ['./image-feed.component.less']
})
export class ImageFeedComponent {
    @Input() imageHeight: number;
    @Input() itemsPerRow: number;
    @Input() selectedPath?: string | null;
    @Input() selectedIndex?: number | null;

    @Output() imageClick = new EventEmitter<{ imageId?: number, itemPath?: string, item?: any }>();

    loadingStatus$ = new BehaviorSubject<boolean>(false);

    emitItemClickEvent(item: any) {
        this.imageClick.emit({ itemPath: item.itemId, imageId: item.itemIndex, item })
    }
}
