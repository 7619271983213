import type {AbstractCardContainer, _AbstractCardContainer} from './common/abstract-card-container';
import type {CardResult} from './card-result';
import type {Card} from './card';
import type {Filter} from './../../compute/filtering/filter';

export namespace WorksheetRootCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.WorksheetRootCard$WorksheetRootCardResult
     */
    export interface WorksheetRootCardResult extends AbstractCardContainer._AbstractCardContainerResult {
        // PolyJson type
        type: 'worksheet_root';
    }

    export namespace WorksheetRootCardResult {
        export const type = 'worksheet_root';
    }

    /** @deprecated Use CardResult.isWorksheetRootCardResult() */ export function isWorksheetRootCardResult(toTest: CardResult): toTest is WorksheetRootCardResult { return WorksheetRootCardResult.type === toTest['type']; }
}

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.WorksheetRootCard
 */
export interface WorksheetRootCard extends _AbstractCardContainer {
    confidenceLevel: number;
    highlightFilter?: Filter | null;
    showConfidenceInterval: boolean;

    // PolyJson type
    type: 'worksheet_root';
}

export namespace WorksheetRootCard {
    export const type = 'worksheet_root';
}

/** @deprecated Use Card.isWorksheetRootCard() */ export function isWorksheetRootCard(toTest: Card): toTest is WorksheetRootCard { return WorksheetRootCard.type === toTest['type']; }