import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DeephubInteractiveScoringDataFetcherService } from '@features/deephub/computer-vision/report/interactive-scoring/deephub-report-interactive-scoring-data-fetcher.service';
import { InteractiveScoringService } from '@features/deephub/computer-vision/report/interactive-scoring/interactive-scoring-data.service';
import { CarouselService } from '@shared/services/carousel.service';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { ImagesDataFetcherService } from '@shared/services/item-feed/image-feed/images-data-fetcher.service';
import { PainterService } from '@shared/services/item-feed/image-feed/painter.service';
import { ItemsDataFetcherService } from '@shared/services/item-feed/items-data-fetcher.service';
import { DeepHubPredictionModelDetails } from 'src/generated-sources';
import { DeephubImageClassificationReportPainterService } from '../services/deephub-image-classification-report-painter.service';
import { DeephubImageClassificationInteractiveScoringCellData, ImageClassificationInteractiveScoringService } from './image-classification-interactive-scoring-data.service';

@Component({
    selector: 'deephub-image-classification-report-interactive-scoring',
    templateUrl: './deephub-image-classification-report-interactive-scoring.component.html',
    styleUrls: ['./deephub-image-classification-report-interactive-scoring.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        CarouselService,
        ColorMapContextService,
        ImageClassificationInteractiveScoringService,
        { provide: InteractiveScoringService, useExisting: ImageClassificationInteractiveScoringService }, 
        DeephubImageClassificationReportPainterService,
        { provide: PainterService, useExisting: DeephubImageClassificationReportPainterService }, 
        DeephubInteractiveScoringDataFetcherService,
        { provide: ImagesDataFetcherService, useExisting: DeephubInteractiveScoringDataFetcherService },
        { provide: ItemsDataFetcherService, useExisting: DeephubInteractiveScoringDataFetcherService }
    ]
})
export class DeephubImageClassificationReportInteractiveScoringComponent {
    @Input() modelData: DeepHubPredictionModelDetails;
    DeephubImageClassificationInteractiveScoringCellData = DeephubImageClassificationInteractiveScoringCellData;

    constructor() { }
}
