import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.Sem
 */
export interface Sem extends Computation._UnivariateComputation {
    // PolyJson type
    type: 'sem';
}

export namespace Sem {
    export const type = 'sem';
}

/** @deprecated Use Computation.isSem() */ export function isSem(toTest: Computation): toTest is Sem { return Sem.type === toTest['type']; }

export namespace Sem {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.Sem$SemResult
     */
    export interface SemResult extends _AvailableResult {
        value: number;

        // PolyJson type
        type: 'sem';
    }

    export namespace SemResult {
        export const type = 'sem';
    }

    /** @deprecated Use ComputationResult.isSemResult() */ export function isSemResult(toTest: ComputationResult): toTest is SemResult { return SemResult.type === toTest['type']; }
}