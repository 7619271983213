import { CompactedArray } from 'src/generated-sources';

export async function decompactArray(compacted: CompactedArray) {
    return new Promise<Float64Array>((resolve, reject) => {
        const image = new Image();
        image.onerror = () => {
            // Error while loading image
            reject();
        };
        image.onload = () => {
            // Image loaded successfully
            const w = image.width;
            const h = image.height;

            // Draw the image into a canvas
            const canvas = document.createElement('canvas');
            canvas.width = w;
            canvas.height = h;
            const ctx = canvas.getContext('2d')!;
            ctx.drawImage(image, 0, 0);

            // Retrieve pixel data from canvas
            const imageData = ctx.getImageData(0, 0, w, h);
            const dataView = new DataView(imageData.data.buffer);

            // Rescale the values
            const scale = (compacted.vmax - compacted.vmin) / (Math.pow(2, 24) - 1);
            const offset = compacted.vmin;
            const output = new Float64Array(w * h);
            for (let i = 0; i < w * h; i++) {
                dataView.setUint8(i * 4 + 3, 0); // Clean alpha
                output[i] = offset + dataView.getUint32(i * 4, true) * scale;
            }

            resolve(output);
        };

        image.src = 'data:image/png;base64,' + compacted.data;
    });
}
