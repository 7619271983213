import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DkuMatTooltipModule } from '@shared/components/dku-mat-tooltip/dku-mat-tooltip.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { DkuSliderModule } from '@migration/modules';
import { ChartColorPickerModule } from '../../chart-color-picker';
import { ReactiveInputModule } from '../../reactive-input';
import { ReferenceLinesFormComponent } from './reference-lines-form.component';
import { ReferenceLineFormComponent } from './reference-line-form';
import { AutofocusDirective } from './autofocus.directive';
import { TextFormattingFormModule } from '@shared/modules';

@NgModule({
    declarations: [
        ReferenceLinesFormComponent,
        ReferenceLineFormComponent,
        AutofocusDirective
    ],
    imports: [
        CommonModule,
        DkuMatTooltipModule,
        ReactiveFormsModule,
        ReactiveInputModule,
        NgSelectModule,
        ChartColorPickerModule,
        DkuSliderModule,
        TextFormattingFormModule
    ],
    exports: [
        ReferenceLinesFormComponent
    ]
})
export class ReferenceLinesFormModule { }
