import { Component, OnInit, ChangeDetectionStrategy, Output, Input, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import _ from 'lodash';
import { Variable } from 'src/generated-sources';
import { CardWizardVariable } from '@features/eda/card-models';
import { CdkDragDrop, CdkDragStart } from '@angular/cdk/drag-drop';

@Component({
    selector: 'list-box-form-control',
    templateUrl: './list-box-form-control.component.html',
    styleUrls: [
        '../../../shared-styles/card-wizard.less',
        '../../../shared-styles/list-box.less',
        './list-box-form-control.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ListBoxFormControlComponent),
            multi: true
        }
    ]
})
export class ListBoxFormControlComponent implements OnInit, ControlValueAccessor {
    @Input() height?: number;
    @Input() dragDropable = false;
    @Input() sortDraggable = false;
    @Input() multiselectable = false;
    @Input() hideItemsOnDrop = false;
    @Input() placeHolderText: string;
    @Output() remove = new EventEmitter<Variable>();
    @Output() select = new EventEmitter<CardWizardVariable[]>();
    @Output() dropped = new EventEmitter<CdkDragDrop<CardWizardVariable[]>>();
    list: CardWizardVariable[] = [];
    // whether we pass array or a single object
    isArray = true;
    Variable = Variable;
    lastSelectedItem: CardWizardVariable | null;

    propagateChange = (_: any) => {};

    constructor() { }

    ngOnInit() {
    }

    removeItem(item: Variable) {
        this.list = this.list.filter(i => i.name !== item.name);
        this.propagateChange(this.isArray ? this.list : (this.list[0]) || {});
    }

    writeValue(value: any) {
        this.list = _.cloneDeep(Array.isArray(value) ? value : Object.keys(value || {}).length ? [value] : []);
    }

    registerOnChange(fn: (_: any) => {}) {
        this.propagateChange = fn;
    }

    registerOnTouched() {
    }

    // whether variable is selected or not
    selectItem(event: MouseEvent, item: CardWizardVariable) {
        if (!this.multiselectable) {
            return;
        }

        const previousItem = this.lastSelectedItem;
        item.selected = !item.selected;
        this.lastSelectedItem = item;

        // if shift was pressed, select all items in between previous item
        // and the item we just clicked on
        if (event.shiftKey) {
            let prevIndex = this.list.indexOf(previousItem!);
            prevIndex = prevIndex >= 0 ? prevIndex : 0;
            const currIndex = this.list.indexOf(item);
            const startIndex = prevIndex < currIndex ? prevIndex : currIndex;
            const endIndex = currIndex >= prevIndex ? currIndex : prevIndex;

            for (let i = startIndex; i <= endIndex; i++) {
                this.list[i].selected = item.selected; // based on select state of clicked item
            }
        }

        // emit all selected items
        this.select.emit(this.list.filter(v => v.selected));
    }

    drop(event: CdkDragDrop<CardWizardVariable[]>): void {
        this.dropped.emit(event);
    }

    dragStarted(ev: CdkDragStart): void {
        const selectedItems = this.list.filter(v => v.selected);
        const items = (selectedItems && selectedItems.length) ? selectedItems : [ev.source.data];
        ev.source.data = items;
    }
}
