import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { CategoricalHistogramCard } from 'src/generated-sources';
import type { EChartsOption, SeriesOption } from 'echarts';
import { CardAction, CardActionType } from '@features/eda/worksheet/cards/events';
import { CardBodyRenderingMode } from '@features/eda/worksheet/cards/body/rendering-mode';
import { ColorsService } from '@shared/graphics/colors.service';
import { encodeHTML } from 'entities';
import { filterName } from '@features/eda/pipes/filter-name.pipe';
import { rgb } from 'd3-color';
import { PatternsService } from '@shared/graphics/patterns.service';


@Component({
    selector: 'categorical-histogram-card-body',
    templateUrl: './categorical-histogram-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/chart.less',
        './categorical-histogram-card-body.component.less'
    ],
})
export class CategoricalHistogramCardBodyComponent implements OnChanges {
    @Input() results: CategoricalHistogramCard.CategoricalHistogramCardResult;
    @Input() params: CategoricalHistogramCard;
    @Input() hasFixedHeight: boolean;
    @Input() renderingMode: CardBodyRenderingMode;
    @Output() action = new EventEmitter<CardAction>();

    chartOptions: EChartsOption;

    constructor(
        private colorsService: ColorsService,
        private patternsService: PatternsService) { }

    ngOnChanges() {
        this.buildChart();
    }

    get compactLoading(): boolean {
        return this.isMiniature();
    }

    private isMiniature() {
        return this.renderingMode === CardBodyRenderingMode.MINIATURE_CARD;
    }

    chartClicked(event: any) {
        if (event.componentType === 'series'
            && event.componentSubType === 'bar') {
            const index: number = event.dataIndex;
            const filter = this.results.histogram.filters[index];
            this.action.emit({
                type: CardActionType.HIGHLIGHT,
                filter: { ...filter, name: this.params.column.name + ': ' + filterName(filter) }
            });
        }
    }

    buildChart() {
        if(this.results.histogram.totalCount === 0) {
            // When there is no data, create a fake chart that looks like others
            this.chartOptions = {
                grid: { left: 0, top: 10, right: 0, bottom: 0, containLabel: true },
                xAxis: {
                    type: 'category',
                    data: [' '], // Space is needed otherwise ECharts thinks there is no label, which breaks bottom alignment with other histograms
                    axisTick: { show: false },
                    axisLine: { show: true }
                },
                yAxis: {
                    type: 'value',
                    min: 0,
                    max: 1,
                    axisLine: { show: false },
                    axisTick: { show: false },
                    axisLabel: { show: false }
                },
                animation: false
            }
            return;
        }
        const labels = this.results.histogram.filters.map(filter => filterName(filter));
        const data = this.results.histogram.counts;
        const highlightData = this.results.histogram.highlightedCounts;
        const series: SeriesOption[] = [];

        if (highlightData) {
            series.push({
                type: 'bar',
                stack: 'stack',
                data: highlightData.map((val, i) => {
                    const itemColor = this.colorsService.getColorForVariable(labels[i]);
                    const borderColor = rgb(itemColor).darker(2).toString();
                    const stripedPattern = this.patternsService.getStripePattern(borderColor, itemColor);
                    return {
                        value: val,
                        itemStyle: {
                            color: stripedPattern as any, // Undocumented officially
                            borderColor: borderColor,
                            borderWidth: 1
                        }
                    };
                })
            });
        }

        series.push({
            type: 'bar',
            stack: 'stack',
            data: data.map((val, i) => {
                const color = this.colorsService.getColorForVariable(labels[i]);
                if (highlightData) {
                    val = val - highlightData[i];
                }
                return {
                    value: val,
                    emphasis: { itemStyle: { color } },
                    itemStyle: { color }
                };
            })
        });

        this.chartOptions = {
            grid: {
                left: 10,
                top: 10,
                right: 10,
                bottom: 0,
                containLabel: true,
            },
            tooltip: {
                formatter: (value: any) => {
                    const dataIndex = value.dataIndex;
                    let tooltip = encodeHTML(this.params.column.name)
                        + ': <b>'
                        + encodeHTML(labels[dataIndex])
                        + '</b>';
                    tooltip += '<br>Count: <b>' + encodeHTML('' + data[dataIndex]) + '</b>';
                    if (this.results.histogram.highlightedCounts) {
                        tooltip += '<br>Selected count: <b>' + encodeHTML('' + highlightData![dataIndex]) + '</b>';
                    }

                    return tooltip;
                },
                confine: true,
                trigger: 'item',
                axisPointer: { type: 'none' }
            },
            xAxis: {
                type: 'category',
                data: labels,
                axisLabel: { color: '#999999', formatter: this.isMiniature() ? ()=>'' : undefined},
                axisTick: { show: true },
                axisLine: { show: true }
            },
            yAxis: {
                type: 'value',
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: { color: '#999999', formatter: this.isMiniature() ? ()=>'' : undefined }
            },
            series,
            animation: false
        };
    }

}
