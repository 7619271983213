import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {Distribution} from './../../distributions/distribution';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.TestDistribution
 */
export interface TestDistribution extends Computation._UnivariateComputation {
    distribution: Distribution.FittedDistribution;

    // PolyJson type
    type: 'test_distribution';
}

export namespace TestDistribution {
    export const type = 'test_distribution';
}

/** @deprecated Use Computation.isTestDistribution() */ export function isTestDistribution(toTest: Computation): toTest is TestDistribution { return TestDistribution.type === toTest['type']; }

export namespace TestDistribution {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$TestDistributionResult
     */
    export interface TestDistributionResult extends _AvailableResult {
        plot: TestDistribution.PlotData;
        test: TestDistribution.StatTests;

        // PolyJson type
        type: 'test_distribution';
    }

    export namespace TestDistributionResult {
        export const type = 'test_distribution';
    }

    /** @deprecated Use ComputationResult.isTestDistributionResult() */ export function isTestDistributionResult(toTest: ComputationResult): toTest is TestDistributionResult { return TestDistributionResult.type === toTest['type']; }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$ProbabilityMassFunction
     */
    export interface ProbabilityMassFunction {
        probs: number[];
        xvals: number[];
    }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$ProbabilityDensityFunction
     */
    export interface ProbabilityDensityFunction {
        probs: number[];
        xvals: number[];
    }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$LikelihoodTestsResult
     */
    export interface LikelihoodTestsResult {
        aic?: number | null;
        aicc?: number | null;
        bic?: number | null;
        ll?: number | null;
    }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$PlotData
     */
    export interface PlotData {
        pdf?: TestDistribution.ProbabilityDensityFunction | null;
        pmf?: TestDistribution.ProbabilityMassFunction | null;
        qq: TestDistribution.QQPlotData;
    }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$KolmogorovSmirnovTestResult
     */
    export interface KolmogorovSmirnovTestResult {
        pvalue: number;
        statistic: number;
    }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$StatTests
     */
    export interface StatTests {
        ks?: TestDistribution.KolmogorovSmirnovTestResult | null;
        ll: TestDistribution.LikelihoodTestsResult;
    }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$QQPlotData
     */
    export interface QQPlotData {
        empirical: number[];
        percentile: number[];
        theoretical: number[];
    }
}