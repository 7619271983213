import { Injectable } from "@angular/core";
import { _VStackRecipePayloadParams } from 'generated-sources';

@Injectable({
    providedIn: 'root',
})
export class StackRecipeNames {
    /**
     * Gets the display name for the schema merging mode
     * Beware: this method is lying about its type as it declares a _VStackRecipePayloadParams.SchemaMergeMode as input, but in fact expects 'UNION', 'FROM_DATASET'...
     * This is only to mirror the lie from the definition of _VStackRecipePayloadParams.SchemaMergeMode, made by j2TS
     * This is also the reason for the bizarre implementation.
     * see https://app.shortcut.com/dataiku/story/120922/fix-j2ts-enum-mapping
     */
    public modeDisplayName(mode: _VStackRecipePayloadParams.SchemaMergeMode) {
        return (_VStackRecipePayloadParams.SchemaMergeMode as unknown as Record<_VStackRecipePayloadParams.SchemaMergeMode, string>)[mode];
    }

    /**
     * Tests if the mode is 'From dataset schema'
     * Beware: this method is lying about its type as it declares a _VStackRecipePayloadParams.SchemaMergeMode as input, but in fact expects 'UNION', 'FROM_DATASET'...
     * This is only to mirror the lie from the definition of _VStackRecipePayloadParams.SchemaMergeMode, made by j2TS
     * This is also the reason for the bizarre implementation.
     * see https://app.shortcut.com/dataiku/story/120922/fix-j2ts-enum-mapping
     */
    isFromDataset(mode: _VStackRecipePayloadParams.SchemaMergeMode) {
        const theRealMode = mode as string; // actually 'UNION', 'FROM_DATASET'...
        return theRealMode === 'FROM_DATASET';
    }
}
