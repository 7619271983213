import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'dss-empty-data-collection-placeholder',
    templateUrl: './empty-data-collection-placeholder.component.html',
    styleUrls: ['./empty-data-collection-placeholder.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyDataCollectionPlaceholderComponent {
    @Input() canPublishToDataCollection: boolean = false;

    @Output() addObjectsToCollection = new EventEmitter<void>();

    constructor() {}
}
