import { Pipe, PipeTransform } from "@angular/core";
import { rgbToString } from "@utils/rgb-to-string";

@Pipe({
    name: 'rgbToString'
})
export class RgbToStringPipe implements PipeTransform {

    transform(rgb: number[], opacity = 1): string {
        return rgbToString(rgb, opacity);
    }
}