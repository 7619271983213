<object-nav label="Data Catalog" [route]="route('.home')" icon="dku-icon-book-24 universe-background catalog">
    <ng-container object-nav-tabs>
        <object-nav-tab label="Home" [route]="route('.home')"></object-nav-tab>
        <object-nav-tab label="Data Collections" [route]="route('.datacollections.home')" [active]="route('.datacollections.**')"></object-nav-tab>
        <object-nav-tab
            [label]="'Datasets' + ((hasIndexedConnections$ | async) !== false ? ' & Indexed Tables' : '')"
            [route]="route('.datasources')"
            [routeParams]="{selectedTab: 'all'}"
        ></object-nav-tab>
        <object-nav-tab label="Connection Explorer"
            [route]="route('.connectionexplorer')"
            [routeParams]="{connectionName: undefined, schemaName: undefined, catalogName: undefined}"
        ></object-nav-tab>
    </ng-container>
</object-nav>
