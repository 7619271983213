import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { InterestsService } from '@shared/services';

@Component({
    selector: 'right-panel-action-watch-btn',
    templateUrl: './right-panel-action-watch-btn.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightPanelActionWatchBtnComponent {
    @Input() watched!: boolean;
    @Input() disabled = false;
    @Input() disabledTooltip = '';
    @Output() watchedChange = new EventEmitter<boolean>(); // emits the new value

    //just to make is visible from template;
    INTEREST_WORDING = this.interestService.WORDING;

    constructor(
        private interestService: InterestsService
    ) { }

}
