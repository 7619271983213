<button
    class="btn header-button trigger subnav-page-header-name has-caret"
    [dkuPopoverTrigger]="popover"
    [wt1Click]="'worksheets_confidence-level'"
>
    Confidence level:
    <span class="confidence-value">{{ confidenceLevel | percent: '1.0-3' }}</span>
    <b class="caret"></b>
</button>
<dku-popover #popover>
    <div class="control-group confidence-level">
        <form
            [formGroup]="form"
            (ngSubmit)="save()"
            [class.form--disabled]="!canSave"
        >
            <fieldset [disabled]="!canSave">
                <p>
                    <i>
                        Used to determine the confidence level of tests.
                    </i>
                </p>
                <label>
                    Confidence level
                    <input
                        type="number"
                        min="0.5"
                        max="0.99999"
                        step="0.00001"
                        formControlName="confidenceLevel"
                    >
                </label>
                <label>
                    <input
                        type="checkbox"
                        class="form__checkbox"
                        formControlName="showConfidenceInterval"
                    >
                    Show confidence intervals
                </label>
                <p>
                    <i>
                        Confidence intervals are displayed in quantile tables and summary stats
                    </i>
                </p>
            </fieldset>
        </form>
    </div>
    <div #closeButton *ngIf="canSave">
        <button
            class="btn btn--primary pull-right"
            [disabled]="form.invalid"
            (click)="save()"
        >
            Save
        </button>
    </div>
</dku-popover>
