import { Component, ChangeDetectionStrategy } from '@angular/core';
import { APIError } from '@core/dataiku-api/api-error';
import { Workspace } from '@model-main/workspaces/workspace';
import { PICKER_STOCK_COLORS } from '@shared/modules/stock-color-picker/stock-color-picker-colors.constant';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { EMPTY_WORKSPACE, WorkspaceSecurityService, WorkspacesService } from '../../shared';

@Component({
    selector: 'workspace-home',
    templateUrl: './workspace-home.component.html',
    styleUrls: ['./workspace-home.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceHomeComponent {
    private FIRST_WORKSPACE_MESSAGE = 'Let\'s create your first workspace! ';
    private FORBIDDEN_WORKSPACE_MESSAGE = 'You haven\'t been added to a Workspace yet';
    private DEFAULT_WORKSPACE: Workspace = {
        ...EMPTY_WORKSPACE,
        color: PICKER_STOCK_COLORS[Math.floor(Math.random() * PICKER_STOCK_COLORS.length)]
    };

    canCreateWorkspaces$ = new BehaviorSubject<boolean>(false);
    workspaceToCreate$ = new BehaviorSubject<Workspace>(this.DEFAULT_WORKSPACE);
    error$ = new BehaviorSubject<APIError | undefined>(undefined);

    isValid$: Observable<boolean>;
    mainMessage$: Observable<string>;

    constructor(
        private workspaceSecurityService: WorkspaceSecurityService,
        private workspacesService: WorkspacesService
    ) {
        this.mainMessage$ = this.canCreateWorkspaces$.pipe(
            map(canCreateWorkspaces => canCreateWorkspaces ? this.FIRST_WORKSPACE_MESSAGE : this.FORBIDDEN_WORKSPACE_MESSAGE)
        );

        this.isValid$ = this.workspaceToCreate$.pipe(
            debounceTime(200),
            map(workspaceToCreate => workspaceToCreate?.workspaceKey?.length > 0 && workspaceToCreate?.displayName?.length > 0)
        );

        this.canCreateWorkspaces$.next(this.workspaceSecurityService.canCreateWorkspaces());
    }

    createWorkspace(): void {
        this.workspacesService.createAndGoToWorkspace(this.workspaceToCreate$.value).subscribe({ error: (error: APIError) => this.error$.next(error) });
    }

    onWorkspaceChange(updatedWorkspace: Workspace): void {
        this.workspaceToCreate$.next(updatedWorkspace);
    }

    closeError(): void {
        this.error$.next(undefined);
    }
}
