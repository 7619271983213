<div class="drop-zone" [class.dragged-over]="draggedOver" [class.loading]="loading" [class.hide-default]="hideDefault">
    <div class="drop-zone-text">

        <div *ngIf="!draggedOver && !loading">
            <h2 class="drop-zone-text__title">
                Drag and drop images
            </h2>
            <h3 class="drop-zone-text__subtitle">
                To live score them or
            </h3>
            
            <div class="mtop8">
                <button class="btn btn--primary drop-zone__browse-btn" (click)="inputFileUpload.click()">
                    Browse for images
                </button>
                <input #inputFileUpload type="file" (change)="handleFileInput(inputFileUpload.files!)" multiple hidden>
            </div>
        </div>

        <div *ngIf="draggedOver">
            <h2 class="drop-zone-text__title">
                You can drop your image(s)
            </h2>
        </div>

        <div *ngIf="loading">
            <i class="dku-loader icon-spin dku-loader--inlined loading-spinner"></i>
            <h2 class="drop-zone-text__title">
                Processing image(s)...
            </h2>
        </div>

    </div>
</div>