import { Component, ChangeDetectionStrategy} from '@angular/core';
import { ErrorContextService } from '@shared/services/error-context.service';

@Component({
    selector: 'api-error-alert-from-context',
    templateUrl: './api-error-alert-from-context.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class APIErrorAlertFromContextComponent {
    constructor(public errorContext: ErrorContextService) {
    }
}
