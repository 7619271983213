<ng-container>
    <div *ngIf="apiError">
        <api-error-alert [apiError]="apiError" [closable]="false"></api-error-alert>
    </div>
    <div *ngIf="initError" class="alert alert-error h-auto">
        <i class="icon-dku-error"></i> {{ initError }}
    </div>
    <div class="experiment-tracking-folder-view managed-folder-view h100 vertical-flex" *ngIf="odb$ | async as odb">
        <managed-folder-contents-view [odb]="odb" [subFolderStartingPoint]="subFolderStartingPoint" [initialSubFolder]="initialSubFolder">
        </managed-folder-contents-view>
    </div>
</ng-container>