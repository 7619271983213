import { DkuHttpService, TypedFutureResponse } from 'dku-frontend-core';
import { Injectable } from '@angular/core';
import { UIDataCatalog } from 'generated-sources';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class PrivatePopularDatasetsAPI {
    constructor(
            private dkuHttp: DkuHttpService
        ) {
    }

    list(): Observable<UIDataCatalog.AbstractPopularDataset[] | ""> {
        return this.dkuHttp.request("GET", "/popular-datasets/list");
    }

    compute(): Observable<TypedFutureResponse<UIDataCatalog.AbstractPopularDataset[]>> {
        return this.dkuHttp.request("POST", "/popular-datasets/compute");
    }
}
