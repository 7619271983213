<div class="empty-annotations-placeholder" *ngIf="!displayedLabel || displayedLabel.annotations.length === 0">
    <div *ngIf="skipped" class="skipped-placeholder"> 
        <i class="icon-fast-forward skipped-placeholder__skip-icon"></i>
        <h2>Sample was skipped</h2>
    </div>

    <div *ngIf="!skipped">
        <h2>No labels yet</h2>
        <div>
            <p>Select a category by...</p>
        </div>
        <div class="circles-container">
            <div class="circle-container">
                <div class="circle cat-example"></div>
                <span>...clicking on the category buttons</span>
            </div>
            <span>or</span>
            <div class="circle-container">
                <div class="circle shortcut-example"></div>
                <span>...using keyboard shortcuts</span>
            </div>
        </div>
        <ng-container *ngIf="labelingService.labelingTaskInfo$ | async as task">
            <p *ngIf="task.type === LabelingTaskType.OBJECT_DETECTION">...then draw a box around the target</p>
            <p *ngIf="task.type === LabelingTaskType.NAMED_ENTITY_EXTRACTION">...then highlight some text</p>
        </ng-container>
    </div>
</div>

<div *ngIf="displayedLabel && displayedLabel?.annotations?.length">
    <ng-container *ngFor="let annotation of displayedLabel.annotations">
        <div #annotationList class="labeling-annotation" [ngClass]="{ 'selected': annotation.selected }" (click)="selectAnnotation($event, annotation)">
            <div class="bbox-thumb-container" *ngIf="annotation | instanceOf: UIBoundingBox">
                <bbox-thumb [bboxHeight]="annotation.height" [bboxWidth]="annotation.width" [color]="color(annotation.category)"></bbox-thumb>
            </div>
            <div class="named-entity-thumb-container" *ngIf="annotation | instanceOf: UINamedEntity">
                <named-entity-thumb [text]="annotation.text" [color]="color(annotation.category)" [maxWordLength]="10"></named-entity-thumb>
            </div>
            <div class="labeling-annotation__class" *ngIf="annotation.category" [matTooltip]="annotation.category" isEllipsisActive>{{annotation.category}}</div>
            <div class="labeling-annotation__class" *ngIf="!annotation.category">Assign a category</div>
            <ng-container *ngTemplateOutlet="actionsTemplate, context: { annotation }"></ng-container>
            <i class="dku-icon-trash-16 mleft4" (click)="deleteAnnotation(annotation)"></i>
        </div>
    </ng-container>
</div>
