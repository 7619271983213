import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    DkuMdPopoverDirective,
    StarInterestDirective
} from './directives';
import {
    AutocompletableTextareaModule,
    StandardizedSidePanelModule
} from './modules';
import { MarkdownDirective } from './markdown/markdown.directive';
import { AngularJSManagedFolderContentsView } from './managed-folders/angularjs-managed-folder-contents-view.directive';
import { ManagedFolderContentsViewComponent } from './managed-folders/managed-folder-contents-view.component';
import { RelatedByTypeComponent } from './catalog/related-by-type.component';
import { UiGlobalTag } from './tags/ui-global-tag.directive';
import { DatasetStatusComponent } from './datasets/dataset-status.component';
import { TagEditPopoverDirective } from './tags/tag-edit-popover.directive';
import { ResponsiveTagsListDirective } from './tags/responsive-tags-list.directive';
import { DatasetPreviewDirective } from './datasets/dataset-preview.directive';

@NgModule({
    imports: [
        CommonModule,
        AutocompletableTextareaModule,
        StandardizedSidePanelModule
    ],
    declarations: [
        MarkdownDirective,
        AngularJSManagedFolderContentsView,
        ManagedFolderContentsViewComponent,
        StarInterestDirective,
        RelatedByTypeComponent,
        UiGlobalTag,
        DatasetStatusComponent,
        TagEditPopoverDirective,
        DatasetPreviewDirective,
        DkuMdPopoverDirective,
        DatasetPreviewDirective,
        ResponsiveTagsListDirective
    ],
    exports: [
        MarkdownDirective,
        ManagedFolderContentsViewComponent,
        StarInterestDirective,
        RelatedByTypeComponent,
        UiGlobalTag,
        DatasetStatusComponent,
        TagEditPopoverDirective,
        DatasetPreviewDirective,
        DkuMdPopoverDirective,
        DatasetPreviewDirective,
        ResponsiveTagsListDirective
    ],
})
export class MigrationModule { }
