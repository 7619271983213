import { Inject, Injectable } from '@angular/core';
import { catchAPIError, catchAPIErrorWithDefault } from '@core/dataiku-api/api-error';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { WaitingService } from '@core/overlays/waiting.service';
import { DATASET_METRIC, RefreshStatusEvent } from '@shared/components/right-panel-summary/right-panel-dataset-status/right-panel-dataset-status.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InterestsService } from '@shared/services';
import { ErrorContextService } from '@shared/services/error-context.service';
import { fairAny } from 'dku-frontend-core';
import { InterestsInternalDB, ITaggingService, UIDataCatalog, ProjectsService } from 'generated-sources';
import produce from 'immer';
import { BehaviorSubject, noop, Observable, combineLatest, switchMap, of, Subject, merge, tap, shareReplay } from 'rxjs';
import { DataSourceExternalTable } from '@shared/models';
import { EditDatasetDataStewardModalService } from '@migration/upgraded-providers';
import { DataCatalogService } from './data-catalog.service';
import { LegacyDialogsService } from '@shared/components/dialogs/legacy-dialogs.service';
import { cloneDeep } from 'lodash';
import { ShareAndPublishService } from '@shared/services/share-and-publish.service';
import { DatasetAndTablePreview } from '@shared/services/dataset-and-table-preview/dataset-and-table-preview.service';

function datasetDetailsToTaggableRefWithName(dataset: UIDataCatalog.AbstractDatasetDetails) {
    return {
        type: ITaggingService.TaggableType.DATASET,
        projectKey: dataset.sourceProjectKey,
        id: dataset.name,
        displayName: dataset.name
    };
}

@UntilDestroy()
@Injectable()
export abstract class DataCatalogRightPanelContextService<T> {
    private detailsRefreshTrigger$ = new BehaviorSubject<void>(undefined);
    private selectedItemDetails?: UIDataCatalog.AbstractDataCatalogItemDetails;

    private selectedItem$ = new BehaviorSubject<T | undefined>(undefined);
    private isItemDetailsLoading$ = new BehaviorSubject<boolean>(false);

    private apiDetailsUpdate$ = combineLatest([
        this.detailsRefreshTrigger$,
        this.selectedItem$
    ]).pipe(
        switchMap(([, selectedItem]) => {
            if (selectedItem === undefined) return of(undefined);
            this.isItemDetailsLoading$.next(true);
            return this.fetchDetails(selectedItem).pipe(
                // in case of error, defaults to undef, but don't close observable so that future selections still query details
                catchAPIErrorWithDefault(this.errorContext, undefined),
                tap(() => this.isItemDetailsLoading$.next(false)),
            );
        }),
    );
    private offlineDetailsUpdate$ = new Subject<UIDataCatalog.AbstractDataCatalogItemDetails>();

    private currentDetails$ = merge(
        this.apiDetailsUpdate$,
        this.offlineDetailsUpdate$,
    ).pipe(
        shareReplay(1)
    );

    constructor(
        private errorContext: ErrorContextService,
        private interestService: InterestsService,
        private waitingService: WaitingService,
        private currentRouteService: CurrentRouteService,
        @Inject('$rootScope') private $rootScope: fairAny,
        @Inject('ExportUtils') private ExportUtils: fairAny,
        @Inject('ConnectionExplorationService') public ConnectionExplorationService: fairAny,
        protected dataikuApiService: DataikuAPIService,
        @Inject('EditDatasetDataStewardModalService') private editDatasetDataStewardModalService: EditDatasetDataStewardModalService,
        private dataCatalogService: DataCatalogService,
        private legacyDialogsService: LegacyDialogsService,
        private shareAndPublishService: ShareAndPublishService,
        private datasetAndTablePreview: DatasetAndTablePreview
    ) {
        this.currentDetails$.pipe(
            untilDestroyed(this)
        ).subscribe(details => this.selectedItemDetails = details);
    }

    protected abstract fetchDetails(selectedItem: T): Observable<UIDataCatalog.AbstractDataCatalogItemDetails | undefined>;

    public setSelectedItem(item?: T) {
        this.selectedItem$.next(item);
    }

    isSameItemAsSelected(details: UIDataCatalog.AbstractDataCatalogItemDetails) {
        if (this.selectedItemDetails === undefined || this.selectedItemDetails.type !== details.type) return false;
        switch(details.type) {
            case UIDataCatalog.DatasetDetails.type:
            case UIDataCatalog.DiscoverableDatasetDetails.type:
                return details.sourceProjectKey === this.selectedItemDetails.sourceProjectKey && details.name === this.selectedItemDetails.name && details.datasetType === this.selectedItemDetails.datasetType;
        }
    }

    /**
     * Update the current details under the condition that the item details are still referring to the same item than when the action was started.
     * @param originalDetails the original details of the items
     * @param detailsTypeGuard a type-guard for the expected details to update (UIDataCollection.AbstractDataCollectionItemDetails.isDatasetDetails for example)
     * @param reducer how to update the details (keep in mind that other concurrent changes may have happened)
     */
    private updateDetailsIfNeeded<T extends UIDataCatalog.AbstractDataCatalogItemDetails>(
        originalDetails: UIDataCatalog.AbstractDataCatalogItemDetails,
        detailsTypeGuard: (details: UIDataCatalog.AbstractDataCatalogItemDetails) => details is T,
        reducer: (details: T) => T,
    ) {
        if (this.selectedItemDetails === undefined) return;
        if (this.isSameItemAsSelected(originalDetails) && detailsTypeGuard(this.selectedItemDetails)) {
            this.selectedItemDetails = reducer(this.selectedItemDetails);
            this.offlineDetailsUpdate$.next(this.selectedItemDetails);
        }
    }

    private forceDetailsUpdateFromServer() {
        this.detailsRefreshTrigger$.next();
    }

    public getCurrentDetails() {
        return this.currentDetails$;
    }

    public getLoadingStatus() {
        return this.isItemDetailsLoading$;
    }

    public getProjectContext() {
        // the type is string, but it actually can be undefined in some contexts!
        const projectKey = this.currentRouteService.projectKey as string | undefined;
        if (projectKey) {
            return this.$rootScope.projectSummary as ProjectsService.UIProject;
        } else {
            return undefined;
        }
    }

    toggleStarred(selectedItemDetails: UIDataCatalog.DatasetDetails, starred: boolean) {
        this.interestService.star([{
            type: ITaggingService.TaggableType.DATASET,
            projectKey: selectedItemDetails.sourceProjectKey,
            id: selectedItemDetails.name,
        }], starred).pipe(
            catchAPIError(this.errorContext),
        ).subscribe(() => {
            this.updateDetailsIfNeeded<UIDataCatalog.DatasetDetails>(
                selectedItemDetails,
                UIDataCatalog.AbstractDataCatalogItemDetails.isDatasetDetails,
                produce(details => {
                    details.interest.starred = starred;
                })
            );
        });
    }

    toggleWatched(selectedItemDetails: UIDataCatalog.DatasetDetails, watched: boolean) {
        const newValue = watched ? InterestsInternalDB.Watching.YES : InterestsInternalDB.Watching.ENO;
        this.interestService.watch([{
            type: ITaggingService.TaggableType.DATASET,
            projectKey: selectedItemDetails.sourceProjectKey,
            id: selectedItemDetails.name,
        }], newValue).pipe(
            catchAPIError(this.errorContext),
        ).subscribe(() => {
            this.updateDetailsIfNeeded<UIDataCatalog.DatasetDetails>(
                selectedItemDetails,
                UIDataCatalog.AbstractDataCatalogItemDetails.isDatasetDetails,
                produce(details => {
                    details.interest.watching = newValue;
                })
            );
        });
    }

    preview(selectedItemDetails: UIDataCatalog.DatasetDetails) {
        return this.datasetAndTablePreview.openDatasetPreviewModal(
            selectedItemDetails.sourceProjectKey,
            selectedItemDetails.name
        );
    }

    exportDataset(selectedItemDetails: UIDataCatalog.DatasetDetails) {
        // beware, behavior of the export modal auto-magically depends on the current project context ($stateParams.projectKey)
        const $scope = this.$rootScope.$new();
        this.ExportUtils.exportDataset($scope, selectedItemDetails.sourceProjectKey, selectedItemDetails.name, selectedItemDetails.sourceProjectKey); // TODO @data-collections we use the dataset's projects as context, which is not optimal if user could see dataset through an other project (linked with the !details.objectAuthorizations.directAccessOnOriginal condition in the button)
    }

    share(selectedItemDetails: UIDataCatalog.DatasetDetails, wt1Context: Record<string, string>) {
        const currentProjectContext = this.getProjectContext();
        if(currentProjectContext) {
            // from inside a project => one-click share
            return this.shareAndPublishService.doShare(
                datasetDetailsToTaggableRefWithName(selectedItemDetails),
                currentProjectContext.projectKey,
                selectedItemDetails.objectAuthorizations.isQuicklyShareable,
                wt1Context
            ).then(() => this.forceDetailsUpdateFromServer());
        } else {
            // else open modal
            return this.shareAndPublishService.openShareModal(
                datasetDetailsToTaggableRefWithName(selectedItemDetails),
                selectedItemDetails.objectAuthorizations,
                wt1Context
            ).then(
                (hasChanged) => hasChanged && this.forceDetailsUpdateFromServer(),
                (err) => this.errorContext.catchAngularjsAPIError(err)
            );
        }
    }

    requestShare(selectedItemDetails: UIDataCatalog.AbstractDatasetDetails, from: string) {
        return this.shareAndPublishService.requestShare({
            type: ITaggingService.TaggableType.DATASET,
            projectKey: selectedItemDetails.sourceProjectKey,
            id: selectedItemDetails.name,
            displayName: selectedItemDetails.name
        }, this.getProjectContext()?.projectKey, { from })
        .then(
            (hasChanged) => hasChanged && this.forceDetailsUpdateFromServer()
        );
    }

    shareToDashboard(selectedItemDetails: UIDataCatalog.DatasetDetails) {
        const contextProjectKey = this.getProjectContext()?.projectKey;
        if (!contextProjectKey) return; // should not be allowed by the UI

        return this.shareAndPublishService.shareDatasetToDashboard(selectedItemDetails.sourceProjectKey, selectedItemDetails.name, contextProjectKey);
    }

    shareToWorkspace(selectedItemDetails: UIDataCatalog.DatasetDetails) {
        return this.shareAndPublishService.shareToWorkspace(datasetDetailsToTaggableRefWithName(selectedItemDetails));
    }

    addToFeatureStore(selectedItemDetails: UIDataCatalog.DatasetDetails) {
        return this.shareAndPublishService.addToFeatureStore(
            selectedItemDetails.sourceProjectKey, selectedItemDetails.name,
            selectedItemDetails.objectAuthorizations
        ).then(
            (hasChanged) => hasChanged && this.forceDetailsUpdateFromServer(),
            (err) => this.errorContext.catchAngularjsAPIError(err)
        );
    }

    addToDataCollection(selectedItemDetails: UIDataCatalog.AbstractDatasetDetails) {
        return this.shareAndPublishService.addToDataCollection(datasetDetailsToTaggableRefWithName(selectedItemDetails), {
            from: 'publish'
        });
    }

    public refreshStatus(selectedItemDetails: UIDataCatalog.DatasetDetails, $event: RefreshStatusEvent) {
        return this.dataikuApiService.datasets.getRefreshedSummaryStatus(
            selectedItemDetails.sourceProjectKey, selectedItemDetails.name, $event.metricToRefresh === DATASET_METRIC.RECORDS, $event.forceRefresh
        ).pipe(
            catchAPIError(this.errorContext),
            this.waitingService.bindOverlayAndWaitForResult()
        ).subscribe((status) => {
            // The deep clone is used because status is frozen by Immer, and angularjs will try to add a $$hashkey attribute to it, which will fail
            this.legacyDialogsService.infoMessagesDisplayOnly(this.$rootScope.$new(), "Metrics computation result", cloneDeep(status.messages))
            .catch(noop); // rejected means closed

            this.updateDetailsIfNeeded(selectedItemDetails,
                UIDataCatalog.AbstractDataCatalogItemDetails.isDatasetDetails,
                produce(details => {
                    details.status = status;
                })
            );
        });
    }

    /** Data sources External Tables actions  */
    previewExternalTable(selectedItem: DataSourceExternalTable) {
        const table = {
            catalog: selectedItem.catalog,
            schema: selectedItem.schema,
            table: selectedItem.name,
            type: selectedItem.type,
            remarks: selectedItem.remarks,
            connectionName: selectedItem.connection
        };
        return this.datasetAndTablePreview.openExternalTablePreviewModal(selectedItem.connectionType, table);
    }

    import(selectedItem: DataSourceExternalTable) {
        return this.dataCatalogService.gotoMassImportPage([selectedItem]);
    }

    editDataSteward(selectedItemDetails: UIDataCatalog.AbstractDatasetDetails, wt1Context: Record<string, string>) {
        const projectKey = selectedItemDetails.sourceProjectKey;
        const datasetName = selectedItemDetails.name;

        this.editDatasetDataStewardModalService.showEditDataStewardModal(
            this.$rootScope, projectKey, datasetName, selectedItemDetails.dataSteward, wt1Context
        ).then((dataSteward) => {
            this.updateDetailsIfNeeded(selectedItemDetails,
                UIDataCatalog.AbstractDataCatalogItemDetails.isDatasetDetails,
                produce(details => {
                    details.dataSteward = dataSteward;
                })
            );
        }, noop);
    }
}
