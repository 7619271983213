import type {Grouping, _Grouping} from './grouping';
import type {GroupingResult, _GroupingResult} from './grouping-result';

/**
 * Generated from com.dataiku.dip.eda.compute.grouping.MultiAnumGrouping
 */
export interface MultiAnumGrouping extends _Grouping {
    columns: string[];

    // PolyJson type
    type: 'multi_anum';
}

export namespace MultiAnumGrouping {
    export const type = 'multi_anum';
}

/** @deprecated Use Grouping.isMultiAnumGrouping() */ export function isMultiAnumGrouping(toTest: Grouping): toTest is MultiAnumGrouping { return MultiAnumGrouping.type === toTest['type']; }

export namespace MultiAnumGrouping {
    /**
     * Generated from com.dataiku.dip.eda.compute.grouping.MultiAnumGrouping$MultiAnumGroupingResult
     */
    export interface MultiAnumGroupingResult extends _GroupingResult {
        columns: string[];
        groupValues: string[][];

        // PolyJson type
        type: 'multi_anum';
    }

    export namespace MultiAnumGroupingResult {
        export const type = 'multi_anum';
    }

    /** @deprecated Use GroupingResult.isMultiAnumGroupingResult() */ export function isMultiAnumGroupingResult(toTest: GroupingResult): toTest is MultiAnumGroupingResult { return MultiAnumGroupingResult.type === toTest['type']; }
}