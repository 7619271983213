import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AutocompletableTextareaModule } from '@migration/modules';
import { WorkspaceGeneralEditionComponent } from './workspace-general-edition.component';
import { InitialsIconModule } from '@shared/modules/initials-icon';
import { StockColorPickerModule } from '@shared/modules/stock-color-picker';

@NgModule({
    declarations: [
        WorkspaceGeneralEditionComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        InitialsIconModule,
        AutocompletableTextareaModule,
        StockColorPickerModule
    ],
    exports: [
        WorkspaceGeneralEditionComponent
    ]
})
export class WorkspaceGeneralEditionModule { }
