import { ElementRef, Injector, Directive, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
    selector: 'angularjs-dku-bs-select'
})
export class AngularjsDkuBsSelectDirective extends UpgradeComponent {
    @Input() params: any;
    @Input() list: any;
    @Input() model: any;
    @Input() ngOptions: any;
    @Input() required: any;
    @Input() modelChange: any;
    @Input() optionsDescriptions: any;
    @Input() optionsAnnotations: any;
    @Input() layout: any;
    @Input() dataActionsBox: any;
    @Input() dataLiveSearch: any;
    @Input() dkuMultiple: any;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('ng1DkuBsSelect', elementRef, injector);
    }
}
