<table class="stats-table">
    <tbody class="stats-table__body">
        <tr
            *ngFor="let filter of results.frequencies.filters; let index = index; trackBy: trackByIndex"
            class="stats-table__row"
        >
            <th class="stats-table__head" [matTooltip]="filter | filterName">
                {{ filter | filterName | slice:0:100 }}
            </th>
            <td class="stats-table__cell">
                <ng-container *ngIf="results.frequencies.totalCount > 0">
                    {{ (results.frequencies.counts[index] / results.frequencies.totalCount) | percent }}
                </ng-container>
            </td>
            <td class="stats-table__cell">{{ results.frequencies.counts[index] }}</td>
        </tr>
        <tr class="stats-table__row">
            <th
                class="stats-table__header"
                colspan="2"
            >
                N distinct
            </th>
            <td class="stats-table__cell">{{ results.distinctCount }}</td>
        </tr>
    </tbody>
</table>
