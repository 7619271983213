<feed-image-modal [imageId]="data.imageId">
    <ng-template let-image="image">
        <image-metadata class="padleftright24 mbot16 db" [image]="image"></image-metadata>
        <div *ngIf="image?.cellData as imageClassifData" class="padleftright24">
            <div class="deephub-image-classification-feed-modal__results-header">Ground Truth</div>
                <div class="mtop4 deephub-image-classification-feed-modal__row">
                    <div class="deephub-image-classification-feed-modal__category"
                                [style.background-color]="getCategoryColor(imageClassifData.target)"
                                [style.color]="getCategoryColor(imageClassifData.target) | colorContrast">
                        {{imageClassifData.target}}
                    </div>
                </div>
            <deephub-image-classification-prediction-widget [classifData]="imageClassifData" [target]="imageClassifData.target">
            </deephub-image-classification-prediction-widget>
        </div>
    </ng-template>
</feed-image-modal>
