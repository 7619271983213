import type {Computation, _Computation} from './../computation';
import type {ComputationResult} from './../computation-result';
import type {ResamplerSpec} from './../../resampling/resampler-spec';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.common.ResampledComputation
 */
export interface ResampledComputation extends _Computation {
    computation: Computation;
    spec: ResamplerSpec;

    // PolyJson type
    type: 'resampled';
}

export namespace ResampledComputation {
    export const type = 'resampled';
}

/** @deprecated Use Computation.isResampledComputation() */ export function isResampledComputation(toTest: Computation): toTest is ResampledComputation { return ResampledComputation.type === toTest['type']; }

export namespace ResampledComputation {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.common.ResampledComputation$ResampledComputationResult
     */
    export interface ResampledComputationResult extends _AvailableResult {
        result: ComputationResult;

        // PolyJson type
        type: 'resampled';
    }

    export namespace ResampledComputationResult {
        export const type = 'resampled';
    }

    /** @deprecated Use ComputationResult.isResampledComputationResult() */ export function isResampledComputationResult(toTest: ComputationResult): toTest is ResampledComputationResult { return ResampledComputationResult.type === toTest['type']; }
}