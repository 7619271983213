import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { PlaceholderSize } from '../image-placeholder/image-placeholder.component';

@Component({
    selector: 'image-container',
    templateUrl: './image-container.component.html',
    styleUrls: ['./image-container.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageContainerComponent implements OnChanges {
    @Input() src: string;
    @Input() scale: number = 1;
    @Input() scaleOrigin: string = 'top';
    @Input() placeholderSize: PlaceholderSize;

    @ViewChild('imageEl') imageEl: ElementRef;
    public loading: boolean = true;
    public hasLoaded: boolean = false;
    public hasError: boolean = false;
    public style = {};

    constructor() { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.scale?.currentValue !== changes.scale?.previousValue) {
            // set max width/height based on scale value
            const maxDimension = 100 / (this.scale || 1) + '%';
            this.style = {
                transform: `scale(${this.scale})`,
                transformOrigin: this.scaleOrigin,
                maxWidth: maxDimension,
                maxHeight: maxDimension
            };
        }

        if (changes.src?.currentValue !== changes.src?.previousValue) {
            this.hasLoaded = false;
            this.hasError = false;
            this.loading = true;
        }
    }

    onLoad() {
        this.hasLoaded = true;
        this.hasError = !this.imageEl?.nativeElement?.width;
        this.loading = false;
    }

    onError(event: any) {
        if (this.loading && this.src) {
            this.hasLoaded = true;
            this.hasError = true;
            this.loading = false;
        }
    }
}
