import { Injectable } from "@angular/core";
import { Chunk } from "@shared/components/infinite-scroll/infinite-scroll.component";
import { ManagedFolder } from "@shared/utils/managed-folder";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { AnyLoc, SerializedMemTableV2, SerializedShakerScript, SerializedTableChunk } from "src/generated-sources";
import { CellData } from "../items-data-fetcher.service";
import { ImagesDataFetcherService } from "./images-data-fetcher.service";
import { SampleDataFormatterService } from "./sample-data-formatter.service";


export interface ColumnMetadata {
    name: string;
}

export interface ColumnMetadataWithIndex extends ColumnMetadata {
    index: number;
}

export interface ColumnContent extends ColumnMetadata {
    content: any;
    isItemPath: boolean;
    isImportantColumn: boolean;
}

export type ImportantColumnsMetadata<T extends ColumnMetadata> = {
    single: {
        itemPath: T;
        [key: string]: T;
    }
    multiple?: {
        [key: string]: T[];
    }
};

export type ImportantColumnsMetadataWithAnnotation<T extends ColumnMetadata> = {
    single: {
        itemPath: T;
        annotation: T;
    }
};
export abstract class CellDataWithAnnotation implements CellData {
    itemId: string;
    itemIndex: number;
    invalid?: boolean;
    invalidReason?: string;
    abstract listCategories(): string[];
}

function enrichImportantColumnsMetadata<T extends ColumnMetadata, P extends ColumnMetadata>(columnsMetadata: ImportantColumnsMetadata<T>, enrichFn: (from: T) => P) : ImportantColumnsMetadata<P>{
    return {
        single: {
           itemPath: enrichFn(columnsMetadata.single.itemPath),
           ... Object.fromEntries(Object.entries(columnsMetadata.single).filter(elem => elem[0] !== "itemPath").map(elem => [elem[0], enrichFn(elem[1])]))
        },
        ... columnsMetadata.multiple && {
           multiple: Object.fromEntries(Object.entries(columnsMetadata.multiple).map(elem => [elem[0], elem[1].map(e => enrichFn(e))]))
        }
    };
}

@Injectable()
export abstract class SampleDataFetcherService extends ImagesDataFetcherService {
    importantColumnsMetadataWithIndex: ImportantColumnsMetadata<ColumnMetadataWithIndex>;
    nbSelectedColumns: number;
    totalItems: number | null;
    selectedColumnNames: string[];
    managedFolderLoc: AnyLoc;

    constructor(private dataFormatter: SampleDataFormatterService) {
        super();
    }

    abstract refreshSample(): Observable<SerializedMemTableV2>;

    abstract getSampleChunk(offset: number, nbRows: number): Observable<SerializedTableChunk>;

    abstract buildImportantColumnsMetadata(): ImportantColumnsMetadata<ColumnMetadata>;

    getImagePath(itemPath: string): string {
        return new ManagedFolder(this.managedFolderLoc, this.projectKey).getImagePath(itemPath);
    }

    private static addIndexToColumnMetadata(columnMetadata: ColumnMetadata, orederedColumns: string[]): ColumnMetadataWithIndex{
        return {
            name: columnMetadata.name,
            index: orederedColumns.indexOf(columnMetadata.name)
        };
    }

    updateMetadataColumns() {
        this.importantColumnsMetadataWithIndex = enrichImportantColumnsMetadata(this.buildImportantColumnsMetadata(),
                                                                                (c) => SampleDataFetcherService.addIndexToColumnMetadata(c, this.selectedColumnNames));
    }

    private onRefreshSample(sample: SerializedMemTableV2): void {
        if (sample.newColumnsSelection && sample.newColumnsSelection.list && sample.newColumnsSelection.mode != SerializedShakerScript.DisplayedColumnsMode.ALL) {
            this.selectedColumnNames = sample.newColumnsSelection.list.filter(c => c.d).map(c => c.name);
        } else {
            this.selectedColumnNames = sample.allColumnNames;
        }
        this.nbSelectedColumns = this.selectedColumnNames.length;
        this.totalItems = sample.totalKeptRows;
        this.updateMetadataColumns();
    }

    formatRow(rowContent: string[], i: number) {
        return this.dataFormatter.rowToCellData(rowContent, this.importantColumnsMetadataWithIndex, i);
    }

    formatRowAsColumnsContent(rowContent: string[]): ColumnContent[] {
        return this.selectedColumnNames.map((columnName, index) => {
            return {
                name: columnName,
                content: rowContent[index],
                index: index,
                isItemPath: index === this.importantColumnsMetadataWithIndex.single.itemPath.index,
                isImportantColumn: this.isImportantColumn(index)
            };
       });
    }

    isImportantColumn(index: number): boolean {
        if (Object.values(this.importantColumnsMetadataWithIndex.single).some(columnMetadata => columnMetadata.index == index)) {
            return true;
        }

        if (this.importantColumnsMetadataWithIndex.multiple
            && Object.values(this.importantColumnsMetadataWithIndex.multiple).some(columns => columns.some(c => c.index == index))) {
            return true;
        }
        return false;
    }

    getChunk(offset: number): Observable<Chunk> {
        let chunk$: Observable<SerializedTableChunk>;

        if (this.totalItems !== null && this.totalItems > 0 && offset >= this.totalItems) {
            return of({
                totalItems: this.totalItems,
                chunkItems: []
            });
        }

        if (offset === 0) {
            chunk$ = this.refreshSample()
                .pipe(
                    map(result => {
                        this.onRefreshSample(result);
                        return result.initialChunk;
                    })
                );
        } else {
            chunk$ = this.getSampleChunk(offset, this.CHUNK_SIZE);
        }

        return chunk$.pipe(
            map(chunk => ({
                totalItems: chunk.totalKeptRows,
                chunkItems: Array.from(Array(Math.min(chunk.nbRows, chunk.totalKeptRows - offset)))
                .map((row, index) => {
                    const rowContent = chunk.content.slice(index * chunk.nbCols, (index + 1) * chunk.nbCols);
                    return this.formatRow(rowContent, offset + index);
                })
            }))
        );
    }
}
