import { Pipe, PipeTransform } from '@angular/core';

/**
 * `[n] | range`        => 0 .. (n-1)
 * `[from, to] | range` => from .. to (inclusive)
 */
@Pipe({
    name: 'range'
})
export class RangePipe implements PipeTransform {
    getArray(min: number, max: number) {
        let arr = [];
        if (max < min) {
            [max, min] = [min, max]; //swapping variables
        }
        for (let i = min; i < max; i++) {
            arr.push(i);
        }
        return arr;
    }

    transform(value: Array<number>): Array<number> {
        if (value.length >= 2) {
            let min = value[0];
            let max = value[value.length - 1] + 1; // +1 so that it's inclusive
            return this.getArray(min, max);
        } else if (value.length == 1) {
            return this.getArray(0, value[0]);
        }
        return [];
    }
}
