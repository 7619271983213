<div class="object-nav experiment-tracking-top-tabs">
    <div class="horizontal-flex">
        <div class="flex">
            <div class="object-breadcrumb">
                <a class="item experiment-tracking" title="Experiment Tracking" [href]="experimentTrackingHref"
                    [wt1Click]="'experiment-tracking-home'">
                    <i class="icon dku-icon-experiment-tracking-24"></i>
                    <span class="title ellipsis ellipsed">
                        <div class="width-fit-content">Experiment Tracking</div>
                    </span>
                </a>
            </div>

            <ng-container *ngFor="let intermediateLink$ of intermediateLinks">
                <ng-container *ngIf="intermediateLink$ | async as intermediateLink">
                    <div class="experiment-tracking-intermediate-link noflex" style="margin-left: 15px">/</div>
                    <div class="experiment-tracking-intermediate-link noflex">
                        <a class="" title="{{ intermediateLink.displayName }}" [href]="intermediateLink.href">{{ intermediateLink.displayName }}</a>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div class="dib pull-right">
            <div class="right-header">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
