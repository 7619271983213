import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'map' })
export class MapPipe implements PipeTransform {

	transform<T, R>(values: T, func: (value: T, index: number, values: T[]) => R, context?: unknown): R;
	transform<T, R>(values: T[], func: (value: T, index: number, values: T[]) => R, context?: unknown): R[];
	transform<T, R>(values: T | T[], func: (value: T, index: number, values: T[]) => R, context?: unknown): R | R[] {
		if (Array.isArray(values)) {
			return values.map(func.bind(context));
		} else {
			return func.call(context, values, 0, [values]);
		}
	}

}
