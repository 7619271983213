<ng-container *ngIf="worksheet$ | async as worksheet">
    <div class="worksheet-header">
        <h4
            class="worksheet-header__text"
            [matMenuTriggerFor]="worksheetMenu"
            [qa]="'worksheet-menu'"
            [wt1Click]="'worksheet-click'"
        >
            {{ worksheet.name }}
            <b class="caret"></b>
        </h4>

        <mat-menu #worksheetMenu>
            <ng-template [ngIf]="!readOnly">
                <button
                    mat-menu-item
                    (click)="createWorksheet()"
                    [wt1Click]="'worksheets_new-worksheet'"
                    [qa]="{'worksheet-menu-item': {action: 'new'}}"
                >
                    <i class="icon-file-alt icon-fixed-width-small"></i> New Worksheet
                </button>
                <button
                    mat-menu-item
                    (click)="renameWorksheet()"
                    [wt1Click]="'worksheets_rename-worksheet'"
                    [qa]="{'worksheet-menu-item': {action: 'rename'}}"
                >
                    <i class="icon-pencil icon-fixed-width-small"></i> Rename
                </button>
                <button
                    mat-menu-item
                    (click)="duplicateWorksheet()"
                    [wt1Click]="'worksheets_duplicate-worksheet'"
                    [qa]="{'worksheet-menu-item': {action: 'duplicate'}}"
                >
                    <i class="icon-copy icon-fixed-width-small"></i> Duplicate
                </button>
                <button
                    mat-menu-item
                    (click)="deleteWorksheet()"
                    [wt1Click]="'worksheets_delete-worksheet'"
                    [qa]="{'worksheet-menu-item': {action: 'delete'}}"
                >
                    <i class="icon-trash icon-fixed-width-small"></i> Delete
                </button>
                <mat-divider></mat-divider>
            </ng-template>
            <button
                mat-menu-item
                *ngFor="let listedWorksheet of worksheets$ | async"
                (click)="changeWorksheet(listedWorksheet.id)"
                [qa]="{'worksheet-menu-item': {
                    action: 'select-worksheet',
                    id: listedWorksheet.id,
                    name: listedWorksheet.name
                }}"
            >
                <i
                    class="icon-ok"
                    *ngIf="worksheet.id === listedWorksheet.id"
                ></i>
                {{ listedWorksheet.name }}
            </button>
        </mat-menu>

        <sample-settings
            *ngIf="dataSpec$ | async as dataSpec"
            [dataSpec]="dataSpec"
            [projectKey]="worksheet.projectKey"
            [readOnly]="readOnly"
            (dataSpecChange)="updateDataSpec($event)"
        >
        </sample-settings>

        <edit-confidence-level-settings
            *ngIf="rootCardParams$ | async as rootCardParams"
            [params]="rootCardParams"
            (action)="handleRootCardAction($event)"
            [canSave]="!readOnly"
        >
        </edit-confidence-level-settings>

        <highlight-settings
            [params]="rootCardParams"
            (action)="handleRootCardAction($event)"
            [readOnly]="readOnly"
            *ngIf="rootCardParams$ | async as rootCardParams">
        </highlight-settings>

        <container-settings
            *ngIf="dataSpec$ | async as dataSpec"
            [dataSpec]="dataSpec"
            [readOnly]="readOnly"
            (dataSpecChange)="updateDataSpec($event)">
        </container-settings>

        <div class="worksheet-header__buttons pull-right" *ngIf="'canWriteProjectContent' | dkuEvaluatePermissions">
            <button
                class="btn btn--primary"
                (click)="openAnalysisModal()"
                [wt1Click]="'worksheets_new-card'"
                [qa]="'eda-create-card-button'"
            >
                + New Card
                <ng-container #modal></ng-container>
            </button>
        </div>
    </div>

    <drag-scroll [dragEnabled]="true" [height]="'calc(100% + -40px)'">
        <div class="worksheet">
            <div class="worksheet__content">
                <dataset-error-cta
                    [apiError]="error$ | async"
                    *ngIf="datasetFullInfo$ | async as datasetFullInfo"
                    [datasetFullInfo]="datasetFullInfo"
                ></dataset-error-cta>
                <ng-container *ngIf="{
                    rootCardParams : rootCardParams$ | async,
                    rootCardResults : rootCardResults$ | async
                } as ctx">
                    <ng-container *ngIf="ctx.rootCardResults && ctx.rootCardParams">
                        <worksheet-root-card
                            [params]="ctx.rootCardParams"
                            [results]="ctx.rootCardResults"
                            [readOnly]="!('canWriteProjectContent' | dkuEvaluatePermissions)"
                            (action)="handleRootCardAction($event)"
                            *ngIf="ctx.rootCardParams.cards.length > 0 else noCards"
                        >
                        </worksheet-root-card>
                        <ng-template #noCards>
                            <div class="empty-list-cta">
                                <h1>Empty worksheet</h1>
                                <a (click)="openAnalysisModal()" class="btn btn--primary btn--large btn--dataset"
                                    [wt1Click]="'worksheets_first-card'" *ngIf="'canWriteProjectContent' | dkuEvaluatePermissions">
                                    <span class="plus-icon">+</span> Add a new card
                                </a>
                            </div>
                        </ng-template>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </drag-scroll>
</ng-container>
