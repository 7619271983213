import { Component, ChangeDetectionStrategy, Input, OnChanges, Inject, Output, EventEmitter } from '@angular/core';
import { DataCollectionsService } from '@features/data-collections/shared/services/data-collections.service';
import { formatItemName } from '@features/feature-store/highlight-utils';
import { RefreshStatusEvent } from '@shared/components/right-panel-summary/right-panel-dataset-status/right-panel-dataset-status.component';
import { Highlight } from '@shared/models';
import { InterestsService } from '@shared/services';
import { fairAny } from 'dku-frontend-core';
import { BasePermissions, UIDataCatalog } from 'src/generated-sources';

@Component({
    selector: 'data-collection-page-right-panel-dataset',
    templateUrl: './data-collection-page-right-panel-dataset.component.html',
    styleUrls: ['../data-collection-page-right-panel-commons.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataCollectionPageRightPanelDatasetComponent implements OnChanges {
    @Input() readonly details!: UIDataCatalog.DatasetDetails;
    @Input() readonly currentUserPermissions?: BasePermissions.Permissions;
    @Input() readonly highlights?: Highlight;
    @Input() readonly selectedTab: string;

    @Output() toggleStarred = new EventEmitter<boolean>();
    @Output() toggleWatched = new EventEmitter<boolean>();
    @Output() removeSelectedItem = new EventEmitter<void>();
    @Output() export = new EventEmitter<void>();
    @Output() preview = new EventEmitter<void>();
    @Output() share = new EventEmitter<void>();
    @Output() requestShare = new EventEmitter<void>();
    @Output() shareToDashboard = new EventEmitter<void>();
    @Output() shareToWorkspace = new EventEmitter<void>();
    @Output() addToFeatureStore = new EventEmitter<void>();
    @Output() addToDataCollection = new EventEmitter<void>();
    @Output() editDataSteward = new EventEmitter<void>();
    @Output() refreshStatus = new EventEmitter<RefreshStatusEvent>();

    // the init value of these params don't really matter as they are set in ngOnChange
    exploreUrl = '';
    watched = false;
    starred = false;
    shareability = 'nothing' as 'share' | 'request' | 'nothing';
    shareButtonTooltip = '';
    shareButtonLabel = 'Share';

    showShareToDashboard = false;
    canShareToDashboard = false;
    dashboardTooltip = '';
    canShareToWorkspace = false;
    workspaceTooltip = '';
    canAddToFeatureStore = false;
    featureStoreTooltip = '';
    canPublishToDataCollections = false;
    dataCollectionTooltip = '';
    isSelectedItemPartitionned: boolean = false;
    doesSelectedItemHaveSize: boolean = false;
    columnsHighlighting: string[] = [];

    constructor(
        @Inject('StateUtils') private StateUtils: fairAny,
        @Inject('$rootScope') private $rootScope: fairAny,
        @Inject("DatasetUtils") private DatasetUtils: fairAny,
        private dataCollectionsService: DataCollectionsService,
        private interestService: InterestsService,
    ) { }

    ngOnChanges(): void {
        this.columnsHighlighting = [
            ...(this.highlights?.["column.plaintext"] || []),
            ...(this.highlights?.column || []),
        ];
        this.exploreUrl = this.StateUtils.href.dataset(
            this.details.name,
            this.details.sourceProjectKey
        );
        this.watched = this.interestService.isWatching(this.details.interest.watching);
        this.starred = this.details.interest.starred;
        this.isSelectedItemPartitionned = Object.keys(this.details.partitioningDimensions).length > 0;
        this.doesSelectedItemHaveSize = this.DatasetUtils.hasSizeStatus(this.details.datasetType);

        this.updateShareButtonState();
        this.updatePublishButtonState();
    }

    shareButtonAction() {
        if (this.shareability === 'share') {
            this.share.emit();
        } else if (this.shareability === 'request') {
            this.requestShare.emit();
        }
        // else sharability = 'nothing' and button is considered disabled
    }

    private updateShareButtonState() {
        // TODO @data-collections disable button if already shared 'Dataset already shared to this project'
        const authorizations = this.details.objectAuthorizations;
        const currentProject = this.dataCollectionsService.getProjectContext();

        if (currentProject?.projectKey === this.details.sourceProjectKey) {
            this.shareability = 'nothing';
            this.shareButtonTooltip = 'Target project must be different from source';
        } else if (authorizations.canManageExposedElements || (authorizations.isQuicklyShareable && authorizations.canReadObject)) {
            this.shareability = 'share';
            this.shareButtonTooltip = currentProject ? 'Use in project ' + currentProject.name : 'Use in another project';
        } else if (authorizations.isObjectSharingRequestEnabled) {
            this.shareability = 'request';
            this.shareButtonTooltip = currentProject ? 'Request to use in project ' + currentProject.name : 'Request to use in another project';
        } else {
            this.shareability = 'nothing';
            this.shareButtonTooltip = 'No permission to use dataset';
        }

        this.shareButtonLabel = this.shareability === 'request'
            ? 'Request'
            : 'Use';
    }

    private updatePublishButtonState() {
        const currentProject = this.dataCollectionsService.getProjectContext();

        // share to dashboard is only possible if the dataset is from the current project
        // TODO @data-collection we could extend it to datasets *shared to* the current project
        if (currentProject?.projectKey === this.details.sourceProjectKey) {
            this.showShareToDashboard = true;
            this.canShareToDashboard = currentProject.canWriteDashboards;
            this.dashboardTooltip = currentProject.canWriteDashboards ? '' : 'You don\'t have the permission to share objects from this project to a dashboard';
        } else {
            this.showShareToDashboard = false;
        }

        if (!this.$rootScope.appConfig.globalPermissions.mayShareToWorkspaces) {
            this.canShareToWorkspace = false;
            this.workspaceTooltip = 'You don\'t have the permission to share objects to a workspace';
        } else {
            this.canShareToWorkspace = this.details.objectAuthorizations.canShareToWorkspaces;
            this.workspaceTooltip = this.canShareToWorkspace ? '' : 'You don\'t have the permission to share objects from this project to a workspace';
        }

        if (!this.$rootScope.appConfig.globalPermissions.mayManageFeatureStore) {
            this.canAddToFeatureStore = false;
            this.featureStoreTooltip = 'You don\'t have the permission to add datasets to the feature store';
        } else if (this.details.isFeatureGroup) {
            this.canAddToFeatureStore = false;
            this.featureStoreTooltip = 'Dataset is already in the feature store';
        } else {
            this.canAddToFeatureStore = this.details.objectAuthorizations.canWriteObject;
            this.featureStoreTooltip = this.canAddToFeatureStore ? '' : 'You don\'t have the permission to add this dataset to the feature store';
        }

        if(!this.dataCollectionsService.mayPublishToDataCollections()) {
            this.canPublishToDataCollections = false;
            this.dataCollectionTooltip = 'You don\'t have the permission to publish objects to a data collection';
        } else {
            this.canPublishToDataCollections = this.details.objectAuthorizations.canPublishToDataCollections;
            this.dataCollectionTooltip = this.canPublishToDataCollections ? '' : 'You don\'t have the permission to publish objects from this project to a data collection';
        }
    }

    public onRefreshStatusEvent($event: RefreshStatusEvent) {
        this.refreshStatus.emit($event);
    }

    formatItemName() {
        return formatItemName(this.highlights || {}, this.details.name || "");
    }
}
