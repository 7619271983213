import type {_TabularPredictionPreprocessingParams} from './tabular-prediction-preprocessing-params';

/**
 * Generated from com.dataiku.dip.analysis.model.preprocessing.TimeseriesForecastingPreprocessingParams
 */
export interface TimeseriesForecastingPreprocessingParams extends _TabularPredictionPreprocessingParams {
    timeseriesSampling: TimeseriesForecastingPreprocessingParams.TimeseriesSamplingParams;
}

export namespace TimeseriesForecastingPreprocessingParams.TimeseriesSamplingParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.TimeseriesForecastingPreprocessingParams$TimeseriesSamplingParams$TimeseriesImputeMethod
     */
    export enum TimeseriesImputeMethod {
        NEAREST = 'NEAREST',
        PREVIOUS = 'PREVIOUS',
        NEXT = 'NEXT',
        LINEAR = 'LINEAR',
        QUADRATIC = 'QUADRATIC',
        CUBIC = 'CUBIC',
        CONSTANT = 'CONSTANT',
        PREVIOUS_NEXT = 'PREVIOUS_NEXT',
        NO_EXTRAPOLATION = 'NO_EXTRAPOLATION',
        MOST_COMMON = 'MOST_COMMON',
        NULL = 'NULL'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.TimeseriesForecastingPreprocessingParams$TimeseriesSamplingParams$DuplicateTimestampsHandlingMethod
     */
    export enum DuplicateTimestampsHandlingMethod {
        FAIL_IF_CONFLICTING = 'FAIL_IF_CONFLICTING',
        DROP_IF_CONFLICTING = 'DROP_IF_CONFLICTING',
        MEAN_MODE = 'MEAN_MODE'
    }
}

export namespace TimeseriesForecastingPreprocessingParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.TimeseriesForecastingPreprocessingParams$TimeseriesSamplingParams
     */
    export interface TimeseriesSamplingParams {
        categoricalConstantValue: string;
        categoricalImputeMethod: TimeseriesForecastingPreprocessingParams.TimeseriesSamplingParams.TimeseriesImputeMethod;
        duplicateTimestampsHandlingMethod: TimeseriesForecastingPreprocessingParams.TimeseriesSamplingParams.DuplicateTimestampsHandlingMethod;
        numericalExtrapolateConstantValue: number;
        numericalExtrapolateMethod: TimeseriesForecastingPreprocessingParams.TimeseriesSamplingParams.TimeseriesImputeMethod;
        numericalInterpolateConstantValue: number;
        numericalInterpolateMethod: TimeseriesForecastingPreprocessingParams.TimeseriesSamplingParams.TimeseriesImputeMethod;
    }
}