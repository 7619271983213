<api-error-alert
    [apiError]="error$ | async"
    (resetError)="resetError()"
    [closable]="true"
></api-error-alert>

<worksheet
    *ngIf="worksheetId && projectKey"
    [worksheetLoc]="{projectKey: projectKey, id: worksheetId}"
    [readOnly]="!('canWriteProjectContent' | dkuEvaluatePermissions)">
</worksheet>

<ng-container *ngIf="worksheetsIfAvailable$ | async as worksheets">
    <div *ngIf="worksheets && worksheets.length == 0" class="worksheet-intro empty-list-cta">
        <h1>No worksheets for this dataset</h1>
        <p>
            Worksheets allow you to generate in-depth statistical reports of your data
        </p>
        <p class="small">
            <doclink-wrapper [page]="'statistics/index'">Read the documentation</doclink-wrapper> to learn more
        </p>
        <a (click)="newWorksheet()" class="btn btn--primary btn--large btn--dataset"
            *ngIf="('canWriteProjectContent' | dkuEvaluatePermissions)">
            <span class="plus-icon">+</span> Create your first worksheet
        </a>
        <p class="small" *ngIf="!('canWriteProjectContent' | dkuEvaluatePermissions)">No worksheet is currently available for this dataset</p>
        <img class="worksheet-intro__image" src="/static/dataiku/images/eda/intro.png">
    </div>
</ng-container>