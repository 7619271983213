<ng-container *ngIf="groupCollapseStates$ | async as groupCollapseStates">
    <div 
        class="group"
        [class.group--fit-parent-height]="fitParentHeight"
        [qa]="{'eda-group': {'name': results.groups[groupIndex] | filterName}}"
        *ngFor="let groupResult of results.results; let groupIndex = index; trackBy: trackByIndex"
    >
        <div class="title">
            <div class="label" (click)="toggleGroup(results.groups[groupIndex], !groupCollapseStates[groupIndex])">
                <i *ngIf="showCollapsingControls" [class.icon-chevron-down]="!groupCollapseStates[groupIndex]"
                    [class.icon-chevron-right]="groupCollapseStates[groupIndex]"></i>
                {{ results.groups[groupIndex] | filterName: false:true }}
            </div>
            <div class="pvalue pull-right" *ngIf="asSingleTestData(groupResult) as singleTestData">
                <colored-pvalue
                    [showLabel]="true"
                    [pvalue]="singleTestData.pValue"
                    [significanceLevel]="1 - singleTestData.confidenceLevel"
                ></colored-pvalue>
                </div>
            <div class="menu" *ngIf="!readOnly && extendedActions">
                <button class="btn btn--icon btn--secondary menu-button pull-right"
                    [matMenuTriggerFor]="contextualMenu"
                    [qa]="'eda-group-menu-trigger'">
                    <i class="icon-dku-ellipsis-vertical"></i>
                </button>

                <mat-menu #contextualMenu>
                    <ng-template matMenuContent>
                        <button mat-menu-item (click)="exportGroup(groupIndex, CardActionType.ADD)"
                            [wt1Click]="'worksheets_duplicate-grouped-card'"
                            [qa]="{'eda-group-menu-item': {action: 'duplicate'}}">
                            Duplicate as new card
                        </button>
                        <button mat-menu-item (click)="exportGroup(groupIndex, CardActionType.DEBUG)"
                            [qa]="{'eda-group-menu-item': {action: 'view-json'}}">
                            View JSON…
                        </button>
                        <button mat-menu-item (click)="exportGroup(groupIndex, CardActionType.PUBLISH)"
                            [wt1Click]="'worksheets_publish-grouped-card'"
                            [qa]="{'eda-group-menu-item': {action: 'publish'}}">
                            Publish…
                        </button>
                    </ng-template>
                </mat-menu>
            </div>
        </div>
        <div class="body" [@fadeInOut]="groupCollapseStates[groupIndex] ? 'closed' : 'opened'">
            <card-body *ngIf="!groupCollapseStates[groupIndex] | delayWhenFalse" 
                [params]="params"
                [results]="groupResult"
                [renderingMode]="renderingMode"
                [readOnly]="readOnly"
                [hasFixedHeight]="!fitParentHeight"
                [extendedActions]="extendedActions"
                (action)="handleAction($event, groupIndex)">
            </card-body>
        </div>
    </div>
</ng-container>
