<form
    [formGroup]="configForm"
    id="config-form"
    class="dkuform-modal-vertical"
>
    <div class="control-group">
        <label class="control-label">Symbol size</label>
        <div class="controls">
            <label>
                <input
                    type="number"
                    formControlName="symbolSize"
                >
            </label>
        </div>
    </div>
    <div class="control-group">
        <label class="control-label">
            Max number of points
            <i class="icon-dku-help"
                matTooltip="The points are randomly drawn from the sample of the worksheet"
            ></i>
        </label>
        <input
            type="number"
            formControlName="maxNumberOfPoints"
        >
    </div>
    <div class="control-group">
        <label class="controls">
            <input type="checkbox" formControlName="showTrend">
            Show trend
        </label>
    </div>
</form>
