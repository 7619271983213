import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PainterImage } from '@shared/models/painter';
import { observeInput } from 'dku-frontend-core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { DeephubObjectDetectionInteractiveScoringCellData } from '../interactive-scoring/object-detection-interactive-scoring.service';
import { DeephubObjectDetectionInteractiveScoringPainterService } from '../services/deephub-object-detection-interactive-scoring-painter.service';

@UntilDestroy()
@Component({
  selector: 'deephub-object-detection-report-image-section',
  templateUrl: './deephub-object-detection-report-image-section.component.html',
  styleUrls: [
      './deephub-object-detection-report-image-section.component.less',
      '../../../shared-styles/deephub-interactive-scoring.less'
    ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeephubObjectDetectionReportImageSectionComponent implements OnInit {
    @Input() cellData: DeephubObjectDetectionInteractiveScoringCellData;
    @Input() threshold: number;
    threshold$ = observeInput(this, 'threshold');

    painterImage$ = new BehaviorSubject<PainterImage>(new PainterImage());
    painterImageUpdateTrigger$ = new Subject<{
        index: number,
        active: boolean
    }>();

    constructor(
        private painterService: DeephubObjectDetectionInteractiveScoringPainterService
    ) { }

    ngOnInit(): void {
        this.threshold$.pipe(
            withLatestFrom(
                this.painterImage$,
            ),
            untilDestroyed(this)
        ).subscribe(([threshold, painterImage]) => {
            this.cellData.prediction.forEach((prediction, index) => {
                this.painterService.toggleArtifact(painterImage, index, false, prediction.confidence >= threshold);
            })

            this.painterImage$.next(cloneDeep(painterImage));
        });
        
        this.painterImageUpdateTrigger$.pipe(
            withLatestFrom(
                this.painterImage$,
                this.threshold$
            ),
            untilDestroyed(this)
        ).subscribe(([toUpdate, painterImage, threshold]) => {
            this.painterService.toggleArtifact(painterImage, toUpdate.index, toUpdate.active, this.cellData?.prediction[toUpdate.index].confidence >= threshold);

            this.painterImage$.next(cloneDeep(painterImage));
        })
    }
}
