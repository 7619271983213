import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeToColor'
})
export class TypeToColorPipe implements PipeTransform {
  constructor(@Inject('TypeMappingService') private readonly typeMappingService: any) { }

  transform(type?: string, background = false): string {
    return this.typeMappingService.mapTypeToColor(type, background);
  }
}
