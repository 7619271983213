import type {OneSampleTZTestRecipePayloadParams} from './stats/one-sample-tztest-recipe-payload-params';

/**
 * Generated from com.dataiku.dip.recipes.eda.StatsRecipePayloadParams
 */
export interface _StatsRecipePayloadParams {
    confidenceLevel: number;
}

export type StatsRecipePayloadParams = OneSampleTZTestRecipePayloadParams;

export namespace StatsRecipePayloadParams {
    export const type = ['recipe_one-sample-tz-test'] as const;
    export function isOneSampleTZTestRecipePayloadParams(toTest: StatsRecipePayloadParams): toTest is OneSampleTZTestRecipePayloadParams { return toTest['type'] === 'recipe_one-sample-tz-test'; }
}