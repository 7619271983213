import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveInputComponent } from './reactive-input.component';

@NgModule({
    declarations: [
        ReactiveInputComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ReactiveInputComponent
    ]
})
export class ReactiveInputModule { }
