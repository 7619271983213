import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { fairAny } from 'dku-frontend-core';

declare let $: fairAny;

@Component({
    selector: 'modal-header',
    templateUrl: './modal-header.component.html',
    styleUrls: ['./modal-header.component.less']
})
export class ModalHeaderComponent implements OnInit {
    @Input() title: string;
    @Input() modalClass: string;
    @Input() icon?: string;
    @Input() hasBorder = true;
    @Output() iconAction = new EventEmitter<any>();

    iconHasAction = false;

    constructor(
        public dialogRef: MatDialogRef<any>
    ) {
        this.dialogRef.backdropClick().subscribe(() => {
            this.dialogRef.close();
        });
    }

    ngOnInit() {
        this.iconHasAction = this.iconAction.observers.length > 0;
    }

    setSeeThrough(seeThrough: boolean) {
        const elts = $('.cdk-overlay-container, div.popover');
        if (seeThrough) {
            elts.addClass('see-through');
        } else {
            elts.removeClass('see-through');
        }
    }

}
