import type {Distribution, _Distribution} from './distribution';

/**
 * Generated from com.dataiku.dip.eda.compute.distributions.LogNormal
 */
export interface LogNormal extends _Distribution {
    // PolyJson type
    type: 'lognormal';
}

export namespace LogNormal {
    export const type = 'lognormal';
}

/** @deprecated Use Distribution.isLogNormal() */ export function isLogNormal(toTest: Distribution): toTest is LogNormal { return LogNormal.type === toTest['type']; }

export namespace LogNormal {
    /**
     * Generated from com.dataiku.dip.eda.compute.distributions.LogNormal$FittedLogNormal
     */
    export interface FittedLogNormal extends Distribution._FittedDistribution {
        logmean: number;
        logstd: number;

        // PolyJson type
        type: 'lognormal';
    }

    export namespace FittedLogNormal {
        export const type = 'lognormal';
    }

    /** @deprecated Use Distribution.FittedDistribution.isFittedLogNormal() */ export function isFittedLogNormal(toTest: Distribution.FittedDistribution): toTest is FittedLogNormal { return FittedLogNormal.type === toTest['type']; }
}