<ng-container *ngIf="matrix$ | async as matrix">
    <confusion-matrix
        class="minh0"
        style="display: flex;
        flex-direction: column;"
        [data]="matrix.data"
        [xLabels]="matrix.xCategories"
        [yLabels]="matrix.yCategories"
        [readOnly]="true"
        [heatmapParams]="matrix.params"
        [popoverTemplate]="popoverTemplate"
        (cellClicked)="matrixClick($event)"
    >
    </confusion-matrix>
</ng-container>

<ng-template #popoverTemplate let-predictionLabel="xLabel" let-groundTruthLabel="yLabel" let-cellValue="value">
    <ul class="raw-unstyled-ul">
        <li>Actual <strong>{{ groundTruthLabel}}</strong></li>
        <li>Predicted <strong>{{ predictionLabel }}</strong></li>
        <li><strong>{{ cellValue }}</strong> {{ (predictionLabel === NO_DETECTION_LABEL ? 'object' : 'prediction') | plurify: cellValue }}</li>
    </ul>
    <div class="tac text-prompt mtop8">
        Click cell to show corresponding images
    </div>
</ng-template>