import { Component, ChangeDetectionStrategy } from '@angular/core';

/**
 * Utility component to display a generic checkbox icon in each <ng-select>'s option when it is selected.
 * By default <ng-select> does not show any icon next to a selected option.
 *
 * Usage:
 *
 * <ng-select [multiple]="true">
 *     <ng-template ng-option-tmp let-item="item">
 *         <ng-select-pseudo-checkbox></ng-select-pseudo-checkbox>
 *         {{ item.label }}
 *     </ng-template>
 * </ng-select>
 */
@Component({
  selector: 'ng-select-pseudo-checkbox',
  templateUrl: './ng-select-pseudo-checkbox.component.html',
  styleUrls: ['./ng-select-pseudo-checkbox.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgSelectPseudoCheckboxComponent {
}
