import { Component, OnInit, ChangeDetectionStrategy, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'sidebar-nav-item',
    templateUrl: './sidebar-nav-item.component.html',
    styleUrls: ['./sidebar-nav-item.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarNavItemComponent implements OnInit {
    @Input() id: string;
    @Input() label: string;

    @ViewChild(TemplateRef)
    template: TemplateRef<any>;

    constructor() { }

    ngOnInit(): void {
    }

}
