import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UINamedEntity } from '@features/labeling/models/annotation';
import { NamedEntityExtractionUILabel, UILabel } from '@features/labeling/models/label';
import { cloneDeep } from 'lodash';
import { LabelingAnnotateTextService } from '../../services/labeling-annotate-text.service';

@Component({
  selector: 'named-entity-annotate-annotation-list',
  templateUrl: './named-entity-annotate-annotation-list.component.html',
  styleUrls: ['./named-entity-annotate-annotation-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NamedEntityAnnotateAnnotationListComponent {
    @Input() skipped: boolean;
    @Input() label: NamedEntityExtractionUILabel | null;
    @Output() labelChange = new EventEmitter<UILabel>();

    constructor(
        private labelingAnnotateTextService: LabelingAnnotateTextService
    ) { }

    createMatchingAnnotations(annotation: UINamedEntity) {
        if (!this.label || !annotation.category) {
            return;
        }

        const newAnnotations = this.labelingAnnotateTextService.getAllMatchingAnnotations(annotation)
            // ensure annotation doesn't exist
            .filter(annotation => !this.label?.annotations.some(a => a.equals(annotation, true)));

        if (newAnnotations.length) {
            const newLabel = cloneDeep(this.label);
            newLabel.addAnnotations(newAnnotations);
            this.labelChange.emit(newLabel);
        }
    }
}
