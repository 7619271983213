import { NgModule } from '@angular/core';
import { EditableListModule } from '../editable-list/editable-list.module';
import { KeyValuesListComponent } from './key-values-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DowngradedKeyValuesListComponent } from './downgraded/downgraded-key-values-list.component';


@NgModule({
    declarations: [  
        KeyValuesListComponent, 
        DowngradedKeyValuesListComponent 
    ],
    imports: [
        EditableListModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [ KeyValuesListComponent ]
})
export class KeyValuesListModule { }
