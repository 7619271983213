export namespace CodeEnvModel {
    /**
     * Generated from com.dataiku.dip.code.CodeEnvModel$UsedCodeEnvRef
     */
    export interface UsedCodeEnvRef {
        envLang: CodeEnvModel.EnvLang;
        envName: string;
    }

    /**
     * Generated from com.dataiku.dip.code.CodeEnvModel$EnvLang
     */
    export enum EnvLang {
        PYTHON = 'python',
        R = 'R',
        JULIA = 'julia'
    }

    /**
     * Generated from com.dataiku.dip.code.CodeEnvModel$EnvImportSpecificationMode
     */
    export enum EnvImportSpecificationMode {
        SPECIFIED = 'SPECIFIED',
        ACTUAL = 'ACTUAL'
    }
}