/**
 * Generated from com.dataiku.dip.dataflow.exec.computedcolumn.ComputedColumn
 */
export interface ComputedColumn {
    expr: string;
    exprVariablesContext: any /* TODO: Missing translation of com.dataiku.dip.variables.VariablesContext */;
    mode: ComputedColumn.Mode;
    name: string;
    type: string;
}

export namespace ComputedColumn {
    /**
     * Generated from com.dataiku.dip.dataflow.exec.computedcolumn.ComputedColumn$Mode
     */
    export enum Mode {
        GREL = 'GREL',
        SQL = 'SQL'
    }
}