<div *ngIf="payload" class="join-right-panel-summary">
    <div *ngIf="preFilters.length > 0" class="join-right-panel-summary__joins-filter join-right-panel-summary__title">
        <h4>Pre-filters</h4>
        <div *ngFor="let input of preFilters">
            <h5>{{params.inputs.main.items[input.index].ref}}</h5>
            <div *ngIf="input.preFilter.distinct">Distinct rows only</div>
            <div *ngIf="input.preFilter.enabled" [innerHTML]="getPreconditionsPreText(input.preFilter) + (input.preFilter | filterNiceRepr)" ></div>
        </div>
    </div>
    <div class="join-right-panel-summary__title">
        <h4>Join conditions</h4>
    </div>
    <div class="join-right-panel-summary__joins-summary" *ngFor="let join of payload.joins">
        <div>
            <table class="join-right-panel-summary__join-table">
                <thead>
                    <tr>
                        <th class="join-right-panel-summary__join-type">{{joinDisplayNames.getJoinTypeName(join.type)}}</th>
                        <th></th>
                        <th>
                            <span *ngIf="join.conditionsMode === 'OR' || join.conditionsMode === 'AND'"
                                    [matTooltip]="getJoinConditionModeMessage(join.conditionsMode)"
                                    matTooltipPosition="left">
                                {{join.conditionsMode}}
                            </span>
                        </th>
                    </tr>
                    <tr class="join-right-panel-summary__dataset-names">
                        <td [matTooltip]="getDatasetName(join.table1)" isEllipsisActive>{{getDatasetName(join.table1)}}</td>
                        <td><i [class]="getJoinIcon(join.type)"></i></td>
                        <td [matTooltip]="getDatasetName(join.table2)" isEllipsisActive>{{getDatasetName(join.table2)}}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="join-right-panel-summary__divider" colspan="3">
                            <hr>
                        </td>
                    </tr>
                    <tr class="join-right-panel-summary__join-condition" *ngFor="let condition of getJoinConditions(join)">
                        <td [matTooltip]="condition.column1.name" isEllipsisActive>{{condition.column1.name}}</td>
                        <td>{{joinDisplayNames.getMatchingTypeName(condition.type)}}</td>
                        <td [matTooltip]="condition.column2.name" isEllipsisActive>{{condition.column2.name}}</td>
                    </tr>
                    <tr class="join-right-panel-summary__custom-condition-mode" *ngIf="!joinCanHaveConditions(join)">
                        <td colspan="3">{{getCustomJoinConditionMessage(join)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="join-right-panel-summary__title">
        <div *ngIf="hasUnmatchedOutput('unmatchedLeft') || hasUnmatchedOutput('unmatchedRight')">
            <h4>Unmatched rows</h4>
            <div class="join-right-panel-summary__unmatched-outputs">
                <span class="mx-textellipsis">
                    <a *ngIf="hasUnmatchedOutput('unmatchedLeft')" [href]="getUnmatchedOutputLink('unmatchedLeft')">{{getUnmatchedOutput('unmatchedLeft')}}</a>
                    <p *ngIf="!hasUnmatchedOutput('unmatchedLeft')" class="join-right-panel-summary__no-unmatched-output">No output</p>
                </span>
                <span class="mx-textellipsis">
                    <a *ngIf="hasUnmatchedOutput('unmatchedRight')" [href]="getUnmatchedOutputLink('unmatchedRight')">{{getUnmatchedOutput('unmatchedRight')}}</a>
                    <p *ngIf="!hasUnmatchedOutput('unmatchedRight')" class="join-right-panel-summary__no-unmatched-output">No output</p>
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="isFilterActive(payload.postFilter)" class="join-right-panel-summary__joins-filter join-right-panel-summary__title">
        <h4>Post-filters</h4>
        <div *ngIf="payload.postFilter.distinct">Distinct rows only</div>
        <div *ngIf="payload.postFilter.enabled" [innerHTML]="getPreconditionsPreText(payload.postFilter) + (payload.postFilter | filterNiceRepr)" ></div>
    </div>
</div>

