import { SerializedError } from 'dku-frontend-core';
import { Input, Component, Inject } from '@angular/core';
import { fairAny } from 'dku-frontend-core';

@Component({
    selector: 'error-fixability',
    templateUrl: './error-fixability.component.html',
    styleUrls: ['./error-fixability.component.less']
})
export class ErrorFixability {
    @Input() serializedError: SerializedError;

    constructor(@Inject('$rootScope') public $root: fairAny) {

    }

    get wl(): fairAny {
        return this.$root.wl;
    }
}
