import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { LabelingService } from "@features/labeling/services/labeling.service";
import { getItemName, searchBy } from "@features/labeling/utils";
import { LabelingTask } from "@model-main/labeling/labeling-task";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ModalShape, ModalsService } from "@shared/modals/modals.service";
import { WT1Service } from "dku-frontend-core";
import { BehaviorSubject, debounceTime, distinctUntilChanged, finalize, Subject, switchMap, switchScan, tap, withLatestFrom } from "rxjs";
import { ResolutionDialogComponent } from "../resolution-dialog/resolution-dialog.component";
import { LabelingReviewService, ReviewFetchType } from "../services/labeling-review.service";

@UntilDestroy()
@Component({
    selector: 'review-feed-header',
    templateUrl: './review-feed-header.component.html',
    styleUrls: ['./review-feed-header.component.less'],

})
export class ReviewFeedHeaderComponent implements OnInit {
    resolvingConsensualRecords$ = new BehaviorSubject<boolean>(false);
    resolutionTrigger$ = new Subject<void>();
    resolutionDialogTrigger$ = new Subject<void>();
    defaultAutoResolveClass = 'icon-dku-label-validate';
    resolveClass$ = new BehaviorSubject<string>(this.defaultAutoResolveClass);
    nbLabeledItems$ = new BehaviorSubject<number>(0);

    reviewForm = this.fb.group({
        reviewFetchType: this.fb.control(ReviewFetchType.RECORDS_TO_REVIEW, [Validators.required]),
        searchQuery: this.fb.control('')
    })

    searchBy = searchBy;
    getItemName = getItemName;

    get ReviewFetchType() {
        return ReviewFetchType;
    }

    constructor(
        private fb: FormBuilder,
        public labelingReviewService: LabelingReviewService,
        public labelingService: LabelingService,
        private modalsService: ModalsService,
        private viewContainerRef: ViewContainerRef,
        private WT1Service: WT1Service,
    ) { }

    ngOnInit(): void {
        this.labelingReviewService.recordIds$.pipe(
            untilDestroyed(this)
        ).subscribe((paths) => {
            this.nbLabeledItems$.next(paths != null ? paths.length : 0);
        });

        this.resolutionTrigger$.pipe(
            switchMap(() => this.labelingService.resolveConsensualRecords()),
            withLatestFrom(this.labelingService.labelingTaskInfo$),
            tap(([result, task]) => {
                this.WT1Service.event("labeling-validate-all", {
                    taskType: task.type,
                    nbResolvedRecords: result.nbResolvedRecords,
                    timeInMs: result.timeInMs,
                    minNbAnnotatorsPerRecord: task.minNbAnnotatorsPerRecord,
                });
            }),
            untilDestroyed(this),
            finalize(() => this.resolvingConsensualRecords$.next(false))
        ).subscribe({
            next: () => {
                this.resolvingConsensualRecords$.next(false);
                this.resolveClass$.next('icon-ok');
                setTimeout(() => this.resolveClass$.next(this.defaultAutoResolveClass), 3000);
                this.labelingReviewService.first();
            },
        });

        this.resolutionDialogTrigger$.pipe(
            untilDestroyed(this)
        ).subscribe(_ => {
            this.modalsService.open(
                ResolutionDialogComponent, 
                undefined,
                ModalShape.NONE,
                this.viewContainerRef,
                {
                    restoreFocus: false,
                    panelClass: ['modal', 'modal3', 'dku-modal-panel-narrow']
                }
            ) .then((confirm)  => {
                if (confirm) {
                    this.resolveConsensualRecords();
                }
            }, () => {});
        });

        this.reviewForm.get('reviewFetchType')?.valueChanges
            .pipe(
                untilDestroyed(this)
            ).subscribe(formValue => {
                this.labelingReviewService.toggleReviewFetchType(formValue);
            }
        );

        this.reviewForm.get('searchQuery')?.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            untilDestroyed(this)
        ).subscribe(
            (formValue) => {
                this.labelingReviewService.filterIds(formValue); 
            }
        )

        this.labelingReviewService.filterQuery$.pipe(
            untilDestroyed(this)
        ).subscribe(query => {
            this.reviewForm.get('searchQuery')?.patchValue(query, {
                emitEvent: false
            });
        });
    }


    openResolveDialog() {
        this.resolutionDialogTrigger$.next();
    }

    resolveConsensualRecords() {
        this.resolvingConsensualRecords$.next(true);
        this.resolutionTrigger$.next();
    }
}
