import { ChangeDetectionStrategy, Component } from "@angular/core";

/**
 * No-op Angular 2+ component added to the <body> of the by AngularJS, to force immediate loading of Angular 2+ modules when DSS is opened
 */
@Component({
    selector: 'ng2-loader',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [':host{display:none}']
})
export class Ng2LoaderComponent {
}
