import { Clipboard } from "@angular/cdk/clipboard";
import { Inject, Injectable } from "@angular/core";
import { APIError, catchAPIError, ErrorContext } from "@core/dataiku-api/api-error";
import { DataikuAPIService } from "@core/dataiku-api/dataiku-api.service";
import { WaitingService } from "@core/overlays/waiting.service";
import { AnnotationGroup } from "@features/labeling/models/annotation-group";
import { CategoryLabelPipe } from '@features/labeling/utils';
import { ResolutionResult } from "@model-main/labeling/consensus/resolution-result";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { resolveSmartName } from "@utils/loc";
import { deepDistinctUntilChanged, SimpleKeyValue } from "dku-frontend-core";
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject } from "rxjs";
import { filter, map, shareReplay, switchMap, withLatestFrom } from "rxjs/operators";
import { AnyLoc, ImageLabelingTask, Label, LabelingAnswer, LabelingRecord, LabelingTask, LabelingTaskStats, ReviewRecordInfo, TextLabelingTask, UsabilityComputer, VerifiedLabelingAnswer, UsersService } from "src/generated-sources";
import { UnusableTaskWarning } from "../labeling-unusable-warning/labeling-unusable-warning.component";
import { UILabel } from "../models/label";
import { getImageURL, isImageTask, isTaskSetProperly, isTextTask, missingMandatorySetting } from "../utils";
import { AnnotationFactory } from "./annotation.factory";
import { LabelingAnswerService } from "./labeling-answer.service";
import { ColorMeaning, LabelingColorService } from "./labeling-color.service";

export enum ImageState {
    CONFLICTING,
    CONSENSUS,
    MISSING_CATEGORY
}
export class LabelingTaskInfo {
    projectKey: string;
    labelingTaskId: string;
    inputMetadata: string;
    idColumn: string | null | undefined;
    minNbAnnotatorsPerRecord: number;
    type: LabelingTask.LabelingTaskType;
    labelsDataset: string | null;
    extraColumns: string[];

    protected constructor(labelingTask: LabelingTask) {
        const labelsDataset = labelingTask.outputs.main.items[0]?.ref;
        this.projectKey = labelingTask.projectKey;
        this.labelingTaskId = labelingTask.id;
        this.inputMetadata = labelingTask.inputs.metadata.items[0].ref;
        this.idColumn = labelingTask.idColumn;
        this.minNbAnnotatorsPerRecord = labelingTask.minNbAnnotatorsPerRecord;
        this.type = labelingTask.type;
        this.labelsDataset = labelsDataset;
        this.extraColumns = labelingTask.extraColumns;
    }

    static createFromTask(labelingTask: LabelingTask): ImageLabelingTaskInfo | TextLabelingTaskInfo {
        if (labelingTask.type === LabelingTask.LabelingTaskType.NAMED_ENTITY_EXTRACTION) {
            return new TextLabelingTaskInfo(labelingTask as TextLabelingTask);
        } else if (labelingTask.type === LabelingTask.LabelingTaskType.IMAGE_CLASSIFICATION ||
            labelingTask.type === LabelingTask.LabelingTaskType.OBJECT_DETECTION) {
            return new ImageLabelingTaskInfo(labelingTask as ImageLabelingTask);
        } else {
            throw new Error("Unknown labeling task type: "+ labelingTask.type);
        }
    }
}

export class TextLabelingTaskInfo extends LabelingTaskInfo {
    dataColumn: string | null | undefined;

    constructor(labelingTask: TextLabelingTask) {
        super(labelingTask);
        this.dataColumn = labelingTask.dataColumn;
    }
}

export class ImageLabelingTaskInfo extends LabelingTaskInfo {
    objectDetectionIOUConflictThreshold: number;
    managedFolderId: string | undefined;

    constructor(labelingTask: ImageLabelingTask) {
        super(labelingTask);
        this.objectDetectionIOUConflictThreshold = labelingTask.objectDetectionIOUConflictThreshold;
        this.managedFolderId = labelingTask.inputs.data?.items[0].ref;
    }

    getManagedFolderLoc() : AnyLoc {
        if (!this.managedFolderId) {
            throw new Error("Labeling task does not have a managed folder as input")
        }
        return resolveSmartName(this.projectKey, this.managedFolderId);
    }
}

@UntilDestroy()
@Injectable()
export class LabelingService implements ErrorContext {

    private error$ = new BehaviorSubject<APIError | undefined>(undefined);
    private labelingTaskInfoTrigger$ = new Subject<LabelingTaskInfo>();
    private labelingTaskInfoSource$ = new ReplaySubject<LabelingTaskInfo>(1);
    
    labelingTaskInfo$ = this.labelingTaskInfoSource$.asObservable();

    private instructionsSource$ = new ReplaySubject<string>(1);
    instructions$ = this.instructionsSource$.asObservable();

    private classesWithInstructionsSource$ = new ReplaySubject<SimpleKeyValue[]>(1);
    classesWithInstructions$ = this.classesWithInstructionsSource$.asObservable();

    classes$: Observable<string[]>;

    private identifierSource$ = new BehaviorSubject<string>('');
    identifier$ = this.identifierSource$.asObservable();

    labelingTaskUnusableReasons$: Observable<UnusableTaskWarning[]>;

    defaultCopyClass = 'dku-icon-copy-step-16';
    copyClass$ = new BehaviorSubject<string>(this.defaultCopyClass);

    allUsersByLogin$: Observable<{[login: string]: UsersService.UIUser}>;

    constructor(
        private DataikuAPI: DataikuAPIService,
        private labelingColorService: LabelingColorService,
        private labelingAnswerService: LabelingAnswerService,
        private annotationFactory: AnnotationFactory,
        private clipboard: Clipboard,
        private waitingService: WaitingService,
        @Inject('$state') private $state: any
    ) {
        this.classes$ = this.classesWithInstructionsSource$.pipe(
            map((classesWithInstructions) => classesWithInstructions.map((c) => c.key))
        );

        this.classes$.pipe(
            untilDestroyed(this)
        ).subscribe((classes) => {
            this.labelingColorService.setColorOptions(ColorMeaning.CLASS, classes);
        });

        this.labelingColorService.setColorOptions(ColorMeaning.ITEM_STATE, [ImageState.CONFLICTING.toString(), ImageState.CONSENSUS.toString(), ImageState.MISSING_CATEGORY.toString()])

        this.labelingTaskInfoTrigger$.pipe(
            deepDistinctUntilChanged(),
            untilDestroyed(this)
        ).subscribe(labelingTask => {
            this.labelingTaskInfoSource$.next(labelingTask);
        });

        this.labelingTaskUnusableReasons$ = combineLatest([
            this.labelingTaskInfoSource$,
            this.classes$
        ]).pipe(
            map(([task, classes]) => {
                const warnings = [];
                const missingSetting = missingMandatorySetting(task);
                if (missingSetting !== null) {
                    warnings.push(new UnusableTaskWarning(`${missingSetting} is not set`, 'settings', 'data'));
                }

                if (isTextTask(task.type) && !(task as TextLabelingTaskInfo).dataColumn) {
                    warnings.push(new UnusableTaskWarning(`Text column is not set`, 'settings', 'data'));
                }

                if (classes.length === 0) {
                    warnings.push(new UnusableTaskWarning(`Labeling task has no ${new CategoryLabelPipe().transform(task.type, true)} defined`, 'settings', 'classes'));
                }
                return warnings;
            }),
        );

        this.allUsersByLogin$ = this.DataikuAPI.security.listUsers().pipe(
            catchAPIError(this),
            shareReplay(1),
            map((allUsers) => {
                return  allUsers.reduce(
                    (obj: { [key: string]: UsersService.UIUser}, item: UsersService.UIUser) => Object.assign(obj, { [item.login]: item }), {});
            })
        );
    }

    setLabelingTask(labelingTask: LabelingTask) {
        if (!labelingTask) {
            return;
        }
        this.labelingTaskInfoTrigger$.next(LabelingTaskInfo.createFromTask(labelingTask));
        this.annotationFactory.init(labelingTask.type);
        this.instructionsSource$.next(labelingTask.instructions);
        this.classesWithInstructionsSource$.next(labelingTask.classes);
    }

    listAnnotatorsIds(): Observable<string[]> {
        return this.labelingTaskInfoSource$.pipe(
            switchMap(taskInfo => this.DataikuAPI.labelingTasks.listAnnotatorIds(
                taskInfo.projectKey, taskInfo.labelingTaskId).pipe(
                    catchAPIError(this)
                ))
        );
    }

    setIdentifier(identifier: string) {
        this.identifierSource$.next(identifier);
    }

    //TODO @labeling move below functions to a labeling-api.service.ts

    fetchRecordIdsToReview(): Observable<string[]> {
        return this.labelingTaskInfoSource$.pipe(
            switchMap(taskInfo => this.DataikuAPI.labelingTasks.getRecordIdsToReview(
                taskInfo.projectKey, taskInfo.labelingTaskId).pipe(
                    catchAPIError(this, true)
                ))
        );
    }

    fetchReviewedRecordIds():  Observable<string[]> {
        return this.labelingTaskInfoSource$.pipe(
            switchMap(taskInfo => this.DataikuAPI.labelingTasks.getReviewedRecordIds(
                taskInfo.projectKey, taskInfo.labelingTaskId).pipe(
                    catchAPIError(this, true)
                ))
        );
    }

    listReviewRecordInfo(recordIds: string[]): Observable<ReviewRecordInfo[]> {
        return this.labelingTaskInfoSource$.pipe(
            switchMap((labelingTaskInfo) => {
                return this.DataikuAPI.labelingTasks.listReviewRecordInfo(labelingTaskInfo.projectKey, labelingTaskInfo.labelingTaskId, recordIds).pipe(catchAPIError(this));
            })
        );
    }

    getManagedFolderLoc(): Observable<AnyLoc> {
        return this.labelingTaskInfoSource$.pipe(
            map((task) => (task as ImageLabelingTaskInfo).getManagedFolderLoc())
        );
    }

    listComputables(): Observable<UsabilityComputer.UsableComputable[]> {
        return this.labelingTaskInfoSource$.pipe(
            switchMap((labelingTaskInfo) => {
                return this.DataikuAPI.flow.listUsableComputables(labelingTaskInfo.projectKey).pipe(catchAPIError(this));
            })
        );
    }

    saveAnswer(answer: Partial<LabelingAnswer>): Observable<LabelingAnswer> {
        return this.labelingTaskInfoSource$.pipe(
            switchMap(taskInfo => this.DataikuAPI.labelingTasks.saveAnswer(
                taskInfo.projectKey, answer
            ).pipe(catchAPIError(this)))
        );
    }

    saveNewAnswer(label: Label, path: string): Observable<LabelingAnswer> {
        return this.labelingTaskInfoSource$.pipe(
            switchMap(taskInfo => this.DataikuAPI.labelingTasks.saveAnswer(
                taskInfo.projectKey, this.labelingAnswerService.createAnswerFromLabel(taskInfo.projectKey, taskInfo.labelingTaskId, label, path)
            ).pipe(catchAPIError(this)))
        );
    }


    resolveRecordFromAnnotation(currentReview: UILabel, path: string): Observable<VerifiedLabelingAnswer> {
        return this.labelingTaskInfoSource$.pipe(
            switchMap(taskInfo => this.DataikuAPI.labelingTasks.resolveRecord(
                taskInfo.projectKey,
                taskInfo.labelingTaskId,
                this.labelingAnswerService.createAnswerFromLabel(taskInfo.projectKey, taskInfo.labelingTaskId,
                    currentReview.toPreparedLabel(),
                    path)
            ).pipe(catchAPIError(this)))
        );
    }

    resolveRecordFromAnnotationGroupList(annotationGroupList: AnnotationGroup[], path: string): Observable<VerifiedLabelingAnswer> {
        return this.labelingTaskInfoSource$.pipe(
            switchMap(taskInfo => this.DataikuAPI.labelingTasks.resolveRecord(
                taskInfo.projectKey,
                taskInfo.labelingTaskId,
                this.labelingAnswerService.answerToSaveFromAnnotationGroupList(taskInfo.projectKey, taskInfo.labelingTaskId, taskInfo.type, annotationGroupList, path)
            ).pipe(catchAPIError(this)))
        );
    }

    resolveConsensualRecords(): Observable<ResolutionResult> {
        return this.labelingTaskInfoSource$.pipe(
            switchMap(taskInfo => this.DataikuAPI.labelingTasks.resolveConsensualRecords(
                taskInfo.projectKey,
                taskInfo.labelingTaskId
            ).pipe(catchAPIError(this)))
        );
    }

    getAnswerFromAnnotator(path: string): Observable<LabelingAnswer | null> {
        return this.labelingTaskInfoSource$.pipe(
            switchMap(taskInfo => this.DataikuAPI.labelingTasks.getAnswerFromAnnotator(
                taskInfo.projectKey,
                taskInfo.labelingTaskId,
                path
            ).pipe(catchAPIError(this)))
        );
    }

    deleteAnswersForRecordId(recordId: string): Observable<void> {
        return this.labelingTaskInfoSource$.pipe(
            switchMap(({projectKey, labelingTaskId}) => this.DataikuAPI.labelingTasks.deleteAnswersForRecordId(projectKey, 
                labelingTaskId, recordId
            ).pipe(catchAPIError(this)))
        )
    }

    getNextRecordToAnnotate(currentRecordId: string): Observable<LabelingRecord | undefined> {
        return this.labelingTaskInfoSource$.pipe(
            filter(isTaskSetProperly),
            switchMap(taskInfo => this.DataikuAPI.labelingTasks.getRecordToAnnotate(
                taskInfo.projectKey,
                taskInfo.labelingTaskId,
                currentRecordId
            ).pipe(
                catchAPIError(this),
                map((response) => response.hasRecord ? response.record! : undefined )))
        );
    }

    getRecord(recordId: string): Observable<LabelingRecord> {
        return this.labelingTaskInfoSource$.pipe(
                filter(isTaskSetProperly),
                switchMap(taskInfo => this.DataikuAPI.labelingTasks.getRecord(
                    taskInfo.projectKey,
                    taskInfo.labelingTaskId,
                    recordId
            ).pipe(catchAPIError(this)))
        );
    }
 
    getStats(): Observable<LabelingTaskStats> {
        return this.labelingTaskInfoSource$.pipe(
            filter(isTaskSetProperly),
            switchMap(taskInfo => this.DataikuAPI.labelingTasks.getStats(
                taskInfo.projectKey,
                taskInfo.labelingTaskId
            ).pipe(
                this.waitingService.bindSpinner(),
                catchAPIError(this)))
        );
    }

    getItemId(itemId: string): Observable<string> {
        return this.labelingTaskInfo$.pipe(
            switchMap((task) => {
                if (isImageTask(task.type)) {
                    return of(task).pipe(
                        withLatestFrom(this.getManagedFolderLoc()),
                        map(([taskInfo, mfLoc]) => getImageURL(taskInfo.projectKey, taskInfo.labelingTaskId, mfLoc, itemId)))
                } else {
                    return of(itemId);
                }
            })
        )
    }

    countAnswers(): Observable<number> {
        return this.labelingTaskInfo$.pipe(
            switchMap((task) => {
                return this.DataikuAPI.labelingTasks.countAnswers(task.projectKey, task.labelingTaskId)
            }),
        )
    }

    pushError(error?: APIError) {
        this.error$.next(error);
    }

    getError(): Observable<APIError | undefined> {
        return this.error$;
    }

    copyPermalinkToClipboard(identifier: string) {
        this.clipboard.copy(this.getPermalink(identifier));
        this.copyClass$.next('dku-icon-checkmark-16');
        setTimeout(() => this.copyClass$.next(this.defaultCopyClass), 3000);
    }

    private getPermalink(identifier: string) {
        return this.$state.href('projects.project.labelingtasks.labelingtask', {
            identifier
        }, { absolute: true });
    }
}
