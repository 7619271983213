import type {_FeaturePreprocessingParams} from './feature-preprocessing-params';

/**
 * Generated from com.dataiku.dip.analysis.model.preprocessing.VectorFeaturePreprocessingParams
 */
export interface VectorFeaturePreprocessingParams extends _FeaturePreprocessingParams {
    impute_constant_value: string;
    missing_handling: VectorFeaturePreprocessingParams.MissingHandlingMethod;
    missing_impute_with: VectorFeaturePreprocessingParams.VectorImputationMethod;
    vector_handling: VectorFeaturePreprocessingParams.VectorHandlingMethod;
}

export namespace VectorFeaturePreprocessingParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.VectorFeaturePreprocessingParams$VectorHandlingMethod
     */
    export enum VectorHandlingMethod {
        UNFOLD = 'UNFOLD'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.VectorFeaturePreprocessingParams$MissingHandlingMethod
     */
    export enum MissingHandlingMethod {
        NONE = 'NONE',
        DROP_ROW = 'DROP_ROW',
        IMPUTE = 'IMPUTE'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.VectorFeaturePreprocessingParams$VectorImputationMethod
     */
    export enum VectorImputationMethod {
        MODE = 'MODE',
        CONSTANT = 'CONSTANT'
    }
}