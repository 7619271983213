import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CarouselService } from '@shared/services/carousel.service';
import { DeephubInteractiveScoringCellData } from '../interactive-scoring-data.service';

@Component({
    selector: 'deephub-report-interactive-scoring-image-header',
    templateUrl: './deephub-report-interactive-scoring-image-header.component.html',
    styleUrls: [
        './deephub-report-interactive-scoring-image-header.component.less',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeephubReportInteractiveScoringImageHeaderComponent {
    @Input() title: string;

    constructor(
        public carouselService: CarouselService<DeephubInteractiveScoringCellData>
    ) { }
}
