import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

export type PlaceholderSize = number | 'small' | 'medium' | 'large';

@Component({
  selector: 'image-placeholder',
  templateUrl: './image-placeholder.component.html',
  styleUrls: ['./image-placeholder.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagePlaceholderComponent {
    @Input() hasLoaded: boolean;
    @Input() noImageMessage: string;
    @Input() set size(value: PlaceholderSize) {
        this.calculatedSize = typeof value === 'string' ? this.sizeMap[value] : value;
    }

    public calculatedSize: number;
    private sizeMap = {
        small: 100,
        medium: 200,
        large: 400,
    };

    constructor() { }
}
