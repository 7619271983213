<div class="new-in-version-popover__main">
  <div class="new-in-version-popover__trigger">
    <ng-content select="[popoverTarget]"></ng-content>
    <div *ngIf="displayMode === 'tooltip-bottom' && displayPopoverTrigger" class="trigger-arrow-up"></div>
    <button *ngIf="displayPopoverTrigger" #popoverTrigger [ngClass]="displayMode" [dkuPopoverTrigger]="popover" (click)="openPopover($event)" [darkBackdrop]="true">
      <span class="new-label">New</span>
      <span class="sr-only">{{ popoverToggleSrOnly }}</span>
    </button>
    <div *ngIf="displayMode === 'tooltip-top' && displayPopoverTrigger" class="trigger-arrow-down"></div>
  </div>

  <dku-popover #popover>
    <div #popoverContent class="new-in-version-popover">
      <div class="new-in-version-popover__banner">
        <img
          class="new-in-version-popover__banner__icon"
          src="/static/dataiku/images/new-in-version/new-features.svg"
        />

        <span class="new-in-version-popover__banner__text"> New in Version {{ targetVersion }}! </span>

        <button type="button" class="new-in-version-popover__banner__close-button" (click)="dismissPopover()">
          <span class="sr-only">Close button</span>
          <i class="dku-icon-dismiss-16"></i>
        </button>
      </div>

      <h4>{{ featureName }}</h4>
      <ng-content select="[popoverContent]"></ng-content>
      <a *ngIf="linkToProductPage" href="{{ linkToProductPage }}" (click)="dismissPopover()" target="_blank" rel="noopener"
        >Learn more about {{ featureName }}</a
      >
      <div class="tar modal-footer-std-buttons">
          <button class="btn btn--text btn--secondary" (click)="dismissPopover()">GOT IT</button>
      </div>
    </div>
  </dku-popover>
</div>
