import { Injectable } from '@angular/core';
import { createBottomLeftLabelArtifact, PainterImage } from '@shared/models/painter';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { PainterService } from '@shared/services/item-feed/image-feed/painter.service';
import { ImageClassificationCellData } from './image-classification-sample-data-formatter.service';

@Injectable()
export class ImageClassificationPainterService extends PainterService {
    constructor(
        private colorMapService: ColorMapContextService
    ) {
        super();
    }

    paintForFeed(cellData: ImageClassificationCellData, image: PainterImage): void {
        const color = this.colorMapService.getColor(cellData.target);
        const artifact = createBottomLeftLabelArtifact(color);
        artifact.text = cellData.target;
        image.addArtifact(artifact);
    }
}
