import type {CardResult, _CardResult} from './../card-result';
import type {Filter} from './../../../compute/filtering/filter';

export namespace GroupedCompiledCardWrapper {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.common.GroupedCompiledCardWrapper$GroupedCardResult
     */
    export interface GroupedCardResult extends _CardResult {
        groups: Filter[];
        results: CardResult[];

        // PolyJson type
        type: 'groups';
    }

    export namespace GroupedCardResult {
        export const type = 'groups';
    }

    /** @deprecated Use CardResult.isGroupedCardResult() */ export function isGroupedCardResult(toTest: CardResult): toTest is GroupedCardResult { return GroupedCardResult.type === toTest['type']; }
}