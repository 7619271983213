<dss-accessible-objects-selector
    [ngModel]="object"
    (ngModelChange)="handleChange($event)"
    [objects]="objects"
    [type]="type"
    [multi]="multi"
    [placeholder]="placeholder"
    [hideForeign]="hideForeign"
    [showObjectLink]="showObjectLink"
    [selectClass]="selectClass">
</dss-accessible-objects-selector>