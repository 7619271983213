export namespace ATSurveyService {
    /**
     * Generated from com.dataiku.dip.server.services.ATSurveyService$ATSurveySettings
     */
    export interface ATSurveySettings {
        nextAction: number;
        state: ATSurveyService.ATSurveyState;
        surveys: {[key: string]: ATSurveyService.ATSurveyOccurrence};
    }

    /**
     * Generated from com.dataiku.dip.server.services.ATSurveyService$ATSurveyState
     */
    export enum ATSurveyState {
        INITIAL = 'INITIAL',
        OPTOUT = 'OPTOUT'
    }

    /**
     * Generated from com.dataiku.dip.server.services.ATSurveyService$ATSurveyOccurrence
     */
    export interface ATSurveyOccurrence {
        nextAction: number;
    }
}