<form [formGroup]="form" class="feature-store__search-form">
    <div class="pull-right">
        <div class="sort-box list-control-widget" style="display: inline-block">
            <span style="display: inline-block;vertical-align:middle;font-size:0;">
                <button class="btn btn--secondary order" [disabled]="disableSortButton()"
                    (click)="form.controls['reverse'].setValue(!form.controls['reverse'].value);"
                >
                    <i class="icon-sort-by-attributes" *ngIf="!reverseOrder()" matTooltip="Ascending order"></i>
                    <i class="icon-sort-by-attributes-alt" *ngIf="reverseOrder()" matTooltip="Descending order"></i>
                </button>
            </span>
            <basic-select 
                formControlName="sortBy"
                [items]="sortOptions"
                bindLabel="label"
                bindValue="value"
                [attr.data-qa-smid]="'sort-order'">
            </basic-select>
        </div>
    </div>
    
    <div class="std-list-search-box" style="display: inline-block" data-block="search-box">
        <i class="icon icon-remove" [hidden]="!hasSearch()" (click)="resetSearch()" [attr.data-qa-smid]="'close-search-button'"></i>
        <span class="add-on" (click)="selectInput()"> <i class="icon-dku-search"></i></span>
        <input type="search"
            #search
            formControlName="query"
            placeholder="Search&hellip;"
            class="catalog-search-input"
            auto-focus="true"
            [attr.data-qa-smid]="'input-search'"
            />
    </div>

    <div class="sort-box list-control-widget" style="display: inline-block">
        <span class="btn-group">
            <button class="btn btn--secondary" [class.btn--active]="isViewByFeatureGroup()" (click)="viewByFeatureGroup()" 
                matTooltip="View by Feature Group" [attr.data-qa-smid]="'view-by-feature-group' + (isViewByFeatureGroup()?'-active':'')">
                <i class="icon-dku-feature-group"></i> By Feature Group
            </button>
            <button class="btn btn--secondary" [class.btn--active]="isViewByFeatureName()" (click)="viewByFeatureName()" 
                matTooltip="View by Feature Name" [attr.data-qa-smid]="'view-by-feature-name' + (isViewByFeatureName()?'-active':'')">
                <i class="icon-dku-feature"></i> By Feature Name
            </button>
        </span>
    </div>
</form>