import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@shared/pipes/pipes.module';
import {
    AppPageModule,
    ArticleInsightViewModule,
    DashboardPageModule,
    DatasetTableInsightEditModule,
    InsightPageModule,
    TextOverflowTooltipModule,
    WebAppInsightViewModule
} from '@migration/modules';
import { WorkspaceErrorMessageModule, WorkspaceRightPanelModule } from '../../../shared';
import {
    AppViewerComponent,
    DashboardViewerComponent,
    DashboardInsightViewerComponent,
    ArticleViewerComponent,
    WebAppViewerComponent,
    DatasetViewerComponent,
    ObjectViewerHostDirective,
    ObjectViewerNavigationComponent
} from './shared';
import { WorkspaceObjectViewerComponent } from './workspace-object-viewer.component';
import { DataikuAPIModule } from '@core/dataiku-api/dataiku-api.module';

@NgModule({
    declarations: [
        WorkspaceObjectViewerComponent,
        AppViewerComponent,
        DashboardInsightViewerComponent,
        DashboardViewerComponent,
        ArticleViewerComponent,
        WebAppViewerComponent,
        DatasetViewerComponent,
        ObjectViewerHostDirective,
        ObjectViewerNavigationComponent
    ],
    imports: [
        CommonModule,
        PipesModule,
        AppPageModule,
        DashboardPageModule,
        InsightPageModule,
        ArticleInsightViewModule,
        DatasetTableInsightEditModule,
        WebAppInsightViewModule,
        WorkspaceRightPanelModule,
        TextOverflowTooltipModule,
        WorkspaceErrorMessageModule,
        DataikuAPIModule
    ],
    exports: [
        WorkspaceObjectViewerComponent
    ]
})
export class WorkspaceObjectViewerModule { }
