import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replace'
})
export class ReplacePipe implements PipeTransform { //TODO rename

    // input | replace:search_str:replacement
    // input | replace:search_regexp:flags:replacement
    transform(input: any, first: string): any {
        let nFirst = parseInt(first, 10);
        let out: any[] = [];
        if (nFirst >= input.length) {
            return out;
        }
        for (let i = nFirst; i < input.length; i++) {
            out.push(input[i]);
        }
        return out;
    }

}
