import type {BivariateHeaderCard} from './../bivariate_header/bivariate-header-card';
import type {Card, _Card} from './../card';
import type {CardResult, _CardResult} from './../card-result';
import type {ColumnCard} from './column-card';
import type {UnivariateHeaderCard} from './../univariate_header/univariate-header-card';
import type {WorksheetRootCard} from './../worksheet-root-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.common.AbstractCardContainer
 */
export interface _AbstractCardContainer extends _Card {
    cards: Card[];
}

export type AbstractCardContainer = WorksheetRootCard | BivariateHeaderCard | ColumnCard | UnivariateHeaderCard;

export namespace AbstractCardContainer {
    export const type = ['worksheet_root', 'bivariate_header', 'column_card', 'univariate_header'] as const;
}

/** @deprecated Use Card.isAbstractCardContainer() */ export function isAbstractCardContainer(toTest: Card): toTest is AbstractCardContainer { return (AbstractCardContainer.type as readonly unknown[]).includes(toTest['type']); }

export namespace AbstractCardContainer {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.common.AbstractCardContainer$AbstractCardContainerResult
     */
    export interface _AbstractCardContainerResult extends _CardResult {
        results: CardResult[];
    }

    export type AbstractCardContainerResult = WorksheetRootCard.WorksheetRootCardResult | BivariateHeaderCard.BivariateHeaderCardResult | ColumnCard.ColumnCardResult | UnivariateHeaderCard.UnivariateHeaderCardResult;

    export namespace AbstractCardContainerResult {
        export const type = ['worksheet_root', 'bivariate_header', 'column_card', 'univariate_header'] as const;
    }

    /** @deprecated Use CardResult.isAbstractCardContainerResult() */ export function isAbstractCardContainerResult(toTest: CardResult): toTest is AbstractCardContainerResult { return (AbstractCardContainerResult.type as readonly unknown[]).includes(toTest['type']); }
}