import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { APIError, catchAPIError, ErrorContext } from "@core/dataiku-api/api-error";
import { CardWizardVariable } from "@features/eda/card-models";
import { SampleContextService } from "@features/eda/sample-context.service";
import { CardWizardService } from "@features/eda/worksheet/card-wizard/card-wizard.service";
import { TimeSeriesCardContext } from "@features/eda/worksheet/cards/config/time-series-card-config/ts-card.context";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { observeFormControl } from "@utils/form-control-observer";
import { toggleFormControls } from "@utils/toggle-form-control";
import { combineLatest, Observable, ReplaySubject, EMPTY, BehaviorSubject, catchError, of } from "rxjs";
import { distinctUntilChanged, filter, map, switchMap, shareReplay, tap, debounceTime } from "rxjs/operators";
import { ACFPlotCard, Card, ListMostFrequentValues, PACF, ResamplerSettings, TimeSeriesCard, UnitRootTestADF, UnitRootTestADFCard, UnitRootTestKPSS, UnitRootTestKPSSCard, UnitRootTestZA, UnitRootTestZACard, Variable, STLDecompositionCard, STLDecomposition, CheckTimeStepRegularity, EdaErrorCodes } from "src/generated-sources";

// For quickly accessing the types and guards.
const {
    InterpolationMethod,
    ExtrapolationMethod,
    TimeUnit,
    DayOfWeek,
    DuplicateTimestampsHandlingMethod,
} = ResamplerSettings;

const {
    isACFPlotCard,
    isUnitRootTestADFCard,
    isUnitRootTestCard,
    isUnitRootTestKPSSCard,
    isUnitRootTestZACard,
} = Card;

@UntilDestroy()
@Component({
    selector: 'time-series-card-config',
    templateUrl: './time-series-card-config.component.html',
    styleUrls: [
        './time-series-card-config.component.less',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        TimeSeriesCardContext,
    ],
})
export class TimeSeriesCardConfigComponent implements OnInit, OnChanges, OnDestroy, ErrorContext {
    @Input() params: TimeSeriesCard;
    params$ = new ReplaySubject<TimeSeriesCard>(1);
    @Output() paramsChange = new EventEmitter<TimeSeriesCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    configForm: FormGroup;
    private resamplerSettingsForm: FormGroup;
    private identifierValuesForm: FormGroup;

    private allVariables$: Observable<CardWizardVariable[]>;
    seriesVariables$: Observable<CardWizardVariable[]>;
    timeVariables$: Observable<CardWizardVariable[]>;
    identifierVariables$: Observable<CardWizardVariable[]>;

    displayTimeStepChecksSpinner$: Observable<boolean>;
    timeStepChecks$: Observable<CheckTimeStepRegularity.CheckTimeStepRegularityResult | Record<string, never>>;
    timeStepChecksErrorCode$: Observable<EdaErrorCodes>;

    // keys are series identifier names
    private valuesMapping = new Map<string, TsIdValuesFormData>();

    error?: APIError | null;
    timeStepChecksAPIError?: APIError | null;

    constructor(
        private fb: FormBuilder,
        private cardWizardService: CardWizardService,
        private changeDetectorRef: ChangeDetectorRef,
        private sampleContextService: SampleContextService,
        private tsCardContext: TimeSeriesCardContext,
    ) {
        // Set default values for the resampling
        this.resamplerSettingsForm = this.fb.group({
            nUnits: this.fb.control(1, [Validators.required, Validators.min(1)]),
            timeUnit: this.fb.control(TimeUnit.DAY, Validators.required),
            timeUnitEndOfWeek: this.fb.control(DayOfWeek.SUNDAY, Validators.required),
            interpolationMethod: this.fb.control(InterpolationMethod.LINEAR, Validators.required),
            interpolationConstantValue: this.fb.control(null, Validators.required),
            extrapolationMethod: this.fb.control(ExtrapolationMethod.NO_EXTRAPOLATION, Validators.required),
            extrapolationConstantValue: this.fb.control(null, Validators.required),
            duplicateTimestampsHandlingMethod: this.fb.control(DuplicateTimestampsHandlingMethod.FAIL_IF_CONFLICTING, Validators.required),
        });

        // Init identifier form
        this.identifierValuesForm = this.fb.group({});

        this.configForm = this.fb.group({
            // Fields that are common to every time series card
            seriesColumn: this.fb.control(null, Validators.required),
            timeColumn: this.fb.control(null, Validators.required),

            // Long format fields
            useLongFormat: this.fb.control(null, Validators.required),
            identifierColumns: this.fb.control([], [Validators.required, Validators.minLength(1)]),
            identifierValues: this.identifierValuesForm,

            // Type of the card to configure (not editable in the UI)
            type: this.fb.control(null, Validators.required),

            // Number of lags (common setting to unit root tests and acf/pacf)
            autoComputeLags: this.fb.control(null, Validators.required),
            nLags: this.fb.control(null, [Validators.required, Validators.min(1)]),

            // Resampler settings
            useResampling: this.fb.control(null, Validators.required),
            resamplerSettings: this.resamplerSettingsForm,

            adfOptions: this.fb.group({
                regressionMode: this.fb.control(null, Validators.required),
            }),

            zaOptions: this.fb.group({
                regressionMode: this.fb.control(null, Validators.required),
            }),

            kpssOptions: this.fb.group({
                regressionMode: this.fb.control(null, Validators.required),
            }),
            stlDecompositionOptions: this.fb.group({
                period: this.fb.control(null, Validators.required),
                trend: this.fb.control(null, Validators.required),
                lowPass: this.fb.control(null, Validators.required),
                autoComputePeriod: this.fb.control(null, Validators.required),
                autoComputeTrend: this.fb.control(null, Validators.required),
                autoComputeLowPass: this.fb.control(null, Validators.required),
                seasonal: this.fb.control(null, Validators.required),
                seasonalJump: this.fb.control(null, Validators.required),
                trendJump: this.fb.control(null, Validators.required),
                lowPassJump: this.fb.control(null, Validators.required),
                decompositionType: this.fb.control(null, Validators.required),
                robust: this.fb.control(null, Validators.required),
                seasonalDeg: this.fb.control(null, Validators.required),
                trendDeg: this.fb.control(null, Validators.required),
                lowPassDeg: this.fb.control(null, Validators.required),
                showSummary: this.fb.control(null, Validators.required),
            }),

            acfPlotOptions: this.fb.group({
                showSummary: this.fb.control(null, Validators.required),
                isPartial: this.fb.control(null, Validators.required),
                adjusted: this.fb.control(null, Validators.required),
                pacfMethod: this.fb.control(null, Validators.required),
            }),
        });

        const timeStepChecksPending$ = new BehaviorSubject(false);
        this.displayTimeStepChecksSpinner$ = timeStepChecksPending$.pipe(debounceTime(400));

        const setApiErrorAndStopSpinner = (error: any) => {
            this.pushTimeStepChecksAPIError(error);
            timeStepChecksPending$.next(false);
            return of({});
        };

        this.timeStepChecks$ = observeFormControl<Variable | null>(this.configForm.controls.timeColumn).pipe(
            map(timeColumn => timeColumn?.name),
            distinctUntilChanged(),
            switchMap(timeColumnName => {
                if (timeColumnName) {
                    timeStepChecksPending$.next(true);
                    this.resetTimeStepChecksAPIError();
                    return this.sampleContextService.runInteractiveQuery({
                        type: CheckTimeStepRegularity.type,
                        column: timeColumnName
                    }).pipe(
                        catchError(setApiErrorAndStopSpinner),
                        tap(() => timeStepChecksPending$.next(false)),
                    );
                }
                return of({});
            }),
            shareReplay(1)
        );

        this.timeStepChecksErrorCode$ = this.timeStepChecks$.pipe(map((timeStepChecks) => {
            const key = timeStepChecks.error_code?.toString() as keyof typeof EdaErrorCodes;
            return EdaErrorCodes[key];
        }));

        toggleFormControls([
            {
                control: this.configForm.controls.adfOptions,
                condition: this.configForm.controls.type,
                value: UnitRootTestADFCard.type
            },
            {
                control: this.configForm.controls.zaOptions,
                condition: this.configForm.controls.type,
                value: UnitRootTestZACard.type
            },
            {
                control: this.configForm.controls.kpssOptions,
                condition: this.configForm.controls.type,
                value: UnitRootTestKPSSCard.type
            },
            {
                control: this.configForm.controls.acfPlotOptions,
                condition: this.configForm.controls.type,
                value: ACFPlotCard.type
            },
            {
                control: this.configForm.controls.stlDecompositionOptions,
                condition: this.configForm.controls.type,
                value: STLDecompositionCard.type
            },
            {
                control: this.configForm.controls.identifierColumns,
                condition: this.configForm.controls.useLongFormat,
                value: true
            },
            {
                control: this.configForm.controls.autoComputeLags,
                condition: this.configForm.controls.type,
                value: (type: Card["type"]) =>
                    type === ACFPlotCard.type ||
                    type === UnitRootTestADFCard.type ||
                    type === UnitRootTestZACard.type ||
                    type === UnitRootTestKPSSCard.type
            },
            {
                control: this.configForm.controls.nLags,
                condition: this.configForm.controls.autoComputeLags,
                value: false
            },
            {
                control: this.resamplerSettingsForm,
                condition: this.configForm.controls.useResampling,
                value: true,
                children: [
                    {
                        control: this.resamplerSettingsForm.controls.timeUnitEndOfWeek,
                        condition: this.resamplerSettingsForm.controls.timeUnit,
                        value: TimeUnit.WEEK
                    },
                    {
                        control: this.resamplerSettingsForm.controls.interpolationConstantValue,
                        condition: this.resamplerSettingsForm.controls.interpolationMethod,
                        value: InterpolationMethod.CONSTANT
                    },
                    {
                        control: this.resamplerSettingsForm.controls.extrapolationConstantValue,
                        condition: this.resamplerSettingsForm.controls.extrapolationMethod,
                        value: ExtrapolationMethod.CONSTANT
                    }
                ]
            },
            {
                control: (this.configForm.controls.stlDecompositionOptions as FormGroup).controls.period,
                condition: (this.configForm.controls.stlDecompositionOptions as FormGroup).controls.autoComputePeriod,
                value: false,
            },
            {
                control: (this.configForm.controls.stlDecompositionOptions as FormGroup).controls.trend,
                condition: (this.configForm.controls.stlDecompositionOptions as FormGroup).controls.autoComputeTrend,
                value: false,
            },
            {
                control: (this.configForm.controls.stlDecompositionOptions as FormGroup).controls.lowPass,
                condition: (this.configForm.controls.stlDecompositionOptions as FormGroup).controls.autoComputeLowPass,
                value: false,
            }
        ]);

        this.configForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(formValue => {
                const resamplerSettings = formValue.useResampling ?
                    formValue.resamplerSettings : null;

                let identifierColumns: CardWizardVariable[] = [];
                let identifierValues: TsIdentifierValues = {};

                if (formValue.useLongFormat) {
                    identifierColumns = formValue.identifierColumns;
                    identifierValues = formValue.identifierValues;
                }

                const seriesIdentifiers: TsIdentifier[] = identifierColumns.map(column => {
                    const key = toFormGroupKey(column.name);
                    const values = identifierValues[key] ?? [];
                    return { column, values };
                });

                let newCard: TimeSeriesCard = {
                    ...this.params,
                    seriesColumn: formValue.seriesColumn,
                    timeColumn: formValue.timeColumn,
                    seriesIdentifiers,
                    resamplerSettings,
                };

                const nLags = formValue.nLags ?? null;

                if (isUnitRootTestADFCard(newCard)) {
                    newCard = {
                        ...newCard,
                        ...formValue.adfOptions,
                        nLags,
                    };
                } else if (isUnitRootTestZACard(newCard)) {
                    newCard = {
                        ...newCard,
                        ...formValue.zaOptions,
                        nLags,
                    };
                } else if (isUnitRootTestKPSSCard(newCard)) {
                    newCard = {
                        ...newCard,
                        ...formValue.kpssOptions,
                        nLags,
                    };
                } else if (isACFPlotCard(newCard)) {
                    newCard = {
                        ...newCard,
                        ...formValue.acfPlotOptions,
                        nLags,
                    };
                } else if (Card.isSTLDecompositionCard(newCard)) {
                    const period = formValue.stlDecompositionOptions.period ?? null;
                    const trend = formValue.stlDecompositionOptions.trend ?? null;
                    const lowPass = formValue.stlDecompositionOptions.lowPass ?? null;

                    newCard = {
                        ...newCard,
                        params: {
                            ...formValue.stlDecompositionOptions,
                            period,
                            trend,
                            lowPass
                        }
                    };
                }

                this.paramsChange.emit(newCard);
            });

        this.configForm.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.validityChange.emit(this.configForm.valid);
            });

        const cardType$ = this.params$.pipe(
            map(params => params.type),
            distinctUntilChanged(),
        );

        this.allVariables$ = cardType$.pipe(
            switchMap(type => this.cardWizardService.availableVariables(type))
        );

        this.seriesVariables$ = cardType$.pipe(
            switchMap(type => this.cardWizardService.availableVariables(type, { isSeriesVariable: true }))
        );

        this.timeVariables$ = cardType$.pipe(
            switchMap(type => this.cardWizardService.availableVariables(type, { isTimeVariable: true }))
        );

        // We disable the selected series / time column to discourage the user
        // from selecting those again as identifiers.
        this.identifierVariables$ = combineLatest([observeFormControl(this.configForm), this.allVariables$]).pipe(
            map(([formValue, allVariables]) => {
                const { seriesColumn, timeColumn } = formValue;
                return allVariables.map(v => {
                    const disabled = v.name === seriesColumn?.name
                        || v.name === timeColumn?.name;

                    return {
                        ...v,
                        disabled,
                    };
                });
            }),
        );

        // Maintain the form consistent with newly added series identifiers.
        observeFormControl<CardWizardVariable[]>(this.configForm.controls.identifierColumns)
            .pipe(
                untilDestroyed(this),
                switchMap(columns => columns.map(column => column.name)),
            )
            .subscribe(columnName => {
                const key = toFormGroupKey(columnName);
                if (this.identifierValuesForm.contains(key)) {
                    return;
                }

                const control = this.fb.control([]);
                this.identifierValuesForm.addControl(key, control);

                const formData = new TsIdValuesFormData(control);
                this.valuesMapping.set(columnName, formData);

                const query: ListMostFrequentValues = {
                    type: ListMostFrequentValues.type,
                    column: columnName,
                    maxValues: 100,
                };

                this.sampleContextService.runInteractiveQuery(query)
                    .pipe(untilDestroyed(this), catchAPIError(this))
                    .subscribe((res: ListMostFrequentValues.ListMostFrequentValuesResult) => {
                        formData.setSuggestions(res.values);
                        this.changeDetectorRef.markForCheck();
                    });
            });
    }

    ngOnInit(): void {
        this.params$
            .pipe(
                untilDestroyed(this),
                filter(params => params.timeColumn == null),
            ).subscribe(() => {
                this.prefillTimeColumn();
            });

        observeFormControl<CardWizardVariable | undefined>(this.configForm.controls.timeColumn)
            .pipe(untilDestroyed(this))
            .subscribe(timeColumn => {
                if (timeColumn == null) {
                    return;
                }

                if (!this.useResampling) {
                    // do not override user provided settings
                    this.prefillResampling(timeColumn);
                }

                if (!this.useLongFormat) {
                    // do not override user provided settings
                    this.prefillSeriesIdentifiers(timeColumn);
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.params == null) {
            return;
        }

        this.params$.next(this.params);
        const card = this.params;
        const { resamplerSettings } = card;
        const seriesIdentifiers = card.seriesIdentifiers ?? [];

        this.configForm.patchValue({
            type: card.type,
            seriesColumn: card.seriesColumn,
            timeColumn: card.timeColumn,
        });

        if (resamplerSettings != null) {
            this.configForm.patchValue({
                useResampling: true,
                resamplerSettings,
            });
        } else {
            this.configForm.patchValue({
                useResampling: false,
            });
        }

        if (seriesIdentifiers.length > 0) {
            this.configForm.patchValue({
                useLongFormat: true,
                identifierColumns: toIdentifierColumns(seriesIdentifiers),
                identifierValues: toIdentifierValues(seriesIdentifiers),
            });
        } else {
            this.configForm.patchValue({
                useLongFormat: false,
            });
        }

        if (isUnitRootTestADFCard(card)) {
            this.configForm.controls.adfOptions.patchValue({
                regressionMode: card.regressionMode,
            });
        } else if (isUnitRootTestZACard(card)) {
            this.configForm.controls.zaOptions.patchValue({
                regressionMode: card.regressionMode,
            });
        } else if (isUnitRootTestKPSSCard(card)) {
            this.configForm.controls.kpssOptions.patchValue({
                regressionMode: card.regressionMode,
            });
        } else if (isACFPlotCard(card)) {
            this.configForm.controls.acfPlotOptions.patchValue({
                showSummary: card.showSummary,
                isPartial: card.isPartial,
                adjusted: card.adjusted,
                pacfMethod: card.pacfMethod,
            });
        } else if (Card.isSTLDecompositionCard(card)) {
            this.configForm.patchValue({
                stlDecompositionOptions: {
                    ...card.params,
                    autoComputePeriod: card.params.period == null,
                    autoComputeTrend: card.params.trend == null,
                    autoComputeLowPass: card.params.lowPass == null,
                },
            });
        }

        if (isACFPlotCard(card) || isUnitRootTestCard(card)) {
            this.configForm.patchValue({
                autoComputeLags: card.nLags == null,
                nLags: card.nLags,
            });
        } else {
            this.configForm.patchValue({
                autoComputeLags: null,
                nLags: null,
            });
        }
    }

    ngOnDestroy(): void {
        // required by @UntilDestroy
    }

    get selectedIdentifiers(): CardWizardVariable[] {
        return (this.configForm.controls.identifierColumns as FormControl).value;
    }

    getTsIdValuesFormData(identifier: CardWizardVariable): TsIdValuesFormData | undefined {
        return this.valuesMapping.get(identifier.name);
    }

    adfRegressionOptions = [
        {
            name: 'Constant only',
            value: UnitRootTestADF.RegressionMode.CONSTANT_ONLY,
        },
        {
            name: 'Constant, linear trend',
            value: UnitRootTestADF.RegressionMode.CONSTANT_WITH_LINEAR_TREND,
        },
        {
            name: 'Constant, linear and quadratic trend',
            value: UnitRootTestADF.RegressionMode.CONSTANT_WITH_LINEAR_QUADRATIC_TREND,
        },
        {
            name: 'No constant, no trend',
            value: UnitRootTestADF.RegressionMode.NO_CONSTANT_NO_TREND,
        },
    ];

    zaRegressionOptions = [
        {
            name: 'Constant only',
            value: UnitRootTestZA.RegressionMode.CONSTANT_ONLY,
        },
        {
            name: 'Trend only',
            value: UnitRootTestZA.RegressionMode.TREND_ONLY,
        },
        {
            name: 'Constant with trend',
            value: UnitRootTestZA.RegressionMode.CONSTANT_WITH_TREND,
        },
    ];

    kpssRegressionOptions = [
        {
            name: 'Constant only',
            value: UnitRootTestKPSS.RegressionMode.CONSTANT,
        },
        {
            name: 'Constant with trend',
            value: UnitRootTestKPSS.RegressionMode.CONSTANT_WITH_TREND,
        },
    ];

    decompositionTypeOptions = [
        {
            name: 'Additive',
            value: STLDecomposition.DecompositionType.ADDITIVE,
        },
        {
            name: 'Multiplicative',
            value: STLDecomposition.DecompositionType.MULTIPLICATIVE,
        },
    ];

    degreeModeOptions = [
        {
            name: 'Constant only',
            value: STLDecomposition.DegreeMode.CONSTANT,
        },
        {
            name: 'Constant with trend',
            value: STLDecomposition.DegreeMode.CONSTANT_WITH_TREND,
        },
    ];

    get isPeriodAuto(): boolean {
        const stlDecompositionOptions = this.configForm.controls.stlDecompositionOptions as FormGroup;
        return stlDecompositionOptions.controls.autoComputePeriod.value === true;
    }

    get isTrendAuto(): boolean {
        const stlDecompositionOptions = this.configForm.controls.stlDecompositionOptions as FormGroup;
        return stlDecompositionOptions.controls.autoComputeTrend.value === true;
    }

    get isLowPassAuto(): boolean {
        const stlDecompositionOptions = this.configForm.controls.stlDecompositionOptions as FormGroup;
        return stlDecompositionOptions.controls.autoComputeLowPass.value === true;
    }

    get isPartialACF(): boolean {
        const acfPlotOptions = this.configForm.controls.acfPlotOptions as FormGroup;
        return acfPlotOptions.controls.isPartial.value === true;
    }

    pacfMethodOptions = [
        {
            name: 'Yule-Walker',
            value: PACF.Method.YULE_WALKER,
        },
        {
            name: 'Regression on lags and on constant',
            value: PACF.Method.OLS,
        },
        {
            name: 'Regression on lags using bias adjustment',
            value: PACF.Method.OLS_UNBIASED,
        },
        {
            name: 'Levinson-Durbin recursion',
            value: PACF.Method.LEVINSON_DURBIN,
        },
    ];

    get useLongFormat(): boolean {
        return this.configForm.controls.useLongFormat.value === true;
    }

    get isAutoComputeLagsEnabled(): boolean {
        return this.configForm.controls.autoComputeLags.enabled;
    }

    get autoComputeLags(): boolean {
        return this.configForm.controls.autoComputeLags.value === true;
    }

    get useResampling(): boolean {
        return this.configForm.controls.useResampling.value === true;
    }

    get hasConstantInterpolation(): boolean {
        return this.resamplerSettingsForm.controls.interpolationMethod.value === InterpolationMethod.CONSTANT;
    }

    get hasConstantExtrapolation(): boolean {
        return this.resamplerSettingsForm.controls.extrapolationMethod.value === ExtrapolationMethod.CONSTANT;
    }

    get hasWeeklyResampling(): boolean {
        return this.resamplerSettingsForm.controls.timeUnit.value === TimeUnit.WEEK;
    }

    interpolationOptions = [
        { name: 'Nearest value', value: InterpolationMethod.NEAREST },
        { name: 'Previous value', value: InterpolationMethod.PREVIOUS },
        { name: 'Next value', value: InterpolationMethod.NEXT },
        { name: 'Linear', value: InterpolationMethod.LINEAR },
        { name: 'Quadratic', value: InterpolationMethod.QUADRATIC },
        { name: 'Cubic', value: InterpolationMethod.CUBIC },
        { name: 'Constant value', value: InterpolationMethod.CONSTANT },
    ];

    extrapolationOptions = [
        { name: 'No extrapolation', value: ExtrapolationMethod.NO_EXTRAPOLATION },
        { name: 'Use previous or next value', value: ExtrapolationMethod.PREVIOUS_NEXT },
        { name: 'Constant value', value: ExtrapolationMethod.CONSTANT },
        { name: 'Linear', value: ExtrapolationMethod.LINEAR },
        { name: 'Quadratic', value: ExtrapolationMethod.QUADRATIC },
        { name: 'Cubic', value: ExtrapolationMethod.CUBIC },
    ];

    timeUnitOptions = [
        { name: 'Millisecond', value: TimeUnit.MILLISECOND },
        { name: 'Second', value: TimeUnit.SECOND },
        { name: 'Minute', value: TimeUnit.MINUTE },
        { name: 'Hour', value: TimeUnit.HOUR },
        { name: 'Business day', value: TimeUnit.BUSINESS_DAY },
        { name: 'Day', value: TimeUnit.DAY },
        { name: 'Week', value: TimeUnit.WEEK },
        { name: 'Month', value: TimeUnit.MONTH },
        { name: 'Quarter', value: TimeUnit.QUARTER },
        { name: 'Half year', value: TimeUnit.HALF_YEAR },
        { name: 'Year', value: TimeUnit.YEAR },
    ];

    dayOfWeekOptions = [
        { name: 'Monday', value: DayOfWeek.MONDAY },
        { name: 'Tuesday', value: DayOfWeek.TUESDAY },
        { name: 'Wednesday', value: DayOfWeek.WEDNESDAY },
        { name: 'Thursday', value: DayOfWeek.THURSDAY },
        { name: 'Friday', value: DayOfWeek.FRIDAY },
        { name: 'Saturday', value: DayOfWeek.SATURDAY },
        { name: 'Sunday', value: DayOfWeek.SUNDAY },
    ];

    duplicateTimestampsHandlingOptions = [
        { name: 'Fail on conflicting duplicates', value: DuplicateTimestampsHandlingMethod.FAIL_IF_CONFLICTING },
        { name: 'Drop all conflicting duplicates', value: DuplicateTimestampsHandlingMethod.DROP_IF_CONFLICTING },
        { name: 'Use mean of numerical variables', value: DuplicateTimestampsHandlingMethod.MEAN_MODE },
    ];

    pushError(error: APIError | null) {
        this.error = error;
        this.changeDetectorRef.markForCheck();
    }

    pushTimeStepChecksAPIError(error: APIError | null) {
        this.timeStepChecksAPIError = error;
        this.changeDetectorRef.markForCheck();
    }

    resetTimeStepChecksAPIError() {
        this.pushTimeStepChecksAPIError(null);
    }

    get hasSettings() {
        return isACFPlotCard(this.params)
            || isUnitRootTestADFCard(this.params)
            || isUnitRootTestKPSSCard(this.params)
            || isUnitRootTestZACard(this.params)
    }

    private prefillTimeColumn(): void {
        const timeColumn = this.tsCardContext.findTimeColumn();

        if (timeColumn != null) {
            this.configForm.patchValue({
                timeColumn,
            });
        }
    }

    private prefillResampling(timeColumn: Variable): void {
        const resamplerSettings = this.tsCardContext.findResampling(timeColumn);

        if (resamplerSettings != null) {
            this.configForm.patchValue({
                resamplerSettings,
            });
        }
    }

    private prefillSeriesIdentifiers(timeColumn: Variable): void {
        const seriesIdentifiers = this.tsCardContext.findSeriesIdentifiers(timeColumn);

        if (seriesIdentifiers.length > 0) {
            this.configForm.patchValue({
                identifierColumns: toIdentifierColumns(seriesIdentifiers),
                identifierValues: toIdentifierValues(seriesIdentifiers),
            });
        }
    }
}

// --- helper functions ---

type TsIdentifier = TimeSeriesCard.TimeSeriesIdentifier;
type FormGroupKey = `fg-${string}`
type TsIdentifierValues = { [name: FormGroupKey]: string[] };

function toIdentifierColumns(identifiers: TsIdentifier[]): Variable[] {
    return identifiers.map(seriesId => seriesId.column);
}

function toIdentifierValues(identiers: TsIdentifier[]): TsIdentifierValues {
    let values: TsIdentifierValues = {};
    identiers.forEach(seriesId => {
        const key = toFormGroupKey(seriesId.column.name);
        values[key] = seriesId.values;
    });

    return values;
}

// adding a prefix to form group keys avoids prototype pollution.
function toFormGroupKey(name: string): FormGroupKey {
    return `fg-${name}`;
}

/**
 * Holds the necessary data for a "time series identifier values" control.
 */
class TsIdValuesFormData {
    isLoading: boolean;
    suggestions: string[];

    constructor(public control: FormControl) {
        this.isLoading = true;
        this.suggestions = [];
    }

    setSuggestions(values: string[]): void {
        this.isLoading = false;
        this.suggestions = values;
    }

    get hasEmptySelection(): boolean {
        return (this.control.value as string[]).length === 0;
    }

    selectAll(): void {
        this.control.setValue([]);
    }
}
