export const SCATTER_SHAPES = [ // font-awesome icons as unicode
    { unicode: 'F111' }, // icon-circle
    { unicode: 'F067' }, // icon-plus
    { unicode: 'F04D' }, // icon-stop
    { unicode: 'F005' }, // icon-star
    { unicode: 'F0A3' }, // icon-certificate
    { unicode: 'F10C' }, // icon-circle-blank
    { unicode: 'F096' }, // icon-check-empty
    { unicode: 'F006' } // icon-star-empty
];
