<div class="waiting">
    <div class="waiting__progress">
        <svg width="120" height="120" viewBox="0 0 120 120" class="waiting__svg">
            <circle [attr.r]="radius" cx="60" cy="60" stroke-width="6" class="waiting__spinner-base" />
            <circle [attr.r]="radius" cx="60" cy="60" stroke-width="6" class="waiting__spinner" />
        </svg>
        <div class="waiting__percentage" *ngIf="started">{{ percentage | number: '1.0-0' }}%</div>
    </div>
    <div class="waiting__message">
        <span [innerHtml]="stackMessage" class="waiting__message-text"></span>
    </div>
    <button (click)="abort()" class="btn btn--danger waiting__abort">Abort</button>
</div>