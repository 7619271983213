import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DateUtilsService {
    /**
   * Convert an UTC date into its counterpart for the specified timezone.
   * Ex: convertDateToTimezone('2020-01-01T00:00:00.000Z', 'America/New_York') will return '2020-12-31T19:00:00.000Z'
   */
    convertDateToTimezone(date: Date, timezone: string) {
        const dateString = date.toLocaleString('en-US', { timeZone: timezone || 'UTC' });
        return new Date(dateString);
    }

    /**
     * Convert an date and a time zone to its UTC counterpart.
     * Ex: convertDateFromTimezone('2020-12-31T19:00:00.000Z', 'America/New_York') will return '2020-01-01T00:00:00.000Z'
     */
    convertDateFromTimezone(date: Date, timezone: string) {
        const offset = this.convertDateToTimezone(date, timezone).getTime() - date.getTime();
        const result = new Date(date.getTime() - offset);
        const checkDate = this.convertDateToTimezone(result, timezone);
        if (date.getTime() === checkDate.getTime()) {
            return result;
        } else {
            // DST bites us
            const dstOffset = date.getTime() - checkDate.getTime();
            return new Date(result.getTime() + dstOffset);
        }
    }

    /**
     * Format the supplied date using the date part of the ISO 8601 notation in local time zone (ex: '2020-01-10')
     */
    formatDateToISOLocalDate(date: Date) {
        const year = date.getFullYear();
        const month = `0${(date.getMonth() + 1)}`.slice(-2);
        const day = `0${date.getDate()}`.slice(-2);
        return `${year}-${month}-${day}`;
    }

    /**
     * Format the supplied date using the time part of the ISO 8601 notation in local time zone (ex: '14:30:00.000')
     */
    formatDateToISOLocalTime(date: Date) {
        const hours = `0${date.getHours()}`.slice(-2);
        const minutes = `0${date.getMinutes()}`.slice(-2);
        const seconds = `0${date.getSeconds()}`.slice(-2);
        const milliseconds = `000${date.getMilliseconds()}`.slice(-3);
        return `${hours}:${minutes}:${seconds}.${milliseconds}`;
    }

    /**
     * Format the supplied date time using ISO 8601 notation in local time zone (ex: '2020-01-10T14:30:00.000')
     */
    formatDateToISOLocalDateTime(date: Date) {
        return this.formatDateToISOLocalDate(date) + 'T' + this.formatDateToISOLocalTime(date);
    }

}
