import { Label, LabelingAnswer, _Label } from 'src/generated-sources';
import { UIAnnotation, UIBoundingBox, UIClassificationAnnotation, UINamedEntity } from './annotation';
import { namedEntityAnnotationsSortingFn } from '../utils';

export abstract class UILabel implements _Label {
    annotations: UIAnnotation[];
    selectable = true;

    canBeSaved(): boolean { 
        return this.annotations && this.annotations.length > 0 && this.annotations.every((a) => !!a.category);
    }

    isEmpty(): boolean {
        return this.annotations.length === 0;
    }

    equals(label: UILabel): boolean {
        if (this.annotations.length !== label.annotations.length) {
            return false;
        }
        for (let i = 0; i < this.annotations.length; i++) {
            if (!this.annotations[i].equals(label.annotations[i])) {
                return false;
            }
        }

        return true;
    }

    abstract toPreparedLabel(): Label;
}

export class ObjectDetectionUILabel extends UILabel {
    annotations: UIBoundingBox[];

    constructor(annotations?: UIBoundingBox[]) {
        super();

        if (annotations) {
            this.annotations = annotations;
        }
    }

    equals(label: UILabel): boolean {
        if (!(label instanceof ObjectDetectionUILabel)) {
            return false;
        }
        return super.equals(label);
    }

    toPreparedLabel(): Label.ObjectDetectionLabel {
        return {
            annotations: this.annotations.map((bbox: UIBoundingBox) => {
                return {
                    category: bbox.category!,
                    bbox: {
                        width: bbox.width,
                        height: bbox.height,
                        x0: bbox.left,
                        y0: bbox.top,
                    }
                }
            }),
            type: 'OBJECT_DETECTION'
        } as Label.ObjectDetectionLabel;
    }
}

export class ImageClassificationUILabel extends UILabel {
    annotations: UIClassificationAnnotation[];
    
    constructor(annotations: UIClassificationAnnotation[]) {
        super();
        this.annotations = annotations;
        this.selectable = false;
    }

    equals(label: UILabel): boolean {
        if (!(label instanceof ImageClassificationUILabel)) {
            return false;
        }
        return super.equals(label);
    }

    toPreparedLabel(): Label.ImageClassificationLabel {
        return {
            annotations: this.annotations.map(annotation => {
                return {
                    category: annotation.category!
                }
            }),
            type: 'IMAGE_CLASSIFICATION'
        } as Label.ImageClassificationLabel
    }
}

export class NamedEntityExtractionUILabel extends UILabel {
    annotations: UINamedEntity[];
    
    constructor(annotations: UINamedEntity[]) {
        super();
        this.annotations = annotations;
        this.selectable = true;
    }

    equals(label: UILabel): boolean {
        if (!(label instanceof NamedEntityExtractionUILabel)) {
            return false;
        }
        return super.equals(label);
    }

    toPreparedLabel(): Label.NamedEntityExtractionLabel {
        return {
            annotations: this.annotations.map(annotation => {
                return {
                    category: annotation.category!,
                    beginningIndex: annotation.beginningIndex,
                    endIndex: annotation.endIndex,
                    text: annotation.text,
                }
            }),
            type: 'NAMED_ENTITY_EXTRACTION'
        } as Label.NamedEntityExtractionLabel
    }

    addAnnotations(annotations: UINamedEntity[]) {
        this.annotations = this.annotations.concat(annotations).sort(namedEntityAnnotationsSortingFn);
    }
}
