import { Injectable } from '@angular/core';
import { DeepHubColumnFormat } from 'src/generated-sources';
import { fabric } from "fabric";
import { Group, IRectOptions, Rect } from 'fabric/fabric-impl';
import { BasePainterOptions, ImagePositionInformation, PainterImage, DivPainterImageArtifact } from '@shared/models/painter';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { BoxHelper } from '@features/deephub/object-detection/utils/box-helper';
import { PainterService } from '@shared/services/item-feed/image-feed/painter.service';
import { DeephubObjectDetectionReportCellData } from './deephub-object-detection-report-data-formatter.service';

export enum BoxType {
    GROUND_TRUTH = 'groundTruth',
    DETECTION = 'detection'
}

export enum EnrichedType {
    FILTERED = 'filtered',
    VALID = 'valid'
}


interface PainterOptions extends BasePainterOptions {
    showResultIcon: boolean,
    selectedStrokeWidth?: number
}

interface CanvasBoxOptions {
    selected?: boolean,
    hovered?: boolean,
    options: PainterOptions
}

export interface CanvasPairedItem {
    boxGrouping: Group,
    groundTruth?: Rect
    detection?: Rect
}

@Injectable({
    providedIn: 'root'
  })
export class DeephubObjectDetectionReportPainterService extends PainterService {
    private STROKE_WIDTH = 2;

    private groundTruth: string | undefined;
    private predicted: string | undefined;
    constructor(
        private colorMapService: ColorMapContextService
    ) {
        super();
    }

    public setClasses(groundTruth?: string, predicted?: string) {
        this.groundTruth = groundTruth;
        this.predicted = predicted;
    }

    paintForFeed(cellData: DeephubObjectDetectionReportCellData, image: PainterImage, imagePosition: ImagePositionInformation): void {
        cellData.enrichedValid.forEach((item: DeepHubColumnFormat.EnrichedObjectDetectionPairedItem) => {
            if (item.groundTruth !== undefined) {
                const box = BoxHelper.createBox(DivPainterImageArtifact, item.groundTruth, imagePosition, {
                    borderColor: this.colorMapService.getColor(item.groundTruth.category),
                    borderWidth: '2px',
                    borderStyle: 'dashed'
                }, 'px');
                if (box !== null) {
                    image.addArtifact(box);
                }
            }

            if (item.detection !== undefined) {
                const box = BoxHelper.createBox(DivPainterImageArtifact, item.detection, imagePosition, {
                    borderColor: this.colorMapService.getColor(item.detection.category),
                    borderWidth: '2px',
                    borderStyle: 'solid'
                }, 'px');
                if (box !== null) {
                    image.addArtifact(box);
                }
            }
        });
    }

    paintForModal(cellData: DeephubObjectDetectionReportCellData, canvas: fabric.StaticCanvas, imagePosition: ImagePositionInformation, options?: PainterOptions): void {
        this.drawBoxesForEnriched(canvas, imagePosition, cellData.enrichedValid, EnrichedType.VALID, options);
        this.drawBoxesForEnriched(canvas, imagePosition, cellData.enrichedFiltered, EnrichedType.FILTERED, options);
    }

    drawBoxesForEnriched(canvas: fabric.StaticCanvas, imagePosition: ImagePositionInformation, enriched: DeepHubColumnFormat.EnrichedObjectDetectionPairedItem[], type: string, options?: PainterOptions): void {
        enriched.forEach((item: DeepHubColumnFormat.EnrichedObjectDetectionPairedItem) => {
            let pairObjects: fabric.Object[] = [];

            if (item.groundTruth !== undefined) {
                const box = BoxHelper.createBox(fabric.Rect, item.groundTruth, imagePosition, {
                    stroke: this.colorMapService.getColor(item.groundTruth.category),
                    strokeDashArray: [7, 4],
                    fill: 'rgba(0, 0, 0, 0)',
                    name: BoxType.GROUND_TRUTH,
                    selectable: false,
                    data: item.groundTruth,
                });
                if (box !== null) {
                    pairObjects.push(box);
                }
            }

            if (item.detection !== undefined) {
                const color = this.colorMapService.getColor(item.detection.category);
                const box = BoxHelper.createBox(fabric.Rect, item.detection, imagePosition, {
                    stroke: color,
                    fill: 'rgba(0, 0, 0, 0)',
                    name: BoxType.DETECTION,
                    selectable: false,
                    data: item.detection
                });
                if (box !== null) {
                    pairObjects.push(box);

                    if (options?.showResultIcon) {
                        pairObjects = pairObjects.concat(this.createStatusIcon(item.groundTruth?.category === item.detection?.category, item.detection.bbox, imagePosition, {
                            fill: color,
                            strokeWidth: this.STROKE_WIDTH
                        }));
                    }
                }
            }

            if (pairObjects.length) {
                const group = new fabric.Group(pairObjects, {
                    name: type,
                    selectable: false,
                    data: {
                        selected: false,
                        hovered: false,
                        options
                    } as CanvasBoxOptions
                });
                const pair = this.createPairItemFromGroup(group);

                // always show valid pairs by default
                this.setPairSelection(pair, type === EnrichedType.VALID);

                canvas.add(group);
            }
        });
    }

    getPairs(canvas: fabric.StaticCanvas): CanvasPairedItem[] {
        return [...(canvas.getObjects() as Group[])]
            .map(group => this.createPairItemFromGroup(group))
            .sort((a, b) => { // sort by reading order, row by row
                const groupA = a.boxGrouping;
                const groupB = b.boxGrouping;

                return groupA.top! - groupB.top! || groupA.left! - groupB.left!;
            });
    }

    createPairItemFromGroup(boxGrouping: Group): CanvasPairedItem {
        const pairItem: CanvasPairedItem = {
            boxGrouping
        };

        boxGrouping.getObjects().forEach(box => {
            const boxType = box.name!;

            // if boxType is null, it means that it's a non-groundtruth/non-detection artifact
            // such as a X or check icon
            if (boxType === BoxType.DETECTION || boxType === BoxType.GROUND_TRUTH) {
                pairItem[boxType] = box;
            }
        });

        return pairItem;
    }

    private createStatusIcon(isCorrect: boolean, detectionBbox: number[], imagePosition: ImagePositionInformation, options: IRectOptions) {
        const padding = {
            x: 3 / imagePosition.scale,
            y: 4 / imagePosition.scale
        };
        const fontSizeRatio = 0.9
        const SUCCESS_ICON = '\uf058';
        const FAIL_ICON = '\uf00d';
        const boxSize = Math.min(20 / imagePosition.scale, detectionBbox[2], detectionBbox[3]);
        const bbox = [...detectionBbox];
        bbox[2] = boxSize;
        bbox[3] = boxSize;

        const container = new fabric.Rect(Object.assign(BoxHelper.getBoxParams(bbox, imagePosition), options));
        const text = new fabric.Text(isCorrect ? SUCCESS_ICON : FAIL_ICON, {
            fill: '#222',
            fontFamily: 'FontAwesome',
            fontSize: boxSize * fontSizeRatio * imagePosition.scale,
            originX: 'center',
            originY: 'center',
            top: imagePosition.top + imagePosition.scale * (bbox[1] + 0.5 * (padding.y + bbox[3])),
            left: imagePosition.left + imagePosition.scale * (bbox[0] + 0.5 * (padding.x + bbox[2])),
            strokeWidth: this.STROKE_WIDTH,
        });

        return [container, text];
    }

    togglePairSelection(pair: CanvasPairedItem) {
        this.setPairSelection(pair, !pair.boxGrouping.data.selected);
        pair.boxGrouping.canvas?.renderAll();
    }

    setPairSelection(pair: CanvasPairedItem, selected: boolean) {
        const data = pair.boxGrouping.data;

        data.selected = selected;

        if (selected) {
            const strokeWidth = this.getStrokeWidth(pair);

            pair.boxGrouping.set({
                visible: true
            });
            pair.groundTruth?.set({
                visible: true,
                strokeWidth
            });
            pair.detection?.set({
                strokeWidth
            });
        } else {
            this.resetPair(pair);
        }
    }

    togglePairHover(pair: CanvasPairedItem) {
        this.setPairHover(pair, !pair.boxGrouping.data.hovered);
        pair.boxGrouping.canvas?.renderAll();
    }

    setPairHover(pair: CanvasPairedItem, hovered: boolean) {
        const data = pair.boxGrouping.data;

        data.hovered = hovered;

        if (hovered) {
            pair.boxGrouping.set({
                visible: true
            });
            pair.groundTruth?.set({
                visible: true
            });

            // increase stroke width even more if already selected
            if (data.selected) {
                const strokeWidth = this.getStrokeWidth(pair);

                pair.groundTruth?.set({
                    strokeWidth
                });

                pair.detection?.set({
                    strokeWidth
                });
            }
        } else {
            this.resetPair(pair);
        }
    }

    resetPair(pair: CanvasPairedItem) {
        const data = pair.boxGrouping.data;
        const strokeWidth = this.getStrokeWidth(pair);

        if (!data.selected && !data.hovered) {
            pair.boxGrouping.set({
                visible: false
            });
        }

        pair.groundTruth?.set({
            strokeWidth
        });

        pair.detection?.set({
            strokeWidth
        });
    }

    private getStrokeWidth(pair: CanvasPairedItem) {
        const data = pair.boxGrouping.data;
        let strokeWidth = this.STROKE_WIDTH;

        if (data.options?.selectedStrokeWidth) {
            if (data.selected) {
                strokeWidth = data.options?.selectedStrokeWidth + (data.hovered ? 1 : 0);
            }
        }

        return strokeWidth;
    }
}
