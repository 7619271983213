<div *ngIf="payload" class="grouping-right-panel-summary">
    <div *ngIf="isFilterActive(payload.preFilter)" class="grouping-right-panel-summary__groupings-filter grouping-right-panel-summary__title">
        <h4>Pre-filter</h4>
        <div>
            <div *ngIf="payload.preFilter.distinct">Distinct rows only</div>
            <div *ngIf="payload.preFilter.enabled" [innerHTML]="getPreconditionsPreText(payload.preFilter) + (payload.preFilter | filterNiceRepr)" ></div>
        </div>
    </div>

    <div class="grouping-right-panel-summary__title" *ngIf="payload.keys.length > 0">
        <h4>{{'Group Key' | plurify: payload.keys.length}}</h4>
    </div>
    <div *ngFor="let key of payload.keys">
        <p *ngIf="key.column"><span class="column-name">{{key.column}}</span></p>
    </div>

    <div class="grouping-right-panel-summary__title" *ngIf="hasAggregation(payload.values)">
        <h4>{{'Aggregation' | plurify: countAggregations(payload.values)}}</h4>
    </div>

    <div *ngFor="let aggregateItem of getFlatAggregates(payload.values)">
        <span>{{aggregateItem.agg | niceConst}} of <span class="column-name">{{aggregateItem.column}}</span></span>
    </div>
    <div *ngFor="let aggregateItem of getCustomAggregations(payload.values)">
        <span class="column-name">{{aggregateItem.customExpr}}</span>
    </div>

    <div *ngIf="isFilterActive(payload.postFilter)" class="grouping-right-panel-summary__groupings-filter grouping-right-panel-summary__title">
        <h4>Post-filter</h4>
        <div *ngIf="payload.postFilter.distinct">Distinct rows only</div>
        <div *ngIf="payload.postFilter.enabled" [innerHTML]="getPreconditionsPreText(payload.postFilter) + (payload.postFilter | filterNiceRepr)"></div>
    </div>
</div>
