import { FormControl, FormGroup, Validators } from '@angular/forms';
import { observeFormControl } from '@utils/form-control-observer';
import { merge, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { PROJECT_VALUES, TYPES } from './object-types';

export interface AddObjectsFormValue {
  type: string | null;
  projectKey?: string | null;
  objects?: unknown[];
  url?: string | null;
  name?: string | null;
  description?: string | null;
}

export class AddObjectsForm extends FormGroup {
    get type(): FormControl {
        return this.get('type') as FormControl;
    }

    get projectKey(): FormControl {
        return this.get('projectKey') as FormControl;
    }

    get objects(): FormControl {
        return this.get('objects') as FormControl;
    }

    get url(): FormControl {
        return this.get('url') as FormControl;
    }

    get name(): FormControl {
        return this.get('name') as FormControl;
    }

    get description(): FormControl {
        return this.get('description') as FormControl;
    }

    private readonly destroy$ = new Subject<void>();

    constructor() {
        super({
            type: new FormControl(null, Validators.required),
            projectKey: new FormControl({ value: null, disabled: true }, Validators.required),
            objects: new FormControl({ value: [], disabled: true }, Validators.required),
            url: new FormControl({ value: null, disabled: true }, Validators.required),
            name: new FormControl({ value: null, disabled: true }, Validators.required),
            description: new FormControl({ value: null, disabled: true })
        });

        this.updateControlStatusesOnFormValueChange();
        this.handleTypeOrProjectKeyChange();
    }

    onDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private handleTypeOrProjectKeyChange(): void {
        merge(observeFormControl<string>(this.type), observeFormControl<string>(this.projectKey)).pipe(
            tap(() => this.objects.setValue([])),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    private updateControlStatusesOnFormValueChange(): void {
        observeFormControl<AddObjectsFormValue>(this).pipe(
            tap(() => {
                // A disabled control always return undefined, so to be sure to have the actual value of a control that may have been enabled in here we use the form values and not the Observable ones.
                this.updateProjectKeyStatus(this.type.value);
                this.updateObjectsStatus(this.type.value, this.projectKey.value, this.projectKey.enabled);
                this.updateUrlTitleAndDescriptionStatuses(this.type.value);
            }),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    private updateProjectKeyStatus(type: string | null): void {
        if (!!type && PROJECT_VALUES.find(validName => validName === type)) {
            this.projectKey.enable({ emitEvent: false });
        } else {
            this.projectKey.disable({ emitEvent: false });
        }
    }

    private updateObjectsStatus(type: TYPES | null, projectKey: string | null, isProjectKeyEnabled: boolean): void {
        if (type === 'APP' || !!type && !!projectKey && isProjectKeyEnabled) {
            this.objects.enable({ emitEvent: false });
        } else {
            this.objects.disable({ emitEvent: false });
        }
    }

    private updateUrlTitleAndDescriptionStatuses(type: TYPES | null): void {
        if (type === 'LINK') {
            this.url.enable({ emitEvent: false });
            this.name.enable({ emitEvent: false });
            this.description.enable({ emitEvent: false });
        } else {
            this.url.disable({ emitEvent: false });
            this.name.disable({ emitEvent: false });
            this.description.disable({ emitEvent: false });
        }
    }
}
