import { cloneDeep } from 'lodash';

export class HistoryBuffer<T> {
    private history: T[];
    private currentIndex = -1;
    private MAX_HISTORY_SIZE = 100;

    private get currentItem() {
        // send copy of item so state isn't modified externally
        return cloneDeep(this.history[this.currentIndex]);
    }

    constructor() {
        this.history = [];
    }

    canUndo() {
        return this.history.length > 1 && this.currentIndex > 0;
    }

    canRedo() {
        return this.history.length > 0 && this.currentIndex < this.history.length - 1;
    }

    push(item: T) {
        this.history = [...this.history.slice(0, this.currentIndex + 1), cloneDeep(item)];
        if (this.history.length > this.MAX_HISTORY_SIZE) {
            this.history.shift();
        } else {
            this.currentIndex++;
        }
    }

    clear() {
        this.currentIndex = -1;
        this.history = [];
    }

    undo() {
        if (this.canUndo()) {
            this.currentIndex--;
        }

    
        return this.currentItem;
    }

    redo() {
        if (this.canRedo()) {
            this.currentIndex++;
        }


        return this.currentItem;
    }
}
