import type {AuditTrailTargetSettings, _AuditTrailTargetSettings} from './audit-trail-target-settings';

/**
 * Generated from com.dataiku.dip.security.audit.model.EventServerTargetSettings
 */
export interface EventServerTargetSettings extends _AuditTrailTargetSettings {
    authKey: string;
    autoconfigureFromNodesDirectory: boolean;
    routingKey: string;
    routingKeyMode: EventServerTargetSettings.RoutingKeyMode;
    trustAllSSLCertificates: boolean;
    url: string;

    // PolyJson type
    type: 'EVENT_SERVER';
}

export namespace EventServerTargetSettings {
    export const type = 'EVENT_SERVER';
}

/** @deprecated Use AuditTrailTargetSettings.isEventServerTargetSettings() */ export function isEventServerTargetSettings(toTest: AuditTrailTargetSettings): toTest is EventServerTargetSettings { return EventServerTargetSettings.type === toTest['type']; }

export namespace EventServerTargetSettings {
    /**
     * Generated from com.dataiku.dip.security.audit.model.EventServerTargetSettings$RoutingKeyMode
     */
    export enum RoutingKeyMode {
        FROM_MESSAGE = 'FROM_MESSAGE',
        STATIC = 'STATIC',
        STATIC_IF_MISSING = 'STATIC_IF_MISSING'
    }
}