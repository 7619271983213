import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CurrentRouteService } from "@core/nav/current-route.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { resolveSmartName } from "@utils/loc";
import { ManagedFolder } from '@shared/utils/managed-folder';
import { cloneDeep } from "lodash";
import { Observable, Subject } from "rxjs";
import { filter, map, startWith } from "rxjs/operators";
import { DataAugmentationService, DeepHubPreTrainModelingParams } from "src/generated-sources";
import { DataAugmentationFetchingService } from "./data-augmentation.service";


export class AugmentationTypeInfo {
    augmentationType: DataAugmentationService.AugmentationType;

    constructor(augmentationType: DataAugmentationService.AugmentationType) {
        this.augmentationType = augmentationType;
    }

    getKeyOfAugmentationParams(): keyof DeepHubPreTrainModelingParams.ImageAugmentationParams {
        switch(this.augmentationType) {
            case DataAugmentationService.AugmentationType.COLOR:
                return "colorJitter";
            case DataAugmentationService.AugmentationType.AFFINE:
                return "affine"
            case DataAugmentationService.AugmentationType.CROP:
                return "crop";
        }
    }

    isDisabled(augmentationParams: DeepHubPreTrainModelingParams.ImageAugmentationParams): boolean {
        switch(this.augmentationType) {
            case DataAugmentationService.AugmentationType.COLOR:
                return !augmentationParams.colorJitter.enabled;
            case DataAugmentationService.AugmentationType.AFFINE:
                return (!augmentationParams.affine.horizontalFlip.enabled
                     && !augmentationParams.affine.verticalFlip.enabled
                     && !augmentationParams.affine.rotate.enabled);
            case DataAugmentationService.AugmentationType.CROP:
                return !augmentationParams.crop.enabled;
        }
    }
}

@UntilDestroy()
@Component({
    selector: 'deephub-design-data-augmentation',
    templateUrl: './deephub-design-data-augmentation.component.html',
    styleUrls: ['./deephub-design-data-augmentation.component.less'],
    providers: [DataAugmentationFetchingService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeephubDesignDataAugmentationComponent implements OnInit {

    @Input() augmentationParams: DeepHubPreTrainModelingParams.ImageAugmentationParams;
    @Input() managedFolderSmartId: string;
    @Output() augmentationParamsChange = new EventEmitter<DeepHubPreTrainModelingParams.ImageAugmentationParams>();

    imagePath$ = new Subject<string>();
    augmentationParams$: Observable<DeepHubPreTrainModelingParams.ImageAugmentationParams>;
    augmentationParamsDisabled$: Observable<boolean>;
    error$ = this.dataAugmentationFetchingService.getError();


    form: FormGroup = this.fb.group({
        colorJitter: this.fb.group({
            enabled: this.fb.control(true, [Validators.required])
        }),
        affine: this.fb.group({
            verticalFlip: this.fb.group({
                enabled: this.fb.control(true, [Validators.required])
            }),
            horizontalFlip: this.fb.group({
                enabled: this.fb.control(true, [Validators.required])
            }),
            rotate: this.fb.group({
                enabled: this.fb.control(true, [Validators.required]),
                maxRotation: this.fb.control(0, [Validators.required, Validators.min(0), Validators.max(360)]),
            })
        }),
        crop: this.fb.group({
            enabled: this.fb.control(true, [Validators.required]),
            minKeptRatio: this.fb.control(0, [Validators.required, Validators.min(0.01), Validators.max(1.)]),
        }),

    })

    constructor(private fb: FormBuilder,
        private currentRoute: CurrentRouteService,
        private dataAugmentationFetchingService: DataAugmentationFetchingService){

    }

    ngOnInit(): void {
        this.form.patchValue(this.augmentationParams);

        this.augmentationParams$ = this.form.valueChanges.pipe(
            untilDestroyed(this),
            startWith(this.augmentationParams),
            filter(() => {
                return this.form.valid;
            }),
            map(() => {
                return this.augmentationParamsFromForm(this.form.getRawValue())}) // use raw value to also get disabled controls
        );

        this.augmentationParams$.subscribe(augmentationParams => {
            this.augmentationParamsChange.emit(augmentationParams);
            this.toggleFormDisable();
        });

        this.augmentationParamsDisabled$ = this.augmentationParams$.pipe(map(this.augmentationDisabled));

        this.getNewImagePath();
    }


    private augmentationParamsFromForm(formValue: DeepHubPreTrainModelingParams.ImageAugmentationParams): DeepHubPreTrainModelingParams.ImageAugmentationParams {
        // Patching formValue with other non form parameters;
        const augmentationParams = cloneDeep(this.augmentationParams);

        augmentationParams.affine.horizontalFlip.enabled = formValue.affine.horizontalFlip.enabled;
        augmentationParams.affine.verticalFlip.enabled = formValue.affine.verticalFlip.enabled;
        augmentationParams.affine.rotate.enabled = formValue.affine.rotate.enabled;
        augmentationParams.affine.rotate.maxRotation = formValue.affine.rotate.maxRotation;
        augmentationParams.colorJitter.enabled = formValue.colorJitter.enabled;
        augmentationParams.crop.enabled = formValue.crop.enabled;
        augmentationParams.crop.minKeptRatio = formValue.crop.minKeptRatio;

        return augmentationParams;
    }

    // Small utility to enable/disable all controls of a group if the "enabled" toggle was triggered
    private toggleFormDisable() {
        // For the future reader, I have tried to have something more generic and more clever than
        // going and fetch the known groups that we may want to enable / disable but it turned out
        // too complex
        const cropGroup = this.form.get("crop") as FormGroup;
        this.toggleControl(cropGroup, "minKeptRatio");

        const rotateGroup = this.form.get("affine")?.get("rotate") as FormGroup;
        this.toggleControl(rotateGroup, "maxRotation");
    }

    private toggleControl(formGroup: FormGroup, controlName: string) {
        // Do not emit events in order not to trigger infinte value changes in the form
        const enabled = formGroup?.get("enabled")?.value;
        if (enabled) {
            formGroup.get(controlName)?.enable({emitEvent: false});
        } else {
            formGroup.get(controlName)?.disable({emitEvent: false});
        }
    }

    private augmentationDisabled(augmentationParams: DeepHubPreTrainModelingParams.ImageAugmentationParams) : boolean {
        return Object.values(DataAugmentationService.AugmentationType).map(augmentationType => new AugmentationTypeInfo(augmentationType))
                                                                      .every(augmentationTypeInfo => augmentationTypeInfo.isDisabled(augmentationParams));
    }

    getImagePath(itemPath: string): string {
        return new ManagedFolder(resolveSmartName(this.currentRoute.projectKey, this.managedFolderSmartId), this.currentRoute.projectKey).getImagePath(itemPath);
    }

    resetError() {
        this.dataAugmentationFetchingService.resetError();
    }

    get AugmentationType() {
        return DataAugmentationService.AugmentationType;
    }

    getAugmentationTypeInfo(augmentationType: DataAugmentationService.AugmentationType): AugmentationTypeInfo {
        return new AugmentationTypeInfo(augmentationType);
    }

    getNewImagePath() {
        this.dataAugmentationFetchingService.getRandomImagePaths(1).pipe(untilDestroyed(this)).subscribe((imagesPath) => {
            this.imagePath$.next(imagesPath[0]);
        });
    };
}
