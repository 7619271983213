import type {_FeaturePreprocessingParams} from './feature-preprocessing-params';

/**
 * Generated from com.dataiku.dip.analysis.model.preprocessing.ImageFeaturePreprocessingParams
 */
export interface ImageFeaturePreprocessingParams extends _FeaturePreprocessingParams {
    image_handling: ImageFeaturePreprocessingParams.ImageHandlingMethod;
    managed_folder_id: string;
    missing_handling: ImageFeaturePreprocessingParams.MissingHandlingMethod;
}

export namespace ImageFeaturePreprocessingParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.ImageFeaturePreprocessingParams$MissingHandlingMethod
     */
    export enum MissingHandlingMethod {
        NONE = 'NONE',
        DROP_ROW = 'DROP_ROW'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.ImageFeaturePreprocessingParams$ImageHandlingMethod
     */
    export enum ImageHandlingMethod {
        CUSTOM = 'CUSTOM'
    }
}