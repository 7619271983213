import { Component, Input, forwardRef, OnDestroy } from '@angular/core';
import { EditableListBase } from '../editable-list/editable-list.component';
import { NG_VALUE_ACCESSOR, FormBuilder, Validators } from '@angular/forms';
import { FormArrayRepeat } from '@utils/form-array-repeat';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { TolerantFormGroup } from '@utils/tolerant-form-group';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'key-values-list',
    templateUrl: './key-values-list.component.html',
    styleUrls: ['./key-values-list.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => KeyValuesListComponent),
            multi: true
        }
    ]
})

/**
 * Editable list of key / value pairs.
 * Uses editable-list-component with a dedicated template.
 *
 * @example
 * <key-values-list items="items"></key-values-list>
 */
export class KeyValuesListComponent extends EditableListBase implements OnDestroy {
    @Input() addLabel: string = 'Add Key/Value';
    @Input() keyPlaceholder: string = 'A key';
    @Input() valuePlaceholder: string = 'A value';
    @Input() iconIdentifier: string = 'icon';
    @Input() iconFn?: (item: any) => string;
    @Input() keyIdentifier: string = 'key';
    @Input() valueIdentifier: string = 'value';
    @Input() keyRequired: boolean = false;
    @Input() valueRequired: boolean = false;
    @Input() required: boolean = true;
    @Input() warnIfTrimmable: boolean = false;
    // suggestions for mat-autocomplete
    @Input() keySuggestions: string[] = [];
    @Input() valueSuggestions: string[] = [];
    @Input() confirmDeletion: boolean = false;
    formBuilder: FormBuilder;
    filteredKeySuggestions: string[] = [];
    filteredValueSuggestions: string[] = [];
    private changesSubscription: Subscription;

    constructor(fb: FormBuilder) {
        super();
        this.formBuilder = fb;
    }

    ngOnChanges() {
        let keyValidators: any[] = [];
        let valueValidators: any[] = [];

        if (this.required) {
            this.keyRequired = true;
            this.valueRequired = true;
        }

        if (this.keyRequired) {
            keyValidators.push(Validators.required);
        }

        if (this.valueRequired) {
            valueValidators.push(Validators.required);
        }

        this.itemsFormArray = new FormArrayRepeat(() => {
            return new TolerantFormGroup({
                [this.keyIdentifier]: this.formBuilder.control('', keyValidators),
                [this.valueIdentifier]: this.formBuilder.control('', valueValidators)
            });
        });

        if (this.items && this.items.length) {
            this.itemsFormArray.setValue(this.items);
        }

        if (this.changesSubscription) {
            this.changesSubscription.unsubscribe();
        }

        this.changesSubscription = this.itemsFormArray.valueChanges
            .pipe(
                untilDestroyed(this),
                debounceTime(100)
            )
            .subscribe((items) => {
                this.items = items;
                this.onChange(items);
            });
    }

    handleFocus($event: FocusEvent, identifier: string) {
        const item = ($event.target as HTMLInputElement)?.value;

        // set current value to reset autocomplete
        this.setSuggestions(item, identifier);

        this.onFocus.emit($event);
    }

    setSuggestions(entry: string, identifier: string) {
        if (identifier === this.keyIdentifier) {
            this.filteredKeySuggestions = this.getFilteredSuggestions(entry,  this.keyIdentifier, this.keySuggestions);
        } else if (identifier === this.valueIdentifier) {
            this.filteredValueSuggestions = this.getFilteredSuggestions(entry, this.valueIdentifier, this.valueSuggestions);
        }
    }

    // for autocomplete suggestions
    getFilteredSuggestions(entry: string, identifier: string, suggestions: string[]) {
        const filteredSuggestions = [];
        if (suggestions && suggestions.length) {
            const lowercaseValue = entry.toLowerCase();
            for (const candidate of suggestions) {
                if (candidate && candidate.toLowerCase().includes(lowercaseValue)
                    && !(this.items as {[key: string]: any}[]).map(item => item[identifier]).includes(candidate)) {
                    filteredSuggestions.push(candidate);
                }
            }
        }
        filteredSuggestions.sort();

        return filteredSuggestions;
    }

    ngOnDestroy(): void { }
}
