import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchAPIError, SimpleObservableErrorContext } from '@core/dataiku-api/api-error';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { ROLE_LIST } from '../../models';
import { Role } from '@shared/models';
import { map, Observable, shareReplay } from 'rxjs';
import { BasePermissions, ITaggingService, UsersService } from 'src/generated-sources';
import { DataCollectionsService } from '../../services/data-collections.service';
import { isEqual } from "lodash";

interface EditSecuritySettingsOptions {
    id: string;
    displayName: string;
    permissions: BasePermissions.PermissionItem[];
}

@Component({
    selector: 'dss-edit-security-settings-modal',
    templateUrl: './edit-security-settings-modal.component.html',
    styleUrls: ['./edit-security-settings-modal.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSecuritySettingsModalComponent {
    modalTitle: string;
    canSave: boolean = false;
    errorContext = new SimpleObservableErrorContext();
    permissions: BasePermissions.PermissionItem[];
    roleList: Role[];
    objectType = ITaggingService.TaggableType.DATA_COLLECTION;

    users$ = this.dataikuAPI.security.listUsers() as Observable<UsersService.UIUser[]>;
    groups$ = this.dataikuAPI.security.listGroups(false).pipe(map(groups => ['$$ALL_USERS$$', ...groups]));

    dataCollectionInfo$ = this.dataCollectionsService.getInfo(this.data.id).pipe(
        catchAPIError(this.errorContext),
        shareReplay(1),
    );

    constructor(
        private dialogRef: MatDialogRef<EditSecuritySettingsModalComponent, boolean>,
        @Inject(MAT_DIALOG_DATA) private data: EditSecuritySettingsOptions,
        private dataikuAPI: DataikuAPIService,
        private dataCollectionsService: DataCollectionsService
    ) {
        this.modalTitle = 'Edit security settings for "' + this.data.displayName + '"';
        this.roleList = ROLE_LIST;
        this.permissions = this.data.permissions;
    }

    onPermissionsChange(changes: { permissions: BasePermissions.PermissionItem[] }) {
        this.permissions = changes.permissions;
        this.canSave = !isEqual(this.permissions, this.data.permissions);
    }

    cancel(): void {
        this.dialogRef.close(false);
    }
    confirm(): void {
        this.dataCollectionsService.updatePermissions(this.data.id, this.permissions).pipe(
            catchAPIError(this.errorContext),
        ).subscribe(() => {
            this.dialogRef.close(true);
        });
    }
}
