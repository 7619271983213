import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { ColorUtilsService } from '../../../services';

@UntilDestroy()
@Component({
    selector: 'chart-color-picker-item',
    templateUrl: './chart-color-picker-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartColorPickerItemComponent implements OnInit {
    private selectedColor$ = new BehaviorSubject<string | null>(null);

    color$ = new BehaviorSubject<string | null>(null);
    itemClass$ = new BehaviorSubject<string | null>(null);

    @Input()
    set color(value: string | null) {
        this.color$.next(value);
    }

    @Input()
    set selectedColor(value: string | null) {
        this.selectedColor$.next(this.getStandardColor(value));
    }

    constructor(private colorUtilsService: ColorUtilsService) {}

    ngOnInit() {
        combineLatest([this.color$, this.selectedColor$])
            .pipe(untilDestroyed(this))
            .subscribe(([color, selectedColor]) => {
                if (color?.toUpperCase() === selectedColor?.toUpperCase()) {
                    this.itemClass$.next('palette-color-picker__item--selected');
                } else if (color && color.toUpperCase() === '#FFFFFF') {
                    this.itemClass$.next('palette-color-picker__item--white');
                } else {
                    this.itemClass$.next(null);
                }
            });
    }

    private getStandardColor(color: string | null): string | null {
        let standardColor = color;

        if (color) {
            const rgba = this.colorUtilsService.strToRGBA(color);
            standardColor = this.colorUtilsService.RGBAToHex(rgba, false);
        }

        return standardColor;
    }
}
