<p>
    The Shapiro-Wilk test is a statistical procedure used to test the hypothesis that your data
    could have been drawn from a normal population.
</p>
<p>
    There are two possible outcomes depending on the 𝘱-value:
</p>
<ul>
    <li>
        <b>
            𝘱-value &le; significance level (&le; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>
        <br>
        The hypothesis is rejected.
        <b>{{ params.column.name }}</b>
        is not normally distributed.
    </li>
    <li>
        <b>
            𝘱-value is &gt; significance level (&gt; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>

        <br>
        Not enough evidence against the hypothesis. Test is inconclusive.
    </li>
</ul>
<p>
    It has been shown that the conclusion is meaningful under the following conditions:
</p>
<ul>
    <li>
        Observations of
        <b>{{ params.column.name }}</b>
        are independent and identically distributed
    </li>
    <li>
        Sample size is not too large (&lt; 5000)
    </li>
</ul>
<p>
    <span class="text-weak">
        <i class="icon-info-sign"></i>
        Missing values are discarded
    </span>
    <br>
    <span class="text-weak">
        <i class="icon-info-sign"></i>
        &ngsp;
        <a
            href="https://docs.scipy.org/doc/scipy/reference/generated/scipy.stats.shapiro.html"
            target="_blank"
        >More information
        </a>
    </span>
</p>
