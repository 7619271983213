
<ng-select
    [formControl]="projectControl"
    appendTo="body"
    [virtualScroll]="true"
    [placeholder]="'Select a project'"
    [clearable]="false"
    [searchable]="false"
    [searchFn]="searchFn"
    [items]="(filteredProjects$ | async) ?? []"
    bindLabel="name"
    bindValue="projectKey">
    <ng-template ng-option-tmp let-item="item">
        <ng-container *ngTemplateOutlet="optionItem; context: { item: item }"></ng-container>
    </ng-template>

    <ng-template ng-header-tmp>
        <ng-select-search-input></ng-select-search-input>
    </ng-template>
</ng-select>

<ng-template #optionItem let-item="item">
    {{ item.name }} ({{ item.projectKey }})
</ng-template>
