<button
    class="btn header-button trigger subnav-page-header-name"
    *ngIf="params.highlightFilter"
    [disabled]="readOnly"
    [matTooltip]="params.highlightFilter | filterName"
    (click)="dropHighlight()"
>
    Selection:
    <span class="selection-name">
        {{ params.highlightFilter | filterName }}
    </span>
    <i class="icon-dku-close" *ngIf="!readOnly"></i>
</button>
