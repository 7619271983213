<div>
    <experiment-tracking-header [intermediateLinks]="intermediateLinks"></experiment-tracking-header>
    <div class="dss-page">
        <div class="runs-table-box h100">
            <api-error-alert *ngIf="error" [apiError]="error" [closable]="false"></api-error-alert>
            <div *ngIf="experimentIdsOnError.length"
                 class="alert alert-warning">
                <i class="icon-dku-warning"></i>The following experiments can not be found:
                <ul><li *ngFor="let experimentId of experimentIdsOnError">{{ experimentId }}</li></ul>
            </div>
            <ng-container *ngIf="runs$ | async as runs">
                <div class="h100 vertical-flex runs-table oys" *ngIf="runs?.length">
                    <chart-runs-metrics [runs]="selectedToDisplayRuns" (hoverOnRun)="setHighlightedRunId($event)"
                        (clickOnRun)="scrollToRunId($event)" class="chart-runs-metrics" (requestRefresh)="refresh()">
                    </chart-runs-metrics>
                    <div class="runs-table-actions">
                        <form class="dkuform-horizontal" [formGroup]="form">
                            <div class="noflex dropdown mass-action-btn mleft8">
                                <input type="checkbox" (change)="masterToggle()"
                                    [checked]="hasSelectedRuns() && isAllSelected()"
                                    [indeterminate]="hasSelectedRuns() && !isAllSelected()"
                                    [qa]="'experiments-runs-mass-actions-check-all'"
                                    [wt1Click]="'experiment-tracking-toggle-all-runs'">
                                <a data-toggle="dropdown" class="dropdown-toggle unselectable"
                                    [ngClass]="{disabled: !hasSelectedRuns(), collapsed: !runs.length}"
                                    [qa]="'experiments-runs-mass-actions-menu'">
                                    <span>ACTIONS</span>
                                    <b class="caret"></b>
                                </a>
                                <ul class="dropdown-menu">
                                    <li [ngClass]="{disabled: !hasSelectedDeletedRuns(runs)}">
                                        <a (click)="restoreSelected(runs)" [qa]="{'experiments-runs-mass-actions': {selection: 'restore'}}"><i class="icon-undo"></i>Restore</a>
                                    </li>
                                    <li [ngClass]="{disabled: !hasSelectedActiveRuns(runs)}">
                                        <a (click)="deleteSelected(runs)" [qa]="{'experiments-runs-mass-actions': {selection: 'delete'}}"><i class="icon-archive"></i>Delete</a>
                                    </li>
                                    <li>
                                        <a (click)="showSelected(runs)" [qa]="{'experiments-runs-mass-actions': {selection: 'show-selected'}}"><i class="icon-eye-open"></i>Show</a>
                                    </li>
                                    <li>
                                        <a (click)="hideSelected(runs)" [qa]="{'experiments-runs-mass-actions': {selection: 'hide-selected'}}"><i class="icon-eye-close"></i>Hide</a>
                                    </li>
                                </ul>
                            </div>
                            <!-- search -->
                            <div class="std-list-search-box list-control-widget mleft16">
                                <span class="add-on">
                                    <i class="icon-dku-search"></i>
                                </span>
                                <input type="search" placeholder="Search..." style="width: 200px"
                                    formControlName="querySearch" [qa]="'experiments-runs-search-input'">
                            </div>
                            <div class="dib column-selector">
                                <label>Columns</label>
                                <div>
                                    <dku-bs-select-form-control
                                        class="runs-table-action__columns-selection"
                                        formControlName="selectedColumns"
                                        ngOptions="column.columnDef as column.header group by column.groupColumnHeader for column in list"
                                        [list]="availableColumns" [dataActionsBox]="true" [dataLiveSearch]="true"
                                        [dkuMultiple]="true" [layout]="'list'"
                                        [qa]="'experiments-runs-column-selector'">
                                    </dku-bs-select-form-control>
                                </div>
                            </div>
                            <div class="dib view-type-group">
                                <input id="viewAllRuns" type="checkbox" formControlName="viewAll" [qa]="'experiments-runs-view-all-checkbox'">
                                <label for="viewAllRuns">Show deleted</label>
                            </div>
                        </form>
                    </div>
                    <div class="runs-table-container">
                        <div class="runs-table-wrapper" *ngIf="querySort$ | async as querySort">
                            <table mat-table class="dku-mat-table table-hover" [dataSource]="dataSource"
                                [trackBy]="trackExperimentBy" matSort (matSortChange)="onSortChange($event)"
                                [matSortActive]="querySort?.active ?? ''"
                                [matSortDirection]="querySort?.direction ?? ''" [@.disabled]="true">
                                <!-- Highlight Column -->
                                <ng-container matColumnDef="highlight" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef class="highlight-cell"
                                        [qa]="{'experiments-runs-table-header': {columnName: 'highlight'}}">
                                    </th>
                                    <td mat-cell *matCellDef="let run" class="highlight-cell"
                                        [ngClass]="{'highlighted-run': run.info.runId == highlightedRunId}"
                                        [qa]="{'experiments-runs-table-cell': {columnName: 'highlight', experimentId: run.info.experimentId, runId: run.info.runId }}">
                                    </td>
                                </ng-container>
                                <!-- Select Column -->
                                <ng-container matColumnDef="select" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef class="select-cell"
                                        [qa]="{'experiments-runs-table-header': {columnName: 'select'}}">
                                    </th>
                                    <td mat-cell *matCellDef="let run" class="select-cell"
                                        [qa]="{'experiments-runs-table-cell': {columnName: 'select', experimentId: run.info.experimentId, runId: run.info.runId }}">
                                        <input class="select-run-input" type="checkbox"
                                            (click)="$event.stopPropagation()"
                                            [wt1Click]="'experiment-tracking-toggle-run'"
                                            (change)="toggleRunSelection(run)" [checked]="isRunSelected(run)">
                                    </td>
                                </ng-container>
                                <!-- Display Column -->
                                <ng-container matColumnDef="display-chart" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef class="display-chart-cell"
                                        [qa]="{'experiments-runs-table-header': {columnName: 'display-chart'}}">
                                        <span matTooltip="Show/hide all runs" (click)="masterToDisplayToggle()"
                                            [wt1Click]="'experiment-tracking-toggle-all-runs'">
                                            <i
                                                [ngClass]="{
                                                'icon-eye-open': hasToDisplaySelectedRuns() || isAllToDisplaySelected(),
                                                'icon-eye-close': !hasToDisplaySelectedRuns(),
                                                'text-disabled': !hasToDisplaySelectedRuns() || !isAllToDisplaySelected() }"></i>
                                        </span>
                                    </th>
                                    <td mat-cell *matCellDef="let run" class="display-chart-cell">
                                        <span class="show-hide-run" matTooltip="Show/hide run"
                                            (click)="toggleRunToDisplaySelection(run)"
                                            [qa]="{'experiments-runs-table-cell': {columnName: 'display-chart', experimentId: run.info.experimentId, runId: run.info.runId }}"
                                            [wt1Click]="'experiment-tracking-toggle-run'">
                                            <i
                                                class="{{ isRunToDisplaySelected(run) ? 'icon-eye-open' : 'icon-eye-close text-disabled' }}">
                                            </i>
                                        </span>
                                    </td>
                                </ng-container>
                                <!-- Deleted Column -->
                                <ng-container matColumnDef="deleted" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef class="deleted-cell"
                                        [qa]="{'experiments-runs-table-header': {columnName: 'deleted'}}"></th>
                                    <td mat-cell *matCellDef="let run" class="deleted-cell"
                                        [qa]="{'experiments-runs-table-cell': {columnName: 'deleted', experimentId: run.info.experimentId, runId: run.info.runId }}"
                                        matTooltip="Deleted runs can be permanently removed with a Project macro">
                                        <i class="icon icon-archive" *ngIf="run.deletedTooltip"></i>
                                    </td>
                                </ng-container>
                                <!-- Color Column -->
                                <ng-container matColumnDef="runColor" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="run-color-square-cell"
                                        [qa]="{'experiments-runs-table-header': {columnName: 'runColor'}}">
                                    </th>
                                    <td mat-cell *matCellDef="let run" class="run-color-square-cell"
                                        [qa]="{'experiments-runs-table-cell': {columnName: 'runColor', experimentId: run.info.experimentId, runId: run.info.runId }}">
                                        <span class="run-color-square"
                                              [ngStyle]="{'background-color': getRunColor(run.info.runId)}"></span>
                                    </td>
                                </ng-container>
                                <!-- Run information Columns -->
                                <ng-container matColumnDef="ri-experiment" [sticky]="runInformationFrozen">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="mx-textellipsis"
                                        [qa]="{'experiments-runs-table-header': {columnName: 'ri-experiment'}}">
                                        Experiment
                                    </th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [matTooltip]="run.experimentName?run.experimentName:run.info.experimentId"
                                        [qa]="{'experiments-runs-table-cell': {columnName: 'ri-experiment', experimentId: run.info.experimentId, runId: run.info.runId }}"
                                        isEllipsisActive>
                                        <span>{{ run.experimentName?run.experimentName:run.info.experimentId }}</span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ri-run" [sticky]="runInformationFrozen">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef mat-sort-header
                                        class="mx-textellipsis"
                                        [qa]="{'experiments-runs-table-header': {columnName: 'ri-run'}}">Run</th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [matTooltip]="run.runNameId"
                                        [qa]="{'experiments-runs-table-cell': {columnName: 'ri-run', experimentId: run.info.experimentId, runId: run.info.runId }}"
                                        isEllipsisActive>
                                        <a [wt1Click]="'experiment-tracking-go-to-run-details'" [href]="run.href">{{ run.runNameId }}</a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ri-startTime" [sticky]="runInformationFrozen">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="mx-textellipsis"
                                        [qa]="{'experiments-runs-table-header': {columnName: 'ri-startTime'}}">
                                        Start Time
                                    </th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [matTooltip]="run.info.startTime | YYYYMMDDHHmmssDateTime"
                                        [qa]="{'experiments-runs-table-cell': {columnName: 'ri-startTime', experimentId: run.info.experimentId, runId: run.info.runId }}"
                                        isEllipsisActive>
                                        <span>{{ run.info.startTime | YYYYMMDDHHmmssDateTime }}</span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ri-totalTime" [sticky]="runInformationFrozen">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="mx-textellipsis"
                                        [qa]="{'experiments-runs-table-header': {columnName: 'ri-totalTime'}}">
                                        Total Time
                                    </th>
                                    <td mat-cell class="mx-textellipsis" *matCellDef="let run"
                                        [matTooltip]="run.info.endTime == 0 ? 'RUNNING' : 'End time: ' + (run.info.endTime | YYYYMMDDHHmmssDateTime)"
                                        [qa]="{'experiments-runs-table-cell': {columnName: 'ri-totalTime', experimentId: run.info.experimentId, runId: run.info.runId }}">
                                        <span>{{ run.info.endTime == 0 ? '-' : run.info.startTime |
                                            friendlyTimeDeltaHHMMSS:run.info.endTime }}</span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ri-origin" [sticky]="runInformationFrozen">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="mx-textellipsis"
                                        [qa]="{'experiments-runs-table-header': {columnName: 'ri-origin'}}">
                                        Origin
                                    </th>
                                    <td mat-cell *matCellDef="let run" [matTooltip]="run.origin == 'analysis'? 'Visual analysis' : 'Imported with mlflow'"
                                        [qa]="{'experiments-runs-table-cell': {columnName: 'ri-origin', experimentId: run.info.experimentId, runId: run.info.runId }}">
                                        <experiment-tracking-origin [runOrigin]="run.origin">
                                        </experiment-tracking-origin>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ri-status" [sticky]="runInformationFrozen">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="mx-textellipsis"
                                        [qa]="{'experiments-runs-table-header': {columnName: 'ri-status'}}">
                                        Status
                                    </th>
                                    <td mat-cell *matCellDef="let run" [matTooltip]="run.info.status"
                                        [qa]="{'experiments-runs-table-cell': {columnName: 'ri-status', experimentId: run.info.experimentId, runId: run.info.runId }}">
                                        <experiment-tracking-status [runStatus]="run.info.status">
                                        </experiment-tracking-status>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ri-dssUser" [sticky]="runInformationFrozen">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef mat-sort-header
                                        class="mx-textellipsis"
                                        [qa]="{'experiments-runs-table-header': {columnName: 'ri-dssUser'}}">User</th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [matTooltip]="run.dssUser || ''"
                                        [qa]="{'experiments-runs-table-cell': {columnName: 'ri-dssUser', experimentId: run.info.experimentId, runId: run.info.runId }}">
                                        <ng-container *ngIf="run.dssUser">
                                            <a [href]="'/profile/' + run.dssUser + '/'">
                                                <user-picture [user]="run.dssUser" [size]="24"></user-picture>
                                            </a>
                                        </ng-container>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ri-dssGitCommit" [sticky]="runInformationFrozen">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef mat-sort-header
                                        class="mx-textellipsis"
                                        [qa]="{'experiments-runs-table-header': {columnName: 'ri-dssGitCommit'}}">Git commit id</th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis runs-table__git-commit"
                                        [matTooltip]="run.dssGitCommit"
                                        [qa]="{'experiments-runs-table-cell': {columnName: 'ri-dssGitCommit', experimentId: run.info.experimentId, runId: run.info.runId }}"
                                        isEllipsisActive>
                                        <a [href]="run.dssGitCommitHref" >{{ run.dssGitCommit }}</a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ri-dssGitMessage" [sticky]="runInformationFrozen">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef mat-sort-header
                                        class="mx-textellipsis"
                                        [qa]="{'experiments-runs-table-header': {columnName: 'ri-dssGitMessage'}}">Git commit message</th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [matTooltip]="run.dssGitMessage"
                                        [qa]="{'experiments-runs-table-cell': {columnName: 'ri-dssGitMessage', experimentId: run.info.experimentId, runId: run.info.runId }}"
                                        isEllipsisActive>
                                        {{ run.dssGitMessage }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ri-dssGitBranch" [sticky]="runInformationFrozen">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef mat-sort-header
                                        class="mx-textellipsis"
                                        [qa]="{'experiments-runs-table-header': {columnName: 'ri-dssGitBranch'}}">Git branch</th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [matTooltip]="run.dssGitBranch"
                                        [qa]="{'experiments-runs-table-cell': {columnName: 'ri-dssGitBranch', experimentId: run.info.experimentId, runId: run.info.runId }}"
                                        isEllipsisActive>
                                        {{ run.dssGitBranch }}
                                    </td>
                                </ng-container>
                                <!-- Metrics Columns -->
                                <ng-container *ngFor="let column of columnsByGroupMap.get(RunGroupColumnsEnum.METRICS)"
                                    [matColumnDef]="column.columnDef">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="mx-textellipsis"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.METRICS"
                                        [qa]="{'experiments-runs-table-header': {columnName: column.columnDef}}">
                                        {{ column.header }}
                                    </th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.METRICS"
                                        [qa]="{'experiments-runs-table-cell': {columnName: column.columnDef, experimentId: run.info.experimentId, runId: run.info.runId }}"
                                        [matTooltip]="run.metrics.get(column.valueKey)?.lastInvalidValue || run.metrics.get(column.valueKey)?.lastValue?.toFixed(4) || '-'"
                                        isEllipsisActive>
                                        <span [class.experiment-tracking-invalid-values]="run.metrics.get(column.valueKey)?.lastInvalidValue">
                                            {{ run.metrics.get(column.valueKey)?.lastInvalidValue || run.metrics.get(column.valueKey)?.lastValue?.toFixed(4) || "-" }}
                                            <experiment-tracking-invalid-metric-value [metricInfo]="run.metrics.get(column.valueKey)"></experiment-tracking-invalid-metric-value>
                                        </span>
                                    </td>
                                </ng-container>
                                <!-- Parameters Columns -->
                                <ng-container *ngFor="let column of columnsByGroupMap.get(RunGroupColumnsEnum.PARAMS)"
                                    [matColumnDef]="column.columnDef">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="mx-textellipsis"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.PARAMS"
                                        [qa]="{'experiments-runs-table-header': {columnName: column.columnDef}}">
                                        {{ column.header }}
                                    </th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.PARAMS"
                                        [matTooltip]="run.params.get(column.header) || '-'"
                                        [qa]="{'experiments-runs-table-cell': {columnName: column.columnDef, experimentId: run.info.experimentId, runId: run.info.runId }}"
                                        isEllipsisActive>
                                        <span>{{ run.params.get(column.header) || "-" }}</span>
                                    </td>
                                </ng-container>
                                <!-- Tags Columns -->
                                <ng-container *ngFor="let column of columnsByGroupMap.get(RunGroupColumnsEnum.TAGS)"
                                    [matColumnDef]="column.columnDef">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="mx-textellipsis"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.TAGS"
                                        [qa]="{'experiments-runs-table-header': {columnName: column.columnDef}}">
                                        {{ column.header }}
                                    </th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.TAGS"
                                        [matTooltip]="run.tags.get(column.header) || '-'"
                                        [qa]="{'experiments-runs-table-cell': {columnName: column.columnDef, experimentId: run.info.experimentId, runId: run.info.runId }}"
                                        isEllipsisActive>
                                        <span>{{ run.tags.get(column.header) || "-" }}</span>
                                    </td>
                                </ng-container>
                                <!-- System Tags Columns -->
                                <ng-container
                                    *ngFor="let column of columnsByGroupMap.get(RunGroupColumnsEnum.MLFLOW_SYSTEM_TAGS)"
                                    [matColumnDef]="column.columnDef">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="mx-textellipsis"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.MLFLOW_SYSTEM_TAGS"
                                        [qa]="{'experiments-runs-table-header': {columnName: column.columnDef}}">
                                        {{ column.header }}
                                    </th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.MLFLOW_SYSTEM_TAGS"
                                        [matTooltip]="run.systemTags.get(column.header) || '-'"
                                        [qa]="{'experiments-runs-table-cell': {columnName: column.columnDef, experimentId: run.info.experimentId, runId: run.info.runId }}"
                                        isEllipsisActive>
                                        <span>{{ run.origin == 'analysis' ? 1 : run.systemTags.get(column.header) || "-" }}</span>
                                    </td>
                                </ng-container>
                                <!-- Artifacts Columns -->
                                <ng-container matColumnDef="ar-models">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef mat-sort-header
                                        class="mx-textellipsis" [ngClass]="'cell-' + RunGroupColumnsEnum.ARTIFACTS"
                                        [qa]="{'experiments-runs-table-header': {columnName: 'ar-models'}}">
                                        Models</th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [matTooltip]="this.run.data?.models?.length || 0"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.ARTIFACTS"
                                        [qa]="{'experiments-runs-table-cell': {columnName: 'ar-models', experimentId: run.info.experimentId, runId: run.info.runId }}"
                                        isEllipsisActive>
                                        <span>{{run.origin == 'analysis' ? 1 : this.run.data?.models?.length || 0 }}</span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ar-artifact">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="mx-textellipsis"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.ARTIFACTS"
                                        [qa]="{'experiments-runs-table-header': {columnName: 'ar-artifact'}}">
                                        Artifact
                                    </th>
                                    <td mat-cell *matCellDef="let run"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.ARTIFACTS"
                                        [qa]="{'experiments-runs-table-cell': {columnName: 'ar-artifact', experimentId: run.info.experimentId, runId: run.info.runId }}">
                                        <div class="managed-folder-container">
                                            <div class="mx-textellipsis">
                                                <a *ngIf="run.origin != 'analysis' && run.artifactInfo.managedFolderName" matTooltip="{{ run.artifactInfo.managedFolderName + '/' +
                                                    run.artifactInfo.subfolder }}"
                                                    [wt1Click]="'experiment-tracking-go-to-run-artifacts'"
                                                    [href]="run.artifactHref">
                                                    <span>{{ run.artifactInfo.managedFolderName + '/' +
                                                        run.artifactInfo.subfolder }}</span>
                                                </a>
                                                <span *ngIf="run.origin != 'analysis' && !run.artifactInfo.managedFolderName"
                                                    [matTooltip]="run.info.artifactUri">
                                                    {{ run.info.artifactUri }}
                                                </span>
                                                <span *ngIf="run.origin == 'analysis'"
                                                    [matTooltip]="'This run was created from a visual analysis in DSS'">
                                                    -
                                                </span>
                                            </div>
                                            <button *ngIf="run.origin != 'analysis'" class="btn btn--text btn--primary copy-button"
                                                (click)="copyToClipboard(run.info.artifactUri)"
                                                matTooltip="Copy artifact URI">
                                                <i class="icon-copy"></i>
                                            </button>
                                        </div>
                                    </td>
                                </ng-container>

                                <!-- Header group -->
                                <ng-container matColumnDef="group-highlight" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef class="highlight-cell" [attr.colspan]="1"
                                        [qa]="{'experiments-runs-table-header-group': {groupColumnName: 'highlight'}}">
                                    </th>
                                </ng-container>
                                <ng-container matColumnDef="group-select" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef class="select-cell" [attr.colspan]="1"
                                        [qa]="{'experiments-runs-table-header-group': {groupColumnName: 'select'}}">
                                    </th>
                                </ng-container>
                                <ng-container matColumnDef="group-display-chart" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef class="display-chart-cell" [attr.colspan]="1"
                                        [qa]="{'experiments-runs-table-header-group': {groupColumnName: 'display-chart'}}">
                                    </th>
                                </ng-container>
                                <ng-container matColumnDef="group-deleted" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef class="deleted-cell" [attr.colspan]="1"
                                        [qa]="{'experiments-runs-table-header-group': {groupColumnName: 'highlight'}}">
                                    </th>
                                </ng-container>
                                <ng-container matColumnDef="group-run-color" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef class="run-color-square-cell"
                                        [attr.colspan]="1"
                                        [qa]="{'experiments-runs-table-header-group': {groupColumnName: 'run-color'}}">
                                    </th>
                                </ng-container>
                                <!-- Header group run information -->
                                <ng-container [matColumnDef]="RunGroupColumnsEnum.RUN_INFORMATION"
                                    class="mx-textellipsis" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef
                                        [attr.colspan]="displayedColumnsMap.get(RunGroupColumnsEnum.RUN_INFORMATION)?.length"
                                        class="run-information-group-header"
                                        [qa]="{'experiments-runs-table-header-group': {groupColumnName: 'run-information'}}">
                                        {{ getGroupColumnsHeader(RunGroupColumnsEnum.RUN_INFORMATION) }}
                                        <span matTooltip="Freeze/Unfreeze run information columns"
                                            (click)="toggleRunInformationFreezeMode()"
                                            [wt1Click]="'experiment-tracking-toggle-run-info-freeze'">
                                            <i
                                                [ngClass]="runInformationFrozen ? 'icon-dku-unpin-chart' : 'icon-dku-pin-chart'"></i>
                                        </span>
                                    </th>
                                </ng-container>
                                <!-- Header group metrics -->
                                <ng-container [matColumnDef]="RunGroupColumnsEnum.METRICS">
                                    <th mat-header-cell *matHeaderCellDef class="first-group-cell mx-textellipsis"
                                        [attr.colspan]="displayedColumnsMap.get(RunGroupColumnsEnum.METRICS)?.length"
                                        [qa]="{'experiments-runs-table-header-group': {groupColumnName: 'metrics'}}">
                                        {{ getGroupColumnsHeader(RunGroupColumnsEnum.METRICS) }}
                                    </th>
                                </ng-container>
                                <!-- Header group parameters -->
                                <ng-container [matColumnDef]="RunGroupColumnsEnum.PARAMS">
                                    <th mat-header-cell *matHeaderCellDef class="first-group-cell mx-textellipsis"
                                        [attr.colspan]="displayedColumnsMap.get(RunGroupColumnsEnum.PARAMS)?.length"
                                        [qa]="{'experiments-runs-table-header-group': {groupColumnName: 'parameters'}}">
                                        {{ getGroupColumnsHeader(RunGroupColumnsEnum.PARAMS) }}
                                    </th>
                                </ng-container>
                                <!-- Header group tags -->
                                <ng-container [matColumnDef]="RunGroupColumnsEnum.TAGS">
                                    <th mat-header-cell *matHeaderCellDef class="first-group-cell mx-textellipsis"
                                        [attr.colspan]="displayedColumnsMap.get(RunGroupColumnsEnum.TAGS)?.length"
                                        [qa]="{'experiments-runs-table-header-group': {groupColumnName: 'tags'}}">
                                        {{ getGroupColumnsHeader(RunGroupColumnsEnum.TAGS) }}
                                    </th>
                                </ng-container>
                                <!-- Header group DSS system tags -->
                                <ng-container [matColumnDef]="RunGroupColumnsEnum.DSS_SYSTEM_TAGS">
                                    <th mat-header-cell *matHeaderCellDef class="first-group-cell mx-textellipsis"
                                        [attr.colspan]="displayedColumnsMap.get(RunGroupColumnsEnum.DSS_SYSTEM_TAGS)?.length"
                                        [qa]="{'experiments-runs-table-header-group': {groupColumnName: 'dss-system-tags'}}">
                                        {{ getGroupColumnsHeader(RunGroupColumnsEnum.DSS_SYSTEM_TAGS) }}
                                    </th>
                                </ng-container>
                                <!-- Header group MLflow system tags -->
                                <ng-container [matColumnDef]="RunGroupColumnsEnum.MLFLOW_SYSTEM_TAGS">
                                    <th mat-header-cell *matHeaderCellDef class="first-group-cell mx-textellipsis"
                                        [attr.colspan]="displayedColumnsMap.get(RunGroupColumnsEnum.MLFLOW_SYSTEM_TAGS)?.length"
                                        [qa]="{'experiments-runs-table-header-group': {groupColumnName: 'mlflow-system-tags'}}">
                                        {{ getGroupColumnsHeader(RunGroupColumnsEnum.MLFLOW_SYSTEM_TAGS) }}
                                    </th>
                                </ng-container>
                                <!-- Header group artifacts -->
                                <ng-container [matColumnDef]="RunGroupColumnsEnum.ARTIFACTS">
                                    <th mat-header-cell *matHeaderCellDef class="first-group-cell mx-textellipsis"
                                        [attr.colspan]="displayedColumnsMap.get(RunGroupColumnsEnum.ARTIFACTS)?.length"
                                        [qa]="{'experiments-runs-table-header-group': {groupColumnName: 'artifacts'}}">
                                        {{ getGroupColumnsHeader(RunGroupColumnsEnum.ARTIFACTS) }}
                                    </th>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedGroupColumns; sticky: true"></tr>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                <tr mat-row *matRowDef="let run; columns: displayedColumns;"
                                    [ngClass]="{'highlighted-row': run.info.runId == highlightedRunId, 'greyed-row': run.deletedTooltip}">
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div *ngIf="!runs?.length && !experimentIdsOnError.length" class="h100 vertical-flex oys empty-list-cta">
                    <form class="dkuform-horizontal" [formGroup]="form">
                        <h1>
                            No <span *ngIf="!form.controls['viewAll'].value">Active </span>Run in this Experiment
                        </h1>
                        <p>
                            Create runs by using the MLflow API.
                        </p>
                        <p>
                            <doclink-wrapper page="mlops/experiment-tracking/index">Read the documentation</doclink-wrapper> to learn more.
                        </p>
                        <p>
                            <input id="viewAllRuns" type="checkbox" formControlName="viewAll"
                                style="vertical-align: baseline;" [qa]="'experiments-runs-empty-list-view-all-checkbox'">&nbsp;Show Deleted Runs
                        </p>
                        <div>
                            <button class="btn btn--secondary btn--large"
                                    (click)="refresh()"
                                    [wt1Click]="'experiment-tracking-refresh-runs'">
                                <i class="icon-refresh"></i>&nbsp;Refresh
                            </button>
                        </div>
                    </form>
                </div>
            </ng-container>
        </div>
    </div>
</div>
