import { realAny } from './typedefs';

export class Assert {
    static trueish(predicate: realAny, msg: string) {
        if (!predicate) {
            throw new Error(msg);
        }
    }
    static fail(msg: string) {
        throw new Error(msg);
    }
}
