<ng-container *ngIf="labelingService.labelingTaskInfo$ | async as task">
    <div class="header">
        <span class="header-title">{{ task.type | categoryLabel: true }}</span>
        <span class="header-subtitle">Select {{ task.type | categoryLabel }} to apply</span>
    </div>
</ng-container>

<div class="categories-container">
    <button *ngFor="let clazz of (classes$ | async); let index = index;" class="btn btn--secondary labeling__right-panel-button category-button"
            [ngClass]="{ 'active': categoryIsActive((selectedCategory$ | async), clazz.key) }" (click)="categoryClicked($event, clazz.key)">
        <div [ngStyle]="{'backgroundColor': colorStr(clazz.key), 'borderColor': colorStr(clazz.key)}" class="color-box color-box--small"> </div>
        <span class="category" title="{{clazz.key}}">{{clazz.key}} </span>
        <i class="category-info icon-info-sign" *ngIf="clazz.value" [matTooltip]="clazz.value"></i>
        <span class="keybind keybind--digit mleft4" *ngIf="index<=9">{{index === 9 ? 0 : index + 1}}</span>
    </button>
</div>
