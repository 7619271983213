import { Component, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { Usages } from '@model-main/featurestore/usages';
import { fairAny } from 'dku-frontend-core';

@Component({
    selector: 'right-panel-details-usages',
    templateUrl: './right-panel-details-usages.component.html',
    styleUrls: ['./right-panel-details-usages.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightPanelDetailsUsagesComponent {
    @Input() usages!: Usages;

    constructor(
        @Inject('StateUtils') public StateUtils: fairAny,
    ) { }
}
