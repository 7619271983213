import type {Distribution, _Distribution} from './distribution';

/**
 * Generated from com.dataiku.dip.eda.compute.distributions.Poisson
 */
export interface Poisson extends _Distribution {
    // PolyJson type
    type: 'poisson';
}

export namespace Poisson {
    export const type = 'poisson';
}

/** @deprecated Use Distribution.isPoisson() */ export function isPoisson(toTest: Distribution): toTest is Poisson { return Poisson.type === toTest['type']; }

export namespace Poisson {
    /**
     * Generated from com.dataiku.dip.eda.compute.distributions.Poisson$FittedPoisson
     */
    export interface FittedPoisson extends Distribution._FittedDistribution {
        lambda: number;

        // PolyJson type
        type: 'poisson';
    }

    export namespace FittedPoisson {
        export const type = 'poisson';
    }

    /** @deprecated Use Distribution.FittedDistribution.isFittedPoisson() */ export function isFittedPoisson(toTest: Distribution.FittedDistribution): toTest is FittedPoisson { return FittedPoisson.type === toTest['type']; }
}