<div
    class="config-area"
    [ngClass]="className"
    cdkScrollable
>
    <generic-summary-stats-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        *ngIf="params.type === 'univariate_summary' || params.type === 'bivariate_summary'"
    >
    </generic-summary-stats-card-config>

    <categorical-histogram-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngIf="params.type === 'categorical_histogram'"
    >
    </categorical-histogram-card-config>

    <bivariate-box-plot-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngIf="params.type === 'bivariate_box_plot'"
    >
    </bivariate-box-plot-card-config>

    <univariate-frequency-table-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngIf="params.type === 'univariate_frequency_table'"
    >
    </univariate-frequency-table-card-config>

    <quantiles-table-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngIf="params.type === 'quantile_table'"
    >
    </quantiles-table-card-config>

    <numerical-histogram-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngIf="params.type === 'numerical_histogram'"
    >
    </numerical-histogram-card-config>

    <abstract-2d-pivot-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngIf="params.type === 'bivariate_frequency_table' || params.type === 'bivariate_histogram' || params.type === 'mosaic_plot'"
    >
    </abstract-2d-pivot-card-config>

    <scatter-plot-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngIf="params.type === 'scatter_plot'"
    >
    </scatter-plot-card-config>

    <univariate-header-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        (validityTooltipChange)="validityTooltipChange.emit($event)"
        *ngIf="params.type === 'univariate_header'"
    ></univariate-header-card-config>

    <bivariate-header-card-config
        [params]="params"
        (validityChange)="validityChange.emit($event)"
        (validityTooltipChange)="validityTooltipChange.emit($event)"
        (paramsChange)="paramsChange.emit($event)"
        *ngIf="params.type === 'bivariate_header'"
    ></bivariate-header-card-config>

    <tztest1-samp-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngIf="params.type === 'ttest_ztest_1samp'"
    ></tztest1-samp-card-config>

    <sign-test1-samp-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngIf="params.type === 'sign_test_1samp'"
    ></sign-test1-samp-card-config>

    <shapiro-normality-test-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngIf="params.type === 'shapiro'"
    ></shapiro-normality-test-card-config>

    <abstract-2-samp-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngIf="params.type === 'ttest_2samp' || params.type === 'mood_test_2samp' || params.type === 'ks_test_2samp'"
    ></abstract-2-samp-card-config>

    <abstract-n-samp-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngIf="params.type === 'oneway_anova' || params.type === 'mood_nsamp'"
    ></abstract-n-samp-card-config>

    <abstract-pairwise-test-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngIf="params.type === 'pairwise_ttest' || params.type === 'pairwise_mood'"
    ></abstract-pairwise-test-card-config>

    <chi2-ind-test-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngIf="params.type === 'chi2_independence_test'"
    ></chi2-ind-test-card-config>

    <fit-distribution-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngIf="params.type === 'fit_distribution'"
    ></fit-distribution-card-config>

    <fit-2d-distribution-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngIf="params.type === 'fit_2d_distribution'"
    ></fit-2d-distribution-card-config>

    <fit-curve-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngIf="params.type === 'fit_curve'"
    ></fit-curve-card-config>

    <multivariate-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        (validityTooltipChange)="validityTooltipChange.emit($event)"
        *ngIf="params.type === 'correlation_matrix' || params.type === 'pca' || params.type === 'parallel_coordinates_plot'"
    ></multivariate-card-config>

    <scatter-plot-3d-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngIf="params.type === 'scatter_plot_3d'"
    ></scatter-plot-3d-card-config>

    <time-series-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngIf="(
            params.type === 'unit_root_test_adf' ||
            params.type === 'unit_root_test_za' ||
            params.type === 'unit_root_test_kpss' ||
            params.type === 'durbin_watson' ||
            params.type === 'mann_kendall_test' ||
            params.type === 'acf_plot' ||
            params.type === 'stl_decomposition'
        )"
    ></time-series-card-config>
</div>
