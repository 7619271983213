import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { merge, Observable, Subject } from 'rxjs';
import { Workspace } from '@model-main/workspaces/workspace';
import { WorkspacesError } from '../../models';
import { DssObjectPayload, LinkPayload, ObjectPayloads, TYPES } from '../workspace-add-objects';
import { APIError } from '@core/dataiku-api/api-error';

interface AddObjectsModalOptions {
    workspace: Workspace;
    submit: (workspaceObjects: Workspace.WorkspaceObject[]) => Observable<Workspace>;
    error: WorkspacesError;
}
@Component({
    selector: 'add-objects-modal',
    templateUrl: './add-objects-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddObjectsModalComponent {

    objects: (DssObjectPayload | LinkPayload)[];
    workspace: Workspace;
    underlyingErrors$ = new Subject<APIError | undefined>();
    errors$: Observable<APIError | undefined>;


    constructor(
        private dialogRef: MatDialogRef<AddObjectsModalComponent, Workspace.WorkspaceObject[] | null>,
        @Inject(MAT_DIALOG_DATA) private data: AddObjectsModalOptions
    ) {
        this.workspace = data.workspace;
        this.errors$ = merge(data.error.error$, this.underlyingErrors$);

        // Clean up error on close
        this.dialogRef.beforeClosed().subscribe(() => this.data.error.resetError());
    }

    onObjectsChanged(objects: ObjectPayloads): void {
        this.objects = objects || [];
    }

    onError(error: APIError): void {
        this.underlyingErrors$.next(error);
    }

    resetError(): void {
        this.underlyingErrors$.next(undefined);
        this.data.error.resetError();
    }

    confirm(): void {
        if (this.objects) {
            const workspaceObjects = <Workspace.WorkspaceObject[]>this.objects.map(object => {
                switch (object.type) {
                    case 'LINK':
                        return { htmlLink: object };
                    case 'APP':
                        return { appId: (object as DssObjectPayload).id };
                    default:
                        return { reference: object };
                }
            });

            this.data.submit(workspaceObjects)
                .subscribe(() => this.dialogRef.close(workspaceObjects));
        }
    }

    cancel(): void {
        this.dialogRef.close(null);
    }
}
