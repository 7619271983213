import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DeephubAbstractReportConfusionMatrixComponent, MatrixData } from '@features/deephub/computer-vision/report/confusion-matrix/deephub-computer-vision-report-confusion-matrix.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DeephubImagesDataService } from 'src/generated-sources';
import { DeephubObjectDetectionReportDataFetcherService } from '../services/deephub-object-detection-report-data-fetcher.service';
import { DeephubObjectDetectionReportService, ObjectDetectionReport } from '../services/deephub-object-detection-report.service';

@UntilDestroy()
@Component({
  selector: 'deephub-object-detection-report-confusion-matrix',
  templateUrl: '../../../computer-vision/report/confusion-matrix/deephub-computer-vision-report-confusion-matrix.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeephubObjectDetectionReportConfusionMatrixComponent extends DeephubAbstractReportConfusionMatrixComponent<ObjectDetectionReport, DeephubObjectDetectionReportService> implements OnInit {
    constructor(
        objectDetectionService: DeephubObjectDetectionReportService,
        private dataFetcher: DeephubObjectDetectionReportDataFetcherService
    ) { 
        super(objectDetectionService);
    }

    matrixClick(clickedCell: { xIndex: number, yIndex: number} | null): void {
        const sorting = {sortBy: DeephubImagesDataService.SortBy.IOU, ascending: false};
        const filter = {
            groundTruth: clickedCell ? this.reportService.getFilterCategory(clickedCell.yIndex, this.categories) : null,
            detection: clickedCell ? this.reportService.getFilterCategory(clickedCell.xIndex, this.categories) : null,
            sorting
        };
        this.dataFetcher.setFilteredPair(filter);
    }

    createMatrix(report: ObjectDetectionReport): MatrixData {
        this.categories = report.categories;
        const iouIndex = this.reportService.iouToIndex(report.iou);
        const confidenceThresholdIndex = this.reportService.confidenceThresholdToIndex(report.confidenceThreshold!);
        const confusionMatrix = (report.perf.confusion_matrix.confusionMatrices[iouIndex] || [])[confidenceThresholdIndex];
        const matrixSize = this.categories.length;
        const newMatrix: number[][] = Array(matrixSize + 1).fill(0).map(() => Array(matrixSize + 1).fill(0)); // include non-detected/non-object

        // initialized non-object and not detected cells
        report.perf.confusion_matrix.perConfidenceScoreDetectionsCount.forEach((counts, index) => {
            newMatrix[index][matrixSize] = counts !== null ? counts[confidenceThresholdIndex] : 0;
        })
        newMatrix[matrixSize] = report.perf.confusion_matrix.groundTruthsCounts.slice().concat(0);

        (confusionMatrix || []).forEach((cell: number[]) => {
            const predictedIndex = cell[0];
            const groundTruthIndex = cell[1];
            const frequency = cell[2];

            newMatrix[predictedIndex][groundTruthIndex] = frequency;

            // calculate non-objects
            newMatrix[predictedIndex][matrixSize] = newMatrix[predictedIndex][matrixSize] - frequency;

            // calculate not detected
            newMatrix[matrixSize][groundTruthIndex] = newMatrix[matrixSize][groundTruthIndex] - frequency;
        });

        return {
            data: newMatrix,
            xCategories: [...this.categories, this.NO_DETECTION_LABEL],
            yCategories: [...this.categories, this.NO_GROUND_TRUTH_LABEL],
            params: {
                filterVariablesWithoutValues: false,
                showAbsValues: true,
                showColors: true,
                showValues: false,
                threshold: 0
            }
        };
    }
}
