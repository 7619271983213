import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { QaSelectorService, QaSelectors, getCssSelector } from './qa-selector.service';
import { ReplaySubject, timer, combineLatest } from 'rxjs';
import { switchMapTo } from 'rxjs/operators';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { OverlayRef } from '@angular/cdk/overlay';

export interface InputData {
    qa: QaSelectors;
}

@UntilDestroy()
@Component({
    selector: 'qa-selector-overlay',
    templateUrl: './qa-selector.component.html',
    styleUrls: ['./qa-selector.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QaSelectorComponent implements OnDestroy {
    displayedData?: {
        selector: string,
        color: string,
        derivedSelectors: string[]
    }[];
    element?: HTMLElement;
    boxShadow: string;
    overlayWidth: number;
    overlayHeight: number;
    element$ = new ReplaySubject<HTMLElement>(1);
    overlayRef$ = new ReplaySubject<OverlayRef>(1);

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private qaSelectorService: QaSelectorService) {
        // Reposition & resize every second
        // (this is slow but this is a dev-only tool)
        timer(0, 1000).pipe(
            switchMapTo(combineLatest([this.element$, this.overlayRef$])),
            untilDestroyed(this)
        ).subscribe(([element, overlayRef]) => {
            // Update position (CDK)
            overlayRef.updatePosition();

            // Update size (manual)
            const size = element.getBoundingClientRect();
            this.overlayWidth = size.width;
            this.overlayHeight = size.height;
            changeDetectorRef.markForCheck();
        });
    }

    setElementAndOverlayRef(element: HTMLElement, overlayRef: OverlayRef) {
        this.element$.next(element);
        this.overlayRef$.next(overlayRef);
    }

    ngOnDestroy() {
    }

    setData(qa: QaSelectors) {
        this.displayedData = Object.keys(qa).map(key => ({
            selector: getCssSelector(key),
            color: this.qaSelectorService.getColorForSelector(key),
            derivedSelectors: Object.keys(qa[key])
                .map(attribute => getCssSelector(key, attribute, '' + qa[key][attribute]))
        }));

        this.boxShadow = this.displayedData
            .map(({ color }, index) => `0 0 0 ${5 * (1 + index)}px ${color}`)
            .join(', ');

        this.changeDetectorRef.markForCheck();
    }
}
