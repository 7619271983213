import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.EmpiricalCDF
 */
export interface EmpiricalCDF extends Computation._UnivariateComputation {
    // PolyJson type
    type: 'empirical_cdf';
}

export namespace EmpiricalCDF {
    export const type = 'empirical_cdf';
}

/** @deprecated Use Computation.isEmpiricalCDF() */ export function isEmpiricalCDF(toTest: Computation): toTest is EmpiricalCDF { return EmpiricalCDF.type === toTest['type']; }

export namespace EmpiricalCDF {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.EmpiricalCDF$EmpiricalCDFResult
     */
    export interface EmpiricalCDFResult extends _AvailableResult {
        probs: number[];
        xvals: number[];

        // PolyJson type
        type: 'empirical_cdf';
    }

    export namespace EmpiricalCDFResult {
        export const type = 'empirical_cdf';
    }

    /** @deprecated Use ComputationResult.isEmpiricalCDFResult() */ export function isEmpiricalCDFResult(toTest: ComputationResult): toTest is EmpiricalCDFResult { return EmpiricalCDFResult.type === toTest['type']; }
}