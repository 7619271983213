import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {UnitRootTest, _UnitRootTest} from './unit-root-test';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestKPSS
 */
export interface UnitRootTestKPSS extends _UnitRootTest {
    nLags?: number | null;
    regressionMode: UnitRootTestKPSS.RegressionMode;

    // PolyJson type
    type: 'unit_root_kpss';
}

export namespace UnitRootTestKPSS {
    export const type = 'unit_root_kpss';
}

/** @deprecated Use Computation.isUnitRootTestKPSS() */ export function isUnitRootTestKPSS(toTest: Computation): toTest is UnitRootTestKPSS { return UnitRootTestKPSS.type === toTest['type']; }

export namespace UnitRootTestKPSS {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestKPSS$RegressionMode
     */
    export enum RegressionMode {
        CONSTANT = 'CONSTANT',
        CONSTANT_WITH_TREND = 'CONSTANT_WITH_TREND'
    }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestKPSS$UnitRootTestKPSSResult
     */
    export interface UnitRootTestKPSSResult extends UnitRootTest._UnitRootTestResult {
        // PolyJson type
        type: 'unit_root_kpss';
    }

    export namespace UnitRootTestKPSSResult {
        export const type = 'unit_root_kpss';
    }

    /** @deprecated Use ComputationResult.isUnitRootTestKPSSResult() */ export function isUnitRootTestKPSSResult(toTest: ComputationResult): toTest is UnitRootTestKPSSResult { return UnitRootTestKPSSResult.type === toTest['type']; }
}