import { Component, ChangeDetectionStrategy, ViewChild, Inject, ChangeDetectorRef, HostListener } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { catchAPIError } from '@core/dataiku-api/api-error';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { WaitingService } from '@core/overlays/waiting.service';
import { PopularDatasetsService } from '@features/popular-datasets/services/popular-datasets.service';
import { DatasetAndTablePreview } from '@shared/services/dataset-and-table-preview/dataset-and-table-preview.service';
import { ErrorContextService } from '@shared/services/error-context.service';
import { Shareability, ShareAndPublishService } from '@shared/services/share-and-publish.service';
import { fairAny } from 'dku-frontend-core';
import { ITaggingService, ProjectsService, UIDataCatalog } from 'generated-sources';
import { shareReplay } from 'rxjs';

function popularDatasetToTaggableRefWithName(dataset: UIDataCatalog.AbstractPopularDataset) {
    return {
        type: ITaggingService.TaggableType.DATASET,
        projectKey: dataset.projectKey,
        id: dataset.name,
        displayName: dataset.name
    };
}

@Component({
    selector: 'popular-datasets-table',
    templateUrl: './popular-datasets-table.component.html',
    styleUrls: ['./popular-datasets-table.component.less'],
    providers: [ErrorContextService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopularDatasetsTableComponent {
    displayedColumns = ['icon', 'name', "projectName", 'projects', 'columns', 'dataCollections', 'lastBuild', 'score', 'description', 'actions'];

    dataSource = new MatTableDataSource<UIDataCatalog.AbstractPopularDataset>();
    isLoading: boolean = false;
    hasLastResult: boolean = false;
    private get contextProject() {
        // the type is string, but it actually can be undefined in some contexts!
        const projectKey = this.currentRouteService.projectKey as string | undefined;
        if(projectKey) {
            return this.$rootScope.projectSummary as ProjectsService.UIProject;
        } else {
            return undefined;
        }
    }

    readonly popularDatasetsHelpMessage: string =
        "Popular datasets are commonly used and shared datasets across your instance, that you may want to publish to a data collection, workspace or feature store, or import directly into a project for immediate use.\n\n" +
        "Popular datasets are defined by a few key parameters, including:\n" +
        "* Number of shares\n" +
        "* Time since last rebuild\n" +
        "* \"Trending datasets\"(meaning they are getting more popular over time)\n";

    private keyword: string;
    golden: boolean = false;

    constructor(
        private popularDatasetsService: PopularDatasetsService,
        private errorContext: ErrorContextService,
        private cd: ChangeDetectorRef,
        private waitingService: WaitingService,
        @Inject("$rootScope") public $rootScope: fairAny,
        @Inject("FutureProgressModal") private FutureProgressModal: fairAny,
        @Inject("ActivityIndicator") private ActivityIndicator: fairAny,
        private shareAndPublishService: ShareAndPublishService,
        private datasetAndTablePreview: DatasetAndTablePreview,
        private currentRouteService: CurrentRouteService
    ) {
        this.isLoading = true;
        this.popularDatasetsService.list().pipe(
            catchAPIError(this.errorContext),
            this.waitingService.bindSpinner()
        ).subscribe((popularDatasets) => {
            this.isLoading = false;
            if (popularDatasets !== "") {
                this.hasLastResult = true;
                this.updateTable(popularDatasets);
            }
            this.cd.detectChanges();
        });
    }

    @ViewChild(MatSort) sort: MatSort;

    private updateTable(popularDatasets: UIDataCatalog.AbstractPopularDataset[]) {
            this.dataSource.data = popularDatasets;
            this.dataSource.sort = this.sort;
            this.dataSource.sortingDataAccessor = (item: UIDataCatalog.AbstractPopularDataset, property: string) => {
                switch (property) {
                    case 'name': return item.name;
                    case 'projectName': return item.projectName;
                    case 'projects': return this.isDatasetReadable(item) ? item.numberOfProjects : 0;
                    case 'columns': return this.isDatasetReadable(item) ? item.numberOfColumns : 0;
                    case 'dataCollections': return this.isDatasetReadable(item) ? item.numberOfDataCollections : 0;
                    case 'lastBuild': return this.isDatasetReadable(item) ? item.lastBuiltOn : 0;
                    case 'score': return item.score;
                    default: return 0;
                }
            };
    }

    compute() {
        this.isLoading = true;
        this.popularDatasetsService.compute().pipe(
            catchAPIError(this.errorContext),
            shareReplay(1),
        ).subscribe(resp => {
            this.FutureProgressModal.show(this.$rootScope, resp, "Compute popular datasets").then((result: fairAny) => {
                this.isLoading = false;
                if (result) {
                    this.updateTable(result);
                    this.ActivityIndicator.success("Popular datasets computation done", 5000);
                } else {
                    this.ActivityIndicator.error("Popular datasets computation failed", 5000);
                }
                this.cd.detectChanges();
            });
        });
    }

    popularDatasetsListIsEmpty(): boolean {
        return this.dataSource.data.length <= 0;
    }

    // magic trick to force type checking inside of mat-table
    isPopularDataset(item: unknown): item is UIDataCatalog.AbstractPopularDataset {
        return true;
    }

    isDatasetReadable(dataset: UIDataCatalog.AbstractPopularDataset): dataset is UIDataCatalog.PopularDataset {
        return UIDataCatalog.AbstractPopularDataset.isPopularDataset(dataset);
    }

    isDatasetDiscoverable(dataset: UIDataCatalog.AbstractPopularDataset): dataset is UIDataCatalog.DiscoverablePopularDataset {
        return UIDataCatalog.AbstractPopularDataset.isDiscoverablePopularDataset(dataset);
    }

    private getWT1AdditionalInfo(item: UIDataCatalog.AbstractPopularDataset) {
        // be uber-safe about the type
        const source = Array.isArray(this.dataSource?.data) ? this.dataSource.data : [];
        return {
            from: 'popular-datasets',
            score: item.score,
            rank: source.indexOf(item)+1,
            accessiblePopularDatasetCount: source.length,
        };
    }

    getShareability(item: UIDataCatalog.AbstractPopularDataset) {
        return this.shareAndPublishService.getShareability(
            popularDatasetToTaggableRefWithName(item),
            item.objectAuthorizations,
            this.contextProject
        );
    }

    getPublishability(item: UIDataCatalog.AbstractPopularDataset) {
        return this.shareAndPublishService.getPublishability(
            popularDatasetToTaggableRefWithName(item),
            item.objectAuthorizations,
            this.contextProject,
            this.$rootScope.appConfig.globalPermissions,
            this.isDatasetReadable(item) ? item.isFeatureGroup : false
        );
    }

    shareButtonAction(item: UIDataCatalog.AbstractPopularDataset, shareability: Shareability['shareability']): Promise<boolean> {
        const objectRef = popularDatasetToTaggableRefWithName(item);

        if (shareability === "share" && this.contextProject?.projectKey) {
            return this.shareAndPublishService.doShare(
                objectRef,
                this.contextProject.projectKey,
                item.objectAuthorizations.isQuicklyShareable,
                this.getWT1AdditionalInfo(item)
            );
        } else if (shareability === "share") {
            return this.shareAndPublishService.openShareModal(
                objectRef,
                item.objectAuthorizations,
                this.getWT1AdditionalInfo(item)
            );
        } else if (shareability === "request") {
            return this.shareAndPublishService.requestShare(
                objectRef,
                this.contextProject?.projectKey,
                this.getWT1AdditionalInfo(item)
            );
        } else {
            // somehow user clicked a disabled button, let's pretend nothing happened
            return Promise.resolve(false);
        }
    }

    shareToDashboard(item: UIDataCatalog.AbstractPopularDataset) {
        if(this.contextProject === undefined) return; // should never happen as button is not displayed
        return this.shareAndPublishService.shareDatasetToDashboard(item.projectKey, item.name, this.contextProject.projectKey);
    }

    shareToWorkspace(item: UIDataCatalog.AbstractPopularDataset) {
        return this.shareAndPublishService.shareToWorkspace(popularDatasetToTaggableRefWithName(item));
    }

    addToFeatureStore(item: UIDataCatalog.AbstractPopularDataset) {
        return this.shareAndPublishService.addToFeatureStore(item.projectKey, item.name, item.objectAuthorizations);
    }

    addToDataCollection(item: UIDataCatalog.AbstractPopularDataset) {
        return this.shareAndPublishService.addToDataCollection(popularDatasetToTaggableRefWithName(item), this.getWT1AdditionalInfo(item));
    }

    preview(item: UIDataCatalog.AbstractPopularDataset) {
        return this.datasetAndTablePreview.openDatasetPreviewModal(item.projectKey, item.name);
    }

    @HostListener('window:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
      this.keyword += event.key.toLowerCase();
      if (this.keyword.includes('gold')) {
        this.golden = true;
        this.keyword = '';
      }
    }
}

export interface PermissionInfo {
    show: boolean;
    enabled: boolean;
    tooltip: string;
}
