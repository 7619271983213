import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.Variance
 */
export interface Variance extends Computation._UnivariateComputation {
    // PolyJson type
    type: 'variance';
}

export namespace Variance {
    export const type = 'variance';
}

/** @deprecated Use Computation.isVariance() */ export function isVariance(toTest: Computation): toTest is Variance { return Variance.type === toTest['type']; }

export namespace Variance {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.Variance$VarianceResult
     */
    export interface VarianceResult extends _AvailableResult {
        value: number;

        // PolyJson type
        type: 'variance';
    }

    export namespace VarianceResult {
        export const type = 'variance';
    }

    /** @deprecated Use ComputationResult.isVarianceResult() */ export function isVarianceResult(toTest: ComputationResult): toTest is VarianceResult { return VarianceResult.type === toTest['type']; }
}