import { Component, ChangeDetectionStrategy, Input, Inject, OnChanges } from '@angular/core';
import { fairAny } from 'dku-frontend-core';
import { ITaggingService } from 'generated-sources';

@Component({
    selector: 'right-panel-details-project',
    templateUrl: './right-panel-details-project.component.html',
    styleUrls: ['./right-panel-details-project.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RightPanelDetailsProjectComponent implements OnChanges {
    @Input() projectKey: string;
    @Input() projectDisplayName: string;
    @Input() objectId: string;
    @Input() directAccessOnOriginal: boolean;
    // PolyJson type
    @Input() type: `${ITaggingService.TaggableType}`;
    projectLink: string;
    flowLink: string;

    constructor(
        @Inject('StateUtils') private stateUtils: fairAny,
        @Inject('Navigator') private navigator: fairAny,
    ) { }

    ngOnChanges(): void {
        this.projectLink = this.stateUtils.href.project(this.projectKey);
        this.flowLink = this.directAccessOnOriginal ? this.stateUtils.href.flowLinkFromProps(this.type, this.projectKey, this.objectId, this.projectKey) : null;
    }

    openNavigator(): void {
        this.navigator.show(this.projectKey, this.type, this.objectId);
    }
}
