import { Observable, of, timer } from "rxjs";
import { expand, mapTo } from "rxjs/operators";

//Inspired from https://github.com/alex-okrushko/backoff-rxjs
export function intervalBackoff(
    initialInterval: number,
    maxInterval: number = Infinity,
    backoffDelay: (iteration: number, initialInterval: number) => number): Observable<number> {
    initialInterval = initialInterval < 0 ? 0 : initialInterval;
    return of(0).pipe(
        expand(iteration =>
            timer(Math.min(backoffDelay(iteration, initialInterval), maxInterval))
                .pipe(mapTo(iteration + 1))
        )
    );
}

