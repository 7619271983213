import { Component, Input, Inject } from '@angular/core';
import { fairAny } from 'dku-frontend-core';
import { ITaggingService } from 'generated-sources';

@Component({
    selector: 'right-panel-details-tags',
    templateUrl: './right-panel-details-tags.component.html',
    styleUrls: ['./right-panel-details-tags.component.less'],
})

export class RightPanelDetailsTagsComponent {
    @Input() tags: string[];
    @Input() objectType!: `${ITaggingService.TaggableType}`;

    constructor(
        @Inject("TaggingService") private taggingService: fairAny,
    ) { }

    // since it's read-only, we don't actually need the list of all tags
    getAllTags = () => Promise.resolve(this.taggingService.fillTagsMapFromArray(this.tags));
}
