import { Input, Injector, ElementRef, Output, EventEmitter, Directive } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { ContainerExecSelection } from 'src/generated-sources';


@Directive({
    selector: 'container-selection-form'
})
export class ContainerSelectionFormComponent extends UpgradeComponent {
    @Input() selection: ContainerExecSelection;
    @Output() selectionChange: EventEmitter<ContainerExecSelection>;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('ng1ContainerSelectionForm', elementRef, injector);
    }
}
