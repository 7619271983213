<h4>{{label}}</h4>
<div class="info-container">
    <span 
        [matTooltip]="tooltip"
        matTooltipPosition="left"
    >{{text}}</span>
    <ng-container *ngIf="user && user.login">
        <span> by</span>
        <a
            class="mleft4"
            href="/profile/{{user.login}}/"
            [matTooltip]="user.displayName || user.login"
            matTooltipPosition="left"
        >
            <user-picture
                [user]="user.login"
                [size]="24"
            ></user-picture>
        </a>
    </ng-container>
</div>
