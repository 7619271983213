import type {Computation, _Computation} from './../computation';
import type {ComputationResult} from './../computation-result';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.common.DummyComputation
 */
export interface DummyComputation extends _Computation {
    // PolyJson type
    type: 'dummy';
}

export namespace DummyComputation {
    export const type = 'dummy';
}

/** @deprecated Use Computation.isDummyComputation() */ export function isDummyComputation(toTest: Computation): toTest is DummyComputation { return DummyComputation.type === toTest['type']; }

export namespace DummyComputation {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.common.DummyComputation$DummyComputationResult
     */
    export interface DummyComputationResult extends _AvailableResult {
        // PolyJson type
        type: 'dummy';
    }

    export namespace DummyComputationResult {
        export const type = 'dummy';
    }

    /** @deprecated Use ComputationResult.isDummyComputationResult() */ export function isDummyComputationResult(toTest: ComputationResult): toTest is DummyComputationResult { return DummyComputationResult.type === toTest['type']; }
}