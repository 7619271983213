<form
    [formGroup]="configForm"
    id="config-form"
    class="dkuform-modal-vertical"
>
    <h4>{{ params.column.name }}</h4>
    <binning-config formControlName="binningConfig"
        [isCategorical]="true">
    </binning-config>
</form>
