import type {FilterDesc} from './../../../../../../../../../dss-core/src/main/java/com/dataiku/dip/dataflow/exec/filter/filter-desc';
import type {_InputDescBase} from './../joinlike/input-desc-base';
import type {_VirtualInputBasedRecipePayloadParams} from './../../common/virtual-input-based-recipe-payload-params';

/**
 * Generated from com.dataiku.dip.dataflow.exec.vstack.VStackRecipePayloadParams
 */
export interface _VStackRecipePayloadParams extends _VirtualInputBasedRecipePayloadParams {
    addOriginColumn: boolean;
    copySchemaFromDatasetWithName?: string | null;
    mode: _VStackRecipePayloadParams.SchemaMergeMode;
    originColumnName: string;
    postFilter: FilterDesc;
    selectedColumns?: string[] | null;
    selectedColumnsIndexes?: number[] | null;
}

export namespace _VStackRecipePayloadParams {
    /**
     * Generated from com.dataiku.dip.dataflow.exec.vstack.VStackRecipePayloadParams$InputDesc
     */
    export interface InputDesc extends _InputDescBase {
        columnsMatch: string[];
        preFilter: FilterDesc;
    }

    /**
     * Generated from com.dataiku.dip.dataflow.exec.vstack.VStackRecipePayloadParams$SchemaMergeMode
     */
    export enum SchemaMergeMode {
        UNION = 'Union of input schemas',
        INTERSECT = 'Intersection of input schemas',
        FROM_DATASET = 'Use schema from dataset',
        FROM_INDEX = 'Using column order',
        REMAP = 'Manually remapping',
        CUSTOM = 'Custom defined schema'
    }
}