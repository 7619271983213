/**
 * Generated from com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams
 */
export interface FeatureGenerationParams {
    categoricals_count_transformer: FeatureGenerationParams.CategoricalsCountTransformerGenerator;
    manual_interactions: FeatureGenerationParams.ManualPairwiseInteractions;
    numericals_clustering: FeatureGenerationParams.NumericalsClusteringGenerator;
    pairwise_linear: FeatureGenerationParams.PairwiseLinearCombinations;
    polynomial_combinations: FeatureGenerationParams.PolynomialCombinations;
}

export namespace FeatureGenerationParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$FeatureInteraction
     */
    export interface FeatureInteraction {
        column_1: string;
        column_2: string;
        max_features: number;
        rescale: boolean;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$PairwiseLinearCombinations
     */
    export interface PairwiseLinearCombinations extends FeatureGenerationParams.AutomatizableFeatureGenerator {}

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$CategoricalsCountTransformerGenerator
     */
    export interface CategoricalsCountTransformerGenerator extends FeatureGenerationParams.AutomatizableFeatureGenerator {
        all_features: boolean;
        input_features: string[];
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$ManualPairwiseInteractions
     */
    export interface ManualPairwiseInteractions {
        interactions: FeatureGenerationParams.FeatureInteraction[];
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$NumericalsClusteringGenerator
     */
    export interface NumericalsClusteringGenerator extends FeatureGenerationParams.AutomatizableFeatureGenerator {
        all_features: boolean;
        input_features: string[];
        k: number;
        transformation_mode: FeatureGenerationParams.NumericalsClusteringGenerator.TransformationMode;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$PolynomialCombinations
     */
    export interface PolynomialCombinations extends FeatureGenerationParams.AutomatizableFeatureGenerator {}

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$AutomatizableFeatureGenerator
     */
    export interface AutomatizableFeatureGenerator {
        behavior: FeatureGenerationParams.GeneratorBehavior;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$GeneratorBehavior
     */
    export enum GeneratorBehavior {
        DISABLED = 'DISABLED',
        ENABLED_AUTOMATIC = 'ENABLED_AUTOMATIC',
        ENABLED_MANUAL = 'ENABLED_MANUAL'
    }
}

export namespace FeatureGenerationParams.NumericalsClusteringGenerator {
    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$NumericalsClusteringGenerator$TransformationMode
     */
    export enum TransformationMode {
        REPLACE_BY_DISTANCE = 'REPLACE_BY_DISTANCE',
        IMPACT_CODE_CLUSTERID = 'IMPACT_CODE_CLUSTERID',
        DUMMIFY_CLUSTERID = 'DUMMIFY_CLUSTERID'
    }
}