import { Component, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { fairAny } from 'dku-frontend-core';

@Component({
    selector: 'data-collection-path',
    templateUrl: './data-collection-path.component.html',
    styleUrls: ['./data-collection-path.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataCollectionPathComponent {
    @Input() readonly id!: string;
    @Input() readonly displayName!: string;

    constructor(
        @Inject("$state") public $state: fairAny,
    ) { }
}
