<ng-container *ngIf="!params.showSummary">
    <ng-container *ngTemplateOutlet="correlogram"></ng-container>
</ng-container>

<div class="card-layout" *ngIf="params.showSummary">
    <div class="card-layout__section card-layout__section--vertical">

        <div class="card-layout__section">
            <ng-container *ngTemplateOutlet="correlogram"></ng-container>
        </div>

        <div class="card-layout__section">
            <div class="card-layout__title">Correlogram</div>
            <div class="card-layout__content">
                <table class="stats-table">
                    <tbody class="stats-table__body">
                        <tr class="stats-table__row" *ngIf="params.isPartial">
                            <th class="stats-table__head">Method</th>
                            <td class="stats-table__cell">{{ methodLabel }}</td>
                        </tr>
                        <tr class="stats-table__row" *ngIf="!params.isPartial">
                            <th class="stats-table__head">Adjust for the loss of data</th>
                            <td class="stats-table__cell">{{ params.adjusted ? 'Yes' : 'No' }}</td>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">Number of lags</th>
                            <td class="stats-table__cell">{{ nLagsLabel }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <time-series-summary
            class="card-layout__section"
            [params]="params"
            [results]="results"
        ></time-series-summary>
    </div>
</div>

<ng-template #correlogram>
    <lazy-echart
        class="chart"
        [class.chart--fixed-height-tall]="hasFixedHeight"
        [options]="acfPlotOptions">
    </lazy-echart>
</ng-template>
