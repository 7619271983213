import { Component, Output, Input, EventEmitter, OnChanges, ChangeDetectionStrategy, ViewChild, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { DataSpec, ContainerExecSelection } from 'src/generated-sources';
import { DkuPopoverComponent } from '@app/widgets/dropdowns/dku-popover/dku-popover.component';

@Component({
    selector: 'container-settings',
    templateUrl: './container-settings.component.html',
    styleUrls: [
        '../../shared-styles/forms.less',
        '../../shared-styles/header-button.less',
        './container-settings.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainerSettingsComponent implements OnChanges {
    @Input() dataSpec: DataSpec;
    @Input() readOnly = false;
    @Output() dataSpecChange = new EventEmitter<DataSpec>();
    @ViewChild('popover') popover: DkuPopoverComponent;
    selection: ContainerExecSelection;

    save() {
        if (!this.readOnly) {
            this.dataSpecChange.emit({
                ... this.dataSpec,
                containerSelection: this.selection
            });
            this.popover.hide();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dataSpec) {
            this.selection = this.dataSpec.containerSelection;
        }
    }
}
