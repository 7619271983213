<header class="workspace-header">

    <initials-icon class="workspace-header__icon" [name]="workspace.displayName" [color]="workspace.color" [size]="64"></initials-icon>

    <div class="workspace-header__metadata">

        <!-- Name -->
        <section class="workspace-header__top">
            <span
                class="workspace-header__name"
                [textContent]="workspace.displayName">
            </span>

            <!-- Members -->
            <div class="workspace-header__user-badge"
                matTooltip="Click to see workspace users"
                matTooltipPosition="right"
                (click)="openMembersModal()">
                <i *ngIf="hasGroups" class="dku-icon-user-group-24"></i>
                <user-picture class="workspace-header__user-badge-avatar" *ngFor="let avatar of avatars" [user]="avatar" [size]="24"></user-picture>
                <span class="mleft4 dku-text-sb" [textContent]="workspace.permissions.length"></span>
                <i *ngIf="workspace.currentUserPermissions.admin" class="dku-icon-edit-16 workspace-header__user-badge-icon"></i>
            </div>
        </section>

        <!-- Description -->
        <section #description class="workspace-header__description-container" 
        [class]="{ 
            'workspace-header__description-container--no-space': (isWordBreak$ | async),
            'workspace-header__description-container--expanded': (isExpanded$ | async),
            'workspace-header__description-container--can-expand': (canExpand$ | async) && (isExpanded$ | async) === false
        }">
        <markdown
            class="workspace-header__description-content markdown-std"
            [from]="workspace.description || 'No description for this workspace'"
        ></markdown>
        </section>
        <div class="workspace-header__description-show-action">
            <button *ngIf="(canExpand$ | async)" class="btn btn--text btn--primary" (click)="setExpanded(!isExpanded$.value)" >
                {{ (isExpanded$ | async) === true ? 'Show Less' : 'Show More' }}
            </button>
        </div>
    </div>

    <span class="workspace-header__top-actions-btns">
        <button *ngIf="canAddContent" class="btn btn--secondary btn--dku-icon workspace-header__top-actions-btn" (click)="openAddObjectModal()" onfocus="this.blur()"><i class="dku-icon-plus-16"></i> Add content</button>
        <workspace-create-datastory *ngIf="canCreateDatastory" class="workspace-header__top-actions-btn" [workspace]="workspace"></workspace-create-datastory>
        <button class="dropdown-toggle" class="btn btn--secondary btn--text btn--icon btn--dku-icon workspace-header__top-actions-btn" [matMenuTriggerFor]="actionMenu" *ngIf="workspace.currentUserPermissions.admin">
            <i class="dku-icon-menu-vertical-dots-16 text-important"></i>
        </button>

        <mat-menu #actionMenu>
            <ng-template matMenuContent>
                <button mat-menu-item (click)="openEditWorkspaceModal()" class="df aic">
                    <i class="mright8 dku-icon-edit-16"></i>Edit
                </button>
                <button mat-menu-item (click)="openDeleteWorkspaceModal()" class="df aic text-error">
                    <i class="mright8 dku-icon-trash-16"></i>Delete
                </button>
            </ng-template>
        </mat-menu>
    </span>

</header>
