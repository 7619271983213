<div class="empty-list-cta">
    <h1>{{ warning.message }}</h1>
    <p class="small" *ngIf="canAccessTab">
        Go to
        <a href="{{$state.href('projects.project.labelingtasks.labelingtask.'+warning.redirectionTab , {selectedTab: warning.redirectionSubTab })}}">
            {{ warning.redirectionTab }}
        </a>
    </p>
    <p class="small" *ngIf="!canAccessTab">
        Contact your labeling administrator
    </p>
</div>