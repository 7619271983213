import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditableListComponent, EditableListArrowComponent, EditableListTemplateComponent, EditableListDeleteComponent, EditableListDragComponent } from './editable-list.component';
import { EditableListInputComponent } from '../editable-list-input/editable-list-input.component';
import { EditableListInputModule } from '../editable-list-input/editable-list-input.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ComponentsModule } from '@shared/components/components.module';
import { InViewportModule } from 'ng-in-viewport';

@NgModule({
    declarations: [
        EditableListComponent,
        EditableListTemplateComponent,
        EditableListArrowComponent,
        EditableListDeleteComponent,
        EditableListDragComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        ComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        ScrollingModule,
        EditableListInputModule,
        InViewportModule
    ],
    exports: [
        EditableListComponent,
        EditableListTemplateComponent,
        EditableListArrowComponent,
        EditableListDeleteComponent,
        EditableListDragComponent,
        EditableListInputComponent
    ]
})
export class EditableListModule { }
