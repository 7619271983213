
<ng-select
    [formControl]="tagsControl"
    appendTo="body"
    [virtualScroll]="true"
    [placeholder]="'Select tags'"
    [clearable]="false"
    [searchable]="false"
    [multiple]="true"
    [closeOnSelect]="false"
    [searchFn]="searchFn"
    [items]="(filteredTags$ | async) ?? []"
    bindLabel="tag"
    bindValue="tag">

    <ng-template ng-header-tmp>
        <ng-select-search-input></ng-select-search-input>
    </ng-template>

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <!-- Template for the menu trigger in multiple mode -->
        <div class="select-trigger-content">
            Tags | <span class="selected-count">{{ items.length }} selected</span>
        </div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item">
        <ng-container *ngTemplateOutlet="optionItem; context: { item: item }"></ng-container>
    </ng-template>
</ng-select>

<ng-template #optionItem let-item="item">
    <div class="option-item">
        <ng-select-pseudo-checkbox></ng-select-pseudo-checkbox>
        <div class="bullet" [style.background-color]="getTagColor(item.tag)"></div>
        <div class="tag-name"><ui-global-tag [tag]="item.tag" objectType="DATA_COLLECTION"></ui-global-tag></div>
        <div class="tag-count">{{ item.count }}</div>
    </div>
</ng-template>
