import { Injectable } from "@angular/core";
import { fabric } from "fabric";
import { LabelingDrawingService } from "../../object-detection-image-canvas/labeling-drawing.service";

@Injectable()
export class LabelingAnnotateDrawingService extends LabelingDrawingService {

    createGroup(rect: fabric.Rect) {
       let group = new fabric.Group([rect], this.GROUP_SETTINGS);
       group.setControlsVisibility({ mtr: false });
       return group;
    }
}