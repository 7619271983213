import { Injectable } from "@angular/core";

export interface ShortcutMapBuilderInterface<T extends string> {
    getShortcutMap(event: KeyboardEvent): {[key in T]: boolean};
}

export abstract class AbstractShortcutService<T extends string> {

    abstract getShortcutMapBuilder(): ShortcutMapBuilderInterface<T>;

    public isShortcut(event: KeyboardEvent, action: T): boolean {
        switch ((event?.target as HTMLElement)?.tagName?.toLowerCase()) {
            case 'input':
            case 'textarea':
                return false;
            default:
                return this.getShortcutMapBuilder().getShortcutMap(event)[action];
        }
    }
}

export type ShortcutAction = "BACK" | "NEXT";

export class ShortcutMapBuilder implements ShortcutMapBuilderInterface<ShortcutAction> {
    getShortcutMap(event: KeyboardEvent) {
        return {
            BACK: event.code === 'ArrowLeft' || event.key === 'a' || event.key === 'q',
            NEXT: event.code === 'ArrowRight'|| event.key === 'd'
        };
    }
}

@Injectable()
export class ShortcutService extends AbstractShortcutService<ShortcutAction> {
    getShortcutMapBuilder(): ShortcutMapBuilderInterface<ShortcutAction> {
        return new ShortcutMapBuilder();
    }
}
