<table class="fit-table">
    <tbody>
        <tr>
            <th class="fit-table__head">Curve</th>
        </tr>
        <tr>
            <td class="fit-table__cell">
                <lazy-echart class="curve-chart"
                    [options]="chartOptions"
                ></lazy-echart>

            </td>
        </tr>
    </tbody>
</table>

<table class="fit-table">
    <tbody>
        <tr>
            <th class="fit-table__head">Fit type</th>
            <th class="fit-table__head">Metrics</th>
            <th class="fit-table__head">Parameters</th>
        </tr>

        <tr *ngFor="let fitResult of results.parametrizedCurves; let curveIndex = index;">
            <th class="fit-table__head">
                <span [ngStyle]="{color: colorsService.getColorFromIndex(curveIndex), 'font-size':'30px'}">&bull;</span>
                {{ fitResult.parametrized.type }}
            </th>
            <td class="fit-table__cell">
                <table class="stats-table">
                    <tbody class="stats-table__body">
                        <tr class="stats-table__row">
                            <th class="stats-table__head">R2</th>
                            <td class="stats-table__cell">{{ fitResult.scores.r2 | smarterNumber }}</td>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">RMSE</th>
                            <td class="stats-table__cell">{{ fitResult.scores.rmse | smarterNumber }}</td>
                        </tr>
                    </tbody>
                </table>
            </td>
            <td class="fit-table__cell">
                <table
                    *ngIf="fitResult.parametrized.type == 'polynomial'"
                    class="stats-table"
                >
                    <tbody class="stats-table__body">
                        <tr class="stats-table__row">
                            <th class="stats-table__head">Degree</th>
                            <td class="stats-table__cell" [qa]="'eda-fit-curve-card-degree'">{{fitResult.parametrized.coefs.length - 1}}</td>
                        </tr>
                        <tr
                            class="stats-table__row"
                            *ngFor="let coef of fitResult.parametrized.coefs; let coefIndex = index;"
                        >
                            <th class="stats-table__head">x{{coefIndex}}</th>
                            <td class="stats-table__cell">{{ coef }}</td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</table>
