import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { IScope } from "angular";
import { AccessibleObjectOption } from "../accessible-objects-selector.component";

@Component({
    selector: 'downgraded-accessible-objects-selector',
    templateUrl: './downgraded-accessible-objects-selector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DowngradedAccessibleObjectsSelectorComponent {
    @Input() objects: AccessibleObjectOption[] = [];
    @Output() objectChange = new EventEmitter<AccessibleObjectOption[]>();
    @Input() object: AccessibleObjectOption[] = [];
    @Input() type: string;
    @Input() multi: boolean;
    @Input() placeholder: string = "Nothing selected";
    @Input() hideForeign: boolean = true;
    @Input() showObjectLink: boolean = false;
    @Input() selectClass: string;

    constructor(@Inject('$rootScope') private $rootScope: IScope) {}

    handleChange(objects: AccessibleObjectOption[]) {
        this.$rootScope.$applyAsync();
        this.objectChange.emit(objects);
    }
}