import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SplitBySpec, Variable, BinningMode } from 'src/generated-sources';
import { ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { DkuPopoverComponent } from '@app/widgets/dropdowns/dku-popover/dku-popover.component';
import { toggleFormControl } from '@utils/toggle-form-control';
import { CardWizardVariable } from '@features/eda/card-models';
import { SampleContextService } from '@features/eda/sample-context.service';
import { DEFAULT_SPLIT_BY_SPEC } from '@utils/dss-defaults';


@Component({
    selector: 'split-by-settings',
    templateUrl: './split-by-settings.component.html',
    styleUrls: [
        '../../shared-styles/forms.less',
        './split-by-settings.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplitBySettingsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() splitBy: SplitBySpec | null | undefined;
    @Input() allowGroupAll = true;
    @Input() readOnly: boolean;
    @Input() acceptsColoring: boolean;
    @Output() splitByChange = new EventEmitter<SplitBySpec | null>();
    @ViewChild('splitByMenu') popover: DkuPopoverComponent;

    variables$: Observable<Variable[]>;

    form: FormGroup;

    constructor(
        private fb: FormBuilder,
        private sampleContextService: SampleContextService
    ) {
        const selectedVariableControl = this.fb.control(null);
        const enableFunction = (variable: CardWizardVariable | null) => !!variable;

        this.form = this.fb.group({
            selectedVariable: selectedVariableControl,
            binningConfig: toggleFormControl(this.fb.control(null, [Validators.required]), selectedVariableControl, enableFunction, () => { this.patchBinningConfig(); }),
            groupWithAll: toggleFormControl(this.fb.control(null), selectedVariableControl, enableFunction, () => { this.patchGroupWithAll(); })
        });
    }

    ngOnInit() {
        this.variables$ = this.sampleContextService.availableVariables();
    }

    patchBinningConfig(): void {
        this.form.patchValue({
            binningConfig: this.splitBy ? {
                maxValues: this.splitBy.maxValues,
                groupWithOthers: this.splitBy.groupWithOthers,
                binningMode: this.splitBy.binningMode,
                customBinningBoundaries: this.splitBy.customBinningBoundaries,
            } : {...DEFAULT_SPLIT_BY_SPEC},
        });
    }

    patchGroupWithAll(): void {
        this.form.patchValue({
            groupWithAll: this.splitBy ? this.splitBy.groupWithAll : true,
        });

    }

    patchForm(): void {
        this.form.patchValue({
            selectedVariable: this.splitBy ? {
                ...this.splitBy.groupingColumn
            } : null,

            binningConfig: this.splitBy ? {
                maxValues: this.splitBy.maxValues,
                groupWithOthers: this.splitBy.groupWithOthers,
                binningMode: this.splitBy.binningMode,
                customBinningBoundaries: this.splitBy.customBinningBoundaries,
            } : {...DEFAULT_SPLIT_BY_SPEC},

            groupWithAll: this.splitBy ? this.splitBy.groupWithAll : true,
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.splitBy) {
            this.patchForm();
        }
    }

    save() {
        if (this.form.valid) {
            this.changeSplitByColumn();
        }

        this.popover.hide();
    }

    ngOnDestroy() {

    }

    changeSplitByColumn() {
        const form = this.form.value;

        if (form.selectedVariable) {
            this.splitByChange.next({
                groupingColumn: form.selectedVariable,
                maxValues: form.binningConfig.maxValues,
                groupWithOthers: form.binningConfig.groupWithOthers,
                binningMode: form.binningConfig.binningMode,
                customBinningBoundaries: form.binningConfig.customBinningBoundaries,
                groupWithAll: this.allowGroupAll && form.groupWithAll
            });
        } else {
            this.splitByChange.next(null);
        }
    }

    get isCategorical(): boolean {
        return this.form.value.selectedVariable ? this.form.value.selectedVariable.type === Variable.Type.CATEGORICAL : false;
    }

    get splitByColumnName(): string {
        return this.splitBy?.groupingColumn?.name ?? this.noVariableLabel;
    }

    get noVariableLabel(): string {
        return this.acceptsColoring ? 'No color' : 'No split';
    }
}
