import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

    transform(input: string): string {
        if (input && input.length > 0) {
            return input.charAt(0).toUpperCase() + input.slice(1);
        } else {
            return '';
        }
    }
}
