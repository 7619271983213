import { DkuHttpService, fairAny } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { InterestsInternalDB } from 'src/generated-sources';

@Injectable({ providedIn: 'root' })
export class PrivateInterestsAPI {

    constructor(private dkuHttp: DkuHttpService) { }

    getForObject(user: fairAny, objectType: string, projectKey: string, objectId: string, workspaceKey: string): Observable<fairAny> {
        return this.dkuHttp.request('GET', '/interests/get-interest-for-object', {
            user,
            objectType,
            projectKey,
            objectId,
            workspaceKey,
        });
    }

    getUserInterests(user: fairAny, offset: number, maxItems: number, filters: fairAny, starsOnly: boolean): Observable<fairAny> {
        return this.dkuHttp.request('GET', '/interests/get-user-interests', {
            user,
            offset,
            maxItems,
            filters,
            starsOnly,
        });
    }

    watch(items: Array<fairAny>, watch: InterestsInternalDB.Watching): Observable<fairAny> {
        return this.dkuHttp.request('POST', '/interests/watch', {
            items: JSON.stringify(items),
            watch,
        });
    }

    star(items: Array<fairAny>, star: boolean): Observable<fairAny> {
        return this.dkuHttp.request('POST', '/interests/star', {
            items: JSON.stringify(items),
            star,
        });
    }

    listWatchingUsers(objectType: string, projectKey: string, objectId: string, workspaceKey: string): Observable<fairAny> {
        return this.dkuHttp.request('GET', '/interests/list-watching-users', {
            objectType,
            projectKey,
            objectId,
            workspaceKey,
        });
    }

    listUsersWithStar(objectType: string, projectKey: string, objectId: string, workspaceKey: string): Observable<fairAny> {
        return this.dkuHttp.request('GET', '/interests/list-users-with-star', {
            objectType,
            projectKey,
            objectId,
            workspaceKey,
        });
    }
}
