import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class WindowService {
    // Shared subscription to window:resize events
    // Note: it is MUCH more efficient to subscribe to this observable rather
    //       than relying on @HostListener('window:resize')
    resize$ = fromEvent<void>(window, 'resize').pipe(share());
}
