import { Pipe, PipeTransform } from '@angular/core';


/**
 * Equivalent of 'instanceof' operator in JS as an Angular pipe
 * (obj | instanceOf: MyClass)  == obj instanceof MyClass
 */
@Pipe({ name: 'instanceOf' })
export class InstanceOfPipe implements PipeTransform {
    transform<Parent, Impl extends Parent>(value: Parent | null | undefined, clazz: (new (...vals: any) => Impl)): value is Impl {
        return !!(value && value instanceof clazz);
    }
}
