import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gentleTruncateBeginning'
})

export class GentleTruncateBeginningPipe implements PipeTransform {
    transform(str: string | null, len: number): any {
        /**
         * Truncate a string to make sure it takes at most
         * n characters.
         *
         * If str is not a string, returns str unchanged.
         */
         if ((typeof str != "string") || (str.length <= len)) {
            return str;
        }
        const cutOn = /[ \/,\.,;\-\\\"\n\?\!\|]/;
        let truncated = str.substring(str.length - len, str.length);
        const res = cutOn.exec(truncated);

        let firstSeparatorIdx;
        if (res) {
            firstSeparatorIdx = res.index;
        }
        // we don't want to cut more too much.
        if (firstSeparatorIdx && firstSeparatorIdx < truncated.length / 2) {
            truncated = truncated.substring(firstSeparatorIdx, truncated.length);
        }
        return "..." + truncated;
    }
}
