import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Workspace } from '@model-main/workspaces/workspace';
import { WorkspaceModalService } from '../../../../shared';
import { ITaggingService } from "@model-main/server/services/itagging-service";


@Component({
    selector: 'workspace-create-datastory',
    templateUrl: './workspace-create-datastory.component.html',
})
export class WorkspaceCreateDatastoryComponent implements OnChanges {
    canCreateDatastory = false;

    @Input() workspace: Workspace;

    constructor(
        private workspaceModal: WorkspaceModalService,
    ) {
    }

    ngOnChanges({ workspace }: SimpleChanges): void {
        if (workspace) {
            this.canCreateDatastory = this.workspace.workspaceObjects.some(obj => obj.reference?.type === ITaggingService.TaggableType.DATASET);
        }
    }

    openCreateDatastoryModal(): void {
        this.workspaceModal.createDatastory(this.workspace);
    }
}
