<div
    class="vertical-flex"
    *ngIf="columnCollapseStates$ | async as columnCollapseStates"
>
    <div class="horizontal-flex card-container column-card-drag-drop-boundary" cdkDropList [cdkDropListDisabled]="readOnly" cdkDropListOrientation="horizontal"
        (cdkDropListDropped)="dropColumn($event)">
        <ng-container *ngFor="let columnCard of params.cards; let columnIndex = index; trackBy: trackByColumnName">
            <div
                cdkDrag
                *ngIf="columnCard.type === 'column_card'"
                class="card--column-card"
                [class.card--collapsed]="columnCollapseStates[columnIndex]"
                [class.card--column-wide]="params.type === 'bivariate_header'"
                [qa]="{
                    'eda-column-card': {
                        'id': columnCard.id,
                        'column-name': columnCard.column.name
                    }
                }"
            >
                <ng-container *ngIf="!columnCollapseStates[columnIndex]; else dragcollapsed">
                    <div class="card--column-card drag-drop-placeholder" *cdkDragPlaceholder></div>
                </ng-container>
                <ng-template #dragcollapsed>
                    <div class="card--column-card drag-drop-placeholder drag-drop-placeholder--collapsed" *cdkDragPlaceholder></div>
                </ng-template>
                <div
                    cdkDragHandle
                    class="column-card__title"
                    [class.column-card__title--collapsed]="columnCollapseStates[columnIndex]"
                >
                    <column-title
                        (click)="toggleColumn(columnCard.column, !columnCollapseStates[columnIndex])"
                        class="column-card__title-content"
                        [class.column-card__title-content--collapsed]="columnCollapseStates[columnIndex]"
                        [column]="columnCard.column"
                        [response]="isBivariateHeaderCard(params) ? params['yColumn'] : undefined"
                        [collapsed]="columnCollapseStates[columnIndex]"
                        [splitBy]="params.splitBy"
                        [showCollapsingControls]="showCollapsingControls"
                    ></column-title>

                    <card-menu
                        *ngIf="!columnCollapseStates[columnIndex]"
                        class="pull-right eda__header-menu"
                        [params]="columnCard"
                        [readOnly]="readOnly"
                        [deletable]="true"
                        [extendedActions]="extendedActions"
                        (action)="handleColumnCardAction(columnIndex, $event)"
                    >
                    </card-menu>
                </div>
                <lazy-render
                    class="column-card__body"
                    *ngIf="!columnCollapseStates[columnIndex] | delayWhenFalse"
                    [class.column-card__body--wide]="params.type === 'bivariate_header'"
                    [class.column-card__body--collapsed]="columnCollapseStates[columnIndex]"
                    [visibleTemplate]="visible"
                    [trackData]="columnCard"
                    [passThrough]="params.cards.length < 20">
                    <ng-template #visible>
                        <ng-container *ngIf="{
                            columnParams: params.cards[columnIndex],
                            columnResults: results.results[columnIndex]
                        } as ctx">
                            <column-card-body
                                *ngIf="isColumnCard(ctx.columnParams) && isColumnCardResult(ctx.columnResults)"
                                [params]="ctx.columnParams"
                                [results]="ctx.columnResults"
                                [headerParams]="params"
                                (headerAction)="action.emit($event)"
                                [readOnly]="readOnly"
                                [extendedActions]="extendedActions"
                                (action)="handleColumnCardAction(columnIndex, $event)"
                            ></column-card-body>
                        </ng-container>
                    </ng-template>
                </lazy-render>
            </div>
        </ng-container>

        <div *ngIf="!readOnly"
            class="card--column-card-placeholder card--column-card"
        >
            <div
                class="column-card--placeholder column-card__body"
                (click)="configureAnalysis()"
            >
                + Add another variable
            </div>
        </div>
    </div>
</div>
