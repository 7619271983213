import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

/*
    Alternative to carousel component
*/
@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class CarouselService<T> {
    items$ = new BehaviorSubject<T[]>([]);
    selectedIndex$ = new BehaviorSubject<number>(0);
    selectNext$ = new Subject<void>();
    selectPrevious$ = new Subject<void>();
    isFirst$ = new BehaviorSubject<boolean>(true);
    isLast$ = new BehaviorSubject<boolean>(false);

    constructor() {
        this.selectPrevious$.pipe(
            withLatestFrom(
                this.selectedIndex$
            ),
            untilDestroyed(this)
        ).subscribe(([_, selectedIndex])  => {
            if (selectedIndex > 0) {
                this.selectedIndex$.next(selectedIndex - 1);
            }
        });

        this.selectNext$.pipe(
            withLatestFrom(
                this.selectedIndex$,
                this.items$
            ),
            untilDestroyed(this)
        ).subscribe(([_, selectedIndex, items]) => {
            if (items.length > 0 && selectedIndex < items.length - 1) {
                this.selectedIndex$.next(selectedIndex + 1);
            }
        });

        this.selectedIndex$.pipe(
            withLatestFrom(
                this.items$
            ),
            untilDestroyed(this)
        ).subscribe(([selectedIndex, items]) => {
            this.isFirst$.next(selectedIndex === 0);
            this.isLast$.next(selectedIndex === items.length - 1);
        });
    }

    selectNext() {
        this.selectNext$.next();
    }

    selectPrevious() {
        this.selectPrevious$.next();
    }
}
