import { Component, OnChanges, ChangeDetectionStrategy, ViewChild, ElementRef, HostListener, Output, EventEmitter, TemplateRef, Input, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HeatmapComponent } from '../heatmap/heatmap.component';
import _ from 'lodash';
import { scaleLog } from 'd3-scale';
import { FormBuilder, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'confusion-matrix',
  templateUrl: './confusion-matrix.component.html',
  styleUrls: ['./confusion-matrix.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfusionMatrixComponent extends HeatmapComponent implements OnChanges {
    @Input() popoverTemplate: TemplateRef<any>;
    @Input() popoverWidth = 225;
    @Input() popoverHeight = 140;
    @Output() cellClicked = new EventEmitter<{xIndex: number, yIndex: number} | null>();
    @ViewChild('container', { static: true }) container: ElementRef;
    @ViewChild('matrix', { static: true }) matrix: ElementRef;
    @ViewChild('sidebar', { static: true }) sidebar: ElementRef;

    showLargeView = true;
    popoverOffsetTop: number;
    popoverOffsetLeft: number;
    isPopoverVisible: boolean = false;
    hoveredCell: {
        xLabel: string,
        yLabel: string,
        value: any
    };
    selectedCell: {
        xIndex: number,
        yIndex: number
    } | null;
    filteredLabelMap: {
        [key: string]: boolean
    } = {};

    form = this.formBuilder.group({
        selectedCategories: new FormControl()
    })

    readonly MINI_VIEW_THRESHOLD = 8; // enable popover when there are more this number of visible classes
    private popoverPositionX = 'right';
    private popoverPositionY = 'bottom';
    private popoverMargin = 8;

    @HostListener('document:click', ['$event']) outsideClicked() {
        // Click outside of the menu was detected
        this.hidePopover();
    }

    constructor(
        private ref: ElementRef,
        private formBuilder: FormBuilder
    ) { 
        super();
    }

    ngOnInit() {
        if (this.maskedXLabels.length > this.MINI_VIEW_THRESHOLD) {
            this.form.get('selectedCategories')!.valueChanges.pipe(
                map((selectedCategories: string[]) => {
                    return (selectedCategories || []).reduce((map: { [key: string]: boolean }, category: string) => {
                        map[category] = true;
                        return map;
                    }, {});
                }),
                untilDestroyed(this)
            ).subscribe(filteredLabelMap => {
                this.filteredLabelMap = filteredLabelMap;
                this.toggleView();
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);

        if (changes.filteredLabelMap || changes.data) {
            this.toggleView();
        }
    }

    computeColor(param: number | null): string {
        return scaleLog<string>()
            .domain([1, this.dataMax])
            .range(['#F2F2F2', '#323dff'])
            (param || 1); // log scale cannot include 0
    }

    clickCell(xIndex: number, yIndex: number) {
        if (this.selectedCell && this.selectedCell.xIndex === xIndex && this.selectedCell.yIndex === yIndex) {
            this.selectedCell = null;
        } else {
            this.selectedCell = {
                xIndex,
                yIndex
            };
        }
        this.cellClicked.emit(this.selectedCell);
    }

    hoverCell(event: MouseEvent, xIndex: number, yIndex: number) {
        const cellElement = event.target as HTMLElement;
        const containerOffset = this.ref.nativeElement.getBoundingClientRect();
        const cellOffset = cellElement.getBoundingClientRect();

        this.popoverOffsetTop = cellOffset.top - containerOffset.top + event.offsetY + (this.popoverPositionY === 'top' ? -this.popoverHeight - this.popoverMargin : 0 + this.popoverMargin);
        this.popoverOffsetLeft = cellOffset.left - containerOffset.left + event.offsetX + (this.popoverPositionX === 'left' ? -this.popoverWidth - this.popoverMargin: 0 + this.popoverMargin);
        this.hoveredCell = {
            xLabel: this.maskedXLabels[xIndex],
            yLabel: this.maskedYLabels[yIndex],
            value: this.maskedData[xIndex][yIndex] || 0,
        };
        
        this.showPopover();
    }

    positionPopover(event: MouseEvent) {
        const cellElement = event.target as HTMLElement;
        const cellOffset = cellElement.getBoundingClientRect();
        
        const containerElement = this.ref.nativeElement;
        const containerWidth = containerElement.offsetWidth;
        const containerHeight = containerElement.offsetHeight;
        const containerOffset = containerElement.getBoundingClientRect();

        // determine which side the popover should be displayed on
        this.popoverPositionX = cellOffset.left - containerOffset.left + this.popoverWidth + 2 * this.popoverMargin > containerWidth ? 'left' : 'right'; // double margin to ensure direction switch early
        this.popoverPositionY = cellOffset.top - containerOffset.top + this.popoverHeight + 2 * this.popoverMargin > containerHeight ? 'top' : 'bottom';
    }

    xIsSelected(xIndex: number) {
        return this.selectedCell && xIndex === this.selectedCell.xIndex;
    }

    yIsSelected(yIndex: number) {
        return this.selectedCell && yIndex === this.selectedCell.yIndex;
    }

    showPopover() {
        this.isPopoverVisible = true;
    }

    hidePopover() {
        this.isPopoverVisible = false;
    }
    
    isLabelVisible(label: string) {
        return _.isEmpty(this.filteredLabelMap) || this.filteredLabelMap[label];
    }

    toggleView() {
        this.showLargeView = (Object.keys(this.filteredLabelMap).length || this.maskedData?.length) > this.MINI_VIEW_THRESHOLD;   
    }
}
