import { Injectable } from '@angular/core';
import { Workspace } from '@model-main/workspaces/workspace';


@Injectable({
    providedIn: 'root',
})
export class WorkspaceUtilsService {

    getWorkspaceObjectId(object: Workspace.WorkspaceObject): string | undefined {
        if (object.appId) {
            return object.appId;
        } else if (object.htmlLink) {
            return object.htmlLink.url;
        } else if (object.reference) {
            return `${object.reference.projectKey}.${object.reference.type}.${object.reference.id}`;
        }
        return undefined;
    }

}
