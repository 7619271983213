import { Component, Input } from '@angular/core';
import { formatDescription, formatShortDesc } from '@features/feature-store/highlight-utils';
import { Highlight } from '@shared/models';

@Component({
    selector: 'right-panel-details-description',
    templateUrl: './right-panel-details-description.component.html',
    styleUrls: ['./right-panel-details-description.component.less'],
})
export class RightPanelDetailsDescriptionComponent {
    @Input() shortDescription: string;
    @Input() longDescription: string;
    @Input() highlights?: Highlight;

    constructor() { }

    formatShortDescription(): string {
        return formatShortDesc(this.highlights, this.shortDescription);
    }

    formatLongDescription(): string {
        return formatDescription(this.highlights, this.longDescription);
    }
}
