import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type HttpVerb = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

/**
 * Minimal API providing access to API using DSS conventions
 */
@Injectable()
export abstract class DkuHttpService {
    public abstract request<T>(
        method: HttpVerb,
        path: string,
        params?: object
    ): Observable<T>;
}
