export enum ColorPalette {
    MEANING = '__dku_meaning__',
    CUSTOM = '__dku_custom__'
}

export interface ColorOptions {
    customColors: Record<string, string | null>;
    colorPalette: ColorPalette;
    customPalette?: {
        colors?: Array<string>;
    };
}
