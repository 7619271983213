import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'dashboard-insight-viewer',
  template: '<insight-page></insight-page>',
  styleUrls: ['./dashboard-insight-viewer.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardInsightViewerComponent {
  //  Wrapper for insight-page, which is an upgraded component from angularJS, should be migrated
}
