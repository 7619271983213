import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'escapeCssString'
})
export class EscapeCssStringPipe implements PipeTransform {
    transform(str: string): string {
        return '"' + CSS.escape(str) + '"';
    }
}
