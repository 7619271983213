<modal-header title="Edit Data Collection Color" icon="dku-icon-stacked-24" [hasBorder]="true"></modal-header>
<form class="dkuform-modal-horizontal">
    <div class="modal-body">
        <div class="control-group">
            <div class="data-collection-modal__color-options">
                <data-collection-icon [name]="displayName" [color]="selectedColor">
                </data-collection-icon>
                <stock-color-picker [color]="selectedColor" (colorChange)="setColor($event)">
                </stock-color-picker>
            </div>
        </div>
    </div>
    <div class="modal-footer modal-footer-std-buttons">
        <div class="pull-right">
            <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
            <button type="submit" class="btn btn-primary" (click)="confirm()" autofocus>
                Save
            </button>
        </div>
    </div>
</form>
