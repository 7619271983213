import { ElementRef, Injector, Directive, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
    selector: 'ui-view'
})
export class UIViewDirective extends UpgradeComponent {
    @Input() addToScope: object;
    
    constructor(elementRef: ElementRef, injector: Injector) {
        super('ng1UiView', elementRef, injector);
    }
}
