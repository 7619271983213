<div class="workspace-error-message">
    <div class="workspace-error-message_container">
        <div class="workspace-error-message__left-bar">
            <img src="/static/dataiku/images/workspaces/workspace-error.svg" width="32" />
        </div>
        <div class="workspace-error-message__content">
            <img src="/static/dataiku/images/workspaces/workspace-error-big.svg" width="190" />
            <h1 class="dku-ultralarge-title-sb text-weak">
                {{ title$ | async }}
            </h1>
            <h2  class="dku-grand-title">
                {{ message$ | async }}
            </h2>
            <div class="text-center">
                <h3 class="dku-medium-title">
                    {{ instructions$ | async }}
                </h3>
                <a target="_self" [href]="redirectLink$ | async">{{redirectLabel$ | async}}</a>
            </div>
        </div>
    </div>
</div>
