import type {PreTrainPredictionModelingParams} from './pre-train-prediction-modeling-params';
import type {ResolvedClassicalPredictionPreprocessingParams} from './resolved-classical-prediction-preprocessing-params';

/**
 * Generated from com.dataiku.dip.analysis.model.prediction.EnsembleParams
 */
export interface EnsembleParams {
    method: EnsembleParams.EnsembleMethod;
    model_ids: string[];
    modeling_params: PreTrainPredictionModelingParams[];
    ordered_hashes: string[];
    preprocessing_hashes: {[key: string]: string};
    preprocessing_params: ResolvedClassicalPredictionPreprocessingParams[];
    proba_inputs: boolean;
    thresholds: number[];
}

export namespace EnsembleParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.EnsembleParams$EnsembleMethod
     */
    export enum EnsembleMethod {
        AVERAGE = 'Average',
        PROBA_AVERAGE = 'Average',
        MEDIAN = 'Median',
        VOTE = 'Majority vote',
        LINEAR_MODEL = 'Linear stacking',
        LOGISTIC_MODEL = 'Logistic stacking'
    }
}