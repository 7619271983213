import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PublicUser } from 'generated-sources';
import { Nullable } from 'dku-frontend-core';

@Component({
    selector: 'right-panel-details-data-steward',
    templateUrl: './right-panel-details-data-steward.component.html',
    styleUrls: ['./right-panel-details-data-steward.component.less'],
})

export class RightPanelDetailsDataStewardComponent {
    @Input() dataSteward: Nullable<PublicUser>;
    @Input() isEditable: boolean;
    @Output() editDataSteward = new EventEmitter<void>();

    constructor() { }
}
