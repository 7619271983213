<form [formGroup]="configForm" class="dkuform-modal-horizontal">
    <div class="control-group">
        <label class="control-label">
            X variable
        </label>
        <variable-selector
            [variables]="allVariables$ | async"
            formControlName="xColumn"
            [qa]="'scatter-plot-3d-x-variable'"
        ></variable-selector>
    </div>

    <div class="control-group">
        <label class="control-label">
            Y variable
        </label>
        <variable-selector
            [variables]="allVariables$ | async"
            formControlName="yColumn"
            [qa]="'scatter-plot-3d-y-variable'"
        ></variable-selector>
    </div>

    <div class="control-group">
        <label class="control-label">
            Z variable
        </label>
        <variable-selector
            [variables]="allVariables$ | async"
            formControlName="zColumn"
            [qa]="'scatter-plot-3d-z-variable'"
        ></variable-selector>
    </div>

    <div class="control-group">
        <label class="control-label">Symbol size</label>
        <input
            type="number"
            formControlName="symbolSize"
        >
    </div>

    <div class="control-group">
        <label class="control-label">
            Max number of points
            <i class="icon-dku-help"
                matTooltip="The points are randomly drawn from the sample of the worksheet"
            ></i>
        </label>
        <input
            type="number"
            formControlName="maxNumberOfPoints"
            [qa]="'maximum-number-of-points'"
        >
    </div>
</form>
