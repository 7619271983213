import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { filterNonNull } from 'dku-frontend-core';
import { combineLatest } from 'rxjs';
import { DeephubImageClassificationReportPainterService } from '@features/deephub/image-classification/report/services/deephub-image-classification-report-painter.service';
import { PainterService } from '@shared/services/item-feed/image-feed/painter.service';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { FeedImageModalService } from '@shared/services/item-feed/image-feed/feed-image-modal.service';

@UntilDestroy()
@Component({
    selector: 'deephub-image-classification-report-image-feed-modal',
    templateUrl: './deephub-image-classification-report-image-feed-modal.component.html',
    styleUrls: ['./deephub-image-classification-report-image-feed-modal.component.less', 
                '../shared-styles/deephub-image-classification-legend.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        DeephubImageClassificationReportPainterService,
        { provide: PainterService, useExisting: DeephubImageClassificationReportPainterService },
    ]
})
export class DeephubImageClassificationReportImageFeedModalComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            imageId: number
        },
        private feedImageModalService: FeedImageModalService,
        private colorMapService: ColorMapContextService

    ) {
        combineLatest([
            this.feedImageModalService.imageId$,
            this.feedImageModalService.canvas$
        ]).pipe(
            filterNonNull(),
        );
    }

    getCategoryColor (category: string) : string | undefined {
        return this.colorMapService.getColor(category);
    }

}

