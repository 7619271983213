<ng-container *ngIf="displayedData">
    <div
        [style.width.px]="overlayWidth"
        [style.height.px]="overlayHeight"
        [style.boxShadow]="boxShadow"
    >
    </div>
    <div class="selectors">
        <div
            *ngFor="let item of displayedData"
            [style.color]="item.color"
        >
            <b>{{ item.selector }}</b>
            <br>
            <span *ngFor="let child of item.derivedSelectors">
                {{ child }}
                <br>
            </span>
        </div>
    </div>
</ng-container>
