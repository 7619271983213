<connection-properties-list 
    [ngModel]="items"
    (ngModelChange)="handleChange($event)" 
    [keyPlaceholder]="keyPlaceholder"
    [valuePlaceholder]="valuePlaceholder"
    [keyIdentifier]="keyIdentifier"
    [valueIdentifier]="valueIdentifier"
    [keyRequired]="keyRequired"
    [valueRequired]="valueRequired"
    [required]="required"
    [addLabel]="addLabel" 
    [sortable]="sortable" 
    [hasDivider]="hasDivider"
    [focusOnEnter]="focusOnEnter"
    [canBeFinal]="canBeFinal"
    (onAdd)="onAdd.emit($event)" 
    (onDelete)="onDelete.emit($event)"
    (onValidityChange)="onValidityChange.emit($event)">
</connection-properties-list>
