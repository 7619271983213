import { Component, Input } from '@angular/core';
import { PublicUser } from 'generated-sources';

@Component({
    selector: 'right-panel-details-text-info',
    templateUrl: './right-panel-details-text-info.component.html',
    styleUrls: ['./right-panel-details-text-info.component.less'],
})

export class RightPanelDetailsTextInfoComponent {
    @Input() label!: string;
    @Input() text!: string;
    @Input() tooltip!: string;
    @Input() user?: PublicUser; // user is expected to have a login or will not be displayed

    constructor() { }
}
