<div class="data-collection-page__collection-picture"
    [class.data-collection-page__collection-picture--editable]="isAdmin"
>
    <data-collection-icon
        [name]="dataCollectionInfo.displayName"
        [color]="dataCollectionInfo.color"
        [size]="80"
        [matTooltip]="isAdmin ? 'Edit data collection color' : ''"
        (click)="isAdmin && openSetColorModal()"
    ></data-collection-icon>
</div>
<div class="data-collection-page__collection-metadata">
    <div id="nameContainer">
        <div
            id="nameDisplayContainer"
            class="data-collection-summary__name-display-container"
            *ngIf="!isEditingDisplayName"
        >
            <span class="dku-large-title">{{dataCollectionInfo.displayName}}</span>
            <button
                class="btn btn-edit btn--icon btn--dku-icon"
                *ngIf="isAdmin && !isEditingDisplayName  && !isEditingDescription"
                matTooltip="Edit data collection name"
                (click)="editDisplayName()"
            >
                <i class="dku-icon-edit-16"></i>
            </button>
        </div>
        <div
            id="nameEditionContainer"
            class="data-collection-summary__name-edition-container"
            *ngIf="isEditingDisplayName"
        >
            <form (ngSubmit)="canSaveNewDisplayName && saveDisplayName(this.newDisplayName)">
                <div class="control-group display-name-input">
                    <input
                        class="in-place-edit"
                        type="text"
                        id="newDisplayName"
                        name="newDisplayName"
                        required
                        [(ngModel)]="newDisplayName"
                        (ngModelChange)="onDisplayNameChange($event)"
                        autofocus="true"
                    >
                </div>
                <div class="control-group">
                    <button
                        class="btn btn--text btn--primary"
                        type="submit"
                        (click)="saveDisplayName(this.newDisplayName)"
                        [disabled]="!canSaveNewDisplayName"
                    >
                        Save
                    </button>
                    <button
                        class="btn btn--text"
                        type="button"
                        (click)="cancelDisplayNameEdition()"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div
        id="descriptionContainer"
        class="data-collection-summary__description-container"
        [class.data-collection-summary__description-container--expanded]="isDescriptionExpanded"
        [class.data-collection-summary__description-container--can-expand]="canDescriptionExpand && !isDescriptionExpanded"
    >

        <div
            id="descriptionDisplayContainer"
            class="data-collection-summary__description-display-container"
            *ngIf="!isEditingDescription"
        >
            <markdown
                #description
                class="data-collection-summary__description"
                [from]="dataCollectionInfo.description"
            >
            </markdown>
            <button
                class="btn btn-edit btn--icon btn--dku-icon"
                *ngIf="isAdmin && !isEditingDescription && !isEditingDisplayName"
                matTooltip="Edit data collection description"
                (click)="editDescription()"
            >
                <i class="dku-icon-edit-16"></i>
            </button>
        </div>
        <div
            id="descriptionEditionContainer"
            class="data-collection-summary__description-edition-container"
            *ngIf="isEditingDescription"
        >
            <div class="control-group description-input">
                <autocompletable-textarea
                    [(model)]="newDescription"
                    (modelChange)="onDescriptionChange($event)"
                    options="{ autofocus: false }"
                >
                </autocompletable-textarea>
            </div>
            <div class="control-group">
                <button
                    class="btn btn--text btn--primary"
                    (click)="saveDescription(this.newDescription)"
                    [disabled]="!canSaveNewDescription"
                >
                    Save
                </button>
                <button
                    class="btn btn--text"
                    (click)="cancelDescriptionEdition()"
                >
                    Cancel
                </button>
            </div>
        </div>
    </div>
    <div
        *ngIf="canDescriptionExpand"
        class="data-collection-summary__description-expand"
    >
        <button
            class="btn btn--text btn--primary"
            (click)="isDescriptionExpanded = !isDescriptionExpanded"
        >
            {{ isDescriptionExpanded ? 'Show Less' : 'Show More' }}
        </button>
    </div>
    <div
        class="data-collection-page__collection-tags"
    >
        <!-- TODO @datacollections: should we make it manageable? -->
        <tag-edit-popover
            [tags]="dataCollectionInfo.tags"
            (tagsChange)="saveTags($event)"
            [manageLink]="false"
            [editable]="isAdmin"
            [getAllTags]="getAllTags"
            objectType="DATA_COLLECTION"
        ></tag-edit-popover>
    </div>
</div>