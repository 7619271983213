import { Directive, ElementRef, EventEmitter, Inject, Injector, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";
import { fairAny } from 'dku-frontend-core';

/**
 * A directive that allows to edit tags on an item.
 * Contrary to its angularjs counterpart, it doesn't work by side-effect & notification of changes by an event, but through classic Angular @Input/@Output behavior. Since the @Output only emits when the popover is closed, it has the (very minor) limitation that the tags list of the item won't be updated on the fly if shown somewhere else on the screen.
 * An other limitation compared to the angularjs directive: it is currently not allowed to omit the getAllTags attribute
 */

@Directive({
    selector: 'tag-edit-popover',
})
export class TagEditPopoverDirective extends UpgradeComponent implements OnChanges {
    @Input() tags: string[];
    @Output() tagsChange = new EventEmitter<string[]>();
    @Input() getAllTags: () => Promise<Map<string, object>>;
    @Input() objectType?: string;
    @Input() manageLink = true;
    @Input() noTagIcon = false;
    @Input() editable = true;
    @Input() responsive = false;

    constructor(
        @Inject('$rootScope') public $rootScope: fairAny,
        elementRef: ElementRef,
        injector: Injector
    ) {
        super('ng1TagEditPopover', elementRef, injector);
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Run AngularJS change detection
        this.$rootScope.$applyAsync();
        super.ngOnChanges(changes);
    }
}
