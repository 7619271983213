export namespace InterestsInternalDB {
    /**
     * Generated from com.dataiku.dip.dao.InterestsInternalDB$Interest
     */
    export interface Interest {
        nbStarred: number;
        nbWatching: number;
        starred: boolean;
        watching: InterestsInternalDB.Watching;
    }

    /**
     * Generated from com.dataiku.dip.dao.InterestsInternalDB$Watching
     */
    export enum Watching {
        INO = 'INO',
        ENO = 'ENO',
        SHALLOW = 'SHALLOW',
        YES = 'YES'
    }
}