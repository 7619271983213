import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { APIError, catchAPIError, ErrorContext } from "@core/dataiku-api/api-error";
import { DataikuAPIService } from "@core/dataiku-api/dataiku-api.service";
import { WaitingService } from "@core/overlays/waiting.service";
import { ExperimentTrackingService, RunViewModel } from "@features/experiment-tracking/experiment-tracking.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
    selector: '[experiment-tracking-run-artifacts]',
    templateUrl: './experiment-tracking-run-artifacts.component.html',
    styleUrls: ['./experiment-tracking-run-artifacts.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperimentTrackingRunArtifactsComponent implements OnChanges, ErrorContext {

    @Input() run: RunViewModel;

    projectKey: string;
    runId: string;
    odb$: Observable<any>;
    subFolderStartingPoint: string;
    initialSubFolder: string;

    apiError?: APIError;
    initError?: string;

    constructor(
        private DataikuAPI: DataikuAPIService,
        private experimentTrackingService: ExperimentTrackingService,
        private cd: ChangeDetectorRef,
        private waitingService: WaitingService,
    ) {
    }

    pushError(error: APIError): void {
        this.apiError = error;
    }

    setInitError(message: string): void {
        this.initError = message;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.run) {
            const contextProjectKey = this.experimentTrackingService.projectKey;
            const [loc, runSubfolderStartingPoint] = this.experimentTrackingService.resolveRunArtifactUri(this.run);
            this.subFolderStartingPoint = runSubfolderStartingPoint;
            this.initialSubFolder =  this.experimentTrackingService.subfolder;

            this.odb$ = this.DataikuAPI.managedFolders.getFullInfo(contextProjectKey, loc.projectKey, loc.id).pipe(
                this.waitingService.bindSpinner(),
                map(odb => odb.folder),
                catchAPIError(this, false, this.cd)
            );

            this.cd.markForCheck();
        }
    }
}
