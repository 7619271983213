import { Injectable } from '@angular/core';
import { ChartStaticDataService } from '.';

@Injectable({
    providedIn: 'root'
})
/**
 * Set of helpers for chart unaggregated dimension manipulation.
 * (!) This service previously was in:
 * - static/dataiku/js/simple_report/services/core/chart-ua-dimension.service.js
 * - static/dataiku/js/simple_report/charts_core.js
 */
export class ChartUADimensionService {

    constructor(private readonly chartStaticDataService: ChartStaticDataService) {}

    isTrueNumerical(dimension: any) {
        if (!dimension) {
            return;
        }
        return dimension.type == 'NUMERICAL' && !dimension.treatAsAlphanum;
    }

    isAlphanumLike(dimension: any) {
        if (!dimension) {
            return;
        }
        return dimension.type == 'ALPHANUM' || (dimension.type == 'NUMERICAL' && dimension.treatAsAlphanum);
    }

    isDiscreteDate(dimension: any) {
        if (!dimension) {
            return;
        }
        return dimension.type == 'DATE' && dimension.dateMode != 'RANGE';
    }

    isDateRange(dimension: any) {
        if (!dimension) {
            return;
        }
        return dimension.type == 'DATE' && dimension.dateMode == 'RANGE';
    }

    isDate(dimension: any) {
        if (!dimension) {
            return;
        }
        return dimension.type == 'DATE';
    }

    getDateModes() {
        return [
            this.chartStaticDataService.DEFAULT_DATE_RANGE_FILTER_TYPE,
            this.chartStaticDataService.YEAR,
            this.chartStaticDataService.QUARTER_OF_YEAR,
            this.chartStaticDataService.MONTH_OF_YEAR,
            this.chartStaticDataService.WEEK_OF_YEAR,
            this.chartStaticDataService.DAY_OF_MONTH,
            this.chartStaticDataService.DAY_OF_WEEK,
            this.chartStaticDataService.HOUR_OF_DAY
        ];
    }
}
