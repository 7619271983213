import type {CardResult, _CardResult} from './card-result';
import type {Card} from './card';
import type {Curve} from './../../compute/curves/curve';
import type {FitCurve} from './../../compute/computations/bivariate/fit-curve';
import type {_BivariateCard} from './bivariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.FitCurveCard
 */
export interface FitCurveCard extends _BivariateCard {
    curves: Curve[];

    // PolyJson type
    type: 'fit_curve';
}

export namespace FitCurveCard {
    export const type = 'fit_curve';
}

/** @deprecated Use Card.isFitCurveCard() */ export function isFitCurveCard(toTest: Card): toTest is FitCurveCard { return FitCurveCard.type === toTest['type']; }

export namespace FitCurveCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.FitCurveCard$FitCurveCardResult
     */
    export interface FitCurveCardResult extends _CardResult {
        parametrizedCurves: FitCurve.FitCurveResult[];
        xSeries: number[];
        ySeries: number[];

        // PolyJson type
        type: 'fit_curve';
    }

    export namespace FitCurveCardResult {
        export const type = 'fit_curve';
    }

    /** @deprecated Use CardResult.isFitCurveCardResult() */ export function isFitCurveCardResult(toTest: CardResult): toTest is FitCurveCardResult { return FitCurveCardResult.type === toTest['type']; }
}