<div class="data-collection-add-object">
    <modal-header [title]="modalTitle" [hasBorder]="true" icon="dku-icon-stacked-24"></modal-header>
    <api-error-alert [apiError]="errorContext.error$ | async" (resetError)="errorContext.resetError()"
        [closable]="true"></api-error-alert>
    <form class="dkuform-modal-wrapper dkuform-modal-horizontal" [formGroup]="addObjectsForm">
        <div class="modal-body">
            <div class="control-group mleft16 mright16">
                <label class="control-label">Project</label>
                <div class="controls">
                    <dss-project-selector class="data-collection-selector" [projects]="projects$ | async"
                        formControlName="projectKey" id="projectKey" required>
                    </dss-project-selector>
                </div>
            </div>
            <div class="control-group mleft16 mright16">
                <label class="control-label" for="objectPicker">{{ addObjectsForm.type.value | niceConst }}</label>
                <div class="controls">
                    <dss-accessible-objects-selector [objects]="objects" [type]="addObjectsForm.type.value"
                        formControlName="objects" [multi]="false">
                    </dss-accessible-objects-selector>
                </div>
            </div>
            <div class="control-group mleft16 mright16"
                *ngIf="suggestionState.unset === false && suggestionState.objectAuthorizations.enabled">
                <label class="control-label horizontal-flex aic">
                    <span>Object authorization</span>
                    <i class="dku-icon-question-circle-fill-16 cursor-pointer" 
                        data-placement="bottom"
                        [dkuMdPopover]="'\<p>This object is currently not fully readable for users who don\'t have access to this project.\</p>'"
                        dkuMdTitle="More info about object authorization" style="margin-left: 5px;">
                    </i>
                </label>
                <div class="controls">
                    <dku-bs-select-form-control [model]="objectAuthorization" formControlName="authorization"
                        [ngOptions]="'authorization.value as authorization.label disable when authorization.disabled for authorization in list'"
                        [list]="objectAuthorizationOptions" [layout]="'list'"
                        [optionsDescriptions]="objectAuthorizationDescriptions">
                    </dku-bs-select-form-control>
                </div>
            </div>

            <div class="control-group mleft16 mright16"
                *ngIf="suggestionState.unset === false && suggestionState.quickSharing.enabled">
                <label class="control-label horizontal-flex aic">
                    <span>Enable Quick sharing</span>
                    <i class="dku-icon-question-circle-fill-16 cursor-pointer" 
                        dkuMdPopover="<p>Quick sharing is currently not enabled on this dataset.<br />Enabling it will allow users who have read access to the dataset<br />to use it in other projects without requiring a request.</p>" 
                        dkuMdTitle="More info about Quick sharing"
                        data-placement="bottom"
                        style="margin-left: 5px;">
                    </i>
                </label>
                <div class="controls control-checkbox">
                    <!--Angular forms set the disabled attribute on the checkbox, so impossible to fiddle with [class.disabled]
                        to add a tooltip to the disabled checkbox, as it will be overruled.
                        Putting the tooltip on a wrapper div then -->
                     <span class="checkbox-wrapper" [matTooltip]="suggestionState.quickSharing.canEnableQuickSharing ? '' : 'You don\'t have the permission to change this option.'">
                         <input type="checkbox" formControlName="quickSharingEnabled" />
                     </span>
                </div>
            </div>
        </div>
    </form>
    <div class="modal-footer modal-footer-std-buttons">
        <div class="pull-right">
            <button type="button" class="btn btn--text btn--secondary" (click)="cancel()">Cancel</button>
            <button type="submit" class="btn btn--text btn--primary" (click)="confirm()" [disabled]="isValid === false"
                autofocus>
                Add
            </button>
        </div>
    </div>
</div>