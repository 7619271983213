import type {GroupProfile} from './auth/group-profile';
import type {UserRemappingRule} from './auth/user-remapping-rule';

/**
 * Generated from com.dataiku.dip.security.SSOSettings
 */
export interface SSOSettings {
    authorizedGroups: string;
    autoProvisionUsersAtLoginTime: boolean;
    autoSyncUsersAtLoginTime: boolean;
    defaultUserProfile: string;
    enabled: boolean;
    groupProfiles: GroupProfile[];
    openIDParams: SSOSettings.OpenIDParams;
    order?: number | null;
    protocol: SSOSettings.SSOProtocol;
    remappingRules: UserRemappingRule[];
    samlIDPMetadata: string;
    samlLoginAttribute: string;
    samlSPParams: SSOSettings.SAMLSPParams;
    spnegoKrb5File: string;
    spnegoLoginConfFile: string;
    spnegoMode: SSOSettings.SPNEGOMode;
    spnegoPreauthKeytab: string;
    spnegoPreauthLogin: string;
    spnegoPreauthPassword: string;
    spnegoPreauthPrincipal: string;
    spnegoStripRealm: boolean;
}

export namespace SSOSettings {
    /**
     * Generated from com.dataiku.dip.security.SSOSettings$SAMLSPParams
     */
    export interface SAMLSPParams {
        acsURL: string;
        displayNameAttribute: string;
        emailAttribute: string;
        enableGroups: boolean;
        entityId: string;
        groupsAttribute: string;
        keyAlias: string;
        keystoreFile: string;
        keystorePassword: string;
        signRequests: boolean;
    }

    /**
     * Generated from com.dataiku.dip.security.SSOSettings$OpenIDTokenEndpointAuthMethod
     */
    export enum OpenIDTokenEndpointAuthMethod {
        CLIENT_SECRET_BASIC = 'CLIENT_SECRET_BASIC',
        CLIENT_SECRET_POST = 'CLIENT_SECRET_POST'
    }

    /**
     * Generated from com.dataiku.dip.security.SSOSettings$OpenIDParams
     */
    export interface OpenIDParams {
        authorizationEndpoint: string;
        claimKeyDisplayName: string;
        claimKeyEmail: string;
        claimKeyGroups: string;
        claimKeyIdentifier: string;
        clientId: string;
        clientSecret: string;
        enableGroups: boolean;
        issuer: string;
        jwksUri: string;
        scope: string;
        tokenEndpoint: string;
        tokenEndpointAuthMethod: SSOSettings.OpenIDTokenEndpointAuthMethod;
        useGlobalProxy: boolean;
        usePKCE: boolean;
    }

    /**
     * Generated from com.dataiku.dip.security.SSOSettings$SPNEGOMode
     */
    export enum SPNEGOMode {
        PREAUTH_KEYTAB = 'PREAUTH_KEYTAB',
        CUSTOM_LOGIN_CONF = 'CUSTOM_LOGIN_CONF'
    }

    /**
     * Generated from com.dataiku.dip.security.SSOSettings$SSOProtocol
     */
    export enum SSOProtocol {
        SAML = 'SAML',
        SPNEGO = 'SPNEGO',
        OPENID = 'OPENID'
    }
}