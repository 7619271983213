/**
 * Generated from com.dataiku.dip.dashboards.model.Tile
 */
export interface Tile {
    autoLoad: boolean;
    borderColor?: string | null;
    box: Tile.Box;
    clickAction: Tile.TileClickAction;
    displayMode?: Tile.TileDisplayMode | null;
    imageId?: string | null;
    insightId?: string | null;
    insightType?: string | null;
    resizeImageMode?: Tile.TileResizeImageMode | null;
    showTitle: Tile.DisplayTitleMode;
    targetInsightId?: string | null;
    tileParams: any /* TODO: Missing translation of com.dataiku.dip.dashboards.model.TileParams */;
    tileType: Tile.TileType;
    title?: string | null;
}

export namespace Tile {
    /**
     * Generated from com.dataiku.dip.dashboards.model.Tile$TileType
     */
    export enum TileType {
        INSIGHT = 'INSIGHT',
        TEXT = 'TEXT',
        IMAGE = 'IMAGE',
        IFRAME = 'IFRAME'
    }

    /**
     * Generated from com.dataiku.dip.dashboards.model.Tile$TileResizeImageMode
     */
    export enum TileResizeImageMode {
        ORIG_SIZE = 'ORIG_SIZE',
        FIT_SIZE = 'FIT_SIZE',
        STRETCH_SIZE = 'STRETCH_SIZE',
        FIT_CROP_SIZE = 'FIT_CROP_SIZE'
    }

    /**
     * Generated from com.dataiku.dip.dashboards.model.Tile$TileClickAction
     */
    export enum TileClickAction {
        DO_NOTHING = 'DO_NOTHING',
        OPEN_INSIGHT = 'OPEN_INSIGHT',
        OPEN_OTHER_INSIGHT = 'OPEN_OTHER_INSIGHT'
    }

    /**
     * Generated from com.dataiku.dip.dashboards.model.Tile$DisplayTitleMode
     */
    export enum DisplayTitleMode {
        YES = 'YES',
        NO = 'NO',
        MOUSEOVER = 'MOUSEOVER'
    }

    /**
     * Generated from com.dataiku.dip.dashboards.model.Tile$Box
     */
    export interface Box {
        height: number;
        left: number;
        top: number;
        width: number;
    }

    /**
     * Generated from com.dataiku.dip.dashboards.model.Tile$TileDisplayMode
     */
    export enum TileDisplayMode {
        INSIGHT = 'INSIGHT',
        INSIGHT_DESC = 'INSIGHT_DESC',
        IMAGE_AND_INSIGHT_DESC = 'IMAGE_AND_INSIGHT_DESC',
        IMAGE = 'IMAGE'
    }
}