<div class="content-grid-container">
    <div class="content-grid">
        <ng-container *ngFor="let item of content | keyvalue">
            <span class="content-grid__cell font-weight-semi-bold" [matTooltip]="item.key" isEllipsisActive>
                {{ item.key }}
            </span>
            <span class="content-grid__cell" [matTooltip]="item.value" isEllipsisActive>
                {{ item.value }}
            </span>
        </ng-container>
    </div>
</div>