import { Component, ChangeDetectionStrategy, Input, OnChanges, Inject, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { catchAPIError } from '@core/dataiku-api/api-error';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { Column, DataSourceExternalTable, Highlight, RelatedProjects } from '@shared/models';
import { ErrorContextService } from '@shared/services/error-context.service';
import { fairAny } from 'dku-frontend-core';
import { AnyLoc } from 'generated-sources';

@Component({
    selector: 'data-catalog-page-right-panel-external-table',
    templateUrl: './data-catalog-page-right-panel-external-table.component.html',
    styleUrls: ['./data-catalog-page-right-panel-external-table.component.less', '../../../../data-collections/data-collection-page/data-collection-page-right-panel/data-collection-page-right-panel-commons.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataCatalogPageRightPanelExternalTableComponent implements OnChanges{

    @Input() readonly details!: DataSourceExternalTable;
    @Input() readonly highlights?: Highlight;
    @Input() readonly selectedTab: string;
    @Output() preview = new EventEmitter<void>();
    @Output() import = new EventEmitter<void>();

    columns: Column[] = [];
    relatedProjects: RelatedProjects[] = [];
    relatedDatasets: AnyLoc[] = [];
    metadata : {description: string, tags: string[]} =  {description: "", tags: []};
    columnsHighlighting: string[] = [];

    constructor(
        @Inject('StateUtils') public StateUtils: fairAny,
        protected dataikuApiService: DataikuAPIService,
        private errorContext: ErrorContextService,
        private cd: ChangeDetectorRef,
    ) {}

    isHiveTable() {
        return this.details.connection.startsWith('@virtual(hive-jdbc):');
    }

    ngOnChanges() {
        this.columnsHighlighting = [
            ...(this.highlights?.["column.plaintext"] || []),
            ...(this.highlights?.column || []),
        ];
        this.columns = this.details.columns
        .map(col => {
            return {
                name: col.name,
                type: col.type,
                comment: col.remarks,
                meaning: null
            };
        });

        this.relatedProjects = [];
        this.relatedDatasets = [];
        if (this.details.rawRelatedItems) {
            this.relatedProjects = this.details.rawRelatedItems.projects;
            this.relatedProjects.forEach(p => {
                p.datasets.forEach(d => {
                    this.relatedDatasets.push({id: d.name, projectKey: p.key});
                });
            });
        }
        this.getExternalTableMetadata();
    }

    getExternalTableMetadata() { // the indexed DSS metadata might not be up to date so we need to fetch it
        this.dataikuApiService.catalog.getExternalTableMetadata(
            this.details.connection,
            this.details.catalog ?? undefined,
            this.details.schema,
            this.details.name
        ).pipe(
            catchAPIError(this.errorContext)
        ).subscribe(metadata => {
            this.metadata = metadata;
            this.cd.detectChanges();
        });
    }
}
