import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BivariateBoxPlotCard, Variable } from 'src/generated-sources';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';


@UntilDestroy()
@Component({
    selector: 'bivariate-box-plot-card-config',
    templateUrl: './bivariate-box-plot-card-config.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BivariateBoxPlotCardConfigComponent implements OnChanges, OnDestroy {
    @Input() params: BivariateBoxPlotCard;
    @Output() paramsChange = new EventEmitter<BivariateBoxPlotCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    configForm = this.fb.group({
        xBinningConfig: this.fb.control(null, [Validators.required])
    });

    constructor(private fb: FormBuilder) {
        this.configForm.statusChanges.pipe(untilDestroyed(this))
            .subscribe(() => this.validityChange.emit(this.configForm.valid));
        this.configForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(formValue => {
                this.paramsChange.emit({
                    ...this.params,
                    maxValues: formValue.xBinningConfig.maxValues,
                    groupOthers: formValue.xBinningConfig.groupWithOthers,
                    binningMode: formValue.xBinningConfig.binningMode,
                    customBinningBoundaries: formValue.xBinningConfig.customBinningBoundaries
                });
            });
    }

    get atLeastOneCategoricalVariable() {
        return this.params.xColumn.type === Variable.Type.CATEGORICAL
            || this.params.yColumn.type === Variable.Type.CATEGORICAL;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.configForm.patchValue({
                xBinningConfig: {
                    maxValues: this.params.maxValues,
                    groupWithOthers: this.params.groupOthers,
                    customBinningBoundaries: this.params.customBinningBoundaries,
                    binningMode: this.params.binningMode
                }
            });
        }
    }

    ngOnDestroy() {
    }
}
