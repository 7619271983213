import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { OneWayANOVACard } from 'src/generated-sources';
import { CardAction, CardActionType } from '../../events';
import { ModalsService, ModalShape } from '@shared/modals/modals.service';
import { EditCardModalComponent } from '@features/eda/worksheet/card-wizard/edit-card-modal/edit-card-modal.component';
import { createVarianceComparisonCard, createShapiroCard} from '../card-creation-templates';

@Component({
    selector: 'one-way-anova-card-help',
    templateUrl: './one-way-anova-card-help.component.html',
    styleUrls: ['./one-way-anova-card-help.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OneWayANOVACardHelpComponent {
    @Input() params: OneWayANOVACard;
    @Input() readOnly: boolean;
    @Input() extendedActions: boolean;
    @Output() action = new EventEmitter<CardAction>();

    constructor(private modalsService: ModalsService, private viewContainerRef: ViewContainerRef) { }

    get canAddCard() {
        return !this.readOnly && this.extendedActions;
    }

    addGroupedShapiro() {
        if (!this.canAddCard) {
            return;
        }

        const proposedCard = createShapiroCard(this.params.xColumn, this.params.yColumn);
        this.modalsService.open(EditCardModalComponent, { params: proposedCard }, ModalShape.NONE,
            this.viewContainerRef)
            .then(({ card }) => {
                this.action.emit({
                    type: CardActionType.ADD,
                    card
                });
            }, () => { });
    }

    addGroupedSummary() {
        if (!this.canAddCard) {
            return;
        }

        const proposedCard = createVarianceComparisonCard(this.params.xColumn, this.params.yColumn);
        this.modalsService.open(EditCardModalComponent, { params: proposedCard }, ModalShape.NONE,
            this.viewContainerRef)
            .then(({ card }) => {
                this.action.emit({
                    type: CardActionType.ADD,
                    card
                });
            }, () => { });
    }
}
