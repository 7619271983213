import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Variable, SplitBySpec } from 'src/generated-sources';

@Component({
    selector: 'column-title',
    templateUrl: './column-title.component.html',
    styleUrls: ['./column-title.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnTitleComponent {
    @Input() column: Variable;
    @Input() response?: Variable;
    @Input() collapsed: boolean;
    @Input() splitBy?: SplitBySpec | null;
    @Input() showCollapsingControls: boolean;
    Variable = Variable;
}
