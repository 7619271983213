import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy, ViewContainerRef, ChangeDetectorRef, Inject } from '@angular/core';
import { Card, Variable, isCorrelationMatrixCard, CorrelationMatrixCard, isPCACard, PCACard, ColumnCard, isColumnCard, isNumericalHistogramCard, isCDFPlotCard, isPlaygroundCard } from 'src/generated-sources';
import { ModalsService, ModalShape } from '@shared/modals/modals.service';
import { CardActionType, CardAction } from '@features/eda/worksheet/cards/events';
import { DeleteDialogComponent } from '../../delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { HeatmapConfigComponent } from '../config/heatmap-config/heatmap-config.component';
import _ from 'lodash';
import { EditCardModalComponent, EditCardModalComponentInput } from '../../card-wizard/edit-card-modal/edit-card-modal.component';
import produce from 'immer';
import { getBasicCardTitle } from '@features/eda/card-utils';
import { DKURootScope } from '@shared/models/dku-root-scope.model';


@Component({
    selector: 'card-menu',
    templateUrl: './card-menu.component.html',
    styleUrls: [
        '../../../shared-styles/menu-button.less',
        './card-menu.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardMenuComponent {
    @Input() params: Card;
    @Input() columnParams?: ColumnCard; // only if the card is a child of a ColumnCard
    @Input() readOnly: boolean;
    @Input() extendedActions: boolean;
    @Input() deletable: boolean;
    @Output() action = new EventEmitter<CardAction>();

    projectKey: string;
    datasetName: string;
    menuActive = false;
    private isRecipeExportEnabled = false;

    Variable = Variable;

    constructor(
        private modalsService: ModalsService,
        private dialog: MatDialog,
        private viewContainerRef: ViewContainerRef,
        private changeDetectorRef: ChangeDetectorRef,
        @Inject('$rootScope') $rootScope: DKURootScope,
    ) {
        this.isRecipeExportEnabled = $rootScope.appConfig.featureFlags.includes("edaRecipeExport");
    }

    get hasConfigureCardModal(): boolean {
        if (isColumnCard(this.params)) {
            // "Treat as" actions are menu items (there is no modal)
            return false;
        }
        if (isNumericalHistogramCard(this.params)) {
            // Box plot is not configurable
            return !!this.params.showHistogram;
        }
        if (isCDFPlotCard(this.params)) {
            return false;
        }
        if(isPlaygroundCard(this.params)) {
            return false;
        }
        return true;
    }

    get hasConfigureVisualizationModal(): boolean {
        return isCorrelationMatrixCard(this.params) || isPCACard(this.params);
    }

    get removalText(): string {
        return isColumnCard(this.params) ? 'Remove' : 'Delete';
    }

    get showMenu() {
        return !this.readOnly && this.params && !(this.params.type === 'column_card' && !this.extendedActions)
    }

    get showExtendedMenu() {
        return this.hasConfigureVisualizationModal || this.extendedActions;
    }

    handleDelete() {
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            data: {
                title: isColumnCard(this.params) ? this.params.column.name : getBasicCardTitle(this.params),
                type: this.params.type
            },
            restoreFocus: false,
            panelClass: ['modal', 'modal3', 'dku-modal-panel-narrow']
        });

        dialogRef.afterClosed().subscribe((deleteCard: boolean) => {
            if (deleteCard) {
                this.action.emit({ type: CardActionType.DELETE });
            }
        });
    }

    configureCard() {
        this.menuActive = true;

        this.modalsService.open(EditCardModalComponent,
            {
                params: this.params,
                columnParams: this.columnParams
            } as EditCardModalComponentInput,
            ModalShape.NONE,
            this.viewContainerRef,
            { restoreFocus: false }
        ).then(({ card, updateSiblings }) => {
            this.menuActive = false;
            this.updateCard(card, updateSiblings);
        }, () => {
            this.menuActive = false;
            this.changeDetectorRef.markForCheck();
        });
    }

    configureVisualization() {
        if (isCorrelationMatrixCard(this.params) || isPCACard(this.params)) {
            this.modalsService.open(HeatmapConfigComponent, { params: this.params.heatmapParams },
                ModalShape.NARROW, this.viewContainerRef)
                .then((heatmapParams) => {
                    // these second typeguards are imposed by TS.
                    if (isCorrelationMatrixCard(this.params) || isPCACard(this.params)) {
                        let card: CorrelationMatrixCard | PCACard = _.cloneDeep(this.params);
                        card.heatmapParams = heatmapParams;
                        this.updateCard(card, false);
                    }
                }, () => { });
        }

    }

    updateCard(card: Card, updateSiblings: boolean) {
        this.action.emit({ type: CardActionType.UPDATE, newParams: card, updateSiblings });
    }

    addCard() {
        this.action.emit({ type: CardActionType.ADD, card: this.params });
    }

    publish() {
        this.action.emit({ type: CardActionType.PUBLISH, card: this.params });
    }

    get canExportAsRecipe(): boolean {
        return this.isRecipeExportEnabled && (
            Card.isPCACard(this.params) ||
            Card.isTZTest1SampCard(this.params)
        );
    }

    exportAsRecipe() {
        this.action.emit({
            type: CardActionType.EXPORT_AS_RECIPE,
            card: this.params
        });
    }

    treatAs(type: Variable.Type) {
        if (this.params.type === 'column_card') {
            const newCard = produce(this.params, draft => {
                draft.column.type = type;
            });
            this.updateCard(newCard, false);
        }
    }

    debugCard() {
        this.action.emit({ type: CardActionType.DEBUG, card: this.params })
    }
}
