import type {DataCollectionDataset} from './data-collection-dataset';

/**
 * Generated from com.dataiku.dip.datacollections.AbstractDataCollectionItem
 */
export interface _AbstractDataCollectionItem {}

export type AbstractDataCollectionItem = DataCollectionDataset;

export namespace AbstractDataCollectionItem {
    export const type = ['dataset'] as const;
    export function isDataCollectionDataset(toTest: AbstractDataCollectionItem): toTest is DataCollectionDataset { return toTest['type'] === 'dataset'; }
}