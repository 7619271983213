import type {JoinInputDescBase} from './../joinlike/join-input-desc-base';
import type {RelationshipType} from './relationship-type';
import type {TimeWindow} from './time-window';
import type {VariableType} from './variable-type';
import type {_JoinRecipePayloadParams} from './../join/join-recipe-payload-params';
import type {_VirtualInputBasedRecipePayloadParams} from './../../common/virtual-input-based-recipe-payload-params';

/**
 * Generated from com.dataiku.dip.dataflow.exec.autofeaturegeneration.AutoFeatureGenerationRecipePayloadParams
 */
export interface AutoFeatureGenerationRecipePayloadParams extends _VirtualInputBasedRecipePayloadParams {
    cutoffTime: AutoFeatureGenerationRecipePayloadParams.CutoffTime;
    features: AutoFeatureGenerationRecipePayloadParams.Feature[];
    relationships: AutoFeatureGenerationRecipePayloadParams.RelationshipDesc[];
}

export namespace AutoFeatureGenerationRecipePayloadParams.CutoffTime {
    /**
     * Generated from com.dataiku.dip.dataflow.exec.autofeaturegeneration.AutoFeatureGenerationRecipePayloadParams$CutoffTime$CutoffTimeMode
     */
    export enum CutoffTimeMode {
        NONE = 'NONE',
        COLUMN = 'COLUMN'
    }
}

export namespace AutoFeatureGenerationRecipePayloadParams {
    /**
     * Generated from com.dataiku.dip.dataflow.exec.autofeaturegeneration.AutoFeatureGenerationRecipePayloadParams$Feature
     */
    export enum Feature {
        DAY_OF_MONTH = 'DAY_OF_MONTH',
        MONTH_OF_YEAR = 'MONTH_OF_YEAR',
        YEAR = 'YEAR',
        DAY_OF_WEEK = 'DAY_OF_WEEK',
        HOUR_OF_DAY = 'HOUR_OF_DAY',
        WEEK_OF_YEAR = 'WEEK_OF_YEAR',
        CHARACTER_COUNT = 'CHARACTER_COUNT',
        WORD_COUNT = 'WORD_COUNT',
        MIN = 'MIN',
        MAX = 'MAX',
        COUNT = 'COUNT',
        DISTINCT = 'DISTINCT',
        SUM = 'SUM',
        AVG = 'AVG'
    }

    /**
     * Generated from com.dataiku.dip.dataflow.exec.autofeaturegeneration.AutoFeatureGenerationRecipePayloadParams$CutoffTime
     */
    export interface CutoffTime {
        mode: AutoFeatureGenerationRecipePayloadParams.CutoffTime.CutoffTimeMode;
    }

    /**
     * Generated from com.dataiku.dip.dataflow.exec.autofeaturegeneration.AutoFeatureGenerationRecipePayloadParams$Column
     */
    export interface Column {
        name: string;
        variableType: VariableType;
    }

    /**
     * Generated from com.dataiku.dip.dataflow.exec.autofeaturegeneration.AutoFeatureGenerationRecipePayloadParams$RelationshipDesc
     */
    export interface RelationshipDesc {
        on: _JoinRecipePayloadParams.MatchingCondition[];
        table1: number;
        table2: number;
        type?: RelationshipType | null;
    }

    /**
     * Generated from com.dataiku.dip.dataflow.exec.autofeaturegeneration.AutoFeatureGenerationRecipePayloadParams$InputDesc
     */
    export interface InputDesc extends JoinInputDescBase {
        selectedColumns: AutoFeatureGenerationRecipePayloadParams.Column[];
        timeIndexColumn: string;
        timeWindows: TimeWindow[];
    }
}