import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sliceList'
})
export class SliceListPipe implements PipeTransform {

    transform<T>(list: T[], from: number, to: number): T[] {
        if (!list || list.length <= from) {
            return [];
        }
        return list.slice(from, to);
    }

}
