<form [formGroup]="configForm" id="config-form" class="horizontal-flex">
    <div class="card-editor__column">
        <div class="control-group">
            <label class="control-label">
                Variable 1
            </label>
            <variable-selector
                [variables]="allVariables$ | async"
                formControlName="xColumn"
                [qa]="'categorical-test-variable1'"
            ></variable-selector>
        </div>
        <div class="control-group">
            <label class="control-label">
                Variable 2
            </label>
            <variable-selector
                [variables]="allVariables$ | async"
                formControlName="yColumn"
                [qa]="'categorical-test-variable2'"
            ></variable-selector>
        </div>
    </div>
    <div class="card-options card-editor__column">
        <div class="control-group">
            <label class="control-label">
                Maximum X Values to Display
            </label>
            <input type="number" class="card-option__input" formControlName="maxValuesX"
                [qa]="'categorical-test-variable1-max-values'">
        </div>
        <div class="control-group">
            <label class="control-label">
                Maximum Y Values to Display
            </label>
            <input type="number" class="card-option__input" formControlName="maxValuesY"
            [qa]="'categorical-test-variable2-max-values'">
        </div>
    </div>
</form>
