
<h1>Permissions</h1>
<div class="section">
    <div class="alert alert-info">
        Users with write project content permission can annotate data and review annotations.
    </div>
    <form [formGroup]="form">
        <table class="table table-striped table-hover labeling-permissions__table">
            <thead>
                <tr>
                    <th width="40%">Name</th>
                    <th width="50%" class="labeling-permissions__right_col">Rights</th>
                    <th width="10%">&nbsp;</th>
                </tr>
            </thead>
            <tbody formArrayName="permissions">
                <tr *ngIf="permissions.controls.length === 0">
                    <td colspan="14" class="labeling-permissions__empty-state">
                        No group is granted access to this labeling task
                    </td>
                </tr>
                <tr *ngFor="let perm of permissions.controls; index as i" [formGroupName]="i">
                    <td *ngIf="perm.get('group')?.value as group">{{group}} </td>
                    <td *ngIf="perm.get('user')?.value as user" title="{{user.login}}">
                        <user-picture class="mright4" [user]="user.login" [size]="24"></user-picture>
                        {{user.displayName}}
                    </td>
                    <td class="labeling-permissions__right_col">
                        <dku-bs-select-form-control
                            formControlName="right"
                            ngOptions="permission.key as permission.desc for permission in list"
                            [list]="PERMISSION_CHOICES"
                            [layout]="'list'">
                        </dku-bs-select-form-control>
                    </td>
                    <td>
                        <a  class="link-danger" (click)="removePermission(i)"> <i class="icon-trash"></i></a>
                    </td>
                </tr>
            </tbody>
        </table>
    </form>
    <form [formGroup]="newPermissionForm">
        <span class="labeling-permissions__new-group-select" *ngIf="unassignedGroups$ | async as unassignedGroups">
            <dku-bs-select-form-control
                formControlName="newGroup"
                ngOptions="grp for grp in list"
                [list]="unassignedGroups"
                [dataLiveSearch]="true"
                [layout]="'list'"
                [params]="{
                    noneSelectedText: 'Select a group...'
                }">
            </dku-bs-select-form-control>
            <button class="btn btn--secondary mleft8" [disabled]="!newPermissionForm.get('newGroup')?.value" (click)="addGroupPermissionToForm()">
                <span plus-icon></span>
                &nbsp;Grant access to group
            </button>
        </span>
        <span>
            <dku-bs-select-form-control
                formControlName="newUser"
                ngOptions="user as user.displayName for user in list"
                [list]="(unassignedUsers$ | async) ?? []"
                [dataLiveSearch]="true"
                [layout]="'list'"
                [params]="{
                    noneSelectedText: 'Select a user...'
                }">
            </dku-bs-select-form-control>
            <button class="btn btn--secondary mleft8" [disabled]="!newPermissionForm.get('newUser')?.value" (click)="addUserPermissionToForm()">
                <span plus-icon ></span>
                &nbsp;Grant access to user
            </button>
        </span>
    </form>
</div>
