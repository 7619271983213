<ng-container *ngIf="labelingService.labelingTaskInfo$ | async as task">
    <ng-container *ngIf="(labelingReviewAnnotationGroupService.annotationGroupList$ | async) as annotationGroups">
        <div *ngIf="annotationGroups.length" class="annotations-wrapper">
            <ng-container *ngFor="let annotationGroup of annotationGroups; index as index">
                <div (click)="annotationGroup.selectable && selectAnnotationGroup(index)" [id]="ANNOTATION_GRP_ID_PREFIX+index">
                    <div class="labeling-annotation" [ngClass]="{ 'selected': annotationGroup.selected, 'selectable': annotationGroup.selectable }">
                        <i class="text-warning labeling-annotation__header-icon icon-dku-label-conflict mright8" *ngIf="annotationGroup.hasConflict()"></i>
                        <i class="text-success labeling-annotation__header-icon icon-dku-label-validate mright8" *ngIf="!annotationGroup.hasConflict() && !annotationGroup.hasMissingCategory()"></i>
                        <i class="text-weak labeling-annotation__header-icon icon-exclamation-sign mright8" *ngIf="annotationGroup.hasMissingCategory()"></i>
                        <div class="vertical-flex minw0">
                            <span class="name">
                                <ng-container [ngSwitch]="task.type">
                                    <ng-container *ngSwitchCase="LabelingTaskType.OBJECT_DETECTION">
                                        Region {{index + 1}}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="LabelingTaskType.IMAGE_CLASSIFICATION">
                                        Classification
                                    </ng-container>
                                    <ng-container *ngSwitchCase="LabelingTaskType.NAMED_ENTITY_EXTRACTION">
                                        <named-entity-thumb [text]=" (annotationGroup | as: NamedEntityGroup).text" [maxWordLength]="12"></named-entity-thumb>
                                    </ng-container>
                                </ng-container>
                            </span>
                        <span *ngIf="!annotationGroup.hasConflict() && !annotationGroup.hasMissingCategory()" [matTooltip]="annotationGroup.getConsensusCategory() || ''" isEllipsisActive class="minw0 mx-textellipsis">{{annotationGroup.getConsensusCategory()}}</span>
                            <span *ngIf="annotationGroup.hasConflict()" class="category--conflict text-warning">
                                <span>Conflict</span>
                            </span>
                            <span *ngIf="annotationGroup.hasMissingCategory()" class="category--conflict text-weak">
                                <span>Missing {{ task.type | categoryLabel }}</span>
                            </span>
                        </div>
                        <i class="dku-icon-trash-16 labeling-annotation__header-icon cursor-pointer mleft16" (click)="deleteAnnotationGroup(task.type, index); $event.stopPropagation();" [matTooltip]="getDeleteTooltip(task.type)" [matTooltipPosition]="'above'"></i>
                        <i *ngIf="annotationGroup.selectable" [ngClass]="{'dku-icon-chevron-down-16': annotationGroup.selected, 'dku-icon-chevron-right-16': !annotationGroup.selected}" class="carret"></i>
                    </div>
                    <div *ngIf="annotationGroup.selected">
                        <div *ngFor="let reason of annotationGroup.conflictReasons()" class="conflict-reason">
                            <i class="conflict-icon icon-warning-sign text-warning" ></i>
                            <div class="mleft8 text-warning"> {{reason}} </div>
                        </div>
                    </div>
                    <annotation-list *ngIf="annotationGroup.selected && annotationGroup.annotations" (click)="$event.stopPropagation()" [conflicting]="annotationGroup.hasConflict()" [updating]="false" [multiAccept]="task.type === LabelingTaskType.NAMED_ENTITY_EXTRACTION"></annotation-list>
                </div>
            </ng-container>
            <p *ngIf="task.type === LabelingTaskType.OBJECT_DETECTION" class="empty-description mtop8">
                <ng-container *ngIf="!(hasSelectedAnnotationGroup$ | async)">
                    Click and drag to add new objects
                </ng-container>
                <ng-container *ngIf="(hasSelectedAnnotationGroup$ | async)">
                    Click and drag to override annotations
                </ng-container>
            </p>
        </div>

        <review-no-annotations-placeholder *ngIf="annotationGroups.length === 0" [taskType]="task.type" [rejected]="false"></review-no-annotations-placeholder>

    </ng-container>

</ng-container>    
