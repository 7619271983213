<table
    class="fit-table"
    *ngIf="renderingMode !== CardBodyRenderingMode.TOP_LEVEL_CARD"
>
    <tbody class="fit-table__body">
        <tr class="fit-table__row">
            <th class="fit-table__title">{{ params.xColumn.name }}</th>
            <th class="text-left fit-table__title">{{ params.yColumn.name }}</th>
            <th>Count</th>
        </tr>
        <tr
            *ngFor="let x of results.table.xValues; let i = index; trackBy: trackByIndex"
            class="fit-table__row"
        >
            <th class="fit-table__head">{{ x | filterName }}</th>
            <td colspan="2">
                <table class="stats-table">
                    <tbody>
                        <tr
                            class="stats-table__row"
                            *ngFor="let y of results.table.yValues; let j = index;"
                        >
                            <th class="stats-table__head">{{ y | filterName }}</th>
                            <td class="stats-table__cell">
                                {{ results.table.counts[results.table.yValues.length * i + j] }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</table>
<table
    class="stats-table"
    *ngIf="renderingMode === CardBodyRenderingMode.TOP_LEVEL_CARD"
>
    <tbody class="stats-table__body">
        <tr class="stats-table__row">
            <th></th>
            <th
                class="stats-table__head stats-table__head--colored"
                *ngFor="let x of results.table.xValues;"
            >
                {{ x | filterName }}
            </th>
            <th class="stats-table__title">Totals</th>
        </tr>
        <tr
            *ngFor="let y of results.table.yValues; let j = index;"
            class="stats-table__row"
        >
            <th class="stats-table__head stats-table__head--colored">{{ y | filterName }}</th>
            <td
                class="stats-table__cell stats-table__cell--left"
                *ngFor="let x of results.table.xValues; let i = index;"
            >
                {{ results.table.counts[results.table.yValues.length * i + j] }}
            </td>
            <td class="stats-table__cell stats-table__cell--left">{{ results.table.yCounts[j] }}</td>
        </tr>
        <tr class="stats-table__row">
            <th class="stats-table__title stats-table__title--colored">Totals</th>
            <td
                class="stats-table__cell stats-table__cell--left"
                *ngFor="let x of results.table.xValues; let i = index;"
            >
                {{ results.table.xCounts[i] }}
            </td>
            <td class="stats-table__cell stats-table__cell--left">{{ results.table.total }}</td>
        </tr>
    </tbody>
</table>
