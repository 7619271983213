import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { MoodTest2SampCard, MoodTestNSampCard, PairwiseMoodTestCard, isAbstractPairwiseTestCard, isAbstract2SampTestCard } from 'src/generated-sources';
import { CardAction } from '../../events';


type SupportedCards = MoodTestNSampCard | MoodTest2SampCard | PairwiseMoodTestCard;

@Component({
    selector: 'generic-nsamp-mood-test-help',
    templateUrl: './generic-nsamp-mood-test-help.component.html',
    styleUrls: ['./generic-nsamp-mood-test-help.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericNSampMoodTestHelpComponent {
    @Input() params: SupportedCards;
    @Input() readOnly: boolean;
    @Input() extendedActions: boolean;
    @Output() action = new EventEmitter<CardAction>();

    get isPairwise() {
        return isAbstractPairwiseTestCard(this.params);
    }

    get hasTwoSamples() {
        return isAbstract2SampTestCard(this.params);
    }
}
