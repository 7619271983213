import { Injectable } from "@angular/core";
import { APIError, catchAPIError, ErrorContext } from "@core/dataiku-api/api-error";
import { DataikuAPIService } from "@core/dataiku-api/dataiku-api.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ColumnMetadata, ImportantColumnsMetadata, SampleDataFetcherService } from "@shared/services/item-feed/image-feed/sample-data-fetcher.service";
import { SampleDataFormatterService } from "@shared/services/item-feed/image-feed/sample-data-formatter.service";
import { Observable } from "rxjs";
import { SerializedMemTableV2, SerializedTableChunk } from "src/generated-sources";
import { ImageLabelingTaskInfo, LabelingService } from "../services/labeling.service";
import { getImageURL } from "../utils";

@UntilDestroy()
@Injectable()
export class ImageLabelingSettingsDataFetcherService extends SampleDataFetcherService implements ErrorContext {

    labelingTaskId: string;
    idColumn: string;
    itemPathColIndex: number;

    constructor(private labelingService: LabelingService, private DataikuAPI: DataikuAPIService, dataFormatter: SampleDataFormatterService) {
        super(dataFormatter);
        this.labelingService.labelingTaskInfo$.pipe(untilDestroyed(this)).subscribe((info) => {
            if (!info.idColumn) {
                return;
            }
            
            const mfLoc = (info as ImageLabelingTaskInfo).getManagedFolderLoc();

            if (this.projectKey !== mfLoc.projectKey ||
                this.managedFolderLoc.id !== mfLoc.id ||
                this.labelingTaskId !== info.labelingTaskId ||
                this.idColumn !== info.idColumn) {
                this.projectKey = info.projectKey;
                this.managedFolderLoc = mfLoc;
                this.labelingTaskId = info.labelingTaskId;
                this.idColumn = info.idColumn;
                this.refetchData();
            }
        });
    }

    refreshSample(): Observable<SerializedMemTableV2> {
        return this.DataikuAPI.labelingTasks.refreshImagesDataSample(this.projectKey, this.labelingTaskId, this.CHUNK_SIZE).pipe(catchAPIError(this, true));
    }

    getSampleChunk(offset: number, nbRows: number): Observable<SerializedTableChunk> {
        return this.DataikuAPI.labelingTasks.getImagesDataChunk(this.projectKey, this.labelingTaskId, nbRows, offset).pipe(catchAPIError(this, true));
    }

    buildImportantColumnsMetadata(): ImportantColumnsMetadata<ColumnMetadata> {
        return {
            single: {
                itemPath: {
                    name: this.idColumn
                }
            }
        }
    }

    setIdColumn(idColumn: string) {
        if (idColumn !== this.idColumn) {
            this.idColumn = idColumn;
            this.updateMetadataColumns();
            this.refetchData();
        }
    }

    pushError(error: APIError): void {
        this.labelingService.pushError(error);
    }

    // Need to override because fetching images for labeling from managed folder has specific permissions.
    getImagePath(itemPath: string): string {
        return getImageURL(this.projectKey, this.labelingTaskId, this.managedFolderLoc, itemPath);
    }

}