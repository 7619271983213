<div class="time-series-card-config">
    <div class="time-series-card-config__config-form-section">
        <form [formGroup]="configForm" id="config-form" class="dkuform-modal-horizontal time-series-card-config__config-form">

            <mat-tab-group
                mat-align-tabs="center"
                mat-stretch-tabs
                animationDuration="0ms"
                qa="ts-config-tabs"
            >
                <mat-tab label="Settings">
                    <ng-container [ngSwitch]="params.type">
                        <ng-container *ngSwitchCase="'stl_decomposition'">
                            <div class="section-header">
                                Input parameters
                            </div>
                        </ng-container>
                    </ng-container>
                    <div class="control-group" qa="ts-series-variable">
                        <label class="control-label">Series variable</label>
                        <variable-selector
                            [variables]="seriesVariables$ | async"
                            formControlName="seriesColumn"
                        ></variable-selector>
                    </div>

                    <div class="control-group" qa="ts-time-variable">
                        <label class="control-label">
                            Time variable
                            <i class="icon-info-sign mleft4" matTooltip="The time variable must hold ISO8601 formatted date times"></i>
                        </label>
                        <variable-selector
                            [variables]="timeVariables$ | async"
                            formControlName="timeColumn"
                        ></variable-selector>
                        <i *ngIf="(displayTimeStepChecksSpinner$ | async); else timeStepChecksDone" class="icon-spin icon-spinner mleft8" qa="ts-config-time-variable-spinner"></i>
                        <ng-template #timeStepChecksDone>
                            <i *ngIf="(timeStepChecksErrorCode$ | async)" class="icon-warning-sign icon-error-color mleft8"></i>
                            <i *ngIf="!useResampling && (timeStepChecks$ | async)?.warnings?.length" class="icon-warning-sign icon-warning-color mleft8"></i>
                        </ng-template>
                    </div>

                    <ng-container [ngSwitch]="params.type">
                        <ng-container *ngSwitchCase="'unit_root_test_adf'" formGroupName="adfOptions">
                            <div class="control-group">
                                <label class="control-label">Regression mode</label>
                                <basic-select
                                    formControlName="regressionMode"
                                    [items]="adfRegressionOptions"
                                    bindLabel="name"
                                    bindValue="value">
                                </basic-select>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'unit_root_test_za'" formGroupName="zaOptions">
                            <div class="control-group">
                                <label class="control-label">Regression mode</label>
                                <basic-select
                                    formControlName="regressionMode"
                                    [items]="zaRegressionOptions"
                                    bindLabel="name"
                                    bindValue="value">
                                </basic-select>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'unit_root_test_kpss'" formGroupName="kpssOptions">
                            <div class="control-group">
                                <label class="control-label">Regression mode</label>
                                <basic-select
                                    formControlName="regressionMode"
                                    [items]="kpssRegressionOptions"
                                    bindLabel="name"
                                    bindValue="value">
                                </basic-select>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'stl_decomposition'" formGroupName="stlDecompositionOptions">
                            <div class="control-group">
                                <label class="control-label">Decomposition type
                                    <i class="icon-info-sign mleft4" matTooltip="If the magnitude of the seasonality varies then the series is multiplicative"></i>
                                </label>
                                <div class="controls">
                                    <basic-select
                                        formControlName="decompositionType"
                                        [items]="decompositionTypeOptions"
                                        bindLabel="name"
                                        bindValue="value">
                                    </basic-select>
                                </div>
                            </div>
                            <div class="control-group">
                                <label class="control-label">Infer period
                                    <i class="icon-info-sign mleft4" matTooltip="Infer the period based on the series frequency using the Pandas library
                                     For instance, if the frequency is 1 day/1 month/1 hour then the period will be 7/12/24"></i>
                                </label>
                                <div>
                                    <label class="dku-toggle mleft0 vat" qa="stl-decomposition-auto-period-guessing">
                                        <input type="checkbox" class="control" formControlName="autoComputePeriod">
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                            <div class="control-group" *ngIf="!isPeriodAuto">
                                <label class="control-label">Period
                                    <i class="icon-info-sign mleft4" matTooltip="Length of the seasonal period"></i>
                                </label>
                                <input type="number" class="control" formControlName="period" min="1" qa="stl-decomposition-period"/>
                            </div>

                            <div class="section-header">
                                Smoothing
                            </div>
                            <div class="control-group">
                                <label class="control-label">Guess trend smoother
                                    <i class="icon-info-sign mleft4" matTooltip="In auto mode uses the smallest odd integer greater than 1.5 * period / (1 - 1.5 / seasonal smoother)"></i>
                                </label>
                                <div>
                                    <label class="dku-toggle mleft0 vat">
                                        <input type="checkbox" class="control" formControlName="autoComputeTrend">
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                            <div class="control-group" *ngIf="!isTrendAuto">
                                <label class="control-label">Trend smoother
                                    <i class="icon-info-sign mleft4" matTooltip="Length of the trend smoother. Must be an odd integer"></i>
                                </label>
                                <input type="number" class="control" formControlName="trend" min="1"/>
                            </div>
                            <div class="control-group">
                                <label class="control-label">Guess low pass filter
                                    <i class="icon-info-sign mleft4" matTooltip="In auto mode uses the smallest odd integer > period"></i>
                                </label>
                                <div>
                                    <label class="dku-toggle mleft0 vat">
                                        <input type="checkbox" class="control" formControlName="autoComputeLowPass">
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                            <div class="control-group" *ngIf="!isLowPassAuto">
                                <label class="control-label">Low pass filter
                                    <i class="icon-info-sign mleft4" matTooltip="Length of the low-pass filter. Must be an odd integer >=3"></i>
                                </label>
                                <input type="number" class="control" formControlName="lowPass" min="1"/>
                            </div>

                            <div class="control-group">
                                <label class="control-label">Seasonal smoother
                                    <i class="icon-info-sign mleft4" matTooltip="The window size used to estimate the seasonal component in STL decompositions. Must be an odd integer greater than 7"></i>
                                </label>
                                <input type="number" class="control" formControlName="seasonal" min="1"/>
                            </div>
                            <div class="section-header">
                                Advanced parameters
                            </div>
                            <div class="control-group">
                                <label class="control-label">Robust
                                    <i class="icon-info-sign mleft4" matTooltip="If selected, the estimation will re-weight data, allowing the model to tolerate larger errors"></i>
                                </label>
                                <input type="checkbox" class="control" formControlName="robust"/>
                            </div>
                            <div class="control-group">
                                <label class="control-label"> Degree of seasonal LOESS
                                </label>
                                <div class="controls">
                                    <basic-select
                                        formControlName="seasonalDeg"
                                        [items]="degreeModeOptions"
                                        bindLabel="name"
                                        bindValue="value">
                                    </basic-select>
                                </div>
                            </div>
                            <div class="control-group">
                                <label class="control-label"> Degree of trend LOESS
                                </label>
                                <div class="controls">
                                    <basic-select
                                        formControlName="trendDeg"
                                        [items]="degreeModeOptions"
                                        bindLabel="name"
                                        bindValue="value">
                                    </basic-select>
                                </div>
                            </div>
                            <div class="control-group">
                                <label class="control-label"> Degree of low pass LOESS
                                </label>
                                <div class="controls">
                                    <basic-select
                                        formControlName="lowPassDeg"
                                        [items]="degreeModeOptions"
                                        bindLabel="name"
                                        bindValue="value">
                                    </basic-select>
                                </div>
                            </div>
                            <div class="control-group">
                                <label class="control-label">Seasonal jump
                                    <i class="icon-info-sign mleft4" matTooltip="Positive integer determining the linear interpolation step. If larger than 1, the LOESS is used every 'seasonal jump' points and linear interpolation is between fitted points. Higher values reduce estimation time"></i>
                                </label>
                                <input type="number" class="control" formControlName="seasonalJump" min="1"/>
                            </div>

                            <div class="control-group">
                                <label class="control-label">Trend jump
                                    <i class="icon-info-sign mleft4" matTooltip="Positive integer determining the linear interpolation step. If larger than 1, the LOESS is used every 'trend jump' points and values between the two are linearly interpolated. Higher values reduce estimation time."></i>
                                </label>
                                <input type="number" class="control" formControlName="trendJump" min="1"/>
                            </div>

                            <div class="control-group">
                                <label class="control-label">Low pass jump
                                    <i class="icon-info-sign mleft4" matTooltip="Positive integer determining the linear interpolation step. If larger than 1, the LOESS is used every 'low pass jump' points and values between the two are linearly interpolated. Higher values reduce estimation time."></i>
                                </label>
                                <input type="number" class="control" formControlName="lowPassJump" min="1"/>
                            </div>
                            <div class="section-header">
                                Display
                            </div>
                            <div class="control-group">
                                <label class="control-label">Show series summary</label>
                                <input type="checkbox" class="control" formControlName="showSummary"/>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'acf_plot'" formGroupName="acfPlotOptions">
                            <div class="control-group">
                                <label class="control-label">Show series summary</label>
                                <input type="checkbox" class="control" formControlName="showSummary"/>
                            </div>

                            <div class="control-group">
                                <label class="control-label">Compute partial auto-correlation</label>
                                <input type="checkbox" class="control" formControlName="isPartial"/>
                            </div>

                            <div class="control-group" *ngIf="isPartialACF">
                                <label class="control-label">Method</label>
                                <basic-select
                                    formControlName="pacfMethod"
                                    [items]="pacfMethodOptions"
                                    bindLabel="name"
                                    bindValue="value">
                                </basic-select>
                            </div>

                            <div class="control-group" *ngIf="!isPartialACF">
                                <label class="control-label">Adjust for the loss of data</label>
                                <input type="checkbox" class="control" formControlName="adjusted"/>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="isAutoComputeLagsEnabled">
                        <div class="control-group">
                            <label class="control-label">Automatically compute the lags</label>
                            <input type="checkbox" class="control" formControlName="autoComputeLags"/>
                        </div>

                        <div class="control-group" *ngIf="!autoComputeLags">
                            <label class="control-label">Number of lags</label>
                            <input type="number" class="control" formControlName="nLags" min="1"/>
                        </div>
                    </ng-container>
                </mat-tab>

                <mat-tab label="Multiple series">
                    <div class="control-group" qa="ts-use-long-format">
                        <label class="control-label">Data has multiple series (long format)</label>
                        <input type="checkbox" class="control" formControlName="useLongFormat"/>
                    </div>

                    <ng-container *ngIf="useLongFormat">
                        <div class="control-group" qa="ts-series-ids">
                            <label class="control-label">Series identifiers</label>
                            <variable-selector
                                [variables]="identifierVariables$ | async"
                                formControlName="identifierColumns"
                                [multiple]="true"
                            ></variable-selector>
                        </div>

                        <div
                            *ngFor="let identifier of selectedIdentifiers"
                            class="control-group"
                            [qa]="{ 'ts-series-id': { 'name': identifier.name } }"
                        >
                            <label class="control-label identifier-name">
                                Filter values for
                                <u [matTooltip]="identifier.name">{{ identifier.name }}</u>
                            </label>
                            <ng-select
                                *ngIf="getTsIdValuesFormData(identifier) as formData"
                                class="values-selector"
                                [formControl]="formData.control"
                                [items]="formData.suggestions"
                                [loading]="formData.isLoading"
                                [multiple]="true"
                                [virtualScroll]="true"
                                [clearable]="false"
                                [searchable]="false"
                                [closeOnSelect]="false"
                                placeholder="Use all values"
                                loadingText="Loading most common values..."
                                appendTo="body"
                            >
                                <ng-template ng-header-tmp *ngIf="!formData.isLoading">
                                    <ng-select-search-input></ng-select-search-input>
                                </ng-template>

                                <ng-template ng-loadingspinner-tmp>
                                    <i class="icon-spin icon-spinner"></i>
                                </ng-template>

                                <ng-template ng-multi-label-tmp let-items="items">
                                    <!-- template to disaply when multiple values are selected -->
                                    <ng-container *ngIf="items.length > 1">
                                        {{ items.length }} values selected
                                    </ng-container>
                                    <ng-container *ngIf="items.length === 1">
                                        {{ items[0] }}
                                    </ng-container>
                                </ng-template>

                                <ng-template ng-footer-tmp *ngIf="!formData.isLoading">
                                    <div class="use-all-values-container">
                                        <button
                                            class="btn btn-small btn--text btn--secondary"
                                            (click.stop)="formData.selectAll()"
                                            [disabled]="formData.hasEmptySelection"
                                        >
                                            Clear (Use all values)
                                        </button>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </ng-container>
                </mat-tab>

                <mat-tab label="Resampling">
                    <div class="control-group" qa="ts-use-resampling">
                        <label class="control-label">Resample the series</label>
                        <input type="checkbox" class="control" formControlName="useResampling"/>
                    </div>

                    <ng-container *ngIf="useResampling" formGroupName="resamplerSettings">
                        <div class="control-group" qa="ts-resampling-time-unit">
                            <label class="control-label">Time unit</label>
                            <basic-select
                                [items]="timeUnitOptions"
                                bindLabel="name"
                                bindValue="value"
                                formControlName="timeUnit">
                            </basic-select>
                        </div>

                        <div class="control-group" *ngIf="hasWeeklyResampling">
                            <label class="control-label">End of week</label>
                            <basic-select
                                [items]="dayOfWeekOptions"
                                bindLabel="name"
                                bindValue="value"
                                formControlName="timeUnitEndOfWeek">
                            </basic-select>
                        </div>

                        <div class="control-group" qa="ts-resampling-n-units">
                            <label class="control-label">Number of units</label>
                            <input type="number" class="control" formControlName="nUnits" min="1" step="1">
                        </div>

                        <div class="control-group" qa="ts-resampling-interpolation">
                            <label class="control-label">
                                Interpolation method
                                <i class="icon-info-sign mleft4"
                                   matTooltip="Method to infer numerical values for missing timestamps that do not begin or end the series"
                                ></i>
                            </label>
                            <basic-select
                                [items]="interpolationOptions"
                                bindLabel="name"
                                bindValue="value"
                                formControlName="interpolationMethod">
                            </basic-select>
                        </div>

                        <div class="control-group" *ngIf="hasConstantInterpolation">
                            <label class="control-label">Value for interpolation</label>
                            <input type="number" class="control" formControlName="interpolationConstantValue">
                        </div>

                        <div class="control-group" qa="ts-resampling-extrapolation">
                            <label class="control-label">
                                Extrapolation method
                                <i class="icon-info-sign mleft4"
                                   matTooltip="Method to infer numerical values for missing timestamps located before the first (or after the last) available timestamp, e.g. when the data contains several series of different sizes"
                                ></i>
                            </label>
                            <basic-select
                                [items]="extrapolationOptions"
                                bindLabel="name"
                                bindValue="value"
                                formControlName="extrapolationMethod">
                            </basic-select>
                        </div>

                        <div class="control-group" *ngIf="hasConstantExtrapolation">
                            <label class="control-label">Value for extrapolation</label>
                            <input type="number" class="control" formControlName="extrapolationConstantValue">
                        </div>

                        <div class="control-group">
                            <label class="control-label">
                                Duplicate timestamps handling
                                <i class="icon-info-sign mleft4"
                                   matTooltip="Method to handle duplicate timestamps in the series"
                                ></i>
                            </label>
                            <basic-select
                                [items]="duplicateTimestampsHandlingOptions"
                                bindLabel="name"
                                bindValue="value"
                                formControlName="duplicateTimestampsHandlingMethod">
                            </basic-select>
                        </div>
                    </ng-container>
                </mat-tab>
            </mat-tab-group>
        </form>
    </div>
    <div class="time-series-card-config__alert-section">
        <ng-container *ngIf="error">
            <div class=time-series-card-config__alert>
                <api-error-alert
                    [apiError]="error"
                    (resetError)="pushError(null)"
                    [closable]="true"
                ></api-error-alert>
            </div>
        </ng-container>
        <ng-container *ngIf="(displayTimeStepChecksSpinner$ | async); else displayTimeStepChecksResults">
            <div class="alert alert-info time-series-card-config__alert">
                <i class="icon-spin icon-spinner"></i>
                Checking the validity of the time variable...
            </div>
        </ng-container>
        <ng-template #displayTimeStepChecksResults>
            <ng-container *ngIf="timeStepChecksAPIError">
                <div class=time-series-card-config__alert>
                    <api-error-alert
                        [apiError]="timeStepChecksAPIError"
                        (resetError)="resetTimeStepChecksAPIError()"
                        [closable]="true"
                    ></api-error-alert>
                </div>
            </ng-container>
            <ng-container *ngIf="(timeStepChecksErrorCode$ | async) as errorCode">
                <div class="alert alert-error api-error-alert time-series-card-config__alert">
                    <div class="api-error-alert__contents">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon mright8"></i>{{errorCode}}</h4>
                        <h5 class="api-error-alert__subtitle" *ngIf="errorCode !== (timeStepChecks$ | async)?.error_message">{{(timeStepChecks$ | async)?.error_message}}</h5>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!useResampling">
                <ng-container *ngFor="let timeStepWarning of (timeStepChecks$ | async)?.warnings">
                    <div class="alert alert-warning time-series-card-config__alert">
                        <i class="icon-warning-sign icon-warning-color"></i>
                        {{ timeStepWarning }}
                    </div>
                </ng-container>
            </ng-container>
        </ng-template>
    </div>
</div>
