import { Component, ChangeDetectionStrategy, Input, OnChanges, Inject, SimpleChanges } from '@angular/core';
import { LabelingTaskPrivileges } from '../labeling.component';

export class UnusableTaskWarning {
    message: string;
    redirectionTab: "overview" | "settings";
    redirectionSubTab?: "classes" | "data";
    
    constructor(message: string, redirectionTab: "overview" | "settings", redirectionSubTab?: "classes" | "data") {
        this.message = message;
        this.redirectionTab = redirectionTab;
        this.redirectionSubTab = redirectionSubTab;
    }
}

@Component({
    selector: 'labeling-unusable-warning',
    templateUrl: './labeling-unusable-warning.component.html',
    styleUrls: ['./labeling-unusable-warning.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelingUnusableWarningComponent implements OnChanges {

    UnusableTaskWarningType: UnusableTaskWarning;
    @Input() privileges: LabelingTaskPrivileges;
    @Input() warning: UnusableTaskWarning;

    canAccessTab: boolean;

    constructor(@Inject('$state') public $state: any) { 
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.warning || changes.privileges) {
            switch(this.warning.redirectionTab) {
                case "overview":
                    this.canAccessTab = this.privileges.canReadConf;
                    break;
                case "settings":
                    this.canAccessTab = this.privileges.canWriteConf;
                    break;
            }
        }
    }

}
