import type {ACF} from './acf';
import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {PACF} from './pacf';
import type {_AvailableResult} from './../available-result';
import type {_TimeSeriesComputation} from './time-series-computation';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.timeseries.CorrelationFunction
 */
export interface _CorrelationFunction extends _TimeSeriesComputation {}

export type CorrelationFunction = ACF | PACF;

export namespace CorrelationFunction {
    export const type = ['acf', 'pacf'] as const;
}

/** @deprecated Use Computation.isCorrelationFunction() */ export function isCorrelationFunction(toTest: Computation): toTest is CorrelationFunction { return (CorrelationFunction.type as readonly unknown[]).includes(toTest['type']); }

export namespace CorrelationFunction {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.CorrelationFunction$CorrelationFunctionResult
     */
    export interface _CorrelationFunctionResult extends _AvailableResult {
        coefficients: number[];
        confidenceIntervals: number[][];
    }

    export type CorrelationFunctionResult = ACF.ACFResult | PACF.PACFResult;

    export namespace CorrelationFunctionResult {
        export const type = ['acf', 'pacf'] as const;
    }

    /** @deprecated Use ComputationResult.isCorrelationFunctionResult() */ export function isCorrelationFunctionResult(toTest: ComputationResult): toTest is CorrelationFunctionResult { return (CorrelationFunctionResult.type as readonly unknown[]).includes(toTest['type']); }
}