<h2 class="foldable-title" (click)="toggle()">
    <i
        [ngClass]="{'dku-icon-chevron-right-16': (opened$ | async) === false, 'dku-icon-chevron-down-16': opened$ | async}"></i>Regression
    line
</h2>

<div class="options-zone" [hidden]="(opened$ | async) === false">
    <div *ngIf="hasRegressionLine(); then regressionLine else emptyState"></div>
    <ng-template #emptyState>
        <div class="options-zone">
            <span class="dku-text db mleftright8 text-prompt">Compute a regression to detect a relationship between your data.</span>
            <button class="btn btn--text btn--primary btn--dku-icon mtop8" (click)="addRegression()">
                <i class="dku-icon-plus-16"></i>
                <span>Add regression line</span>
            </button>
        </div>
    </ng-template>

    <ng-template #regressionLine>
        <div class="dku-chart-option-box">
            <span class="dku-text-b mtop8 mbot4 db">Regression</span>
            <button class="btn btn-trash btn--text btn--icon btn--secondary btn--dku-icon" (click)="deleteRegression()">
                <i class="dku-icon-trash-12"></i>
            </button>
            <form class="dkuform-chart-options" [formGroup]="regressionLineForm">
                <div class="horizontal-flex">
                    <div class="control-group flex-no-basis">
                        <label class="control-label">Type
                            <i class="dku-icon-warning-filled-16 control-label__warning-icon dibvab" matTooltip="Negative values from {{regressionLineForm.value.type === REGRESSION_TYPES.LOGARITHMIC ? 'x' : 'y'}}-axis are not considered."
                                matTooltipPosition="above"
                                *ngIf="cutValuesWarning">
                            </i>
                        </label>
                        <div class="controls">
                            <ng-select formControlName="type" bindLabel="name" bindValue="id" appendTo="body"
                                [items]="regressionTypes" [clearable]="false">
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div class="horizontal-flex">
                    <div class="control-group">
                        <label class="control-label">Color</label>
                        <div class="controls flex">
                            <dss-reactive-input [inputTemplate]="colorPickerInput" formControlName="lineColor">
                                <ng-template #colorPickerInput let-value="value" let-onValueChange="onValueChange">
                                    <chart-color-picker class="flex mx-center-children" [itemsPerRow]="10"
                                        [color]="value" [colors]="colors"
                                        (colorChange)="onValueChange($event)"></chart-color-picker>
                                </ng-template>
                            </dss-reactive-input>
                        </div>
                    </div>
                    <div class="control-group flex mbot0">
                        <label class="control-label">Stroke width</label>
                        <div class="controls">
                            <label>
                                <dss-reactive-input [inputTemplate]="sliderInput" formControlName="lineSize">
                                    <ng-template #sliderInput let-value="value" let-onValueChange="onValueChange">
                                        <dku-slider [value]="value" [min]="1" [max]="5"
                                            (valueChange)="onValueChange($event)">
                                        </dku-slider>
                                    </ng-template>
                                </dss-reactive-input>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="control-group mtop4">
                    <div class="controls">
                        <label>
                            <input type="checkbox" formControlName="displayFormula" />
                            <span>Display formula</span>
                        </label>
                    </div>
                </div>

                <ng-container *ngIf="regressionLineForm.value.displayFormula">
                    <div class="control-group">
                        <label class="control-label">Position</label>
                        <div class="controls">
                            <ng-select formControlName="labelPosition" bindLabel="name" bindValue="id" appendTo="body"
                                [items]="labelPositionOptions" [clearable]="false">
                            </ng-select>
                        </div>
                    </div>

                    <dss-reactive-input [inputTemplate]="textFormattingForm" formControlName="textFormatting">
                        <ng-template #textFormattingForm let-value="value" let-onValueChange="onValueChange">
                            <text-formatting-form [textFormatting]="value" (textFormattingChange)="onValueChange($event)"></text-formatting-form>
                        </ng-template>
                    </dss-reactive-input>

                </ng-container>
            </form>
        </div>
    </ng-template>
</div>
