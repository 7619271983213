<button class="mass-action-container"
    [class.disabled]="!menuEnabled"
    [matMenuTriggerFor]="actionsMenu"
>
    <input type="checkbox"
        (click)="$event.stopPropagation()"
        [checked]="this.toggleMode !== 'NONE'"
        (change)="toggleSelectAll()"
        [indeterminate]="toggleMode === 'INDETERMINATE'"
        [disabled]="this.selectableItems.length === 0"
    />
    <span *ngIf="menuEnabled">ACTIONS</span>
    <b class="caret"></b>
</button>


<mat-menu #actionsMenu>
    <ng-template matMenuContent>
        <button mat-menu-item
            *ngFor="let opt of options"
            (click)="opt.action()"
        >
            <i [class]="opt.icon"></i>
            <span>{{opt.label}}</span>
        </button>
    </ng-template>
</mat-menu>
