import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LabelingAnnotateTextService } from '@features/labeling/labeling-task-annotate/services/labeling-annotate-text.service';
import { UIAnnotation, UINamedEntity } from '@features/labeling/models/annotation';
import { NamedEntityGroup } from '@features/labeling/models/annotation-group';
import { NamedEntityExtractionUILabel } from '@features/labeling/models/label';
import { ImageState, LabelingService } from '@features/labeling/services/labeling.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, map, Observable, Subject, withLatestFrom } from 'rxjs';
import { LabelingReviewAnnotationGroupService } from '../services/labeling-review-annotation-group.service';
import { LabelingReviewService } from '../services/labeling-review.service';
import { ReviewRecordInfo, TextLabelingRecord } from 'generated-sources';

class ReviewNamedEntityExtractionUILabel extends NamedEntityExtractionUILabel {
    annotations: ReviewUINamedEntity[];
}

export class ReviewUINamedEntity extends UINamedEntity {
    regionIndex: number;
    private _hidden: boolean;

    constructor({ category, beginningIndex, endIndex, text, labelWidth, annotator, selected, hidden, regionIndex}: { category?: string, beginningIndex: number, endIndex: number, text: string, labelWidth?: number, annotator?: string, selected?: boolean, hidden?: boolean, regionIndex: number}) {
        super({category, beginningIndex, endIndex, annotator, text, selected, labelWidth});
        this.regionIndex = regionIndex;
        this._hidden = hidden || false;
    }

    isHidden(): boolean {
        return this._hidden;
    }
}

export class ReviewRegionUINamedEntity extends ReviewUINamedEntity {
    private _disabled: boolean;
    private _hasConflict: boolean;
    state: string;

    constructor({ category, beginningIndex, endIndex, text, disabled, labelWidth, annotator, selected, hidden, regionIndex, hasConflict, state}: { category?: string, beginningIndex: number, endIndex: number, text: string, disabled: boolean, labelWidth?: number, annotator?: string, selected?: boolean, hidden?: boolean, regionIndex: number, hasConflict: boolean, state: string}) {
        super({category, beginningIndex, endIndex, annotator, text, selected, labelWidth, regionIndex, hidden});
        this.state = state;
        this.regionIndex = regionIndex;
        this._disabled = disabled;
        this._hasConflict = hasConflict;
    }

    selectableInSidePanel(): boolean {
        return false;
    }

    hasConflict(): boolean {
        return this._hasConflict;
    }

    isDisabled(): boolean {
        return this._disabled;
    }
}

@UntilDestroy()
@Component({
    selector: 'named-entity-region-selector',
    templateUrl: './named-entity-region-selector.component.html',
    styleUrls: ['./named-entity-region-selector.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NamedEntityRegionSelectorComponent {
    readonly ReviewRecordInfo: ReviewRecordInfo;
    readonly TextLabelingRecord: TextLabelingRecord;
    label$: Observable<ReviewNamedEntityExtractionUILabel>;
    onLabelChange$ = new Subject<NamedEntityExtractionUILabel>();
    
    constructor(
        public labelingReviewService: LabelingReviewService,
        private labelingReviewAnnotationGroupService: LabelingReviewAnnotationGroupService,
        private labelingAnnotateTextService: LabelingAnnotateTextService,
        public labelingService: LabelingService,
    ) {
        // format change made on the annotation group list:
        //   * side pannel
        //   * reflexion of a change done on the annotate component
        this.label$ = combineLatest([this.labelingReviewAnnotationGroupService.annotationGroupList$, labelingReviewService.currentReviewRecord$]).pipe(
            map(([annotationGroupList, reviewRecordInfo]) => this.formatGroupListToDisplayableNamedEntities(annotationGroupList as NamedEntityGroup[], reviewRecordInfo.record as TextLabelingRecord))
        );

        // Reflect change made on the labeling-task-text-annotate component to the annotation group list
        this.onLabelChange$.pipe(
            untilDestroyed(this),
            withLatestFrom(
                this.labelingService.labelingTaskInfo$,
                this.labelingReviewAnnotationGroupService.annotationGroupList$,
            ),
        ).subscribe(([newLabel, task, oldAnnotationGroupList]) => {
            const newEntities = (newLabel?.annotations || []) as ReviewUINamedEntity[];
            const oldAnnotations = oldAnnotationGroupList.flatMap(r => r.annotations);
            const newAnnotationsEntities = newEntities.filter(e => !(e instanceof ReviewRegionUINamedEntity));
            const newRegionEntities = newEntities.filter((e) => e instanceof ReviewRegionUINamedEntity);
            
            // region has been selected (nothing else changed)
            // region.selected=true only when selected after it's the first annotation that is selected=true
            const selectedRegionEntity = newRegionEntities.find(l => l.selected);
            if (selectedRegionEntity !== undefined) {
                this.labelingReviewAnnotationGroupService.selectAnnotationGroup(selectedRegionEntity.regionIndex);
            } else {
                // Some modification might have happened in opened region (selection/deselection of some annotation, deletion of some annotation)
                const openRegion = newRegionEntities.find(r => r.isHidden()); // the opened region is the only one hidden
                if (openRegion !== undefined) {
                    const annotationInOpenRegion = newAnnotationsEntities.filter(a => a.regionIndex === openRegion.regionIndex);
                    this.labelingReviewAnnotationGroupService.updateAnnotationGroup(annotationInOpenRegion, true);
                }

                if (oldAnnotations.length < newAnnotationsEntities.length) { // new annotation, there can be at most one new annotation
                    const newAnnotation = newAnnotationsEntities.find((a: UIAnnotation) => !oldAnnotations.some((a2) => a.equals(a2)));
                    if (newAnnotation) {
                        this.labelingReviewAnnotationGroupService.addAnnotationGroup(new NamedEntityGroup([newAnnotation], task.minNbAnnotatorsPerRecord, false, true, true, true), true);	
                    }
                } else if (oldAnnotationGroupList.length > newRegionEntities.length) { // not-opened region has been deleted
                    // Old indices are range(oldList.length)
                    const oldAnnotationGroupIndices = Array.from(Array(oldAnnotationGroupList.length).keys());
                    const remainingRegionIndices = new Set(newRegionEntities.map(regionEntity => regionEntity.regionIndex));
                    const deletedIndex = oldAnnotationGroupIndices.find(index => !remainingRegionIndices.has(index));
                    if (deletedIndex !== undefined) {
                        this.labelingReviewAnnotationGroupService.deleteAnnotationGroup(deletedIndex);
                    }
                }
            }
        })
    }

    private formatGroupListToDisplayableNamedEntities(annotationGroupList: NamedEntityGroup[], record: TextLabelingRecord) {
        const selectedRegion = annotationGroupList.filter(r => r.selected)[0];
        let annotations: ReviewUINamedEntity[] = [];

        annotationGroupList.forEach((annotationGroup, annotationGroupIndex) => {
            annotations = annotations.concat(annotationGroup.annotations.map(annotation => new ReviewUINamedEntity({
                ...annotation,
                regionIndex: annotationGroupIndex,
                hidden: !annotationGroup.selected,
            })));
            annotations.push(this.createReviewRegionUINamedEntityFromAnnotationGroup(annotationGroup, record, annotationGroupIndex, !!selectedRegion, selectedRegion === annotationGroup));
        });

        return new ReviewNamedEntityExtractionUILabel(annotations);
    }

    private createReviewRegionUINamedEntityFromAnnotationGroup(annotationGroup: NamedEntityGroup, record: TextLabelingRecord, annotationGroupIndex: number, disableAnnotations: boolean, hidden: boolean): ReviewRegionUINamedEntity {
        const { beginningIndex, endIndex } = annotationGroup.getRange();
        if (annotationGroup.annotations.length > 0) {
            return new ReviewRegionUINamedEntity({
                category: annotationGroup.getCategoryLabel(),
                annotator: 'region',
                hasConflict: !disableAnnotations && annotationGroup.hasConflict(),
                beginningIndex,
                endIndex,
                hidden,
                regionIndex: annotationGroupIndex,
                disabled: disableAnnotations, // used for graying out non-selected regions
                text: this.labelingAnnotateTextService.getTextFromBoundaries(record.text, beginningIndex, endIndex),
                state: annotationGroup.hasConflict() ? ImageState.CONFLICTING.toString() : annotationGroup.hasMissingCategory() ? ImageState.MISSING_CATEGORY.toString() : ImageState.CONSENSUS.toString()
            })
        } else {
            return {} as ReviewRegionUINamedEntity;
        }
    }
}
