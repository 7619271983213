<table class="stats-table">
    <thead>
        <tr>
            <th class="stats-table__head">%</th>
            <th class="stats-table__head">Value</th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let quantile of results.quantiles; let index = index; trackBy: trackByIndex">
            <tr class="stats-table__row">
                <th class="stats-table__head">{{ quantile.freq | percent: '1.0-4' }}</th>
                <td class="stats-table__cell">{{ quantile.quantile | smarterNumber }}</td>
            </tr>
            <ng-container *ngIf="params.showConfidenceInterval && quantile.lower != null && quantile.upper != null">
                <tr></tr>
                <tr class="stats-table__row">
                    <th class="stats-table__head stats-table__head--derived" [matTooltip]="'Confidence interval'">Conf. int.</th>
                    <td class="stats-table__cell stats-table__cell--derived">[{{ quantile.lower | smarterNumber }}, {{ quantile.upper | smarterNumber }}]</td>
                </tr>
            </ng-container>
        </ng-container>
    </tbody>
</table>
