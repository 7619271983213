import type {CompactedArray} from './../../utils/compacted-array';
import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {Distribution2D} from './../../distributions/distribution2-d';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.bivariate.Fit2DDistribution
 */
export interface Fit2DDistribution extends Computation._BivariateComputation {
    distribution: Distribution2D;
    xResolution: number;
    yResolution: number;

    // PolyJson type
    type: 'fit_2d_distribution';
}

export namespace Fit2DDistribution {
    export const type = 'fit_2d_distribution';
}

/** @deprecated Use Computation.isFit2DDistribution() */ export function isFit2DDistribution(toTest: Computation): toTest is Fit2DDistribution { return Fit2DDistribution.type === toTest['type']; }

export namespace Fit2DDistribution {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.bivariate.Fit2DDistribution$Fit2DDistributionResult
     */
    export interface Fit2DDistributionResult extends _AvailableResult {
        density: Fit2DDistribution.Density;
        model: Distribution2D.FittedDistribution2D;

        // PolyJson type
        type: 'fit_2d_distribution';
    }

    export namespace Fit2DDistributionResult {
        export const type = 'fit_2d_distribution';
    }

    /** @deprecated Use ComputationResult.isFit2DDistributionResult() */ export function isFit2DDistributionResult(toTest: ComputationResult): toTest is Fit2DDistributionResult { return Fit2DDistributionResult.type === toTest['type']; }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.bivariate.Fit2DDistribution$Density
     */
    export interface Density {
        data: CompactedArray;
        xMax: number;
        xMin: number;
        yMax: number;
        yMin: number;
    }
}