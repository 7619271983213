import { Injectable } from "@angular/core";
import { LabelingService } from "@features/labeling/services/labeling.service";
import { isTaskSetProperly } from "@features/labeling/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Chunk } from "@shared/components/infinite-scroll/infinite-scroll.component";
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, withLatestFrom } from "rxjs/operators";
import { deepDistinctUntilChanged } from 'dku-frontend-core';
import { LabelingRecord, ReviewRecordInfo} from "src/generated-sources";
import { CellData } from "@shared/services/item-feed/items-data-fetcher.service";

export enum ReviewFetchType {
    RECORDS_TO_REVIEW = 'RECORDS_TO_REVIEW',
    REVIEWED_RECORDS = 'REVIEWED_RECORDS'
}

export enum ReviewStatus {
    CONFLICTING = 'CONFLICTING',
    CONSENSUS = 'CONSENSUS',
    VALIDATED = 'VALIDATED',
    REJECTED = 'REJECTED',
}

export class LabelingReviewCellData implements CellData {
    itemId: string;
    itemIndex: number;
    status: ReviewStatus;
}

export type LabelingReviewRecord = LabelingReviewCellData & ReviewRecordInfo;


@UntilDestroy()
@Injectable()
export class LabelingReviewService {
    private previousItemTrigger$ = new Subject<void>();
    private previousTrigger$ = new Subject<void>();
    private nextItemTrigger$ = new Subject<void>();
    private refreshTrigger$ = new Subject<void>();
    private fetchTrigger$ = new ReplaySubject<void>(1);
    private updateReviewRecordTrigger$ = new Subject<Partial<LabelingReviewRecord>>();

    isLast$: Observable<boolean>;
    isFirst$: Observable<boolean>;
    isEmpty$: Observable<boolean>;
    isFiltered$: Observable<boolean>;

    recordIds$ = new ReplaySubject<string[]>(1);
    private originalRecordIds$: Observable<string[]>;

    currentItemSource$ = new ReplaySubject<{ itemIndex: number, itemId: string }>(1);
    currentReviewRecord$ = new ReplaySubject<LabelingReviewRecord>(1);
    currentId$: Observable<string>;
    currentIndex$: Observable<number>;
    finishedReview$ = new BehaviorSubject<boolean>(false);
    reviewFetchType$ = new BehaviorSubject<ReviewFetchType>(ReviewFetchType.RECORDS_TO_REVIEW);
    filterQuery$ = new BehaviorSubject<string>('');
    
    constructor(private labelingService: LabelingService) {

        this.originalRecordIds$ = this.fetchTrigger$.pipe(
            withLatestFrom(
                this.labelingService.labelingTaskInfo$,
                this.labelingService.identifier$, 
                this.reviewFetchType$,
            ),
            switchMap(([, task, identifier, reviewFetchType]) => {
                if (!isTaskSetProperly(task)) {
                    return of([]);
                }
                if (identifier) {
                    return of([identifier]);
                } else {
                    switch(reviewFetchType) {
                        case ReviewFetchType.RECORDS_TO_REVIEW: 
                            return this.labelingService.fetchRecordIdsToReview();
                        case ReviewFetchType.REVIEWED_RECORDS:
                            return this.labelingService.fetchReviewedRecordIds();
                    }
                }
            }),
            shareReplay(1)
        );

        this.originalRecordIds$.pipe(
            untilDestroyed(this),
        ).subscribe(() => {
            this.finishedReview$.next(false);
        });

        this.currentReviewRecord$.pipe(
            distinctUntilChanged((r1, r2) => r1.itemId === r2.itemId),
            untilDestroyed(this)
        ).subscribe((record) => {
           this.setImageSource(record.itemId, record.itemIndex); 
        });

        combineLatest([
            this.originalRecordIds$,
            this.filterQuery$ // TODO search through text?
        ]).pipe(
            map(([originalItemIds, query]) => query ? originalItemIds.filter(recordId => recordId.toLowerCase().includes(query.toLowerCase())) : originalItemIds),
            untilDestroyed(this),
        ).subscribe(recordIds => {
            this.recordIds$.next(recordIds);
        });

        this.nextItemTrigger$.pipe(
            withLatestFrom(
                this.recordIds$,
                this.currentItemSource$
            ),
            map(([_, itemIds, currentId]) => {
                if (currentId) {
                    return [itemIds, itemIds.indexOf(currentId.itemId) + 1] as const;
                } else {
                    return [itemIds, 1] as const;
                }
            }),
            filter(([itemIds, newIndex]) => {
                return newIndex !== -1 && newIndex < itemIds.length;
            }),
            untilDestroyed(this)
        ).subscribe(([imageIds, index]) => {
            const itemId = imageIds[index];
            this.currentItemSource$.next({ itemId, itemIndex: index });
        });

        this.refreshTrigger$.pipe(
            withLatestFrom(this.currentItemSource$),
            untilDestroyed(this)
        ).subscribe(([_, { itemId, itemIndex }]) => {
            this.currentItemSource$.next({ itemIndex, itemId });
        });

        this.previousTrigger$.pipe(
            untilDestroyed(this),
            withLatestFrom(this.finishedReview$)
        ).subscribe(([_trigger, finishedReview]) => {
            if (finishedReview) {
                this.refreshTrigger$.next();
                this.finishedReview$.next(false);
            } else {
                this.previousItemTrigger$.next();
            }
        })

        // TODO remove duplication with nextItemSource$
        this.previousItemTrigger$.pipe(
            withLatestFrom(
                this.recordIds$,
                this.currentItemSource$
            ),
            map(([_, itemIds, currentItemSource]) => {
                if (currentItemSource) {
                    return [itemIds, itemIds.indexOf(currentItemSource.itemId) - 1] as const;
                } else {
                    return [itemIds, 1] as const;
                }
            }),
            filter(([imageIds, newIndex]) => {
                return newIndex !== -1 && newIndex < imageIds.length;
            }),
            untilDestroyed(this)
        ).subscribe(([imageIds, newIndex]) => {
            this.setImageSource(imageIds[newIndex], newIndex);
        });

        this.isLast$ = combineLatest([this.currentItemSource$, this.recordIds$]).pipe(
            map(([{ itemIndex }, ids]) => ids.length > 0 && (itemIndex === ids.length-1))
        );

        this.isFirst$ = this.currentItemSource$.pipe(
            map(({ itemIndex }) => itemIndex === 0),
            startWith(true)
        );

        this.isEmpty$ = this.recordIds$.pipe(map(ids => ids.length === 0));

        this.isFiltered$ = this.filterQuery$.pipe(map(query => query.length !== 0));

        this.recordIds$.pipe(
            distinctUntilChanged(([prevItemIds,], [currItemIds,]) => prevItemIds === currItemIds),
            untilDestroyed(this)
        ).subscribe(itemIds => {
            this.setImageSource(itemIds[0], 0);
        });

        this.currentId$ = this.currentItemSource$.pipe(
            map(item => item.itemId),
            filter(path => path != null)
        );

        this.currentIndex$ = this.currentItemSource$.pipe(
            map(item => item.itemIndex)
        );

        this.reviewFetchType$.pipe(
            untilDestroyed(this)
        ).subscribe(_ => {
            this.fetchTrigger$.next();
        });

        this.updateReviewRecordTrigger$.pipe(
            withLatestFrom(this.currentReviewRecord$),
            untilDestroyed(this)
        ).subscribe(([partialRecord, currentRecord]) => {
            this.setReviewRecord({
                ...currentRecord, 
                ...partialRecord
            });
        });
    }

    setReviewRecord(item: LabelingReviewRecord) {
        this.currentReviewRecord$.next(item);
    }

    updateCurrentReviewRecord(item: Partial<LabelingReviewRecord>) {
        this.updateReviewRecordTrigger$.next(item);
    }

    setImageSource(itemId: string, index: number) {
        this.currentItemSource$.next({ itemId , itemIndex: index });
        this.finishedReview$.next(false);
    }

    first() {
        this.fetchTrigger$.next();
    }

    getChunk(offset: number, chunkSize: number): Observable<Chunk> {
        return this.recordIds$.pipe(
            deepDistinctUntilChanged(),
            switchMap(recordIds => {
                const chunkedRecordIds = recordIds.slice(offset, offset + chunkSize);
                return this.labelingService.listReviewRecordInfo(chunkedRecordIds).pipe(
                    map((recordReviewInfo) => {
                        return {
                            chunkItems: chunkedRecordIds.map((id, index) => {
                                return {
                                    itemIndex: index + offset,
                                    itemId: id,
                                    status: this.getRecordStatus(recordReviewInfo[index]),
                                    ...recordReviewInfo[index]
                                }
                            }),
                            totalItems: recordIds.length,
                        }
                    })
                )
            }),
        );
    }

    public toggleReviewFetchType(fetchType: ReviewFetchType) {
        this.reviewFetchType$.next(fetchType);
    }

    public filterIds(query: string) {
        this.filterQuery$.next(query);
    }

    public clearFilter() {
        this.filterQuery$.next('');
    }

    nextItem() {
        this.nextItemTrigger$.next();
    }

    previousItem() {
        this.previousTrigger$.next();
    }

    private getRecordStatus(recordReviewInfo: ReviewRecordInfo): ReviewStatus {
        if (recordReviewInfo.verifiedAnswer) {
            return ReviewStatus.VALIDATED;
        } else if (recordReviewInfo.regions.some(region => region.conflicting)) {
            return ReviewStatus.CONFLICTING;
        }
        
        return ReviewStatus.CONSENSUS;
    }
}
