import { Observable, ReplaySubject } from 'rxjs';
import { DeephubImagesDataService, PredictionMLTask, SerializedMemTableV2, SerializedTableChunk } from "src/generated-sources";
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';
import { resolveSmartName } from '@utils/loc';
import { ColumnMetadata, ImportantColumnsMetadataWithAnnotation, SampleDataFetcherService } from '@shared/services/item-feed/image-feed/sample-data-fetcher.service';
import { SampleDataFormatterService } from '@shared/services/item-feed/image-feed/sample-data-formatter.service';

interface MLTaskInfo {
    projectKey: string,
    analysisId: string,
    mlTask: PredictionMLTask.DeepHubPredictionMLTask
}

@UntilDestroy()
@Injectable()
export class DeephubDesignDataFetcherService extends SampleDataFetcherService {
    filters: DeephubImagesDataService.DeepHubDesignFilterRequest;
    analysisId: string;
    projectKey: string;
    mlTask: PredictionMLTask.DeepHubPredictionMLTask;

    private mlTaskInfo$ = new ReplaySubject<MLTaskInfo>(1);

    constructor(private DataikuAPI: DataikuAPIService, dataFormatterService: SampleDataFormatterService) {
        super(dataFormatterService);
        this.mlTaskInfo$.pipe(untilDestroyed(this)).subscribe(mlTaskInfo => {
            this.mlTask = mlTaskInfo.mlTask;
            this.projectKey = mlTaskInfo.projectKey;
            this.analysisId = mlTaskInfo.analysisId;
            this.managedFolderLoc = resolveSmartName(this.projectKey, this.mlTask.managedFolderSmartId);
            this.refetchData();
        });
    }

    setMLTaskInfo(projectKey: string, analysisId: string, mlTask: PredictionMLTask.DeepHubPredictionMLTask) {
        this.mlTaskInfo$.next({ projectKey, analysisId, mlTask });
    }

    refreshSample(): Observable<SerializedMemTableV2> {
        return this.DataikuAPI.analysis.refreshPMLImagesDataSample(this.projectKey, this.analysisId, this.mlTask.id, this.CHUNK_SIZE ,this.filters);
    }

    getSampleChunk(firstRow: number, nbRows: number): Observable<SerializedTableChunk> {
        return this.DataikuAPI.analysis.getPMLImagesDataChunk(this.projectKey, this.analysisId, this.mlTask.id, firstRow, nbRows, this.filters);
    }

    buildImportantColumnsMetadata(): ImportantColumnsMetadataWithAnnotation<ColumnMetadata> {
        return {
            single: {
                annotation: {
                    name: this.mlTask.targetVariable
                },
                itemPath: {
                    name: this.mlTask.pathColumn
                }
            }
        }
    }

    setFilteredCategories(filteredCategories: string[]) {
        this.filters = {
            targetCategories: filteredCategories
        };
        this.refetchData();
    }
}
