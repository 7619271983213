import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextOverflowTooltipDirective } from './text-overflow-tooltip.directive';



@NgModule({
  declarations: [
    TextOverflowTooltipDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TextOverflowTooltipDirective
  ]
})
export class TextOverflowTooltipModule { }
