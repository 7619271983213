import { UIAnnotation, UIBoundingBox, UIClassificationAnnotation, UINamedEntity } from './../models/annotation';
import { UILabel, ObjectDetectionUILabel, ImageClassificationUILabel, NamedEntityExtractionUILabel } from './../models/label';
import { Injectable } from "@angular/core";
import { BaseLabelingAnswer, Label, LabelingAnswer, LabelingTask } from "src/generated-sources";

@Injectable()
export class AnnotationFactory {
    private labelingTaskType: LabelingTask.LabelingTaskType;

    init(labelingTaskType: LabelingTask.LabelingTaskType) {
        this.labelingTaskType = labelingTaskType;
    }

    createEmptyAnnotation(): UIAnnotation {
        switch(this.labelingTaskType) {
            case LabelingTask.LabelingTaskType.IMAGE_CLASSIFICATION:
                return new UIClassificationAnnotation();
            default:
                throw('Not supported for ' + this.labelingTaskType);
        }
    }

    fromAnnotations(annotations: UIAnnotation[]): UILabel {
        switch(this.labelingTaskType) {
            case LabelingTask.LabelingTaskType.OBJECT_DETECTION:
                return new ObjectDetectionUILabel(annotations as UIBoundingBox[]);
            case LabelingTask.LabelingTaskType.IMAGE_CLASSIFICATION:
                return new ImageClassificationUILabel(annotations as UIClassificationAnnotation[]);
            case LabelingTask.LabelingTaskType.NAMED_ENTITY_EXTRACTION:
                return new NamedEntityExtractionUILabel(annotations as UINamedEntity[]);
            default:
                throw('Not supported for ' + this.labelingTaskType);
        }
    }

    fromAnswer(answer: BaseLabelingAnswer | null): UILabel {
        return this.fromAnswers(answer ? [answer] : []);
    }

    fromAnswers(answers: BaseLabelingAnswer[]): UILabel {
        const annotations = answers.map(this.fromAnswerToAnnotation, this).flat();
        
        switch(this.labelingTaskType) {
            case LabelingTask.LabelingTaskType.OBJECT_DETECTION:
                return new ObjectDetectionUILabel(annotations as UIBoundingBox[]);
            case LabelingTask.LabelingTaskType.IMAGE_CLASSIFICATION:
                return new ImageClassificationUILabel(annotations as UIClassificationAnnotation[]);
            case LabelingTask.LabelingTaskType.NAMED_ENTITY_EXTRACTION:
                return new NamedEntityExtractionUILabel(annotations as UINamedEntity[]);
            default:
                throw('Not supported for ' + this.labelingTaskType);
        }
    }

    private isLabelingAnswer(answer: BaseLabelingAnswer): answer is LabelingAnswer {
        return (answer as LabelingAnswer).annotatorId !== undefined;
    }

    fromAnswerToAnnotation(answer: BaseLabelingAnswer | null): UIAnnotation[] {
        if (!answer) {
            return [];
        }

        switch(this.labelingTaskType) {
            case LabelingTask.LabelingTaskType.OBJECT_DETECTION:
                return (answer.label as Label.ObjectDetectionLabel).annotations.map((annotation: UIBoundingBox) => {
                    let annotator = undefined;
                    if (this.isLabelingAnswer(answer)) {
                        annotator = answer.annotatorId;
                    }
                    return new UIBoundingBox({
                        category: annotation.category,
                        annotator,
                        pinned: false,
                        top: annotation.bbox.y0,
                        left: annotation.bbox.x0,
                        width: annotation.bbox.width,
                        height: annotation.bbox.height,
                    });
                });
            case LabelingTask.LabelingTaskType.IMAGE_CLASSIFICATION:
                return (answer.label as Label.ImageClassificationLabel).annotations.map((label) => {
                    return new UIClassificationAnnotation(label.category)
                });
            case LabelingTask.LabelingTaskType.NAMED_ENTITY_EXTRACTION:
                return (answer.label as Label.NamedEntityExtractionLabel).annotations.map(annotation => {
                    let annotator = undefined;
                    if (this.isLabelingAnswer(answer)) {
                        annotator = answer.annotatorId;
                    }
                    return new UINamedEntity({
                        category: annotation.category,
                        annotator,
                        beginningIndex: annotation.beginningIndex,
                        endIndex: annotation.endIndex,
                        labelWidth: annotation.labelWidth,
                        text: annotation.text
                    })
                });
            default:
                throw('Not supported for ' + this.labelingTaskType);
        }
    }
}