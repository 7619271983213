import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'right-panel-header',
    templateUrl: './right-panel-header.component.html',
    styleUrls: ['./right-panel-header.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightPanelHeaderComponent {
    @Input() objectType: string;
    @Input() icon: string;

    constructor() { }
}
