/**
 * Generated from com.dataiku.dip.analysis.model.prediction.TimestepParams
 */
export interface TimestepParams {
    endOfWeekDay: number;
    numberOfTimeunits: number;
    timeunit: TimestepParams.Timeunit;
}

export namespace TimestepParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.TimestepParams$Timeunit
     */
    export enum Timeunit {
        MILLISECOND = 'MILLISECOND',
        SECOND = 'SECOND',
        MINUTE = 'MINUTE',
        HOUR = 'HOUR',
        DAY = 'DAY',
        BUSINESS_DAY = 'BUSINESS_DAY',
        WEEK = 'WEEK',
        MONTH = 'MONTH',
        QUARTER = 'QUARTER',
        HALF_YEAR = 'HALF_YEAR',
        YEAR = 'YEAR'
    }
}