import type {Abstract2SampTestCard, _Abstract2SampTestCard} from './common/abstract2-samp-test-card';
import type {CardResult} from './card-result';
import type {Card} from './card';
import type {EmpiricalCDF} from './../../compute/computations/univariate/empirical-cdf';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.KsTest2SampCard
 */
export interface KsTest2SampCard extends _Abstract2SampTestCard {
    // PolyJson type
    type: 'ks_test_2samp';
}

export namespace KsTest2SampCard {
    export const type = 'ks_test_2samp';
}

/** @deprecated Use Card.isKsTest2SampCard() */ export function isKsTest2SampCard(toTest: Card): toTest is KsTest2SampCard { return KsTest2SampCard.type === toTest['type']; }

export namespace KsTest2SampCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.KsTest2SampCard$KsTest2SampCardResult
     */
    export interface KsTest2SampCardResult extends Abstract2SampTestCard._Abstract2SampTestCardResult {
        cdf1: EmpiricalCDF.EmpiricalCDFResult;
        cdf2: EmpiricalCDF.EmpiricalCDFResult;
        pvalue: number;
        statistic: number;

        // PolyJson type
        type: 'ks_test_2samp';
    }

    export namespace KsTest2SampCardResult {
        export const type = 'ks_test_2samp';
    }

    /** @deprecated Use CardResult.isKsTest2SampCardResult() */ export function isKsTest2SampCardResult(toTest: CardResult): toTest is KsTest2SampCardResult { return KsTest2SampCardResult.type === toTest['type']; }
}