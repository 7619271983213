import { Injectable } from '@angular/core';
import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';

@Injectable({
    providedIn: 'root'
})
export class RegressionLineOptionsService {

    public labelPositionOptions = [{
        id: ChartDef.RegressionLinePosition.INSIDE_START,
        name: 'Left'
    },
    {
        id: ChartDef.RegressionLinePosition.INSIDE_END,
        name: 'Right'
    }];

    public colors = (window as any).dkuColorPalettes.discrete[1].colors;

    public regressionTypes = [{
        id: ChartDef.RegressionType.LINEAR,
        name: 'Linear'
    },
    {
        id: ChartDef.RegressionType.POLYNOMIAL,
        name: 'Polynomial'
    },
    {
        id: ChartDef.RegressionType.LOGARITHMIC,
        name: 'Logarithmic'
    },
    {
        id: ChartDef.RegressionType.EXPONENTIAL,
        name: 'Exponential'
    },
    ];
}
