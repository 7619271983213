import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { convertFilterToSeriesIdentifiers, noFilterIfAll } from '@features/eda/card-utils';
import { CollapsibleGroup, CollapsingService, UpdatableCollapsingService } from '@features/eda/collapsing.service';
import { filterName } from '@features/eda/pipes/filter-name.pipe';
import { CardBodyRenderingMode } from '@features/eda/worksheet/cards/body/rendering-mode';
import { CardAction, CardActionType } from '@features/eda/worksheet/cards/events';
import { fadeInOutHeight } from '@shared/animations/fade-in-out';
import { combineLatest, EMPTY, Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AnumFilter, Card, CardResult, Filter, GroupedCompiledCardWrapper } from 'src/generated-sources';

interface SingleTestData {
    confidenceLevel: number
    pValue: number
}

@Component({
    selector: 'grouped-card-body',
    templateUrl: './grouped-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/menu-button.less',
        './grouped-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeInOutHeight]
})
export class GroupedCardBodyComponent implements OnChanges {
    @Input() results: GroupedCompiledCardWrapper.GroupedCardResult;
    @Input() params: Card;
    @Input() renderingMode: CardBodyRenderingMode;
    @Input() readOnly: boolean;
    @Input() extendedActions: boolean;
    @Input() hasFixedHeight: boolean;
    @Output() action = new EventEmitter<CardAction>();
    private params$ = new ReplaySubject<Card>(1);
    private results$ = new ReplaySubject<GroupedCompiledCardWrapper.GroupedCardResult>(1);
    groupCollapseStates$: Observable<boolean[]>;
    CardActionType = CardActionType;

    showCollapsingControls: boolean;

    constructor(private collapsingService: CollapsingService) {
        this.groupCollapseStates$ = combineLatest([this.params$, this.results$]).pipe(
            switchMap(([params, results]) => {
                if (results.type === 'groups') {
                    return combineLatest(
                        results.groups
                            .map(group => new CollapsibleGroup(params.id, group))
                            .map(collapsible => this.collapsingService.watchIsCollapsed(collapsible))
                    );
                }
                return EMPTY;
            })
        );
        this.showCollapsingControls = this.collapsingService instanceof UpdatableCollapsingService;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.params$.next(this.params);
        }
        if (changes.results) {
            this.results$.next(this.results);
        }
    }

    toggleGroup(group: Filter, newIsCollapsed: boolean) {
        this.collapsingService.setIsCollapsed(
            new CollapsibleGroup(this.params.id, group),
            newIsCollapsed
        );
    }

    trackByIndex(index: number) {
        return index;
    }

    /**
     * Export a part of this header as another header card, by creating a new
     * filtered header card. Special case for time series, where we rather
     * adjust the long format filters.
     */
    exportGroup(groupIndex: number, type: CardActionType.ADD | CardActionType.DEBUG | CardActionType.PUBLISH) {
        let card: Card;
        const group = this.results.groups[groupIndex];

        if (Card.isTimeSeriesCard(this.params)) {
            card = {
                ...this.params,
                filter: null,
                splitBy: null,
                seriesIdentifiers: convertFilterToSeriesIdentifiers(group),
            };
        } else {
            card = {
                ...this.params,
                splitBy: null,
                filter: noFilterIfAll(group),
            };
        }

        this.action.emit({ type, card });
    }

    handleAction(action: CardAction, groupIndex: number) {
        if (action.type === CardActionType.HIGHLIGHT) {
            if (action.filter) {
                const groupFilter = {
                    ...this.results.groups[groupIndex],
                    name: this.params.splitBy!.groupingColumn.name
                        + ': ' + filterName(this.results.groups[groupIndex])
                };
                action = {
                    ...action, filter: noFilterIfAll({
                        type: 'and',
                        filters: [action.filter, groupFilter]
                    })
                };
            }
            this.action.emit(action);
        }
    }

    asSingleTestData(groupResult: CardResult): SingleTestData | null {
        if (
            Card.isTZTest1SampCard(this.params)
            || Card.isSignTest1SampCard(this.params)
            || Card.isShapiroNormalityTestCard(this.params)
            || Card.isAbstract2SampTestCard(this.params)
            || Card.isOneWayANOVACard(this.params)
            || Card.isMoodTestNSampCard(this.params)
            || Card.isChi2IndTestCard(this.params)
        ) {
            if (this.params.type === groupResult.type) {
                return {
                    confidenceLevel: this.params.confidenceLevel,
                    pValue: groupResult.pvalue,
                };
            }
        }

        if (
            Card.isUnitRootTestCard(this.params)
            || Card.isMannKendallTestCard(this.params)
        ) {
            if (this.params.type === groupResult.type) {
                return {
                    confidenceLevel: this.params.confidenceLevel,
                    pValue: groupResult.pValue,
                }
            }
        }

        return null;
    }

    get fitParentHeight(): boolean {
        return !(this.hasFixedHeight || this.results.groups.length > 1);
    }
}
