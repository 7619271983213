import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { PainterImage } from "@shared/models/painter";
import { ColorMapContextService } from "@shared/services/color-map-context.service";
import { DeephubImageClassificationReportCellData } from "../services/deephub-image-classification-report-data-formatter.service";


class FormattedPrediction {
    category?: string;
    proba: number;
    title: string;
    color: string | undefined;
    others: boolean;
}

@UntilDestroy()
@Component({
    selector: 'deephub-image-classification-prediction-widget',
    templateUrl: './deephub-image-classification-prediction-widget.component.html',
    styleUrls: [
        './deephub-image-classification-prediction-widget.component.less',
        '../shared-styles/deephub-image-classification-legend.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeephubImageClassificationReportPredictionWidgetComponent implements OnChanges {

    static readonly NUM_SHOWN_CLASSES = 7;
    @Input() classifData: DeephubImageClassificationReportCellData;
    @Input() target?: string;
    @Input() painterImage: PainterImage;
    @Input() selectableCategories: boolean = false;

    @Output() toggleSelectedCategory = new EventEmitter<{
        category: string,
        active: boolean
    }>();
    @Output() togglePinnedCategory = new EventEmitter<{
        category: string
    }>();
    formattedPredictions: FormattedPrediction[];

    constructor(private colorMapService: ColorMapContextService) {
    }

    ngOnChanges(changes: SimpleChanges): void {

        this.formattedPredictions = this.formatPredictions(this.classifData.probabilities);
    }

    private getCategoryName (prefixedCategory: string): string {
        return prefixedCategory.replace('proba_', '');
    }

    private formatTitle(probas: [string, number][]): string {
        return probas.map(proba => `${this.getCategoryName(proba[0])} (${(proba[1]*100).toFixed(1)}%)`).join("\n");
    }

    getCategoryColor(category: string): string | undefined {
        return this.colorMapService.getColor(category);
    }

    formatPredictions(probabilities: Map<string, number>): FormattedPrediction[] {   
        const sortedProbas = Array.from(probabilities).sort((a, b) => (b[1] - a[1]));
        const formattedPredictions: FormattedPrediction[] = [];
        for (let i = 0; i < Math.min(DeephubImageClassificationReportPredictionWidgetComponent.NUM_SHOWN_CLASSES, sortedProbas.length); i++) {
            const catName = this.getCategoryName(sortedProbas[i][0]);
            formattedPredictions.push({category: catName,
                                       proba: sortedProbas[i][1],
                                       title: this.formatTitle([sortedProbas[i]]),
                                       others: false,
                                       color: this.getCategoryColor(catName)});
        }
        if (sortedProbas.length > DeephubImageClassificationReportPredictionWidgetComponent.NUM_SHOWN_CLASSES){
            // Aggregate the rest of the categories/probas into a single item.
            const otherProbas = sortedProbas.slice(DeephubImageClassificationReportPredictionWidgetComponent.NUM_SHOWN_CLASSES);
            const otherAggProba = otherProbas.reduce((sumProbas, nextValue) => sumProbas + nextValue[1], 0);
            formattedPredictions.push({proba: otherAggProba,
                                       title: this.formatTitle(otherProbas),
                                       others: true,
                                       color: '#DDDDDD'});
        }
        return formattedPredictions;
    }

}
