import { PivotTableTensorResponse } from '@model-main/pivot/backend/model/pivot-table-tensor-response';
import { AxisHandler } from '@model-main/pivot/backend/dss/axis-handler';
import { extent } from 'd3-array';
import { ChartLabels } from '../enums';
import { ChartDataWrapper } from '../interfaces';

/**
 * A wrapper for easily access the data stored in a PivotTableTensorResponse
 * @param data
 * @param axesDef: a map from axis names to axis idx in the tensor response, axis names can then be used to retrieve the data instead of the idx for better code readability
 */
// This service is mocked in mocks.js to maintain angularjs karma unit tests.
export class ChartTensorDataWrapper implements ChartDataWrapper {
    coords: Record<number, number> = {};
    /** @type {number[]} coord of subtotal label in each dimension. Available when `computeSubTotals` option has been enabled in pivot tensor request. */
    subtotalCoords: Array<number> = [];
    numAxes: number;
    /** @type {boolean} true if tensor has empty bins */
    hasEmptyBins: boolean;

    constructor(public data: PivotTableTensorResponse, public axesDef: Record<string, number> = {}) {
        const axisLabels = data.axisLabels || [];
        this.numAxes = axisLabels.length;
        this.hasEmptyBins = data.counts && data.counts.tensor.includes(0);
        this.subtotalCoords = axisLabels.map((axisLabels: Array<any>) => axisLabels.findIndex(axisLabel => axisLabel.label === ChartLabels.SUBTOTAL_BIN_LABEL));
    }

    hasAnimations(): boolean {
        return this.axesDef && this.axesDef.animation != undefined;
    }

    getFacets(): Array<any> {
        return this.getAxisLabels('facet') || [];
    }

    getAggrExtent(aggrIdx: number): [undefined, undefined] | [number, number] {
        return extent(this.data.aggregations[aggrIdx].tensor);
    }

    getCoordsLoc(tensor: any, coordsArray: Record<number, number>): number {
        let loc = 0;
        for (let i = 0; i < this.numAxes; i++) {
            loc += coordsArray[i] * tensor.multipliers[i];
        }
        return loc;
    }

    getPoint(tensor: any, coordsArray: Record<number, number>): any {
        return tensor.tensor[this.getCoordsLoc(tensor, coordsArray)];
    }

    getCoordsArray(coordsDict: Record<string, number>): Record<number, number> {
        for (const axisName in coordsDict) {
            this.coords[this.axesDef[axisName]] = coordsDict[axisName];
        }
        return this.coords;
    }

    getAggrPoint(aggrIdx: number, coordsDict: Record<string, number>): any {
        return this.getPoint(this.data.aggregations[aggrIdx], this.getCoordsArray(coordsDict));
    }

    aggr(aggrIdx: number): any {
        return {
            get: (coordsDict: Record<string, number>) => {
                return this.getAggrPoint(aggrIdx, coordsDict);
            },

            getAxisValue: (axisName: string, axisCoord: number) => {
                return this.data.aggregations[aggrIdx].axes[this.axesDef[axisName]][axisCoord];
            }
        };
    }

    getCount(coordsDict: Record<string, number>): number {
        return this.getPoint(this.data.counts, this.getCoordsArray(coordsDict));
    }

    getNonNullCount(coordsDict: Record<string, number>, aggrIdx: number): number {
        if (this.data.aggregations[aggrIdx].nonNullCounts) {
            return this.data.aggregations[aggrIdx].nonNullCounts[this.getCoordsLoc(this.data.aggregations[aggrIdx], this.getCoordsArray(coordsDict))];
        } else {
            // When the aggregation has no null value, nonNullCounts isn't sent because nonNullCounts == counts
            return this.getCount(coordsDict);
        }
    }

    getSubtotal(tensor: any, coordsArray: Array<number>): number {
        return tensor.tensor[this.getCoordsLoc(tensor, coordsArray)];
    }

    hasSubtotalCoords(): boolean {
        return this.aggr.length === 1 || this.subtotalCoords && this.subtotalCoords.length > 1 && this.subtotalCoords[0] !== -1;
    }

    getSubTotalCoordsArray(coordsDict: Record<string, number>): Array<number> {
        if (!this.hasSubtotalCoords()) {
            throw new Error('Option `computeSubTotals` must be enabled to fetch subtotals in pivot tensor request');
        }

        const coords: Array<number> = [];

        for (const axisName in this.axesDef) {
            if (coordsDict[axisName] !== undefined) {
                coords[this.axesDef[axisName]] = coordsDict[axisName];
            } else {
                coords[this.axesDef[axisName]] = this.subtotalCoords[this.axesDef[axisName]];
            }
        }

        return coords;
    }

    /**
     * Returns subtotal corresponding to coordinates passed in coordDict, where missing dimensions will be replaced by the subtotal bin.
     * This function is available when `computeSubTotals` option has been enabled in pivot tensor request.
     * @param {number} aggrIdx
     * @param {Record<string, number>} coordsDict
     * @returns {number}
     */
    getSubtotalPoint(aggrIdx: number, coordsDict: Record<string, number>): number {
        const aggregation = this.data.aggregations[aggrIdx];
        return this.getSubtotal(aggregation, this.getSubTotalCoordsArray(coordsDict));
    }

    /**
     * Returns dimension grand total.
     * This function is available when `computeSubTotals` option has been enabled in pivot tensor request.
     * @param {number} aggrIdx
     * @returns {number}
     */
    getGrandTotalPoint(aggrIdx: number): number {
        const aggregation = this.data.aggregations[aggrIdx];
        return this.getSubtotal(aggregation, this.getSubTotalCoordsArray({}));
    }

    getAxisLabels(axisName: string): Array<any> | undefined {
        return this.data.axisLabels[this.axesDef[axisName]];
    }

    areLabelsRegroupedDates(axisLabels: Array<any>): boolean {
        const tsValues = axisLabels.map(label => label?.tsValue);
        return [...new Set(tsValues)].length <= 1 && tsValues[0] === 0;
    }

    purgeTsValue(axisLabels: Array<any>): Array<any> {
        return axisLabels.map(label => ({ ...label, tsValue: -1 }));
    }

    getSubtotalLabelIndex(axisName: string): number {
        return this.subtotalCoords[this.axesDef[axisName]];
    }

    getMinValue(axisName: string): number | null {
        const axisLabels = this.getAxisLabels(axisName);
        if (!axisLabels || !axisLabels.length) {
            return null;
        }
        const { min, max, sortValue } = axisLabels[0];
        return min === max ? sortValue : min;
    }

    getMaxValue(axisName: string): number | null {
        const axisLabels = this.getAxisLabels(axisName);
        if (!axisLabels || !axisLabels.length) {
            return null;
        }
        const { min, max, sortValue } = axisLabels[axisLabels.length - 1];
        return min === max ? sortValue : max;
    }

    getNumValues(axisName: string): number | null {
        const axisLabels = this.getAxisLabels(axisName);
        if (!axisLabels || !axisLabels.length) {
            return null;
        }
        return axisLabels.length;
    }

    getAxisDef(axisName: string): any {
        return this.data.axisDefs[this.axesDef[axisName]];
    }

    getLabels(): Array<AxisHandler.AxisElt[]> {
        return this.data.axisLabels;
    }

    getAxisIdx(axisName: string): number {
        return this.axesDef[axisName];
    }

    fixAxis(axisName: string, binIdx: number): ChartTensorDataWrapper {
        this.coords[this.axesDef[axisName]] = binIdx;
        return this;
    }

    getCurrentCoord(axisName: string): number {
        return this.coords[this.axesDef[axisName]];
    }
}
