<p>
    The Augmented Dickey-Fuller test tests the hypothesis that there exists a
    unit root in <b>{{ params.seriesColumn.name }}</b>, with
    the alternative hypothesis that there is no unit root.
</p>

<p>
    The hypothesis is tested in the context of serial correlation.

    Serial correlation occurs in a series when a series value and a lagged
    version of itself (for instance the values at times T and at T-1) are
    observed to be correlated with one another over periods of time.

    Serial correlation can be checked either through
    <span *ngIf="!canAddCard">
        autocorrelation visual inspection
    </span>
    <a *ngIf="canAddCard" (click)="addACFPlotCard()">
        autocorrelation visual inspection
    </a>
    or by using the
    <span *ngIf="!canAddCard">
        Durbin-Watson statistic
    </span>
    <a *ngIf="canAddCard" (click)="addDurbinWatsonCard()">
        Durbin-Watson statistic
    </a>.
</p>

<p>
    There are two possible outcomes depending on the 𝘱-value:
</p>

<ul>
    <li>
        <b>
            𝘱-value &le; significance level (&le; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>

        <br>
        The hypothesis is rejected.
        The test rejects the presence of a unit root in <b>{{ params.seriesColumn.name }}</b>.
        It indicates that the series may be {{ stationarityLabel }}.
    </li>

    <li>
        <b>
            𝘱-value is &gt; significance level (&gt; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>

        <br>
        Not enough evidence against the hypothesis.
        The test is inconclusive about whether there exists a unit root in <b>{{ params.seriesColumn.name }}</b>.
    </li>
</ul>
