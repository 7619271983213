import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { ShapiroNormalityTestCard, Variable } from 'src/generated-sources';
import { FormBuilder, Validators } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Subject, Observable } from 'rxjs';
import { CardWizardVariable } from '@features/eda/card-models';
import { CardWizardService } from '../../../card-wizard/card-wizard.service';

@UntilDestroy()
@Component({
  selector: 'shapiro-normality-test-card-config',
  templateUrl: './shapiro-normality-test-card-config.component.html',
  styleUrls: ['./shapiro-normality-test-card-config.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShapiroNormalityTestCardConfigComponent implements OnInit, OnChanges, OnDestroy {
    @Input() params: ShapiroNormalityTestCard;
    @Output() paramsChange = new EventEmitter<ShapiroNormalityTestCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    allVariables$: Observable<CardWizardVariable[]>;

    configForm = this.fb.group({
        column: this.fb.control(null, [Validators.required])
    });

    constructor(
        private fb: FormBuilder,
        private cardWizardService: CardWizardService
    ) {
        this.configForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((formValue) => this.paramsChange.emit({
                ...this.params,
                column: formValue.column
            }));

        this.configForm.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => this.validityChange.emit(this.configForm.valid));
    }

    ngOnInit() {
        this.allVariables$ = this.cardWizardService.availableVariables(this.params.type);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.configForm.patchValue({
                column: this.params.column
            });
        }
    }

    ngOnDestroy() {

    }
}
