<div>
    
    <modal-header [title]="data.title" [icon]="data.icon"></modal-header>
    
    <div class="modal-body">
        <p *ngIf="data.message" [innerHtml]="data.message"></p>
        <div class="selectable-items-list">
            <div *ngFor="let item of data.items" class="selectable-item selectable" [class.selected]="item === selectedItem" (click)="selectItem(item)">
                <div class="selection-indicator">
                    <i class="icon-ok" title="active" *ngIf="item === selectedItem"></i>
                </div>
                <p style="margin: 0px 10px 0px 10px" class="ng-binding">
                    <strong class="ng-binding">{{item.title}} {{item.desc ? "&ndash;": ""}} </strong>
                    {{item.desc}}
                </p>
            </div>
        </div>
    </div>
    
    <div class="modal-footer modal-footer-std-buttons">
        <div class="pull-right">
            <button type="button" class="btn btn-primary" (click)="confirm()" autofocus>Ok</button>
        </div>
    </div>
    
</div>
