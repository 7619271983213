import { Injectable } from '@angular/core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { switchMap, shareReplay } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ProjectsService as GeneratedProjectsServiceSources } from 'src/generated-sources';

@Injectable({
    providedIn: 'root'
})
export class ProjectsService {

    public currentProjectSummary$ = this.currentRoute.projectKey$.pipe(
        switchMap(projectKey => projectKey ?
            this.DataikuAPI.projects.getSummary(projectKey)
            : of(undefined)
        ),
        shareReplay(1)
    );

    constructor(
        private currentRoute: CurrentRouteService,
        private DataikuAPI: DataikuAPIService
    ) { }

    list(): Observable<GeneratedProjectsServiceSources.UIProject[]> {
        return this.DataikuAPI.projects.list();
    }
}
