import { Inject, Injectable } from '@angular/core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeephubImagesDataService } from 'src/generated-sources';
import { DeephubObjectDetectionReportService, ObjectDetectionReport } from './deephub-object-detection-report.service';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { DeephubObjectDetectionReportPainterService } from './deephub-object-detection-report-painter.service';
import { AbstractDeephubReportDataFetcherService } from '@features/deephub/computer-vision/report/services/abstract-deephub-report-data-fetcher.service';
import { SampleDataFormatterService } from '@shared/services/item-feed/image-feed/sample-data-formatter.service';
import { ColumnMetadata } from '@shared/services/item-feed/image-feed/sample-data-fetcher.service';


export type DeephubObjectDetectionResultsImportantColumnsMetadata<T extends ColumnMetadata> = {
    single: {
        itemPath: T;
        annotation: T;
        prediction: T;
        pairing: T;
        enrichedValid: T;
        enrichedFiltered: T;
    }
}

@UntilDestroy()
@Injectable()
export class DeephubObjectDetectionReportDataFetcherService extends AbstractDeephubReportDataFetcherService<ObjectDetectionReport, DeephubImagesDataService.ObjectDetectionPredictedFilter> {
    canvasPainter: DeephubObjectDetectionReportPainterService;

    constructor(
        DataikuAPI: DataikuAPIService,
        objectDetectionService: DeephubObjectDetectionReportService,
        canvasPainter: DeephubObjectDetectionReportPainterService,
        currentRoute: CurrentRouteService,
        colorMapService: ColorMapContextService,
        dataFormatter: SampleDataFormatterService,
        @Inject('COLOR_PALETTES') COLOR_PALETTES: { [palette: string]: string[]; }
    ) {
        super(DataikuAPI, objectDetectionService, currentRoute, colorMapService, dataFormatter, COLOR_PALETTES);
        this.canvasPainter = canvasPainter;

        this.filter$.pipe(
            untilDestroyed(this)
        ).subscribe(filter => {
            this.canvasPainter.setClasses(filter?.groundTruth?.value, filter?.detection?.value);
        });
    }

    getDefaultFilter(): Partial<DeephubImagesDataService.ObjectDetectionPredictedFilter> {
        return { sorting: {sortBy: DeephubImagesDataService.SortBy.IOU, ascending: false}};
    }

    combineReportFilter(report: ObjectDetectionReport, filter: DeephubImagesDataService.ObjectDetectionPredictedFilter): DeephubImagesDataService.ObjectDetectionPredictedFilter {
        filter.minIOU = report.iou;
        filter.minConfidence = report.confidenceThreshold!;
        filter.type = "DEEP_HUB_IMAGE_OBJECT_DETECTION";
        return filter;
    }

    buildImportantColumnsMetadata(): DeephubObjectDetectionResultsImportantColumnsMetadata<ColumnMetadata> {
        return  {
            single: {
                annotation: {
                    name: this.coreParams.target_variable
                },
                itemPath: {
                    name: this.coreParams.pathColumn
                },
                prediction: {
                    name: 'prediction',
                },
                pairing: {
                    name: 'pairing',
                },
                enrichedValid: {
                    name: 'enrichedValid',
                },
                enrichedFiltered: {
                    name: 'enrichedFiltered'
                }
            }
        }
    }
}
