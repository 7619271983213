<div class="card-layout">
    <div class="card-layout__section card-layout__section--horizontal">
        <div class="card-layout__section">
            <div class="card-layout__title">
                Computation
            </div>
            <div class="card-layout__content">
                <textarea [readonly]="readOnly" [(ngModel)]="rawComputation">
                </textarea>
                <button *ngIf="!readOnly" class="btn btn--secondary" (click)="run()">Run</button>
            </div>
        </div>
        <div class="card-layout__section">
            <div class="card-layout__title">Result</div>
            <div class="card-layout__content">
                <textarea readonly [ngModel]="results.errorMessage || results.rawResult">
                </textarea>
            </div>
        </div>
    </div>
</div>