import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.bivariate.Covariance
 */
export interface Covariance extends Computation._BivariateComputation {
    // PolyJson type
    type: 'covariance';
}

export namespace Covariance {
    export const type = 'covariance';
}

/** @deprecated Use Computation.isCovariance() */ export function isCovariance(toTest: Computation): toTest is Covariance { return Covariance.type === toTest['type']; }

export namespace Covariance {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.bivariate.Covariance$CovarianceResult
     */
    export interface CovarianceResult extends _AvailableResult {
        value: number;

        // PolyJson type
        type: 'covariance';
    }

    export namespace CovarianceResult {
        export const type = 'covariance';
    }

    /** @deprecated Use ComputationResult.isCovarianceResult() */ export function isCovarianceResult(toTest: ComputationResult): toTest is CovarianceResult { return CovarianceResult.type === toTest['type']; }
}