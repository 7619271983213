import { Injectable } from '@angular/core';
import { ImagePositionInformation, PainterImage, DivPainterImageArtifact } from '@shared/models/painter';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { PainterService } from '@shared/services/item-feed/image-feed/painter.service';
import { BoxHelper } from '@features/deephub/object-detection/utils/box-helper';
import { DeepHubColumnFormat } from 'src/generated-sources';
import { fabric } from "fabric";
import { ObjectDetectionDesignCellData } from './object-detection-data-formatter.service';

@Injectable()
export class ObjectDetectionPainterService extends PainterService {
    constructor(
        private colorMapService: ColorMapContextService
    ) {
        super();
    }

    paintForModal(cellData: ObjectDetectionDesignCellData, canvas: fabric.StaticCanvas, imagePosition: ImagePositionInformation): void {
        if (!cellData.invalid) {
            cellData.target?.forEach((elem: DeepHubColumnFormat.ObjectDetectionTargetItem) => {
                const box = BoxHelper.createBox(fabric.Rect, elem, imagePosition, {
                    stroke: this.colorMapService.getColor(elem.category),
                    strokeWidth: 2,
                    fill: 'rgba(0, 0, 0, 0)'
                });
                if (box !== null) {
                    canvas.add(box);
                }
            });
        }
    }

    paintForFeed(cellData: ObjectDetectionDesignCellData, image: PainterImage, imagePosition: ImagePositionInformation): void {
        if (!cellData.invalid) {
            cellData.target?.forEach((elem: DeepHubColumnFormat.ObjectDetectionTargetItem) => {
                const box = BoxHelper.createBox(DivPainterImageArtifact, elem, imagePosition, {
                    borderColor: this.colorMapService.getColor(elem.category),
                    borderWidth: '2px',
                    borderStyle: 'solid'
                }, 'px');
                if (box !== null) {
                    image.addArtifact(box);
                }
            });
        }
    }
}
