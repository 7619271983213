import type {EventServerTargetSettings} from './event-server-target-settings';
import type {KafkaTargetSettings} from './kafka-target-settings';
import type {Log4JTargetSettings} from './log4-jtarget-settings';

/**
 * Generated from com.dataiku.dip.security.audit.model.AuditTrailTargetSettings
 */
export interface _AuditTrailTargetSettings {
    routingKeys: string[];
    routingKeysFiltering: AuditTrailTargetSettings.RoutingKeyFiltering;
    topics: string[];
    topicsFiltering: AuditTrailTargetSettings.TopicsFiltering;
}

export type AuditTrailTargetSettings = EventServerTargetSettings | KafkaTargetSettings | Log4JTargetSettings;

export namespace AuditTrailTargetSettings {
    export const type = ['EVENT_SERVER', 'KAFKA', 'LOG4J'] as const;
    export function isEventServerTargetSettings(toTest: AuditTrailTargetSettings): toTest is EventServerTargetSettings { return toTest['type'] === 'EVENT_SERVER'; }
    export function isKafkaTargetSettings(toTest: AuditTrailTargetSettings): toTest is KafkaTargetSettings { return toTest['type'] === 'KAFKA'; }
    export function isLog4JTargetSettings(toTest: AuditTrailTargetSettings): toTest is Log4JTargetSettings { return toTest['type'] === 'LOG4J'; }
}

export namespace AuditTrailTargetSettings {
    /**
     * Generated from com.dataiku.dip.security.audit.model.AuditTrailTargetSettings$RoutingKeyFiltering
     */
    export enum RoutingKeyFiltering {
        ALL = 'ALL',
        SELECTED = 'SELECTED'
    }

    /**
     * Generated from com.dataiku.dip.security.audit.model.AuditTrailTargetSettings$TopicsFiltering
     */
    export enum TopicsFiltering {
        ALL = 'ALL',
        SELECTED = 'SELECTED'
    }
}