/**
 * Generated from com.dataiku.dip.pivot.backend.model.NumericalAxisParams
 */
export interface NumericalAxisParams {
    binSize: number;
    emptyBinsMode: NumericalAxisParams.EmptyBinsMode;
    mode: NumericalAxisParams.BinningMode;
    nbBins: number;
    niceBounds: boolean;
}

export namespace NumericalAxisParams {
    /**
     * Generated from com.dataiku.dip.pivot.backend.model.NumericalAxisParams$BinningMode
     */
    export enum BinningMode {
        NONE = 'NONE',
        FIXED_NB = 'FIXED_NB',
        FIXED_SIZE = 'FIXED_SIZE'
    }

    /**
     * Generated from com.dataiku.dip.pivot.backend.model.NumericalAxisParams$EmptyBinsMode
     */
    export enum EmptyBinsMode {
        ZEROS = 'ZEROS',
        DASHED = 'DASHED',
        AVERAGE = 'AVERAGE'
    }
}