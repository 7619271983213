import { rgb } from "d3-color";
import { scaleOrdinal } from "d3-scale";

// Constants copied from d3
export const category20 = scaleOrdinal([
    '#1f77b4', '#aec7e8',
    '#ff7f0e', '#ffbb78',
    '#2ca02c', '#98df8a',
    '#d62728', '#ff9896',
    '#9467bd', '#c5b0d5',
    '#8c564b', '#c49c94',
    '#e377c2', '#f7b6d2',
    '#7f7f7f', '#c7c7c7',
    '#bcbd22', '#dbdb8d',
    '#17becf', '#9edae5'
].map(value => rgb(value)));


export const category20b = scaleOrdinal([
    '#393b79', '#5254a3', '#6b6ecf', '#9c9ede',
    '#637939', '#8ca252', '#b5cf6b', '#cedb9c',
    '#8c6d31', '#bd9e39', '#e7ba52', '#e7cb94',
    '#843c39', '#ad494a', '#d6616b', '#e7969c',
    '#7b4173', '#a55194', '#ce6dbd', '#de9ed6'
].map(value => rgb(value)));

