<dku-bs-select
    [params]="params" 
    (modelChange)="select($event)" 
    [model]="model" 
    [list]="list" 
    [layout]="layout" 
    [ngOptions]="ngOptions" 
    [required]="required" 
    [optionsDescriptions]="optionsDescriptions"
    [dataActionsBox]="dataActionsBox" 
    [dataLiveSearch]="dataLiveSearch"
    [dkuMultiple]="dkuMultiple"
></dku-bs-select>