import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StandardizedSidePanelDirective } from './standardized-side-panel.directive';

@NgModule({
  declarations: [
    StandardizedSidePanelDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    StandardizedSidePanelDirective
  ]
})
export class StandardizedSidePanelModule { }
