import { Injectable } from "@angular/core";
import { LabelingService } from "@features/labeling/services/labeling.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject, switchMap, tap, withLatestFrom } from "rxjs";
import { LabelingReviewAnnotationGroupService } from "./labeling-review-annotation-group.service";
import { LabelingReviewService, ReviewStatus } from "./labeling-review.service";
import { LabelingReviewUpdateService } from "./labeling-review-update.service";
import { ItemsDataFetcherService } from "@shared/services/item-feed/items-data-fetcher.service";
import { WT1Service } from "dku-frontend-core";

@UntilDestroy()
@Injectable()
export class LabelingValidateService {

    validateTrigger$ = new Subject<void>();
    rejectionTrigger$ = new Subject<void>();

    constructor(
        private labelingReviewService: LabelingReviewService,
        private labelingReviewUpdateService: LabelingReviewUpdateService, 
        private labelingReviewAnnotationGroupService: LabelingReviewAnnotationGroupService,
        private labelingService: LabelingService,
        private dataFetcher: ItemsDataFetcherService,
        private WT1Service: WT1Service
    ){  

        this.rejectionTrigger$.pipe(
            untilDestroyed(this),
            withLatestFrom(
                this.labelingReviewService.currentId$,
                this.labelingReviewService.currentReviewRecord$,
                this.labelingService.labelingTaskInfo$),
            tap(([, id, currentReviewRecord, task]) => {
                this.WT1Service.event("labeling-reject-record", {
                    recordId: this.WT1Service.md5(id),
                    taskType: task.type,
                    statusPriorRejection: currentReviewRecord.status,
                })
            }),
            switchMap(([, id]) => this.labelingService.deleteAnswersForRecordId(id)),
            withLatestFrom(this.labelingReviewService.currentIndex$),
        ).subscribe(([_, index]) => {
            this.dataFetcher.updateItem(index, {
                verifiedAnswer: null,
                status: ReviewStatus.REJECTED
            });
            this.labelingReviewService.nextItem();
        });

        this.validateTrigger$.pipe(
            withLatestFrom(
                this.labelingReviewUpdateService.updatingReview$,
                this.labelingReviewUpdateService.currentReview$,
                this.labelingReviewService.isLast$,
                this.labelingReviewService.currentId$,
                this.labelingReviewAnnotationGroupService.annotationGroupList$,
                this.labelingService.labelingTaskInfo$,
                this.labelingReviewService.currentReviewRecord$,
            ),
            tap(([_trigger, update, currentReview, _isLast, recordId, annotationGroupList, task, reviewRecord]) => {
                this.WT1Service.event("labeling-save-review", {
                    recordId: this.WT1Service.md5(recordId),
                    taskType: task.type,
                    updating: update,
                    statusPriorReview: reviewRecord.status,
                    nbRegionsPriorReview: update ? null : reviewRecord.regions.length,
                    nbAnnotationsAfterSave: update ? currentReview.annotations.length : annotationGroupList.length,
                });
            }),
            switchMap(([_trigger, update, currentReview, isLast, path, annotationGroupList]) => {
                if (isLast) {
                    this.labelingReviewService.finishedReview$.next(true);
                }

                if (update) {
                    return this.labelingService.resolveRecordFromAnnotation(currentReview, path!);
                }

                return this.labelingService.resolveRecordFromAnnotationGroupList(annotationGroupList, path!);
            }),
            withLatestFrom(this.labelingReviewService.currentIndex$),
            untilDestroyed(this),
        ).subscribe(([verifiedAnswer, index]) => {
            this.dataFetcher.updateItem(index, {
                verifiedAnswer,
                status: ReviewStatus.VALIDATED
            });
            this.labelingReviewService.nextItem();
        });
    }

    validate() {
        this.validateTrigger$.next();
    }

    reject() {
        this.rejectionTrigger$.next();
    }
    
}