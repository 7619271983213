export namespace FutureProgressState {
    /**
     * Generated from com.dataiku.dip.futures.FutureProgressState$StateUnit
     */
    export enum StateUnit {
        SIZE = 'SIZE',
        FILES = 'FILES',
        RECORDS = 'RECORDS',
        NONE = 'NONE'
    }
}

/**
 * Generated from com.dataiku.dip.futures.FutureProgressState
 */
export interface FutureProgressState {
    cur: number;
    depth: number;
    important: boolean;
    msSinceStart: number;
    name: string;
    startTimestamp: number;
    target: number;
    unit: FutureProgressState.StateUnit;
}