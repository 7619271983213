import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'YYYYMMDDHHmmssDateTime'
})
export class YYYYMMDDHHmmssDateTimePipe extends DatePipe implements PipeTransform {
  transform(value: any): any {
    return super.transform(value, "YYYY-MM-dd HH:mm:ss");
  }
}