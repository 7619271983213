import type {ACFPlotCard} from './acfplot-card';
import type {Abstract2DPivotCard} from './bivariate_header/abstract2-dpivot-card';
import type {Abstract2SampTestCard} from './common/abstract2-samp-test-card';
import type {AbstractCardContainer} from './common/abstract-card-container';
import type {AbstractHeaderCard} from './common/abstract-header-card';
import type {AbstractNSampTestCard} from './common/abstract-nsamp-test-card';
import type {AbstractPairwiseTestCard} from './common/abstract-pairwise-test-card';
import type {BivariateBoxPlotCard} from './bivariate_header/bivariate-box-plot-card';
import type {BivariateCard} from './bivariate-card';
import type {BivariateFrequencyTableCard} from './bivariate_header/bivariate-frequency-table-card';
import type {BivariateHeaderCard} from './bivariate_header/bivariate-header-card';
import type {BivariateHistogramCard} from './bivariate_header/bivariate-histogram-card';
import type {BivariateSummaryStatsCard} from './bivariate_header/bivariate-summary-stats-card';
import type {CDFPlotCard} from './cdfplot-card';
import type {CategoricalHistogramCard} from './univariate_header/categorical-histogram-card';
import type {Chi2IndTestCard} from './chi2-ind-test-card';
import type {ColumnCard} from './common/column-card';
import type {CorrelationMatrixCard} from './correlation-matrix-card';
import type {DurbinWatsonCard} from './durbin-watson-card';
import type {Filter} from './../../compute/filtering/filter';
import type {Fit2DDistributionCard} from './fit2-ddistribution-card';
import type {FitCurveCard} from './fit-curve-card';
import type {FitDistributionCard} from './fit-distribution-card';
import type {KsTest2SampCard} from './ks-test2-samp-card';
import type {MannKendallTestCard} from './mann-kendall-test-card';
import type {MoodTest2SampCard} from './mood-test2-samp-card';
import type {MoodTestNSampCard} from './mood-test-nsamp-card';
import type {MosaicPlotCard} from './bivariate_header/mosaic-plot-card';
import type {MultivariateCard} from './multivariate-card';
import type {NumericalHistogramCard} from './univariate_header/numerical-histogram-card';
import type {OneWayANOVACard} from './one-way-anovacard';
import type {PCACard} from './pcacard';
import type {PairwiseMoodTestCard} from './pairwise-mood-test-card';
import type {PairwiseTTestCard} from './pairwise-ttest-card';
import type {ParallelCoordinatesPlotCard} from './parallel-coordinates-plot-card';
import type {PlaygroundCard} from './playground-card';
import type {QuantilesTableCard} from './univariate_header/quantiles-table-card';
import type {STLDecompositionCard} from './stldecomposition-card';
import type {ScatterPlot3DCard} from './scatter-plot3-dcard';
import type {ScatterPlotCard} from './bivariate_header/scatter-plot-card';
import type {ShapiroNormalityTestCard} from './shapiro-normality-test-card';
import type {SignTest1SampCard} from './sign-test1-samp-card';
import type {SplitBySpec} from './../models/split-by-spec';
import type {TTest2SampCard} from './ttest2-samp-card';
import type {TZTest1SampCard} from './tztest1-samp-card';
import type {TimeSeriesCard} from './time-series-card';
import type {TrivariateCard} from './trivariate-card';
import type {UnitRootTestADFCard} from './unit-root-test-adfcard';
import type {UnitRootTestCard} from './unit-root-test-card';
import type {UnitRootTestKPSSCard} from './unit-root-test-kpsscard';
import type {UnitRootTestZACard} from './unit-root-test-zacard';
import type {UnivariateCard} from './univariate-card';
import type {UnivariateFrequencyTableCard} from './univariate_header/univariate-frequency-table-card';
import type {UnivariateHeaderCard} from './univariate_header/univariate-header-card';
import type {UnivariateSummaryStatsCard} from './univariate_header/univariate-summary-stats-card';
import type {WorksheetRootCard} from './worksheet-root-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.Card
 */
export interface _Card {
    filter?: Filter | null;
    id: string;
    splitBy?: SplitBySpec | null;
}

export type Card = ACFPlotCard | CDFPlotCard | Chi2IndTestCard | CorrelationMatrixCard | DurbinWatsonCard | Fit2DDistributionCard | FitCurveCard | FitDistributionCard | KsTest2SampCard | MannKendallTestCard | MoodTest2SampCard | MoodTestNSampCard | OneWayANOVACard | PCACard | PairwiseMoodTestCard | PairwiseTTestCard | ParallelCoordinatesPlotCard | PlaygroundCard | STLDecompositionCard | ScatterPlot3DCard | ShapiroNormalityTestCard | SignTest1SampCard | TTest2SampCard | TZTest1SampCard | UnitRootTestADFCard | UnitRootTestKPSSCard | UnitRootTestZACard | WorksheetRootCard | BivariateBoxPlotCard | BivariateFrequencyTableCard | BivariateHeaderCard | BivariateHistogramCard | BivariateSummaryStatsCard | MosaicPlotCard | ScatterPlotCard | ColumnCard | CategoricalHistogramCard | NumericalHistogramCard | QuantilesTableCard | UnivariateFrequencyTableCard | UnivariateHeaderCard | UnivariateSummaryStatsCard;

export namespace Card {
    export const type = ['acf_plot', 'cdf_plot', 'chi2_independence_test', 'correlation_matrix', 'durbin_watson', 'fit_2d_distribution', 'fit_curve', 'fit_distribution', 'ks_test_2samp', 'mann_kendall_test', 'mood_test_2samp', 'mood_nsamp', 'oneway_anova', 'pca', 'pairwise_mood', 'pairwise_ttest', 'parallel_coordinates_plot', 'playground', 'stl_decomposition', 'scatter_plot_3d', 'shapiro', 'sign_test_1samp', 'ttest_2samp', 'ttest_ztest_1samp', 'unit_root_test_adf', 'unit_root_test_kpss', 'unit_root_test_za', 'worksheet_root', 'bivariate_box_plot', 'bivariate_frequency_table', 'bivariate_header', 'bivariate_histogram', 'bivariate_summary', 'mosaic_plot', 'scatter_plot', 'column_card', 'categorical_histogram', 'numerical_histogram', 'quantile_table', 'univariate_frequency_table', 'univariate_header', 'univariate_summary'] as const;
    export function isACFPlotCard(toTest: Card): toTest is ACFPlotCard { return toTest['type'] === 'acf_plot'; }
    export function isBivariateCard(toTest: Card): toTest is BivariateCard { return ['chi2_independence_test', 'fit_2d_distribution', 'fit_curve', 'ks_test_2samp', 'mood_test_2samp', 'mood_nsamp', 'oneway_anova', 'pairwise_mood', 'pairwise_ttest', 'ttest_2samp', 'bivariate_box_plot', 'bivariate_frequency_table', 'bivariate_histogram', 'bivariate_summary', 'mosaic_plot', 'scatter_plot'].includes(toTest['type']); }
    export function isCDFPlotCard(toTest: Card): toTest is CDFPlotCard { return toTest['type'] === 'cdf_plot'; }
    export function isChi2IndTestCard(toTest: Card): toTest is Chi2IndTestCard { return toTest['type'] === 'chi2_independence_test'; }
    export function isCorrelationMatrixCard(toTest: Card): toTest is CorrelationMatrixCard { return toTest['type'] === 'correlation_matrix'; }
    export function isDurbinWatsonCard(toTest: Card): toTest is DurbinWatsonCard { return toTest['type'] === 'durbin_watson'; }
    export function isFit2DDistributionCard(toTest: Card): toTest is Fit2DDistributionCard { return toTest['type'] === 'fit_2d_distribution'; }
    export function isFitCurveCard(toTest: Card): toTest is FitCurveCard { return toTest['type'] === 'fit_curve'; }
    export function isFitDistributionCard(toTest: Card): toTest is FitDistributionCard { return toTest['type'] === 'fit_distribution'; }
    export function isKsTest2SampCard(toTest: Card): toTest is KsTest2SampCard { return toTest['type'] === 'ks_test_2samp'; }
    export function isMannKendallTestCard(toTest: Card): toTest is MannKendallTestCard { return toTest['type'] === 'mann_kendall_test'; }
    export function isMoodTest2SampCard(toTest: Card): toTest is MoodTest2SampCard { return toTest['type'] === 'mood_test_2samp'; }
    export function isMoodTestNSampCard(toTest: Card): toTest is MoodTestNSampCard { return toTest['type'] === 'mood_nsamp'; }
    export function isMultivariateCard(toTest: Card): toTest is MultivariateCard { return ['correlation_matrix', 'pca', 'parallel_coordinates_plot'].includes(toTest['type']); }
    export function isOneWayANOVACard(toTest: Card): toTest is OneWayANOVACard { return toTest['type'] === 'oneway_anova'; }
    export function isPCACard(toTest: Card): toTest is PCACard { return toTest['type'] === 'pca'; }
    export function isPairwiseMoodTestCard(toTest: Card): toTest is PairwiseMoodTestCard { return toTest['type'] === 'pairwise_mood'; }
    export function isPairwiseTTestCard(toTest: Card): toTest is PairwiseTTestCard { return toTest['type'] === 'pairwise_ttest'; }
    export function isParallelCoordinatesPlotCard(toTest: Card): toTest is ParallelCoordinatesPlotCard { return toTest['type'] === 'parallel_coordinates_plot'; }
    export function isPlaygroundCard(toTest: Card): toTest is PlaygroundCard { return toTest['type'] === 'playground'; }
    export function isSTLDecompositionCard(toTest: Card): toTest is STLDecompositionCard { return toTest['type'] === 'stl_decomposition'; }
    export function isScatterPlot3DCard(toTest: Card): toTest is ScatterPlot3DCard { return toTest['type'] === 'scatter_plot_3d'; }
    export function isShapiroNormalityTestCard(toTest: Card): toTest is ShapiroNormalityTestCard { return toTest['type'] === 'shapiro'; }
    export function isSignTest1SampCard(toTest: Card): toTest is SignTest1SampCard { return toTest['type'] === 'sign_test_1samp'; }
    export function isTTest2SampCard(toTest: Card): toTest is TTest2SampCard { return toTest['type'] === 'ttest_2samp'; }
    export function isTZTest1SampCard(toTest: Card): toTest is TZTest1SampCard { return toTest['type'] === 'ttest_ztest_1samp'; }
    export function isTimeSeriesCard(toTest: Card): toTest is TimeSeriesCard { return ['acf_plot', 'durbin_watson', 'mann_kendall_test', 'stl_decomposition', 'unit_root_test_adf', 'unit_root_test_kpss', 'unit_root_test_za'].includes(toTest['type']); }
    export function isTrivariateCard(toTest: Card): toTest is TrivariateCard { return ['scatter_plot_3d'].includes(toTest['type']); }
    export function isUnitRootTestADFCard(toTest: Card): toTest is UnitRootTestADFCard { return toTest['type'] === 'unit_root_test_adf'; }
    export function isUnitRootTestCard(toTest: Card): toTest is UnitRootTestCard { return ['unit_root_test_adf', 'unit_root_test_kpss', 'unit_root_test_za'].includes(toTest['type']); }
    export function isUnitRootTestKPSSCard(toTest: Card): toTest is UnitRootTestKPSSCard { return toTest['type'] === 'unit_root_test_kpss'; }
    export function isUnitRootTestZACard(toTest: Card): toTest is UnitRootTestZACard { return toTest['type'] === 'unit_root_test_za'; }
    export function isUnivariateCard(toTest: Card): toTest is UnivariateCard { return ['cdf_plot', 'fit_distribution', 'shapiro', 'sign_test_1samp', 'ttest_ztest_1samp', 'categorical_histogram', 'numerical_histogram', 'quantile_table', 'univariate_frequency_table', 'univariate_summary'].includes(toTest['type']); }
    export function isWorksheetRootCard(toTest: Card): toTest is WorksheetRootCard { return toTest['type'] === 'worksheet_root'; }
    export function isAbstract2DPivotCard(toTest: Card): toTest is Abstract2DPivotCard { return ['bivariate_frequency_table', 'bivariate_histogram', 'mosaic_plot'].includes(toTest['type']); }
    export function isBivariateBoxPlotCard(toTest: Card): toTest is BivariateBoxPlotCard { return toTest['type'] === 'bivariate_box_plot'; }
    export function isBivariateFrequencyTableCard(toTest: Card): toTest is BivariateFrequencyTableCard { return toTest['type'] === 'bivariate_frequency_table'; }
    export function isBivariateHeaderCard(toTest: Card): toTest is BivariateHeaderCard { return toTest['type'] === 'bivariate_header'; }
    export function isBivariateHistogramCard(toTest: Card): toTest is BivariateHistogramCard { return toTest['type'] === 'bivariate_histogram'; }
    export function isBivariateSummaryStatsCard(toTest: Card): toTest is BivariateSummaryStatsCard { return toTest['type'] === 'bivariate_summary'; }
    export function isMosaicPlotCard(toTest: Card): toTest is MosaicPlotCard { return toTest['type'] === 'mosaic_plot'; }
    export function isScatterPlotCard(toTest: Card): toTest is ScatterPlotCard { return toTest['type'] === 'scatter_plot'; }
    export function isAbstract2SampTestCard(toTest: Card): toTest is Abstract2SampTestCard { return ['ks_test_2samp', 'mood_test_2samp', 'ttest_2samp'].includes(toTest['type']); }
    export function isAbstractCardContainer(toTest: Card): toTest is AbstractCardContainer { return ['worksheet_root', 'bivariate_header', 'column_card', 'univariate_header'].includes(toTest['type']); }
    export function isAbstractHeaderCard(toTest: Card): toTest is AbstractHeaderCard { return ['bivariate_header', 'univariate_header'].includes(toTest['type']); }
    export function isAbstractNSampTestCard(toTest: Card): toTest is AbstractNSampTestCard { return ['mood_nsamp', 'oneway_anova', 'pairwise_mood', 'pairwise_ttest'].includes(toTest['type']); }
    export function isAbstractPairwiseTestCard(toTest: Card): toTest is AbstractPairwiseTestCard { return ['pairwise_mood', 'pairwise_ttest'].includes(toTest['type']); }
    export function isColumnCard(toTest: Card): toTest is ColumnCard { return toTest['type'] === 'column_card'; }
    export function isCategoricalHistogramCard(toTest: Card): toTest is CategoricalHistogramCard { return toTest['type'] === 'categorical_histogram'; }
    export function isNumericalHistogramCard(toTest: Card): toTest is NumericalHistogramCard { return toTest['type'] === 'numerical_histogram'; }
    export function isQuantilesTableCard(toTest: Card): toTest is QuantilesTableCard { return toTest['type'] === 'quantile_table'; }
    export function isUnivariateFrequencyTableCard(toTest: Card): toTest is UnivariateFrequencyTableCard { return toTest['type'] === 'univariate_frequency_table'; }
    export function isUnivariateHeaderCard(toTest: Card): toTest is UnivariateHeaderCard { return toTest['type'] === 'univariate_header'; }
    export function isUnivariateSummaryStatsCard(toTest: Card): toTest is UnivariateSummaryStatsCard { return toTest['type'] === 'univariate_summary'; }
}