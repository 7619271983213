import { transition, trigger, style, animate, state } from '@angular/animations';


// Do not use in combination with *ngIf (it breaks state transition)
export const fadeInOutHeight = trigger('fadeInOut', [
    state('opened', style({ opacity: 1, height: '*', willChange: 'height, opacity' })),
    state('closed', style({ opacity: 0, height: '0px', willChange: 'height, opacity' })),

    transition('opened => closed', animate('200ms ease-out')),
    transition('closed => opened', animate('200ms ease-out'))
]);
