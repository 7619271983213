<form class="data-collections-top-bar"
      [formGroup]="filtersForm">
    <span class="data-collections-top-bar__title">Data Collections</span>
    <div class="data-collections-top-bar__search">
        <span><i class="dku-icon-search-16"></i></span>
        <input type="text"
            formControlName="search"
            placeholder="Search Data Collections"
        />
    </div>

    <div class="data-collections-top-bar__select-with-addon">
        <div class="add-on">
            <i class="dku-icon-tag-16"></i>
        </div>
        <dss-tags-selector
            formControlName="selectedTags"
            [tags]="availableTags"
        ></dss-tags-selector>
    </div>

    <div class="data-collections-top-bar__select-with-addon" *ngIf="sortOrder$ | async as sortOrder">
        <button class="add-on" (click)="toggleSortOrder(sortOrder)">
            <i  [class.dku-icon-sort-ascending-16]="sortOrder === 'ASC'"
                [class.dku-icon-sort-descending-16]="sortOrder === 'DESC'"
            ></i>
        </button>
        <basic-select
            formControlName="sortBy"
            [items]="SORT_BY_OPTIONS_OBJ"
            bindLabel="opt"
            bindValue="opt"
            [searchable]="false"
        ></basic-select>
    </div>

    <button *ngIf="canCreateDataCollections"
        class="btn btn--primary btn--dku-icon"
        (click)="newCollection.emit()"
    >
        <i class="dku-icon-plus-16"></i>
        New collection
    </button>
</form>