<p>
    The Mann-Kendall Trend Test is used to analyze series data for
    consistently increasing or decreasing trends (monotonic trends). It tests
    the hypothesis that there is no monotonic trend in <b>{{ params.seriesColumn.name }}</b>,
    with the alternate hypothesis that there is a monotonic trend.
</p>

<p>
    The Mann-Kendall test does not consider serial correlation or seasonal
    effects. These two assumptions must be checked prior to running the test.
</p>

<p>
    Serial correlation occurs in a series when a series value and a lagged
    version of itself (for instance the values at times T and at T-1) are
    observed to be correlated with one another over periods of time.

    Serial correlation can be checked either through
    <span *ngIf="!canAddCard">
        autocorrelation visual inspection
    </span>
    <a *ngIf="canAddCard" (click)="addACFPlotCard()">
        autocorrelation visual inspection
    </a>
    or by using the
    <span *ngIf="!canAddCard">
        Durbin-Watson statistic
    </span>
    <a *ngIf="canAddCard" (click)="addDurbinWatsonCard()">
        Durbin-Watson statistic
    </a>.
</p>

<p>
    There are two possible outcomes depending on the 𝘱-value:
</p>

<ul>
    <li>
        <b>
            𝘱-value &le; significance level (&le; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>

        <br>
        The hypothesis is rejected. There is a monotonic trend present in <b>{{ params.seriesColumn.name }}</b>,
        and the test is able to tell whether the trend is increasing or decreasing.
    </li>

    <li>
        <b>
            𝘱-value is &gt; significance level (&gt; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>

        <br>
        Not enough evidence against the hypothesis. The test is inconclusive about whether
        there is no monotonic trend present in <b>{{ params.seriesColumn.name }}</b>.
    </li>
</ul>
