import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual } from 'lodash';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { tap, map, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { Workspace } from '@model-main/workspaces/workspace';
import { ITaggingService } from '@model-main/server/services/itagging-service';
import { ATSurveyService } from '@features/surveys/surveys-migration';
import { WorkspaceObjectKey } from '@shared/models';
import {
    WorkspaceDisplayService,
    WorkspacesNavigationService,
    WorkspacesService
} from '../../shared';
import { DkuActivatedRouteService } from '@migration/dku-activated-route';

@UntilDestroy()
@Component({
    selector: 'workspace-object-page',
    templateUrl: './workspace-object-page.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceObjectPageComponent implements OnInit, OnDestroy {
    workspace$ = new BehaviorSubject<Workspace | undefined>(undefined);
    object$ = new BehaviorSubject<Workspace.WorkspaceObject | undefined>(undefined);
    insightId$ = new BehaviorSubject<string | null | undefined>(undefined);

    constructor(
        private activatedRoute: DkuActivatedRouteService,
        private workspacesService: WorkspacesService,
        private workspaceDisplayService: WorkspaceDisplayService,
        private workspacesNavigation: WorkspacesNavigationService,
        @Inject('ATSurveyService') private ATSurveyService: ATSurveyService
    ) { }

    ngOnInit(): void {
        this.activatedRoute.paramMap
            .pipe(
                tap(paramMap => this.insightId$.next(paramMap.get('insightId'))),
                map(paramMap => {
                    return {
                        workspaceKey: paramMap.get('workspaceKey'),
                        appId: paramMap.get('appId'),
                        projectKey: paramMap.get('projectKey'),
                        objectType: paramMap.get('objectType'),
                        objectId: paramMap.get('objectId')
                    };
                }),
                distinctUntilChanged((prev: { [key: string]: string | null }, curr: { [key: string]: string | null }) => isEqual(prev, curr)),
                switchMap(params => {
                    const objectKey = this.getObjectKeyFromParams(params);

                    if (params.workspaceKey && objectKey) {
                        return this.workspacesService.fetchWorkspaceObject(params.workspaceKey, objectKey);
                    }

                    return of(null);
                }),
                switchMap(() => combineLatest([this.workspacesService.getCurrentWorkspace(), this.workspacesService.getCurrentObject()])),
                tap(([workspace, object]) => {
                    this.workspace$.next(workspace);
                    this.object$.next(object);
                    this.onUpdateObject(workspace, object);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.workspacesService.pushError();
    }

    private getObjectKeyFromParams({ workspaceKey, appId, projectKey, objectType, objectId }: {[key: string]: string | null}): WorkspaceObjectKey | null {
        if (appId) {
            return { appId };
        } else if (workspaceKey && projectKey && objectType && objectId) {
            return {
                reference: {
                    projectKey,
                    workspaceKey,
                    type: objectType as ITaggingService.TaggableType,
                    id: objectId
                }
            };
        }
        return null;
    }

    private onUpdateObject(workspace?: Workspace, object?: Workspace.WorkspaceObject): void {
        const title = object?.displayName
            ? `${object.displayName} - ${this.workspaceDisplayService.getObjectTypeUI(object)}`
            : 'Workspace Object';
        this.workspacesNavigation.updateTopNavTitle(workspace, title);

        if (object) {
            this.ATSurveyService.updateCounter('WorkspacesOpenEditObject');
        }
    }
}
