import { createToken, Lexer, CstParser, EMPTY_ALT } from 'chevrotain';
import { FrontendChartFilter } from '../../../../simple-report/models';
import { DATE_PART_FROM_QUERY_STRING, QUERY_PARAM_PARSING_ERROR } from '../../constants';
import { getDashboardFiltersUrlQueryParamVisitor } from './dashboard-filters-url-query-param-visitor';

const columnNameCharacterPattern = '\\\\.|[^:]';
const getColumnRegex = (operators: string[], allowEmptyColumnName = false) => {
    const columnNamePattern = `(?:${columnNameCharacterPattern})${allowEmptyColumnName ? '*' : '+'}`;
    if (!operators.length) {
        return RegExp(`${columnNamePattern}:`);
    }
    const operatorPattern = `(?:${operators.join('|')})`;
    return RegExp(`${operatorPattern}\\(${columnNamePattern}\\):`);
};

// In DSS column names aren't restricted so all the tokens defined before the column ones could be part of a column name.
// If the tokenizer meet one of these tokens it will identify it as such and not as the beginning of the AlphanumColumn token.
// To handle that, we define a category for these tokens to catch them manually and the column name will be reconstructed in the visitor.
const PossibleColumnNamePrefix = createToken({ name: 'PossibleColumnNamePrefix', pattern: Lexer.NA });

const THIS = createToken({ name: 'THIS', pattern: /THIS/, label: 'THIS', categories: [PossibleColumnNamePrefix] });
const TD = createToken({ name: 'TD', pattern: /TD/, label: 'TD', categories: [PossibleColumnNamePrefix] });
const Last = createToken({ name: 'Last', pattern: /last/, label: 'Last', categories: [PossibleColumnNamePrefix] });
const Next = createToken({ name: 'Next', pattern: /next/, label: 'Next', categories: [PossibleColumnNamePrefix] });
const OFF = createToken({ name: 'OFF', pattern: /OFF/, label: 'OFF', categories: [PossibleColumnNamePrefix] });
const Not = createToken({ name: 'Not', pattern: /not/, label: 'not', categories: [PossibleColumnNamePrefix] });
const LParen = createToken({ name: 'LParen', pattern: /\(/, label: '(', categories: [PossibleColumnNamePrefix] });
const RParen = createToken({ name: 'RParen', pattern: /\)/, label: ')', categories: [PossibleColumnNamePrefix] });
const Comma = createToken({ name: 'Comma', pattern: /,/, label: ',', categories: [PossibleColumnNamePrefix] });
const Semicolon = createToken({ name: 'Semicolon', pattern: /;/, label: ';', categories: [PossibleColumnNamePrefix] });
const IsoDate = createToken({ name: 'IsoDate', pattern: /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+(?:[+-][0-2]\d:[0-5]\d|Z))?/, categories: [PossibleColumnNamePrefix] });
const Decimal = createToken({ name: 'Decimal', pattern: /-?[0-9]+\.[0-9]+/, categories: [PossibleColumnNamePrefix] });
const Int = createToken({ name: 'Int', pattern: /-?[0-9]+/, categories: [PossibleColumnNamePrefix] });
const QuotedInt = createToken({ name: 'QuotedInt', pattern: /"-?[0-9]+"/, categories: [PossibleColumnNamePrefix] });
const String = createToken({ name: 'String', pattern: /"(?:\\.|[^"])*"/, categories: [PossibleColumnNamePrefix] });
const RangeColumn = createToken({ name: 'RangeColumn', pattern: getColumnRegex(['range']) });
const DatePartColumn = createToken({ name: 'DatePartColumn', pattern: getColumnRegex(Object.keys(DATE_PART_FROM_QUERY_STRING)) });
const AlphanumColumn = createToken({ name: 'AlphanumColumn', pattern: getColumnRegex([], true) });

const tokens = [
    PossibleColumnNamePrefix,
    THIS,
    TD,
    Last,
    Next,
    OFF,
    Not,
    LParen,
    RParen,
    Comma,
    Semicolon,
    IsoDate,
    Decimal,
    Int,
    QuotedInt,
    String,
    RangeColumn,
    DatePartColumn,
    AlphanumColumn
];

const DashboardFiltersUrlQueryParamLexer = new Lexer(tokens);

export class DashboardFiltersUrlQueryParamParser extends CstParser {
    constructor() {
        super(tokens);
        this.performSelfAnalysis();
    }

    filters = this.RULE('filters', () => {
        this.MANY_SEP({
            SEP: Semicolon,
            DEF: () => {
                this.SUBRULE(this.filter);
            }
        });
    });

    filter = this.RULE('filter', () => {
        this.OR([
            { ALT: () => { this.SUBRULE(this.datePartFilter); } },
            { ALT: () => { this.SUBRULE(this.rangeFilter); } },
            { ALT: () => { this.SUBRULE(this.alphanumericalFilter); } },
        ]);
    });

    datePartFilter = this.RULE('datePartFilter', () => {
        this.CONSUME(DatePartColumn);
        this.OR([
            { ALT: () => { this.CONSUME(OFF); } },
            { ALT: () => { this.SUBRULE(this.relativeDateValue); } },
            { ALT: () => { this.SUBRULE(this.datePartValues); } }
        ]);
    });

    relativeDateValue = this.RULE('relativeDateValue', () => {
        this.OR2([
            { ALT: () => { this.CONSUME(THIS); } },
            { ALT: () => { this.CONSUME(TD); } },
            { ALT: () => {
                    this.OR3([
                        { ALT: () => { this.CONSUME(Last); } },
                        { ALT: () => { this.CONSUME(Next); } }
                    ]);
                    this.CONSUME(LParen);
                    this.SUBRULE(this.number);
                    this.CONSUME(RParen);
                }
            },
        ]);
    });

    datePartValues = this.RULE('datePartValues', () => {
        this.negatable(index => {
            this.subrule(index, this.datePartValue);
            this.many(index, () => {
                this.consume(index, Comma);
                // We index from the end here to avoid clashes due to the use of negatable.
                this.subrule(9 - index, this.datePartValue);
            });
        });
    });

    datePartValue = this.RULE('datePartValue', () => {
        this.OR([
            { ALT: () => { this.CONSUME(QuotedInt); } },
            { ALT: () => { this.CONSUME(String); } }
        ]);
    });

    rangeFilter = this.RULE('rangeFilter', () => {
        this.CONSUME(RangeColumn);
        this.OR([
            { ALT: () => { this.CONSUME(OFF); } },
            {
                ALT: () => {
                    this.OPTION(() => {
                        this.SUBRULE(this.rangeValue);
                    });
                    this.CONSUME(Comma);
                    this.SUBRULE1(this.rangeValue);
                }
            },
            { ALT: () => { this.SUBRULE2(this.rangeValue); } },
            { ALT: () => { EMPTY_ALT(); } }
        ]);
    });

    rangeValue = this.RULE('rangeValue', () => {
        this.OR([
            { ALT: () => { this.CONSUME(IsoDate); } },
            { ALT: () => { this.SUBRULE(this.number); } }
        ]);

    });

    number = this.RULE('number', () => {
        this.OR([
            { ALT: () => this.CONSUME(Decimal) },
            { ALT: () => this.CONSUME(Int) }
        ]);
    });

    alphanumericalFilter = this.RULE('alphanumericalFilter', () => {
        this.MANY(() => { this.CONSUME(PossibleColumnNamePrefix); });
        this.CONSUME(AlphanumColumn);
        this.OR([
            { ALT: () => { this.CONSUME(OFF); } },
            { ALT: () => { this.SUBRULE(this.alphanumericalValues); } }
        ]);
    });

    alphanumericalValues = this.RULE('alphanumericalValues', () => {
        this.negatable(index => {
            this.subrule(index, this.alphanumericalValue);
            this.many(index, () => {
                this.consume(index, Comma);
                // We index from the end here to avoid clashes due to the use of negatable.
                this.subrule(9 - index, this.alphanumericalValue);
            });
        });
    });

    alphanumericalValue = this.RULE('alphanumericalValue', () => {
        this.OR([
            { ALT: () => { this.CONSUME(QuotedInt); } },
            { ALT: () => { this.CONSUME(String); } }
        ]);
    });

    negatable(implementation: (index: number) => void) {
        // We use OPTION8 and OPTION9 below because `negatable` is a helper fonction called in actual rules.
        // These actual rules could also use the OPTION operator, so to minimise the risk of using one that has already been used, we take the last available indexes.
        this.OR([
            {
                ALT: () => {
                    this.CONSUME(Not);
                    this.CONSUME(LParen);
                    this.OPTION9(() => { implementation(0); });
                    this.CONSUME(RParen);
                }
            },
            {
                ALT: () => {
                    this.OPTION8(() => { implementation(1); });
                }
            },
        ]);
    }

    parse(queryParam: string): Partial<FrontendChartFilter>[] {
        const lexResult = DashboardFiltersUrlQueryParamLexer.tokenize(queryParam);
        this.reset();
        this.input = lexResult.tokens;
        const cstOutput = this.filters();
        if (lexResult.errors.length > 0 || cstOutput === undefined) {
            throw QUERY_PARAM_PARSING_ERROR;
        }
        const visitor = getDashboardFiltersUrlQueryParamVisitor(this);
        return visitor.visit(cstOutput);
    }
}
