import { Injectable } from '@angular/core';
import { AxisDef } from '@model-main/pivot/backend/model/axis-def';

@Injectable({
    providedIn: 'root'
})
/**
 * Utility functions easing chart column types manipulations.
 */
export class ChartColumnTypeUtilsService {

    isGeometryColumnType(columnType?: AxisDef.Type | string): boolean {
        return columnType === AxisDef.Type.GEOMETRY;
    }

    isGeopointColumnType(columnType?: AxisDef.Type | string): boolean {
        return columnType === AxisDef.Type.GEOPOINT;
    }

    isGeoColumnType(columnType?: AxisDef.Type | string): boolean {
        return this.isGeopointColumnType(columnType) || this.isGeometryColumnType(columnType);
    }

    isDateColumnType(columnType?: AxisDef.Type | string): boolean {
        return columnType === AxisDef.Type.DATE;
    }

    isAlphanumColumnType(columnType?: AxisDef.Type | string): boolean {
        return columnType === AxisDef.Type.ALPHANUM;
    }

    isNumericalColumnType(columnType?: AxisDef.Type | string): boolean {
        return columnType === AxisDef.Type.NUMERICAL;
    }

    isCount(column?: {column?: string, function?: string}): boolean {
        return !!column && (column.column === '__COUNT__' || column.column == null && column.function === 'COUNT');
    }

    isNonColumnData(column?: {type?: AxisDef.Type | string}): boolean {
        return !column || !column.type;
    }
}
