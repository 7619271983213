import { Pipe, PipeTransform } from '@angular/core';

const TARGET_NUMBER_LENGTH = 12;

/**
 * Similar to smartNumber except:
 * - Formatted numbers takes <= TARGET_NUMBER_LENGTH characters
 * - Precision is preserved as much as possible
 */
export function smarterNumber(v?: number | null): string {
    if (typeof v !== 'number') {
        return 'N/A';
    }
    const str = v.toString();
    if (str.length <= TARGET_NUMBER_LENGTH) {
        return str;
    }
    for (let precision = TARGET_NUMBER_LENGTH; precision > 1; precision--) {
        // Round to lower precision
        const rounded = v.toPrecision(precision);
        // Remove trailing zeros
        const cleaned = rounded.replace(/^([-+]?[0-9]*)(?:\.0*|(\.[0-9]*[1-9])0*)([eE][-+]?[0-9]+)?$/, '$1$2$3');
        if (cleaned.length <= TARGET_NUMBER_LENGTH) {
            return cleaned;
        }
    }
    // Should never happen
    return v.toString();
}

@Pipe({
    name: 'smarterNumber'
})
export class SmarterNumberPipe implements PipeTransform {
    transform(d: number | undefined | null): string {
        return smarterNumber(d);
    }
}
