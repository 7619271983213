import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { DkuMatTooltipModule } from '@shared/components/dku-mat-tooltip/dku-mat-tooltip.module';
import { KeyValuesListModule } from '@app/widgets/lists/key-values-list/key-values-list.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { CoreModule } from '@core/core.module';
import { DataikuAPIModule } from '@core/dataiku-api/dataiku-api.module';
import { BboxThumbComponent } from '@features/labeling/labeling-task-annotate/category-selector/bbox-thumb/bbox-thumb.component';
import { LabelingTaskAnnotateComponent } from '@features/labeling/labeling-task-annotate/labeling-task-annotate.component';
import { LabelingTaskReviewComponent } from '@features/labeling/labeling-task-review/labeling-task-review.component';
import { LabelingTaskSettingsComponent } from '@features/labeling/labeling-task-settings/labeling-task-settings.component';
import { LabelingComponent } from '@features/labeling/labeling.component';
import { ObjectDetectionImageCanvasComponent } from '@features/labeling/object-detection-image-canvas/object-detection-image-canvas.component';
import { ComponentsModule } from '@shared/components/components.module';
import { ModalsModule } from '@shared/modals/modals.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { DkuFrontendCoreModule } from 'dku-frontend-core';
import { LabelingContainerComponent } from './labeling-container/labeling-container.component';
import { LabelingErrorComponent } from './labeling-error/labeling-error.component';
import { AnnotateAnnotationListComponent } from './labeling-task-annotate/annotate-annotation-list/annotate-annotation-list.component';
import { NamedEntityAnnotateAnnotationListComponent } from './labeling-task-annotate/annotate-annotation-list/named-entity-annotate-annotation-list/named-entity-annotate-annotation-list.component';
import { CategorySelectorComponent } from './labeling-task-annotate/category-selector/category-selector.component';
import { NamedEntityThumbComponent } from './labeling-task-annotate/category-selector/named-entity-thumb/named-entity-thumb.component';
import { LabelingTaskImageAnnotateComponent } from './labeling-task-annotate/labeling-task-image-annotate/labeling-task-image-annotate.component';
import { LabelingTaskTextAnnotateComponent } from './labeling-task-annotate/labeling-task-text-annotate/labeling-task-text-annotate.component';
import { ObjectDetectionPerLabelerStatsComponent } from './labeling-task-overview/classical-per-labeler-stats/classical-per-labeler-stats.component';
import { ImageClassificationPerLabelerStatsComponent } from './labeling-task-overview/image-classification-per-labeler-stats/image-classification-per-labeler-stats.component';
import { LabelingTaskOverviewComponent } from './labeling-task-overview/labeling-task-overview.component';
import { PerLabelerStatsComponent } from './labeling-task-overview/per-labeler-stats/per-labeler-stats.component';
import { ReviewFeedHeaderComponent } from './labeling-task-review/feed-header/review-feed-header.component';
import { ReviewItemHeaderComponent } from './labeling-task-review/header/review-item-header.component';
import { ImageLabelingTaskReviewComponent } from './labeling-task-review/image-labeling-task-review/image-labeling-task-review.component';
import { AnnotationGroupListComponent } from './labeling-task-review/lists/annotation-group-list/annotation-group-list.component';
import { AnnotationListComponent } from './labeling-task-review/lists/annotation-list/annotation-list.component';
import { NamedEntityRegionSelectorComponent } from './labeling-task-review/named-entity-region-selector/named-entity-region-selector.component';
import { ObjectDetectionRegionSelectorComponent } from './labeling-task-review/object-detection-region-selector/object-detection-region-selector.component';
import { ResolutionDialogComponent } from './labeling-task-review/resolution-dialog/resolution-dialog.component';
import { ReviewImageComponent } from './labeling-task-review/review-image/review-image.component';
import { ReviewItemEmptyComponent } from './labeling-task-review/review-item-empty/review-item-empty.component';
import { ReviewItemFeedComponent } from './labeling-task-review/review-item-feed/review-item-feed.component';
import { ReviewStatusIconComponent } from './labeling-task-review/review-item-feed/review-status-icon/review-status-icon.component';
import { ReviewNoAnnotationsPlaceholderComponent } from './labeling-task-review/review-no-annotations-placeholder/review-no-annotations-placeholder.component';
import { TextLabelingTaskReviewComponent } from './labeling-task-review/text-labeling-task-review/text-labeling-task-review.component';
import { LabelingPermissionsComponent } from './labeling-task-settings/labeling-permissions/labeling-permissions.component';
import { ImageLabelingSettingsDataComponent } from './labeling-task-settings/labeling-settings-data/image-labeling-settings-data/image-labeling-settings-data.component';
import { LabelingSettingsDataComponent } from './labeling-task-settings/labeling-settings-data/labeling-settings-data.component';
import { TextLabelingSettingsDataComponent } from './labeling-task-settings/labeling-settings-data/text-labeling-settings-data/text-labeling-settings-data.component';
import { LabelingUnusableWarningComponent } from './labeling-unusable-warning/labeling-unusable-warning.component';
import { CategoryLabelPipe, FormattedAnnotatorNamePipe } from './utils';
import { LabelingMetadataContentComponent } from './labeling-metadata-content/labeling-metadata-content.component';

@NgModule({
    declarations: [
        LabelingComponent,
        LabelingTaskAnnotateComponent,
        LabelingTaskReviewComponent,
        LabelingTaskSettingsComponent,
        ObjectDetectionImageCanvasComponent,
        BboxThumbComponent,
        LabelingTaskReviewComponent,
        ReviewImageComponent,
        ReviewItemHeaderComponent,
        ReviewFeedHeaderComponent,
        AnnotationListComponent,
        AnnotationGroupListComponent,
        ObjectDetectionRegionSelectorComponent,
        LabelingSettingsDataComponent,
        LabelingTaskSettingsComponent,
        LabelingPermissionsComponent,
        LabelingErrorComponent,
        LabelingTaskOverviewComponent,
        PerLabelerStatsComponent,
        LabelingContainerComponent,
        FormattedAnnotatorNamePipe,
        CategoryLabelPipe,
        CategorySelectorComponent,
        AnnotateAnnotationListComponent,
        LabelingUnusableWarningComponent,
        ObjectDetectionPerLabelerStatsComponent,
        ImageClassificationPerLabelerStatsComponent,
        ReviewItemEmptyComponent,
        ResolutionDialogComponent,
        ImageLabelingSettingsDataComponent,
        TextLabelingSettingsDataComponent,
        LabelingTaskImageAnnotateComponent,
        LabelingTaskTextAnnotateComponent,
        NamedEntityThumbComponent,
        ReviewItemFeedComponent,
        NamedEntityRegionSelectorComponent,
        ImageLabelingTaskReviewComponent,
        TextLabelingTaskReviewComponent,
        ReviewStatusIconComponent,
        NamedEntityAnnotateAnnotationListComponent,
        LabelingMetadataContentComponent,
        ReviewNoAnnotationsPlaceholderComponent
    ],
    imports: [
        CoreModule,
        CommonModule,
        ComponentsModule,
        FormsModule,
        DataikuAPIModule,
        ReactiveFormsModule,
        KeyValuesListModule,
        PipesModule,
        ModalsModule,
        MatSelectModule,
        DkuMatTooltipModule,
        MatProgressSpinnerModule,
        WidgetsModule,
        DkuFrontendCoreModule
    ]
})
export class LabelingModule {}
