import type {Card, _Card} from './card';
import type {CorrelationMatrixCard} from './correlation-matrix-card';
import type {PCACard} from './pcacard';
import type {ParallelCoordinatesPlotCard} from './parallel-coordinates-plot-card';
import type {Variable} from './../models/variable';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.MultivariateCard
 */
export interface _MultivariateCard extends _Card {
    columns: Variable[];
}

export type MultivariateCard = CorrelationMatrixCard | PCACard | ParallelCoordinatesPlotCard;

export namespace MultivariateCard {
    export const type = ['correlation_matrix', 'pca', 'parallel_coordinates_plot'] as const;
}

/** @deprecated Use Card.isMultivariateCard() */ export function isMultivariateCard(toTest: Card): toTest is MultivariateCard { return (MultivariateCard.type as readonly unknown[]).includes(toTest['type']); }