import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'experiment-tracking-status',
    templateUrl: './experiment-tracking-status.component.html',
    styleUrls: ['./experiment-tracking-status.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperimentTrackingStatusComponent {
    @Input() runStatus: string;

    constructor(
    ) {

    }
}
