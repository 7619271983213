import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StockColorPickerComponent } from './stock-color-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        StockColorPickerComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        StockColorPickerComponent
    ]
})
export class StockColorPickerModule { }
