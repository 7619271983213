import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { APIError } from '@core/dataiku-api/api-error';
import { BehaviorSubject } from 'rxjs';
import { ObjectViewerOptions } from './object-viewer-options.model';

@Directive()
export abstract class ObjectViewer {
    protected options$ = new BehaviorSubject<ObjectViewerOptions | null | undefined>(undefined);

    @Input()
    set options(value: ObjectViewerOptions | undefined) {
        this.options$.next(value);
    }

    @Output() fail = new EventEmitter<APIError>();

    handleError(err: APIError) {
        this.fail.next(err);
    }
}
