import { RGBColor } from "d3-color";
import { round } from "lodash";

function _relativeLuminanceForComponent(color: number) {
    const c = color / 255;
    return (c <= 0.03928) ? c / 12.92 : Math.pow(((c + 0.055) / 1.055), 2.4);
}

export function getRelativeLuminance(rgbColor: RGBColor) {
    const R = _relativeLuminanceForComponent(rgbColor.r);
    const G = _relativeLuminanceForComponent(rgbColor.g);
    const B = _relativeLuminanceForComponent(rgbColor.b);
    const luminance = 0.2126 * R + 0.7152 * G + 0.0722 * B;
    return round(luminance, 4);
}

export function shouldUseBlackText(rgbColor: RGBColor) {
    return getRelativeLuminance(rgbColor) > 0.179;
}
