/**
 * Generated from com.dataiku.dip.analysis.model.prediction.CalibrationParams
 */
export interface CalibrationParams {
    calibrationMethod: CalibrationParams.CalibrationMethod;
}

export namespace CalibrationParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.CalibrationParams$CalibrationMethod
     */
    export enum CalibrationMethod {
        NO_CALIBRATION = 'NO_CALIBRATION',
        SIGMOID = 'SIGMOID',
        ISOTONIC = 'ISOTONIC'
    }
}