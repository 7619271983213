<ng-container *ngIf="isBuildingPopulationsAutomatically else manual">
    The compared populations are determined from the first {{ maxNbOfAutomaticPopulations }} most common values of
    <b>{{ params.yColumn.name }}</b>.
</ng-container>

<ng-template #manual>
    The compared populations are:
    <ul>
        <li *ngFor="let population of manualPopulations">
            <u>{{ population.column.name }}</u>:
            {{ population.values.join(', ') }}
        </li>
    </ul>
</ng-template>
