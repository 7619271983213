import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewContainerRef } from "@angular/core";
import { fairAny } from 'dku-frontend-core';
import { CurrentRouteService } from "@core/nav/current-route.service";
import { ImageFeedModalComponent } from "@shared/components/image-feed-modal/image-feed-modal.component";
import { ModalShape, ModalsService } from "@shared/modals/modals.service";
import { ColorMapContextService } from "@shared/services/color-map-context.service";
import { isEqual } from "lodash";
import { FilterRequest, ImageViewSettings, SerializedShakerScript } from "src/generated-sources";
import { DatasetViewDataFetcherService } from "../dataset-view-data-fetcher.service";

@Component({
    selector: 'dataset-view-explore-images',
    templateUrl: './dataset-view-explore-images.component.html',
    styleUrls: ['./dataset-view-explore-images.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatasetViewExploreImagesComponent implements OnChanges, OnInit {
    @Input() imageViewSettings: ImageViewSettings;
    @Input() nbColumnsInDataset: number;
    @Input() inputDatasetName: string;
    @Input() inputDatasetProjectKey: string;
    @Input() script: SerializedShakerScript;
    @Input() requestedSampleId: string|null;
    @Input() filters: FilterRequest;

    public constructor(
        private currentRoute: CurrentRouteService,
        private dataFetcher: DatasetViewDataFetcherService,
        private viewContainerRef: ViewContainerRef,
        private colorMapService: ColorMapContextService,
        private modalsService: ModalsService,
        @Inject('COLOR_PALETTES') private COLOR_PALETTES: { [palette: string]: string[]; },
        @Inject('$state') public readonly $state: fairAny
    ) {}
    
    ngOnInit(): void {
        if (this.imageViewSettings.annotationParams && this.imageViewSettings.annotationParams.enabled) {
            this.colorMapService.setMapping(this.imageViewSettings.annotationParams.annotationCategories, this.COLOR_PALETTES.highContrast);
        }
    }

    // Dataset view is always accessed after having gotten refreshed the table in the table view. Therefore, we only want to refresh the data:
    //  * when the sample id has changed (if the sample was updated) and is not null (re-using the one from the table view)
    //  * if some of the filters changed
    private shouldTriggerNewFetch(changes: SimpleChanges): boolean {
        if (changes.requestedSampleId) {
            if (changes.requestedSampleId.firstChange) {
                return true;
            }
            if (changes.requestedSampleId.currentValue == null) {
                return false;
            }
            if (changes.requestedSampleId.currentValue != changes.requestedSampleId.previousValue) {
                return true;
            }
        }

        if (changes.filters && !isEqual(changes.filters.currentValue, changes.filters.previousValue)) {
            return true;
        }

        return false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.shouldTriggerNewFetch(changes)) {
            this.dataFetcher.setDatasetViewInfo(this.currentRoute.projectKey, {projectKey: this.inputDatasetProjectKey, id: this.inputDatasetName}, this.imageViewSettings, this.nbColumnsInDataset, this.script, this.filters, this.requestedSampleId);
        }
    }

    openModal({imageId}: { imageId?: number }) {
        this.modalsService
            .open(ImageFeedModalComponent, {
                imageId
            }, ModalShape.FULL, this.viewContainerRef)
            .then(() => {}, () => {});
    }
}