import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import type { IScope } from 'angular';

@Component({
    selector: 'downgraded-values-list',
    templateUrl: './downgraded-values-list.component.html',
})

/**
 * Bridge between Angular and AngularJS to use values lists in the later.
 * Simply provides proper inputs and outputs, adapts ControlValueAccessor structure and propagates changes.
 *
 * @example
 * // In AngularJS code
 * <ng2-values-list [(items)]="items" (onChange)="change()"></ng2-values-list>
 */
export class DowngradedValuesListComponent {
    @Input() items: Array<Object>;
    @Input() sortable: boolean;
    @Input() suggestions: string[];
    @Output() onAdd = new EventEmitter<number>();
    @Output() onDelete = new EventEmitter<number>();
    @Output() onChange = new EventEmitter<Array<Object>>();
    @Output() onFocus = new EventEmitter<any>();
    @Output() onBlur = new EventEmitter<any>();
    @Output() onReorder = new EventEmitter<any>();
    @Output() itemsChange = new EventEmitter<Array<Object>>();
    @Output() onValidityChange = new EventEmitter<boolean>();
    @Output() onMouseOverAddButton = new EventEmitter<any>();
    @Output() onMouseLeaveAddButton = new EventEmitter<any>();
    @Input() addLabel: string = 'Add Value';
    @Input() hasDivider: boolean = true;
    @Input() focusOnEnter: boolean = true;
    @Input() fullWidthList: boolean = false;
    @Input() disableAdd: boolean = false;
    @Input() valuePlaceholder: string = 'A value';
    @Input() valueIdentifier: string;
    @Input() required: boolean = false;
    @Input() richValuePlaceholder: boolean = false;

    constructor(@Inject('$rootScope') private $rootScope: IScope) {
        this.onValidityChange.subscribe(() => $rootScope.$applyAsync());
    }

    handleChange(items: Array<Object>) {
        this.$rootScope.$applyAsync();
        this.itemsChange.emit(items);
        this.onChange.emit(items);
    }
}
