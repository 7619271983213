import type {LabelingAnswer} from './labeling-answer';
import type {LabelingMetrics} from './labeling-metrics';

/**
 * Generated from com.dataiku.dip.labeling.LabelingTaskStats
 */
export interface LabelingTaskStats {
    globalRecordsStat: LabelingTaskStats.RecordsStat;
    perAnnotatorRecordsStats: {[key: string]: LabelingTaskStats.LabelerStats};
    perClassCount: {[key: string]: number};
}

export namespace LabelingTaskStats {
    /**
     * Generated from com.dataiku.dip.labeling.LabelingTaskStats$RecordStatus
     */
    export enum RecordStatus {
        TO_LABEL = 'TO_LABEL',
        PARTIALLY_LABELED = 'PARTIALLY_LABELED',
        READY_FOR_REVIEW = 'READY_FOR_REVIEW',
        REVIEWED = 'REVIEWED'
    }

    /**
     * Generated from com.dataiku.dip.labeling.LabelingTaskStats$LabelerStats
     */
    export interface LabelerStats {
        metrics: LabelingMetrics;
        perAnswerStatusCount: {[key in LabelingAnswer.AnswerStatus]: number};
    }

    /**
     * Generated from com.dataiku.dip.labeling.LabelingTaskStats$RecordsStat
     */
    export interface RecordsStat {
        perRecordStatusCount: {[key in LabelingTaskStats.RecordStatus]: number};
    }
}