<p>
    The Kwiatkowski-Phillips-Schmidt-Shin test tests the hypothesis that
    <b>{{ params.seriesColumn.name }}</b> is {{ stationarityLabel }},
    with the alternate hypothesis that it is not {{ stationarityLabel }}.
</p>

<p>
    There are two possible outcomes depending on the 𝘱-value:
</p>

<ul>
    <li>
        <b>
            𝘱-value &le; significance level (&le; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>

        <br>
        The hypothesis is rejected.
        <b>{{ params.seriesColumn.name }}</b> is not {{ stationarityLabel }}.
        It indicates that the series may admit a unit root.
    </li>

    <li>
        <b>
            𝘱-value is &gt; significance level (&gt; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>

        <br>
        Not enough evidence against the hypothesis. Test is inconclusive about
        whether <b>{{ params.seriesColumn.name }}</b> is {{ stationarityLabel }}.
    </li>
</ul>
