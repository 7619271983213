import type {ColumnDesc} from './../joinlike/column-desc';
import type {ComputedColumn} from './../computedcolumn/computed-column';
import type {FilterDesc} from './../../../../../../../../../dss-core/src/main/java/com/dataiku/dip/dataflow/exec/filter/filter-desc';
import type {JoinInputDescBase} from './../joinlike/join-input-desc-base';
import type {_JoinDescBase} from './../joinlike/join-desc-base';
import type {_JoinLikeRecipePayloadParams} from './../joinlike/join-like-recipe-payload-params';
import type {_MatchingConditionBase} from './../joinlike/matching-condition-base';

/**
 * Generated from com.dataiku.dip.dataflow.exec.join.JoinRecipePayloadParams
 */
export interface _JoinRecipePayloadParams extends _JoinLikeRecipePayloadParams {
    computedColumns: ComputedColumn[];
    enableAutoCastInJoinConditions: boolean;
    postFilter: FilterDesc;
}

export namespace _JoinRecipePayloadParams {
    /**
     * Generated from com.dataiku.dip.dataflow.exec.join.JoinRecipePayloadParams$JoinDesc
     */
    export interface _JoinDesc extends _JoinDescBase {
        customSQLCondition: string;
        outerJoinOnTheLeft: boolean;
        rightLimit: _JoinRecipePayloadParams.RightLimitDesc;
    }

    /**
     * Generated from com.dataiku.dip.dataflow.exec.join.JoinRecipePayloadParams$RightLimitDesc
     */
    export interface RightLimitDesc {
        decisionColumn: ColumnDesc;
        enabled: boolean;
        maxMatches: number;
        strict: boolean;
        type: _JoinRecipePayloadParams.SelectionType;
    }

    /**
     * Generated from com.dataiku.dip.dataflow.exec.join.JoinRecipePayloadParams$InputDesc
     */
    export interface InputDesc extends JoinInputDescBase {}

    /**
     * Generated from com.dataiku.dip.dataflow.exec.join.JoinRecipePayloadParams$MatchingCondition
     */
    export interface MatchingCondition extends _MatchingConditionBase {
        caseInsensitive: boolean;
        dateDiffUnit: string;
        maxDistance: number;
        maxMatches: number;
        normalizeText: boolean;
        strict: boolean;
        type: _JoinRecipePayloadParams.MatchingType;
        windowFrom: number;
        windowTo: number;
    }

    /**
     * Generated from com.dataiku.dip.dataflow.exec.join.JoinRecipePayloadParams$SelectionType
     */
    export enum SelectionType {
        KEEP_ALL = 'KEEP_ALL',
        KEEP_LARGEST = 'KEEP_LARGEST',
        KEEP_SMALLEST = 'KEEP_SMALLEST'
    }

    /**
     * Generated from com.dataiku.dip.dataflow.exec.join.JoinRecipePayloadParams$MatchingType
     */
    export enum MatchingType {
        EQ = '=',
        LTE = '<=',
        LT = '<',
        GTE = '>=',
        GT = '>',
        NE = '!=',
        WITHIN_RANGE = 'Within range',
        K_NEAREST = 'Nearest match',
        K_NEAREST_INFERIOR = 'Nearest match before',
        CONTAINS = 'Contains',
        STARTS_WITH = 'Starts with',
        WITHIN_WINDOW_OF = 'Within window of'
    }
}