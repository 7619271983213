<div class="right-panel-accordion__title"
    [class.right-panel-accordion__title--with-border]="showTopBorder"
    (click)="toggleOpened()">
    <h4>
        <ng-content select="[title]"></ng-content>
    </h4>
    <i [class]="internalOpenedState ? 'dku-icon-chevron-up-16' : 'dku-icon-chevron-down-16'"></i>
</div>

<div [hidden]="!internalOpenedState" class="right-panel-accordion__body">
    <ng-content select="[content]"></ng-content>
</div>