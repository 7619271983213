<div class="modal">
    <modal-header
        [title]="'Duplicate worksheet'"
        [hasBorder]="false"
    ></modal-header>
    <div class="modal-body">
        <api-error-alert
            [apiError]="apiError"
            (resetError)="apiError = undefined">
        </api-error-alert>
        <form class="dkuform-modal-horizontal" [formGroup]="form">
            <div class="control-group">
                <label class="control-label">Project</label>
                <div class="controls">
                    <dku-bs-select-form-control [params]="projectSelectParams" [dataLiveSearch]="true" [list]="projects" formControlName="project" ngOptions="project.projectKey as project.name for project in list"></dku-bs-select-form-control>
                </div>
            </div>
            <div class="control-group modal-first-cg-clear-totem" ng-show="selectedProject">
                <label class="control-label">Dataset</label>
                <div class="controls">
                    <dss-accessible-objects-selector
                        formControlName="dataset"
                        [objects]="datasets"
                        [type]="ITaggingService.TaggableType.DATASET">
                    </dss-accessible-objects-selector>
                    <div class="help-inline" *ngIf="!datasets.length">
                        <a (click)="importDataset(form.get('project')!.value)">No dataset in this project: Import a new dataset</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer modal-footer-std-buttons">
        <div class="pull-right">
            <button type="button" class="btn btn--text btn--secondary" (click)="dialogRef.close(false)">Cancel</button>
            <button type="button" class="btn btn--text btn--primary" (click)="confirm()" [disabled]="!form.valid">
                Confirm
            </button>
        </div>
    </div>
</div>