import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Chi2IndTestCard } from 'src/generated-sources';
import _ from 'lodash';
import { scaleLinear } from 'd3-scale';
import { CardAction } from '../../events';

@Component({
    selector: 'chi2-ind-test-card-body',
    templateUrl: './chi2-ind-test-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/test-conclusion.less',
        '../../../../shared-styles/card-layout.less',
        '../../../../shared-styles/stats-table.less',
        './chi2-ind-test-card-body.component.less'
    ]
})
export class Chi2IndTestCardBodyComponent implements OnChanges {
    @Input() params: Chi2IndTestCard;
    @Input() results: Chi2IndTestCard.Chi2IndTestCardResult;
    @Output() action = new EventEmitter<CardAction>();

    cellColors: string[];

    get isRejected() {
        return this.results.pvalue <= (1 - this.params.confidenceLevel);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.results) {
            this.rebuildColorScale();
        }
    }

    rebuildColorScale() {
        // Compute how much each cell contributes to the chi2 score
        const chi2contribution = _.zip(
            this.results.freqTableData.counts,
            this.results.freqTableData.expectedCounts
        ).map(([actual, expected], flatIndex) => {
            const xIdx = Math.floor(flatIndex / this.results.freqTableData.yValues.length);
            const yIdx = Math.floor(flatIndex % this.results.freqTableData.yValues.length);
            // Check if X and Y values are actual values (=> not "others")
            if (this.results.freqTableData.xValues[xIdx].type === 'anum'
                && this.results.freqTableData.yValues[yIdx].type === 'anum') {
                return Math.pow(actual! - expected!, 2) / expected!;
            }
            // Ignore the difference if one of the axes is "others", because we don't display it
            return 0;
        });
        const maxContribution = _.max(chi2contribution) || 0;
        const colorScale = scaleLinear<string>().domain([0, maxContribution]).range(['#888888', '#CC2222']);

        this.cellColors = chi2contribution.map(sqDiff => colorScale(sqDiff));
    }
}
