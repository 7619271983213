<modal-header [title]="modalTitle" [hasBorder]="true"></modal-header>
<div class="modal-body">
    <dataset-preview
        [projectKey]="data.projectKey"
        [datasetName]="data.id"
    ></dataset-preview>
</div>
<div class="modal-footer modal-footer-std-buttons">
    <div class="pull-right">
        <button type="button" class="btn btn--text btn--secondary" (click)="reload()">
            Reload Preview
        </button>
        <button type="button" class="btn btn--text btn--primary" (click)="close()" autofocus>
            Close
        </button>
    </div>
</div>
