import { Component, Input, ChangeDetectionStrategy, Optional, EventEmitter, Output, ViewContainerRef } from '@angular/core';
import { TZTest1SampCard } from 'src/generated-sources';
import { ModalsService, ModalShape } from '@shared/modals/modals.service';
import { CardAction, CardActionType } from '../../events';
import { EditCardModalComponent } from '../../../card-wizard/edit-card-modal/edit-card-modal.component';
import { createShapiroCard } from '../card-creation-templates';

@Component({
    selector: 'tztest1-samp-card-help',
    templateUrl: './tztest1-samp-card-help.component.html',
    styleUrls: ['./tztest1-samp-card-help.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TZTest1SampCardHelpComponent {
    @Input() params: TZTest1SampCard;
    @Input() readOnly: boolean;
    @Input() extendedActions: boolean;
    @Output() action = new EventEmitter<CardAction>();

    constructor(
        private modalsService: ModalsService,
        private viewContainerRef: ViewContainerRef
    ) {
    }

    get canAddCard() {
        return !this.readOnly && this.extendedActions;
    }

    addShapiro() {
        if (!this.canAddCard) {
            return;
        }

        const proposedCard = createShapiroCard(this.params.column);
        this.modalsService.open(EditCardModalComponent, { params: proposedCard }, ModalShape.NONE, this.viewContainerRef)
            .then(({ card })  => {
                this.action.emit({
                    type: CardActionType.ADD,
                    card
                });
            }, () => { });
    }
}
