import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { ShapiroNormalityTestCard } from 'src/generated-sources';
import { CardAction } from '../../events';

@Component({
    selector: 'shapiro-normality-test-card-help',
    templateUrl: './shapiro-normality-test-card-help.component.html',
    styleUrls: ['./shapiro-normality-test-card-help.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShapiroNormalityTestCardHelpComponent {
    @Input() params: ShapiroNormalityTestCard;
    @Input() readOnly: boolean;
    @Input() extendedActions: boolean;
    @Output() action = new EventEmitter<CardAction>();
}

