import type {VerifiedLabel} from './verified-label';
import type {_BaseLabelingAnswer} from './base-labeling-answer';

/**
 * Generated from com.dataiku.dip.labeling.LabelingAnswer
 */
export interface LabelingAnswer extends _BaseLabelingAnswer {
    annotatorId: string;
    reviewTime: number;
    status: LabelingAnswer.AnswerStatus;
    verifiedLabel?: VerifiedLabel | null;
}

export namespace LabelingAnswer {
    /**
     * Generated from com.dataiku.dip.labeling.LabelingAnswer$AnswerStatus
     */
    export enum AnswerStatus {
        PENDING_REVIEW = 'PENDING_REVIEW',
        REVIEWED = 'REVIEWED'
    }
}