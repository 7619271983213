import { Component, Input, forwardRef } from '@angular/core';
import { EditableListBase } from '../editable-list/editable-list.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'connection-properties-list',
    templateUrl: './connection-properties-list.component.html',
    styleUrls: ['./connection-properties-list.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ConnectionPropertiesListComponent),
            multi: true
        }
    ]
})

/**
 * List of connection properties.
 * Uses CredentialsListComponent with a dedicated template.
 * 
 * @example
 * <connection-properties-list items="connection.properties"></connection-properties-list>
 */
export class ConnectionPropertiesListComponent extends EditableListBase {
    @Input() addLabel: string = 'Add Property';
    @Input() keyPlaceholder: string = 'Property name';
    @Input() valuePlaceholder: string = 'Property value';
    @Input() canBeFinal: boolean = false;
    @Input() keyRequired: boolean = true;
    @Input() valueRequired: boolean = true;
    @Input() required: boolean = true;
    @Input() keyIdentifier: string = 'name';
    @Input() valueIdentifier: string = 'value';

    constructor() {
        super();
    }
}
