import { Component, Input, EventEmitter, Output, SimpleChanges, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { FitDistributionCard, IntervalFilter } from 'src/generated-sources';
import type { EChartsOption, SeriesOption } from 'echarts';
import _ from 'lodash';
import { scaleLinear } from 'd3-scale';
import { CardAction } from '@features/eda/worksheet/cards/events';
import { ColorsService } from '@shared/graphics/colors.service';
import { FilterNamePipe } from '@features/eda/pipes/filter-name.pipe';
import { encodeHTML } from 'entities';
import { DistributionNamePipe } from '@features/eda/pipes/distribution-name.pipe';
import { smarterNumber } from '@shared/pipes/number-pipes/smarter-number.pipe';

const distributionNamePipe = new DistributionNamePipe();

@Component({
    selector: 'fit-distribution-card-body',
    templateUrl: './fit-distribution-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/fit-table.less',
        '../../../../shared-styles/chart.less',
        '../../../../shared-styles/test-conclusion.less',
        '../../../../shared-styles/stats-table.less',
        './fit-distribution-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FitDistributionCardBodyComponent implements OnChanges {
    @Input() params: FitDistributionCard;
    @Input() results: FitDistributionCard.FitDistributionCardResult;
    @Output() action = new EventEmitter<CardAction>();

    densityChartOptions: EChartsOption;
    qqPlotOptions: EChartsOption;

    constructor(public colorsService: ColorsService) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results) {
            this.buildDensityCharts();
            this.buildQQPlots();
        }
    }

    buildQQPlots() {
        const series: SeriesOption[] = [];
        let maxVal: number | undefined;
        let minVal: number | undefined;
        let minAbsDiff: number | undefined;
        let maxAbsDiff: number | undefined;

        type QQDataPoint = [number, number, number];

        for (let i = 0; i < this.results.distributions.length; i++) {
            const fitResult = this.results.distributions[i];
            const qqData: QQDataPoint[] = fitResult.plot.qq.theoretical
                .map((theoretical, idx) => {
                    const empirical = fitResult.plot.qq.empirical[idx];
                    const absDiff = Math.abs(empirical - theoretical);
                    minVal = minVal === undefined ? Math.min(empirical, theoretical) : Math.min(empirical, theoretical, minVal);
                    maxVal = maxVal === undefined ? Math.max(empirical, theoretical) : Math.max(empirical, theoretical, maxVal);
                    minAbsDiff = minAbsDiff !== undefined ? Math.min(minAbsDiff, absDiff) : absDiff;
                    maxAbsDiff = maxAbsDiff !== undefined ? Math.max(maxAbsDiff, absDiff) : absDiff;
                    return [theoretical, fitResult.plot.qq.empirical[idx], absDiff];
                });


            const sizeScale = scaleLinear()
                .domain([minAbsDiff!, maxAbsDiff!]).range([3, 7]);

            series.push({
                symbolSize: (data: QQDataPoint) => sizeScale(data[2]),
                data: qqData,
                type: 'scatter',
                itemStyle: {
                    color: this.colorsService.getColorFromIndex(i),
                    opacity: 0.6
                },
                large: true
            });
        }

        if(minVal != null && maxVal != null) {
            series.push({
                type: 'line',
                itemStyle: {
                    opacity: 0
                },
                lineStyle: {
                    color: '#DDDDDD'
                },
                data: [
                    [minVal, minVal],
                    [maxVal, maxVal]
                ]
            });
        }

        this.qqPlotOptions = {
            tooltip: {
                trigger: 'none',
                axisPointer: { type: 'cross' }
            },
            animation: false,
            grid: {
                left: 30,
                top: 10,
                right: 10,
                bottom: 10,
                containLabel: true,
            },
            xAxis: {
                name: 'Theoretical',
                type: 'value',
                nameLocation: 'middle',
                nameGap: 20,
                axisPointer: {
                    label: {
                        formatter: ({ value }) => {
                            return this.params.column.name + ' (theoretical): ' + smarterNumber(Number(value));
                        }
                    }
                }
            },
            yAxis: {
                name: 'Empirical',
                type: 'value',
                nameLocation: 'middle',
                nameGap: 40,
                axisPointer: {
                    label: {
                        formatter: ({ value }) => {
                            return this.params.column.name + ' (empirical): ' + smarterNumber(Number(value));
                        }
                    }
                }
            },
            series
        };
    }

    buildDensityCharts() {
        // Histogram
        const filterName = new FilterNamePipe();
        const histogramSeriesData = [];
        const histogramData = this.results.histogram;
        const histogramLabels = this.results!.histogram!.bins.map(b => filterName.transform(b));
        const totalCount = _.chain(this.results.histogram.counts).sum().value();
        const legendData = [];

        for (let i = 0; i < histogramData.bins.length; i++) {
            const bin = histogramData.bins[i] as IntervalFilter;
            const count = histogramData.counts[i] / (totalCount * (bin.right - bin.left));
            histogramSeriesData.push([bin.left, bin.right, count]);
        }

        let maxDensity = _.chain(histogramSeriesData).map(2).max().value();
        const minVal = _.chain(histogramSeriesData).map(0).min().value();
        const maxVal = _.chain(histogramSeriesData).map(1).max().value();

        // Density of distributions
        const distributionLines: SeriesOption[] = [];

        for (let i = 0; i < this.results.distributions.length; i++) {
            const distribution = this.results.distributions[i];
            const name = i.toString();
            legendData.push(name);

            if (!distribution.plot.pdf) {
                continue;
            }

            const pdfPlot = distribution.plot.pdf;
            const seriesData = pdfPlot.xvals.map((x, idx) => [x, pdfPlot.probs[idx]]);
            maxDensity = Math.max(_.chain(seriesData).map(1).max().value(), maxDensity);

            distributionLines.push({
                name,
                type: 'line',
                yAxisIndex: 0,
                data: seriesData,
                symbol: 'none',
                itemStyle: {
                    color: this.colorsService.getColorFromIndex(i)
                },
            });
        }

        this.densityChartOptions = {
            color: ['#3398DB'],
            animation: false,
            grid: {
                left: 10,
                top: 20,
                right: 10,
                bottom: 0,
                containLabel: true,
            },
            tooltip: {
                confine: true,
                trigger: 'item',
                axisPointer: { type: 'none' }
            },
            legend: {
                textStyle: {
                    color: '#333',
                    fontSize: 13
                },
                type: 'scroll',
                padding: [0, 0, 15, 0],
                data: legendData,
                formatter: (index: string) => {
                    return distributionNamePipe.transform(this.results.distributions[+index].distribution);
                }
            },
            xAxis: {
                type: 'value',
                min: minVal,
                max: maxVal,
                axisTick: { show: true },
                axisLine: { show: true },
                axisLabel: { color: '#999999' }
            },
            yAxis: {
                type: 'value',
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: { show: false },
                splitLine: { show: false },
                min: 0,
                max: maxDensity
            },
            series: [
                ...distributionLines,
                {
                    type: 'custom',
                    tooltip: {
                        formatter: (value: any) => {
                            const dataIndex = value.dataIndex;
                            return encodeHTML(this.params.column.name)
                                + ': <b>'
                                + encodeHTML(histogramLabels[dataIndex])
                                + '</b><br>Count: <b>'
                                + encodeHTML('' + histogramData.counts[dataIndex])
                                + '</b>';
                        }
                    },
                    renderItem: (params: any, api: any) => {
                        const yValue = api.value(2);
                        const start = api.coord([api.value(0), yValue]);
                        const size = api.size([api.value(1) - api.value(0), yValue]);

                        return {
                            type: 'rect',
                            shape: {
                                x: start[0],
                                y: start[1],
                                width: size[0],
                                height: size[1]
                            },
                            style: api.style(),
                            emphasisStyle: api.style()
                        };
                    },
                    itemStyle: {
                        normal: { color: '#c4dffe', borderColor: '#c4dffe', borderWidth: 1 }
                    },
                    data: histogramSeriesData
                } as SeriesOption // Cast required because renderItem() typing is not really usable
            ]
        };
    }

}
