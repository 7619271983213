import { Injectable } from "@angular/core";
import { CellData } from "../items-data-fetcher.service";
import { ColumnMetadataWithIndex, ImportantColumnsMetadata } from "./sample-data-fetcher.service";

@Injectable()
export abstract class SampleDataFormatterService {
    abstract rowToCellData(rowContent: string[], columnsMetadata: ImportantColumnsMetadata<ColumnMetadataWithIndex>, i: number): CellData;
}


@Injectable()
export class SimpleDataFormatterService extends SampleDataFormatterService {
    rowToCellData(rowContent: string[], columnsMetadata: ImportantColumnsMetadata<ColumnMetadataWithIndex>, i: number): CellData {
        return {
            itemId: rowContent[columnsMetadata.single.itemPath.index],
            itemIndex: i
        }
    }
}