<edit-typeahead [ngModel]="value"
    (ngModelChange)="handleChange($event)"
    [name]="name"
    [placeholder]="placeholder"
    [required]="required"
    [suggestions]="suggestions"
    [displayFn]="displayFn"
    [valueFn]="valueFn"
    [autocompletePanelWidth]="autocompletePanelWidth ?? 'auto'"
    [noMatchTooltip]="noMatchTooltip"
    (onFocus)="onFocus.emit($event)"
    (onBlur)="onBlur.emit($event)"
    (onEnter)="onBlur.emit($event)">
</edit-typeahead>
