import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.bivariate.Pearson
 */
export interface Pearson extends Computation._BivariateComputation {
    // PolyJson type
    type: 'pearson';
}

export namespace Pearson {
    export const type = 'pearson';
}

/** @deprecated Use Computation.isPearson() */ export function isPearson(toTest: Computation): toTest is Pearson { return Pearson.type === toTest['type']; }

export namespace Pearson {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.bivariate.Pearson$PearsonResult
     */
    export interface PearsonResult extends _AvailableResult {
        correlation: number;
        pvalue: number;

        // PolyJson type
        type: 'pearson';
    }

    export namespace PearsonResult {
        export const type = 'pearson';
    }

    /** @deprecated Use ComputationResult.isPearsonResult() */ export function isPearsonResult(toTest: ComputationResult): toTest is PearsonResult { return PearsonResult.type === toTest['type']; }
}