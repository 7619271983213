import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { LabelingTaskPrivileges } from '@features/labeling/labeling.component';
import { LabelingReviewDocumentsDataFetcherService } from '@features/labeling/services/labeling-review-documents-data-fetcher.service';
import { ItemsDataFetcherService } from '@shared/services/item-feed/items-data-fetcher.service';
import { LabelingReviewService } from '../services/labeling-review.service';

@Component({
    selector: 'text-labeling-task-review',
    templateUrl: './text-labeling-task-review.component.html',
    styleUrls: ['./text-labeling-task-review.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LabelingReviewService,
        LabelingReviewDocumentsDataFetcherService,
        { provide: ItemsDataFetcherService, useExisting: LabelingReviewDocumentsDataFetcherService }]
})
export class TextLabelingTaskReviewComponent {
    @Input() privilegesOnTask: LabelingTaskPrivileges;
}
