<div [style.height]="imageHeight + 'px'" class="w100">
    <div class="augmented-images-container">
        <div class="augmented-images" *ngIf="augmentedImages$ | async as augmentedImages">
            <div [style.height]="imageHeight + 'px'" class="augmented-image" [class.multiple-line-images]="numAugmentedVersions > 3"
                 *ngFor="let augmentedImage of augmentedImages">
                <img *ngIf="(showAugmentedVersions$ | async) && !augmentedImage?.failed"
                     [src]="'data:image/png;base64,' + augmentedImage.imgDataAsBase64">
                <image-placeholder *ngIf="!(showAugmentedVersions$ | async) || (!augmentedImage || augmentedImage.failed)"
                                   [hasLoaded]="!(loadingAugmentedImg$ | async)"
                                   [size]="imageHeight"
                                   [noImageMessage]="(disabledAugmentation$ | async) ? 'Augmentation disabled': 'Augmentation failed'">
                </image-placeholder>
            </div>
        </div>
        <div *ngIf="!(disabledAugmentation$ | async) && legend && legend.length > 0" class="augmented-image__legend">
            {{legend}}
        </div>
    </div>
</div>
