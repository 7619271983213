import type {BivariateBoxPlotCard} from './bivariate_header/bivariate-box-plot-card';
import type {BivariateFrequencyTableCard} from './bivariate_header/bivariate-frequency-table-card';
import type {BivariateHistogramCard} from './bivariate_header/bivariate-histogram-card';
import type {BivariateSummaryStatsCard} from './bivariate_header/bivariate-summary-stats-card';
import type {Card, _Card} from './card';
import type {Chi2IndTestCard} from './chi2-ind-test-card';
import type {Fit2DDistributionCard} from './fit2-ddistribution-card';
import type {FitCurveCard} from './fit-curve-card';
import type {KsTest2SampCard} from './ks-test2-samp-card';
import type {MoodTest2SampCard} from './mood-test2-samp-card';
import type {MoodTestNSampCard} from './mood-test-nsamp-card';
import type {MosaicPlotCard} from './bivariate_header/mosaic-plot-card';
import type {OneWayANOVACard} from './one-way-anovacard';
import type {PairwiseMoodTestCard} from './pairwise-mood-test-card';
import type {PairwiseTTestCard} from './pairwise-ttest-card';
import type {ScatterPlotCard} from './bivariate_header/scatter-plot-card';
import type {TTest2SampCard} from './ttest2-samp-card';
import type {Variable} from './../models/variable';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.BivariateCard
 */
export interface _BivariateCard extends _Card {
    xColumn: Variable;
    yColumn: Variable;
}

export type BivariateCard = Chi2IndTestCard | Fit2DDistributionCard | FitCurveCard | KsTest2SampCard | MoodTest2SampCard | MoodTestNSampCard | OneWayANOVACard | PairwiseMoodTestCard | PairwiseTTestCard | TTest2SampCard | BivariateBoxPlotCard | BivariateFrequencyTableCard | BivariateHistogramCard | BivariateSummaryStatsCard | MosaicPlotCard | ScatterPlotCard;

export namespace BivariateCard {
    export const type = ['chi2_independence_test', 'fit_2d_distribution', 'fit_curve', 'ks_test_2samp', 'mood_test_2samp', 'mood_nsamp', 'oneway_anova', 'pairwise_mood', 'pairwise_ttest', 'ttest_2samp', 'bivariate_box_plot', 'bivariate_frequency_table', 'bivariate_histogram', 'bivariate_summary', 'mosaic_plot', 'scatter_plot'] as const;
}

/** @deprecated Use Card.isBivariateCard() */ export function isBivariateCard(toTest: Card): toTest is BivariateCard { return (BivariateCard.type as readonly unknown[]).includes(toTest['type']); }