import { Component, Input } from '@angular/core';
import { PainterService } from "@shared/services/item-feed/image-feed/painter.service";
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { PredictionMLTask } from 'src/generated-sources';
import { DeephubDesignDataFetcherService } from '@features/deephub/computer-vision/design/services/deephub-design-data-fetcher.service';
import { ObjectDetectionDataFormatterService } from '@shared/services/item-feed/image-feed/object-detection/object-detection-data-formatter.service';
import { ObjectDetectionPainterService } from '@shared/services/item-feed/image-feed/object-detection/object-detection-painter.service';
import { SampleDataFormatterService } from '@shared/services/item-feed/image-feed/sample-data-formatter.service';
import { SampleDataFetcherService } from '@shared/services/item-feed/image-feed/sample-data-fetcher.service';
import { ItemsDataFetcherService } from '@shared/services/item-feed/items-data-fetcher.service';
import { ImagesDataFetcherService } from '@shared/services/item-feed/image-feed/images-data-fetcher.service';

@Component({
    selector: 'deephub-object-detection-design-image-feed',
    templateUrl: 'deephub-object-detection-design-image-feed.component.html',
    providers: [
        ColorMapContextService,
        DeephubDesignDataFetcherService,
        ObjectDetectionDataFormatterService,
        ObjectDetectionPainterService,
        { provide: SampleDataFormatterService, useExisting: ObjectDetectionDataFormatterService},
        { provide: ImagesDataFetcherService, useExisting: DeephubDesignDataFetcherService},
        { provide: ItemsDataFetcherService, useExisting: DeephubDesignDataFetcherService },
        { provide: SampleDataFetcherService, useExisting: DeephubDesignDataFetcherService },
        { provide: PainterService, useExisting: ObjectDetectionPainterService },
    ]
})
export class DeephubObjectDetectionDesignImageFeedComponent {
    @Input() mltask: PredictionMLTask.DeepHubPredictionMLTask;
    @Input() selectedCategories?: string[];
    // Explicitly pass pathColumn and managedFolderSmartId to trigger change from Angular
    @Input() pathColumn: string;
    @Input() managedFolderSmartId: string;
};
