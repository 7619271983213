<ng-container *ngIf="results.reason === 'NOT_COMPUTED'">
    <span class="dku-loader icon-spin card-spinner" [class.m8]="params.type === 'univariate_header' || params.type === 'bivariate_header'"></span>
</ng-container>

<ng-container *ngIf="results.reason === 'FAILURE'">
    <ng-container *ngIf="errorCode">
        <div class="alert alert-error api-error-alert">
        <div class="api-error-alert__contents">
            <h4 class="api-error-alert__title" ><i class="icon-dku-error api-error-alert__icon mright8"></i>{{errorCode}}</h4>
            <h5 class="api-error-alert__subtitle" *ngIf="errorCode != results.message">{{results.message}}</h5>
        </div>
    </div>
    </ng-container>
    <ng-container *ngIf="!errorCode">
        <div class="alert alert-danger">
            <i class="icon-warning-sign"></i>
            {{ results.message }}
        </div>
    </ng-container>
</ng-container>
