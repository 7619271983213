import { Component, OnInit, TemplateRef, Input, ChangeDetectionStrategy } from '@angular/core';
import { concat, Observable, ReplaySubject } from 'rxjs';
import { debounceTime, startWith, switchMap, take } from 'rxjs/operators';
import { lazyRenderingValve, observeInput } from '../../utils/rxutils';
import { WindowService } from '../../window.service';

@Component({
    selector: 'lazy-render',
    templateUrl: './lazy-render.component.html',
    styleUrls: ['./lazy-render.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LazyRenderComponent implements OnInit {
    @Input() trackData: any;
    trackData$ = observeInput(this, 'trackData');

    @Input() passThrough = false;
    passThrough$ = observeInput(this, 'passThrough');

    @Input() visibleTemplate: TemplateRef<any>;
    @Input() invisibleTemplate: TemplateRef<any>;

    visibility$ = new ReplaySubject<boolean>(1);
    displayData$: Observable<any>;

    constructor(private windowService: WindowService) {
    }

    ngOnInit() {
        this.displayData$ = this.passThrough$.pipe(
            switchMap(passThrough => passThrough ? this.trackData$ : lazyRenderingValve(
                this.trackData$.pipe(startWith(true)),
                concat(
                    this.visibility$.pipe(take(1)),
                    this.visibility$.pipe(debounceTime(300))
                ),
                this.windowService.resize$
            ))
        );
    }

    onIntersection({ visible }: { visible: boolean }) {
        this.visibility$.next(visible);
    }
}
