import { DkuHttpService } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UsersService } from '@model-main/server/services/users-service';

@Injectable({providedIn: 'root'})
export class PrivateSecurityAPI {

    constructor(private dkuHttp: DkuHttpService) { }

    listUsers(projectKey?: string): Observable<UsersService.UIUser[]> {
        return this.dkuHttp.request('GET', '/security/list-users', { projectKey });
    }

    listConnectedUsers(projectKey?: string): Observable<any> {
        return this.dkuHttp.request('GET', '/security/list-connected-users', { projectKey });
    }

    listGroups(localOnly: boolean): Observable<string[]> {
        return this.dkuHttp.request('GET', '/security/list-groups', { localOnly });
    }

    listGroupsFull(): Observable<any> {
        return this.dkuHttp.request('GET', '/security/list-groups-full');
    }
}
