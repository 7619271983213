import type {Distribution, _Distribution} from './distribution';

/**
 * Generated from com.dataiku.dip.eda.compute.distributions.Triangular
 */
export interface Triangular extends _Distribution {
    // PolyJson type
    type: 'triangular';
}

export namespace Triangular {
    export const type = 'triangular';
}

/** @deprecated Use Distribution.isTriangular() */ export function isTriangular(toTest: Distribution): toTest is Triangular { return Triangular.type === toTest['type']; }

export namespace Triangular {
    /**
     * Generated from com.dataiku.dip.eda.compute.distributions.Triangular$FittedTriangular
     */
    export interface FittedTriangular extends Distribution._FittedDistribution {
        a: number;
        b: number;
        c: number;

        // PolyJson type
        type: 'triangular';
    }

    export namespace FittedTriangular {
        export const type = 'triangular';
    }

    /** @deprecated Use Distribution.FittedDistribution.isFittedTriangular() */ export function isFittedTriangular(toTest: Distribution.FittedDistribution): toTest is FittedTriangular { return FittedTriangular.type === toTest['type']; }
}