import { SmartNumberPipe } from '@shared/pipes/number-pipes/smart-number.pipe';

export function formatTime(value: number, maxDuration: number): string {
    const numberFormatter = new SmartNumberPipe();
    let unit = 'h';
    let factor = 1 / (1000 * 60 * 60);
    if (maxDuration <= 1000) {
        unit = 'ms';
        factor = 1;
    } else if (maxDuration <= 1000 * 60) {
        unit = 's';
        factor = 1 / 1000;
    } else if (maxDuration <= 1000 * 60 * 60) {
        unit = 'm';
        factor = 1 / (1000 * 60);
    }
    return numberFormatter.transform(value * factor) + unit;
}