/**
 * Generated from com.dataiku.dip.eda.compute.resampling.ResamplerSettings
 */
export interface ResamplerSettings {
    duplicateTimestampsHandlingMethod: ResamplerSettings.DuplicateTimestampsHandlingMethod;
    extrapolationConstantValue?: number | null;
    extrapolationMethod: ResamplerSettings.ExtrapolationMethod;
    interpolationConstantValue?: number | null;
    interpolationMethod: ResamplerSettings.InterpolationMethod;
    nUnits: number;
    timeUnit: ResamplerSettings.TimeUnit;
    timeUnitEndOfWeek?: ResamplerSettings.DayOfWeek | null;
}

export namespace ResamplerSettings {
    /**
     * Generated from com.dataiku.dip.eda.compute.resampling.ResamplerSettings$InterpolationMethod
     */
    export enum InterpolationMethod {
        NEAREST = 'NEAREST',
        PREVIOUS = 'PREVIOUS',
        NEXT = 'NEXT',
        LINEAR = 'LINEAR',
        QUADRATIC = 'QUADRATIC',
        CUBIC = 'CUBIC',
        CONSTANT = 'CONSTANT'
    }

    /**
     * Generated from com.dataiku.dip.eda.compute.resampling.ResamplerSettings$ExtrapolationMethod
     */
    export enum ExtrapolationMethod {
        PREVIOUS_NEXT = 'PREVIOUS_NEXT',
        NO_EXTRAPOLATION = 'NO_EXTRAPOLATION',
        CONSTANT = 'CONSTANT',
        LINEAR = 'LINEAR',
        QUADRATIC = 'QUADRATIC',
        CUBIC = 'CUBIC'
    }

    /**
     * Generated from com.dataiku.dip.eda.compute.resampling.ResamplerSettings$DayOfWeek
     */
    export enum DayOfWeek {
        MONDAY = 'MONDAY',
        TUESDAY = 'TUESDAY',
        WEDNESDAY = 'WEDNESDAY',
        THURSDAY = 'THURSDAY',
        FRIDAY = 'FRIDAY',
        SATURDAY = 'SATURDAY',
        SUNDAY = 'SUNDAY'
    }

    /**
     * Generated from com.dataiku.dip.eda.compute.resampling.ResamplerSettings$TimeUnit
     */
    export enum TimeUnit {
        MILLISECOND = 'MILLISECOND',
        SECOND = 'SECOND',
        MINUTE = 'MINUTE',
        HOUR = 'HOUR',
        BUSINESS_DAY = 'BUSINESS_DAY',
        DAY = 'DAY',
        WEEK = 'WEEK',
        MONTH = 'MONTH',
        QUARTER = 'QUARTER',
        HALF_YEAR = 'HALF_YEAR',
        YEAR = 'YEAR'
    }

    /**
     * Generated from com.dataiku.dip.eda.compute.resampling.ResamplerSettings$DuplicateTimestampsHandlingMethod
     */
    export enum DuplicateTimestampsHandlingMethod {
        FAIL_IF_CONFLICTING = 'FAIL_IF_CONFLICTING',
        DROP_IF_CONFLICTING = 'DROP_IF_CONFLICTING',
        MEAN_MODE = 'MEAN_MODE'
    }
}