<ng-container *ngIf="metricInfo?.hasInvalidValue">
    <a class="experiment-tracking-invalid-values" [dkuPopoverTrigger]="popover">
        <i class="icon-dku-warning experiment-tracking-invalid-values"></i>
    </a>
    <dku-popover #popover>
        <div *ngIf="showRuns">
            <ul class="raw-unstyled-ul">
                <li *ngFor="let invalidRunEntry of invalidRuns | keyvalue">
                    <a (click)="clickOnRunAndHidePopover(invalidRunEntry.value.runId)"><span class="run-color-square mleft4 mright4" [ngStyle]="{'background-color': getRunColor(invalidRunEntry.value.runId)}"></span>{{ invalidRunEntry.value.runName }}</a>:
                    <ul>
                        <li class="experiment-tracking-invalid-values" *ngFor="let invalidValuesWithDetails of invalidRunEntry.value.invalidValuesWithDetails">
                            Invalid value "{{ invalidValuesWithDetails.invalidValue }}"
                            <ng-container *ngIf="invalidRunEntry.value.hasManyValues">
                                at step "{{ invalidValuesWithDetails.step }}" and timestamp "{{ invalidValuesWithDetails.timestamp | date: "YYYY-MM-dd HH:mm:ss.SSS" }}"
                            </ng-container>
                        </li>
                        <li class="experiment-tracking-invalid-values" *ngIf="invalidRunEntry.value.additionalInvalidValueCount">
                            ... ({{ invalidRunEntry.value.additionalInvalidValueCount }} more invalid {{ 'value' | plurify: invalidRunEntry.value.additionalInvalidValueCount }})</li>
                    </ul>
                </li>
                <li class="experiment-tracking-invalid-values mtop8" *ngIf="additionalInvalidRunIds.size > 0">
                    ... (invalid values found in {{ additionalInvalidRunIds.size }} more {{ 'run' | plurify: additionalInvalidRunIds.size }})</li>
            </ul>
        </div>
        <div *ngIf="!showRuns">
            <ng-container *ngFor="let invalidRunEntry of invalidRuns | keyvalue">
                <ul class="raw-unstyled-ul">
                    <li class="experiment-tracking-invalid-values" *ngFor="let invalidValuesWithDetails of invalidRunEntry.value.invalidValuesWithDetails">
                        Invalid value "{{ invalidValuesWithDetails.invalidValue }}"
                        <ng-container *ngIf="invalidRunEntry.value.hasManyValues">
                            at step "{{ invalidValuesWithDetails.step }}" and timestamp "{{ invalidValuesWithDetails.timestamp | date: "YYYY-MM-dd HH:mm:ss.SSS" }}"
                        </ng-container>
                    </li>
                    <li class="experiment-tracking-invalid-values" *ngIf="invalidRunEntry.value.additionalInvalidValueCount">
                        ... ({{ invalidRunEntry.value.additionalInvalidValueCount }} more invalid {{ 'value' | plurify: invalidRunEntry.value.additionalInvalidValueCount }})</li>
                </ul>
            </ng-container>
        </div>
    </dku-popover>
</ng-container>