export function copyToClipboard(text: string) {
    const tempInput = document.createElement('textarea');
    tempInput.style.position = 'absolute';
    tempInput.style.left = '-1000px';
    tempInput.style.top = '-1000px';
    tempInput.value = text;
    document.body.appendChild(tempInput);
    try {
        tempInput.select();
        document.execCommand('copy');
    } catch (err) {
        throw new Error(`Unable to copy to clipboard ${err}`);
    } finally {
        document.body.removeChild(tempInput);
    }
}
