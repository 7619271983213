<div class="modal">
    <modal-header [hidden]="currentPanel !== ModalPanels.CREATE_SMV"
                  [title]="modalTitle"
                  [hasBorder]="false">
    </modal-header>
    <div class="modal-body"
         [hidden]="currentPanel !== ModalPanels.CREATE_SMV">
        <api-error-alert *ngIf="error"
                         [apiError]="error"
                         [closable]="true"
                         (resetError)="resetError()"></api-error-alert>
        <form class="dkuform-modal-horizontal"
              [formGroup]="form" [qa]="'experiments-run-model-deploy-form'">
            <div class="control-group">
                <label class="control-label">Model to deploy*</label>
                <div class="controls">
                    <dku-bs-select-form-control formControlName="modelName"
                                                ngOptions="model.artifactPath as model.artifactPath for model in list"
                                                [list]="run.data.models"
                                                [layout]="'list'"
                                                [dataLiveSearch]="true"
                                                [qa]="'experiments-run-model-deploy-form-modelName'">
                    </dku-bs-select-form-control>
                </div>
            </div>
            <div class="control-group">
                <label class="control-label">Saved Model*</label>
                <div class="controls">
                    <div class="ctrl-radio-saved-model">
                        <div class="dib" matTooltip="Cannot create a saved model from an automation node, please use an existing saved model from the list."
                             [matTooltipDisabled]="!isAutomationNode()">
                            <label [attr.disabled]="isAutomationNode() ? true : null">
                                <input [attr.disabled]="isAutomationNode() ? true : null" type="radio" class="control" formControlName="createNewSavedModelRB" [value]="true" [(ngModel)]="createNewSavedModel"/>
                                Create new
                            </label>
                        </div>
                        <label>
                            <input type="radio" class="control" formControlName="createNewSavedModelRB" [value]="false" [(ngModel)]="createNewSavedModel"/>
                            Use existing
                        </label>
                    </div>
                    <input *ngIf="createNewSavedModel" formControlName="newSavedModelName" type="text" [qa]="'experiments-run-model-creation-form-smName'"/>
                    <dku-bs-select-form-control *ngIf="!createNewSavedModel"
                                                formControlName="savedModel"
                                                ngOptions="sm.id as sm.label for sm in list"
                                                [list]="(savedModels$ | async) ?? []"
                                                [layout]="'list'"
                                                [dataLiveSearch]="true"
                                                matTooltip="Displaying {{runPredictionTypeName ? 'only models with prediction type \'' + runPredictionTypeName + '\'' : 'all models'}} "

                                                [qa]="'experiments-run-model-deploy-form-savedModel'">
                    </dku-bs-select-form-control>
                </div>
            </div>
            <div class="control-group">
                <label class="control-label">Prediction type*</label>
                <div class="controls">
                    <label *ngIf="run.predictionType || !createNewSavedModel"
                           [qa]="'experiments-run-model-deploy-form-predictionType'" >{{predictionType?.label}}</label>
                    <dku-bs-select-form-control *ngIf="!run.predictionType && createNewSavedModel"
                                                formControlName="predictionType"
                                                ngOptions="prediction_type.key as prediction_type.label for prediction_type in list"
                                                [list]="supportedPredictionTypes"
                                                [layout]="'list'"
                                                [dataLiveSearch]="false"
                                                [qa]="'experiments-run-model-creation-form-predictionType'">
                    </dku-bs-select-form-control>
                </div>
            </div>
            <div class="control-group">
                <label class="control-label">
                    Version ID*
                </label>
                <div class="controls">
                    <input formControlName="versionId" type="text" [qa]="'experiments-run-model-deploy-form-versionId'">
                    <span class="help-inline">Unique identifier of a Saved Model Version. If it already exists, existing
                        version is overwritten. Whitespaces are not authorized.</span>
                </div>
            </div>
            <div class="alert alert-warning mtop8"
                 *ngIf="savedModelVersions.includes(form.controls['versionId'].value)"
                 [qa]="'experiments-run-model-deploy-form-versionId-warning'">
                <i class="icon-dku-warning"></i> Model version {{ form.controls['versionId'].value }} already exists and
                will be overwritten.
            </div>
            <div class="control-group">
                <label class="control-label">Activate this version*</label>
                <div class="controls">
                    <input formControlName="activate" type="checkbox" [qa]="'experiments-run-model-deploy-form-activateVersion'">
                </div>
            </div>
            <div class="control-group">
                <label class="control-label">
                    Code Env*
                </label>
                <div class="controls">
                    <dku-bs-select-form-control formControlName="codeEnvName"
                                                ngOptions="codeEnv.envName as codeEnv.envName for codeEnv in list"
                                                [list]="codeEnvs"
                                                [optionsDescriptions]="codeEnvsDescriptions"
                                                [layout]="'list'"
                                                [dataLiveSearch]="true"
                                                [qa]="'experiments-run-model-deploy-form-codeEnvName'">
                    </dku-bs-select-form-control>
                    <span class="help-inline">Must contain a supported version of the mlflow package and the ML libs
                        used to train the model.</span>
                </div>
            </div>
            <div *ngIf="codeEnvCompatibilityStatus === COMPATIBILITY_STATUS_MAYBE_COMPATIBLE"
                 class="alert alert-warning mtop8"
                 [qa]="'experiments-run-model-deploy-form-codeEnvName-warning'">
                <i class="icon-dku-warning"></i> The model may not work with the selected code environment: {{ codeEnvMessage }}.
            </div>
            <div class="alert alert-error mtop8"
                 *ngIf="codeEnvCompatibilityStatus === COMPATIBILITY_STATUS_INCOMPATIBLE"
                 [qa]="'experiments-run-model-deploy-form-codeEnvName-error'">
                <i class="icon-dku-error"></i> Selected code environment does not meet requirements: {{ codeEnvMessage }}.
            </div>
            <ng-container *ngIf="predictionType && predictionType.key !== 'OTHER'; else predOther">
                <div class="control-group">
                    <label class="control-label">Evaluation Dataset*</label>
                    <div class="controls">
                        <dss-accessible-objects-selector [objects]="datasets"
                                                        [type]="'DATASET'"
                                                        [multi]="false"
                                                        formControlName="dataset"
                                                        [qa]="'experiments-run-model-deploy-form-dataset'">
                        </dss-accessible-objects-selector>
                    </div>
                </div>
                <div class="control-group">
                    <label for="samplingMethod" class="control-label control-dropdown-label">Sampling method*</label>
                    <div class="controls">
                        <dku-bs-select-form-control
                            ngOptions="x[0] as x[1] for x in list"
                            [list]="samplingMethods"
                            [layout]="'list'"
                            [optionsDescriptions]="samplingMethodDescriptions"
                            formControlName="samplingMethod"
                            [qa]="'experiments-run-model-deploy-form-sampling'"
                        ></dku-bs-select-form-control>
                    </div>
                </div>
                <div class="control-group" *ngIf="samplingMethodWithMaxRecords(form.value.samplingMethod)">
                    <label for="maxRecords" class="control-label control-dropdown-label">Number of records*</label>
                    <div class="controls">
                        <input
                            formControlName="maxRecords"
                            name="maxRecords"
                            min="1"
                            type="number"
                            [qa]="'experiments-run-model-deploy-form-maxRecords'"
                        >
                    </div>
                </div>
                <div class="control-group" *ngIf="samplingMethodWithRatio(form.value.samplingMethod)">
                    <label
                        for="targetRatio"
                        class="control-label control-dropdown-label"
                    >
                        Ratio*
                    </label>
                    <div class="controls">
                        <input
                            formControlName="targetRatio"
                            name="targetRatio"
                            min="0"
                            max="1"
                            step="0.1"
                            type="number"
                            [qa]="'experiments-run-model-deploy-form-targetRatio'"
                        >
                    </div>
                </div>
                <div class="control-group" *ngIf="samplingMethodWithColumn(form.value.samplingMethod)">
                    <label
                        for="samplingColumn"
                        class="control-label control-dropdown-label"
                    >
                        {{ samplingMethodWithAscending(form.value.samplingMethod) ? "Sorted by*" : "Column*" }}
                    </label>
                    <div class="controls">
                        <dku-bs-select-form-control ngOptions="name as name for name in list"
                            formControlName="samplingColumn"
                            [list]="datasetColumns"
                            [layout]="'list'"
                            [dataLiveSearch]="true"
                            [qa]="'experiments-run-model-deploy-form-samplingColumn'">
                        </dku-bs-select-form-control>
                    </div>
                </div>
                <div class="control-group" *ngIf="samplingMethodWithAscending(form.value.samplingMethod)">
                    <label class="control-label">Ascending order*</label>
                    <div class="controls">
                        <input formControlName="ascending" type="checkbox">
                    </div>
                </div>
                <div class="control-group" *ngIf="datasetColumns">
                    <label class="control-label">Target column*</label>
                    <div class="controls">
                        <dku-bs-select-form-control ngOptions="name as name for name in list"
                                                    formControlName="targetColumn"
                                                    [list]="datasetColumns"
                                                    [layout]="'list'"
                                                    [dataLiveSearch]="true"
                                                    [qa]="'experiments-run-model-deploy-form-targetColumn'">
                        </dku-bs-select-form-control>
                    </div>
                </div>
                <div *ngIf="classification"
                    class="control-group">
                    <label class="control-label">
                        Classes*
                    </label>
                    <div class="controls">
                        <div class="classes-input-control">
                            <values-list formControlName="classes"
                                        [addLabel]="'Add class'"
                                        [valuePlaceholder]="'Class label'"
                                        (onValidityChange)="onClassValuesListValidityChanged($event)"
                                        [qa]="'experiments-run-model-deploy-form-classes'">
                            </values-list>
                        </div>
                        <span class="help-inline">Should be declared in the same order as when training the model. Classes
                            saved using mlflow_extension.set_run_classes(run_id, classes_list) are automatically proposed
                            here.</span>
                    </div>
                </div>
                <div *ngIf="binaryClassification"
                     class="control-group">
                    <label class="control-label">
                        Threshold
                    </label>
                    <div class="controls">
                        <label>
                            <div class="classes-input-control">
                                <input type="radio" class="control" formControlName="useOptimalThreshold" [value]="true" [(ngModel)]="useOptimalThreshold"/>
                                Use optimal threshold <span *ngIf="form.controls.savedModel.value">for <span class="optimal-threshold-metric">{{thresholdOptimizationMetric || "F1 Score"}}</span></span>
                            </div>
                        <span class="help-inline">The optimization metric is defined in the saved model configuration.</span>
                        </label>
                        <label>
                            <input type="radio" class="control" formControlName="useOptimalThreshold" [value]="false" [(ngModel)]="useOptimalThreshold"/>
                            Override threshold
                        </label>
                        <label *ngIf="!useOptimalThreshold">
                            <input class="control" formControlName="binaryClassificationThreshold" type="number" min="0" max="1" step="0.01" [attr.disabled]="useOptimalThreshold ? true : null">
                            <span class="help-inline">Threshold (or cut-off) for this binary classification. 0.5 by default, must be comprised between 0 and 1.</span>
                        </label>
                    </div>
                </div>
                <div *ngIf="form.controls.classes?.errors?.binaryIncorrectNumClasses"
                    class="alert alert-error mtop8"
                    [qa]="'experiments-run-model-deploy-form-classes-binary-error'">
                    <i class="icon-dku-error"></i> Binary classification must have only two classes defined.
                </div>
                <div *ngIf="form.controls.classes?.errors?.multiclassIncorrectNumClasses"
                    class="alert alert-error mtop8"
                    [qa]="'experiments-run-model-deploy-form-classes-multiclass-error'">
                    <i class="icon-dku-error"></i> Multiclass classification must have at least two classes defined.
                </div>
                <div class="control-group">
                    <label class="control-label">Skip expensive reports*</label>
                    <div class="controls">
                        <input formControlName="skipExpensiveReports" type="checkbox">
                        <span class="help-inline">Don't compute expensive report screens (e.g. feature importance).</span>
                    </div>
                </div>
            </ng-container>
            <ng-template #predOther>
                <div class="alert alert-warning" *ngIf="predictionType?.key === 'OTHER'">A model with this prediction type can not be evaluated</div>
            </ng-template>
        </form>
    </div>
    <div class="modal-footer modal-footer-std-buttons"
         [hidden]="currentPanel !== ModalPanels.CREATE_SMV">
        <div class="pull-right">
            <button type="button"
                    class="btn btn--text btn--secondary"
                    (click)="dismiss()"
                    [qa]="'experiments-run-model-deploy-form-cancel'">Cancel</button>
            <button type="button"
                    class="btn btn--text btn--primary"
                    (click)="deploy()"
                    [disabled]="form.invalid || (error && form.pristine) || deployingModel"
                    [qa]="'experiments-run-model-deploy-form-deploy'">
                Deploy
            </button>
        </div>
    </div>
</div>
