import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ModalHeaderComponent } from '@shared/modals/modal-header/modal-header.component';
import { ConfirmModalComponent } from '@shared/modals/confirm-modal/confirm-modal.component';
import { AckModalComponent } from '@shared/modals/ack-modal/ack-modal.component';
import { PromptModalComponent } from '@shared/modals/prompt-modal/prompt-modal.component';
import { SelectModalComponent } from '@shared/modals/select-modal/select-modal.component';

@NgModule({
    imports: [
        CommonModule, FormsModule
    ],
    declarations: [
        ModalHeaderComponent,
        ConfirmModalComponent,
        AckModalComponent,
        PromptModalComponent,
        SelectModalComponent,
    ],
    exports: [
        ModalHeaderComponent,
        ConfirmModalComponent,
        AckModalComponent,
        PromptModalComponent,
        SelectModalComponent,
    ]
})
export class ModalsModule {
}
