<div class="card-layout">
    <div class="card-layout__section card-layout__section--horizontal">
        <div class="card-layout__section">
            <div class="card-layout__title">Samples</div>
            <div class="card-layout__content">
                <table class="stats-table">
                    <tbody class="stats-table__body">
                        <tr class="stats-table__row">
                            <th class="stats-table__title">{{params.yColumn.name}}</th>
                            <th class="stats-table__title"></th>
                            <th class="stats-table__title">Count</th>
                            <th
                                class="stats-table__title"
                                *ngIf="isMeanRelevant"
                            >
                                Mean
                            </th>
                            <th
                                class="stats-table__head"
                                *ngIf="isMedianRelevant"
                            >
                                Median
                            </th>
                        </tr>
                        <tr
                            class="stats-table__row"
                            *ngFor="let rowGroup of results.groups; let rowIdx = index;"
                        >
                            <th class="stats-table__head">{{ rowGroup.filter | filterName }}</th>
                            <td class="stats-table__cell boxplot-cell">
                                <embedded-box-plot
                                    *ngIf="rowGroup.boxPlot"
                                    [variableName]="params.yColumn.name"
                                    [boxPlot]="rowGroup.boxPlot"
                                    [minValue]="boxPlotsMin"
                                    [maxValue]="boxPlotsMax"
                                ></embedded-box-plot>
                            </td>
                            <td class="stats-table__cell stats-table__cell--left">{{ rowGroup.boxPlot.countComplete }}</td>
                            <td
                                class="stats-table__cell stats-table__cell--left"
                                *ngIf="isMeanRelevant"
                            >
                                {{ rowGroup.boxPlot.mean | smarterNumber }}
                            </td>
                            <td
                                class="stats-table__cell stats-table__cell--left"
                                *ngIf="isMedianRelevant"
                            >
                                {{ rowGroup.boxPlot.median | smarterNumber }}
                            </td>
                        </tr>
                    </tbody>
                    <tbody class="stats-table__body">
                        <tr class="stats-table__row">
                            <th class="stats-table__head">Total</th>
                            <td class="stats-table__cell boxplot-cell">
                                <embedded-box-plot
                                    *ngIf="results.allGroups.boxPlot"
                                    [variableName]="params.yColumn.name"
                                    [boxPlot]="results.allGroups.boxPlot"
                                    [minValue]="boxPlotsMin"
                                    [maxValue]="boxPlotsMax"
                                ></embedded-box-plot>
                            </td>
                            <td class="stats-table__cell stats-table__cell--left">{{ results.allGroups.boxPlot.countComplete }}</td>
                            <td
                                class="stats-table__cell stats-table__cell--left"
                                *ngIf="isMeanRelevant"
                            >
                                {{ results.allGroups.boxPlot.mean | smarterNumber }}
                            </td>
                            <td
                                class="stats-table__cell stats-table__cell--left"
                                *ngIf="isMedianRelevant"
                            >
                                {{ results.allGroups.boxPlot.median | smarterNumber }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="card-layout__section card-layout__section--vertical">
            <div class="card-layout__section">
                <div class="card-layout__title">Hypothesis</div>
                <div class="card-layout__content">
                    <table class="stats-table">
                        <tbody class="stats-table__body">
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Tested hypothesis</th>
                                <td class="stats-table__cell">
                                    <u>{{ params.xColumn.name }}</u>
                                    {{ locationParameterName }}s
                                    are identical in {{ hasTwoSamples ? 'both' : 'all' }} populations
                                </td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Significance level</th>
                                <td class="stats-table__cell">
                                    {{ 1 - params.confidenceLevel | smarterNumber }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-layout__section">
                <div class="card-layout__title">Results</div>
                <div class="card-layout__content">
                    <table class="stats-table">
                        <tbody class="stats-table__body">
                            <tr class="stats-table__row">
                                <th class="stats-table__head">{{ testStatisticName }}</th>
                                <td class="stats-table__cell">{{ results.statistic | smarterNumber }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">𝘱-value</th>
                                <td class="stats-table__cell">
                                    <colored-pvalue
                                        [pvalue]="results.pvalue"
                                        [significanceLevel]="1 - params.confidenceLevel"
                                    ></colored-pvalue>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="test-conclusion">
    <ng-container *ngIf="isRejected else inconclusive">
        <p class="test-conclusion__wording test-conclusion__wording--rejected">
            At the given significance level, the
            {{ locationParameterName }} of
            <u>{{ params.xColumn.name }}</u>
            is not the same among {{ hasTwoSamples ? 'both' : 'all' }} populations
        </p>
    </ng-container>

    <ng-template #inconclusive>
        <p class="test-conclusion__wording">
            At the given significance level, the test is inconclusive about whether the
            {{ locationParameterName }} of
            <u>{{ params.xColumn.name }}</u>
            is not the same among {{ hasTwoSamples ? 'both' : 'all' }} populations
        </p>
    </ng-template>
</div>
