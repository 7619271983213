import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, HostBinding } from '@angular/core';

export type OptionSetting = {
    show: boolean;
    disabled?: boolean;
    tooltip?: string;
};

@Component({
    selector: 'right-panel-action-publish-btn',
    templateUrl: './right-panel-action-publish-btn.component.html',
    styleUrls: ['./right-panel-action-publish-btn.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightPanelActionPublishBtnComponent implements OnChanges {
    @Input() showShareToDashboard?: boolean;
    @Input() disableShareToDashboard?: boolean;
    @Input() dashboardTooltip = '';
    @Output() shareToDashboard = new EventEmitter<void>();

    @Input() showShareToWorkspace?: boolean;
    @Input() disableShareToWorkspace?: boolean;
    @Input() workspaceTooltip = '';
    @Output() shareToWorkspace = new EventEmitter<void>();

    @Input() showAddToFeatureStore?: boolean;
    @Input() disableAddToFeatureStore?: boolean;
    @Input() featureStoreTooltip = '';
    @Output() addToFeatureStore = new EventEmitter<void>();

    @Input() showAddToDataCollection?: boolean;
    @Input() disableAddToDataCollection?: boolean;
    @Input() collectionTooltip = ''; // we can't name this dataCollectionTooltip because this component is downgraded and angularJS normalizes data-* attributes
    @Output() addToDataCollection = new EventEmitter<void>();

    // we apply the hide on the host for the parent grid-like component to not leave an empty space.
    @HostBinding('class.hide') hideButton: boolean = false;

    constructor() { }

    ngOnChanges(): void {
        this.hideButton = !(
            this.showShareToDashboard
            || this.showShareToWorkspace
            || this.showAddToFeatureStore
            || this.showAddToDataCollection
        );
    }

    // we use a disabled class & not disable the element because disabled elements can't show the tooltip.
    // because of this, we have to check that the button is actually enabled & call stopPropagation to prevent the menu to close when we click on a disabled element

    clickDashboard($event: MouseEvent) {
        if(this.disableShareToDashboard) {
            $event.stopPropagation();
        } else {
            this.shareToDashboard.emit();
        }
    }

    clickWorkspace($event: MouseEvent) {
        if(this.disableShareToWorkspace) {
            $event.stopPropagation();
        } else {
            this.shareToWorkspace.emit();
        }
    }

    clickFeatureStore($event: MouseEvent) {
        if(this.disableAddToFeatureStore) {
            $event.stopPropagation();
        } else {
            this.addToFeatureStore.emit();
        }
    }

    clickDataCollection($event: MouseEvent) {
        if(this.disableAddToDataCollection) {
            $event.stopPropagation();
        } else {
            this.addToDataCollection.emit();
        }
    }

}
