<h4>Project</h4>
<a
    class="project-link"
    [href]="projectLink"
>
    <span
        [matTooltip] = "directAccessOnOriginal ? '' : 'You don\'t have access to this project'"
        matTooltipPosition="left"
    >{{projectDisplayName}}</span>
</a>
<button
    type="button"
    class="btn btn--secondary btn--icon btn--dku-icon"
    [matTooltip]="directAccessOnOriginal ? 'Navigate around' : 'You don\'t have access to this project'"
    matTooltipPosition="left"
    (click)="openNavigator()"
    [ngClass]="{disabled: !directAccessOnOriginal}"
>
    <i class="dku-icon-compass-16"></i>
</button>
<a
    class="btn btn--secondary btn--icon btn--dku-icon"
    [attr.href] = "flowLink"
    [matTooltip]="directAccessOnOriginal ? 'See in flow' : 'You don\'t have access to this project'"
    matTooltipPosition="left"
    [ngClass]="{disabled: !directAccessOnOriginal}"
>
    <i class="dku-icon-flow-16"></i>
</a>
