<ng-container *ngIf="!!object && (objectDetails$ | async) as objectDetails">
    <div [ngSwitch]="objectDetails.view"
         class="workspace-object-thumbnail">

        <img *ngSwitchCase="View.Image"
             class="workspace-object-thumbnail__image"
             [src]="objectDetails.safeImage" />

        <div *ngSwitchCase="View.ImageDescription" class="workspace-object-thumbnail__container">
            <img class="workspace-object-thumbnail__image" [src]="objectDetails.safeImage" />
            <markdown 
                class="workspace-object-thumbnail__description markdown-in-tile"
                [from]="object.shortDesc || object.description">
            </markdown>
        </div>

        <div *ngSwitchCase="View.IconDescription" class="workspace-object-thumbnail__container">
            <i *ngIf="objectDetails.type === WorkspaceNonTaggableType.STORY"
                class="workspace-object-thumbnail__icon icon-dku-datastory workspace-object-datasory-icon__color">
            </i>
            <i *ngIf="objectDetails.type !== WorkspaceNonTaggableType.STORY"
                class="workspace-object-thumbnail__icon"
                [ngClass]="[objectDetails.type | typeToIcon | toModernIcon, objectDetails.type | typeToColor]">

            </i>
            <markdown
                class="workspace-object-thumbnail__description markdown-in-tile"
                [from]="object.shortDesc || object.description">
            </markdown>
        </div>

        <div *ngSwitchCase="View.FaviconDescription" class="workspace-object-thumbnail__container">
            <workspace-object-link-favicon
                [object]="object"
                [iconSize]="48">
            </workspace-object-link-favicon>
            <markdown
                class="workspace-object-thumbnail__description markdown-in-tile"
                [from]="object.shortDesc">
            </markdown>
        </div>

        <workspace-object-link-favicon *ngSwitchCase="View.Favicon"
                                       [object]="object"
                                       [iconSize]="48">
        </workspace-object-link-favicon>

        <i *ngSwitchCase="View.DatasetIcon"
           class="workspace-object-thumbnail__icon universe-color dataset"
           [ngClass]="(object.subType || objectDetails.type) | datasetTypeToIcon | toModernIcon:48">
        </i>

        <i *ngSwitchDefault
           class="workspace-object-thumbnail__icon"
           [ngClass]="[objectDetails.type | typeToIcon | toModernIcon:48, objectDetails.type | typeToColor]">
        </i>
    </div>
</ng-container>
