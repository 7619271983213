<right-panel-header class="data-collection-right-panel__header" objectType="dataset" [icon]="details.datasetType | typeToIcon | toModernIcon:24">
    <a *ngIf="exploreUrl && details.objectAuthorizations.directAccessOnOriginal" [href]="exploreUrl" [innerHTML]="formatItemName() | escapeHighlightedHtml"></a>
    <span *ngIf="!exploreUrl || !details.objectAuthorizations.directAccessOnOriginal" [innerHTML]="formatItemName() | escapeHighlightedHtml"></span>
</right-panel-header>
<div class="data-collection-right-panel__content" *ngIf="selectedTab === 'info'">
    <right-panel-accordion-section [opened]="true" [showTopBorder]="false">
        <ng-container title>Actions</ng-container>
        <div
            content
            class="data-collection-right-panel__actions-container"
        >
            <right-panel-actions-container content>
                <right-panel-action
                    icon="dku-icon-data-table-24"
                    [href]="exploreUrl"
                    [disabled]="!details.objectAuthorizations.directAccessOnOriginal"
                    disabledTooltip="You don't have the permission to read datasets from this project"
                >Explore
                </right-panel-action>


                <right-panel-action-publish-btn
                    [showShareToDashboard]="showShareToDashboard"
                    [disableShareToDashboard]="!canShareToDashboard"
                    [dashboardTooltip]="dashboardTooltip"
                    (shareToDashboard)="shareToDashboard.emit()"
                    [showShareToWorkspace]="true"
                    [disableShareToWorkspace]="!canShareToWorkspace"
                    [workspaceTooltip]="workspaceTooltip"
                    (shareToWorkspace)="shareToWorkspace.emit()"
                    [showAddToFeatureStore]="true"
                    [disableAddToFeatureStore]="!canAddToFeatureStore"
                    [featureStoreTooltip]="featureStoreTooltip"
                    (addToFeatureStore)="addToFeatureStore.emit()"
                    [showAddToDataCollection]="true"
                    [disableAddToDataCollection]="!canPublishToDataCollections"
                    [collectionTooltip]="dataCollectionTooltip"
                    (addToDataCollection)="addToDataCollection.emit()"
                >
                </right-panel-action-publish-btn>


                <right-panel-action
                    icon="dku-icon-arrow-download-24"
                    [disabled]="!details.objectAuthorizations.directAccessOnOriginal || !details.canExportData"
                    tooltip="Download data or export it"
                    disabledTooltip="You don't have the permission to export data from this project"
                    (clicked)="export.emit()"
                >Export</right-panel-action>


                <right-panel-action-watch-btn
                    [watched]="watched"
                    [disabled]="!details.objectAuthorizations.directAccessOnOriginal"
                    disabledTooltip="You cannot watch a dataset that you cannot read"
                    (watchedChange)="toggleWatched.emit($event)"
                >
                </right-panel-action-watch-btn>


                <right-panel-action-star-btn
                    [starred]="starred"
                    [disabled]="!details.objectAuthorizations.directAccessOnOriginal"
                    disabledTooltip="You cannot mark as favorite a dataset that you cannot read"
                    (starredChange)="toggleStarred.emit($event)"
                ></right-panel-action-star-btn>


                <right-panel-action *ngIf="removeSelectedItem && currentUserPermissions"
                    icon="dku-icon-trash-24"
                    class="action--danger"
                    [disabled]="!currentUserPermissions.write"
                    disabledTooltip="You don't have the rights to remove this item"
                    (clicked)="removeSelectedItem.emit()"
                >Remove</right-panel-action>
            </right-panel-actions-container>


            <div class="data-collection-right-panel__big-actions-buttons">
                <button class="btn btn--secondary"
                    [class.disabled]="!details.objectAuthorizations.canReadObject"
                    [attr.aria-disabled]="!details.objectAuthorizations.canReadObject"
                    [matTooltip]="details.objectAuthorizations.canReadObject ? '' : 'You don\'t have the permission to read datasets from this project'"
                    (click)="details.objectAuthorizations.canReadObject && preview.emit()"
                >
                    <i class="dku-icon-search-16"></i>
                    <span>Preview</span>
                </button>
                <button
                    class="btn btn--primary"
                    [class.disabled]="shareability === 'nothing'"
                    [attr.aria-disabled]="shareability === 'nothing'"
                    [matTooltip]="shareButtonTooltip"
                    (click)="shareButtonAction()"
                >
                    <i [class]="shareability === 'request' ? 'dku-icon-lock-closed-16' : 'dku-icon-share-16'"></i>
                    <span>{{shareButtonLabel}}</span>
                </button>
            </div>
        </div>
    </right-panel-accordion-section>


    <right-panel-accordion-section [opened]="true">
        <ng-container title>Details</ng-container>
        <div
            content
            class="data-collection-right-panel__details-container"
        >
            <right-panel-details-project
                [projectKey]="details.sourceProjectKey"
                [projectDisplayName]="details.sourceProjectName"
                [objectId]="details.name"
                [type]="'DATASET'"
                [directAccessOnOriginal]="details.objectAuthorizations.directAccessOnOriginal"
            >
            </right-panel-details-project>
            <right-panel-details-description
                [shortDescription]="details.shortDesc"
                [longDescription]="details.description"
                [highlights]="highlights"
                *ngIf="details.shortDesc || details.description"
            >
            </right-panel-details-description>
            <right-panel-details-tags
                [tags]="details.tags"
                objectType="DATASET"
            ></right-panel-details-tags>
            <div class="children-vertical-spacing">
                <right-panel-details-data-steward
                    [dataSteward]="details.dataSteward"
                    [isEditable]="details.objectAuthorizations.canWriteObject"
                    (editDataSteward)="editDataSteward.emit()"
                >
                </right-panel-details-data-steward>
                <right-panel-details-text-info
                    *ngIf="details.creationDate !== -1"
                    label="Creation"
                    [text]="details.creationDate | friendlyTimeDeltaShort"
                    [tooltip]="details.creationDate | friendlyDateTime"
                    [user]="details.createdBy"
                ></right-panel-details-text-info>
                <right-panel-details-text-info
                    *ngIf="details.lastModificationDate !== -1"
                    label="Last modified"
                    [text]="details.lastModificationDate | friendlyTimeDeltaShort"
                    [tooltip]="details.lastModificationDate | friendlyDateTime"
                    [user]="details.lastModifiedBy"
                ></right-panel-details-text-info>
                <right-panel-details-text-info
                    *ngIf="details.lastBuiltOn !== -1"
                    label="Last build"
                    [text]="details.lastBuiltOn | friendlyTimeDeltaShort"
                    [tooltip]="details.lastBuiltOn | friendlyDateTime"
                ></right-panel-details-text-info>
            </div>

        </div>
    </right-panel-accordion-section>


    <right-panel-accordion-section [opened]="true">
        <ng-container title>Status</ng-container>
        <right-panel-dataset-status
            content
            [status]="details.status"
            [showSize]="doesSelectedItemHaveSize"
            [showPartitionning]="isSelectedItemPartitionned"
            [canUpdate]="details.objectAuthorizations.canWriteObject"
            (refreshStatus)="onRefreshStatusEvent($event)"
        ></right-panel-dataset-status>
    </right-panel-accordion-section>

    <right-panel-accordion-section
        [opened]="true"
        *ngIf="isSelectedItemPartitionned"
    >
        <ng-container title>Partitioning ({{details.partitioningDimensions.length}})</ng-container>
        <right-panel-details-partitioning
            content
            [partitioningDimensions]="details.partitioningDimensions"
        ></right-panel-details-partitioning>
    </right-panel-accordion-section>


    <right-panel-accordion-section
        [opened]="true"
        *ngFor="let type of details.splitInputsByType | keyvalue"
    >
        <ng-container title>Related initial {{ type.key | niceTaggableType: type.value.length }}
            ({{type.value.length}})</ng-container>
        <right-panel-details-related-elements
            content
            [objects]="type.value"
        ></right-panel-details-related-elements>
    </right-panel-accordion-section>


    <right-panel-accordion-section
        [opened]="true"
        *ngFor="let type of details.splitOutputsByType | keyvalue"
    >
        <ng-container title>Related final {{ type.key | niceTaggableType: type.value.length }}
            ({{type.value.length}})</ng-container>
        <right-panel-details-related-elements
            content
            [objects]="type.value"
        ></right-panel-details-related-elements>
    </right-panel-accordion-section>


    <right-panel-accordion-section
        [opened]="true"
        *ngIf="details.usages.projectsWithAccess.length > 0 || details.usages.usagesInNonAccessibleProjects > 0"
    >
        <ng-container title>Used in DSS projects ({{details.usages.projectsWithAccess.length +
            details.usages.usagesInNonAccessibleProjects}})</ng-container>
        <right-panel-details-usages
            content
            [usages]="details.usages"
        ></right-panel-details-usages>
    </right-panel-accordion-section>
</div>
<div class="data-collection-right-panel__content" *ngIf="selectedTab === 'schema'">
    <right-panel-schema content class="data-collection-right-panel-schema__full-height"
        wordingOption="column"
        [columns]="this.details.schema.columns"
        [showMeaning]="false"
        [columnHighlight]="columnsHighlighting">
    </right-panel-schema>
</div>
