import { FormControl, FormGroup, Validators } from '@angular/forms';
import { observeFormControl } from '@utils/form-control-observer';
import { isObject, isString } from '@utils/objects';
import { AccessibleObjectsService } from 'src/generated-sources';
import { map } from 'rxjs/operators';
import { deepDistinctUntilChanged } from 'dku-frontend-core';

interface RawAddObjectsFormValue {
    type: 'DATASET';
    projectKey: string | null;
    objects?: AccessibleObjectsService.AccessibleObject | null;
    authorization?: AuthorizationOptions;
    quickSharingEnabled?: boolean;
}

export type AuthorizationOptions = 'READ' | 'DISCOVER' | 'NONE';

export interface ValidatedAddObjectsFormValue {
    type: 'DATASET';
    projectKey: string;
    objects: AccessibleObjectsService.AccessibleObject;
    authorization: AuthorizationOptions;
    quickSharingEnabled: boolean;
}

export type AddObjectsFormValueType = ValidatedAddObjectsFormValue['type'];

export class AddObjectsForm extends FormGroup {
    get type(): FormControl {
        return this.get('type') as FormControl;
    }

    get projectKey(): FormControl {
        return this.get('projectKey') as FormControl;
    }

    get objects(): FormControl {
        return this.get('objects') as FormControl;
    }

    get authorization(): FormControl {
        return this.get('authorization') as FormControl;
    }

    get quickSharingEnabled(): FormControl {
        return this.get('quickSharingEnabled') as FormControl;
    }

    constructor() {
        super({
            type: new FormControl('DATASET', Validators.required),
            projectKey: new FormControl({ value: null, disabled: false }, Validators.required),
            objects: new FormControl({ value: null, disabled: true }, Validators.required),
            authorization: new FormControl({ value: null, disabled: true }, Validators.required),
            quickSharingEnabled: new FormControl({ value: null, disabled: true }, Validators.required),
        });
    }

    public readonly rawValues$ = observeFormControl<RawAddObjectsFormValue>(this).pipe(
        deepDistinctUntilChanged()
    );

    public readonly isValid$ = this.rawValues$.pipe(
        map(this.isValid.bind(this))
    );

    setObjectAuthorizationValue(authorizationLevel: AuthorizationOptions) {
        this.authorization.setValue(authorizationLevel);
    }

    setQuickSharingValue(quickSharingEnabled: boolean) {
        this.quickSharingEnabled.setValue(quickSharingEnabled);
    }

    isValid(val: RawAddObjectsFormValue): val is ValidatedAddObjectsFormValue {
        return val.type === 'DATASET' && isString(val.projectKey) && isObject(val.objects);
    }

    /**
     * Gets the current value, if valid (undefined otherwise)
     */
    getCurrentValue() {
        if(this.isValid(this.value)) {
            return this.value;
        }
        return undefined;
    }


}
