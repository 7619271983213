import type {BoxPlotFragment} from './fragments/box-plot-fragment';
import type {CardResult, _CardResult} from './card-result';
import type {Card} from './card';
import type {NumericalHistogramFragment} from './fragments/numerical-histogram-fragment';
import type {TestDistribution} from './../../compute/computations/univariate/test-distribution';
import type {_UnivariateCard} from './univariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.ShapiroNormalityTestCard
 */
export interface ShapiroNormalityTestCard extends _UnivariateCard {
    confidenceLevel: number;

    // PolyJson type
    type: 'shapiro';
}

export namespace ShapiroNormalityTestCard {
    export const type = 'shapiro';
}

/** @deprecated Use Card.isShapiroNormalityTestCard() */ export function isShapiroNormalityTestCard(toTest: Card): toTest is ShapiroNormalityTestCard { return ShapiroNormalityTestCard.type === toTest['type']; }

export namespace ShapiroNormalityTestCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.ShapiroNormalityTestCard$ShapiroNormalityTestCardResult
     */
    export interface ShapiroNormalityTestCardResult extends _CardResult {
        boxPlot: BoxPlotFragment;
        histogram: NumericalHistogramFragment;
        normalPdf: TestDistribution.ProbabilityDensityFunction;
        pvalue: number;
        statistic: number;

        // PolyJson type
        type: 'shapiro';
    }

    export namespace ShapiroNormalityTestCardResult {
        export const type = 'shapiro';
    }

    /** @deprecated Use CardResult.isShapiroNormalityTestCardResult() */ export function isShapiroNormalityTestCardResult(toTest: CardResult): toTest is ShapiroNormalityTestCardResult { return ShapiroNormalityTestCardResult.type === toTest['type']; }
}