import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { UIAnnotation, UIBoundingBox, UIClassificationAnnotation, UINamedEntity } from '@features/labeling/models/annotation';
import { createFromAnnotations } from '@features/labeling/models/annotation-group';
import { LabelingColorService } from '@features/labeling/services/labeling-color.service';
import { LabelingService, LabelingTaskInfo } from '@features/labeling/services/labeling.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { iif } from 'rxjs';
import { LabelingTask } from 'src/generated-sources';
import { LabelingReviewUpdateService } from '../../services/labeling-review-update.service';
import { ReviewStatus } from '../../services/labeling-review.service';
import { LabelingValidateService } from '../../services/labeling-validate.service';
import { LabelingReviewAnnotationGroupService } from './../../services/labeling-review-annotation-group.service';

enum ReviewMode {
    INITIAL,
    UPDATE
}

@UntilDestroy()
@Component({
    selector: 'annotation-list',
    templateUrl: './annotation-list.component.html',
    styleUrls: [
        '../../../shared-styles/annotation.less',
        './annotation-list.component.less'
    ]
})
export class AnnotationListComponent implements OnInit {
    @Input() updating: boolean;
    @Input() multiAccept: boolean = false; // can resolve multiple annotations
    @Input() conflicting?: boolean; // can be undefined in ReviewMode.UPDATE

    annotations: UIAnnotation[];
    classes: string[];
    reviewMode: ReviewMode;
    readonly LabelingTaskType = LabelingTask.LabelingTaskType;
    readonly ReviewMode = ReviewMode;
    readonly ANNOTATION_ID_PREFIX = "annotation-";
    UIBoundingBox = UIBoundingBox;
    UIClassificationAnnotation = UIClassificationAnnotation;
    UINamedEntity = UINamedEntity;
    ReviewStatus = ReviewStatus;

    constructor(
        public labelingReviewUpdateService: LabelingReviewUpdateService,
        private labelingReviewAnnotationGroupService: LabelingReviewAnnotationGroupService,
        public labelingColorService: LabelingColorService,
        public labelingService: LabelingService,
        private labelingValidateService: LabelingValidateService,
        private host: ElementRef,
    ) { }
    
    ngOnInit(): void {
        iif(() => this.updating, this.labelingReviewUpdateService.currentReview$, this.labelingReviewAnnotationGroupService.selectedAnnotationGroup$).pipe(
            untilDestroyed(this)
        ).subscribe(review => {
            this.annotations = review.annotations;
            this.reviewMode = this.updating ? ReviewMode.UPDATE : ReviewMode.INITIAL;

            const idx = this.annotations.findIndex(a =>  a.selected); 
            if (idx >= 0) {
                this.host.nativeElement.querySelector(`#${this.ANNOTATION_ID_PREFIX+idx}`)?.scrollIntoView({
                    block: 'center'
                });
            }
        });

        this.labelingService.classes$.subscribe(classes => this.classes = classes);
    }

    update(modifiedByReviewer?: boolean, resolvedByReviewer?: boolean) {
        if (this.reviewMode === ReviewMode.INITIAL) {
            this.labelingReviewAnnotationGroupService.updateAnnotationGroup(this.annotations, modifiedByReviewer, resolvedByReviewer);
        } else {
            this.labelingReviewUpdateService.updateReview(this.annotations);
        }
    }

    color(bbox: UIBoundingBox) {
        return this.labelingColorService.getColorForBBox(bbox);
    }
    
    selectAnnotation(index: number) {
        const selecting = !this.annotations[index].selected;
        if (selecting) {
            this.annotations.forEach(annotation => {
                annotation.selected = false;
            });
        }
        this.annotations[index].selected = selecting;

        this.update();
    }

    deleteAnnotation(index: number, type: LabelingTask.LabelingTaskType) {
        if (type === LabelingTask.LabelingTaskType.IMAGE_CLASSIFICATION && this.annotations.length === 1) {
            this.annotations[0].category = undefined;
            this.annotations[0].annotator = undefined;
        } else {
            this.annotations = this.annotations.filter((_, idx) => idx != index);
        }

        this.update(true);
    }

    resolveAnnotation(annotationToResolve: UIAnnotation, task: LabelingTaskInfo) {
        if (this.multiAccept && this.annotations.length > 1) {
            // remove the annotation from the current annotation group
            this.annotations = this.annotations.filter(annotation => annotation !== annotationToResolve);
            this.update(true, false);
            // create a new annotation group for the resolved annotation
            this.labelingReviewAnnotationGroupService.addAnnotationGroup(createFromAnnotations([annotationToResolve], task, true, true));
        } else {
            this.annotations = this.annotations.filter(annotation => annotation === annotationToResolve);

            this.update(true, true);
        }

        if (task.type === LabelingTask.LabelingTaskType.IMAGE_CLASSIFICATION) {
            this.labelingValidateService.validate();
        }
    }

    emitNewAnnotations() {
        this.update(true);
    }

    canDelete(annotation: UIAnnotation, type: LabelingTask.LabelingTaskType) {
        return (this.reviewMode === ReviewMode.INITIAL && annotation.annotator) ||
            (this.reviewMode === ReviewMode.UPDATE && type !== LabelingTask.LabelingTaskType.IMAGE_CLASSIFICATION);
    }

    canResolve(numberOfAnnotations: number): boolean {
        return this.reviewMode === ReviewMode.INITIAL && ((!this.multiAccept && numberOfAnnotations > 1) || this.conflicting === true);
    }
}

