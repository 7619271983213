<p>
    The two-sample Kolmogorov–Smirnov test is a statistical procedure used
    to test the hypothesis that the underlying distribution of
    <b>{{ params.xColumn.name }}</b>
    is the same between two populations.
</p>

<list-compared-populations [params]="params"></list-compared-populations>

<p>
    There are two possible outcomes depending on the 𝘱-value:
</p>
<ul>
    <li>
        <b>
            𝘱-value &le; significance level (&le; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>
        <br>
        The hypothesis is rejected.
        <b>{{ params.xColumn.name }}</b>
        distribution is different in the two populations.
    </li>
    <li>
        <b>
            𝘱-value is &gt; significance level (&gt; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>

        <br>
        Not enough evidence against the hypothesis. Test is inconclusive.
    </li>
</ul>
<p>
    It has been shown that the conclusion is meaningful under the following condition:
</p>
<ul>
    <li>
        Observations of
        <b>{{ params.xColumn.name }}</b>
        are independent and identically distributed
    </li>
</ul>
<p>
    <span class="text-weak">
        <i class="icon-info-sign"></i>
        Missing values are discarded
    </span>
</p>
