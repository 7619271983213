import { LabelingRegion, LabelingTask } from "src/generated-sources";
import { ImageLabelingTaskInfo, LabelingTaskInfo } from "../services/labeling.service";
import { getAverageRectangleFromAnnotations, namedEntityAnnotationsSortingFn } from "../utils";
import { UIAnnotation, UIBoundingBox, UIClassificationAnnotation, UINamedEntity } from "./annotation";
import { ClassificationAnnotationRegion, EntityExtractionRegion, ObjectDetectionRegion } from "./labeling-region";

export function createFromRegion(r: LabelingRegion, task: LabelingTaskInfo): AnnotationGroup {
    switch(task.type) {
        case LabelingTask.LabelingTaskType.OBJECT_DETECTION:
            return BoundingBoxGroup.createFromRegion(r, task.minNbAnnotatorsPerRecord, (task as ImageLabelingTaskInfo).objectDetectionIOUConflictThreshold);
        case LabelingTask.LabelingTaskType.IMAGE_CLASSIFICATION:
            return ClassificationAnnotationGroup.createFromRegion(r, task.minNbAnnotatorsPerRecord);
        case LabelingTask.LabelingTaskType.NAMED_ENTITY_EXTRACTION:
            return NamedEntityGroup.createFromRegion(r, task.minNbAnnotatorsPerRecord);
        default:
            throw('Not supported for ' + task.type);
    }
}

export function createFromAnnotations(annotations: UIAnnotation[], task: LabelingTaskInfo, modifiedByReviewer: boolean, createdByReviewer: boolean): AnnotationGroup {
    switch(task.type) {
        case LabelingTask.LabelingTaskType.OBJECT_DETECTION:
            return new BoundingBoxGroup(annotations as UIBoundingBox[], task.minNbAnnotatorsPerRecord, (task as ImageLabelingTaskInfo).objectDetectionIOUConflictThreshold, false, modifiedByReviewer);
        case LabelingTask.LabelingTaskType.IMAGE_CLASSIFICATION:
            return new ClassificationAnnotationGroup(annotations as UIClassificationAnnotation[], task.minNbAnnotatorsPerRecord, modifiedByReviewer)
        case LabelingTask.LabelingTaskType.NAMED_ENTITY_EXTRACTION:
            return new NamedEntityGroup(annotations as UINamedEntity[], task.minNbAnnotatorsPerRecord, false, false, modifiedByReviewer, createdByReviewer);
        default:
            throw('Not supported for ' + task.type);
    }
}


enum ConflictReason {
    NO_CONSENSUS_CATEGORY = "No consensus on the category",
    NO_CONSENSUS_CLASS = "No consensus on the class",
    MISSING_ANNOTATOR = "Some annotators missed this object",
    MISSING_ANNOTATOR_TEXT = "Some annotators missed this text snippet",
    BBOX_OVERLAP = 'Boxes don\'t overlap enough',
    TEXT_MATCH = 'Annotators did not label the same text snippet',
}

export abstract class AnnotationGroup {
    abstract annotations: UIAnnotation[];
    minNbAnnotators: number;
    modifiedByReviewer: boolean;
    selected: boolean;
    selectable: boolean;
    resolved: boolean;

    constructor(minNbAnnotators: number) {
        this.minNbAnnotators = minNbAnnotators;
    }

    hasConflict(): boolean {
        return this.conflictReasons().length !== 0;
    }

    conflictReasons(): string[] {
        const reasons: string[] = [];

        if (this.annotations.length === 0) {
            return reasons;
        }

        if (this.hasConflictingCategory()) {
            // use "category" if multiple annotations are possible (this.selectable === true)
            if (this.selectable) {
                reasons.push(ConflictReason.NO_CONSENSUS_CATEGORY);
            } else {
                reasons.push(ConflictReason.NO_CONSENSUS_CLASS);
            }
        }

        return reasons;
    }

    idxToInsert(annotationGrps: AnnotationGroup[]) {
        return annotationGrps.length; // by default insert at the end
    } 

    canBeValidated(): boolean {
        return !this.hasConflictingCategory() && !this.hasMissingCategory() && this.annotations.length > 0;
    }

    hasMissingAnnotator(): boolean {
        return new Set(this.annotations.map(object => object.annotator)).size < this.minNbAnnotators;
    }

    hasConflictingCategory(): boolean {
        return this.annotations.map(object => object.category).some((category, _, categories) => category != categories[0])
    }

    hasMissingCategory(): boolean {
        return this.annotations.map(object => object.category).some(category => category === undefined)
    }

    getConsensusCategory(): string | undefined {
        if (this.annotations.map(object => object.category).every((category, _, categories) => category === categories[0])) {
            if (this.annotations[0]) {
                return this.annotations[0].category!
            }
        }
        return undefined;
    }

    abstract computeAverageObject(): UIAnnotation; // todo @labeling: probably should only be for bounding boxes
}

export class ClassificationAnnotationGroup extends AnnotationGroup {
    annotations: UIClassificationAnnotation[];

    constructor(annotations: UIClassificationAnnotation[], minNbAnnotators: number, modifiedByReviewer?: boolean) {
        super(minNbAnnotators);
        this.annotations = annotations;
        this.selected = true;
        this.selectable = false;
        this.modifiedByReviewer = modifiedByReviewer || false;
    }

    conflictReasons(): string[] {
        const reasons = super.conflictReasons();

        if (this.hasMissingAnnotator()) {
            reasons.push(ConflictReason.MISSING_ANNOTATOR);
        }

        return reasons;
    }

    computeAverageObject(): UIClassificationAnnotation {
        return this.annotations[0];
    }

    static createFromRegion(region: LabelingRegion, minNbAnnotators: number) {
        let annotations = (region as ClassificationAnnotationRegion).elements.map(e => {
            return new UIClassificationAnnotation(e.annotation.category, undefined, e.annotator)
        });
        
        return new ClassificationAnnotationGroup(annotations, minNbAnnotators);
    }

    hasMissingAnnotator() : boolean {
        return !this.modifiedByReviewer && super.hasMissingAnnotator();
    }
}

export class BoundingBoxGroup extends AnnotationGroup {
    annotations: UIBoundingBox[];
    IOUConflictThreshold: number;

    constructor(annotations: UIBoundingBox[], minNbAnnotators: number, IOUConflictThreshold: number, selected?: boolean, modifiedByReviewer?: boolean) {
        super(minNbAnnotators);
        this.annotations = annotations;
        this.selected = selected || false;
        this.selectable = true;
        this.modifiedByReviewer = modifiedByReviewer || false;
        this.IOUConflictThreshold = IOUConflictThreshold;
    }

    static createFromRegion(region: LabelingRegion, minNbAnnotators: number, IOUConflictThreshold: number) {
        const bboxes = (region as ObjectDetectionRegion).elements.map(e => {
            const boundingBox = e.annotation;

            return new UIBoundingBox({
                width: boundingBox.bbox.width,
                top: boundingBox.bbox.y0,
                left: boundingBox.bbox.x0,
                height: boundingBox.bbox.height,
                category: boundingBox.category,
                annotator: e.annotator,
                pinned: true
            });
        });
        return new BoundingBoxGroup(bboxes, minNbAnnotators, IOUConflictThreshold);
    }

    computeAverageObject(): UIBoundingBox {
        return new UIBoundingBox({
            ...getAverageRectangleFromAnnotations(this.annotations),
            category: this.annotations[0].category!,
            annotator: "",
            pinned: false
        })
    }

    conflictReasons(): string[] {
        const reasons = super.conflictReasons();

        if (this.hasConflictingIoU()) {
            reasons.push(ConflictReason.BBOX_OVERLAP);
        }

        if (this.hasMissingAnnotator()) {
            reasons.push(ConflictReason.MISSING_ANNOTATOR);
        }

        return reasons;
    }

    hasMissingAnnotator() : boolean {
        return !this.modifiedByReviewer && super.hasMissingAnnotator();
    }

    hasConflictingIoU(): boolean {
        return this.annotations.some((object, _, annotations) => !object.empty() && object.iou(annotations[0].bbox) < this.IOUConflictThreshold);
    }
}

export class NamedEntityGroup extends AnnotationGroup {
    text: string;
    beginningIndex: number;
    endIndex: number;

    private _annotations: UINamedEntity[];
    get annotations() {
        return this._annotations;
    }
    set annotations(newAnnotations: UINamedEntity[]) {
        this._annotations = newAnnotations;
        if (newAnnotations.length > 0) {
            this.text = this.getText();
            const range = this.getRange();
            this.beginningIndex = range.beginningIndex;
            this.endIndex = range.endIndex;
        }
    }

    constructor(annotations: UINamedEntity[], minNbAnnotators: number, conflicting?: boolean, selected?: boolean, modifiedByReviewer?: boolean, resolved?: boolean) {
        super(minNbAnnotators);
        
        this.selected = selected || false;
        this.selectable = true;
        this.modifiedByReviewer = modifiedByReviewer || false;
        this.resolved = resolved || false;
        this.annotations = annotations;
    }

    static createFromRegion(region: LabelingRegion, minNbAnnotators: number) {
        const namedEntities = (region as EntityExtractionRegion).elements.map(e => new UINamedEntity({
            annotator: e.annotator,
            ...e.annotation
        }));
        return new NamedEntityGroup(namedEntities, minNbAnnotators, region.conflicting);
    }

    conflictReasons(): string[] {
        const reasons = super.conflictReasons();

        if (this.hasConflictingText() && new Set(this.annotations.map(a => a.annotator)).size > 1) {
            reasons.push(ConflictReason.TEXT_MATCH);
        }

        if (this.hasMissingAnnotator()) {
            reasons.push(ConflictReason.MISSING_ANNOTATOR_TEXT);
        }

        return reasons;
    }

    hasConflictingText(): boolean {
        return new Set(this.annotations.map(a => a.getIdBasedOnIndex())).size !== 1;
    }

    /*
        Get start and end index of combined annotations
    */
    getRange(): { beginningIndex: number, endIndex: number } {
        return {
            beginningIndex: Math.min.apply(Math, this.annotations.map(annotation => annotation.beginningIndex)),
            endIndex: Math.max.apply(Math, this.annotations.map(annotation => annotation.endIndex))
        }
    }

    private getText(): string {
        const sorted = [...this.annotations].sort(namedEntityAnnotationsSortingFn);
        const offset = sorted[0].beginningIndex;
        
        return sorted.reduce((text, annotation) => {
            const startIndex = annotation.beginningIndex - offset;
            const endIndex = annotation.endIndex - offset;

            if (endIndex > text.length) {
                return text.substring(0, startIndex) + annotation.text + text.substring(endIndex);
            }

            return text;
        }, '')
    }

    canBeValidated(): boolean {
        return super.canBeValidated() && !this.hasConflictingText();
    }

    idxToInsert(otherGroups: NamedEntityGroup[]): number {
        let idxToAddGrpAfter = super.idxToInsert(otherGroups);

        const groupStartingIndex = Math.min(...this.annotations.map(a => a.beginningIndex));
        const otherGroupsStartingIndices = otherGroups.map(grp => Math.min(...grp.annotations.map(a => a.beginningIndex)));

        for (let i=0; i < otherGroupsStartingIndices.length; i++) {
            if (otherGroupsStartingIndices[i] > groupStartingIndex) {
                idxToAddGrpAfter = i;
                break;
            }
        }

        return idxToAddGrpAfter;
    }

    getCategoryLabel() {
        if (this.hasConflict()) {
            return '(conflict)'
        } else if (this.hasMissingAnnotator()) {
            return 'Missing annotators'
        }

        return this.getConsensusCategory();
    }


    hasMissingAnnotator() : boolean {
        return !this.resolved && super.hasMissingAnnotator();
    }

    computeAverageObject(): UINamedEntity {
        return this.annotations[0];
    }
}
