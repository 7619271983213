import _ from 'lodash';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, ViewContainerRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable, concat, of, Subject, merge, EMPTY } from 'rxjs';
import { catchError, delay, filter, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { auditMap } from 'dku-frontend-core';
import { APIError, ErrorContext } from '@core/dataiku-api/api-error';
import { WT1Service } from 'dku-frontend-core';
import { SampleContextService } from '@features/eda/sample-context.service';
import { CardPreviewModalComponent, CardPreviewModalComponentAction, CardPreviewModalComponentData } from '@features/eda/worksheet/card-wizard/card-preview-modal/card-preview-modal.component';
import { NewCardModalComponent } from '@features/eda/worksheet/card-wizard/new-card-modal/new-card-modal.component';
import { CardBodyRenderingMode } from '@features/eda/worksheet/cards/body/rendering-mode';
import { WorksheetContextService } from '@features/eda/worksheet-state/worksheet.context.service';
import { Card, CardResult, SuggestCards, Variable, WorksheetRootCard } from 'src/generated-sources';
import { clearCardIds, resetCardIds } from '@features/eda/card-utils';
import { ModalShape, ModalsService } from '@shared/modals/modals.service';
import { CollapsingService, NoopCollapsingService } from '@features/eda/collapsing.service';

class AssistantSuggestion {
    public isSelected: boolean = false;
    public isAlreadyIncludedInWorksheet: boolean = false;

    constructor(public suggestedCard: SuggestCards.SuggestedCard) {}

    public get card() {
        return this.suggestedCard.card;
    }

    public toggleSelection() {
        this.isSelected = !this.isSelected;
    }
}

class WT1Report {
    private sessionStart = Date.now();
    private variablesCount = 0;
    private previewedCards: Card[] = [];
    private suggestedCards: Card[] = [];
    private selectedCards: Card[] = [];
    private createdCards: Card[] = [];
    private suggestionDurationsInMs: number[] = [];

    constructor(private wt1Service: WT1Service) {}

    setVariablesCount(count: number) {
        this.variablesCount = count;
    }

    addPreviewedCard(card: Card) {
        this.previewedCards.push(card);
    }

    addSuggestedCards(cards: Card[]) {
        this.suggestedCards = this.suggestedCards.concat(cards);
    }

    setSelectedCards(cards: Card[]) {
        this.selectedCards = cards;
    }

    addCreatedCards(cards: Card[]) {
        this.createdCards = this.createdCards.concat(cards);
    }

    addSuggestionDuration(durationInMs: number) {
        this.suggestionDurationsInMs.push(durationInMs);
    }

    send() {
        const durationInMs = Date.now() - this.sessionStart;
        const variablesCount = this.variablesCount;
        const hasCreatedCards = this.createdCards.length > 0;
        const suggestionQueriesCount = this.suggestionDurationsInMs.length;
        const meanSuggestionDurationInMs = (suggestionQueriesCount > 0) ?
            _.mean(this.suggestionDurationsInMs) : null;

        const cardPreviewReport = _.countBy(this.previewedCards, it => it.type);
        const cardSuggestionReport = _.countBy(this.suggestedCards, it => it.type);
        const cardSelectionReport = _.countBy(this.selectedCards, it => it.type);
        const cardCreationReport = _.countBy(this.createdCards, it => it.type);

        this.wt1Service.event("statistics-worksheet-suggester-session", {
            durationInMs,
            suggestionQueriesCount,
            meanSuggestionDurationInMs,
            variablesCount,
            hasCreatedCards,
            cardPreviewReport,
            cardSuggestionReport,
            cardSelectionReport,
            cardCreationReport,
        });
    }
}

class APIErrorContext implements ErrorContext {
    private error$ = new BehaviorSubject<APIError | undefined>(undefined);

    pushError(error?: APIError) {
        this.error$.next(error);
    }

    resetError() {
        this.error$.next(undefined);
    }

    getError(): Observable<APIError | undefined> {
        return this.error$;
    }
}

@UntilDestroy()
@Component({
    selector: 'automagic-sniffer',
    templateUrl: './automagic-sniffer.component.html',
    styleUrls: [
        './automagic-sniffer.component.less'
    ],
    providers: [
        {
            provide: CollapsingService,
            useClass: NoopCollapsingService,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutomagicSnifferComponent implements OnInit, OnDestroy  {
    readonly CardBodyRenderingMode = CardBodyRenderingMode;

    private wt1Report: WT1Report

    private suggestCardsErrorContext = new APIErrorContext();
    private suggestCardsAborter$ = new Subject();
    showSuggestCardsSpinner$ = new BehaviorSubject(false);

    private cardPreviewErrorContext = new APIErrorContext();
    showCardPreviewInterrupted$: Observable<boolean>;
    apiError$: Observable<APIError | undefined>;

    isFirstRun = true;
    hasEmptyDataset = false;

    variables: SuggestCards.SuggestedVariable[] = [];
    selectedVariableNames$ = new BehaviorSubject<string[]>([]);

    get hasSniffedVariables() {
        return this.variables.length > 0;
    }

    get hasSelectedVariables() {
        return this.selectedVariableNames$.getValue().length > 0;
    }

    assistantSuggestions: AssistantSuggestion[] = [];

    get selectedAssistantSuggestions() {
        return this.assistantSuggestions.filter(it => it.isSelected);
    }

    private nameFilter$ = new BehaviorSubject<string | null>(null);

    get nameFilter() {
        return this.nameFilter$.getValue();
    }

    set nameFilter(newVal) {
        this.nameFilter$.next(newVal);
    }

    constructor(
        private sampleContextService: SampleContextService,
        private changeDetectorRef: ChangeDetectorRef,
        private modalsService: ModalsService,
        private dialogRef: MatDialogRef<NewCardModalComponent>,
        private worksheetContextService: WorksheetContextService,
        private wt1Service: WT1Service,
        private viewContainerRef: ViewContainerRef
    ) {
        this.showCardPreviewInterrupted$ = this.cardPreviewErrorContext.getError()
            .pipe(
                filter(error => error?.errorType === "FutureAbort"),
                switchMap(() =>
                    concat(
                        of(true),
                        of(false).pipe(delay(2500))
                    )
                ),
                untilDestroyed(this)
            );

        this.apiError$ = merge(
            this.suggestCardsErrorContext.getError(),
            this.cardPreviewErrorContext.getError()
                .pipe(filter(error => error?.errorType !== "FutureAbort"))
        ).pipe(untilDestroyed(this));
    }

    /**
     * When a variable is selected (or removed from selection), this observable
     * runs an interactive query to suggest cards. Subsequent variable updates
     * are ignored until the current interactive query completes.
     *
     * @returns an Observable emitting the suggested cards
     */
    private getSuggestionsObservable(): Observable<SuggestCards.SuggestCardsResult> {
        // Error handler when the interactive query fails.
        const setApiErrorAndStopSpinner = (error: any) => {
            this.suggestCardsErrorContext.pushError(error);
            this.triggerSuggestCardsSpinner(false);
            return EMPTY;
        };

        const interactiveQueryRunner = (variableNames: string[], rootCard: WorksheetRootCard) => {
            const {
                confidenceLevel,
                showConfidenceInterval,
                highlightFilter,
            } = rootCard;

            const query: SuggestCards = {
                type: 'suggest_cards',
                selectedVariables: variableNames,
                assistantSettings: {
                    confidenceLevel,
                    showConfidenceInterval,
                    highlightFilter,
                },
            };

            const queryStart = Date.now();
            this.triggerSuggestCardsSpinner(true);
            return this.sampleContextService.runInteractiveQuery(query)
                .pipe(
                    catchError(setApiErrorAndStopSpinner),
                    tap(() => {
                        this.triggerSuggestCardsSpinner(false);
                        const queryEnd = Date.now();
                        this.wt1Report.addSuggestionDuration(queryEnd - queryStart);
                    }),
                    takeUntil(this.suggestCardsAborter$)
                );
        };

        return this.selectedVariableNames$
            .pipe(
                withLatestFrom(this.worksheetContextService.getRootCard()),
                auditMap(([variableNames, rootCard]) => {
                    if (rootCard == null) {
                        // Worksheet not yet available.
                        return EMPTY;
                    }

                    return interactiveQueryRunner(variableNames, rootCard).pipe(
                        map(suggestions => ({suggestions, variableNames}))
                    );
                }),

                // Ignore result if the variable selection has changed since it was computed
                withLatestFrom(this.selectedVariableNames$),
                filter(([{variableNames}, variableNamesAfterCompute]) => _.isEqual(variableNames, variableNamesAfterCompute)),
                map(([{suggestions}]) => suggestions)
            );
    }

    private triggerSuggestCardsSpinner(show: boolean) {
        if (this.isFirstRun) {
            return;
        }

        this.showSuggestCardsSpinner$.next(show);
    }

    abort() {
        this.suggestCardsAborter$.next(undefined);
        this.triggerSuggestCardsSpinner(false);
    }

    ngOnInit() {
        this.wt1Report = new WT1Report(this.wt1Service);

        combineLatest([
            this.worksheetContextService.getRootCard(),
            this.getSuggestionsObservable(),
            this.nameFilter$,
        ])
        .pipe(
            untilDestroyed(this),
        )
        .subscribe(([worksheetRootCard, engineSuggestions, nameFilter]) => {
            if (worksheetRootCard == null) {
                // the worksheet has not loaded
                return;
            }

            const filteredVariables = this.filterByName(engineSuggestions.suggestedVariables, nameFilter);
            this.variables = _.orderBy(filteredVariables, ['individualScore'], ['desc']);
            this.assistantSuggestions = this.mergeWithSelection(engineSuggestions.suggestedCards);
            this.markAlreadyIncludedSuggestions(worksheetRootCard.cards);

            const lastSuggestedCards = engineSuggestions.suggestedCards.map(it => it.card);
            this.wt1Report.addSuggestedCards(lastSuggestedCards);

            if (this.isFirstRun) {
                this.isFirstRun = false;
                const variablesCount = this.variables.length;
                this.wt1Report.setVariablesCount(variablesCount);
                this.hasEmptyDataset = variablesCount === 0;
            }

            this.changeDetectorRef.markForCheck();
        });
    }

    private filterByName(
        variables: SuggestCards.SuggestedVariable[],
        nameFilter: string | null
    ): SuggestCards.SuggestedVariable[] {
        if (nameFilter == null) {
            return variables;
        }

        const subName = nameFilter.toLocaleLowerCase();
        return variables.filter(it => it.name.toLocaleLowerCase().includes(subName));
    }

    private mergeWithSelection(rawSuggestedCards: SuggestCards.SuggestedCard[]): AssistantSuggestion[] {
        // The card ids are randomly assigned by the backend - even when they hold the
        // same definition - so we need to clear the ids before comparing the cards.
        const referenceCards = this.selectedAssistantSuggestions.map(it => clearCardIds(it.card));
        const candidateCards = rawSuggestedCards.map(it => clearCardIds(it.card));
        const newSuggestions: AssistantSuggestion[] = [];


        candidateCards.forEach((candidate, i) => {
            const duplicate = referenceCards.some(reference => _.isEqual(candidate, reference));

            if (duplicate) {
                return;
            }

            const originalSuggestedCard = rawSuggestedCards[i];
            const suggestedCard = {
                ...originalSuggestedCard,
                // Ensure the card and the miniature have different IDs
                // (IDs are used as keys by the <height-equalizer> and we want to avoid interference between preview and miniature)
                miniatureCard: resetCardIds(originalSuggestedCard.miniatureCard),
                card: resetCardIds(originalSuggestedCard.card)
            }

            newSuggestions.push(new AssistantSuggestion(suggestedCard));
        });

        // Return selected suggestions in first, followed by all new suggestions.
        return this.selectedAssistantSuggestions.concat(newSuggestions);
    }

    private markAlreadyIncludedSuggestions(worksheetTopLevelCards: Card[]) {
        const worksheetCards = worksheetTopLevelCards.map(it => clearCardIds(it));
        const assistantCards = this.assistantSuggestions.map(it => clearCardIds(it.card));

        assistantCards.forEach((candidate, i) => {
            this.assistantSuggestions[i].isAlreadyIncludedInWorksheet =
                worksheetCards.some(reference => _.isEqual(candidate, reference));
        });
    }

    ngOnDestroy() {
        // Send the usage report to WT1 when the session ends - aka when the
        // component is destroyed.
        const selectedCards = this.selectedAssistantSuggestions.map(it => it.card);
        this.wt1Report.setSelectedCards(selectedCards);
        this.wt1Report.send();
    }

    toggleVariable(variable: SuggestCards.SuggestedVariable) {
        const alreadySelectedNames = this.selectedVariableNames$.getValue();

        if (alreadySelectedNames.includes(variable.name)) {
            this.unselectVariableName(variable.name);
        } else {
            this.selectVariableName(variable.name);
        }
    }

    private selectVariableName(variableName: string) {
        const alreadySelectedNames = this.selectedVariableNames$.getValue();
        const selectedNames = [
            ...alreadySelectedNames,
            variableName,
        ];

        this.selectedVariableNames$.next(selectedNames);
    }

    unselectVariableName(variableName: string) {
        const alreadySelectedNames = this.selectedVariableNames$.getValue();
        const selectedNames = alreadySelectedNames.filter(name => name !== variableName);
        this.selectedVariableNames$.next(selectedNames);
    }

    isVariableSelected(variable: SuggestCards.SuggestedVariable): boolean {
        const alreadySelectedNames = this.selectedVariableNames$.getValue();
        return alreadySelectedNames.includes(variable.name);
    }

    isContinuous(variable: SuggestCards.SuggestedVariable): boolean {
        return variable.type === Variable.Type.CONTINUOUS;
    }

    isCategorical(variable: SuggestCards.SuggestedVariable): boolean {
        return variable.type === Variable.Type.CATEGORICAL;
    }

    showVariableScore(variable: SuggestCards.SuggestedVariable): boolean {
        return this.hasSelectedVariables && !this.isVariableSelected(variable);
    }

    getVariableScore(variable: SuggestCards.SuggestedVariable): number {
        return Math.min(variable.totalScore, 0.95) * 100;
    }

    canSave() {
        return this.selectedAssistantSuggestions.length > 0;
    }

    save() {
        const cards = this.selectedAssistantSuggestions.map(it => it.card);
        this.worksheetContextService.addTopLevelCards(cards);
        this.wt1Report.addCreatedCards(cards);
        this.dialogRef.close('');
    }

    dismiss() {
        this.dialogRef.close('');
    }

    openCardPreview(suggestion: AssistantSuggestion) {
        // Recompute the result on the whole sample because the suggested card
        // are only computed on the first 1000 rows.
        this.worksheetContextService.computeCardWithErrorContext(
            suggestion.card,
            this.cardPreviewErrorContext
        )
        .pipe(
            filter(result => result != null),
            untilDestroyed(this),
        )
        .subscribe((result) => {
            this.showCardPreview(suggestion, result!);
        });
    }

    showCardPreview(suggestion: AssistantSuggestion, result: CardResult) {
        const { card, isSelected } = suggestion;
        this.wt1Report.addPreviewedCard(card);

        const data: CardPreviewModalComponentData = {
            isSelected,
            params: card,
            results: result,
        };

        this.modalsService.open(CardPreviewModalComponent, data, ModalShape.WIDE, this.viewContainerRef)
            .then((action: CardPreviewModalComponentAction) => {
                if (action.toggleSelection) {
                    suggestion.toggleSelection();
                    this.changeDetectorRef.markForCheck();
                }
            })
            .catch(() => { /* silently swallow the error as the user may simply dismiss the modal */ })
    }

    trackByName(index: number, v: SuggestCards.SuggestedVariable) {
        return v.name;
    }

    formatExplanations(variable: SuggestCards.SuggestedVariable): string {
        return variable.explanations.join("\n");
    }

    resetApiErrors() {
        this.suggestCardsErrorContext.resetError();
        this.cardPreviewErrorContext.resetError();
    }
}
