import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { uniqueId } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class MigrationStoreService {
    private observables: Record<string, Observable<any>> = {};
    private subscriptions: Record<string, Subscription> = {};

    constructor() {
    }

    register(key: string, obs: Observable<any>) {
        if (this.observables[key]) {
            throw Error(`Given key: ${key} is already registered`);
        }

        this.observables[key] = obs;
    }

    unregister(key: string) {
        if (this.observables[key]) {
            delete this.observables[key];
        }
    }

    subscribe(key: string, callback: (result: any) => any): string {
        const subId = key + uniqueId();

        if (this.subscriptions[subId]) {
            throw Error(`System error while trying to subscribe on given key: ${key}`);
        }

        this.subscriptions[subId] = this.observables[key].subscribe(callback);
        return subId;
    }

    unsubscribe(subId: string) {
        if (this.subscriptions[subId]) {
            this.subscriptions[subId].unsubscribe();
        }
    }
}
