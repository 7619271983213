<div class="data-collections-content-table__filters">
    <div class="dku-medium-title data-collection-content-table__title">
        Datasets
    </div>
    <div class="data-collections-content-table__filters__search">
        <span><i class="dku-icon-search-16"></i></span>
        <input type="text"
            [(ngModel)]="searchQueryInput"
            placeholder="Search Datasets"
        />
    </div>
</div>

<table mat-table [dataSource]="sortedContent$">
    <!-- Icon -->
    <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item">
            <i *ngIf="isDataset(item)" class="icon universe-color dataset {{item.datasetType | typeToIcon | toModernIcon:24}}" ></i>
        </td>
    </ng-container>

    <!-- Dataset name -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef
            (click)="toggleSortBy('name')">
            <button class="data-collection-table__sortable-column-header">
                Name
                <i [class]="getCurrentSortIcon('name')"></i>
            </button>
        </th>
        <td mat-cell *matCellDef="let item">
            <div *ngIf="isDataset(item)"
                [matTooltip]="item.name" isEllipsisActive
            >
                {{ item.name }}
            </div>
        </td>
    </ng-container>

    <!-- Project name -->
    <ng-container matColumnDef="sourceProjectName">
        <th mat-header-cell *matHeaderCellDef
            (click)="toggleSortBy('sourceProjectName')">
            <button class="data-collection-table__sortable-column-header">
                Project
                <i [class]="getCurrentSortIcon('sourceProjectName')"></i>
            </button>
            
        </th>
        <td mat-cell *matCellDef="let item">
            <div *ngIf="isDataset(item)"
                [matTooltip]="item.sourceProjectName" isEllipsisActive
            >
                {{ item.sourceProjectName }}
            </div>
        </td>
    </ng-container>

    <!-- Used in projects count -->
    <ng-container matColumnDef="usedInProjectCount">
        <th mat-header-cell *matHeaderCellDef
            (click)="toggleSortBy('usedInProjectCount')"
        >
            <button class="data-collection-table__sortable-column-header">
                <div  matTooltip="The total number of projects this dataset is used in"
                    matTooltipPosition="above"
                >
                    # Projects
                </div>
                <i [class]="getCurrentSortIcon('usedInProjectCount')"></i>
            </button>
        </th>
        <td mat-cell *matCellDef="let item">
            <ng-container *ngIf="isReadableDataset(item)">
                {{ item.usedInProjectCount }}
            </ng-container>
        </td>
    </ng-container>

    <!-- Columns count-->
    <ng-container matColumnDef="columnCount">
        <th mat-header-cell *matHeaderCellDef
            (click)="toggleSortBy('columnCount')"
        >
            <button class="data-collection-table__sortable-column-header">
                <div matTooltip="The number of columns in this dataset"
                    matTooltipPosition="above"
                >
                    # Columns
                </div>
                <i [class]="getCurrentSortIcon('columnCount')"></i>
            </button>
        </th>
        <td mat-cell *matCellDef="let item">
            <ng-container *ngIf="isReadableDataset(item)">
                {{ item.columnCount }}
            </ng-container>
        </td>
    </ng-container>

    <!-- Last modified -->
    <ng-container matColumnDef="lastBuiltOn">
        <th mat-header-cell *matHeaderCellDef
            (click)="toggleSortBy('lastBuiltOn')"
        >
            <button class="data-collection-table__sortable-column-header">
                Last build
                <i [class]="getCurrentSortIcon('lastBuiltOn')"></i>
            </button>
        </th>
        <td mat-cell *matCellDef="let item">
            <div *ngIf="isReadableDataset(item) && item.lastBuiltOn !== -1; else noBuildDate"
                [matTooltip]="item.lastBuiltOn | friendlyDateTime"
            >
                {{ item.lastBuiltOn | friendlyTimeDeltaShort }}
            </div>
            <ng-template #noBuildDate>
                <div [matTooltip]="isReadableDataset(item) ? 'No build information was found' : 'You are not allowed to see that information'">
                    -
                </div>
            </ng-template>
        </td>
    </ng-container>

    <!-- Description -->
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef
            (click)="toggleSortBy('description')"
        >
            <button class="data-collection-table__sortable-column-header">
                Description
                <i [class]="getCurrentSortIcon('description')"></i>
            </button>
        </th>
        <td mat-cell *matCellDef="let item">
            <div *ngIf="isDataset(item)"
                [matTooltip]="item.description" isEllipsisActive
            >
                {{ item.description }}
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let item; columns: displayedColumns;"
        (click)="selectedItemChange.emit(item)"
        [class.selected]="selectedItem === item"
    ></tr>

</table>

<div  *ngIf="(sortedContent$ | async)?.length === 0"
    class="alert alert-info">
    <h4>No match</h4>
    <p>
        Clear your filters to see more
        <button class="btn btn--secondary mleft8" (click)="resetFilters()">Clear filters</button>
    </p>
</div>
