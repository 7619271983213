/**
 * Generated from com.dataiku.dip.labeling.Label
 */
export interface _Label {
    annotations: any /* TODO: Missing translation of T */[];
}

export type Label = Label.ImageClassificationLabel | Label.NamedEntityExtractionLabel | Label.ObjectDetectionLabel;

export namespace Label {
    export const type = ['IMAGE_CLASSIFICATION', 'NAMED_ENTITY_EXTRACTION', 'OBJECT_DETECTION'] as const;
    export function isImageClassificationLabel(toTest: Label): toTest is Label.ImageClassificationLabel { return toTest['type'] === 'IMAGE_CLASSIFICATION'; }
    export function isNamedEntityExtractionLabel(toTest: Label): toTest is Label.NamedEntityExtractionLabel { return toTest['type'] === 'NAMED_ENTITY_EXTRACTION'; }
    export function isObjectDetectionLabel(toTest: Label): toTest is Label.ObjectDetectionLabel { return toTest['type'] === 'OBJECT_DETECTION'; }
}

export namespace Label {
    /**
     * Generated from com.dataiku.dip.labeling.Label$NamedEntityExtractionLabel
     */
    export interface NamedEntityExtractionLabel extends _Label {
        // PolyJson type
        type: 'NAMED_ENTITY_EXTRACTION';
    }

    export namespace NamedEntityExtractionLabel {
        export const type = 'NAMED_ENTITY_EXTRACTION';
    }



    /**
     * Generated from com.dataiku.dip.labeling.Label$ImageClassificationLabel
     */
    export interface ImageClassificationLabel extends _Label {
        // PolyJson type
        type: 'IMAGE_CLASSIFICATION';
    }

    export namespace ImageClassificationLabel {
        export const type = 'IMAGE_CLASSIFICATION';
    }



    /**
     * Generated from com.dataiku.dip.labeling.Label$ObjectDetectionLabel
     */
    export interface ObjectDetectionLabel extends _Label {
        // PolyJson type
        type: 'OBJECT_DETECTION';
    }

    export namespace ObjectDetectionLabel {
        export const type = 'OBJECT_DETECTION';
    }


}