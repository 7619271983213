import type {CodeEnvSelection} from './../code/code-env-selection';

/**
 * Generated from com.dataiku.dip.coremodel.AppHomepageTile
 */
export interface _AppHomepageTile {
    help: string;
    helpTitle: string;
    prompt: string;
    type: AppHomepageTile.AppHomepageTileType;
    visibilityCondition: string;
}

export type AppHomepageTile = AppHomepageTile.DashboardLinkTile | AppHomepageTile.DownloadDashboardTile | AppHomepageTile.DownloadDatasetTile | AppHomepageTile.DownloadManagedFolderTile | AppHomepageTile.DownloadReportTile | AppHomepageTile.EditAnyDatasetTile | AppHomepageTile.EditAnyStreamingEndpointTile | AppHomepageTile.EditFSDatasetTile | AppHomepageTile.EditFolderTile | AppHomepageTile.EditInlineDatasetTile | AppHomepageTile.EditSQLDatasetTile | AppHomepageTile.GuessTrainDeployTile | AppHomepageTile.ManagedFolderLinkTile | AppHomepageTile.ManagedFolderSetFileTile | AppHomepageTile.ProjectVariablesTile | AppHomepageTile.PropagateSchemaTile | AppHomepageTile.RunInlinePythonTile | AppHomepageTile.RunScenarioTile | AppHomepageTile.UploadDatasetSetFileTile | AppHomepageTile.VariableDisplayTile;

export namespace AppHomepageTile {
    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$RunInlinePythonTile
     */
    export interface RunInlinePythonTile extends _AppHomepageTile {
        buttonText: string;
        code: string;
        desc: any /* TODO: Missing translation of com.dataiku.dip.scheduler.runnables.PythonRunnableDesc */;
        envSelection: CodeEnvSelection;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$EditFolderTile
     */
    export interface EditFolderTile extends _AppHomepageTile {
        behavior: AppHomepageTile.EditFolderTileBehavior;
        folderId: string;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$DownloadReportTile
     */
    export interface DownloadReportTile extends _AppHomepageTile {
        format: any /* TODO: Missing translation of com.dataiku.dip.reports.Report$ReportOutputFormat */;
        reportId: string;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$PropagateSchemaTileBehavior
     */
    export enum PropagateSchemaTileBehavior {
        MANUAL = 'MANUAL',
        AUTO_NO_BUILD = 'AUTO_NO_BUILD',
        AUTO_WITH_BUILDS = 'AUTO_WITH_BUILDS'
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$DashboardLinkTile
     */
    export interface DashboardLinkTile extends _AppHomepageTile {
        dashboardId: string;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$EditAnyDatasetTile
     */
    export interface EditAnyDatasetTile extends _AppHomepageTile {
        datasetName: string;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$GuessTrainDeployTile
     */
    export interface GuessTrainDeployTile extends _AppHomepageTile {
        modelId: string;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$AppHomepageTileType
     */
    export enum AppHomepageTileType {
        UPLOAD_DATASET_SET_FILE = 'UPLOAD_DATASET_SET_FILE',
        INLINE_DATASET_EDIT = 'INLINE_DATASET_EDIT',
        MANAGED_FOLDER_ADD_FILE = 'MANAGED_FOLDER_ADD_FILE',
        DATASET_EDIT_SETTINGS = 'DATASET_EDIT_SETTINGS',
        STREAMING_ENDPOINT_EDIT_SETTINGS = 'STREAMING_ENDPOINT_EDIT_SETTINGS',
        FILES_BASED_DATASET_BROWSE_AND_PREVIEW = 'FILES_BASED_DATASET_BROWSE_AND_PREVIEW',
        MANAGED_FOLDER_BROWSE = 'MANAGED_FOLDER_BROWSE',
        CONNECTION_EXPLORER_TO_REPLACE_THE_SETTINGS_OF_A_DATASET_WITH_A_NEW_TABLE_REFERENCE = 'CONNECTION_EXPLORER_TO_REPLACE_THE_SETTINGS_OF_A_DATASET_WITH_A_NEW_TABLE_REFERENCE',
        PROJECT_VARIABLES_EDIT = 'PROJECT_VARIABLES_EDIT',
        PERFORM_SCHEMA_PROPAGATION = 'PERFORM_SCHEMA_PROPAGATION',
        GUESS_TRAIN_DEPLOY = 'GUESS_TRAIN_DEPLOY',
        SCENARIO_RUN = 'SCENARIO_RUN',
        INLINE_PYTHON_RUN = 'INLINE_PYTHON_RUN',
        DASHBOARD_LINK = 'DASHBOARD_LINK',
        DOWNLOAD_DATASET = 'DOWNLOAD_DATASET',
        DOWNLOAD_RMARKDOWN = 'DOWNLOAD_RMARKDOWN',
        MANAGED_FOLDER_LINK = 'MANAGED_FOLDER_LINK',
        DOWNLOAD_MANAGED_FOLDER_FILE = 'DOWNLOAD_MANAGED_FOLDER_FILE',
        DOWNLOAD_DASHBOARD_EXPORT = 'DOWNLOAD_DASHBOARD_EXPORT',
        VARIABLE_DISPLAY = 'VARIABLE_DISPLAY'
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$DownloadManagedFolderTile
     */
    export interface DownloadManagedFolderTile extends _AppHomepageTile {
        folderId: string;
        itemPath: string;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$EditFSDatasetTile
     */
    export interface EditFSDatasetTile extends _AppHomepageTile {
        behavior: AppHomepageTile.EditFSDatasetTileBehavior;
        datasetName: string;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$UploadDatasetSetFileTile
     */
    export interface UploadDatasetSetFileTile extends _AppHomepageTile {
        behavior: AppHomepageTile.UploadDatasetSetFileTileBehavior;
        datasetName: string;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$ManagedFolderSetFileTileBehavior
     */
    export enum ManagedFolderSetFileTileBehavior {
        GO_TO_FOLDER = 'GO_TO_FOLDER',
        INLINE_UPLOAD = 'INLINE_UPLOAD'
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$PartitionDef
     */
    export interface PartitionDef {
        from: string;
        to: string;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$ManagedFolderSetFileTile
     */
    export interface ManagedFolderSetFileTile extends _AppHomepageTile {
        behavior: AppHomepageTile.ManagedFolderSetFileTileBehavior;
        folderId: string;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$DownloadDashboardTile
     */
    export interface DownloadDashboardTile extends _AppHomepageTile {
        dashboardId: string;
        format: any /* TODO: Missing translation of com.dataiku.dip.graphicsexport.model.ExportFormat */;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$EditFSDatasetTileBehavior
     */
    export enum EditFSDatasetTileBehavior {
        GO_TO_DATASET = 'GO_TO_DATASET',
        INLINE_BROWSE_ONLY = 'INLINE_BROWSE_ONLY',
        INLINE_BROWSE_AND_REDETECT = 'INLINE_BROWSE_AND_REDETECT',
        INLINE_BROWSE_REDETECT_AND_INFER = 'INLINE_BROWSE_REDETECT_AND_INFER',
        MODAL_BROWSE_REDETECT_AND_INFER = 'MODAL_BROWSE_REDETECT_AND_INFER'
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$DownloadDatasetTile
     */
    export interface DownloadDatasetTile extends _AppHomepageTile {
        datasetName: string;
        exportParams: any /* TODO: Missing translation of com.dataiku.dip.export.ExportParams */;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$VariableDisplayTile
     */
    export interface VariableDisplayTile extends _AppHomepageTile {
        content: string;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$RunScenarioTile
     */
    export interface RunScenarioTile extends _AppHomepageTile {
        buttonText: string;
        scenarioId: string;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$EditSQLDatasetTile
     */
    export interface EditSQLDatasetTile extends _AppHomepageTile {
        behavior: AppHomepageTile.EditSQLDatasetTileBehavior;
        datasetName: string;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$UploadDatasetSetFileTileBehavior
     */
    export enum UploadDatasetSetFileTileBehavior {
        GO_TO_DATASET = 'GO_TO_DATASET',
        INLINE_UPLOAD_ONLY = 'INLINE_UPLOAD_ONLY',
        INLINE_UPLOAD_AND_REDETECT = 'INLINE_UPLOAD_AND_REDETECT',
        INLINE_UPLOAD_REDETECT_AND_INFER = 'INLINE_UPLOAD_REDETECT_AND_INFER'
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$PropagateSchemaTile
     */
    export interface PropagateSchemaTile extends _AppHomepageTile {
        behavior: AppHomepageTile.PropagateSchemaTileBehavior;
        datasetName: string;
        excludedRecipes: string[];
        markAsOkRecipes: string[];
        partitionByComputable: AppHomepageTile.PartitionDef[];
        partitionByDim: AppHomepageTile.PartitionDef[];
        recipeUpdateOptions: {[key: string]: any};
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$EditAnyStreamingEndpointTile
     */
    export interface EditAnyStreamingEndpointTile extends _AppHomepageTile {
        streamingEndpointId: string;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$EditInlineDatasetTile
     */
    export interface EditInlineDatasetTile extends _AppHomepageTile {
        datasetName: string;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$ProjectVariablesTile
     */
    export interface ProjectVariablesTile extends _AppHomepageTile {
        behavior: AppHomepageTile.ProjectVariablesTileBehavior;
        buttonText: string;
        html: string;
        js: string;
        module: string;
        params: any /* TODO: Missing translation of com.dataiku.dip.autoconfig.ParamDesc */[];
        python: string;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$EditFolderTileBehavior
     */
    export enum EditFolderTileBehavior {
        GO_TO_FOLDER = 'GO_TO_FOLDER',
        INLINE_BROWSE = 'INLINE_BROWSE',
        MODAL_BROWSE = 'MODAL_BROWSE'
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$EditSQLDatasetTileBehavior
     */
    export enum EditSQLDatasetTileBehavior {
        GO_TO_DATASET = 'GO_TO_DATASET',
        MODAL_BROWSE = 'MODAL_BROWSE'
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$ProjectVariablesTileBehavior
     */
    export enum ProjectVariablesTileBehavior {
        INLINE_AUTO_SAVE = 'INLINE_AUTO_SAVE',
        INLINE_EXPLICIT_SAVE = 'INLINE_EXPLICIT_SAVE',
        MODAL = 'MODAL'
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppHomepageTile$ManagedFolderLinkTile
     */
    export interface ManagedFolderLinkTile extends _AppHomepageTile {
        folderId: string;
    }
}