import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ElementRef, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, TemplateRef, ViewChildren } from '@angular/core';
import { UIAnnotation, UIBoundingBox, UINamedEntity } from '@features/labeling/models/annotation';
import { UILabel } from '@features/labeling/models/label';
import { LabelingColorService } from '@features/labeling/services/labeling-color.service';
import { LabelingService } from '@features/labeling/services/labeling.service';
import { LabelingTask } from 'generated-sources';
import { clone, cloneDeep } from 'lodash';

@Component({
    selector: 'annotate-annotation-list',
    templateUrl: './annotate-annotation-list.component.html',
    styleUrls: [
        '../../shared-styles/annotation.less',
        '../../shared-styles/right-panel.less',
        './annotate-annotation-list.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnotateAnnotationListComponent implements OnChanges {
    @Input() skipped: boolean;
    @Input() label: UILabel | null;
    @Output() labelChange = new EventEmitter<UILabel>();
    @ViewChildren('annotationList') annotationList: QueryList<ElementRef>;
    @ContentChild(TemplateRef) public actionsTemplate: TemplateRef<{ item: UIAnnotation; }>;

    public displayedLabel: UILabel;
    readonly UIBoundingBox = UIBoundingBox;
    readonly UINamedEntity = UINamedEntity;
    readonly LabelingTaskType = LabelingTask.LabelingTaskType;

    private shouldScroll = true; // do not scroll if action occurs within this component

    constructor(
        public labelingService: LabelingService,
        private labelingColorService: LabelingColorService,
        private changeDetectorRef: ChangeDetectorRef
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.label && this.label) {
            this.displayedLabel = cloneDeep(this.label);

            const selectedAnnotations = this.displayedLabel.annotations.filter(annotation => annotation.selected);

            if (selectedAnnotations.length && this.shouldScroll) {
                // find the annotation that was just selected
                const selectedAnnotation = selectedAnnotations.filter(annotation => !changes.label.previousValue.annotations.includes(annotation))[0];

                if (selectedAnnotation) {
                    // detect changes to ensure DOM is up to date for scrolling
                    this.changeDetectorRef.detectChanges();
                    // scroll to selected annotation; if not yet in DOM (because just created), scroll to current last one
                    const id = Math.min(this.displayedLabel.annotations.indexOf(selectedAnnotation), this.annotationList.length - 1);
                    this.annotationList.filter((_, index) => index === id)[0]?.nativeElement.scrollIntoView({
                        block: 'center'
                    });
                }
            }

            this.shouldScroll = true;
        }
    }

    color(category: string) {
        return this.labelingColorService.getColor(category);
    }

    selectAnnotation(event: MouseEvent, annotation: UIAnnotation) {
        if(!this.displayedLabel) {
            return;
        }
        const selecting = !annotation.selected;

        annotation.selected = selecting;
        if (selecting && !event.shiftKey) {
            this.displayedLabel.annotations.forEach(a => {
                a.selected = false;
                if (a.equals(annotation)) {
                    a.selected = selecting;
                }
            });
        }

        this.shouldScroll = false;
        this.labelChange.emit(this.displayedLabel);
    }

    deleteAnnotation(annotationToDelete: UIAnnotation) {
        if(!this.displayedLabel) {
            return;
        }

        this.displayedLabel.annotations = this.displayedLabel.annotations.filter(annotation => !annotation.equals(annotationToDelete));
        this.labelChange.emit(this.displayedLabel);
    }
}
