import { DkuHttpService } from 'dku-frontend-core';
import { Injectable } from '@angular/core';
import { BasePermissions, TaggableObjectsService, UIDataCatalog, UIDataCollection, AbstractDataCollectionItemRef } from 'src/generated-sources';
import { pluck } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PrivateDataCollectionsAPI {
    constructor(private dkuHttp: DkuHttpService) {}

    create(creationInfo: UIDataCollection.CreationInfo) {
        return this.dkuHttp.request<{id: string}>('POST', '/data-collections/create', { creationInfo: creationInfo }).pipe(
            pluck('id')
        );
    }

    getInfo(id: string) {
        return this.dkuHttp.request<UIDataCollection.DataCollectionInfo>('GET', '/data-collections/get-info', { id });
    }

    updateMetadata(id: string, metadata: UIDataCollection.MetadataInfo) {
        return this.dkuHttp.request<void>('PUT', '/data-collections/update-metadata', { id, metadata });
    }

    updatePermissions(id: string, permissions: BasePermissions.PermissionItem[]) {
        return this.dkuHttp.request<void>('PUT', '/data-collections/update-permissions', {id, permissions});
    }

    addObjects(id: string, items: TaggableObjectsService.TaggableObjectRef[], options: UIDataCollection.AddObjectsOptions) {
        return this.dkuHttp.request<UIDataCollection.AddObjectsResponse>('POST', '/data-collections/add-objects', {dataCollectionId: id, items: items, options});
    }

    listWithDetails() {
        return this.dkuHttp.request<UIDataCollection.ListItemWithDetails[]>('GET', '/data-collections/list-with-details');
    }

    listAllTags() {
        return this.dkuHttp.request<string[]>('GET', '/data-collections/list-all-tags');
    }

    delete(id: string) {
        return this.dkuHttp.request<void>('POST', '/data-collections/delete', { id });
    }

    getItemDetails(dataCollectionId: string, itemRef: AbstractDataCollectionItemRef) {
        return this.dkuHttp.request<UIDataCatalog.AbstractDataCatalogItemDetails>('GET', '/data-collections/get-item-details', {dataCollectionId, itemRef});
    }

    removeItem(dataCollectionId: string, itemRef: AbstractDataCollectionItemRef) {
        return this.dkuHttp.request<void>('POST', '/data-collections/remove-item', {dataCollectionId, itemRef});
    }
}
