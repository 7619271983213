import { Component, Input, forwardRef, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { EditableListBase } from '../editable-list/editable-list.component';
import { FormArrayRepeat } from '@utils/form-array-repeat';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { TolerantFormGroup } from '@utils/tolerant-form-group';

@UntilDestroy()
@Component({
    selector: 'credentials-list',
    templateUrl: './credentials-list.component.html',
    styleUrls: ['./credentials-list.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CredentialsListComponent),
            multi: true
        }
    ]
})

/**
 * Editable list of credentials.
 * Uses editable-list-component with a dedicated template.
 * 
 * @example
 * <credentials-list-component [(ngModel)]="credentials"></credentials-list-component>
 */
export class CredentialsListComponent extends EditableListBase implements OnDestroy {
    @Input() addLabel: string = 'Add Credentials';
    @Input() keyPlaceholder: string = 'User Name';
    @Input() valuePlaceholder: string = 'User Password';
    @Input() keyIdentifier: string = 'key';
    @Input() valueIdentifier: string = 'value';
    @Input() canBeFinal: boolean = true;
    @Input() keyRequired: boolean = false;
    @Input() valueRequired: boolean = false;
    @Input() required: boolean = false;
    @Input() warnIfTrimmable: boolean = false;
    formBuilder: FormBuilder;
    private changesSubscription: Subscription;

    constructor(fb: FormBuilder) {
        super();
        this.formBuilder = fb;
    }

    ngOnChanges() {
        let keyValidators: any[] = [];
        let valueValidators: any[] = [];

        if (this.required) {
            this.keyRequired = true;
            this.valueRequired = true;
        }

        if (this.keyRequired) {
            keyValidators.push(Validators.required);
        }

        if (this.valueRequired) {
            valueValidators.push(Validators.required);
        }

        this.itemsFormArray = new FormArrayRepeat(() => {
            const itemsControls = {
                [this.keyIdentifier]: this.formBuilder.control('', keyValidators),
                [this.valueIdentifier]: this.formBuilder.control('', valueValidators),
                secret: this.formBuilder.control(false, [])
            }
    
            if (this.canBeFinal) {
                itemsControls.isFinal = this.formBuilder.control(false, []);
            }

            return new TolerantFormGroup(itemsControls);
        });

        if (this.items && this.items.length) {
            this.itemsFormArray.setValue(this.items);
        }

        if (this.changesSubscription) {
            this.changesSubscription.unsubscribe();
        }

        this.changesSubscription = this.itemsFormArray.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((event) => { this.onChange(event); });
    }

    ngOnDestroy(): void { }
}
