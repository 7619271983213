import { Injectable, Inject } from '@angular/core';
import { NavUniverses } from '@core/nav/nav-universes.enum';
import { NavTabs } from '@core/nav/nav-tabs.enum';
import { NavItems } from '@core/nav/nav-items.enum';

@Injectable({
    providedIn: 'root'
})
export class TopNavService {

    constructor(
        @Inject('TopNav') private topNav: {setLocation: Function, setPageTitle: Function, setItem: Function, setNoItem: Function},
    ) {
    }
    setLocation(top: NavUniverses, left?: NavItems, tabsType?: NavTabs, tab?: string) {
        this.topNav.setLocation(top, left, tabsType, tab);
    }
    setPageTitle(title: string) {
        this.topNav.setPageTitle(title);
    }
    setItem(type: NavItems, id: string, data?: object) {
        this.topNav.setItem(type, id, data);
    }
    setNoItem() {
        this.topNav.setNoItem();
    }
}
