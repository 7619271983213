import { AbstractControl } from '@angular/forms';
import { concat, defer, of, Observable } from 'rxjs';

// Observe a form control and make sure the current value is emitted
export function observeFormControl<T = any>(formControl: AbstractControl): Observable<T> {
    return concat(
        defer(() => of(formControl.value)),
        formControl.valueChanges
    );
}
