import type {ITaggingService} from './../../server/services/itagging-service';

/**
 * Generated from com.dataiku.dip.timelines.workspace.WorkspaceTimelineItem
 */
export interface WorkspaceTimelineItem {
    action: WorkspaceTimelineItem.WorkspaceActionType;
    details: {[key: string]: any};
    objectId: string;
    objectType: ITaggingService.TaggableType;
    projectKey: string;
    time: number;
    user: string;
    workspaceKey: string;
}

export namespace WorkspaceTimelineItem {
    /**
     * Generated from com.dataiku.dip.timelines.workspace.WorkspaceTimelineItem$WorkspaceActionType
     */
    export enum WorkspaceActionType {
        WORKSPACE_CREATE = 'WORKSPACE_CREATE',
        WORKSPACE_USER_ADD = 'WORKSPACE_USER_ADD',
        WORKSPACE_USER_REMOVE = 'WORKSPACE_USER_REMOVE',
        WORKSPACE_OBJECT_ADD = 'WORKSPACE_OBJECT_ADD',
        WORKSPACE_OBJECT_REMOVE = 'WORKSPACE_OBJECT_REMOVE',
        WORKSPACE_OBJECT_DELETE = 'WORKSPACE_OBJECT_DELETE',
        WORKSPACE_UNKNOWN = 'WORKSPACE_UNKNOWN'
    }
}