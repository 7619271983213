import { ElementRef, Injector, Directive, Input, Inject, SimpleChanges, OnChanges } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { fairAny } from 'dku-frontend-core';

@Directive({
    selector: 'related-by-type'
})
export class RelatedByTypeComponent extends UpgradeComponent implements OnChanges {
    @Input() elementsByType: any;
    @Input() baseItemProjectKey: string;
    @Input() baseString: string;

    constructor(@Inject('$rootScope') public $rootScope: fairAny,
    elementRef: ElementRef, injector: Injector) {
        super('ng1RelatedByType', elementRef, injector);
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Run AngularJS change detetion
        this.$rootScope.$applyAsync();
        super.ngOnChanges(changes);
    }
}
