<!-- Note about qa-selectors:

    Most card bodies don't need to add 'eda-card-body' because they 
    are almost always embedded within a <card-body> component. This component 
    is an exception because it is always used directly 
    (outside of any <card-body> wrapper) -->

<div
    class="card-column"
    [qa]="{'eda-card-body': {'id': params.id, 'type': params.type}}"
    *ngIf="collapseStates$ | async as collapseStates"
>
    <div
        *ngFor="let card of params.cards; let index = index; trackBy: trackByCardId"
        class="stat-card"
        [qa]="{'eda-stat-card': {id: params.id, type: params.type}}"
    >
        <div class="stat-card__title">
            <span
                (click)="toggleStatCard(card, !collapseStates[index])"
                class="stat-card__collapse"
            >
                <i
                    *ngIf="showCollapsingControls"
                    class="icon-chevron-right chevron"
                    [class.chevron--collapsed]="collapseStates[index]"
                ></i>
                &nbsp;
                {{ card | cardTitle: 'basic' }}
            </span>
            <card-menu
                [params]="card"
                [columnParams]="params"
                [readOnly]="readOnly"
                [extendedActions]="extendedActions"
                (action)="handleStatCardAction(index, $event)"
                [deletable]="false"
                class="pull-right eda__header-menu"
            ></card-menu>
        </div>
        <div [@fadeInOut]="collapseStates[index] ? 'closed' : 'opened'">
            <div *ngIf="!collapseStates[index] | delayWhenFalse">
                <height-equalizer [key]="getCardKey(card)">
                    <card-body
                        class="stat-card__body"
                        [params]="card"
                        [results]="results.results[index]"
                        [readOnly]="readOnly"
                        [extendedActions]="extendedActions"
                        [hasFixedHeight]="true"
                        (action)="handleStatCardAction(index, $event)"
                    ></card-body>
                </height-equalizer>
            </div>
        </div>
    </div>
</div>
