import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DKURootScope } from '@shared/models/dku-root-scope.model';

@Pipe({
  name: 'featureFlagEnabled'
})
export class FeatureFlagEnabledPipe implements PipeTransform {

    constructor(
        @Inject('$rootScope') private $root: DKURootScope
    ) {}

  transform(value: string): boolean {
    return this.$root.appConfig.featureFlags.includes(value);
  }
}
