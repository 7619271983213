import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MathUtilsService {
    log10(x: number) {
        return Math.log(x)/Math.log(10);
    }

    isInteger(x: number) {
        return Math.floor(x) == x;
    }
}
