import { NgModule } from '@angular/core';
import { ComponentsModule } from '@shared/components/components.module';
import { DatasetViewExploreImagesComponent } from './explore-images/image-feed/dataset-view-explore-images.component';
import { ImageClassificationDatasetViewExploreImagesComponent } from './explore-images/image-feed/image-classification/image-classification-dataset-view-explore-images.component';
import { ObjectDetectionDatasetViewExploreImagesComponent } from './explore-images/image-feed/object-detection/object-detection-dataset-view-explore-images.component';
import { SimpleDatasetViewExploreImagesComponent } from './explore-images/image-feed/simple/simple-dataset-view-explore-images.component';
import { EditableDatasetSpreadsheetComponent } from './editable-dataset-spreadsheet/editable-dataset-spreadsheet.component';
import { SpreadsheetColumnHeaderComponent } from './editable-dataset-spreadsheet/spreadsheet-column-header/spreadsheet-column-header.component';
import { AgGridModule } from 'ag-grid-angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { DatasetViewImagePreview } from './image-preview/dataset-view-image-preview.component';
import { SpreadsheetCellEditorComponent } from './editable-dataset-spreadsheet/spreadsheet-cell-editor/spreadsheet-cell-editor.component';
import { DataikuAPIModule } from "../../core/dataiku-api/dataiku-api.module";

@NgModule({
    declarations: [
        DatasetViewExploreImagesComponent,
        DatasetViewImagePreview,
        ObjectDetectionDatasetViewExploreImagesComponent,
        ImageClassificationDatasetViewExploreImagesComponent,
        SimpleDatasetViewExploreImagesComponent,
        EditableDatasetSpreadsheetComponent,
        SpreadsheetColumnHeaderComponent,
        SpreadsheetCellEditorComponent
    ],
    exports: [],
    providers: [],
    imports: [
        ComponentsModule,
        AgGridModule,
        CommonModule,
        FormsModule,
        DataikuAPIModule
    ]
})
export class DatasetViewModule { }
