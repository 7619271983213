import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import { BehaviorSubject, combineLatest, concat, identity, Observable } from "rxjs";
import { debounceTime, distinctUntilKeyChanged, map, skip, take } from "rxjs/operators";
import { DataAugmentationService, DeepHubPreTrainModelingParams } from "src/generated-sources";
import { AugmentationTypeInfo } from "../deephub-design-data-augmentation.component";
import { DataAugmentationFetchingService } from "../data-augmentation.service";
import { isEqual } from "lodash";
import { observeInput } from "dku-frontend-core";

@UntilDestroy()
@Component({
    selector: 'deephub-design-data-augmentation-augmented-image',
    templateUrl: './deephub-design-data-augmentation-augmented-image.component.html',
    styleUrls: ['./deephub-design-data-augmentation-augmented-image.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeephubDesignDataAugmentationAugmentedImageComponent implements OnInit {

    @Input() augmentationTypeInfo: AugmentationTypeInfo;
    @Input() applyMaxTransform: boolean = false;
    @Input() legend: string = "";
    @Input() numAugmentedVersions: number;
    @Input() imageHeight: number;
    @Input() imagePath: string;
    imagePath$ = observeInput(this, 'imagePath');
    @Input() augmentationParams: DeepHubPreTrainModelingParams.ImageAugmentationParams;
    augmentationParams$ = observeInput(this, 'augmentationParams');
    @Input() managedFolderSmartId: string;

    loadingAugmentedImg$ = new BehaviorSubject<boolean>(true);
    failedToAugment$ = new BehaviorSubject<boolean>(false);
    disabledAugmentation$ = new BehaviorSubject<boolean>(false);
    augmentedImages$: BehaviorSubject<DataAugmentationService.AugmentedImage[]>;
    showAugmentedVersions$ : Observable<boolean>;

    constructor(private dataAugmentationFetchingService: DataAugmentationFetchingService) {}

    isDisabled(augmentationParams: DeepHubPreTrainModelingParams.ImageAugmentationParams) {
        if (!this.augmentationTypeInfo) {
            return false;
        }
        return this.augmentationTypeInfo.isDisabled(augmentationParams);
    }

    fetchAugmentedImagesData(imagePath: string, augmentationParams: DeepHubPreTrainModelingParams.ImageAugmentationParams) {

        if (this.isDisabled(augmentationParams)) {
            this.disabledAugmentation$.next(true);
            this.loadingAugmentedImg$.next(false);
        } else {
            this.disabledAugmentation$.next(false);
            this.loadingAugmentedImg$.next(true);

            this.dataAugmentationFetchingService.fetchAugmentedImagesData(imagePath, augmentationParams,
                this.numAugmentedVersions, this.applyMaxTransform, this.augmentationTypeInfo?.augmentationType).subscribe((augmentedImages) => {
                    this.loadingAugmentedImg$.next(false);
                    this.augmentedImages$.next(augmentedImages);
                    this.failedToAugment$.next(false);
                });
        }

    }

    ngOnInit(): void {
        this.augmentedImages$ = new BehaviorSubject<DataAugmentationService.AugmentedImage[]>(new Array(this.numAugmentedVersions));

        this.dataAugmentationFetchingService.getError().pipe(
            untilDestroyed(this)).subscribe((error) => {
                if (error !== undefined) {
                    this.failedToAugment$.next(true);
                    this.loadingAugmentedImg$.next(false);
                }
        });

        // Only forward changes in augmentation params if it concerns the augmentation type
        const augmentationParamsIfChanged$ = this.augmentationParams$.pipe(
            this.augmentationTypeInfo ? distinctUntilKeyChanged(this.augmentationTypeInfo.getKeyOfAugmentationParams(), isEqual): identity);

        // Debounce all changes in augmentation params except the first one, to prevent from calling
        // the backend each time a control changes.
        const debouncedAugmentationParams$ = concat(
            augmentationParamsIfChanged$.pipe(take(1)),
            augmentationParamsIfChanged$.pipe(skip(1), debounceTime(300))
        );

        combineLatest([
            debouncedAugmentationParams$,
            this.imagePath$]).pipe(untilDestroyed(this)).subscribe(([augmentationParams, imagePath]) => {
                this.fetchAugmentedImagesData(imagePath, augmentationParams);
        });

        this.showAugmentedVersions$ = combineLatest([
            this.loadingAugmentedImg$,
            this.failedToAugment$,
            this.disabledAugmentation$
        ]).pipe(
            map(([loadingAugmentedImg, failedToAugment, disabledAugmentation]) => {
                return !loadingAugmentedImg && !failedToAugment && !disabledAugmentation;
            }));
    }
}
