import type {AllFilter} from './all-filter';
import type {AndFilter} from './and-filter';
import type {AnumFilter} from './anum-filter';
import type {IntervalFilter} from './interval-filter';
import type {MissingValueFilter} from './missing-value-filter';
import type {NotFilter} from './not-filter';

/**
 * Generated from com.dataiku.dip.eda.compute.filtering.Filter
 */
export interface _Filter {
    name?: string | null;
}

export type Filter = AllFilter | AndFilter | AnumFilter | IntervalFilter | MissingValueFilter | NotFilter;

export namespace Filter {
    export const type = ['all', 'and', 'anum', 'interval', 'missing', 'not'] as const;
    export function isAllFilter(toTest: Filter): toTest is AllFilter { return toTest['type'] === 'all'; }
    export function isAndFilter(toTest: Filter): toTest is AndFilter { return toTest['type'] === 'and'; }
    export function isAnumFilter(toTest: Filter): toTest is AnumFilter { return toTest['type'] === 'anum'; }
    export function isIntervalFilter(toTest: Filter): toTest is IntervalFilter { return toTest['type'] === 'interval'; }
    export function isMissingValueFilter(toTest: Filter): toTest is MissingValueFilter { return toTest['type'] === 'missing'; }
    export function isNotFilter(toTest: Filter): toTest is NotFilter { return toTest['type'] === 'not'; }
}