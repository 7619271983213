<div class="noflex header last-header dataset-schema-editor-header">
    <span>
        <div class="std-list-search-box list-control-widget right-panel-schema__name-filtering"
            [ngClass]="{'right-panel-schema__name-filtering-padding': maxHeight === null}"
            >
            <span
                class="add-on"
            ><i class="dku-icon-search-16"></i></span>
            <input
                type="search"
                [(ngModel)]="filterName"
                (ngModelChange)="updateFilter()"
                placeholder="Search&hellip;"
                class="catalog-search-input ng-pristine ng-valid"
                [attr.data-qa-smid]="'features-filter'"
            />
        </div>
    </span>
</div>
<div class="right-panel-schema__table" [attr.data-qa-smid]="'features-table'">
    <table [ngClass]="{'right-panel-schema__padding': maxHeight === null}">
        <colgroup>
            <col class="name_column">
            <col class="type_column">
            <col class="meaning_column" *ngIf="showMeaning">
            <col class="desc_column">
        </colgroup>
        <thead>
            <tr class="dku-border-bottom">
                <th class="feature-name-column" textOverflowTooltip textTooltip="{{ wordingOption | capitalize }} name">{{ wordingOption | capitalize }} name</th>
                <th class="feature-type-column">Type</th>
                <th class="feature-meaning-column" *ngIf="showMeaning">Meaning</th>
                <th class="feature-description-column">Description</th>
            </tr>
        </thead>
    </table>
    <cdk-virtual-scroll-viewport [dynamicViewport]="filteredHighlightedColumns.length" [dynamicViewportMaxHeight]="maxHeight" [itemSize]="37" [ngClass]="{'right-panel-schema__viewport': maxHeight === null}">
        <table [ngClass]="{'right-panel-schema__padding': maxHeight === null}">
            <colgroup>
                <col class="name_column">
                <col class="type_column">
                <col class="meaning_column" *ngIf="showMeaning">
                <col class="desc_column">
            </colgroup>
            <tbody>
                <ng-container *cdkVirtualFor="let column of filteredHighlightedColumns; let last=last; templateCacheSize: 0"  >
                    <tr [ngClass]="{'dku-border-bottom': !last, 'selected':selected === removeHighlighting(column.name)}">
                        <td>
                            <div class="ellipsis right-panel-schema__column-name highlight"
                                textOverflowTooltip
                                [textTooltip]="column.name | escapeHighlightedHtml"
                                [allowHtml]="true"
                                [attr.data-qa-smid]="'feature-name'"
                            ></div>
                        </td>
                        <td>
                            <span class="mx-textellipsis"
                                textOverflowTooltip [textTooltip]="column.type | lowercase | capitalize"
                                tooltipDirection="tooltip-left"
                            ></span>
                        </td>
                        <td *ngIf="showMeaning">
                            <span class="mx-textellipsis">{{column.meaning | meaningLabel }}</span>
                        </td>
                        <td>
                            <span class="mx-textellipsis"
                                textOverflowTooltip
                                [textTooltip]="column.comment ? column.comment : ''"
                                tooltipDirection="tooltip-left"
                            ></span>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </cdk-virtual-scroll-viewport>
</div>
