<div class="line users">
    <h4>Users</h4>
    <div>
        <a *ngFor="let user of details.datasetFullInfo.timeline.allContributors" href="/profile/{{user.login}}/"
        [matTooltip]="user.displayName || user.login" class="avatar">
            <div>
                <user-picture [user]="user.login" [size]="24"></user-picture>
            </div>
        </a>
    </div>
</div>
<div class="line" *ngIf="details.datasetFullInfo.dataset.creationTag 
                        && details.datasetFullInfo.dataset.creationTag.lastModifiedOn 
                        && details.datasetFullInfo.dataset.creationTag.lastModifiedBy.login">
    <h4>Created</h4>
    <p class="feature-store__created-modified-info">
        <span [matTooltip]="details.datasetFullInfo.dataset.creationTag.lastModifiedOn | friendlyDateTime">
                {{details.datasetFullInfo.dataset.creationTag.lastModifiedOn | friendlyTimeDeltaShort}} by
        </span>
        <span>
            <a href="/profile/{{details.datasetFullInfo.dataset.creationTag.lastModifiedBy.login}}/"
                class="avatar"
                [matTooltip]="users[details.datasetFullInfo.dataset.creationTag.lastModifiedBy.login] || details.datasetFullInfo.dataset.creationTag.lastModifiedBy.login">
                    <user-picture [user]="details.datasetFullInfo.dataset.creationTag.lastModifiedBy.login" [size]="24"></user-picture>
            </a>
        </span>
    </p>
</div>
<div class="line" *ngIf="details.datasetFullInfo.dataset.versionTag 
                        && details.datasetFullInfo.dataset.versionTag.lastModifiedOn 
                        && details.datasetFullInfo.dataset.versionTag.lastModifiedBy.login">
    <h4>Last modified</h4>
    <p class="feature-store__created-modified-info">
        <span [matTooltip]="details.datasetFullInfo.dataset.versionTag.lastModifiedOn | friendlyDateTime">
                {{details.datasetFullInfo.dataset.versionTag.lastModifiedOn | friendlyTimeDeltaShort}} by
        </span>
        <span>
            <a href="/profile/{{details.datasetFullInfo.dataset.versionTag.lastModifiedBy.login}}/"
                class="avatar"
                [matTooltip]="users[details.datasetFullInfo.dataset.versionTag.lastModifiedBy.login || ''] || details.datasetFullInfo.dataset.versionTag.lastModifiedBy.login">
                    <user-picture [user]="details.datasetFullInfo.dataset.versionTag.lastModifiedBy.login" [size]="24"></user-picture>
            </a>
        </span>
    </p>
</div>
<div class="line">
    <h4>Last build</h4>
    <p [innerHtml]="lastBuildInfo()"></p>
</div>
<div class="line" *ngIf="details.datasetFullInfo.dataset.params.connection">
    <h4>Connection</h4>
    <p>{{details.datasetFullInfo.dataset.params.connection}}</p>
</div>