import {
    Directive,
    Host
} from '@angular/core';
import { InViewportDirective } from 'ng-in-viewport';

/**
 * To be used with CdkVirtualScrollViewport
 *
 * When virtual scroll viewport is loaded but not visible,
 * (eg, when using tabs), when it becomes visible again,
 * sometimes the viewport does not have the correct size.
 * 
 * This directive forces a resize of the viewport once visible
 * again.
 */
@Directive({
	selector: '[inViewport][forceResize]',
})
export class ForceResizeDirective {
	constructor(
		@Host() private inViewportDirective: InViewportDirective,
	) {
        this.inViewportDirective.inViewportAction.subscribe((intersection: any) => {
            this.onIntersection(intersection);
        });
    }

	onIntersection(intersection: any) {
        if (intersection.visible) {
            window.dispatchEvent(new Event('resize'));
        }
    }
}
