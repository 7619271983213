import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild, TemplateRef, ViewContainerRef, ElementRef, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, debounceTime, pairwise, Subscription } from 'rxjs';
import { ColorUtilsService } from '../../services';

@UntilDestroy()
@Component({
    selector: 'chart-color-picker',
    templateUrl: './chart-color-picker.component.html',
    styleUrls: ['./chart-color-picker.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartColorPickerComponent implements OnDestroy {
    private backdropClickSubscription: Subscription | null = null;

    private overlayRef: OverlayRef | null;

    private color$ = new BehaviorSubject<string | null>(null);

    icon$ = new BehaviorSubject<string | null | undefined>(null);
    itemsPerRow$ = new BehaviorSubject<number | null>(null);
    displayAlpha$ = new BehaviorSubject<boolean>(false);

    displayedColor$ = new BehaviorSubject<string | null>(null);
    colors$ = new BehaviorSubject<Array<string>>([]);
    paletteStyle$ = new BehaviorSubject<string>('');

    isWhite$ = new BehaviorSubject<boolean>(false);

    @Input()
    set color(value: string | null) {
        this.color$.next(value);
    }

    @Input()
    set colors(value: Array<string> | null) {
        this.colors$.next(value || []);
    }

    @Input()
    set icon(value: string | undefined) {
        this.icon$.next(value);
    }

    @Input()
    set itemsPerRow(value: number | null) {
        this.itemsPerRow$.next(value);
    }

    @Input()
    set displayAlpha(value: boolean) {
        this.displayAlpha$.next(!!value);
    }

    @Output() colorChange = new EventEmitter<string | null>();

    @ViewChild('colorPalette') colorPalette: TemplateRef<any>;
    @ViewChild('colorPickerBtn') colorPickerBtn: ElementRef<HTMLElement>;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private overlay: Overlay,
        private colorUtilsService: ColorUtilsService
    ) {
        this.color$
            .pipe(debounceTime(10), untilDestroyed(this))
            .subscribe(color => {
                const isWhite = this.isWhite(color);
                this.isWhite$.next(isWhite);
                this.displayedColor$.next(color);
            });

        this.color$
            .pipe(pairwise(), untilDestroyed(this))
            .subscribe(([prevColor, nextColor]) => {
                if (prevColor !== nextColor) {
                    this.colorChange.emit(nextColor);
                }
            });

        combineLatest([this.colors$, this.itemsPerRow$])
            .pipe(untilDestroyed(this))
            .subscribe(([colors, itemsPerRow]) => {
                const paletteStyle = this.getPaletteStyle(colors, itemsPerRow);
                this.paletteStyle$.next(paletteStyle);
            });
    }

    ngOnDestroy() {
        if (this.backdropClickSubscription) {
            this.backdropClickSubscription.unsubscribe();
        }

        if (this.overlayRef) {
            this.close();
        }
    }

    private isWhite(color?: string | null) {
        const upperCaseColor = color && color.toUpperCase();
        return !!upperCaseColor && (this.colorUtilsService.strToRGBA(color).a === 0 || upperCaseColor.startsWith('#FFFFFF') || upperCaseColor === '#FFF');
    }

    private getPaletteStyle(colors: Array<string>, itemsPerRow?: number | null) {
        // Maintain menu grid aspect ratio
        if (!itemsPerRow) {
            itemsPerRow = Math.ceil(Math.sqrt(colors.length));
        }

        return `grid-template-columns: repeat(${itemsPerRow}, auto);`;
    }

    private close() {
        this.overlayRef?.dispose();
        this.overlayRef = null;
    }

    togglePalette() {
        if (this.backdropClickSubscription) {
            this.backdropClickSubscription.unsubscribe();
            this.backdropClickSubscription = null;
        }

        if (this.overlayRef) {
            this.close();
        } else if (this.colorPalette) {

            this.overlayRef = this.overlay.create({
                hasBackdrop: true,
                backdropClass: 'color-picker-backdrop', // Replaces default which is a grey background (we don't need to define it though)
                disposeOnNavigation: true
            });
            const colorPalettePortal = new TemplatePortal(this.colorPalette, this.viewContainerRef);
            this.overlayRef.attach(colorPalettePortal);

            const position = this.getPalettePosition();

            if (position) {
                const positionStrategy = this.overlay
                    .position()
                    .global()
                    .top(`${position.top}px`)
                    .left(`${position.left}px`)
                this.overlayRef.updatePositionStrategy(positionStrategy);
            }

            this.backdropClickSubscription = this.overlayRef.backdropClick()
                .subscribe(($event) => {
                    $event.stopPropagation();
                    this.close();
                });
        }
    }

    getPalettePosition(): {top: number, left: number} | undefined {
        if (this.overlayRef) {
            const button = this.colorPickerBtn.nativeElement.getBoundingClientRect();
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            const offsetTop = button.top + window.pageYOffset;
            const height = button.height;
            const dropdownHeight = this.overlayRef.overlayElement.getElementsByClassName('color-picker-palette')[0].getBoundingClientRect().height;
            if (offsetTop + height + dropdownHeight > scrollTop + document.documentElement.clientHeight) {
                return { top: button.top - dropdownHeight, left: button.left };
            } else {
                return { top: button.top + button.height, left: button.left };
            }
        }
        return;
    }

    setColor($event: MouseEvent, color: string) {
        $event.stopPropagation();

        if (!this.displayAlpha$.getValue()) {
            this.close();
        }

        this.color$.next(color);
    }

    onAlphaChange(color: string) {
        this.color$.next(color);
    }
}
