import type {BasePermissions} from './../security/base-permissions';
import type {SerializedProject} from './../coremodel/serialized-project';

export namespace UIDataCollection {
    /**
     * Generated from com.dataiku.dip.datacollections.UIDataCollection$AddObjectsResponse
     */
    export interface AddObjectsResponse {
        allDataStewardsDefined: boolean;
        modified: boolean;
    }

    /**
     * Generated from com.dataiku.dip.datacollections.UIDataCollection$AddObjectsOptions
     */
    export interface AddObjectsOptions {
        requestQuickSharing: boolean;
        requestedReaderAuthorizations: SerializedProject.ReaderAuthorization.Mode[];
    }

    /**
     * Generated from com.dataiku.dip.datacollections.UIDataCollection$DatasetInfo
     */
    export interface DatasetInfo extends UIDataCollection._AbstractDatasetInfo {
        columnCount: number;
        lastBuiltOn: number;
        usedInProjectCount: number;

        // PolyJson type
        type: 'dataset';
    }

    export namespace DatasetInfo {
        export const type = 'dataset';
    }

    /** @deprecated Use UIDataCollection.AbstractDataCollectionItemInfo.isDatasetInfo() */ export function isDatasetInfo(toTest: UIDataCollection.AbstractDataCollectionItemInfo): toTest is DatasetInfo { return DatasetInfo.type === toTest['type']; }

    /**
     * Generated from com.dataiku.dip.datacollections.UIDataCollection$ListItemWithDetails
     */
    export interface ListItemWithDetails {
        color: string;
        description: string;
        displayName: string;
        id: string;
        itemCount: number;
        lastModifiedOn: number;
        tags: string[];
    }

    /**
     * Generated from com.dataiku.dip.datacollections.UIDataCollection$DataCollectionInfo
     */
    export interface DataCollectionInfo {
        color: string;
        currentUserPermissions: BasePermissions.Permissions;
        description: string;
        displayName: string;
        id: string;
        items: UIDataCollection.AbstractDataCollectionItemInfo[];
        permissions: BasePermissions.PermissionItem[];
        tags: string[];
    }

    /**
     * Generated from com.dataiku.dip.datacollections.UIDataCollection$CreationInfo
     */
    export interface CreationInfo {
        color: string;
        description: string;
        displayName: string;
        permissions: BasePermissions.PermissionItem[];
    }

    /**
     * Generated from com.dataiku.dip.datacollections.UIDataCollection$AbstractDatasetInfo
     */
    export interface _AbstractDatasetInfo extends UIDataCollection._AbstractDataCollectionItemInfo {
        datasetType: string;
        description: string;
        name: string;
        sourceProjectKey: string;
        sourceProjectName: string;
    }

    export type AbstractDatasetInfo = UIDataCollection.DatasetInfo | UIDataCollection.DiscoverableDatasetInfo;

    export namespace AbstractDatasetInfo {
        export const type = ['dataset', 'discoverable-dataset'] as const;
    }

    /** @deprecated Use UIDataCollection.AbstractDataCollectionItemInfo.isAbstractDatasetInfo() */ export function isAbstractDatasetInfo(toTest: UIDataCollection.AbstractDataCollectionItemInfo): toTest is AbstractDatasetInfo { return (AbstractDatasetInfo.type as readonly unknown[]).includes(toTest['type']); }

    /**
     * Generated from com.dataiku.dip.datacollections.UIDataCollection$DiscoverableDatasetInfo
     */
    export interface DiscoverableDatasetInfo extends UIDataCollection._AbstractDatasetInfo {
        // PolyJson type
        type: 'discoverable-dataset';
    }

    export namespace DiscoverableDatasetInfo {
        export const type = 'discoverable-dataset';
    }

    /** @deprecated Use UIDataCollection.AbstractDataCollectionItemInfo.isDiscoverableDatasetInfo() */ export function isDiscoverableDatasetInfo(toTest: UIDataCollection.AbstractDataCollectionItemInfo): toTest is DiscoverableDatasetInfo { return DiscoverableDatasetInfo.type === toTest['type']; }

    /**
     * Generated from com.dataiku.dip.datacollections.UIDataCollection$AbstractDataCollectionItemInfo
     */
    export interface _AbstractDataCollectionItemInfo {}

    export type AbstractDataCollectionItemInfo = UIDataCollection.DatasetInfo | UIDataCollection.DiscoverableDatasetInfo;

    export namespace AbstractDataCollectionItemInfo {
        export const type = ['dataset', 'discoverable-dataset'] as const;
        export function isAbstractDatasetInfo(toTest: UIDataCollection.AbstractDataCollectionItemInfo): toTest is UIDataCollection.AbstractDatasetInfo { return ['dataset', 'discoverable-dataset'].includes(toTest['type']); }
        export function isDatasetInfo(toTest: UIDataCollection.AbstractDataCollectionItemInfo): toTest is UIDataCollection.DatasetInfo { return toTest['type'] === 'dataset'; }
        export function isDiscoverableDatasetInfo(toTest: UIDataCollection.AbstractDataCollectionItemInfo): toTest is UIDataCollection.DiscoverableDatasetInfo { return toTest['type'] === 'discoverable-dataset'; }
    }

    /**
     * Generated from com.dataiku.dip.datacollections.UIDataCollection$MetadataInfo
     */
    export interface MetadataInfo {
        color: string;
        description: string;
        displayName: string;
        tags: string[];
    }

    /**
     * Generated from com.dataiku.dip.datacollections.UIDataCollection$ListItem
     */
    export interface ListItem {
        id: string;
        metadata: UIDataCollection.MetadataInfo;
    }
}