import { Pipe, PipeTransform } from '@angular/core';
import { Distribution } from 'src/generated-sources';
import { CapitalizePipe } from '@shared/pipes/capitalize.pipe';

const capitalizePipe = new CapitalizePipe();

@Pipe({
    name: 'distributionName',
    pure: true
})
export class DistributionNamePipe implements PipeTransform {
    transform(value: Distribution): string {
        switch (value.type) {
            case 'beta':
            case 'binomial':
            case 'pareto':
            case 'poisson':
            case 'triangular':
            case 'weibull':
            case 'exponential':
            case 'laplace':
            case 'normal':
                return capitalizePipe.transform(value.type);
            case 'lognormal':
                return 'Log-normal';
            case 'normal_mixture':
                return `Normal mixture`;
        }
    }
}
