import { Injectable } from "@angular/core";
import { ImagePositionInformation, MaskPainterImageArtifact, PainterImage } from "@shared/models/painter";
import { ColorMapContextService } from "@shared/services/color-map-context.service";
import { PainterService } from "@shared/services/item-feed/image-feed/painter.service";
import { DeephubImageClassificationInteractiveScoringCellData } from "../interactive-scoring/image-classification-interactive-scoring-data.service";

@Injectable({
    providedIn: 'root'
})
export class DeephubImageClassificationImageSectionPainterService extends PainterService {

    constructor(
        private colorMapService: ColorMapContextService
    ) {
        super();
    }

    paintForFeed(cellData: DeephubImageClassificationInteractiveScoringCellData, image: PainterImage, imagePosition: ImagePositionInformation): void {
        
        cellData.base64Heatmaps.forEach(function(heatmapUrl, category){
                const mask = new MaskPainterImageArtifact({
                    visibility : 'hidden',
                    left: imagePosition.left + 'px',
                    top: imagePosition.top + 'px'
                }, category);
                mask.maskUrl = "data:image/jpg;base64," + heatmapUrl;
                image.addArtifact(mask);
            }
        )
        if (cellData.pinnedCategory !== undefined){
            const pinnedArtifact = image.getArtifactByName(cellData.pinnedCategory);
            if (pinnedArtifact){ 
                pinnedArtifact.style.visibility = 'visible';
            }
        }
    }

    toggleMasksVisibility(image: PainterImage, currentMaskName: string, selected: boolean, pinnedMaskName: string | undefined) {
        // hidding any previously pinned or selected mask
        image.artifacts.forEach(artifact => artifact.style.visibility = 'hidden');

        const currentMask = image.getArtifactByName(currentMaskName);
        if (currentMask) {
            if (selected) {
                currentMask.style.visibility = 'visible';
            }
            else {
                if (pinnedMaskName !== undefined) {
                    // default to pinned mask

                    const pinnedMask = image.getArtifactByName(pinnedMaskName);
                    if (pinnedMask){ 
                        pinnedMask.style.visibility = 'visible';
                    }
                }
            }
        }
    }
}
