import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'connectionNameFormatter'
})
export class ConnectionNameFormatterPipe implements PipeTransform {
    static virtualConnectionRegex = /^@virtual\((.+)\):(connection:)?(.+)$/;

    transform(input: string): string {
        if (!input) return input;
        const match = input.match(ConnectionNameFormatterPipe.virtualConnectionRegex);
        return match ? `Hive (${match[3]})` : input;
    }

}
