import type {CardResult, _CardResult} from './../card-result';
import type {Card} from './../card';
import type {Quantiles} from './../../../compute/computations/univariate/quantiles';
import type {_UnivariateCard} from './../univariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.univariate_header.QuantilesTableCard
 */
export interface QuantilesTableCard extends _UnivariateCard {
    confidenceLevel: number;
    customFreqs?: number[] | null;
    showConfidenceInterval: boolean;

    // PolyJson type
    type: 'quantile_table';
}

export namespace QuantilesTableCard {
    export const type = 'quantile_table';
}

/** @deprecated Use Card.isQuantilesTableCard() */ export function isQuantilesTableCard(toTest: Card): toTest is QuantilesTableCard { return QuantilesTableCard.type === toTest['type']; }

export namespace QuantilesTableCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.univariate_header.QuantilesTableCard$QuantilesTableCardResult
     */
    export interface QuantilesTableCardResult extends _CardResult {
        quantiles: Quantiles.QuantileDesc[];

        // PolyJson type
        type: 'quantile_table';
    }

    export namespace QuantilesTableCardResult {
        export const type = 'quantile_table';
    }

    /** @deprecated Use CardResult.isQuantilesTableCardResult() */ export function isQuantilesTableCardResult(toTest: CardResult): toTest is QuantilesTableCardResult { return QuantilesTableCardResult.type === toTest['type']; }
}