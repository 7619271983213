import { Injectable } from '@angular/core';
import { Chunk, IndexedChunkItem } from '@shared/components/infinite-scroll/infinite-scroll.component';
import { Observable, ReplaySubject } from 'rxjs';


export interface CellData {
    itemId: string,
    itemIndex: number
}


@Injectable()
export abstract class ItemsDataFetcherService {
    CHUNK_SIZE = 64;

    projectKey: string;

    // refetch the chunk
    private refetchDataTrigger$ = new ReplaySubject<void>(1);
    dataRefetch$: Observable<void> = this.refetchDataTrigger$.asObservable();

    // update an individual item in the chunk
    private updateItemTrigger$ = new ReplaySubject<IndexedChunkItem>(1);
    itemUpdate$: Observable<IndexedChunkItem> = this.updateItemTrigger$.asObservable();

    abstract getChunk(offset: number): Observable<Chunk>;

    refetchData(): void {
        this.refetchDataTrigger$.next();
    }

    updateItem(index: number, item: any): void {
        this.updateItemTrigger$.next({
            index,
            item
        });
    }
}
