import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { APIError, ErrorContext, catchAPIError } from "@core/dataiku-api/api-error";
import { DataikuAPIService } from "@core/dataiku-api/dataiku-api.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { resolveSmartName } from '@utils/loc';
import { deepDistinctUntilChanged, observeInput } from "dku-frontend-core";
import { PCARecipePayloadParams, SchemaColumn, SerializedRecipe } from "generated-sources";
import { Observable, distinctUntilChanged, map, switchMap } from "rxjs";

/**
 * Typing for the config form values.
 */
type ConfigFormValue = {
    columns: string[],
    forwardedColumns: string[],
    addComputationTimestamp: boolean
};

@UntilDestroy()
@Component({
    selector: 'pca-recipe-settings',
    templateUrl: './pca-recipe-settings.component.html',
    styleUrls: ['./pca-recipe-settings.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PcaRecipeSettingsComponent implements OnInit, ErrorContext {

    /**
     * The recipe project key.
     */
    @Input() projectKey: string;

    /**
     * The recipe input.
     */
    @Input() recipeInput: SerializedRecipe.RecipeInput;

    /**
     * The recipe projections output.
     */
    @Input() projectionsOutput: SerializedRecipe.RecipeOutput | undefined;

    /**
     * The recipe payload parameters.
     */
    @Input() payload: PCARecipePayloadParams;

    /**
     * Event emitter for forwarding the payload changes.
     */
    @Output() payloadChange = new EventEmitter<PCARecipePayloadParams>();

    /**
     * Event emitter for forwarding any api error.
     */
    @Output() onApiError = new EventEmitter<APIError>();

    private recipeInput$: Observable<SerializedRecipe.RecipeInput> = observeInput(this, 'recipeInput');
    private payload$: Observable<PCARecipePayloadParams> = observeInput(this, 'payload');
    projectionsOutput$: Observable<SerializedRecipe.RecipeOutput | undefined> = observeInput(this, 'projectionsOutput');

    configForm: FormGroup;
    columns$: Observable<SchemaColumn[]>;

    constructor(
        fb: FormBuilder,
        private DataikuAPI: DataikuAPIService,
    ) {
        this.configForm = fb.group({
            columns: fb.control([], [
                Validators.required,
            ]),
            forwardedColumns: fb.control([], [
                Validators.required,
            ]),
            addComputationTimestamp: fb.control(false, [
                Validators.required
            ])
        });
    }

    get selectedColumns(): string[] {
        return this.configForm.controls.columns.value;
    }

    get forwardedColumns(): string[] {
        return this.configForm.controls.forwardedColumns.value;
    }

    ngOnInit(): void {
        this.columns$ = this.recipeInput$
            .pipe(
                map(input => input.ref),
                distinctUntilChanged(),
                switchMap(datasetRef => {
                    const loc = resolveSmartName(this.projectKey, datasetRef);
                    return this.DataikuAPI.datasets.get(loc.projectKey, loc.id, this.projectKey);
                }),
                catchAPIError(this),
                map(dataset => {
                    return dataset.schema.columns;
                }),
                untilDestroyed(this),
            );

        this.configForm.valueChanges
            .pipe(
                deepDistinctUntilChanged(),
                untilDestroyed(this)
            )
            .subscribe((formValue: ConfigFormValue) => {
                this.emitUpdatedPayload(formValue);
            });

        this.payload$
            .pipe(
                deepDistinctUntilChanged(),
                untilDestroyed(this),
            )
            .subscribe(payload => {
                this.patchConfigForm(payload);
            });
    }

    private emitUpdatedPayload(formValue: ConfigFormValue): void {
        const newPayload: PCARecipePayloadParams = {
            ...this.payload,
            columns: formValue.columns,
            forwardedColumns: formValue.forwardedColumns,
            addComputationTimestamp: formValue.addComputationTimestamp
        };

        this.payloadChange.emit(newPayload);
    }

    private patchConfigForm(payload: PCARecipePayloadParams): void {
        this.configForm.patchValue({
            columns: payload.columns,
            forwardedColumns: payload.forwardedColumns,
            addComputationTimestamp: payload.addComputationTimestamp
        });
    }

    pushError(error: APIError): void {
        this.onApiError.emit(error);
    }
}
