<feed-image-modal-section [sectionTitle]="'Classes'">
    <ng-container *ngIf="!cellData.invalid">
        <ul class="raw-unstyled-ul" *ngIf="categories && categories.length > 0">
            <li *ngFor="let item of categoryColorMap | keyvalue" class="image-details__category">
                <div class="image-legend noflex">
                    <span class="image-legend__item" [style.background-color]="item.value"></span>
                </div>
                <span class="mleft8" [title]="item.key">
                    {{ item.key }}
                </span>
            </li>
        </ul>
        <span *ngIf="!categories || categories.length == 0">No class for this image</span>
    </ng-container>
    <ng-container *ngIf="cellData.invalid">
        <div class="alert alert-error mbot8">
            <i class="icon-dku-error mright4"></i> {{cellData.invalidReason}}
        </div>
    </ng-container>
</feed-image-modal-section>