import { Input, Component } from '@angular/core';
import { SmartLogTail } from 'dku-frontend-core';

@Component({
    selector: 'smart-log-tail',
    templateUrl: './smart-log-tail.component.html',
    styleUrls: ['./smart-log-tail.component.less']
})
export class SmartLogTailComponent {
    @Input() smartLogTail: SmartLogTail;

}
