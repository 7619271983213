import { Injectable } from "@angular/core";
import { AbstractShortcutService, ShortcutMapBuilder, ShortcutMapBuilderInterface, ShortcutAction } from "@shared/services/shortcut.service";

export type LabelingShortcutAction = ShortcutAction | "DELETE" | "SKIP" | "FIRST" | "LAST" | "MULTI_SELECTION" | "UNDO" | "REDO";

class LabelingShortcutMapBuilder extends ShortcutMapBuilder implements ShortcutMapBuilderInterface<LabelingShortcutAction> {
    getShortcutMap(event: KeyboardEvent) {
        return {
            ...super.getShortcutMap(event),
            MULTI_SELECTION: event.key === "Shift",
            DELETE: event.key === "Delete" || event.key === "Backspace",
            SKIP: event.key === 'x',
            FIRST: event.code === 'ArrowUp' || event.key === 'w' || (!(event.metaKey || event.ctrlKey) && event.key === 'z'),
            LAST: event.code === 'ArrowDown' || event.key === 's',
            UNDO: (event.metaKey || event.ctrlKey) && !event.shiftKey && event.key === 'z',
            REDO: (event.metaKey || event.ctrlKey) && ((event.shiftKey && event.key === 'z') || event.key === 'Z')
        };
    }
}


@Injectable()
export class LabelingShortcutService extends AbstractShortcutService<LabelingShortcutAction> {
    getShortcutMapBuilder(): ShortcutMapBuilderInterface<LabelingShortcutAction> {
        return new LabelingShortcutMapBuilder();
    }
}
