import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { MosaicPlotCard } from 'src/generated-sources';
import type { CustomSeriesOption, EChartsOption, SeriesOption } from 'echarts';
import { ColorsService } from '@shared/graphics/colors.service';
import { CardActionType, CardAction } from '@features/eda/worksheet/cards/events';
import { filterName } from '@features/eda/pipes/filter-name.pipe';
import { encodeHTML } from 'entities';
import { rgb } from 'd3-color';
import { PatternsService } from '@shared/graphics/patterns.service';

// Only draw a label if it is large enough
const MIN_DISPLAY_PERCENT_X = 0.15;
const MIN_DISPLAY_PERCENT_Y = 0.05;

@Component({
    selector: 'mosaic-plot-card-body',
    templateUrl: './mosaic-plot-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/chart.less',
        './mosaic-plot-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MosaicPlotCardBodyComponent implements OnChanges {
    @Input() results: MosaicPlotCard.MosaicPlotCardResult;
    @Input() params: MosaicPlotCard;
    @Input() hasFixedHeight: boolean;
    @Output() action = new EventEmitter<CardAction>();

    chartOptions: EChartsOption;

    constructor(
        private colorsService: ColorsService,
        private patternsService: PatternsService
    ) { }

    updateNBins(value: number) {
        const newParams = {
            ...this.params,
            maxValues: value
        };

        this.action.emit({ type: CardActionType.UPDATE, newParams });
    }

    chartClicked(event: any) {
        if (event.componentType === 'series'
            && event.componentSubType === 'custom') {
            let flatIndex: number = event.dataIndex;
            if (this.results.mosaic.highlightedYPercentages) {
                // Because we have 2 values per tile (highlighted & all)
                flatIndex = Math.floor(flatIndex / 2);
            }
            const yCount = this.results.mosaic.yVals.length;
            const yIndex = flatIndex % yCount;
            const xIndex = Math.floor(flatIndex / yCount);
            let xFilter = this.results.mosaic.xVals[xIndex];
            xFilter = { ...xFilter, name: this.params.xColumn.name + ': ' + filterName(xFilter) };
            let yFilter = this.results.mosaic.yVals[yIndex];
            yFilter = { ...yFilter, name: this.params.yColumn.name + ': ' + filterName(yFilter) };

            this.action.emit({
                type: CardActionType.HIGHLIGHT,
                filter: { type: 'and', filters: [xFilter, yFilter] }
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results) {
            this.chartOptions = this.results && this.buildChartOptions(this.results);
        }
    }

    buildChartOptions(results: MosaicPlotCard.MosaicPlotCardResult): EChartsOption {
        const data: any = [];
        const xTickData: any[] = [];
        const yTickData: any[] = [];
        const mosaic = results.mosaic;
        const xPercentages = mosaic.xPercentages;
        const yPercentages = mosaic.yPercentages;
        const xVals = mosaic.xVals;
        const xLabels = xVals.map(filter => filterName(filter));
        const yVals = mosaic.yVals;
        const yLabels = yVals.map(filter => filterName(filter));
        const xCount = xPercentages.length;
        const yCount = yPercentages.length / xPercentages.length;
        let xOffset = 0;
        let yOffset = 0;

        // data contains an array of array of counts.
        // the first element in data is an array containing
        // all the first yBin count of each xBin
        for (let i = 0; i < xCount; i++) {
            const x = xPercentages[i];
            for (let j = 0; j < yCount; j++) {
                const flatIndex = yCount * i + j;
                const y = yPercentages[flatIndex];
                const count = mosaic.counts[flatIndex];

                // use a mark line to show axis label in right position
                if (i === 0 && y > MIN_DISPLAY_PERCENT_Y) {
                    yTickData.push({
                        yAxis: (yOffset * 2 + y) / 2,
                        lineStyle: {
                            opacity: 0
                        },
                        label: {
                            show: true,
                            position: 'start',
                            formatter: () => yLabels[j],
                            color: '#333',
                        }
                    });
                }

                yOffset += y;

                const itemColor = this.colorsService.getColorForVariable(yLabels[j]);
                const borderColor = rgb(itemColor).darker(2).toString();

                if (mosaic.highlightedYPercentages) {
                    const stripedPattern = this.patternsService.getStripePattern(borderColor, itemColor);
                    const highlightedY = mosaic.highlightedYPercentages[flatIndex];

                    data.push({
                        value: [xOffset, x, yOffset, y - highlightedY, i, j, count],
                        itemStyle: {
                            color: itemColor,
                            borderWidth: 1,
                            borderColor
                        },
                        emphasis: { itemStyle: { color: itemColor } }
                    });

                    data.push({
                        value: [xOffset, x, yOffset - y + highlightedY, highlightedY, i, j, count],
                        itemStyle: {
                            color: stripedPattern as any, // Bad typings
                            borderWidth: 1,
                            borderColor
                        }
                    });
                } else {
                    data.push({
                        value: [xOffset, x, yOffset, y, i, j, count],
                        itemStyle: {
                            color: itemColor,
                            borderWidth: 1,
                            borderColor
                        },
                        emphasis: { itemStyle: { color: itemColor } }
                    });
                }
            }
            // use a mark line to show axis label in right position
            if (x > MIN_DISPLAY_PERCENT_X) {
                xTickData.push({
                    xAxis: (xOffset * 2 + x) / 2,
                    lineStyle: {
                        opacity: 0
                    },
                    label: {
                        show: true,
                        position: 'start',
                        formatter: () => xLabels[i],
                        color: '#333'
                    }
                });
            }
            xOffset += x;
            yOffset = 0;
        }

        return {
            animation: false,
            tooltip: {
                confine: true,
                trigger: 'item',
                axisPointer: { type: 'none' },
                formatter: (params: any) => {
                    const xLabel = xLabels[params.value[4]];
                    const yLabel = yLabels[params.value[5]];
                    const count = params.value[6];
                    const percent = Math.round(1000 * count / mosaic.totalCount) / 10;

                    return `
                        ${encodeHTML(this.params.xColumn.name)}:
                        <b>${encodeHTML(xLabel)}</b>
                        <br>
                        ${encodeHTML(this.params.yColumn.name)}:
                        <b>${encodeHTML(yLabel)}</b>
                        <br>
                        Count: <b>${encodeHTML('' + count)}</b> (${encodeHTML('' + percent)}%)
                    `;
                }
            },
            grid: {
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                containLabel: true,
            },
            xAxis: [{
                show: false,
                min: 0,
                max: 1,
                position: 'top'
            }, { // to get automatic padding on bottom
                data: xLabels,
                boundaryGap: false,
                position: 'bottom',
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false,
                },
                axisLabel: {
                    color: 'rgba(0,0,0,0)'
                }
            }],
            yAxis: [{
                show: false,
                min: 0,
                max: 1,
                position: 'right'
            }, { // to get automatic padding on left
                data: yLabels,
                boundaryGap: false,
                position: 'left',
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false,
                },
                axisLabel: {
                    color: 'rgba(0,0,0,0)'
                }
            }],
            series: [{
                type: 'custom',
                renderItem: (params: any, api: any) => {
                    const xStart = api.value(0);
                    const yStart = api.value(2);
                    const xSize = api.value(1);
                    const ySize = api.value(3);
                    const start = api.coord([xStart, yStart]);
                    const size = api.size([xSize, ySize]);

                    return {
                        type: 'rect',
                        shape: {
                            x: start[0],
                            y: start[1],
                            width: size[0],
                            height: size[1]
                        },
                        style: api.style(),
                        styleEmphasis: api.styleEmphasis()
                    };
                },
                encode: {
                    x: [0, 1],
                    y: [2, 3]
                },
                markLine: {
                    silent: true,
                    data: [...xTickData, ...yTickData]
                },
                data
            } as SeriesOption],
        };
    }
}
