<form [formGroup]="configForm" id="config-form" class="dkuform-modal-horizontal">
    <div class="control-group">
        <label class="control-label">
            Variable
        </label>
        <variable-selector
            [variables]="allVariables$ | async"
            formControlName="column"
        ></variable-selector>
    </div>
    <div class="control-group">
        <label class="control-label">Hypothesized mean</label>
        <input type="number" formControlName="hypothesizedMean">
    </div>    
</form>
