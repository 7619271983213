import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, ChangeDetectorRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { PlaygroundCard } from 'src/generated-sources';
import { CardAction, CardActionType } from '../../events';

@UntilDestroy()
@Component({
    selector: 'playground-card-body',
    templateUrl: './playground-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/card-layout.less',
        './playground-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaygroundCardBodyComponent implements OnChanges {
    @Input() results: PlaygroundCard.PlaygroundCardResult;
    @Input() params: PlaygroundCard;
    @Input() readOnly: boolean;
    @Output() action = new EventEmitter<CardAction>();

    rawComputation = '';
    params$ = new ReplaySubject<PlaygroundCard>(1);

    constructor(changeDetectorRef: ChangeDetectorRef) {
        this.params$.pipe(
            map(params => params.rawComputation),
            distinctUntilChanged(),
            untilDestroyed(this)
        ).subscribe(rawComputation => {
            this.rawComputation = rawComputation;
            changeDetectorRef.markForCheck();
        });
    }

    run() {
        this.action.emit({
            type: CardActionType.UPDATE,
            newParams: { ... this.params, rawComputation: this.rawComputation }
        });
    }

    ngOnChanges() {
        this.params$.next(this.params);
    }
}
