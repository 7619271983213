import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

/**
 * WIP component to provide a collapsible right panel (like in the flow)
 *
 * Usage:
 *
 <collapsible-right-panel-layout>

  <ng-container main-panel>
      whatever will be in the main panel part
  </ng-container>

  <ng-container right-panel>
    The right panel content
  </ng-container>
</collapsible-right-panel-layout>
 *
 * The [(isRightPanelOpened)]="rpOpened" is optional.
 * You can either fully control the value (`[(isRightPanelOpened)]="rpOpened"`), only listen for changes (`(isRightPanelOpened)="soStuff()"`) or ignore it and let the component handle it internally
 * But you should not only set it (`[isRightPanelOpened]="rpOpened"`) as it could lead to inconsistency between internal state and parent state.
 *
 **** About tabs
 * tabs are optional.
 * Component takes a TabDefinition object as input, and a selectedTab input/output.
 * selectedTab type can be restricted to an union of literals to make type-safe switch possible
 */

export const INFO_TAB : TabDefinition<'info'> = {
    id: 'info',
    icon: 'dku-icon-info-circle-fill-24',
    tooltip: 'Details',
  };
  export const SCHEMA_TAB : TabDefinition<'schema'> = {
    id: 'schema',
    icon: 'dku-icon-text-bullet-list-circle-24',
    tooltip: 'Schema',
  };

export type TabDefinition<TabsId extends string> = {
  id: TabsId;
  icon: string;
  tooltip?: string;
};

@Component({
  selector: 'collapsible-right-panel-layout',
  templateUrl: './collapsible-right-panel-layout.component.html',
  styleUrls: ['./collapsible-right-panel-layout.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightPanelLayoutComponent<TabsId extends string> implements OnChanges {
  @Input() set isRightPanelOpened(newValue: boolean) {
    this.isRightPanelOpenedInternal = newValue;
  }
  @Output() isRightPanelOpenedChange = new EventEmitter<boolean>();
  isRightPanelOpenedInternal: boolean = false;

  @Input() availableTabs: TabDefinition<TabsId>[] = [];
  @Input() selectedTab: TabsId;
  @Output() selectedTabChange = new EventEmitter<TabsId>();

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.availableTabs) {
      if (!this.availableTabs.find(tab => tab.id === this.selectedTab)) {
        this.selectedTab = this.availableTabs[0].id;
        this.selectedTabChange.emit(this.selectedTab);
      }
    }
  }

  togglePanelOpened() {
    this.isRightPanelOpenedInternal = !this.isRightPanelOpenedInternal;
    this.isRightPanelOpenedChange.emit(this.isRightPanelOpenedInternal);
  }
}
