import { Inject, Injectable } from '@angular/core';
import { MeasureDef } from '@model-main/pivot/frontend/model/measure-def';
import { ChartType } from '@model-main/pivot/frontend/model/chart-type';
import { isEqual, isUndefined, merge } from 'lodash';
import { FrontendChartDef } from '../interfaces';

enum MeasureOrDimensionChangeType {
    IMPORTANT,
    IMPORTANT_DELAYED,
    NO_REDRAW
}

@Injectable({
    providedIn: 'root'
})
/**
 * Defines what to do when the definition of a chart changes.
 * (!) This service previously was in static/dataiku/js/simple_report/services/chart-definition-change-handler.service.js
 */
export class ChartDefinitionChangeHandlerService {

    readonly MeasureOrDimensionChangeType = MeasureOrDimensionChangeType;

    noRedrawProperties = {
        [MeasureOrDimensionChangeType.IMPORTANT]: [],
        [MeasureOrDimensionChangeType.IMPORTANT_DELAYED]: [],
        [MeasureOrDimensionChangeType.NO_REDRAW]: ['decimalPlaces', 'prefix', 'suffix', 'displayLabel', 'multiplier']
    };

    allRedrawProperties = {
        [MeasureOrDimensionChangeType.IMPORTANT]: ['multiplier'],
        [MeasureOrDimensionChangeType.IMPORTANT_DELAYED]: ['decimalPlaces', 'prefix', 'suffix', 'displayLabel', 'colorRules.value'],
        [MeasureOrDimensionChangeType.NO_REDRAW]: []
    };

    //Value is displayed in the chart but not legend
    valuesDisplayedProperties = {
        [MeasureOrDimensionChangeType.IMPORTANT]: ['multiplier', 'textFormatting.fontSize'],
        [MeasureOrDimensionChangeType.IMPORTANT_DELAYED]: ['decimalPlaces', 'prefix', 'suffix'],
        [MeasureOrDimensionChangeType.NO_REDRAW]: ['displayLabel']
    };

    //Display label affects the legend display
    valuesInLegendProperties = {
        [MeasureOrDimensionChangeType.IMPORTANT]: [],
        [MeasureOrDimensionChangeType.IMPORTANT_DELAYED]: ['displayLabel'],
        [MeasureOrDimensionChangeType.NO_REDRAW]: ['decimalPlaces', 'prefix', 'suffix', 'multiplier']
    };

    constructor(
        @Inject('Logger') public loggerService: any,
        @Inject('ChartAxesUtils') private chartAxesUtilsService: any,
        @Inject('ChartFeatures') private chartFeaturesService: any,
    ) {
    }

    // Don't forget to update these lists each time a property is needed in watch

    private formattableMeasures = [
        'genericMeasures', 'colorMeasure', 'uaColor', 'uaSize', 'uaShape', 'boxplotValue', 'uaTooltip',
        'tooltipMeasures', 'sizeMeasure', 'xMeasure', 'yMeasure',
    ];

    private formattableDimensions = [
        'genericDimension0', 'genericDimension1', 'facetDimension', 'animationDimension', 'xDimension', 'yDimension',
        'uaXDimension', 'uaYDimension', 'groupDimension', 'boxplotBreakdownDim'
    ];

    // Update of these properties triggers save + recompute + redraw
    private importantProperties = [
        'type', 'variant', 'webAppType',
        ...this.formattableMeasures,
        ...this.formattableDimensions,
        'geometry', 'filters',
        'stdAggregatedChartMode', 'stdAggregatedMeasureScale',
        'includeZero', 'hexbinRadius', 'hexbinRadiusMode', 'hexbinNumber', 'smoothing', 'brush',
        'axis1LogScale',
        'axis2LogScale',
        'useLiveProcessingIfAvailable',
        'mapGridOptions', 'scatterOptions'
    ];


    // Update of these properties triggers save + redraw
    private frontImportantProperties = [
        'displayWithECharts', 'displayWithEChartsByDefault', 'colorOptions', 'showLegend', 'pieOptions', 'legendPlacement',
        'mapOptions', 'showXAxis', 'strokeWidth', 'fillOpacity', 'chartHeight', 'singleXAxis', 'legendFormatting',
        'xAxisFormatting.displayAxis', 'yAxisFormatting.displayAxis', 'xAxisFormatting.showAxisTitle', 'yAxisFormatting.showAxisTitle',
        'xAxisFormatting.axisTitleFormatting', 'yAxisFormatting.axisTitleFormatting', 
        'xAxisFormatting.axisValuesFormatting.axisTicksFormatting', 'yAxisFormatting.axisValuesFormatting.axisTicksFormatting',
        'showInChartValues', 'showInChartTotalValues', 'showInChartLabels', 'valuesInChartDisplayOptions', 'geoWeight', 'bubblesOptions',
        'webAppConfig', 'xCustomExtent.editMode', 'yCustomExtent.editMode', 'pivotMeasureDisplayMode', 'referenceLines',
        ...this.formattableMeasures,
        ...this.formattableDimensions
    ];


    private measureFrontImportantProperties = [
        'multiplier', 'decimalPlaces', 'prefix', 'suffix', 'displayLabel', 'colorRules.value'
    ];

    private dimensionFrontImportantProperties = [
        'multiplier', 'decimalPlaces', 'prefix', 'suffix', 'displayLabel', 'textFormatting.fontSize'
    ];

    // Update of these properties triggers save + redraw after a timeout
    private frontImportantDelayedProperties = [
        'xCustomExtent.manualExtent', 'yCustomExtent.manualExtent',
        'decimalPlaces', 'prefix', 'suffix', 'displayLabel', 'colorRules.value',
        'xAxisFormatting.axisValuesFormatting.numberFormatting', 'yAxisFormatting.axisValuesFormatting.numberFormatting',
        'xAxisFormatting.axisTitle', 'yAxisFormatting.axisTitle',
        ...this.formattableMeasures,
        ...this.formattableDimensions
    ];

    // Update of these properties triggers save
    private frontImportantNoRedrawProperties = [
        'animationFrameDuration', 'animationRepeat', 'pivotDisplayTotals',
        ...this.formattableMeasures,
        ...this.formattableDimensions,
    ];


    ////////////

    /**
     * Resolves the value of an object nested in a rootObject knowing its relative string keyPath.
     *
     * @example
     *
     * const rootObject = {
     *      id: 'my-plugin',
     *      storeDesc: {
     *          meta: {
     *              support: 'tier2'
     *           }
     *       }
     *  }
     *
     * resolveValue(rootObject, 'storeDesc.meta.support');
     * // -> 'tier2'
     *
     * @param {Object}  rootObject  - The object where we're looking for the value.
     * @param {String}  keyPath     - The string key where to find the value from rootObject.
     * @param {String}  separator   - (Optional) Character to use to split the keyPath.
     */
    private resolveValue = (rootObject: Object, keyPath: string, separator = '.') => {
        const keys = keyPath.split(separator);
        const resolver = (previousObject: Object, currentKey: keyof Object) => previousObject && previousObject[currentKey];
        return keys.reduce(resolver as any, rootObject);
    };

    private getInvalidCustomExtentMessage = (axisName: string) => {
        return `Manually defined ${axisName} range max is lower than its min. Please check the ${axisName} axis formatting.`;
    };

    getInvalidChangeMessage = (nv: any) => {
        if (!this.chartAxesUtilsService.isCustomExtentValid(nv.xCustomExtent)) {
            return this.getInvalidCustomExtentMessage('X');
        }
        if (!this.chartAxesUtilsService.isCustomExtentValid(nv.yCustomExtent)) {
            return this.getInvalidCustomExtentMessage('Y');
        }
        return null;
    };

    private isPropertyValueEqual = (_property: any, nv: any, ov: any) => {
        return isEqual(nv, ov); // default equality check
    };

    /**
     * Return the first found changed property (if any) from a given list of properties
     * @param  {ChartDef}   nvList - ChartDef new values
     * @param  {ChartDef}   ovList - ChartDef old values
     * @param  {Array}      propertiesList - list of properties to check for change
     * @param  {Function}   [checkEqualityFn] - Called with (key, nv, ov), specific function used to check values equality
     * @return {Object}     changed property - {name, ov, nv}, or null if no change found
     */
    private getPropertyChangeInList = (nvList: FrontendChartDef, ovList: FrontendChartDef, propertiesList: Array<any>, checkEqualityFn: Function = this.isPropertyValueEqual): { name: string, nv: any, ov: any } | null => {
        let changedProperty = null;
        const that = this;

        propertiesList.some(property => {
            const nvProp = that.resolveValue.bind(that, nvList, property)();
            const ovProp = that.resolveValue.bind(that, ovList, property)();

            //  Filtering properties starting with $ and properties with undefined values
            const filteredNvProp = this.filterPrivateProperties(nvProp);
            const filteredOvProp = this.filterPrivateProperties(ovProp);

            if (!checkEqualityFn.bind(that, property, filteredNvProp, filteredOvProp, nvList)()) {
                changedProperty = {
                    name: property,
                    nv: nvProp,
                    ov: ovProp
                };
                return true;
            }
            return;
        });
        return changedProperty;
    };

    /**
     * Check measures equality against a list of properties
     * @param  {Array}      newMeasures - list of new measures
     * @param  {Array}      oldMeasures - list of old measures
     * @param  {Array}      propertiesList - list of properties to check for change
     * @return {Boolean}    true if new and old measures have equal values for the properties checked
     */
    private haveEqualMeasuresPropertiesInList = (newMeasures: Array<MeasureDef>, oldMeasures: Array<MeasureDef>, propertiesList: Array<string>) => {
        for (let i = 0; i < Math.max(newMeasures.length, oldMeasures.length); i++) {
            if (!this.depthFirstSearchProperties(newMeasures[i], oldMeasures[i], propertiesList)) {
                return false;
            }
        }

        return true;
    };

    /**
     * Recursivly Check Objects equality against a list of properties
     * Handle Arrays by flattening properties for example:
     * o = {
     *       prop1: 'value'
     *       prop2: [
     *           {
     *             a: 'value'
     *           },
     *           {
     *             a: 'value2'
     *           }
     *       ]
     *     }
     *
     * Property 'a' can be checked by adding 'prop2.a' in propertiesList
     * The check will be performed symmetrically on all list entries between the 2 objects.
     *
     * @param   {Object}     newObject - new object
     * @param   {Array}      oldObject - old object
     * @param   {Array}      propertiesList - list of properties to check for change
     * @returns {Boolean}    true if new and old object have equal values for the properties checked
     */
    depthFirstSearchProperties = (newObject: Object, oldObject: Object, propertiesList: Array<string>): boolean => {
        if (newObject === undefined || oldObject === undefined) {
            return newObject === oldObject;
        }

        let res = true;
        for (const props of propertiesList) {
            const propsSplit = props.split('.');
            while (propsSplit.length > 0) {
                const prop = propsSplit.shift() as keyof Object;
                if (!res) {
                    return false;
                } else if (prop.startsWith('$')) {
                    res = true;
                } else if (newObject[prop] === undefined || oldObject[prop] === undefined) {
                    res = res && newObject[prop] === oldObject[prop];
                } else if (!propsSplit.length) {
                    res = res && isEqual(newObject[prop], oldObject[prop]);
                } else if (typeof newObject[prop] === 'object') {
                    for (let j = 0; j < Math.max(newObject[prop].length, oldObject[prop].length) || j == 0; j++) {
                        res = res && this.depthFirstSearchProperties(
                            (newObject[prop] as any)[j] || newObject[prop],
                            (oldObject[prop] as any)[j] || oldObject[prop],
                            [propsSplit.join('.')]
                        );
                    }
                } else {
                    this.loggerService.warn(`Property ${prop} could not be found, returning false.`);
                }
            }
        }

        return res;
    };

    private isFormattableMeasure = (option: string) => {
        return this.formattableMeasures.includes(option);
    };

    private isFormattableDimension = (option: string) => {
        return this.formattableDimensions.includes(option);
    };

    /**
     * Flatten all object properties
     * @param   { Object }     object - from which we extract properties
     * @param   { String }     prefix - prefix used for recursion
     * @returns { Array }      list of properties
     *
     * --- Specifications:
     * Not supported: Array of primitve elements (only Array of objects)
     * As soon as a property is an array, the property won't be extracted, only its nested ones.
     * If a property is an Array we assume its nested properties can ALL be found in its first element,
     * we do not loop over all array elements to find new properties.
     *
     * Example:
     * o = {
     *       prop1: 'value'
     *       prop2: [
     *           {
     *             a: 'value',
     *           },
     *           {
     *             a: 'value'
     *             b: 'value'
     *           }
     *       ]
     *     }
     *
     * Returned Array: ['prop1', 'prop2.a']
     */
    getAllDeepProperties = (object: Object, prefix: string = ''): Array<string> => {
        object = (Array.isArray(object) && object[0] || object) as Object;
        return Object.keys(object).reduce((res, property) => {
            if (typeof object[property as keyof Object] === 'object' && object[property as keyof Object] !== null) {
                return [...res, ...this.getAllDeepProperties(object[((isNaN(property as any) && property) || 0) as keyof Object], prefix + ((isNaN(property as any) && property + '.') || ''))];
            }
            return [...res, prefix + property];
        }, [] as Array<string>);
    };

    private filterPrivateProperties<T = any>(collection: T): T {
        if (typeof collection !== 'object' || collection === null) {
          // If the collection is not an object or is null, return it as-is
          return collection;
        } else if (Array.isArray(collection)) {
          // If the collection is an array, map over it and recursively remove properties starting with '$'
          return collection.map((item) => this.filterPrivateProperties(item)) as unknown as T;
        } else {
          // If the collection is an object, create a new object and copy over all properties except those starting with '$' and which values are undefined
          const newObj: any = {};
          for (const [key, value] of Object.entries(collection)) {
            if (!key.startsWith('$') && !isUndefined(value)) {
              newObj[key] = this.filterPrivateProperties(value);
            }
          }
          return newObj as T;
        }
    }

    // Check equality of a property which change triggers save + recompute + redraw
    private isImportantValueEqual = (property: string, nv: any, ov: any) => {
        if (this.isFormattableMeasure(property) && nv && ov) {
            // Compare all existing inner measure properties except those from measuresFrontImportantProperties
            const allMeasures = [...nv, ...ov];
            const allMeasuresKeys = this.getAllDeepProperties(merge({}, ...allMeasures));
            const measuresImportantProperties = allMeasuresKeys.filter(key => !this.measureFrontImportantProperties.includes(key));
            return this.haveEqualMeasuresPropertiesInList(nv, ov, measuresImportantProperties);
        }
        if (this.isFormattableDimension(property) && nv && ov) {
            const allDimensions = [...nv, ...ov];
            const allDimensionKeys = this.getAllDeepProperties(merge({}, ...allDimensions));
            const dimensionsImportantProperties = allDimensionKeys.filter(key => !this.dimensionFrontImportantProperties.includes(key)).filter(key => !key.endsWith('$$hashKey'));
            return this.haveEqualMeasuresPropertiesInList(nv, ov, dimensionsImportantProperties);
        }

        return isEqual(nv, ov);
    };

    // Check equality of a property which change triggers save + redraw
    private isFrontImportantValueEqual = (property: string, nv: any, ov: any, chartDef: FrontendChartDef) => {
        if ((this.isFormattableMeasure(property) || this.isFormattableDimension(property)) && nv && ov) {
            const properties = this.getFormattingPropertiesForChangeType(chartDef, MeasureOrDimensionChangeType.IMPORTANT, property);
            return this.haveEqualMeasuresPropertiesInList(nv, ov, properties);
        }
        return isEqual(nv, ov);
    };

    // Check equality of a property which change triggers save + redraw after timeout
    private isFrontImportantDelayedValueEqual = (property: string, nv: any, ov: any, chartDef: FrontendChartDef) => {
        if ((this.isFormattableMeasure(property) || this.isFormattableDimension(property)) && nv && ov) {
            const propertiesDelayed = this.getFormattingPropertiesForChangeType(chartDef, MeasureOrDimensionChangeType.IMPORTANT_DELAYED, property);
            return this.haveEqualMeasuresPropertiesInList(nv, ov, propertiesDelayed);
        }
        return isEqual(nv, ov);
    };

    // Check equality of a property which change triggers save
    private isFrontNoRedrawValueEqual = (property: string, nv: any, ov: any, chartDef: FrontendChartDef) => {
        if ((this.isFormattableMeasure(property) || this.isFormattableDimension(property)) && nv && ov) {
            const propertiesNoRedraw = this.getFormattingPropertiesForChangeType(chartDef, MeasureOrDimensionChangeType.NO_REDRAW, property);
            return this.haveEqualMeasuresPropertiesInList(nv, ov, propertiesNoRedraw);
        }
        return isEqual(nv, ov);
    };

    private removeIgnoredFields = (properties: any, ignoredFields: FrontendChartDef['$ignoreFields']) => {
        const updatedProperties = [...properties];
        if (ignoredFields && ignoredFields.length) {
            ignoredFields.forEach(ignoredField => {
                const index = updatedProperties.findIndex(property => property === ignoredField);
                if (index >= 0) {
                    updatedProperties.splice(index, 1);
                }
            });
        }
        return updatedProperties;
    };

    getImportantChange = (nv: FrontendChartDef, ov: FrontendChartDef) => {
        const properties = this.removeIgnoredFields(this.importantProperties, nv.$ignoreFields);
        return this.getPropertyChangeInList(nv, ov, properties, this.isImportantValueEqual);
    };

    getFrontImportantChange = (nv: FrontendChartDef, ov: FrontendChartDef) => {
        const properties = this.removeIgnoredFields(this.frontImportantProperties, nv.$ignoreFields);
        return this.getPropertyChangeInList(nv, ov, properties, this.isFrontImportantValueEqual);
    };

    getDelayedFrontImportantChange = (nv: FrontendChartDef, ov: FrontendChartDef) => {
        const properties = this.removeIgnoredFields(this.frontImportantDelayedProperties, nv.$ignoreFields);
        return this.getPropertyChangeInList(nv, ov, properties, this.isFrontImportantDelayedValueEqual);
    };

    getNoRedrawChange = (nv: FrontendChartDef, ov: FrontendChartDef) => {
        const properties = this.removeIgnoredFields(this.frontImportantNoRedrawProperties, nv.$ignoreFields);
        return this.getPropertyChangeInList(nv, ov, properties, this.isFrontNoRedrawValueEqual);
    };

    getFormattingPropertiesForChangeType = (chartDef: FrontendChartDef, changeType: MeasureOrDimensionChangeType, property: string): string[] => {
        let properties: {
            [key: string]: string[]
        } = {}

        // for maps we always need a full redraw because of the way the tooltips are computed
        if ((property === 'tooltipMeasures' || property === 'uaTooltip') && !this.chartFeaturesService.isMap(chartDef.type)) {
            return this.noRedrawProperties[changeType];
        }

        switch (chartDef.type) {
            case ChartType.grouped_columns:
            case ChartType.stacked_columns:
            case ChartType.stacked_bars: 
                if (property === 'genericMeasures') {
                    properties = chartDef.showInChartValues ? this.valuesDisplayedProperties : chartDef.genericDimension1.length ? this.noRedrawProperties : this.valuesInLegendProperties;
                } else if (property === 'genericDimension0') {
                    properties = this.noRedrawProperties;
                } else {
                    //genericDimension1 (color)
                    properties = this.valuesDisplayedProperties;
                }
                break;
            case ChartType.pivot_table:
                if (property === 'colorMeasure') {
                    properties = this.noRedrawProperties;
                } else {
                    properties = this.allRedrawProperties;
                }
                break;
            case ChartType.treemap:
                if (property === 'genericMeasures') {
                    properties = chartDef.showInChartValues ? this.valuesDisplayedProperties : this.noRedrawProperties;
                } else {
                    //yDimension, colorMeasure
                    //to be updated for yDimension - not always - depending on whether the dimension's values are displayed in chart or not
                    properties = this.valuesDisplayedProperties;
                }
                break;
            case ChartType.lines:
            case ChartType.multi_columns_lines:
            case ChartType.stacked_area:
                if (property === 'genericDimension1') {
                    properties = this.valuesDisplayedProperties;
                } else if (property === 'genericMeasures') {
                    properties = chartDef.genericDimension1.length ? this.noRedrawProperties : this.valuesInLegendProperties;
                } else {
                    //genericDimension0 (x)
                    properties = this.noRedrawProperties;
                }
                break;
            case ChartType.pie: 
                if (property === 'genericMeasures') {
                    properties = chartDef.showInChartValues ? this.valuesDisplayedProperties : this.noRedrawProperties;
                } else {
                    //genericDimension1
                    properties = this.valuesDisplayedProperties;
                }
                break;
            case ChartType.kpi: 
                //genericMeasures
                properties = this.allRedrawProperties;
                break;
            case ChartType.scatter: 
                if (property === 'uaColor') {
                    properties = this.valuesDisplayedProperties;
                }
                else {
                    //uaXDimension, uaYDimension, uaSize, uaShape
                    properties = this.noRedrawProperties;
                }
                break;
            case ChartType.geom_map:
            case ChartType.grid_map:
            case ChartType.admin_map:
            case ChartType.scatter_map:
                //we need a redraw on maps, due to a different tooltip implementation - they don't update without a redraw
                properties = this.allRedrawProperties;
                break;
            case ChartType.grouped_xy:
            case ChartType.binned_xy:
                if (property === 'colorMeasure') {
                    properties = this.valuesDisplayedProperties;
                }
                else {
                    //yMeasure, xMeasure, sizeMeasure
                    properties = this.noRedrawProperties;
                }
                break;
            case ChartType.boxplots:
            case ChartType.lift:
                properties = this.noRedrawProperties;
                break;
            default:
                properties = this.allRedrawProperties;
                break;

        }
        return properties[changeType];
    };
}
