import type {CardResult} from './card-result';
import type {Card} from './card';
import type {UnitRootTestCard, _UnitRootTestCard} from './unit-root-test-card';
import type {UnitRootTestZA} from './../../compute/computations/timeseries/unit-root-test-za';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.UnitRootTestZACard
 */
export interface UnitRootTestZACard extends _UnitRootTestCard {
    regressionMode: UnitRootTestZA.RegressionMode;

    // PolyJson type
    type: 'unit_root_test_za';
}

export namespace UnitRootTestZACard {
    export const type = 'unit_root_test_za';
}

/** @deprecated Use Card.isUnitRootTestZACard() */ export function isUnitRootTestZACard(toTest: Card): toTest is UnitRootTestZACard { return UnitRootTestZACard.type === toTest['type']; }

export namespace UnitRootTestZACard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.UnitRootTestZACard$UnitRootTestZACardResult
     */
    export interface UnitRootTestZACardResult extends UnitRootTestCard._UnitRootTestCardResult {
        // PolyJson type
        type: 'unit_root_test_za';
    }

    export namespace UnitRootTestZACardResult {
        export const type = 'unit_root_test_za';
    }

    /** @deprecated Use CardResult.isUnitRootTestZACardResult() */ export function isUnitRootTestZACardResult(toTest: CardResult): toTest is UnitRootTestZACardResult { return UnitRootTestZACardResult.type === toTest['type']; }
}