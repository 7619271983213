import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {STLDecompositionParams} from './../stldecomposition-params';
import type {_AvailableResult} from './../available-result';
import type {_TimeSeriesComputation} from './time-series-computation';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.timeseries.STLDecomposition
 */
export interface STLDecomposition extends _TimeSeriesComputation {
    params: STLDecompositionParams;

    // PolyJson type
    type: 'stl_decomposition';
}

export namespace STLDecomposition {
    export const type = 'stl_decomposition';
}

/** @deprecated Use Computation.isSTLDecomposition() */ export function isSTLDecomposition(toTest: Computation): toTest is STLDecomposition { return STLDecomposition.type === toTest['type']; }

export namespace STLDecomposition.STLDecompositionResult {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.STLDecomposition$STLDecompositionResult$STLGuessedParams
     */
    export interface STLGuessedParams {
        lowPassSmoother: number;
        period: number;
        trendSmoother: number;
    }
}

export namespace STLDecomposition {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.STLDecomposition$DegreeMode
     */
    export enum DegreeMode {
        CONSTANT = 'CONSTANT',
        CONSTANT_WITH_TREND = 'CONSTANT_WITH_TREND'
    }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.STLDecomposition$DecompositionType
     */
    export enum DecompositionType {
        MULTIPLICATIVE = 'MULTIPLICATIVE',
        ADDITIVE = 'ADDITIVE'
    }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.STLDecomposition$STLDecompositionResult
     */
    export interface STLDecompositionResult extends _AvailableResult {
        guessedParams: STLDecomposition.STLDecompositionResult.STLGuessedParams;
        observed: number[];
        resid: number[];
        seasonal: number[];
        time: string[];
        trend: number[];

        // PolyJson type
        type: 'stl_decomposition';
    }

    export namespace STLDecompositionResult {
        export const type = 'stl_decomposition';
    }

    /** @deprecated Use ComputationResult.isSTLDecompositionResult() */ export function isSTLDecompositionResult(toTest: ComputationResult): toTest is STLDecompositionResult { return STLDecompositionResult.type === toTest['type']; }
}