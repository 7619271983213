<ng-container [ngSwitch]="task.type" *ngIf="imageUrl">
    <ng-container *ngSwitchCase="LabelingTaskType.OBJECT_DETECTION" >
        <object-detection-image-canvas
            class="w100 h100 pad16"
            [imagePath]="imageUrl"
            [label]="label"
            (labelChange)="labelChange.emit($event)"
            [selectionModeAvailable]="true" 
            *ngIf="(label | instanceOf: ObjectDetectionUILabel)">
        </object-detection-image-canvas>
    </ng-container>
    <div class="w100 h100" *ngSwitchCase="LabelingTaskType.IMAGE_CLASSIFICATION">
        <image-container [src]="imageUrl" [scale]="3" [placeholderSize]="'large'"></image-container>
    </div>
</ng-container>
