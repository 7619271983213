import type {Filter, _Filter} from './filter';

/**
 * Generated from com.dataiku.dip.eda.compute.filtering.AllFilter
 */
export interface AllFilter extends _Filter {
    // PolyJson type
    type: 'all';
}

export namespace AllFilter {
    export const type = 'all';
}

/** @deprecated Use Filter.isAllFilter() */ export function isAllFilter(toTest: Filter): toTest is AllFilter { return AllFilter.type === toTest['type']; }