import { Injectable, OnDestroy, Optional } from "@angular/core";
import { WorksheetContextService } from "@features/eda/worksheet-state/worksheet.context.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Card, ResamplerSettings, TimeSeriesCard, Variable } from "src/generated-sources";

@Injectable()
@UntilDestroy()
export class TimeSeriesCardContext implements OnDestroy {

    /**
     * All the time series card for the current worksheet
     */
    private wsTsCards: TimeSeriesCard[] = [];

    constructor(
        // does not exist in the "dashboards" section
        @Optional() private worksheetContextService: WorksheetContextService | null,
    ) {
        this.listenForWorksheetChanges();
    }

    private listenForWorksheetChanges(): void {
        if (this.worksheetContextService == null) {
            return; // nothing to do
        }

        this.worksheetContextService.getRootCard()
            .pipe(untilDestroyed(this))
            .subscribe(rootCard => {
                this.wsTsCards = (rootCard?.cards ?? [])
                    .filter(c => Card.isTimeSeriesCard(c))
                    .map(c => c as TimeSeriesCard);
            })
    }

    ngOnDestroy(): void {
        //required by @UntilDestroy
    }

    /**
     * Finds common resampler settings in the current card context.
     */
    findResampling(timeColumn: Variable): ResamplerSettings | null {
        const candidate = this.wsTsCards.find(c =>
            c.timeColumn.name === timeColumn.name &&
            c.resamplerSettings != null
        );

        return candidate?.resamplerSettings ?? null;
    }

    /**
     * Finds common series identiers settings in the current card context.
     */
    findSeriesIdentifiers(timeColumn: Variable): TimeSeriesCard.TimeSeriesIdentifier[] {
        const candidate = this.wsTsCards.find(c =>
            c.timeColumn.name === timeColumn.name &&
            c.seriesIdentifiers.length > 0
        );

        return candidate?.seriesIdentifiers ?? [];
    }

    /**
     * Finds in the worksheet the time column of any pre-existing time series analysis.
     */
    findTimeColumn(): Variable | null {
        if (this.wsTsCards.length > 0) {
            return this.wsTsCards[0].timeColumn;
        }

        return null;
    }
}
