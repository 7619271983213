import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'initials'
})
export class InitialsPipe implements PipeTransform {

    transform(text: string): string {
        let initials = text || '';

        if (text) {
            const match = text.match(/\b(\w)/g);
            if (match && match.length >= 2) {
                initials = match.join('');
            }
            initials = initials.charAt(0).toUpperCase() + initials.charAt(1);
        }

        return initials;
    }

}
