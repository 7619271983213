import { Inject, Injectable } from '@angular/core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { DataSourceExternalTable } from '@shared/models';
import { fairAny } from 'dku-frontend-core';
import { catchError, map, of, shareReplay } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataCatalogService {

    constructor(
        private currentRouteService: CurrentRouteService,
        private dataikuApi: DataikuAPIService,
        @Inject('$rootScope') private $rootScope: fairAny,
        @Inject("CreateModalFromTemplate") private CreateModalFromTemplate: fairAny,
        @Inject('$state') private $state: fairAny
    ) {
    }

    // This has a pure display usage and doesn't need to be refreshed nor be very smart in error management
    public hasIndexedConnections = this.dataikuApi.connections.countIndexedAndUnindexed().pipe(
        shareReplay(1),
        map((res) => res.indexedConnections > 0),
        catchError(() => of(true))
    );

    /**
     * Open the mass import external table page.
     * Uses projectKey route param to decide the project where to import, otherwise uses a modal to let the user chose
     * Uses zoneId route param to import to the right flow-zone if available
     */
    gotoMassImportPage(selectedItems: DataSourceExternalTable[]) {
        const importData = {
            wt1Context: {
                from: 'data-catalog'
            },
            workflowType: "KEYS",
            tableKeys: selectedItems.map((selectedItem) => ({
                connectionName: selectedItem.connection,
                catalog: selectedItem.catalog,
                schema: selectedItem.schema,
                name: selectedItem.name
            }))
        };

        const currentProjectContextKey: string | undefined = this.currentRouteService.projectKey ?? undefined;
        if(currentProjectContextKey) {
            return this.$state.go('projects.project.tablesimport', {
                projectKey: currentProjectContextKey,
                importData : JSON.stringify(importData),
                zoneId: this.currentRouteService.zoneId
            });
        } else {
            return this.CreateModalFromTemplate("/templates/datasets/tables-import-project-selection-modal.html", this.$rootScope.$new(), "TablesImportProjectSelectionModalController", (newScope: fairAny) => {
                newScope.getImportData = () => importData;
            });
        }
    }
}
