import type {BivariateHistogramCard} from './../bivariate_header/bivariate-histogram-card';
import type {BoxPlotFragment} from './../fragments/box-plot-fragment';
import type {CardResult, _CardResult} from './../card-result';
import type {Card} from './../card';
import type {Filter} from './../../../compute/filtering/filter';
import type {MoodTestNSampCard} from './../mood-test-nsamp-card';
import type {OneWayANOVACard} from './../one-way-anovacard';
import type {PairwiseMoodTestCard} from './../pairwise-mood-test-card';
import type {PairwiseTTestCard} from './../pairwise-ttest-card';
import type {_BivariateCard} from './../bivariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.common.AbstractNSampTestCard
 */
export interface _AbstractNSampTestCard extends _BivariateCard {
    confidenceLevel: number;
    maxGroups: number;
    yValues?: string[] | null;
}

export type AbstractNSampTestCard = MoodTestNSampCard | OneWayANOVACard | PairwiseMoodTestCard | PairwiseTTestCard;

export namespace AbstractNSampTestCard {
    export const type = ['mood_nsamp', 'oneway_anova', 'pairwise_mood', 'pairwise_ttest'] as const;
}

/** @deprecated Use Card.isAbstractNSampTestCard() */ export function isAbstractNSampTestCard(toTest: Card): toTest is AbstractNSampTestCard { return (AbstractNSampTestCard.type as readonly unknown[]).includes(toTest['type']); }

export namespace AbstractNSampTestCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.common.AbstractNSampTestCard$AbstractNSampleTestCardResult
     */
    export interface _AbstractNSampleTestCardResult extends _CardResult {
        allGroups: AbstractNSampTestCard.GroupInfo;
        groups: AbstractNSampTestCard.GroupInfo[];
        histogramData: BivariateHistogramCard.BivariateHistogramData;
    }

    export type AbstractNSampleTestCardResult = MoodTestNSampCard.MoodTestNSampCardResult | OneWayANOVACard.OneWayANOVACardResult | PairwiseMoodTestCard.PairwiseMoodTestCardResult | PairwiseTTestCard.PairwiseTTestCardResult;

    export namespace AbstractNSampleTestCardResult {
        export const type = ['mood_nsamp', 'oneway_anova', 'pairwise_mood', 'pairwise_ttest'] as const;
    }

    /** @deprecated Use CardResult.isAbstractNSampleTestCardResult() */ export function isAbstractNSampleTestCardResult(toTest: CardResult): toTest is AbstractNSampleTestCardResult { return (AbstractNSampleTestCardResult.type as readonly unknown[]).includes(toTest['type']); }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.common.AbstractNSampTestCard$GroupInfo
     */
    export interface GroupInfo {
        boxPlot: BoxPlotFragment;
        filter: Filter;
    }
}