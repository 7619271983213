import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Card } from 'src/generated-sources';

const {
    isUnivariateHeaderCard,
    isBivariateHeaderCard,
    isCorrelationMatrixCard,
    isParallelCoordinatesPlotCard,
    isPCACard,
    isTimeSeriesCard,
} = Card;

@Component({
    selector: 'card-editor',
    templateUrl: './card-editor.component.html',
    styleUrls: [
        '../../../shared-styles/card-wizard.less',
        './card-editor.component.less'
    ]
})
export class CardEditorComponent implements OnChanges {
    @Input() params: Card;
    @Output() paramsChange = new EventEmitter<Card>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);
    @Output() validityTooltipChange = new EventEmitter<string>(true);

    className = '';

    constructor() {

    }

    ngOnChanges(): void {
        const requiresWideEditor =
            isUnivariateHeaderCard(this.params)
            || isBivariateHeaderCard(this.params)
            || isCorrelationMatrixCard(this.params)
            || isParallelCoordinatesPlotCard(this.params)
            || isPCACard(this.params);

        const cssClasses = [
            `card-wizard__body--${this.params.type}`,
            `card-wizard__body--${requiresWideEditor ? "wide" : "narrow"}`
        ];

        if (isTimeSeriesCard(this.params)) {
            cssClasses.push('card-wizard__body--time-series');
        }

        this.className = cssClasses.join(' ');
    }
}
