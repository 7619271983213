import { CdkVirtualForOf, CdkVirtualScrollViewport, ExtendedScrollToOptions } from '@angular/cdk/scrolling';
import { Component, ChangeDetectionStrategy, ViewChild, ElementRef, NgZone, Input, TemplateRef, AfterViewInit, ChangeDetectorRef, HostBinding } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WindowService } from 'dku-frontend-core';

@UntilDestroy()
@Component({
    selector: 'drag-virtual-scroll',
    templateUrl: './drag-virtual-scroll.component.html',
    styleUrls: ['./drag-virtual-scroll.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DragVirtualScrollComponent implements AfterViewInit {
    @Input() items: unknown[];
    @Input() itemTemplate: TemplateRef<{ item: object }>;
    @Input() itemWidth: number = 320;
    @Input() itemHeight: number = 200;

    originalClickX: number;
    originalClickY: number;
    dragged: boolean = false;
    isDragging: boolean = false;

    @HostBinding('style.--tile-width') get tileWidth() { return `${this.itemWidth}px`; }
    @HostBinding('style.--tile-height') get tileHeight() { return `${this.itemHeight}px`; }

    @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
    @ViewChild('scrollZone', { static: true }) el: ElementRef;
    end: number;
    displayLeftArrow: boolean = false;
    displayRightArrow: boolean = false;

    constructor(
        private zone: NgZone,
        private changeDetectorRef: ChangeDetectorRef,
        private windowService: WindowService
    ) { }

    onDragStart(event: MouseEvent) {
        this.isDragging = this.displayLeftArrow || this.displayRightArrow;
        this.originalClickX = event.pageX;
        this.originalClickY = event.pageY;
    }

    onDragEnd() {
        this.isDragging = false;
        this.dragged = false;
    }

    scrollByPage(event: MouseEvent, scrollDirection: -1 | 1) {
        setTimeout(() => {
            const scrollDistance = (this.viewport.measureScrollOffset('start') + this.itemWidth * 3 * scrollDirection);
            const options: ExtendedScrollToOptions = { behavior: 'smooth', start: scrollDistance };
            this.viewport.scrollTo(options);
        }, 0);
    }

    onMouseMove(event: MouseEvent) {
        if (this.isDragging) {
            // prevent text selection
            event.preventDefault();

            const deltaX = Math.abs(event.pageX - this.originalClickX);
            const deltaY = Math.abs(event.pageY - this.originalClickY);
            if (!this.dragged) {
                this.dragged = this.dragged || deltaX > 4 || deltaY > 4;
                this.zone.run(() => this.changeDetectorRef.markForCheck());
            }

            const scrollDistance = (this.viewport.measureScrollOffset('start') - event.movementX);
            const options: ExtendedScrollToOptions = { behavior: 'auto', start: scrollDistance };
            setTimeout(() => {
                this.viewport.scrollTo(options);
            }, 0);
        }
    }

    updateArrowDisplay() {
        this.displayLeftArrow = this.viewport.measureScrollOffset('start') > 50;
        this.displayRightArrow = this.viewport.measureScrollOffset('end') > 50;
        this.zone.run(() => this.changeDetectorRef.markForCheck());
    }

    ngAfterViewInit(): void {
        // don't want to fire angular change detection every time the mouse moves
        this.zone.runOutsideAngular(() => {
            this.el.nativeElement.addEventListener('mousemove', this.onMouseMove.bind(this));
        });

        // call one time on page load because to display or not the right arrow
        setTimeout(() => this.updateArrowDisplay());
        this.viewport.elementScrolled().subscribe(() => this.updateArrowDisplay());

        this.windowService.resize$.pipe(
            untilDestroyed(this)
        ).subscribe(() => {
            this.updateArrowDisplay();
        });
    }
}
