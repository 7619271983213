import { Inject, Injectable } from '@angular/core';
import { FrontendChartDef } from '@features/simple-report/interfaces';
import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';
import { Selection } from 'd3-selection';
import { rgb } from 'd3-color';
import { cloneDeep, isNil, isNumber } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { NumberFormatterService } from '../../../services';
import { ReferenceLineAxisInfo, D3ReferenceLineAxisInfo, FrontendReferenceLine, ReferenceLinesExtents, ReferenceLineAvailableAxisInfo } from './interfaces';

@Injectable({
    providedIn: 'root'
})
export class ReferenceLinesService {
    private logger: any;
    private REFERENCE_LINE_CLASS = 'reference-line';
    private REFERENCE_LINE_VALUE_CLASS = 'reference-line-value';

    private DEFAULT_COLOR = '#ccc';
    private DEFAULT_FONT_COLOR = '#333';
    private DEFAULT_BACKGROUND_COLOR = '#D9D9D9BF';
    private DEFAULT_STROKE_WIDTH = 1;
    private DEFAULT_TEXT_MARGIN = 10;
    private DEFAULT_SIDE_MARGIN = 4;
    private DEFAULT_SVG_TEXT_RATIO = 11/15; //  <text> applies a default margin on a text with a 11/15 ratio
    private DEFAULT_FONT_SIZE = 12;
    private DEFAULT_FONT_FAMILY = 'SourceSansPro';

    public availableAxisOptions$ = new BehaviorSubject<Array<ReferenceLineAvailableAxisInfo>>([]);

    constructor(
        @Inject('Logger') loggerFactory: any,
        @Inject('D3ChartAxes') private d3ChartAxesService: any,
        private numberFormatterService: NumberFormatterService
    ) {
        this.logger = loggerFactory(({ serviceName: 'ReferenceLinesService', objectName: 'Service' }));
    }

    public removeReferenceLines(container: HTMLElement) {
        const referenceLines = (container || document).querySelectorAll(`.${this.REFERENCE_LINE_CLASS}`);
        const referenceLineValues = (container || document).querySelectorAll(`.${this.REFERENCE_LINE_VALUE_CLASS}`);
        referenceLines.forEach(referenceLine => referenceLine.remove());
        referenceLineValues.forEach(referenceLineValue => referenceLineValue.remove());
    }

    public drawReferenceLines(container: Selection<HTMLElement, any, HTMLElement, any>, width: number, height: number, xAxis: D3ReferenceLineAxisInfo, yAxis: D3ReferenceLineAxisInfo, y2Axis: D3ReferenceLineAxisInfo, referenceLines: Array<FrontendReferenceLine>, position?: {left: number, top: number}) {
        (referenceLines || []).forEach(referenceLine => {
            let mainAxis = yAxis;
            let extent;

            switch(referenceLine.axis) {
                case ChartDef.ReferenceLineAxis.LEFT_Y_AXIS:
                    if (!yAxis) {
                        this.logger.warn('Trying to display on left Y axis but it is not defined');
                        return;
                    }
                    extent = this.d3ChartAxesService.getCurrentAxisExtent(yAxis);
                    break;
                case ChartDef.ReferenceLineAxis.RIGHT_Y_AXIS:
                    if (!y2Axis) {
                        this.logger.warn('Trying to display on right Y axis but it is not defined');
                        return;
                    }
                    mainAxis = y2Axis;
                    extent = this.d3ChartAxesService.getCurrentAxisExtent(y2Axis);
                    break;
                case ChartDef.ReferenceLineAxis.X_AXIS:
                    if (!xAxis) {
                        this.logger.warn('Trying to display on X axis but it is not defined');
                        return;
                    }
                    mainAxis = xAxis;
                    extent = this.d3ChartAxesService.getCurrentAxisExtent(xAxis);
                    break;
                default:
                    this.logger.warn(`${referenceLine.axis} is not defined`);
                    return;
            }

            if (isNil(referenceLine.value)) {
                this.logger.warn('A value should be defined to draw a reference line');
                return;
            }

            /*
             *  Checks if current value for reference line is in its axis domain.
             *  Axis domain is already impacted by the reference line values,
             *  except if the user set a custom extent.
             */
            const referenceLineValue = mainAxis.isPercentScale ? referenceLine.value / 100 : referenceLine.value;

            if (mainAxis.scaleType !== 'ORDINAL' && referenceLineValue >= extent[0] && referenceLineValue <= extent[1]) {
                const value: number = mainAxis.scale()(referenceLineValue);
                const basis = 0;

                const xBasis: number = position?.left || basis;
                const yBasis: number = position?.top || basis;

                const xPosition = value + xBasis;
                const yPosition = value + yBasis;

                let x1Position = xBasis;
                let x2Position = width + xBasis;
                let y1Position = yBasis;
                let y2Position = height + yBasis;

                if (xAxis && xAxis.scaleType !== 'ORDINAL') {
                    const xDomain = xAxis.scale().domain();
                    x1Position = xAxis.scale()(xDomain[0]) + xBasis;
                    x2Position = xAxis.scale()(xDomain[1]) + xBasis;
                }

                if (yAxis && yAxis.scaleType !== 'ORDINAL') {
                    const yDomain = yAxis.scale().domain();
                    y1Position = yAxis.scale()(yDomain[0]) + yBasis;
                    y2Position = yAxis.scale()(yDomain[1]) + yBasis;
                }

                const line = container.append('line')
                    .style('stroke', referenceLine.lineColor || this.DEFAULT_COLOR)
                    .style('stroke-width', referenceLine.lineSize || this.DEFAULT_STROKE_WIDTH)
                    .style('pointer-events', 'none') // To avoid issues with tooltips beneath it
                    .attr('class', this.REFERENCE_LINE_CLASS);

                if (referenceLine.axis === ChartDef.ReferenceLineAxis.X_AXIS) {
                    line.attr('x1', xPosition)
                        .attr('x2', xPosition)
                        .attr('y1', y1Position)
                        .attr('y2', y2Position);
                } else {
                    line.attr('x1', x1Position)
                        .attr('x2', x2Position)
                        .attr('y1', yPosition)
                        .attr('y2', yPosition);
                }

                if (referenceLine.lineType === ChartDef.ChartLineType.DASHED) {
                    line.attr('stroke-dasharray', 12);
                }

                if (referenceLine.displayValue) {
                    const formattingOptions = cloneDeep(mainAxis.formattingOptions);
                    if (referenceLine.multiplier !== ChartDef.ReferenceLineMultiplier.Inherit) {
                        formattingOptions.multiplier = referenceLine.multiplier;
                    }
                    const formatter = this.numberFormatterService.getForAxis(extent[0], extent[1], mainAxis.ticks()[0], formattingOptions, mainAxis.isPercentScale);
                    const formattedValue = mainAxis.isPercentScale ? formatter(referenceLine.value / 100) : formatter(referenceLine.value);
                    const valueLabel = `${referenceLine.prefix || ''}${formattedValue}${referenceLine.suffix || ''}`;
                    const valueSize = (referenceLine.valueFormatting && referenceLine.valueFormatting.fontSize) || this.DEFAULT_FONT_SIZE;

                    let rect;
                    if (referenceLine.valueFormatting && referenceLine.valueFormatting.hasBackground) {
                        //  Draw rect before text to be sure that text always appears above background
                        rect = container.append('rect');
                    }
                    const text = container.append('text')
                        .attr('class', this.REFERENCE_LINE_VALUE_CLASS)
                        .attr('fill', (referenceLine.valueFormatting && referenceLine.valueFormatting.fontColor) || this.DEFAULT_FONT_COLOR)
                        .style('font-size', `${valueSize}px`)
                        .style('line-height', `${valueSize}px`)
                        .style('font-family', this.DEFAULT_FONT_FAMILY)
                        .text(valueLabel);

                    const textRect = text.node()?.getBoundingClientRect();
                    const textWidth = textRect?.width || 0;
                    const textHeight = textRect?.height || 0;
                    const lineSize = referenceLine.lineSize || 1;

                    let xTextPosition = 0,
                        yTextPosition = 0;

                    if (referenceLine.axis === ChartDef.ReferenceLineAxis.X_AXIS) {
                        xTextPosition = xPosition - this.DEFAULT_SIDE_MARGIN - lineSize;
                        yTextPosition = this.DEFAULT_TEXT_MARGIN;

                        switch(referenceLine.labelPosition) {
                            case ChartDef.ReferenceLinePosition.INSIDE_START_BOTTOM:
                                xTextPosition = xPosition - textWidth - lineSize - this.DEFAULT_SIDE_MARGIN;
                                yTextPosition = y1Position - textHeight * this.DEFAULT_SVG_TEXT_RATIO;
                                break;
                            case ChartDef.ReferenceLinePosition.INSIDE_START_TOP:
                                xTextPosition = xPosition - textWidth - lineSize - this.DEFAULT_SIDE_MARGIN;
                                yTextPosition = y2Position + textHeight * this.DEFAULT_SVG_TEXT_RATIO;
                                break;
                            case ChartDef.ReferenceLinePosition.INSIDE_END_BOTTOM:
                                xTextPosition = xPosition + this.DEFAULT_SIDE_MARGIN + lineSize;
                                yTextPosition = y1Position - textHeight * this.DEFAULT_SVG_TEXT_RATIO;
                                break;
                            case ChartDef.ReferenceLinePosition.INSIDE_END_TOP:
                                xTextPosition = xPosition + this.DEFAULT_SIDE_MARGIN + lineSize;
                                yTextPosition = y2Position + textHeight * this.DEFAULT_SVG_TEXT_RATIO;
                                break;

                            default:
                                break;
                        }
                    } else {
                        xTextPosition = this.DEFAULT_SIDE_MARGIN;
                        yTextPosition = yPosition - this.DEFAULT_TEXT_MARGIN - lineSize;

                        switch(referenceLine.labelPosition) {
                            case ChartDef.ReferenceLinePosition.INSIDE_START_TOP:
                                xTextPosition = x1Position + this.DEFAULT_SIDE_MARGIN;
                                yTextPosition = yPosition - this.DEFAULT_TEXT_MARGIN - lineSize;
                                break;
                            case ChartDef.ReferenceLinePosition.INSIDE_START_BOTTOM:
                                xTextPosition = x1Position + this.DEFAULT_SIDE_MARGIN;
                                yTextPosition = yPosition + this.DEFAULT_TEXT_MARGIN + lineSize + textHeight * this.DEFAULT_SVG_TEXT_RATIO;
                                break;
                            case ChartDef.ReferenceLinePosition.INSIDE_END_TOP:
                                xTextPosition = x2Position - textWidth - this.DEFAULT_SIDE_MARGIN;
                                yTextPosition = yPosition - this.DEFAULT_TEXT_MARGIN - lineSize;
                                break;
                            case ChartDef.ReferenceLinePosition.INSIDE_END_BOTTOM:
                                xTextPosition = x2Position - textWidth - this.DEFAULT_SIDE_MARGIN;
                                yTextPosition = yPosition + this.DEFAULT_TEXT_MARGIN + lineSize + textHeight * this.DEFAULT_SVG_TEXT_RATIO;
                                break;

                            default:
                                break;
                        }
                    }

                    text.attr('x', xTextPosition)
                        .attr('y', yTextPosition);

                    if (rect) {
                        const textNode = text.node();
                        if (textNode) {
                            const textRect = textNode.getBBox();
                            const backgroundColor = rgb((referenceLine.valueFormatting && referenceLine.valueFormatting.backgroundColor) || this.DEFAULT_BACKGROUND_COLOR);

                            rect
                                .attr('x', textRect.x - this.DEFAULT_SIDE_MARGIN / 2)
                                .attr('y', textRect.y - this.DEFAULT_SIDE_MARGIN / 2)
                                .attr('width', textRect.width + this.DEFAULT_SIDE_MARGIN)
                                .attr('height', textRect.height + this.DEFAULT_SIDE_MARGIN)
                                .attr('fill', backgroundColor.formatRgb());
                        }
                    }

                }
            }
        });
    }

    public getReferenceLinesExtents(referenceLines: Array<FrontendReferenceLine>, axes?: {yAxis: ReferenceLineAxisInfo, y2Axis: ReferenceLineAxisInfo, xAxis: ReferenceLineAxisInfo}): ReferenceLinesExtents {
        const referenceLinesExtents = {
            y1ReferenceLinesExtent: { min: Infinity, max: -Infinity },
            y2ReferenceLinesExtent: { min: Infinity, max: -Infinity },
            xReferenceLinesExtent: { min: Infinity, max: -Infinity }
        };

        return (referenceLines || []).reduce((acc, referenceLine) => {
            if (referenceLine.axis === ChartDef.ReferenceLineAxis.LEFT_Y_AXIS && isNumber(referenceLine.value)) {
                const referenceLineValue = axes && axes.yAxis && axes.yAxis.isPercentScale ? referenceLine.value / 100 : referenceLine.value;
                acc.y1ReferenceLinesExtent.min = Math.min(acc.y1ReferenceLinesExtent.min, referenceLineValue);
                acc.y1ReferenceLinesExtent.max = Math.max(acc.y1ReferenceLinesExtent.max, referenceLineValue);
            }

            if (referenceLine.axis === ChartDef.ReferenceLineAxis.RIGHT_Y_AXIS && isNumber(referenceLine.value)) {
                const referenceLineValue = axes && axes.y2Axis && axes.y2Axis.isPercentScale ? referenceLine.value / 100 : referenceLine.value;
                acc.y2ReferenceLinesExtent.min = Math.min(acc.y2ReferenceLinesExtent.min, referenceLineValue);
                acc.y2ReferenceLinesExtent.max = Math.max(acc.y2ReferenceLinesExtent.max, referenceLineValue);
            }

            if (referenceLine.axis === ChartDef.ReferenceLineAxis.X_AXIS && isNumber(referenceLine.value)) {
                const referenceLineValue = axes && axes.xAxis && axes.xAxis.isPercentScale ? referenceLine.value / 100 : referenceLine.value;
                acc.xReferenceLinesExtent.min = Math.min(acc.xReferenceLinesExtent.min, referenceLineValue);
                acc.xReferenceLinesExtent.max = Math.max(acc.xReferenceLinesExtent.max, referenceLineValue);
            }

            return acc;
        }, referenceLinesExtents);
    }

    public getExtentWithReferenceLines(extent: [number, number], referenceLinesExtent: {min: number, max: number}): [number, number] {
        let min = extent[0];
        let max = extent[1];

        if (isFinite(min) && referenceLinesExtent.min <= min) {
            min = referenceLinesExtent.min;
        }

        if (isFinite(max) && referenceLinesExtent.max >= max) {
            max = referenceLinesExtent.max;
        }

        /**
         * Adding 5% margin if min/max is overriden by reference line extent
         * to be sure it is visible in the chart
         */
        const gap = max - min;
        const margin = 0.05 * gap;

        if (min === referenceLinesExtent.min) {
            min = min - margin;
        }

        if (max === referenceLinesExtent.max) {
            max = max + margin;
        }

        return [min, max];
    }

    public updateAvailableAxisOptions(availableAxisOptions: Array<ReferenceLineAvailableAxisInfo>) {
        this.availableAxisOptions$.next(availableAxisOptions);
    }

    public getAvailableAxesForReferenceLines(chartDef: FrontendChartDef): Partial<Record<ChartDef.ReferenceLineAxis, boolean>> {
        const availableAxes: Partial<Record<ChartDef.ReferenceLineAxis, boolean>> = {};
        return chartDef.genericMeasures.reduce((acc, measure) => {
            if (measure.displayAxis === 'axis1') {
                acc[ChartDef.ReferenceLineAxis.LEFT_Y_AXIS] = true;
            }

            if (measure.displayAxis === 'axis2') {
                acc[ChartDef.ReferenceLineAxis.RIGHT_Y_AXIS] = true;
            }

            return acc;
        }, availableAxes);
    }
}
