import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { realAny } from 'dku-frontend-core';

@Component({
    selector: 'prompt-modal',
    templateUrl: './prompt-modal.component.html',
    styleUrls: ['./prompt-modal.component.less']
})
export class PromptModalComponent implements OnInit {
    value: string;

    constructor(
        public dialogRef: MatDialogRef<realAny, string>,
        @Inject(MAT_DIALOG_DATA) public data: {
            title: string,
            message: string,
            defaultVal: string,
            danger?: boolean
            icon?: string
        }
    ) {
        this.value = this.data.defaultVal;
    }

    confirm() {
        this.dialogRef.close(this.value);
    }

    cancel() {
        this.dialogRef.close(undefined);
    }

    ngOnInit(): void {
    }
}
