import * as dep_dku_frontend_core from 'dku-frontend-core/generated-sources';
import type {PredictionModelingParams} from './prediction/prediction-modeling-params';

/**
 * Generated from com.dataiku.dip.analysis.model.ModelTrainInfo
 */
export interface ModelTrainInfo {
    endTime: number;
    failure: dep_dku_frontend_core.APIError;
    fullRows: number;
    hyperparamsSearchTime: number;
    kfold: boolean;
    modelId: string;
    postSearchDescription: ModelTrainInfo.PostSearchDescription;
    preSearchDescription: ModelTrainInfo.PreSearchDescription;
    preprocessingTime: number;
    progress: ModelTrainInfo.TrainProgress;
    pythonVersion: string;
    resumed: boolean;
    sampleWeightsEnabled: boolean;
    sampleWeightsVariable: string;
    startTime: number;
    state: ModelTrainInfo.ModelTrainState;
    testRows: number;
    timeOrderingEnabled: boolean;
    timeVariable: string;
    trainRows: number;
    trainingTime: number;
}

export namespace ModelTrainInfo {
    /**
     * Generated from com.dataiku.dip.analysis.model.ModelTrainInfo$PostSearchDescription
     */
    export interface PostSearchDescription {
        importantParams: ModelTrainInfo.SearchParam[];
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.ModelTrainInfo$PreSearchDescription
     */
    export interface PreSearchDescription {
        gridLength: number;
        importantParams: ModelTrainInfo.SearchParam[];
        searchStrategy: PredictionModelingParams.GridSearchParams.Strategy;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.ModelTrainInfo$ModelTrainState
     */
    export enum ModelTrainState {
        PENDING = 'PENDING',
        RUNNING = 'RUNNING',
        DONE = 'DONE',
        FAILED = 'FAILED',
        ABORTED = 'ABORTED'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.ModelTrainInfo$SearchParam
     */
    export interface SearchParam {
        cnt: number;
        id: string;
        max: string;
        min: string;
        name: string;
        val: string;
        vals: string[];
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.ModelTrainInfo$TrainProgress
     */
    export interface TrainProgress {
        stack: dep_dku_frontend_core.FutureProgressState[];
        top_level_done: ModelTrainInfo.TrainProgress.TopLevelDone[];
        top_level_todo: string[];
    }
}

export namespace ModelTrainInfo.TrainProgress {
    /**
     * Generated from com.dataiku.dip.analysis.model.ModelTrainInfo$TrainProgress$TopLevelDone
     */
    export interface TopLevelDone {
        name: string;
        time: number;
    }
}