import { Pipe, PipeTransform } from '@angular/core';
import { Dict } from 'dku-frontend-core';

@Pipe({
    name: 'escapeHtml'
})
export class EscapeHtmlPipe implements PipeTransform {

    private chars = /[<>&'"`]/g;
    private map: Dict<string> = {
        '<': '&lt;',
        '>': '&gt;',
        '&': '&amp;',
        '"': '&quot;',
        "'": '&apos;',
        "`": '&#96;',
    };

    transform(s: string): string {
        return s.replace(this.chars, x => this.map[x]);
    }

}
