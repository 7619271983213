
<div class="dku-grand-title">
    Popular Datasets
    <i class="dku-icon-question-circle-fill-16 cursor-pointer"
        data-placement="right"
        [dkuMdPopover]="popularDatasetsHelpMessage"
        dkuMdTitle="Popular datasets">
    </i>
</div>
<api-error-alert-from-context></api-error-alert-from-context>
<div *ngIf="popularDatasetsListIsEmpty() && !isLoading" class="popular-datasets__empty-table">
    <img src="/static/dataiku/images/data-catalog/popular-datasets.svg">
    <h2 *ngIf="hasLastResult" class="dku-large-title popular-datasets__empty-title">No popular datasets available</h2>
    <h2 *ngIf="!hasLastResult" class="dku-large-title popular-datasets__empty-title">Popular datasets have not been computed on this instance yet</h2>
    <button class="btn btn--primary btn--dku-icon" *ngIf="!hasLastResult || $rootScope.isDSSAdmin()" (click)="compute()">
        Compute
    </button>
</div>
<table mat-table matSortDisableClear matSortActive="score" [dataSource]="dataSource" matSort [hidden]="popularDatasetsListIsEmpty() || isLoading">

    <!-- Icon -->
    <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item"><ng-container *ngIf="isPopularDataset(item)">
            <i class="icon universe-color dataset {{ item.datasetType | typeToIcon | toModernIcon:24 }}" [class.golden-dataset-icon]="golden"></i>
        </ng-container></td>
    </ng-container>

    <!-- Item name -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Name
            <i *ngIf="sort && sort.active === 'name'" [class]="'dku-icon-chevron-' + (sort.direction === 'asc' ? 'up' : 'down') + '-16'"></i>
        </th>
        <td mat-cell *matCellDef="let item"><div *ngIf="isPopularDataset(item)" [matTooltip]="item.name" isEllipsisActive>
            <a [href]="$rootScope.StateUtils.href.dataset(item.name, item.projectKey)" class="link-std">
                {{item.name}}
            </a>
        </div></td>
    </ng-container>

    <!-- Project name -->
    <ng-container matColumnDef="projectName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Project
            <i *ngIf="sort && sort.active === 'projectName'" [class]="'dku-icon-chevron-' + (sort.direction === 'asc' ? 'up' : 'down') + '-16'"></i>
        </th>
        <td mat-cell *matCellDef="let item"><div *ngIf="isPopularDataset(item)" [matTooltip]="item.projectName" isEllipsisActive>
            {{item.projectName}}
        </div></td>
    </ng-container>

    <!-- Number of projects-->
    <ng-container matColumnDef="projects">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            # Projects
            <i *ngIf="sort && sort.active === 'projects'" [class]="'dku-icon-chevron-' + (sort.direction === 'asc' ? 'up' : 'down') + '-16'"></i>
        </th>
        <td mat-cell *matCellDef="let item">
            <ng-container *ngIf="isDatasetReadable(item)">
                {{item.numberOfProjects}}
            </ng-container>
            <div *ngIf="isDatasetDiscoverable(item)" matTooltip="You are not allowed to see this information">
                -
            </div>
        </td>
    </ng-container>

    <!-- Number of columns-->
    <ng-container matColumnDef="columns">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            # Columns
            <i *ngIf="sort && sort.active === 'columns'" [class]="'dku-icon-chevron-' + (sort.direction === 'asc' ? 'up' : 'down') + '-16'"></i>
        </th>
        <td mat-cell *matCellDef="let item">
            <ng-container *ngIf="isDatasetReadable(item)">
                {{item.numberOfColumns}}
            </ng-container>
            <div *ngIf="isDatasetDiscoverable(item)" matTooltip="You are not allowed to see this information">
                -
            </div>
        </td>
    </ng-container>

    <!-- Number of data collections-->
    <ng-container matColumnDef="dataCollections">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            # Data Collections
            <i *ngIf="sort && sort.active === 'dataCollections'" [class]="'dku-icon-chevron-' + (sort.direction === 'asc' ? 'up' : 'down') + '-16'"></i>
        </th>
        <td mat-cell *matCellDef="let item">
            <ng-container *ngIf="isDatasetReadable(item)">
                {{item.numberOfDataCollections}}
            </ng-container>
            <div *ngIf="isDatasetDiscoverable(item)" matTooltip="You are not allowed to see this information">
                -
            </div>
        </td>
    </ng-container>

    <!-- Last build -->
    <ng-container matColumnDef="lastBuild">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Last build
            <i *ngIf="sort && sort.active === 'lastBuild'" [class]="'dku-icon-chevron-' + (sort.direction === 'asc' ? 'up' : 'down') + '-16'"></i>
        </th>
        <td mat-cell *matCellDef="let item">
            <span *ngIf="isDatasetReadable(item) && item.lastBuiltOn && item.lastBuiltOn !== -1" [matTooltip]="item.lastBuiltOn | friendlyDateTime">
                {{item.lastBuiltOn | friendlyTimeDeltaShort }}
            </span>
            <div *ngIf="isDatasetReadable(item) && (!item.lastBuiltOn || item.lastBuiltOn === -1)" matTooltip="No last build information">
                -
            </div>
            <div *ngIf="isDatasetDiscoverable(item)" matTooltip="You are not allowed to see this information">
                -
            </div>
        </td>
    </ng-container>

    <!-- score -->
    <ng-container matColumnDef="score">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Score
            <i *ngIf="sort && sort.active === 'score'" [class]="'dku-icon-chevron-' + (sort.direction === 'asc' ? 'up' : 'down') + '-16'"></i>
        </th>
        <td mat-cell *matCellDef="let item"><ng-container *ngIf="isPopularDataset(item)">
            {{ (item.score * 100).toFixed(0) }}
        </ng-container></td>
    </ng-container>

    <!-- Description -->
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>
            Description
        </th>
        <td mat-cell *matCellDef="let item"><div *ngIf="isPopularDataset(item)" [matTooltip]="item.shortDesc" isEllipsisActive>
            {{item.shortDesc }}
        </div></td>
    </ng-container>

    <!-- Trending status -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item">
            <div class="popular-datasets__action-icons-cell" *ngIf="isDatasetReadable(item)">
                <right-panel-action-publish-btn
                    *ngIf="getPublishability(item) as publishability"

                    [showShareToDashboard]="publishability.dashboard.show"
                    [disableShareToDashboard]="!publishability.dashboard.enabled"
                    [dashboardTooltip]="publishability.dashboard.reason"
                    (shareToDashboard)="shareToDashboard(item)"

                    [showShareToWorkspace]="publishability.workspace.show"
                    [disableShareToWorkspace]="!publishability.workspace.enabled"
                    [workspaceTooltip]="publishability.workspace.reason"
                    (shareToWorkspace)="shareToWorkspace(item)"

                    [showAddToFeatureStore]="publishability.featureStore.show"
                    [disableAddToFeatureStore]="!publishability.featureStore.enabled"
                    [featureStoreTooltip]="publishability.featureStore.reason"
                    (addToFeatureStore)="addToFeatureStore(item)"

                    [showAddToDataCollection]="publishability.dataCollection.show"
                    [disableAddToDataCollection]="!publishability.dataCollection.enabled"
                    [collectionTooltip]="publishability.dataCollection.reason"
                    (addToDataCollection)="addToDataCollection(item)"
                >
                </right-panel-action-publish-btn>

                <right-panel-action icon="dku-icon-search-24" (click)="preview(item)">
                    Preview
                </right-panel-action>

                <right-panel-action
                    *ngIf="getShareability(item) as shareability"
                    [icon]="shareability.shareability === 'request' ? 'dku-icon-lock-closed-24' : 'dku-icon-share-24'"
                    [disabled]="shareability.shareability === 'nothing'"
                    [disabledTooltip]="shareability.reason"
                    (click)="shareButtonAction(item, shareability.shareability)"
                > {{ shareability.shareability === 'request' ? "Request" : "Use"}} </right-panel-action>
            </div>

            <div class="popular-datasets__action-icons-cell" *ngIf="isDatasetDiscoverable(item)">
                <right-panel-action
                    icon="dku-icon-publish-24"
                    [disabled]="true"
                    disabledTooltip="You don't have the permission to publish this dataset"
                > Publish <span class="caret disabled"></span> </right-panel-action>
                <right-panel-action
                    icon="dku-icon-search-24"
                    [disabled]="true"
                    disabledTooltip="You don't have the permission to preview this dataset"
                > Preview </right-panel-action>

                <right-panel-action
                    *ngIf="getShareability(item) as shareability"
                    [icon]="shareability.shareability === 'request' ? 'dku-icon-lock-closed-24' : 'dku-icon-share-24'"
                    [disabled]="shareability.shareability === 'nothing'"
                    [disabledTooltip]="shareability.reason"
                    (click)="shareButtonAction(item, shareability.shareability)"
                > {{ shareability.shareability === 'request' ? "Request" : "Use"}} </right-panel-action>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let item; columns: displayedColumns;"></tr>

</table>
