import { Inject, Injectable } from '@angular/core';
import { PivotTableTensorResponse } from '@model-main/pivot/backend/model/pivot-table-tensor-response';
import { ChartType } from '@model-main/pivot/frontend/model/chart-type';
import { EChartsOption, YAXisComponentOption, init } from 'echarts';
import { ChartLabels } from '../../enums';
import { ChartBase, FrontendChartDef } from '../../interfaces';
import { ChartTensorDataWrapper } from '../../models';
import { ChartFormattingService } from '../../services';
import { EChartDef } from './models';
import { EChartsInstanceManagerService } from './echarts-instance-manager.service';
import { EChartsTooltipsService } from './echarts-tooltips.service';
import { EChartsLegendsService } from './echarts-legends.service';
import { ChartDirectiveScope, ChartColorContext, EChartScope } from './interfaces';

@Injectable({
    providedIn: 'root'
})
export class EChartsManagerService {
    constructor(
        @Inject('Logger') private logger: any,
        @Inject('ChartStoreFactory') private chartStoreFactoryService: any,
        @Inject('ChartFeatures') private chartFeaturesService: any,
        @Inject('ChartColorScales') private chartColorScalesService: any,
        @Inject('ChartLegendUtils') private chartLegendUtilsService: any,
        @Inject('AnimatedChartsUtils') private animatedChartsUtilsService: any,
        @Inject('ActivityIndicatorManager') private activityIndicatorManagerService: any,
        private chartFormattingService: ChartFormattingService,
        private echartsTooltipsService: EChartsTooltipsService,
        private echartsLegendsService: EChartsLegendsService,
        private echartsInstanceManager: EChartsInstanceManagerService
    ) { }

    private hasMeta(hasFacets: boolean, hasSingleXAxis: boolean, type: ChartType): boolean {
        return hasFacets && hasSingleXAxis && this.chartFeaturesService.canDisplayAxes(type);
    }

    /**
     * Get scroll options on a solo chart if the height of y axis ticks
     * is longer than the default chart height (100%)
     */
    private getScrollOptions(options: EChartsOption) {
        //  Subcharts, we don't do anything
        if (Array.isArray(options.grid) || !options.yAxis) {
            return;
        } else {
            const top = options.grid?.top as number || 0;
            const bottom = options.grid?.bottom as number || 0;

            let result: { yAxes: Array<YAXisComponentOption>, minChartHeight: number } = {
                yAxes: [],
                minChartHeight: -1
            };

            result = (options.yAxis as Array<YAXisComponentOption>).reduce(
                (acc, yAxis) => {
                    if (yAxis.type === 'category') {
                        //  Reset interval as we provide enough space to display all ticks
                        (yAxis as any).axisLabel.interval = 0;

                        const defaultFontSize = 12;
                        const defaultGap = 6;
                        const mandatoryTicksNb = (yAxis as any).data.length;
                        const minChartHeight = mandatoryTicksNb * (defaultFontSize + defaultGap) + top + bottom;
                        acc.minChartHeight = Math.max(acc.minChartHeight, minChartHeight);
                    }

                    acc.yAxes.push(yAxis);
                    return acc;
                },
                result
            );

            options.yAxis = result.yAxes;

            return { options, minChartHeight: result.minChartHeight };
        }
    }

    private drawFrame(
        scope: any,
        chartDef: FrontendChartDef,
        chartData: ChartTensorDataWrapper,
        chartBase: ChartBase,
        echartDef: EChartDef,
        frameIndex = 0
    ) {
        const facets = chartData.getFacets();

        const result = this.echartsInstanceManager.draw(chartDef.$chartStoreId, chartDef, chartData, chartBase, echartDef, scope.noXAxis, scope.noYAxis, scope.legends, frameIndex, facets);

        if (this.chartFeaturesService.canDisplayAxes(chartDef.type)) {
            /** Used to scroll horizontally when the number of ticks overflows 100% of the chart height */
            const scrollOptions = this.getScrollOptions(result.options);
            if (scrollOptions) {
                if (scrollOptions.minChartHeight > 0) {
                    scope.minChartHeight = `${scrollOptions.minChartHeight}px`;
                } else {
                    scope.minChartHeight = '100%';
                }

                result.options = scrollOptions.options;
            }
        }

        result.options.tooltip = this.echartsTooltipsService.getTooltipOptions();
        result.options.backgroundColor = '#fff';

        if (result.meta) {
            if (scope.echartMeta && scope.echartMeta.echartInstance) {
                scope.echartMeta.height = result.meta.height;
                scope.echartMeta.echartInstance.setOption(result.meta.options, true, true);
            } else {
                //  If not already defined, we let the component handle the first draw
                Object.assign(scope.echartMeta, result.meta);
            }
        }

        scope.echart.echartDefInstance = echartDef;

        if (scope.echart && scope.echart.echartInstance) {
            scope.echart.afterChange = echartDef.afterChange;
            /*
             *  Updating the chart by setting options manually and calling back onChartInit
             *  We don't update "echart" object anymore to bypass the component
             */
            const updatedEchart = Object.assign({}, scope.echart, result);
            scope.echart.echartInstance.setOption(result.options, true, true);
            scope.onChartInit(scope.echart.echartInstance, updatedEchart);
        } else {
            //  If not already defined, we let the component handle the first draw
            Object.assign(scope.echart, result);
        }
    }

    private getIgnoreLabels(chartType: ChartType) {
        if (chartType === ChartType.treemap) {
            return new Set([ChartLabels.SUBTOTAL_BIN_LABEL]);
        }
        return new Set<string>();
    }

    initEcharts(
        scope: any,
        element: any,
        data: PivotTableTensorResponse,
        axesDef: Record<string, any>,
        echartDef: EChartDef,
        chartActivityIndicator: any,
        hideLegend: boolean
    ) {
        // Display the chart
        const $container = element.find('.pivot-charts').css('display', '');
        const chartDef = scope.chart.def;

        const chartStoreMeta = this.chartStoreFactoryService.getOrCreate(chartDef.$chartStoreId);
        chartDef.$chartStoreId = chartStoreMeta.id;

        //  Mandatory to build chart data
        echartDef.onInit(chartDef, data, axesDef);

        const chartData = echartDef.chartData;

        if (!chartData) {
            throw new Error('chartData should be initialized in echartDef\'s onInit call');
        }

        //  Builds color spec
        const colorSpec = echartDef.getColorSpec(chartDef);
        const colorContext: ChartColorContext = {
            chartData: chartData,
            colorOptions: chartDef.colorOptions,
            genericMeasures: chartDef.genericMeasures,
            colorSpec,
            chartHandler: scope,
            ignoreLabels: this.getIgnoreLabels(chartDef.type)
        };
        const colorScale = this.chartColorScalesService.createColorScale(colorContext);

        scope.chartActivityIndicator = chartActivityIndicator;

        if (!this.hasMeta(echartDef.hasFacets, chartDef.singleXAxis, chartDef.type)) {
            if (scope.echartMeta && scope.echartMeta.echartInstance) {
                scope.echartMeta.echartInstance.dispose();
            }

            scope.echartMeta = {};
        }

        if (!scope.echart) {
            scope.echart = {};
            scope.echartMeta = {};
        } else if (scope.echart.echartInstance) {
            // If there's already an echart instance, we need to off all events previously there to bind them again with recent information.
            this.echartsInstanceManager.clearEvents(scope.echart.echartInstance);
            setTimeout(() => {
                this.echartsTooltipsService.hideTooltip(scope.tooltips);
            });
        }

        this.chartLegendUtilsService.createLegend($container, chartDef, chartData, scope, colorSpec, colorScale, hideLegend, this.getIgnoreLabels(chartDef.type)).then(() => {
            try {
                // Initial margins
                const width = $container.find('.main-echarts-zone').width();
                const height = $container.find('.main-echarts-zone').height();
                const margins = { top: 25, bottom: 10, left: 10, right: 10 };

                const measureFormatters = this.chartFormattingService.createMeasureFormatters(chartDef, chartData, Math.max(width, height));

                //  Tooltips creation
                scope.tooltips = this.echartsTooltipsService.createTooltips($container, scope, chartData, chartDef, measureFormatters, colorScale);

                const chartBase: ChartBase = {
                    width,
                    height,
                    margins,
                    colorScale
                };

                if (chartData.hasAnimations()) {
                    this.animatedChartsUtilsService.initAnimation(scope, chartData, chartDef, (frameIndex: number) => {
                        scope.tooltips.setAnimationFrame(frameIndex);
                        this.drawFrame(scope, chartDef, chartData, chartBase, echartDef, frameIndex);
                    });
                    scope.animation.drawFrame(scope.animation.currentFrame || 0);
                    if (scope.autoPlayAnimation) {
                        scope.animation.play();
                    }
                } else {
                    this.animatedChartsUtilsService.unregisterAnimation(scope);
                    this.drawFrame(scope, chartDef, chartData, chartBase, echartDef);
                }
            } catch (err: any) {
                // To replace with err instanceof ChartIAE when all chart services throwing the erorr are migrated to TS
                if (err.name === 'ChartIAE') {
                    this.logger({ serviceName: 'EChartsManager', objectName: 'Service' }).warn('CHART IAE', err);
                    if (scope.validity) {
                        scope.validity.valid = false;
                        scope.validity.type = 'DRAW_ERROR';
                        scope.validity.message = err.message;
                    }
                } else {
                    throw err;
                }
            }
        });
    }

    disposeEcharts(scope: any) {
        if (scope.echart && scope.echart.echartInstance) {
            scope.echart.echartInstance.dispose();
        }

        if (scope.echartMeta && scope.echartMeta.echartInstance) {
            scope.echartMeta.echartInstance.dispose();
        }

        scope.echart = null;
        scope.echartMeta = null;
    }

    onInit(scope: ChartDirectiveScope, element: any) {
        return ($event: any, echart: EChartScope) => {
            echart.echartInstance = $event;
            scope.echart.options = echart.options;

            if (scope.echart.echartDefInstance.afterChange) {
                scope.echart.echartDefInstance.afterChange(scope.echart.echartInstance);
            }

            const validity = scope.echart.echartDefInstance.checkDataValidity();
            if (validity && !validity.valid) {
                this.activityIndicatorManagerService.configureActivityIndicator(scope.chartActivityIndicator, validity.type, validity.message, 5000, false);
            }

            //  Tooltips back-up
            if (!scope.noTooltips) {
                //  @TODO : setup tooltips in dedicated echartDef to avoid switch case on chart type
                const coordinates = echart.allCoords;
                const legend = scope.legends[0];
                this.echartsTooltipsService.setupTooltips($event, scope.tooltips, coordinates, legend, scope.echart.echartDefInstance.tooltipIgnoredSeries);
            }

            //  Legends setup
            if (scope.echart && scope.echart.echartInstance) {
                const updatedLegends = this.echartsLegendsService.setupLegends(scope.echart.echartInstance, scope.chart.def.colorOptions.transparency, scope.legends, scope.echart.echartDefInstance.onLegendHover);
                //  We reassign so that the color menu still references the same legends
                scope.legends = Object.assign(scope.legends, updatedLegends);
            }

            //  Adjust legends when in chart
            const $legendZone = element.find('.legend-zone').show();
            let grid;

            if (this.chartFeaturesService.canHaveZoomControls(scope.chart.def.type) && scope.echart.options.grid && !Array.isArray(scope.echart.options.grid)) {
                grid = [{
                    top: scope.echart.options.grid.top,
                    bottom: scope.echart.options.grid.bottom,
                    left: scope.echart.options.grid.left,
                    right: scope.echart.options.grid.right
                }];
                const legendsHeight = $legendZone.outerHeight();
                const chartHeight = element.height();
                const zoomPanelHeight = element.find('.zoom-control-panel').outerHeight();
                // Avoid collision with zoom controls
                switch (scope.chart.def.legendPlacement) {
                    case 'INNER_TOP_LEFT':
                        grid[0].top = 3;
                        grid[0].left = 45;
                        break;
                    case 'INNER_BOTTOM_LEFT':
                        // checking if the legend will overlap with the zoom controls
                        if (chartHeight - zoomPanelHeight <= legendsHeight) {
                            grid[0].left = 45;
                        }
                        break;
                }
            }

            this.echartsLegendsService.adjustLegends($legendZone, scope.chart.def.legendPlacement, grid || scope.echart.options.grid || []);

            //  Adjust meta zone width if chart is scrollable (in the future, replace in CSS with https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-gutter)
            const $metaZone = element.find('.chart-meta');
            if ($metaZone.siblings().first().height() < scope.echart.echartDefInstance.chartHeight) {
                $metaZone.addClass('overflow');
            } else {
                $metaZone.addClass('overflow');
            }

            $event.on('finished', () => {
                if (typeof scope.loadedCallback === 'function') {
                    scope.loadedCallback();
                }
            });
        };
    }

    onMetaInit() {
        return ($event: any, echart: any) => {
            echart.echartInstance = $event;
        };
    }

    getThumbnailCanvasUrl(options: EChartsOption, width: number, height: number): string {
        const container = document.createElement('div');
        container.style.width = width + 'px';
        container.style.height = height + 'px';
        const myChart = init(container);

        options && myChart.setOption(options);

        const canvas = container.querySelector('canvas');
        const canvasUrl = canvas?.toDataURL() || '';

        myChart.dispose();
        return canvasUrl;
    }
}
