import { Pipe, PipeTransform } from '@angular/core';
import { now, durationHHMMSS } from '@shared/pipes/date-pipes/date-pipes-common';

@Pipe({
    name: 'friendlyTimeDeltaHHMMSS'
})
export class FriendlyTimeDeltaHHMMSSPipe implements PipeTransform {

    transform(input: string | number, reference?: number): string {
        reference = (reference == undefined || reference == 0) ? now : reference;
        if (typeof input == 'string') {
            input = parseInt(input, 10);
        }
        let diffInSeconds = (reference - input) / 1000;
        return durationHHMMSS(diffInSeconds);
    }

}