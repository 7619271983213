import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PainterService } from '@shared/services/item-feed/image-feed/painter.service';
import { LabelingAnnotateTextService } from '../labeling-task-annotate/services/labeling-annotate-text.service';
import { LabelingTaskPrivileges } from '../labeling.component';
import { ColorMeaning, LabelingColorService } from '../services/labeling-color.service';
import { LabelingService } from '../services/labeling.service';
import { LabelingReviewAnnotationGroupService } from './services/labeling-review-annotation-group.service';
import { LabelingReviewImagePainterService } from './services/labeling-review-image-painter.service';
import { LabelingReviewUpdateService } from './services/labeling-review-update.service';
import { LabelingValidateService } from './services/labeling-validate.service';

@UntilDestroy()
@Component({
    selector: 'labeling-task-review',
    templateUrl: './labeling-task-review.component.html',
    styleUrls: ['./labeling-task-review.component.less'],
    providers: [
        LabelingReviewAnnotationGroupService,
        LabelingValidateService,
        LabelingReviewUpdateService,
        LabelingAnnotateTextService,
        LabelingReviewImagePainterService,
        { provide: PainterService, useExisting: LabelingReviewImagePainterService },
    ]
})
export class LabelingTaskReviewComponent implements OnInit {
    @Input() privilegesOnTask: LabelingTaskPrivileges;

    constructor(
        private labelingColorService: LabelingColorService,
        private labelingService: LabelingService
    ) {}

    ngOnInit() {
        // get annotator ids and associate color to each
        this.labelingService.listAnnotatorsIds().pipe(
            untilDestroyed(this)
        ).subscribe((annotatorIds) => {
            this.labelingColorService.setColorOptions(ColorMeaning.ANNOTATOR, annotatorIds);
        });
    }
}
