import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-viewer',
  template: '<app-page></app-page>',
  styleUrls: ['./app-viewer.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppViewerComponent {
  //  Wrapper for app-page, which is an upgraded component from angularJS, should be migrated
}
