import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'right-panel-actions-container',
  templateUrl: './right-panel-actions-container.component.html',
  styleUrls: ['./right-panel-actions-container.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightPanelActionsContainerComponent {
  constructor() { }

}
