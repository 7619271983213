import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { getItemName } from '@features/labeling/utils';
import { LabelingTask } from 'generated-sources';

@Component({
    selector: 'review-no-annotations-placeholder',
    templateUrl: './review-no-annotations-placeholder.component.html',
    styleUrls: ['./review-no-annotations-placeholder.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewNoAnnotationsPlaceholderComponent implements OnChanges {

    @Input() taskType: LabelingTask.LabelingTaskType;
    @Input() rejected: boolean;

    message: {title: string, body: string} | null = null;
    
    ngOnChanges(): void {
        if (this.taskType != null && this.rejected != null) {
            this.message = this.getAnnotationsMessage();
        }
    }

    getAnnotationsMessage(): {title: string, body: string} | null {
        if (this.rejected) {
            return {title: "Annotations rejected", body: "Annotations have been rejected. This "+ getItemName(this.taskType) + " will be available again for annotators to label."};
        } else {
            if (this.taskType === LabelingTask.LabelingTaskType.OBJECT_DETECTION) {
                return {title: "No labels", body: "Draw a box around the target and choose a category"};
            } else if (this.taskType === LabelingTask.LabelingTaskType.NAMED_ENTITY_EXTRACTION) {
                return {title: "No labels", body: "Highlight some text and choose a category"};
            }
            // No messages for image classification because there is always a dropdown to select a category
        }
        return null;
    }

}
