import { ProposedCard } from './worksheet/card-wizard/proposed-card/proposed-card.component';
import { AutomagicSnifferComponent } from './worksheet/card-wizard/automagic-sniffer/automagic-sniffer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { NgxEchartsModule } from 'ngx-echarts';

import { EdaComponent } from '@features/eda/eda.component';
import { ModalsModule } from '@shared/modals/modals.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { DataikuWT1Module } from 'dku-frontend-core';
import { WorksheetComponent } from './worksheet/worksheet.component';
import { ColumnCardBodyComponent } from './worksheet/cards/body/column-card-body/column-card-body.component';
import { HeaderCardBodyComponent } from './worksheet/cards/body/header-card-body/header-card-body.component';
import { CategoricalHistogramCardBodyComponent } from './worksheet/cards/body/categorical-histogram-card-body/categorical-histogram-card-body.component';
import { GenericSummaryStatsCardBodyComponent } from './worksheet/cards/body/generic-summary-stats-card-body/generic-summary-stats-card-body.component';
import { QuantilesTableCardBodyComponent } from './worksheet/cards/body/quantiles-table-card-body/quantiles-table-card-body.component';
import { NumericalHistogramCardBodyComponent } from './worksheet/cards/body/numerical-histogram-card-body/numerical-histogram-card-body.component';
import { UnivariateFrequencyTableCardBodyComponent } from './worksheet/cards/body/univariate-frequency-table-card-body/univariate-frequency-table-card-body.component';
import { BivariateHistogramCardBodyComponent } from './worksheet/cards/body/bivariate-histogram-card-body/bivariate-histogram-card-body.component';
import { Fit2DDistributionCardBodyComponent } from './worksheet/cards/body/fit-2d-distribution-card-body/fit-2d-distribution-card-body.component';
import { TopLevelCardComponent } from './worksheet/cards/top-level-card/top-level-card.component';
import { WorksheetRootCardComponent } from './worksheet/cards/worksheet-root-card/worksheet-root-card.component';
import { CardBodyComponent } from './worksheet/cards/card-body/card-body.component';
import { GroupedCardBodyComponent } from './worksheet/cards/body/grouped-card-body/grouped-card-body.component';
import { GroupedHeaderCardBodyComponent } from './worksheet/cards/body/grouped-header-card-body/grouped-header-card-body.component';
import { ColumnTitleComponent } from './worksheet/cards/column-title/column-title.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { DkuMatTooltipModule } from '@shared/components/dku-mat-tooltip/dku-mat-tooltip.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { CardMenuComponent } from './worksheet/cards/card-menu/card-menu.component';
import { FitDistributionCardBodyComponent } from './worksheet/cards/body/fit-distribution-card-body/fit-distribution-card-body.component';
import { FitCurveCardBodyComponent } from './worksheet/cards/body/fit-curve-card-body/fit-curve-card-body.component';
import { CorrelationMatrixCardBodyComponent } from './worksheet/cards/body/correlation-matrix-card-body/correlation-matrix-card-body.component';
import { InViewportModule } from 'ng-in-viewport';
import { ComponentsModule } from '@shared/components/components.module';
import { NewCardModalComponent } from './worksheet/card-wizard/new-card-modal/new-card-modal.component';
import { KsTest2SampCardBodyComponent } from './worksheet/cards/body/ks-test-2samp-card-body/ks-test-2samp-card-body.component';
import { GenericPairwiseTestCardBodyComponent } from './worksheet/cards/body/generic-pairwise-test-card-body/generic-pairwise-test-card-body.component';
import { FilterNamePipe } from './pipes/filter-name.pipe';
import { UnavailableCardBodyComponent } from './worksheet/cards/body/unavailable-card-body/unavailable-card-body.component';
import { PCACardBodyComponent } from './worksheet/cards/body/pca-card-body/pca-card-body.component';
import { BivariateFrequencyTableCardBodyComponent } from './worksheet/cards/body/bivariate-frequency-table-card-body/bivariate-frequency-table-card-body.component';
import { BivariateBoxPlotCardBodyComponent } from './worksheet/cards/body/bivariate-box-plot-card-body/bivariate-box-plot-card-body.component';
import { MosaicPlotCardBodyComponent } from './worksheet/cards/body/mosaic-plot-card-body/mosaic-plot-card-body.component';
import { ScatterPlotCardBodyComponent } from './worksheet/cards/body/scatter-plot-card-body/scatter-plot-card-body.component';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { GenericNSampTestCardBodyComponent } from './worksheet/cards/body/generic-nsamp-test-card-body/generic-nsamp-test-card-body.component';
import { ShapiroNormalityTestCardBodyComponent } from './worksheet/cards/body/shapiro-normality-test-card-body/shapiro-normality-test-card-body.component';
import { Chi2IndTestCardBodyComponent } from './worksheet/cards/body/chi2-ind-test-card-body/chi2-ind-test-card-body.component';
import { TZTest1SampCardBodyComponent } from './worksheet/cards/body/tztest1-samp-card-body/tztest1-samp-card-body.component';
import { SignTest1SampCardBodyComponent } from './worksheet/cards/body/sign-test1-samp-card-body/sign-test1-samp-card-body.component';
import { EditConfidenceLevelSettingsComponent } from './worksheet/edit-confidence-level-settings/edit-confidence-level-settings.component';
import { SampleSettingsComponent } from './worksheet/sample-settings/sample-settings.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeightEqualizerComponent } from '@shared/directives/height-equalizer/height-equalizer.component';
import { SplitBySettingsComponent } from './worksheet/split-by-settings/split-by-settings.component';
import { DebugCardModalComponent } from './worksheet/debug-card-modal/debug-card-modal.component';
import { DeleteDialogComponent } from './worksheet/delete-dialog/delete-dialog.component';
import { InsightCardComponent } from './worksheet/cards/insight-card/insight-card.component';
import { CardEditorComponent } from './worksheet/card-wizard/card-editor/card-editor.component';
import { DistributionNamePipe } from './pipes/distribution-name.pipe';
import { CardHelpComponent } from './worksheet/cards/card-help/card-help.component';
import { TZTest1SampCardHelpComponent } from './worksheet/cards/help/tztest1-samp-card-help/tztest1-samp-card-help.component';
import { ShapiroNormalityTestCardHelpComponent } from './worksheet/cards/help/shapiro-normality-test-card-help/shapiro-normality-test-card-help.component';
import { KsTest2SampCardHelpComponent } from './worksheet/cards/help/ks-test2-samp-card-help/ks-test2-samp-card-help.component';
import { SignTest1SampCardHelpComponent } from './worksheet/cards/help/sign-test1-samp-card-help/sign-test1-samp-card-help.component';
import { Chi2IndTestCardHelpComponent } from './worksheet/cards/help/chi2-ind-test-card-help/chi2-ind-test-card-help.component';
import { GenericSummaryStatsCardConfigComponent } from './worksheet/cards/config/generic-summary-stats-card-config/generic-summary-stats-card-config.component';
import { EditCardModalComponent } from './worksheet/card-wizard/edit-card-modal/edit-card-modal.component';
import { UnivariateFrequencyTableCardConfigComponent } from './worksheet/cards/config/univariate-frequency-table-card-config/univariate-frequency-table-card-config.component'
import { CategoricalHistogramCardConfigComponent } from './worksheet/cards/config/categorical-histogram-card-config/categorical-histogram-card-config.component';
import { HeatmapConfigComponent } from './worksheet/cards/config/heatmap-config/heatmap-config.component';
import { QuantilesTableCardConfigComponent } from './worksheet/cards/config/quantiles-table-card-config/quantiles-table-card-config.component';
import { NumericalHistogramCardConfigComponent } from './worksheet/cards/config/numerical-histogram-card-config/numerical-histogram-card-config.component';
import { Abstract2DPivotCardConfigComponent } from './worksheet/cards/config/abstract-2d-pivot-card-config/abstract-2d-pivot-card-config.component';
import { BivariateBoxPlotCardConfigComponent } from './worksheet/cards/config/bivariate-box-plot-card-config/bivariate-box-plot-card-config.component';
import { RenameWorksheetDialogComponent } from './worksheet/rename-worksheet-dialog/rename-worksheet-dialog.component';
import { ScatterPlotCardConfigComponent } from './worksheet/cards/config/scatter-plot-card-config/scatter-plot-card-config.component';
import { UnivariateHeaderCardConfigComponent } from './worksheet/cards/config/univariate-header-card-config/univariate-header-card-config.component';
import { BivariateHeaderCardConfigComponent } from './worksheet/cards/config/bivariate-header-card-config/bivariate-header-card-config.component';
import { MultivariateCardConfigComponent } from './worksheet/cards/config/multivariate-card-config/multivariate-card-config.component';
import { ScatterPlot3DCardConfigComponent } from './worksheet/cards/config/scatter-plot-3d-card-config/scatter-plot-3d-card-config.component';
import { TZTest1SampCardConfigComponent } from './worksheet/cards/config/tztest1-samp-card-config/tztest1-samp-card-config.component';
import { SignTest1SampCardConfigComponent } from './worksheet/cards/config/sign-test1-samp-card-config/sign-test1-samp-card-config.component';
import { ShapiroNormalityTestCardConfigComponent } from './worksheet/cards/config/shapiro-normality-test-card-config/shapiro-normality-test-card-config.component';
import { Abstract2SampCardConfigComponent } from './worksheet/cards/config/abstract-2-samp-card-config/abstract-2-samp-card-config.component';
import { AbstractNSampCardConfigComponent } from './worksheet/cards/config/abstract-n-samp-card-config/abstract-n-samp-card-config.component';
import { FitDistributionCardConfigComponent } from './worksheet/cards/config/fit-distribution-card-config/fit-distribution-card-config.component';
import { FitCurveCardConfigComponent } from './worksheet/cards/config/fit-curve-card-config/fit-curve-card-config.component';
import { Fit2DDistributionCardConfigComponent } from './worksheet/cards/config/fit-2d-distribution-card-config/fit-2d-distribution-card-config.component';
import { AbstractPairwiseTestCardConfigComponent } from './worksheet/cards/config/abstract-pairwise-test-card-config/abstract-pairwise-test-card-config.component';
import { ListBoxComponent } from './worksheet/card-wizard/list-box/list-box.component';
import { ListBoxFormControlComponent } from './worksheet/card-wizard/list-box-form-control/list-box-form-control.component';
import { VariableSelectorComponent } from './worksheet/card-wizard/variable-selector/variable-selector.component';
import { Chi2IndTestCardConfigComponent } from './worksheet/cards/config/chi2-ind-test-card-config/chi2-ind-test-card-config.component';
import { GenericNSampMoodTestHelpComponent } from './worksheet/cards/help/generic-nsamp-mood-test-help/generic-nsamp-mood-test-help.component';
import { OneWayANOVACardHelpComponent } from './worksheet/cards/help/one-way-anova-card-help/one-way-anova-card-help.component';
import { GenericNSampTTestCardHelpComponent } from './worksheet/cards/help/generic-nsamp-ttest-card-help/generic-nsamp-ttest-card-help.component';
import { ListComparedPopulationsComponent } from './worksheet/cards/fragments/list-compared-populations/list-compared-populations.component';
import { ColoredPValueComponent } from './worksheet/cards/fragments/colored-pvalue/colored-pvalue.component';
import { CardTitlePipe } from './pipes/card-title.pipe';
import { DataikuAPIModule } from '@core/dataiku-api/dataiku-api.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PartitionSelectionComponent } from './worksheet/partition-selection/partition-selection.component';
import { DuplicateWorksheetDialogComponent } from './worksheet/duplicate-worksheet-dialog/duplicate-worksheet-dialog.component';
import { BinningConfigComponent } from './worksheet/cards/config/binning-config/binning-config.component';
import { ContainerSettingsComponent } from './worksheet/container-settings/container-settings.component';
import { ValuesListModule } from '@app/widgets/lists/values-list/values-list.module';
import { EditableListModule } from '@app/widgets/lists/editable-list/editable-list.module';
import { DkuFrontendCoreModule } from 'dku-frontend-core';
import { HighlightSettingsComponent } from './worksheet/highlight-settings/highlight-settings.component';
import { WorksheetRedirectionPageComponent } from './pages/worksheet-redirection-page/worksheet-redirection-page.component';
import { ScatterPlot3DCardBodyComponent } from './worksheet/cards/body/scatter-plot-3d-card-body/scatter-plot-3d-card-body.component';
import { CdfPlotCardBodyComponent } from './worksheet/cards/body/cdf-plot-card-body/cdf-plot-card-body.component';
import { ParallelCoordinatesPlotCardBodyComponent } from './worksheet/cards/body/parallel-coordinates-plot-card-body/parallel-coordinates-plot-card-body.component';
import { CardPreviewModalComponent } from './worksheet/card-wizard/card-preview-modal/card-preview-modal.component';
import { UnitRootTestCardBodyComponent } from './worksheet/cards/body/unit-root-test-card-body/unit-root-test-card-body.component';
import { UnitRootTestADFCardHelpComponent } from './worksheet/cards/help/unit-root-test-adf-card-help/unit-root-test-adf-card-help.component';
import { UnitRootTestZACardHelpComponent } from './worksheet/cards/help/unit-root-test-za-card-help/unit-root-test-za-card-help.component';
import { UnitRootTestKPSSCardHelpComponent } from './worksheet/cards/help/unit-root-test-kpss-card-help/unit-root-test-kpss-card-help.component';
import { DurbinWatsonCardBodyComponent } from './worksheet/cards/body/durbin-watson-card-body/durbin-watson-card-body.component';
import { DurbinWatsonCardHelpComponent } from './worksheet/cards/help/durbin-watson-card-help/durbin-watson-card-help.component';
import { EmbeddedBoxPlotComponent } from './worksheet/cards/fragments/embedded-box-plot/embedded-box-plot.component';
import { TimeSeriesCardConfigComponent } from './worksheet/cards/config/time-series-card-config/time-series-card-config.component';
import { MannKendallTestCardBodyComponent } from './worksheet/cards/body/mann-kendall-test-card-body/mann-kendall-test-card-body.component';
import { MannKendallTestCardHelpComponent } from './worksheet/cards/help/mann-kendall-test-card-help/mann-kendall-test-card-help.component';
import { ACFPlotCardBodyComponent } from './worksheet/cards/body/acf-plot-card-body/acf-plot-card-body.component';
import { PlaygroundCardBodyComponent } from './worksheet/cards/body/playground-card-body/playground-card-body.component';
import { TimeSeriesSummaryComponent } from './worksheet/cards/fragments/time-series-summary/time-series-summary.component';
import { Iso8601DurationPipe } from './pipes/iso8601-duration.pipe';
import { AcfPlotCardHelpComponent } from './worksheet/cards/help/acf-plot-card-help/acf-plot-card-help.component';
import { TimeseriesDecompositionCardBodyComponent } from './worksheet/cards/body/timeseries-decomposition-card-body/timeseries-decomposition-card-body.component';
import { PcaRecipeSettingsComponent } from './recipe/pca-recipe-settings/pca-recipe-settings.component';
import { StatsRecipeSettingsComponent } from './recipe/stats-recipe-settings/stats-recipe-settings.component';

@NgModule({
    declarations: [
        EdaComponent,
        WorksheetComponent,
        InsightCardComponent,
        ColumnCardBodyComponent,
        HeaderCardBodyComponent,
        CategoricalHistogramCardBodyComponent,
        GenericSummaryStatsCardBodyComponent,
        QuantilesTableCardBodyComponent,
        NumericalHistogramCardBodyComponent,
        UnivariateFrequencyTableCardBodyComponent,
        BivariateHistogramCardBodyComponent,
        BivariateFrequencyTableCardBodyComponent,
        BivariateBoxPlotCardBodyComponent,
        Fit2DDistributionCardBodyComponent,
        TopLevelCardComponent,
        WorksheetRootCardComponent,
        CardBodyComponent,
        GroupedCardBodyComponent,
        GroupedHeaderCardBodyComponent,
        ColumnTitleComponent,
        CardMenuComponent,
        FitDistributionCardBodyComponent,
        FitCurveCardBodyComponent,
        CorrelationMatrixCardBodyComponent,
        NewCardModalComponent,
        KsTest2SampCardBodyComponent,
        GenericPairwiseTestCardBodyComponent,
        CardTitlePipe,
        FilterNamePipe,
        UnavailableCardBodyComponent,
        PCACardBodyComponent,
        MosaicPlotCardBodyComponent,
        ScatterPlotCardBodyComponent,
        GenericNSampTestCardBodyComponent,
        ShapiroNormalityTestCardBodyComponent,
        Chi2IndTestCardBodyComponent,
        TZTest1SampCardBodyComponent,
        SignTest1SampCardBodyComponent,
        EditConfidenceLevelSettingsComponent,
        SampleSettingsComponent,
        HeightEqualizerComponent,
        SplitBySettingsComponent,
        DebugCardModalComponent,
        DeleteDialogComponent,
        CardEditorComponent,
        HeatmapConfigComponent,
        DistributionNamePipe,
        CardHelpComponent,
        TZTest1SampCardHelpComponent,
        ShapiroNormalityTestCardHelpComponent,
        KsTest2SampCardHelpComponent,
        SignTest1SampCardHelpComponent,
        Chi2IndTestCardHelpComponent,
        GenericSummaryStatsCardConfigComponent,
        EditCardModalComponent,
        CategoricalHistogramCardConfigComponent,
        UnivariateFrequencyTableCardConfigComponent,
        QuantilesTableCardConfigComponent,
        NumericalHistogramCardConfigComponent,
        Abstract2DPivotCardConfigComponent,
        BivariateBoxPlotCardConfigComponent,
        RenameWorksheetDialogComponent,
        ScatterPlotCardConfigComponent,
        UnivariateHeaderCardConfigComponent,
        BivariateHeaderCardConfigComponent,
        MultivariateCardConfigComponent,
        ScatterPlot3DCardConfigComponent,
        TZTest1SampCardConfigComponent,
        SignTest1SampCardConfigComponent,
        ShapiroNormalityTestCardConfigComponent,
        Abstract2SampCardConfigComponent,
        AbstractNSampCardConfigComponent,
        FitDistributionCardConfigComponent,
        FitCurveCardConfigComponent,
        Fit2DDistributionCardConfigComponent,
        AbstractPairwiseTestCardConfigComponent,
        ListBoxComponent,
        ListBoxFormControlComponent,
        VariableSelectorComponent,
        Chi2IndTestCardConfigComponent,
        GenericNSampMoodTestHelpComponent,
        OneWayANOVACardHelpComponent,
        GenericNSampTTestCardHelpComponent,
        ListComparedPopulationsComponent,
        ColoredPValueComponent,
        CardTitlePipe,
        PartitionSelectionComponent,
        DuplicateWorksheetDialogComponent,
        BinningConfigComponent,
        ContainerSettingsComponent,
        HighlightSettingsComponent,
        WorksheetRedirectionPageComponent,
        AutomagicSnifferComponent,
        ScatterPlot3DCardBodyComponent,
        CdfPlotCardBodyComponent,
        ParallelCoordinatesPlotCardBodyComponent,
        CardPreviewModalComponent,
        ProposedCard,
        TimeseriesDecompositionCardBodyComponent,
        UnitRootTestCardBodyComponent,
        UnitRootTestADFCardHelpComponent,
        UnitRootTestZACardHelpComponent,
        UnitRootTestKPSSCardHelpComponent,
        DurbinWatsonCardBodyComponent,
        DurbinWatsonCardHelpComponent,
        EmbeddedBoxPlotComponent,
        TimeSeriesCardConfigComponent,
        TimeSeriesSummaryComponent,
        MannKendallTestCardBodyComponent,
        MannKendallTestCardHelpComponent,
        AcfPlotCardHelpComponent,
        ACFPlotCardBodyComponent,
        PlaygroundCardBodyComponent,
        // recipes
        PcaRecipeSettingsComponent,
        StatsRecipeSettingsComponent,
        // pipes
        Iso8601DurationPipe,
    ],
    exports: [EdaComponent],
    imports: [
        BrowserAnimationsModule,
        ComponentsModule,
        CommonModule,
        DragDropModule,
        FormsModule,
        InViewportModule,
        ModalsModule,
        MatMenuModule,
        DkuMatTooltipModule,
        MatDividerModule,
        MatTabsModule,
        MatCheckboxModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        MatInputModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        PipesModule,
        WidgetsModule,
        ReactiveFormsModule,
        DataikuAPIModule,
        DataikuWT1Module,
        ScrollingModule,
        ValuesListModule,
        EditableListModule,
        MatButtonToggleModule,
        DkuFrontendCoreModule,
        NgxEchartsModule.forRoot({
            // Lazy loaded echarts modules
            echarts: async () => (await import('../../import-echarts')).echarts
        })
    ]
})
export class EdaModule { }
