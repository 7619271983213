<!-- the main screen-->
<div
    class="h100"
    [ngClass]="isRightPanelOpenedInternal ? 'ng2-main-panel--reduced': 'ng2-main-panel--full'"
>
    <ng-content select="[main-panel]"></ng-content>
</div>

<!-- the right panel itself -->
<div
    class="right-panel"
    [class.ng2-right-panel--opened]="isRightPanelOpenedInternal"
>
    <div class="right-panel__bubbles">
        <button
            type="button"
            onfocus="this.blur()"
            class="right-panel__button-toggle btn btn--text btn--secondary btn--icon btn--round"
            [matTooltip]="isRightPanelOpenedInternal ? 'Close panel' : 'Open panel'"
            matTooltipPosition="left"
            (click)="togglePanelOpened()"
        >
            <i
                [class.dku-icon-arrow-left-16]="!isRightPanelOpenedInternal"
                [class.dku-icon-arrow-right-16]="isRightPanelOpenedInternal"
            >
            </i>
        </button>

        <button
            type="button"
            class="right-panel__bubble right-panel__bubble--blue"
            [class.right-panel__bubble--active]="selectedTab === tab.id && isRightPanelOpenedInternal"
            *ngFor="let tab of availableTabs"
            [matTooltip]="tab.tooltip || ''"
            matTooltipPosition="left"
            (click)="selectedTabChange.emit(tab.id)"
        >
            <i [class]="tab.icon"></i>
        </button>
    </div>
    <div class="ng2-right-panel__content">
        <ng-content select="[right-panel]"></ng-content>
    </div>
</div>
