import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Card, ColumnCard } from 'src/generated-sources';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Variable } from 'src/generated-sources';
import { CardWizardService } from '../card-wizard.service';

export interface EditCardModalComponentInput {
    params: Card;
    isStatCard: boolean;
    columnParams?: ColumnCard;
}

@Component({
    selector: 'edit-card-modal',
    templateUrl: './edit-card-modal.component.html',
    styleUrls: [
        '../../../shared-styles/card-wizard.less',
        './edit-card-modal.component.less'
    ],
    providers: [CardWizardService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCardModalComponent {
    // Inputs
    params: Card;
    isStatCard: boolean;
    columnParams?: ColumnCard; // null if the card is top level (ie. not contained in a ColumnCard)

    // Local
    changedParamsValid = true;
    validationTooltip: string;
    changedParams?: Card;
    updateSiblings = false;
    Variable = Variable;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: EditCardModalComponentInput,
        private dialogRef: MatDialogRef<EditCardModalComponentInput>
    ) {
        this.params = data.params;
        this.columnParams = data.columnParams;
        this.isStatCard = data.isStatCard;
    }

    changeValidity(validity: boolean) {
        this.changedParamsValid = validity;
    }

    changeValidityTooltip(tooltip: string) {
        this.validationTooltip = tooltip;
    }

    changeParams(params: Card) {
        this.changedParams = params;
    }

    dismiss() {
        this.dialogRef.close('');
    }

    get canSave() {
        return this.changedParams && this.changedParamsValid;
    }

    save() {
        if (this.canSave) {
            this.dialogRef.close({
                card: this.changedParams,
                updateSiblings: this.updateSiblings
            });
        }
    }
}
