import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { UIDataCatalog } from 'generated-sources';

@Component({
    selector: 'right-panel-details-partitioning',
    templateUrl: './right-panel-details-partitioning.component.html',
    styleUrls: ['./right-panel-details-partitioning.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightPanelDetailsPartitioningComponent {
    @Input() partitioningDimensions: UIDataCatalog.DetailsDimension[];

    constructor() { }

}
