export namespace DatasetNotebookService {
    /**
     * Generated from com.dataiku.dip.server.services.DatasetNotebookService$NotebookType
     */
    export enum NotebookType {
        SQL = 'SQL',
        JUPYTER = 'JUPYTER'
    }

    /**
     * Generated from com.dataiku.dip.server.services.DatasetNotebookService$SimpleNotebook
     */
    export interface SimpleNotebook {
        analyzedDataset: string;
        associatedRecipe: string;
        connection: string;
        creator: string;
        id: string;
        language: string;
        lastModified: number;
        name: string;
        projectKey: string;
        type: DatasetNotebookService.NotebookType;
    }
}