import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FilterDesc, _GroupingRecipePayloadParams } from 'src/generated-sources';

/** Element that allow you to quickly visualise each aggregation associated to a grouping recipe, in a flattened way*/
export interface AggregationFlattenedDesc {
    agg:string, column:string, type:string
}

type aggregationFunctionKey = keyof _GroupingRecipePayloadParams.GroupingValue;

const aggregationFunctions: aggregationFunctionKey[] = ["avg", "concat", "concatDistinct", "count", "countDistinct", "first", "firstLastNotNull", "last", "max", "min", "singleAggregate", "stddev", "sum", "sum2"];


@Component({
    selector: 'right-panel-details-grouping-recipe',
    templateUrl: './right-panel-details-grouping-recipe.component.html',
    styleUrls: ['./right-panel-details-grouping-recipe.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightPanelDetailsGroupingRecipeComponent {
    @Input() payload!: _GroupingRecipePayloadParams;

    getPreconditionsPreText(filterDesc: FilterDesc): string {
        const preConditions: FilterDesc.FilterUiCondition[] = filterDesc.uiData?.conditions || [];
        return preConditions.length == 0 ? "" : "Filter rows satisfying the following condition:<br />";
    }

    isFilterActive(filter: FilterDesc) {
        return filter.enabled || filter.distinct;
    }

    hasAggregation(values: _GroupingRecipePayloadParams.GroupingValue[]) {
        return values.some((value) => {
            return value.customExpr || aggregationFunctions.some((aggregationFunction) => value[aggregationFunction]);
        });
    }

    countAggregations(values: _GroupingRecipePayloadParams.GroupingValue[]) {
        return values.flatMap<number>((value) => {
            if (value.customExpr) {
                return 1;
            } else {
                return aggregationFunctions.filter((aggregationFunction) => value[aggregationFunction]).length;
            }
        }).reduce((left, right) => left + right, 0)
    }

    getFlatAggregates(values: _GroupingRecipePayloadParams.GroupingValue[]) {
        return values.flatMap<AggregationFlattenedDesc>((value) => {
            return aggregationFunctions.filter((aggregationFunction) => value[aggregationFunction])
                .map((aggregationFunction) => ({agg:aggregationFunction, column:value.column, type:value.type}));
        });
    }

    getCustomAggregations(values: _GroupingRecipePayloadParams.GroupingValue[]) {
        return values.filter(value => value.customExpr);
    }

}
