import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { PainterImage } from '@shared/models/painter';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { DeepHubColumnFormat } from 'src/generated-sources';
import { DeephubObjectDetectionInteractiveScoringPainterService } from '../services/deephub-object-detection-interactive-scoring-painter.service';

@Component({
    selector: 'deephub-object-detection-prediction-widget',
    templateUrl: './deephub-object-detection-prediction-widget.component.html',
    styleUrls: [
        './deephub-object-detection-prediction-widget.component.less',
        '../../../../../shared/components/image-feed-modal/image-feed-modal-shared-style.less',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeephubObjectDetectionPredictionWidgetComponent implements OnChanges {
    @Input() predictions: DeepHubColumnFormat.ObjectDetectionPredictedItem[];
    @Input() threshold: number = 0;
    @Input() painterImage: PainterImage;
    @Output() togglePrediction = new EventEmitter<{
        index: number,
        active: boolean
    }>();

    displayedPredictions: (DeepHubColumnFormat.ObjectDetectionPredictedItem & {
        originalIndex: number,
        aboveThreshold: boolean
    })[]

    constructor(
        public painterService: DeephubObjectDetectionInteractiveScoringPainterService,
        private colorMapService: ColorMapContextService
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.predictions || changes.threshold) {
            this.displayedPredictions = this.predictions.map((prediction, index) => ({
                ...prediction,
                originalIndex: index,
                aboveThreshold: prediction.confidence >= this.threshold
            }));

            this.displayedPredictions.sort((a, b) => b.confidence - a.confidence);
        }
    }

    getCategoryColor(category: string): string | undefined {
        return this.colorMapService.getColor(category);
    }
}
