import type {CardResult, _CardResult} from './card-result';
import type {Card} from './card';
import type {Distribution2D} from './../../compute/distributions/distribution2-d';
import type {Fit2DDistribution} from './../../compute/computations/bivariate/fit2-ddistribution';
import type {_BivariateCard} from './bivariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.Fit2DDistributionCard
 */
export interface Fit2DDistributionCard extends _BivariateCard {
    distribution: Distribution2D;
    xResolution: number;
    yResolution: number;

    // PolyJson type
    type: 'fit_2d_distribution';
}

export namespace Fit2DDistributionCard {
    export const type = 'fit_2d_distribution';
}

/** @deprecated Use Card.isFit2DDistributionCard() */ export function isFit2DDistributionCard(toTest: Card): toTest is Fit2DDistributionCard { return Fit2DDistributionCard.type === toTest['type']; }

export namespace Fit2DDistributionCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.Fit2DDistributionCard$Fit2DDistributionCardResult
     */
    export interface Fit2DDistributionCardResult extends _CardResult {
        result: Fit2DDistribution.Fit2DDistributionResult;

        // PolyJson type
        type: 'fit_2d_distribution';
    }

    export namespace Fit2DDistributionCardResult {
        export const type = 'fit_2d_distribution';
    }

    /** @deprecated Use CardResult.isFit2DDistributionCardResult() */ export function isFit2DDistributionCardResult(toTest: CardResult): toTest is Fit2DDistributionCardResult { return Fit2DDistributionCardResult.type === toTest['type']; }
}