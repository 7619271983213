import type {CDFPlotCard} from './cdfplot-card';
import type {Card, _Card} from './card';
import type {CategoricalHistogramCard} from './univariate_header/categorical-histogram-card';
import type {FitDistributionCard} from './fit-distribution-card';
import type {NumericalHistogramCard} from './univariate_header/numerical-histogram-card';
import type {QuantilesTableCard} from './univariate_header/quantiles-table-card';
import type {ShapiroNormalityTestCard} from './shapiro-normality-test-card';
import type {SignTest1SampCard} from './sign-test1-samp-card';
import type {TZTest1SampCard} from './tztest1-samp-card';
import type {UnivariateFrequencyTableCard} from './univariate_header/univariate-frequency-table-card';
import type {UnivariateSummaryStatsCard} from './univariate_header/univariate-summary-stats-card';
import type {Variable} from './../models/variable';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.UnivariateCard
 */
export interface _UnivariateCard extends _Card {
    column: Variable;
}

export type UnivariateCard = CDFPlotCard | FitDistributionCard | ShapiroNormalityTestCard | SignTest1SampCard | TZTest1SampCard | CategoricalHistogramCard | NumericalHistogramCard | QuantilesTableCard | UnivariateFrequencyTableCard | UnivariateSummaryStatsCard;

export namespace UnivariateCard {
    export const type = ['cdf_plot', 'fit_distribution', 'shapiro', 'sign_test_1samp', 'ttest_ztest_1samp', 'categorical_histogram', 'numerical_histogram', 'quantile_table', 'univariate_frequency_table', 'univariate_summary'] as const;
}

/** @deprecated Use Card.isUnivariateCard() */ export function isUnivariateCard(toTest: Card): toTest is UnivariateCard { return (UnivariateCard.type as readonly unknown[]).includes(toTest['type']); }