import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InViewportModule } from 'ng-in-viewport';
import { NgxEchartsModule } from 'ngx-echarts';

import { DragScrollComponent } from './components/drag-scroll/drag-scroll.component';
import { LazyEchartComponent } from './components/lazy-echart/lazy-echart.component';
import { LazyRenderComponent } from './components/lazy-render/lazy-render.component';
import { TypedNgTemplateDirective } from './directives/typed-ng-template.directive';
import { FilterDefinedPipe } from './pipes/filter-defined.pipe';
import { HasPropertyPipe } from './pipes/has-property.pipe';
import { InstanceOfPipe } from './pipes/instance-of.pipe';
import { WindowService } from './window.service';
import { DataikuWT1Module } from './dataiku-wt1/dataiku-wt1.module';
import { QaModule } from './qa/qa.module';
import { AsPipe } from './pipes/as.pipe';

@NgModule({
    imports: [
        CommonModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
        InViewportModule,
        ScrollingModule,
        DataikuWT1Module,
        QaModule
    ],
    providers: [
        WindowService,
    ],
    bootstrap: [],
    declarations: [
        LazyEchartComponent,
        LazyRenderComponent,
        DragScrollComponent,
        InstanceOfPipe,
        AsPipe,
        HasPropertyPipe,
        FilterDefinedPipe,
        TypedNgTemplateDirective,
    ],
    exports: [
        LazyEchartComponent,
        LazyRenderComponent,
        DragScrollComponent,
        InstanceOfPipe,
        AsPipe,
        HasPropertyPipe,
        FilterDefinedPipe,
        TypedNgTemplateDirective,
        DataikuWT1Module,
        QaModule
    ],
})
export class DkuFrontendCoreModule { }
