<div class="modal modal--heatmap">
    <modal-header [title]="'Edit heatmap'" [hasBorder]="false"></modal-header>
    <div class="modal-body flex">
        <form class="dkuform-modal-vertical" [formGroup]="configForm">
            <div class="controls">
                <label>
                    <input type="checkbox" class="form__checkbox" formControlName="showAbsValues">
                    Show absolute values
                </label>
            </div>
            <div class="controls">

                <label>
                    <input type="checkbox" class="form__checkbox" formControlName="showColors">
                    Show values as colors
                </label>
            </div>
            <div class="controls">
                <label>
                    <input type="checkbox" class="form__checkbox" formControlName="showValues">
                    Show values as text
                </label>
            </div>
            <div class="controls">
                <label>
                    Threshold
                    <input type="number" min="0" max="1" step="0.01" formControlName="threshold"
                        required>
                </label>
            </div>
            <div class="controls" *ngIf="configForm.controls.threshold.value != 0">
                <label>
                    <input type="checkbox" class="form__checkbox" formControlName="filterVariablesWithoutValues">
                    Do not show variables for which all values have been filtered out
                </label>
            </div>
        </form>
    </div>
    <div class="modal-footer modal-footer-std-buttons">
        <div class="pull-right">
            <button type="button" class="btn btn--text btn--secondary" (click)="dismiss();" tabindex="-1">Cancel</button>
            <button type="button" class="btn btn--text btn--primary" (click)="finish();" tabindex="-1" [disabled]="configForm.invalid">Apply</button>
        </div>
    </div>
</div>
