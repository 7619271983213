<div class="review-item-header" *ngIf="labelingService.labelingTaskInfo$ | async as task">
    <ng-container *ngIf="labelingReviewUpdateService.reviewStatus$ | async as reviewStatus">
        <div class="buttons-container">
            <button class="btn btn--secondary action-button" (click)="previousItem()"
                [disabled]="((labelingReviewService.isFirst$ | async) && !(labelingReviewService.finishedReview$ | async)) || (labelingReviewService.isEmpty$ | async)">
                <i class="mleft8 icon-chevron-left"></i>
                <span class="action-name">BACK</span>
                <code class="keybind action-key padtop0">←</code>
            </button>
            <button class="btn btn--secondary action-button mleft8" (click)="nextItem()"
                [disabled]="(this.labelingService.identifier$ | async) || (labelingReviewService.isEmpty$ | async) || (labelingReviewService.isLast$ | async) ">
                <span class="action">NEXT</span>
                <i class="mleft8 icon-chevron-right"></i>
                <code class="keybind action-key padtop0">→</code>
            </button>
            <button class="btn btn--secondary icon-container btn--dku-icon mleft8" (click)="resetModifications()" 
            [matTooltip]="'Reset current modifications'" [matTooltipPosition]="'above'">
                <i class="labeling-review__refrech-icon dku-icon-arrow-sync-16"></i>
            </button>
        </div>
        <div *ngIf="!(labelingReviewService.isEmpty$ | async) && !(labelingReviewService.finishedReview$ | async)"
            class="review-info-container mleft8">
            <div class="icon-container mleft8">
                <i class="cursor-pointer" [class]="labelingService.copyClass$ | async" (click)="copyPermalink()"
                    [matTooltip]="'Copy '+ getItemName(task.type)+' link to clipboard'" [matTooltipPosition]="'above'"></i>
            </div>
            <div class="mleft8 path-container" [matTooltip]="(labelingReviewService.currentId$ | async) ?? ''"
                [matTooltipPosition]="'above'">
                {{labelingReviewService.currentId$ | async | gentleTruncateBeginning : 50}}
            </div>
            <div class="mleft32 icon-container">
                <span *ngIf="reviewStatus === ReviewStatus.VALIDATED" class="text-success review-status-container">
                    <i class="dku-icon-label-validate-20 mright8"></i>
                    Validated
                </span>
                <span *ngIf="reviewStatus === ReviewStatus.CONSENSUS" class="review-status-container icon-digital-blue-base">
                    <i class="dku-icon-label-review-20 mright8"></i>
                    Needs validation
                </span>
                <span *ngIf="reviewStatus === ReviewStatus.CONFLICTING" class="text-warning review-status-container">
                    <i class="dku-icon-label-conflict-20 mright8"></i>
                    Conflicting
                </span>
                <span *ngIf="reviewStatus === ReviewStatus.REJECTED" class="text-error review-status-container">
                    <i class="dku-icon-label-reject-20 mright8"></i>
                    Rejected
                </span>
            </div>

        </div>
        <div *ngIf="!(labelingReviewService.isEmpty$ | async) && !(labelingReviewService.finishedReview$ | async)" class="action-buttons-container">
            <div [matTooltip]="reviewStatus === ReviewStatus.REJECTED ? 'Annotations already rejected' : 'Reject all annotations so that annotators can annotate this '+ getItemName(task.type) + ' again'">
                <button class="btn btn--secondary mright8" (click)="reject(task.type)" [disabled]="reviewStatus === ReviewStatus.REJECTED">
                    Reject
                </button>
            </div>
            <div *ngIf="!(labelingReviewUpdateService.updatingReview$ | async)"
                [matTooltip]="'Unable to save because of blocking conflicts or missing ' + (task.type | categoryLabel)" [matTooltipDisabled]="canSave$ | async"
                [matTooltipPosition]="'left'">
                <button class="btn btn--contained btn--primary btn--dku-icon h100" (click)="saveReview()"
                    [disabled]="!(canSave$ | async)">
                    <i class="dku-icon-checkmark-16"></i>
                    Validate
                </button>
            </div>

            <div *ngIf="reviewStatus === ReviewStatus.REJECTED">
                <button class="btn btn--contained btn--primary btn--dku-icon h100" [disabled]="true">
                    <i class="dku-icon-checkmark-16"></i>
                    Validate
                </button>
            </div>
            <div *ngIf="(labelingReviewUpdateService.updatingReview$ | async) && (labelingReviewUpdateService.isDirty$ | async)"
                [matTooltip]="'Unable to save because review is empty or missing ' + (task.type | categoryLabel)" [matTooltipDisabled]="canSave$ | async"
                [matTooltipPosition]="'left'">
                <button class="btn btn--contained btn--primary btn--dku-icon h100" (click)="saveReview()"
                    [disabled]="!(canSave$ | async)">
                    <i class="dku-icon-save-16"></i>
                    Update
                </button>
            </div>
            <div *ngIf="reviewStatus === ReviewStatus.VALIDATED && !(labelingReviewUpdateService.isDirty$ | async)">
                <button class="btn btn--contained btn--primary btn--dku-icon h100" disabled>
                    <i class="dku-icon-save-16"></i>
                    Validated!
                </button>
            </div>
        </div>
    </ng-container>
</div>
