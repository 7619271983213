<div>
    <modal-header [title]="modalTitle" icon="dku-icon-edit-24"></modal-header>
    <api-error-alert [apiError]="errorContext.error$ | async" (resetError)="errorContext.resetError()"
        [closable]="true"></api-error-alert>
    <div class="modal-body">
        <base-permissions-edition *ngIf="dataCollectionInfo$ | async as dataCollectionInfo"
            [objectType]="objectType"
            [permissions]="dataCollectionInfo.permissions"
            [roleList]="roleList"
            [users]="users$ | async"
            [groups]="groups$ | async"
            [editionMode]="true"
            [canEdit]="dataCollectionInfo.currentUserPermissions.admin"
            (permissionsChange)="onPermissionsChange({ permissions: $event })"></base-permissions-edition>
    </div>
    <div class="modal-footer modal-footer-std-buttons">
        <div class="pull-right">
            <button type="button" class="btn btn--text btn--secondary" (click)="cancel()">Cancel</button>
            <button type="submit" class="btn btn--text btn--primary" (click)="confirm()" [disabled]="!canSave" autofocus>
                Save
            </button>
        </div>
    </div>
</div>
