import { Component, OnChanges, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { VStackRecipePayloadParams } from '@shared/models/stack-recipe-params.model';
import { StackRecipeNames } from '@shared/services/stack-recipe-names.service';
import { isDefined } from '@utils/objects';
import { fairAny } from 'dku-frontend-core';
import { FilterDesc, SerializedRecipe, _VStackRecipePayloadParams } from 'src/generated-sources';

@Component({
    selector: 'right-panel-details-stack-recipe',
    templateUrl: './right-panel-details-stack-recipe.component.html',
    styleUrls: ['./right-panel-details-stack-recipe.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightPanelDetailsStackRecipeComponent implements OnChanges {
    @Input() contextProjectKey!: string;
    @Input() params: SerializedRecipe;
    @Input() payload!: VStackRecipePayloadParams;
    public preFilters: _VStackRecipePayloadParams.InputDesc[] = []; // TODO : change to protected once in Angular 14+ (more secure as outside classes should not have access to this + can be bound to the template)

    columnsSelectionDescription = '';
    datasetNameToShow = ''; // the name of the dataset in FROM_DATASET mode, '' otherwise
    datasetToShowHref = ''; // the link to said dataset (inside contextProject using foreign path if needed) or ''

    constructor(
        private stackRecipeNames: StackRecipeNames,
        @Inject('StateUtils') private StateUtils: fairAny,
    ) { }

    ngOnChanges() {
        this.columnsSelectionDescription = this.stackRecipeNames.modeDisplayName(this.payload.mode);

        // isFromDataset == true and copySchemaFromDatasetWithName being undefined/null would be a corrupted config.
        this.datasetNameToShow = this.stackRecipeNames.isFromDataset(this.payload.mode) && isDefined(this.payload.copySchemaFromDatasetWithName) ? this.payload.copySchemaFromDatasetWithName : '';
        this.datasetToShowHref = this.datasetNameToShow && this.StateUtils.href.dataset(this.datasetNameToShow, this.contextProjectKey);
        this.preFilters = this.payload ? this.filterActiveFilters(this.payload.virtualInputs) : [];
    }

    // Pre and post filters

    filterActiveFilters(virtualInputs: _VStackRecipePayloadParams.InputDesc[]): _VStackRecipePayloadParams.InputDesc[] {
        return virtualInputs.filter(input => input && this.isFilterActive(input.preFilter));
    }

    isFilterActive(filter: FilterDesc) {
        return filter && (filter.enabled || filter.distinct);
    }

    getPreconditionsPreText(filterDesc: FilterDesc): string {
        const preConditions: FilterDesc.FilterUiCondition[] = filterDesc.uiData?.conditions || [];
        const plural = preConditions.length > 1 ? "s" : "";
        return preConditions.length == 0 ? "" : "Filter rows satisfying the following condition" + plural + ":<br />";
    }

}
