import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";

@Directive({
    selector: 'dku-slider'
})
export class DkuSliderDirective extends UpgradeComponent {
  @Input()
  value: number;

  @Input()
  min: number;

  @Input()
  max: number;

  @Input()
  nbDecimalPlaces?: number;

  @Output()
  valueChange = new EventEmitter<number>();

  constructor(elementRef: ElementRef, injector: Injector) {
    super('ng1DkuSlider', elementRef, injector);
  }
}
