import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent {
    @Input() index: number;
    @Input() itemCount: number;
    @Output() change = new EventEmitter<number>();

    constructor() {}

    changeItem(newIndex: number) {
        this.change.emit(newIndex);
    }
}
