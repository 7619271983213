import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.multivariate.FetchValues
 */
export interface FetchValues extends Computation._MultivariateComputation {
    // PolyJson type
    type: 'fetch_values';
}

export namespace FetchValues {
    export const type = 'fetch_values';
}

/** @deprecated Use Computation.isFetchValues() */ export function isFetchValues(toTest: Computation): toTest is FetchValues { return FetchValues.type === toTest['type']; }

export namespace FetchValues {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.multivariate.FetchValues$FetchValuesResult
     */
    export interface FetchValuesResult extends _AvailableResult {
        series: number[][];

        // PolyJson type
        type: 'fetch_values';
    }

    export namespace FetchValuesResult {
        export const type = 'fetch_values';
    }

    /** @deprecated Use ComputationResult.isFetchValuesResult() */ export function isFetchValuesResult(toTest: ComputationResult): toTest is FetchValuesResult { return FetchValuesResult.type === toTest['type']; }
}