
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { NumericalHistogramCard, Variable } from 'src/generated-sources';
import { FormBuilder, Validators } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';


@UntilDestroy()
@Component({
    selector: 'numerical-histogram-card-config',
    templateUrl: './numerical-histogram-card-config.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumericalHistogramCardConfigComponent implements OnChanges, OnDestroy {
    @Input() params: NumericalHistogramCard;
    @Output() paramsChange = new EventEmitter<NumericalHistogramCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    binningMode = this.fb.control(null, [Validators.required]);
    nbBins = this.fb.control(null);
    customBinningBoundaries = this.fb.control(null);

    configForm = this.fb.group({
        binningConfig: this.fb.control(null, [Validators.required])
    });

    constructor(private fb: FormBuilder) {
        this.configForm.statusChanges.pipe(untilDestroyed(this))
            .subscribe(() => this.validityChange.emit(this.configForm.valid));
        this.configForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(formValue => {
                this.paramsChange.emit({
                    ...this.params,
                    nbBins: formValue.binningConfig.maxValues,
                    binningMode: formValue.binningConfig.binningMode,
                    customBinningBoundaries: formValue.binningConfig.customBinningBoundaries
                });
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.configForm.patchValue({
                binningConfig: {
                    maxValues: this.params.nbBins,
                    customBinningBoundaries: this.params.customBinningBoundaries,
                    binningMode: this.params.binningMode
                }
            });
        }
    }

    ngOnDestroy() {
    }
}
