import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Hit, Source, isDataSourceDataset, isDataSourceExternalTable, Highlight, DataSourceExternalTable, DataSourceDataset } from '@shared/models/query-result-models';
import { ReplaySubject } from 'rxjs';
import { DataSourcesTab } from '../shared/models';
import { formatItemName, formatProjectNameOrKey } from "../../../feature-store/highlight-utils";
import { assertNever } from 'dku-frontend-core';

// keep in sync with template
type AvailableColumns = 'icon' | 'name' | 'sourceProjectName' | 'usedInProjectCount' | 'columnCount' | 'catalog' | 'connection' | 'schema' | 'description' | 'mass-select';

const ALL_COLUMNS: readonly AvailableColumns[] = ['icon', 'name', 'sourceProjectName', 'columnCount'];
const DATASET_COLUMNS: readonly AvailableColumns[] = ['icon', 'name', 'sourceProjectName', 'usedInProjectCount', 'columnCount'];
const TABLE_COLUMNS: readonly AvailableColumns[] = ['mass-select', 'icon', 'name', 'connection', 'catalog', 'schema', 'columnCount'];

@Component({
    selector: 'data-sources-content-table',
    templateUrl: './data-sources-content-table.component.html',
    styleUrls: ['./data-sources-content-table.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataSourcesContentTableComponent implements OnChanges {
    @Input() isRightPanelOpened!: boolean;
    @Input() items!: Hit<Source>[];
    @Input() selectedTab!: DataSourcesTab;
    @Input() selectedItem?: Hit<Source>;
    @Output() selectedItemChange = new EventEmitter<Hit<Source>>();
    @Input() multiSelectedItems!: Hit<Source>[];
    @Output() multiSelectedItemsChange = new EventEmitter<Hit<Source>[]>();

    displayedColumns = ALL_COLUMNS;
    readonly content$ = new ReplaySubject<Hit<Source>[]>(1);
    isDataSourceDataset = isDataSourceDataset;
    isDataSourceExternalTable = isDataSourceExternalTable;
    formatItemName = formatItemName;
    formatProjectNameOrKey = formatProjectNameOrKey;

    getColumnsForTab() {
        const extraColumn: AvailableColumns[] = this.isRightPanelOpened ? [] : ['description'];
        switch(this.selectedTab) {
            case DataSourcesTab.ALL:
                return ALL_COLUMNS.concat(extraColumn);
            case DataSourcesTab.DATASETS:
                return DATASET_COLUMNS.concat(extraColumn);
            case DataSourcesTab.EXTERNAL_TABLES:
                return TABLE_COLUMNS.concat(extraColumn);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedTab || changes.isRightPanelOpened) {
            this.displayedColumns = this.getColumnsForTab();
        }
        if (changes.items) {
            this.content$.next(this.items);
        }
    }

    isMultiSelected(item: Hit<Source>) {
        return this.multiSelectedItems.includes(item);
    }

    multiSelect(item: Hit<Source>, checked: boolean) {
        this.multiSelectedItemsChange.emit(checked
            ? [...this.multiSelectedItems, item]
            : this.multiSelectedItems.filter(i => i !== item)
        );
    }

    getDescription(hit: Hit<DataSourceExternalTable> | Hit<DataSourceDataset>) {
        if(isDataSourceDataset(hit)) {
            return hit._source.description;
        } else if(isDataSourceExternalTable(hit)) {
            return hit._source.description || hit._source.remarks || '';
        }
        assertNever(hit);
    }
}
