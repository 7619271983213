import { Directive, HostListener} from '@angular/core';

@Directive({
  selector: 'input[selectAll]'
})
export class SelectAllDirective {
    @HostListener('document:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        // Check if Ctrl+A is pressed
        if ((event.ctrlKey || event.metaKey) && event.key === 'a') { // ASCII code for 'A'
            event.preventDefault();
            if (event.target) {
                (event.target as HTMLInputElement).select();
            }
        }
    }
}
