import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.MatchTimeStep
 */
export interface MatchTimeStep extends Computation._UnivariateComputation {
    maxSteps: number;

    // PolyJson type
    type: 'match_time_step';
}

export namespace MatchTimeStep {
    export const type = 'match_time_step';
}

/** @deprecated Use Computation.isMatchTimeStep() */ export function isMatchTimeStep(toTest: Computation): toTest is MatchTimeStep { return MatchTimeStep.type === toTest['type']; }

export namespace MatchTimeStep.MatchTimeStepResult {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.MatchTimeStep$MatchTimeStepResult$IsoDurationStep
     */
    export interface IsoDurationStep extends MatchTimeStep.MatchTimeStepResult._Step {
        duration: string;

        // PolyJson type
        type: 'iso_duration_step';
    }

    export namespace IsoDurationStep {
        export const type = 'iso_duration_step';
    }

    /** @deprecated Use MatchTimeStep.MatchTimeStepResult.Step.isIsoDurationStep() */ export function isIsoDurationStep(toTest: MatchTimeStep.MatchTimeStepResult.Step): toTest is IsoDurationStep { return IsoDurationStep.type === toTest['type']; }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.MatchTimeStep$MatchTimeStepResult$BusinessDaysStep
     */
    export interface BusinessDaysStep extends MatchTimeStep.MatchTimeStepResult._Step {
        weekMask: string;

        // PolyJson type
        type: 'business_days_step';
    }

    export namespace BusinessDaysStep {
        export const type = 'business_days_step';
    }

    /** @deprecated Use MatchTimeStep.MatchTimeStepResult.Step.isBusinessDaysStep() */ export function isBusinessDaysStep(toTest: MatchTimeStep.MatchTimeStepResult.Step): toTest is BusinessDaysStep { return BusinessDaysStep.type === toTest['type']; }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.MatchTimeStep$MatchTimeStepResult$Step
     */
    export interface _Step {}

    export type Step = MatchTimeStep.MatchTimeStepResult.BusinessDaysStep | MatchTimeStep.MatchTimeStepResult.IsoDurationStep;

    export namespace Step {
        export const type = ['business_days_step', 'iso_duration_step'] as const;
        export function isBusinessDaysStep(toTest: MatchTimeStep.MatchTimeStepResult.Step): toTest is MatchTimeStep.MatchTimeStepResult.BusinessDaysStep { return toTest['type'] === 'business_days_step'; }
        export function isIsoDurationStep(toTest: MatchTimeStep.MatchTimeStepResult.Step): toTest is MatchTimeStep.MatchTimeStepResult.IsoDurationStep { return toTest['type'] === 'iso_duration_step'; }
    }
}

export namespace MatchTimeStep {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.MatchTimeStep$MatchTimeStepResult
     */
    export interface MatchTimeStepResult extends _AvailableResult {
        nSteps: number;
        steps: MatchTimeStep.MatchTimeStepResult.Step[];

        // PolyJson type
        type: 'match_time_step';
    }

    export namespace MatchTimeStepResult {
        export const type = 'match_time_step';
    }

    /** @deprecated Use ComputationResult.isMatchTimeStepResult() */ export function isMatchTimeStepResult(toTest: ComputationResult): toTest is MatchTimeStepResult { return MatchTimeStepResult.type === toTest['type']; }
}