import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'named-entity-thumb',
    templateUrl: './named-entity-thumb.component.html',
    styleUrls: ['./named-entity-thumb.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NamedEntityThumbComponent implements OnChanges {
    @Input() text: string;
    @Input() color?: number[];
    @Input() maxWordLength = 5; // length of string on either side of ellipsis
    textPreview: string;
    
    readonly SEPARATOR = '...';

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.text) {
            const MAX_TRUNCATED_LENGTH = this.maxWordLength * 2 + this.SEPARATOR.length;
            this.textPreview = this.text.length <= MAX_TRUNCATED_LENGTH ? this.text : this.text.substring(0, this.maxWordLength) + this.SEPARATOR + this.text.substring(this.text.length - this.maxWordLength, this.text.length);
        }
    }
}
