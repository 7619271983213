import { Pipe, PipeTransform } from '@angular/core';
import { EscapeHtmlPipe } from './escape-html.pipe';

/**
 * This pipe escapes HTML, but maintains the <em> tags
 * Optionally, it can apply a style to the remaining em tags
 */

@Pipe({
    name: 'escapeHighlightedHtml'
})
export class EscapeHighlightedHtmlPipe implements PipeTransform {
    private escapeHtml = new EscapeHtmlPipe();

    transform(highlightedString?: string | null, addedClass?: string): string {
        return this.escapeHtml.transform(
            (highlightedString ?? '')
            .replace(/<em>/g, "((STARTEM))").replace(/<\/em>/g, "((ENDEM))")
          )
          .replace(/\(\(STARTEM\)\)/g, addedClass ? `<em class="${this.escapeHtml.transform(addedClass)}">` : '<em>')
          .replace(/\(\(ENDEM\)\)/g, "</em>");
    }

}
