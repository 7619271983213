import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { shouldUseBlackText } from '@utils/color-contrast';
import { rgb } from 'd3-color';

@Component({
    selector: 'data-collection-icon',
    templateUrl: './data-collection-icon.component.html',
    styleUrls: ['./data-collection-icon.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataCollectionIconComponent implements OnChanges{
    @Input() name: string;
    @Input() color: string;
    @Input() size = 32;

    @HostBinding('class.light-color') shouldUseBlackText = false;
    @HostBinding('style.--color') get backgroundColor() { return this.color; }
    @HostBinding('style.--icon-size') get iconSize() { return `${this.size}px`; }
    @HostBinding('style.--font-size') get fontSize() { return `${Math.ceil(this.size * 13 / 32)}px`; }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.color) {
            const rgbColor =  rgb(this.color);
            this.shouldUseBlackText = shouldUseBlackText(rgbColor);
        }
    }
}
