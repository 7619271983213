import { Inject, Pipe, PipeTransform } from '@angular/core';
import { fairAny } from 'dku-frontend-core';

@Pipe({
  name: 'meaningLabel'
})
export class MeaningLabelPipe implements PipeTransform {
  public readonly DEFAULT_VALUE = 'Auto-detect';
  readonly allMeanings: Record<string, string>;

  constructor(
    @Inject('$rootScope') $rootScope: fairAny,
  ) {
    this.allMeanings = {
      ...$rootScope.appConfig.meanings.labelsMap
    };
  }

  transform(meaning: string | null, defaultValue = this.DEFAULT_VALUE): unknown {
    if (meaning) {
      return this.allMeanings[meaning];
    } else {
      return defaultValue;
    }
  }
}
