import { Role } from '@shared/models';
import { ROLES } from '../models';

export const ROLE_LIST: Role[] = [
    {
        role: ROLES.ADMIN,
        label: 'Admin',
        description: 'All contributors privileges, plus the ability to manage workspace and its users.',
        permissions: { admin: true, write: true, read: true }
    },
    {
        role: ROLES.CONTRIBUTOR,
        label: 'Contributor',
        description: 'Contributors can add and remove content from workspace.',
        permissions: { admin: false, write: true, read: true }
    },
    {
        role: ROLES.MEMBER,
        label: 'Member',
        description: 'Members can interact with workspace content.',
        permissions: { admin: false, write: false, read: true }
    },
];
