import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnChanges, OnInit, SimpleChanges, Inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataSourceDataset, DataSourceExternalTable, Hit, isDataSourceDataset, isDataSourceExternalTable, Source } from '@shared/models/query-result-models';
import { ErrorContextService } from '@shared/services/error-context.service';
import { UIDataCatalog } from 'generated-sources';
import { DataCatalogRightPanelContextService } from '@features/data-catalog/data-catalog-right-panel-context.service';
import { DataSourcesRightPanelContextService } from './data-sources-right-panel-context.service';
import { isDefined } from '@utils/objects';
import { fairAny } from 'dku-frontend-core';

@UntilDestroy()
@Component({
    selector: 'data-sources-right-panel',
    templateUrl: './data-sources-right-panel.component.html',
    styleUrls: ['./data-sources-right-panel.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ErrorContextService, {
        provide: DataCatalogRightPanelContextService,
        useClass: DataSourcesRightPanelContextService
    }],
})
export class DataSourcesRightPanelComponent implements OnChanges, OnInit {
    @Input() readonly selectedItem?: Hit<Source>;
    @Input() readonly selectedTab: string;
    selectedItemDetails?: Readonly<UIDataCatalog.AbstractDataCatalogItemDetails>;
    isItemDetailsLoading$ = this.rightPanelContext.getLoadingStatus();
    constructor(
        private cd: ChangeDetectorRef,
        public rightPanelContext: DataCatalogRightPanelContextService<Hit<Source>>
    ) {}

    ngOnInit(): void {
        this.rightPanelContext.getCurrentDetails().pipe(
            untilDestroyed(this)
        ).subscribe(details => {
            this.selectedItemDetails = details;
            this.cd.detectChanges();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedItem) {
            this.rightPanelContext.setSelectedItem(this.selectedItem);
        }
    }

    isSelectedItemReadableDataset(): this is { selectedItemDetails: UIDataCatalog.DatasetDetails, selectedItem: Hit<DataSourceDataset> } {
        return isDefined(this.selectedItem) && isDataSourceDataset(this.selectedItem)
            && isDefined(this.selectedItemDetails) && UIDataCatalog.AbstractDataCatalogItemDetails.isDatasetDetails(this.selectedItemDetails);
    }

    isSelectedItemExternalTable(): this is { selectedItem: Hit<DataSourceExternalTable>} {
        return isDefined(this.selectedItem) && isDataSourceExternalTable(this.selectedItem);
    }

    share(selectedItemDetails: UIDataCatalog.DatasetDetails) {
        this.rightPanelContext.share(selectedItemDetails, {
            from: 'data-catalog',
        });
    }

    editDataSteward(selectedItemDetails: UIDataCatalog.DatasetDetails) {
        this.rightPanelContext.editDataSteward(selectedItemDetails, {
            from: 'data-catalog',
        });
    }
}
