import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Card, CardResult, EdaErrorCodes } from 'src/generated-sources';

@Component({
    selector: 'unavailable-card-body',
    templateUrl: './unavailable-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/card-spinner.less',
        './unavailable-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnavailableCardBodyComponent {
    @Input() params: Card;
    @Input() results: CardResult.UnavailableCardResult;

    get errorCode(): string | null {
        const { code } = this.results;
        if (code == null) {
            return null;
        }
        const key = code.toString() as keyof typeof EdaErrorCodes;
        return EdaErrorCodes[key];
    }
}
