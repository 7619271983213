import { OnInit, Component, Input, Inject } from '@angular/core';
import { fairAny } from 'dku-frontend-core';
import { StreamableDatasetSelection } from 'src/generated-sources';

@Component({
    selector: 'dataset-selection-ordering-fields',
    template: `<angularjs-dataset-selection-ordering [selection]="selection" [datasetSupportsReadOrdering]="boundDatasetSupportsReadOrdering"
    [shakerState]="shakerState" ></angularjs-dataset-selection-ordering>`
})
export class DatasetSelectionOrderingFieldsComponent{
    @Input() selection: StreamableDatasetSelection;
    @Input() datasetFullInfo: fairAny;

    boundDatasetSupportsReadOrdering: Function;

    shakerState: {
        withSteps: true;
    };

    constructor(@Inject('DatasetUtils') private DatasetUtils: fairAny) {
        this.boundDatasetSupportsReadOrdering = this.datasetSupportsReadOrdering.bind(this);
    }

    datasetSupportsReadOrdering(): boolean {
        return this.datasetFullInfo && this.DatasetUtils.supportsReadOrdering(this.datasetFullInfo.dataset);
    };

}