<object-viewer-navigation [ngClass]="{ 'workspace-object-viewer--full-size': (showRightPanel$ | async) === false }"
    [workspace]="workspace$ | async" [object]="object$ | async" [project]="project$ | async"
    [insightId]="insightId$ | async">
</object-viewer-navigation>

<api-error-alert *ngIf="(hasError$ | async)" [apiError]="(apiError$ | async)" (resetError)="closeError()"
    [closable]="true" [ngStyle]="{display: 'block'}"></api-error-alert>
<workspace-error-message *ngIf="errorMessage$ | async as errorMessage" [message]="errorMessage">
</workspace-error-message>

<div class="workspace-object-viewer"
    [ngClass]="{ 'workspace-object-viewer--full-size': (showRightPanel$ | async) === false }">
    <ng-template objectViewerHost></ng-template>
</div>

<workspace-right-panel *ngIf="showRightPanel$ | async" [workspace]="workspace$ | async" [object]="object$ | async"
    [editable]="editable$ | async" [project]="project$ | async"></workspace-right-panel>
