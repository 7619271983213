import { AsyncPipe } from '@angular/common';
import { PipeTransform, Pipe, ChangeDetectorRef } from '@angular/core';
import { of, BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { delay, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { QaModeService } from 'dku-frontend-core';

/**
 * This pipe can delay propagation of information in an Angular expression:
 * - Falsy values are delayed by 'delayInMs'
 * - Truthy values are forwarded transparently
 * - Initial value is always forwared transparently
 * - Delay is always disabled in QA mode (Selenium)
 *
 * Use case:
 *
 * Prevent a component from being destroy during :leave animation. Even if Angular doesn't clear the
 * DOM immediately, destroying a component can have side-effects (eg. echarts are cleared)
 *
 * This is a known Angular issue: https://github.com/angular/angular/issues/19826
 *
 * Usage:
 *
 * <div [@fadeInOut]="condition ? 'opened' : 'closed'">
 *     <div *ngIf="condition | delayWhenFalse: 200">
 *         <!--
 *              destroyed 200ms later so that opened->closed
 *              transition has enough time to complete
 *         -->
 *     </div>
 * </div>
 */
@Pipe({
    name: 'delayWhenFalse',
    pure: false,
})
export class DelayWhenFalsePipe extends AsyncPipe implements PipeTransform {
    inputValue$ = new BehaviorSubject<any>(undefined);
    delayInMs: number;
    pipeValue$: Observable<any>;

    constructor(cdRef: ChangeDetectorRef, qaModeService: QaModeService) {
        super(cdRef);

        this.pipeValue$ = combineLatest([
            this.inputValue$.pipe(distinctUntilChanged()),
            qaModeService.isQaEnabled()
        ]).pipe(
            switchMap(([value, qaMode], index) => (value || index === 0 || qaMode) ? of(value) : delay(this.delayInMs)(of(value)))
        );
    }

    transform(value: any, delayInMs: number = 200) {
        this.delayInMs = delayInMs;
        this.inputValue$.next(value);
        return super.transform(this.pipeValue$);
    }
}
