import { Inject, Injectable } from '@angular/core';
import { Workspace } from '@model-main/workspaces/workspace';
import { ProjectsService } from '@model-main/server/services/projects-service';
import { ITaggingService } from 'src/generated-sources';
import { DKURootScope } from '@shared/models/dku-root-scope.model';

@Injectable({
    providedIn: 'root'
})
export class WorkspaceSecurityService {

    constructor(@Inject('$rootScope') private $rootScope: DKURootScope) {}

    canCreateWorkspaces(): boolean {
        return this.$rootScope.appConfig && this.$rootScope.appConfig.loggedIn
            && (this.$rootScope.appConfig.admin || this.$rootScope.appConfig.globalPermissions.mayCreateWorkspaces);
    }

    canShareToWorkspace(workspace: Workspace): boolean {
        return this.$rootScope && this.$rootScope.appConfig && this.$rootScope.appConfig.loggedIn
            && (this.$rootScope.appConfig.admin || this.$rootScope.appConfig.globalPermissions.mayShareToWorkspaces)
            && workspace.currentUserPermissions && workspace.currentUserPermissions.write;
    }

    canEditWorkspace(workspace: Workspace): boolean {
        return this.$rootScope && this.$rootScope.appConfig && this.$rootScope.appConfig.loggedIn
            && (this.$rootScope.appConfig.admin || workspace.currentUserPermissions.admin);
    }

    canShareFromProject(project: ProjectsService.UIProject): boolean {
        return this.$rootScope && this.$rootScope.appConfig && this.$rootScope.appConfig.loggedIn
            && (this.$rootScope.appConfig.admin || this.$rootScope.appConfig.globalPermissions.mayShareToWorkspaces)
            && project.canShareToWorkspaces;
    }

    canAddContent(workspace: Workspace) : boolean {
        return workspace.currentUserPermissions && workspace.currentUserPermissions.write;
    }

    canCreateDatastory(workspace: Workspace): boolean {
        return workspace.currentUserPermissions && workspace.currentUserPermissions.write && this.isDatastoryPossible();
    }

    isDatastoryEnabled(): boolean {
        return this.$rootScope.appConfig.dataStoryIntegrationEnabled;
    }

    isDatastoryPossible(): boolean {
        return !this.$rootScope.appConfig.isAutomation && this.$rootScope.appConfig.userProfile.mayWriteStory && this.isDatastoryEnabled();
    }

    canGoToSource(object: Workspace.WorkspaceObject, project: ProjectsService.UIProject | null | undefined): boolean {
        if (object.appId) {
            return true;
        }
        switch(object.reference?.type) {
            case ITaggingService.TaggableType.DASHBOARD:
                return !!project?.canReadDashboards;
            case ITaggingService.TaggableType.DATASET:
            case ITaggingService.TaggableType.ARTICLE:
            case ITaggingService.TaggableType.WEB_APP:
                return !!project?.canWriteProjectContent;
            default:
                return false;
        }
    }

    isObjectSharingRequestEnabled(project: ProjectsService.UIProject): boolean {
        return this.$rootScope && this.$rootScope.appConfig &&
            (this.$rootScope.appConfig.objectSharingRequestsMode === 'ALL_ENABLED' ||
                (this.$rootScope.appConfig.objectSharingRequestsMode !== 'ALL_DISABLED' && project.sharingRequestsEnabled)
            );
    }

}
