import { PICKER_STOCK_COLORS } from '@shared/modules/stock-color-picker/stock-color-picker-colors.constant';
import { assertNever } from 'dku-frontend-core';
import { UIDataCatalog, UIDataCollection, AbstractDataCollectionItemRef } from 'src/generated-sources';

export type DataCollectionFullItemRef = AbstractDataCollectionItemRef & { dataCollectionId: string };

export function buildDefaultCreationInfo(): UIDataCollection.CreationInfo {
    return {
        displayName: '',
        color: PICKER_STOCK_COLORS[Math.floor(Math.random() * PICKER_STOCK_COLORS.length)],
        description: '',
        permissions: []
    };
}

export function getDataCollectionItemDisplayName(item: UIDataCollection.AbstractDataCollectionItemInfo): string {
    switch(item.type){
        case UIDataCollection.DatasetInfo.type:
        case UIDataCollection.DiscoverableDatasetInfo.type:
            return `${item.sourceProjectKey}.${item.name}`;
    }
}

export function dataCollectionItemRefEquals(a: AbstractDataCollectionItemRef, b: AbstractDataCollectionItemRef): boolean {
    switch(a.type) {
        case AbstractDataCollectionItemRef.DatasetRef.type:
            return b.type === a.type && a.projectKey === b.projectKey && a.id === b.id;
    }
}

export function getDataCollectionItemRef(
    item: UIDataCollection.AbstractDataCollectionItemInfo | UIDataCatalog.AbstractDataCatalogItemDetails
): AbstractDataCollectionItemRef {
    switch(item.type){
        case UIDataCollection.DatasetInfo.type:
        case UIDataCollection.DiscoverableDatasetInfo.type:
        case UIDataCatalog.DatasetDetails.type:
        case UIDataCatalog.DiscoverableDatasetDetails.type:
            return {
                type: AbstractDataCollectionItemRef.DatasetRef.type,
                projectKey: item.sourceProjectKey,
                id: item.name,
            };
    }
}

export function dataCollectionFullItemRefEquals(a: DataCollectionFullItemRef, b: DataCollectionFullItemRef): boolean {
    return a.dataCollectionId === b.dataCollectionId && dataCollectionItemRefEquals(a, b);
}

export function getDataCollectionFullItemRef(
    dataCollectionId: string,
    item: UIDataCollection.AbstractDataCollectionItemInfo | UIDataCatalog.AbstractDataCatalogItemDetails
): DataCollectionFullItemRef {
    return {
        dataCollectionId,
        ... getDataCollectionItemRef(item),
    };
}

export function getObjectTaggableTypeFromInfo(item: UIDataCollection.AbstractDataCollectionItemInfo): string {
    if (UIDataCollection.AbstractDataCollectionItemInfo.isAbstractDatasetInfo(item)) {
        return 'DATASET';
    }
    assertNever(item);
}
