import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ColorMapContextService {
    private mapping: Map<string, string>;

    setMapping(labels: string[], colors: string[]) {
        this.mapping = new Map();
        labels.forEach((category: string, index: number) => {
            this.mapping.set(category, colors[index % colors.length]);
        });
    }

    getColor(label: string) : string {
        if (this.mapping && this.mapping.has(label)) {
            return this.mapping.get(label)!;
        } else { // Default to grey color if not found
            return "#999999"; // grey-lighten-4
        }
    }
}
