import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { WidgetsModule } from "@app/widgets/widgets.module";
import { ComponentsModule } from "@shared/components/components.module";
import { DeephubDesignImageFeedComponent } from "./computer-vision/design/image-feed/deephub-design-image-feed.component";
import { BrowserModule } from "@angular/platform-browser";
import { PipesModule } from "@shared/pipes/pipes.module";
import { DeephubObjectDetectionReportComponent } from './object-detection/report/deephub-object-detection-report.component';
import { DeephubObjectDetectionReportPrecisionRecallChartComponent } from './object-detection/report/precision-recall-chart/deephub-object-detection-report-precision-recall-chart.component';
import { DeephubObjectDetectionReportConfusionMatrixComponent } from './object-detection/report/confusion-matrix/deephub-object-detection-report-confusion-matrix.component';
import { DeephubObjectDetectionReportImageFeedComponent } from './object-detection/report/image-feed/deephub-object-detection-report-image-feed.component';
import { DeephubObjectDetectionReportImageFeedModalComponent } from './object-detection/report/image-feed-modal/deephub-object-detection-report-image-feed-modal.component';
import { DeephubObjectDetectionReportImageLegendComponent } from './object-detection/report/image-legend/deephub-object-detection-report-image-legend.component';
import { DeephubImageClassificationReportComponent } from './image-classification/report/deephub-image-classification-report.component';
import { DeephubImageClassificationReportConfusionMatrixComponent } from './image-classification/report/confusion-matrix/deephub-image-classification-report-confusion-matrix.component';
import { DeephubImageClassificationReportImageFeedComponent } from './image-classification/report/image-feed/deephub-image-classification-report-image-feed.component';
import { DeephubImageClassificationReportImageFeedModalComponent } from './image-classification/report/image-feed-modal/deephub-image-classification-report-image-feed-modal.component';
import { DeephubImageClassificationReportImageLegendComponent } from './image-classification/report/image-legend/deephub-image-classification-report-image-legend.component';
import { NgxEchartsModule } from "ngx-echarts";
import { DeephubObjectDetectionReportPairListComponent } from './object-detection/report/pair-list/deephub-object-detection-report-pair-list.component';
import { DeephubImageClassificationDesignImageFeedComponent } from "./image-classification/design/image-feed-provider/deephub-image-classification-design-image-feed.component";
import { DeephubObjectDetectionDesignImageFeedComponent } from "./object-detection/design/image-feed-provider/deephub-object-detection-design-image-feed.component";
import { DkuMatTooltipModule } from '@shared/components/dku-mat-tooltip/dku-mat-tooltip.module';
import { DeephubDesignDataAugmentationComponent } from "./computer-vision/design/data-augmentation/deephub-design-data-augmentation.component";
import { DeephubDesignDataAugmentationAugmentedImageComponent } from "./computer-vision/design/data-augmentation/augmented-image/deephub-design-data-augmentation-augmented-image.component";
import { DataikuAPIModule } from "@core/dataiku-api/dataiku-api.module";
import { DeephubReportInteractiveScoringComponent } from "./computer-vision/report/interactive-scoring/deephub-report-interactive-scoring.component";
import { DeephubReportInteractiveScoringDropZoneComponent } from "./computer-vision/report/interactive-scoring/drop-zone/deephub-report-interactive-scoring-drop-zone.component";
import { DeephubImageClassificationReportPredictionWidgetComponent } from "./image-classification/report/prediction-widget/deephub-image-classification-prediction-widget.component";
import { DeephubImageClassificationReportInteractiveScoringComponent } from './image-classification/report/interactive-scoring/deephub-image-classification-report-interactive-scoring.component';
import { DeephubObjectDetectionReportInteractiveScoringComponent } from './object-detection/report/interactive-scoring/deephub-object-detection-report-interactive-scoring.component';
import { DeephubObjectDetectionPredictionWidgetComponent } from './object-detection/report/prediction-widget/deephub-object-detection-prediction-widget.component';
import { DeephubObjectDetectionReportImageSectionComponent } from './object-detection/report/image-section/deephub-object-detection-report-image-section.component';
import { DeephubImageClassificationReportImageSectionComponent } from './image-classification/report/image-section/deephub-image-classification-report-image-section.component';
import { DeephubReportInteractiveScoringImageHeaderComponent } from './computer-vision/report/interactive-scoring/image-header/deephub-report-interactive-scoring-image-header.component';
import { DkuFrontendCoreModule } from "dku-frontend-core";

@NgModule({
    declarations: [
        DeephubImageClassificationDesignImageFeedComponent,
        DeephubObjectDetectionDesignImageFeedComponent,
        DeephubDesignDataAugmentationComponent,
        DeephubDesignDataAugmentationAugmentedImageComponent,
        DeephubReportInteractiveScoringComponent,
        DeephubReportInteractiveScoringDropZoneComponent,
        DeephubDesignImageFeedComponent,
        DeephubObjectDetectionReportComponent,
        DeephubObjectDetectionReportPrecisionRecallChartComponent,
        DeephubObjectDetectionReportConfusionMatrixComponent,
        DeephubObjectDetectionReportImageFeedComponent,
        DeephubObjectDetectionReportImageFeedModalComponent,
        DeephubObjectDetectionReportImageLegendComponent,
        DeephubObjectDetectionReportPairListComponent,
        DeephubImageClassificationReportComponent,
        DeephubImageClassificationReportConfusionMatrixComponent,
        DeephubImageClassificationReportImageFeedComponent,
        DeephubImageClassificationReportImageFeedModalComponent,
        DeephubImageClassificationReportPredictionWidgetComponent,
        DeephubImageClassificationReportImageLegendComponent,
        DeephubImageClassificationReportInteractiveScoringComponent,
        DeephubObjectDetectionReportInteractiveScoringComponent,
        DeephubObjectDetectionPredictionWidgetComponent,
        DeephubObjectDetectionReportImageSectionComponent,
        DeephubImageClassificationReportImageSectionComponent,
        DeephubReportInteractiveScoringImageHeaderComponent,
    ],
    exports: [],
    entryComponents: [
        DeephubObjectDetectionReportImageFeedModalComponent
    ],
    imports: [
        BrowserModule,
        DataikuAPIModule,
        DkuFrontendCoreModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        WidgetsModule,
        PipesModule,
        NgxEchartsModule.forRoot({
            // Lazy loaded echarts modules
            echarts: async () => (await import('../../import-echarts')).echarts
        }),
        DkuMatTooltipModule
    ]
})

export class DeephubModule {}
