import { Component, Input } from '@angular/core';

@Component({
    selector: 'managed-folder-contents-view',
    template: '<angularjs-managed-folder-contents-view [odb]="odb" [subFolderStartingPoint]="subFolderStartingPoint" [initialSubFolder]="initialSubFolder"></angularjs-managed-folder-contents-view>',
})
export class ManagedFolderContentsViewComponent {
    @Input() odb: object;
    @Input() subFolderStartingPoint?: string;
    @Input() initialSubFolder?: string;
}
