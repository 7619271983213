import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { Insight } from '@model-main/dashboards/model/insight';
import { AppConfig } from '@shared/models';
import { DatasetOptions } from './dataset-options.model';

@Directive({
  selector: '[datasetTableInsightEdit]'
})
export class DatasetTableInsightEditDirective extends UpgradeComponent {
  @Input()
  insight?: Partial<Insight> | null;

  @Input()
  projectKey?: string | null;

  @Input()
  datasetOptions?: DatasetOptions | null;

  @Input()
  appConfig?: AppConfig | null;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('ng1DatasetTableInsightEdit', elementRef, injector);
  }
}
