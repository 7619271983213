import { DkuRect } from "./labeling-drawing.service";

export function clipCoordinatesAndScale(obj: fabric.Object, canvasWidth: number, canvasHeight: number) {
    // Setting scaleX and scaleY to the clipped box is enough to rescale the box to the right size
    if (obj.left! < 0) {
        const newWidth = obj.left! + obj.width! * obj.scaleX!;
        obj.left = 0;
        obj.scaleX = (newWidth) / obj.width!;
    }

    if (obj.top! < 0) {
        const newHeight = obj.top! + obj.height! * obj.scaleY!;
        obj.top = 0;
        obj.scaleY = (newHeight) / obj.height!;
    }

    if (obj.left! + (obj.width! * obj.scaleX!) > canvasWidth) {
        obj.scaleX = (canvasWidth - obj.left!) / obj.width!;
    }

    if (obj.top! + (obj.height! * obj.scaleY!) > canvasHeight) {
        obj.scaleY = (canvasHeight - obj.top!) / obj.height!;
    }
}

export function clipCoordinates(rect: DkuRect, rightBound: number, bottomBound: number) {
    const boundingRect = rect.getBoundingRect();
    let newTop = rect.top!;
    let newLeft = rect.left!;
    let clipped = false;

    if (newTop < 0) {
        newTop = 0;
        clipped = true;
    }
    if (newTop + boundingRect.height! > bottomBound) {
        newTop = bottomBound - boundingRect.height!;
        clipped = true;
    }
    if (newLeft < 0) {
        newLeft = 0;
        clipped = true;
    }
    if (newLeft + boundingRect.width! > rightBound) {
        newLeft = rightBound - boundingRect.width!;
        clipped = true;
    }

    if (clipped) {
        rect.set({ left: newLeft });
        rect.set({ top: newTop });
        rect.setCoords();
    }
}