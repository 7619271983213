import { DkuHttpService } from 'dku-frontend-core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppsService } from '../../../../generated-sources';

@Injectable({ providedIn: 'root' })
export class PrivateAppsAPI {
  constructor(
    private dkuHttp: DkuHttpService,
  ) { }

  listTemplates(): Observable<{ items: AppsService.AppListItem[] }> {
    return this.dkuHttp.request('GET', '/apps/list-templates');
  }
}