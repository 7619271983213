import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CorrelationMatrixCard } from 'src/generated-sources';
import type { EChartsOption } from 'echarts';
import { CardAction, CardActionType } from '@features/eda/worksheet/cards/events';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'correlation-matrix-card-body',
    templateUrl: './correlation-matrix-card-body.component.html',
    styleUrls: ['./correlation-matrix-card-body.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorrelationMatrixCardBodyComponent implements OnChanges {
    @Input() results: CorrelationMatrixCard.CorrelationMatrixCardResult;
    @Input() params: CorrelationMatrixCard;
    @Input() readOnly: boolean;
    @Output() action = new EventEmitter<CardAction>();
    chartOptions: EChartsOption;

    heatmapLabels: string[] | undefined;
    heatmapData: number[][] | undefined;
    heatmapWarnings: (string | null)[][] | undefined;

    constructor() {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results) {
            this.buildHeatmapChartParams();
        }
    }

    buildHeatmapChartParams(): void {
        this.heatmapData = [];
        this.heatmapWarnings = [];
        let scoreIdx = 0;
        let warningIdx = 0;
        for (let col = 0; col < this.params.columns.length; col++) {
            this.heatmapData[col] = [];
            this.heatmapWarnings[col] = [];
        }

        for (let xIdx = 0; xIdx < this.params.columns.length; xIdx++) {
            for (let yIdx = xIdx; yIdx < this.params.columns.length; yIdx++) {
                const score = this.results.scores[scoreIdx];
                this.heatmapData[xIdx][yIdx] = score;
                this.heatmapData[yIdx][xIdx] = score;
                if ((null == score) && this.results.partialErrors && this.results.partialErrors.length > warningIdx) {
                    this.heatmapWarnings[xIdx][yIdx] = this.results.partialErrors[warningIdx];
                    this.heatmapWarnings[yIdx][xIdx] = this.results.partialErrors[warningIdx];
                    warningIdx++;
                } else {
                    this.heatmapWarnings[xIdx][yIdx] = null;
                    this.heatmapWarnings[yIdx][xIdx] = null;
                }
                scoreIdx++;
            }
        }

        this.heatmapLabels = this.params.columns.map((variable) => variable.name)
    }
}
