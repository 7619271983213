import { Inject, Injectable } from '@angular/core';
import { fairAny } from 'dku-frontend-core';


/*
    This service is an Angular 2+ wrapper around the angularjs activity indicator
    It only serves the purpose of ensuring that angularjs will notice the implied $scope value changes despite them being run outside of its change detection loop.
*/
@Injectable({
  providedIn: 'root'
})
export class ActivityIndicatorService {

  constructor(
    @Inject("ActivityIndicator") private activityIndicator: fairAny,
    @Inject('$rootScope') private $rootScope: fairAny,
  ) {}

    waiting(text: string) {
        this.activityIndicator.waiting(text);
        this.$rootScope.$applyAsync();
    }

    hide() {
        this.activityIndicator.hide();
        this.$rootScope.$applyAsync();
    }

    success(text: string, time?: number) {
        this.activityIndicator.success(text, time);
        this.$rootScope.$applyAsync();
    }

    warning(text: string, time?: number) {
        this.activityIndicator.warning(text, time);
        this.$rootScope.$applyAsync();
    }

    info(text: string, time?: number) {
        this.activityIndicator.info(text, time);
        this.$rootScope.$applyAsync();
    }

    error(text: string, time?: number) {
        this.activityIndicator.error(text, time);
        this.$rootScope.$applyAsync();
    }

}
