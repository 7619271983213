<modal-header title="Add content to workspace" icon="dku-icon-plus-16"></modal-header>
<div class="modal-body">
    <api-error-alert
        [apiError]="errors$ | async"
        (resetError)="resetError()"
        [closable]="true">
    </api-error-alert>
    <workspace-add-objects
        [workspace]="workspace"
        (onChange)="onObjectsChanged($event)"
        (onError)="onError($event)">
    </workspace-add-objects>
    <div class="modal-footer modal-footer-std-buttons">
        <div class="pull-right">
            <button type="button" class="btn btn--secondary btn--text" (click)="cancel()">Cancel</button>
            <button type="submit" class="btn btn--primary btn--text" (click)="confirm()" [disabled]="!objects.length" autofocus>
                Add content
            </button>
        </div>
    </div>
</div>
