import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewContainerRef } from "@angular/core";
import { CardAction } from "@features/eda/worksheet/cards/events";
import { CardCreationHelper } from "@features/eda/worksheet/cards/help/card-creation-helper";
import { ModalsService } from "@shared/modals/modals.service";
import { MannKendallTestCard } from "src/generated-sources";

@Component({
    selector: 'mann-kendall-test-card-help',
    templateUrl: './mann-kendall-test-card-help.component.html',
    styleUrls: ['./mann-kendall-test-card-help.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MannKendallTestCardHelpComponent {
    @Input() params: MannKendallTestCard;
    @Input() readOnly: boolean;
    @Input() extendedActions: boolean;
    @Output() action = new EventEmitter<CardAction>();

    private cardCreationHelper: CardCreationHelper;

    constructor(
        modalsService: ModalsService,
        viewContainerRef: ViewContainerRef,
    ) {
        this.cardCreationHelper = new CardCreationHelper(
            modalsService,
            viewContainerRef,
            this.action
        );
    }

    get canAddCard() {
        return !this.readOnly && this.extendedActions;
    }

    addACFPlotCard() {
        if (this.canAddCard) {
            this.cardCreationHelper.createACFPlotCard(this.params);
        }
    }

    addDurbinWatsonCard() {
        if (this.canAddCard) {
            this.cardCreationHelper.createDurbinWatsonCard(this.params);
        }
    }
}
