import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DkuSliderDirective } from './dku-slider.directive';

@NgModule({
  declarations: [
    DkuSliderDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DkuSliderDirective
  ]
})
export class DkuSliderModule { }
