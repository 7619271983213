import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { BasePermissions } from 'src/generated-sources';

@Component({
    selector: 'dss-data-collection-header',
    templateUrl: './data-collection-header.component.html',
    styleUrls: ['./data-collection-header.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataCollectionHeaderComponent {
    @Input() readonly id!: string;
    @Input() readonly displayName!: string;
    @Input() readonly isEmptyCollection!: boolean;
    @Input() readonly permissions: BasePermissions.Permissions;
    @Input() readonly mayPublishToDataCollections: boolean = false;
    @Output() addObjectsToCollection = new EventEmitter<void>();
    @Output() editPermissions = new EventEmitter<void>();
    @Output() deleteCollection = new EventEmitter<void>();

    constructor(
    ){}
}
