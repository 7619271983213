import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.SignTest1Samp
 */
export interface SignTest1Samp extends Computation._UnivariateComputation {
    hypothesizedMedian: number;

    // PolyJson type
    type: 'sign_test_1samp';
}

export namespace SignTest1Samp {
    export const type = 'sign_test_1samp';
}

/** @deprecated Use Computation.isSignTest1Samp() */ export function isSignTest1Samp(toTest: Computation): toTest is SignTest1Samp { return SignTest1Samp.type === toTest['type']; }

export namespace SignTest1Samp {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.SignTest1Samp$SignTest1SampResult
     */
    export interface SignTest1SampResult extends _AvailableResult {
        nbLarger: number;
        nbSmaller: number;
        pvalue: number;
        pvalueAltGt: number;
        pvalueAltLt: number;

        // PolyJson type
        type: 'sign_test_1samp';
    }

    export namespace SignTest1SampResult {
        export const type = 'sign_test_1samp';
    }

    /** @deprecated Use ComputationResult.isSignTest1SampResult() */ export function isSignTest1SampResult(toTest: ComputationResult): toTest is SignTest1SampResult { return SignTest1SampResult.type === toTest['type']; }
}