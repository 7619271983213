import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'values-display-form',
    templateUrl: './values-display-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ValuesDisplayFormComponent {

    public showOverlapSettings$ = new BehaviorSubject(true);

    public overlappingStrategies = [{
        id: ChartDef.ValuesInChartOverlappingStrategy.AUTO,
        name: 'Hide overlapping values'
    }, {
        id: ChartDef.ValuesInChartOverlappingStrategy.ALL,
        name: 'Display all values'
    }];

    public displayOptionsForm = new FormGroup({
        overlappingStrategy: new FormControl(ChartDef.ValuesInChartOverlappingStrategy.AUTO),
        textFormatting: new FormControl()
    });

    @Input()
    set valuesInChartDisplayOptions(value: ChartDef.ValuesInChartDisplayOptions) {
        if (!this.equals(value, this.displayOptionsForm.getRawValue())) {
            this.displayOptionsForm.patchValue(value);
        }
    }

    @Input()
    set showOverlapSettings(value: boolean) {
        this.showOverlapSettings$.next(value);
        if (value === false) {
            this.displayOptionsForm.removeControl('overlappingStrategy');
        }
    }

    @Input()
    showBackgroundSettings = true;

    @Input()
    showFontColorSettings = true;
    
    @Output() valuesDisplayOptionsChange = new EventEmitter<ChartDef.ValuesInChartDisplayOptions>();

    constructor() {
        this.displayOptionsForm.valueChanges
            .pipe(debounceTime(10))
            .subscribe((nextValue: ChartDef.ValuesInChartDisplayOptions) => {
                this.valuesDisplayOptionsChange.emit({ ...nextValue });
            });
    }

    equals(displayOptions: ChartDef.ValuesInChartDisplayOptions, displayOptionsForm: ChartDef.ValuesInChartDisplayOptions) {
        return displayOptions && displayOptions.overlappingStrategy === displayOptionsForm.overlappingStrategy
            && displayOptions.textFormatting === displayOptionsForm.textFormatting;
    }
}
