<editable-list 
    [itemsFormArray]="itemsFormArray"
    [addLabel]="addLabel" 
    [sortable]="sortable"
    (onAdd)="onAdd.emit($event)" 
    (onDelete)="onDelete.emit($event)"
    (onValidityChange)="onValidityChange.emit($event)">
    <ng-template let-item="item" let-goToNext="goToNext">
        <editable-list-template (onInputEnter)="goToNext($event)">  
            <editable-list-input 
                [inputControl]="item?.get(keyIdentifier)"
                placeholder="{{keyPlaceholder}}"
                autocomplete="off"
                name="credentials-name"
                [required]="keyRequired"
                [warnIfTrimmable]="warnIfTrimmable">
            </editable-list-input>
            <editable-list-arrow></editable-list-arrow>
            <editable-list-input
                type="{{item?.get('secret').value ? 'password' : 'text'}}"
                [inputControl]="item?.get(valueIdentifier)"
                placeholder="{{valuePlaceholder}}"
                name="credentials-value"
                autocomplete="off"
                [required]="valueRequired"
                [warnIfTrimmable]="warnIfTrimmable">
            </editable-list-input>
            <div class="credentials-list__checkbox">
                <input
                    #secret
                    [formControl]="item?.get('secret')"
                    type="checkbox"
                    class="credentials-list__checkbox-input" />
                <label class="credentials-list__checkbox-label" (click)="secret.click()">
                    Secret
                </label>
            </div>
            <div class="credentials-list__checkbox" *ngIf="canBeFinal">
                <input
                    #final
                    [formControl]="item?.get('isFinal')"
                    type="checkbox"
                    class="credentials-list__checkbox-input" />
                <label class="credentials-list__checkbox-label" (click)="final.click()">
                    Final
                </label>
            </div>
        </editable-list-template>
    </ng-template>
</editable-list>
