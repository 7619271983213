import { Directive, Renderer2, ElementRef, HostListener, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
    selector: '[commaSeparatedView]',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CommaSeparatedViewDirective),
        multi: true
    }]
})
export class CommaSeparatedViewDirective implements ControlValueAccessor {
    onChange: any = () => {};
    onTouch: any = () => {};

    constructor(private renderer: Renderer2,
        private element: ElementRef) {
    }
    @HostListener('input', ['$event.target.value'])
    input(value: string) {
        this.onChange(value.split(',').filter(Boolean));
    }
    writeValue(value: any): void {
        const element = this.element.nativeElement;
        this.renderer.setProperty(element, 'value', value?value.join(','):'');
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

}
