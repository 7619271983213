import { Inject, Injectable } from '@angular/core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { getDataCollectionItemRef, getObjectTaggableTypeFromInfo } from '@features/data-collections/utils';
import { fairAny, WT1Service } from 'dku-frontend-core';
import { tap } from 'rxjs';
import { TaggableObjectsService, ProjectsService, UIDataCollection, BasePermissions, AbstractDataCollectionItemRef } from 'src/generated-sources';

@Injectable({
    providedIn: 'root'
})
export class DataCollectionsService {
    constructor(
        private dataikuAPI: DataikuAPIService,
        private currentRouteService: CurrentRouteService,
        @Inject('$rootScope') private $rootScope: fairAny,
        private WT1Service: WT1Service,
    ) { }

    public getProjectContext() {
        // the type is string, but it actually can be undefined in some contexts!
        const projectKey = this.currentRouteService.projectKey as string | undefined;
        if(projectKey) {
            return this.$rootScope.projectSummary as ProjectsService.UIProject;
        } else {
            return undefined;
        }
    }

    public mayCreateDataCollections() {
        return (this.$rootScope.appConfig?.loggedIn
            && this.$rootScope.appConfig.globalPermissions.mayCreateDataCollections) === true;
    }

    public mayPublishToDataCollections() {
        return (this.$rootScope.appConfig?.loggedIn
            && this.$rootScope.appConfig.globalPermissions.mayPublishToDataCollections) === true;
    }

    public getFlowZoneContext() {
        // the type is string | null, but it actually can be undefined! I force it to undefined
        return this.currentRouteService.params.get('zoneId') ?? undefined;
    }

    create(creationInfo: UIDataCollection.CreationInfo) {
        return this.dataikuAPI.dataCollections.create(creationInfo).pipe(
            tap((id) => this.WT1Service.event('data-collection-creation', {
                dataCollectionh: this.WT1Service.md5(id)
            }))
        );
    }

    getInfo(id: string) {
        return this.dataikuAPI.dataCollections.getInfo(id);
    }

    updateMetadata(id: string, metadata: UIDataCollection.MetadataInfo) {
        return this.dataikuAPI.dataCollections.updateMetadata(id, metadata);
    }

    updatePermissions(id: string, permissions: BasePermissions.PermissionItem[]) {
        return this.dataikuAPI.dataCollections.updatePermissions(id, permissions);
    }

    addObjects(id: string, items: TaggableObjectsService.TaggableObjectRef[], options: UIDataCollection.AddObjectsOptions) {
        return this.dataikuAPI.dataCollections.addObjects(id, items, options).pipe(
            tap(() => items.forEach(item => this.WT1Service.event('data-collection-object-added', {
                from: 'data-collection',
                objectType: item.type,
                dataCollectionh: this.WT1Service.md5(id),
                originProjecth: this.WT1Service.md5(item.projectKey),
            })))
        );
    }

    public listWithDetails() {
        return this.dataikuAPI.dataCollections.listWithDetails();
    }

    listAllTags() {
        return this.dataikuAPI.dataCollections.listAllTags();
    }

    delete(id: string) {
        return this.dataikuAPI.dataCollections.delete(id).pipe(
            tap(() => this.WT1Service.event('data-collection-deletion', {
                dataCollectionh: this.WT1Service.md5(id)
            }))
        );
    }

    getItemDetails(dataCollectionId: string, ref: AbstractDataCollectionItemRef) {
        return this.dataikuAPI.dataCollections.getItemDetails(dataCollectionId, ref);
    }

    removeItem(dataCollectionId: string, item: UIDataCollection.AbstractDataCollectionItemInfo) {
        return this.dataikuAPI.dataCollections.removeItem(dataCollectionId, getDataCollectionItemRef(item)).pipe(
            tap(() => this.WT1Service.event('data-collection-object-removed', {
                objectType: getObjectTaggableTypeFromInfo(item),
                dataCollectionh: this.WT1Service.md5(dataCollectionId)
            }))
        );
    }
}
