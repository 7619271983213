import { Injectable } from '@angular/core';
import { ChartType } from '@model-main/pivot/frontend/model/chart-type';
import { UnitSymbol } from '../enums/unit-symbol.enum';
import { MultiplierInfo } from '../interfaces';

@Injectable({
    providedIn: 'root'
})
/**
 * Service listing several charts generic static constants. 
 * Should probably become a constant file instead (sc-76372) 
 * (!) This service previously was in:
 * - static/dataiku/js/simple_report/services/core/charts-static-data.service.js
 * - static/dataiku/js/simple_report/charts_core.js
 */
export class ChartStaticDataService {
    private GROUP_FIXED_TIMELINE = 'Fixed timeline';
    private GROUP_DYNAMIC_TIMELINE = 'Dynamic timeline';
    private GROUP_REGROUP = 'Regroup';
    private AUTOMATIC = this.createDateMode('AUTOMATIC', 'Automatic', this.GROUP_DYNAMIC_TIMELINE);
    YEAR = this.createDateMode('YEAR', 'Year', this.GROUP_FIXED_TIMELINE);
    private QUARTER = this.createDateMode('QUARTER', 'Quarter', this.GROUP_FIXED_TIMELINE);
    private MONTH = this.createDateMode('MONTH', 'Month', this.GROUP_FIXED_TIMELINE);
    private WEEK = this.createDateMode('WEEK', 'Week', this.GROUP_FIXED_TIMELINE);
    private DAY = this.createDateMode('DAY', 'Day', this.GROUP_FIXED_TIMELINE);
    private HOUR = this.createDateMode('HOUR', 'Hour', this.GROUP_FIXED_TIMELINE);
    private MINUTE = this.createDateMode('MINUTE', 'Minute', this.GROUP_FIXED_TIMELINE);
    private SECOND = this.createDateMode('SECOND', 'Second', this.GROUP_FIXED_TIMELINE);
    QUARTER_OF_YEAR = this.createDateMode('QUARTER_OF_YEAR', 'Quarter of year', this.GROUP_REGROUP);
    MONTH_OF_YEAR = this.createDateMode('MONTH_OF_YEAR', 'Month of year', this.GROUP_REGROUP);
    WEEK_OF_YEAR = this.createDateMode('WEEK_OF_YEAR', 'Week of year', this.GROUP_REGROUP);
    DAY_OF_MONTH = this.createDateMode('DAY_OF_MONTH', 'Day of month', this.GROUP_REGROUP);
    DAY_OF_WEEK = this.createDateMode('DAY_OF_WEEK', 'Day of week', this.GROUP_REGROUP);
    HOUR_OF_DAY = this.createDateMode('HOUR_OF_DAY', 'Hour of day', this.GROUP_REGROUP);
    INDIVIDUAL = this.createDateMode('INDIVIDUAL', 'Individual dates', this.GROUP_REGROUP);
    RELATIVE_YEAR = this.createDateMode('YEAR', 'Year', this.GROUP_FIXED_TIMELINE);
    RELATIVE_QUARTER = this.createDateMode('QUARTER_OF_YEAR', 'Quarter', this.GROUP_FIXED_TIMELINE);
    RELATIVE_MONTH = this.createDateMode('MONTH_OF_YEAR', 'Month', this.GROUP_FIXED_TIMELINE);
    RELATIVE_DAY = this.createDateMode('DAY_OF_MONTH', 'Day', this.GROUP_FIXED_TIMELINE);
    RELATIVE_HOUR = this.createDateMode('HOUR_OF_DAY', 'Hour', this.GROUP_FIXED_TIMELINE);

    private TIMELINE_DATE_MODES = [
        this.YEAR,
        this.QUARTER,
        this.MONTH,
        this.WEEK,
        this.DAY,
        this.HOUR,
        this.MINUTE,
        this.SECOND
    ];

    private GROUPED_DATE_MODES = [
        this.QUARTER_OF_YEAR,
        this.MONTH_OF_YEAR,
        this.WEEK_OF_YEAR,
        this.DAY_OF_MONTH,
        this.DAY_OF_WEEK,
        this.HOUR_OF_DAY
    ];

    private BACKEND_ONLY_DATE_MODES = [
        this.createDateMode('QUARTER_OF_DAY', 'Quarter of day', 'NA'),
        this.createDateMode('QUARTER_OF_HOUR', 'Quarter of hour', 'NA'),
        this.createDateMode('QUARTER_OF_MINUTE', 'Quarter of minute', 'NA')
    ];

    private DATE_MODES = [this.AUTOMATIC].concat(this.TIMELINE_DATE_MODES).concat(this.GROUPED_DATE_MODES);

    NUMERICAL_BIN_NUMBERS = [
        this.buildBinNumberConfiguration(ChartType.grouped_columns, 10, 5),
        this.buildBinNumberConfiguration(ChartType.stacked_bars, 10, 5),
        this.buildBinNumberConfiguration(ChartType.stacked_columns, 10, 5),
        this.buildBinNumberConfiguration(ChartType.binned_xy, 10, 10)
    ];

    AUTOMATIC_MAX_BIN_NUMBERS = [
        this.buildBinNumberConfiguration(ChartType.grouped_columns, 30, 5),
        this.buildBinNumberConfiguration(ChartType.stacked_bars, 30, 5),
        this.buildBinNumberConfiguration(ChartType.stacked_columns, 30, 5),
        this.buildBinNumberConfiguration(ChartType.binned_xy, 10, 10),
        this.buildBinNumberConfiguration(ChartType.lines, 1000, 10),
        this.buildBinNumberConfiguration(ChartType.stacked_area, 1000, 10),
        this.buildBinNumberConfiguration(ChartType.multi_columns_lines, 30, 10),
        this.buildBinNumberConfiguration(ChartType.pie, 30, 10)
    ];

    private ALL_MULTIPLIERS: Record<string, MultiplierInfo> = {
        NONE: { label: 'None', powerOfTen: 0 },
        THOUSANDS: { label: 'Thousands', powerOfTen: 3, symbol: UnitSymbol.K },
        MILLIONS: { label: 'Millions', powerOfTen: 6, symbol: UnitSymbol.M },
        BILLIONS: { label: 'Billions', powerOfTen: 9, symbol: UnitSymbol.B },
        TRILLIONS: { label: 'Trillions', powerOfTen: 12, symbol: UnitSymbol.T },
        QUADRILLIONS: { label: 'quadrillions', powerOfTen: 15, symbol: UnitSymbol.P },
        QUINTILLIONS: { label: 'quintillions', powerOfTen: 18, symbol: UnitSymbol.E },
        SEXTILLIONS: { label: 'sextillions', powerOfTen: 21, symbol: UnitSymbol.Z },
        SEPTILLIONS: { label: 'septillions', powerOfTen: 24, symbol: UnitSymbol.Y }
    };

    private AVAILABLE_MULTIPLIERS: Record<string, MultiplierInfo> = {
        AUTO: { label: 'Auto' },
        NONE: this.ALL_MULTIPLIERS.NONE,
        THOUSANDS: this.ALL_MULTIPLIERS.THOUSANDS,
        MILLIONS: this.ALL_MULTIPLIERS.MILLIONS,
        BILLIONS: this.ALL_MULTIPLIERS.BILLIONS
    };

    DEFAULT_DATE_RANGE_FILTER_TYPE = this.createDateMode('RANGE', 'Date range');
    DEFAULT_DATE_RELATIVE_FILTER_TYPE = this.createDateMode('RELATIVE', 'Relative range');
    DEFAULT_DATE_PART_FILTER_TYPE = this.createDateMode('PART', 'Date part');

    TIMELINE_AND_AUTOMATIC_DATE_MODES = [this.AUTOMATIC].concat(this.TIMELINE_DATE_MODES);
    DATE_MODES_WITH_BACKEND_ONLY = this.DATE_MODES.concat(this.BACKEND_ONLY_DATE_MODES);

    BIN_NUMBER_DEFAULT = this.buildBinNumberConfiguration('default', 30, 30);

    DEFAULT_COLOR_OPTIONS = {
        singleColor: '#2678B1',
        transparency: 0.75
    };

    DEFAULT_FILL_OPACITY = 0.6;

    DEFAULT_STROKE_WIDTH = 2;

    AUTO_EXTENT_MODE = 'AUTO';

    //Margin for the chart
    CHART_BASE_MARGIN = 15;
    //Axis line width + tick length
    AXIS_WIDTH = 6;
    //Margin between the axis and the axis title
    AXIS_MARGIN = 4;

    MANUAL_EXTENT_MODE = 'MANUAL';

    AUTOMATIC_DATE_MODE = this.AUTOMATIC;

    MEASURES_PERCENT_MODES = ['PERCENT', 'PERCENTAGE', 'CUMULATIVE_PERCENTAGE'];

    stdAggrMeasureComputeModes = {
        NORMAL: ['NORMAL', 'Normal'],
        // "INDICE_100": ["INDICE_100", "100-indexed"],
        CUMULATIVE: ['CUMULATIVE', 'Cumulative values'],
        DIFFERENCE: ['DIFFERENCE', 'Differencial values'],
        LOG_SCALE: ['LOG_SCALE', 'Log scale'],
        PERCENTAGE: ['PERCENTAGE', 'Percentage scale'],
        CUMULATIVE_PERCENTAGE: ['CUMULATIVE_PERCENTAGE', 'Cumulative percentage scale'],
        AVG_RATIO: ['AVG_RATIO', 'Ratio to average']
    };

    mapAdminLevels = [
        [2, 'Country'],
        [4, 'Region/State'],
        [6, 'Department/County'],
        [7, 'Metropolis'],
        [8, 'City']
    ];

    dateModes = this.DATE_MODES;
    defaultDateMode = this.AUTOMATIC;

    availableMultipliers = this.AVAILABLE_MULTIPLIERS;
    allMultipliers = this.ALL_MULTIPLIERS;
    highestMultiplier = this.ALL_MULTIPLIERS.SEPTILLIONS;
    autoMultiplier = this.AVAILABLE_MULTIPLIERS.AUTO;

    DEFAULT_CUSTOM_EXTENT = {
        editMode: this.AUTO_EXTENT_MODE,
        manualExtent: [null, null]
    };

    extentEditModes = {
        AUTO_EXTENT_MODE: { value: this.AUTO_EXTENT_MODE, label: 'Auto' },
        MANUAL_EXTENT_MODE: { value: this.MANUAL_EXTENT_MODE, label: 'Manual' }
    };

    pivotMeasureDisplayModes = {
        ROWS: 'ROWS',
        COLUMNS: 'COLUMNS'
    };

    defaultPivotDisplayTotals = {
        subTotals: { rows: true, columns: true },
        grandTotal: { row: true, column: true }
    };

    GEOM_AGGREGATIONS = {
        DEFAULT: undefined,
        DISTINCT: 'DISTINCT'
    };

    AVAILABLE_AGGREGATIONS = {
        ALPHANUM: ['COUNT', 'COUNTD'],
        NUMERICAL: ['COUNTD', 'COUNT', 'SUM', 'AVG', 'MIN', 'MAX']
    };

    LABEL_POSITIONS = {
        BOTTOM: 'BOTTOM',
        TOP: 'TOP'
    };

    COLOR_RULE_MODE_MAPPING = {
        LOWER_THAN: '<',
        LOWER_OR_EQUAL_THAN: '≤',
        GREATER_THAN: '>',
        GREATER_OR_EQUAL_THAN: '≥'
    };

    private createDateMode(value: string, label:string, group?: string): {group?: string, value: string, label: string} {
        return {
            group,
            value,
            label
        };
    }

    private buildBinNumberConfiguration(chartType: ChartType | 'default', valueForMainDimension: number, valueForOtherDimension: number) {
        return {
            chartType,
            valueForMainDimension,
            valueForOtherDimension
        };
    }
}
