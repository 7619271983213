import type {CardResult} from './../cards/card-result';
import type {Card} from './../cards/card';
import type {ComputationResult} from './../../compute/computations/computation-result';
import type {Computation} from './../../compute/computations/computation';
import type {InteractiveQuery, _InteractiveQuery} from './interactive-query';

/**
 * Generated from com.dataiku.dip.eda.worksheets.queries.DebugCard
 */
export interface DebugCard extends _InteractiveQuery {
    card: Card;

    // PolyJson type
    type: 'debug_card';
}

export namespace DebugCard {
    export const type = 'debug_card';
}

/** @deprecated Use InteractiveQuery.isDebugCard() */ export function isDebugCard(toTest: InteractiveQuery): toTest is DebugCard { return DebugCard.type === toTest['type']; }

export namespace DebugCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.queries.DebugCard$DebugCardResult
     */
    export interface DebugCardResult extends InteractiveQuery._InteractiveQueryResult {
        card: Card;
        cardResult: CardResult;
        computation: Computation;
        computationResult: ComputationResult;
        estimatedCacheUsageFactorized: number;
        estimatedCacheUsageFlattened: number;

        // PolyJson type
        type: 'debug_card';
    }

    export namespace DebugCardResult {
        export const type = 'debug_card';
    }

    /** @deprecated Use InteractiveQuery.InteractiveQueryResult.isDebugCardResult() */ export function isDebugCardResult(toTest: InteractiveQuery.InteractiveQueryResult): toTest is DebugCardResult { return DebugCardResult.type === toTest['type']; }
}