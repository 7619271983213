import type {AbstractCardContainer, _AbstractCardContainer} from './abstract-card-container';
import type {BivariateHeaderCard} from './../bivariate_header/bivariate-header-card';
import type {CardResult} from './../card-result';
import type {Card} from './../card';
import type {UnivariateHeaderCard} from './../univariate_header/univariate-header-card';
import type {Variable} from './../../models/variable';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.common.AbstractHeaderCard
 */
export interface _AbstractHeaderCard extends _AbstractCardContainer {
    xColumns: Variable[];
}

export type AbstractHeaderCard = BivariateHeaderCard | UnivariateHeaderCard;

export namespace AbstractHeaderCard {
    export const type = ['bivariate_header', 'univariate_header'] as const;
}

/** @deprecated Use Card.isAbstractHeaderCard() */ export function isAbstractHeaderCard(toTest: Card): toTest is AbstractHeaderCard { return (AbstractHeaderCard.type as readonly unknown[]).includes(toTest['type']); }

export namespace AbstractHeaderCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.common.AbstractHeaderCard$AbstractHeaderCardResult
     */
    export interface _AbstractHeaderCardResult extends AbstractCardContainer._AbstractCardContainerResult {}

    export type AbstractHeaderCardResult = BivariateHeaderCard.BivariateHeaderCardResult | UnivariateHeaderCard.UnivariateHeaderCardResult;

    export namespace AbstractHeaderCardResult {
        export const type = ['bivariate_header', 'univariate_header'] as const;
    }

    /** @deprecated Use CardResult.isAbstractHeaderCardResult() */ export function isAbstractHeaderCardResult(toTest: CardResult): toTest is AbstractHeaderCardResult { return (AbstractHeaderCardResult.type as readonly unknown[]).includes(toTest['type']); }
}