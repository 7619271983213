export namespace EnrichedDashboardPage {
    /**
     * Generated from com.dataiku.dip.dashboards.model.ui.EnrichedDashboardPage$InsightAccessState
     */
    export enum InsightAccessState {
        DELETED = 'DELETED',
        NOT_EXPOSED = 'NOT_EXPOSED',
        ANALYST = 'ANALYST',
        READER = 'READER'
    }
}