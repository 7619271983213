import { Directive, Type, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';
import { ObjectViewerInputs } from './object-viewer-inputs.model';
import { ObjectViewerOutputs } from './object-viewer-outputs.model';

@UntilDestroy()
@Directive({
    selector: '[objectViewerHost]'
})
export class ObjectViewerHostDirective {
    constructor(public viewContainerRef: ViewContainerRef) { }

    public createComponent(
        component: Type<any>,
        inputs?: ObjectViewerInputs,
        outputs?: ObjectViewerOutputs
    ) {
        this.viewContainerRef.clear();

        const componentRef = this.viewContainerRef.createComponent(component);

        if (inputs) {
            Object.entries(inputs).forEach(([key, value]) => {
                componentRef.instance[key] = value;
            });
        }

        if (outputs) {
            Object.entries(outputs).forEach(([key, callback]) => {
                componentRef.instance[key]
                    .pipe(
                        tap(event => callback(event)),
                        untilDestroyed(this)
                    )
                    .subscribe();
            });
        }
    }
}
