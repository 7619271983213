<div class="mouse-value">
    <span [hidden]="mouseValue == null">
        <b>Density:</b>
        {{ mouseValue | smarterNumber }}
    </span>
    &nbsp;
</div>

<div
    inViewport
    [inViewportOptions]="{ threshold: [0] }"
    (inViewportAction)="onIntersection($event)"
    class="chart-wrapper"
>
    <div
        echarts
        class="chart"
        *ngIf="chartData$ | async as chartData"
        [options]="chartData.chartOptions"
        [class.chart--fixed-height-tall]="hasFixedHeight"
        (chartInit)="onChartInitialized($event)"
        (chartMouseMove)="onMouseOver($event)"
        (chartMouseOver)="onMouseOver($event)"
        (chartMouseOut)="onMouseOut()"
    >
    </div>
</div>