export namespace NPSSurveyService {
    /**
     * Generated from com.dataiku.dip.server.services.NPSSurveyService$NPSSurveyState
     */
    export enum NPSSurveyState {
        INITIAL = 'INITIAL',
        INITIAL_POSTPONED = 'INITIAL_POSTPONED',
        RECURRING = 'RECURRING',
        RECURRING_POSTPONED = 'RECURRING_POSTPONED',
        OPTOUT = 'OPTOUT'
    }

    /**
     * Generated from com.dataiku.dip.server.services.NPSSurveyService$NPSSurveySettings
     */
    export interface NPSSurveySettings {
        nextAction: number;
        state: NPSSurveyService.NPSSurveyState;
    }
}