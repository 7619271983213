import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'friendlyDateTime'
})
export class FriendlyDateTimePipe implements PipeTransform {

    transform(time: number): string {
        function sameDay(date1: Date, date2: Date) {
            return date1.getFullYear() === date2.getFullYear() &&
                   date1.getMonth() === date2.getMonth() &&
                   date1.getDate() === date2.getDate();
        }

        let today = new Date(),
            yesterday = new Date(),
            tomorrow = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        tomorrow.setDate(tomorrow.getDate() + 1);

        let datePipe = new DatePipe("en-US");
        let date = new Date(time);
        let datePart;
        if (sameDay(date, today)) {
            datePart = 'Today';
        } else if (sameDay(date, yesterday)) {
            datePart = 'Yesterday';
        } else if (sameDay(date, tomorrow)) {
            datePart = 'Tomorrow';
        } else {
            datePart = datePipe.transform(date, 'EEEE, d MMMM');
        }

        return datePart + ' at ' + datePipe.transform(date, 'HH:mm');
    }

}
