import { Component, ChangeDetectionStrategy, Inject, OnInit } from '@angular/core';
import { UIState } from '@shared/models';
import { DataCatalogService } from '../data-catalog.service';

@Component({
  selector: 'data-catalog-nav',
  templateUrl: './data-catalog-nav.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataCatalogNavComponent implements OnInit {
    hasIndexedConnections$ = this.dataCatalogService.hasIndexedConnections;
    statePrefix: string = 'datacatalog';

    constructor(
        private dataCatalogService: DataCatalogService,
        @Inject('$state') private $state: UIState,
    ) {
    }

    ngOnInit(): void {
        // Read the current state to see if we are inside a project or not.
        // If current state name doesn't contain datacatalog we fallback on the out-of-project routes, but that should never happen
        this.statePrefix = this.$state.current.name.match(/^.*\.datacatalog/)?.[0] ?? 'datacatalog';
    }

    route(relativeRoute: string): string {
        return this.statePrefix + relativeRoute;
    }
}
