<div class="span2 offset0 nav-list-sidebar sidebar-admin modal-footer-std-buttons">
    <ul>
        <li class="l1" *ngFor="let tab of tabs" [ngClass]="{ 'active': tab === activeTab }" (click)="open(tab)">
            <a>{{ tab.label }}</a>
        </li>
    </ul>
</div>
<div class="span10 h100 offset0 boxed-next-to-sidebar no-padding">
    <div class="h100 oa paned-settings-page-pane paned-settings-page-pane">
        <ng-container *ngIf="activeTab">
            <ng-container *ngTemplateOutlet="activeTab.template"></ng-container> 
        </ng-container>
    </div>
</div>
