import type {CardResult} from './card-result';
import type {Card} from './card';
import type {TimeSeriesCard, _TimeSeriesCard} from './time-series-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.DurbinWatsonCard
 */
export interface DurbinWatsonCard extends _TimeSeriesCard {
    // PolyJson type
    type: 'durbin_watson';
}

export namespace DurbinWatsonCard {
    export const type = 'durbin_watson';
}

/** @deprecated Use Card.isDurbinWatsonCard() */ export function isDurbinWatsonCard(toTest: Card): toTest is DurbinWatsonCard { return DurbinWatsonCard.type === toTest['type']; }

export namespace DurbinWatsonCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.DurbinWatsonCard$DurbinWatsonCardResult
     */
    export interface DurbinWatsonCardResult extends TimeSeriesCard._TimeSeriesCardResult {
        statistic: number;

        // PolyJson type
        type: 'durbin_watson';
    }

    export namespace DurbinWatsonCardResult {
        export const type = 'durbin_watson';
    }

    /** @deprecated Use CardResult.isDurbinWatsonCardResult() */ export function isDurbinWatsonCardResult(toTest: CardResult): toTest is DurbinWatsonCardResult { return DurbinWatsonCardResult.type === toTest['type']; }
}