import { Component, Input, OnChanges, SimpleChanges, ElementRef, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import _ from 'lodash';
import { scaleLinear } from 'd3-scale';
import { HeatmapParams } from '@model-main/eda/worksheets/cards/common/heatmap-params';

const NO_SORT = -1;
const SCALEBAR_VERTICAL_MARGIN = 12;
const CANVAS_WIDTH = 70;
const ZERO_PLUS_MINUS_OFFSET = 4;
const ZERO_PLUS_MINUS_VERTICAL_MARGIN = 20;
const CANVAS_COLORSCALE_OFFSET = 15;
const CANVAS_COLORSCALE_WIDTH = 10;

@Component({
    selector: 'heatmap',
    templateUrl: './heatmap.component.html',
    styleUrls: ['./heatmap.component.less']
})
export class HeatmapComponent implements OnChanges {
    @Input() xLabels: string[];
    @Input() yLabels: string[];
    @Input() data: (number | null)[][];
    @Input() warnings: (string | null)[][];
    @Input() readOnly: boolean;
    @Input() ignoreDiagonal = false;
    @Input() heatmapParams: HeatmapParams; // heatmap visualization params adjustable by the user

    @ViewChild('scaleCanvas', { static: true }) scaleCanvasRef: ElementRef;

    dataMin: number;
    dataMax: number;
    dataMaxMin: number; // dataMax - dataMin

    xVariablesWithValues: number[];
    yVariablesWithValues: number[];

    xMask: number[]; // mask to sort according to the order of values for a given x
    yMask: number[]; // mask to sort according to the order of values for a given y

    maskedXLabels: string[]; // labels ordered according to current sort
    maskedYLabels: string[]; // labels ordered according to current sort
    maskedData: (number | null)[][];
    maskedColors: (string | null)[][];
    maskedWarnings: (string | null)[][];

    lastXSort: number = NO_SORT; // last X column data was sorted according to. -1 == no sort
    lastYSort: number = NO_SORT; // last Y row data was sorted according to. -1 == no sort
    lastXSortAscending: boolean;
    lastYSortAscending: boolean;

    canvasHeight: number;
    scaleBarHeight: number;

    xCrossMasked = -1;
    yCrossMasked = -1;

    valueTransformer = (v: null | number): number => v || 0;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data) {
            const flattenedData = _.flatten(this.data);
            const tmpMin = _.min(flattenedData) || 0;
            const tmpMax = _.max(flattenedData) || 0;
            this.dataMax = Math.max(Math.abs(tmpMin), Math.abs(tmpMax));
            this.dataMin = -this.dataMax;
            this.dataMaxMin = this.dataMax - this.dataMin;
        }

        if (changes.data || changes.heatmapParams) {
            if (this.heatmapParams.filterVariablesWithoutValues) {
                this.xVariablesWithValues = [];
                for (let x = 0 ; x < this.data.length ; x++) {
                    const curVariable = this.data[x];
                    for (let y = 0 ; y < curVariable.length ; y++) {
                        const curVal = curVariable[y];
                        if (curVal && Math.abs(curVal) >= this.heatmapParams.threshold
                            && !((x === y) && this.ignoreDiagonal)) {
                            this.xVariablesWithValues.push(x);
                            break;
                        }
                    }
                }
                this.yVariablesWithValues = [];
                for (let y = 0 ; y < this.data[0].length ; y++) {
                    for (let x = 0 ; x < this.data.length ; x++) {
                        const curVal = this.data[x][y];
                        if (curVal && Math.abs(curVal) >= this.heatmapParams.threshold
                            && !((x === y) && this.ignoreDiagonal)) {
                            this.yVariablesWithValues.push(y);
                            break;
                        }
                    }
                }
            } else {
                this.xVariablesWithValues = Array.from(Array(this.data.length).keys());
                this.yVariablesWithValues = Array.from(Array(this.data[0].length).keys());
            }
        }

        if (this.heatmapParams.showAbsValues) {
            this.valueTransformer = v => Math.abs(v || 0);
        } else {
            this.valueTransformer = v => v || 0;
        }

        if (changes.xLabels || changes.yLabels || changes.data || changes.heatmapParams) {
            this.initMasks();
            this.applyMasks();
        }

        if (this.scaleCanvasRef) {
            this.drawScales();
        }
    }

    reset(): void {
        this.lastXSort = NO_SORT;
        this.lastYSort = NO_SORT;
        this.lastXSortAscending = false;
        this.lastYSortAscending = false;
        this.initMasks();
        this.applyMasks();
    }

    resetable(): boolean {
        return (this.lastXSort !== NO_SORT) || (this.lastYSort !== NO_SORT);
    }

    initMasks(): void {
        // sequence 0..Nx-1
        this.xMask = this.xVariablesWithValues.slice();
        this.yMask = this.yVariablesWithValues.slice();
    }

    trackByIndex(index: number) {
        return index;
    }

    applyMasks(): void {
        this.maskedXLabels = this.xMask.map(i => this.xLabels[i]);
        this.maskedYLabels = this.yMask.map(i => this.yLabels[i]);

        this.maskedData = [];
        this.maskedColors = [];
        this.maskedWarnings = [];
        for (let x = 0; x < this.xMask.length; x++) {
            this.maskedData[x] = [];
            this.maskedColors[x] = [];
            this.maskedWarnings[x] = [];
            for (let y = 0; y < this.yMask.length; y++) {
                this.maskedData[x][y] = this.data[this.xMask[x]][this.yMask[y]];
                this.maskedColors[x][y] = this.color(this.maskedData[x][y]);
                this.maskedWarnings[x][y] = this.warnings?this.warnings[this.xMask[x]][this.yMask[y]]:null;
            }
        }
    }

    color(param: number | null): string {
        if (param == null) {
            return `rgb(210, 210, 210)`;
        } else if (this.heatmapParams.showColors && (Math.abs(param || 0) >= this.heatmapParams.threshold)) {
            return this.computeColor(param);
        } else {
            return `rgb(255, 255, 255)`;
        }
    }

    computeColor(param: number | null): string {
        return scaleLinear<string>()
            .domain([this.dataMin, 0, this.dataMax])
            .range(['#323dff', '#FFFFFF', '#CC2222']) // color scale is the same as div.heat-gradient
            (this.valueTransformer(param));
    }

    textColor(param: number | null): string {
        if (param == null) {
            return `rgb(0, 0, 0)`;
        } else if (this.heatmapParams.showColors && Math.abs(param) > 0.5) {
            return `rgb(255,255,255)`;
        } else {
            return `rgb(0,0,0)`;
        }
    }

    showAscendingArrowX(xindex: number): boolean {
        return (xindex === this.lastXSort) && this.lastXSortAscending;
    }

    showDescendingArrowX(xindex: number): boolean {
        return (xindex === this.lastXSort) && !this.lastXSortAscending;
    }

    showAscendingArrowY(yindex: number): boolean {
        return (yindex === this.lastYSort) && this.lastYSortAscending;
    }

    showDescendingArrowY(yindex: number): boolean {
        return (yindex === this.lastYSort) && !this.lastYSortAscending;
    }

    sortForX(xindex: number): void {
        if (xindex === this.lastXSort) {
            if (this.lastXSortAscending) {
                this.lastXSort = NO_SORT;
                this.yMask = this.yVariablesWithValues.slice();
            } else {
                this.yMask = this.yMask.slice().reverse();
                this.lastXSortAscending = !this.lastXSortAscending;
                if (this.lastYSort !== NO_SORT) {
                    this.lastYSort = this.yMask.length - 1 - this.lastYSort;
                }
            }
        } else {
            // extract values for this line
            const valuesToSort = this.maskedData[xindex];
            // take a copy and store original index
            const valuesToSortWithOriIndex = valuesToSort.map((v, i) => ({ v: this.valueTransformer(v), i }));
            // sort according to value
            valuesToSortWithOriIndex.sort((y1, y2) => y1.v - y2.v);

            // got to update lastYSort according to new sorting
            // first phase, setting it to the real, masked value
            if (this.lastYSort !== NO_SORT) {
                this.lastYSort = this.yMask[this.lastYSort];
            }

            // get the mask
            this.yMask = valuesToSortWithOriIndex.map(v => this.yMask[v.i]);

            // second phase : find index of the real, masked value, in mask
            if (this.lastYSort !== NO_SORT) {
                this.lastYSort = this.yMask.indexOf(this.lastYSort);
            }

            this.lastXSort = xindex;
            this.lastXSortAscending = false;
        }
        this.applyMasks();
    }

    sortForY(yindex: number): void {
        if (yindex === this.lastYSort) {
            if (this.lastYSortAscending) {
                this.lastYSort = NO_SORT;
                this.xMask = this.xVariablesWithValues.slice();
            } else {
                this.xMask = this.xMask.slice().reverse();
                this.lastYSortAscending = !this.lastYSortAscending;
                if (this.lastXSort !== NO_SORT) {
                    this.lastXSort = this.xMask.length - 1 - this.lastXSort;
                }
            }
        } else {
            // extract values for this line
            const valuesToSort = [];
            for (let x = 0; x < this.xMask.length; x++) {
                valuesToSort.push(this.valueTransformer(this.maskedData[x][yindex]));
            }
            const valuesToSortWithOriIndex = valuesToSort.map((v, i) => { return { v, i } });
            // sort according to value
            valuesToSortWithOriIndex.sort((x1, x2) => x1.v - x2.v);

            // got to update lastXSort according to new sorting
            // first phase, setting it to the real, masked value
            if (this.lastXSort !== NO_SORT) {
                this.lastXSort = this.xMask[this.lastXSort];
            }
            // get the mask
            this.xMask = valuesToSortWithOriIndex.map(v => this.xMask[v.i]);

            // second phase : find index of the real, masked value, in mask
            if (this.lastXSort !== NO_SORT) {
                this.lastXSort = this.xMask.indexOf(this.lastXSort);
            }

            this.lastYSort = yindex;
            this.lastYSortAscending = false;
        }
        this.applyMasks();
    }

    drawScales(): void {
        const canvas: HTMLCanvasElement = this.scaleCanvasRef.nativeElement! as HTMLCanvasElement;
        const dpr = window.devicePixelRatio || 1;
        const rect = canvas.getBoundingClientRect();

        this.canvasHeight = rect.height;
        canvas.width = CANVAS_WIDTH * dpr;
        canvas.height = this.canvasHeight * dpr;
        canvas.style.width = CANVAS_WIDTH + 'px';
        canvas.style.height = this.canvasHeight + 'px';
        this.scaleBarHeight = this.canvasHeight - 2 * SCALEBAR_VERTICAL_MARGIN;

        const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;
        ctx.scale(dpr, dpr);
        ctx.fillStyle = 'rgb(255,255,255)';
        ctx.clearRect(0, 0, CANVAS_WIDTH, this.canvasHeight);

        if (!this.heatmapParams.showAbsValues) {
            const increment = this.dataMaxMin / this.scaleBarHeight;
            for (let i = 0; i < this.scaleBarHeight; i++) {
                const v = this.dataMax - increment * i;
                ctx.fillStyle = this.computeColor(v);
                ctx.fillRect(CANVAS_COLORSCALE_OFFSET, SCALEBAR_VERTICAL_MARGIN + i, CANVAS_COLORSCALE_WIDTH - 1, 1);
            }

            const y0 = this.canvasHeight / 2;
            const yM = this.canvasHeight - ZERO_PLUS_MINUS_VERTICAL_MARGIN;
            const yP = ZERO_PLUS_MINUS_VERTICAL_MARGIN;
            ctx.fillStyle = 'rgb(0,0,0)';
            ctx.fillText('0', ZERO_PLUS_MINUS_OFFSET, y0);
            ctx.fillText('+', ZERO_PLUS_MINUS_OFFSET, yP);
            ctx.fillText('-', ZERO_PLUS_MINUS_OFFSET, yM);
        } else {
            const increment = this.dataMax / this.scaleBarHeight;
            for (let i = 0; i < this.scaleBarHeight; i++) {
                const v = this.dataMax - increment * i;
                ctx.fillStyle = this.computeColor(v);
                ctx.fillRect(CANVAS_COLORSCALE_OFFSET, SCALEBAR_VERTICAL_MARGIN + i, CANVAS_COLORSCALE_WIDTH - 1, 1);
            }

            const y0 = this.canvasHeight - ZERO_PLUS_MINUS_VERTICAL_MARGIN;
            const yP = ZERO_PLUS_MINUS_VERTICAL_MARGIN;
            ctx.fillStyle = 'rgb(0,0,0)';
            ctx.fillText('0', ZERO_PLUS_MINUS_OFFSET, y0);
            ctx.fillText('+', ZERO_PLUS_MINUS_OFFSET, yP);
        }
    }

    mouseOverScale(event: MouseEvent): void {
        if (!this.heatmapParams.showColors) {
            return;
        }
        let y = event.offsetY;

        const canvas: HTMLCanvasElement = this.scaleCanvasRef.nativeElement! as HTMLCanvasElement;
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;
        ctx.clearRect(CANVAS_COLORSCALE_OFFSET + CANVAS_COLORSCALE_WIDTH + 1, 0, CANVAS_WIDTH - CANVAS_COLORSCALE_WIDTH, this.canvasHeight);

        if (y < SCALEBAR_VERTICAL_MARGIN) {
            y = SCALEBAR_VERTICAL_MARGIN;
        } else if (y > this.canvasHeight - SCALEBAR_VERTICAL_MARGIN) {
            y = this.canvasHeight - SCALEBAR_VERTICAL_MARGIN;
        }


        let increment;
        if (!this.heatmapParams.showAbsValues) {
            increment = this.dataMaxMin / this.scaleBarHeight;
        } else {
            increment = this.dataMax / this.scaleBarHeight;
        }
        let v = this.dataMax - increment * (y - SCALEBAR_VERTICAL_MARGIN);
        if (this.heatmapParams.showAbsValues) {
            // avoid printing -0,000
            v = Math.max(0, v);
        }

        this.drawValueOnScale(v, y);
    }

    mouseOverValue(v: number | null): void {
        const canvas: HTMLCanvasElement = this.scaleCanvasRef.nativeElement! as HTMLCanvasElement;
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;
        ctx.clearRect(CANVAS_COLORSCALE_OFFSET + CANVAS_COLORSCALE_WIDTH + 1, 0, CANVAS_WIDTH - CANVAS_COLORSCALE_WIDTH, this.canvasHeight);

        if (!this.heatmapParams.showColors || null == v) {
            return;
        }
        let increment: number;
        if (!this.heatmapParams.showAbsValues) {
            increment = this.dataMaxMin / this.scaleBarHeight;
        } else {
            increment = this.dataMax / this.scaleBarHeight;
        }
        const y = SCALEBAR_VERTICAL_MARGIN + (this.dataMax - this.valueTransformer(v)) / increment;

        this.drawValueOnScale(this.valueTransformer(v), y);
    }

    drawValueOnScale(v: number, y: number): void {
        const canvas: HTMLCanvasElement = this.scaleCanvasRef.nativeElement! as HTMLCanvasElement;
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;
        ctx.fillStyle = 'rgb(0,0,0)';
        ctx.font = '400 1.2em SourceSansPro';
        ctx.fillText(v.toFixed(3), CANVAS_COLORSCALE_OFFSET + CANVAS_COLORSCALE_WIDTH + 2, y)
        ctx.fillStyle = this.computeColor(v);
        ctx.fillRect(CANVAS_COLORSCALE_OFFSET + CANVAS_COLORSCALE_WIDTH + 2, y + 10, CANVAS_WIDTH - CANVAS_COLORSCALE_WIDTH - 4, 10);
    }

    mouseOutScale(): void {
        if (!this.heatmapParams.showColors) {
            return;
        }
        const canvas: HTMLCanvasElement = this.scaleCanvasRef.nativeElement! as HTMLCanvasElement;
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;
        ctx.clearRect(CANVAS_COLORSCALE_OFFSET + CANVAS_COLORSCALE_WIDTH + 1, 0, CANVAS_WIDTH - CANVAS_COLORSCALE_WIDTH, this.canvasHeight);
    }


    displayValue(v: number | null): string {
        if (null == v) {
            if (!this.heatmapParams.showColors) {
                return 'N/A';
            } else {
                return '';
            }
        } else if (Math.abs(v) >= this.heatmapParams.threshold) {
            return this.valueTransformer(v).toFixed(3);
        } else {
            return '';
        }
    }

    maskedDataOrWarning(x: number, y: number) {
        if (this.maskedData[x][y]) {
            return this.maskedData[x][y];
        }
        return this.maskedWarnings[x][y];
    }

    cross(x: number, y: number) {
        const xMasked = this.xMask[x];
        const yMasked = this.yMask[y];
        if ( (this.xCrossMasked === xMasked) && (this.yCrossMasked === yMasked) ) {
            this.xCrossMasked = -1;
            this.yCrossMasked = -1;
        } else {
            this.xCrossMasked = xMasked;
            this.yCrossMasked = yMasked;
        }
    }

    horizontalCross(x: number, y: number) {
        const xMasked = this.xMask[x];
        const yMasked = this.yMask[y];
        return ((this.yCrossMasked !== -1) && (xMasked !== this.xCrossMasked) && (yMasked === this.yCrossMasked));
    }

    verticalCross(x: number, y: number) {
        const xMasked = this.xMask[x];
        const yMasked = this.yMask[y];
        return ((this.xCrossMasked !== -1) && (xMasked === this.xCrossMasked) && (yMasked !== this.yCrossMasked));
    }
}
