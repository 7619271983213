<collapsible-right-panel-layout [availableTabs]="rightPanelTabs"
    [(selectedTab)]="rightPanelSelectedTab"
    [(isRightPanelOpened)]="isRightPanelOpened">
    <ng-container main-panel>
        <div class="data-sources__content">
            <data-catalog-nav></data-catalog-nav>
            <api-error-alert-from-context></api-error-alert-from-context>
            <data-sources-top-bar
                [searchQuery]="query$.value" (searchQueryChange)="query$.next($event)"
                [selectedTab]="selectedTab$.value" (selectedTabChange)="selectedTab$.next($event)"
                [facets]="facets$.value" (facetsChange)="onFacetChange($event)"
                [itemCount]="itemCount"
                [aggregations]="aggregations"
                (resetFilters)="resetFilters()"
            >
                <div *ngIf="isMultiSelectEnabled()" mass-action-menu class="data-sources__mass-action-menu">
                    <mass-action-menu
                        [selectedItems]="multiSelectedItems"
                        (selectedItemsChange)="setMultiSelectedItems($event)"
                        [selectableItems]="itemsToShow"
                        [options]="massActionOptions"
                    >
                    </mass-action-menu>
                    <button *ngIf="this.multiSelectedItems.length > 0"
                        class="btn btn-link"
                        (click)="toggleShowSelected()">
                        {{ this.multiSelectedItems.length }} selected
                    </button>
                    <div *ngIf="showSelected">&nbsp;(showing only selected)</div>
                </div>
            </data-sources-top-bar>

            <div *ngIf="showLoadingPanel" class="alert alert-info data-sources__loading-panel">
                <h4><i class="icon-spin icon-spinner"></i><span>Loading...</span></h4>
            </div>

            <div *ngIf="itemsToShow.length && !showLoadingPanel" class="data-sources__scrollable-content">
                <data-sources-content-table
                    [items]="itemsToShow"
                    [(selectedItem)]="selectedItem"
                    [multiSelectedItems]="multiSelectedItems"
                    (multiSelectedItemsChange)="setMultiSelectedItems($event)"
                    [selectedTab]="selectedTab$.value"
                    [isRightPanelOpened]="isRightPanelOpened"
                >
                </data-sources-content-table>
            </div>
            <div *ngIf="!itemsToShow.length && !showLoadingPanel" class="alert alert-info">
                <h4>No match</h4>
                <p *ngIf="query$.value.length">
                    Clear your filters to see more
                    <button class="btn btn--secondary mleft8" (click)="resetFilters()">Clear filters</button>
                </p>
                <div *ngIf="selectedTab$.value !== DataSourcesTab.DATASETS">
                    <p>You can:</p>
                    <ul>
                        <li>
                            Import tables with the <a (click)="goToConnectionsExplorer()">connections explorer</a>
                        </li>
                        <li *ngIf="$rootScope.appConfig.admin || $rootScope.appConfig.globalPermissions.mayCreateAuthenticatedConnections">
                            <a href="{{$state.href('admin.connections.list')}}">Index connections</a> to find their tables in the catalog
                        </li>
                        <li *ngIf="!$rootScope.appConfig.admin && !$rootScope.appConfig.globalPermissions.mayCreateAuthenticatedConnections">
                            Ask a Dataiku administrator to index connections to find their tables in the catalog
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </ng-container>

    <data-sources-right-panel right-panel
        [selectedItem]="selectedItem"
        [selectedTab]="rightPanelSelectedTab">
    </data-sources-right-panel>
</collapsible-right-panel-layout>
