import type {CatFeaturePreprocessingParams} from './cat-feature-preprocessing-params';
import type {ImageFeaturePreprocessingParams} from './image-feature-preprocessing-params';
import type {NumFeaturePreprocessingParams} from './num-feature-preprocessing-params';
import type {TextFeaturePreprocessingParams} from './text-feature-preprocessing-params';
import type {VectorFeaturePreprocessingParams} from './vector-feature-preprocessing-params';

/**
 * Generated from com.dataiku.dip.analysis.model.preprocessing.FeaturePreprocessingParams
 */
export interface _FeaturePreprocessingParams {
    autoReason: FeaturePreprocessingParams.FeatureHandlingReason;
    customHandlingCode: string;
    customProcessorWantsMatrix: boolean;
    name: string;
    role: FeaturePreprocessingParams.Role;
    sendToInput: string;
    state: FeaturePreprocessingParams.FeatureState;
    type: FeaturePreprocessingParams.FeatureType;
}

export type FeaturePreprocessingParams = CatFeaturePreprocessingParams | ImageFeaturePreprocessingParams | NumFeaturePreprocessingParams | TextFeaturePreprocessingParams | VectorFeaturePreprocessingParams;

export namespace FeaturePreprocessingParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.FeaturePreprocessingParams$FeatureType
     */
    export enum FeatureType {
        NUMERIC = 'NUMERIC',
        CATEGORY = 'CATEGORY',
        VECTOR = 'VECTOR',
        TEXT = 'TEXT',
        IMAGE = 'IMAGE'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.FeaturePreprocessingParams$FeatureHandlingReason
     */
    export enum FeatureHandlingReason {
        REJECT_ZERO_VARIANCE = 'REJECT_ZERO_VARIANCE',
        REJECT_MISSING = 'REJECT_MISSING',
        REJECT_IDENTIFIER = 'REJECT_IDENTIFIER',
        REJECT_DEFAULT_TEXT_HANDLING = 'REJECT_DEFAULT_TEXT_HANDLING',
        REJECT_CARDINALITY = 'REJECT_CARDINALITY'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.FeaturePreprocessingParams$Role
     */
    export enum Role {
        INPUT = 'INPUT',
        REJECT = 'REJECT',
        WEIGHT = 'WEIGHT',
        PROFILING = 'PROFILING',
        TARGET = 'TARGET',
        TIMESERIES_IDENTIFIER = 'TIMESERIES_IDENTIFIER',
        TIME = 'TIME',
        TREATMENT = 'TREATMENT'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.FeaturePreprocessingParams$FeatureState
     */
    export interface FeatureState {
        autoModifiedByDSS: boolean;
        dssWantsToSet: FeaturePreprocessingParams;
        previousSettings: FeaturePreprocessingParams;
        recordedMeaning: string;
        userModified: boolean;
    }
}