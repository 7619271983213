import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'enrichValuePlaceholder'})
export class EnrichValuePlaceholderPipe implements PipeTransform {
  transform(value: string, shouldEnrichPlaceholder: boolean, index: number): string {
    if (shouldEnrichPlaceholder) {
        return value.replace("{%index%}", index.toString()).replace("{%index+1%}", (index + 1).toString());
    }
    return value;
  }
}
