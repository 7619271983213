import { Component, ChangeDetectionStrategy } from '@angular/core';
import { LabelingService } from '@features/labeling/services/labeling.service';
import { getItemName } from '@features/labeling/utils';
import { LabelingTask } from 'generated-sources';
import { Observable } from 'rxjs';
import { LabelingReviewService, LabelingReviewRecord, ReviewStatus } from '../services/labeling-review.service';

@Component({
    selector: 'review-item-feed',
    templateUrl: './review-item-feed.component.html',
    styleUrls: ['./review-item-feed.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewItemFeedComponent {
    LabelingTaskType = LabelingTask.LabelingTaskType;

    currentItemId$: Observable<string>;
    currentIndex$: Observable<number>;

    getItemName = getItemName;

    constructor(public labelingService: LabelingService,
        public labelingReviewService: LabelingReviewService) {

        this.currentItemId$ = labelingReviewService.currentId$;
        this.currentIndex$ = labelingReviewService.currentIndex$;
    }

    setImageSource($event: { imageId?: number, itemPath?: string, item?: LabelingReviewRecord}) {
        if ($event.item) {
            this.labelingReviewService.setReviewRecord($event.item);
        }
    }

    setDocumentSource(item: LabelingReviewRecord) {
        this.labelingReviewService.setReviewRecord(item);
    }
}
