<div class="card-layout">
    <div class="card-layout__section card-layout__section--vertical">
        <div class="card-layout__section card-layout__section--horizontal">
            <div class="card-layout__section">
                <div class="card-layout__title">Hypothesis</div>
                <div class="card-layout__content">
                    <table class="stats-table">
                        <tbody class="stats-table__body">
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Tested hypothesis</th>
                                <td class="stats-table__cell">
                                    <u>{{ params.xColumn.name }}</u>
                                    and
                                    <u>{{ params.yColumn.name }}</u>
                                    are independent
                                </td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Significance level</th>
                                <td class="stats-table__cell">
                                    {{ 1 - params.confidenceLevel | smarterNumber }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-layout__section">
                <div class="card-layout__title">Results</div>
                <div class="card-layout__content">
                    <table class="stats-table">
                        <tbody class="stats-table__body">

                            <tr class="stats-table__row">
                                <th class="stats-table__head">Chi-square statistic</th>
                                <td class="stats-table__cell">{{ results.statistic | smarterNumber }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Degrees of freedom</th>
                                <td class="stats-table__cell">{{ results.dof }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">𝘱-value</th>
                                <td class="stats-table__cell">
                                    <colored-pvalue
                                        [pvalue]="results.pvalue"
                                        [significanceLevel]="1 - params.confidenceLevel"
                                    ></colored-pvalue>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="card-layout__section">
            <div class="card-layout__content">
                <div class="test-conclusion">
                    <ng-container *ngIf="isRejected else inconclusive">
                        <p class="test-conclusion__wording test-conclusion__wording--rejected">
                            At the given significance level, variables
                            <u>{{ params.xColumn.name }}</u>
                            and
                            <u>{{ params.yColumn.name }}</u>
                            are not independent
                        </p>
                    </ng-container>

                    <ng-template #inconclusive>
                        <p class="test-conclusion__wording">
                            At the given significance level, the test is inconclusive about whether variables
                            <u>{{ params.xColumn.name }}</u>
                            and
                            <u>{{ params.yColumn.name }}</u>
                            are not independent
                        </p>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="frequency-table">
    <table class="stats-table">
        <tbody class="stats-table__body">
            <tr class="stats-table__row stats-table__row--colored">
                <th class="stats-table__head"></th>
                <th
                    *ngFor="let x of results.freqTableData.xValues;"
                    colspan="2"
                    class="stats-table__head stats-table__head--center"
                >
                    <ng-container *ngIf="x.type == 'anum'; else others">{{ x | filterName }}</ng-container>
                    <ng-template #others>...</ng-template>
                </th>
                <th class="stats-table__title stats-table__title--center">Total</th>
            </tr>
            <tr class="stats-table__row stats-table__row--colored">
                <th class="stats-table__head"></th>
                <ng-container *ngFor="let x of results.freqTableData.xValues;">
                    <ng-container *ngIf="x.type == 'anum'; else others">
                        <th class="stats-table__head">Actual</th>
                        <th class="stats-table__head">Expected</th>
                    </ng-container>
                    <ng-template #others>
                        <th class="stats-table__head"></th>
                        <th class="stats-table__head"></th>
                    </ng-template>
                </ng-container>
                <th class="stats-table__head"></th>
            </tr>
            <tr class="stats-table__row stats-table__row--invisible"></tr>
            <tr
                *ngFor="let y of results.freqTableData.yValues; let j = index;"
                class="stats-table__row"
            >
                <th class="stats-table__head stats-table__head--colored">
                    <ng-container *ngIf="y.type == 'anum'; else others">{{ y | filterName }}</ng-container>
                    <ng-template #others>...</ng-template>
                </th>
                <ng-container *ngFor="let x of results.freqTableData.xValues; let i = index;">
                    <ng-container *ngIf="y.type == 'anum' && x.type == 'anum'; else others">
                        <td class="stats-table__cell left-bordered">
                            {{ results.freqTableData.counts[results.freqTableData.yValues.length * i + j] }}
                        </td>
                        <td
                            [style.color]="cellColors[results.freqTableData.yValues.length * i + j]"
                            class="stats-table__cell"
                        >
                            {{ results.freqTableData.expectedCounts[results.freqTableData.yValues.length * i + j] | smarterNumber }}
                        </td>
                    </ng-container>
                    <ng-template #others>
                        <td
                            colspan="2"
                            class="left-bordered"
                        ></td>
                    </ng-template>
                </ng-container>
                <td class="stats-table__cell left-bordered">{{ results.freqTableData.yCounts[j] }}</td>
            </tr>
            <tr class="stats-table__row">
                <th class="stats-table__title stats-table__title--colored">Total</th>
                <td
                    *ngFor="let x of results.freqTableData.xValues; let i = index;"
                    colspan="2"
                    class="stats-table__cell left-bordered"
                >
                    {{ results.freqTableData.xCounts[i] }}
                </td>
                <td class="stats-table__cell left-bordered">{{ results.freqTableData.total }}</td>
            </tr>
        </tbody>
    </table>
</div>
