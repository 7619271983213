export enum NavTabs {
    NONE = "NONE",
    ANALYSIS = "ANALYSIS",
    DATASET = "DATASET",
    NEW_DATASET = "NEW_DATASET",
    SAVED_MODEL = "SAVED_MODEL",
    SAVED_MODEL_VERSION = "SAVED_MODEL_VERSION",
    RECIPE = "RECIPE",
    SQL_NOTEBOOK = "SQL_NOTEBOOK",
    JUPYTER_NOTEBOOK = "JUPYTER_NOTEBOOK",
    DASHBOARD = "DASHBOARD",
    INSIGHT = "INSIGHT",
    JOB = "JOB",
    MANAGED_FOLDER = "MANAGED_FOLDER",
    LAMBDA = "LAMBDA",
    SCENARIO = "SCENARIO",
    MONITORING = "MONITORING",
    RUNNABLE = "RUNNABLE"
}
