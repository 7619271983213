
export let now = new Date().getTime();
const NOW_REFRESH_INTERVAL = 5 * 1000;

// We don't want "now" to be recomupted constantly or it will cause frequent DOM refresh
setInterval(function() {
    now = new Date().getTime();
}, NOW_REFRESH_INTERVAL);

export function friendlyDuration(diffInSeconds: number) {
    let sec = Math.floor((diffInSeconds >= 60 ? diffInSeconds % 60 : diffInSeconds));
    let min = Math.floor((diffInSeconds = (diffInSeconds / 60)) >= 60 ? diffInSeconds % 60 : diffInSeconds);
    let hrs = Math.floor((diffInSeconds = (diffInSeconds / 60)) >= 24 ? diffInSeconds % 24 : diffInSeconds);
    let days = Math.floor( (diffInSeconds = (diffInSeconds / 24)) >= 30 ? diffInSeconds % 30 : diffInSeconds);
    let months = Math.floor( (diffInSeconds = (diffInSeconds / 30)) >= 12 ? diffInSeconds % 12 : diffInSeconds);
    let years = Math.floor( (diffInSeconds = (diffInSeconds / 12)));
    let sb = "";
    if (years > 0) {
        if (years == 1) {
            sb += ("1 year");
        } else {
            sb += (years + " years");
        }
        if (years <= 6 && months > 0) {
            if (months == 1) {
                sb += (" and one month");
            } else {
                sb += (" and " + months + " months");
            }
        }
    } else if (months > 0) {
        if (months == 1) {
            sb += ("one month");
        } else {
            sb += (months + " months");
        }
        if (months <= 6 && days > 0) {
            if (days == 1) {
                sb += (" and a day");
            } else {
                sb += (" and " + days + " days");
            }
        }
    } else if (days > 0) {
        if (days == 1) {
            sb += ("one day");
        } else {
            sb += (days + " days");
        }
        if (days <= 3 && hrs > 0) {
            if (hrs == 1) {
                sb += (" and one hour");
            } else {
                sb += (" and " + hrs + " hours");
            }
        }
    } else if (hrs > 0) {
        if (hrs == 1) {
            sb += ("one hour");
        } else {
            sb += (hrs + " hours");
        }
        if (min > 1) {
            sb += (" and " + min + " minutes");
        }
    } else if (min > 0) {
        if (min == 1) {
            sb += ("one minute");
        } else {
            sb += (min + " minutes");
        }
        if (sec > 1) {
            sb += (" and " + sec + " seconds");
        }
    } else {
        if (sec <= 1) {
            sb += ("about a second");
        } else {
            sb += ("about " + sec + " seconds");
        }
    }
    return sb;
}

export function durationHHMMSS(diffInSeconds: number) {
    let sec = Math.floor((diffInSeconds >= 60 ? diffInSeconds % 60 : diffInSeconds));
    let min = Math.floor((diffInSeconds = (diffInSeconds / 60)) >= 60 ? diffInSeconds % 60 : diffInSeconds);
    let hours = Math.floor( diffInSeconds / 60);
    let sb = "";
    if (hours > 0) {
        sb += (hours + "h ");
    }
    if (min > 0) {
        sb += (min + "m ");
    }
    sb += (sec + "s");
    return sb;
}

export function durationHHMMSSPadded(diffInSeconds: number) {
    if (diffInSeconds == 0) {
        diffInSeconds = 1;
    }
    let sec = Math.floor((diffInSeconds >= 60 ? diffInSeconds % 60 : diffInSeconds));
    let min = Math.floor((diffInSeconds = (diffInSeconds / 60)) >= 60 ? diffInSeconds % 60 : diffInSeconds);
    let hours = Math.floor( diffInSeconds / 60);

    function pad(number: number) {
        if (number < 10) {
            return "0" + number;
        } else {
            return number;
        }
    }
    return pad(hours) + "h" + pad(min) + "m" + pad(sec) + "s";
}


export function friendlyDurationShort(seconds: number, ref?: string, noSeconds = false) {
    let sec    = Math.floor(seconds >= 60 ? seconds % 60 : seconds),
        min    = Math.floor((seconds = (seconds / 60)) >= 60 ? seconds % 60 : seconds),
        hours  = Math.floor((seconds = (seconds / 60)) >= 24 ? seconds % 24 : seconds),
        days   = Math.floor((seconds = (seconds / 24)) >= 30 ? seconds % 30 : seconds),
        months = Math.floor((seconds = (seconds / 30)) >= 12 ? seconds % 12 : seconds),
        years  = Math.floor((seconds = (seconds / 12))),
        sb = "";

    if (years > 0) {
        sb = (years + " year" + (years > 1 ? "s" : ""));
    } else if (months > 0) {
        sb = (months + " month" + (months > 1 ? "s" : ""));
    } else if (days > 0) {
        sb = (days + " day" + (days > 1 ? "s" : ""));
    } else if (hours > 0) {
        sb = (hours + " hour" + (hours > 1 ? "s" : ""));
    } else if (min > 0) {
        sb = (min + " minute" + (min > 1 ? "s" : ""));
    } else if (!noSeconds && sec > 0) {
        sb = (sec + " second" + (sec > 1 ? "s" : ""));
    }

    switch (ref) {
        case 'ago':   return sb ? sb + " ago"   : "just now";
        case 'in':    return sb ? "in " + sb    : "immediately";
        default:      return sb ? sb            : "< 1 " + (noSeconds ? "minute" : "second");
    }
}

export function dateDayDiff(date1: number, date2: number) {
    let d1 = new Date(date1);
    let d2 = new Date(date2);
    d1.setHours(0);
    d1.setMinutes(0);
    d1.setSeconds(0);
    d1.setMilliseconds(0);
    d2.setHours(0);
    d2.setMinutes(0);
    d2.setSeconds(0);
    d2.setMilliseconds(0);
    let dayLength = 24 * 60 * 60 * 1000;
    return Math.floor(d1.getTime() / dayLength) - Math.floor(d2.getTime() / dayLength);
}

