import { Component, OnInit, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fairAny } from 'dku-frontend-core';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

export type TagsWithCount = {tag: string, count: number}[];
export type SelectedTags = string[];

@UntilDestroy()
@Component({
    selector: 'dss-tags-selector',
    templateUrl: './tags-selector.component.html',
    styleUrls: [ './tags-selector.component.less' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: TagsSelectorComponent,
        multi: true
    }]
})
export class TagsSelectorComponent implements OnInit, ControlValueAccessor {
    @Input() id: string;
    @Input() set tags(tags: TagsWithCount) {
        this.tags$.next(tags);
    }

    touched = false;
    readonly tagsControl = this.fb.control(null);
    readonly searchControl = this.fb.control('');
    readonly tags$ = new ReplaySubject<TagsWithCount>(1);

    readonly filteredTags$ = combineLatest([
        this.tags$,
        this.searchControl.valueChanges.pipe(startWith(this.searchControl.value)) as Observable<string>
    ]).pipe(
        map(([tagsWithCount, filter]) => this.filterProjects(tagsWithCount, filter)),
    );

    private onChange?: (tags: SelectedTags) => void;
    private onTouched?: () => void;

    constructor(
      private readonly fb: FormBuilder,
      @Inject("TaggingService") private taggingService: fairAny,
    ) { }

    ngOnInit(): void {
        this.handleProjectSelection();
    }

    writeValue(projectKey: string[]): void {
        this.tagsControl.setValue(projectKey, { emitEvent: false });
    }

    registerOnChange(onChange: (tags: SelectedTags) => void): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    private markAsTouched(): void {
        if (!this.touched) {
            this.onTouched?.();
            this.touched = true;
        }
    }

    searchFn(filter: string, {tag}: {tag: string} ): boolean {
        const lowerCaseFilter = filter.toLowerCase();
        return tag.toLowerCase().includes(lowerCaseFilter);
    }

    private filterProjects(tagsWithCount: TagsWithCount, filter: string): TagsWithCount {
        const lowerCaseFilter = filter.toLowerCase();
        return tagsWithCount.filter(({tag}) => {
            return tag.toLowerCase().includes(lowerCaseFilter);
        });
    }

    private handleProjectSelection(): void {
        this.tagsControl.valueChanges.pipe(
            untilDestroyed(this),
            tap((tags: SelectedTags) => {
                this.onChange?.(tags);
                this.markAsTouched();
            }
            )).subscribe();
    }

    getTagColor(tag: string) {
        return this.taggingService.getTagColor(tag);
    }
}
