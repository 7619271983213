import { Injectable } from '@angular/core';
import { DeephubInteractiveScoringCellData, InteractiveScoringService } from '@features/deephub/computer-vision/report/interactive-scoring/interactive-scoring-data.service';
import { DeephubImageClassificationReportPredictionWidgetComponent } from '../prediction-widget/deephub-image-classification-prediction-widget.component';
import { DeephubImageClassificationReportCellData } from '../services/deephub-image-classification-report-data-formatter.service';

export class DeephubImageClassificationInteractiveScoringCellData extends DeephubInteractiveScoringCellData implements DeephubImageClassificationReportCellData {
    prediction: string;
    probabilities: Map<string, number>;
    pinnedCategory: string | undefined;
    base64Heatmaps: Map<string, string>;

    public setScore(modelResponse: any) {
        this.score = modelResponse;
        this.prediction = this.score.prediction;
        this.probabilities = new Map(Object.entries(this.score).filter(([key, _]) => key.startsWith("proba_"))) as Map<string, number>;
    }
    public setExplanation(explanations: any): void {
        this.base64Heatmaps = new Map(Object.entries(explanations)); // for image classification -for 1 image- there are one heatmap per displayed prediction in the result widget 
    }

    public togglePinnedCategory(category: string){
        if (this.pinnedCategory !== category){
            this.pinnedCategory = category;
        }
        else{
            // was pinned, unpin all:
            this.pinnedCategory = undefined;
        }
    }
}

@Injectable()
export class ImageClassificationInteractiveScoringService extends InteractiveScoringService {

    createCellData(image: string, file: string) {
        return new DeephubImageClassificationInteractiveScoringCellData(image, file);
    }    
    protected getNbExplanations(): number {
        return DeephubImageClassificationReportPredictionWidgetComponent.NUM_SHOWN_CLASSES; 
    }
}
