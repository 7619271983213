import { Injectable } from '@angular/core';

/**
 *  \b\w   Works with non-accented Latin characters
 *  (?<!\p{Alphabetic})\p{Alphabetic} Works with accented characters but requires lookbehind support, which JS lacks
 *  (?:^|\P{Alphabetic})\p{Alphabetic} + only take the last character but \p{UnicodeNamedCharacterClass} is not supported in Firefox
 */
@Injectable({
  providedIn: 'root',
})
export class ProjectInitialsService {
  getProjectInitials(name: string): string {
    let initials = name || '';

    if (name) {
      const match = name.match(/\b(\w)/g);
      if (match && match.length >= 2) {
        initials = match.join('');
      }
      initials = initials.charAt(0).toUpperCase() + initials.charAt(1);
    }

    return initials;
  }
}
