<p>
    The Chi-square independence test is a statistical procedure used
    to test the hypothesis that variables
    <b>{{ params.xColumn.name }}</b>
    and
    <b>{{ params.yColumn.name }}</b>
    are independent.
</p>

<p>
    There are two possible outcomes depending on the 𝘱-value:
</p>
<ul>
    <li>
        <b>
            𝘱-value &le; significance level (&le; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>
        <br>
        The hypothesis is rejected. Variables
        <b>{{ params.xColumn.name }}</b>
        and
        <b>{{ params.yColumn.name }}</b>
        are not independent.
    </li>
    <li>
        <b>
            𝘱-value is &gt; significance level (&gt; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>

        <br>
        Not enough evidence against the hypothesis. Test is inconclusive.
    </li>
</ul>
<p>
    It has been shown that the conclusion is meaningful under the following conditions:
</p>
<ul>
    <li>
        Observations of
        (<b>{{ params.xColumn.name }}</b>,
        <b>{{ params.yColumn.name }}</b>)
        are independent and identically distributed
    </li>
    <li>
        The expected count for each cell of the frequency table is at least
        <b>5</b>
    </li>
</ul>
<p>
    <span class="text-weak">
        <i class="icon-info-sign"></i>
        Missing values in
        <b>{{ params.xColumn.name }}</b>
        and/or
        <b>{{ params.yColumn.name }}</b>
        are considered as a separate category
    </span>
    <br>
    <span class="text-weak">
        <i class="icon-info-sign"></i>
        &ngsp;
        <a
            href="https://docs.scipy.org/doc/scipy/reference/generated/scipy.stats.chi2_contingency.html"
            target="_blank"
        >More information
        </a>
    </span>
</p>
