<div #containerEl class="image-container">
    <img class="artifact-image" #imageEl [src]="imgUrl" [class.display-none]="hasError" (load)="onImageLoad()" (error)="onImageError()" />
    <ng-container *ngIf="showArtifacts">
        <ng-container *ngFor="let artifact of activePainterImage.artifacts">
            <div *ngIf="artifact | instanceOf: LabelPainterImageArtifact" class="artifact-image__label" [style]="artifact.style">
                <i class="{{artifact.icon}}" *ngIf="artifact.icon"></i>
                <span class="mleft4" *ngIf="artifact.text">{{artifact.text}}</span>
            </div>
            <div *ngIf="artifact | instanceOf: MaskPainterImageArtifact" class="artifact-image__mask" [style]="artifact.style">
                <img [src]="artifact.maskUrl" class="artifact-image__mask-image">
            </div>
        </ng-container>
    </ng-container>
    <image-placeholder *ngIf="hasError || !hasLoaded" [hasLoaded]="hasLoaded" [size]="placeholderSize"></image-placeholder>
</div>