import { Component, OnInit, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { WorkspaceDisplayService, WorkspaceSecurityService } from '@features/workspaces/shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TypeToIconPipe } from '@shared/pipes/visualization/type-to-icon.pipe';
import { ITaggingService } from '@model-main/server/services/itagging-service';
import { ProjectsService } from '@model-main/server/services/projects-service';
import { Workspace } from '@model-main/workspaces/workspace';
import { UIState } from '@shared/models';
import { BehaviorSubject, combineLatest, distinct, distinctUntilChanged, tap } from 'rxjs';
import { Navigation } from './navigation.model';

@UntilDestroy()
@Component({
    selector: 'object-viewer-navigation',
    templateUrl: './object-viewer-navigation.component.html',
    styleUrls: ['./object-viewer-navigation.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObjectViewerNavigationComponent implements OnInit {
    private defaultNav: Navigation = {
        back: {
            label: 'Back to workspace',
            href: null
        },
        source: {
            label: 'Go to source',
            disabled: true,
            href: null
        }
    };

    workspace$ = new BehaviorSubject<Workspace | null | undefined>(undefined);
    object$ = new BehaviorSubject<Workspace.WorkspaceObject | null | undefined>(undefined);
    project$ = new BehaviorSubject<ProjectsService.UIProject | null | undefined>(undefined);
    insightId$ = new BehaviorSubject<string | null | undefined>(undefined);
    nav$ = new BehaviorSubject<Navigation>(this.defaultNav);
    objectType$ = new BehaviorSubject<string>('');
    objectColor$ = new BehaviorSubject<string>('');
    objectName$ = new BehaviorSubject<string>('');

    @Input()
    set workspace(value: Workspace | null | undefined) {
        this.workspace$.next(value);
    }

    @Input()
    set object(value: Workspace.WorkspaceObject | null | undefined) {
        this.object$.next(value);

        if (value) {
            this.objectType$.next(this.typeToIconPipe.transform(this.workspaceDisplayService.getObjectType(value)));
            this.objectColor$.next(this.workspaceDisplayService.getObjectColor(value, true));
            this.objectName$.next(value.displayName);
        }
    }

    @Input()
    set project(value: ProjectsService.UIProject | null | undefined) {
        this.project$.next(value);
    }

    @Input()
    set insightId(value: string | null | undefined) {
        this.insightId$.next(value);
    }

    constructor(
      @Inject('$state') private $state: UIState,
      private workspaceDisplayService: WorkspaceDisplayService,
      private workspaceSecurityService: WorkspaceSecurityService,
      private typeToIconPipe: TypeToIconPipe
    ) { }


    private setNavigation(nav?: Navigation) {
        this.nav$.next(nav || this.defaultNav);
    }

    private loadInsightNav(
        workspaceKey: string,
        object: Workspace.WorkspaceObject
    ) {
        const nav = {
            back: {
                label: 'Back to dashboard',
                href: this.$state.href('workspaces.object', {
                    workspaceKey: workspaceKey,
                    projectKey: object.reference?.projectKey,
                    objectType: object.reference?.type,
                    objectId: object.reference?.id
                })
            },
            source: null
        };

        this.setNavigation(nav);
    }

    private loadObjectNav(
        object: Workspace.WorkspaceObject,
        isSourceDisabled: boolean
    ) {
        const nav = { ...this.defaultNav };
        if (nav.source) {
            nav.source.disabled = isSourceDisabled;
            nav.source.href = nav.source.disabled ? null : this.workspaceDisplayService.getSourceObjectLink(object);
            this.setNavigation(nav);
        }
    }

    private updateDefaultNavigation(workspaceKey: string, object: Workspace.WorkspaceObject | null) {
        if (!this.defaultNav.source) {
            this.defaultNav.source = { label: 'Go to source', href: null };
        }
        if (object) {
            this.defaultNav.source.label = `Go to source ${this.workspaceDisplayService.getObjectTypeUI(object)}`;
        }

        if (!this.defaultNav.back) {
            this.defaultNav.back = { label: 'Back to workspace', href: null };
        }
        this.defaultNav.back.href = this.$state.href('workspaces.workspace', { workspaceKey });
        this.setNavigation();
    }

    ngOnInit(): void {
        combineLatest([
            this.object$.pipe(distinct(object => object?.appId || object?.reference?.id)),
            this.workspace$.pipe(distinct(workspace => workspace?.workspaceKey)),
            this.project$.pipe(distinct(project => project?.projectKey)),
            this.insightId$.pipe(distinctUntilChanged())
        ]).pipe(
            tap(([object, workspace, project, insightId]) => {
                if (object && workspace) {
                    const workspaceKey = workspace.workspaceKey;

                    this.updateDefaultNavigation(workspaceKey, object);

                    if (object.reference?.type === ITaggingService.TaggableType.DASHBOARD && insightId) {
                        this.loadInsightNav(workspaceKey, object);
                    } else {
                        const canGoToSource = this.workspaceSecurityService.canGoToSource(object, project);
                        this.loadObjectNav(object, !canGoToSource);
                    }
                } else {
                    this.updateDefaultNavigation(this.$state.params["workspaceKey"], null);
                }
            }),
            untilDestroyed(this)
        ).subscribe();
    }
}
