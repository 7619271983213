<div class="spreadsheet-column-header">
    <div class="spreadsheet-column-header__text-container" [ngClass]="{'spreadsheet-column-header__text-container--invisible': params.isIndexCol}">
        <div class="spreadsheet-column-header__text-container__title">{{params.displayName}}</div>
        <div class="spreadsheet-column-header__text-container__type">{{params.schemaColumn?.type}}</div>
    </div>

    <div *ngIf="!params.isIndexCol" #menuButton (click)="onMenuClicked($event)" class="spreadsheet-column-header__menu-toggle">
        <i class="dku-icon-text-align-justified-16"></i>
    </div>
</div>
