import type {PredictionModelingParams} from './prediction/prediction-modeling-params';

/**
 * Generated from com.dataiku.dip.analysis.model.ClusteringModelingParams
 */
export interface ClusteringModelingParams {
    custom_mllib: PredictionModelingParams.MLLibCustomGridParams[];
    custom_python: ClusteringModelingParams.ClusteringCustomPythonParams[];
    db_scan_clustering: ClusteringModelingParams.DBScanParams;
    gmm_clustering: ClusteringModelingParams.GMMParams;
    h2o_kmeans: ClusteringModelingParams.H2OKMeansModelingParams;
    isolation_forest: ClusteringModelingParams.IsolationForestParams;
    kmeans_clustering: ClusteringModelingParams.KMeansParams;
    metrics: ClusteringModelingParams.MetricParams;
    mini_batch_kmeans_clustering: ClusteringModelingParams.MiniBatchKMeansParams;
    mllib_gaussian_mixture_clustering: ClusteringModelingParams.MLLibGaussianMixtureParams;
    mllib_kmeans_clustering: ClusteringModelingParams.MLLibKMeansParams;
    spectral_clustering: ClusteringModelingParams.SpectralParams;
    two_step: ClusteringModelingParams.TwoStepParams;
    vertica_kmeans_clustering: ClusteringModelingParams.VerticaKMeansParams;
    ward_clustering: ClusteringModelingParams.WardParams;
}

export namespace ClusteringModelingParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.ClusteringModelingParams$EvaluationMetric
     */
    export enum EvaluationMetric {
        SILHOUETTE = 'SILHOUETTE',
        INERTIA = 'INERTIA',
        NB_CLUSTERS = 'NB_CLUSTERS',
        CUSTOM = 'CUSTOM'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.ClusteringModelingParams$KMeansParams
     */
    export interface KMeansParams {
        enabled: boolean;
        k: number[];
        n_jobs: number;
        seed: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.ClusteringModelingParams$SpectralParams
     */
    export interface SpectralParams {
        affinity_nearest_neighbors: boolean;
        affinity_polynomial: boolean;
        affinity_rbf: boolean;
        affinity_sigmoid: boolean;
        coef0: number;
        enabled: boolean;
        gamma: number[];
        k: number[];
        seed: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.ClusteringModelingParams$GMMParams
     */
    export interface GMMParams {
        enabled: boolean;
        k: number[];
        max_iterations: number;
        seed: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.ClusteringModelingParams$MLLibGaussianMixtureParams
     */
    export interface MLLibGaussianMixtureParams {
        enabled: boolean;
        k: number[];
        max_iterations: number;
        seed: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.ClusteringModelingParams$H2OKMeansModelingParams
     */
    export interface H2OKMeansModelingParams {
        enabled: boolean;
        init: string;
        k: number[];
        max_iterations: number;
        seed: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.ClusteringModelingParams$VerticaKMeansParams
     */
    export interface VerticaKMeansParams {
        description: string;
        enabled: boolean;
        epsilon: number[];
        k: number[];
        max_iterations: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.ClusteringModelingParams$MetricParams
     */
    export interface MetricParams {
        customEvaluationMetricCode: string;
        customEvaluationMetricGIB: boolean;
        evaluationMetric: ClusteringModelingParams.EvaluationMetric;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.ClusteringModelingParams$ClusteringCustomPythonParams
     */
    export interface ClusteringCustomPythonParams {
        code: string;
        custom_has_k: boolean;
        enabled: boolean;
        k: number[];
        name: string;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.ClusteringModelingParams$WardParams
     */
    export interface WardParams {
        enabled: boolean;
        k: number[];
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.ClusteringModelingParams$MLLibKMeansParams
     */
    export interface MLLibKMeansParams {
        enabled: boolean;
        k: number[];
        max_iterations: number;
        seed: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.ClusteringModelingParams$MiniBatchKMeansParams
     */
    export interface MiniBatchKMeansParams {
        enabled: boolean;
        k: number[];
        seed: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.ClusteringModelingParams$IsolationForestParams
     */
    export interface IsolationForestParams {
        bootstrap: boolean;
        contamination: number;
        enabled: boolean;
        k: number;
        max_anomalies: number;
        max_depth: number;
        max_features: number;
        max_samples: number;
        n_estimators: number;
        n_samples: number;
        seed: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.ClusteringModelingParams$DBScanParams
     */
    export interface DBScanParams {
        enabled: boolean;
        epsilon: number[];
        min_sample_ratio: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.ClusteringModelingParams$TwoStepParams
     */
    export interface TwoStepParams {
        enabled: boolean;
        kmeans_k: number;
        max_iterations: number;
        n_clusters: number;
        seed: number;
    }
}