<collapsible-right-panel-layout
    [availableTabs]="rightPanelTabs"
    [(selectedTab)]="rightPanelSelectedTab"
    [(isRightPanelOpened)]="isRightPanelOpened"
>
    <div main-panel class="data-collection-page__main-panel">
        <data-catalog-nav></data-catalog-nav>
        <api-error-alert-from-context></api-error-alert-from-context>
        <div *ngIf="dataCollectionInfo" class="data-collection-page__content">
            <dss-data-collection-header
                class="data-collection-page__header"
                [id]="dataCollectionInfo.id"
                [displayName]="dataCollectionInfo.displayName"
                [permissions]="dataCollectionInfo.currentUserPermissions"
                [isEmptyCollection]="dataCollectionInfo.items.length === 0"
                [mayPublishToDataCollections]="mayPublishToDataCollections"
                (addObjectsToCollection)="addObjectsToCollection(dataCollectionInfo)"
                (editPermissions)="editPermissions(dataCollectionInfo)"
                (deleteCollection)="deleteCollection(dataCollectionInfo)"
            ></dss-data-collection-header>
            <div class="data-collection-page__scrollable-content">
                <data-collection-summary
                    [dataCollectionInfo]="dataCollectionInfo"
                    [isRightPanelOpened]="isRightPanelOpened"
                    (metadataChange)="updateMetadata(dataCollectionInfo, $event)"
                ></data-collection-summary>
                <data-collection-content-table
                    *ngIf="dataCollectionInfo.items.length > 0"
                    [items]="dataCollectionInfo.items"
                    [isRightPanelOpened]="isRightPanelOpened"
                    [(selectedItem)]="selectedDataCollectionItem"
                ></data-collection-content-table>
                <dss-empty-data-collection-placeholder
                    *ngIf="dataCollectionInfo.items.length === 0"
                    [canPublishToDataCollection]="mayPublishToDataCollections && dataCollectionInfo.currentUserPermissions.write"
                    (addObjectsToCollection)="addObjectsToCollection(dataCollectionInfo)"
                ></dss-empty-data-collection-placeholder>
            </div>
        </div>
    </div>
    <ng-container right-panel>
        <data-collection-page-right-panel
            [dataCollectionId]="id"
            [currentUserPermissions]="dataCollectionInfo?.currentUserPermissions"
            [selectedItem]="selectedDataCollectionItem"
            (removeSelectedItem)="removeSelectedItem(id, $event)"
            [selectedTab]="rightPanelSelectedTab"
        ></data-collection-page-right-panel>
    </ng-container>
</collapsible-right-panel-layout>
