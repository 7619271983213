import { Injectable } from '@angular/core';
import { Card, CardResult } from 'src/generated-sources';
import { Observable, of, concat } from 'rxjs';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { WaitingService } from '@core/overlays/waiting.service';
import { switchMap } from 'rxjs/operators';
import { isFullyComputed } from './card-utils';

@Injectable({
    providedIn: 'root'
})
export class ComputeService {
    constructor(
        private DataikuAPI: DataikuAPIService,
        private waitingService: WaitingService
    ) { }

    // Compute a card and return up to two CardResults
    // 1- First from the backend cache (fast but maybe incomplete)
    // 2- From Python if (1) was incomplete (slow)
    //
    // Note that only (2) is returned if 'useCache' is set to false
    computeCard(card: Card, sampleId: string, useCache: boolean): Observable<CardResult> {
        return this.DataikuAPI.statistics.computeResults(card, useCache, sampleId)
            .pipe(
                this.waitingService.bindOverlayAndWaitForResult(),
                switchMap(cardResult => {
                    if (isFullyComputed(cardResult)) {
                        return of(cardResult);
                    }
                    return concat(
                        of(cardResult),
                        this.DataikuAPI.statistics.computeResults(card, false, sampleId).pipe(
                            this.waitingService.bindOverlayAndWaitForResult()
                        )
                    );
                }),
                this.waitingService.bindStaticOverlay()
            );
    }
}
