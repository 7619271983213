import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LabelingReviewService, ReviewFetchType } from '../services/labeling-review.service';

@Component({
    selector: 'review-item-empty',
    templateUrl: './review-item-empty.component.html',
    styleUrls: ['./review-item-empty.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewItemEmptyComponent {

    get ReviewFetchType() {
      return ReviewFetchType;
    }

    constructor(
        public labelingReviewService: LabelingReviewService
    ) { }
    
}
