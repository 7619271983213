import { Pipe, PipeTransform } from '@angular/core';
import { now, friendlyDurationShort } from '@shared/pipes/date-pipes/date-pipes-common';

@Pipe({
    name: 'friendlyTimeDeltaShort'
})
export class FriendlyTimeDeltaShortPipe implements PipeTransform {

    transform(input: string|number, reference?: number): string {
        reference = reference || now;
        if (typeof input == 'string') {
            input = parseInt(input, 10);
        }
        let diffInSeconds = (reference - input) / 1000;
        return friendlyDurationShort(diffInSeconds, 'ago', true);
    }
}
