<ng-container *ngIf="!readOnly else readOnlyTemplate">
    <div class="global-header">
        <sample-settings
            *ngIf="dataSpec"
            [projectKey]="insight.projectKey"
            [dataSpec]="dataSpec"
            [readOnly]="false"
            (dataSpecChange)="updateDataSpec($event)"
        >
        </sample-settings>
        <edit-confidence-level-settings
            *ngIf="params && hasConfidenceLevel"
            [params]="params"
            (action)="handleCardAction($event)"
            [canSave]="!readOnly"
        >
        </edit-confidence-level-settings>
        <container-settings
            *ngIf="dataSpec"
            [dataSpec]="dataSpec"
            [readOnly]="false"
            (dataSpecChange)="updateDataSpec($event)"
        ></container-settings>
    </div>

    <api-error-alert
        *ngIf="error$ | async as error"
        [apiError]="error"
        [closable]="true"
        (resetError)="resetError()"
    >
    </api-error-alert>

    <top-level-card
        *ngIf="params && results"
        [params]="params"
        [results]="results"
        [readOnly]="false"
        [extendedActions]="false"
        [hasFixedHeight]="true"
        (action)="handleCardAction($event)"
    >
    </top-level-card>
</ng-container>

<ng-template #readOnlyTemplate>
    <api-error-alert
        *ngIf="error$ | async as error else noErrorTemplate"
        [apiError]="error"
        [closable]="false"
    >
    </api-error-alert>

    <ng-template #noErrorTemplate>
        <card-body
            class="standalone-card-body"
            *ngIf="params && results"
            [params]="params"
            [results]="results"
            [renderingMode]="CardBodyRenderingMode.TOP_LEVEL_CARD"
            [readOnly]="true"
            [extendedActions]="false"
            [hasFixedHeight]="false"
        >
        </card-body>
    </ng-template>
</ng-template>
