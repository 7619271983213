<per-labeler-stats [perAnnotatorStats]="perAnnotatorStats" [numMetrics]="3">
    <ng-template #headerTemplate>
        <th width="11%">Precision  <i class="labeling-overview-tooltip icon-question-sign" matTooltip="Ability of the annotator to identify only the relevant objects. Percentage of correct annotations (compared to the final review) among all annotations"></i></th>
        <th width="9%">Recall  <i class="labeling-overview-tooltip icon-question-sign" matTooltip="Ability of the annotator to find all the relevant cases. Percentage of correct annotations (compared to the final review) among all relevant objects."></i></th>
        <th width="10%">F1 Score  <i class="labeling-overview-tooltip icon-question-sign" matTooltip="Harmonic mean of the two others metrics."></i></th>
    </ng-template>
    
    <ng-template #dataTemplate let-metrics="metrics">
        <td>
            {{ metrics.precision != undefined ? (metrics.precision * 100 | number:'1.0-0') + '%' : '-' }} 
        </td>
        <td>
            {{ metrics.recall != undefined ? (metrics.recall * 100 | number:'1.0-0') + '%' : '-' }} 
        </td>
        <td>
            {{ metrics.f1Score != undefined ? (metrics.f1Score * 100 | number:'1.0-0') + '%' : '-' }} 
        </td>
    </ng-template>
</per-labeler-stats>