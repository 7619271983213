import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { UnivariateFrequencyTableCard } from 'src/generated-sources';
import { CardAction } from '../../events';


@Component({
    selector: 'univariate-frequency-table-card-body',
    templateUrl: './univariate-frequency-table-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/stats-table.less',
        './univariate-frequency-table-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnivariateFrequencyTableCardBodyComponent {
    @Input() params: UnivariateFrequencyTableCard;
    @Input() results: UnivariateFrequencyTableCard.UnivariateFrequencyTableCardResult;
    @Output() action = new EventEmitter<CardAction>();

    trackByIndex(index: number) {
        return index;
    }
}
