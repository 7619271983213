import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchAPIError, SimpleObservableErrorContext } from '@core/dataiku-api/api-error';
import { catchError, distinctUntilChanged, map, Observable, of, switchMap, filter, tap, delay, zip } from 'rxjs';
import { DataCollectionsService } from '../../services/data-collections.service';
import { AccessibleObjectsService, SerializedProject, TaggableObjectsService, UIDataCollection } from 'src/generated-sources';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { AddObjectsForm, AddObjectsFormValueType, AuthorizationOptions } from './add-object-to-collection.form';
import { UntilDestroy } from '@ngneat/until-destroy';
import { assertNever, fairAny } from 'dku-frontend-core';
import { ProjectsService } from '@shared/services/projects.service';
import { fieldNonNullableGuard } from '@utils/objects';

interface AddObjectToCollectionModalOptions {
    id: string;
    displayName: string;
}

export interface AddObjectToCollectionModalResult {
    response: UIDataCollection.AddObjectsResponse,
    requestedObjectsRefs: TaggableObjectsService.TaggableObjectRef[]
}

// A set of type & constant state to describe the state of the suggestions in order to control how the UI is displayed

const SUGGESTIONS_UNSET = { unset: true as const }; // represents a blocking state (either no object is selected or API call are currently pending)
const SUGGESTIONS_OFF = { enabled: false as const }; // represents a valid state that doesn't show any suggestion

type ObjectAuthorizationSuggestionState = typeof SUGGESTIONS_OFF | {
    enabled: true,
    disableNone: boolean,
};

type QuickSharingSuggestionState = typeof SUGGESTIONS_OFF | {
    enabled: true,
    canEnableQuickSharing: boolean,
};

type ValidSuggestionsState = {
    objectAuthorizations: ObjectAuthorizationSuggestionState;
    quickSharing: QuickSharingSuggestionState;
};

type SuggestionsState = typeof SUGGESTIONS_UNSET | (ValidSuggestionsState & { unset: false });

const AUTHORIZATION_OPTIONS = [
    { label: 'None', value: 'NONE', disabled: false},
    { label: 'Allow discover', value: 'DISCOVER'},
    { label: 'Allow read', value: 'READ'},
];

const AUTHORIZATION_DESCRIPTIONS = [
    '&quot;None&quot; will prevent users from seeing the dataset in the data collection at all',
    '&quot;Allow Discover&quot; will allow users to see the dataset metadata',
    '&quot;Allow Read&quot; will allow users to additionally preview the dataset from the collection'
];

const REQUESTED_OBJECT_AUTHORIZATION_MAPPING: {
    [key in AuthorizationOptions]: SerializedProject.ReaderAuthorization.Mode[]
} = {
    NONE: [],
    DISCOVER: [SerializedProject.ReaderAuthorization.Mode.DISCOVER],
    READ: [SerializedProject.ReaderAuthorization.Mode.DISCOVER, SerializedProject.ReaderAuthorization.Mode.READ],
};

@UntilDestroy()
@Component({
    selector: 'dss-add-object-to-collection-modal',
    templateUrl: './add-object-to-collection-modal.component.html',
    styleUrls: ['./add-object-to-collection-modal.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddObjectToCollectionModalComponent {
    errorContext = new SimpleObservableErrorContext();
    suggestObjectAuthorization = false;
    canEnableQuickSharing = true;
    enableQuickSharing = true;
    objectAuthorization = 'READ';
    disableNone = false;
    readonly addObjectsForm = new AddObjectsForm();
    readonly projects$ = this.projectsService.list().pipe(
        catchAPIError(this.errorContext),
        // only show projects the user can actually publish from
        // TODO @datacollections UI will not be great if this list is empty, but do we really care?
        map(projects => projects.filter(project => project.canPublishToDataCollections))
    );
    objects: AccessibleObjectsService.AccessibleObject[];
    isValid = false;
    readonly modalTitle: string;
    objectAuthorizationOptions = AUTHORIZATION_OPTIONS;
    objectAuthorizationDescriptions = AUTHORIZATION_DESCRIPTIONS;
    suggestionState: SuggestionsState = SUGGESTIONS_UNSET;

    constructor(
        private dialogRef: MatDialogRef<AddObjectToCollectionModalComponent, AddObjectToCollectionModalResult>,
        @Inject(MAT_DIALOG_DATA) private data: AddObjectToCollectionModalOptions,
        private dataCollectionsService: DataCollectionsService,
        private projectsService: ProjectsService,
        private readonly dataikuApi: DataikuAPIService,
        @Inject("$rootScope") public $rootScope: fairAny,
        private cd: ChangeDetectorRef,
    ) {
        this.modalTitle = 'Add a dataset to "' + this.data.displayName + '"';
        this.addObjectsForm.rawValues$.pipe(
            distinctUntilChanged((a, b) => a.type === b.type && a.projectKey === b.projectKey),
            // We need to add a fake delay to avoid some magic from observables.
            delay(0),
            // When changing the project we need to change the suggestion state as long as there is no more a dataset selected
            tap(() => {
                this.isValid = false;
                this.suggestionState.unset = true;
                this.addObjectsForm.objects.setValue(null);
                this.addObjectsForm.objects.disable();
            }),
            filter(fieldNonNullableGuard('projectKey')),
            switchMap(({projectKey, type}) => this.getAccessibleObjects(type, projectKey))
        ).subscribe(data => {
            this.objects = data;
            this.addObjectsForm.objects.enable();
            this.cd.detectChanges();
        });

        this.addObjectsForm.rawValues$.pipe(
            distinctUntilChanged((a, b) =>  a.objects === b.objects),
            tap(() => {
                this.isValid = false;
                this.addObjectsForm.authorization.disable();
                this.addObjectsForm.quickSharingEnabled.disable();
            }), // disable quick sharing and authorization while api call are pending
            filter(fieldNonNullableGuard('type')), filter(fieldNonNullableGuard('projectKey')), filter(fieldNonNullableGuard('objects')), // bail out if no object selected
            switchMap(({projectKey, objects: {id: objectId}, type}) => zip([
                this.getAuthorization(projectKey, type, objectId),
                this.getQuickSharingAuthorization(projectKey, type, objectId)
            ]))
        ).subscribe(([objectAuthorizations, quickSharing]) => {
            this.suggestionState = {
                unset: false,
                objectAuthorizations,
                quickSharing,
            };

            if(objectAuthorizations.enabled) {
                this.addObjectsForm.setObjectAuthorizationValue('READ');
                this.addObjectsForm.authorization.enable();
                this.objectAuthorizationOptions = AUTHORIZATION_OPTIONS;
                this.objectAuthorizationOptions[0].disabled = objectAuthorizations.disableNone;
            } else {
                this.addObjectsForm.setObjectAuthorizationValue('NONE');
            }

            this.addObjectsForm.setQuickSharingValue(quickSharing.enabled && quickSharing.canEnableQuickSharing);
            if (quickSharing.enabled && quickSharing.canEnableQuickSharing) {
                    this.addObjectsForm.quickSharingEnabled.enable();
            }
            this.isValid = true;
            this.cd.detectChanges();
        });
    }

    getAuthorization(projectKey: string, objectType: string, objectId: string): Observable<ObjectAuthorizationSuggestionState> {
        return this.dataikuApi.projects.getDashboardAuthorizations(projectKey).pipe(
            map((data) => {
                const authorizationsForThisItem = (data.authorizations.find(({ objectRef }) =>
                    objectRef.objectId === objectId && objectRef.objectType === objectType
                ) || {modes:[]}).modes as string[];
                if(data.allAuthorized
                    || authorizationsForThisItem.includes('READ')
                    || authorizationsForThisItem.includes('WRITE')) {
                    return SUGGESTIONS_OFF;
                }
                return {
                    enabled: true,
                    disableNone: authorizationsForThisItem.includes('DISCOVER'),
                };
            }),
            catchError(err => {
                this.errorContext.pushError(err);
                return of();
            })
        );
    }

    getQuickSharingAuthorization(projectKey: string, type: string, objectId: string): Observable<QuickSharingSuggestionState> {
        if (!this.$rootScope.appConfig.quickSharingElementsEnabled) {
            return of(SUGGESTIONS_OFF);
        }

        return this.dataikuApi.projects.getObjectAuthorizations(projectKey, type, objectId).pipe(
            map((authorization) => {
                if(authorization.isQuicklyShareable) {
                    return SUGGESTIONS_OFF;
                } else {
                    return {
                        enabled: true,
                        canEnableQuickSharing: authorization.canManageExposedElements
                    };
                }
            }),
            catchError(err => {
                this.errorContext.pushError(err);
                return of();
            })
        );
    }

    private getAccessibleObjects(type: AddObjectsFormValueType, projectKey: string | null): Observable<AccessibleObjectsService.AccessibleObject[]> {
        if (projectKey === null) {
            return of([]);
        }

        return this.dataikuApi.taggableObjects.listAccessibleObjects(projectKey, type, 'READ').pipe(
            map(objects => {
                switch(type) {
                    case 'DATASET': return objects.filter(dataset => dataset.localProject);
                    default: assertNever(type);
                }
            }),
            catchError(err => {
                this.errorContext.pushError(err);
                return of([]);
            })
        );
    }

    cancel (): void {
        this.dialogRef.close();
    }

    confirm (): void {
        const formValue = this.addObjectsForm.getCurrentValue();
        if(!formValue) return; // we clicked on add while the form is invalid, should never happen.
        const objectRef : TaggableObjectsService.TaggableObjectRef = (({projectKey, type, id}) => ({projectKey, type, id}))(formValue.objects);
        // TODO @datacollections maybe improve the modal to make option customizable
        this.dataCollectionsService.addObjects(this.data.id, [objectRef], {
            requestQuickSharing: !this.suggestionState.unset && this.suggestionState.quickSharing.enabled && formValue.quickSharingEnabled,
            requestedReaderAuthorizations: REQUESTED_OBJECT_AUTHORIZATION_MAPPING[formValue.authorization],
        }).pipe(
            catchAPIError(this.errorContext),
        ).subscribe((response) => {
            this.dialogRef.close({response: response, requestedObjectsRefs: [objectRef]});
        });
    }
}
