export enum NavItems {
    DATASET = "DATASET",
    RECIPE = "RECIPE",
    ANALYSIS = "ANALYSIS",
    DASHBOARD = "DASHBOARD",
    WEB_APP = "WEB_APP",
    REPORT = "REPORT",
    SQL_NOTEBOOK = "SQL_NOTEBOOK",
    JUPYTER_NOTEBOOK = "JUPYTER_NOTEBOOK",
    SAVED_MODEL = "SAVED_MODEL",
    MANAGED_FOLDER = "MANAGED_FOLDER",
    JOB = "JOB",
    INSIGHT = "INSIGHT",
    LAMBDA_SERVICE = "LAMBDA_SERVICE",
    SCENARIO = "SCENARIO",
    MONITORING = "MONITORING",
    RUNNABLE = "RUNNABLE",
    PROJECT = "PROJECT",
    ARTICLE = "ARTICLE",
    FEATURE_STORE = "FEATURE_STORE",
    WORKSPACES = "WORKSPACES"
}
