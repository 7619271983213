import type {Filter, _Filter} from './filter';

/**
 * Generated from com.dataiku.dip.eda.compute.filtering.AnumFilter
 */
export interface AnumFilter extends _Filter {
    column: string;
    values: string[];

    // PolyJson type
    type: 'anum';
}

export namespace AnumFilter {
    export const type = 'anum';
}

/** @deprecated Use Filter.isAnumFilter() */ export function isAnumFilter(toTest: Filter): toTest is AnumFilter { return AnumFilter.type === toTest['type']; }