import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, ViewChild } from '@angular/core';
import _ from 'lodash';
import { BivariateHeaderCard, Variable } from 'src/generated-sources';
import { Observable, combineLatest } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { map, startWith, tap } from 'rxjs/operators';
import { CardWizardVariable } from '@features/eda/card-models';
import { minCheckedValidator } from '@utils/min-checked-validator';
import { objectNotEmptyValidator } from '@utils/object-not-empty-validator';
import { CardWizardService } from '../../../card-wizard/card-wizard.service';
import { MAX_HEADER_COLUMNS, CANNOT_ADD_REASON, identicalVariableNames, unselectVariables, unselectVariable } from '@features/eda/card-utils';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { WT1Service } from 'dku-frontend-core';

@UntilDestroy()
@Component({
    selector: 'bivariate-header-card-config',
    templateUrl: './bivariate-header-card-config.component.html',
    styleUrls: [
        '../../../../shared-styles/card-wizard.less',
        './bivariate-header-card-config.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BivariateHeaderCardConfigComponent implements  OnInit, OnChanges, OnDestroy {
    @Input() params: BivariateHeaderCard;
    @Output() paramsChange = new EventEmitter<BivariateHeaderCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);
    @Output() validityTooltipChange = new EventEmitter<string>(true);

    allVariables$: Observable<CardWizardVariable[]>;
    xColumns$: Observable<Variable[]>;
    yColumn$: Observable<Variable>;
    currentVariables$: Observable<CardWizardVariable[]>;
    selectedVariables: CardWizardVariable[] = [];
    selectedFactors: CardWizardVariable[] = [];
    count = 0;
    tooManySelectedVariablesWarning = '';
    options = [{
        name: 'Mosaic Plot',
        id: 'showMosaicPlot',
        enabled: false,
        variableTypes: [{
            x: Variable.Type.CONTINUOUS,
            y: Variable.Type.CONTINUOUS
        }, {
            x: Variable.Type.CATEGORICAL,
            y: Variable.Type.CONTINUOUS
        }, {
            x: Variable.Type.CONTINUOUS,
            y: Variable.Type.CATEGORICAL
        }, {
            x: Variable.Type.CATEGORICAL,
            y: Variable.Type.CATEGORICAL
        }]
    }, {
        name: 'Scatter Plot',
        id: 'showScatterPlot',
        enabled: false,
        variableTypes: [{
            x: Variable.Type.CONTINUOUS,
            y: Variable.Type.CONTINUOUS
        }]
    }, {
        name: 'Histogram',
        id: 'showHistogram',
        enabled: false,
        variableTypes: [{
            x: Variable.Type.CONTINUOUS,
            y: Variable.Type.CONTINUOUS
        }, {
            x: Variable.Type.CATEGORICAL,
            y: Variable.Type.CONTINUOUS
        }, {
            x: Variable.Type.CONTINUOUS,
            y: Variable.Type.CATEGORICAL
        }, {
            x: Variable.Type.CATEGORICAL,
            y: Variable.Type.CATEGORICAL
        }]
    }, {
        name: 'Frequency Table',
        id: 'showFrequencyTable',
        enabled: false,
        variableTypes: [{
            x: Variable.Type.CONTINUOUS,
            y: Variable.Type.CONTINUOUS
        }, {
            x: Variable.Type.CATEGORICAL,
            y: Variable.Type.CONTINUOUS
        }, {
            x: Variable.Type.CONTINUOUS,
            y: Variable.Type.CATEGORICAL
        }, {
            x: Variable.Type.CATEGORICAL,
            y: Variable.Type.CATEGORICAL
        }]
    }, {
        name: 'Box Plot',
        id: 'showBoxPlot',
        enabled: false,
        variableTypes: [{
            x: Variable.Type.CONTINUOUS,
            y: Variable.Type.CONTINUOUS
        }, {
            x: Variable.Type.CATEGORICAL,
            y: Variable.Type.CONTINUOUS
        }, {
            x: Variable.Type.CONTINUOUS,
            y: Variable.Type.CATEGORICAL
        }]
    }, {
        name: 'Summary Stats',
        id: 'showSummary',
        enabled: false,
        variableTypes: [{
            x: Variable.Type.CONTINUOUS,
            y: Variable.Type.CONTINUOUS
        }, {
            x: Variable.Type.CONTINUOUS,
            y: Variable.Type.CATEGORICAL
        }, {
            x: Variable.Type.CATEGORICAL,
            y: Variable.Type.CONTINUOUS
        }]
    }];

    configForm = this.fb.group({
        xColumns: this.fb.control([], [Validators.required]),
        yColumn: this.fb.control({} as Variable, [objectNotEmptyValidator()]),
        showBoxPlot: this.fb.control(true, [Validators.required]),
        showFrequencyTable: this.fb.control(true, [Validators.required]),
        showHistogram: this.fb.control(true, [Validators.required]),
        showScatterPlot: this.fb.control(true, [Validators.required]),
        showMosaicPlot: this.fb.control(true, [Validators.required]),
        showSummary: this.fb.control(true, [Validators.required]),
    }, { validator: minCheckedValidator(this.options.map(o => o.id)) });

    constructor(
        private fb: FormBuilder,
        private cardWizardService: CardWizardService,
        private wt1Service: WT1Service
    ) {
        this.configForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((formValue) => this.paramsChange.emit({
                ...this.params,
                xColumns: formValue.xColumns,
                yColumn: formValue.yColumn,
                showBoxPlot: formValue.showBoxPlot,
                showFrequencyTable: formValue.showFrequencyTable,
                showHistogram: formValue.showHistogram,
                showScatterPlot: formValue.showScatterPlot,
                showMosaicPlot: formValue.showMosaicPlot,
                showSummary: formValue.showSummary
            }));

    }

    ngOnInit() {
        const xColumns = this.configForm.controls.xColumns.value as Variable[]; // need starting value for combineLatest
        const yColumn = this.configForm.controls.yColumn.value as Variable; // need starting value for combineLatest

        this.allVariables$ = this.cardWizardService.availableVariables(this.params.type);
        this.xColumns$ = this.configForm.controls.xColumns.valueChanges;
        this.yColumn$ = this.configForm.controls.yColumn.valueChanges;

        this.currentVariables$ = combineLatest([this.allVariables$, this.xColumns$.pipe(startWith(xColumns)), this.yColumn$.pipe(startWith(yColumn))])
            .pipe(
                map(([all, x, y]) => {
                    // remove x and y variables from current variable list
                    return all.filter(variable => x.findIndex(xVariable => xVariable.name === variable.name) < 0).filter(variable => !y || (y.name !== variable.name)).map(({selected, ...attrs}) => attrs);
                }),
                tap(() => {
                    this.selectedFactors = [];
                    this.selectedVariables = [];
                })
            );

        this.currentVariables$
            .pipe(untilDestroyed(this))
            .subscribe(variables => {
                this.count = variables.length;
            });

        combineLatest([this.xColumns$, this.yColumn$])
            .pipe(untilDestroyed(this))
            .subscribe(([x, y]) => this.toggleOptions(x, y));

        combineLatest([this.configForm.statusChanges.pipe(startWith('')), this.xColumns$.pipe(startWith(xColumns))])
        .pipe(untilDestroyed(this))
        .subscribe(([sc, curColumns]) => {
            const overLimit = curColumns.length > MAX_HEADER_COLUMNS;
            this.validityChange.emit(this.configForm.valid && !overLimit);
            if (overLimit) {
                this.tooManySelectedVariablesWarning = `${curColumns.length} variables selected. Max is ${MAX_HEADER_COLUMNS}.`;
            } else {
                this.tooManySelectedVariablesWarning = '';
            }
            this.validityTooltipChange.emit(this.tooManySelectedVariablesWarning);
    });
    }

    toggleOptions(xColumns: Variable[], yColumn: Variable) {
        const xColumnTypes = xColumns
            .map((v: Variable) => v.type)
            .filter((v: Variable.Type, i: number, arr: Variable.Type[]) => arr.indexOf(v) === i);
        const yColumnType = yColumn ? yColumn.type : '';

        this.options.forEach(option => {
            option.enabled = !!option.variableTypes
                .filter((type: any) => xColumnTypes.includes(type.x) && yColumnType === type.y).length;

            if (option.enabled) {
                this.configForm.get(option.id)!.enable();
            } else {
                this.configForm.get(option.id)!.disable();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.configForm.patchValue({
                xColumns: this.params.xColumns,
                yColumn: this.params.yColumn,
                showBoxPlot: this.params.showBoxPlot,
                showFrequencyTable: this.params.showFrequencyTable,
                showHistogram: this.params.showHistogram,
                showScatterPlot: this.params.showScatterPlot,
                showMosaicPlot: this.params.showMosaicPlot,
                showSummary: this.params.showSummary
            });

            this.toggleOptions(this.params.xColumns, this.params.yColumn);
        }
    }

    add(controlName: string) {
        const value = this.configForm.get(controlName)!.value;

        this.configForm.patchValue({
            [controlName]: Array.isArray(value) ? unselectVariables(_.unionWith(value, this.selectedVariables, identicalVariableNames)) : unselectVariable(this.selectedVariables[0])
        });

        this.selectedVariables = [];
    }

    del(controlName: string) {
        const value = this.configForm.get(controlName)!.value;

        this.configForm.patchValue({
            [controlName]: Array.isArray(value) ? _.differenceWith(value, this.selectedFactors, identicalVariableNames) : []
        });

        this.selectedFactors = [];
    }

    get canAddX() {
        return this.selectedVariables.length > 0;
    }

    get canAddY() {
        const yValue = this.configForm.get('yColumn')!.value;

        return this.selectedVariables.length === 1 && (typeof yValue === 'undefined' || typeof yValue.name === 'undefined');
    }

    get disabledReasonX() {
        if (this.configForm.controls.xColumns.value.length + this.selectedVariables.length > MAX_HEADER_COLUMNS) {
            return CANNOT_ADD_REASON.MAX_VARIABLES_EXCEEDED;
        } else if (this.selectedVariables.length <= 0) {
            return CANNOT_ADD_REASON.NO_VARIABLE_SELECTED;
        }

        return '';
    }

    get disabledReasonY() {
        if (this.selectedVariables.length !== 1) {
            return CANNOT_ADD_REASON.NOT_EXACTLY_ONE;
        }

        return '';
    }

    ngOnDestroy() {

    }

    onDropFactors(dropped: CdkDragDrop<CardWizardVariable[]>): void {
        const droppedItems: CardWizardVariable[] = dropped.item.data;
        droppedItems.forEach(v => v.selected = false);
        const currentXValue = this.configForm.controls.xColumns.value;
        let newXValue: CardWizardVariable[];
        if (currentXValue) {
            let spliceIndex = dropped.currentIndex;
            newXValue = _.cloneDeep(currentXValue);
            if (dropped.container === dropped.previousContainer) {
                const indexes = droppedItems.map(d => newXValue.findIndex(e => e.name === d.name ));
                indexes.splice(0, 1);
                const offset = _.sumBy(indexes, curIndex => curIndex <= spliceIndex ? 1 : 0);
                spliceIndex -= offset;
                newXValue = _.differenceWith(newXValue, droppedItems, identicalVariableNames);
            }
            newXValue.splice(dropped.currentIndex, 0, ...droppedItems);
        } else {
            newXValue = droppedItems;
        }
        const currentYValue = this.configForm.controls.yColumn.value;
        const newYValue = currentYValue && _.differenceWith([currentYValue], droppedItems, identicalVariableNames).length ? unselectVariable(currentYValue) : {};
        this.configForm.patchValue({
            xColumns: unselectVariables(newXValue),
            yColumn: newYValue
        });
        this.selectedVariables = [];
        this.selectedFactors = [];
        this.wt1Service.event('statistics-drag-drop-variables', { droppedCount: dropped.item.data.length });
    }

    onDropResponse(dropped: CdkDragDrop<CardWizardVariable[]>): void {
        const droppedItems: CardWizardVariable[] = dropped.item.data;
        droppedItems.forEach(v => v.selected = false);
        const currentYValue = this.configForm.controls.yColumn.value;
        const newYValue = unselectVariable(droppedItems[0]);
        if (currentYValue === newYValue) {
            return;
        }
        const currentXValue = this.configForm.controls.xColumns.value;
        const newXValue = currentXValue ? _.differenceWith(currentXValue, [newYValue], identicalVariableNames) : currentXValue;
        this.configForm.patchValue({
            xColumns: unselectVariables(newXValue),
            yColumn: newYValue
        });
        this.selectedVariables = [];
        this.selectedFactors = [];
        this.wt1Service.event('statistics-drag-drop-variables', { droppedCount: dropped.item.data.length });
    }

    onDropVariables(dropped: CdkDragDrop<CardWizardVariable[]>): void {
        const droppedItems: CardWizardVariable[] = dropped.item.data;
        droppedItems.forEach(v => v.selected = false);

        const currentXValue = this.configForm.controls.xColumns.value;
        const newXValue = currentXValue ? _.differenceWith(currentXValue, droppedItems, identicalVariableNames) : currentXValue;

        const currentYValue = this.configForm.controls.yColumn.value;
        const newYValue = currentYValue && _.differenceWith([currentYValue], droppedItems, identicalVariableNames).length ? unselectVariable(currentYValue) : {};

        this.configForm.patchValue({
            xColumns: unselectVariables(newXValue),
            yColumn: newYValue
        });
        this.selectedVariables = [];
        this.selectedFactors = [];
        this.wt1Service.event('statistics-drag-drop-variables', { droppedCount: dropped.item.data.length });
    }
}
