import { Component, ChangeDetectionStrategy } from '@angular/core';
import { LabelingService } from '../services/labeling.service';

@Component({
    selector: 'labeling-error',
    templateUrl: './labeling-error.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelingErrorComponent {

    error$ = this.labelingService.getError();

    constructor(private labelingService: LabelingService) { }

    resetError() {
        this.labelingService.pushError();
    }

}
