<div class="card-layout">
    <div class="card-layout__section card-layout__section--horizontal">
        <div class="card-layout__section card-layout__section--vertical">
            <div class="card-layout__section">
                <div class="card-layout__title">Summary</div>
                <div class="card-layout__content">
                    <table class="stats-table">
                        <tbody class="stats-table__body">
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Count</th>
                                <td class="stats-table__cell">{{ results.boxPlot.countComplete }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Sample median</th>
                                <td class="stats-table__cell">{{ results.boxPlot.median | smarterNumber }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-layout__section">
                <div class="card-layout__title">Hypothesis</div>
                <div class="card-layout__content">
                    <table class="stats-table">
                        <tbody class="stats-table__body">
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Hypothesized median</th>
                                <td class="stats-table__cell">
                                    {{ params.hypothesizedMedian | smarterNumber }}
                                </td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Significance level</th>
                                <td class="stats-table__cell">
                                    {{ 1 - params.confidenceLevel | smarterNumber }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="card-layout__section">
            <div class="card-layout__title">Results</div>
            <div class="card-layout__content">
                <table class="stats-table">
                    <tbody class="stats-table__body">
                        <tr class="stats-table__row">
                            <th class="stats-table__head">Count &lt; hypothesized median</th>
                            <td class="stats-table__cell">{{ results.nbSmaller }}</td>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">Count &gt; hypothesized median</th>
                            <td class="stats-table__cell">{{ results.nbLarger }}</td>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">
                                Count = hypothesized median
                                <span class="text-weak">(ties are ignored)</span>
                            </th>
                            <td class="stats-table__cell">
                                {{ (results.boxPlot.countComplete || 0) - (results.nbLarger + results.nbSmaller) }}
                            </td>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">𝘱-value</th>
                            <td class="stats-table__cell">
                                <colored-pvalue
                                    [pvalue]="results.pvalue"
                                    [significanceLevel]="1 - params.confidenceLevel"
                                ></colored-pvalue>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="test-conclusion">
    <ng-container *ngIf="isRejected else inconclusive">
        <p class="test-conclusion__wording test-conclusion__wording--rejected">
            At the given significance level, the population median of
            <u>{{ params.column.name }}</u>
            is different from
            <u>{{ params.hypothesizedMedian | smarterNumber }}</u>
        </p>
    </ng-container>

    <ng-template #inconclusive>
        <p class="test-conclusion__wording">
            At the given significance level, the test is inconclusive about whether the population median of
            <u>{{ params.column.name }}</u>
            is different from
            <u>{{ params.hypothesizedMedian | smarterNumber }}</u>
        </p>
    </ng-template>
</div>
