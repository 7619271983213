export enum AnalysisType {
    UNIVARIATE_HEADER = 'univariate_header',
    BIVARIATE_HEADER = 'bivariate_header',
    UNIVARIATE_CARD = 'univariate_card',
    BIVARIATE_CARD = 'bivariate_card',
    TRIVARIATE_CARD = 'trivariate_card',
    MULTIVARIATE_CARD = 'multivariate_card',
    TIME_SERIES_CARD = 'time_series_card',
}

export type Analysis = 'univariate_header' | 'bivariate_header' | 'univariate_card' | 'bivariate_card' | 'trivariate_card' | 'multivariate_card';