import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, ElementRef, AfterViewInit, OnChanges, SimpleChanges, ContentChild, TemplateRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { resize$ } from '@shared/utils/resize-observable';
import { map } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'card-virtual-scroll',
    templateUrl: './card-virtual-scroll.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardVirtualScrollComponent<T> implements AfterViewInit, OnChanges {
    @ContentChild(TemplateRef) public template: TemplateRef<{ item: T; }>;
    @Input() items: T[];
    @Input() itemHeight: number;
    @Input() itemsPerRow: number;

    // this is the content width of the content wrapper.
    // Does not contain the scroll-bar width nor the possible paddings & margins on the content-wrapper.
    // formula for number of items for a simple grid is `itemsPerRow = Math.max(1, Math.floor((width + gap)/(max_width + gap)))`
    @Output() widthChange = new EventEmitter<number>();

    groupedByRow: T[][] = [];

    constructor(
        private elementRef: ElementRef<HTMLElement>,
    ) { }

    ngAfterViewInit(): void {
        // we need to watch the content-wrapper size, otherwise we get fooled by the scrollbar width
        const contentWrapperElement = this.elementRef.nativeElement.querySelector('.cdk-virtual-scroll-content-wrapper');
        if(contentWrapperElement === null) return; // never true

        resize$(contentWrapperElement).pipe(
            untilDestroyed(this),
            map(ev => ev.contentRect.width)
        ).subscribe((val) => {
            this.widthChange.emit(val);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.items || changes.itemsPerRow) {
            this.groupedByRow = this.items.reduce((res, item, index) => {
                if(index % this.itemsPerRow === 0) {
                    res.push([item]);
                } else {
                    res[res.length - 1].push(item);
                }
                return res;
            }, [] as T[][]);
        }
    }
}
