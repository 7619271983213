import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.MinTime
 */
export interface MinTime extends Computation._UnivariateComputation {
    // PolyJson type
    type: 'min_time';
}

export namespace MinTime {
    export const type = 'min_time';
}

/** @deprecated Use Computation.isMinTime() */ export function isMinTime(toTest: Computation): toTest is MinTime { return MinTime.type === toTest['type']; }

export namespace MinTime {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.MinTime$MinTimeResult
     */
    export interface MinTimeResult extends _AvailableResult {
        timestamp: string;

        // PolyJson type
        type: 'min_time';
    }

    export namespace MinTimeResult {
        export const type = 'min_time';
    }

    /** @deprecated Use ComputationResult.isMinTimeResult() */ export function isMinTimeResult(toTest: ComputationResult): toTest is MinTimeResult { return MinTimeResult.type === toTest['type']; }
}