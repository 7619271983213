import { Directive, ElementRef, Inject, Injector, Input, OnChanges, SimpleChanges } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";
import { fairAny } from 'dku-frontend-core';

/**
 * A directive that allows to display the preview of a dataset
 */

@Directive({
    selector: 'dataset-preview',
})
export class DatasetPreviewDirective extends UpgradeComponent implements OnChanges {
    @Input() projectKey: string;
    @Input() datasetName: string;
    @Input() embeddedLoadingDisplay?: boolean;
    @Input() showName?: boolean;
    @Input() showMeaning?: boolean;
    @Input() showStorageType?: boolean;
    @Input() showDescription?: boolean;
    @Input() showCustomFields?: boolean;
    @Input() showProgressBar?: boolean;
    @Input() showHeaderSeparator?: boolean;
    @Input() disableHeaderMenu?: boolean;

    constructor(
        @Inject('$rootScope') public $rootScope: fairAny,
        elementRef: ElementRef,
        injector: Injector
    ) {
        super('ng1DatasetPreview', elementRef, injector);
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Run AngularJS change detection
        this.$rootScope.$applyAsync();
        super.ngOnChanges(changes);
    }
}
