<form
    [formGroup]="configForm"
    id="config-form"
    class="dkuform-modal-vertical"
>
    <h4>{{ params.xColumn.name }}</h4>
    <binning-config
        formControlName="xBinningConfig"
        [isCategorical]="atLeastOneCategoricalVariable">
    </binning-config>
</form>
