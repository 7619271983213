export const PROJECT_VALUES = ['DATASET', 'DASHBOARD', 'ARTICLE', 'WEB_APP'] as const;
export const NO_RIGHTS_VALUES = ['LINK'] as const;
export const TYPES_VALUES = [...PROJECT_VALUES,  'APP', ...NO_RIGHTS_VALUES] as const;
export type TYPES = typeof TYPES_VALUES[number];
export interface DssObjectPayload {
    id: string;
    projectKey: string;
    type: TYPES;
}
export interface LinkPayload {
    type: TYPES;
    url: string;
    name: string;
    description: string | null;
}

export type ObjectPayloads = (DssObjectPayload | LinkPayload)[] | null;
