<h4>About</h4>
<markdown
    *ngIf="shortDescription"
    class="highlight"
    [from]="formatShortDescription()"
></markdown>
<markdown
    *ngIf="longDescription"
    class="highlight"
    [from]="formatLongDescription()"
></markdown>