<div>
    <modal-header [title]="data.title" [icon]="data.icon"></modal-header>
    <div class="modal-body flex">
        {{data.message}}
    </div>
    <div class="modal-footer modal-footer-std-buttons">
        <div class="pull-right">
            <button type="button" class="btn btn-primary" (click)="ack();" autofocus>Ok</button>
        </div>
    </div>
</div>
