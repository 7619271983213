export function createGradientColorStyleImg(d3colorPalette: { (t: number): string; }){
    // construct a CSS rule to create a gradient image from d3-scale-chromatic colorPalette.

    const gradientGranularity = 20;
    let gradientStyleImg = 'linear-gradient(90deg,';
    for(let i = 0; i < gradientGranularity; i++) {
        if (i !== 0) {
            gradientStyleImg += ',';
        }
        gradientStyleImg += d3colorPalette(i/gradientGranularity);  
    }

    gradientStyleImg += ')';
    return gradientStyleImg;

}