import type {FilterDesc} from './../../../../../../../../dss-core/src/main/java/com/dataiku/dip/dataflow/exec/filter/filter-desc';
import type {StreamableDatasetSelection} from './../../datasets/streamable-dataset-selection';

/**
 * Generated from com.dataiku.dip.analysis.model.SplitParams
 */
export interface SplitParams {
    efsdDatasetSmartName: string;
    efsdTest: SplitParams.EFSDSplit;
    efsdTrain: SplitParams.EFSDSplit;
    eftdTest: SplitParams.EFTDSplit;
    eftdTrain: SplitParams.EFTDSplit;
    instanceIdRefresher: number;
    kfold: boolean;
    nFolds: number;
    splitBeforePrepare: boolean;
    ssdColumn: string;
    ssdDatasetSmartName: string;
    ssdGroupColumnName: string;
    ssdGrouped: boolean;
    ssdSeed: number;
    ssdSelection: StreamableDatasetSelection;
    ssdSplitMode: SplitParams.SplitMode;
    ssdStratified: boolean;
    ssdTrainingRatio: number;
    streamAll: boolean;
    subSamplingSeed: number;
    testOnLargerValues: boolean;
    ttPolicy: SplitParams.TrainTestPolicy;
}

export namespace SplitParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.SplitParams$EFDSplit
     */
    export interface EFDSplit {
        filter: FilterDesc;
        selection: StreamableDatasetSelection;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.SplitParams$EFSDSplit
     */
    export interface EFSDSplit extends SplitParams.EFDSplit {}

    /**
     * Generated from com.dataiku.dip.analysis.model.SplitParams$EFTDSplit
     */
    export interface EFTDSplit extends SplitParams.EFDSplit {
        datasetSmartName: string;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.SplitParams$TrainTestPolicy
     */
    export enum TrainTestPolicy {
        SPLIT_SINGLE_DATASET = 'SPLIT_SINGLE_DATASET',
        EXPLICIT_FILTERING_SINGLE_DATASET = 'EXPLICIT_FILTERING_SINGLE_DATASET',
        EXPLICIT_FILTERING_TWO_DATASETS = 'EXPLICIT_FILTERING_TWO_DATASETS'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.SplitParams$SplitMode
     */
    export enum SplitMode {
        RANDOM = 'RANDOM',
        SORTED = 'SORTED'
    }
}