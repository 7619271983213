<div class="card-layout">
    <div class="card-layout__section card-layout__section--horizontal">
        <div class="card-layout__section card-layout__section--vertical">
            <div class="card-layout__section">
                <div class="card-layout__title">Summary</div>
                <div class="card-layout__content">
                    <table class="stats-table">
                        <tbody class="stats-table__body">
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Count</th>
                                <td class="stats-table__cell">{{ results.boxPlot.countComplete }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Sample mean</th>
                                <td class="stats-table__cell">{{ results.boxPlot.mean | smarterNumber }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Sample standard deviation</th>
                                <td class="stats-table__cell">{{ results.boxPlot.stdDev | smarterNumber }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-layout__section">
                <div class="card-layout__title">Hypothesis</div>
                <div class="card-layout__content">

                    <table class="stats-table">

                        <tbody class="stats-table__body">
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Hypothesized mean</th>
                                <td class="stats-table__cell">{{ params.hypothesizedMean | smarterNumber }}</td>
                            </tr>
                            <tr *ngIf="params.knownStdDev != null">
                                <th class="stats-table__head">Standard deviation (known)</th>
                                <td class="stats-table__cell">{{ params.knownStdDev | smarterNumber }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Significance level</th>
                                <td class="stats-table__cell">
                                    {{ 1 - params.confidenceLevel | smarterNumber }}
                                </td>
                            </tr>

                        </tbody>
                    </table>

                </div>
            </div>
            <div class="card-layout__section">
                <div class="card-layout__title">Results</div>
                <div class="card-layout__content">
                    <table class="stats-table">
                        <tbody class="stats-table__body">
                            <!-- DOF is only relevant for Student's test -->
                            <tr
                                *ngIf="params.knownStdDev == null"
                                class="stats-table__row"
                            >
                                <th class="stats-table__head">Degrees of freedom</th>
                                <td class="stats-table__cell">{{ results.dof }}</td>
                            </tr>

                            <tr class="stats-table__row">
                                <th class="stats-table__head">
                                    <ng-container *ngIf="params.knownStdDev == null; else ztest">Student's 𝘵-statistic</ng-container>
                                    <ng-template #ztest>z-statistic</ng-template>
                                </th>
                                <td class="stats-table__cell">{{ results.statistic | smarterNumber }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">𝘱-value</th>
                                <td class="stats-table__cell">
                                    <colored-pvalue
                                        [pvalue]="results.pvalue"
                                        [significanceLevel]="1 - params.confidenceLevel"
                                    ></colored-pvalue>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

        <div class="card-layout__section card-layout__section--side">
            <div class="card-layout__title">Test statistic distribution</div>
            <div class="card-layout__content tzdistribution">
                <lazy-echart
                    [options]="statisticDistributionChartOptions">
                </lazy-echart>
            </div>
        </div>
    </div>
</div>
<div class="test-conclusion">
    <ng-container *ngIf="isRejected else inconclusive">
        <p class="test-conclusion__wording test-conclusion__wording--rejected">
            At the given significance level, the population mean of
            <u>{{ params.column.name }}</u>
            is different from
            <u>{{ params.hypothesizedMean | smarterNumber }}</u>
        </p>
    </ng-container>

    <ng-template #inconclusive>
        <p class="test-conclusion__wording">
            At the given significance level, the test is inconclusive about whether the population mean of
            <u>{{ params.column.name }}</u>
            is different from
            <u>{{ params.hypothesizedMean | smarterNumber }}</u>
        </p>
    </ng-template>
</div>
