import { Component, ChangeDetectionStrategy, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { DataCollectionsService } from '@features/data-collections/shared/services/data-collections.service';

import { BasePermissions, UIDataCatalog } from 'src/generated-sources';

@Component({
    selector: 'data-collection-page-right-panel-discoverable-dataset',
    templateUrl: './data-collection-page-right-panel-discoverable-dataset.component.html',
    styleUrls: ['../data-collection-page-right-panel-commons.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataCollectionPageRightPanelDiscoverableDatasetComponent implements OnChanges {
    @Input() details!: UIDataCatalog.DiscoverableDatasetDetails;
    @Input() currentUserPermissions!: BasePermissions.Permissions;

    @Output() removeSelectedItem = new EventEmitter<void>();
    @Output() requestShare = new EventEmitter<void>();

    // the init value of these params don't really matter as they are set in ngOnChange
    shareability = 'nothing' as 'request' | 'nothing';
    shareButtonTooltip = '';

    constructor(
        private dataCollectionsService: DataCollectionsService,
    ) {}

    ngOnChanges(): void {
        this.updateShareButtonState();
    }

    shareButtonAction() {
        if(this.shareability === 'request') {
            this.requestShare.emit();
        }
        // else shareability = 'nothing' and button is considered disabled
    }

    private updateShareButtonState() {
        // TODO @data-collections disable button if already shared 'Dataset already shared to this project'
        const authorizations = this.details.objectAuthorizations;
        const currentProject = this.dataCollectionsService.getProjectContext();

        if(currentProject?.projectKey === this.details.sourceProjectKey) {
            this.shareability = 'nothing';
            this.shareButtonTooltip = 'Target project must be different from source';
        } else if (authorizations.isObjectSharingRequestEnabled) {
            this.shareability = 'request';
            this.shareButtonTooltip = currentProject ? 'Request to use in project '+currentProject.name : 'Request to use in another project';
        } else {
            this.shareability = 'nothing';
            this.shareButtonTooltip = 'No permission to use dataset';
        }
    }
}
