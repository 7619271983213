import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy} from '@angular/core';
import { PICKER_STOCK_COLORS } from './stock-color-picker-colors.constant';

@Component({
    selector: 'stock-color-picker',
    templateUrl: './stock-color-picker.component.html',
    styleUrls: ['./stock-color-picker.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StockColorPickerComponent {
    @Input() color: string;
    @Output() colorChange = new EventEmitter<string>(true);

    readonly stockColors = PICKER_STOCK_COLORS;

    selectColor(selectedColor: string): void {
        this.colorChange.emit(selectedColor);
    }
}
