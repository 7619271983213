<div class="color-options mleft8" [style.--picker-color]="color">
    <ul class="color-options__list horizontal-flex">
        <li *ngFor="let stockColor of stockColors" class="color-options__item">
            <button type="button" [class.selected]="stockColor === color" class="color-dot"
                (click)="selectColor(stockColor)" [style.backgroundColor]="stockColor"></button>
        </li>
    </ul>
    <label class="stock-color-picker__color-input">
        <span class="color-dot">
            <i class="icon-tint stock-color-picker__icon-tint"></i>
        </span>
        <span class="color-code">{{ color }}</span>
        <input type="color" name="pickerColor" [(ngModel)]="color" (ngModelChange)="selectColor($event)">
    </label>
</div>