<div class="labeling-data-tab">
    <labeling-error></labeling-error>
    <div class="section">
        <div class="paned-page-section__heading mbot16">Data</div>
        <div *ngIf="managedFolder$ | async as managedFolder" class="labeling-data-tab__managed-folder-link">
            Images from <a (click)="goToManagedFolder(managedFolder)"><i class="icon-folder-open universe-color managed_folder"></i>{{ managedFolder.label }}</a>
        </div>
        <form [formGroup]="form" *ngIf="metadataColumns" class="form-inline labeling-data-tab__form">
            <div class="control-group">
                <label class="control-label">
                    Path column
                </label>
                <div class="controls">
                    <basic-select
                        formControlName="idColumn"
                        [items]="metadataColumns"
                        [searchable]="true"
                        [bindLabel]="'name'"
                        [bindValue]="'name'"
                    ></basic-select>
                    <span class="help-inline">Column containing the path to the images</span>
                </div>
            </div>
            <div *ngIf="form.controls.idColumn.invalid" class="alert alert-error mtop8 faic">
                <i class="dku-icon-dismiss-16 mright4"></i>
                No path column is defined. Please select one or the labeling task will not be usable
            </div>
            <div class="control-group">
                <label class="control-label">
                    Context columns
                </label>
                <div class="controls">
                    <basic-select
                        formControlName="extraColumns"
                        [items]="metadataColumns"
                        [multiple]="true"
                        [searchable]="true"
                        [bindLabel]="'name'"
                        [bindValue]="'name'"
                    ></basic-select>
                    <span class="help-inline">(Optional) Columns shown alongside the image in Annotate and Review</span>
                </div>
            </div>
        </form>
    </div>
    <image-feed [imageHeight]="200" [itemsPerRow]="4" (imageClick)="openModal($event)" class="labeling-data-tab__image-feed"></image-feed>
</div>