import { Injectable } from "@angular/core";
import { fabric } from "fabric";
import { LabelingDrawingService } from "../../object-detection-image-canvas/labeling-drawing.service";

@Injectable()
export class LabelingReviewDrawingService extends LabelingDrawingService {

    createGroup(rect: fabric.Rect, category?: string, pinned?: boolean): fabric.Group {
        let group: fabric.Group;
        if (category) {
            const fabricText = new fabric.Text(this.formatText(category, rect.width), {
                fontSize: this.FONT_SIZE,
                originX: 'left',
                originY: 'top',
                backgroundColor: rect.stroke,
                fontFamily: 'SourceSansPro',
                fill: '#FFFFFF',
                left: rect.left! + this.STROKE_WIDTH,
                top: rect.top! + rect.height! - this.BOX_TITLE_HEIGHT - this.STROKE_WIDTH
            });
            group = new fabric.Group([rect, fabricText], this.GROUP_SETTINGS);
        } else {
            group = new fabric.Group([rect], this.GROUP_SETTINGS);
        }

        group.setControlsVisibility({ mtr: false });
        if (pinned) {
            group.lockMovementX = true;
            group.lockMovementY = true;
            group.lockScalingX = true;
            group.lockScalingY = true;
            group.hoverCursor = "default";
        }
        return group;
    }

    private formatText(text: string, rectWidth?: number): string {
        if (!rectWidth) {
            return '';
        } else {
            const maxCharacters = Math.floor(rectWidth / 10);
            return ' ' + (maxCharacters >= text.length ? text : text.substring(0, maxCharacters) + '...') + ' '
        }
    }

}