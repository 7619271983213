import { Component, OnChanges, SimpleChanges, Input, ChangeDetectionStrategy } from '@angular/core';
import { normalizeTextForSearch } from '@utils/string-utils';

export type Column = {
    name: string;
    type: string;
    meaning: string | null;
    comment: string | null;
};

type NormalizedColumn = {
    searchTokens: string[],
    column: Column
};

@Component({
    selector: 'right-panel-schema',
    templateUrl: './right-panel-schema.component.html',
    styleUrls: [
        './right-panel-schema.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RightPanelSchemaComponent implements OnChanges {
    @Input() wordingOption: "feature" | "column";
    @Input() columns: Column[]; // this type is compatible with the _source.columns info from the internal catalog ES result, but also with serializedDataset.schema.columns
    @Input() columnHighlight?: string[]; // optional (a list of column names with some parts embedded in a <em> tag)
    @Input() showMeaning: boolean = true;
    @Input() selected?: string; // optional (highlights a specified column)
    @Input() maxHeight: number | null = null;

    filterName: string;
    preparedColumns: NormalizedColumn[];
    filteredHighlightedColumns: Column[];

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.columns) {
            this.filterName = "";
        }
        if (changes.columns || changes.columnHighlight || changes.showMeaning) {
            this.preparedColumns = prepareColumns(this.columns, this.showMeaning, this.columnHighlight);
            this.filteredHighlightedColumns = getFilteredHighlightedColumns(this.preparedColumns, this.filterName);
        }
    }

    updateFilter() {
        this.filteredHighlightedColumns = getFilteredHighlightedColumns(this.preparedColumns, this.filterName);
    }

    hasHighlightedColumns(): boolean {
        return (this.columnHighlight?.length || 0) > 0;
    }

    removeHighlighting(name: string) {
        return removeHighlighting(name);
    }
}

function removeHighlighting(name: string) {
    return name.replace(/<\/?em>/g, '');
}

function prepareColumns(columns: Column[], showMeaning: boolean, columnHighlight: string[] = []) {
    const strippedCols = columnHighlight.map(str => ({stripped: removeHighlighting(str), highlighted: str}));

    return columns.map(col => {
        // get the highlighted version if exists, otherwise the 'normal'
        const name = strippedCols.find(({stripped}) => stripped === col.name)?.highlighted ?? col.name;

        const searchTokens = [col.name, col.type];
        if(col.comment !== null) searchTokens.push(col.comment);
        if(col.meaning !== null && showMeaning) searchTokens.push(col.meaning);

        return {
            column: {
                name,
                type: col.type,
                meaning: col.meaning,
                comment: col.comment,
            },
            searchTokens: searchTokens.map(normalizeTextForSearch)
        };
    });
}

function getFilteredHighlightedColumns(preparedColumns: NormalizedColumn[], searchQuery: string): Column[] {
    const normalizedSearchQuery = normalizeTextForSearch(searchQuery);
    return preparedColumns
        .filter(({searchTokens}) => searchTokens.some(token => token.includes(normalizedSearchQuery)))
        .map(({column}) => column);
}
