import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class SlugifyService {
  transform(input?: string): string {
    if (input === undefined) {
      return '';
    }
    return input
      .replace(/\s+/g, '-')
      .replace(/[^a-zA-Z0-9-]/g, '')
      .replace(/-+/g, '-')
      .toLowerCase();
  }
}
