import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { CellDataWithAnnotation } from '@shared/services/item-feed/image-feed/sample-data-fetcher.service';
import { CellData } from '@shared/services/item-feed/items-data-fetcher.service';

@Component({
    selector: 'image-feed-modal',
    templateUrl: './image-feed-modal.component.html',
    styleUrls: ['./image-feed-modal.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageFeedModalComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            imageId: number
        },
        public colorMapService: ColorMapContextService
        ) {}
    
    isCellDataWithAnnotation(cellData: CellData): cellData is CellDataWithAnnotation {
        return "listCategories" in cellData;
    }
}
