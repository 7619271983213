import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subtypeToColor'
})
export class SubtypeToColorPipe implements PipeTransform {

  constructor(@Inject('TypeMappingService') private readonly typeMappingService: any) { }

  transform(subtype: string, type: string): string {
    return this.typeMappingService.mapSubtypeToColor(subtype, type);
  }
}
