/**
 * Generated from com.dataiku.dip.dataflow.exec.joinlike.JoinType
 */
export enum JoinType {
    INNER = 'INNER',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    FULL = 'FULL',
    CROSS = 'CROSS',
    LEFT_ANTI = 'LEFT_ANTI',
    RIGHT_ANTI = 'RIGHT_ANTI',
    ADVANCED = 'ADVANCED'
}