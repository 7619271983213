import { ElementRef, Injector, Directive, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
    selector: 'ui-sref, [ui-sref]'
})
export class UISrefDirective extends UpgradeComponent {
    @Input() route: string;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('ng1UiSref', elementRef, injector);
    }
}
