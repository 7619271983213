import type {AxisSortPrune} from './axis-sort-prune';
import type {DateAxisParams} from './date-axis-params';
import type {NumericalAxisParams} from './numerical-axis-params';
import type {Unaggregated} from './unaggregated';

/**
 * Generated from com.dataiku.dip.pivot.backend.model.AxisDef
 */
export interface AxisDef {
    column: string;
    dateMode: Unaggregated.DateMode;
    dateParams: DateAxisParams;
    numParams: NumericalAxisParams;
    sortPrune: AxisSortPrune;
    type: AxisDef.Type;
}

export namespace AxisDef {
    /**
     * Generated from com.dataiku.dip.pivot.backend.model.AxisDef$Type
     */
    export enum Type {
        ALPHANUM = 'ALPHANUM',
        NUMERICAL = 'NUMERICAL',
        DATE = 'DATE',
        GEOPOINT = 'GEOPOINT',
        GEOMETRY = 'GEOMETRY'
    }
}