import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ImagesDataFetcherService } from "@shared/services/item-feed/image-feed/images-data-fetcher.service";
import { ObjectDetectionDataFormatterService } from "@shared/services/item-feed/image-feed/object-detection/object-detection-data-formatter.service";
import { ObjectDetectionPainterService } from "@shared/services/item-feed/image-feed/object-detection/object-detection-painter.service";
import { PainterService } from "@shared/services/item-feed/image-feed/painter.service";
import { SampleDataFetcherService } from "@shared/services/item-feed/image-feed/sample-data-fetcher.service";
import { SampleDataFormatterService } from "@shared/services/item-feed/image-feed/sample-data-formatter.service";
import { ItemsDataFetcherService } from "@shared/services/item-feed/items-data-fetcher.service";
import { DatasetViewDataFetcherService } from "../../dataset-view-data-fetcher.service";
import { DatasetViewExploreImagesComponent } from "../dataset-view-explore-images.component";

@Component({
    selector: 'object-detection-dataset-view-explore-images',
    templateUrl: '../dataset-view-explore-images.component.html',
    styleUrls: ['../dataset-view-explore-images.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ObjectDetectionDataFormatterService,
        DatasetViewDataFetcherService,
        ObjectDetectionPainterService,
        { provide: SampleDataFormatterService, useExisting: ObjectDetectionDataFormatterService},
        { provide: ItemsDataFetcherService, useExisting: DatasetViewDataFetcherService },
        { provide: ImagesDataFetcherService, useExisting: DatasetViewDataFetcherService },
        { provide: SampleDataFetcherService, useExisting: DatasetViewDataFetcherService },
        { provide: PainterService, useExisting: ObjectDetectionPainterService },
    ]
})
export class ObjectDetectionDatasetViewExploreImagesComponent extends DatasetViewExploreImagesComponent  {
}