import type {AxisDef} from './../axis-def';
import type {Unaggregated} from './../unaggregated';
import type {_PivotTableRequest} from './../pivot-table-request';

/**
 * Generated from com.dataiku.dip.pivot.backend.model.boxplots.PTBoxplotsRequest
 */
export interface PTBoxplotsRequest extends _PivotTableRequest {
    column: Unaggregated;
    whiskersMode: PTBoxplotsRequest.WhiskersMode;
    whiskersPercentile: number;
    xAxis: AxisDef;
}

export namespace PTBoxplotsRequest {
    /**
     * Generated from com.dataiku.dip.pivot.backend.model.boxplots.PTBoxplotsRequest$WhiskersMode
     */
    export enum WhiskersMode {
        IQR = 'IQR',
        PERCENTILE = 'PERCENTILE'
    }
}