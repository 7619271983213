import type {AbstractNSampTestCard} from './abstract-nsamp-test-card';
import type {CardResult, _CardResult} from './../card-result';
import type {Card} from './../card';
import type {KsTest2SampCard} from './../ks-test2-samp-card';
import type {MoodTest2SampCard} from './../mood-test2-samp-card';
import type {TTest2SampCard} from './../ttest2-samp-card';
import type {_BivariateCard} from './../bivariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.common.Abstract2SampTestCard
 */
export interface _Abstract2SampTestCard extends _BivariateCard {
    confidenceLevel: number;
    value1?: string[] | null;
    value2?: string[] | null;
}

export type Abstract2SampTestCard = KsTest2SampCard | MoodTest2SampCard | TTest2SampCard;

export namespace Abstract2SampTestCard {
    export const type = ['ks_test_2samp', 'mood_test_2samp', 'ttest_2samp'] as const;
}

/** @deprecated Use Card.isAbstract2SampTestCard() */ export function isAbstract2SampTestCard(toTest: Card): toTest is Abstract2SampTestCard { return (Abstract2SampTestCard.type as readonly unknown[]).includes(toTest['type']); }

export namespace Abstract2SampTestCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.common.Abstract2SampTestCard$Abstract2SampTestCardResult
     */
    export interface _Abstract2SampTestCardResult extends _CardResult {
        allGroups: AbstractNSampTestCard.GroupInfo;
        groups: AbstractNSampTestCard.GroupInfo[];
    }

    export type Abstract2SampTestCardResult = KsTest2SampCard.KsTest2SampCardResult | MoodTest2SampCard.MoodTest2SampCardResult | TTest2SampCard.TTest2SampCardResult;

    export namespace Abstract2SampTestCardResult {
        export const type = ['ks_test_2samp', 'mood_test_2samp', 'ttest_2samp'] as const;
    }

    /** @deprecated Use CardResult.isAbstract2SampTestCardResult() */ export function isAbstract2SampTestCardResult(toTest: CardResult): toTest is Abstract2SampTestCardResult { return (Abstract2SampTestCardResult.type as readonly unknown[]).includes(toTest['type']); }
}