<values-list [ngModel]="items"
    (ngModelChange)="handleChange($event)"
    (onValidityChange)="onValidityChange.emit($event)"
    [richValuePlaceholder]="richValuePlaceholder"
    [valuePlaceholder]="valuePlaceholder"
    [valueIdentifier]="valueIdentifier"
    [required]="required"
    [addLabel]="addLabel"
    [sortable]="sortable"
    [hasDivider]="hasDivider"
    [focusOnEnter]="focusOnEnter"
    [fullWidthList]="fullWidthList"
    [disableAdd]="disableAdd"
    [suggestions]="suggestions"
    (onAdd)="onAdd.emit($event)"
    (onMouseLeaveAddButton)="onMouseLeaveAddButton.emit()"
    (onMouseOverAddButton)="onMouseOverAddButton.emit()"
    (onDelete)="onDelete.emit($event)"
    (onFocus)="onFocus.emit($event)"
    (onBlur)="onBlur.emit($event)"
    (onReorder)="onReorder.emit($event)">
</values-list>
