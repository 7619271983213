import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {_AvailableResult} from './../available-result';
import type {_TimeSeriesComputation} from './time-series-computation';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.timeseries.DurbinWatson
 */
export interface DurbinWatson extends _TimeSeriesComputation {
    // PolyJson type
    type: 'durbin_watson';
}

export namespace DurbinWatson {
    export const type = 'durbin_watson';
}

/** @deprecated Use Computation.isDurbinWatson() */ export function isDurbinWatson(toTest: Computation): toTest is DurbinWatson { return DurbinWatson.type === toTest['type']; }

export namespace DurbinWatson {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.DurbinWatson$DurbinWatsonResult
     */
    export interface DurbinWatsonResult extends _AvailableResult {
        statistic: number;

        // PolyJson type
        type: 'durbin_watson';
    }

    export namespace DurbinWatsonResult {
        export const type = 'durbin_watson';
    }

    /** @deprecated Use ComputationResult.isDurbinWatsonResult() */ export function isDurbinWatsonResult(toTest: ComputationResult): toTest is DurbinWatsonResult { return DurbinWatsonResult.type === toTest['type']; }
}