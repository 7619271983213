<p>
    Autocorrelation represents the degree of correlation between a series and a lagged version
    of itself over successive time periods: for instance the values at times T and at time T-1,
    the values at time T and at time T-2, and so on.

    The autocorrelation function is the function that, for a given lag, computes the corresponding
    autocorrelation coefficient.
</p>

<p>
    When autocorrelation is detected, the inspection of the partial autocorrelation function may
    reveal additional information about the influence of specific lags on the autocorrelation
    coefficients. Like the autocorrelation, the partial autocorrelation represents the degree of
    correlation between a series and a lagged version of itself, but it excludes the influence of
    the series values within the observed time period.
</p>
