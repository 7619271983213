<p>
    The sign test is a nonparametric test used to determine whether your data
            could have been drawn from a population where the median of
    <b>{{ params.column.name }}</b>
    is
    <b>
        {{params.hypothesizedMedian | smarterNumber}}
    </b>.
</p>
<p>
    There are two possible outcomes depending on the 𝘱-value:
</p>
<ul>
    <li>
        <b>
            𝘱-value &le; significance level (&le; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>
        <br>
        The hypothesis is rejected.
        The population median of
        <b>{{ params.column.name }}</b>
        is different from {{params.hypothesizedMedian | smarterNumber}}.
    </li>
    <li>
        <b>
            𝘱-value is &gt; significance level (&gt; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>

        <br>
        Not enough evidence against the hypothesis. Test is inconclusive.
    </li>
</ul>
<p>
    It has been shown that the conclusion is meaningful under the following condition:
</p>
<ul>
    <li>
        Observations of
        <b>{{ params.column.name }}</b>
        are independent and identically distributed
    </li>
</ul>
<p>
    <span class="text-weak">
        <i class="icon-info-sign"></i>
        Missing values and values which are equal to the hypothesized median are discarded
    </span>
</p>
