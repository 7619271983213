<per-labeler-stats [perAnnotatorStats]="perAnnotatorStats" [numMetrics]="1">
    <ng-template #headerTemplate>
        <th width="10%">Accuracy  <i class="labeling-overview-tooltip icon-question-sign" matTooltip="Ability of the annotator to classify images correctly. Percentage of correct class annotations (compared to the final review) among all annotations."></i></th>
    </ng-template>
    
    <ng-template #dataTemplate let-metrics="metrics">
        <td>
            {{ metrics.accuracy != undefined ? (metrics.accuracy * 100 | number:'1.0-0') + '%' : '-' }} 
        </td>
    </ng-template>
</per-labeler-stats>
