import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { zipSameSize } from '@features/eda/echarts-utils';
import { NumberFormatterService } from '@features/simple-report/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ColorsService } from '@shared/graphics/colors.service';
import { NicePrecisionPipe } from '@shared/pipes/number-pipes/nice-precision.pipe';
import { DataZoomComponentOption, EChartsOption } from 'echarts';

import { encodeHTML } from 'entities';
import _ from 'lodash';
import { debounceTime, Subject } from 'rxjs';
import { Card, STLDecompositionCard } from 'src/generated-sources';
import { CardAction, CardActionType } from '../../events';


type YAxisData = {
    kind: STLDecompositionCard.SeriesKind;
    series: number[];
    padding: number[];
}

@Component({
    selector: 'timeseries-decomposition-card-body',
    templateUrl: './timeseries-decomposition-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/chart.less',
        '../../../../shared-styles/stats-table.less',
        '../../../../shared-styles/card-layout.less',
        './timeseries-decomposition-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class TimeseriesDecompositionCardBodyComponent implements OnChanges, OnInit {
    @Input() results: STLDecompositionCard.STLDecompositionCardResult;
    @Input() params: STLDecompositionCard;
    @Input() hasFixedHeight: boolean;
    @Input() readOnly: boolean;
    @Output() action = new EventEmitter<CardAction>();

    plotOptions: EChartsOption;
    SERIES_KIND = Object.values(STLDecompositionCard.SeriesKind);
    SERIES_COLOR = new Map(this.SERIES_KIND.map(kind => [kind, this.colorsService.getColorForVariable(kind)]));
    private dataZoomClick$ = new Subject<any>();
    constructor(
        private colorsService: ColorsService,
        private nicePrecisionPipe: NicePrecisionPipe,
        private numberFormatterService: NumberFormatterService,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results || changes.params) {
            this.plotOptions = this.results && this.buildChartOptions(this.results);
        }
    }

    ngOnInit() {
        this.dataZoomClick$.pipe(
            debounceTime(300),
            untilDestroyed(this)
        ).subscribe(event => {
            this.updateCard({
                ...this.params,
                dataZoomStart: event.start ?? event.batch[0].start,
                dataZoomEnd: event.end ?? event.batch[0].end
            })
        })
    }

    dataZoomClicked(event: any) {
        this.dataZoomClick$.next(event);
    }

    getSelectedSeries() {
        return this.params.showCompactChart ? this.params.selectedSeries : this.SERIES_KIND;
    }

    formatSeriesKind(kind: STLDecompositionCard.SeriesKind) {
        return kind.charAt(0) + kind.substring(1).toLowerCase();
    }

    displaySerie(serieName: STLDecompositionCard.SeriesKind) {
        if (!this.params.showCompactChart) {
            return;
        }
        const selectedSeries = [...this.params.selectedSeries];
        let idx = selectedSeries.indexOf(serieName)
        if (idx >= 0 && this.getSelectedSeries().length <= 1) {
            // <=1 because we don't want to have zero line on the chart
            return;
        } else if (idx >= 0 && this.getSelectedSeries().length > 1) {
            // deselection
            selectedSeries.splice(idx, 1);
        } else {
            // selection
            selectedSeries.splice(this.SERIES_KIND.indexOf(serieName), 0, serieName);
        }

        this.updateCard({
            ...this.params,
            selectedSeries,
        });
    }

    toggleCompactChart() {
        this.updateCard({
            ...this.params,
            showCompactChart: !this.params.showCompactChart
        })
    }

    private getCircleTemplateHTML(color: string) {
        return '<span class="chart-tooltip-circle" style="background-color:' + encodeHTML(color) + ';"></span>'
    }

    private getAxisFormatter(serie: number[]): (value: number) => string {
        return this.numberFormatterService.get(_.min(serie) || 0, _.max(serie) || 0, serie.length, false, true)
    }

    private formatTooltipNumber(value: number) {
        // Casting to number then string to remove trailing zeros
        return Number(value.toPrecision(8)).toString()
    }

    private getXaxis(numberOfSeries: number): EChartsOption["xAxis"] {
        const xAxis = [];
        for (let i = 0; i < numberOfSeries; i++) {
            xAxis.push({
                show: i == numberOfSeries - 1 || numberOfSeries == 1,
                gridIndex: i,
                type: "time",
            });
        }
        return xAxis as EChartsOption["xAxis"];
    }

    private addSeriesNameAndMin(axisOption: any, series: number[], padding: number[], kind: STLDecompositionCard.SeriesKind) {
        // axisOption type should be YAXisOption but it is not exported by Echarts
        const seriesMin = this.nicePrecisionPipe.transform(_.min(series), 3);
        return {
            ...axisOption,
            min: seriesMin,
            name: this.formatSeriesKind(kind),
            nameLocation: "middle",
            nameGap: 60,
            nameTextStyle: {
                backgroundColor : "#cccccc",
                padding,
            },
        }
    }

    private getYaxis(yAxesData: YAxisData[]): EChartsOption["yAxis"] {
        let axesToDisplayData = _.cloneDeep(yAxesData);
        let selectedSeries = this.getSelectedSeries();
        axesToDisplayData = axesToDisplayData.filter(function(value, index) {
            return selectedSeries.indexOf(value.kind) != -1;
       })

        return axesToDisplayData.map(({ kind, series, padding }, i) => {

            const formatter = this.getAxisFormatter(series);

            let axisOption = {
                gridIndex: this.params.showCompactChart ? 0 : i,
                axisLabel: {
                    formatter,
                },
            };
            if (this.params.showCompactChart) {
                return axisOption;
            } else {
                return this.addSeriesNameAndMin(axisOption, series, padding, kind);
            }
        });
    }

    private getGrid(numberOfSeries: number): EChartsOption["grid"] {
        switch (numberOfSeries) {
            case 1:
                return [
                    { top : '2%', bottom: '12%', left: 60, right: 30 },
                ];
            case 4:
                return [
                    { top : '2%', height: '17%', left: 110, right: 30 },
                    { top: '25%', height: '17%', left: 110, right: 30 },
                    { top: '48%', height: '17%', left: 110, right: 30 },
                    { bottom: '12%', height: '17%', left: 110, right: 30 },
                ];
            default:
                throw new Error("Invalid number of series: " + numberOfSeries)
        }
    }

    private addColor(seriesOption: any, chartType: string, serieName: STLDecompositionCard.SeriesKind) {
        switch (chartType) {
            case "bar":
                return {
                    ...seriesOption,
                    itemStyle : {
                        color : this.SERIES_COLOR.get(serieName)
                    }
                }
            case "line":
                return {
                    ...seriesOption,
                    lineStyle: {
                        color: this.SERIES_COLOR.get(serieName),
                    },
                }
            default:
                throw new Error('Not supported chart type')
        }
    }

    private getSeries(yAxesData: YAxisData[], results: STLDecompositionCard.STLDecompositionCardResult): EChartsOption["series"] {
        let series = [];
        for (let i = 0; i < this.SERIES_KIND.length; i++) {
            if (this.getSelectedSeries().includes(this.SERIES_KIND[i])) {
                let chartType = yAxesData[i].kind == STLDecompositionCard.SeriesKind.RESIDUALS ? 'bar' : 'line';
                let serieOption = {
                    type: chartType,
                    showSymbol: false,
                    data: zipSameSize(results.time, yAxesData[i].series),
                    xAxisIndex: this.params.showCompactChart ? 0 : i,
                    yAxisIndex: this.params.showCompactChart ? 0 : i,

                    emphasis: {
                        itemStyle: {
                            borderColor: this.SERIES_COLOR.get(yAxesData[i].kind),
                        },
                    },
                }
                series.push(
                    this.addColor(serieOption, chartType, yAxesData[i].kind)
                )

            }
        }
        return series as EChartsOption["series"];
    }

    private getNumberOfSeries() {
        return this.params.showCompactChart ? 1 : this.SERIES_KIND.length;
    }

    private buildChartOptions(results: STLDecompositionCard.STLDecompositionCardResult): EChartsOption {
        const yAxesData : YAxisData[] = [
            { kind: STLDecompositionCard.SeriesKind.OBSERVED, series: results.observed, padding: [4, 26] },
            { kind: STLDecompositionCard.SeriesKind.TREND, series: results.trend, padding: [4, 36] },
            { kind: STLDecompositionCard.SeriesKind.SEASONALITY, series: results.seasonal, padding: [4, 24] },
            { kind: STLDecompositionCard.SeriesKind.RESIDUALS, series: results.resid, padding: [4, 30] },
        ];

        let options = {
            tooltip: {
                trigger: "axis",
                formatter: this.getTooltipFormatter(yAxesData, results),
            },
            xAxis: this.getXaxis(this.getNumberOfSeries()),
            yAxis: this.getYaxis(yAxesData),
            grid: this.getGrid(this.getNumberOfSeries()),
            series: this.getSeries(yAxesData, results),
            axisPointer: {
                link: [
                    {
                        xAxisIndex: [0, 1, 2, 3],
                    },
                ],
            },
            dataZoom: this.getDataZoom(),
        };
        return options as EChartsOption;
    }

    private getDataZoom(): DataZoomComponentOption[] {
        if (this.readOnly) {
            return [{
                type: 'inside',
                xAxisIndex: [0, 1, 2, 3],
                start: this.params.dataZoomStart,
                end: this.params.dataZoomEnd,
                disabled: true
            }];
        } else {
            return  [{
                type: 'inside',
                xAxisIndex: [0, 1, 2, 3],
                start: this.params.dataZoomStart,
                end: this.params.dataZoomEnd
            },
            {
                type: 'slider',
                xAxisIndex: [0, 1, 2, 3],
                width: "80%",
                left: "10%",
                start: this.params.dataZoomStart,
                end: this.params.dataZoomEnd
            }];
        }
    }

    getTooltipFormatter(yAxesData: YAxisData[], results: STLDecompositionCard.STLDecompositionCardResult) {
        return (param: any) => {
            // Parsing epoch and removing milliseconds
            const index = param[0].dataIndex as number;
            const time = this.results.time[index];
            let tooltip = `Time: ${encodeHTML(time)}<br/>`;
            for (let axisData of yAxesData) {
                if (this.getSelectedSeries().includes(axisData.kind)) {
                    tooltip += this.getCircleTemplateHTML(this.SERIES_COLOR.get(axisData.kind)!) + this.formatSeriesKind(axisData.kind) + `: ${encodeHTML(this.formatTooltipNumber(axisData.series[index]))}<br />`
                }
            }
            return  tooltip;
        }
    }

    updateCard(card: Card) {
        if (this.readOnly) {
            return;
        }
        this.action.emit({ type: CardActionType.UPDATE, newParams: card});
    }
}
