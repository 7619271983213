import { Component, ElementRef, OnInit, Input, ChangeDetectionStrategy, ViewChild, NgZone } from '@angular/core';

@Component({
    selector: 'drag-scroll',
    templateUrl: './drag-scroll.component.html',
    styleUrls: [
        './drag-scroll.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DragScrollComponent implements OnInit {
    @Input() dragEnabled = false;
    @Input() height: string | number | null = null;

    @ViewChild('scroller', { static: true }) el: ElementRef;

    dragging = false;
    selecting = false;
    lastPositionX: number;
    lastPositionY: number;
    SCROLL_RATE = 1;

    constructor(private zone: NgZone) { }

    ngOnInit() {
        // don't want to fire angular change detection every time the mouse moves
        this.zone.runOutsideAngular(() => {
            this.el.nativeElement.addEventListener('mousemove', this.onMouseMove.bind(this));
        });
    }

    onMouseDown(event: MouseEvent) {
        if (this.dragEnabled) {
            const target = event.target as Element;

            if (!(target && this.clickedOnText(target, event.pageX, event.pageY))) {
                this.dragging = true;
                this.lastPositionX = event.pageX;
                this.lastPositionY = event.pageY;
            } else {
                this.selecting = true;
            }
        }
    }

    onMouseMove(event: MouseEvent) {
        if (this.dragging && this.dragEnabled) {
            // prevent text selection
            event.preventDefault();

            // get current X position
            const currentPositionX = event.pageX;
            const currentPositionY = event.pageY;

            const deltaX = (this.lastPositionX - currentPositionX) * this.SCROLL_RATE;
            const deltaY = (this.lastPositionY - currentPositionY) * this.SCROLL_RATE;
            const scrollLeft = this.el.nativeElement.scrollLeft;
            const scrollTop = this.el.nativeElement.scrollTop;

            this.el.nativeElement.scrollLeft = scrollLeft + deltaX;
            this.el.nativeElement.scrollTop = scrollTop + deltaY;
            this.lastPositionX = currentPositionX;
            this.lastPositionY = currentPositionY;
        }
    }

    onMouseUp(event: MouseEvent) {
        this.dragging = false;
        this.selecting = false;
    }

    onMouseLeave(event: MouseEvent) {
        this.dragging = false;
        this.selecting = false;
    }

    private clickedOnText(element: Element, x: number, y: number) {
        // Check current element
        const nodeName = element.nodeName.toLowerCase();
        if (nodeName === 'textarea' || nodeName === 'input') {
            return true;
        }

        // Check children
        let clickedText = false;
        const nodes = element.childNodes;
        const range = document.createRange();
        nodes.forEach(node => {
            if (!clickedText && node.nodeType === node.TEXT_NODE) {
                range.selectNodeContents(node);
                if (this.isInside(x, y, range.getBoundingClientRect())) {
                    clickedText = true;
                }
            }
        });

        return clickedText;
    }

    private isInside(x: number, y: number, rect: DOMRect) {
        return x >= rect.left && y >= rect.top
            && x <= rect.right && y <= rect.bottom;
    }
}
