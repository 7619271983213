import { Component, Inject } from '@angular/core';
import { fairAny } from 'dku-frontend-core';

@Component({
    selector: 'waiting-overlay',
    templateUrl: './waiting-overlay.component.html',
    styleUrls: ['./waiting-overlay.component.less']
})
export class WaitingOverlayComponent {
    percentage = 0;
    radius = 54;
    stackMessage = '';
    started = false;
    abortFunction?: () => void;

    constructor(
        @Inject('ProgressStackMessageBuilder') private ProgressStackMessageBuilder: fairAny
    ) {
    }

    setAbortFunction(abortFunction: () => void) {
        this.abortFunction = abortFunction;
    }

    abort(): void {
        if (this.abortFunction) {
            this.abortFunction();
        }
    }

    update(progress: any) {
        this.percentage = this.ProgressStackMessageBuilder.getPercentage(progress);
        this.started = progress && progress.states && progress.states.length;
        this.stackMessage = this.ProgressStackMessageBuilder.build(progress);
    }
}
