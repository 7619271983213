import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.Shapiro
 */
export interface Shapiro extends Computation._UnivariateComputation {
    // PolyJson type
    type: 'shapiro';
}

export namespace Shapiro {
    export const type = 'shapiro';
}

/** @deprecated Use Computation.isShapiro() */ export function isShapiro(toTest: Computation): toTest is Shapiro { return Shapiro.type === toTest['type']; }

export namespace Shapiro {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.Shapiro$ShapiroResult
     */
    export interface ShapiroResult extends _AvailableResult {
        pvalue: number;
        statistic: number;

        // PolyJson type
        type: 'shapiro';
    }

    export namespace ShapiroResult {
        export const type = 'shapiro';
    }

    /** @deprecated Use ComputationResult.isShapiroResult() */ export function isShapiroResult(toTest: ComputationResult): toTest is ShapiroResult { return ShapiroResult.type === toTest['type']; }
}