import { Observable, ReplaySubject, Subject } from 'rxjs';

type ObservedElement = {
    subscriberCount: number,
    subject: Subject<ResizeObserverEntry>,
    observable: Observable<ResizeObserverEntry>
};

const observedElements = new Map<Element, ObservedElement>();
const resizeObserver = new ResizeObserver(changes => {
    changes.forEach(change => observedElements.get(change.target)?.subject.next(change));
});


function makeObservable(el: Element) {
    const subject = new ReplaySubject<ResizeObserverEntry>(1); // so that a sub to an element that is already watched fires immediately with the latest info
    const observedElement = {
        subscriberCount: 0,
        subject: subject,
        observable: new Observable<ResizeObserverEntry>((subscriber) => {
            if(observedElement.subscriberCount == 0) {
                resizeObserver.observe(el);
            }
            observedElement.subscriberCount += 1;
            const subToObservedElement = subject.subscribe(subscriber);

            // unsubscribe function
            return () => {
                subToObservedElement.unsubscribe();
                observedElement.subscriberCount -= 1;
                if(observedElement.subscriberCount == 0) {
                    resizeObserver.unobserve(el);
                    observedElements.delete(el);
                }
            };
        }),
    };
    return observedElement;
}

/**
 * Observes an element size changes
 * A single ResizeObserver is used in order to get decent perfs even with hundreds of elements.
 * Function keeps track of subscriber counts and unobserve elements that are not needed anymore.
 * This means you MUST unsubscribe from the observable returned, but you don't have to handle the ResizeObserver manually.
 * @param el the html element you want to watch
 * @returns an observable that fires on the ResizeObserverEntry created by a ResizeObserver for that element
 */
export function resize$(el: Element) {
    let observedElement = observedElements.get(el);
    if(!observedElement) {
        observedElement = makeObservable(el);
        observedElements.set(el, observedElement);
    }
    return observedElement.observable;
}
