<div class="bbox-thumb-wrapper">
    <div [ngStyle]="{ 
            'height': bboxHeight * scale + 'px',
            'width': bboxWidth * scale + 'px',
            'backgroundColor': color | rgbToString:0.3,
            'borderColor': color | rgbToString:0.8
        }"
        class="bbox-thumb"
         *ngIf="scale">
    </div>
</div>