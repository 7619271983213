import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: '[textOverflowTooltip]'
})
export class TextOverflowTooltipDirective extends UpgradeComponent  {
  @Input()
  textTooltip: string | null;

  @Input()
  tooltipDirection?: 'tooltip-left' | 'tooltip-right' | 'tooltip-bottom' | 'tooltip-top'; // undefined is allowed and will fallback on the default from the angularjs directive for consistency.

  @Input()
  allowHtml?: boolean;

  constructor(elementRef: ElementRef, injector: Injector) {
      super('ng1TextOverflowTooltip', elementRef, injector);
  }
}

