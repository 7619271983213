import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {Grouping} from './../../grouping/grouping';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.OneWayAnova
 */
export interface OneWayAnova extends Computation._UnivariateComputation {
    grouping: Grouping;

    // PolyJson type
    type: 'one_way_anova';
}

export namespace OneWayAnova {
    export const type = 'one_way_anova';
}

/** @deprecated Use Computation.isOneWayAnova() */ export function isOneWayAnova(toTest: Computation): toTest is OneWayAnova { return OneWayAnova.type === toTest['type']; }

export namespace OneWayAnova {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.OneWayAnova$OneWayAnovaResult
     */
    export interface OneWayAnovaResult extends _AvailableResult {
        pvalue: number;
        statistic: number;

        // PolyJson type
        type: 'one_way_anova';
    }

    export namespace OneWayAnovaResult {
        export const type = 'one_way_anova';
    }

    /** @deprecated Use ComputationResult.isOneWayAnovaResult() */ export function isOneWayAnovaResult(toTest: ComputationResult): toTest is OneWayAnovaResult { return OneWayAnovaResult.type === toTest['type']; }
}