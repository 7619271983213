import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {Distribution} from './../../distributions/distribution';
import type {TestDistribution} from './test-distribution';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.FitDistribution
 */
export interface FitDistribution extends Computation._UnivariateComputation {
    distribution: Distribution;
    test: boolean;

    // PolyJson type
    type: 'fit_distribution';
}

export namespace FitDistribution {
    export const type = 'fit_distribution';
}

/** @deprecated Use Computation.isFitDistribution() */ export function isFitDistribution(toTest: Computation): toTest is FitDistribution { return FitDistribution.type === toTest['type']; }

export namespace FitDistribution {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.FitDistribution$FitDistributionResult
     */
    export interface FitDistributionResult extends _AvailableResult {
        fit: Distribution.FittedDistribution;
        test?: TestDistribution.TestDistributionResult | null;

        // PolyJson type
        type: 'fit_distribution';
    }

    export namespace FitDistributionResult {
        export const type = 'fit_distribution';
    }

    /** @deprecated Use ComputationResult.isFitDistributionResult() */ export function isFitDistributionResult(toTest: ComputationResult): toTest is FitDistributionResult { return FitDistributionResult.type === toTest['type']; }
}