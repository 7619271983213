<div class="item-feed__container" [class.item-feed__container--single-row]="itemsPerRow === 1" #container>
    <div class="tac" *ngIf="loadingStatus$ | async">
        <i class="dku-loader icon-spin dku-loader--inlined item-feed__loader"></i>
    </div>    
    <infinite-scroll class="w100 h100" (itemSelect)="itemClick.emit($event)" [getChunkFn]="getChunk" [selectedIndex]="selectedIndex" [chunkSize]="getChunkSize()" [itemsPerRow]="itemsPerRow" [itemHeight]="itemHeight" (loadingStatus)="loadingStatus$.next($event)">
        <ng-template let-item="item" let-index="index">
            <div class="item-feed-item" 
                 [ngClass]="{ 'item-feed-item--selected': selectedIndex !== undefined && selectedIndex === index ,
                              'cursor-pointer': !!itemClick && (selectedId === undefined || selectedId !== item?.itemId)}"
                 [style.height.px]="itemHeight"
                 (click)="itemClick?.emit(item)">
                 <ng-container *ngTemplateOutlet="template, context: { item: item, index: index }"></ng-container>
            </div>
        </ng-template>
    </infinite-scroll>
</div>
