export interface Result<T> {
    aggregations: Record<string, Aggregation>
    hits: ResultHits<T>
}

export interface Aggregation {
    agg: Agg
    doc_count: number
}

export interface Agg {
    buckets: Bucket[],
    doc_count_error_upper_bound: number
    sum_other_doc_count: number
}

export interface Bucket {
    key: string | number
    key_as_string?: string
    doc_count: number
}

export interface ResultHits<T> {
    hits: Hit<T>[]
    total: number
}

export interface Hit<T> {
    highlight?: Highlight
    _id: string
    _source: T
    _type: string
    _score: number
}

export function isDataSourceExternalTable(toTest: Hit<Source> | undefined): toTest is Hit<DataSourceExternalTable> { return toTest !== undefined && toTest._type === 'table';}
export function isDataSourceDataset(toTest: Hit<Source> | undefined): toTest is Hit<DataSourceDataset> { return toTest !== undefined && toTest._type === 'dataset';}

export interface Source {
    type: string | string[]
}

export interface DataSourceExternalTable extends Source {
    name: string
    connection: string
    catalog: string | undefined | null
    schema: string
    description: string | undefined | null
    tag: string[]
    columns: ExternalTableColumn[]
    numColumns: number
    connectionType: string
    remarks: string | undefined | null

    rawRelatedItems: RawRelatedItems
}

export interface ExternalTableColumn {
    name: string
    remarks: string
    type: string
}

export interface RawRelatedItems {
    projects: RelatedProjects[];
}

export interface RelatedProjects {
    name: string
    key: string
    datasets: RelatedDatasets[]
}

export interface RelatedDatasets {
    name: string
    type: string
}

export interface DataSourceDataset extends Source {
    id: string
    connection: string
    createdBy: number
    createdOn: number
    columns: Column[]
    description: string
    shortDesc: string
    isQuicklyShareable: boolean
    lastModifiedBy: number
    lastModifiedOn: number
    name: string
    numColumns: number
    projectKey: string
    projectName: string
    tag: string[]
    type_raw: string
    usedIn: string[]
    user: string[]
}

export interface Column {
    comment: string | null
    name: string
    type: string
    meaning: string | null
}

export interface Highlight {
    name?: string[]
    "name.raw"?: string[]
    "name.plaintext"?: string[]
    tag?: string[]
    "tag.plaintext"?: string[]
    projectKey?: string[]
    "projectKey.plaintext"?: string[]
    projectName?: string[]
    "projectName.plaintext"?: string[]
    shortDesc?: string[]
    "shortDesc.plaintext"?: string[]
    description?: string[]
    "description.plaintext"?: string[]
    column?: string[]
    "column.plaintext"?: string[]
    dataset?: string[]
    "dataset.raw"?: string[]
    "dataset.plaintext"?: string[]
}
