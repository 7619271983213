import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Directive, ElementRef } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { fairAny } from 'dku-frontend-core';

/**
 * This directive applies on any MatTooltip
 * It watches the position of the tooltip with regard to the element it's applied to, and controls whether we want to show the caret or not.
 * The rule is that the caret should be displayed if its pointy end coordinate (vertical if caret is left/right, hrz otherwise)
 * intersects is within the corresponding dimension of the bounding box of the element it's related to.
 *
 * This way, the tooltip look like the old bootstrap tooltip most of the time (with the caret),
 * but if the tooltip position would make the caret point somewhere else, we hide it in order to avoid being misleading
*/

// keep in sync with dku-material-theme.less. caret is only displayed if the mat-tooltip-panel has this class
const WITH_CARET_CLASS = 'mat-tooltip-with-caret';

// test if the caret position intersect the vertical side of the element
function intersectVert(paneBoundingRect: DOMRect, elementBoundingRect: DOMRect) {
    const caretPosition = (paneBoundingRect.top + paneBoundingRect.bottom)/2;
    return caretPosition >= elementBoundingRect.top && caretPosition <= elementBoundingRect.bottom;
}

// test if the caret position intersect the horizontal side of the element
function intersectHrz(paneBoundingRect: DOMRect, elementBoundingRect: DOMRect) {
    const caretPosition = (paneBoundingRect.left + paneBoundingRect.right)/2;
    return caretPosition >= elementBoundingRect.left && caretPosition <= elementBoundingRect.right;
}


@Directive({
    selector: '[matTooltip]'
})
export class MatTooltipCaretDirective {
    anyTooltip: fairAny; // magic trick to access MatTooltip private fields

    constructor(
        private matTooltip: MatTooltip,
        private elementRef: ElementRef<HTMLElement>
    ) {
        this.anyTooltip = this.matTooltip;

        // _updateCurrentPositionClass is the method that adds the real-position class on the tooltip panel
        // it's an ideal hook because it's called on each tooltip position change and will ensure that the _currentPosition is fresh updated
        const originalUpdateCurrentPositionClass = this.anyTooltip._updateCurrentPositionClass.bind(this.matTooltip);
        this.anyTooltip._updateCurrentPositionClass = (connectionPair: ConnectionPositionPair) => {
            originalUpdateCurrentPositionClass(connectionPair);
            this.updateCaretClass();
        };
    }

    updateCaretClass() {
        const showCaret = this.shouldShowCaret();
        this.anyTooltip._overlayRef[showCaret ? 'addPanelClass': 'removePanelClass'](WITH_CARET_CLASS);
    }

    shouldShowCaret() {
        const positionStrategy = this.anyTooltip._overlayRef._positionStrategy;

        if(! positionStrategy._pane) { // just for safety, should not happen
            return false;
        }

        // _isPushed is true if the position strategy was forced to move the tooltip from its favorite position
        // to keep it in the viewport.
        // if false, the tooltip HAS TO be aligned with the element and further checks are useless.
        else if(positionStrategy._isPushed !== true) {
            return true;
        }

        // We check if the caret position (which is the middle of the adequate side) intersect the element that defines the tooltip.
        // It allows to still show the tooltip if it's only pushed a little, and caret still points the right element.
        if(['left', 'right', 'before', 'after'].includes(this.anyTooltip._currentPosition)) {
            return intersectVert(
                positionStrategy._pane.getBoundingClientRect(),
                this.elementRef.nativeElement.getBoundingClientRect()
            );
        } else {
            return intersectHrz(
                positionStrategy._pane.getBoundingClientRect(),
                this.elementRef.nativeElement.getBoundingClientRect()
            );
        }
    }
}
