/**
 * Generated from com.dataiku.dip.datasets.SamplingParam
 */
export interface SamplingParam {
    ascending: boolean;
    column?: string | null;
    maxReadUncompressedBytes: number;
    maxRecords: number;
    samplingMethod: SamplingParam.SamplingMethod;
    seed?: number | null;
    targetRatio: number;
    withinFirstN: number;
}

export namespace SamplingParam {
    /**
     * Generated from com.dataiku.dip.datasets.SamplingParam$SamplingMethod
     */
    export enum SamplingMethod {
        FULL = 'FULL',
        HEAD_SEQUENTIAL = 'HEAD_SEQUENTIAL',
        TAIL_SEQUENTIAL = 'TAIL_SEQUENTIAL',
        RANDOM_FIXED_NB = 'RANDOM_FIXED_NB',
        RANDOM_FIXED_RATIO = 'RANDOM_FIXED_RATIO',
        COLUMN_BASED = 'COLUMN_BASED',
        STRATIFIED_TARGET_NB_EXACT = 'STRATIFIED_TARGET_NB_EXACT',
        STRATIFIED_TARGET_RATIO_EXACT = 'STRATIFIED_TARGET_RATIO_EXACT',
        CLASS_REBALANCE_TARGET_NB_APPROX = 'CLASS_REBALANCE_TARGET_NB_APPROX',
        CLASS_REBALANCE_TARGET_RATIO_APPROX = 'CLASS_REBALANCE_TARGET_RATIO_APPROX',
        RANDOM_FIXED_NB_EXACT = 'RANDOM_FIXED_NB_EXACT',
        RANDOM_FIXED_RATIO_EXACT = 'RANDOM_FIXED_RATIO_EXACT',
        COLUMN_ORDERED = 'COLUMN_ORDERED'
    }
}