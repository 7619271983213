import type {ComputedColumn} from './../computedcolumn/computed-column';
import type {FilterDesc} from './../../../../../../../../../dss-core/src/main/java/com/dataiku/dip/dataflow/exec/filter/filter-desc';
import type {_InputDescBase} from './input-desc-base';

/**
 * Generated from com.dataiku.dip.dataflow.exec.joinlike.JoinInputDescBase
 */
export interface JoinInputDescBase extends _InputDescBase {
    alias: string;
    computedColumns: ComputedColumn[];
    outputColumnsSelectionMode: JoinInputDescBase.OutputColumnsSelectionMode;
    preFilter: FilterDesc;
    prefix: string;
}

export namespace JoinInputDescBase {
    /**
     * Generated from com.dataiku.dip.dataflow.exec.joinlike.JoinInputDescBase$OutputColumnsSelectionMode
     */
    export enum OutputColumnsSelectionMode {
        MANUAL = 'MANUAL',
        ALL = 'ALL',
        AUTO_NON_CONFLICTING = 'AUTO_NON_CONFLICTING'
    }
}