<div [matTooltip]="(!options || !options.length) ? 'No filters to display' : ''">
    <ng-select 
        placeholder="{{placeholder}}" 
        [disabled]="!options || !options.length"
        [items]="options" 
        [bindValue]="'key'"
        [bindLabel]="'key'"
        appendTo="body"
        [closeOnSelect]="false" 
        [clearable]="false"
        [searchable]="false" 
        [multiple]="true" 
        (ngModelChange)="onModelChange($event)"
        [ngModel]="facets[key]">

        <ng-template ng-multi-label-tmp let-items="items">
            <!-- Template for the menu trigger in multiple mode -->
            <span *ngIf="facets[key]">
                <b *ngIf="facets[key].length">{{ facets[key].length }}</b>
                {{ placeholder | plurify: usePlural ? facets[key].length : 1}}
            </span>
        </ng-template>

        <ng-template ng-header-tmp>
            <ng-select-search-input *ngIf="options && options.length > 10"></ng-select-search-input>
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
            <ng-select-pseudo-checkbox></ng-select-pseudo-checkbox>
            <div *ngIf="key === 'tag.raw'" class="filter-option filter-option-tag">
                <span class="filter-option-tag__color" [style.background-color]="taggingService.getTagColor(item.key)"></span>
                <ui-global-tag [tag]="item.key" objectType="TAGGABLE_OBJECT"></ui-global-tag>
            </div>
            <div *ngIf="key !== 'tag.raw'" class="filter-option" style="">{{ formatFacetValue(key, item.key) }}</div>
            <b class="filter-doc-count">{{ item.doc_count }}</b>
        </ng-template>
    </ng-select>
</div>