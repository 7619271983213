import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filesize'
})
export class FilesizePipe implements PipeTransform {

    transform(size: number) {
        if (size >= 1024 * 1024 * 1024) {
            return Math.round(size / 1024 / 1024 / 1024 * 100) / 100 + ' GB';
        } else if (size >= 1024 * 1024) {
            return Math.round(size / 1024 / 1024 * 100) / 100 + ' MB';
        } else {
            return Math.round(size / 1024 * 100) / 100 + ' KB';
        }
    }

}
