<div 
    *ngIf="canPublishToDataCollection"
    class="data-collection-page__empty-collection-placeholder"
>
    <i class="dku-icon-dataset-48"></i>
    <h2 class="dku-large-title">Add datasets to your data collection</h2>
    <p>Publish datasets to this collection to promote data discovery and reuse across your organization</p>
    <button
        class="btn btn--primary btn--dku-icon"
        (click)="addObjectsToCollection.emit()"
    >
        <i class="dku-icon-plus-16"></i>
        Add Dataset
    </button>
</div>

<div 
    *ngIf="!canPublishToDataCollection"
    class="data-collection-page__empty-collection-placeholder"
>
    <i class="dku-icon-dataset-48"></i>
    <h2 class="dku-grand-title">No datasets in data collection yet</h2>
    <p>Users with permission can publish datasets to this data collection to facilitate data discovery and reuse</p>
</div>
