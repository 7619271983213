import { Inject, Injectable } from '@angular/core';
import { UIDataCatalog } from 'generated-sources';
import { DataCatalogRightPanelContextService } from '@features/data-catalog/data-catalog-right-panel-context.service';
import { Observable } from 'rxjs';
import { DataCollectionsService } from '@features/data-collections/shared/services/data-collections.service';
import { ErrorContextService } from '@shared/services/error-context.service';
import { InterestsService } from '@shared/services';
import { WaitingService } from '@core/overlays/waiting.service';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { fairAny } from 'dku-frontend-core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { EditDatasetDataStewardModalService } from '@migration/upgraded-providers';
import { DataCatalogService } from '@features/data-catalog/data-catalog.service';
import { DataCollectionFullItemRef } from '@features/data-collections/utils';
import { LegacyDialogsService } from '@shared/components/dialogs/legacy-dialogs.service';
import { ShareAndPublishService } from '@shared/services/share-and-publish.service';
import { DatasetAndTablePreview } from '@shared/services/dataset-and-table-preview/dataset-and-table-preview.service';

@Injectable()
export class DataCollectionPageRightPanelContextService extends DataCatalogRightPanelContextService<DataCollectionFullItemRef> {
    constructor(
        errorContext: ErrorContextService,
        interestService: InterestsService,
        waitingService: WaitingService,
        currentRouteService: CurrentRouteService,
        @Inject('$rootScope') $rootScope: fairAny,
        @Inject('ConnectionExplorationService') ConnectionExplorationService: fairAny,
        @Inject('ExportUtils') ExportUtils: fairAny,
        dataikuApiService: DataikuAPIService,
        private dataCollectionsService: DataCollectionsService,
        @Inject('EditDatasetDataStewardModalService') editDatasetDataStewardModalService: EditDatasetDataStewardModalService,
        dataCatalogService: DataCatalogService,
        legacyDialogsService: LegacyDialogsService,
        shareAndPublishService: ShareAndPublishService,
        datasetAndTablePreview: DatasetAndTablePreview
    ) {
        super(errorContext, interestService, waitingService, currentRouteService, $rootScope, ExportUtils, ConnectionExplorationService, dataikuApiService, editDatasetDataStewardModalService, dataCatalogService, legacyDialogsService, shareAndPublishService, datasetAndTablePreview);
    }

    protected fetchDetails(itemRef: DataCollectionFullItemRef): Observable<UIDataCatalog.AbstractDataCatalogItemDetails | undefined> {
        return this.dataCollectionsService.getItemDetails(itemRef.dataCollectionId, itemRef);
    }
}
