import { CodeEnvsUserController } from 'src/generated-sources';
import { Observable } from 'rxjs';
import { DkuHttpService } from 'dku-frontend-core';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PrivateCodeEnvsAPI {
    constructor(private dkuHttp: DkuHttpService) { }

    listPythonWithExperimentTrackingPackages(projectKey: string): Observable<CodeEnvsUserController.ExperimentTrackingEnvNamesWithProjectDefault> {
        return this.dkuHttp.request('GET', '/code-envs/list-python-with-experiment-tracking-packages', { projectKey });
    }
}
