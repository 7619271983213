import { Component, HostListener, OnInit } from "@angular/core";
import { LabelingShortcutService } from "@features/labeling/services/labeling-shortcut.service";
import { LabelingService } from "@features/labeling/services/labeling.service";
import { getItemName } from "@features/labeling/utils";
import { LabelingTask } from "@model-main/labeling/labeling-task";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ConfirmModalComponent } from "@shared/modals/confirm-modal/confirm-modal.component";
import { ModalsService } from "@shared/modals/modals.service";
import { combineLatest, Observable, of, Subject } from "rxjs";
import { map, switchMap, withLatestFrom } from "rxjs/operators";
import { LabelingReviewAnnotationGroupService } from "../services/labeling-review-annotation-group.service";
import { LabelingReviewUpdateService } from "../services/labeling-review-update.service";
import { LabelingReviewService, ReviewStatus } from "../services/labeling-review.service";
import { LabelingValidateService } from "../services/labeling-validate.service";

@UntilDestroy()
@Component({
    selector: 'review-item-header',
    templateUrl: './review-item-header.component.html',
    styleUrls: ['./review-item-header.component.less'],
})
export class ReviewItemHeaderComponent implements OnInit {
    private keyUpEvent$ = new Subject<KeyboardEvent>();

    resetModificationsTrigger$ = new Subject<void>();

    copyPermalinkTrigger$ = new Subject<void>();
    canSave$: Observable<boolean>;
    shouldSave$: Observable<boolean>;

    ReviewStatus = ReviewStatus;
    getItemName = getItemName;
    
    constructor(
        public labelingReviewService: LabelingReviewService,
        public labelingReviewUpdateService: LabelingReviewUpdateService,
        public labelingReviewAnnotationGroupService: LabelingReviewAnnotationGroupService,
        public labelingService: LabelingService,
        private labelingShortcutService: LabelingShortcutService,
        private labelingValidateService: LabelingValidateService,
        private modalsService: ModalsService
    ) { }

    ngOnInit(): void {
        this.canSave$ = combineLatest([
            this.labelingReviewUpdateService.updatingReview$,
            this.labelingReviewUpdateService.currentReview$,
        ]).pipe(
            switchMap(([updatingReview, currentReview]) => {
                if (updatingReview) {
                    return of(currentReview != null && currentReview.annotations.length > 0
                        && currentReview.annotations.every((a) => !!a.category));
                }

                return this.labelingReviewAnnotationGroupService.annotationGroupList$.pipe(
                    map(annotationGroupList => annotationGroupList.length > 0 &&
                        annotationGroupList.every(annotationGroup => annotationGroup.canBeValidated())
                    )
                );
            })
        );

        this.resetModificationsTrigger$.pipe(
            untilDestroyed(this),
            withLatestFrom(this.labelingReviewUpdateService.updatingReview$)
        ).subscribe(([_trigger, updatingReview]) => {
            updatingReview ? this.labelingReviewUpdateService.resetReview() : this.labelingReviewAnnotationGroupService.resetAnnotationGroupList()
        });

        this.copyPermalinkTrigger$.pipe(
            withLatestFrom(this.labelingReviewService.currentId$),
        ).subscribe(([, itemId]) => this.labelingService.copyPermalinkToClipboard(itemId));

        this.keyUpEvent$.pipe(
            withLatestFrom(
                this.labelingReviewService.isLast$,
                this.labelingReviewService.isFirst$,
            ),
            untilDestroyed(this),
        ).subscribe(([event, isLast, isFirst]) => {
            if (this.labelingShortcutService.isShortcut(event, "BACK") && !isFirst) {
                this.previousItem();
            }

            if (this.labelingShortcutService.isShortcut(event, "NEXT") && !isLast) {
                this.nextItem();
            }
        });
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.keyUpEvent$.next(event);
    }


    nextItem() {
        this.labelingReviewService.nextItem();
    }

    previousItem() {
        this.labelingReviewService.previousItem();
    }

    saveReview() {
        this.labelingValidateService.validate();
    }

    resetModifications() {
        this.resetModificationsTrigger$.next();
    }

    copyPermalink() {
        this.copyPermalinkTrigger$.next();
    }
    
    reject(type: LabelingTask.LabelingTaskType) {
        this.modalsService.open(ConfirmModalComponent, {
            title: `Reject all annotations`,
            message: `Do you really want to reject all annotations for this ${this.getItemName(type)}? <br> It will be considered as not annotated and annotators will be able to annotate it again. <br> This action cannot be undone.` ,
            confirm: 'Reject'
        }).then(() => {
            this.labelingValidateService.reject();
        });
    }

 }
