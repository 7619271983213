import type {AbstractNSampTestCard, _AbstractNSampTestCard} from './common/abstract-nsamp-test-card';
import type {CardResult} from './card-result';
import type {Card} from './card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.OneWayANOVACard
 */
export interface OneWayANOVACard extends _AbstractNSampTestCard {
    // PolyJson type
    type: 'oneway_anova';
}

export namespace OneWayANOVACard {
    export const type = 'oneway_anova';
}

/** @deprecated Use Card.isOneWayANOVACard() */ export function isOneWayANOVACard(toTest: Card): toTest is OneWayANOVACard { return OneWayANOVACard.type === toTest['type']; }

export namespace OneWayANOVACard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.OneWayANOVACard$OneWayANOVACardResult
     */
    export interface OneWayANOVACardResult extends AbstractNSampTestCard._AbstractNSampleTestCardResult {
        pvalue: number;
        statistic: number;

        // PolyJson type
        type: 'oneway_anova';
    }

    export namespace OneWayANOVACardResult {
        export const type = 'oneway_anova';
    }

    /** @deprecated Use CardResult.isOneWayANOVACardResult() */ export function isOneWayANOVACardResult(toTest: CardResult): toTest is OneWayANOVACardResult { return OneWayANOVACardResult.type === toTest['type']; }
}