<form class="workspace-datastory" [formGroup]="datastoryForm">
    <div class="workspace-datastory__column">
        <div>
            <label for="datastory_title">
                Title
            </label>
            <input type="text"
                    class="workspace-datastory__title"
                    id="datastory_title"
                    placeholder="Story title"
                    name="datastory_title"
                    formControlName="title"/>
        </div>
        <div class="workspace-datastory__description-fieldset">
            <label for="datastory_description">
                Description
            </label>
            <textarea
                    class="workspace-datastory__description"
                    id="datastory_description"
                    placeholder="Type the story description"
                    name="datastory_description"
                    formControlName="description">
            </textarea>
        </div>
    </div>
    <div class="workspace-datastory__column">
        <label>Datasets to synchronize</label>
        <div class="std-list-search-box list-control-widget workspace-datastory__search ">
            <clearable-searchbar
                inputPlaceholder="Search"
                (searchTermChange)="searchChanged($event)"
                [hasPrefixIcon]="true"
                [isClearable]="true">
            </clearable-searchbar>
        </div>
        <cdk-virtual-scroll-viewport [itemSize]="40" class="workspace-datastory__datasets" formGroupName="references">
            <label *cdkVirtualFor="let datasetObject of datasetObjects | filter: query:'displayName';" class="workspace-datastory__dataset" for="{{datasetObject.id}}">
                <!-- Using formControlName loose the binding, visible if we have a filter clause that remove most of the items, workaround is to use formControl... -->
                <input type="checkbox" name="{{datasetObject.id}}" id="{{datasetObject.id}}" [formControl]="$any(references).controls[datasetObject.id]" class="workspace-datastory__dataset-input">
                <span class="workspace-datastory__dataset-label">{{ datasetObject.displayName }}</span>
            </label>
        </cdk-virtual-scroll-viewport>
    </div>
</form>
