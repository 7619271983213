import { Directive, ElementRef, Inject, Injector, Input, OnChanges, SimpleChanges } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";
import { fairAny } from "dku-frontend-core";

@Directive({
    selector: 'markdown',
})
export class MarkdownDirective extends UpgradeComponent implements OnChanges {
    @Input() from: string;
    constructor(
        @Inject('$rootScope') public $rootScope: fairAny,
        elementRef: ElementRef, injector: Injector) {
        super('ng1Markdown', elementRef, injector);
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        /* Make sure that angularjs will notice the implied $scope value changes despite them being run outside of its change detection loop */
        this.$rootScope.$applyAsync();
    }
}
