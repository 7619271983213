import { Inject, Injectable } from '@angular/core';
import { ModalShape, ModalsService } from '@shared/modals/modals.service';
import { fairAny } from 'dku-frontend-core';
import { UIDataCollection } from 'src/generated-sources';
import { CreateDataCollectionModalComponent } from '../components/create-data-collection-modal/create-data-collection-modal.component';
import { AddObjectToCollectionModalComponent, AddObjectToCollectionModalResult } from '../components/add-object-to-collection-modal/add-object-to-collection-modal.component';
import { ConfirmModalComponent } from '@shared/modals/confirm-modal/confirm-modal.component';
import { EditSecuritySettingsModalComponent } from '../components/edit-security-settings-modal/edit-security-settings-modal.component';
import { SetDataCollectionColorModalComponent } from '../components/set-data-collection-color-modal/set-data-collection-color-modal.component';

@Injectable({
    providedIn: 'root'
})
export class DataCollectionsModalService {

    constructor(
        private modalsService: ModalsService,
        @Inject('CreateModalFromTemplate') private CreateModalFromTemplate: fairAny,
        @Inject('$rootScope') private $rootScope: fairAny,
    ) {}

    openCreationModal(): Promise<string | undefined> {
        return this.modalsService.open(CreateDataCollectionModalComponent, {}, ModalShape.NONE).catch(() => {}); //we silently ignore the error when closing the modal
    }

    openAddObjectModal(data: object): Promise<AddObjectToCollectionModalResult> {
        return this.modalsService.open(AddObjectToCollectionModalComponent, data, ModalShape.NARROW);
    }

    // resolves if and only if deletion was confirmed
    openDeleteModal(displayName: string): Promise<void> {
        return this.modalsService.open(ConfirmModalComponent, {
            title: `Delete data collection "${displayName}"`,
            message: 'Deleting a data collection is a permanent action, but will not delete any data or remove datasets from any projects.',
            danger: true,
            confirm: 'Delete data collection',
        });
    }

    // resolves if and only if removal is confirmed
    openRemoveItemModal(displayName: string): Promise<boolean> {
        return this.modalsService.open(ConfirmModalComponent, {
            title: 'Remove item "'+displayName+'" from data collection',
            message: 'Removing an item from a data collection will not delete any data or remove datasets from any projects',
            danger: true,
            confirm: 'Remove',
        });
    }

    // resolves if and only if permissions are updated
    openPermissionsModal(dataCollectionInfo: UIDataCollection.DataCollectionInfo): Promise<boolean> {
        return this.modalsService.open(EditSecuritySettingsModalComponent, {
            id: dataCollectionInfo.id,
            displayName: dataCollectionInfo.displayName,
            permissions: dataCollectionInfo.permissions
        }, ModalShape.NONE);
    }

    openSetColorModal(displayName: string, color: string) {
        return this.modalsService.open(SetDataCollectionColorModalComponent, {
            displayName,
            color,
        });
    }
}
