<credentials-list 
    [ngModel]="items" 
    (ngModelChange)="onChange($event)" 
    [addLabel]="addLabel" 
    [sortable]="sortable"
    [keyPlaceholder]="keyPlaceholder" 
    [valuePlaceholder]="valuePlaceholder"
    [keyIdentifier]="keyIdentifier" 
    [valueIdentifier]="valueIdentifier" 
    [keyRequired]="keyRequired" 
    [valueRequired]="valueRequired" 
    [required]="required"
    [canBeFinal]="canBeFinal"
    (onAdd)="onAdd.emit($event)" 
    (onDelete)="onDelete.emit($event)"
    (onValidityChange)="onValidityChange.emit($event)" >
</credentials-list>
