<div class="vertical-flex h100 padbot0">
    <api-error-alert
        [apiError]="error$ | async"
        (resetError)="pushError()"
        [closable]="true"
    ></api-error-alert>
    <div class="model-report-title simple-columns">
        <h2 class="model-report-title__tab-name">What if?</h2>
        <ng-container *ngIf="(loading$ | async) === false">
            <ng-container *ngIf="hasLoadedImages$ | async">
                <ng-container
                    *ngIf="headerTemplate"
                    [ngTemplateOutlet]="headerTemplate"
                ></ng-container>
                <div>
                    <button class="btn btn--text btn--secondary mright8" (click)="clearImages()">
                        Clear
                    </button>
                    <button class="btn btn--primary" (click)="inputFileUpload.click()">
                        Add more images
                    </button>
                </div>
                <input #inputFileUpload type="file" (change)="uploadImages(inputFileUpload.files!)" multiple hidden>
            </ng-container>
        </ng-container>
    </div>
    <div class="interactive-scoring-container">
        <deephub-report-interactive-scoring-drop-zone
          [draggedOver]="draggedOver$ | async"
          [loading]="loading$ | async"
          [hideDefault]="hasLoadedImages$ | async"
          (uploadByClick)="uploadImages($event)"
        >
        </deephub-report-interactive-scoring-drop-zone>
        <div *ngIf="hasLoadedImages$ | async" class="interactive-scoring-container__content">
            <ng-container *ngIf="selectedCellData$ | async as selectedCellData">
                <image-feed class="h100 interactive-scoring-image-feed" (imageClick)="selectImage($event)" [selectedPath]="selectedCellData.itemId" [selectedIndex]="carouselService.selectedIndex$ | async" [imageHeight]="200" [itemsPerRow]="1"></image-feed>
                <ng-container
                    [ngTemplateOutlet]="imageDetailsTemplate"
                    [ngTemplateOutletContext]="{ cellData: selectedCellData }"
                ></ng-container>
            </ng-container>
        </div>
    </div>
</div>
