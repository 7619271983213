<div
    inViewport
    [inViewportOptions]="{ threshold: [0] }"
    (inViewportAction)="onIntersection($event)"
>
    <ng-container *ngIf="displayData$ | async; else invisible">
        <ng-container *ngTemplateOutlet="visibleTemplate"></ng-container>
    </ng-container>

    <ng-template #invisible>
        <ng-container *ngTemplateOutlet="invisibleTemplate"></ng-container>
    </ng-template>
</div>
