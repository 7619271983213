import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { assertNever } from 'dku-frontend-core';

type ToggleMode = 'NONE' | 'INDETERMINATE' | 'ALL';
export type MassActionOption = {
    icon: string,
    label: string,
    action: () => void,
};

@Component({
    selector: 'mass-action-menu',
    templateUrl: './mass-action-menu.component.html',
    styleUrls: ['./mass-action-menu.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MassActionMenuComponent<T> implements OnChanges {
    @Input() options: MassActionOption[];
    @Input() selectedItems: T[];
    /**
     * Selectable items are the items that are managed by the mass select button. It may contain only part of the massSelectedItems.
     * In that case, unselect all will not emit [], but massSelectedItems minus selectableItems
     * Conversely, select all when massSelectedItems contains stuff that is not in selectableItems will emit the union of massSelectedItems & selectableItems
     */
    @Input() selectableItems: T[];

    @Output() selectedItemsChange = new EventEmitter<T[]>();

    toggleMode: ToggleMode = 'NONE';
    menuEnabled = false;

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.selectableItems || changes.selectedItems) {
            const {all, none} = this.selectableItems.reduce(({all, none}, item) => {
                const isSelected = this.selectedItems.includes(item);
                return {
                    all: all && isSelected,
                    none: none && !isSelected,
                };
            }, {all: true, none: true});

            this.toggleMode = none ? 'NONE' : all ? 'ALL' : 'INDETERMINATE';
            this.menuEnabled = this.selectedItems.length > 0;
        }
    }

    toggleSelectAll() {
        switch(this.toggleMode) {
            case 'NONE':
                this.selectedItemsChange.emit([...new Set([...this.selectedItems, ...this.selectableItems])]);
                break;
            case 'INDETERMINATE':
            case 'ALL':
                this.selectedItemsChange.emit(this.selectedItems.filter(i => !this.selectableItems.includes(i)));
                break;
            default: assertNever(this.toggleMode);
        }
    }
}
