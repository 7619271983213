import { AccessibleObjectsService, TaggableObjectsService, UsabilityComputer } from 'src/generated-sources';
import { DkuHttpService } from 'dku-frontend-core';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class PrivateFlowZonesAPI {
    constructor(private dkuHttp: DkuHttpService) {
    }

    moveItems(projectKey: string, zoneId: string, movingItems: (AccessibleObjectsService.AccessibleObject|undefined)[]) {
        return this.dkuHttp.request("POST", "/flow/move-to-zone", { projectKey, zoneId, movingItems: JSON.stringify(movingItems)});
    }

    moveToItemZone(projectKey: string, item: TaggableObjectsService.TaggableObjectRef|undefined, movingItems: (TaggableObjectsService.TaggableObjectRef|undefined)[]) {
        return this.dkuHttp.request("POST", "/flow/move-to-item-zone", { projectKey, item: JSON.stringify(item), movingItems: JSON.stringify(movingItems)});
    }

    getZoneId(projectKey: string, item?: AccessibleObjectsService.AccessibleObject) {
        return this.dkuHttp.request("POST", "/flow/retrieve-zone-item", { projectKey, item: JSON.stringify(item) });
    }
}
