/**
 * Generated from com.dataiku.dip.security.auth.AuthSyncSettings
 */
export interface AuthSyncSettings {
    missingUserAction: AuthSyncSettings.MissingUserAction;
    notInAuthorizedGroupsAction: AuthSyncSettings.NotInAuthorizedGroupsAction;
    syncUserDisplayName: boolean;
    syncUserEmail: boolean;
    syncUserGroups: boolean;
    syncUserProfile: boolean;
}

export namespace AuthSyncSettings {
    /**
     * Generated from com.dataiku.dip.security.auth.AuthSyncSettings$NotInAuthorizedGroupsAction
     */
    export enum NotInAuthorizedGroupsAction {
        WARN = 'WARN',
        DISABLE_USER = 'DISABLE_USER'
    }

    /**
     * Generated from com.dataiku.dip.security.auth.AuthSyncSettings$MissingUserAction
     */
    export enum MissingUserAction {
        WARN = 'WARN',
        DISABLE_USER = 'DISABLE_USER'
    }
}