import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'number-select',
    templateUrl: './number-select.component.html'
})

export class NumberSelectComponent implements OnInit {
    
    options: number[];

    @Input()
    value: number

    @Input()
    min: number;

    @Input()
    max: number;

    @Input()
    step = 1;

    @Output()
    valueChange = new EventEmitter<number>();

    constructor() { }

    ngOnInit(): void {
        this.options = [];
        for (let i = this.min; i <= this.max; i += this.step) {
            this.options[this.options.length] = i;
        }
    }
}
