<ng-container *ngIf="labelingService.labelingTaskInfo$ | async as task">
    <div class="review-item-feed__wrapper vertical-flex h100" >
        <review-feed-header></review-feed-header>
        <div *ngIf="(labelingReviewService.isEmpty$ | async)" class="empty-list-cta">
            <ng-container *ngIf="(labelingReviewService.isFiltered$ | async) === true; else noAnnotations">
                <p>No {{getItemName(task.type)}}s match your filter</p>
                <button class="btn btn--secondary" (click)="labelingReviewService.clearFilter()">
                    Clear Filter
                </button>
            </ng-container>
            <ng-template #noAnnotations>
                <p>
                    <review-item-empty></review-item-empty>
                </p>
            </ng-template>
        </div>
        <ng-container  *ngIf="(labelingReviewService.isEmpty$ | async) === false" >
            <image-feed *ngIf="task.type === LabelingTaskType.IMAGE_CLASSIFICATION || task.type === LabelingTaskType.OBJECT_DETECTION" class="flex review-item-feed__feed" [imageHeight]="200" [itemsPerRow]="1"  (imageClick)="setImageSource($event)" [selectedPath]="currentItemId$ | async" [selectedIndex]="currentIndex$ | async"></image-feed>
            <document-feed *ngIf="task.type === LabelingTaskType.NAMED_ENTITY_EXTRACTION" class="flex review-item-feed__feed" [itemsPerRow]="1" [selectedId]="currentItemId$ | async" [selectedIndex]="currentIndex$ | async" (documentClick)="setDocumentSource($event)">
                <ng-template #icon let-item="item">
                    <review-status-icon *ngIf="item" [status]="item.status"></review-status-icon>
                </ng-template>
            </document-feed>
        </ng-container>
    </div>
</ng-container>
