import { Component, ChangeDetectionStrategy, Input, Inject, EventEmitter, Output, AfterViewInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { catchAPIError } from '@core/dataiku-api/api-error';
import { DataCollectionsModalService } from '@features/data-collections/shared/services/data-collections-modal.service';
import { DataCollectionsService } from '@features/data-collections/shared/services/data-collections.service';
import { UIDataCollection } from '@model-main/datacollections/uidata-collection';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ErrorContextService } from '@shared/services/error-context.service';
import { resize$ } from '@shared/utils/resize-observable';
import { fairAny } from 'dku-frontend-core';
import { firstValueFrom, noop } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'data-collection-summary',
    templateUrl: './data-collection-summary.component.html',
    styleUrls: ['./data-collection-summary.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataCollectionSummaryComponent implements AfterViewInit {
    @Input() dataCollectionInfo!: Readonly<UIDataCollection.DataCollectionInfo>;
    @Input() isRightPanelOpened: boolean;
    @Output() metadataChange = new EventEmitter<Partial<UIDataCollection.MetadataInfo>>();

    descriptionPlaceholder: ElementRef<HTMLElement>;
    isDescriptionExpanded = false;
    canDescriptionExpand = false;
    isEditingDisplayName = false;
    isEditingDescription = false;
    newDescription: string;
    newDisplayName: string;
    canSaveNewDisplayName = false;
    canSaveNewDescription: boolean;
    listTagsPromise:Promise<Map<string, object>>;
    isAdmin: boolean = false;

    // Setter is used to re-apply resizing after *ngIf changes values
    @ViewChild('description') set descriptionElement(descriptionElement: ElementRef) {
        if(descriptionElement) {
            this.descriptionPlaceholder = descriptionElement;
            resize$(this.descriptionPlaceholder.nativeElement).pipe(
                untilDestroyed(this)
            ).subscribe((entry) => {
                this.canDescriptionExpand = entry.contentRect.height > 200;
                this.cd.detectChanges();
            });
        }
    }
    @ViewChild('descriptionContainer') descriptionContainer: ElementRef<HTMLElement>;

    constructor(
        @Inject("TaggingService") private taggingService: fairAny,
        private dataCollectionsService: DataCollectionsService,
        private dataCollectionsModalService: DataCollectionsModalService,
        private cd: ChangeDetectorRef,
        private errorContextService: ErrorContextService,
    ) {
        this.listTagsPromise = firstValueFrom(
            this.dataCollectionsService.listAllTags().pipe(catchAPIError(this.errorContextService))
        ).catch(() => this.dataCollectionInfo.tags)
        .then((tags) => this.taggingService.fillTagsMapFromArray(tags));
    }

    ngAfterViewInit(): void {
        this.newDisplayName = this.dataCollectionInfo.displayName;
        this.newDescription = this.dataCollectionInfo.description;
        this.isAdmin = this.dataCollectionInfo.currentUserPermissions && this.dataCollectionInfo.currentUserPermissions.admin;
    }

    getAllTags = () => this.listTagsPromise;

    saveTags(tags: string[]) {
        this.metadataChange.emit({ tags });
    }

    editDisplayName(){
        this.isEditingDisplayName = !this.isEditingDisplayName;
    }

    onDisplayNameChange(changes:string){
        this.canSaveNewDisplayName = changes !== this.dataCollectionInfo.displayName;
    }

    saveDisplayName(displayName: string) {
        this.metadataChange.emit({displayName});
        this.isEditingDisplayName = !this.isEditingDisplayName;
    }

    cancelDisplayNameEdition(){
        this.isEditingDisplayName = false;
    }

    editDescription(){
        this.isEditingDescription = !this.isEditingDescription;
    }

    onDescriptionChange(changes: string){
        this.canSaveNewDescription = changes !== this.dataCollectionInfo.description;
    }

    saveDescription(description: string) {
        this.metadataChange.emit({description});
        this.isEditingDescription = !this.isEditingDescription;
    }

    cancelDescriptionEdition() {
        this.isEditingDescription = false;
    }

    openSetColorModal() {
        this.dataCollectionsModalService.openSetColorModal(this.dataCollectionInfo.displayName, this.dataCollectionInfo.color).then((color) => {
            this.metadataChange.emit({color});
        }, noop); // reject = canceled change
    }
}
