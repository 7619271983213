import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { CategoricalHistogramCard, Variable, BinningMode } from 'src/generated-sources';
import { FormBuilder, Validators } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'categorical-histogram-card-config',
    templateUrl: './categorical-histogram-card-config.component.html',
    styleUrls: ['./categorical-histogram-card-config.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoricalHistogramCardConfigComponent implements OnChanges, OnDestroy {
    @Input() params: CategoricalHistogramCard;
    @Output() paramsChange = new EventEmitter<CategoricalHistogramCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    configForm = this.fb.group({
        binningConfig: this.fb.control(null, [Validators.required])
    });

    constructor(private fb: FormBuilder) {
        this.configForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(formValue => this.paramsChange.emit({
                ...this.params,
                maxValues: formValue.binningConfig.maxValues,
                groupOthers: formValue.binningConfig.groupWithOthers
            }));

        this.configForm.statusChanges.pipe(untilDestroyed(this))
            .subscribe(() => this.validityChange.emit(this.configForm.valid));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.configForm.patchValue({
                binningConfig: {
                    maxValues: this.params.maxValues,
                    groupWithOthers: this.params.groupOthers,
                    binningMode: BinningMode.AUTO,
                    customBinningBoundaries: null
                }
            });
        }
    }

    ngOnDestroy() {
    }
}
