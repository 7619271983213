import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'plurify'
})
export class PlurifyPipe implements PipeTransform {

    transform(singular: string, num: number, plural?: string): string {
        plural = typeof(plural) !== 'undefined' ? plural : singular + 's';
        return num == 1 ? singular : plural; // in english zero uses plural mode (crazy guys)
    }

}
