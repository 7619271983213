// $q promise
export interface EnrichedPromise<T> extends Promise<T> {
    success(x: (data: any, status: number, headers: any) => void): EnrichedPromise<T>;
    update(x: (data: any, status: number, headers: any) => void): EnrichedPromise<T>;
    error(x: (data: any, status: number, headers: any) => void): EnrichedPromise<T>;
}

/**
 * In short: make Angular2+ code work properly with promises returned by AngularJS's $q
 *
 * Here is why:
 * - AngularJS uses $q for promises: this is different than the standard/native "Promise"
 * - Angular2+ relies on ZoneJS to trigger change detection when appropriate
 * - ZoneJS works by monkey-patching all browser APIs including "Promise", but does NOT know about $q
 * - $q promises may be resolved outside the Angular zone
 * - Callbacks are not called in the Angular zone
 * - Angular2+ change detection is not run
 *
 * How it works:
 * - This helper replaces the (unpatched) $q promise by a native (patched) promise
 *
 * Notes:
 * - Update notifications are lost (it's a $q extension)
 * - Using 'UpgradeModule' alleviates the need for this fix because it syncs Angular 1 & 2 change detections
 *   BUT it also incurs a much larger overhead: this is the reason why we are doing this
 */
export function upgradePromise<T>(qPromise: EnrichedPromise<T>): Promise<T> {
    return Promise.resolve(qPromise);
}
