import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ImageClassificationPainterService } from "@shared/services/item-feed/image-feed/image-classification/image-classification-painter.service";
import { ImageClassificationDataFormatterService } from "@shared/services/item-feed/image-feed/image-classification/image-classification-sample-data-formatter.service";
import { ImagesDataFetcherService } from "@shared/services/item-feed/image-feed/images-data-fetcher.service";
import { PainterService } from "@shared/services/item-feed/image-feed/painter.service";
import { SampleDataFetcherService } from "@shared/services/item-feed/image-feed/sample-data-fetcher.service";
import { SampleDataFormatterService } from "@shared/services/item-feed/image-feed/sample-data-formatter.service";
import { ItemsDataFetcherService } from "@shared/services/item-feed/items-data-fetcher.service";
import { DatasetViewDataFetcherService } from "../../dataset-view-data-fetcher.service";
import { DatasetViewExploreImagesComponent } from "../dataset-view-explore-images.component";

@Component({
    selector: 'image-classification-dataset-view-explore-images',
    templateUrl: '../dataset-view-explore-images.component.html',
    styleUrls: ['../dataset-view-explore-images.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ImageClassificationDataFormatterService,
        DatasetViewDataFetcherService,
        ImageClassificationPainterService,
        { provide: SampleDataFormatterService, useExisting: ImageClassificationDataFormatterService},
        { provide: ItemsDataFetcherService, useExisting: DatasetViewDataFetcherService },
        { provide: ImagesDataFetcherService, useExisting: DatasetViewDataFetcherService },
        { provide: SampleDataFetcherService, useExisting: DatasetViewDataFetcherService },
        { provide: PainterService, useExisting: ImageClassificationPainterService },
    ]
})
export class ImageClassificationDatasetViewExploreImagesComponent extends DatasetViewExploreImagesComponent {
}