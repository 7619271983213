<form
    class="dkuform-modal-vertical"
    id="config-form"
    [formGroup]="configForm"
>
    <div class="control-group">
        <div class="controls">
            <label>
                <input
                    type="checkbox"
                    class="form__checkbox"
                    formControlName="customFreqsEnabled"
                >
                Define custom quantiles
            </label>
        </div>
    </div>
    <div
        class="control-group"
        formArrayName="customFreqs"
        *ngIf="customFreqsEnabled.value"
    >
        <label class="control-label">Custom quantiles</label>
        <div
            class="controls"
            *ngFor="let city of customFreqs.controls; index as quantileIndex"
        >
            <input
                type="number"
                min="0"
                step="0.001"
                max="1"
                formControlName="{{quantileIndex}}"
            >
            &nbsp;
            <a
                (click)="dropQuantile(quantileIndex)"
                class="card-options__delete"
            >
                <i class="icon-trash"></i>
            </a>
        </div>
        <button
            (click)="addQuantile()"
            class="btn btn--secondary"
        >
            + Add a quantile
        </button>
    </div>
</form>
