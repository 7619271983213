/**
 * Generated from com.dataiku.dip.coremodel.ConditionalOutput
 */
export interface ConditionalOutput {
    defaultOutput: string;
    input: string;
    name: string;
    rules: ConditionalOutput.Rule[];
}

export namespace ConditionalOutput {
    /**
     * Generated from com.dataiku.dip.coremodel.ConditionalOutput$Rule
     */
    export interface Rule {
        operand: number;
        operation: ConditionalOutput.ComparisonOperation;
        output: string;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.ConditionalOutput$ComparisonOperation
     */
    export enum ComparisonOperation {
        LT = 'LT',
        LE = 'LE',
        GT = 'GT',
        GE = 'GE'
    }
}