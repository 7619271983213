import type {Grouping, _Grouping} from './grouping';
import type {GroupingResult, _GroupingResult} from './grouping-result';

/**
 * Generated from com.dataiku.dip.eda.compute.grouping.UnionGrouping
 */
export interface UnionGrouping extends _Grouping {
    groupings: Grouping[];

    // PolyJson type
    type: 'union';
}

export namespace UnionGrouping {
    export const type = 'union';
}

/** @deprecated Use Grouping.isUnionGrouping() */ export function isUnionGrouping(toTest: Grouping): toTest is UnionGrouping { return UnionGrouping.type === toTest['type']; }

export namespace UnionGrouping {
    /**
     * Generated from com.dataiku.dip.eda.compute.grouping.UnionGrouping$UnionGroupingResult
     */
    export interface UnionGroupingResult extends _GroupingResult {
        groupings: GroupingResult[];

        // PolyJson type
        type: 'union';
    }

    export namespace UnionGroupingResult {
        export const type = 'union';
    }

    /** @deprecated Use GroupingResult.isUnionGroupingResult() */ export function isUnionGroupingResult(toTest: GroupingResult): toTest is UnionGroupingResult { return UnionGroupingResult.type === toTest['type']; }
}