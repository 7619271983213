/**
 * Generated from com.dataiku.dip.labeling.VerifiedLabel
 */
export interface _VerifiedLabel {}

export type VerifiedLabel = VerifiedLabel.ClassificationVerifiedLabel | VerifiedLabel.NamedEntityExtractionVerifiedLabel | VerifiedLabel.ObjectDetectionVerifiedLabel;

export namespace VerifiedLabel {
    export const type = ['CLASSIFICATION', 'NAMED_ENTITY_EXTRACTION', 'OBJECT_DETECTION'] as const;
    export function isClassificationVerifiedLabel(toTest: VerifiedLabel): toTest is VerifiedLabel.ClassificationVerifiedLabel { return toTest['type'] === 'CLASSIFICATION'; }
    export function isNamedEntityExtractionVerifiedLabel(toTest: VerifiedLabel): toTest is VerifiedLabel.NamedEntityExtractionVerifiedLabel { return toTest['type'] === 'NAMED_ENTITY_EXTRACTION'; }
    export function isObjectDetectionVerifiedLabel(toTest: VerifiedLabel): toTest is VerifiedLabel.ObjectDetectionVerifiedLabel { return toTest['type'] === 'OBJECT_DETECTION'; }
}

export namespace VerifiedLabel.ObjectDetectionVerifiedLabel {
    /**
     * Generated from com.dataiku.dip.labeling.VerifiedLabel$ObjectDetectionVerifiedLabel$BoundingBoxPair
     */
    export interface BoundingBoxPair extends VerifiedLabel.AnnotationPair {
        iou: number;
    }
}

export namespace VerifiedLabel {
    /**
     * Generated from com.dataiku.dip.labeling.VerifiedLabel$NamedEntityExtractionVerifiedLabel
     */
    export interface NamedEntityExtractionVerifiedLabel extends _VerifiedLabel {
        entityPairs: VerifiedLabel.AnnotationPair[];

        // PolyJson type
        type: 'NAMED_ENTITY_EXTRACTION';
    }

    export namespace NamedEntityExtractionVerifiedLabel {
        export const type = 'NAMED_ENTITY_EXTRACTION';
    }



    /**
     * Generated from com.dataiku.dip.labeling.VerifiedLabel$ClassificationVerifiedLabel
     */
    export interface ClassificationVerifiedLabel extends _VerifiedLabel {
        verifiedCategories: string[];

        // PolyJson type
        type: 'CLASSIFICATION';
    }

    export namespace ClassificationVerifiedLabel {
        export const type = 'CLASSIFICATION';
    }



    /**
     * Generated from com.dataiku.dip.labeling.VerifiedLabel$AnnotationPair
     */
    export interface AnnotationPair {
        annotationIdx: number;
        verifiedCategory: string;
    }

    /**
     * Generated from com.dataiku.dip.labeling.VerifiedLabel$ObjectDetectionVerifiedLabel
     */
    export interface ObjectDetectionVerifiedLabel extends _VerifiedLabel {
        boundingBoxPairs: VerifiedLabel.ObjectDetectionVerifiedLabel.BoundingBoxPair[];

        // PolyJson type
        type: 'OBJECT_DETECTION';
    }

    export namespace ObjectDetectionVerifiedLabel {
        export const type = 'OBJECT_DETECTION';
    }


}