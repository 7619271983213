export const DKU_EXT_TAGS_KEY_PREFIX:string = "dku-ext.";
export const DKU_EXT_EXPERIMENT_NAME:string = "dku-ext.experimentName";
export const DKU_EXT_EXPERIMENT_RUN_COUNT:string = "dku-ext.experimentRunCount";
export const DKU_EXT_LAST_RUN_START:string = "dku-ext.experimentLastRunStart";
export const DKU_EXT_DSS_USER:string = "dku-ext.dssUser";
export const DKU_EXT_DSS_GIT_COMMIT:string = "dku-ext.git.commit";
export const DKU_EXT_DSS_GIT_MESSAGE:string = "dku-ext.git.message";
export const DKU_EXT_DSS_GIT_BRANCH:string = "dku-ext.git.branch";
export const DKU_EXT_CLASS_NAMES:string = "dku-ext.targetClasses";
export const DKU_EXT_PREDICTION_TYPE:string = "dku-ext.predictionType";
export const DKU_EXT_CODE_ENV:string = "dku-ext.codeEnv";
export const DKU_EXT_TARGET:string = "dku-ext.target";
export const DKU_EXT_TAG_ORIGIN:string = "dku-ext.origin";
export const DKU_EXT_TAG_ANALYSIS_ID:string = "dku-ext.analysisId";
export const DKU_EXT_TAG_FULL_MODEL_ID:string = "dku-ext.fullModelId";
export const DKU_EXT_TAG_MLTASK_TYPE:string = "dku-ext.mlTaskType";
export const DKU_EXT_TAG_DISPLAY_NAME:string = "dku-ext.displayName";
export const DKU_EXT_TAG_KEPT_EPOCH:string = "dku-ext.keptEpoch";
export const DKU_EXT_TAG_KEPT_EPOCH_TIMESTAMP:string = "dku-ext.keptEpochTimestamp";
