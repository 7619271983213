import { PipeTransform, Pipe } from '@angular/core';
import { AllKeys } from 'dku-frontend-core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform<T, K extends AllKeys<T>>(items: T[] | null | undefined, query: string, key?: K): T[] {
        let results = items ? items : [];
        query = (query || '').toLowerCase();

        if (query) {
            results = results.filter(item => {
                const i = key ? ((item[key] || '')+'').toLowerCase() : ((item || '') + '').toLowerCase();
                return i.indexOf(query) >= 0;
            });
        }

        return results;
    }
}