import { ElementRef, Injector, Directive, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
    selector: 'standardized-side-panel'
})
export class StandardizedSidePanelDirective extends UpgradeComponent {
    @Input() objectType: string;
    @Input() page: 'flow' | 'objects_list' | 'object' = 'object';
    @Input() closeOnClickOutside: boolean = false;
    @Input() toggleTab?: string;
    @Input() singleType?: boolean;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('ng1StandardizedSidePanel', elementRef, injector);
    }
}
