<div [qa]="'eda-assistant'">
    <div *ngIf="isFirstRun" class="top-level-info-container">
        <div *ngIf="apiError$ | async as apiError; else loadingMessage">
            <div class="top-level-info dku-grand-title">
                An unexpected error occured <br>
                You can close the assistant and try again
            </div>

            <div class="top-level-info-error-details">
                <api-error-alert
                    [apiError]="apiError"
                    [closable]="false"
                ></api-error-alert>
            </div>
        </div>
        <ng-template #loadingMessage>
            <div class="top-level-info dku-grand-title">
                <i class="icon-spin icon-spinner"></i>
                <div>
                    Select some features <br>
                    and see which cards Dataiku suggests
                </div>
            </div>
        </ng-template>
    </div>

    <div *ngIf="hasEmptyDataset" class="top-level-info-container">
        <div class="top-level-info dku-grand-title">
            The dataset is empty <br>
            This tool requires a non empty dataset in order to work properly
        </div>
    </div>

    <ng-container *ngIf="!isFirstRun && !hasEmptyDataset">
        <div class="suggester-container" [qa]="'eda-assistant--loaded'">
            <div class="suggested-variables-container">
                <div class="suggested-variables-name-filter std-list-search-box list-control-widget">
                    <span class="add-on">
                        <i class="icon-dku-search"></i>
                    </span>
                    <input
                        class="list__search-input"
                        type="search"
                        [(ngModel)]="nameFilter"
                        placeholder="Search variables..."
                    >
                </div>

                <div *ngIf="!hasSniffedVariables">
                    <div class="suggested-variables-no-match-info">
                        <h5>The filter did not match any variable name</h5>
                    </div>
                </div>

                <div *ngIf="hasSniffedVariables" class="suggested-variables-list">
                        <div
                            *ngFor="let variable of variables; trackBy: trackByName"
                            class="suggested-variable"
                            [class.selected]="isVariableSelected(variable)"
                            (click)="toggleVariable(variable)"
                            [matTooltip]="formatExplanations(variable)"
                            matTooltipPosition="right"
                        >
                            <div class="suggested-variable-description">
                                <mat-checkbox
                                    [checked]="isVariableSelected(variable)"
                                    [class.mat-checkbox-disabled]="false"
                                    disabled
                                >
                                    <span
                                        class="dku-text-sb"
                                        [title]="variable.name"
                                    >
                                        <i
                                            class="suggested-variable-type"
                                            [class.icon-number]="isContinuous(variable)"
                                            [class.icon-font]="isCategorical(variable)"
                                        ></i>
                                        {{ variable.name }}
                                    </span>
                                </mat-checkbox>

                                <mat-progress-bar
                                    *ngIf="showVariableScore(variable)"
                                    mode="determinate"
                                    [value]="getVariableScore(variable)"
                                ></mat-progress-bar>
                            </div>
                                <card-body
                                    class="suggested-variable-thumbnail"
                                    [renderingMode]="CardBodyRenderingMode.MINIATURE_CARD"
                                    [params]="variable.card"
                                    [results]="variable.result"
                                ></card-body>
                        </div>
                </div>
            </div>

            <div class="suggested-cards-container">
                <div class="selected-variables-container dku-small-title-sb">
                    <ng-container *ngIf="!hasSelectedVariables">
                        Select features and let Dataiku suggest interesting cards
                    </ng-container>
                    <ng-container *ngIf="hasSelectedVariables">
                        Suggested cards for
                        <span
                            *ngFor="let name of selectedVariableNames$ | async"
                            class="selected-variable"
                            (click)="unselectVariableName(name)"
                        >
                            <span
                                class="selected-variable-name"
                                [title]="name"
                            >
                                {{ name }}
                            </span>
                            <span class="unselect-action">
                                &times;
                            </span>
                        </span>
                    </ng-container>
                </div>

                <api-error-alert
                    *ngIf="apiError$ | async as apiError"
                    [apiError]="apiError"
                    [closable]="true"
                    (resetError)="resetApiErrors()"
                >
                </api-error-alert>

                <div class="suggested-cards-list">
                    <proposed-card
                        *ngFor="let suggestion of assistantSuggestions"
                        class="suggested-card"
                        [isSelected]="suggestion.isSelected"
                        [isAlreadyIncludedInWorksheet]="suggestion.isAlreadyIncludedInWorksheet"
                        [proposedVisualization]="suggestion.suggestedCard"
                        (select)="suggestion.toggleSelection()"
                        (showDetails)="openCardPreview(suggestion)"
                        [qa]="{
                            'eda-assistant-suggestion': {
                                'card-id': suggestion.card.id,
                                'card-type': suggestion.card.type,
                                selected: suggestion.isSelected
                            }
                        }"
                    >
                    </proposed-card>
                </div>
            </div>
        </div>
    </ng-container>

    <div
        class="modal-footer modal-footer-std-buttons"
        [class.hidden-footer]="isFirstRun || hasEmptyDataset"
    >
        <div class="pull-right">
            <button
                type="button"
                class="btn btn--text btn--secondary"
                (click)="dismiss()"
                tabindex="-1"
            >
                Cancel
            </button>
            <button
                type="submit"
                form="config-form"
                class="btn btn--text btn--primary"
                [disabled]="!canSave()"
                (click)="save()"
                tabindex="-1"
                [wt1Click]="'worksheets_create-card'"
                [qa]="'eda-assistant-create-cards'"
            >
                Create selected Cards
            </button>
        </div>
        <div class="notification" *ngIf="showSuggestCardsSpinner$ | async">
            <span class="notification-text dku-text">
                <i class="icon-spin icon-spinner"></i>
                Suggesting cards...
            </span>
            <button class="btn btn--text btn--danger" (click)="abort()">
                Abort
            </button>
        </div>
        <div class="notification" *ngIf="showCardPreviewInterrupted$ | async">
            <span class="notification-text dku-text">
                <i class="icon-info-sign"></i>
                Card preview successfully interrupted
            </span>
        </div>
    </div>
</div>
