import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CanvasPairedItem, DeephubObjectDetectionReportPainterService } from '@features/deephub/object-detection/report/services/deephub-object-detection-report-painter.service';

@Component({
    selector: 'deephub-object-detection-report-pair-list',
    templateUrl: './deephub-object-detection-report-pair-list.component.html',
    styleUrls: [
        './deephub-object-detection-report-pair-list.component.less',
        '../../../../../shared/components/image-feed-modal/image-feed-modal-shared-style.less',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeephubObjectDetectionReportPairListComponent {
    @Input() pairList: CanvasPairedItem[];

    constructor(
        public canvasPainter: DeephubObjectDetectionReportPainterService
    ) {}
}
