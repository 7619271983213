import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextOverflowTooltipModule } from '@migration/modules';
import { ChartCustomColorsComponent } from './chart-custom-colors.component';
import { ChartColorPickerModule } from '../chart-color-picker';

@NgModule({
    declarations: [
        ChartCustomColorsComponent
    ],
    imports: [
        CommonModule,
        TextOverflowTooltipModule,
        ChartColorPickerModule
    ],
    exports: [
        ChartCustomColorsComponent
    ]
})
export class ChartCustomColorsModule { }
