import { ElementRef, OnInit, Input, OnDestroy, Component, ViewChild, ChangeDetectionStrategy, NgZone, OnChanges } from '@angular/core';
import { HeightService } from './height-equalizer.service';
import { ReplaySubject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';


@Component({
    selector: 'height-equalizer',
    templateUrl: './height-equalizer.component.html',
    styleUrls: ['./height-equalizer.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeightEqualizerComponent implements OnInit, OnDestroy {
    @ViewChild('child', { static: true }) child: ElementRef;

    key$ = new ReplaySubject<string>(1);
    subscription: Subscription;

    constructor(
        private heightService: HeightService,
        private elementRef: ElementRef,
        private ngZone: NgZone
    ) { }

    getChildHeight(): number | undefined {
        if (this.child && this.child.nativeElement) {
            return this.child.nativeElement.offsetHeight;
        }
        return undefined;
    }

    setParentHeight(height: number) {
        if (this.elementRef && this.elementRef.nativeElement) {
            // Directly update the DOM element without Angular
            this.elementRef.nativeElement.style.height = height + 'px';
        }
    }

    @Input() set key(newKey: string) {
        this.key$.next(newKey);
    }

    ngOnInit() {
        this.ngZone.runOutsideAngular(() => {
            this.subscription = this.key$.pipe(
                switchMap(key => this.heightService.registerHeight(key, () => this.getChildHeight())),
            ).subscribe(height => this.setParentHeight(height));
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
