import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, OnInit, Inject } from '@angular/core';
import { catchAPIError } from '@core/dataiku-api/api-error';
import { Aggregation, Bucket, CatalogFacetMap } from '@shared/models';
import { ConnectionNameFormatterPipe } from '@shared/pipes/connection-name-formatter.pipe';
import { ErrorContextService } from '@shared/services/error-context.service';
import { ProjectsService } from '@shared/services/projects.service';
import { fairAny } from 'dku-frontend-core';
import _ from 'lodash';

@Component({
    selector: 'data-sources-filter',
    templateUrl: './data-sources-filter.component.html',
    styleUrls: ['./data-sources-filter.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataSourcesFilterComponent implements OnInit, OnChanges {
    @Input() key!: string;
    @Input() label: string = 'key';
    @Input() placeholder!: string;
    @Input() usePlural: boolean = true; // whether to use the plural in the template when some items are selected

    @Input() facets!: CatalogFacetMap;
    @Output() facetsChange = new EventEmitter<CatalogFacetMap>();
    @Input() aggregations!: Record<string, Aggregation>;

    options : Bucket[] = [];
    projectNames : Record<string, string> = {};
    connectionNameFormatter = new ConnectionNameFormatterPipe();

    constructor(
        private projectsService: ProjectsService,
        private errorContext: ErrorContextService,
        @Inject("TaggingService") public taggingService: fairAny,
    ) {}

    ngOnInit() {
        switch(this.key) {
            case 'projectKey.raw':
                this.projectsService.list().pipe(
                    catchAPIError(this.errorContext)
                ).subscribe(projects => {
                    projects.forEach(project => {
                        this.projectNames[project.projectKey] = project.name;
                    });
                });
                break;
            case 'tag.raw':
                this.taggingService.fetchGlobalTags();
                break;
        }
    }

    ngOnChanges() {
        if (!this.aggregations[this.key]) {
            this.options = [];
            return;
        }
        // add facets that are selected but not present in the aggregations
        this.options = _.cloneDeep(this.aggregations[this.key].agg.buckets);
        const currentFacets = this.facets[this.key];
        if (currentFacets) {
            for (const facet of currentFacets) {
                if (!this.options.some(it => it.key === facet)) {
                    this.options.push({key: facet,  doc_count: 0});
                }
            }
        }
    }

    formatFacetValue (facet: string, value: string | number) {
        switch(facet) {
            case 'projectKey.raw':
                return this.projectNames[value] || value;
            case 'partitioned':
                return value === 0 ? 'No' : 'Yes';
            case 'connection.raw':
                return this.connectionNameFormatter.transform(value.toString());
        }
        return value as string;
    }

    onModelChange(value: string[]) {
        const facet : CatalogFacetMap = {};
        facet[this.key] = value;
        this.facetsChange.emit(facet);
    }

}
