<form
    [formGroup]="configForm"
    id="config-form"
    class="dkuform-modal-vertical"
>
    <div class="control-group">
        <label class="control-label">Max values</label>
        <div class="controls">
            <input
                type="number"
                min="1"
                formControlName="maxValues"
            >
        </div>
    </div>
</form>
