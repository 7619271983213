import { Injectable } from '@angular/core';
import { PrivatePopularDatasetsAPI } from '@core/dataiku-api/definitions/private-popular-datasets-api';
import { WT1Service } from 'dku-frontend-core';
import { tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PopularDatasetsService {
    constructor(
        private popularDatasetsAPI: PrivatePopularDatasetsAPI,
        private wt1Service: WT1Service
    ) { }

    list() {
        return this.popularDatasetsAPI.list();
    }

    compute() {
        return this.popularDatasetsAPI.compute().pipe(
            tap(() => this.wt1Service.event('popular-dataset-manual-compute', {}))
        );
    }
}
