export namespace UsageDataReporting {
    /**
     * Generated from com.dataiku.dip.server.services.UsageDataReporting$UsageDataReportingPrivacyMode
     */
    export enum UsageDataReportingPrivacyMode {
        ANONYMOUS = 'ANONYMOUS',
        LINK = 'LINK',
        DEFAULT = 'DEFAULT'
    }

    /**
     * Generated from com.dataiku.dip.server.services.UsageDataReporting$UsageDataReportingMode
     */
    export enum UsageDataReportingMode {
        NO = 'NO',
        OPEN_ONLY = 'OPEN_ONLY',
        DEFAULT = 'DEFAULT'
    }
}