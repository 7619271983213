import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { CardAction } from "@features/eda/worksheet/cards/events";
import { DurbinWatsonCard } from "src/generated-sources";

@Component({
    selector: 'durbin-watson-card-help',
    templateUrl: './durbin-watson-card-help.component.html',
    styleUrls: ['./durbin-watson-card-help.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DurbinWatsonCardHelpComponent {
    @Input() params: DurbinWatsonCard;
    @Input() readOnly: boolean;
    @Input() extendedActions: boolean;
    @Output() action = new EventEmitter<CardAction>();
}
