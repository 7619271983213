import type {ComputationResultDataStreamer} from './../../engine/computation-result-data-streamer';
import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.multivariate.FetchCSV
 */
export interface FetchCSV extends Computation._MultivariateComputation {
    // PolyJson type
    type: 'fetch_csv';
}

export namespace FetchCSV {
    export const type = 'fetch_csv';
}

/** @deprecated Use Computation.isFetchCSV() */ export function isFetchCSV(toTest: Computation): toTest is FetchCSV { return FetchCSV.type === toTest['type']; }

export namespace FetchCSV {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.multivariate.FetchCSV$FetchCSVResult
     */
    export interface FetchCSVResult extends _AvailableResult {
        dataStreamId: ComputationResultDataStreamer.DataStreamId;

        // PolyJson type
        type: 'fetch_csv';
    }

    export namespace FetchCSVResult {
        export const type = 'fetch_csv';
    }

    /** @deprecated Use ComputationResult.isFetchCSVResult() */ export function isFetchCSVResult(toTest: ComputationResult): toTest is FetchCSVResult { return FetchCSVResult.type === toTest['type']; }
}