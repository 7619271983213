import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DurbinWatsonCard } from "src/generated-sources";

@Component({
    selector: 'durbin-watson-card-body',
    templateUrl: './durbin-watson-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/test-conclusion.less',
        '../../../../shared-styles/stats-table.less',
        '../../../../shared-styles/card-layout.less',
        './durbin-watson-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DurbinWatsonCardBodyComponent {
    @Input() params: DurbinWatsonCard;
    @Input() results: DurbinWatsonCard.DurbinWatsonCardResult;

    get hasLikelyPositiveCorrelation() {
        return this.results.statistic < 1.5;
    }

    get hasLikelyNegativeCorrelation() {
        return this.results.statistic > 2.5;
    }

    get isInconclusive() {
        return !(this.hasLikelyNegativeCorrelation || this.hasLikelyPositiveCorrelation);
    }
}
