import { IToken } from 'chevrotain';
import { ChartFilter } from 'generated-sources';
import { FrontendChartFilter } from '../../../../simple-report/models';
import { DATE_PART_FROM_QUERY_STRING, DAYS_OF_WEEK_LABELS, QUERY_PARAM_PARSING_ERROR } from '../../constants';
import { DashboardFiltersUrlParamsService } from './dashboard-filters-url-params.service';
import { DashboardFiltersUrlQueryParamParser } from './dashboard-filters-url-query-param-parser';
import { AlphanumericalFilterCstChildren, AlphanumericalValueCstChildren, AlphanumericalValuesCstChildren, DatePartFilterCstChildren, DatePartValueCstChildren, DatePartValuesCstChildren, FilterCstChildren, FiltersCstChildren, NumberCstChildren, NumberCstNode, RangeFilterCstChildren, RangeValueCstChildren, RelativeDateValueCstChildren } from './dashboard-filters-url-query-params';

export function getDashboardFiltersUrlQueryParamVisitor(parser: DashboardFiltersUrlQueryParamParser) {
    class DashboardFiltersUrlQueryParamVisitor extends parser.getBaseCstVisitorConstructor() {
        constructor() {
            super();
            this.validateVisitor();
        }

        filters(ctx: FiltersCstChildren): Partial<FrontendChartFilter>[] {
            return (ctx.filter || []).map((filterCtx) => this.visit(filterCtx));
        }

        filter(ctx: FilterCstChildren): Partial<FrontendChartFilter> {
            const ctxKeys = Object.keys(ctx) as [keyof typeof ctx];
            if (!ctxKeys.length) {
                throw QUERY_PARAM_PARSING_ERROR;
            }
            const filterType = ctxKeys[0];
            const childrenCtx = ctx[filterType];
            if (!childrenCtx) {
                throw QUERY_PARAM_PARSING_ERROR;
            }
            return {
                active: true,
                $isFromUrlQuery: true,
                ...this.visit(childrenCtx)
            };
        }

        datePartFilter(ctx: DatePartFilterCstChildren): Partial<FrontendChartFilter> {
            const image = ctx.DatePartColumn[0].image;
            const firstParenIndex = image.indexOf('(');
            const queryDatePart = image.slice(0, firstParenIndex);
            const dateFilterPart = DATE_PART_FROM_QUERY_STRING[queryDatePart];
            const column = DashboardFiltersUrlParamsService.decodeColumnName(image.slice(firstParenIndex + 1, -2));
            if (ctx.OFF) {
                return {
                    filterType: ChartFilter.FilterType.DATE_FACET,
                    dateFilterPart,
                    column,
                    active: false
                };
            }
            if (ctx.datePartValues) {
                return {
                    filterSelectionType: ChartFilter.FilterSelectionType.MULTI_SELECT,
                    filterType: ChartFilter.FilterType.DATE_FACET,
                    dateFilterType: ChartFilter.DateFilterType.PART,
                    dateFilterPart,
                    column,
                    ...this.visit(ctx.datePartValues, { dateFilterPart })
                };
            }
            if (ctx.relativeDateValue) {
                return {
                    filterType: ChartFilter.FilterType.DATE_FACET,
                    filterSelectionType: ChartFilter.FilterSelectionType.RANGE_OF_VALUES,
                    column,
                    dateFilterPart,
                    ...this.visit(ctx.relativeDateValue, { dateFilterPart })
                };
            }
            throw QUERY_PARAM_PARSING_ERROR;
        }

        relativeDateValue(ctx: RelativeDateValueCstChildren): Partial<ChartFilter>{
            if (ctx.THIS) {
                return {
                    dateFilterType: ChartFilter.DateFilterType.RELATIVE,
                    dateFilterOption: ChartFilter.DateRelativeOption.THIS
                };

            }
            if (ctx.TD) {
                return {
                    dateFilterType: ChartFilter.DateFilterType.RELATIVE,
                    dateFilterOption: ChartFilter.DateRelativeOption.TO
                };
            }
            if (ctx.Last && ctx.number) {
                return {
                    dateFilterType: ChartFilter.DateFilterType.RELATIVE,
                    dateFilterOption: ChartFilter.DateRelativeOption.LAST,
                    minValue: this.visit(ctx.number),
                    maxValue: 1
                };
            }
            if (ctx.Next && ctx.number) {
                return {
                    dateFilterType: ChartFilter.DateFilterType.RELATIVE,
                    dateFilterOption: ChartFilter.DateRelativeOption.NEXT,
                    minValue: 1,
                    maxValue: this.visit(ctx.number)
                };
            }
            throw QUERY_PARAM_PARSING_ERROR;
        }

        datePartValues(ctx: DatePartValuesCstChildren, params: { dateFilterPart: ChartFilter.DateFilterPart }): Partial<FrontendChartFilter> {
            const values: Record<string, boolean> = {};
            for (const datePartValue of ctx.datePartValue || []) {
                const value: string = this.visit(datePartValue, params);
                values[value] = true;
            }
            if (ctx.Not) {
                return { excludeOtherValues: false, excludedValues: values };
            }
            return { excludeOtherValues: true, selectedValues: values };
        }

        datePartValue(ctx: DatePartValueCstChildren, { dateFilterPart }: { dateFilterPart: ChartFilter.DateFilterPart }): string {
            const ctxKeys = Object.keys(ctx) as [keyof typeof ctx];
            if (!ctxKeys.length) {
                throw QUERY_PARAM_PARSING_ERROR;
            }
            const valueType = ctxKeys[0];
            const valueCstChild = ctx[valueType];
            if (!valueCstChild) {
                throw QUERY_PARAM_PARSING_ERROR;
            }
            let value: string = valueCstChild[0].image.slice(1, -1);
            if (dateFilterPart === ChartFilter.DateFilterPart.DAY_OF_WEEK) {
                // Day of week filters need their value to be remapped from the day name to the day index.
                if (!isNaN(Number(value))) {
                    value = String(Number.parseInt(value) - 1);
                } else {
                    value = String(DAYS_OF_WEEK_LABELS.indexOf(value));
                }
            }
            if (dateFilterPart === ChartFilter.DateFilterPart.DAY_OF_MONTH || dateFilterPart === ChartFilter.DateFilterPart.QUARTER_OF_YEAR || dateFilterPart === ChartFilter.DateFilterPart.MONTH_OF_YEAR || dateFilterPart === ChartFilter.DateFilterPart.WEEK_OF_YEAR) {
                // Some other date parts value ids and labels aren't the same, so the id needs to be computed from the label.
                value = String(Number.parseInt(value) - 1);
            }
            return value;
        }

        rangeFilter(ctx: RangeFilterCstChildren): Partial<FrontendChartFilter> {
            const column = DashboardFiltersUrlParamsService.decodeColumnName(ctx.RangeColumn[0].image.slice(6, -2));

            const filter: Partial<FrontendChartFilter> = {
                column,
                /*
                 * Because filters from URL are applied as soon as possible we never get the chance to load the default filter state.
                 * The min and max from the URL are thus considered as extremums of the range and the user cannot extend the range more.
                 * To avoid that, we switch to `allValuesInSample: true`.
                 */
                allValuesInSample: true
            };

            if (ctx.OFF) {
                return {
                    ...filter,
                    active: false,
                    filterSelectionType: ChartFilter.FilterSelectionType.RANGE_OF_VALUES,
                    minValue: null,
                    maxValue: null
                };
            }

            if (ctx.rangeValue && ctx.rangeValue.length > 0) {
                if (Object.keys(ctx.rangeValue[0].children)[0] === 'IsoDate') {
                    filter.filterType = ChartFilter.FilterType.DATE_FACET;
                    filter.dateFilterType = ChartFilter.DateFilterType.RANGE;
                } else {
                    filter.filterType = ChartFilter.FilterType.NUMERICAL_FACET;
                }
            }

            if (!ctx.rangeValue) {
                // No min and no max.
                filter.minValue = null;
                filter.maxValue = null;
            } else if (!ctx.Comma && ctx.rangeValue.length === 1) {
                // If there is no comma but there is one value, then the range only contains the minValue.
                filter.minValue = this.visit(ctx.rangeValue[0]);
                filter.maxValue = null;
            } else if (ctx.rangeValue.length === 1) {
                // If there is a comma but only one number, then the range only contains the maxValue.
                filter.minValue = null;
                filter.maxValue = this.visit(ctx.rangeValue[0]);
            } else {
                // Else there is both the minValue and the maxValue.
                filter.minValue = this.visit(ctx.rangeValue[0]);
                filter.maxValue = this.visit(ctx.rangeValue[1]);
            }

            return {
                ...filter,
                filterSelectionType: ChartFilter.FilterSelectionType.RANGE_OF_VALUES
            };
        }

        rangeValue(ctx: RangeValueCstChildren): number {
            const valueType = Object.keys(ctx)[0] as keyof typeof ctx;
            const valueCstChild = ctx[valueType];
            if (!valueCstChild) {
                throw QUERY_PARAM_PARSING_ERROR;
            }
            if (valueType === 'number') {
                return this.visit(valueCstChild as NumberCstNode[]) as number;
            } else if (valueType === 'IsoDate') {
                return Date.parse((valueCstChild as IToken[])[0].image);
            }
            throw QUERY_PARAM_PARSING_ERROR;
        }

        number(ctx: NumberCstChildren): number {
            const valueType = Object.keys(ctx)[0] as keyof typeof ctx;
            const numberToken = ctx[valueType];
            if (!numberToken || !numberToken.length) {
                throw QUERY_PARAM_PARSING_ERROR;
            }
            if (valueType === 'Decimal') {
                return parseFloat(numberToken[0].image);
            }
            return parseInt(numberToken[0].image);
        }

        alphanumericalFilter(ctx: AlphanumericalFilterCstChildren): Partial<FrontendChartFilter> {
            const encodedColumn = `${ctx.PossibleColumnNamePrefix?.map(({ image }) => image).join('') || ''}${ctx.AlphanumColumn[0].image.slice(0, -1)}`;
            const column = DashboardFiltersUrlParamsService.decodeColumnName(encodedColumn);
            const filter: Partial<FrontendChartFilter> = {
                filterType: ChartFilter.FilterType.ALPHANUM_FACET,
                column
            };
            if (ctx.OFF) {
                return {
                    ...filter,
                    active: false,
                    filterSelectionType: ChartFilter.FilterSelectionType.MULTI_SELECT
                };
            }
            if (ctx.alphanumericalValues) {
                return {
                    filterType: ChartFilter.FilterType.ALPHANUM_FACET,
                    filterSelectionType: ChartFilter.FilterSelectionType.MULTI_SELECT,
                    column,
                    ...this.visit(ctx.alphanumericalValues)
                };
            }
            throw QUERY_PARAM_PARSING_ERROR;
        }

        alphanumericalValues(ctx: AlphanumericalValuesCstChildren): Partial<FrontendChartFilter> {
            const values: Record<string, boolean> = {};
            for (const datePartValue of ctx.alphanumericalValue || []) {
                const value: string = this.visit(datePartValue);
                values[value] = true;
            }

            if (ctx.Not) {
                return { excludeOtherValues: false, excludedValues: values };
            }
            return { excludeOtherValues: true, selectedValues: values };
        }

        alphanumericalValue(ctx: AlphanumericalValueCstChildren): string {
            const valueType = Object.keys(ctx)[0] as keyof typeof ctx;
            const alphanumericalValueCstChild = ctx[valueType];
            if (!alphanumericalValueCstChild || !alphanumericalValueCstChild.length) {
                throw QUERY_PARAM_PARSING_ERROR;
            }

            return DashboardFiltersUrlParamsService.decodeAlphanumValue(alphanumericalValueCstChild[0].image);
        }

        convertValuesListToValuesObject(values: string[]): Record<string, boolean> {
            return values.reduce((acc, curr) => ({ ...acc, [curr]: true }), {});
        }
    }

    return new DashboardFiltersUrlQueryParamVisitor();
}
