import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ContentChild, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'document-feed',
    templateUrl: './document-feed.component.html',
    styleUrls: ['./document-feed.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentFeedComponent {

    @Input() itemsPerRow: number;
    @Input() selectedId?: string | null;
    @Input() selectedIndex?: number | null;
    @ContentChild("icon") iconTemplateRef: TemplateRef<any>;

    @Output() documentClick = new EventEmitter<any>();

    loadingStatus$ = new BehaviorSubject<boolean>(false);

}
