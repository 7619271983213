import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'smartPercentage'
})
export class SmartPercentagePipe implements PipeTransform {

    transform(ratio: number, precision: number, spaces: number) {
        precision = Math.max(+(precision || 0), 0);
        let tens = Math.pow(10, precision);
        let min = 1 / tens / 100; // e.g. precision = 2 =>  0.01
        let max = 1 - min;        //                    => 99.99
        let out = [];
        if (ratio < 1 && ratio > max) {
            ratio = max;
            out.push('>');
        } else if (ratio > 0 && ratio < min) {
            ratio = min;
            out.push('<');
        }
        out.push((Math.round(ratio * 100 * tens) / tens).toFixed(precision), '%');
        return out.join(spaces ? '\u00A0' : '');
    }

}
