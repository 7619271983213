/**
 * Generated from com.dataiku.dip.dataflow.exec.autofeaturegeneration.TimeWindow
 */
export interface TimeWindow {
    from: number;
    to: number;
    windowUnit: TimeWindow.WindowUnit;
}

export namespace TimeWindow {
    /**
     * Generated from com.dataiku.dip.dataflow.exec.autofeaturegeneration.TimeWindow$WindowUnit
     */
    export enum WindowUnit {
        SECOND = 'secs',
        MINUTE = 'mins',
        HOUR = 'hours',
        DAY = 'days',
        WEEK = 'weeks',
        MONTH = 'months',
        YEAR = 'years'
    }
}