<span
    class="pvalue pvalue--rejected"
    *ngIf="pvalue <= significanceLevel else inconclusive"
    [matTooltip]="'𝘱-value &le; significance level (&le; '
        + (significanceLevel | smarterNumber)
        + ')\nHypothesis rejected'"
>
    {{ label }} {{ pvalue | smarterNumber }}
</span>

<ng-template #inconclusive>
    <span
        class="pvalue"
        [matTooltip]="'𝘱-value &gt; significance level (&gt; '
        + (significanceLevel | smarterNumber)
        + ')\nTest is inconclusive'"
    >
        {{ label }} {{ pvalue | smarterNumber }}
    </span>
</ng-template>
