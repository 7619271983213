import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { APIError, catchAPIError, ErrorContext } from "@core/dataiku-api/api-error";
import { DataikuAPIService } from "@core/dataiku-api/dataiku-api.service";
import { LabelingService } from "@features/labeling/services/labeling.service";
import { resolveSmartName } from "@utils/loc";
import { Observable } from "rxjs";
import { map, share, switchMap } from "rxjs/operators";
import { LabelingTask, SchemaColumn } from "src/generated-sources";

@Component({
    selector: 'labeling-settings-data',
    templateUrl: './labeling-settings-data.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelingSettingsDataComponent implements OnInit, ErrorContext {
    
    @Output() partialTaskChange = new EventEmitter<Partial<LabelingTask>>();

    metadataColumns$: Observable<SchemaColumn[]>;
    error: APIError;
    LabelingTaskType = LabelingTask.LabelingTaskType;

    constructor(public labelingService: LabelingService,
        private DataikuAPI: DataikuAPIService) {
    }

    ngOnInit(): void {
        this.metadataColumns$ = this.labelingService.labelingTaskInfo$.pipe(
            switchMap((taskInfo) => {
                const datasetLoc = resolveSmartName(taskInfo.projectKey, taskInfo.inputMetadata)
                return this.DataikuAPI.datasets.getFullInfo(taskInfo.projectKey, datasetLoc.projectKey, datasetLoc.id).pipe(catchAPIError(this))
            }),
            map((datasetInfo) => datasetInfo.dataset.schema.columns),
            share(),
        );
    }

    pushError(error: APIError): void {
        this.labelingService.pushError(error);
    }
}