import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StandaloneWT1Service, LegacyWT1Service, WT1Service } from './wt1.service';
import { Wt1ClickDirective } from './wt1-click.directive';

@NgModule({
    declarations: [Wt1ClickDirective],
    imports: [
        CommonModule
    ],
    exports: [Wt1ClickDirective]
})
export class DataikuWT1Module {
    static standalone(): ModuleWithProviders<DataikuWT1Module> {
        return {
            ngModule: DataikuWT1Module,
            providers: [{ provide: WT1Service, useClass: StandaloneWT1Service }]
        };
    }
    static legacy(): ModuleWithProviders<DataikuWT1Module> {
        return {
            ngModule: DataikuWT1Module,
            providers: [{ provide: WT1Service, useClass: LegacyWT1Service }]
        };
    }
}
