import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LabelingAnnotationService {
    private selectedCategory: string;

    getSelectedCategory() {
        return this.selectedCategory;
    }

    selectCategory(selectedCategory: string) {
        this.selectedCategory = selectedCategory;
    }
}
