<div class="clearable-searchbar__container">
    <i class="dku-icon-search-16 workspaces-left-menu__icon--prefix" 
        *ngIf="hasPrefixIcon">
    </i>
    <input class="clearable-searchbar__input" #input
        [formControl]="searchTerm"
        type="text"
        placeholder="{{inputPlaceholder || defaultPlaceholder}}">
    <i class="dku-icon-dismiss-16 workspaces-left-menu__icon--suffix" 
        *ngIf="searchTerm.value && isClearable"
        (click)="clearSearch()">
    </i>
</div>
