import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'object-nav',
  templateUrl: './object-nav.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObjectNavComponent {
    @Input() icon!: string; // it can be more than just an icon, for example 'dku-icon-book-24 universe-background catalog'
    @Input() label!: string;
    @Input() route!: string;

  constructor() { }
}
