import { Inject, Injectable } from '@angular/core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { Dimension } from '@model-core/partitioning/dimension';
import { DKURootScope } from '@shared/models/dku-root-scope.model';
import type { IScope } from 'angular';
import { firstValueFrom } from 'rxjs';
import { CreateModalFromTemplate } from '../models';
import { ExportUtils } from '../models/migration/export-utils.model';

export type Ng1ExportService = {
    initExportBehavior: (scope: IScope, dialog: DialogOptions, featureOptions: FeatureOptions, container: IScope) => Promise<any>
};

export type DialogOptions = {
    title: string
};

export type FeatureOptions = {
    downloadMethod: boolean
    downloadOnly: boolean
    advancedSampling: boolean,
    pluginsExport: boolean,
    allowFiltering: boolean,
    explorationFiltersAndSearchQuery?: { explorationFilters: string, globalSearchQuery?: string},
};

export type ExportHooks = {
    beforeExport?: (params?: any) => void;
};

@Injectable({
    providedIn: 'root'
})
export class ExportService {
    constructor(
        @Inject('$rootScope') private $rootScope: DKURootScope,
        @Inject('ExportService') private exportService: Ng1ExportService,
        @Inject('CreateModalFromTemplate') private createModalFromTemplate: CreateModalFromTemplate,
        @Inject('ExportUtils') private exportUtils: ExportUtils,
        private readonly dataikuAPIService: DataikuAPIService,
    ) {
    }

    exportDataset(projectKey: string, datasetName: string, additionalFeatures: FeatureOptions, hook?: any) {
        this.dataikuAPIService.datasets.getForExport(projectKey, datasetName).subscribe(datasetDefinition => {
            // Casting because Dimension has a custom serializer in java and j2ts cannot be aware of it
            const partitionListLoader = (<Array<Dimension>>(<unknown>datasetDefinition.partitioning.dimensions)).length == 0 ? null : () => {
                return firstValueFrom(this.dataikuAPIService.datasets.listPartitions(datasetDefinition))
            };

            const features = { partitionListLoader, datasetDefinition, ...additionalFeatures };
            const scope = this.$rootScope.$new(true);
            const promise = this.exportService.initExportBehavior(scope, {title : `Export "${datasetName}"`}, features, scope);
            const exportModal = (<any>scope).exportModal;
            const originalHandlePartitionListLoaded = exportModal.handlePartitionListLoaded;
            exportModal.handlePartitionListLoaded = function(data: any) {
                originalHandlePartitionListLoaded.bind(exportModal)(data);
                scope.$apply();
            };
            this.createModalFromTemplate('/templates/exports/export_modal.html', scope);
            //eslint-disable-next-line @typescript-eslint/no-floating-promises
            promise.then((params: any) => {
                if (params.applyExplorationFilters && features.explorationFiltersAndSearchQuery) {
                    params.explorationFiltersAndSearchQuery = features.explorationFiltersAndSearchQuery;
                }
                this.dataikuAPIService.datasets.exportDS(projectKey, projectKey, datasetName, params).subscribe((data: any) => {
                    this.exportUtils.defaultHandleExportResult(scope, params, data);
                });
            });
        });
    }
}
