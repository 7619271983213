import { Observable } from 'rxjs';
import { DkuHttpService } from 'dku-frontend-core';
import { Injectable } from '@angular/core';
import { SerializedProject } from '@model-main/coremodel/serialized-project';
import { AccessibleObjectsService } from '@model-main/server/services/accessible-objects-service';

@Injectable({ providedIn: 'root' })
export class PrivateTaggableObjectsAPI {
    constructor(private dkuHttp: DkuHttpService) { }

    delete(request: any[], contextProjectKey: string): Observable<any> {
        return this.dkuHttp.request('GET', '/taggable-objects/delete', {
            request,
            contextProjectKey,
        });
    }

    listAllTags(includeLimitedVisibility: boolean = false): Observable<any> {
        return this.dkuHttp.request("GET", "/taggable-objects/list-all-tags", {
            includeLimitedVisibility: includeLimitedVisibility
        });
    }

    listAccessibleObjects(projectKey: string, type: string, mode: keyof typeof SerializedProject.ReaderAuthorization.Mode): Observable<AccessibleObjectsService.AccessibleObject[]> {
        return this.dkuHttp.request('GET', '/taggable-objects/list-accessible-objects', {
            projectKey: projectKey,
            type: type,
            mode: mode
        });
    }
}
