<key-values-list [ngModel]="items"
    (ngModelChange)="handleChange($event)"
    [keyPlaceholder]="keyPlaceholder"
    [valuePlaceholder]="valuePlaceholder"
    [iconIdentifier]="iconIdentifier"
    [iconFn]="iconFn"
    [keyIdentifier]="keyIdentifier"
    [valueIdentifier]="valueIdentifier"
    [keyRequired]="keyRequired"
    [valueRequired]="valueRequired"
    [keySuggestions]="keySuggestions"
    [valueSuggestions]="valueSuggestions"
    [required]="required"
    [addLabel]="addLabel"
    [sortable]="sortable"
    [hasDivider]="hasDivider"
    [focusOnEnter]="focusOnEnter"
    [warnIfTrimmable]="warnIfTrimmable"
    [confirmDeletion]="confirmDeletion"
    (onAdd)="onAdd.emit($event)"
    (onDelete)="onDelete.emit($event)"
    (onValidityChange)="onValidityChange.emit($event)">
</key-values-list>
