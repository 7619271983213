import { Injectable } from "@angular/core";
import { CellDataWithAnnotation, ColumnMetadataWithIndex, ImportantColumnsMetadataWithAnnotation } from "../sample-data-fetcher.service";
import { SampleDataFormatterService } from "../sample-data-formatter.service";

export class ImageClassificationCellData implements CellDataWithAnnotation {
    constructor(
        public itemId: string,
        public target: string,
        public itemIndex: number,
    ) {}

    listCategories(): string[] {
        return this.target ? [this.target] : [];
    }
};

@Injectable()
export class ImageClassificationDataFormatterService extends SampleDataFormatterService {
    rowToCellData(rowContent: string[], columnsMetadata: ImportantColumnsMetadataWithAnnotation<ColumnMetadataWithIndex>, i: number): ImageClassificationCellData {
        return new ImageClassificationCellData(
            rowContent[columnsMetadata.single.itemPath.index],
            rowContent[columnsMetadata.single.annotation.index],
            i
        );
    }
}