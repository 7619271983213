import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output, SimpleChanges, OnChanges, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { AbstractPairwiseTestCard, PValueAdjustmentMethod, Variable } from 'src/generated-sources';
import { Observable, EMPTY } from 'rxjs';
import { CardWizardVariable } from '@features/eda/card-models';
import { CardWizardService } from '../../../card-wizard/card-wizard.service';
import { toggleFormControl } from '@utils/toggle-form-control';
import { observeFormControl } from '@utils/form-control-observer';
import { map, distinctUntilChanged, switchMap, shareReplay } from 'rxjs/operators';
import { SampleContextService } from '@features/eda/sample-context.service';
import { catchAPIError, APIError } from '@core/dataiku-api/api-error';

@UntilDestroy()
@Component({
    selector: 'abstract-pairwise-test-card-config',
    templateUrl: './abstract-pairwise-test-card-config.component.html',
    styleUrls: [
        '../../../../shared-styles/card-wizard.less',
        './abstract-pairwise-test-card-config.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbstractPairwiseTestCardConfigComponent implements OnInit, OnChanges, OnDestroy {
    @Input() params: AbstractPairwiseTestCard;
    @Input() variables: Variable[];
    @Output() paramsChange = new EventEmitter<AbstractPairwiseTestCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    adjustmentMethods = [{
        name: 'None',
        value: PValueAdjustmentMethod.NONE
    }, {
        name: 'Bonferroni',
        value: PValueAdjustmentMethod.BONFERRONI
    }, {
        name: 'Holm-Bonferroni',
        value: PValueAdjustmentMethod.HOLM_BONFERRONI
    }];

    testVariables$: Observable<CardWizardVariable[]>;
    groupVariables$: Observable<CardWizardVariable[]>;
    suggestedYValues$: Observable<string[]>;
    error?: APIError | null;

    private groupingMethod = this.fb.control(null, [Validators.required]);

    configForm = this.fb.group({
        xColumn: this.fb.control(null, [Validators.required]),
        yColumn: this.fb.control(null, [Validators.required]),
        groupingMethod: this.groupingMethod,
        yValues: toggleFormControl(this.fb.control({ value: null, disabled: true }, [Validators.required, Validators.minLength(1)]), this.groupingMethod, 'manual'),
        maxGroups: toggleFormControl(this.fb.control({ value: null, disabled: true }, [Validators.required, Validators.min(2)]), this.groupingMethod, 'auto'),
        adjustmentMethod: this.fb.control(null, [Validators.required])
    });

    constructor(
        private fb: FormBuilder,
        private cardWizardService: CardWizardService,
        private sampleContextService: SampleContextService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.suggestedYValues$ = observeFormControl<Variable | null>(this.configForm.controls.yColumn).pipe(
            map(yColumn => yColumn?.name),
            distinctUntilChanged(),
            switchMap(yColumnName => {
                if (yColumnName) {
                    return this.sampleContextService.runInteractiveQuery({
                        type: 'list_most_frequent_values',
                        column: yColumnName,
                        maxValues: 100
                    }).pipe(
                        catchAPIError(this),
                        map(queryResult => queryResult.values || [])
                    )
                }
                return EMPTY;
            }),
            shareReplay(1)
        );

        this.configForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((formValue) => {
                const params = {
                    ...this.params,
                    xColumn: formValue.xColumn,
                    yColumn: formValue.yColumn,
                    adjustmentMethod: formValue.adjustmentMethod
                };

                if (formValue.groupingMethod === 'auto') {
                    params.maxGroups = formValue.maxGroups;
                    delete params.yValues;
                } else {
                    params.yValues = formValue.yValues;
                }

                this.paramsChange.emit(params);
            });

        this.configForm.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => this.validityChange.emit(this.configForm.valid));
    }

    pushError(error: APIError | null) {
        this.error = error;
        this.changeDetectorRef.markForCheck();
    }

    ngOnInit() {
        this.testVariables$ = this.cardWizardService.availableVariables(this.params.type, { isTestVariable: true });
        this.groupVariables$ = this.cardWizardService.availableVariables(this.params.type);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.configForm.patchValue({
                xColumn: this.params.xColumn,
                yColumn: this.params.yColumn,
                groupingMethod: this.params.yValues ? 'manual' : 'auto',
                yValues: this.params.yValues,
                maxGroups: this.params.maxGroups,
                adjustmentMethod: this.params.adjustmentMethod
            });
        }
    }

    ngOnDestroy() {

    }
}
