import { Directive, ElementRef, Inject, Injector, Input, OnChanges } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";
import { fairAny } from 'dku-frontend-core';

/**
 * A directive that allows to display a markdown popover
 */

@Directive({
    selector: '[dkuMdPopover]',
})
export class DkuMdPopoverDirective extends UpgradeComponent implements OnChanges {
    @Input() dkuMdPopover: string;
    @Input() dkuMdTitle: string;

    constructor(
        @Inject('$rootScope') public $rootScope: fairAny,
        elementRef: ElementRef,
        injector: Injector
    ) {
        super('ng1DkuMdPopover', elementRef, injector);
    }
}
