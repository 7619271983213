/**
 * Generated from com.dataiku.dip.labeling.text.TokenizationParam
 */
export interface TokenizationParam {
    engine: TokenizationParam.TokenizationEngine;
    splitMechanism: TokenizationParam.TokenizationSplitMechanism;
}

export namespace TokenizationParam {
    /**
     * Generated from com.dataiku.dip.labeling.text.TokenizationParam$TokenizationEngine
     */
    export enum TokenizationEngine {
        SIMPLE = 'SIMPLE'
    }

    /**
     * Generated from com.dataiku.dip.labeling.text.TokenizationParam$TokenizationSplitMechanism
     */
    export enum TokenizationSplitMechanism {
        WHITESPACE = 'WHITESPACE',
        CHARACTER = 'CHARACTER'
    }
}