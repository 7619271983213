<div (mouseup)="onDragEnd()" (mousedown)="onDragStart($event)" (mouseleave)="onDragEnd()" #scrollZone class="tile-row" [class.drag]="isDragging">
    <cdk-virtual-scroll-viewport [itemSize]="itemWidth" [orientation]="'horizontal'" [class.dragged]="dragged">
        <div class="cdk-virtual-scroll-viewport-row">
            <ng-container *cdkVirtualFor="let item of items; templateCacheSize: 0">
                <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ item: item }">
                </ng-container>
            </ng-container>
        </div>
    </cdk-virtual-scroll-viewport>
    <div *ngIf="displayLeftArrow" (click)="scrollByPage($event, -1)" class="arrow left">
        <i class="dku-icon-chevron-left-24"></i>
    </div>
    <div *ngIf="displayRightArrow" (click)="scrollByPage($event, 1)" class="arrow right">
        <i class="dku-icon-chevron-right-24"></i>
    </div>
</div>