import { Component, Input, ViewChild, OnInit, Inject, ElementRef, OnDestroy } from "@angular/core";
import { DkuPopoverComponent } from "@app/widgets/dropdowns/dku-popover/dku-popover.component";
import { DKURootScope } from "@shared/models/dku-root-scope.model";
import { NewInVersionService } from "../new-in-version.service";

type displayTriggerMode = "tooltip-top" | "tooltip-bottom" | "corner" | "badge";

@Component({
  selector: "new-in-version-popover",
  templateUrl: "./new-in-version-popover.component.html",
  styleUrls: ["./new-in-version-popover.component.less"],
})
export class NewInVersionPopoverComponent implements OnInit, OnDestroy {
  @Input() targetVersion: string;
  @Input() popoverId: string; // used to store the dismiss value in LocalStorage
  @Input() popoverToggleSrOnly: string = "Click to learn more about this new feature";
  @Input() featureName: string;
  @Input() linkToProductPage: string;
  @Input() displayMode: displayTriggerMode = "tooltip-top";
  @ViewChild("popover") popover: DkuPopoverComponent;
  @ViewChild("popoverTrigger") popoverTrigger: ElementRef;
  @ViewChild("popoverContent") popoverContent: ElementRef;

  displayPopoverTrigger: boolean = false;
  appVersionMajor: string | undefined;
  popoverShown: boolean = false;
  boundOpenPopover: EventListenerOrEventListenerObject;
  boundDocumentClick: EventListenerOrEventListenerObject;
  isUnattendedMode: boolean = false;

  constructor(
    @Inject("$rootScope") private $rootScope: DKURootScope,
    private NewInVersionService: NewInVersionService,
    private element: ElementRef<HTMLElement>
  ) {
    this.boundOpenPopover = this.openPopover.bind(this);
    this.boundDocumentClick = this.documentClick.bind(this);
  }

  ngOnInit(): void {
    this.isUnattendedMode = this.$rootScope.appConfig?.unattendedMode;
    const isInLocalStorage = this.NewInVersionService.getDismissedPopoverFromLocalStorage(
      this.popoverId,
      this.targetVersion
    );
    this.appVersionMajor = this.NewInVersionService.extractMajorVersion(
      this.$rootScope.appConfig?.version?.product_version
    );
    this.displayPopoverTrigger =
      this.targetVersion === this.appVersionMajor && !isInLocalStorage && !this.isUnattendedMode;

    if (this.displayPopoverTrigger) {
      // We bind the click to the whole component to our custom method, to use the click in capture phase
      // allowing use to stop the propagation as needed to display the popover on the first click
      this.element.nativeElement.addEventListener("click", this.boundOpenPopover, { capture: true });

      // We bind a document click handler to target clicks outside of the component
      document.addEventListener("click", this.boundDocumentClick);
    }
  }

  ngOnDestroy(): void {
    this.element.nativeElement.removeEventListener("click", this.boundOpenPopover, { capture: true });
    document.removeEventListener("click", this.boundDocumentClick);
  }

  openPopover(event: Event): void {
    if (!event.isTrusted) {
      return;
    }
    if (this.popoverTrigger) {
      // send a click on the popover trigger to use its position to display the popover
      this.popoverTrigger.nativeElement.click();
      this.popoverShown = true;
      event.stopPropagation();
      event.preventDefault();
    }
  }

  dismissPopover(): void {
    this.popover.hide();
    this.displayPopoverTrigger = false;
    this.popoverShown = false;
    if (this.appVersionMajor) {
      this.NewInVersionService.storeDismissedPopoverInLocalStorage(this.popoverId, this.appVersionMajor);
    }
    this.element.nativeElement.removeEventListener("click", this.boundOpenPopover, { capture: true });
  }

  documentClick(event: Event): void {
    const isClickOutsidePopover = !this.popoverContent.nativeElement.contains(event.target as Node);
    if (this.popoverShown && event.isTrusted && isClickOutsidePopover) {
      this.dismissPopover();
    }
  }
}
