<form
    [formGroup]="configForm"
    id="config-form"
    class="dkuform-modal-vertical"
>
    <fieldset>
        <h4>{{ params.xColumn.name }}</h4>
        <binning-config formControlName="xBinningConfig"
            [isCategorical]="isXCategorical">
        </binning-config>
    </fieldset>
    <fieldset>
        <h4>{{ params.yColumn.name }}</h4>
        <binning-config formControlName="yBinningConfig"
            [isCategorical]="isYCategorical">
        </binning-config>
    </fieldset>
</form>
