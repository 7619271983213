<div class="interactive-scoring-image-container">
    <image-with-artifacts [cellData]="cellData" *ngIf="painterImage$ | async as painterImage" [painterImage]="painterImage" (painterImageChange)="painterImage$.next($event)"></image-with-artifacts>
</div>
<div class="interactive-scoring-image-results">
    <deephub-report-interactive-scoring-image-header class="mbot16" [title]="cellData.fileName"></deephub-report-interactive-scoring-image-header>
    <div class="interactive-scoring-image-results__values">        
        <h2 class="settings-section-title mtop0 mbot0">
            The model says 
            <div [style.background-color]="getPredictionColor(cellData.score)" 
                 [style.color]="getPredictionColor(cellData.score) | colorContrast" 
                 class="dib deephub-image-classification-feed-modal__category">{{cellData.score.prediction}}
            </div>
            with {{getFormattedProba(cellData.score)}}% probability
        </h2>
        <deephub-image-classification-prediction-widget [classifData]="cellData" (toggleSelectedCategory)="painterImageUpdateTrigger$.next($event)" (togglePinnedCategory)="painterImageUpdatePinTrigger$.next($event)" [selectableCategories]="true">
        </deephub-image-classification-prediction-widget>
        <div class="mtop16 text-weak"> Hover over a class to display the Activation Map for that specific class. </div>
        <div class="deephub-image-classification-feed-modal__results-color-scale-block" >
            <div  class="text-weak"> Model focus</div>
            <div class="deephub-image-classification-feed-modal__results-color-scale">
                <div class="text-weak"> - </div>
                <div  [style.background-image]="gradientColorStyleImg" class="deephub-image-classification-feed-modal__results-color-image">  </div>
                <div  class="text-weak"> + </div>
            </div>
        </div>
    </div>
</div>
