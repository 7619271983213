import { Component, Input, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Fit2DDistributionCard } from 'src/generated-sources';
import type { EChartsOption, SeriesOption } from 'echarts';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Observable, from, Subject, of, combineLatest } from 'rxjs';
import { map, shareReplay, first, switchMap, debounceTime } from 'rxjs/operators';
import { lazyRenderingValve, observeInput } from 'dku-frontend-core';
import { CardAction } from '@features/eda/worksheet/cards/events';
import { DensityMap } from '@shared/graphics/density-map';
import { WindowService } from 'dku-frontend-core';
import { smarterNumber } from '@shared/pipes/number-pipes/smarter-number.pipe';


@UntilDestroy()
@Component({
    selector: 'fit-2d-distribution-card-body',
    templateUrl: './fit-2d-distribution-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/chart.less',
        './fit-2d-distribution-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class Fit2DDistributionCardBodyComponent implements OnDestroy {
    @Input() results: Fit2DDistributionCard.Fit2DDistributionCardResult;
    @Input() params: Fit2DDistributionCard;
    @Input() hasFixedHeight: boolean;
    @Output() action = new EventEmitter<CardAction>();

    results$ = observeInput(this, 'results');
    params$ = observeInput(this, 'params');

    chartData$: Observable<{
        results: Fit2DDistributionCard.Fit2DDistributionCardResult,
        densityMap: DensityMap,
        chartOptions: EChartsOption
    } | null>;

    chart: any;
    mousePosition: any;
    mouseValue?: number;
    isVisible$ = new Subject<boolean>();

    constructor(private windowService: WindowService) {
        const debouncedVisibility$ = this.isVisible$.pipe(debounceTime(300));
        const resultsAndParams$ = combineLatest([this.results$, this.params$]);
        this.chartData$ = lazyRenderingValve(resultsAndParams$, debouncedVisibility$, this.windowService.resize$)
            .pipe(
                switchMap(state => {
                    if (!state) {
                        return of(null);
                    }
                    const [results, params] = state;
                    return from(DensityMap.createFromResult(results.result.density))
                        .pipe(map(densityMap => ({
                            results, densityMap,
                            chartOptions: this.buildChartOptions(params, densityMap)
                        })));
                }),
                shareReplay(1),
                untilDestroyed(this)
            );
    }

    onIntersection({ visible }: { visible: boolean }) {
        this.isVisible$.next(visible);
    }

    onMouseOver(event: any) {
        // Convert "screen pixels" to "point in data domain" using echart itself
        this.mousePosition = this.chart.convertFromPixel('grid', [event.event.offsetX, event.event.offsetY]);

        this.chartData$.pipe(first()).subscribe(chartData => {
            if (chartData) {
                this.mouseValue = chartData!.densityMap.valueAtDataPoint(this.mousePosition);
            }
        });
    }

    onMouseOut() {
        this.mouseValue = undefined;
    }

    onChartInitialized(chart: any) {
        this.chart = chart;
    }

    buildChartOptions(params: Fit2DDistributionCard, densityMap: DensityMap): EChartsOption {
        const { xMin, xMax, yMin, yMax } = densityMap;
        const canvas = densityMap.createDensityImage();

        return {
            tooltip: {
                showContent: false,
                axisPointer: { type: 'cross' },
            },
            backgroundColor: '#FFFFFF',
            animation: false,
            grid: {
                left: 10,
                top: 0,
                right: 10,
                bottom: 0,
                containLabel: true,
            },
            xAxis: {
                type: 'value',
                min: xMin,
                max: xMax,
                zlevel: 1,
                splitLine: { show: false },
                axisLine: {
                    lineStyle: {
                        color: '#333',
                    },
                },
                axisLabel: {
                    color: 'black',
                    formatter: (value: number) => value === xMin || value === xMax ? '' : value + ''
                },
                axisPointer: {
                    label: {
                        formatter: ({ value }) => {
                            return params.xColumn.name + ': ' + smarterNumber(Number(value));
                        }
                    }
                }
            },
            yAxis: {
                type: 'value',
                min: yMin,
                max: yMax,
                zlevel: 1,
                splitLine: { show: false },
                axisLine: {
                    lineStyle: {
                        color: '#333',
                    },
                },
                axisLabel: {
                    color: 'black',
                    formatter: (value: number) => value === yMin || value === yMax ? '' : value + ''
                },
                axisPointer: {
                    label: {
                        formatter: ({ value }) => {
                            return params.yColumn.name + ': ' + smarterNumber(Number(value));
                        }
                    }
                }
            },
            series: [{
                dimensions: [{ type: 'number' }, { type: 'number' }],
                type: 'custom',
                data: [{}],
                renderItem: ((params: any, api: any) => {
                    const origin = api.coord([xMin, yMin]);
                    const scale = api.size([xMax - xMin, yMax - yMin]);

                    return {
                        type: 'image',
                        style: {
                            image: canvas,
                            x: origin[0],
                            y: origin[1],
                            width: scale[0],
                            // Negative height: draw image drawn upside down
                            height: -scale[1]
                        }
                    };
                })
            } as SeriesOption]
        };
    }

    ngOnDestroy() { }
}

