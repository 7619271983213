<button class="btn header-button trigger subnav-page-header-name has-caret" [dkuPopoverTrigger]="popover"
    [wt1Click]="'worksheets_container-selection'">
    <i class="icon-cogs"></i>
    <b class="caret"></b>
</button>
<dku-popover #popover>
    <div class="control-group">
        <h2 class="settings-section-title">Containerized execution</h2>
        <container-selection-form [(selection)]="selection"></container-selection-form>
    </div>
    <div #closeButton *ngIf="!readOnly">
        <button class="btn btn--primary pull-right" (click)="save()">
            Save
        </button>
    </div>
</dku-popover>