import { Inject, Injectable } from '@angular/core';
import { catchError, EMPTY, forkJoin, map, Observable, of, tap } from 'rxjs';
import type { PartialDeep } from 'type-fest';
import { fairAny } from 'dku-frontend-core';
import { Dashboard, DashboardPage } from 'generated-sources';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { DashboardPageToCreatePinningOrder, DashboardToCreatePinningOrder, ExistingDashboardPinningOrder, PinningOrder } from '../../models/pinning-order.model';
import { DashboardPageToCreate } from '../../models/dashboard-page-to-create.model';

@Injectable({
    providedIn: 'root'
})
export class CreateAndPinInsightService {

    constructor(
        private readonly dataikuApiService: DataikuAPIService,
        @Inject('DashboardUtils') private readonly dashboardUtils: fairAny,
        @Inject('$rootScope') private readonly $rootScope: fairAny,
        @Inject('$stateParams') private readonly $stateParams: fairAny
    ) { }

    /**
     * Returns a Dashboard object to be used to create a new dashboard.
     */
    getDashboardTemplate(): PartialDeep<Dashboard> {
        return {
            projectKey: this.$stateParams.projectKey,
            owner: this.$rootScope.appConfig.user.login,
            pages: [
                {}
            ]
        };
    }

    /**
     * Returns a Dashboard object to be used as a selector option to ask for a new dashboard creation.
     */
    getCreateDashboardOption(): PartialDeep<Dashboard> {
        return {
            ...this.getDashboardTemplate(),
            name: 'Create new dashboard...'
        };
    }

    /**
     * Returns a DashboardPage object to be used to create a new page.
     */
    getPageTemplate(): PartialDeep<DashboardPage> {
        return {
            grid: {
                tiles: []
            }
        };
    }

    /**
     * Returns a DashboardPage object to be used to ask for a new page creation.
     */
    getCreatePageOption(): PartialDeep<DashboardPage> {
        return {
            ...this.getPageTemplate(),
            title: 'Create new slide...'
        };
    }

    /**
     * Create all the dashboard and pages required by the given pinning orders and returns them asynchronously.
     */
    createPinningOrdersDashboardAndPages(pinningOrders: PinningOrder[], errorCallback: () => void): Observable<PinningOrder[]> {
        if (!pinningOrders.length) {
            return of([]);
        }
        // Only the pinning orders that doesn't need a new dashboard or new page.
        const readyToUse: ExistingDashboardPinningOrder[] = [];
        // Only the pinning orders for which we need to create dashboards.
        const needsDashboardCreation: DashboardToCreatePinningOrder[] = [];
        // Only the pinning orders for which we need to create pages.
        const needsPageCreation: DashboardPageToCreatePinningOrder[] = [];

        for (const pinningOrder of pinningOrders) {
            if (!pinningOrder.dashboard.id) {
                needsDashboardCreation.push(pinningOrder);
            } else if (!pinningOrder.page.id) {
                needsPageCreation.push(pinningOrder as DashboardPageToCreatePinningOrder);
            } else {
                readyToUse.push(pinningOrder as ExistingDashboardPinningOrder);
            }
        }

        return forkJoin([
            this.handlePinningOrdersOnNewDashboards(needsDashboardCreation, errorCallback),
            this.handlePinningOrdersOnNewPages(needsPageCreation, errorCallback)
        ]).pipe(
            map(([dashboardCreationPinningOrders, pageCreationPinningOrders]) => ([...dashboardCreationPinningOrders, ...pageCreationPinningOrders])),
            // The user will be redirected to the first pinning order's dashboard so the updated pinning orders should have the correct first element.
            map(creationPinningOrders => {
                const updatedPinningOrders = [...creationPinningOrders, ...readyToUse];
                return this.sortPinningOrders(pinningOrders, updatedPinningOrders);
            })
        );
    }

    private handlePinningOrdersOnNewDashboards(dashboardCreationPinningOrders: DashboardToCreatePinningOrder[], errorCallback: () => void): Observable<PinningOrder[]> {
        if (!dashboardCreationPinningOrders.length) {
            return of([]);
        }
        return forkJoin(dashboardCreationPinningOrders
            .map(({ dashboard }) => {
                const { name, owner, projectKey } = dashboard;
                return this.dataikuApiService.dashboards.save({ name: name || this.dashboardUtils.getDashboardDefaultName(), owner, projectKey })
                    .pipe(
                        catchError(() => {
                            errorCallback();
                            return EMPTY;
                        }),
                        map(updatedDashboard => ({ dashboard: updatedDashboard, page: updatedDashboard.pages[0] }))
                    );
            }));
    }

    private handlePinningOrdersOnNewPages(pageCreationPinningOrders: DashboardPageToCreatePinningOrder[], errorCallback: () => void): Observable<PinningOrder[]> {
        if (!pageCreationPinningOrders.length) {
            return of([]);
        }

        const pagesToCreateByDashboard = new Map<Dashboard, DashboardPageToCreate[]>();
        for (const { dashboard, page } of pageCreationPinningOrders) {
            const oldPagesToCreate = pagesToCreateByDashboard.get(dashboard) || [];
            pagesToCreateByDashboard.set(dashboard, [...oldPagesToCreate, page]);

        }
        const createdPagesObservables: Observable<DashboardPageToCreatePinningOrder[]>[] = Array.from(pagesToCreateByDashboard.entries()).map(([dashboard, pages]) => {
            // Add and save all the new dashboard pages at once.
            const requestDashboard = { ...dashboard, pages:  [...dashboard.pages, ...pages] };
            return this.dataikuApiService.dashboards.save(requestDashboard)
                .pipe(
                    catchError(() => {
                        errorCallback();
                        return EMPTY;
                    }),
                    map(updatedDashboard => {
                        const pinningOrders = [];
                        for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
                            pinningOrders.push({ dashboard: updatedDashboard, page: updatedDashboard.pages[dashboard.pages.length + pageIndex] });
                        }
                        return pinningOrders;
                    })
                );
        });
        return forkJoin(createdPagesObservables).pipe(
            map((groupedResult) => groupedResult.flat())
        );
    }

    /**
     * Sort the new pinning orders so that the new first element matches the old first element.
     */
    private sortPinningOrders(oldPinningOrders: PinningOrder[], newPinningOrders: PinningOrder[]): PinningOrder[] {
        const dashboardToPutFirstIndex = newPinningOrders.findIndex(({ dashboard }) => {
            if (!oldPinningOrders[0].dashboard.id) {
                const newDashboardName = oldPinningOrders[0].dashboard.name || this.dashboardUtils.getDashboardDefaultName();
                return dashboard.name === newDashboardName;
            }
            return dashboard.id === oldPinningOrders[0].dashboard.id;
        });

        if (dashboardToPutFirstIndex !== -1) {
            const dashboardToPutFirst = newPinningOrders[dashboardToPutFirstIndex];
            newPinningOrders.splice(dashboardToPutFirstIndex, 1);
            newPinningOrders.unshift(dashboardToPutFirst);
        }
        return newPinningOrders;
    }
}
