import { BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy, RendererFactory2 } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';


@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class QaModeService implements OnDestroy {
    // Enabled when DSS is running in Selenium
    private enabled$ = new BehaviorSubject(false);

    constructor(rendererFactory2: RendererFactory2) {
        // Make toggleQaMode() globally available (called by Selenium test suite)
        (window as any).toggleQaMode = (flag?: boolean) => this.enabled$.next(flag ?? !this.enabled$.value);

        // Create a new renderer (it cannot be injected)
        const renderer = rendererFactory2.createRenderer(document.body, null);

        this.enabled$
            .pipe(distinctUntilChanged(), untilDestroyed(this))
            .subscribe((flag) => {
                if (flag) {
                    console.warn('QA mode is enabled (Selenium)');
                }

                // Disable Angular 2+ animations
                renderer.setProperty(document.body, '@.disabled', flag);

                // Add a CSS selector on body to enable/disable custom animations
                if (flag) {
                    document.body.classList.add('qa-mode-enabled');
                    document.body.classList.remove('qa-mode-disabled');
                } else {
                    document.body.classList.remove('qa-mode-enabled');
                    document.body.classList.add('qa-mode-disabled');
                }
            });

        // Enable QA mode by default if a special cookie is present
        if (/\bdisable_dku_fancy=true\b/.test(document.cookie)) {
            this.enabled$.next(true);
        }
    }

    ngOnDestroy() {
    }

    isQaEnabled() {
        return this.enabled$.pipe(distinctUntilChanged());
    }
}
