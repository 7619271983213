<form class="dkuform-chart-options" [formGroup]="textFormattingForm">

    <div class="control-group mright8">
        <label class="control-label">Font</label>
        <div class="controls horizontal-flex">
            <dss-reactive-input [inputTemplate]="fontSizeInput" formControlName="fontSize">
                <ng-template #fontSizeInput let-value="value" let-onValueChange="onValueChange">
                    <number-select [value]="value" [min]="8" [max]="24" (valueChange)="onValueChange($event)"></number-select>
                </ng-template>
            </dss-reactive-input>

            <div class="mleft8" *ngIf="showFontColorSettings$ | async">
                <dss-reactive-input [inputTemplate]="fontColorPickerInput" formControlName="fontColor">
                    <ng-template #fontColorPickerInput let-value="value" let-onValueChange="onValueChange">
                        <chart-color-picker class="flex mx-center-children" [itemsPerRow]="10" [color]="value"
                            icon="dku-icon-text-case-sensitive-16" [colors]="colors" (colorChange)="onValueChange($event)"></chart-color-picker>
                    </ng-template>
                </dss-reactive-input>
            </div>
        </div>
    </div>

    <div class="control-group" *ngIf="showBackgroundSettings$ | async">
        <div class="controls">
            <label>
                <input type="checkbox" formControlName="hasBackground" />
                Add background
            </label>
        </div>
        <div class="controls width-fit-content" *ngIf="textFormattingForm.value.hasBackground">
            <dss-reactive-input [inputTemplate]="backgroundColorPickerInput" formControlName="backgroundColor">
                <ng-template #backgroundColorPickerInput let-value="value" let-onValueChange="onValueChange">
                    <chart-color-picker class="flex mx-center-children" [itemsPerRow]="10" [color]="value" [displayAlpha]="true"
                        icon="dku-icon-paint-bucket-16" [colors]="colors" (colorChange)="onValueChange($event)"></chart-color-picker>
                </ng-template>
            </dss-reactive-input>
        </div>
    </div>
</form>
