import { ElementRef, Injector, Directive, Input, Inject, SimpleChanges, OnChanges } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { fairAny } from 'dku-frontend-core';

@Directive({
    selector: 'dataset-status'
})
export class DatasetStatusComponent extends UpgradeComponent implements OnChanges {
    @Input() objectName: string;
    @Input() checklists: any[];
    @Input() data: any;
    @Input() context: string;
    @Input() readOnly: boolean;

    constructor(@Inject('$rootScope') public $rootScope: fairAny,
    elementRef: ElementRef, injector: Injector) {
        super('ng1DatasetStatus', elementRef, injector);
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Run AngularJS change detetion
        this.$rootScope.$applyAsync();
        super.ngOnChanges(changes);
    }
}