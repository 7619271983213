<item-feed [itemHeight]="90" [itemsPerRow]="itemsPerRow" [selectedId]="selectedId" [selectedIndex]="selectedIndex" (itemClick)="documentClick.emit($event)">
    <ng-template let-item="item" let-index="index">
        <div class="document-feed__document">
            <p class="document-feed__document-title">
                <span class="font-weight-semi-bold mx-textellipsis"> Document {{item?.id}}</span>
                <ng-container *ngTemplateOutlet="iconTemplateRef, context: { item }"></ng-container>
            </p>
            <p class="document-feed__document-text">{{item?.record?.text}} </p>
        </div>
    </ng-template>
</item-feed>
