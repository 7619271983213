import { Injectable, Inject, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Data, ActivatedRouteSnapshot, UrlSegment, ParamMap, Route } from '@angular/router';
import { Dict, fairAny, Params } from 'dku-frontend-core';
import { $stateType } from '@migration/upgraded-providers';
import { Observable, Observer } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DkuActivatedRouteService implements ActivatedRoute {

    component: Type<any> | string | null;
    data: Observable<Data>;
    fragment: Observable<string>;
    outlet: string;
    snapshot: ActivatedRouteSnapshot;
    url: Observable<UrlSegment[]>;

    readonly children: ActivatedRoute[];
    readonly firstChild: ActivatedRoute | null;
    readonly parent: ActivatedRoute | null;
    readonly pathFromRoot: ActivatedRoute[];
    readonly queryParamMap: Observable<ParamMap>;
    readonly root: ActivatedRoute;
    readonly routeConfig: Route | null;

    readonly paramMap: Observable<ParamMap>;

    queryParams: Observable<Params>; // NOT IMPLEMENTED: discouraged in Angular
    params: Observable<Params>; // NOT IMPLEMENTED: discouraged in Angular

    constructor(
        @Inject('$state') $state: $stateType,
        @Inject('$rootScope') $rootScope: fairAny,
        private dialogs: MatDialog
    ) {
        this.paramMap = new Observable((observer: Observer<ParamMap>) => {
            observer.next(new DkuParamMap($state.params));

            $rootScope.$on('$stateChangeStart', () => {
                // just in case, make sure we discard all modals/popup
                this.dialogs.closeAll();
            });

            $rootScope.$on('$stateChangeSuccess', () => {
                observer.next(new DkuParamMap($state.params));
            });
        });
    }
}

/*
This service provides an interface very close from that of the native Angular Router's ActivatedRoute
so that we can seamlessly transition to Angular2+ router
 */
export class DkuParamMap implements ParamMap {
    /** Name of the parameters */
    readonly keys: string[];

    has(name: string): boolean {
        return Object.keys(this.params).includes(name);
    }
    /**
     * Return a single value for the given parameter name:
     * - the value when the parameter has a single value,
     * - the first value if the parameter has multiple values,
     * - `null` when there is no such parameter.
     */
    get(name: string): string | null {
        return this.params[name];
    }
    /**
     * Return an array of values for the given parameter name.
     *
     * If there is no such parameter, an empty array is returned.
     */
    getAll(name: string): string[] {
        return [this.params[name]];
    }

    constructor(private params: Dict<string>) {
    }
}
