import { Injectable } from '@angular/core';
import { cloneDeep, isEqual, isNil, uniqueId } from 'lodash';
import { BehaviorSubject, filter, map, Observable, pairwise, shareReplay } from 'rxjs';
import { ColorOptions } from './color-options.interface';

class CustomColorsOptions {
    inputColorOptions$: BehaviorSubject<ColorOptions | null>;
    outputColorOptions$: BehaviorSubject<ColorOptions | null>;
    distinctInputColorOptions$: Observable<[ColorOptions | null, ColorOptions | null]>;
    distinctOutputColorOptions$: Observable<ColorOptions | null>;
}

@Injectable({
    providedIn: 'root'
})
export class ChartCustomColorsService {
    private colorOptionsMap: Record<string, CustomColorsOptions> = {};

    createColorOptions(customId?: string): {id: string, colorOptions$: Observable<ColorOptions | null>} {
        const id = customId || uniqueId();

        const inputColorOptions$ = new BehaviorSubject<ColorOptions | null>(null);
        const distinctInputColorOptions$ = inputColorOptions$.pipe(
            pairwise(),
            filter(([prev, next]) => {
                return !isEqual(prev, next);
            }),
            shareReplay(1)
        );

        distinctInputColorOptions$.subscribe();

        const outputColorOptions$ = new BehaviorSubject<ColorOptions | null>(null);
        const distinctOutputColorOptions$ = outputColorOptions$.pipe(
            pairwise(),
            filter(([prev, next]) => {
                return !isEqual(prev, next);
            }),
            map(([prev, next]) => {
                return cloneDeep(next);
            }),
            shareReplay(1)
        );

        distinctOutputColorOptions$.subscribe();

        this.colorOptionsMap[id] = {
            inputColorOptions$,
            outputColorOptions$,
            distinctInputColorOptions$,
            distinctOutputColorOptions$
        };

        return { id, colorOptions$: this.colorOptionsMap[id].distinctOutputColorOptions$ };
    }

    getInputColorOptions(id: string | null): Observable<[ColorOptions | null, ColorOptions | null]> {
        if (isNil(id)) {
            throw new Error('Please provide a valid id');
        }
        return this.colorOptionsMap[id].distinctInputColorOptions$;
    }

    getOutputColorOptions(id: string | null): Observable<ColorOptions | null> {
        if (isNil(id)) {
            throw new Error('Please provide a valid id');
        }
        return this.colorOptionsMap[id].distinctOutputColorOptions$;
    }

    updateInputColorOptions(id: string | null, colorOptions: ColorOptions | null) {
        if (isNil(id) || isNil(this.colorOptionsMap[id])) {
            throw new Error('Color options not found');
        }
        this.colorOptionsMap[id].inputColorOptions$.next(colorOptions);
    }

    updateOutputColorOptions(id: string | null, colorOptions: ColorOptions | null) {
        if (isNil(id) || isNil(this.colorOptionsMap[id])) {
            throw new Error('Color options not found');
        }
        this.colorOptionsMap[id].outputColorOptions$.next(colorOptions);
    }

    removeColorOptions(ids: Array<string>) {
        ids.forEach(id => {
            delete this.colorOptionsMap[id];
        });
    }
}
