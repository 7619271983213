import { Inject, Injectable } from '@angular/core';
import { NiceConstService, ProjectInitialsService } from '@shared/services';
import { ITaggingService } from '@model-main/server/services/itagging-service';
import { Workspace } from '@model-main/workspaces/workspace';
import { WorkspaceNonTaggableType, WorkspaceObjectType } from '../models';
import { SlugifyService } from '@shared/services/slugify.service';
import { DKURootScope } from '@shared/models/dku-root-scope.model';

@Injectable({
    providedIn: 'root'
})
export class WorkspaceDisplayService {
    constructor(
        @Inject('StateUtils') private readonly StateUtils: any,
        @Inject('TypeMappingService') private readonly typeMappingService: any,
        @Inject('$rootScope') private $rootScope: DKURootScope,
        private readonly niceConstService: NiceConstService,
        private readonly projectInitialsService: ProjectInitialsService,
        private readonly slugifyService: SlugifyService
    ) {
    }

    getObjectLink(workspaceKey: string, object: Workspace.WorkspaceObject): { href: string, target: string, features: string[] } | undefined {
        if (object.htmlLink) {
            return { href: object.htmlLink.url, target: '_blank', features: ['noopener', 'noreferrer']};
        } else if (object.appId) {
            return { href: this.StateUtils.href.workspaceApp(workspaceKey, object.appId), target: '', features: [] };
        } else if (object.reference) {
            return {
                href: this.StateUtils.href.workspaceObject(
                    workspaceKey,
                    object.reference.projectKey,
                    object.reference.type,
                    object.reference.id
                ),
                target: '',
                features: []
            };
        } else if (object.story && this.$rootScope.appConfig.dataStoryIntegrationEnabled) {
            return {href: getDatastoryURL(this.$rootScope, object.story), target: '_blank', features: ['noopener', 'noreferrer']};
        }
        return undefined;
    }

    getSourceObjectLink(object: Workspace.WorkspaceObject): string {
        if (object.appId) {
            return this.StateUtils.href.app(object.appId);
        } else if (object.reference) {
            switch (object.reference.type) {
                case ITaggingService.TaggableType.DASHBOARD:
                    return this.StateUtils.href.dashboard(object.reference.id, object.reference.projectKey, {
                        name: object.displayName
                    });
                case ITaggingService.TaggableType.DATASET:
                    return this.StateUtils.href.dataset(object.reference.id, object.reference.projectKey, {
                        name: object.displayName
                    });
                case ITaggingService.TaggableType.WEB_APP:
                    return this.StateUtils.href.webapp(object.reference.id, object.reference.projectKey, {
                        name: this.slugifyService.transform(object.displayName)
                    });
                case ITaggingService.TaggableType.ARTICLE:
                    return this.StateUtils.href.article(object.reference.id, object.reference.projectKey, {
                        name: object.displayName
                    });
                default:
                    return '';
            }
        }
        return '';
    }

    getObjectType(object: Workspace.WorkspaceObject): WorkspaceObjectType {
        if (object.reference) {
            return object.reference.type;
        } else if (object.htmlLink) {
            return WorkspaceNonTaggableType.LINK;
        } else if (object.appId) {
            return WorkspaceNonTaggableType.APP;
        } else if (object.story) {
            return WorkspaceNonTaggableType.STORY;
        }
        return WorkspaceNonTaggableType.UNKNOWN;
    }

    getObjectTypeUI(object: Workspace.WorkspaceObject): string {
        return this.niceConstService.transform(this.getObjectType(object));
    }

    getObjectColor(object: Workspace.WorkspaceObject, background: boolean): string {
        if (object.htmlLink) {
            return background ? 'workspace-object-link-icon__background' : 'workspace-object-link-icon__color';
        } else if (object.appId) {
            return this.typeMappingService.mapTypeToColor('app', background);
        } else if (object.story) {
            return background ? 'workspace-object-datasory-icon__background' : 'workspace-object-datasory-icon__color';
        }
        return this.typeMappingService.mapTypeToColor(object.reference?.type, background);
    }

    getWorkspaceInitials(workspace: Workspace): string {
        return this.projectInitialsService.getProjectInitials(workspace.displayName || 'Workspace');
    }

    getAppImageURL(appId: string): string {
        return `/dip/api/apps/get-template-image?appId=${encodeURIComponent(appId)}`;
    }

    transformTimeline(timeline?: { items: Array<{ objectType: WorkspaceObjectType }> }) {
        if (!timeline) {
            return timeline;
        }

        return {
            ...timeline, items: timeline.items.map(item => {
                if (item.objectType === 'PROJECT') {
                    return { ...item, objectType: WorkspaceNonTaggableType.APP };
                }
                return item;
            })
        };
    }

    shouldOpenExternally(object: Workspace.WorkspaceObject): boolean {
        return object.htmlLink !== undefined || object.story !== undefined;
    }
}

function getDatastoryURL(rootScope: DKURootScope, story: Workspace.Story): string {
    if (story.id) {
        let url = rootScope.appConfig.dataStoryIntegrationUrl;
        if (url.endsWith('/')) {
            url = url.slice(0, -1);
        }
        return `${url}/slide/${story.id}`;
    }
    return '';
}
