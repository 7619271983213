/**
 * Generated from com.dataiku.dip.analysis.model.prediction.WeightParams
 */
export interface WeightParams {
    sampleWeightVariable: string;
    weightMethod: WeightParams.WeightMethod;
}

export namespace WeightParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.WeightParams$WeightMethod
     */
    export enum WeightMethod {
        NO_WEIGHTING = 'NO_WEIGHTING',
        SAMPLE_WEIGHT = 'SAMPLE_WEIGHT',
        CLASS_WEIGHT = 'CLASS_WEIGHT',
        CLASS_AND_SAMPLE_WEIGHT = 'CLASS_AND_SAMPLE_WEIGHT'
    }
}