import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatModality'
})
export class FormatModalityPipe implements PipeTransform {
    transform(values: string[], quoteValues: boolean = false): string {
        if (!values) {
            return '';
        }

        if (quoteValues) {
            values = values.map(value => `"${value}"`);
        }

        if (1 === values.length) {
            return values[0];
        }
        return `(${values.join(', ')})`;
    }
}