import { Aggregation } from '@model-main/pivot/backend/model/aggregation';
import { PivotTableTensorResponse } from '@model-main/pivot/backend/model/pivot-table-tensor-response';
import { EChartsOption, TitleComponentOption } from 'echarts';
import { ChartLabels } from '../../../enums';
import { ChartCoordinates, ChartPoint, FrontendChartDef, FrontendMeasureDef } from '../../../interfaces';
import { ChartTensorDataWrapper } from '../../../models';
import { EChartDrawContext, EChartLegendContext, EChartMatrixPoint, EChartOptionsContext, EChartSeriesContext, EChartSeriesOption, FacetsMetadata, MetaEChartDef } from '../interfaces';
import { EChartPrepareDataContext } from './echart-prepare-data-context.model';
import { PrepareData } from './prepare-data.model';

/**
 * Main entrypoint to create an EChart definition
 * This definition provides a "draw" method which must return EChartsOption (an object interpreted by ECharts to draw a chart)
 * A set of utility methods are also defined to provide building steps (getSeries, getOptions etc...)
 */
export abstract class EChartDef {
    chartData: ChartTensorDataWrapper;
    hasFacets: boolean = false;
    chartHeight: number = 0;
    tooltipIgnoredSeries: string[] = []

    protected AXIS_LABELS_DEFAULT_FORMATTING_OPTIONS: { fontFamily: string, color: string, fontSize: number } = {
        fontFamily: this.chartFormattingOptions.FONT_FAMILY,
        color: this.chartFormattingOptions.COLOR,
        fontSize: this.chartFormattingOptions.AXIS_LABELS_FONT_SIZE
    };

    constructor(
        protected stringUtils: any,
        protected chartFormattingOptions: any,
        protected prepareDataService: PrepareData
    ) { }

    /**
     * Highlights or downplays an element of the chart on mouseover
     * @param {string} itemLabel        label of the legend item triggering the event
     * @param {Object} echartInstance
     * @param {boolean} mouseEnter      whether it is a mouse in or mouse out event
     */
    onLegendHover = (legendContext: EChartLegendContext): void => {
        const actionType = legendContext.mouseEnter ? 'highlight' : 'downplay';
        legendContext.echartInstance.dispatchAction({
            type: actionType,
            seriesName: legendContext.item.label
        });
    };

    afterChange = (echartInstance: any): void => {
        return;
    };

    /**
     * wrapData returns a data wrapper which embeds a set of methods to explore
     * a given response thanks to a map of properties which describes the data.
     * @param data A response with all the data related to the chart we want to draw
     * @param axesDef A map of properties which describes how to read the backend response
     */
    protected wrapData(data: PivotTableTensorResponse, axesDef?: Record<string, any>): ChartTensorDataWrapper {
        return new ChartTensorDataWrapper(data, axesDef);
    };

    protected abstract getSeriesId(coord: ChartCoordinates): string;

    /**
     * getAllCoords returns chart coordinates which allow to associate a point to a tooltip
     * @param chartPoints
     * @returns chartCoordinates a matrix of chart coordinates used by tooltips
     */
    protected getAllCoords(chartPoints: Array<ChartPoint>): Array<Array<ChartCoordinates>> {
        let allCoordsMap: Record<string, Array<ChartCoordinates>> = {};

        allCoordsMap = chartPoints.reduce((acc, point) => {
            const coordinatesId = this.getSeriesId(point.coord);
            if (!acc[coordinatesId]) {
                acc[coordinatesId] = [];
            }

            acc[coordinatesId].push(point.coord);

            return acc;
        }, allCoordsMap);

        return Object.values(allCoordsMap);
    }

    /**
     * mapValue transforms the value of a point according to its implementation in children classes
     * @param value
     * @returns modified value
     */
    protected mapValue(value: any): any {
        return value;
    }

    protected isMeasureComputedInPercentage(measure: FrontendMeasureDef): boolean {
        return measure.computeMode === Aggregation.ComputeMode.PERCENTAGE || measure.computeMode === Aggregation.ComputeMode.CUMULATIVE_PERCENTAGE;
    }

    protected prepareData(context: EChartPrepareDataContext): Array<EChartMatrixPoint> {
        return this.prepareDataService.prepareData(context);
    }

    /**
     * getTitle is an utility method which is used to build an ECharts title
     * @param chartPoints
     * @returns A TitleComponentOption (ECharts title options definition)
     */
    protected getTitle(chartPoints: Array<EChartMatrixPoint>): TitleComponentOption | undefined {
        return;
    }

    /**
     * checkDataValidity is an utility method which checks whether the chart data is valid
     * @returns a object containing all the information necessary to display the warning toast
     */
    checkDataValidity(): { valid: boolean, message: string, type: string } | undefined  {
        return;
    }


    /**
     * getSeries is an utility method which is used to build series
     * @param seriesContext
     * @returns An array of EChartSeriesOption, an object which defines a piece of series
     */
    protected abstract getSeries(seriesContext: EChartSeriesContext): Array<EChartSeriesOption>;

    /**
     * getOptions is an utility method which is used to build echart options (axes, series, grid)
     * @param optionsContext
     * @returns EChartsOption, an object which defines a chart to be drawn by ECharts
     */
    protected abstract getOptions(optionsContext: EChartOptionsContext): EChartsOption;

    /**
     * getThumbnailOptions is an utility method which is used to remove certain properties (grid, axes options) in order to render a thumbnail
     * @param options
     * @returns EChartsOption, an object which defines a chart thumbnail to be drawn by ECharts
     */
    abstract getThumbnailOptions(options: EChartsOption): Partial<EChartsOption>;

    /**
     * getColorSpec is an utility method used to retrieve the color spec of a chart
     * @param chartDef
     * @returns ColorSpec
     */
    abstract getColorSpec(chartDef: FrontendChartDef): any;

    protected getFacetLabel(facet?: { label: string }): string {
        if (!facet || !facet.label || facet.label === ChartLabels.NO_VALUE) {
            return 'No value';
        } else {
            return facet.label;
        }
    }

    protected getFacetsMetadata(facets: Array<{label: string}>): FacetsMetadata {
        const FACET_LABEL_MARGIN = 20;
        const FACET_LABEL_MIN_WIDTH = 50;
        const FACET_LABEL_MAX_WIDTH = 250;

        const facetsMetadata: FacetsMetadata = {
            facets: [],
            maxContainerWidth: 50
        };

        return facets.reduce(
            (acc, facet: { label: string }) => {
                const facetLabel = this.getFacetLabel(facet);
                const facetLabelWidth = this.stringUtils.getTextWidth(facetLabel, `${this.AXIS_LABELS_DEFAULT_FORMATTING_OPTIONS.fontSize}px ${this.AXIS_LABELS_DEFAULT_FORMATTING_OPTIONS.fontFamily}`) || 0;

                let facetLabelContainerWidth = 0;

                //  Min/max width
                if (facetLabelWidth + FACET_LABEL_MARGIN < FACET_LABEL_MIN_WIDTH) {
                    facetLabelContainerWidth = FACET_LABEL_MIN_WIDTH;
                } else if (facetLabelWidth + FACET_LABEL_MARGIN > FACET_LABEL_MAX_WIDTH) {
                    facetLabelContainerWidth = FACET_LABEL_MAX_WIDTH;
                } else {
                    facetLabelContainerWidth = facetLabelWidth + FACET_LABEL_MARGIN;
                }

                acc.maxContainerWidth = Math.max(acc.maxContainerWidth, facetLabelContainerWidth);
                acc.facets.push({ label: facetLabel, width: facetLabelWidth, containerWidth: facetLabelContainerWidth });

                return acc;
            },
            facetsMetadata
        );
    }

    protected getLabelLeft(maxContainerWidth: number, facetWidth: number): number {
        if (maxContainerWidth >= facetWidth) {
            return Math.ceil(maxContainerWidth / 2 - facetWidth / 2);
        } else {
            return 4;
        }
    }

    protected getFacetTitleOption(
        facet: {label: string, width: number, containerWidth: number},
        facetIndex: number,
        top: number,
        chartHeight: number,
        maxContainerWidth: number,
        singleXAxis?: boolean,
        subtext?: string,
        subtextStyle?: any
    ): TitleComponentOption {
        const facetTitle: TitleComponentOption = {
            text: facet.label,
            subtext,
            subtextStyle,
            textStyle: {
                fontWeight: 'normal',
                width: facet.containerWidth,
                overflow: 'truncate',
                ...this.AXIS_LABELS_DEFAULT_FORMATTING_OPTIONS
            },
            left: this.getLabelLeft(maxContainerWidth, facet.width)
        };

        /**
         * The title should always be aligned to the middle of the chart,
         * with a single X axis, we need to take care of the initial top
         * but, as we removed the other X axes, we also need to remove it
         * from the current chart height (original chart height embeds an X axis).
         */
        if (singleXAxis) {
            facetTitle.top = Math.ceil(top + chartHeight * facetIndex + (chartHeight - top) / 2 - this.AXIS_LABELS_DEFAULT_FORMATTING_OPTIONS.fontSize / 2);
        } else {
            facetTitle.top = Math.ceil(chartHeight * facetIndex + chartHeight / 2 - this.AXIS_LABELS_DEFAULT_FORMATTING_OPTIONS.fontSize / 2);
        }

        return facetTitle;
    }

    onInit(chartDef: FrontendChartDef, data: PivotTableTensorResponse, axesDef?: Record<string, any>) {
        //  Builds Chart Tensor Data Wrapper (utils to quickly get access to coordinates)
        this.chartData = this.wrapData(data, axesDef)

        const facets = this.chartData.getFacets();
        this.hasFacets = facets.length > 0;
        this.chartHeight = facets.length * chartDef.chartHeight;
    }

    /**
     * draw is the main entrypoint, it is used to retrieve both coordinates (for tooltips) and echart options (to draw the chart)
     * @param drawContext
     */
    abstract draw(drawContext: EChartDrawContext): { options: EChartsOption, allCoords: Array<Array<ChartCoordinates>> };

    /**
     * drawFacets is the second main entrypoint, it relies on 'draw' to get options for each facets and it binds them together
     * @param facets
     * @param drawContext
     */
    drawFacets?(
        facets: Array<{ label: string }>,
        drawContext: EChartDrawContext
    ): { options: EChartsOption, allCoords: Array<Array<ChartCoordinates>>, meta?: MetaEChartDef };
}
