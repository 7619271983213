import { Injectable } from "@angular/core";
import { DeepHubColumnFormat } from "src/generated-sources";
import { CellDataWithAnnotation, ColumnMetadataWithIndex, ImportantColumnsMetadataWithAnnotation } from "../sample-data-fetcher.service";
import { SampleDataFormatterService } from "../sample-data-formatter.service";

export class ObjectDetectionDesignCellData implements CellDataWithAnnotation {
    constructor(
        public itemId: string,
        public target: DeepHubColumnFormat.ObjectDetectionTargetItem[] | null,
        public itemIndex: number,
        public invalid?: boolean,
        public invalidReason?: string
    ) {}

    listCategories(): string[] {
        return (this.invalid || !this.target)  ? [] : this.target?.map(t => t.category);
    }
}


@Injectable()
export class ObjectDetectionDataFormatterService extends SampleDataFormatterService {

    rowToCellData(rowContent: string[], columnsMetadata: ImportantColumnsMetadataWithAnnotation<ColumnMetadataWithIndex>, i: number): ObjectDetectionDesignCellData {
        try {
            return new ObjectDetectionDesignCellData(
                rowContent[columnsMetadata.single.itemPath.index],
                JSON.parse(rowContent[columnsMetadata.single.annotation.index]),
                i
            );
        } catch(error) { // Not logging the error, because would flood the console
            return new ObjectDetectionDesignCellData(
                rowContent[columnsMetadata.single.itemPath.index],
                null,
                i,
                true,
                "Invalid Object detection format: '" + rowContent[columnsMetadata.single.annotation.index] + "'")
        }
    }
}