import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, ViewChildren, ElementRef, QueryList, SimpleChanges } from '@angular/core';
import { MatRow } from '@angular/material/table';
import { UIDataCollection } from '@model-main/datacollections/uidata-collection';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { normalizeTextForSearch } from '@utils/string-utils';
import { Diff } from 'dku-frontend-core';
import { BehaviorSubject, combineLatest, ReplaySubject, map, Subject, debounceTime } from 'rxjs';

// keep in sync with template
type AvailableColumns = 'icon' | 'name' | 'sourceProjectName' | 'usedInProjectCount' | 'columnCount' | 'lastBuiltOn' | 'description';

type AvailableColumnsForSortBy = Diff<AvailableColumns, 'icon'>;
type SearchAndSortParams = {
    searchQuery: string;
    sortBy: AvailableColumnsForSortBy;
    sortDirection: 'ASC'|'DESC';
};

const ALL_COLUMNS: AvailableColumns[] = ['icon', 'name', 'sourceProjectName', 'usedInProjectCount', 'columnCount', 'lastBuiltOn', 'description'];
const COLUMNS_WHEN_RIGHT_PANEL_OPENED: AvailableColumns[] = ['icon', 'name', 'sourceProjectName', 'usedInProjectCount', 'columnCount', 'lastBuiltOn'];

type DataCollectionItemInfoComparator = (a: UIDataCollection.AbstractDataCollectionItemInfo, b: UIDataCollection.AbstractDataCollectionItemInfo) => number;

// this method  wraps a comparator that only apply to a specific sub-type to make is applicable to the more generic type.
// comparing a item 'a' that is of the subtype with 'b' that isn't will consider that a > b
const onlyForItemType = <T extends UIDataCollection.AbstractDataCollectionItemInfo>(typeGuard: (o: UIDataCollection.AbstractDataCollectionItemInfo) => o is T, comparator: (a: T, b:T) => number) : DataCollectionItemInfoComparator => (a, b) => {
    if(typeGuard(a)) {
        if(typeGuard(b)) {
            return comparator(a, b);
        } else {
            return 1;
        }
    } else if(typeGuard(b)) {
        return -1;
    } else {
        return 0;
    }
};

const sortDefaultOrder: Record<NonNullable<SearchAndSortParams['sortBy']>, 'ASC'|'DESC'> = {
    'name': 'ASC',
    'sourceProjectName': 'ASC',
    'usedInProjectCount': 'DESC',
    'columnCount': 'DESC',
    'lastBuiltOn': 'DESC',
    'description': 'ASC',
}

const sortByMethods: Record<NonNullable<SearchAndSortParams['sortBy']>, DataCollectionItemInfoComparator> = {
    'name': (a, b) => a.name.localeCompare(b.name),
    'sourceProjectName': (a, b) => a.sourceProjectName.localeCompare(b.sourceProjectName),
    'usedInProjectCount': onlyForItemType(UIDataCollection.AbstractDataCollectionItemInfo.isDatasetInfo, (a, b) => a.usedInProjectCount - b.usedInProjectCount),
    'columnCount': onlyForItemType(UIDataCollection.AbstractDataCollectionItemInfo.isDatasetInfo, (a, b) => a.columnCount - b.columnCount),
    'lastBuiltOn': onlyForItemType(UIDataCollection.AbstractDataCollectionItemInfo.isDatasetInfo, (a, b) => a.lastBuiltOn - b.lastBuiltOn),
    'description': (a, b) => a.description.localeCompare(b.description),
};

@UntilDestroy()
@Component({
    selector: 'data-collection-content-table',
    templateUrl: './data-collection-content-table.component.html',
    styleUrls: ['./data-collection-content-table.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataCollectionContentTableComponent implements OnChanges {
    @Input() isRightPanelOpened!: boolean;

    private readonly rawContent$ = new ReplaySubject<UIDataCollection.AbstractDataCollectionItemInfo[]>(1);
    @Input() set items(value: UIDataCollection.AbstractDataCollectionItemInfo[]) {
        this.rawContent$.next(value);
    }

    readonly selectedItem$ = new Subject<UIDataCollection.AbstractDataCollectionItemInfo | undefined>();
    @Input() selectedItem?: UIDataCollection.AbstractDataCollectionItemInfo;

    @Output() selectedItemChange = new EventEmitter<UIDataCollection.AbstractDataCollectionItemInfo>();
    @ViewChildren(MatRow, { read: ElementRef }) private tableRows!: QueryList<ElementRef<HTMLTableRowElement>>;

    displayedColumns = ALL_COLUMNS;

    readonly sortAndFilterParams$ = new BehaviorSubject<SearchAndSortParams>({
        searchQuery: '',
        sortBy: 'name',
        sortDirection: 'ASC',
    });

    // maps to the input field value only. debounced-pushed into the actual filter params
    private readonly searchQueryInput$ = new BehaviorSubject<string>('');
    get searchQueryInput() { return this.searchQueryInput$.value; }
    set searchQueryInput(val: string) {
        this.searchQueryInput$.next(val);
    }

    // the content of the table as displayed, ie after sorting and filtering
    readonly sortedContent$ = combineLatest([this.rawContent$, this.sortAndFilterParams$]).pipe(
        map(([rawContent, {searchQuery, sortBy, sortDirection}]) => {
            const normalizedSearchQuery = normalizeTextForSearch(searchQuery);

            const filteredContent = rawContent.filter(item => {
                switch(item.type) {
                    case "dataset":
                    case "discoverable-dataset":
                        return normalizeTextForSearch(item.name).includes(normalizedSearchQuery)
                            || normalizeTextForSearch(item.description).includes(normalizedSearchQuery)
                            || normalizeTextForSearch(item.sourceProjectName).includes(normalizedSearchQuery)
                            || normalizeTextForSearch(item.sourceProjectKey).includes(normalizedSearchQuery);
                }
            });

            if(sortBy === undefined) {
                return filteredContent;
            }
            const dirAsNumber = sortDirection === 'ASC' ? 1 : -1;
            const sortComparator: DataCollectionItemInfoComparator = (a, b) => dirAsNumber * sortByMethods[sortBy](a, b);
            return filteredContent.sort(sortComparator);
        }),
    );


    constructor() {
        // watches content & selected item to keep the currently selected item in the scroll view
        combineLatest([this.sortedContent$, this.selectedItem$]).pipe(
            map(([content, selectedItem]) => selectedItem !== undefined ? content.indexOf(selectedItem) : -1),
            debounceTime(0), // let mat-table actually update the DOM
            untilDestroyed(this),
        ).subscribe(idx => {
            const selectedRow = this.tableRows.find(item => item.nativeElement.sectionRowIndex === idx);
            selectedRow?.nativeElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
        });

        // we debounce the changes in the input field before applying them & possibly triggering a scroll
        this.searchQueryInput$.pipe(
            debounceTime(400),
            untilDestroyed(this)
        ).subscribe((searchQuery) => {
            this.sortAndFilterParams$.next({
                ...this.sortAndFilterParams$.value,
                searchQuery: searchQuery,
            });
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.displayedColumns =  this.isRightPanelOpened
            ? COLUMNS_WHEN_RIGHT_PANEL_OPENED
            : ALL_COLUMNS;

        if(changes.selectedItem) {
            this.selectedItem$.next(this.selectedItem);
        }
    }

    isDataset(item: UIDataCollection.AbstractDataCollectionItemInfo): item is UIDataCollection.AbstractDatasetInfo {
        return UIDataCollection.AbstractDataCollectionItemInfo.isAbstractDatasetInfo(item);
    }

    isReadableDataset(item: UIDataCollection.AbstractDataCollectionItemInfo): item is UIDataCollection.DatasetInfo {
        return UIDataCollection.AbstractDataCollectionItemInfo.isDatasetInfo(item);
    }

    resetFilters() {
        this.searchQueryInput$.next('');
        this.sortAndFilterParams$.next({
            ...this.sortAndFilterParams$.value,
            searchQuery: ''
        });
    }

    toggleSortBy(column: AvailableColumnsForSortBy) {
        this.sortAndFilterParams$.next({
            ...this.sortAndFilterParams$.value,
            sortBy: column,
            sortDirection: this.getNextSortState(column),
        });
    }

    private getNextSortState(column: AvailableColumnsForSortBy) {
        if(this.sortAndFilterParams$.value.sortBy !== column) {
            return sortDefaultOrder[column];
        } else {
            return this.sortAndFilterParams$.value.sortDirection === 'ASC' ? 'DESC' : 'ASC';
        }
    }

    getCurrentSortIcon(column: AvailableColumnsForSortBy) {
        const {sortBy, sortDirection} = this.sortAndFilterParams$.value;
        return sortBy !== column
            ? 'data-collection-table__sort-icon-hidden'
            : (sortDirection === 'ASC'
                ? 'dku-icon-chevron-up-16'
                : 'dku-icon-chevron-down-16'
            );
    }
}
