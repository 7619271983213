<div class="data-sources-top-bar children-horizontal-spacing-2x">
    <div class="data-sources-top-bar__search">
        <span><i class="dku-icon-search-16"></i></span>
        <input [formControl]="query" type="text" placeholder="Search..." />
    </div>
    <span class="data-sources-top-bar__item-count">{{itemCount}} {{'item' | plurify : itemCount}} </span>

    <div class="btn-group noflex" style="display:inline-block" ng-if="itemsPage">
        <button class="btn btn--secondary" [class.btn--active]="this.selectedTab === DataSourcesTab.ALL"  (click)="selectedTabChange.emit(DataSourcesTab.ALL)">
            <span class="title">All</span>
        </button>
        <button class="btn btn--secondary" [class.btn--active]="this.selectedTab === DataSourcesTab.DATASETS" (click)="selectedTabChange.emit(DataSourcesTab.DATASETS)">
            <span class="title">Datasets</span>
        </button>
        <button class="btn btn--secondary" [class.btn--active]="this.selectedTab === DataSourcesTab.EXTERNAL_TABLES"  (click)="selectedTabChange.emit(DataSourcesTab.EXTERNAL_TABLES)">
            <span class="title">Indexed External Tables</span>
        </button>
    </div>
</div>
<div class="data-sources-top-bar">
    <ng-content slot="[mass-action-menu]"></ng-content>

    <data-sources-filter *ngIf="selectedTab !== DataSourcesTab.EXTERNAL_TABLES" key="projectKey.raw" placeholder="Project"
        [facets]="facets" [aggregations]="aggregations" (facetsChange)="facetsChange.emit($event)">
    </data-sources-filter>
    <data-sources-filter *ngIf="selectedTab !== DataSourcesTab.EXTERNAL_TABLES" key="type_raw" placeholder="Dataset type"
        [facets]="facets" [aggregations]="aggregations" (facetsChange)="facetsChange.emit($event)">
    </data-sources-filter>
    <data-sources-filter *ngIf="selectedTab !== DataSourcesTab.DATASETS" key="connection.raw" placeholder="Database connection"
        [facets]="facets" [aggregations]="aggregations" (facetsChange)="facetsChange.emit($event)">
    </data-sources-filter>
    <data-sources-filter *ngIf="selectedTab !== DataSourcesTab.DATASETS" key="catalog.raw" placeholder="Database catalog"
        [facets]="facets" [aggregations]="aggregations" (facetsChange)="facetsChange.emit($event)">
    </data-sources-filter>
    <data-sources-filter *ngIf="selectedTab !== DataSourcesTab.DATASETS" key="schema.raw" placeholder="Schema"
        [facets]="facets" [aggregations]="aggregations" (facetsChange)="facetsChange.emit($event)">
    </data-sources-filter>
    <data-sources-filter key="tag.raw" placeholder="Tag" [facets]="facets" [aggregations]="aggregations"
        (facetsChange)="facetsChange.emit($event)">
    </data-sources-filter>
    <data-sources-filter *ngIf="selectedTab !== DataSourcesTab.EXTERNAL_TABLES" key="partitioned" placeholder="Partitioned"
        [usePlural]="false" [facets]="facets" [aggregations]="aggregations" (facetsChange)="facetsChange.emit($event)">
    </data-sources-filter>
    <button *ngIf="hasAnyFilter" class="btn btn--primary btn--text" (click)="resetFilters.emit()">
        Clear filters
    </button>
</div>