<div class="modal" [qa]="'eda-configure-card-modal'">
    <modal-header
        [title]="params | cardTitle: 'basic'"
        [hasBorder]="false"
    ></modal-header>
    <div class="modal-body flex modal-body--analysis no-padding">
        <card-editor
            [params]="params"
            (paramsChange)="changeParams($event)"
            (validityChange)="changeValidity($event)"
            (validityTooltipChange)="changeValidityTooltip($event)"
        ></card-editor>
    </div>
    <div class="modal-footer modal-footer-std-buttons">
        <div class="pull-right" [matTooltip]="validationTooltip">
            <button
                type="button"
                class="btn btn--text btn--secondary"
                (click)="dismiss()"
                tabindex="-1"
            >
                Cancel
            </button>
            <button
                type="submit"
                form="config-form"
                class="btn btn--text btn--primary"
                [disabled]="!canSave"
                (click)="save()"
                [wt1Click]="'worksheets_edit-card'"
                [qa]="'eda-save-card-button'"
                tabindex="-1"
            >
                Apply
            </button>
        </div>
        <form
            class="controls"
            *ngIf="columnParams"
        >
            <div class="control-group">
                <label class="control-label control-label--flex" [qa]="'eda-propagate-columns-checkbox'">
                    <input
                        type="checkbox"
                        [(ngModel)]="updateSiblings"
                        name="updateSiblings"
                    >
                    <span class="help-inline">
                        Propagate settings to all
                        <ng-container *ngIf="columnParams!.column.type === Variable.Type.CONTINUOUS">numerical</ng-container>
                        <ng-container *ngIf="columnParams!.column.type === Variable.Type.CATEGORICAL">categorical</ng-container>
                        columns
                    </span>
                </label>
            </div>
        </form>
    </div>
</div>
