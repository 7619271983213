<div *ngIf="payload" class="stack-right-panel-summary">
    <div *ngIf="preFilters.length > 0" class="stack-right-panel-summary__stacks-filter stack-right-panel-summary__title">
        <h4>Pre-filters</h4>
        <div *ngFor="let input of preFilters">
            <h5>{{params.inputs.main.items[input.index].ref}}</h5>
            <div *ngIf="input.preFilter.distinct">Distinct rows only</div>
            <div *ngIf="input.preFilter.enabled" [innerHTML]="getPreconditionsPreText(input.preFilter) + (input.preFilter | filterNiceRepr)" ></div>
        </div>
    </div>

    <div class="stack-right-panel-summary__title">
        <h4>Column selection</h4>
    </div>
    <div>
        <p>
            {{ columnsSelectionDescription }}
            <a *ngIf="datasetNameToShow"
               class="mx-textellipsis"
               [href]="datasetToShowHref"
               [matTooltip]="datasetNameToShow" isEllipsisActive
               matTooltipPosition="left"
            >{{ datasetNameToShow }}</a>
        </p>
    </div>

    <div *ngIf="isFilterActive(payload.postFilter)" class="stack-right-panel-summary__stacks-filter stack-right-panel-summary__title">
        <h4>Post-filters</h4>
        <div *ngIf="payload.postFilter.distinct">Distinct rows only</div>
        <div *ngIf="payload.postFilter.enabled" [innerHTML]="getPreconditionsPreText(payload.postFilter) + (payload.postFilter | filterNiceRepr)"></div>
    </div>
</div>
