<div class="mat-tab-body-content" #content
     [@translateTab]="{
        value: _position,
        params: {animationDuration: animationDuration}
     }"
     (@translateTab.start)="_onTranslateTabStarted($event)"
     (@translateTab.done)="_translateTabComplete.next($event)"
     cdkScrollable>
  <ng-template matTabBodyHost></ng-template>
</div>
