import type {BivariateFrequencyTableFragment} from './fragments/bivariate-frequency-table-fragment';
import type {CardResult, _CardResult} from './card-result';
import type {Card} from './card';
import type {_BivariateCard} from './bivariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.Chi2IndTestCard
 */
export interface Chi2IndTestCard extends _BivariateCard {
    confidenceLevel: number;
    maxValuesX: number;
    maxValuesY: number;

    // PolyJson type
    type: 'chi2_independence_test';
}

export namespace Chi2IndTestCard {
    export const type = 'chi2_independence_test';
}

/** @deprecated Use Card.isChi2IndTestCard() */ export function isChi2IndTestCard(toTest: Card): toTest is Chi2IndTestCard { return Chi2IndTestCard.type === toTest['type']; }

export namespace Chi2IndTestCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.Chi2IndTestCard$Chi2IndTestCardResult
     */
    export interface Chi2IndTestCardResult extends _CardResult {
        dof: number;
        freqTableData: BivariateFrequencyTableFragment;
        pvalue: number;
        statistic: number;

        // PolyJson type
        type: 'chi2_independence_test';
    }

    export namespace Chi2IndTestCardResult {
        export const type = 'chi2_independence_test';
    }

    /** @deprecated Use CardResult.isChi2IndTestCardResult() */ export function isChi2IndTestCardResult(toTest: CardResult): toTest is Chi2IndTestCardResult { return Chi2IndTestCardResult.type === toTest['type']; }
}