import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ChartColorPickerModule } from '@features/simple-report/modules/chart-color-picker';
import { ReactiveInputModule } from '@features/simple-report/modules/reactive-input';
import { NumberSelectModule } from '@shared/modules/number-select';
import { TextFormattingFormComponent } from './text-formatting-form.component';


@NgModule({
    declarations: [
        TextFormattingFormComponent
    ],
    imports: [
        CommonModule,
        ChartColorPickerModule,
        ReactiveFormsModule,
        ReactiveInputModule,
        NumberSelectModule
    ],
    exports: [
        TextFormattingFormComponent
    ]
})

export class TextFormattingFormModule { }
