import { Injectable } from '@angular/core';
import { createBottomLeftLabelArtifact, PainterImage } from '@shared/models/painter';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { PainterService } from '@shared/services/item-feed/image-feed/painter.service';
import { DeephubImageClassificationReportCellData, isEvalCellData } from './deephub-image-classification-report-data-formatter.service';

@Injectable({
    providedIn: 'root'
  })
export class DeephubImageClassificationReportPainterService extends PainterService {
    readonly SUCCESS_ICON = 'icon-ok-sign';
    readonly FAIL_ICON = 'icon-remove';

    constructor(
        private colorMapService: ColorMapContextService
    ) {
        super();
    }

    paintForFeed(cellData: DeephubImageClassificationReportCellData, image: PainterImage): void {
        const color = this.colorMapService.getColor(cellData.prediction);
        const artifact = createBottomLeftLabelArtifact(color);
        if (isEvalCellData(cellData)) {
            artifact.icon = cellData.prediction === cellData.target ? this.SUCCESS_ICON : this.FAIL_ICON;
        }
        const probaCol = `proba_${cellData.prediction}`;
        artifact.text = `${cellData.prediction} (${cellData.probabilities.get(probaCol)!.toFixed(3)})`;
        image.addArtifact(artifact);
    }
}
