import { Injectable } from '@angular/core';
import { DeephubReport, DeephubReportService } from '@features/deephub/computer-vision/report/services/abstract-deephub-report.service';
import { DeepHubPredictionModelPerf } from 'src/generated-sources';

export interface ImageClassificationReport extends DeephubReport {
    perf: DeepHubPredictionModelPerf.DeepHubImageClassificationPredictionModelPerf,
}

@Injectable({
  providedIn: 'root'
})
export class DeephubImageClassificationReportService extends DeephubReportService<ImageClassificationReport> {
    getFilterCategory(index: number, categories: string[]): string {
        return categories[index];
    }
}
