<form class="dkuform-horizontal tar" [formGroup]="form" *ngIf="maskedXLabels.length > MINI_VIEW_THRESHOLD">
    <div class="control-group">
        <div class="controls mleft0">
            <dku-bs-select-form-control
                formControlName="selectedCategories"
                ngOptions="category for category in list"
                [list]="maskedXLabels"
                [dataActionsBox]="true"
                [dataLiveSearch]="true"
                [dkuMultiple]="true"
                [layout]="'list'"
                [params]="{
                    noneSelectedText: 'Filter categories...'
                }">
            </dku-bs-select-form-control>
        </div>
    </div>
</form>
<div class="font-weight-semi-bold tac mtop8">Predicted</div>
<div class="horizontal-flex minh0">
    <div class="conf-matrix__y-label">
        Ground Truth
    </div>
    <div class="conf-matrix" #container [class.conf-matrix--large-view]="showLargeView">
        <div class="conf-matrix__row-header" #sidebar>
            <div class="conf-matrix__cell conf-matrix__cell--corner conf-matrix__cell--row-header"></div>
            <ng-container *ngFor="let yName of maskedYLabels; index as yIndex; trackBy: trackByIndex">
                <div *ngIf="isLabelVisible(yName)" class="conf-matrix__cell conf-matrix__cell--row-header" (click)="clickCell(-1, yIndex)" [class.conf-matrix__cell--horizontal-selection]="yIsSelected(yIndex)">
                    <div class="conf-matrix__cell-text"[title]="yName">{{ yName }}</div>
                </div>
            </ng-container>
        </div>
        <div class="conf-matrix__body" #matrix>
            <div class="conf-matrix__row conf-matrix__column-header">
                <ng-container *ngFor="let xName of maskedXLabels; index as xIndex; trackBy: trackByIndex">
                    <div class="conf-matrix__cell conf-matrix__cell--column-header conf-matrix__cell--body" *ngIf="isLabelVisible(xName)" (click)="clickCell(xIndex, -1)" 
                    [class.conf-matrix__cell--vertical-selection]="xIsSelected(xIndex)">
                        <div class="conf-matrix__cell-text conf-matrix__cell-text--header" [title]="xName">{{ xName }}</div>
                    </div>
                </ng-container>
            </div>
            <div>
                <ng-container *ngFor="let yName of maskedYLabels; index as yIndex; trackBy: trackByIndex">
                    <div class="conf-matrix__row" *ngIf="isLabelVisible(yName)">
                        <ng-container *ngFor="let xName of maskedXLabels; index as xIndex; trackBy: trackByIndex">
                            <div class="conf-matrix__cell conf-matrix__cell--body" *ngIf="isLabelVisible(xName)" [style.background-color]="maskedColors[xIndex][yIndex]" [class.conf-matrix__cell--vertical-selection]="xIsSelected(xIndex)"
                            [class.conf-matrix__cell--horizontal-selection]="yIsSelected(yIndex)"
                            [style.color]="!showLargeView ? (maskedColors[xIndex][yIndex]! | colorContrast) : ''"
                            (click)="clickCell(xIndex, yIndex)"
                            (mousemove)="hoverCell($event, xIndex, yIndex)"
                            (mouseover)="positionPopover($event)"
                            (mouseout)="hidePopover()">
                                <ng-container *ngIf="!showLargeView">
                                    {{ maskedData[xIndex][yIndex] }}
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    
    <div class="conf-matrix-popover" 
        #popover 
        *ngIf="hoveredCell"
        [class.conf-matrix-popover--visible]="isPopoverVisible" 
        [style.transform]="'translate(' + popoverOffsetLeft + 'px, ' + popoverOffsetTop + 'px)'" 
        [style.width.px]="popoverWidth" 
        [style.height.px]="popoverHeight"
    >
        <ng-container [ngTemplateOutlet]="popoverTemplate" [ngTemplateOutletContext]="{ xLabel: hoveredCell.xLabel, yLabel: hoveredCell.yLabel, value: hoveredCell.value }"></ng-container>
    </div>
</div>
