import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, SimpleChanges, OnChanges } from '@angular/core';
import { ColumnCard, Card, AbstractHeaderCard } from 'src/generated-sources';
import { CardActionType, CardAction } from '@features/eda/worksheet/cards/events';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { fadeInOutHeight } from '@shared/animations/fade-in-out';
import { CollapsingService, CollapsibleStatCard, UpdatableCollapsingService } from '@features/eda/collapsing.service';

@Component({
    selector: 'column-card-body',
    templateUrl: './column-card-body.component.html',
    styleUrls: ['./column-card-body.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeInOutHeight]
})
export class ColumnCardBodyComponent implements OnChanges {
    @Input() params: ColumnCard;
    @Input() results: ColumnCard.ColumnCardResult;
    @Output() action = new EventEmitter<CardAction>();

    @Input() headerParams: AbstractHeaderCard;
    @Output() headerAction = new EventEmitter<CardAction>();

    @Input() readOnly: boolean;
    @Input() extendedActions: boolean;

    showCollapsingControls: boolean;

    private params$ = new ReplaySubject<ColumnCard>(1);
    private headerParams$ = new ReplaySubject<AbstractHeaderCard>(1);
    collapseStates$: Observable<boolean[]>;

    constructor(public collapsingService: CollapsingService) {
        this.collapseStates$ = combineLatest([this.params$, this.headerParams$]).pipe(
            map(([params, headerParams]) => {
                return params.cards
                    .map(card => new CollapsibleStatCard(headerParams, params.column, card.type))
                    .map(collapsible => this.collapsingService.watchIsCollapsed(collapsible))
            }),
            switchMap((watchers) => combineLatest(watchers))
        );
        this.showCollapsingControls = this.collapsingService instanceof UpdatableCollapsingService;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.params$.next(this.params);
        }
        if (changes.headerParams) {
            this.headerParams$.next(this.headerParams);
        }
    }

    handleStatCardAction(index: number, action: CardAction) {
        if (action.type === CardActionType.UPDATE) {

            if (action.updateSiblings) {
                const newColumnCards = this.headerParams.cards.map((columnCard) => {
                    if (columnCard.type !== 'column_card') {
                        // Never happen (typechecking purpose)
                        return columnCard;
                    }
                    if (columnCard.column.type !== this.params.column.type) {
                        // Only update siblings having the same type (categorical, numerical)
                        return columnCard;
                    }
                    const existingStatCard = this.params.cards[index];
                    return {
                        ...columnCard,
                        cards: columnCard.cards.map(statCard => {
                            if (statCard.type !== existingStatCard.type) {
                                // Only update stat cards having the same type (histogram, quantiles, etc)
                                return statCard;
                            }
                            // Override existing card with new stat card:
                            // - Referenced columns will be incorrect: they are fixed by the backend
                            // - The ID shouldn't be modified: we preserve it
                            return {
                                ...action.newParams,
                                id: statCard.id
                            };
                        })
                    };
                });

                const newHeaderParams = {
                    ...this.headerParams,
                    cards: newColumnCards
                };

                this.headerAction.emit({ type: CardActionType.UPDATE, newParams: newHeaderParams });
            } else {
                const newParams: ColumnCard = {
                    ...this.params,
                    cards: [...this.params.cards!]
                };

                newParams.cards![index] = action.newParams;

                this.action.emit({ type: CardActionType.UPDATE, newParams });
            }
        }
        else if ([CardActionType.ADD, CardActionType.DEBUG, CardActionType.PUBLISH, CardActionType.HIGHLIGHT]
            .includes(action.type)) {
            this.action.emit(action);
        }
    }

    toggleStatCard(statCard: Card, newIsCollapsed: boolean) {
        const collapsible = new CollapsibleStatCard(this.headerParams, this.params.column, statCard.type);
        this.collapsingService.setIsCollapsed(collapsible, newIsCollapsed);
    }

    getCardKey(statCard: Card) {
        const collapsible = new CollapsibleStatCard(this.headerParams, this.params.column, statCard.type);
        return collapsible.persistenceKey();
    }

    trackByCardId(index: number, card: Card) {
        return card.id;
    }

    trackByIndex(index: number) {
        return index;
    }
}
