import type {_JoinDescBase} from './../joinlike/join-desc-base';
import type {_JoinLikeRecipePayloadParams} from './../joinlike/join-like-recipe-payload-params';
import type {_MatchingConditionBase} from './../joinlike/matching-condition-base';

/**
 * Generated from com.dataiku.dip.dataflow.exec.fuzzyjoin.FuzzyJoinRecipePayloadParams
 */
export interface FuzzyJoinRecipePayloadParams extends _JoinLikeRecipePayloadParams {
    debugMode: boolean;
    withMetaColumn: boolean;
}

export namespace FuzzyJoinRecipePayloadParams {
    /**
     * Generated from com.dataiku.dip.dataflow.exec.fuzzyjoin.FuzzyJoinRecipePayloadParams$FuzzyMatchDesc
     */
    export interface FuzzyMatchDesc {
        distanceType: FuzzyJoinRecipePayloadParams.DistanceType;
        relativeTo: number;
        threshold: number;
    }

    /**
     * Generated from com.dataiku.dip.dataflow.exec.fuzzyjoin.FuzzyJoinRecipePayloadParams$JoinDesc
     */
    export interface JoinDesc extends _JoinDescBase {}

    /**
     * Generated from com.dataiku.dip.dataflow.exec.fuzzyjoin.FuzzyJoinRecipePayloadParams$MatchingCondition
     */
    export interface MatchingCondition extends _MatchingConditionBase {
        fuzzyMatchDesc: FuzzyJoinRecipePayloadParams.FuzzyMatchDesc;
        normaliseDesc: FuzzyJoinRecipePayloadParams.NormaliseDesc;
    }

    /**
     * Generated from com.dataiku.dip.dataflow.exec.fuzzyjoin.FuzzyJoinRecipePayloadParams$DistanceType
     */
    export enum DistanceType {
        EXACT_HASH = 'Strict equality',
        EXACT = 'Strict equality',
        EUCLIDEAN = 'Euclidean',
        GEO = 'Geospatial',
        HAMMING = 'Hamming',
        LEVENSHTEIN = 'Damerau-Levenshtein',
        COSINE = 'Cosine',
        JACCARD = 'Jaccard'
    }

    /**
     * Generated from com.dataiku.dip.dataflow.exec.fuzzyjoin.FuzzyJoinRecipePayloadParams$NormaliseDesc
     */
    export interface NormaliseDesc {
        caseInsensitive: boolean;
        clearSalutations: boolean;
        clearStopWords: boolean;
        language: string;
        normaliseText: boolean;
        sortAlphabetically: boolean;
        transformToStem: boolean;
        unicodeCasting: boolean;
    }
}