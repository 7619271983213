import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DkuPopoverComponent } from '@app/widgets/dropdowns/dku-popover/dku-popover.component';
import { ColorsService } from '@shared/graphics/colors.service';
import { MetricInfo, MetricValue } from '../experiment-tracking.service';

const MAX_INVALID_VALUES_TO_DISPLAY = 5;
const MAX_INVALID_RUNS_TO_DISPLAY = 3;

class InvalidRun {
    public runId: string;
    public runName: string;

    public invalidValuesWithDetails: {
        step: number,
        timestamp: number,
        invalidValue: string
    }[] = [];

    additionalInvalidValueCount = 0;
    public hasManyValues = false;
    
    pushInvalidValue(metricValue: MetricValue) {
        if (this.invalidValuesWithDetails.length < MAX_INVALID_VALUES_TO_DISPLAY) {
            const step = metricValue.step;
            const timestamp = metricValue.timestamp;
            const invalidValue =  metricValue.invalidValue || '';
            this.invalidValuesWithDetails.push({ step, timestamp, invalidValue });
        } else {
            this.additionalInvalidValueCount++;
        }
    }
}

@Component({
    selector: 'experiment-tracking-invalid-metric-value',
    templateUrl: './experiment-tracking-invalid-metric-value.component.html',
    styleUrls: ['./experiment-tracking-invalid-metric-value.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperimentTrackingInvalidMetricValueComponent implements OnInit {
    @Input() metricInfo: MetricInfo;
    @Input() showRuns = false;

    @Output() clickOnRun = new EventEmitter<string>();
    
    @ViewChild('popover') popover: DkuPopoverComponent;

    invalidRuns: Map<string, InvalidRun> = new Map();
    additionalInvalidRunIds: Set<string> = new Set();

    constructor(
        private colorsService: ColorsService,
    ) {
    }

    ngOnInit(): void {
        if (!this.metricInfo?.hasInvalidValue) {
            return;
        }
        const hasManyValues = (this.metricInfo?.serie.length > 1);
        for (const metricValue of this.metricInfo?.serie) {
            if (!metricValue.invalidValue) {
                continue;
            }
            let invalidRun = this.invalidRuns.get(metricValue.runId);
            if (!invalidRun) {
                if (this.invalidRuns.size >= MAX_INVALID_RUNS_TO_DISPLAY) {
                    this.additionalInvalidRunIds.add(metricValue.runId);
                    continue;
                }
                invalidRun = new InvalidRun();
                invalidRun.runId = metricValue.runId;
                invalidRun.runName = metricValue.runName;
                invalidRun.hasManyValues = hasManyValues;
                this.invalidRuns.set(metricValue.runId, invalidRun);
            }
            invalidRun.pushInvalidValue(metricValue);
        }
    }

    clickOnRunAndHidePopover(runId: string) {
        this.popover.hide();
        this.clickOnRun.emit(runId);
    }

    getRunColor(runId: string) {
        return this.colorsService.getColorForVariable(runId);
    }
}
