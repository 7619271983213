import { Component, EventEmitter, ChangeDetectionStrategy, OnChanges, Input, Output, SimpleChanges } from '@angular/core';
import { getChartNumericalAxisMaxTickLength, zipSameSize } from '@features/eda/echarts-utils';
import { CardBodyRenderingMode } from '@features/eda/worksheet/cards/body/rendering-mode';
import { filterName } from '@features/eda/pipes/filter-name.pipe';
import { ColorsService } from '@shared/graphics/colors.service';
import type { EChartsOption, SeriesOption, XAXisComponentOption } from 'echarts';
import _ from 'lodash';
import { CDFPlotCard, Filter } from 'src/generated-sources';
import { CardAction } from '../../events';

@Component({
    selector: 'cdf-plot-card-body',
    templateUrl: './cdf-plot-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/chart.less',
        './cdf-plot-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CdfPlotCardBodyComponent implements OnChanges {
    private readonly AXIS_COLOR = '#999999';
    private readonly DEFAULT_COLOR = '#1E7EFA';

    @Input() results: CDFPlotCard.CDFPlotCardResult;
    @Input() params: CDFPlotCard;
    @Output() action = new EventEmitter<CardAction>();
    @Input() hasFixedHeight: boolean;
    @Input() renderingMode: CardBodyRenderingMode;

    cdfChartOptions: EChartsOption;

    constructor(public colorsService: ColorsService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results) {
            this.cdfChartOptions = this.results && this.buildChartOptions(this.results);
        }
    }

    buildChartOptions(results: CDFPlotCard.CDFPlotCardResult): EChartsOption {
        const getGroupColor = (group: Filter) => {
            if (results.groups.length === 1) {
                return this.DEFAULT_COLOR;
            }

            const groupName = filterName(group);
            return this.colorsService.getColorForVariable(groupName);
        };

        const getGroupColors = (groups: Filter[]) => {
            if (results.groups.length === 1) {
                return [this.DEFAULT_COLOR];
            }

            return results.groups.map(getGroupColor);
        };

        const isHighlightEnabled = results.cdfs.some(it => it.isHighlighted);
        const getOpacity = (highlight: boolean) =>
            (highlight || !isHighlightEnabled) ? 1 : 0.3;

        const hasMultipleSubgroups = results.groups.length > 1 || isHighlightEnabled;
        // We choose to hide the legend when the CDF is included in a header card
        // because the chart area is too small to include the legend area.
        const showLegend = this.renderingMode === CardBodyRenderingMode.TOP_LEVEL_CARD && hasMultipleSubgroups;
        const legendItems = results.cdfs.map(it => {
            const cdfGroup = results.groups[it.groupIndex];
            let name = filterName(cdfGroup);

            if (it.isHighlighted) {
                name += " (selection)";
            } else if (isHighlightEnabled) {
                name += " (total)";
            }

            return {
                name,
                itemStyle: {
                    opacity: getOpacity(it.isHighlighted),
                }
            };
        });

        const buildSeriesOption = (cdfItem: CDFPlotCard.CDFItem, i: number): SeriesOption => {
            const cdfGroup = results.groups[cdfItem.groupIndex];

            return {
                name: legendItems[i].name,
                type: 'line',
                symbol: 'none',
                step: 'middle',
                data: zipSameSize(cdfItem.xvals, cdfItem.probs),
                lineStyle: {
                    opacity: getOpacity(cdfItem.isHighlighted),
                },
                itemStyle: {
                    color: getGroupColor(cdfGroup),
                }
            };
        };

        const getXAxis = (): XAXisComponentOption => {
            const splitXAxis = 5;

            // The xvals are already sorted for CDFs, so extracting the extrema
            // is easy.
            const allMin = results.cdfs.map(it => it.xvals[0]);
            const allMax = results.cdfs.map(it => it.xvals[it.xvals.length - 1]);

            const minX = _.min(allMin) ?? 0;
            const maxX = _.max(allMax) ?? 0;

            const maxTickLength = getChartNumericalAxisMaxTickLength(splitXAxis, minX, maxX);
            const rotate = maxTickLength > 4 ? 45 : 0;

            return {
                type: 'value',
                scale: true,
                splitNumber: splitXAxis,
                min: minX,
                max: maxX,
                axisTick: {
                    show: true,
                },
                axisLine: {
                    show: true,
                },
                axisLabel: {
                    color: this.AXIS_COLOR,
                    rotate,
                    formatter: (value: number) =>
                        value === minX || value === maxX ? '' : '' + value,
                },
            };
        };

        return {
            series: results.cdfs.map(buildSeriesOption),
            color: getGroupColors(results.groups),
            animation: false,
            legend: {
                show: showLegend,
                orient: 'vertical',
                type: 'scroll',
                right: 10,
                bottom: 30,
                data: legendItems,
            },
            tooltip: {
                trigger: 'none',
                axisPointer: {
                    type: 'cross',
                },
            },
            grid: {
                left: 10,
                top: 10,
                right: 10,
                bottom: 0,
                containLabel: true,
            },
            xAxis: getXAxis(),
            yAxis: {
                type: 'value',
                min: 0,
                max: 1,
                axisLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    color: this.AXIS_COLOR,
                },
            },
        };
    }
}
