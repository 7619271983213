import { Pipe, PipeTransform } from '@angular/core';
import { PlurifyPipe } from './text-pipes/plurify.pipe';

@Pipe({
    name: 'niceTaggableType'
})
export class NiceTaggableType implements PipeTransform {
    //Make sure to keep in sync with its angularjs version
    //TODO downgrade and replace corresponding angularjs pipe with this one

    constructor(public plurifyPipe: PlurifyPipe) { }

    dict: { [k: string]: string } = {
        'MANAGED_FOLDER': 'folder',
        'SAVED_MODEL': 'model',
        'MODEL_EVALUATION_STORE': 'evaluation store',
        'MODEL_COMPARISON': 'model comparison',
        'LAMBDA_SERVICE': 'API service',
        'SQL_NOTEBOOK': 'SQL notebook',
        'TAGGABLE_OBJECT': 'item'
    };

    plurals: { [k: string]: string } = {
        'ANALYSIS': 'analyses'
    };

    transform(input: string, count: number = 1): string {
        if (!input) return input;
        return this.plurifyPipe.transform(this.dict[input] || input.toLowerCase().replace('_', ' '), count, this.plurals[input]);
    }

}
