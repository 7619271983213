import { Injectable } from '@angular/core';
import { WorkspaceDisplayService } from '@features/workspaces/shared';
import { Workspace } from '@model-main/workspaces/workspace';
import { ObjectFilters, ObjectSort, ObjectSortProperties } from '../models';

@Injectable({
    providedIn: 'root'
})
export class WorkspaceFilteringService {

    constructor(private workspaceDisplayService: WorkspaceDisplayService) {
    }

    filterObjects(objects: Workspace.WorkspaceObject[], filters: ObjectFilters): Workspace.WorkspaceObject[] {
        return objects?.filter(object => (!filters.search.length || object.displayName.toLowerCase().includes(filters.search.toLowerCase()))
                && (!filters.types.length || filters.types.includes(this.workspaceDisplayService.getObjectType(object)))
                && (!filters.starred || object.starred)) || [];
    }

    sortObjects(objects: Workspace.WorkspaceObject[], sort: ObjectSort): Workspace.WorkspaceObject[] {
        return objects?.sort((a, b) => {
            const order = sort.reverse ? -1 : 1;
            switch (sort.by) {
                case ObjectSortProperties.DISPLAY_NAME:
                    return a.displayName && b.displayName ? a.displayName.localeCompare(b.displayName) * order : 0;
                case ObjectSortProperties.TYPE:
                    return this.workspaceDisplayService.getObjectType(a).localeCompare(this.workspaceDisplayService.getObjectType(b)) * order;
                default:
                    return 0;
            }
        }) || [];
    }

    sortAndFilterObjects(objects: Workspace.WorkspaceObject[], filters: ObjectFilters, sort: ObjectSort): Workspace.WorkspaceObject[] {
        return this.sortObjects(
            this.filterObjects(objects, filters),
            sort
        );
    }
}
