import { Component, Input, Output, OnChanges, SimpleChanges, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Insight, DataSpec, Card, CardResult, AbstractHeaderCard, GroupedCompiledCardWrapper } from 'src/generated-sources';
import { CollapsingService, NoopCollapsingService } from '@features/eda/collapsing.service';
import { CardAction, CardActionType } from '../events';
import { EventEmitter } from '@angular/core';
import { CardBodyRenderingMode } from '@features/eda/worksheet/cards/body/rendering-mode';
import { InsightContextService } from '@features/eda/insight-state/insight-context.service';
import { SampleContextService } from '@features/eda/sample-context.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { hasConfidenceLevel } from '@features/eda/utils';
import _ from 'lodash';
import { Subject, of, concat, interval } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';
import produce from 'immer';
import { moveItemInArray } from '@angular/cdk/drag-drop';

@UntilDestroy()
@Component({
    selector: 'insight-card',
    templateUrl: './insight-card.component.html',
    styleUrls: ['./insight-card.component.less'],
    providers: [
        InsightContextService,
        {
            provide: SampleContextService,
            useExisting: InsightContextService
        },
        {
            provide: CollapsingService,
            useClass: NoopCollapsingService
        }
    ]
})
export class InsightCardComponent implements OnChanges, OnDestroy {
    readonly CardBodyRenderingMode = CardBodyRenderingMode;

    @Input() insight: Insight;
    @Input() readOnly: boolean;
    @Output() updated = new EventEmitter<any>();

    error$ = this.insightContextService.getError();
    params?: Card;
    results?: CardResult;
    dataSpec?: DataSpec;

    constructor(
        private insightContextService: InsightContextService,
        changeDetectorRef: ChangeDetectorRef
    ) {
        insightContextService.getInsightData()
            .pipe(untilDestroyed(this))
            .subscribe(insightData => {
                if (insightData) {
                    this.params = insightData.params;
                    this.results = insightData.results;
                    this.dataSpec = this.adjustDataSpec(insightData.dataSpec);

                    changeDetectorRef.markForCheck();
                }
            });

        insightContextService.getUpdatedInsight()
            .pipe(untilDestroyed(this))
            .subscribe(update => this.updated.emit(update));
    }

    adjustDataSpec(param: DataSpec): DataSpec {
        const newParam = _.cloneDeep(param);
        if (!newParam.datasetSelection.latestPartitionsN) {
            newParam.datasetSelection.latestPartitionsN = 1;
        }
        return newParam;
    }

    resetError() {
        this.insightContextService.pushError();
    }

    get hasConfidenceLevel() {
        return this.params && hasConfidenceLevel(this.params);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.insight) {
            this.insightContextService.setInsight(this.insight);
        }
    }

    updateDataSpec(newDataSpec: DataSpec) {
        this.insightContextService.updateDataSpec({ dataSpec: newDataSpec, refreshOnDataSpecChange: true });
    }

    handleCardAction(action: CardAction) {
        if (action.type === CardActionType.UPDATE) {
            const unsubscribe$ = new Subject<void>();
            // let's wait for the right sample to be available
            this.insightContextService.getSample()
                .pipe(takeUntil(unsubscribe$))
                .subscribe(
                    // when sample is available, update insight
                    sample => {
                        if (_.isEqual(sample.dataSpec, this.dataSpec)) {
                            this.insightContextService.updateInsight({ params: action.newParams, dataSpec: this.dataSpec! });
                            unsubscribe$.next();
                            unsubscribe$.complete();
                        }
                    });

            concat(this.insightContextService.getDataSpecRequest().pipe(takeUntil(interval(10))), of(this.dataSpec))
                .pipe(first())
                .subscribe(
                    dataSpec => this.insightContextService.updateDataSpec({ dataSpec: dataSpec!, refreshOnDataSpecChange: false })
                );
        } else if (action.type === CardActionType.REORDER_HEADER_CARD) {
            this.insightContextService.getInsightData()
                .pipe(untilDestroyed(this), first())
                .subscribe(insightData => {
                    if (insightData) {
                        this.params = insightData.params;
                        this.results = insightData.results;
                        this.dataSpec = this.adjustDataSpec(insightData.dataSpec);

                        const newState = produce(insightData, draft => {
                            const draftCard = draft.params as AbstractHeaderCard;
                            moveItemInArray(draftCard.cards, action.previousIndex, action.currentIndex);
                            moveItemInArray(draftCard.xColumns, action.previousIndex, action.currentIndex);

                            const draftResults = draft.results;
                            if (AbstractHeaderCard.isAbstractHeaderCardResult(draftResults)) {
                                moveItemInArray(draftResults.results, action.previousIndex, action.currentIndex);
                            } else if (GroupedCompiledCardWrapper.isGroupedCardResult(draftResults)) {
                                for (const c of draftResults.results) {
                                    if (AbstractHeaderCard.isAbstractHeaderCardResult(c)) {
                                        moveItemInArray(c.results, action.previousIndex, action.currentIndex);
                                    }
                                }
                            }
                        });

                        // immediatly update what is displayed
                        this.insightContextService.updateInsightParamsResults({ card: newState.params, result: newState.results, dataSpec: this.dataSpec! })
                        // update with save
                        this.insightContextService.updateInsight({ params: newState.params, dataSpec: this.dataSpec! });
                    }
                });
        }
    }

    ngOnDestroy() {
    }
}
