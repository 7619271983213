import { Pipe, PipeTransform } from '@angular/core';
import { durationHHMMSS } from '@shared/pipes/date-pipes/date-pipes-common';

@Pipe({
    name: 'durationHHMMSS'
})
export class DurationHHMMSSPipe implements PipeTransform {

    transform(input: string|number) {
        if (typeof input == 'string') {
            input = parseInt(input, 10);
        }
        return durationHHMMSS(input / 1000);
    }

}
