import type {AbstractCardContainer, _AbstractCardContainer} from './abstract-card-container';
import type {CardResult} from './../card-result';
import type {Card} from './../card';
import type {Variable} from './../../models/variable';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.common.ColumnCard
 */
export interface ColumnCard extends _AbstractCardContainer {
    column: Variable;

    // PolyJson type
    type: 'column_card';
}

export namespace ColumnCard {
    export const type = 'column_card';
}

/** @deprecated Use Card.isColumnCard() */ export function isColumnCard(toTest: Card): toTest is ColumnCard { return ColumnCard.type === toTest['type']; }

export namespace ColumnCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.common.ColumnCard$ColumnCardResult
     */
    export interface ColumnCardResult extends AbstractCardContainer._AbstractCardContainerResult {
        column: Variable;

        // PolyJson type
        type: 'column_card';
    }

    export namespace ColumnCardResult {
        export const type = 'column_card';
    }

    /** @deprecated Use CardResult.isColumnCardResult() */ export function isColumnCardResult(toTest: CardResult): toTest is ColumnCardResult { return ColumnCardResult.type === toTest['type']; }
}