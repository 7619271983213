import type {Grouping, _Grouping} from './grouping';
import type {GroupingResult, _GroupingResult} from './grouping-result';

/**
 * Generated from com.dataiku.dip.eda.compute.grouping.SubsampledGrouping
 */
export interface SubsampledGrouping extends _Grouping {
    maxRows: number;
    seed: number;

    // PolyJson type
    type: 'subsampled';
}

export namespace SubsampledGrouping {
    export const type = 'subsampled';
}

/** @deprecated Use Grouping.isSubsampledGrouping() */ export function isSubsampledGrouping(toTest: Grouping): toTest is SubsampledGrouping { return SubsampledGrouping.type === toTest['type']; }

export namespace SubsampledGrouping {
    /**
     * Generated from com.dataiku.dip.eda.compute.grouping.SubsampledGrouping$SubsampledGroupingResult
     */
    export interface SubsampledGroupingResult extends _GroupingResult {
        // PolyJson type
        type: 'subsampled';
    }

    export namespace SubsampledGroupingResult {
        export const type = 'subsampled';
    }

    /** @deprecated Use GroupingResult.isSubsampledGroupingResult() */ export function isSubsampledGroupingResult(toTest: GroupingResult): toTest is SubsampledGroupingResult { return SubsampledGroupingResult.type === toTest['type']; }
}