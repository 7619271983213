<left-pane [isOpen]="isMenuExpanded" [hasHandle]="false" (isOpenChange)="toggleMenu($event)">
    <div header class="workspaces-left-menu__header">
        <clearable-searchbar #searchbar
            class="workspaces-left-menu__searchbar"
            [ngClass]="{ 'workspaces-left-menu__searchbar--shrunk' : !isMenuExpanded }"
            (searchTermChange)="filterWorkspaces($event)"
            [hasPrefixIcon]="true"
            [isInputDisabled]="!isMenuExpanded"
            inputPlaceholder="Filter workspaces..."
            [isClearable]="isMenuExpanded">
        </clearable-searchbar>
        <button class="workspaces-left-menu__button-toggle btn btn--text btn--secondary btn--icon" 
            (click)="this.leftPane.toggleMenu()"
            onfocus="this.blur()"
            matTooltip="{{ isMenuExpanded ? 'Close panel' : 'Open panel' }}"
            matTooltipPosition="right"
            matTooltipClass="workspaces-left-menu__icon-tooltip">
            <i [ngClass]="isMenuExpanded ? 'dku-icon-arrow-left-16' : 'dku-icon-arrow-right-16'"></i>
        </button>
    </div>
    <div class="workspaces-left-menu__body">
        <ul class="workspaces-left-menu__list">
            <li *ngFor="let workspace of workspaces; trackBy: trackWorkspace" #workspaceObject
                container="body"
                [hidden]="workspace.visible === false"
                class="workspaces-left-menu__object"
                [class.workspaces-left-menu__object--selected]="workspace.workspaceKey === selectedWorkspaceKey"
                (click)="selectWorkspace(workspace.workspaceKey)"
                [ngClass]="{ 'workspaces-left-menu__object--shrunk' : !isMenuExpanded }">
                <initials-icon
                    [ngClass]="{ 'workspaces-left-menu__icon--expanded' : !isMenuExpanded }"
                    class="workspaces-left-menu__icon"
                    [name]="workspace.displayName"
                    [color]="workspace.color"
                    matTooltip="{{workspace.displayName}}"
                    [matTooltipDisabled]="isMenuExpanded"
                    matTooltipPosition="right"
                    matTooltipClass="workspaces-left-menu__icon-tooltip"
                ></initials-icon>
                <span class="workspaces-left-menu__object-label"
                    textOverflowTooltip
                    [textTooltip]="workspace.displayName"
                    tooltipDirection="tooltip-right">
                    {{workspace.displayName}}
                </span>
            </li>
        </ul>
        <button class="btn btn--secondary btn--outline btn--icon btn--dku-icon workspaces-left-menu__add-button"
                [hidden]="!canCreateWorkspaces || isFilterApplied"
                matTooltip="Add workspace"
                [matTooltipDisabled]="isMenuExpanded"
                matTooltipPosition="right"
                matTooltipClass="workspaces-left-menu__icon-tooltip"
                (click)="onAddWorkspace()">
                    <i class="dku-icon-plus-16 workspaces-left-menu__add-button-icon" 
                        [ngClass]=" { 'workspaces-left-menu__add-button-icon--shrunk' : !isMenuExpanded }">
                    </i>
                    <span class="workspaces-left-menu__add-button-label"
                        [ngClass]=" { 'workspaces-left-menu__add-button-label--shrunk' : !isMenuExpanded }">
                        Add workspace
                    </span>
        </button>
    </div>
    <div footer class="workspaces-left-menu__footer">
        <span class="workspaces-left-menu__counter">
            {{ isFilterApplied ? resultsNumber + ' results' : workspaces.length + (isMenuExpanded ? getWorkspacesCountLabel() : ' WS')}}
        </span>
    </div>
</left-pane>
