import { Component, ChangeDetectionStrategy, Inject, ViewChild, ElementRef } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { fairAny } from 'dku-frontend-core';
import { IHeaderParams } from 'ag-grid-community';
import { DkuHeaderParams, selectEntireGrid } from '../utils';


type EnrichedHeaderParams = IHeaderParams & DkuHeaderParams;


@Component({
    selector: 'spreadsheet-column-header',
    templateUrl: './spreadsheet-column-header.component.html',
    styleUrls: ['./spreadsheet-column-header.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpreadsheetColumnHeaderComponent implements IHeaderAngularComp {
    @ViewChild('menuButton', {read: ElementRef}) public menuButton: ElementRef<HTMLDivElement>;

    public params!: EnrichedHeaderParams;

    constructor(
        @Inject('$rootScope') private $rootScope: fairAny,
        @Inject("CreateModalFromTemplate") private CreateModalFromTemplate: fairAny
    ){}

    agInit(params: EnrichedHeaderParams): void {
        this.params = params;
        // Use a pointer cursor on the whole header cell to tell the user that it is clickable
        this.params.eGridHeader.style.cursor = 'pointer';
        // Make the whole header cell clickable (otherwise only the title)
        this.params.eGridHeader.onclick = (ev: MouseEvent) => {
            // Ignore clicks on the resize button (it's not clickable)
            if ((ev.target as HTMLDivElement)?.classList.contains('ag-header-cell-resize')) return;
            this.onClick();
        };
        }

    refresh(params: EnrichedHeaderParams) {
        this.agInit(params);
        return true;
    }

    onMenuClicked(event: MouseEvent) {
        event.preventDefault();
        this.selectEntireColumn();
        this.params.showColumnMenu(this.menuButton.nativeElement);
    }

    onClick() {
        // Clear all existing selection first
        this.params.api.clearRangeSelection();
        if (this.params.isIndexCol) {
            // Select the whole table if the click is on index column header
            selectEntireGrid(this.params.api, this.params.columnApi);
        } else {
            // Only select all cells of the target column
            this.selectEntireColumn();
        }
    }

    private selectEntireColumn() {
        this.params.api.clearRangeSelection();
        this.params.api.clearFocusedCell();
        this.params.api.addCellRange({
            rowStartIndex: 0,
            rowEndIndex: this.params.api.getDisplayedRowCount() - 1, // `rowEndIndex` is included in the selection
            columns: [this.params.column]
        });
    }
}
