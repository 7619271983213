<div class="workspaces-home-page__container">
    <div class="workspaces-home-page__left-bar">
        <img [hidden]="(canCreateWorkspaces$ | async) === false"
            src="/static/dataiku/images/workspaces/new-workspace.svg" width="32" />
        <img [hidden]="canCreateWorkspaces$ | async" src="/static/dataiku/images/workspaces/workspace-error.svg"
            width="32" />
    </div>
    <div class="workspaces-home-page__content mleftright16">
        <img *ngIf="(canCreateWorkspaces$ | async) === false" class="mbot16"
            src="/static/dataiku/images/workspaces/workspace-error-big.svg" width="190" />
        <h1 class="dku-ultralarge-title-sb text-weak mtop0 text-center">Welcome to Dataiku Workspaces</h1>

        <ng-container *ngIf="canCreateWorkspaces$ | async; then authorized else forbidden"></ng-container>

        <ng-template #authorized>
            <div class="mtop16">
                <form name="newWorkspaceForm">
                    <api-error-alert [apiError]="error$ | async" (resetError)="closeError()" [closable]="true">
                    </api-error-alert>

                    <workspace-general-edition [workspace]="workspaceToCreate$ | async"
                        (workspaceChange)="onWorkspaceChange($event)">
                    </workspace-general-edition>
                </form>

                <button class="btn btn--primary mtop16 mbot16" [disabled]="(isValid$ | async) === false"
                    (click)="createWorkspace()">
                    Create workspace
                </button>
            </div>
        </ng-template>

        <ng-template #forbidden>
            <div class="text-center mtop24">
                <h3 class="dku-medium-title">
                    {{ mainMessage$ | async }}
                    <br>
                    In the meantime, you can visit the <academy-link>Dataiku Academy</academy-link> to ramp up on
                    Dataiku DSS
                </h3>
                <button ui-sref route="home" class="btn btn--primary mtop16 mbot16">
                    Go back to home page
                </button>
            </div>
        </ng-template>
    </div>
</div>
