import { Injectable } from '@angular/core';
import { DkuHttpService } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { ClusteringSMMgmtService } from 'src/generated-sources';

@Injectable({ providedIn: 'root' })
export class PrivateSavedModelsClusteringAPI {
    constructor(
        private dkuHttp: DkuHttpService
    ) { }

    getStatus(projectKey: string, savedModelId: string): Observable<ClusteringSMMgmtService.ClusteringSMStatus> {
        return this.dkuHttp.request('GET', '/savedmodels/clustering/get-status', {
            projectKey, savedModelId
        });
    }
}
