<right-panel-action
    id="qa_right_menu_share_dropdown"
    icon="dku-icon-publish-24"
    [matMenuTriggerFor]="optionsMenu"
>
    Publish<span class="caret"></span>
</right-panel-action>

<mat-menu #optionsMenu>
    <ng-template matMenuContent>
        <button mat-menu-item
            id="qa_right_menu_share_dashboard_item"
            *ngIf="showShareToDashboard"
            [class.disabled]="disableShareToDashboard"
            [disableRipple]="disableShareToDashboard"
            (click)="clickDashboard($event)"
            wt1Click="rightpaneldataset_actions_publish"
            [matTooltip]="dashboardTooltip"
            matTooltipPosition="left"
        >
            <div class="right-panel-publish__item-icon">
                <i class="icon-dku-publish"></i>
            </div>
            <div class="right-panel-publish__item-info">
                <div class="right-panel-publish__item-title">Dashboard</div>
                <div class="right-panel-publish__item-description">Showcase datasets, charts, models, webapps, etc within a project.</div>
            </div>
        </button>

        <button mat-menu-item
            id="qa_right_menu_share_workspace_item"
            *ngIf="showShareToWorkspace"
            [class.disabled]="disableShareToWorkspace"
            [disableRipple]="disableShareToWorkspace"
            (click)="clickWorkspace($event)"
            wt1Click="rightpaneldataset_actions_share_to_workspace"
            [matTooltip]="workspaceTooltip"
            matTooltipPosition="left"
        >
            <div class="right-panel-publish__item-icon">
                <i class="icon-dku-workspace"></i>
            </div>
            <div class="right-panel-publish__item-info">
                <div class="right-panel-publish__item-title">Workspace</div>
                <div class="right-panel-publish__item-description">Collaboration space to share datasets, dashboards and apps with others.</div>
            </div>
        </button>

        <button mat-menu-item
            id="qa_right_menu_add_to_feature_store"
            *ngIf="showAddToFeatureStore"
            [class.disabled]="disableAddToFeatureStore"
            [disableRipple]="disableAddToFeatureStore"
            (click)="clickFeatureStore($event)"
            wt1Click="rightpaneldataset_actions_add_to_feature_store"
            [matTooltip]="featureStoreTooltip"
            matTooltipPosition="left"
        >
                <div class="right-panel-publish__item-icon">
                    <i class="icon-dku-label-feature-store"></i>
                </div>
                <div class="right-panel-publish__item-info">
                    <div class="right-panel-publish__item-title">Feature Store</div>
                    <div class="right-panel-publish__item-description">Central registry for Feature Groups (curated and promoted datasets containing valuable features).</div>
                </div>
        </button>


        <button mat-menu-item
            id="qa_right_menu_add_to_data_collection"
            *ngIf="showAddToDataCollection"
            [class.disabled]="disableAddToDataCollection"
            [disableRipple]="disableAddToDataCollection"
            (click)="clickDataCollection($event)"
            wt1Click="rightpaneldataset_actions_add_to_data_collection"
            [matTooltip]="collectionTooltip"
            matTooltipPosition="left"
        >
            <div class="right-panel-publish__item-icon">
                <i class="dku-icon-stacked-20"></i>
            </div>
            <div class="right-panel-publish__item-info">
                <div class="right-panel-publish__item-title">Data Collection</div>
                <div class="right-panel-publish__item-description">Curated lists of datasets (by team, use case etc) for use by other designers.</div>
            </div>
        </button>
    </ng-template>
</mat-menu>
