import { Injectable } from '@angular/core';
import { BoxHelper } from '@features/deephub/object-detection/utils/box-helper';
import { ImagePositionInformation, PainterImage, DivPainterImageArtifact } from '@shared/models/painter';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { PainterService } from '@shared/services/item-feed/image-feed/painter.service';
import { DeepHubColumnFormat } from 'src/generated-sources';
import { DeephubObjectDetectionInteractiveScoringCellData } from '../interactive-scoring/object-detection-interactive-scoring.service';

@Injectable({
    providedIn: 'root'
  })
export class DeephubObjectDetectionInteractiveScoringPainterService extends PainterService {
    private STROKE_WIDTH = 2;
    private SELECTED_STROKE_WIDTH = this.STROKE_WIDTH + 2;

    constructor(
        private colorMapService: ColorMapContextService
    ) {
        super();
    }

    paintForFeed(cellData: DeephubObjectDetectionInteractiveScoringCellData, image: PainterImage, imagePosition: ImagePositionInformation): void {

        cellData.prediction.forEach((item: DeepHubColumnFormat.ObjectDetectionPredictedItem) => {
            const artifact = BoxHelper.createBox(DivPainterImageArtifact, item, imagePosition, {
                borderColor: this.colorMapService.getColor(item.category),
                borderWidth: this.STROKE_WIDTH + 'px',
                borderStyle: 'solid',
                visibility: 'visible'
            }, 'px') || new DivPainterImageArtifact({});

            image.addArtifact(artifact);
        });
    }

    toggleArtifact(image: PainterImage, index: number, selected?: boolean, show?: boolean) {
        const box = image.artifacts[index];
        if (box) {
            if (show !== undefined) {
                box.style.visibility = show ? 'visible' : 'hidden'; 
            }

            if (selected) {
                box.style.borderWidth = this.SELECTED_STROKE_WIDTH + 'px'; 
                box.style.visibility = 'visible';
            } else {
                box.style.borderWidth = this.STROKE_WIDTH + 'px';
            }
        }
    }
}
