<div
    class="details__usage_container"
    *ngFor="let project of usages.projectsWithAccess"
>
    <a
        class="project-link"
        [href]="StateUtils.href.project(project.projectKey)"
        [matTooltip]="project.projectName" isEllipsisActive
        matTooltipPosition="left"
    >
        {{project.projectName}}
    </a>
    <i
        class="dku-icon-compass-16"
        *ngIf="project.canAccess"
    ></i>
    <i
        class="dku-icon-lock-closed-16"
        *ngIf="!project.canAccess"
        matTooltip="You don't have full access to view this project"
        matTooltipPosition="left"
    ></i>
</div>
<div
    *ngIf="usages.usagesInNonAccessibleProjects > 0"
    class="details__private-projects"
    matTooltip="You don't have access to private projects"
    matTooltipPosition="left"
>
    <span>Private projects ({{usages.usagesInNonAccessibleProjects}})</span>
    <i class="dku-icon-question-circle-fill-16"></i>
</div>