import { Component, ChangeDetectionStrategy, Input, Output, ViewChild, EventEmitter, ElementRef, ContentChild, TemplateRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ItemsDataFetcherService } from '@shared/services/item-feed/items-data-fetcher.service';
import { BehaviorSubject, delay, Observable } from 'rxjs';
import { Chunk, InfiniteScrollComponent } from '../infinite-scroll/infinite-scroll.component';

@UntilDestroy()
@Component({
  selector: 'item-feed',
  templateUrl: './item-feed.component.html',
  styleUrls: ['./item-feed.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemFeedComponent {
    @Input() itemHeight: number;
    @Input() itemsPerRow: number;
    @Input() selectedId?: string | null;
    @Input() selectedIndex?: number | null;
    @Output() itemClick = new EventEmitter<any>();
    
    @ViewChild(InfiniteScrollComponent) viewport: InfiniteScrollComponent;
    @ContentChild(TemplateRef) public template: TemplateRef<{ index: number; item: any; }>;

    loadingStatus$ = new BehaviorSubject<boolean>(false);

    constructor(
        public dataFetcher: ItemsDataFetcherService
    ) { }

    getChunk = (offset: number): Observable<Chunk> => {
        return this.dataFetcher.getChunk(offset);
    }

    getChunkSize(): number {
        return this.dataFetcher.CHUNK_SIZE;
    }

    ngAfterViewInit(): void {
        this.dataFetcher.dataRefetch$.pipe(
            delay(0), // don't reset viewport if change detection hasn't finished yet
            untilDestroyed(this)
        ).subscribe(_ => {
            this.viewport.reset();
        });

        this.dataFetcher.itemUpdate$.pipe(
            untilDestroyed(this)
        ).subscribe(item => {
            this.viewport.updateItem(item);
        });
    }
}
