import { Component, ChangeDetectionStrategy, Inject, Input } from "@angular/core";
import { fairAny } from "dku-frontend-core";

@Component({
  selector: "doclink-wrapper",
  templateUrl: "./doclink-wrapper.component.html",
  styleUrls: ["./doclink-wrapper.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoclinkWrapperComponent {
  @Input() page: string;

  /**
   * Allows to disable OPALS for the link, e.g. if the link is in a modal.
   * Currently OPALS (the Help Center) doesn't play well with modals as it
   * opens on the background underneath an open modal.
   */
  @Input() enableOpals = true;

  get url() {
    return `${(this.$rootScope.versionDocRoot as string) + this.page}.html`;
  }

  constructor(
    @Inject("$rootScope") private $rootScope: fairAny,
    @Inject("OpalsService") private opalsService: fairAny
  ) {}

  async openInOpalsIfEnabled(event: MouseEvent) {
    if(this.enableOpals) {
      event.preventDefault();

      if (await this.opalsService.isEnabled()) {
        this.opalsService.navigateToAndShowDrawer(this.opalsService.PAGES.EMBEDDED_BROWSER, { href: this.url });
      }
      else {
        window.open(this.url, '_blank');
      }
    }
  }
}
