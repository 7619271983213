import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { DashboardOptions } from './dashboard-options.model';

@Directive({
  selector: 'dashboard-page'
})
export class DashboardPageDirective extends UpgradeComponent  {
  @Input()
  options: DashboardOptions;

  constructor(elementRef: ElementRef, injector: Injector) {
      super('ng1DashboardPage', elementRef, injector);
  }
}
