<p>
    The {{ params.type === 'pairwise_mood' ? 'pairwise' : '' }} Mood test is a nonparametric test that tests the hypothesis that the median of
    <b>{{ params.xColumn.name }}</b>
    is identical
    <ng-container *ngIf="isPairwise">in every pair of populations.</ng-container>
    <ng-container *ngIf="!isPairwise">
        <ng-container *ngIf="hasTwoSamples else nSamp">in both populations.</ng-container>
        <ng-template #nSamp>in all populations.</ng-template>
    </ng-container>
</p>

<p>
    <list-compared-populations [params]="params"></list-compared-populations>
</p>

<p>
    There are two possible outcomes depending on the {{ params.type === 'pairwise_mood' ? '𝘱-values' : '𝘱-value' }}:
</p>

<ul>
    <li>
        <b>
            𝘱-value &le; significance level (&le; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>
        <br>
        The hypothesis is rejected. the
        median <b>{{ params.xColumn.name }}</b>
        is not the same
        <ng-container *ngIf="isPairwise">for this pair of populations.</ng-container>
        <ng-container *ngIf="!isPairwise">
            <ng-container *ngIf="hasTwoSamples else nSamp">in both populations.</ng-container>
            <ng-template #nSamp>in all populations.</ng-template>
        </ng-container>
    </li>
    <li>
        <b>
            𝘱-value is &gt; significance level (&gt; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>

        <br>
        Not enough evidence against the hypothesis. Test is inconclusive.
    </li>
</ul>

<p>
    It has been shown that the conclusion is meaningful under the following conditions:
</p>
<ul>
    <li>
        Observations of
        <b>{{ params.xColumn.name }}</b>
        are independent and identically distributed
    </li>
    <li>
        Distributions of
        <b>{{ params.xColumn.name }}</b>
        have the same shape in all populations
    </li>
</ul>

<p>
    <span class="text-weak">
        <i class="icon-info-sign"></i>
        Missing values and values which are equal to the grand median are discarded
    </span>
    <br>
    <span class="text-weak">
        <i class="icon-info-sign"></i>
        &ngsp;
        <a
            href="https://docs.scipy.org/doc/scipy/reference/generated/scipy.stats.median_test.html"
            target="_blank"
        >More information
        </a>
    </span>
</p>
