import { Injectable } from '@angular/core';
import { DeephubInteractiveScoringCellData, InteractiveScoringService } from '@features/deephub/computer-vision/report/interactive-scoring/interactive-scoring-data.service';
import { DeepHubColumnFormat } from 'src/generated-sources';

export class DeephubObjectDetectionInteractiveScoringCellData extends DeephubInteractiveScoringCellData {

    prediction: DeepHubColumnFormat.ObjectDetectionPredictedItem[];
    base64Heatmap: string | null;

    public setScore(modelResponse: any) {
        this.score = modelResponse;
        this.prediction = this.score.prediction;
    }
    public setExplanation(explanations: any): void {
        throw new Error("Explainability not implemented for Object detection");
    }
}

@Injectable()
export class ObjectDetectionInteractiveScoringService extends InteractiveScoringService {
    createCellData(image: string, file: string) {
        return new DeephubObjectDetectionInteractiveScoringCellData(image, file);
    }
    getNbExplanations (): number {
        return 0;  // only compute scoring, not explanations
    }
}
