import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DkuBsSelectComponent} from './selection/dku-bs-select/dku-bs-select.component';
import {AngularjsDkuBsSelectDirective} from '@app/widgets/selection/dku-bs-select/angularjs-dku-bs-select.directive';
import { DkuPopoverTriggerDirective } from './dropdowns/dku-popover-trigger.directive';
import { DkuPopoverComponent } from './dropdowns/dku-popover/dku-popover.component';
import { DkuBsSelectFormControlComponent } from '@shared/dku-bs-select-form-control/dku-bs-select-form-control.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { AngularjsDatasetSelectionOrderingDirective } from '@shared/components/dataset-selection-ordering-fields/angularjs-dataset-selection-ordering.directive';
import { DatasetSelectionOrderingFieldsComponent } from '@shared/components/dataset-selection-ordering-fields/dataset-selection-ordering-fields.component';
import { DatasetSelectorComponent } from './selection/dataset-selector/dataset-selector.component';
import { AngularjsDatasetSelectorDirective } from './selection/dataset-selector/angularjs-dataset-selector.directive';
import { DatasetSelectorFormControlComponent } from './selection/dataset-selector/dataset-selector-form-control.component';

@NgModule({
    declarations: [
        DkuBsSelectComponent,
        AngularjsDkuBsSelectDirective,
        AngularjsDatasetSelectionOrderingDirective,
        AngularjsDatasetSelectorDirective,
        DkuPopoverTriggerDirective,
        DkuPopoverComponent,
        DkuBsSelectFormControlComponent,
        DatasetSelectionOrderingFieldsComponent,
        DatasetSelectorComponent,
        DatasetSelectorFormControlComponent
    ],
    imports: [
        CommonModule,
        OverlayModule
    ],
    exports: [
        DkuBsSelectComponent,
        DkuPopoverTriggerDirective,
        DkuPopoverComponent,
        DkuBsSelectFormControlComponent,
        DatasetSelectionOrderingFieldsComponent,
        DatasetSelectorComponent,
        DatasetSelectorFormControlComponent
    ]
})
export class WidgetsModule {
}
