/**
 * Generated from com.dataiku.dip.pivot.backend.model.AxisSortPrune
 */
export interface AxisSortPrune {
    aggregationSortId: number;
    filters: AxisSortPrune.MeasureFilter[];
    generateOthersCategory: boolean;
    maxValues: number;
    sortAscending: boolean;
    sortType: AxisSortPrune.SortType;
}

export namespace AxisSortPrune {
    /**
     * Generated from com.dataiku.dip.pivot.backend.model.AxisSortPrune$MeasureFilter
     */
    export interface MeasureFilter {
        maxValue: number;
        measureFilterId: number;
        minValue: number;
    }

    /**
     * Generated from com.dataiku.dip.pivot.backend.model.AxisSortPrune$SortType
     */
    export enum SortType {
        NATURAL = 'NATURAL',
        AGGREGATION = 'AGGREGATION',
        COUNT = 'COUNT'
    }
}