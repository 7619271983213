import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.StdDev
 */
export interface StdDev extends Computation._UnivariateComputation {
    // PolyJson type
    type: 'std_dev';
}

export namespace StdDev {
    export const type = 'std_dev';
}

/** @deprecated Use Computation.isStdDev() */ export function isStdDev(toTest: Computation): toTest is StdDev { return StdDev.type === toTest['type']; }

export namespace StdDev {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.StdDev$StdDevResult
     */
    export interface StdDevResult extends _AvailableResult {
        value: number;

        // PolyJson type
        type: 'std_dev';
    }

    export namespace StdDevResult {
        export const type = 'std_dev';
    }

    /** @deprecated Use ComputationResult.isStdDevResult() */ export function isStdDevResult(toTest: ComputationResult): toTest is StdDevResult { return StdDevResult.type === toTest['type']; }
}