import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { CardAction } from "@features/eda/worksheet/cards/events";
import { ACFPlotCard } from "src/generated-sources";

@Component({
    selector: 'acf-plot-card-help',
    templateUrl: './acf-plot-card-help.component.html',
    styleUrls: ['./acf-plot-card-help.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AcfPlotCardHelpComponent {
    @Input() params: ACFPlotCard;
    @Input() readOnly: boolean;
    @Input() extendedActions: boolean;
    @Output() action = new EventEmitter<CardAction>();
}
