import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Workspace } from '@model-main/workspaces/workspace';
import { ITaggingService } from 'generated-sources';
import { WorkspaceObjectType, WorkspaceDisplayService, WorkspaceListType, WorkspaceNonTaggableType, WorkspaceSecurityService } from '../../../../shared';

export interface WorkspaceObjectStarEvent {
    status: boolean;
    object: Workspace.WorkspaceObject;
}

@Component({
    selector: 'workspace-object-list-element',
    templateUrl: './workspace-object-list-element.component.html',
    styleUrls: ['./workspace-object-list-element.component.less'],
    host: { '[class]': 'displayType' },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceObjectListElementComponent implements OnChanges {

    readonly WorkspaceListType = WorkspaceListType;
    readonly TaggableType = ITaggingService.TaggableType;
    readonly WorkspaceNonTaggableType = WorkspaceNonTaggableType;

    link?: { href: string, target: string, features: string };
    type: WorkspaceObjectType;
    background: string;
    openExternally: boolean;
    canEditObject: boolean;

    @Input() workspaceKey: string;
    @Input() object: Workspace.WorkspaceObject;
    @Input() displayType = WorkspaceListType.LIST;
    @Input() editable = false;
    @Output() duplicate = new EventEmitter<Workspace.WorkspaceObject>();
    @Output() remove = new EventEmitter<Workspace.WorkspaceObject>();
    @Output() toggleStar = new EventEmitter<WorkspaceObjectStarEvent>();

    transition = {
        'workspace-object-list-element--transitioning': false
    };

    constructor(
        private workspaceDisplayService: WorkspaceDisplayService,
        private workspaceSecurityService: WorkspaceSecurityService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        const link = this.workspaceDisplayService.getObjectLink(this.workspaceKey, this.object);
        if (link) {
            this.link = { ...link, features: link.features.join(' ') };
        }
        this.type = this.workspaceDisplayService.getObjectType(this.object);
        this.background = this.workspaceDisplayService.getObjectColor(this.object, true);
        this.openExternally = this.workspaceDisplayService.shouldOpenExternally(this.object);
        this.canEditObject = this.editable && (this.type !== WorkspaceNonTaggableType.STORY || (this.type === WorkspaceNonTaggableType.STORY && this.workspaceSecurityService.isDatastoryPossible()));
        if (changes.displayType) {
            this.transition['workspace-object-list-element--transitioning'] = true;
        }
    }

    onToggleStar(nextStatus: boolean): void {
        this.toggleStar.emit({
            status: nextStatus,
            object: this.object
        });
    }

    onDuplicate(): boolean {
        this.duplicate.emit(this.object);
        return false;
    }

    onRemove(): boolean {
        this.remove.emit(this.object);
        return false;
    }

}
