import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { Observable, Subscription, ReplaySubject } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { ProjectsService } from '@shared/services/projects.service';


/**
 * Evaluate the string piped to the pipe as a canXXX in UIProject
 *
 * If the string does not match any of them, false is returned. Else, the 
 * value of the attribute is returned.
 */

@Pipe({
    name: 'dkuEvaluatePermissions',
    pure: false // see https://angular.io/guide/pipes#the-impure-asyncpipe
})
export class DkuEvaluatePermissionsPipe extends AsyncPipe implements PipeTransform {
    evaluationResult = false;
    subscription: Subscription;
    private valueSubject = new ReplaySubject<string>(1);
    private value$: Observable<boolean>;

    constructor(cdr: ChangeDetectorRef, private projectsService: ProjectsService) {
        super(cdr);
        this.value$ = this.valueSubject.asObservable().pipe(
            distinctUntilChanged(),
            switchMap((permission: string) => this.projectsService.currentProjectSummary$
                .pipe(
                    map((currentProjectSummary) => {
                        try {
                            let evaluatedExpr = false;
                            if (currentProjectSummary && permission in currentProjectSummary.object) {
                                evaluatedExpr = (currentProjectSummary.object as any)[permission] as boolean;
                            }
                            return evaluatedExpr;
                        } catch (e) {
                            throw new Error(`Error while evaluating permission '${permission}': ${e}`);
                        }
                    })
                ))
        );
    }
    // takes no args, so second parameter omitted
    transform(permissionValue: any): any {
        this.valueSubject.next(permissionValue);
        return super.transform(this.value$);
    }
}
