import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@shared/pipes/pipes.module';
import { InitialsIconComponent } from './initials-icon.component';

@NgModule({
    declarations: [
        InitialsIconComponent
    ],
    imports: [
        CommonModule,
        PipesModule
    ],
    exports: [
        InitialsIconComponent
    ]
})
export class InitialsIconModule { }
