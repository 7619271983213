import { Directive, Input, Injector, ElementRef } from "@angular/core";
import { UpgradeComponent } from '@angular/upgrade/static';
import { StreamableDatasetSelection } from 'src/generated-sources';
import { fairAny } from 'dku-frontend-core';

@Directive({
    selector: 'angularjs-dataset-selection-ordering'
})
export class AngularjsDatasetSelectionOrderingDirective extends UpgradeComponent {
    @Input() selection: StreamableDatasetSelection;
    @Input() datasetSupportsReadOrdering: fairAny;
    @Input() shakerState: fairAny;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('ng1DatasetSelectionOrderingDirective', elementRef, injector);
    }
}