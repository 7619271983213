import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.Kurtosis
 */
export interface Kurtosis extends Computation._UnivariateComputation {
    // PolyJson type
    type: 'kurtosis';
}

export namespace Kurtosis {
    export const type = 'kurtosis';
}

/** @deprecated Use Computation.isKurtosis() */ export function isKurtosis(toTest: Computation): toTest is Kurtosis { return Kurtosis.type === toTest['type']; }

export namespace Kurtosis {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.Kurtosis$KurtosisResult
     */
    export interface KurtosisResult extends _AvailableResult {
        value: number;

        // PolyJson type
        type: 'kurtosis';
    }

    export namespace KurtosisResult {
        export const type = 'kurtosis';
    }

    /** @deprecated Use ComputationResult.isKurtosisResult() */ export function isKurtosisResult(toTest: ComputationResult): toTest is KurtosisResult { return KurtosisResult.type === toTest['type']; }
}