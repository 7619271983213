import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { DataCollectionsService } from '@features/data-collections/shared/services/data-collections.service';

import { fairAny } from 'dku-frontend-core';
import { catchAPIError } from '@core/dataiku-api/api-error';
import { WaitingService } from '@core/overlays/waiting.service';
import { map, shareReplay, noop } from 'rxjs';
import { ErrorContextService } from '@shared/services/error-context.service';
import { sortDataCollections } from '@features/data-collections/shared/models/data-collections-filter.model';
import { LocalStorageSortingService, SortingParams, } from '@features/data-collections/shared/services/data-collections-sorting.service';
import { DataSourcesTab } from '../data-sources/shared/models';
import { DataCollectionsModalService } from '@features/data-collections/shared/services/data-collections-modal.service';
import { DataCatalogService } from '../data-catalog.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';


@UntilDestroy()
@Component({
    selector: 'dss-data-catalog-home',
    templateUrl: './data-catalog-home.component.html',
    providers: [ErrorContextService],
    styleUrls: ['./data-catalog-home.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataCatalogHomeComponent {
    hasIndexedConnections: boolean = true;

    sortByParams: SortingParams;
    DataSourcesTab = DataSourcesTab;
    canCreateDataCollections = this.dataCollectionsService.mayCreateDataCollections();

    constructor(
        @Inject("$state") public $state: fairAny,
        @Inject('$rootScope') public $rootScope: fairAny,
        private waitingService: WaitingService,
        private dataCollectionsService: DataCollectionsService,
        private localStorageSortingService: LocalStorageSortingService,
        private errorContextService: ErrorContextService,
        private dataCollectionsModal: DataCollectionsModalService,
        public dataCatalogService: DataCatalogService
    ) {
        this.sortByParams = this.localStorageSortingService.getSortingParams();
        this.dataCatalogService.hasIndexedConnections
            .pipe(untilDestroyed(this))
            .subscribe((v) => this.hasIndexedConnections = v);
    }

    items$ = this.dataCollectionsService.listWithDetails().pipe(
        map((dataCollections) => dataCollections.sort(sortDataCollections(this.sortByParams.sortBy, this.sortByParams.sortOrder))),
        catchAPIError(this.errorContextService),
        this.waitingService.bindSpinner(),
        shareReplay(1),
    );

    openCollectionPage(id: string) {
        this.$state.go('^.datacollections.datacollection', { dataCollectionId: id });
    }

    getDataSourcesRoute(tab: DataSourcesTab) {
        return `^.datasources({selectedTab:'${tab.toLowerCase()}'})`;
    }

    createDataCollection(): void {
        this.dataCollectionsModal.openCreationModal().then((id) => {
            if(id !== undefined) {
                this.openCollectionPage(id);
            }
        }, noop);
    }
}
