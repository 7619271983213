import type {AxisDef} from './axis-def';

export namespace Aggregation {
    /**
     * Generated from com.dataiku.dip.pivot.backend.model.Aggregation$ComputeMode
     */
    export enum ComputeMode {
        NORMAL = 'NORMAL',
        PERCENTAGE = 'PERCENTAGE',
        AVG_RATIO = 'AVG_RATIO',
        CUMULATIVE = 'CUMULATIVE',
        CUMULATIVE_PERCENTAGE = 'CUMULATIVE_PERCENTAGE',
        DIFFERENCE = 'DIFFERENCE'
    }

    /**
     * Generated from com.dataiku.dip.pivot.backend.model.Aggregation$Function
     */
    export enum Function {
        COUNT = 'COUNT',
        COUNTD = 'COUNTD',
        SUM = 'SUM',
        AVG = 'AVG',
        MAX = 'MAX',
        MIN = 'MIN',
        DISTINCT = 'DISTINCT'
    }
}

/**
 * Generated from com.dataiku.dip.pivot.backend.model.Aggregation
 */
export interface Aggregation {
    column: string;
    computeMode: Aggregation.ComputeMode;
    computeModeDim: number;
    function: Aggregation.Function;
    id: string;
    type: AxisDef.Type;
}