import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'resolution-dialog',
  templateUrl: './resolution-dialog.component.html',
  styleUrls: ['./resolution-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResolutionDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<any>
    ) { }

    ngOnInit(): void {
    }

}
