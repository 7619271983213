// Describes how the card body should be rendered.
export enum CardBodyRenderingMode {
    // The card is embedded in larger header cards.
    STAT_CARD,

    // The card is a top level card within the worksheet.
    TOP_LEVEL_CARD,

    // The assisted data exploration wizard uses miniature cards.
    MINIATURE_CARD
}
