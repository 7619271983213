import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'colored-pvalue',
    templateUrl: './colored-pvalue.component.html',
    styleUrls: ['./colored-pvalue.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColoredPValueComponent {
    @Input() pvalue: number;
    @Input() significanceLevel: number;
    @Input() showLabel: boolean;

    get label() {
        return this.showLabel ? '𝘱-value:' : ''
    }
}
