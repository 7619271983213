/**
 * Generated from com.dataiku.dip.containers.exec.ContainerExecSelection
 */
export interface ContainerExecSelection {
    containerConf?: string | null;
    containerMode: ContainerExecSelection.ContainerExecMode;
}

export namespace ContainerExecSelection {
    /**
     * Generated from com.dataiku.dip.containers.exec.ContainerExecSelection$ContainerExecMode
     */
    export enum ContainerExecMode {
        INHERIT = 'INHERIT',
        EXPLICIT_CONTAINER = 'EXPLICIT_CONTAINER',
        NONE = 'NONE'
    }
}