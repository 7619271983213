import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, OnDestroy, forwardRef } from '@angular/core';
import { BinningMode } from 'src/generated-sources';
import { ControlValueAccessor, FormBuilder, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';



function validateBinningBoundaries(control: AbstractControl): ValidationErrors | null {
    if (!control.value || control.value.length < 1) {
        return { badBinningBoundary: 'Should have a least one value' };
    }
    return null;
}

function adjustCustomBinningValidators(
    binningMode: BinningMode,
    customBinningBoundariesControl: AbstractControl, maxValuesControl: AbstractControl,
    groupWithOthersControl?: AbstractControl): void {
    switch (binningMode) {
        case BinningMode.AUTO:
        case BinningMode.FIXED_NB:
            customBinningBoundariesControl.clearValidators();
            maxValuesControl.setValidators([
                Validators.required,
                Validators.min(1),
                Validators.max(100000) // Prevent stupidity
            ]);
            if (groupWithOthersControl) {
                groupWithOthersControl.setValidators([Validators.required]);
            }
            break;
        case BinningMode.CUSTOM:
            maxValuesControl.clearValidators();
            if (groupWithOthersControl) {
                groupWithOthersControl.clearValidators();
            }
            customBinningBoundariesControl.setValidators([validateBinningBoundaries]);
            break;
        default:
            throw new Error('Unhandled binning mode');
    }
    customBinningBoundariesControl.updateValueAndValidity();
    maxValuesControl.updateValueAndValidity();
    if (groupWithOthersControl) {
        groupWithOthersControl.updateValueAndValidity();
    }
}

export class BinningConfig {
    binningMode: BinningMode;
    groupWithOthers: boolean;
    maxValues: number | null;
    customBinningBoundaries: number[];

}

class BinningModeConfig {
    key: BinningMode;
    label: string;
}

export const BINNING_MODES: BinningModeConfig[] = [{
    key: BinningMode.AUTO,
    label: 'Max nb. of bins'
}, {
    key: BinningMode.FIXED_NB,
    label: 'Fixed nb. of bins'
}, {
    key: BinningMode.CUSTOM,
    label: 'Custom bins'
}
];

@UntilDestroy()
@Component({
    selector: 'binning-config',
    templateUrl: './binning-config.component.html',
    styleUrls: [
        '../../../../shared-styles/forms.less',
        './binning-config.component.less'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BinningConfigComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => BinningConfigComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class BinningConfigComponent implements ControlValueAccessor, OnChanges, OnDestroy {
    @Input() isCategorical: boolean;
    @Input() binningModes: BinningMode[];

    BinningMode = BinningMode;

    supportedBinningModes: BinningModeConfig[] = BINNING_MODES;

    configForm = this.fb.group({
        binningMode: this.fb.control(null, [Validators.required]),
        maxValues: this.fb.control(null),
        groupWithOthers: this.fb.control(null),
        customBinningBoundaries: this.fb.control(null)
    });


    onChange: Function;
    onTouched: Function;

    constructor(
        private fb: FormBuilder
    ) {
        this.configForm.valueChanges.pipe(untilDestroyed(this))
        .subscribe(formValue => {
            if (this.onChange) {
                this.onChange({
                    binningMode: formValue.binningMode,
                    maxValues: formValue.maxValues,
                    groupWithOthers: formValue.groupWithOthers,
                    customBinningBoundaries: formValue.customBinningBoundaries
                });
            }
        });

        const maxValuesControl = this.configForm.controls.maxValues;
        const groupWithOthersControl = this.configForm.controls.groupWithOthers;
        const binningModeControl = this.configForm.controls.binningMode;
        const customBinningBoundariesControl = this.configForm.controls.customBinningBoundaries;

        binningModeControl.valueChanges.subscribe(
            binningMode => { adjustCustomBinningValidators(binningMode, customBinningBoundariesControl,
                maxValuesControl, this.isCategorical ? groupWithOthersControl : undefined); }
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.binningModes) {
            this.supportedBinningModes = BINNING_MODES.filter(bm => this.binningModes.includes(bm.key));
        }
    }

    writeValue(param: BinningConfig): void {
        this.configForm.patchValue({
            maxValues: param.maxValues,
            groupWithOthers: param.groupWithOthers,
            binningMode: param.binningMode,
            customBinningBoundaries: param.customBinningBoundaries
        });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.configForm.disable() : this.configForm.enable();
    }

    validate(c: AbstractControl): ValidationErrors | null{
        return this.configForm.valid ? null : { invalidForm: {valid: false, message: "Binning config is invalid"}};
    }

    ngOnDestroy() {
    }
}
