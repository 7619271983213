import { Inject, Injectable } from '@angular/core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { WT1Service } from 'dku-frontend-core';
import { TaggableObjectsService } from '@model-main/server/services/taggable-objects-service';
import { Observable } from 'rxjs';
import { UIState } from '../models';
import { InterestsInternalDB } from 'src/generated-sources';

// Duplicated from user-profile.js
@Injectable({
    providedIn: 'root'
})
export class InterestsService {
    public readonly WORDING = {
        labels: {
            WATCH: 'Watch',
            UNWATCH: 'Unwatch',
            STAR: 'Star',
            UNSTAR: 'Unstar',
        },
        tooltips: {
            WATCH: 'Receive notifications when this object changes',
            UNWATCH: 'Stop receiving notifications when this object changes',
            STAR: 'Mark this object as favorite - favorites can be easily filtered/searched in various places, including your profile',
            UNSTAR: 'Remove this object from your list of favorites',

            plural: {
                WATCH: 'Receive notifications when these objects change',
                UNWATCH: 'Stop receiving notifications when these objects change',
                STAR: 'Mark these objects as favorites - favorites can be easily filtered/searched in various places, including your profile',
                UNSTAR: 'Remove these objects from your list of favorites',
            },
        },
    } as const;

    constructor(
        private WT1: WT1Service,
        private dataikuAPI: DataikuAPIService,
        @Inject('$state') private $state: UIState,
    ) {}

    watch(taggableObjects: TaggableObjectsService.TaggableObjectRef[], watch: boolean | InterestsInternalDB.Watching): Observable<void> {
        // Note: watch is not a boolean, please see WatchInterestState
        let w;
        if (watch === true) {
            w = InterestsInternalDB.Watching.YES;
        } else if (watch === false) {
            w = InterestsInternalDB.Watching.ENO;
        } else {
            w = watch;
        }

        this.WT1.event('watch-object', { watch: w, state: this.$state.current.name, objects: taggableObjects.length });
        return this.dataikuAPI.interests.watch(taggableObjects, w);
    }

    star(taggableObjects: TaggableObjectsService.TaggableObjectRef[], star: boolean) : Observable<void> {
        this.WT1.event('star-object', { star, state: this.$state.current.name, objects: taggableObjects.length });
        return this.dataikuAPI.interests.star(taggableObjects, star);
    }

    isShallowWatching = (state: InterestsInternalDB.Watching) => InterestsInternalDB.Watching.SHALLOW == state;
    isFullyWatching = (state: InterestsInternalDB.Watching) => InterestsInternalDB.Watching.YES == state;
    isWatching = (state: InterestsInternalDB.Watching) => this.isFullyWatching(state) || this.isShallowWatching(state);
}
