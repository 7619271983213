<div class="dku-grand-title">Labeler performance</div>
<table class="table labeling-overview-insight__table">
    <thead>
        <tr>
            <th width="25%">Labeler</th>
            <th [attr.width]="(75 - numMetrics * 10) + '%'" class="table__status-col-header">
                <span><span class="labeling-color-dot labeling-color-dot--reviewed"></span>Reviewed</span>
                <span class="mleft8"><span class="labeling-color-dot labeling-color-dot--partially-labeled"></span>Labeled</span>
            </th>
            <ng-template [ngTemplateOutlet]="headerTemplateRef"></ng-template>
        </tr>
    </thead>
    <ng-container *ngIf="{
            allUsersByLogin: labelingService.allUsersByLogin$ | async,
            numRecordsLabeledByBiggestAnnotator: numRecordsLabeledByBiggestAnnotator$ | async 
        } as ctx">
        <tbody *ngIf="ctx.allUsersByLogin">
            <tr *ngIf="ctx.numRecordsLabeledByBiggestAnnotator === 0">
                <td [attr.colspan]="2 + numMetrics" class="labeling-overview-insight__empty-state-table">
                    No annotations have been made yet
                </td>
            </tr>
            <ng-container *ngIf="ctx.numRecordsLabeledByBiggestAnnotator && ctx.numRecordsLabeledByBiggestAnnotator > 0">
                <tr *ngFor="let annotatorStat of perAnnotatorStats | keyvalue: sortByBiggerLabeler; let index = index;">
                    <ng-container *ngIf="annotatorStat.value as annotatorStats">
                        
                        <td class="labeling-overview-insight__name-col"
                            title="{{annotatorStat.key | formattedAnnotatorName: ctx.allUsersByLogin}}">
                            <user-picture class="mright4" [user]="annotatorStat.key" [size]="24"></user-picture>
                            {{ annotatorStat.key | formattedAnnotatorName: ctx.allUsersByLogin }}
                        </td>
                        <td>
                            <div class="progress-bar-container">
                                <div class="labeler-progress-bar progress-bar--reviewed"
                                    [matTooltip]="annotatorStats.numReviewed +' ('+ ((annotatorStats.numReviewed / annotatorStats.numTotal)| percent: '1.0-1') +')' "
                                    matTooltipPosition="above" matTooltipClass="progress-chart-tooltip"
                                    [ngStyle]="{ 'width': 100 * annotatorStats.numReviewed / ctx.numRecordsLabeledByBiggestAnnotator + '%' }">
                                </div>
                                <div class="labeler-progress-bar progress-bar--partially-labeled"
                                    [matTooltip]="annotatorStats.numLabeled +' ('+ ((annotatorStats.numLabeled / annotatorStats.numTotal)| percent: '1.0-1') +')'"
                                    matTooltipPosition="above"
                                    [ngStyle]="{ 'width': 100 * annotatorStats.numLabeled / ctx.numRecordsLabeledByBiggestAnnotator + '%' }">
                                </div>
                            </div>
                        </td>
                        <ng-template [ngTemplateOutlet]="dataTemplateRef" [ngTemplateOutletContext]="{ metrics: annotatorStats.metrics}"></ng-template>
                    </ng-container>
                </tr>
            </ng-container>
        </tbody>
    </ng-container>

</table>
