import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MannKendallTest, MannKendallTestCard } from "src/generated-sources";

@Component({
    selector: 'mann-kendall-test-card-body',
    templateUrl: './mann-kendall-test-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/test-conclusion.less',
        '../../../../shared-styles/stats-table.less',
        '../../../../shared-styles/card-layout.less',
        './mann-kendall-test-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MannKendallTestCardBodyComponent {
    @Input() params: MannKendallTestCard;
    @Input() results: MannKendallTestCard.MannKendallTestCardResult;

    get isRejected(): boolean {
        return this.results.pValue <= (1 - this.params.confidenceLevel);
    }

    get trendLabel(): string {
        switch (this.results.trend) {
            case MannKendallTest.Trend.NO_TREND:
                return "no monotonic trend";
            case MannKendallTest.Trend.DECREASING:
                return "a decreasing trend";
            case MannKendallTest.Trend.INCREASING:
                return "an increasing trend";
        }
    }
}
