import { Component, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { fairAny } from 'dku-frontend-core';
import { TaggableObjectsService } from 'generated-sources';

@Component({
    selector: 'right-panel-details-related-elements',
    templateUrl: './right-panel-details-related-elements.component.html',
    styleUrls: ['./right-panel-details-related-elements.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightPanelDetailsRelatedElementsComponent {
    @Input() objects!: TaggableObjectsService.TaggableObjectRefWithName[];

    constructor(
        @Inject('StateUtils') public StateUtils: fairAny,
    ) { }

    public getObjectLink(projectKey: string, objectType: string, objectId: string) {
        return this.StateUtils.href.dssObject(objectType, objectId, projectKey);
    }
}
