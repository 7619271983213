<ng-container *ngIf="params.knownStdDev == null; else ztest">
    <p>
        The one-sample 𝘵-test is a statistical procedure used to determine whether your data
        could have been drawn from a population where the mean of
        <b>{{ params.column.name }}</b>
        is
        <b>
            {{params.hypothesizedMean | smarterNumber}}
        </b>.
    </p>
    <p>
        There are two possible outcomes depending on the 𝘱-value:
    </p>
    <ul>
        <li>
            <b>
                𝘱-value &le; significance level (&le; {{ 1 - params.confidenceLevel | smarterNumber }})
            </b>
            <br>
            The hypothesis is rejected.
            The population mean of
            <b>{{ params.column.name }}</b>
            is different from {{params.hypothesizedMean | smarterNumber}}.
        </li>
        <li>
            <b>
                𝘱-value is &gt; significance level (&gt; {{ 1 - params.confidenceLevel | smarterNumber }})
            </b>

            <br>
            Not enough evidence against the hypothesis. Test is inconclusive.
        </li>
    </ul>
    <p>
        It has been shown that the conclusion is meaningful under the following conditions:
    </p>
    <ul>
        <li>
            Observations of
            <b>{{ params.column.name }}</b>
            are independent and identically distributed
        </li>
        <li>
            Variable
            <b>{{ params.column.name }}</b>
            is normally distributed
            <br>
            <span
                class="text-weak"
                *ngIf="canAddCard"
            >
                <i class="icon-lightbulb"></i>
                You may want to check this assumption using a
                <a (click)="addShapiro()">Shapiro-Wilk test</a>
            </span>
        </li>
    </ul>
    <p>
        <span class="text-weak">
            <i class="icon-info-sign"></i>
            Missing values are discarded
        </span>
    </p>
</ng-container>
<ng-template #ztest>
    NOT IMPLEMENTED
    <!-- POSTPONED AFTER EDA V1 RELEASE -->
</ng-template>
