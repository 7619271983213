import { Pipe, PipeTransform } from "@angular/core";

// These formats may be ambiguous (what is a year, a month in terms of days?)
// We use them only to express simple durations (n years, n months)
const regex_year = /P(\d+)Y/;
const regex_month = /P(\d+)M/;
// The unambiguous format
const regex_iso = /P(\d+)DT(\d+)H(\d+)M(\d+(\.\d+)?)S/;

export function iso8601Duration(isoDuration: string): string {
    if (isoDuration == null) {
        return '';
    }

    const year_match = isoDuration.match(regex_year);
    if (year_match != null) {
        const years = Number(year_match[1]);
        return years === 1 ? '1 year' : `${years} years`;
    }

    const month_match = isoDuration.match(regex_month);
    if (month_match != null) {
        const months = Number(month_match[1]);
        return months === 1 ? '1 month' : `${months} months`;
    }

    const match = isoDuration.match(regex_iso);
    if (match == null) {
        return '';
    }

    const days = match[1];
    const hours = match[2].padStart(2, '0');
    const minutes = match[3].padStart(2, '0');

    // it may contain ms, µs, ns...
    const [wholeSeconds, subSeconds] = match[4].split('.');
    const seconds = subSeconds != null ?
        wholeSeconds.padStart(2, '0') + "." + subSeconds :
        wholeSeconds.padStart(2, '0');

    const result = `${hours}:${minutes}:${seconds}`;
    const daysOnly = result === '00:00:00';

    switch (days) {
        case '0':
            return result;
        case '1':
            return daysOnly ? '1 day' : `1 day ${result}`;
        default:
            return daysOnly ? `${days} days` : `${days} days ${result}`;
    }
}

@Pipe({
    name: 'iso8601Duration',
})
export class Iso8601DurationPipe implements PipeTransform {
    transform(isoDuration: string): string {
        return iso8601Duration(isoDuration);
    }
}
