import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CardWizardVariable } from '@features/eda/card-models';
import { Variable } from 'src/generated-sources';

interface SelectableItem {
    displayName: string;
    variable?: CardWizardVariable;
    fakeDisabled: boolean;
}

function toSelectableItem(variable: CardWizardVariable): SelectableItem {
    return {
        displayName: variable.name,
        fakeDisabled: variable.disabled ?? false,
        variable,
    };
}

@Component({
    selector: 'variable-selector',
    templateUrl: './variable-selector.component.html',
    styleUrls: ['./variable-selector.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => VariableSelectorComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariableSelectorComponent implements OnChanges, ControlValueAccessor {
    @Input() noVariableLabel?: string;
    @Input() variables: CardWizardVariable[] | null;
    @Input() multiple: boolean = false;

    selectableItems: SelectableItem[] = [];
    currentSelection?: SelectableItem | SelectableItem[];

    private touched = false;
    private onChange?: (_: any) => void;
    private onTouched?: () => void;

    constructor(private changeDetectorRef: ChangeDetectorRef) { }

    get placeholder(): string {
        return this.multiple ? "Select variables..." : "Select variable...";
    }

    isContinuous(item: SelectableItem): boolean {
        return item?.variable?.type === Variable.Type.CONTINUOUS;
    }

    isCategorical(item: SelectableItem): boolean {
        return item?.variable?.type === Variable.Type.CATEGORICAL;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.variables) {
            this.makeSelectableItems();
            this.fixupCurrentSelection();
        }
    }

    private makeSelectableItems() {
        let selectableItems = (this.variables ?? []).map(toSelectableItem);

        if (this.noVariableLabel) {
            const noVariableItem: SelectableItem = {
                displayName: this.noVariableLabel,
                fakeDisabled: false,
            };

            selectableItems = [
                noVariableItem,
                ...selectableItems,
            ];
        }

        this.selectableItems = selectableItems;
    }

    /**
     * Updates the current selection to make sure that the references to
     * objects are kept up to date.
     *
     * Notes:
     *   - the selected items which are no longer in the list of
     *     selectable items are preserved.
     *
     *   - when there is a null item to represent "no value", it is
     *     automatically selected (ie when `noVariableLabel` is set)
     */
    private fixupCurrentSelection(): void {
        if (this.multiple) {
            this.fixupCurrentMultipleSelection();
        } else {
            this.fixupCurrentSingleSelection();
        }
    }

    /**
     * Fixes up the current selection, under a single selection context.
     */
    private fixupCurrentSingleSelection(): void {
        if (this.currentSelection == null) {
            // Automatically select the "null object" by default, if it exists.
            this.currentSelection = this.selectableItems.find(si => si.variable == null);
            return;
        }

        if (Array.isArray(this.currentSelection)) {
            throw new Error("the current selection is bound to an array in single mode");
        }

        const selectedVariable = this.currentSelection.variable;
        const matchingItem = this.selectableItems.find(si => si.variable?.name === selectedVariable?.name);
        if (matchingItem != null) {
            this.currentSelection = matchingItem;
        }
    }

    /**
     * Fixes up the current selection, under a multiple selection context.
     */
    private fixupCurrentMultipleSelection(): void {
        if (this.currentSelection == null) {
            return;
        }

        if (!Array.isArray(this.currentSelection)) {
            throw new Error("the current selection is not bound to an array in multiple mode");
        }

        this.currentSelection = this.currentSelection.map(item => {
            const matchingItem = this.selectableItems.find(si => si.variable?.name === item.variable?.name);
            return matchingItem ?? item;
        });
    }

    writeValue(value: CardWizardVariable | CardWizardVariable[] | undefined): void {
        if (value) {
            if (Array.isArray(value)) {
                this.currentSelection = value.map(toSelectableItem);
            } else {
                this.currentSelection = toSelectableItem(value);
            }
        } else {
            this.currentSelection = undefined;
        }

        this.fixupCurrentSelection();
        this.changeDetectorRef.markForCheck();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    changeCurrentSelection(selection: SelectableItem | SelectableItem[] | undefined): void {
        this.currentSelection = selection;

        if (Array.isArray(selection)) {
            this.onChange?.(selection.map(item => item.variable));
        } else {
            this.onChange?.(selection?.variable);
        }

        if (!this.touched) {
            this.touched = true;
            this.onTouched?.();
        }
    }
}
