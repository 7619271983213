// stolen from rxjs internals
export const isArray = (() => Array.isArray || (<T>(x: any): x is T[] => x && typeof x.length === 'number'))();

// stolen from rxjs internals
export function isNumeric(val: any): val is number | string {
    // parseFloat NaNs numeric-cast false positives (null|true|false|"")
    // ...but misinterprets leading-number strings, particularly hex literals ("0x...")
    // subtraction forces infinities to NaN
    // adding 1 corrects loss of precision from parseFloat (#15100)
    return !isArray(val) && (val - parseFloat(val) + 1) >= 0;
}

export function compareNumbers(a: string | number, b: string | number, isAsc: boolean): number {
    let compareValue;

    if (typeof a == 'string') {
        a = parseFloat(a);
    }

    if (typeof b == 'string') {
        b = parseFloat(b);
    }

    if (isFinite(a - b)) {
        compareValue = a - b;
    } else {
        compareValue = isFinite(a) ? -1 : 1;
    }
    return compareValue * (isAsc ? 1 : -1);
}

export function compareStrings(a: string | number, b: string | number, isAsc: boolean): number {
    // Not all browsers support the extended options for localeCompare(). As such, let's
    // wrap the extended version in a try/catch and just fall-back to using the simple
    // version. In this case, we're going to use the "numeric" option, which gets the
    // browser to treat embedded numbers AS NUMBERS, which allows for a more "natural
    // sort" behavior.
    if (typeof a == 'number') {
        a = a.toString();
    }
    if (typeof b == 'number') {
        b = b.toString();
    }

    try {
        return a.localeCompare(b, undefined, { numeric: true }) * (isAsc ? 1 : -1);
    } catch (error) {
        console.warn("Extended localeCompare() not supported in this browser.");
        return (a.localeCompare(b)) * (isAsc ? 1 : -1);
    }
}