import { Injectable } from "@angular/core";
import { APIError, catchAPIError, ErrorContext } from "@core/dataiku-api/api-error";
import { DataikuAPIService } from "@core/dataiku-api/dataiku-api.service";
import { CurrentRouteService } from "@core/nav/current-route.service";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { DataAugmentationService, DeepHubPreTrainModelingParams } from "src/generated-sources";
@Injectable()
export class DataAugmentationFetchingService implements ErrorContext {
    private error$ = new BehaviorSubject<APIError | undefined>(undefined);

    constructor(private DataikuAPI: DataikuAPIService, private currentRoute: CurrentRouteService) {}
    
    pushError(error?: APIError) {
        this.error$.next(error);
    }

    getError(): Observable<APIError | undefined> {
        return this.error$.pipe(distinctUntilChanged());
    }
    
    resetError() {
        this.error$.next(undefined);
    }

    fetchAugmentedImagesData(imagePath: string, augmentationParams: DeepHubPreTrainModelingParams.ImageAugmentationParams, 
        numAugmentedVersions: number, applyMaxTransform: boolean, augmentationType: DataAugmentationService.AugmentationType) : Observable<DataAugmentationService.AugmentedImage[]> {
        return this.DataikuAPI.analysis.getAugmentedImages(this.currentRoute.projectKey,
                                                           this.currentRoute.analysisId,
                                                           this.currentRoute.mlTaskId,
                                                           augmentationParams,
                                                           imagePath,
                                                           numAugmentedVersions, 
                                                           applyMaxTransform, 
                                                           augmentationType).pipe(catchAPIError(this));
    }

    getRandomImagePaths(numImagePaths:number) :Observable<string[]> {
        return this.DataikuAPI.analysis.getRandomImagePaths(this.currentRoute.projectKey,
            this.currentRoute.analysisId,
            this.currentRoute.mlTaskId,
            numImagePaths).pipe(catchAPIError(this));
    }
}