import type {Distribution, _Distribution} from './distribution';

/**
 * Generated from com.dataiku.dip.eda.compute.distributions.Exponential
 */
export interface Exponential extends _Distribution {
    // PolyJson type
    type: 'exponential';
}

export namespace Exponential {
    export const type = 'exponential';
}

/** @deprecated Use Distribution.isExponential() */ export function isExponential(toTest: Distribution): toTest is Exponential { return Exponential.type === toTest['type']; }

export namespace Exponential {
    /**
     * Generated from com.dataiku.dip.eda.compute.distributions.Exponential$FittedExponential
     */
    export interface FittedExponential extends Distribution._FittedDistribution {
        rate: number;

        // PolyJson type
        type: 'exponential';
    }

    export namespace FittedExponential {
        export const type = 'exponential';
    }

    /** @deprecated Use Distribution.FittedDistribution.isFittedExponential() */ export function isFittedExponential(toTest: Distribution.FittedDistribution): toTest is FittedExponential { return FittedExponential.type === toTest['type']; }
}