import { SuggestCards } from './../../../../../../generated-sources/src/main/java/com/dataiku/dip/eda/worksheets/queries/suggest-cards';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { isAbstractHeaderCard, isACFPlotCard, isPCACard } from 'src/generated-sources';

@Component({
    selector: 'proposed-card',
    templateUrl: './proposed-card.component.html',
    styleUrls: ['./proposed-card.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProposedCard {
    @Input() isSelected: boolean;
    @Input() isAlreadyIncludedInWorksheet: boolean;
    @Input() proposedVisualization: SuggestCards.SuggestedCard;
    @Output() select: EventEmitter<any> = new EventEmitter();
    @Output() showDetails: EventEmitter<any> = new EventEmitter();

    thumbnailWidth = 260;
    thumbnailHeight = 80;

    get desiredCardHeight() {
        if(this.isWideThumbnail && this.isLongThumbnail) {
            return 650;
        }
        if(this.isWideThumbnail) {
            return 400;
        }
        if(this.isLongThumbnail) {
            return 650;
        }
        return 200;
    }

    get desiredCardWidth() {
        if(this.isWideThumbnail && this.isLongThumbnail) {
            return 1300;
        }
        if(this.isWideThumbnail) {
            return 1300;
        }
        if(this.isLongThumbnail) {
            return 650;
        }
        return 650;
    }

    get displayRatio() {
        const widthRatio = this.thumbnailWidth / this.desiredCardWidth;
        const heightRatio = this.thumbnailHeight / this.desiredCardHeight;
        return Math.min(widthRatio, heightRatio, 1.0)
    }

    get isWideThumbnail(): boolean {
        const { card } = this.proposedVisualization;
        return isAbstractHeaderCard(card)
            || isPCACard(card)
            || isACFPlotCard(card);
    }

    get isLongThumbnail(): boolean {
        const { card } = this.proposedVisualization;
        return !isAbstractHeaderCard(card) && card.splitBy != null;
    }
}
