<div class="line feature-store__feature-group-actions" *ngIf="!selectedFeature">
    <h4 [attr.data-qa-smid]="'source-project-label'">Source project</h4>
    <p><a [href]="linkToProject(details.datasetFullInfo.dataset.projectKey)" class="highlight" [innerHtml]="formatProjectName() | escapeHighlightedHtml"></a></p>
    <a (click)="!this.disableNavigator() && showNavigator(details.datasetFullInfo.dataset.projectKey, details.datasetFullInfo.dataset.name)" class="btn btn--secondary btn--icon btn--dku-icon"
        [matTooltip]="navigateTitle" [class.disabled]="disableNavigator()">
        <i class="dku-icon-compass-16" [attr.data-qa-smid]="'navigate-around'"></i>
    </a>
    <a class="btn btn--secondary btn--icon btn--dku-icon" (click)="viewInProject()"
    [matTooltip]="viewInProjectTitle" [class.disabled]="disableViewInProject()">
        <i class="dku-icon-flow-16 icon-rotate-180" [attr.data-qa-smid]="'flow-button'"></i>
    </a>
</div>

<div class="line">
    <h4>Data connection type</h4>
    <p>{{featureGroupType()}}</p>
</div>
<div class="block" *ngIf="details.datasetFullInfo.dataset.shortDesc">
    <h4>About</h4>
    <markdown class="markdown-description"
        [from]="formatShortDesc() | escapeHighlightedHtml:'highlighted'"></markdown>
</div>
<div class="block" *ngIf="details.datasetFullInfo.dataset.description">
    <h4>Description</h4>
    <markdown class="markdown-description"
        [from]="formatDescription() | escapeHighlightedHtml:'highlighted'"></markdown>
</div>
<div class="line" *ngIf="selectedFeature && details.datasetFullInfo.dataset.tags.length > 0">
    <h4>Tags</h4>
    <div class="feature-store__feature-group-details-tags">
        <ul class="tags feature-store__tags-listing inline">
            <li *ngFor="let tag of details.datasetFullInfo.dataset.tags">
                <span class="tag feature-store__tag" [ngStyle]="{'background-color': tagBackgroundColor(details.datasetFullInfo.dataset.projectKey, tag)}">
                    <ui-global-tag [tag]="tag" objectType="DATASET"></ui-global-tag>
                </span>
            </li>
        </ul>
    </div>
</div>

<ng-container *ngIf="!selectedFeature">
    <hr/>

    <feature-group-general-info
        [details]="details"
        [users]="users"
    ></feature-group-general-info>
</ng-container>

<right-panel-accordion-section [opened]="true" *ngIf="datasetFeatures.length > 0">
    <div title [attr.data-qa-smid]="'features-title'">Features ({{datasetFeatures.length}})</div>
    <right-panel-schema
        content
        wordingOption="feature"
        [columns]="datasetFeatures"
        [columnHighlight]="featuresHighlighting"
        [selected]="selectedFeature?._source?.name"
        [maxHeight]="370"
    ></right-panel-schema>
</right-panel-accordion-section>

<div class="accordion" *ngIf="numberOfUsages > 0">
    <h4 class="accordion-title" (click)="showUsage=!showUsage">
        <i [ngClass]="{'dku-icon-chevron-up-16':showUsage, 'dku-icon-chevron-down-16':!showUsage}"></i>
        Usage
        <span>
            (<span>{{numberOfUsages}}</span>)
        </span>
    </h4>
    <div [hidden]="!showUsage" class="feature-store__accordion-body">
        <div class="feature-store__feature-group-project-usage horizontal-flex" *ngFor="let project of details.usages.projectsWithAccess; let last=last;" [class.dku-border-bottom]="details.usages.usagesInNonAccessibleProjects > 0 || !last">
            <p><a [href]="linkToProject(project.projectKey)">{{project.projectName}} ({{project.projectKey}})</a></p>
            <a (click)="showNavigator(project.projectKey, details.datasetFullInfo.dataset.projectKey + '.' + details.datasetFullInfo.dataset.name)" class="btn btn--secondary btn--icon btn--dku-icon" alt="Navigate around" matTooltip="Navigate around">
                <i class="dku-icon-compass-16"></i>
            </a>
            <a class="btn btn--secondary btn--icon btn--dku-icon" [href]="flowLink(project.projectKey, details.datasetFullInfo.dataset.projectKey + '.' + details.datasetFullInfo.dataset.name)" alt="See in flow" matTooltip="See in flow">
                <i class="dku-icon-flow-16 icon-rotate-180"></i>
            </a>
        </div>
        <div *ngIf="details.usages.usagesInNonAccessibleProjects > 0" class="feature-store__feature-group-no-access-to-project">
            <span>+ {{details.usages.usagesInNonAccessibleProjects}} non-accessible project{{details.usages.usagesInNonAccessibleProjects < 2 ? "":"s"}}</span>
        </div>
    </div>
</div>

<dataset-status objectName="" [checklists]="details.datasetFullInfo.dataset.checklists.checklists" [data]="details.datasetFullInfo" context="feature-store" [readOnly]="true"></dataset-status>

<related-by-type [elementsByType]="details.splitInputsByType" baseString="Related initial" [baseItemProjectKey]="details.datasetFullInfo.dataset.projectKey"></related-by-type>
<related-by-type [elementsByType]="details.splitOutputsByType" baseString="Related final" [baseItemProjectKey]="details.datasetFullInfo.dataset.projectKey"></related-by-type>
