import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'feed-image-modal-section',
    templateUrl: './feed-image-modal-section.component.html',
    styleUrls: ['./feed-image-modal-section.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedImageModalSectionComponent {
    @Input() sectionTitle: string;
}
