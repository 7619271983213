import { Observable, defer } from 'rxjs';
import { Variable, Sample, InteractiveQuery } from 'src/generated-sources';
import { Injectable } from '@angular/core';
import { switchMap, first } from 'rxjs/operators';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { FutureWatcherService, filterNonNull, Filter } from 'dku-frontend-core';

@Injectable()
export abstract class SampleContextService {
    constructor(protected DataikuAPI: DataikuAPIService, protected futureWatcherService: FutureWatcherService) {
    }

    abstract availableVariables(): Observable<Variable[]>;
    abstract getSample(): Observable<Sample | undefined>;
    abstract forceLoadSample(): void;

    runInteractiveQuery<T extends InteractiveQuery>(query: T) {
        return defer(() => {
            this.forceLoadSample();
            return this.getSample().pipe(
                filterNonNull(),
                first(),
                switchMap(sample => this.DataikuAPI.statistics.runInteractiveQuery(query, sample.id).pipe(
                    this.futureWatcherService.waitForResult()
                ))
            )
        });
    }
}
