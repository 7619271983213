<p>
    The {{ isPairwise ? 'pairwise' : '' }} Student 𝘵-test
        is a parametric test that tests the hypothesis that the mean of
    <b>{{ params.xColumn.name }}</b>
    is identical {{ isPairwise ? 'in every pair of' : 'in both' }} populations.
</p>

<p>
    <list-compared-populations [params]="params"></list-compared-populations>
</p>

<p>
    There are two possible outcomes depending on the {{ isPairwise ? '𝘱-values' : '𝘱-value' }}:
</p>

<ul>
    <li>
        <b>
            𝘱-value &le; significance level (&le; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>
        <br>
        The hypothesis is rejected. The
        mean <b>{{ params.xColumn.name }}</b>
        is not the same {{ isPairwise ? 'for this pair of' : 'in both' }} populations.
    </li>
    <li>
        <b>
            𝘱-value is &gt; significance level (&gt; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>

        <br>
        Not enough evidence against the hypothesis. Test is inconclusive.
    </li>
</ul>

<p>
    It has been shown that the conclusion is meaningful under the following conditions:
</p>

<ul>
    <li>
        Observations of
        <b>{{ params.xColumn.name }}</b>
        are independent and identically distributed
    </li>
    <li>
        Variable
        <b>{{ params.xColumn.name }}</b>
        is normally distributed in {{ hasTwoSamples ? 'both' : 'every' }} populations
        <br>
        <span
            class="text-weak"
            *ngIf="canAddCard"
        >
            <i class="icon-lightbulb"></i>
            You may want to check this assumption using a
            <a (click)="addGroupedShapiro()">Shapiro-Wilk test</a>
        </span>
    </li>
    <li>
        Variance of
        <b>{{ params.xColumn.name }}</b>
        is identical in {{ hasTwoSamples ? 'both' : 'every' }} populations
        <br>
        <span
            class="text-weak"
            *ngIf="canAddCard"
        >
            <i class="icon-lightbulb"></i>
            You may want to have a look at
            <a (click)="addGroupedSummary()">summary statistics</a>
        </span>
    </li>
</ul>
<p>
    <span class="text-weak">
        <i class="icon-info-sign"></i>
        Missing values are discarded
    </span>
    <br>
    <span class="text-weak">
        <i class="icon-info-sign"></i>
        &ngsp;
        <a
            href="https://docs.scipy.org/doc/scipy/reference/generated/scipy.stats.ttest_ind.html"
            target="_blank"
        >More information
        </a>
    </span>
</p>
