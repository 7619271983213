import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges } from "@angular/core";

@Component({
    selector: 'user-picture',
    template: `<span [class]="'avatar' + size" [style.backgroundImage]="url" [style.border]="borderColor ? '2px solid ' + borderColor : ''"></span>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPictureComponent implements OnChanges {
    url: string;

    @Input() user?: string | null;
    @Input() size: number;
    @Input() borderColor?: string;

    constructor(
        @Inject('UserImageUrl') private UserImageUrl: (userLogin: string, size: number) => string,
    ) {}

    ngOnChanges() {
        if (this.user) {
            this.url = `url(${this.UserImageUrl(this.user, this.size)})`;
        }
    }
}
