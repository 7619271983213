import type {CardResult, _CardResult} from './../card-result';
import type {Card} from './../card';
import type {Filter} from './../../../compute/filtering/filter';
import type {_Abstract2DPivotCard} from './abstract2-dpivot-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHistogramCard
 */
export interface BivariateHistogramCard extends _Abstract2DPivotCard {
    highlightFilter?: Filter | null;

    // PolyJson type
    type: 'bivariate_histogram';
}

export namespace BivariateHistogramCard {
    export const type = 'bivariate_histogram';
}

/** @deprecated Use Card.isBivariateHistogramCard() */ export function isBivariateHistogramCard(toTest: Card): toTest is BivariateHistogramCard { return BivariateHistogramCard.type === toTest['type']; }

export namespace BivariateHistogramCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHistogramCard$BivariateHistogramData
     */
    export interface BivariateHistogramData {
        counts: number[];
        highlightedCounts?: number[] | null;
        xBins: Filter[];
        yBins: Filter[];
    }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHistogramCard$BivariateHistogramCardResult
     */
    export interface BivariateHistogramCardResult extends _CardResult {
        histogram: BivariateHistogramCard.BivariateHistogramData;

        // PolyJson type
        type: 'bivariate_histogram';
    }

    export namespace BivariateHistogramCardResult {
        export const type = 'bivariate_histogram';
    }

    /** @deprecated Use CardResult.isBivariateHistogramCardResult() */ export function isBivariateHistogramCardResult(toTest: CardResult): toTest is BivariateHistogramCardResult { return BivariateHistogramCardResult.type === toTest['type']; }
}