import { DkuHttpService, FutureWatcherService, TypedFutureResponse, Nullable } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DatasetMetricsStatusService, FilterRequest, NavigatorService, SerializedDataset, SerializedMemTableV2, SerializedShakerScript, SerializedTableChunk, EditableDatasetService, VersionTag } from 'src/generated-sources';

@Injectable({providedIn: 'root'})
export class PrivateDatasetsAPI {
    constructor(
        private futureWatcher: FutureWatcherService,
        private dkuHttp: DkuHttpService) {
    }

    get(projectKey: string, name: string, contextProjectKey?: string): Observable<SerializedDataset> {
        return this.dkuHttp.request('GET', '/datasets/get', { projectKey, name, contextProjectKey });
    }

    listPartitionsWithName(projectKey: string, datasetName: string): Observable<String[]> {
        return this.dkuHttp.request('GET', '/datasets/list-partitions-with-name', { projectKey, datasetName });
    }

    getFullInfo(contextProjectKey: string, projectKey: string, name: string): Observable<NavigatorService.DatasetFullInfo> {
        return this.dkuHttp.request('GET', '/datasets/get-full-info', { projectKey, name, contextProjectKey });
    }

    refreshTable(contextProjectKey: string, projectKey:string, datasetName:string, data: SerializedShakerScript,
                 allowCache: boolean, filters: FilterRequest, nbCols: number, requestedSampleId: string|null): Observable<TypedFutureResponse<SerializedMemTableV2>> {
        // explicitely passing requestSampleId as undefined if null, otherwise, it is sent as "null"
        return this.futureWatcher.requestFuture("POST", "/shaker/refresh-table",
                                    {contextProjectKey, projectKey, datasetName, data: JSON.stringify(data), allowCache, filters: JSON.stringify(filters), requestedSampleId: requestedSampleId ? requestedSampleId : undefined, nbCols});
    }

    getTableChunk(contextProjectKey:string, projectKey: string, datasetName: string,
                  data: SerializedShakerScript, firstRow: number, nbRows: number,
                  firstCol: number, nbCols: number, filters: FilterRequest, requestedSampleId: string|null): Observable<SerializedTableChunk>{
        // explicitely passing requestSampleId as undefined if null, otherwise, it is sent as "null"
        return this.dkuHttp.request("POST", "/shaker/get-table-chunk",
                                    {contextProjectKey, projectKey, datasetName, data: JSON.stringify(data),
                                     requestedSampleId: requestedSampleId ? requestedSampleId : undefined, firstRow, nbRows, firstCol, nbCols, filters: JSON.stringify(filters)});
    }

    getData(projectKey: string, dataset: string): Observable<EditableDatasetService.EditableDatasetGetResult>{
        return this.dkuHttp.request("POST", "/datasets/editable/get", {
            projectKey: projectKey,
            dataset: dataset
        });
    }

    save(projectKey: string, dataset: string, data: EditableDatasetService.EditableDatasetSaveQuery): Observable<EditableDatasetService.EditableDatasetSaveResult | VersionTag.ConflictCheckResult>{
        return this.dkuHttp.request("POST", "/datasets/editable/save", {
            projectKey: projectKey,
            dataset: dataset,
            data: JSON.stringify(data)
        });
    }

    getRefreshedSummaryStatus(projectKey: string, datasetName: string, computeRecords: boolean, forceRecompute: boolean): Observable<TypedFutureResponse<DatasetMetricsStatusService.DatasetSummaryStatusWithMessages>> {
        return this.futureWatcher.requestFuture("POST", "/datasets/get-refreshed-summary-status", {
            projectKey: projectKey,
            datasetName:datasetName,
            computeRecords:computeRecords,
            forceRecompute: forceRecompute
        });
    }

    getForExport(projectKey: string, name:string, contextProjectKey:string = projectKey): Observable<SerializedDataset> {
        return this.dkuHttp.request("GET", "/datasets/get-for-export", {projectKey, name, contextProjectKey});
    }

    exportDS(contextProjectKey: string, projectKey: string, name: string, exportParams: any): Observable<any> {
        return this.dkuHttp.request("POST", "/datasets/export", {contextProjectKey, projectKey, name, params: JSON.stringify(exportParams)});
    }

    listPartitions(dataset: SerializedDataset): Observable<Array<String>> {
        return this.dkuHttp.request("POST", "/datasets/list-partitions", {data: JSON.stringify(dataset)});
    }

    setDataSteward(projectKey: string, name: string, dataSteward: Nullable<string>) {
        return this.dkuHttp.request("POST", "/datasets/set-data-steward",  {
            projectKey: projectKey,
            name: name,
            dataSteward: dataSteward,
        });
    }

}
