import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    ChartColorPickerModule,
    ChartCustomColorsModule,
    ReferenceLinesFormModule,
    RegressionLineFormModule
} from './modules';

@NgModule({
    imports: [
        CommonModule,
        /*
         *  ! Important !
         *  You should import here:
         *  - Public modules
         *  - Modules which contains downgraded components without any Angular reference
         *  (cf https://github.com/angular/angular/issues/37102), otherwise, components are tree-shaken.
         *  Even though a simple hidden typescript import would work, please add a reference to avoid any issues.
         */
        ReferenceLinesFormModule,
        RegressionLineFormModule,
        ChartColorPickerModule,
        ChartCustomColorsModule
    ]
})
export class SimpleReportModule {
}
