<div cdkDropList [cdkDropListDisabled]="readOnly" (cdkDropListDropped)="drop($event)"
    class="ws-cards-drag-boundary">
    <div *ngFor="let card of params.cards; let cardIndex = index; trackBy: trackByCardId"
        cdkDrag cdkDragBoundary=".ws-cards-drag-boundary">
        <ng-container *ngIf="isCardCollapsible(card.id) | async as collapsed">
            <top-level-card
                (remoteDragHandle)="discoverRemoteDragHandle(cardIndex, $event)"
                [params]="card"
                [results]="results.results[cardIndex]"
                [readOnly]="readOnly"
                [extendedActions]="true"
                [hasFixedHeight]="true"
                (action)="handleTopLevelCardAction(cardIndex, $event)"
            >
            </top-level-card>
            <ng-container *ngIf="!collapsed.v; else collapsedtoplevel">
                <div class="card card__inner card__header drag-drop-placeholder" *cdkDragPlaceholder></div>
            </ng-container>
            <ng-template #collapsedtoplevel>
                <div class="card card__inner card__header drag-drop-placeholder drag-drop-placeholder--collapsed" *cdkDragPlaceholder></div>
            </ng-template>
        </ng-container>
    </div>
</div>