export function isNumeric(type: string) {
    switch (type) {
        case "int":
        case "float":
        case "bigint":
        case "smallint":
        case "tinyint":
        case 'double':
            return true;
        default:
            return false;
    }
}