<div
    class="right-panel-action"
    [matTooltip]="realTooltip"
    matTooltipPosition="right"
>
    <button class="right-panel-action__element"
        *ngIf="href === undefined || disabled"
        (click)="!disabled && clicked.emit()"
        [attr.aria-disabled]="disabled"
        [class.disabled]="disabled"
    >
        <i [class]="icon"></i>
        <label><ng-container *ngTemplateOutlet="contentTpl"></ng-container> </label>
    </button>
    <a class="right-panel-action__element"
        *ngIf="href && !disabled"
        [href]="href"
    >
        <i [class]="icon"></i>
        <label><ng-container *ngTemplateOutlet="contentTpl"></ng-container> </label>
    </a>
</div>

<ng-template #contentTpl><ng-content></ng-content></ng-template>