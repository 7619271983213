import { isNumeric } from '@utils/schema';
import { SchemaColumn, Variable } from 'src/generated-sources';

export function columnsToVariables(schemaColumns: SchemaColumn[]): Variable[] {
    return schemaColumns.map(column => {
        if (isNumeric(column.type)) {
            return { type: Variable.Type.CONTINUOUS, name: column.name };
        } else {
            return { type: Variable.Type.CATEGORICAL, name: column.name };
        }
    });
}