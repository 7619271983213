import { Type } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { fairAny } from 'dku-frontend-core';
import { InterestsService, ProjectInitialsService, SlugifyService, DateUtilsService } from '@shared/services';
import { MigrationStoreService } from './migration-store.service';
import { DashboardFiltersUrlParamsService } from '@features/dashboards';
import { JoinDisplayNamesService } from '@shared/services/join-display-names.service';
import * as agGrid from 'ag-grid-enterprise';

// Downgrades Angular service to an angularJS service
export function service(serviceName: string, svc: Type<fairAny>, ajsModule: string = 'dataiku') {
  const angularJS = (window as fairAny).angular;
  angularJS.module(ajsModule).service(serviceName, downgradeInjectable(svc));
}

// Migrate a constant to an angularJS constant
export function constant(serviceName: string, obj: fairAny, ajsModule: string = 'dataiku') {
  const angularJS = (window as fairAny).angular;
  angularJS.module(ajsModule).constant(serviceName, obj);
}

export function downgradeProviders() {
  return [
    service('ProjectInitials', ProjectInitialsService),
    service('MigrationStoreService', MigrationStoreService),
    service('Ng2InterestService', InterestsService),
    service('DashboardFiltersUrlParams', DashboardFiltersUrlParamsService),
    service('SlugifyService', SlugifyService),
    service('JoinDisplayNamesService', JoinDisplayNamesService),
    service('DateUtilsService', DateUtilsService),
    constant('AgGrid', agGrid)
  ];
}
