import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'data-catalog-home-link',
    templateUrl: './data-catalog-home-link.component.html',
    styleUrls: ['./data-catalog-home-link.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataCatalogHomeLinkComponent {
    @Input() route!: string;
    @Input() src!: string;
    @Input() title!: string;
    @Input() description!: string;
}
