import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FrontendWorkspace, LeftPaneState } from '../../models';
import { WorkspaceLocalStorageService, WorkspaceModalService, WorkspaceSecurityService } from '../../services';
import { LeftPaneComponent } from '@shared/components/left-pane/left-pane.component';
import { ClearableSearchbarComponent } from '@shared/components/clearable-searchbar/clearable-searchbar.component';
import { PlurifyPipe } from '@shared/pipes/text-pipes/plurify.pipe';
const plurify = new PlurifyPipe().transform;

@Component({
    selector: 'workspaces-left-menu',
    templateUrl: './workspaces-left-menu.component.html',
    styleUrls: ['./workspaces-left-menu.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspacesLeftMenuComponent  {
    canCreateWorkspaces: boolean = false;
    isMenuExpanded: boolean;
    isFilterApplied: boolean;
    resultsNumber: number;

    @ViewChild(LeftPaneComponent) leftPane: LeftPaneComponent;
    @ViewChild(ClearableSearchbarComponent) clearableSearchbar: ClearableSearchbarComponent;
    @Input() workspaces = [] as Array<FrontendWorkspace>;
    @Input() selectedWorkspaceKey?: string | null;
    @Output() workspaceSelected = new EventEmitter<string>();

    constructor (
        private workspaceSecurityService: WorkspaceSecurityService,
        private workspaceModal: WorkspaceModalService,
        private workspaceLocalStorageService: WorkspaceLocalStorageService
    ) {
        this.canCreateWorkspaces = this.workspaceSecurityService.canCreateWorkspaces();
        this.isMenuExpanded = this.workspaceLocalStorageService.getLeftPaneState() === LeftPaneState.CLOSED ? false : true;
    }

    toggleMenu(isOpen: boolean) {
        this.isMenuExpanded = isOpen;
        this.workspaceLocalStorageService.saveLeftPaneState(this.isMenuExpanded ? LeftPaneState.OPEN : LeftPaneState.CLOSED);
        if (this.isMenuExpanded === false) {
            this.clearSearch();
            this.clearableSearchbar.disableInput();
        } else {
            this.clearableSearchbar.enableInput();
        }
    }

    clearSearch() {
        this.clearableSearchbar.clearSearch();
    }

    filterWorkspaces(searchTerm: string) {
        this.isFilterApplied = false;
        this.resultsNumber = 0;
        this.workspaces.forEach(workspace => {
            const sanitizedSearchTerm = searchTerm.toLowerCase().replace(/[, ]+/, '');
            const sanitizedWorkspaceName = workspace.displayName.toLowerCase().replace(/[, ]+/, '');
            if (!sanitizedWorkspaceName.includes(sanitizedSearchTerm) && searchTerm.length > 0) {
                workspace.visible = false;
                this.isFilterApplied = true;
            } else {
                workspace.visible = true;
                this.resultsNumber++;
            }
        });
    }

    trackWorkspace(index: number, workspace: FrontendWorkspace): string {
        return workspace.workspaceKey;
    }

    selectWorkspace(workspaceKey: string): void {
        this.workspaceSelected.emit(workspaceKey);
    }

    onAddWorkspace(): void {
        this.workspaceModal.createWorkspace();
    }

    getWorkspacesCountLabel(): string {
        return plurify(' workspace', this.workspaces.length);
    }
}
