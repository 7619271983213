import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { KsTest2SampCard } from 'src/generated-sources';
import { CardAction } from '../../events';

@Component({
    selector: 'ks-test2-samp-card-help',
    templateUrl: './ks-test2-samp-card-help.component.html',
    styleUrls: ['./ks-test2-samp-card-help.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KsTest2SampCardHelpComponent {
    @Input() params: KsTest2SampCard;
    @Input() readOnly: boolean;
    @Input() extendedActions: boolean;
    @Output() action = new EventEmitter<CardAction>();
}
