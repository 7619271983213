import { Injectable } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { cloneDeep } from "lodash";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { filter, map, withLatestFrom } from "rxjs/operators";
import { AnnotationGroup } from "@features/labeling/models/annotation-group";
import { UIAnnotation } from "@features/labeling/models/annotation";

@UntilDestroy()
@Injectable()
export class LabelingReviewAnnotationGroupService {
    annotationGroupList$ = new BehaviorSubject<AnnotationGroup[]>([]);
    fetchedAnnotationGroupList$ = new BehaviorSubject<AnnotationGroup[]>([]);
    private selectAnnotationGroupTrigger$ = new Subject<number>();
    private deleteAnnotationGroupTrigger$ = new Subject<number>();
    private updateAnnotationGroupTrigger$ = new Subject<UIAnnotation[]>();
    private addAnnotationGroupTrigger$ = new Subject<{newGroup: AnnotationGroup, openOnAdding: boolean}>();
    selectedAnnotationGroup$: Observable<AnnotationGroup>;
    isConflicting$: Observable<boolean>;
    private modifiedByReviewerTrigger$ = new Subject<void>();
    private resetAnnotationGroupTrigger$ = new Subject<void>();
    private resolvedTrigger$ = new Subject<void>();

    constructor() {
        this.selectAnnotationGroupTrigger$.pipe(
            untilDestroyed(this),
            withLatestFrom(this.annotationGroupList$),
        ).subscribe(([index, annotationGroupList]) => {
            if (annotationGroupList[index]) {
                if (!annotationGroupList[index].selected) {
                    for (let annotationGroup of annotationGroupList) {
                        annotationGroup.selected = false;
                        // deselect all annotations
                        annotationGroup.annotations.forEach(annotation => annotation.selected = false);
                    }
                }
                annotationGroupList[index].selected = !annotationGroupList[index].selected;

                //Automatically select first box
                annotationGroupList[index].annotations[0].selected = !annotationGroupList[index].annotations[0].selected;

                this.annotationGroupList$.next(annotationGroupList);
            }
        })

        this.deleteAnnotationGroupTrigger$.pipe(
            untilDestroyed(this),
            withLatestFrom(this.annotationGroupList$),
        ).subscribe(([index, annotationGroupList]) => {
            if (annotationGroupList[index]) {
                annotationGroupList.splice(index, 1);
                this.annotationGroupList$.next(annotationGroupList);
            }
        })

        this.selectedAnnotationGroup$ = this.annotationGroupList$.pipe(
            map(annotationGroupList => annotationGroupList.filter(r => r.selected)[0]),
            filter(annotationGroup => annotationGroup != undefined)
        )

        this.isConflicting$ = this.annotationGroupList$.pipe(
            map(
                annotationGroupList => annotationGroupList.some(
                    annotationGroup => annotationGroup.hasConflict()
                )
            )
        );

        this.updateAnnotationGroupTrigger$.pipe(
            untilDestroyed(this),
            withLatestFrom(this.annotationGroupList$)
        ).subscribe(([annotations, annotationGroupList]) => {
            if (annotationGroupList) {
                annotationGroupList.filter(r => r.selected)[0].annotations = annotations;
                annotationGroupList = annotationGroupList.filter(r => r.annotations.length > 0);
                this.annotationGroupList$.next(annotationGroupList);
            }
        })

        this.modifiedByReviewerTrigger$.pipe(
            untilDestroyed(this),
            withLatestFrom(this.annotationGroupList$)
        ).subscribe(([_trigger, annotationGroupList]) => {
            if (annotationGroupList.filter(r => r.selected).length) {
                annotationGroupList.filter(r => r.selected)[0].modifiedByReviewer = true;
            }
        })

        this.resolvedTrigger$.pipe(
            untilDestroyed(this),
            withLatestFrom(this.annotationGroupList$)
        ).subscribe(([_trigger, annotationGroupList]) => {
            if (annotationGroupList.filter(r => r.selected).length) {
                annotationGroupList.filter(r => r.selected)[0].resolved = true;
            }
        });

        this.resetAnnotationGroupTrigger$.pipe(
            untilDestroyed(this),
            withLatestFrom(this.fetchedAnnotationGroupList$)
        ).subscribe(([_trigger, annotationGroupList]) => {
            this.annotationGroupList$.next(cloneDeep(annotationGroupList));
        })

        this.addAnnotationGroupTrigger$.pipe(
            untilDestroyed(this),
            withLatestFrom(this.annotationGroupList$)
        ).subscribe(([{newGroup, openOnAdding}, annotationGroupList]) => {
            if (annotationGroupList) {                
                // select new one and unselect other groups if needed 
                if (openOnAdding) {
                    newGroup.selected = true;
                    annotationGroupList.forEach((g) => g.selected = false);
                }
                
                const idxToAddNewGrp = newGroup.idxToInsert(annotationGroupList)
                const newGroupList = [...annotationGroupList.slice(0, idxToAddNewGrp), newGroup, ...annotationGroupList.slice(idxToAddNewGrp)];
                this.annotationGroupList$.next(newGroupList);
            }
        })
    }

    selectAnnotationGroup(index: number) {
        this.selectAnnotationGroupTrigger$.next(index);
    }

    deleteAnnotationGroup(index: number) {
        this.deleteAnnotationGroupTrigger$.next(index);
    }

    resetAnnotationGroupList() {
        this.resetAnnotationGroupTrigger$.next();
    }

    updateAnnotationGroup(annotation: UIAnnotation[], modifiedByReviewer?: boolean, resolvedByReviewer?: boolean) {
        if (modifiedByReviewer) {
            this.modifiedByReviewerTrigger$.next();
        }

        if (resolvedByReviewer) {
            this.resolvedTrigger$.next();
        }

        this.updateAnnotationGroupTrigger$.next(annotation);
    }

    addAnnotationGroup(annotationGroup: AnnotationGroup, openOnAdding?: boolean) {
        this.addAnnotationGroupTrigger$.next({newGroup: annotationGroup, openOnAdding: openOnAdding || false});
    }
}