/**
 * Generated from com.dataiku.dip.eda.EdaErrorCodes
 */
export enum EdaErrorCodes {
    ERR_EDA_COMPUTE = 'Unexpected EDA compute error',
    ERR_EDA_COMPUTE_KERNEL_FAILURE = 'Unexpected EDA kernel failure',
    ERR_EDA_COMPUTE_INVALID_PARAMS = 'Invalid computation parameters',
    ERR_EDA_COMPUTE_NOT_INDEPENDENT = 'Groups must be disjoint to be considered independent',
    ERR_EDA_COMPUTE_UNKNOWN_TYPE = 'Unknown object type',
    ERR_EDA_COMPUTE_CAST_TO_NUMERICAL_FAILED = 'Could not cast string to numerical',
    ERR_EDA_COMPUTE_PARSE_TO_DATE_FAILED = 'Could not parse string to date',
    ERR_EDA_COMPUTE_INVALID_RESULT = 'Invalid results',
    ERR_EDA_COMPUTE_NO_DATA = 'No data',
    ERR_EDA_COMPUTE_NOT_ENOUGH_DATA = 'No enough data',
    ERR_EDA_COMPUTE_NOT_ENOUGH_GROUPS = 'No enough groups',
    ERR_EDA_COMPUTE_DEGENERATE = 'Degenerate case',
    ERR_EDA_COMPUTE_NO_SUCH_SAMPLE = 'Sample does not exist (or is not accessible)'
}